import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

interface IButtonProps {
  variant?: "text" | "outlined" | "contained" | undefined;
  color?:
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning"
  | undefined;
  text?: string | undefined;
  onClick: any;
  className?: string;
  nextPagePath?: string;
  isDisable?: boolean;
  isSubmitting?: boolean;
}

export const AppButton: React.FC<IButtonProps> = ({
  variant,
  color,
  text,
  onClick,
  className,
  nextPagePath,
  isDisable,
  isSubmitting
}) => {


  const handleSubmit = (e: any) => {
    onClick(e);
  };

  const LoaderIcon = () => (
    <FontAwesomeIcon icon={faSpinner} spin style={{ marginRight: "8px" }} />
  );

  if (nextPagePath)
    return (
      <>
        <button disabled={isDisable} onClick={handleSubmit} color={color} className={className}>
          <Link to={nextPagePath}>{text}</Link>
        </button>
      </>
    );
  else
    return (
      <>
        <button
          disabled={isDisable || isSubmitting}
          onClick={handleSubmit}
          color={color}
          className={className ? className : "myBtn hover:bg-emerald-600"}
        >
          {isSubmitting ? <LoaderIcon /> : text}
        </button>
      </>
    );
};
