import { AlertColor, IconButton } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppButton } from "../../../common/AppButton";
import { AppFileInput } from "../../../common/AppFileInput";
import { AppInput } from "../../../common/AppInput";
import { AppSnackBar } from "../../../common/AppSnackBar";
import DeleteIcon from "@mui/icons-material/Delete";
import { AppTextArea } from "../../../common/AppTextArea";
import { addBechteChaiMainCatagoryService } from "../../../../httpService/bechteChai/categoryServices";
import { AddBechteChaiMainCatagory } from "../../../../models/bechteChai/category/categoryModels";
import { bechteChaiMainCategoryList } from "../../../../store/actions/bechteChai/bechteChaiCategoryActions";

export default function AdminCreateBechteChaiMainCategory() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);

  const [id, setId] = useState(0);

  const [name, setName] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");

  const [description, setDescription] = useState("");
  const [isPublished, setIsPublished] = useState(false);
  const [isShowOnHomePage, setIsShowOnHomePage] = useState(false);
  const [displayOrder, setDisplayOrder] = useState(0);
  const [userRoleId, setUserRoleId] = useState(1);

  const [bannerId, setBanner] = useState(0);
  const [bannerError, setBannerError] = useState("");

  const [tempPath, setTempPath] = useState("");

  const handleNameChange = (event: any) => {
    setName(event);
    setNameErrorMessage("");
  };

  const handleDescriptionChange = (event: any) => {
    setDescription(event);
  };

  const handleIsPublishedChange = (event: any) => [setIsPublished(event)];

  const handleIsShowOnHomePageChange = (event: any) => {
    setIsShowOnHomePage(event);
  };

  const handleDisplayOrder = (event: any) => {
    setDisplayOrder(event);
  };

  const handleBanner = (id: any, path: any) => {
    setBanner(id);
    setTempPath(path);
    setBannerError("");
  };

  const deleteBanner = () => {
    setBanner(0);
    setTempPath("");
  };

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    setSnackBarOn(false);

    var model = new AddBechteChaiMainCatagory();
    model.id = id;
    model.name = name;
    model.description = description;
    model.isPublished = isPublished;
    model.isShowOnHomePage = isShowOnHomePage;
    model.displayOrder = displayOrder;
    model.userRoleId = userRoleId;
    model.bannerId = bannerId;

    setIsButtonLoading(true);

    addBechteChaiMainCatagoryService(model)
      .then(() => {
        setSnackBarOn(true);
        setsnackBarMessage("Main Category  Added Successfully.");
        setSnackBarMessageType("success");
        setIsButtonLoading(false);
        setTimeout(() => {
          navigate("/admin/bechte-chai/main-categories");
        }, 300);

        dispatch(bechteChaiMainCategoryList());
      })
      .catch((error: any) => {
        setIsButtonLoading(false);
        setNameErrorMessage(error.response.data.errors?.Name?.[0]);
        setBannerError(error.response.data.errors?.BannerId?.[0]);
      });
  };

  return (
    <div>
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      <div className="pl-4 flex items-center justify-between w-3/5 pb-6">
        <AppButton
          className="bechteChaiBtn"
          onClick={() => navigate("/admin/bechte-chai/main-categories")}
          text="Back"
        />
        <h2 className="text-center title">Create Bechte Chai Main Category</h2>
      </div>

      <form className="max-w-xl mx-auto myBorder py-4 px-5">
        <AppInput
          label="Category name"
          className="myInput"
          type="text"
          placeholder="Enter Main Category Name"
          onChange={handleNameChange}
          errorMessage={nameErrorMessage}
        />
        <AppTextArea
          label="Description"
          placeholder="Enter Description"
          onChange={handleDescriptionChange}
        />
        {/* publish and show to home checkbox here  */}
        <div className="flex justify-between items-center py-1">

          <div className="flex items-center gap-x-3">
            <label htmlFor="isPublished" className="">
              Published
            </label>
            <input
              className="w-5 h-5 cursor-pointer"
              type="checkbox"
              name="isPublished"
              onChange={() => handleIsPublishedChange(!isPublished)}
            />
          </div>
          {/* <div className="flex items-center gap-x-3">
            <label htmlFor="isShowHomeOnPage" className="">
              Show on homepage
            </label>
            <input
              className="w-6 h-6 cursor-pointer"
              type="checkbox"
              name="isShowHomeOnPage"
              onChange={() => handleIsShowOnHomePageChange(!isShowOnHomePage)}
            />
          </div> */}
        </div>
        {/* order photo upload and submit button here  */}
        <div className="flex items-center gap-3 py-2 justify-between">
          <div className="w-1/2 flex items-center gap-1">
            <label className="">Photo</label>
            {!bannerId && (
              <AppFileInput onSave={handleBanner} errorMessage={bannerError} />
            )}
            <br />
            {bannerId !== 0 && (
              <div className="flex items-center gap-x-2">
                <img
                  className=""
                  src={`${tempPath}`}
                  alt="upload"
                />
                <IconButton onClick={deleteBanner}>
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
          </div>

          {/* <div className="w-1/2">
            <AppInput
              className="myInput"
              label="Display order"
              type="number"
              placeholder="Enter Display Order"
              onChange={handleDisplayOrder}
            />
          </div> */}
        </div>
        <div className="flex justify-end">
          <AppButton className="bechteChaiBtn" onClick={handleSubmit} isSubmitting={isButtonLoading} variant="contained" text="Submit" />
        </div>
      </form>
    </div>
  );
}
