import { GET_BECHTE_CHAI_COMMENT_LIST, GET_BECHTE_CHAI_COMMENT_LIST_BY_ID, GET_BECHTE_CHAI_FAVOURITE_LIST, GET_BECHTE_CHAI_IS_ALREADY_FAVOURITE, GET_BECHTE_CHAI_MY_PRODUCT_LIST, GET_BECHTE_CHAI_PRODUCT_BY_ID, GET_BECHTE_CHAI_PRODUCT_LIST } from "../../constants/bechteChai/product";

export function bechteChaiProductListReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case GET_BECHTE_CHAI_PRODUCT_LIST:
            return { loading: false, data: action.payload };
        default:
            return state;
    }
}
export function bechteChaiMyProductsListReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case GET_BECHTE_CHAI_MY_PRODUCT_LIST:
            return { loading: false, data: action.payload };
        default:
            return state;
    }
}

export function bechteChaiProductByIdReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case GET_BECHTE_CHAI_PRODUCT_BY_ID:
            return { loading: false, data: action.payload };
        default:
            return state;
    }
}

export function bechteChaiProductCommentListReducer(state = { data: [] }, action: any) {
    switch (action.type) {
      case GET_BECHTE_CHAI_COMMENT_LIST:
        return { ...state, loading: false, data: action.payload };
      case GET_BECHTE_CHAI_COMMENT_LIST_BY_ID:
        return { ...state, loading: false, dataById: action.payload };
      default:
        return state;
    }
  }

export  function bechteChaiGetFavouriteItemReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case GET_BECHTE_CHAI_FAVOURITE_LIST:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}


export function bechteChaiGetIsAlreadyFavouriteReducer(state = { data: false }, action: any) {
    switch (action.type) {
        case GET_BECHTE_CHAI_IS_ALREADY_FAVOURITE:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}


  
