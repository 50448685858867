
import { GET_PUBLICREPRESENTATIVES_NUMBER } from "../../../constants/importantNumbers/publicRepresentatives/publicRepresentativesConstant";


// export function getPublicRepresentativesReducer(state = { data: [] }, action: any) {
//   switch (action.type) {
//     case GET_PUBLICREPRESENTATIVES_NUMBER:
//       return { loading: false, data: action.payload };
//     default:
//       return state;
//   }
// }
// const initialState = {
//     dataOfferList: [],
//   };
// function dataOfferListReducer(state = initialState, action: any) {
//     if (action.type === GET_DATA_OFFER_LIST) {
//       return { dataOfferList: action.payload };
//     }
//     return state;
// }
// export {
//     dataOfferListReducer
// }
// import { GET_DATA_OFFER_LIST } from "../../constants/auth/dataOffer/dataOfferConstatnt";

// const initialState = {
//     publicRepresentativesList: [],
//   };
// function getPublicRepresentativesReducer(state = initialState, action: any) {
//     if (action.type === GET_PUBLICREPRESENTATIVES_NUMBER) {
//       return { publicRepresentativesList: action.payload };
//     }
//     return state;
// }

export function getPublicRepresentativesReducer(state = { data: [] }, action: any) {
	switch (action.type) {
		case GET_PUBLICREPRESENTATIVES_NUMBER:
			return { loading: false, data: action.payload };
		default:
			return state;
	}
}
