export const GET_USER_PROFILE = 'GET_USER_PROFILE';

export const GET_USER = 'GET_USER';

export const GET_USER_NAME = 'GET_USER_NAME';

export const GET_USER_POINT_LIST = 'GET_USER_POINT_LIST';

export const GET_VENDOR_POINT_LIST = 'GET_VENDOR_POINT_LIST';

export const GET_DATA_OFFER_VENDOR_POINT_LIST = 'GET_DATA_OFFER_VENDOR_POINT_LIST';

export const GET_COMPANY_POINT_LIST = 'GET_COMPANY_POINT_LIST';

export const GET_USER_WALLET = 'GET_USER_WALLET';

export const GET_RIDER_WALLET = 'GET_RIDER_WALLET';

export const GET_VENDOR_WALLET = 'GET_VENDOR_WALLET';

export const GET_DATA_OFFER_VENDOR_WALLET = 'GET_DATA_OFFER_VENDOR_WALLET';

export const GET_ADMIN_WALLET = 'GET_ADMIN_WALLET';

export const GET_ADMIN_WALLET_CALCULATIONS = 'GET_ADMIN_WALLET_CALCULATIONS';

export const GET_USER_PULL_HISTORY = 'GET_USER_PULL_HISTORY';

export const GET_VENDOR_PULL_HISTORY = 'GET_VENDOR_PULL_HISTORY';

export const GET_DATA_OFFER_VENDOR_PULL_HISTORY = 'GET_DATA_OFFER_VENDOR_PULL_HISTORY';

export const GET_USER_WITHDRAW_HISTORY = 'GET_USER_WITHDRAW_HISTORY';

export const GET_VENDOR_WITHDRAW_HISTORY = 'GET_VENDOR_WITHDRAW_HISTORY';

export const GET_DATA_OFFER_VENDOR_WITHDRAW_HISTORY = 'GET_DATA_OFFER_VENDOR_WITHDRAW_HISTORY';

export const GET_USER_WITHDRAW_LIST = 'GET_USER_WITHDRAW_LIST';

export const GET_WALLET_RECHARGE_NAGAD_LIST = 'GET_WALLET_RECHARGE_NAGAD_LIST';

export const GET_VENDOR_WITHDRAW_LIST = 'GET_VENDOR_WITHDRAW_LIST';

export const GET_USER_POINT = 'GET_USER_POINT';

export const GET_RIDER_POINT_LIST = 'GET_RIDER_POINT_LIST';

export const GET_VENDOR_POINT = 'GET_VENDOR_POINT';

export const GET_USER_TOTAL_POINT = 'GET_USER_TOTAL_POINT';

export const GET_RESELLER_TEAM = 'GET_RESELLER_TEAM';

export const GET_COMPANY_TEAM = 'GET_COMPANY_TEAM';

export const GET_AFFILIATER_TEAM = 'GET_AFFILIATER_TEAM';

export const GET_USER_DETAILS = 'GET_USER_DETAILS';

export const IS_AREA_SAVED = 'IS_AREA_SAVED';

export const VENDOR_DETAILS_WITH_CAT = 'VENDOR_DETAILS_WITH_CAT';

export const GET_WALLET_RECHARGE_LIST = 'GET_VENDOR_WITHDRAW_LIST';

export const GET_GIVE_BACK_LIST = 'GET_VENDOR_WITHDRAW_LIST';

export const IS_VENDOR_ALL_AMOUNT_PAID = 'IS_VENDOR_ALL_AMOUNT_PAID';

export const GET_BLOOD_DONORS = 'GET_BLOOD_DONORS';

export const GET_DATA_OFFER_ORDER_LIST = 'GET_DATA_OFFER_ORDER_LIST';

export const GET_IS_RIDER_HAVE_TO_PAY = 'GET_IS_RIDER_HAVE_TO_PAY';