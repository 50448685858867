import {
	AddCountryZilaUpazilaAdminRequest,
	AddUpdateEmailPhoneModel,
	ChangePasswordRequest,
	CreateUserRequest,
	DisableUserProfileRequest,
	EmailOrPhoneResetModel,
	EmailOrPhoneTokenResetModel,
	GetCompanyTeamPayload,
	GetGiveBackHistoryRequest,
	GetUserListModel,
	GetWalletRechargeHistoryRequest,
	GetWalletRechargeRequestListRequest,
	GiveBackModel,
	RiderGivenToPayRequest,
	SignInModel,
	SignUpModel,
	UpdateUserAddressRequest,
	UpdateUserProfileRequest,
	UpdateUserRequest,
	VendorSignUpModel,
	VerifyVerificationCodeModel,
	WalletRechargeModel,
	WalletRechargeNagadModel,
	WithdrawRequest
} from "../../models/auth/user/UserModels";
import {
	ChangeDataOfferStatusRequest,
	CreateDataOfferOrderRequest,
	DataOfferOrderListRequest,
	GetUserPointRequest,
	GetUserPointWithdrawHistoryRequest,
	GetUserPullHistoryRequest,
	GetUserWithdrawListRequest,
	GetVendorPointRequest,
	GetVendorPointWithdrawHistoryRequest,
	GetVendorWalletRequest,
	UpdateUserPointRequest,
	UpdateVendorPointRequest,
	UpdateWalletRechargeNagadRequest,
	UserPullAmountRequest,
	VendorPullAmountRequest
} from "../../models/auth/user/UserPointModels";
import { GetBloodDonorsRequest } from "../../models/bloodClub/bloodClubModels";
import { GET, POST, PUT } from "../service";

export const createUserService = (data: CreateUserRequest) => {
	return POST(`/api/Users/createUser`, data);
};

export const getUserListService = (payload: GetUserListModel) => {
	if (payload) {
		return GET(
			`/api/Users/getUsers?limit=${payload?.limit}&offset=${payload?.offset}${payload?.searchQuery ? '&searchQuery=' + payload.searchQuery : ''}&userRole=${payload.userRole}&divisionId=${payload.divisionId}&districtId=${payload.districtId}&areaId=${payload.areaId}`
		);
	} else {
		return GET(`/api/Users/getUsers`);
	}
};

export const getUserDetailsService = (id: any) => {
	if (id) {
		return GET(`/api/Users/GetUserDetails?Id=${id}`);
	}
};

export const getIsAreaSaved = (userId: any = 0) => {
	return GET(`/api/Users/isAreaSaved?userId=${userId}`);
};

export const getUserService = (id: any) => {
	return GET(`/api/Users/getUser?id=${id}`);
};

export const updateUserService = (data: UpdateUserRequest) => {
	return PUT(`/api/Users/updateUser`, data);
};

export const getUserProfileService = () => {
	return GET(`/api/Users/getUserProfile`);
};

export const getUserProfileByIdService = (userId?: any) => {
	return GET(`/api/Users/getUserProfileById?id=${userId}`);
};

export const updateUserProfileService = (data: UpdateUserProfileRequest) => {
	return PUT(`/api/Users/updateUserProfile`, data);
};

export const disableUserProfileService = (data: DisableUserProfileRequest) => {
	return PUT(`/api/Users/disableProfile`, data);
};

export const createUserBloodDonateService = (data: any) => {
	return POST(`/api/Users/createUserBloodDonation`, data);
};

export const changePasswordService = (data: ChangePasswordRequest) => {
	return POST(`/api/Users/change-password`, data);
};

export const getUserPointListService = (payload?: GetUserPointRequest) => {
	return GET(`/api/Wallet/GetUserPoints?searchQuery=${payload?.searchQuery}&userId=${payload?.userId}&earningStatus=${payload?.earningStatus}&earningType=${payload?.earningType}`);
};

export const getRiderPointListService = (payload?: GetUserPointRequest) => {
	return GET(`/api/Wallet/GetRiderPoints?searchQuery=${payload?.searchQuery}&userId=${payload?.userId ? payload?.userId : 0}`);
};

export const getVendorPointListService = (payload?: GetVendorPointRequest) => {
	return GET(`/api/Wallet/GetVendorPoints?searchQuery=${payload?.searchQuery}&userId=${payload?.userId}&earningStatus=${payload?.earningStatus}&earningType=${payload?.earningType}`);
};

export const getCompanyPointListService = (payload?: GetUserPointRequest) => {
	return GET(`/api/Wallet/GetCompanyPoint?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}&userId=${payload?.userId}&earningStatus=${payload?.earningStatus}&earningType=${payload?.earningType}`);
};

export const getUserWalletService = (userId?: any) => {
	return GET(`/api/Wallet/GetUserWallet?userId=${userId}`);
};

export const getRiderWalletService = (userId?: any) => {
	return GET(`/api/Wallet/GetRiderWallet?userId=${userId}`);
};

export const riderGivenToPayService = (model?:RiderGivenToPayRequest ) => {
	return POST(`/api/Wallet/RiderGivenToPay`, model);
};

export const getIsRiderHaveToPayService = () => {
	return GET(`/api/Wallet/IsRiderMustHaveCompletePayment`);
};

export const getCompanyWalletService = () => {
	return GET(`/api/Wallet/GetCompanyWallet`);
};

export const getVendorWalletService = (payload?: GetVendorWalletRequest) => {
	return GET(`/api/Wallet/GetVendorWallet?userId=${payload?.userId}`);
};

export const getUserWithdrawListService = (payload?: GetUserWithdrawListRequest) => {
	return GET(`/api/Wallet/userPointWithdrawRequestList?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}&pointStatus=${payload?.pointStatus}`,);
};

export const getWalletRechargeListService = (payload?: GetWalletRechargeRequestListRequest) => {
	return GET(`/api/Wallet/GetWalletRechargeManualList?limit=${payload?.limit}&offset=${payload?.offset}&requestStatus=${payload?.requestStatus}&SearchQuery=${payload?.searchQuery}`);
};

export const getVendorWithdrawListService = (payload?: GetUserWithdrawListRequest) => {
	return GET(`/api/Wallet/VendorPointWithdrawRequestList?limit=${payload?.limit}&offset=${payload?.offset}&pointStatus=${payload?.pointStatus}`,);
};

export const userPullAmountService = (payload?: UserPullAmountRequest) => {
	return POST(`/api/Wallet/UserPullAmount`, payload);
};

export const vendorPullAmountService = (payload?: VendorPullAmountRequest) => {
	return POST(`/api/Wallet/VendorPullAmount`, payload);
};

export const getUserPullHistoryService = (payload?: GetUserPullHistoryRequest) => {
	return GET(`/api/Wallet/GetUserPullHistory?UserId=${payload?.userId}&Earningtype=${payload?.earningType}`,);
};

export const getVendorPullHistoryService = () => {
	return GET(`/api/Wallet/GetVendorPullHistory`);
};

export const getUserWithdrawHistoryService = (payload?: GetUserPointWithdrawHistoryRequest) => {
	return GET(`/api/Wallet/UserPointWithdrawRequestList?UserId=${payload?.userId}&PointStatus=${payload?.PointStatus}`,);
};

export const getVendorWithdrawHistoryService = (payload?: GetVendorPointWithdrawHistoryRequest) => {
	return GET(`/api/Wallet/VendorPointWithdrawRequestList?UserId=${payload?.userId}&PointStatus=${payload?.pointStatus}`,);
};

export const getUserPointService = (id: any) => {
	return GET(`/api/Wallet/getUserPointById?userPointId=${id}`,);
};

export const updateUserPointStatusService = (data: UpdateUserPointRequest) => {
	return PUT(`/api/Wallet/updateUserPointStatus`, data);
};

export const updateWalletRechargeNagadStatusService = (data: UpdateWalletRechargeNagadRequest) => {
	return PUT(`/api/Wallet/WalletRechargeNogodAction`, data);
};

export const updateVendorPointStatusService = (data: UpdateVendorPointRequest) => {
	return PUT(`/api/Wallet/updateVendorPointStatus`, data);
};

export const updateDataofferVendorPointStatusService = (data: UpdateVendorPointRequest) => {
	return PUT(`/api/dataOffer/updateDataOfferVendorPointWithdrawStatus`, data);
};

export const getUserTotalPointsService = () => {
	return GET(`/api/Users/userTotalPointsDetails`);
};

export const getResellerTeamService = (payload?: any) => {
	if (payload?.id) {
		return GET(`/api/Users/getResellerTeam?userId=${payload?.id}&limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}`,);
	} else {
		return GET(`/api/Users/getResellerTeam?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}`);
	}
};

export const getAffiliaterTeamService = (payload?: any) => {
	return GET(`/api/Users/getAffiliaterTeam?isAffiliaterTeam=${payload.isAffiliaterTeam}&userId=${payload?.userId}&limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}`,);
};

export const getCompanyTeamService = (payload: GetCompanyTeamPayload) => {
	return GET(`/api/Users/getCompanyTeam?isResellingReferTeam=${payload.isResellingReferTeam}&IsAffiliateReferTeam=${payload.isAffiliateReferTeam}&isAffiliateProductTeam=${payload.isAffiliateProductTeam}&limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}`,);
}

export const withdrawPointRequestService = (data: WithdrawRequest) => {
	return POST(`/api/Wallet/userPointWithdrawRequest`, data);
};

export const withdrawVendorPointRequestService = (data: WithdrawRequest) => {
	return POST(`/api/Wallet/VendorPointWithdrawRequest`, data);
};


export const getVendorService = (id: any) => {
	return GET(`/api/Users/getVendorDetails?vendorId=${id}`,);
};





export const walletRecharge = (data: WalletRechargeModel) => {
	return POST(`/api/Wallet/WalletRecharge`, data);
};

export const walletRechargeNagad = (data: WalletRechargeNagadModel) => {
	return POST(`/api/Wallet/WalletRechargeNagad`, data);
};

export const getWalletRechargeHistoryService = (payload?: GetWalletRechargeHistoryRequest) => {
	return GET(`/api/Wallet/GetWalletRechargeHistory?isMyRecord=${payload?.isMyRecord}&userId=${payload?.userId}`);
};

export const giveBack = (data: GiveBackModel) => {
	return POST(`/api/Wallet/GiveBack`, data);
};


export const getGiveBackHistoryService = (payload?: GetGiveBackHistoryRequest) => {
	return GET(`/api/Wallet/GetGiveBackHistory?isMyRecord=${payload?.isMyRecord}&userId=${payload?.userId}`);
};

export const IsVendorAllAmountPaid = () => {
	return GET(`/api/Wallet/IsVendorAllAmountPaid`);
};


export const getWalletCalculationsService = () => {
	return GET(`/api/Wallet/GetWalletCalculations`);
};


export const getBloodDonorsService = (payload?: GetBloodDonorsRequest) => {
	if (payload) {
		return GET(
			`/api/Users/getBloodDonors?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}&divisionId=${payload?.divisionId}&districtId=${payload?.districtId}&areaId=${payload?.areaId}&bloodGroup=${payload?.bloodGroup}`
		);
	}
	return GET(`/api/Users/getBloodDonors`);
};


export const addCountryZilaUpazilaAdmin = (data: AddCountryZilaUpazilaAdminRequest) => {
	return POST(`/api/Users/add-country-zila-upazila-admin`, data);
};

export const createUpazilaAdminService = (data: any) => {
	return POST(`/api/Users/createUpazilaAdmin`, data);
};

export const removeUpazilaAdminService = (data: any) => {
	return POST(`/api/Users/removeUpazilaAdmin`, data);
};

export const blockUnBlockUserService = (data: any) => {
	return PUT(`/api/Users/blockUnblockUser`, data);
};





export const getDataOfferVendorPointListService = (payload?: GetVendorPointRequest) => {
	return GET(`/api/DataOffer/getDataOfferVendorPoints?searchQuery=${payload?.searchQuery}&userId=${payload?.userId}&earningStatus=${payload?.earningStatus}&earningType=${payload?.earningType}`);
};

export const getDataOfferVendorWalletService = (payload?: GetVendorWalletRequest) => {
	return GET(`/api/DataOffer/getDataOfferVendorWallet?userId=${payload?.userId}`);
};

export const getDataOfferVendorPullHistoryService = () => {
	return GET(`/api/dataOffer/getDataOfferPullHistory`);
};

export const getDataOfferVendorWithdrawHistoryService = (payload?: GetVendorPointWithdrawHistoryRequest) => {
	return GET(`/api/DataOffer/dataOfferVendorPointWithdrawRequestList?limit=${payload?.limit}&offset=${payload?.offset}&UserId=${payload?.userId}&PointStatus=${payload?.pointStatus}`,);
};

export const withdrawDataOfferVendorPointRequestService = (payload?: WithdrawRequest) => {
	return POST(`/api/DataOffer/dataOfferVendorPointWithdrawRequest`, payload);
};

export const dataOfferVendorPullAmountService = (payload?: UserPullAmountRequest) => {
	return POST(`/api/DataOffer/dataOfferVendorPullAmount`, payload);
};

export const getDataOfferOrderListService = (payload?: DataOfferOrderListRequest) => {
	return GET(`/api/DataOffer/getDataOfferOrders?userId=${payload?.userId}&dataOrderStatus=${payload?.dataOrderStatus}&limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}`);
};

export const changeDataOfferOrderStatusService = (payload?: ChangeDataOfferStatusRequest) => {
	return PUT(`/api/DataOffer/changeDataOfferOrderStatus`, payload);
};

export const createDataOfferOrderService = (payload?: CreateDataOfferOrderRequest) => {
	return POST(`/api/DataOffer/createDataOfferOrder`, payload);
};


export const signUpService = (user: SignUpModel) => {
	return POST("/api/Users/signup", user);
};

export const vendorSignUpService = (user: VendorSignUpModel) => {
	return POST("/api/Users/vendorSignUp", user);
};

export const signInService = (data: SignInModel) => {
	return POST("/api/Users/signin-token", data);
};


export const emailOrPhoneSendConfirmationCodeService = (emailOrPhone: any) => {
	return POST("/api/Users/verification/send-confirmation-code", { emailOrPhone: emailOrPhone });
};

export const phoneSendConfirmationCodeService = (phone: any) => {
	return POST("/api/Users/verification/send-phone-confirmation-code", { phone: phone });
};

export const emailOrPhoneVerifyConfirmationCodeService = (data: VerifyVerificationCodeModel) => {
	return POST("/api/Users/verification/verify-confirmation-code", data);
};

export const emailOrPhoneSendVerificationCodeService = (emailOrPhone: any) => {
	return POST("/api/Users/verification/send-user-verification-code", { emailOrPhone: emailOrPhone });
};

export const emailOrPhoneVerifyVerificationCodeService = (data: VerifyVerificationCodeModel) => {
	return POST("/api/Users/verification/verify-user-verification-code", data);
};



export const emailOrPhoneSendResetPassCodeService = (emailOrPhone: string) => {
	return POST("/api/Users/verification/send-reset-password-code", { emailOrPhone: emailOrPhone });
};

export const verifyResetPassCodeService = (data: EmailOrPhoneResetModel) => {
	return POST("/api/Users/verification/verify-reset-password-code", data);
};

export const resetPassCodeService = (data: EmailOrPhoneTokenResetModel) => {
	return POST("/api/Users/verification/reset-password", data);
};

export const addUpdateEmailOrPhone = (data: AddUpdateEmailPhoneModel) => {
	return PUT("/api/Users/updateUserEmailOrPhone", data);
};


export const createDataOfferVendor = (user: SignUpModel) => {
	return POST("/api/Users/create-data-offer-vendor", user);
};


export const updateUserAddressService = (user: UpdateUserAddressRequest) => {
	return POST("/api/Users/updateAddress", user);
};
