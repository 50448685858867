import { RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { IoMdClose } from "react-icons/io";
import { BechteChaiCategoryFilterGetSubCategories } from "./BechteChaiCategoryFilterGetSubCategories";
import { BechteChaiCategoryFilterGetMainCategories } from "./BechteChaiCategoryFilterGetMainCategories";

export const BechteChaiCategoryFilter = () => {
  const dispatch = useDispatch();

  const explore = useSelector((state: RootState) => {
    return state.exploreResponse.explore;
  });

  const closeExplore = () => {
    dispatch({ type: "CLOSE-EXPLORE" });
  };

  return (
    <div className={explore ? "drawerOpen" : "drawerHidden"}>
      {/* explore section main div  */}
      <div className="drawerContainer md:w-1/4 w-4/5 h-screen">
        {/* explore header start here  */}
        <div className="pt-6 pr-6 text-bechteChai flex justify-end">
          <IoMdClose onClick={closeExplore} className="cursor-pointer  2xl:text-3xl text-2xl font-semibold" />
        </div>
        {/* explore component body start here  */}
        <div className="pt-2 pb-6 h-[94dvh] overflow-y-auto">
          <BechteChaiCategoryFilterGetMainCategories />
          <BechteChaiCategoryFilterGetSubCategories />
          {/* <ExploreLevelThree/> */}
        </div>
      </div>
    </div>
  );
};
