import { reactLocalStorage } from "reactjs-localstorage";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { SignalRService } from "../../../signalR/SignalRServices";
import { RootState } from "../../../store";
import { resetNotificationCountService } from "../../../httpService/notification/NotificationServices";
import { Link } from "react-router-dom";
import { AppDefaultProfilePicture } from "../../common/AppDefaultProfilePicture";
import { getUserProfileAction } from "../../../store/actions/auth/user/userActions";
import { IoMdNotificationsOutline } from "react-icons/io";
import { getNotificationCountAction } from "../../../store/actions/notification/notificationActions";
import { isAdmin, isBazarRider, isDataOfferVendor, isVendor } from "../../../common/common";

const signalRService = new SignalRService();

export const AdminNavbar = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  var token = reactLocalStorage.getObject("SignIn");

  const handleLogOut = () => {
    reactLocalStorage.remove("SignIn");
    reactLocalStorage.remove("RoleName");
    reactLocalStorage.remove("UserId");
    navigate(`/`);
  }

  const handleNotificationReceived = (notification: any) => {
    dispatch(getNotificationCountAction());
  }

  useEffect(() => {
    signalRService.startConnection();
    signalRService.notificationListener(handleNotificationReceived);
  }, []);

  const notificationCount = useSelector((state: RootState) => {
    return state.getNotificationCountResponse.data;
  });

  useEffect(() => {
    dispatch(getNotificationCountAction());
  }, [dispatch]);

  const [isShowNotification, setIsShowNotification] = useState(false);
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 30);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  const handleNotificationPopUp = () => {
    setIsShowNotification(!isShowNotification);
    resetNotificationCountService()
      .then(() => {
        dispatch(getNotificationCountAction());
      }).catch(() => {
      })
  };
  const resetNotification = () => {
    resetNotificationCountService()
      .then(() => {
        dispatch(getNotificationCountAction());
      }).catch(() => {
      })
  };

  const userProfile = useSelector((state: RootState) => {
    return state.getUserProfileResponse.data;
  });

  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);

  return (
    <div className={`${sticky ? "stickyNav" : ""}`}>
      <div className="border-b lg:border-b-2 border-grey">

        <div className="container mx-auto flex justify-between items-center py-1.5 px-3 lg:px-0">
          {/* logo section here  */}
          <div className="flex items-center gap-x-0.5 lg:gap-x-2 pl-7 lg:pl-0 cursor-pointer">
            <img className="h-6 w-7 lg:h-10 lg:w-14  2xl:h-11 2xl:w-16 object-fill" src="/assets/hb_logo.png" alt="These is logo" />
            <h2 className="text-sm md:text-lg lg:text-xl 2xl:text-2xl select-none">
              {
                isAdmin(reactLocalStorage.get("RoleName")) &&
                <Link to={'admin/dashboard'}>
                  <span className="hidden lg:block">হ্যালোবাজার_এডমিন</span>
                  <span className="block lg:hidden">এডমিন</span>
                </Link>
              }
              {
                isVendor(reactLocalStorage.get("RoleName")) &&
                <Link to={'admin/vendor-dashboard'}>
                  <span className="hidden lg:block">হ্যালোবাজার_ভেন্ডর</span>
                  <span className="block lg:hidden">ভেন্ডর</span>
                </Link>
              }
              {
                isDataOfferVendor(reactLocalStorage.get("RoleName")) &&
                <Link to={'admin/data-offer-vendor/wallet'}>
                  <span className="hidden lg:block">হ্যালোবাজার_ডাটা অফার</span>
                  <span className="block lg:hidden">ডাটা অফার</span>
                </Link>
              }
              {
                isBazarRider(reactLocalStorage.get("RoleName")) &&
                <Link to={'admin/super-shop/rider/dashboard'}>
                  <span className="hidden lg:block">হ্যালোবাজার_রাইডার - ({userProfile?.bazarName})</span>
                  <span className="block lg:hidden">রাইডার - ({userProfile?.bazarName})</span>
                </Link>
              }
            </h2>
          </div>

          {/* these is logout section  */}
          <div className="flex items-center 2xl:gap-x-10 gap-x-3 md:gap-x-6 lg:gap-x-8">
            {/* <div>
              <Link to="/admin/dashboard" className="myBtn px-1 lg:px-4 text-xs lg:text-sm 2xl:text-base rounded lg:rounded hover:bg-emerald-600">Administrator</Link>
            </div> */}
            {(JSON.stringify(token) !== JSON.stringify({})) && (
              <Link to="/notifications" >
                <div onClick={resetNotification} className="relative shadow-sm 2xl:p-2 md:p-1.5 p-1 rounded cursor-pointer">
                  {/* {nofificationCount > 0 ?
                    (
                      <img className="2xl:w-7 2xl:h-8 lg:h-6 lg:w-5 h-5 w-4 cursor-pointer object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Group_16_j5zvbe.png" alt="notification button" />
                    ) : (
                      <img className="2xl:w-7 2xl:h-8 lg:h-6 lg:w-5 h-5 w-4 cursor-pointer object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Vector_5_ok0pw0.png" alt="These is notification icon" />
                    )} */}
                  <IoMdNotificationsOutline className="text-secondary cursor-pointer 2xl:text-4xl md:text-3xl text-xl font-bold" />
                  <div className={`${notificationCount > 0 ? 'notification' : ''}`}>{notificationCount > 0 ? notificationCount : ''}</div>

                  {/* {isShowNotification && (
                    <div className="absolute top-10 2xl:top-12 w-80 2xl:w-96 -right-40 myBorder px-4 bg-white z-40">
                      <Notification />
                    </div>
                  )} */}
                </div>
              </Link>
            )}

            <div className="flex items-center gap-x-2 md:gap-x-4">
              <Link to="/admin/profile">
                <AppDefaultProfilePicture className="rounded-xl cursor-pointer 2xl:w-[50px] 2xl:h-[50px] lg:w-10 lg:h-10 w-8 h-8" name={userProfile?.firstName} profilePicture={userProfile?.photoPath} />
              </Link>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};
