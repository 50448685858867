import { AlertColor } from "@mui/material";
import { useState, useEffect } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import {
  disableUserProfileService,
  updateUserProfileService,
} from "../../../../httpService/user/userServices";
import { RootState } from "../../../../store";
import { areaListByDistrictId } from "../../../../store/actions/auth/address/areaActions";
import { divisionList } from "../../../../store/actions/auth/address/divisionActions";
import { getUserProfileAction } from "../../../../store/actions/auth/user/userActions";
import { AppDefaultProfilePicture } from "../../../common/AppDefaultProfilePicture";
import { AppFileInput } from "../../../common/AppFileInput";
import { AppInput } from "../../../common/AppInput";
import { AppRadioButton } from "../../../common/AppRadioButton";
import { AppSelect } from "../../../common/AppSelect";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { districtListByDivisionId as districtListByDivisionId } from "../../../../store/actions/auth/address/districtActions";
import { AppButton } from "../../../common/AppButton";
import {
  DisableUserProfileRequest,
  UpdateUserProfileRequest,
} from "../../../../models/auth/user/UserModels";
import { AppModal } from "../../../common/AppModal";
import { IdNameResponseModel } from "../../../../models/common/IdNameModel";
import { isBazarRider, isCountryAdmin, isUpazilaAdmin, isZilaAdmin } from "../../../../common/common";

const areaTypes = [
  { id: 1, name: "Upazila" },
  { id: 2, name: "CityCorporation" },
];

const Gender = [
  { id: 0, name: "Male" },
  { id: 1, name: "Female" },
  { id: 2, name: "Unspecified" },
];

export default function AdminGetAdminProfile() {
  const ip = reactLocalStorage.get("ip") as string;
  const currentUserId = reactLocalStorage.get("UserId") as string;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

  const divisions = useSelector((state: RootState) => {
    return state.divisionListResponse.data;
  });
  var divisionIdNameModels: IdNameResponseModel[] = [];

  divisions?.map((x: any) => {
    var division = new IdNameResponseModel();
    division.id = x.id;
    division.name = x.name;
    divisionIdNameModels.push(division);
  });

  const districts = useSelector((state: RootState) => {
    return state.districtListResponse.data;
  });
  var districtsIdNameModels: IdNameResponseModel[] = [];

  districts?.map((x: any) => {
    var district = new IdNameResponseModel();
    district.id = x.id;
    district.name = x.name;
    districtsIdNameModels.push(district);
  });

  const areass = useSelector((state: RootState) => {
    return state.areaListResponse.data;
  });
  var areaIdNameModels: IdNameResponseModel[] = [];

  areass?.map((x: any) => {
    var area = new IdNameResponseModel();
    area.id = x.id;
    area.name = x.name;
    areaIdNameModels.push(area);
  });

  const userProfile = useSelector((state: RootState) => {
    return state.getUserProfileResponse.data;
  });

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [photoPath, setPhotoPath] = useState("");
  const [profilePictureId, setProfilePictureId] = useState("");
  const [gender, setGender] = useState(0);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [postCode, setPostCode] = useState("");
  const [divisionId, setDivisionId] = useState(0);
  const [districtId, setDistrictId] = useState(0);
  const [areaId, setAreaId] = useState(0);

  const [nIDNumber, setNIDNumber] = useState("");
  const [bkashNumber, setBkashNumber] = useState("");
  const [nagadNumber, setNagadNumber] = useState("");
  const [rocketNumber, setRocketNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");

  const [faceBookLink, setFaceBookLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [whatsAppNumber, setWhatsAppNumber] = useState("");
  const [twitterLink, setTwitterLink] = useState("");

  useEffect(() => {
    if (userProfile) {
      setFirstName(userProfile?.firstName);
      setLastName(userProfile?.lastName);
      setEmail(userProfile?.email);
      setPhoneNumber(userProfile?.phoneNumber);
      setProfilePictureId(userProfile?.profilePictureId);
      setGender(userProfile?.gender);
      setAddress(userProfile?.userAddress);
      setPhotoPath(userProfile?.photoPath);
      setPostCode(userProfile?.postCode);
      setDivisionId(
        userProfile?.divisionId == null ? 0 : userProfile?.divisionId
      );
      setDistrictId(
        userProfile?.districtId == null ? 0 : userProfile?.districtId
      );
      setAreaId(userProfile?.areaId == null ? 0 : userProfile?.areaId);

      setNIDNumber(userProfile?.nidNumber);
      setBkashNumber(userProfile?.bkashNumber);
      setNagadNumber(userProfile?.nagadNumber);
      setRocketNumber(userProfile?.rocketNumber);
      setBankName(userProfile?.bankName);
      setBankAccountNumber(userProfile?.bankAccountNumber);

      setFaceBookLink(userProfile?.faceBookLink);
      setInstagramLink(userProfile?.instagramLink);
      setWhatsAppNumber(userProfile?.whatsAppNumber);
      setTwitterLink(userProfile?.twitterLink);
    }
  }, [userProfile]);

  const [firstNameError, setFirstNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [divisionError, setDivisionError] = useState("");
  const [districtError, setDistrictError] = useState("");
  const [areaError, setAreaError] = useState("");

  const [bkashNoError, setbkashNoError] = useState("");
  const [nagadNoError, setNagadNoError] = useState("");
  const [rocketError, setRocketNOError] = useState("");

  useEffect(() => {
    dispatch(divisionList());
  }, [dispatch]);

  useEffect(() => {
    if (divisionId !== 0 && divisionId != null) {
      dispatch(districtListByDivisionId(divisionId));
    }
  }, [divisionId, dispatch]);

  useEffect(() => {
    if (districtId !== 0 && districtId != null) {
      dispatch(areaListByDistrictId(districtId));
    }
  }, [districtId, dispatch]);

  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);

  const handleFirstName = (value: string) => {
    setFirstNameError("");
    setFirstName(value);
  };

  const handleLastName = (value: string) => {
    setLastName(value);
  };

  const handlePhoneNumber = (value: string) => {
    setPhoneNumberError("");
    setPhoneNumber(value);
  };

  const handleEmail = (value: string) => {
    setEmailError("");
    setEmail(value);
  };

  const handleDivision = (id: any) => {
    setDivisionError("");
    setDivisionId(id);
    setDistrictId(0);
    setAreaId(0);
  };

  const handleDistrict = (id: any) => {
    setDistrictError("");
    setDistrictId(id);
    setAreaId(0);
  };

  const handleArea = (id: any) => {
    setAreaError("");
    setAreaId(id);
  };

  const handleAddress = (value: string) => {
    setAddressError("");
    setAddress(value);
  };

  const handlePostCode = (value: string) => {
    setPostCode(value);
  };

  const handleBakashNumber = (value: string) => {
    setBkashNumber(value);
    setbkashNoError("");
  };

  const handleNgadNumber = (value: string) => {
    setNagadNumber(value);
    setNagadNoError("");
  };

  const handleRocketnumber = (value: string) => {
    setRocketNumber(value);
    setRocketNOError("");
  };

  const handleBankName = (value: string) => {
    setBankName(value);
  };

  const handleBankAccountNumber = (value: string) => {
    setBankAccountNumber(value);
  };

  const handleFacebookLink = (value: string) => {
    setFaceBookLink(value);
  };

  const handleInstagramLink = (value: string) => {
    setInstagramLink(value);
  };

  const handleTwitterLink = (value: string) => {
    setTwitterLink(value);
  };

  const handleWhatsAppNumber = (value: string) => {
    setWhatsAppNumber(value);
  };

  const navigateToChangePassword = () => {
    navigate(`/changePassword`);
  };

  const navigateToChangePhone = () => {
    navigate(`/updateEmailOrPhone?phone=${phoneNumber}`);
  };

  const navigateToChangeEmail = () => {
    navigate(`/updateEmailOrPhone?email=${email}`);
  };

  function isValidPhoneNumber(phoneNumber: any) {
    // Define a regular expression to match against the phone number format
    const phoneRegex = /^01[0-9]{9}$/;
    // Test the phone number against the regex pattern and return the result
    return phoneRegex.test(phoneNumber);
  }

  function isValidEmail(email: any) {
    // regular expression to validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // test the email against the regex pattern
    return emailRegex.test(email);
  }

  const [isSaving, setIsSaving] = useState(false);

  const handleSaveProfile = () => {
    let canExecute = true;

    if (!firstName) {
      setFirstNameError("First Name Is Required");
      canExecute = false;
    }
    if (bkashNumber && !isValidPhoneNumber(bkashNumber)) {
      setbkashNoError("Bkash Number is Invalid");
      canExecute = false;
    }
    if (nagadNumber && !isValidPhoneNumber(nagadNumber)) {
      setNagadNoError("Nagad Number is Invalid");
      canExecute = false;
    }
    if (rocketNumber && !isValidPhoneNumber(rocketNumber)) {
      setRocketNOError("Rocket Number is Invalid");
      canExecute = false;
    }

    if (canExecute) {
      const model = new UpdateUserProfileRequest();
      model.firstName = firstName;
      model.lastName = lastName;
      model.email = email;
      model.gender = gender;
      model.profilePictureId = profilePictureId;
      model.phoneNumber = phoneNumber;
      model.userAddress = address;
      model.postCode = postCode;
      model.areaId = areaId;

      model.nIDNumber = nIDNumber;
      model.bkashNumber = bkashNumber;
      model.nagadNumber = nagadNumber;
      model.rocketNumber = rocketNumber;
      model.bankName = bankName;
      model.bankAccountNumber = bankAccountNumber;

      model.faceBookLink = faceBookLink;
      model.twitterLink = twitterLink;
      model.instagramLink = instagramLink;
      model.whatsAppNumber = whatsAppNumber;

      setIsSaving(true);

      updateUserProfileService(model)
        .then(() => {
          setIsSaving(false);
          dispatch(getUserProfileAction());
          setIsShowSnackBar(true);
          setSnackBarMessage("Successfully Updated Profile");
          setSnackBarMessageType("success");
          setTimeout(() => {
            setIsShowSnackBar(false);
          }, 3000);
        })
        .catch(() => {
          setIsSaving(false);
          setIsShowSnackBar(true);
          setSnackBarMessage("Something went wrong please try again");
          setSnackBarMessageType("error");
          setTimeout(() => {
            setIsShowSnackBar(false);
          }, 3000);
        });
    }
  };

  const handleProfilePictureChange = (id: any, path: any) => {
    setPhotoPath(path);
    setProfilePictureId(id);
  };

  const handleSelectGender = (item: any) => {
    setGender(item.id);
  };

  const [isCopied, setIsCopied] = useState(false);

  function copyToClipboard() {
    const url = window.location.origin;
    const refUrl = `${url}?resellRef=${currentUserId}`;
    navigator.clipboard.writeText(refUrl);
    setIsCopied(true);
    setIsShowSnackBar(true);
    setSnackBarMessage("Successfully copied your profile link.");
    setSnackBarMessageType("success");
    setTimeout(() => {
      setIsShowSnackBar(false);
    }, 3000);
  }

  const [isDisableModalOpen, setIsDisableModalOpen] = useState(false);

  const openDisableModal = () => {
    setIsDisableModalOpen(true);
  };

  const closeDisableModal = () => {
    setIsDisableModalOpen(false);
  };

  const handleDisableProfile = () => {
    var model = new DisableUserProfileRequest();
    model.userId = 0;
    disableUserProfileService(model)
      .then(() => {
        dispatch(getUserProfileAction());
        setIsShowSnackBar(true);
        setSnackBarMessage("Successfully Disabled Profile");
        setSnackBarMessageType("success");
        setTimeout(() => {
          setIsShowSnackBar(false);
        }, 3000);
        closeDisableModal();
      })
      .catch(() => { });
  };

  return (
    <>
      {isShowSnackBar && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      {isDisableModalOpen && (
        <AppModal>
          <div className="flex max-w-md flex-col gap-4 p-6 text-center md:text-2xl">
            {userProfile.isDisable
              ? " Are you sure you want to Open your products?"
              : " Are you sure you want to Hide your products?"}
            <div className="flex items-center justify-center gap-4">
              <AppButton text="Yes" onClick={handleDisableProfile} />
              <AppButton text="No" onClick={closeDisableModal} />
            </div>
          </div>
        </AppModal>
      )}
      <div className="container mx-auto px-2 pb-2 md:px-20 md:pb-12 md:pt-8">
        {!isBazarRider(reactLocalStorage.get("RoleName")) && (
          <Link to="/home">
            <h3 className="flex items-center gap-x-1 text-lg font-semibold text-primary ">
              <BiArrowBack /> Back to home
            </h3>{" "}
          </Link>
        )}
        {/* basic information div starts here  */}
        <div className="px-0 py-4 md:px-8 md:py-8">
          <h2 className="text-lg font-semibold uppercase md:text-xl">
            Basic information
          </h2>
          {/* basic information profile part start here  */}
          <div className="flex flex-col justify-between gap-y-4 pb-6 pt-4 md:items-center lg:flex-row">
            {/* basic info name and image section here  */}
            <div className="flex items-center gap-x-4 md:gap-x-8">
              {/* basic info image section here  */}
              <div className="relative rounded-full bg-white">
                <AppDefaultProfilePicture
                  className="profile-image-header"
                  name={userProfile?.firstName}
                  profilePicture={photoPath}
                />
                <div className="absolute -right-[5%] top-[65%] cursor-pointer">
                  <AppFileInput onSave={handleProfilePictureChange} />
                </div>
              </div>
              {/* basic info header details here  */}
              <div className="space-y-2">
                <h4 className="text-lg font-medium text-textBlack md:text-xl">
                  {userProfile?.firstName} {userProfile?.lastName}{" "}
                </h4>
              </div>
            </div>

            {/* basic info link and button section here  */}
            {!isBazarRider(reactLocalStorage.get("RoleName")) && (
              <div className="grid grid-cols-2 gap-2 2xl:gap-4">
                <div
                  className="outlineBtn text-sm 2xl:text-lg"
                  onClick={navigateToChangePhone}
                >
                  <span>Add or update Phone</span>
                </div>
                <div
                  className="outlineBtn text-sm 2xl:text-lg"
                  onClick={navigateToChangeEmail}
                >
                  <span>Add or update Email</span>
                </div>
                {!userProfile.isDisable && (
                  <div
                    className="myBtn border-red bg-red text-sm 2xl:text-lg"
                    onClick={openDisableModal}
                  >
                    <span> Disable???</span>
                  </div>
                )}
                {userProfile.isDisable && (
                  <div
                    className="myBtn bg-green border-green text-sm 2xl:text-lg"
                    onClick={openDisableModal}
                  >
                    <span>Open???</span>
                  </div>
                )}
                <div
                  className="outlineBtn text-sm 2xl:text-lg"
                  onClick={navigateToChangePassword}
                >
                  <span>Change Password</span>
                </div>
              </div>
            )}

          </div>

          {/* basic information info and address part start here  */}
          <div className="grid grid-cols-1 gap-x-8 gap-y-4 lg:grid-cols-2">
            {/* basic information name and email part start here  */}
            <div className="myBorder flex flex-col space-y-2 p-4 shadow md:p-8">
              <div className="flex w-full flex-col items-center md:flex-row md:gap-x-4">
                <AppInput
                  className="myInput w-full"
                  type="text"
                  label="First name"
                  placeholder="Enter your first name"
                  value={firstName}
                  onChange={handleFirstName}
                  errorMessage={firstNameError}
                />
                <AppInput
                  className="myInput w-full"
                  label="Last name"
                  type="text"
                  placeholder="Enter your last name"
                  value={lastName}
                  onChange={handleLastName}
                />
              </div>
              <AppInput
                className="myInput"
                label="Email"
                type="text"
                isDisabled={true}
                placeholder="Email address"
                value={email}
                onChange={handleEmail}
                errorMessage={emailError}
              />
              <AppInput
                className="myInput"
                label="Phone"
                type="text"
                isDisabled={true}
                placeholder=""
                value={phoneNumber}
                onChange={handlePhoneNumber}
                errorMessage={phoneNumberError}
              />
              {/* <AppInput
                className="myInput"
                label="NID"
                type="text"
                isDisabled={false}
                placeholder="170000000000000"
                // value={}
                onChange={handlePhoneNumber}
                errorMessage={phoneNumberError}
              /> */}
              {/* <AppInput
                className="myInput"
                label="Date"
                type="date"
                isDisabled={false}
                placeholder=""
                value={DatePicker}
                onChange={ }
                errorMessage={ }
              /> */}
              <AppRadioButton
                items={Gender}
                title="Select Gender"
                defaultValue={gender}
                onChange={handleSelectGender}
              />
            </div>

            {/* basic information address and division start here  */}
            <div className="myBorder flex flex-col space-y-2 p-4 shadow md:p-8">
              <AppInput
                className="myInput"
                label="Address"
                type="text"
                placeholder="Enter your address"
                value={address}
                onChange={handleAddress}
              />

              <div>
                <label className="text-base md:text-lg 2xl:text-xl" placeholder="Dhaka">
                  Division*
                </label>
                <AppSelect
                  defaultValue="Select Division"
                  data={divisionIdNameModels}
                  value={divisionId}
                  onChange={handleDivision}
                  errorMessage={divisionError}
                  disabled={
                    isCountryAdmin(reactLocalStorage.get("RoleName")) ||
                      isZilaAdmin(reactLocalStorage.get("RoleName")) ||
                      isUpazilaAdmin(reactLocalStorage.get("RoleName")) ||
                      isBazarRider(reactLocalStorage.get("RoleName"))
                      ? true
                      : false
                  }
                />
              </div>

              {divisionId != 0 && (
                <div>
                  <label
                    className="text-base md:text-lg 2xl:text-xl"
                    placeholder="Dhaka"
                  >
                    District*
                  </label>
                  <AppSelect
                    defaultValue="Select District"
                    data={districtsIdNameModels}
                    value={districtId}
                    onChange={handleDistrict}
                    errorMessage={districtError}
                    disabled={
                      isCountryAdmin(reactLocalStorage.get("RoleName")) ||
                        isZilaAdmin(reactLocalStorage.get("RoleName")) ||
                        isUpazilaAdmin(reactLocalStorage.get("RoleName")) ||
                        isBazarRider(reactLocalStorage.get("RoleName"))
                        ? true
                        : false
                    }
                  />
                </div>
              )}

              {divisionId != 0 && districtId != 0 && (
                <div>
                  <label
                    className="text-base md:text-lg 2xl:text-xl"
                    placeholder="Dhaka"
                  >
                    Area
                  </label>
                  <AppSelect
                    defaultValue="Select Area"
                    data={areaIdNameModels}
                    value={areaId}
                    onChange={handleArea}
                    errorMessage={areaError}
                    disabled={
                      isCountryAdmin(reactLocalStorage.get("RoleName")) ||
                        isZilaAdmin(reactLocalStorage.get("RoleName")) ||
                        isUpazilaAdmin(reactLocalStorage.get("RoleName")) ||
                        isBazarRider(reactLocalStorage.get("RoleName"))
                        ? true
                        : false
                    }
                  />
                </div>
              )}

              <div>
                <AppInput
                  className="myInput"
                  label="Post code"
                  type="text"
                  placeholder="Enter your post code"
                  value={postCode}
                  onChange={handlePostCode}
                />
              </div>
            </div>
          </div>
        </div>

        {/* payment method div starts here  */}
        <div className="px-0 md:px-8">
          <h2 className="text-lg font-semibold uppercase md:text-xl">
            Payment Method
          </h2>
          <p className="text-sm">Add Your Payment Method</p>
          {/* payment div section here  */}
          <div className="myBorder mt-4 grid grid-cols-1 items-center gap-x-8 gap-y-4 p-4 shadow md:grid-cols-2 md:p-8">
            <AppInput
              className="myInput"
              label="Bkash Number"
              type="text"
              placeholder="Enter your bkash Number"
              value={bkashNumber}
              onChange={handleBakashNumber}
              errorMessage={bkashNoError}
            />
            <AppInput
              className="myInput"
              label="Nagad Number"
              type="text"
              placeholder="Enter your Nagad Number"
              value={nagadNumber}
              onChange={handleNgadNumber}
              errorMessage={nagadNoError}
            />
            <AppInput
              className="myInput"
              label="Rocket Number"
              type="text"
              placeholder="Enter your Nagad Number"
              value={rocketNumber}
              onChange={handleRocketnumber}
              errorMessage={rocketError}
            />
            <div className="flex w-full flex-col items-center gap-x-4 lg:flex-row">
              <AppInput
                className="myInput"
                label="Bank Account Name"
                type="text"
                placeholder="Bank Account Name"
                value={bankName}
                onChange={handleBankName}
              />
              <AppInput
                className="myInput"
                label="Bank Account Number"
                type="text"
                placeholder="Bank Account Number"
                value={bankAccountNumber}
                onChange={handleBankAccountNumber}
              />
            </div>
          </div>
        </div>
        {/* Social Profiles div starts here  */}
        <div className="flex flex-col px-0 py-4 md:px-8 md:py-8">
          <h2 className="text-lg font-semibold uppercase md:text-xl">
            Social Profiles
          </h2>
          <p className="text-sm">Add elsewhere links to your profile</p>
          {/* social profile div body starts here  */}
          <div className="myBorder mt-4 grid grid-cols-1 items-center gap-x-8 gap-y-4 p-4 shadow md:grid-cols-2 md:p-8">
            <AppInput
              className="myInput"
              label="Facebook Link"
              type="text"
              placeholder="Enter your Facebook Link"
              value={faceBookLink}
              onChange={handleFacebookLink}
            />
            <AppInput
              className="myInput"
              label="Twitter Link"
              type="text"
              placeholder="Enter your Twitter Link"
              value={twitterLink}
              onChange={handleTwitterLink}
            />
            <AppInput
              className="myInput"
              label="Instagram Link"
              type="text"
              placeholder="Enter your Instagram Link"
              value={instagramLink}
              onChange={handleInstagramLink}
            />
            <AppInput
              className="myInput"
              label="WhatsApp Number"
              type="text"
              placeholder="Enter your WhatsApp Number"
              value={whatsAppNumber}
              onChange={handleWhatsAppNumber}
            />
          </div>
        </div>
        {/* these is save button section  */}
        <div className="flex justify-end px-8">
          <AppButton
            text="Save"
            isSubmitting={isSaving}
            className="myBtn px-4 md:px-8"
            onClick={handleSaveProfile}
          />
        </div>
      </div>
    </>
  );
}
