import { getMainCategoryListService, getSubCategoryListService } from "../../../httpService/catalog/categoryServices";
import { PaginationPayloadModel } from "../../../models/common/paginationModels";
import { GET_MAIN_CATEGORY_LIST, GET_SUB_CATEGORY__ONE_LIST, GET_SUB_CATEGORY__TWO_LIST } from "../../constants/catalog/mainCategory";

export const getMainCategoryListAction = (payload?: PaginationPayloadModel, isAll?: boolean, forPublic?: boolean) => async (dispatch: any) => {
    var data = getMainCategoryListService(payload, isAll, forPublic);
    const pload = (await data).data;
    dispatch({ type: GET_MAIN_CATEGORY_LIST, payload: pload });
};

export const getSubCategoryListAction = (payload?: PaginationPayloadModel, mainCategoryId?: number, isAll?: any) => async (dispatch: any) => {
    var data = getSubCategoryListService(mainCategoryId, payload, isAll)
    const pload = (await data).data;
    dispatch({ type: GET_SUB_CATEGORY__ONE_LIST, payload: pload });
}