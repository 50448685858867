import { useEffect, useState } from 'react'
import { createMagazinePostService, deleteMagazinePostService, getMagazinePostListService, submitRemoveLikeService } from '../../../httpService/magazine/magazinePostServices'
import { PaginationPayloadModel } from '../../../models/common/paginationModels';
import { AppDescription } from '../../common/AppDescription';
import { AppInput } from '../../common/AppInput';
import { getMagazineCategoryListService } from '../../../httpService/magazine/magazineCategoryServices';
import { AppSelect } from '../../common/AppSelect';
import { MagazineCreateMagazineModel } from '../../../models/magazine/magazinePostModels';
import { AppButton } from '../../common/AppButton';
import { AppFileInput } from '../../common/AppFileInput';
import { PhotoModel } from '../../../models/bechteChai/product/bechteChaiProductModels';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { magazineMyPostList, magazinePostList } from '../../../store/actions/magazine/magazinePostActions';
import { magazineCategoryList } from '../../../store/actions/magazine/magazineCategoryActions';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';

export const MagazinePostList = () => {

  var pModel = new PaginationPayloadModel();

  pModel.limit = 1000;
  pModel.offset = 0;

  var postList = useSelector((state: RootState) => {
    return state.magazineGetPostListResponse.data;
  });

  console.log('post list', postList)

  var myPostList = useSelector((state: RootState) => {
    return state.magazineGetMyPostListReducer.data;
  });

  var categoryList = useSelector((state: RootState) => {
    return state.magazineCategoryListResponse.data;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(magazinePostList(pModel))
    dispatch(magazineMyPostList(pModel))
    dispatch(magazineCategoryList(pModel))
  }, [])

  const [isMyPostVisible, setIsMyPostVisible] = useState(true)
  const [isAllPostVisible, setIsAllPostVisible] = useState(false)
  const handleMyPostView = () => {
    setIsMyPostVisible(true);
    setIsAllPostVisible(false);
  }
  const handleAllPostView = () => {
    setIsMyPostVisible(false);
    setIsAllPostVisible(true);
  }

  const [postDescription, setPostDescription] = useState('');
  const [postDescriptionError, setPostDescriptionError] = useState("");
  console.log('postDes', postDescription)

  const [postTitle, setPostTitle] = useState('');
  const [postTitleError, setPostTitleError] = useState('');

  const [postMainCategoryId, setPostMainCategoryId] = useState('');
  const [postMainCategoryIdError, setPostMainCategoryIdError] = useState('');

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [bannerId, setBannerId] = useState(0);
  const [bannerError, setBannerError] = useState("");

  const [productMedias, setProductMedias] = useState<PhotoModel[]>([]);
  const [photoIds, setPhotoIds] = useState<any[]>([]);

  const handlePostTitle = (value: any) => {
    setPostTitle(value);
    setPostTitleError('');
  }

  const handlePostDescription = (value: any) => {
    setPostDescription(value);
    setPostDescriptionError("");
  };

  const handlePostMainCategoryId = (value: any) => {
    setPostMainCategoryId(value);
    setPostMainCategoryIdError('');
  }

  const handleBanner = (id: any, path: any) => {
    const photoModel = new PhotoModel();
    photoModel.id = id;
    photoModel.url = path;
    setBannerId(id);
    setBannerError("");
    setProductMedias(prevMedias => [...prevMedias, photoModel]);
    setPhotoIds(prevPhotoId => [...prevPhotoId, id]);
    console.log(id);
    console.log(path);
  };

  const handlePostDelete = (value: any) => {
    deleteMagazinePostService(value)
      .then(() => {
        dispatch(magazinePostList(pModel))
        dispatch(magazineMyPostList(pModel))
      })

  }

  const navigate = useNavigate();

  const handelNagivateToPostDetails = (id: any) => {
    navigate(`/postDetails/${id}`);
  }

  const handelNagivateToPostUpdate = (id: any) => {
    navigate(`/updatePost/${id}`);
  }

  const handleClearForm = () => {
    setPostDescription('');
    setPostMainCategoryId('');
    setBannerId(0);
    setPostTitle("");
  }

  const handleSubmitLike = (value: any) => {
    submitRemoveLikeService(value)
      .then((res: any) => {
        dispatch(magazinePostList(pModel))
        dispatch(magazineMyPostList(pModel))
      })
      .catch((err: any) => {
        console.log("Comment Error: ", err)
      })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();

    var model = new MagazineCreateMagazineModel();

    model.Title = postTitle;
    model.Description = postDescription;
    model.MagazineCategoryId = postMainCategoryId;
    model.MediaIds = [bannerId];

    createMagazinePostService(model)
      .then((res: any) => {
        handleClearForm();
        dispatch(magazinePostList(pModel))
        dispatch(magazineMyPostList(pModel))
        console.log('res', res)
      })
      .catch((err: any) => {
        console.log('err', err)
      })
  }

  return (
    <div>
      <div>
        <h2>Create Post</h2>
        <AppInput
          type="text"
          className="postTitle"
          label="Post Title"
          placeholder="Enter post title"
          onChange={handlePostTitle}
          value={postTitle}
          errorMessage={postTitleError}
        />
        <AppDescription
          value={postDescription}
          label="Enter description of your post"
          onChange={handlePostDescription}
          errorMessage={postDescriptionError}
        />
        <label>Select the category of the post:</label>
        <AppSelect
          defaultValue="Select Post Category"
          value={postMainCategoryId}
          data={categoryList}
          onChange={handlePostMainCategoryId}
          errorMessage={postMainCategoryIdError}
        />

        <AppFileInput onSave={handleBanner} errorMessage={bannerError} />

        <AppButton
          className="createMagazinePost"
          text="Save"
          variant="contained"
          isSubmitting={isButtonLoading}
          color="primary"
          onClick={(e: any) => handleSubmit(e)}
        />
      </div>
      <div>
        <Button onClick={() => handleMyPostView()}>Show My Posts</Button>
        <Button onClick={() => handleAllPostView()}>Show All Posts</Button>
      </div>
      {isMyPostVisible &&
        <div>
          <h1>My Post List</h1>
          {myPostList &&
            (
              myPostList.items?.map((post: any, index: any) =>
                <div>
                  <div className="w-2/5">
                    <img className="rounded-l" src={post.magazineMedias[0].url} alt="Post Pic" loading="lazy" />
                  </div>
                  <h2>{post.id}</h2>
                  <h2>{post.title}</h2>
                  <p><div
                    dangerouslySetInnerHTML={{
                      __html: post?.description,
                    }}
                  /></p>

                  <AppButton
                    className="createMagazinePost"
                    text="Delete"
                    variant="contained"
                    isSubmitting={isButtonLoading}
                    color="primary"
                    onClick={() => handlePostDelete(post.id)}
                  />
                  <Button onClick={() => handleSubmitLike(post.id)}>{post.totalLike} Like</Button>
                  <Button onClick={() => handelNagivateToPostDetails(post.id)}>{post.totalComment} Comment</Button>
                  <Button onClick={() => handelNagivateToPostDetails(post.id)}>Details</Button>
                  <Button onClick={() => handelNagivateToPostUpdate(post.id)}>Update</Button>
                </div>
              )
            )}
        </div>}

      {isAllPostVisible &&
        <div>
          <h1>All post list</h1>
          {postList &&
            (
              postList.items?.map((post: any, index: any) =>
                <div>
                  <div className="w-2/5">
                    <img className="rounded-l" src={post.magazineMedias[0].url} alt="Post Pic" loading="lazy" />
                  </div>
                  <h2>{post.id}</h2>
                  <h2>{post.title}</h2>
                  <p><div
                    dangerouslySetInnerHTML={{
                      __html: post?.description,
                    }}
                  /></p>
                  <Button onClick={() => handleSubmitLike(post.id)}>{post.totalLike} Like</Button>
                  <Button onClick={() => handelNagivateToPostDetails(post.id)}>{post.totalComment} Comment</Button>
                  <Button onClick={() => handelNagivateToPostDetails(post.id)}>Details</Button>
                </div>
              )
            )}
        </div>
      }

    </div>
  )
}
