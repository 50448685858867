import { SSGET_BAZAR_BY_ID, SSGET_BAZAR_LIST } from "../../constants/superShop/ssBazarConstants";

const inititalState = {
    data: [],
    dataById: []
}

export function sSGetBazarListReducer(state = inititalState, action: any) {
    switch (action.type) {
        case SSGET_BAZAR_LIST:
            return { ...state, loading: false, data: action.payload };
        case SSGET_BAZAR_BY_ID:
            return { ...state, loading: false, dataById: action.payload };
        default:
            return state;
    }
}

export function sSGetBazarByIdReducer(state = { data: {} }, action: any) {
	switch (action.type) {
		case SSGET_BAZAR_BY_ID:
			return { loading: false, data: action.payload };
		default:
			return state;
	}
}
