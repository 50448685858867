import { sSGetProductByIdService, sSGetProductListService } from "../../../httpService/superShops/ssProductServices";
import { SSGetProductsRequest } from "../../../models/superShopp/catalog/sSProduct/sSProductModel";
import { SSGET_PRODUCT_BY_ID, SSGET_PRODUCT_LIST } from "../../constants/superShop/ssProductConstants";

export const sSGetProductListAction = (payload?: SSGetProductsRequest) => async (dispatch: any) => {
    try {
        const data = await sSGetProductListService(payload);
        const pload = (await data).data;
        dispatch({ type: SSGET_PRODUCT_LIST, payload: pload });
    }
    catch (error: any) { }
}

export const sSGetProductByIdAction = (id: any) => async (dispatch: any) => {
	try {
		var data = sSGetProductByIdService(id);
		const payload = (await data).data;
		dispatch({ type: SSGET_PRODUCT_BY_ID, payload: payload });
	} catch (error: any) { }
};