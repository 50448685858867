export class submitCommentOrReplyModel {
    CommentId?: number;
    MagazinePostId?: any;
    OrderId?: number;
    CommentText?: string;
    IsParent?: boolean;
    ParentId?: number;
    MediaIds?: any;
}

export class getCommentByPostId {
    SearchQuery?:string;
    MagazinePostId?:any;
}