export class SSAddToCartRequest {
    customerIp?: any;
    cartItems?: SSCartItemsRequest[];
}

export class SSGetCartItemsRequest {
    userId?: number = 0;
    bazarId?: any = 0;
}

export class SSIncreseOrDecreseProductQuantityRequest {
    id?: any;
    isIncrese?: any;
}

export class SSRemoveItemSingleOrListItemRequest {
    ids?: any;
}

export class SSCartItemsRequest {
    productId?: any;
    productQuantity?: any;
    bannerPath?: any;
    unitPrice?: any;
    oldUnitPrice?: any;
    totalPrice?: any;
}

export class SSCartItemSelection {
    id?: number;
    productId?: number;
    isSelected?: boolean;
}

