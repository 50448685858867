import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { reactLocalStorage } from "reactjs-localstorage";
import { useNavigate } from "react-router-dom";
import { BechteChaiProductContainer } from "./BechteChaiProductContainer";
import { isAdmin, isRoleExist, isVendor } from "../../../common/common";

const BechteChaiHomeComponent = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ip = reactLocalStorage.get("ip");

  useEffect(() => {
    if (!ip) {
      axios
        .get("https://api.ipify.org?format=json")
        .then((response) => {
          reactLocalStorage.set("ip", response.data.ip)
        })
        .catch(() => {
        });
    }
  }, [dispatch, ip]);

  useEffect(() => {
    if (isAdmin(reactLocalStorage.get("RoleName")) || isVendor(reactLocalStorage.get("RoleName"))) {
      navigate(`/admin`);
    }
  }, [dispatch, navigate, isRoleExist(reactLocalStorage.get("RoleName"))]);

  const [currentFilter, setCurrentFilter] = useState(0);
  const [filterOn, setFilterOn] = useState(true);

  return (
    <div className="container mx-auto md:pt-4 md:pb-6 2xl:py -3 lg:py-2 py-2 px-2 md:px-16 lg:px-28">

      {/* these is home top section  */}
      <div className="flex gap-2 lg:gap-4 h-[16vh] lg:h-[50vh] box-border">
        <img
          src="/assets/Home/04.png"
          className="w-full rounded lg:rounded-xl object-fill"
          alt="These is home banner"
          loading="lazy"
        />
      </div>

      {filterOn &&
        <BechteChaiProductContainer type={currentFilter} />
      }
    </div>
  );
}

export default BechteChaiHomeComponent;
