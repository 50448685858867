import { AlertColor } from "@mui/material/Alert";
import { useEffect, useState } from "react";
import { GetProductListRequest, HomeProductFilterEnum } from "../../../models/catalog/product/productsModels";
import { AppSnackBar } from "../../common/AppSnackBar";
import { ResellingProductBox } from "./ResellingProductBox";
import { AppLoader } from "../../common/AppLoader";
import { AppLoadMore } from "../../common/AppLoadMore";
import { GET } from "../../../httpService/service";

interface IInputProps {
  type?: HomeProductFilterEnum
}

interface productProps {
  id?: any;
  name: string;
  shortDescription?: string;
  price?: number;
  oldPrice?: number;
  discount?: string;
  bannerPath?: any;
  isBestSeller?: boolean;
  totalSold?: number;
  totalReview?: number;
  totalView?: number;
  totalFavourite?: number;
  rating?: number;
  afterAddToCart?: any;
  isDisountProduct?: any;
  discountText?: any
}

export const ResellingProductContainer: React.FC<IInputProps> = ({
  type
}) => {

  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

  const [size, setSize] = useState(0);
  const [page, setPage] = useState(1);
  const [isDisable, setIsDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadMoreButtonLoading, setIsLoadMoreButtonLoading] = useState(false);

  const [loadedProducts, setLoadedProducts] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadMoreButtonLoading(true);
        var pModel = new GetProductListRequest();
        pModel.limit = 48;
        pModel.offset = 0;
        pModel.searchQuery = "";
        pModel.sortBy = 0;
        pModel.minimumPrice = 0;
        pModel.maximumPrice = 0;
        pModel.homeProductFilterType = type;
        const response = await GET(`/api/Product/GetProductsPublic?homeProductFilterType=${pModel.homeProductFilterType}&limit=${pModel?.limit}&offset=${pModel?.offset}&searchQuery=${pModel?.searchQuery}&sortBy=${pModel?.sortBy}&minimumPrice=${pModel?.minimumPrice}&maximumPrice=${pModel?.maximumPrice}&vendorId=${pModel?.vendorId}&mainCategoryId=${pModel?.mainCategoryId}&subCategoryLevelOneId=${pModel?.subCategoryLevelOneId}`);
        setLoadedProducts(response?.data?.items);
        setSize(response?.data?.size)
        setIsLoadMoreButtonLoading(false);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleOnRowChange = async (payload: GetProductListRequest) => {
    setPage(page + 1)
    setIsDisable(payload.offset as number >= size)
    var pModel = new GetProductListRequest();
    pModel.limit = payload.limit;
    pModel.offset = payload.offset;
    pModel.searchQuery = "";
    pModel.sortBy = 0;
    pModel.minimumPrice = 0;
    pModel.maximumPrice = 0;
    pModel.homeProductFilterType = type;
    try {
      setIsLoadMoreButtonLoading(true);
      const response = await GET(`/api/Product/GetProductsPublic?homeProductFilterType=${pModel.homeProductFilterType}&limit=${pModel?.limit}&offset=${pModel?.offset}&searchQuery=${pModel?.searchQuery}&sortBy=${pModel?.sortBy}&minimumPrice=${pModel?.minimumPrice}&maximumPrice=${pModel?.maximumPrice}&vendorId=${pModel?.vendorId}&mainCategoryId=${pModel?.mainCategoryId}&subCategoryLevelOneId=${pModel?.subCategoryLevelOneId}`);
      setLoadedProducts(prevProducts => [...prevProducts, ...response.data.items]);
      setIsLoadMoreButtonLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  const handleAfterAddToCart = () => {
    setIsShowSnackBar(true);
    setSnackBarMessage("Add to cart Successfully");
    setSnackBarMessageType("success");
    setTimeout(() => {
      setIsShowSnackBar(false);
    }, 2000);
  }


  if (isLoading) {
    return (
      <div className="container h-[50dvh] flex items-center justify-center mx-auto px-2 md:px-0 pb-12">
        <AppLoader status={true} />
      </div>
    );
  }

  return (
    <div className="container mx-auto">
      {isShowSnackBar && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      {/* popular products and see more button  */}
      <div className="flex justify-center items-center md:py-4 py-2">
        <h2 className="title text-center">Popular Products {size}</h2>
        {/* <div onClick={onFilterPage} className="outlineBtn">See more</div> */}
      </div>

      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-2 md:gap-4 lg:gap-6">
        {loadedProducts &&
          (
            loadedProducts?.map((product: productProps, index: any) =>
              <ResellingProductBox key={index}
                id={product?.id}
                name={product?.name}
                shortDescription={product?.shortDescription}
                bannerPath={product?.bannerPath}
                discount={product?.discount}
                isBestSeller={product?.isBestSeller}
                price={product?.price}
                oldPrice={product?.oldPrice}
                rating={product?.rating}
                totalSold={product?.totalSold}
                isDisountProduct={product?.isDisountProduct}
                discountText={product?.discountText}
                afterAddToCart={handleAfterAddToCart}
                totalReview={product?.totalReview}
                totalView={product?.totalView}
                totalFavourite={product?.totalFavourite}
              />
            )
          )}
      </div>

      <div className='flex items-center justify-center pt-4 lg:pt-6'>
        <AppLoadMore isSubmitting={isLoadMoreButtonLoading} text="Show more Products" className="resellingOutlineBtn text-xl font-medium" isDisable={isDisable} page={page} onRowChange={handleOnRowChange} size={size} />
      </div>
    </div>
  );
}