import { Route, Routes } from "react-router";
import GetGiveBackInvoices from "../../components/admin/invoices/GetGiveBackInvoices";
import GetTrainningInvoices from "../../components/admin/invoices/GetTrainningInvoices";
import GetWalletRechargeableInvoices from "../../components/admin/invoices/GetWalletRechargeableInvoices";
import AdminGetOrderInvoice from "../../components/admin/invoices/AdminGetOrderInvoice";
import AdminGetOrderInvoices from "../../components/admin/invoices/AdminGetOrderInvoices";
import AdminGetDataOfferInvoices from "../../components/admin/invoices/AdminGetDataOfferInvoices";
import AdminGetDataOfferInvoice from "../../components/admin/invoices/AdminGetDataOfferInvoice";

export default function InvoicesRoute() {
  return (
    <>
      <Routes>
        {/* <Route index element={<ImportantNumber />} /> */}
        <Route path="/order-invoices" element={<AdminGetOrderInvoices />} />
        <Route path="/order-invoice/:id" element={<AdminGetOrderInvoice />} />
        <Route
          path="/data-offer-invoices"
          element={<AdminGetDataOfferInvoices />}
        />
        <Route
          path="/data-offer-invoice/:id"
          element={<AdminGetDataOfferInvoice />}
        />
        <Route path="/give-back-invoices" element={<GetGiveBackInvoices />} />
        <Route path="/trainning-invoices" element={<GetTrainningInvoices />} />
        <Route
          path="/wallet-rechargeable-invoices"
          element={<GetWalletRechargeableInvoices />}
        />
      </Routes>
    </>
  );
}
