import _moment from "moment";

export const isRoleExist = (roles: any) => {
    return (roles === null || roles === undefined || roles.trim() === '') ? true : false;
}

export const isCustomer = (roles: any) => {
    return /\bCUSTOMER\b/.test(roles) ? true : false;
}

export const isReseller = (roles: any) => {
    return /\bRESELLER\b/.test(roles) ? true : false;
}

export const isAffiliater = (roles: any) => {
    return /\bAFFILIATER\b/.test(roles) ? true : false;
}

export const isAdmin = (roles: any) => {
    return /\bADMIN\b/.test(roles) ? true : false;
}

export const isModerator = (roles: any) => {
    return /\bMODERATOR\b/.test(roles) ? true : false;
}

export const isVendor = (roles: any) => {
    return /\bVENDOR\b/.test(roles) ? true : false;
}

export const isDataOfferVendor = (roles: any) => {
    return /\bDATA_OFFER_VENDOR\b/.test(roles) ? true : false;
}

export const isUpazilaAdmin = (roles: any) => {
    return /\bUPAZILA_ADMIN\b/.test(roles) ? true : false;
}

export const isBazarRider = (roles: any) => {
    return /\bBAZAR_RIDER\b/.test(roles) ? true : false;
}

export const isZilaAdmin = (roles: any) => {
    return /\bZILA_ADMIN\b/.test(roles) ? true : false;
}

export const isCountryAdmin = (roles: any) => {
    return /\bCOUNTRY_ADMIN\b/.test(roles) ? true : false;
}


export const dateTimeFormatter = (createdDateTime: any) => {
    const createdTime = _moment(createdDateTime, "YYYY-MM-DD HH:mm:ss.SSSSSSS");
    return createdTime.format("MMM D, YY [at] h:mm A");
  };

export  function isValidPhoneNumber(phoneNumber: any) {
    // Define a regular expression to match against the phone number format
    const phoneRegex = /^01[0-9]{9}$/;
    // Test the phone number against the regex pattern and return the result
    return phoneRegex.test(phoneNumber);
  }