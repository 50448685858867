import { getAcademicInstituteEmployeesService, getAcademicInstitutesService, getTeachersService } from "../../../httpService/importantNumbers/academicInstitutesServices";
import { GET_ACADEMICINSTITUTES_NUMBER, GET_ACADEMIC_INSTITUTE_EMPLOYEES_NUMBER, GET_TEACHERS_ONLY } from "../../constants/importantNumbers/academicInstitutes/academicInstitutesConstant";


const getAcademicInstitutesListAction = (p: any) => async (dispatch: any) => {
	try {
		const data = getAcademicInstitutesService(p);
		const payload = (await data).data;
		dispatch({ type: GET_ACADEMICINSTITUTES_NUMBER, payload: payload });
	} catch (error: any) { }
};

const getAcademicInstituteEmployeesListAction = (p: any) => async (dispatch: any) => {
	try {
		const data = getAcademicInstituteEmployeesService(p);
		const payload = (await data).data;
		dispatch({ type: GET_ACADEMIC_INSTITUTE_EMPLOYEES_NUMBER, payload: payload });
	} catch (error: any) { }
};


const getTeachersListAction = (p: any) => async (dispatch: any) => {
	try {
		const data = getTeachersService(p);
		const payload = (await data).data;
		dispatch({ type: GET_TEACHERS_ONLY, payload: payload });
	} catch (error: any) { }
};


export { getAcademicInstitutesListAction, getAcademicInstituteEmployeesListAction, getTeachersListAction };
