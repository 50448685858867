import { useEffect, useState } from 'react'
import {
    GetGiveBackHistory,
    GetVendorPointRequest,
    GetVendorPointResponse,
    GetVendorPointWithdrawHistoryRequest,
    GetVendorPointWithdrawHistoryResponse,
    GetVendorPullHistoryResponse,
    GetVendorWalletRequest,
    GetVendorWalletResponse,
    GetWalletRechargeHistory
} from '../../../../models/auth/user/UserPointModels';
import { AppButton } from '../../../common/AppButton';
import { GetGiveBackHistoryRequest, GetWalletRechargeHistoryRequest } from '../../../../models/auth/user/UserModels';
import { getGiveBackHistoryAction, getVendorPointListAction, getVendorPullHistoryAction, getVendorWalletAction, getVendorWithdrawHistoryAction, getWalletRechargeHistoryAction } from '../../../../store/actions/auth/user/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import _moment from "moment";

interface IProps {
    id?: any;
}

export const AdminGetVendorWallet: React.FC<IProps> = ({ id }) => {

    const dispatch = useDispatch();

    const wallet = useSelector((state: RootState) => {
        return state.getVendorWalletResponse.data as GetVendorWalletResponse;
    });

    useEffect(() => {
        var wlt = new GetVendorWalletRequest();
        wlt.userId = id;
        dispatch(getVendorWalletAction(wlt));
    }, [dispatch]);


    useEffect(() => {
        var model = new GetVendorPointRequest();
        model.userId = id;
        model.searchQuery = searchQuery;
        model.earningStatus = earningStatus;
        model.earningType = earningType;
        dispatch(getVendorPointListAction(model));
    }, [dispatch]);

    const pointList = useSelector((state: RootState) => {
        return state.getVendorPointListResponse.data as GetVendorPointResponse[];
    });

    const [pointStatus, setPointStatus] = useState(0);

    const withdrawList = useSelector((state: RootState) => {
        return state.getVendorWithdrawHistoryResponse.data;
    });

    var withdrawlHistryModel = new GetVendorPointWithdrawHistoryRequest();
    withdrawlHistryModel.userId = id;
    withdrawlHistryModel.pointStatus = pointStatus;

    useEffect(() => {
        dispatch(getVendorWithdrawHistoryAction(withdrawlHistryModel));
    }, [dispatch]);

    const pullList = useSelector((state: RootState) => {
        return state.getVendorPullHistoryResponse.data as GetVendorPullHistoryResponse[];
    });

    useEffect(() => {
        dispatch(getVendorPullHistoryAction());
    }, [dispatch]);

    const [searchQuery, setSearchQuery] = useState("");

    const [earningType, setEarningType] = useState(0);
    const [earningStatus, setEarningStatus] = useState(0);

    const orderTimeFormatter = (createdDateTime: any) => {
        const createdTime = _moment(createdDateTime, "YYYY-MM-DD HH:mm:ss.SSSSSSS");
        return createdTime.format("MMM D, YY [at] h:mm A");
    };

    const [isEarningHistry, setIsEarningHistry] = useState(true);
    const [isWithdrawHistry, setIsWithdrawHistry] = useState(false);
    const [isPullHistry, setIsPullHistry] = useState(false);
    const [isRechargeHistry, setIsRechargeHistry] = useState(false);
    const [isGiveBackHistry, setGiveBackHistry] = useState(false);
    const [isSelected, setIsSelected] = useState("");

    const openEaringHistry = () => {
        var model = new GetVendorPointRequest();
        model.userId = id;
        model.searchQuery = searchQuery;
        model.earningStatus = earningStatus;
        model.earningType = earningType;
        dispatch(getVendorPointListAction(model));
        setIsSelected("EaringHistry");
        setIsEarningHistry(true);
        setIsWithdrawHistry(false);
        setIsPullHistry(false);
        setIsRechargeHistry(false);
        setGiveBackHistry(false);
    };

    const openWithdrawHistry = () => {
        dispatch(getVendorWithdrawHistoryAction(withdrawlHistryModel))
        setIsSelected("WithdrawHistry");
        setIsEarningHistry(false);
        setIsWithdrawHistry(true);
        setIsPullHistry(false);
        setIsRechargeHistry(false);
        setGiveBackHistry(false);
    };

    const openPullHistry = () => {
        dispatch(getVendorPullHistoryAction());
        setIsSelected("PullHistry");
        setIsEarningHistry(false);
        setIsWithdrawHistry(false);
        setIsPullHistry(true);
        setIsRechargeHistry(false);
        setGiveBackHistry(false);
    };

    const rechargeHistory = useSelector((state: RootState) => {
        return state.getWalletRechargeHistoryResponse.data as GetWalletRechargeHistory[];
    });


    const openRechargeHistry = () => {
        var model = new GetWalletRechargeHistoryRequest();
        model.isMyRecord = true;
        model.userId = id;
        dispatch(getWalletRechargeHistoryAction(model));
        setIsSelected("RechargeHistory");
        setIsEarningHistry(false);
        setIsWithdrawHistry(false);
        setIsPullHistry(false);
        setIsRechargeHistry(true);
        setGiveBackHistry(false);
    };


    const giveBackHistory = useSelector((state: RootState) => {
        return state.getGiveBackHistoryResponse.data as GetGiveBackHistory[];
    });


    const openGiveBackHistry = () => {
        var model = new GetGiveBackHistoryRequest();
        model.isMyRecord = true;
        model.userId = id;
        dispatch(getGiveBackHistoryAction(model));
        setIsSelected("GiveBackHistory");
        setIsEarningHistry(false);
        setIsWithdrawHistry(false);
        setIsPullHistry(false);
        setIsRechargeHistry(false);
        setGiveBackHistry(true);
    };

    return (
        <div className="min-w-[80vw] h-[80vh] overflow-y-auto pb-4 px-2 lg:px-0">
            <h2 className="title pb-1 lg:pb-4">Vendor wallet</h2>
            {/* this is first div  */}
            <div className="grid grid-cols-2 lg:flex flex-col lg:flex-row items-center gap-2 lg:gap-4 w-full">
                {/* this is Main balance of wallet  */}
                <div className="col-span-1 w-full lg:w-1/3 h-24 lg:h-36 px-2 lg:px-4 py-2 rounded lg:rounded lg:space-y-2 bg-gradient-to-r from-emerald-500 via-emerald-400 to-emerald-300 shadow-md">
                    <p className="font-medium text-white text-center text-base lg:text-2xl">Main Balance</p>
                    <div className="flex items-center justify-center">
                        <img className="w-5 lg:w-8 h-5 lg:h-8 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka-icon_s8y7yy.png" alt="" />
                        <h3 className="text-lg lg:text-3xl font-semibold text-white">{wallet.mainBalance}</h3>
                    </div>
                </div>
                <div className="col-span-1 w-full lg:w-1/3 h-24 lg:h-36 p-1 lg:p-4 rounded lg:rounded lg:space-y-2.5 bg-white myShadow border-b-2 lg:border-b-4 border-b-royalBlue">
                    <p className="font-medium text-center text-textBlack text-base lg:text-xl">Advance Income</p>
                    <div className="flex items-center justify-center">
                        <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_4_grdgmx.png" alt="" />
                        <h3 className="text-base lg:text-xl font-semibold text-royalBlue">{wallet?.advanceIncome}</h3>
                    </div>
                </div>

                {/* this is Total Reselling Earning of wallet  */}
                <div className="col-span-2 w-full lg:w-1/3 h-24 lg:h-36 px-2 lg:px-3 py-2 lg:py-4 rounded lg:rounded space-y-1 lg:space-y-2 bg-white myShadow border-b-2 lg:border-b-4 border-b-red">
                    <div className="flex items-center justify-between">
                        <p className="font-medium text-textBlack text-sm lg:text-lg">Total Withdraw</p>
                        <div className="flex items-center">
                            <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_2_j2npjs.png" alt="" />
                            <h3 className="text-base lg:text-xl font-semibold text-red">{wallet.totalWithdrawAmount}</h3>
                        </div>
                    </div>
                    <div className="flex items-center justify-between">
                        <p className="font-medium text-textBlack text-sm lg:text-lg">Total Advance</p>
                        <div className="flex items-center">
                            <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_2_j2npjs.png" alt="" />
                            <h3 className="text-base lg:text-xl font-semibold text-red">{wallet.totalAdvanceIncome}</h3>
                        </div>
                    </div>
                    <div className="flex items-center justify-between">
                        <p className="font-medium text-textBlack text-sm lg:text-lg">Total Give Back</p>
                        <div className="flex items-center">
                            <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_2_j2npjs.png" alt="" />
                            <h3 className="text-base lg:text-xl font-semibold text-red">{wallet.totalCommisionToPay}</h3>
                        </div>
                    </div>

                </div>
            </div>

            {/* this is second div  */}
            <div className="grid grid-cols-2 gap-2 lg:gap-4 pt-2 lg:pt-4">


                {/* this is recharge your amount of wallet  */}
                <div className="w-full h-28 lg:h-40 px-4 py-1 lg:py-2 rounded lg:rounded space-y-2 bg-[#FF4B4B] myShadow">
                    <p className="font-medium text-center text-white text-base lg:text-lg">Give Back</p>
                    <div className="flex flex-col items-center px-4 gap-2 lg:gap-4">
                        <div className="flex items-center justify-center">
                            <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka-icon_s8y7yy.png" alt="" />
                            <h3 className="text-base lg:text-xl font-semibold text-white">{wallet.commisionToPay}</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div className="lg:myBorder flex items-center justify-between lg:justify-center gap-1 lg:gap-4 lg:rounded px-0 lg:px-2 py-0 lg:py-2.5 md:gap-6 my-2 lg:my-4">
                <AppButton
                    className={`${isSelected === "EaringHistry" || isSelected === ""
                        ? "myBtn text-xxs lg:text-base px-0.5 lg:px-4 py-0 lg:py-1"
                        : "outlineBtn text-xxs lg:text-base px-0.5 lg:px-4 py-0 lg:py-1"
                        }`}
                    text="Earning History"
                    onClick={openEaringHistry}
                />
                <AppButton
                    className={`${isSelected === "WithdrawHistry"
                        ? "myBtn text-xxs lg:text-base px-0.5 lg:px-4 py-0 lg:py-1"
                        : "outlineBtn text-xxs lg:text-base px-0.5 lg:px-4 py-0 lg:py-1"
                        }`}
                    text="Withdraw History"
                    onClick={openWithdrawHistry}
                />
                <AppButton
                    className={`${isSelected === "PullHistry"
                        ? "myBtn text-xxs lg:text-base px-0.5 lg:px-4 py-0 lg:py-1"
                        : "outlineBtn text-xxs lg:text-base px-0.5 lg:px-4 py-0 lg:py-1"
                        }`}
                    text="Pull History"
                    onClick={openPullHistry}
                />

                <AppButton
                    className={`${isSelected === "RechargeHistory"
                        ? "myBtn text-xxs lg:text-base px-0.5 lg:px-4 py-0 lg:py-1"
                        : "outlineBtn text-xxs lg:text-base px-0.5 lg:px-4 py-0 lg:py-1"
                        }`}
                    text="Recharge History"
                    onClick={openRechargeHistry}
                />

                <AppButton
                    className={`${isSelected === "GiveBackHistory"
                        ? "myBtn text-xxs lg:text-base px-0.5 lg:px-4 py-0 lg:py-1"
                        : "outlineBtn text-xxs lg:text-base px-0.5 lg:px-4 py-0 lg:py-1"
                        }`}
                    text="GiveBack History"
                    onClick={openGiveBackHistry}
                />
            </div>
            {/* this is earning history  */}
            {isEarningHistry && (
                <div className="flex w-full bg-primary rounded lg:rounded">
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[20%]">Earning Amount</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[19.8%]">Date & Time</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[19.8%]">Earning Type</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[19.9%]">Earning Status</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 w-[20%]">Earned From</div>
                </div>
            )}

            {isEarningHistry && <div className="flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-0.5 lg:gap-1 py-0.5 lg:py-1">
                {isEarningHistry &&
                    pointList?.map((item: GetVendorPointResponse) => {
                        return (
                            <div key={item?.id} className="myBorder flex w-full items-center">
                                <div className="centerDiv text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 border-r border-grey h-full w-[20%]">৳ {item?.earnedAmount}</div>
                                <div className="centerDiv text-xxs lg:text-sm px-0.5 lg:px-4 py-0.5 lg:py-1 border-r border-grey h-full w-[20%]">{orderTimeFormatter(item.createdAt)}</div>
                                <div className="centerDiv text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 border-r border-grey h-full w-[20%]">
                                    <span
                                        className={
                                            item.earningType === "CourseEarning"
                                                ? "CourseEarning"
                                                : "" || item.earningType === "ResellerEarning"
                                                    ? "ResellerEarning"
                                                    : "" || item.earningType === "RefferEarning"
                                                        ? "RefferEarning"
                                                        : "" || item.earningType === "ModeratorEarning"
                                                            ? "ModeratorEarning"
                                                            : "" || item.earningType === "ProductEarning"
                                                                ? "ProductEarning"
                                                                : "" || item.earningType === "ShippingCost"
                                                                    ? "ShippingCost"
                                                                    : "" || item.earningType === "AdvancedPaymentEarning"
                                                                        ? "AdvancedPaymentEarning"
                                                                        : ""
                                        }
                                    >
                                        {item.earningType === "AdvancedPaymentEarning" ? "AdvancePayment" : item.earningType}
                                    </span>
                                </div>
                                <div className="centerDiv border-r border-grey py-1 h-full w-[20%]">
                                    <span
                                        className={
                                            item.earningStatus === "InProgress"
                                                ? "ProgressIn"
                                                : "" || item.earningStatus === "Denied"
                                                    ? "Denied"
                                                    : "" || item.earningStatus === "Earned"
                                                        ? "Earned"
                                                        : ""
                                        }
                                    >
                                        {item.earningStatus}
                                    </span>
                                </div>
                                <div className="centerDiv text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[20%]">{item?.earnedFrom}</div>
                            </div>
                        );
                    })}
            </div>}

            {isWithdrawHistry && (
                <div className="flex w-full bg-primary  rounded lg:rounded">
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[33.33%]">Withdraw Amount</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[33.33%]">Date & Time</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 w-[33.33%]">Withdraw status</div>
                </div>
            )}
            {isWithdrawHistry && <div className="flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-0.5 lg:gap-1 py-0.5 lg:py-1">
                {isWithdrawHistry &&
                    withdrawList?.items?.map((item: GetVendorPointWithdrawHistoryResponse) => {
                        return (
                            <div key={item?.id} className="myBorder flex w-full items-center">
                                <div className="centerDiv text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 border-r border-grey h-full w-[33.33%]">৳ {item.requestedAmount}</div>
                                <div className="centerDiv text-xxs lg:text-sm px-0.5 lg:px-4 py-0.5 lg:py-1 border-r border-grey h-full w-[33.33%]">{orderTimeFormatter(item.requestDateTime)}</div>
                                <div className="centerDiv text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[33.33%]">
                                    <span
                                        className={
                                            item.pointStatus === "InProgress" ? "ProgressIn" : "" ||
                                                item.pointStatus === "Cancelled" ? "Denied" : "" ||
                                                    item.pointStatus === "Received" ? "Earned" : ""
                                        }
                                    >
                                        {item.pointStatus}
                                    </span>
                                </div>
                            </div>
                        );
                    })}
            </div>}


            {/* this is pull history  */}
            {isPullHistry && (
                <div className="flex w-full bg-primary  rounded lg:rounded">
                    <div className="centerDiv text-white font-medium  text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[50%]">Pull Amount</div>
                    <div className="centerDiv text-white font-medium  text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[50%]">Date & Time</div>
                    {/* <div className="centerDiv text-white font-medium py-2.5 w-[33.33%]">Pull Type</div> */}
                </div>
            )}
            {isPullHistry && <div className="flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-0.5 lg:gap-1 py-0.5 lg:py-1">
                {isPullHistry &&
                    pullList?.map((item: any) => {
                        return (
                            <div key={item?.id} className="myBorder flex w-full items-center">
                                <div className="centerDiv border-r border-grey  text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[50%]">৳ {item?.pulledAmount}</div>
                                <div className="centerDiv border-r border-grey  text-xxs lg:text-sm px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[50%]">{orderTimeFormatter(item.dateTime)}</div>
                                {/* <div className="centerDiv py-1 h-full w-[33.33%]">
                      <span className={item.earningType === "CourseEarning" ? "CourseEarning" : "" || item.earningType === "ResellerEarning" ? "ResellerEarning" : "" || item.earningType === "RefferEarning" ? "RefferEarning" : "" || item.earningType === "ModeratorEarning" ? "ModeratorEarning" : "" || item.earningType === "ProductEarning" ? "ProductEarning" : ""}>{item.earningType}</span>
                    </div> */}
                            </div>
                        );
                    })}
            </div>}


            {/* this is recharge history  */}
            {isRechargeHistry && (
                <div className="flex w-full bg-primary  rounded lg:rounded">
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[25%]">Recharge Amount</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[25%]">Date & Time</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[25%]">Payment Method</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 w-[25%]">Recharge status</div>
                </div>
            )}
            {isRechargeHistry && <div className="flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-0.5 lg:gap-1 py-0.5 lg:py-1">
                {isRechargeHistry &&
                    rechargeHistory?.map((item: GetWalletRechargeHistory, index: any) => {
                        return (
                            <div key={index} className="myBorder flex w-full items-center">
                                <div className="centerDiv border-r border-grey  text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[25%]">৳ {item?.amount}</div>
                                <div className="centerDiv border-r border-grey  text-xxs lg:text-sm px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[25%]">{orderTimeFormatter(item.dateTime)}</div>
                                <div className="centerDiv border-r border-grey  text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[25%]">
                                    {item.paymentMethods}
                                </div>
                                <div className="centerDiv  text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[25%]">
                                    {item.transectionStatus}
                                </div>
                            </div>
                        );
                    })}
            </div>}

            {/* this is give back history  */}
            {isGiveBackHistry && (
                <div className="flex w-full bg-primary  rounded lg:rounded">
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[24.9%]">GiveBack Amount</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[24.9%]">Date & Time</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[24.8%]">Payment Method</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 w-[25%]">GiveBack status</div>
                </div>
            )}
            {isGiveBackHistry && <div className="flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-0.5 lg:gap-1 py-0.5 lg:py-1">
                {isGiveBackHistry &&
                    giveBackHistory?.map((item: GetWalletRechargeHistory, index: any) => {
                        return (
                            <div key={index} className="myBorder flex w-full items-center">
                                <div className="centerDiv border-r border-grey text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[25%]">৳ {item?.amount}</div>
                                <div className="centerDiv border-r border-grey text-xxs lg:text-sm px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[25%]">{orderTimeFormatter(item.dateTime)}</div>
                                <div className="centerDiv border-r border-grey text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[25%]">
                                    {item.paymentMethods}
                                </div>
                                <div className="centerDiv text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[25%]">
                                    {item.transectionStatus}
                                </div>
                            </div>
                        );
                    })}
            </div>}
        </div>
    );
}
