import { GET } from "../../../httpService/service";
import { GetProductListRequest } from "../../../models/catalog/product/productsModels";
import { AFFILIATE_GET_PRODUCTS, AFFILIATE_GET_PRODUCT_BY_ID } from "../../constants/catalog/affiliateProductConstants";


const affiliateGetProductsAction = (payload?: GetProductListRequest) => async (dispatch: any) => {
    try {
        const data = await GET(`/api/Product/GetProductsPublic?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}&sortBy=${payload?.sortBy}&minimumPrice=${payload?.minimumPrice}&maximumPrice=${payload?.maximumPrice}&vendorId=${payload?.vendorId}&mainCategoryId=${payload?.mainCategoryId}&subCategoryLevelOneId=${payload?.subCategoryLevelOneId}`);
        dispatch({ type: AFFILIATE_GET_PRODUCTS, payload: data.data });
        return (await data).data;;
    }
    catch (error: any) { }
}

const affiliateGetProductByIdAction = (id: any) => async (dispatch: any) => {
    try {
        const data = await GET(`/api/AffiliateProduct/GetProductPublicById/${id}`);
        dispatch({ type: AFFILIATE_GET_PRODUCT_BY_ID, payload: data });
    }
    catch (error: any) { }
}

export {
    affiliateGetProductsAction,
    affiliateGetProductByIdAction
}