import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { AlertColor } from "@mui/material";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { AppButton } from "../../../common/AppButton";
import { AppInput } from "../../../common/AppInput";
import { AppSelect } from "../../../common/AppSelect";
import { addPublicRepresentativesService } from "../../../../httpService/importantNumbers/publicRepresentativesServices";
import { IdNameResponseModel } from "../../../../models/common/IdNameModel";
import { RootState } from "../../../../store";
import { getUnionAuthListAction, getUnionListAction } from "../../../../store/actions/importantNumbers/getUnionListAction";
import { reactLocalStorage } from "reactjs-localstorage";
import { isAdmin } from "../../../../common/common";

const designations = [
  { id: 1, name: "UnionChairman" },
  { id: 2, name: "UnionMember" },
  { id: 3, name: "UpazilaChairman" },
];

export default function INCreatePublicRepresentatives() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [id, setId] = useState(0);
  const [name, setName] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [
    publicRepresentativeDesignations,
    setpublicRepresentativeDesignations,
  ] = useState(0);
  const [
    publicRepresentativeDesignationsErrorMessage,
    setPublicRepresentativeDesignationsErrorMessage,
  ] = useState("");
  const [phoneNumberFirst, setPhoneNumberFirst] = useState("");
  const [phoneNumberFirstErrorMessage, setPhoneNumberFirstErrorMessage] =
    useState("");
  const [phoneNumberSecond, setPhoneNumberSecond] = useState("");
  const [phoneNumberSecondErrorMessage, setPhoneNumberSecondErrorMessage] =
    useState("");
  const [note, setNote] = useState("");
  const [noteErrorMessage, setNoteErrorMessage] = useState("");

  const [unionOrWard, setUnionOrWard] = useState(0);
  const [unionOrWardErrorMessage, setUnionOrWardErrorMessage] = useState("");

  const handleNameChange = (event: any) => {
    setName(event);
    setNameErrorMessage("");
  };

  const handleUnionOrWard = (id: any) => [setUnionOrWard(id)];

  const handlepublicRepresentativeDesignations = (id: any) => [
    setpublicRepresentativeDesignations(id),
  ];

  const handlePhoneNumberFirst = (event: any) => {
    setPhoneNumberFirst(event);
    setPhoneNumberFirstErrorMessage("");
  };
  const handlePhoneNumberSecond = (event: any) => {
    setPhoneNumberSecond(event);
    setPhoneNumberSecondErrorMessage("");
  };

  const handleNote = (event: any) => {
    setNote(event);
    setNoteErrorMessage("");
  };

  useEffect(() => {
    dispatch(getUnionAuthListAction());
  }, [dispatch]);

  const dataUnionList = useSelector((state: RootState) => {
    return state.getUnionListResponseByAuthArea.data;
  });

  var dataUnionListIdNameModels: IdNameResponseModel[] = [];
  dataUnionList?.map((x: any) => {
    var union = new IdNameResponseModel();
    union.id = x.id;
    union.name = x.name;
    dataUnionListIdNameModels.push(union);
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSnackBarOn(false);
    var payload = {
      name: name,
      publicRepresentativeDesignations: publicRepresentativeDesignations,
      phoneNumberFirst: phoneNumberFirst,
      phoneNumberSecond: phoneNumberSecond,
      note: note,
      unionOrWardId: unionOrWard,
    };

    setIsButtonLoading(true);

    addPublicRepresentativesService(payload)
      .then((Response) => {
        setSnackBarOn(true);
        setsnackBarMessage("Public Representative Number Added Successfully.");
        setSnackBarMessageType("success");
        setIsButtonLoading(false);
        setTimeout(() => {
          navigateToPublicRepresentatives();
        }, 300);
      })
      .catch((error: any) => {
        setIsButtonLoading(false);
        setNameErrorMessage(error.response.data.errors?.Name?.[0]);
        setPhoneNumberFirstErrorMessage(
          error.response.data.errors?.PhoneNumberFirst?.[0]
        );
        setPhoneNumberSecondErrorMessage(
          error.response.data.errors?.PhoneNumberSecond?.[0]
        );
        setPublicRepresentativeDesignationsErrorMessage(error.response.data.errors?.PublicRepresentativeDesignations?.[0]);
        setNoteErrorMessage(error.response.data.errors?.Note?.[0]);
        setUnionOrWardErrorMessage(error.response.data.errors?.UnionOrWardId?.[0]);
      });
  };
  const navigateToPublicRepresentatives = () => {
		if (isAdmin(reactLocalStorage.get("RoleName"))) {
		  navigate(`/admin/important-numbers/public-representatives`);
		} else {
		  navigate(`/important-numbers/public-representatives`);
		}
	  };

  return (
    <div className="container mx-auto px-2 py-4">
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      <div className="flex flex-col lg:flex-row items-start lg:items-center pb-4">
        <AppButton
          className="gradientBtn"
          onClick={navigateToPublicRepresentatives}
          text="Back"
        />
        <div className='w-full'>
          <h2 className="title text-center text-cyan-600">
            Create Public Representatives Contact Number
          </h2>
        </div>
      </div>

      <form className="myBorder mx-auto max-w-xl grid grid-cols-2 gap-x-2 items-center lg:gap-x-4 gap-y-2 shadow-lg px-2 lg:px-5 py-2 lg:py-4">
        <AppInput
          label="Name"
          className="myInput"
          type="text"
          placeholder="Enter Name"
          onChange={handleNameChange}
          errorMessage={nameErrorMessage}
        />
        <div className="">
          <p className="text-sm md:text-base">Designation</p>
          <AppSelect
            defaultValue="Select Designation"
            value={publicRepresentativeDesignations}
            data={designations}
            onChange={handlepublicRepresentativeDesignations}
            errorMessage={publicRepresentativeDesignationsErrorMessage}
          />
        </div>
        <AppInput
          label="Phone Number First"
          className="myInput"
          type="text"
          placeholder="Enter Phone Number"
          onChange={handlePhoneNumberFirst}
          errorMessage={phoneNumberFirstErrorMessage}
        />
        <AppInput
          label="Phone Number Second"
          className="myInput"
          type="text"
          placeholder="Enter Phone Number"
          onChange={handlePhoneNumberSecond}
          errorMessage={phoneNumberSecondErrorMessage}
        />
        <AppInput
          label="Note"
          className="myInput"
          type="text"
          placeholder="Enter Note"
          onChange={handleNote}
          errorMessage={noteErrorMessage}
        />
        <div className="">
          <p className="text-sm md:text-base">Union or Ward:</p>
          <AppSelect
            defaultValue="Select Ward Or Union"
            value={unionOrWard}
            data={dataUnionListIdNameModels}
            onChange={handleUnionOrWard}
            errorMessage={unionOrWardErrorMessage}
          />
        </div>

        <div className="flex justify-end col-span-2">
          <AppButton
            text="Submit"
            className="gradientBtn"
            onClick={handleSubmit}
            isSubmitting={isButtonLoading}
            variant="contained"
          />
        </div>
      </form>
    </div>
  );
}
