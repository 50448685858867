import { getAreaByDistrictIdService, areaListService, areaListAdminService } from "../../../../httpService/area/areaServices";
import { PaginationPayloadModel } from "../../../../models/common/paginationModels";
import { GET_AREA_ADMIN_LIST, GET_AREA_LIST } from "../../../constants/auth/address/AddressConstants";

export const areaList = (payload?: PaginationPayloadModel) => async (dispatch: any) => {
        const data = areaListService(payload)
        const pload = (await data).data;
        dispatch({ type: GET_AREA_LIST, payload: pload });
}

export const areaListAdmin = (payload?: PaginationPayloadModel) => async (dispatch: any) => {
        const data = areaListAdminService(payload)
        const pload = (await data).data;
        dispatch({ type: GET_AREA_ADMIN_LIST, payload: pload });
}

export const areaListByDistrictId = (payload?: any) => async (dispatch: any) => {
        const data = getAreaByDistrictIdService(payload)
        const pload = (await data).data;
        dispatch({ type: GET_AREA_LIST, payload: pload });
}

