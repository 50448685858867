import { AlertColor } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { addProductReviewService } from "../../../../httpService/catalog/productReviewServices";
import { ProductMediaModel, ProductReviewCustomerRequest, ProductReviewCustomerResponse } from "../../../../models/catalog/productReview/productReviewModels";
import { RootState } from "../../../../store";
import { resellingProductReviewsActions } from "../../../../store/actions/catalog/resellingProductReviewActions";
import { AppDefaultProfilePicture } from "../../../common/AppDefaultProfilePicture";
import { AppSnackBar } from "../../../common/AppSnackBar";
import _moment from 'moment';
import { AppButton } from "../../../common/AppButton";
import { AppInput } from "../../../common/AppInput";

interface IGetProductReviewProps {
    productReviews?: ProductReviewCustomerResponse[];
}

export const AdminGetCustomerReviews: React.FC<IGetProductReviewProps> = ({ productReviews }) => {

    const dispatch = useDispatch();

    const { id } = useParams();

    const [isShowSnackBar, setIsShowSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

    const reviews = useSelector((state: RootState) => {
        return state.productReviewListResponse.data;
    });

    useEffect(() => {
        dispatch(resellingProductReviewsActions(id));
    }, [dispatch]);


    const calculateTime = (createdDateTime: any) => {
        const createdTime = _moment(createdDateTime, 'YYYY-MM-DD HH:mm:ss A');
        let formattedTime;

        if (_moment().diff(createdTime, 'hours') < 1) {
            formattedTime = createdTime.fromNow();
        } else if (_moment().diff(createdTime, 'days') < 1) {
            formattedTime = createdTime.format('h:mm A');
        } else {
            formattedTime = createdTime.format('MMM D, YYYY [at] h:mm A');
        }
        return formattedTime;
    }

    const [isRepOpen, setIsRepOpen] = useState(false);
    const [selectedRepId, setSelectedRepId] = useState(0);
    const [repText, setRepText] = useState("");
    const [repTextError, setRepTextError] = useState("");

    const handleReplyOpen = (item: any) => {
        setIsRepOpen(!isRepOpen)
        setSelectedRepId(item.id)
    }

    const handleRepText = (value: any) => {
        setRepText(value)
        setRepTextError("")
    }

    const [medias, setMedias] = useState<ProductMediaModel[]>([]);

    const [isReviewRatingSubmitButtonLoading, setIsReviewRatingSubmitButtonLoading] = useState(false);


    const handleSubmitReply = (item: ProductReviewCustomerResponse) => {

        if (repText === "" || repText === null || repText === undefined) {
            setRepTextError("Reply text Is Required")
        }
        else {
            let model = new ProductReviewCustomerRequest();
            model.isParent = false;
            model.parentId = item.id;
            model.productId = id;
            model.reviewText = repText;
            model.orderId = item.orderId;
            model.mediaIds = medias?.map((_: any) => _.id)
            setIsReviewRatingSubmitButtonLoading(true);
            addProductReviewService(model)
                .then(() => {
                    setIsRepOpen(false);
                    setIsShowSnackBar(true);
                    setSnackBarMessage("Submitted Reply Successfully");
                    setSnackBarMessageType("success");
                    setTimeout(() => {
                        setIsShowSnackBar(false);
                    }, 2000);
                    setIsReviewRatingSubmitButtonLoading(false);
                    dispatch(resellingProductReviewsActions(id));
                    setRepText("")
                })
                .catch((error: any) => {
                    setIsReviewRatingSubmitButtonLoading(false);
                    setRepTextError(error.response.data.errors?.ReviewText?.[0])
                });
        }

    }

    return (
        <div className="py-4">
            {isShowSnackBar && (
                <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
            )}

            <h2 className="title text-center">Comments</h2>
            {reviews?.length ? (
                reviews?.map((item: ProductReviewCustomerResponse, index: any) => {
                    return (
                        <div key={index}>
                            <div className="flex flex-col gap-1.5 py-2">
                                <div className="flex items-center gap-2 lg:gap-4">
                                    <div className="">
                                        {item?.customerName != null && (
                                            <AppDefaultProfilePicture className="customer-review-image" name={item?.customerName} profilePicture={item?.customerPhotoPath} />
                                        )}
                                    </div>
                                    <div className="">
                                        <h3 className="font-medium">{item?.customerName}</h3>
                                        <p className="text-gray-600 text-xs">{calculateTime(item?.updatedDateTime)}</p>
                                    </div>
                                </div>

                                <div className="ml-10 lg:ml-16">
                                    <p> {item.reviewText}</p>
                                    <div className="flex items-center gap-2 flex-wrap">
                                        {item?.reviewMedias?.map((i: any, index: any) => {
                                            return (
                                                <img key={index} className="w-12 lg:w-20 h-12 lg:h-20 object-fill" src={i.url} alt="" />
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>

                            {item.reviewResponseList?.map((rep: ProductReviewCustomerResponse, index: any) => {
                                return (
                                    <div key={index} className="flex flex-col gap-1.5 pl-1 ml-12 lg:ml-20 border-l-2 border-primary">
                                        <div className="flex items-center gap-2 lg:gap-4">
                                            <div className="">
                                                {rep?.customerName != null && (
                                                    <AppDefaultProfilePicture className="customer-review-image" name={rep?.customerName} profilePicture={rep?.customerPhotoPath} />
                                                )}
                                            </div>
                                            <div className="">
                                                <h3 className="font-medium">{rep.customerName}</h3>
                                                <p className="text-gray-600 text-xs">{calculateTime(rep.updatedDateTime)}</p>
                                            </div>
                                        </div>

                                        <p className="ml-10 lg:ml-16">
                                            {rep.reviewText}
                                        </p>
                                    </div>
                                )
                            })}

                            <div className="pt-4 ml-10 lg:ml-16">
                                <AppButton text="Reply" onClick={() => handleReplyOpen(item)} />

                                {isRepOpen && (selectedRepId === item.id) && (
                                    <div className="flex flex-col gap-2 md:px-12 py-4 md:py-2">
                                        <p className="text-lg font-medium">Reply</p>
                                        <div className="flex flex-col myBorder px-2 md:px-4 py-2">
                                            <AppInput
                                                className="text-lg"
                                                placeholder="Write your reply"
                                                value={repText}
                                                onChange={handleRepText}
                                                errorMessage={repTextError}
                                            />
                                        </div>
                                        <div className="flex justify-end items-center pt-2">
                                            <AppButton
                                                isSubmitting={isReviewRatingSubmitButtonLoading}
                                                text="Submit Reply"
                                                className="myBtn w-fit"
                                                onClick={() => handleSubmitReply(item)}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                })
            ) : (
                <p>No review yet.</p>
            )}
        </div>
    )
}
