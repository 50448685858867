import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { AppButton } from "../../../common/AppButton";
import { AppSearchBox } from "../../../common/AppSearchBox";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { AlertColor } from "@mui/material";
import { AppLoader } from "../../../common/AppLoader";
import { AppPagination } from "../../../common/AppPagination";
import { AppSelect } from "../../../common/AppSelect";
import { debounce } from "lodash";
import { districtListByDivisionId as districtListByDivisionId } from "../../../../store/actions/auth/address/districtActions";
import { BiSearchAlt2 } from "react-icons/bi";
import { Link } from "react-router-dom";
import { IdNameResponseModel } from "../../../../models/common/IdNameModel";
import { areaListByDistrictId } from "../../../../store/actions/auth/address/areaActions";
import { divisionList } from "../../../../store/actions/auth/address/divisionActions";
import { getUserProfileAction } from "../../../../store/actions/auth/user/userActions";
import {
  GetAcademicInstitutesNumber,
  GetTeachersNumberModel,
} from "../../../../models/importantNumber/ImportantNumberModel";
import { getUnionListByAreaId } from "../../../../store/actions/importantNumbers/getUnionListAction";
import {
  getAcademicInstitutesListAction,
  getTeachersListAction,
} from "../../../../store/actions/importantNumbers/academicInstitutesActions";
import { isAdmin } from "../../../../common/common";
import { reactLocalStorage } from "reactjs-localstorage";

export default function INGetTeachersNumber() {
  const dispatch = useDispatch();

  var pModel = new GetTeachersNumberModel();

  const [divisionId, setDivisionId] = useState(0);
  const [districtId, setDistrictId] = useState(0);
  const [unionOrWardId, setUnionOrWardId] = useState(0);
  const [unionOrWardError, setUnionOrWardError] = useState("");
  const [instituteId, setInstituteId] = useState(0);
  const [instituteIdError, setInstituteIdError] = useState("");
  const [areaId, setAreaId] = useState(0);
  const [divisionError, setDivisionError] = useState("");
  const [districtError, setDistrictError] = useState("");
  const [areaError, setAreaError] = useState("");
  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const userProfile = useSelector((state: RootState) => {
    return state.getUserProfileResponse.data;
  });
  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);

  const [isLoading, setIsLoading] = useState(true);

  const divisions = useSelector((state: RootState) => {
    return state.divisionListResponse.data;
  });
  var divisionIdNameModels: IdNameResponseModel[] = [];

  divisions?.map((x: any) => {
    var division = new IdNameResponseModel();
    division.id = x.id;
    division.name = x.name;
    divisionIdNameModels.push(division);
  });

  const districts = useSelector((state: RootState) => {
    return state.districtListResponse.data;
  });
  var districtsIdNameModels: IdNameResponseModel[] = [];

  districts?.map((x: any) => {
    var district = new IdNameResponseModel();
    district.id = x.id;
    district.name = x.name;
    districtsIdNameModels.push(district);
  });

  const thanas = useSelector((state: RootState) => {
    return state.areaListResponse.data;
  });
  var areaIdNameModels: IdNameResponseModel[] = [];

  thanas?.map((x: any) => {
    var area = new IdNameResponseModel();
    area.id = x.id;
    area.name = x.name;
    areaIdNameModels.push(area);
  });

  const unionOrWards = useSelector((state: RootState) => {
    return state.getUnionListResponseByArea.data;
  });
  var unionOrWardIdNameModels: IdNameResponseModel[] = [];

  unionOrWards?.map((x: any) => {
    var unionOrWard = new IdNameResponseModel();
    unionOrWard.id = x.id;
    unionOrWard.name = x.name;
    unionOrWardIdNameModels.push(unionOrWard);
  });

  const institutes = useSelector((state: RootState) => {
    return state.getAcademicInstitutesResponse.data;
  });
  var institutesIdNameModels: IdNameResponseModel[] = [];

  institutes?.items?.map((x: any) => {
    var institute = new IdNameResponseModel();
    institute.id = x.id;
    institute.name = x.name;
    institutesIdNameModels.push(institute);
  });

  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(divisionList());
  }, [dispatch]);

  useEffect(() => {
    if (divisionId !== 0) {
      dispatch(districtListByDivisionId(divisionId));
    }
  }, [divisionId, dispatch]);

  useEffect(() => {
    if (districtId !== 0) {
      dispatch(areaListByDistrictId(districtId));
    }
  }, [districtId, dispatch]);

  useEffect(() => {
    if (areaId !== 0) {
      dispatch(getUnionListByAreaId(areaId));
    }
  }, [areaId, dispatch]);

  useEffect(() => {
    if (unionOrWardId !== 0) {
      var pModel = new GetAcademicInstitutesNumber();
      pModel.unionOrWardId = unionOrWardId;
      dispatch(getAcademicInstitutesListAction(pModel));
    }
  }, [unionOrWardId, dispatch]);

  const academicInstituteEmployees = useSelector((state: RootState) => {
    return state.getTeachersOnlyResponse.data;
  });

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        var pModel = new GetTeachersNumberModel();
        pModel.limit = 10;
        pModel.offset = 0;
        pModel.searchQuery = searchQuery;
        pModel.divisionId = userProfile.divisionId ? userProfile.divisionId : 0;
        pModel.districtId = userProfile.districtId ? userProfile.districtId : 0;
        pModel.areaId = userProfile.areaId ? userProfile.areaId : 0;
        setDivisionId(userProfile.divisionId ? userProfile.divisionId : 0);
        setDistrictId(userProfile.districtId ? userProfile.districtId : 0);
        setAreaId(userProfile.areaId ? userProfile.areaId : 0);
        pModel.unionOrWardId = 0;
        pModel.instituteId = 0;
        await dispatch(getTeachersListAction(pModel));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  const handleDivision = (id: number) => {
    setDivisionError("");
    setDivisionId(id);
    setDistrictId(0);
    setAreaId(0);
    setUnionOrWardId(0);
    var pModel = new GetTeachersNumberModel();
    pModel.limit = 100000;
    pModel.offset = 0;
    pModel.divisionId = id;
    pModel.districtId = 0;
    pModel.areaId = 0;
    pModel.unionOrWardId = 0;
    pModel.instituteId = 0;
    pModel.searchQuery = searchQuery;
    dispatch(getTeachersListAction(pModel));
  };

  const handleDistrict = (id: number) => {
    setDistrictError("");
    setDistrictId(id);
    setAreaId(0);
    setUnionOrWardId(0);
    var pModel = new GetTeachersNumberModel();
    pModel.limit = 100000;
    pModel.offset = 0;
    pModel.divisionId = divisionId;
    pModel.districtId = id;
    pModel.areaId = 0;
    pModel.unionOrWardId = 0;
    pModel.instituteId = 0;
    pModel.searchQuery = searchQuery;
    dispatch(getTeachersListAction(pModel));
  };

  const handleArea = (id: number) => {
    setAreaError("");
    setAreaId(id);
    setUnionOrWardId(0);
    var pModel = new GetTeachersNumberModel();
    pModel.limit = 100000;
    pModel.offset = 0;
    pModel.divisionId = divisionId;
    pModel.districtId = districtId;
    pModel.areaId = id;
    pModel.unionOrWardId = 0;
    pModel.instituteId = 0;
    pModel.searchQuery = searchQuery;
    dispatch(getTeachersListAction(pModel));
  };

  const handleUnionOrWard = (id: number) => {
    setUnionOrWardError("");
    setUnionOrWardId(id);
    var pModel = new GetTeachersNumberModel();
    pModel.limit = 100000;
    pModel.offset = 0;
    pModel.divisionId = divisionId;
    pModel.districtId = districtId;
    pModel.areaId = areaId;
    pModel.unionOrWardId = id;
    pModel.instituteId = 0;
    pModel.searchQuery = searchQuery;
    dispatch(getTeachersListAction(pModel));
  };

  const handleInstituteChange = (id: number) => {
    setInstituteIdError("");
    setInstituteId(id);
    var pModel = new GetTeachersNumberModel();
    pModel.limit = 100000;
    pModel.offset = 0;
    pModel.divisionId = divisionId;
    pModel.districtId = districtId;
    pModel.areaId = areaId;
    pModel.unionOrWardId = unionOrWardId;
    pModel.instituteId = id;
    pModel.searchQuery = searchQuery;
    dispatch(getTeachersListAction(pModel));
  };

  const handleOnRowChange = (payload: GetTeachersNumberModel) => {
    const fetchData = async () => {
      try {
        var pModel = new GetTeachersNumberModel();
        pModel.limit = payload.limit;
        pModel.offset = 0;
        await dispatch(getTeachersListAction(pModel));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();
  };

  const handleOnPageChange = (payload: GetTeachersNumberModel) => {
    const fetchData = async () => {
      try {
        var pModel = new GetTeachersNumberModel();
        pModel.limit = payload.limit;
        pModel.offset = payload.offset;
        await dispatch(getTeachersListAction(pModel));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  };

  const handleSearchQuery = debounce((searchQuery: string) => {
    const fetchData = async () => {
      try {
        setSearchQuery(searchQuery);
        var pModel = new GetTeachersNumberModel();
        pModel.limit = 100000;
        pModel.offset = 0;
        pModel.divisionId = divisionId;
        pModel.districtId = districtId;
        pModel.areaId = areaId;
        pModel.unionOrWardId = unionOrWardId;
        pModel.searchQuery = searchQuery;

        dispatch(getTeachersListAction(pModel));
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, 500);

  return (
    <div className="container mx-auto px-2 py-2">
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      <div className="flex flex-col items-center lg:flex-row">
        <div className="flex w-full items-center justify-between">
          {isAdmin(reactLocalStorage.get("RoleName")) ? (
            <Link to="/admin/important-numbers">
              <AppButton
                className="gradientBtn"
                text="Back"
                onClick={() => {}}
              />
            </Link>
          ) : (
            <Link to="/important-numbers">
              <AppButton
                className="gradientBtn"
                text="Back"
                onClick={() => {}}
              />
            </Link>
          )}
          <h2 className="title hidden w-full text-center text-cyan-600 lg:block">
            Teachers Number ({academicInstituteEmployees?.size})
          </h2>
        </div>
        <h2 className="title w-full text-center text-cyan-600 lg:hidden">
          Teachers Number ({academicInstituteEmployees?.size})
        </h2>
      </div>

      <div className="grid grid-cols-3 gap-2 py-2 lg:grid-cols-6 lg:py-4">
        <div className="flex cursor-pointer items-center rounded border border-grey p-1 shadow-sm md:px-1.5 lg:w-full 2xl:p-2">
          <BiSearchAlt2 className="cursor-pointer text-xl font-bold text-cyan-600 md:text-2xl 2xl:text-3xl" />
          <AppSearchBox
            onChange={handleSearchQuery}
            placeholder="Search here"
            className="w-full border-none text-xs md:text-base lg:px-2"
          />
        </div>

        <AppSelect
          defaultValue="Select Division"
          data={divisionIdNameModels}
          value={divisionId}
          onChange={handleDivision}
          errorMessage={divisionError}
        />
        {divisionId !== 0 && (
          <AppSelect
            defaultValue="Select District"
            data={districtsIdNameModels}
            value={districtId}
            onChange={handleDistrict}
            errorMessage={districtError}
          />
        )}
        {divisionId !== 0 && districtId !== 0 && (
          <AppSelect
            defaultValue="Select Thana"
            data={areaIdNameModels}
            value={areaId}
            onChange={handleArea}
            errorMessage={areaError}
          />
        )}
        {divisionId !== 0 && districtId !== 0 && areaId !== 0 && (
          <AppSelect
            defaultValue="Union or Ward"
            data={unionOrWardIdNameModels}
            value={unionOrWardId}
            onChange={handleUnionOrWard}
            errorMessage={unionOrWardError}
          />
        )}
        {divisionId !== 0 &&
          districtId !== 0 &&
          areaId !== 0 &&
          unionOrWardId !== 0 && (
            <AppSelect
              defaultValue="Select An Institute"
              data={institutesIdNameModels}
              value={instituteId}
              onChange={handleInstituteChange}
              errorMessage={instituteIdError}
            />
          )}
      </div>

      {isLoading ? (
        <AppLoader status={true} />
      ) : (
        <>
          {academicInstituteEmployees?.items ? (
            <>
              <div className="w-full">
                <div className="myBorder myGradient hidden w-full lg:flex">
                  <div className="tableHead w-[15%]">FirstName</div>
                  <div className="tableHead w-[15%]">LastName</div>
                  <div className="tableHead w-[15%]">Designation</div>
                  <div className="tableHead w-[15%]">Email</div>
                  <div className="tableHead w-[15%]">Phone First</div>
                  <div className="tableHead w-[15%]">Phone Second</div>
                  <div className="tableHead w-[15%]">note</div>
                  <div className="tableHead w-[15%]">Address</div>
                </div>
                <div className="hidden h-[65dvh] flex-col gap-1 overflow-y-auto py-1.5 lg:flex 2xl:h-[68dvh]">
                  {academicInstituteEmployees?.items?.map(
                    (academicInstituteEmployee: any) => (
                      <div
                        key={academicInstituteEmployee?.id}
                        id={academicInstituteEmployee?.id}
                        className="myBorder flex w-full items-center"
                      >
                        <div className="tableRow textOverflowHidden w-[15%] justify-start">
                          {academicInstituteEmployee?.firstName}
                        </div>
                        <div className="tableRow textOverflowHidden w-[15%] justify-start">
                          {academicInstituteEmployee?.lastName}
                        </div>

                        <div className="tableRow w-[15%]">
                          {academicInstituteEmployee?.designation}
                        </div>

                        <div className="tableRow w-[15%]">
                          {academicInstituteEmployee?.email}
                        </div>

                        <div className="tableRow w-[15%]">
                          {academicInstituteEmployee?.phoneNumberFirst}
                        </div>
                        <div className="tableRow w-[15%]">
                          {academicInstituteEmployee?.phoneNumberSecond}
                        </div>

                        <div className="tableRow w-[15%]">
                          {academicInstituteEmployee?.note}
                        </div>

                        <div className="tableRow w-[15%]">
                          {academicInstituteEmployee?.address}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
              {/* these is footer part  */}
              <div className="fixed bottom-1 flex w-full items-center bg-white py-1 lg:static">
                <AppPagination
                  curPage={1}
                  limit={10}
                  offset={0}
                  onPageChange={handleOnPageChange}
                  onRowChange={handleOnRowChange}
                  size={academicInstituteEmployees?.size}
                />
              </div>
            </>
          ) : (
            <div className="flex h-[50dvh] w-full items-center justify-center text-3xl font-medium text-textBlack/90">
              <span>Teachers Number Not found!</span>
            </div>
          )}
        </>
      )}
    </div>
  );
}
