import { useEffect, useState } from 'react'
import { AppLoader } from '../../../common/AppLoader';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { getIsRiderHaveToPayAction, getRiderPointListAction, getRiderWalletAction } from '../../../../store/actions/auth/user/userActions';
import _moment from "moment";
import { AppButton } from '../../../common/AppButton';
import { AppModal } from '../../../common/AppModal';
import { GiveBackModel, PaymentMethods, RiderGivenToPayRequest } from '../../../../models/auth/user/UserModels';
import { giveBack, riderGivenToPayService } from '../../../../httpService/user/userServices';
import { CreateBkashPaymentRequest, CreateBkashPaymentResponse } from '../../../../models/payment/paymnetModel';
import { BKASH_CHARGE_PERCENTAGE } from '../../../../constants';
import { appUrl } from '../../../../config/config';
import { createBkashPaymentService } from '../../../../httpService/payment/paymnetServices';

export default function AdminSSGetRiderWallet() {

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);

    const orderTimeFormatter = (createdDateTime: any) => {
        const createdTime = _moment(createdDateTime, "YYYY-MM-DD HH:mm:ss.SSSSSSS");
        return createdTime.format("MMM D, YY [at] h:mm A");
    };

    const wallet = useSelector((state: RootState) => {
        return state.getRiderWalletResponse.data;
    });

    const pointList = useSelector((state: RootState) => {
        return state.getRiderPointListResponse.data;
    });

    const haveToPay = useSelector((state: RootState) => {
        return state.getIsRiderHaveToPayResponse.data;
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(getRiderWalletAction(0));
                await dispatch(getRiderPointListAction());
                await dispatch(getIsRiderHaveToPayAction());
                await completeGiveBack();
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [dispatch]);

    const [isPayModalOpen, setIsPayModalOpen] = useState(false);
    const [isPayButtonLoading, setIsPayButtonLoading] = useState(false);

    const handlePayModal = () => {
        setIsPayModalOpen(true);
    }

    const handlePay = () => {
        handleGiveBack();
    }

    const handleClosePayModal = () => {
        setIsPayModalOpen(false);
    }

    const handleGiveBack = () => {
        if (wallet?.commissionToPay as number <= 0) {
            return;
        }
        var model = new GiveBackModel();
        model.amount = wallet?.commissionToPay as number;
        model.paymentMethods = PaymentMethods.BKASH;
        model.isPaymentSuccessful = false;
        setIsPayButtonLoading(true);
        giveBack(model)
            .then((response: any) => {
                bkashPayment(
                    response?.data?.invoiceNumber,
                    response?.data?.totalAmount
                );
            })
            .catch((error: any) => {
                setIsPayButtonLoading(false);
            });
    };

    const bkashPayment = (invoiceNumber: any, totalAmount: number) => {
        var model = new CreateBkashPaymentRequest();
        model.amount = Number(
            (
                (totalAmount as number) +
                (totalAmount as number) * BKASH_CHARGE_PERCENTAGE
            ).toFixed(2)
        );
        model.callbackURL = `${appUrl}/admin/super-shop/rider/wallet`;
        model.invoiceNumber = invoiceNumber;
        createBkashPaymentService(model)
            .then((response: any) => {
                let res = response?.data as CreateBkashPaymentResponse;
                if (res?.isSucceeded) {
                    window.location.replace(res.data?.bkashURL);
                } else {
                    setIsPayButtonLoading(false);
                }
            })
            .catch(() => {
                setIsPayButtonLoading(false);
            });
    };

    const handleRiderWaletUpdate = () => {
        var model = new RiderGivenToPayRequest();
        model.amount = 0;
        setIsPayButtonLoading(true);
        riderGivenToPayService(model)
            .then(() => {
                dispatch(getRiderWalletAction(0));
                dispatch(getIsRiderHaveToPayAction());
                setIsPayButtonLoading(false);
            })
            .catch((error: any) => {
                setIsPayButtonLoading(false);
            });
    }

    const completeGiveBack = () => {
        const urlParams = new URLSearchParams(window.location.search);

        const paymentID = urlParams.get("paymentID");
        const status = urlParams.get("status");
        if (paymentID && status) {
            var model = new GiveBackModel();
            model.amount = wallet?.commissionToPay as number;
            model.paymentMethods = PaymentMethods.BKASH;
            model.isPaymentSuccessful = true;
            setIsPayButtonLoading(true);
            giveBack(model)
                .then((response: any) => {
                    handleRiderWaletUpdate();
                    setIsPayButtonLoading(false);
                })
                .catch((error: any) => {
                    setIsPayButtonLoading(false);
                });
        }
    }

    if (isLoading) {
        return (
            <div className="container mx-auto flex h-screen items-center justify-center px-2 pb-12 md:px-0">
                <AppLoader status={true} />
            </div>
        );
    }

    return (
        <>
            {(isPayModalOpen) && (
                <AppModal>
                    <div className="flex max-w-md flex-col gap-4 p-6 text-center md:text-2xl">
                        Confirm to Pay?
                        <div className="flex items-center justify-center gap-4">
                            <AppButton text="Yes" onClick={handlePay} isSubmitting={isPayButtonLoading} />
                            <AppButton text="No" onClick={handleClosePayModal} />
                        </div>
                    </div>
                </AppModal>
            )}

            {(haveToPay) && (
                <AppModal>
                    <div className="flex max-w-md flex-col gap-4 p-6 text-center md:text-2xl">
                        Must have to pay
                        <div className="flex items-center justify-center gap-4">
                            <AppButton text="OK" onClick={handlePay} isSubmitting={isPayButtonLoading} />
                        </div>
                    </div>
                </AppModal>
            )}
            <div className="w-full mx-auto mb-4 grid grid-cols-2 lg:grid-cols-4 gap-3 lg:gap-4">
                {/* <div className={`w-full h-24 lg:h-36 2xl:h-44  rounded-sm  bg-gradient-to-r from-emerald-500 via-emerald-400 to-emerald-300 py-1 lg:space-y-1 lg:py-2 lg:shadow`}>
                    <p className="pt-1 text-center text-base font-medium text-white lg:pt-2 lg:text-[22px]">
                        Main Balance
                    </p>
                    <div className="flex items-center justify-center">
                        <h3 className="text-lg font-semibold text-white lg:text-3xl">
                            {wallet?.mainBalance}
                        </h3>
                    </div>
                </div> */}
                <div className={`w-full h-24 lg:h-36 2xl:h-44  rounded-sm  bg-gradient-to-r from-emerald-500 via-emerald-400 to-emerald-300 py-1 lg:space-y-1 lg:py-2 lg:shadow`}>
                    <p className="pt-1 text-center text-base font-medium text-white lg:pt-2 lg:text-[22px]">
                        Commission To Pay
                    </p>
                    <div className="flex items-center justify-center">
                        <h3 className="text-lg font-semibold text-white lg:text-3xl">
                            {wallet?.commissionToPay}
                        </h3>
                    </div>
                    {(wallet?.commissionToPay > 10) && (
                        <div className="flex items-center justify-center">
                            <h3 className="text-lg font-semibold text-white lg:text-3xl">
                                <AppButton text='Pay' onClick={handlePayModal} />
                            </h3>
                        </div>
                    )}
                </div>
            </div>
            <div className="mx-auto container pt-2 lg:pt-4 pb-4 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28">
                <div className="flex w-full bg-primary rounded">
                    <div className="tableHead text-xxs lg:text-base w-[20%]">Earning Amount</div>
                    <div className="tableHead text-xxs lg:text-base w-[20%]">Date & Time</div>
                    <div className="tableHead text-xxs lg:text-base w-[19.8%]">Earning Type</div>
                    <div className="tableHead text-xxs lg:text-base w-[19.9%]">Earning Status</div>
                    <div className="tableHead text-xxs lg:text-base border-none w-[20%]">Earned From</div>
                </div>
                <div className="flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-0.5 lg:gap-1 py-0.5 lg:py-1">
                    {pointList?.map((item: any) => {
                        return (
                            <div key={item?.id} className="myBorder flex w-full items-center">
                                <div className="tableRow w-[20%]">৳ {item?.earnedAmount}</div>
                                <div className="tableRow text-xxs lg:text-base w-[20%]">{orderTimeFormatter(item.createdAt)}</div>
                                <div className="tableRow w-[20%]"><p>{item.earningType}</p></div>
                                <div className="tableRow w-[20%]">
                                    <p className={item.earningStatus === "InProgress" ? "ProgressIn" : "" || item.earningStatus === "Denied" ? "Denied" : "" || item.earningStatus === "Earned" ? "Earned" : ""}>{(item.earningType === "AdvancedPaymentEarning" || item.earningType === "ShippingCost") ? "Returned" : item.earningStatus === "Denied" ? "Cancelled" : item.earningStatus}</p>
                                </div>
                                <div className="tableRow border-none text-xxs lg:text-base w-[20%]">{item?.earnedFrom}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

