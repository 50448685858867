import { AlertColor } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppButton } from "../../../common/AppButton";
import { AppInput } from "../../../common/AppInput";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { RootState } from "../../../../store";
import { AppSelect } from "../../../common/AppSelect";
import { AppTextArea } from "../../../common/AppTextArea";
import { AddSubCatagoryOne } from "../../../../models/catalog/category/categoryModels";
import { addBechteChaiSubCategoryServices } from "../../../../httpService/bechteChai/categoryServices";
import { bechteChaiMainCategoryList, bechteChaiSubCategoryList } from "../../../../store/actions/bechteChai/bechteChaiCategoryActions";
import { IdNameResponseModel } from "../../../../models/common/IdNameModel";

export default function AdminCreateBechteChaiSubCategory() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mainCatagoryList = useSelector((state: RootState) => {
    return state.bechteChaiMainCategoryListResponse.data;
  });

  var idNameModels: IdNameResponseModel[] = [];
  mainCatagoryList.items?.map((x: { id: number; name: string | undefined }) => {
    var temp = new IdNameResponseModel();
    temp.id = x.id;
    temp.name = x.name;
    idNameModels.push(temp);
  });

  useEffect(() => {
    dispatch(bechteChaiMainCategoryList());
  }, []);

  const [errors, setErrors] = useState<any>({});
  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);

  const [id, setId] = useState(0);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isPublished, setIsPublished] = useState(false);
  const [isShowOnHomePage, setIsShowOnHomePage] = useState(false);
  const [isSpecial, setIsSpecial] = useState(false);
  const [displayOrder, setDisplayOrder] = useState(0);
  const [mainCategoryId, setMainCategoryId] = useState(0);
  const [userRoleId, setUserRoleId] = useState(0);
  const [bannerId, setBanner] = useState(0);
  const [bannerPath, setBannerPath] = useState("");

  const [nameError, setNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [displayOrderError, setDisplayOrderError] = useState("");
  const [useRoleIdError, setUserRoleIdError] = useState("");
  const [bannerError, setBannerError] = useState("");

  const [mainCategoryError, setMainCategoryError] = useState("");

  const handleIdChange = (event: any) => {
    setId(event);
    errors.id = "";
  };
  const handleNameChange = (event: any) => {
    setName(event);
    errors.name = "";
  };
  const handleDescriptionChange = (event: any) => {
    setDescription(event);
    errors.description = "";
  };

  const handleIsPublishedChange = (event: any) => [
    setIsPublished(event),
    (errors.isPublished = ""),
  ];

  const handleIsShowOnHomePageChange = (event: any) => {
    setIsShowOnHomePage(event);
    errors.isShowOnHomePage = "";
  };

  const handleIsSpecialChange = (event: any) => {
    setIsSpecial(event);
    errors.isSpecial = "";
  };

  const handleDisplayOrder = (event: any) => {
    setDisplayOrder(event);
    errors.displayOrder = "";
  };

  const handleMainCategoryIdChange = (event: any) => {
    setMainCategoryId(event);
    errors.mainCategoryId = "";
  };

  const handleUserRoleIdChange = (event: any) => {
    setUserRoleId(event);
    errors.userRoleId = "";
  };

  const handleBanner = (id: any, path: any) => {
    setBanner(id);
    setBannerPath(path);
  };

  const deleteBanner = () => {
    setBanner(0);
    setBannerPath("");
  };

  const [isSignInButtonLoading, setSignInButtonLoading] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    setSnackBarOn(false);

    var model = new AddSubCatagoryOne();
    model.id = id;
    model.name = name;
    model.description = description;
    model.isPublished = isPublished;
    model.isShowOnHomePage = isShowOnHomePage;
    model.isSpecial = isSpecial;
    model.displayOrder = displayOrder;
    model.mainCategoryId = mainCategoryId;
    model.userRoleId = userRoleId;
    model.bannerId = bannerId;

    setSignInButtonLoading(true);

    addBechteChaiSubCategoryServices(model)
      .then((response: any) => {
        setSnackBarOn(true);
        setsnackBarMessage("Successfully Added Main Ctegory");
        setSnackBarMessageType("success");

        setSignInButtonLoading(false);

        setErrors({});

        setTimeout(() => {
          navigate("/admin/bechte-chai/sub-categories");
        }, 300);

        dispatch(bechteChaiSubCategoryList());
      })
      .catch((error: any) => {

        setSignInButtonLoading(false);

        setMainCategoryError(error.response.data.errors.MainCategoryId[0]);
        setNameError(error.response.data.errors?.Name?.[0]);
        setDescriptionError(error.response.data.errors?.Description?.[0]);
        setDisplayOrderError(error.response.data.errors?.DisplayOrder?.[0]);
        setBannerError(error.response.data.errors?.BannerId?.[0]);
        setUserRoleIdError(error.response.data.errors?.UserRoleId?.[0]);
      });
  };
  return (
    <div>
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      {/* these is back button and title  */}
      <div className="lg:pl-4 lg:flex items-center lg:justify-between w-full lg:w-3/6 pb-2 lg:pb-6">
        <AppButton
          className="bechteChaiBtn"
          onClick={() => navigate("/admin/bechte-chai/sub-categories")}
          text="Back"
        />
        <h2 className="text-center title">Create Bechte Chai Sub Catagory</h2>
      </div>
      {/* these is form section  */}
      <form className="max-w-xl mx-auto myBorder py-2 lg:py-4 px-2 lg:px-5">
        <AppInput
          type="text"
          label="Sub category name"
          className="myInput"
          placeholder="Enter Sub Category Name"
          onChange={handleNameChange}
          errorMessage={nameError}
        />
        <p>{errors?.MainCategoryName?.[0]}</p>
        <AppTextArea
          label="Description"
          placeholder="Enter Description"
          onChange={handleDescriptionChange}
          errorMessage={descriptionError}
        />
        {/* published and show on homepage div here  */}
        <div className="flex justify-between items-center py-2">
          <div className="flex items-center gap-x-3">
            <label htmlFor="isPublished" className="text-xl">Published</label>
            <input
              className="w-6 h-6 cursor-pointer"
              type="checkbox"
              name="isPublished"
              onChange={() => handleIsPublishedChange(!isPublished)}
            />
            <p>{errors?.isPublished?.[0]}</p>
          </div>
          <div className='w-full lg:w-1/2'>
            <label className="text-sm lg:text-base" placeholder="Dhaka">Select Main Category</label>
            <AppSelect
              value={mainCategoryId}
              data={idNameModels}
              defaultValue="Select Main Category"
              errorMessage={mainCategoryError}
              onChange={handleMainCategoryIdChange}
            />
          </div>
          {/* <div className="flex items-center gap-x-3">
            <label htmlFor="isShowHomeOnPage" className="text-xl">Show On Homepage</label>
            <input
              className="w-6 h-6 cursor-pointer"
              type="checkbox"
              name="isShowHomeOnPage"
              onChange={() => handleIsShowOnHomePageChange(!isShowOnHomePage)}
            />
            <p>{errors?.isShowOnHomePage?.[0]}</p>
          </div> */}
        </div>

        <div className="flex justify-end items-center pt-3">
          {/* <div className="w-1/2 flex items-center gap-1">
            <label className="text-xl">Photo</label>
            {!bannerId && (
              <AppFileInput onSave={handleBanner} errorMessage={bannerError} />
            )}

            {bannerId !== 0 && (
              <div className="flex items-center gap-1">
                <img
                  // height={100}
                  // width={100}
                  src={`${bannerPath}`}
                  alt="sub category"
                />
                <IconButton onClick={deleteBanner}>
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
          </div> */}
          <AppButton onClick={handleSubmit} isSubmitting={isSignInButtonLoading} className="bechteChaiBtn" variant="contained" text="Submit" />
        </div>
      </form>

      {/* <AppInput
          type="text"
          placeholder="Enter ID"
          onChange={handleIdChange}
        />
        <p>{errors?.id?.[0]}</p> */}

      {/* <input
          type="checkbox"
          name="isSpecial"
          onChange={() => handleIsSpecialChange(!isSpecial)}
        />{" "}
        <label htmlFor="isSpecial">Special</label>
        <p>{errors?.isSpecial?.[0]}</p> */}
      {/* <AppInput
          type="text"
          placeholder="Enter Display Order"
          onChange={handleDisplayOrder}
          errorMessage={displayOrderError}
        />
        <p>{errors?.displayOrder?.[0]}</p> */}
      {/* <AppInput
          type="text"
          placeholder="Enter User Role Id"
          onChange={handleUserRoleIdChange}
          errorMessage={useRoleIdError}
        />
        <p>{errors?.userRoleId?.[0]}</p> */}
    </div>
  );
}
