import { bechteChaiProductCommentListService, getBechteChaiFavouriteItemsService, isAlreadyBechteChaiFavouriteService } from "../../../httpService/bechteChai/bechteChaiProductServices";
import { GET } from "../../../httpService/service";
import { PaginationPayloadModel } from "../../../models/common/paginationModels";
import { GET_BECHTE_CHAI_COMMENT_LIST, GET_BECHTE_CHAI_FAVOURITE_LIST, GET_BECHTE_CHAI_IS_ALREADY_FAVOURITE, GET_BECHTE_CHAI_MY_PRODUCT_LIST, GET_BECHTE_CHAI_PRODUCT_BY_ID, GET_BECHTE_CHAI_PRODUCT_LIST } from "../../constants/bechteChai/product";

export const bechteChaiProductList = (payload?: PaginationPayloadModel) => async (dispatch: any) => {
    try {
        const data = await GET(`/api/BechteChaiProduct/GetBechteChaiProductList?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}&filterBy=${payload?.filterBy}&posted=${false}`);
        dispatch({ type: GET_BECHTE_CHAI_PRODUCT_LIST, payload: data.data });
    }
    catch (error: any) { }
}

export const bechteChaiMyProductList = (payload?: PaginationPayloadModel) => async (dispatch: any) => {
    try {
        const data = await GET(`/api/BechteChaiProduct/GetBechteChaiProductList?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}&filterBy=${payload?.filterBy}&posted=${true}`);
        dispatch({ type: GET_BECHTE_CHAI_MY_PRODUCT_LIST, payload: data.data });
    }
    catch (error: any) { }
}


export const bechteChaiGetProductByIdAction = (id: any) => async (dispatch: any) => {
    try {
        const data = await GET(`/api/BechteChaiProduct/GetBechteChaiProductById?id=${id}`);
        dispatch({ type: GET_BECHTE_CHAI_PRODUCT_BY_ID, payload: data });
    }
    catch (error: any) { }
}

export const bechteChaiProductCommentsAction = (productId: any) => async (dispatch: any) => {
    var data = bechteChaiProductCommentListService(productId);
    const pload = (await data).data;
    dispatch({ type: GET_BECHTE_CHAI_COMMENT_LIST, payload: pload });
  };

  
export const bechteChaiGetFavouriteItemListAction = (pload: any) => async (dispatch: any) => {
    try {
        const data = await getBechteChaiFavouriteItemsService();
        dispatch({ type: GET_BECHTE_CHAI_FAVOURITE_LIST, payload: data.data });
    }
    catch (error: any) { }
}

export const bechteChaiGetIsAlreadyFavouriteOrNotAction = (id: any) => async (dispatch: any) => {
    try {
        const data = await isAlreadyBechteChaiFavouriteService(id);
        dispatch({ type: GET_BECHTE_CHAI_IS_ALREADY_FAVOURITE, payload: data.data });
    }
    catch (error: any) { }
}