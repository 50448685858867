import { useState, useEffect } from "react";
import { BiSearchAlt2 } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { SSGetOrderListRequest, SSGetOrdersResponse } from "../../../../models/superShopp/order/sSOrderModel";
import { RootState } from "../../../../store";
import { AppButton } from "../../../common/AppButton";
import { AppLoader } from "../../../common/AppLoader";
import { AppSearchBox } from "../../../common/AppSearchBox";
import _moment from 'moment';
import { sSAdminGetOrderListAction } from "../../../../store/actions/superShop/sSOrderActions";
import { reactLocalStorage } from "reactjs-localstorage";
import { AppModal } from "../../../common/AppModal";
import { getUserProfileAction } from "../../../../store/actions/auth/user/userActions";

export default function SSAdminSSGetOrderList() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const orders = useSelector((state: RootState) => {
    return state.sSAdminGetOrderListResponse.data;
  });

  let payload = new SSGetOrderListRequest();
  payload.orderId = 0;
  payload.orderStatus = 0;
  payload.paymentStatus = 0;
  payload.shippingStatus = 0;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await dispatch(sSAdminGetOrderListAction(payload));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  const [stickyTitle, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 80);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  const orderTimeFormatter = (createdDateTime: any) => {
    const createdTime = _moment(createdDateTime, 'YYYY-MM-DD HH:mm:ss.SSSSSSS');
    return createdTime.format('MMM D, YY [at] h:mm A');
  }

  const [isInProgress, setInProgress] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [isShipped, setIsShipped] = useState(false);
  const [isReturned, setIsReturned] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const selectInProgress = () => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setInProgress(true);
        setIsComplete(false);
        setIsCancelled(false);
        setIsShipped(false);
        setIsReturned(false);
        setIsConfirmed(false);
        payload.orderStatus = 1;
        await dispatch(sSAdminGetOrderListAction(payload));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }

  const selectComplete = () => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setInProgress(false);
        setIsComplete(true);
        setIsCancelled(false);
        setIsShipped(false);
        setIsReturned(false);
        setIsConfirmed(false);
        payload.orderStatus = 4;
        await dispatch(sSAdminGetOrderListAction(payload));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }

  const selectCancelled = () => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setInProgress(false);
        setIsComplete(false);
        setIsCancelled(true);
        setIsShipped(false);
        setIsReturned(false);
        setIsConfirmed(false);
        payload.orderStatus = 2;
        await dispatch(sSAdminGetOrderListAction(payload));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }

  const selectConfirmed = () => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setInProgress(false);
        setIsComplete(false);
        setIsCancelled(false);
        setIsShipped(false);
        setIsReturned(false);
        setIsConfirmed(true);
        payload.orderStatus = 5;
        await dispatch(sSAdminGetOrderListAction(payload));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }

  const selectShipped = () => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setInProgress(false);
        setIsComplete(false);
        setIsCancelled(false);
        setIsShipped(true);
        setIsReturned(false);
        setIsConfirmed(false);
        payload.shippingStatus = 2;
        await dispatch(sSAdminGetOrderListAction(payload));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }

  const selectReturned = () => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setInProgress(false);
        setIsComplete(false);
        setIsCancelled(false);
        setIsShipped(false);
        setIsReturned(true);
        setIsConfirmed(false);
        payload.orderStatus = 3;
        await dispatch(sSAdminGetOrderListAction(payload));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }

  const handleSearchChange = (newValue: any) => {
    payload.searchQuery = newValue;
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await dispatch(sSAdminGetOrderListAction(payload));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  };

  const userProfile = useSelector((state: RootState) => {
    return state.getUserProfileResponse.data;
  });

  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);

  const handleLogout = () => {
    reactLocalStorage.remove("SignIn");
    reactLocalStorage.remove("RoleName");
    reactLocalStorage.remove("UserId");
    navigate(`/`);
  };

  if (userProfile?.isBlocked) {
    return (
      <div className="container mx-auto flex h-screen items-center justify-center px-2 pb-12 md:px-0">
        <AppModal>
          <div className="py-12 px-4 md:px-20 flex flex-col-reverse md:flex-row items-center justify-between gap-4">
            <div className="flex flex-col items-center md:items-start gap-3">
              <h2 className="text-xl text-red md:text-3xl md:max-w-md font-semibold">Your Account has been Suspended. Please contact with Admin</h2>
              <div className="myBtn w-fit py-1.5 bg-red border-red" onClick={handleLogout} >
                Ok
              </div>
            </div>
          </div>
        </AppModal>
      </div>
    );
  }


  return (
    <div className="container mx-auto pt-2 pb-8 md:py-4 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28">
      <div className="flex items-center justify-between pb-2 md:pb-4">
        <h3 className="title">My Orders</h3>
        <h3 className="subTitle">Orders: <span>{orders?.items?.length}</span></h3>
      </div>
      <div className="flex flex-col-reverse lg:flex-row gap-2 items-center justify-between pb-2">
        <div className="flex items-center border shadow-sm border-grey 2xl:p-2 px-1.5 py-0.5 rounded cursor-pointer">
          <BiSearchAlt2 className="text-reselling cursor-pointer 2xl:text-3xl md:text-2xl text-xl font-bold" />
          <AppSearchBox onChange={handleSearchChange} className="border-none w-[81dvw] md:w-[25dvw] px-2 text-sm md:text-base" />
        </div>
        <div className="grid grid-cols-3 md:flex gap-2">
          <AppButton className={isInProgress ? "resellingBtn text-xs lg:text-sm px-1 lg:px-4" : "resellingOutlineBtn text-xs lg:text-sm px-1 lg:px-4"} text="Pending" onClick={selectInProgress} />
          <AppButton className={isCancelled ? "resellingBtn text-xs lg:text-sm px-1 lg:px-4" : "resellingOutlineBtn text-xs lg:text-sm px-1 lg:px-4"} text="Cancelled" onClick={selectCancelled} />
          <AppButton className={isConfirmed ? "resellingBtn text-xs lg:text-sm px-1 lg:px-4" : "resellingOutlineBtn text-xs lg:text-sm px-1 lg:px-4"} text="Confirmed" onClick={selectConfirmed} />
          <AppButton className={isShipped ? "resellingBtn text-xs lg:text-sm px-1 lg:px-4" : "resellingOutlineBtn text-xs lg:text-sm px-1 lg:px-4"} text="On the Way" onClick={selectShipped} />
          <AppButton className={isReturned ? "resellingBtn text-xs lg:text-sm px-1 lg:px-4" : "resellingOutlineBtn text-xs lg:text-sm px-1 lg:px-4"} text="Returned" onClick={selectReturned} />
          <AppButton className={isComplete ? "resellingBtn text-xs lg:text-sm px-1 lg:px-4" : "resellingOutlineBtn text-xs lg:text-sm px-1 lg:px-4"} text="Completed" onClick={selectComplete} />
        </div>
      </div>
      <hr className="border border-gray-700" />
      {isLoading ? (
        <div className="container mx-auto px-2 md:px-0 pb-12">
          <AppLoader status={true} />
        </div>
      ) : (
        <>
          {orders?.items?.length ? (
            <div className="w-full pt-2">
              <div className="hidden lg:flex h-[55dvh] 2xl:h-[60dvh] overflow-y-auto flex-col gap-1 py-1">
                {orders?.items?.map((order: SSGetOrdersResponse) => (
                  <div key={order?.id} className="myBorder flex w-full items-center">
                    <div className="tableRow w-[8%]">{order?.id}</div>
                    <div className="tableRow font-medium w-[6%]">{order?.totalItem}</div>
                    <div className="tableRow w-[13%]">{order?.bazarName}</div>
                    <div className="tableRow w-[15%]">{order?.totalAmount}</div>
                    <div className="tableRow w-[19%]">
                      <span className={`${order?.orderStatus === "InProgress" ? 'InProgress' : ''}` || `${order?.orderStatus === "Cancelled" ? 'Cancelled' : ''}` || `${order?.orderStatus === "Complete" ? 'Completed' : ''}` || `${order?.orderStatus === "Returned" ? 'Returned' : ''}` || `${order?.orderStatus === "Confirm" ? 'Confirmed' : ''}` || `${order?.orderStatus === "paid" ? 'paid' : ''}` || `${order?.shippingStatus === "Shipped" ? 'Shipped' : ''}`}>
                        {order?.orderStatus === "InProgress" ? "Pending" : order?.orderStatus === "Cancelled" ? "Cancelled" : order?.orderStatus === "Complete" ? "Completed" : order?.orderStatus === "Returned" ? "Returned" : order?.shippingStatus === "Shipped" ? "On the Way" : order?.orderStatus === "Confirm" ? "Confirmed" : ""}
                      </span>
                    </div>
                    <div className="tableRow text-sm w-[23%]">{orderTimeFormatter(order?.orderDateTime)}</div>
                    <div className="centerDiv py-0.5 w-[16%]">
                      <Link className="resellingBtn" to={`/admin/super-shop/order/${order.id}`}>
                        Details
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
              <div className="h-[70dvh] lg:hidden overflow-y-auto flex flex-col gap-1">
                {orders?.items?.map((order: SSGetOrdersResponse) => (
                  <Link key={order?.id} to={`/admin/super-shop/order/${order.id}`} className="myBorder flex w-full hover:shadow">
                    <div className="w-[37%] h-full p-1 space-y-1 border-r border-grey">
                      <div className="text-xsm flex items-center justify-between gap-1 pr-1">
                        <span className="">Order ID:</span> <span>#{order?.id}</span>
                      </div>
                      <div className="text-xsm flex items-center justify-between gap-1 pr-1">
                        <span className="">Bazar Name:</span> <span>#{order?.bazarName}</span>
                      </div>
                      <div className="text-[9px] text-center"> {orderTimeFormatter(order?.orderDateTime)}</div>
                    </div>
                    <div className="w-[27%] text-xsm h-full p-1 space-y-1 border-r border-grey">
                      <div className="flex items-center justify-between gap-1 pr-1">
                        <span>Quantity:</span> <span className="font-medium">{order?.totalItem}</span>
                      </div>
                      <div className="flex items-center justify-between gap-1 pr-1">
                        <span className="">Price:</span> <span className="font-medium">৳{order?.totalAmount}</span>
                      </div>
                    </div>
                    <div className="w-[36%] flex items-center justify-center text-[9px] h-full px-0.5 py-1 space-y-0.5 border-r border-grey">
                      <div className="flex items-center gap-1.5 pr-1">
                        <span className="">Order Status:</span>
                        <span className={`${order?.orderStatus === "InProgress" ? 'InProgress' : ''}` || `${order?.orderStatus === "paid" ? 'paid' : ''}` || `${order?.orderStatus === "Cancelled" ? 'Cancelled' : ''}` || `${order?.orderStatus === "Returned" ? 'ModeratorEarning' : ''}` || `${order?.orderStatus === "Complete" ? 'Completed' : ''}` || `${order?.shippingStatus === "Shipped" ? 'Shipped' : ''}` || `${order?.shippingStatus === "Delivered" ? 'Shipped' : ''}`}>
                          {order?.orderStatus === "InProgress" ? "Pending" : order?.orderStatus === "Cancelled" ? "Cancelled" : order?.orderStatus === "Complete" ? "Completed" : order?.orderStatus === "Returned" ? "Returned" : order?.shippingStatus === "Shipped" ? "On the Way" : order?.orderStatus === "Confirm" ? "Confirmed" : ""}
                        </span>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          ) : (
            <div className="w-full h-[50dvh] flex justify-center items-center text-3xl text-textBlack/90 font-medium">
              <span>No order found</span>
            </div>
          )}
        </>
      )}

    </div>
  );
}
