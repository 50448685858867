import { ProductMediaModel, ProductReviewCustomerRequest, ProductReviewCustomerResponse } from "../../../models/catalog/productReview/productReviewModels";
import _moment from 'moment';
import { AppDefaultProfilePicture } from "../../common/AppDefaultProfilePicture";
import { AppButton } from "../../common/AppButton";
import { useState } from "react";
import { addProductReviewService, deleteProductReviewService } from "../../../httpService/catalog/productReviewServices";
import { AppSnackBar } from "../../common/AppSnackBar";
import { AlertColor } from "@mui/material/Alert";
import { AppInput } from "../../common/AppInput";
import { AppFileInput } from "../../common/AppFileInput";
import { IoMdClose } from "react-icons/io";
import { resellingProductReviewsActions } from "../../../store/actions/catalog/resellingProductReviewActions";
import { useDispatch } from "react-redux";
import { AiFillDelete } from "react-icons/ai";
import { reactLocalStorage } from "reactjs-localstorage";
import { AppModal } from "../../common/AppModal";

interface ICustomerReviewProps {
  productReviews?: ProductReviewCustomerResponse[];
  productId?: any
}

export const AffiliateGetCustomerReviews: React.FC<ICustomerReviewProps> = ({ productReviews, productId }) => {

  const dispatch = useDispatch();

  const currentUserId = reactLocalStorage.get("UserId") as string;

  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();


  const calculateTime = (createdDateTime: any) => {
    const createdTime = _moment(createdDateTime, 'YYYY-MM-DD HH:mm:ss A');
    let formattedTime;

    if (_moment().diff(createdTime, 'hours') < 1) {
      formattedTime = createdTime.fromNow();
    } else if (_moment().diff(createdTime, 'days') < 1) {
      formattedTime = createdTime.format('h:mm A');
    } else {
      formattedTime = createdTime.format('MMM D, YYYY [at] h:mm A');
    }
    return formattedTime;
  }

  const [reviewText, setReviewText] = useState("");
  const [reviewTextError, setReviewTextError] = useState("");
  const [medias, setMedias] = useState<ProductMediaModel[]>([]);
  const [replyMedias, setReplyMedias] = useState<ProductMediaModel[]>([]);

  const [isReviewRatingSubmitButtonLoading, setIsReviewRatingSubmitButtonLoading] = useState(false);

  const handleReviewText = (value: any) => {
    setReviewText(value)
    setReviewTextError("")
  }

  const [isShowFullHight, setIsShowFullHight] = useState(false);

  const handleSaveDocumentIds = (id: any, url: any) => {
    setIsShowFullHight(true);
    let model = new ProductMediaModel();
    model.id = id;
    model.url = url;
    setMedias(_ => [..._, model])
  }
  const handleReplySaveDocumentIds = (id: any, url: any) => {
    setIsShowFullHight(true);
    let model = new ProductMediaModel();
    model.id = id;
    model.url = url;
    setReplyMedias(_ => [..._, model])
  }


  const handleReviewSubmit = () => {

    let model = new ProductReviewCustomerRequest();
    model.isParent = true;
    model.parentId = 0;
    model.productId = productId;
    model.reviewText = reviewText;
    model.mediaIds = medias?.map((_: any) => _.id)

    setIsReviewRatingSubmitButtonLoading(true)

    addProductReviewService(model)
      .then(() => {
        setIsReviewRatingSubmitButtonLoading(false)

        setIsShowSnackBar(true);
        setSnackBarMessage("Review & Ratings Submitted Successfully");
        setSnackBarMessageType("success");
        setTimeout(() => {
          setIsShowSnackBar(false);
        }, 2000);

        dispatch(resellingProductReviewsActions(productId));
        setReviewText("")
        setMedias([])
        setIsShowFullHight(false)
      })
      .catch((error: any) => {
        setIsReviewRatingSubmitButtonLoading(false);
        setReviewTextError(error.response.data.errors?.ReviewText?.[0])
      });
  };

  const [isConfirmOrderModal, setIsConfirmOrderModal] = useState(false);
  const [reviewId, setReviewId] = useState(0);

  const closeConfirmOrderModal = () => {
    setIsConfirmOrderModal(false);
  }


  const handleRemoveProductReview = (item: any) => {
    setReviewId(item.id)
    setIsConfirmOrderModal(true);
  }

  const removeProductReview = () => {
    var model = {
      id: reviewId
    }
    deleteProductReviewService(model)
      .then(() => {
        setIsShowSnackBar(true);
        setSnackBarMessage("Comment deleted successfully");
        setSnackBarMessageType("success");
        setTimeout(() => {
          setIsShowSnackBar(false);
        }, 2000);
        dispatch(resellingProductReviewsActions(productId));
        closeConfirmOrderModal();
      }).catch(() => {
        setSnackBarMessage("Comment deleted error occurred");
      })
  }
  const handleRemovePhoto = (item: any) => {
    setMedias(medias.filter(_ => _ !== item));
  }
  const handleReplyRemovePhoto = (item: any) => {
    setReplyMedias(replyMedias.filter(_ => _ !== item));
  }


  const [isRepOpen, setIsRepOpen] = useState(false);
  const [selectedRepId, setSelectedRepId] = useState(0);
  const [repText, setRepText] = useState("");
  const [repTextError, setRepTextError] = useState("");

  const handleReplyOpen = (item: any) => {
    setIsRepOpen(!isRepOpen)
    setSelectedRepId(item.id)
  }

  const handleRepText = (value: any) => {
    setRepText(value)
    setRepTextError("")
  }


  const handleSubmitReply = (item: ProductReviewCustomerResponse) => {

    if (repText === "" || repText === null || repText === undefined) {
      setRepTextError("Reply text Is Required")
    }
    else {
      let model = new ProductReviewCustomerRequest();
      model.isParent = false;
      model.parentId = item.id;
      model.productId = productId;
      model.reviewText = repText;
      model.mediaIds = replyMedias?.map((_: any) => _.id)
      setIsReviewRatingSubmitButtonLoading(true)
      addProductReviewService(model)
        .then(() => {
          setIsReviewRatingSubmitButtonLoading(false)
          setIsRepOpen(false);
          setIsShowSnackBar(true);
          setSnackBarMessage("Submitted Reply Successfully");
          setSnackBarMessageType("success");
          setTimeout(() => {
            setIsShowSnackBar(false);
          }, 2000);
          dispatch(resellingProductReviewsActions(productId));
          setRepText("")
          setReplyMedias([])
          setIsShowFullHight(false)
        })
        .catch(() => {
          setIsReviewRatingSubmitButtonLoading(false)
          setRepTextError("Comment reply submitting error occurred")
        });
    }

  }

  return (
    <div className="py-4">
      {isShowSnackBar && (
        < AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      {isConfirmOrderModal && (
        <AppModal>
          <div className='flex flex-col gap-4 text-2xl max-w-md text-center p-4'>
            Are you sure you want to Remove?
            <div className='flex items-center justify-center gap-4'>
              <AppButton text="Yes" onClick={removeProductReview} />
              <AppButton text="No" onClick={closeConfirmOrderModal} />
            </div>
          </div>
        </AppModal>
      )}


      <h2 className="title text-center">Comments</h2>
      {productReviews?.length ? (
        productReviews?.map((item: ProductReviewCustomerResponse, index: any) => {
          return (
            <div key={index}>
              <div className="flex flex-col gap-1.5 py-2">
                <div className="flex items-center gap-2 lg:gap-4">
                  <div className="">
                    <AppDefaultProfilePicture className="customer-review-image" name={item?.customerName} profilePicture={item?.customerPhotoPath} />
                  </div>
                  <div className="">
                    <h3 className="font-medium">{item?.customerName}</h3>
                    <p className="text-gray-600 text-xs">{calculateTime(item?.updatedDateTime)}</p>
                  </div>
                </div>

                <div className="ml-10 lg:ml-16">
                  <p className="flex items-center gap-1"> {item?.reviewText}
                    {(String(item?.reviewerId) === currentUserId) && (<AiFillDelete onClick={() => handleRemoveProductReview(item)} className="text-textBlack text-lg hover:text-red cursor-pointer" />)}
                  </p>

                  <div className="flex items-center gap-2 flex-wrap">
                    {item?.reviewMedias?.map((i: any, index: any) => {
                      return (
                        <img key={index} className="w-12 lg:w-20 h-12 lg:h-20 object-fill" src={i.url} alt="" />
                      )
                    })}
                  </div>
                </div>
              </div>

              {item.reviewResponseList?.map((rep: ProductReviewCustomerResponse, index: any) => {
                return (
                  <div key={index} className="flex flex-col gap-1.5 pl-4 ml-12 lg:ml-20 border-l-2 border-affiliate">
                    <div className="flex items-center gap-2 lg:gap-4">
                      <div className="">
                        <AppDefaultProfilePicture className="customer-review-image" name={rep?.customerName} profilePicture={rep?.customerPhotoPath} />
                      </div>
                      <div className="">
                        <h3 className="font-medium">{rep.customerName}</h3>
                        <p className="text-gray-600 text-xs">{calculateTime(rep.updatedDateTime)}</p>
                      </div>
                    </div>

                    <p className="ml-10 lg:ml-16">
                      {rep?.reviewText}
                      {(String(rep?.reviewerId) === currentUserId) && (<AiFillDelete onClick={() => handleRemoveProductReview(rep)} className="text-textBlack text-lg hover:text-red cursor-pointer" />)}
                    </p>

                    <div className="ml-10 lg:ml-16 flex items-center gap-2 flex-wrap">
                      {rep?.reviewMedias?.map((i: any, index: any) => {
                        return (
                          <img key={index} className="w-8 lg:w-16 h-8 lg:h-16 object-fill" src={i.url} alt="" />
                        )
                      })}
                    </div>
                  </div>
                )
              })}

              <div className="pt-4 ml-10 lg:ml-16">
                <AppButton text="Reply" onClick={() => handleReplyOpen(item)} />

                {isRepOpen && (selectedRepId === item.id) && (
                  <div className={`flex flex-col gap-2 md:px-12 py-4 md:py-2 ${isShowFullHight ? '2xl:px-0' : '2xl:px-32'}`}>
                    <p className="text-lg font-medium">Reply</p>
                    <div className="flex flex-col myBorder px-2 md:px-4 py-2">
                      <div className={`flex flex-wrap gap-2 md:gap-4 py-1`}>
                        {replyMedias && (
                          replyMedias.map((item: ProductMediaModel, index: any) => {
                            return (
                              <div key={index} className="relative w-fit h-fit pr-1">
                                <IoMdClose onClick={() => handleReplyRemovePhoto(item)} className="cursor-pointer text-affiliate absolute top-0 right-0 2xl:text-2xl text-xl font-semibold" />
                                <img
                                  className="lg:w-20 w-12 h-12 lg:h-20 object-fill rounded"
                                  src={item.url}
                                  alt=""
                                />
                              </div>
                            )
                          })
                        )}
                      </div>
                      <div className="flex items-center justify-between">
                        <AppInput
                          className="text-lg"
                          placeholder="Write your reply"
                          value={repText}
                          onChange={handleRepText}
                          errorMessage={repTextError}
                        />
                        <div className="flex items-center gap-x-4">
                          <div className="w-7 h-7 cursor-pointer">
                            <AppFileInput onSave={handleReplySaveDocumentIds} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end items-center pt-2">
                      <AppButton
                        isSubmitting={isReviewRatingSubmitButtonLoading}
                        text="Submit Reply"
                        className="affiliateBtn w-fit"
                        onClick={() => handleSubmitReply(item)}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )
        })
      ) : (
        <p className="text-center text-textGrey">No comment yet.</p>
      )}

      <div className={`flex flex-col gap-2 md:px-12 py-4 md:py-2 ${isShowFullHight ? '2xl:px-0' : '2xl:px-32'}`}>
        <p className="text-lg font-medium">Write a comment</p>
        {/* these is writing div  */}
        <div className="flex flex-col myBorder px-2 md:px-4 py-2">
          {/* these is image container  */}
          <div className={`flex flex-wrap gap-2 md:gap-4 py-1`}>
            {medias && (
              medias.map((item: ProductMediaModel, index: any) => {
                return (
                  <div key={index} className="relative w-fit h-fit pr-1">
                    <IoMdClose onClick={() => handleRemovePhoto(item)} className="cursor-pointer text-affiliate absolute top-0 right-0 2xl:text-2xl text-xl font-semibold" />
                    <img
                      className="w-12 lg:w-20 h-12 lg:h-20 object-fill rounded"
                      src={item.url}
                      alt=""
                    />
                  </div>
                )
              })
            )}
          </div>
          {/* these is input element  */}
          <div className="flex items-center justify-between">
            <AppInput
              className="text-lg"
              placeholder="Write your comment"
              value={reviewText}
              onChange={handleReviewText}
              errorMessage={reviewTextError}
            />
            {/* these is icons div  */}
            <div className="flex items-center gap-x-4">
              {/* <MdOutlineAddPhotoAlternate className="cursor-pointer text-affiliate text-xl font-semibold" /> */}
              {/* <BsEmojiSmile className="cursor-pointer text-affiliate text-xl font-semibold" /> */}
              <div className="w-7 h-7 cursor-pointer">
                <AppFileInput onSave={handleSaveDocumentIds} />
                {/* <MdAddAPhoto className="text-affiliate text-3xl font-semibold" /> */}
              </div>
              {/* <div className="w-7 h-7 cursor-pointer">
                <LuSend className=" text-affiliate text-3xl font-semibold" />
              </div> */}
            </div>
          </div>
        </div>
        <div className="flex justify-end items-center pt-2">
          <AppButton
            isSubmitting={isReviewRatingSubmitButtonLoading}
            text="Submit"
            className="affiliateBtn w-fit"
            onClick={handleReviewSubmit}
          />
        </div>
      </div>
    </div>
  );
}
