import { useNavigate, useParams } from "react-router-dom";
import { updateNoticeService } from "../../../httpService/notice/noticeServices";
import { UpdateNoticeRequest } from "../../../models/notice/noticeModels";
import { getUseRoleListAction } from "../../../store/actions/auth/address/userRoleActions";
import { getNoticeByIdAction } from "../../../store/actions/notice/noticeAction";
import { AppButton } from "../../common/AppButton";
import { AppCheckBox } from "../../common/AppCheckBox";
import { AppDescription } from "../../common/AppDescription";
import { AppInput } from "../../common/AppInput";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../../../store";
import { AppLoader } from "../../common/AppLoader";
import { AppSnackBar } from "../../common/AppSnackBar";
import { AlertColor } from "@mui/material";


export default function AdminUpdateNotice() {

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [snackBarMessage, setSnackBarMessage] = useState("");
	const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
	const [snackBarOn, setSnackBarOn] = useState(false);

	const [isButtonLoading, setIsButtonLoading] = useState(false);

	const notice = useSelector((state: RootState) => {
		return state.getNoticeByIdResponse.data;
	});

	const [selectedRoles, setSelectedRoles] = useState<number[]>(notice != null && notice.userRoles);
	const [selectedRolesError, setSelectedRolesError] = useState("");
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [titleError, setTitleError] = useState("");
	const [descriptionError, setDescriptionError] = useState("");

	const [isLoading, setIsLoading] = useState(true);

	const { id } = useParams();

	const roles = useSelector((state: RootState) => {
		return state.userRoleListResponse.userRole;
	});

	useEffect(() => {
		dispatch(getNoticeByIdAction(id));
	}, []);

	useEffect(() => {
		dispatch(getUseRoleListAction());
	}, []);

	useEffect(() => {
		if ((notice)) {
			try {
				setTitle(notice.title);
				setDescription(notice.description);
				setSelectedRoles(notice.userRoles);
			} catch {
				setIsLoading(false);
			} finally {
				setIsLoading(false);
			}
		}
	}, [notice]);

	const handleTitle = (event: any) => {
		setTitle(event);
		setTitleError("");
	};

	const handleDescription = (event: any) => {
		setDescription(event);
		setDescriptionError("");
	};

	const handleSelectedRoles = (event: number) => {
		if (selectedRoles.includes(event)) {
			const updatedSelectedRoles = [...selectedRoles];
			const roleIndex = updatedSelectedRoles.indexOf(event);
			if (roleIndex !== -1) {
				updatedSelectedRoles.splice(roleIndex, 1);
			}
			setSelectedRoles(updatedSelectedRoles);
		} else {
			setSelectedRoles([...selectedRoles, event]);
		}
	};


	const handleSubmit = (event: any) => {
		let canExecute = true;
		if (title === "" || title == null || title === undefined) {
			setTitleError("Title is Requied");
			canExecute = false;
		}

		if (description === "" || description === null || description === undefined) {
			setDescriptionError("Description is required");
			canExecute = false;
		}

		if (selectedRoles === null || selectedRoles === undefined || selectedRoles.length === 0) {
			setSelectedRolesError("Please select roles");
			canExecute = false;
		}


		if (canExecute) {
			setSnackBarOn(false);
			event.preventDefault();
			var model = new UpdateNoticeRequest();

			model.id = id;
			model.title = title;
			model.description = description;
			model.roles = selectedRoles;
			setIsButtonLoading(true);
			updateNoticeService(model)
				.then(() => {
					setSnackBarOn(true);
					setSnackBarMessage("Notice Updated Successfully.");
					setSnackBarMessageType("success");
					setIsButtonLoading(false);
					setTimeout(() => {
						navigate("/admin/notice/notice-list", { replace: true });
					}, 1000);
				})
				.catch(() => {
					setIsButtonLoading(false);
				});
		}

	};


	const isSelectedRole = (roleId: any) => {
		if (selectedRoles?.includes(roleId)) return true;
		return false;
	}

	return (
		<>
			{snackBarOn && (
				<AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
			)}
			<div className="px-2 lg:px-4 lg:pt-4">
				<AppButton
					className="myOfferBtn text-xs lg:text-sm"
					onClick={() => navigate("/admin/notice/notice-list")}
					text="Back"
				/>
			</div>

			<div className='max-w-3xl mx-auto mt-2 lg:mt-0 p-2 lg:p-4 space-y-2 shadow-md myBorder'>
				<h2 className="title text-center">Update Notice</h2>
				{isLoading ?
					(
						<AppLoader status={true} />
					) : (
						<>
							<div className='grid grid-cols-1 lg:grid-cols-1 gap-4'>
								<AppInput
									type="text"
									label="Title"
									className="myInput"
									value={title}
									onChange={handleTitle}
									errorMessage={titleError}
								/>
								<AppDescription
									value={description}
									label='Full Description'
									onChange={handleDescription}
									errorMessage={descriptionError}
								/>
							</div>
							<div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
								{roles?.filter((_: any) => _.name == "RESELLER" || _.name == "VENDOR")?.map((role: any) => (
									<div className="flex items-center gap-x-0.5 lg:gap-x-2">
										<AppCheckBox
											key={role.id}
											isChecked={isSelectedRole(role.id)}
											onChange={() => handleSelectedRoles(role.id)}
										/>

										<label className="text-sm lg:text-lg">{role.name}</label>
									</div>
								))}
								<p className="text-xs text-red pl-[2px]">{selectedRolesError}</p>
							</div>
							<div className="flex items-center justify-end">
								<AppButton isSubmitting={isButtonLoading} className="myOfferBtn" text="Submit" onClick={handleSubmit} />
							</div>
						</>
					)
				}
			</div>
		</>
	);
}