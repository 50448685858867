import { getUserRoleListService } from "../../../../httpService/user/userRoleServices";

const getUseRoleListAction = () => async (dispatch:any) => {
  try {
    const data = getUserRoleListService();
    const payload = (await data).data;
    dispatch({ type: "GET-USER-ROLE-LIST", payload: payload });
  } catch (error:any) {
  }
};

export { getUseRoleListAction };
