import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { PaginationPayloadModel } from '../../../../models/common/paginationModels';
import { RootState } from '../../../../store';
import { sSGetMainCategoryListAction } from '../../../../store/actions/superShop/sSMainCategoryActions';
import { AppLoader } from '../../../common/AppLoader';
import { AppSearchBox } from '../../../common/AppSearchBox';
import { IdNameResponseModel } from '../../../../models/common/IdNameModel';
import { SSGetSubCategoryListRequest } from '../../../../models/superShopp/catalog/sSCategory/ssSubCategoryModel';
import { SSGetProductsRequest } from '../../../../models/superShopp/catalog/sSProduct/sSProductModel';
import { SSGetProductBrandListRequest } from '../../../../models/superShopp/catalog/sSProductBrand/sSProductBrandModel';
import { getUserProfileAction } from '../../../../store/actions/auth/user/userActions';
import { sSGetProductListAction } from '../../../../store/actions/superShop/sSProductActions';
import { sSgetProductBrandListAction } from '../../../../store/actions/superShop/sSProductBrandAction';
import { sSGetSubCategoryListAction } from '../../../../store/actions/superShop/sSSubCategoryActions';
import { sSGetBazarByIdAction, sSGetBazarListAction } from '../../../../store/actions/superShop/sSBazarActions';
import { SSGetBazarListRequest } from '../../../../models/superShopp/Address/sSBazar/sSBazarModel';
import { SSProductBox } from './SSProductBox';
import { useLocation } from 'react-router';
import { debounce } from "lodash";
import { AppInput } from '../../../common/AppInput';

export default function SSProductList() {

  const { bazarId } = useParams();

  const numericBazarId = bazarId ? Number(bazarId) : NaN;

  const dispatch = useDispatch();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const mainCat = queryParams.get('mainCat') as any;
  const subCat = queryParams.get('subCat') as any;
  const brand = queryParams.get('brand') as any;
  const filter = queryParams.get('filter') as any;

  const [mainCategoryId, setMainCategoryId] = useState(0);
  const [subCategoryId, setSubCategoryId] = useState(0);
  const [productBrandId, setProductBrandId] = useState(0);
  const [ssBazarId, setSSBazarId] = useState(0);
  const [filterById, setFilterById] = useState(0);

  useEffect(() => {
    setSSBazarId(numericBazarId);
    if (mainCat > 0) setMainCategoryId(mainCat ? Number(mainCat) : NaN);
    if (subCat > 0) setSubCategoryId(subCat ? Number(subCat) : NaN);
    if (brand > 0) setProductBrandId(brand ? Number(brand) : NaN);
    if (filter > 0) setFilterById(filter ? Number(filter) : NaN);
  }, [dispatch]);

  var request = new SSGetProductsRequest();

  const productss = useSelector((state: RootState) => {
    return state.sSGetProductListResponse.data;
  });

  const [isLoading, setIsLoading] = useState(true);

  const filterOptions = [
    { name: "Total Products", id: 0 },
    { name: "Active Products", id: 1 },
    { name: "Inactive Products", id: 2 },
    { name: "Stock Out Products", id: 3 },
  ];

  const [searchQuery, setSearchQuery] = useState(localStorage.getItem("shouldMaintainPage") ? localStorage.getItem("searchQuery") ?? "" : "");
  const [currentPage, setCurrentPage] = useState(localStorage.getItem("shouldMaintainPage") ? parseInt(localStorage.getItem("currentPage") || "1", 10) : 1);
  const [pageLimit, setPageLimit] = useState(10000);

  useEffect(() => {
    localStorage.removeItem("shouldMaintainPage");
  }, []);

  const handleOptionChange = (value: any) => {
    setFilterById(value);
    setCurrentPage(1);
    request.filterBy = value;
    request.limit = pageLimit;
    request.offset = 0;
    request.searchQuery = searchQuery;
    dispatch(sSGetProductListAction(request));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setSSBazarId(numericBazarId)
        request.limit = pageLimit;
        request.offset = pageLimit * (currentPage - 1);
        request.filterBy = filterById;
        request.searchQuery = searchQuery;
        request.bazarId = numericBazarId;
        await dispatch(sSGetProductListAction(request));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  const onSearch = (query: string) => {
    const fetchData = async () => {
      try {
        setSearchQuery(query);
        setCurrentPage(1);
        setIsLoading(true);
        request.searchQuery = query;
        request.limit = pageLimit;
        request.offset = 0;
        request.filterBy = filterById;
        request.bazarId = numericBazarId;
        await dispatch(sSGetProductListAction(request));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
    fetchData();
  };

  const [products, setProducts] = useState<any>([]);

  useEffect(() => {
    setProducts(productss?.items);
  }, [dispatch, productss]);

  const userProfile = useSelector((state: RootState) => {
    return state.getUserProfileResponse.data;
  });

  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);

  const bazar = useSelector((state: RootState) => {
    return state.sSGetBazarByIdResponse.data;
  });

  useEffect(() => {
    dispatch(sSGetBazarByIdAction(numericBazarId));
  }, [dispatch]);

  const bazarList = useSelector((state: RootState) => {
    return state.sSBazarListResponse.data;
  });

  var bzrIdNameModels: IdNameResponseModel[] = [];
  bazarList.items?.map((x: { id: number; name: string | undefined }) => {
    var temp = new IdNameResponseModel();
    temp.id = x.id;
    temp.name = x.name;
    bzrIdNameModels.push(temp);
  });

  useEffect(() => {
    let paginationModel = new SSGetBazarListRequest();
    paginationModel.limit = 1000;
    paginationModel.offset = 0;
    dispatch(sSGetBazarListAction(paginationModel));
  }, []);

  var mainCatList = useSelector((state: RootState) => {
    return state.sSGetMainCategoryListResponse.data;
  });

  var mCatIdNameModels: IdNameResponseModel[] = [];
  mainCatList.items?.map((x: any) => {
    var cat = new IdNameResponseModel;
    cat.id = x.id;
    cat.name = x.name;
    mCatIdNameModels.push(cat);
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const model = new PaginationPayloadModel();
        model.limit = 10000;
        model.offset = 0;
        await dispatch(sSGetMainCategoryListAction(model));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const subCatList = useSelector((state: RootState) => {
    return state.sSGetSubCategoryListResponse.data;
  });

  var sCatIdNameModels: IdNameResponseModel[] = [];

  subCatList.items?.map((x: any) => {
    var cat = new IdNameResponseModel;
    cat.id = x.id;
    cat.name = x.name;
    sCatIdNameModels.push(cat);
  })

  useEffect(() => {
    const model = new SSGetSubCategoryListRequest();
    model.limit = 10000;
    model.offset = 0;
    model.sSMainCategoryId = mainCategoryId;
    dispatch(sSGetSubCategoryListAction(model));
  }, [mainCategoryId]);

  const pbList = useSelector((state: RootState) => {
    return state.sSProductBrandResponse.data as any;
  });

  var pbIdNameModels: IdNameResponseModel[] = [];

  pbList.items?.map((x: any) => {
    var pb = new IdNameResponseModel;
    pb.id = x.id;
    pb.name = x.name;
    pbIdNameModels.push(pb);
  })

  useEffect(() => {
    const model = new SSGetProductBrandListRequest();
    model.limit = 10;
    model.offset = 0;
    dispatch(sSgetProductBrandListAction(model));
  }, [dispatch]);

  const handleMainCategory = (mainCatId: number) => {
    const fetchData = async () => {
      try {
        setMainCategoryId(mainCatId);
        setSubCategoryId(0);
        setIsLoading(true);
        request.bazarId = ssBazarId;
        request.mainCategoryId = mainCatId;
        await dispatch(sSGetProductListAction(request));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }

  const handleSubCategory = (subCatId: number) => {
    const fetchData = async () => {
      try {
        setSubCategoryId(subCatId);
        setIsLoading(true);
        request.subCategoryId = subCatId;
        request.bazarId = ssBazarId;
        await dispatch(sSGetProductListAction(request));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }

  const handleBazarIdChange = (bId: any) => {
    const fetchData = async () => {
      try {
        setSSBazarId(bId);
        setIsLoading(true);
        request.bazarId = bId;
        request.mainCategoryId = mainCategoryId;
        request.subCategoryId = subCategoryId;
        await dispatch(sSGetProductListAction(request));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }

  const handleProductBrand = (pbId: any) => {
    const fetchData = async () => {
      try {
        setProductBrandId(pbId);
        setIsLoading(true);
        request.productBrandId = pbId;
        request.bazarId = ssBazarId;
        request.mainCategoryId = mainCategoryId;
        request.subCategoryId = subCategoryId;
        await dispatch(sSGetProductListAction(request));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }

  return (
    <div className='container mx-auto items-center 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28 '>
      {userProfile?.isBlocked && <h2 className="text-xl text-red md:text-3xl font-semibold">Your Account has been Suspended. Please contact with Admin.</h2>}
      {/* these is home top section  */}
      <div className="flex gap-2 lg:gap-4 h-[16vh] lg:h-[50vh] box-border">
        <img
          src="/assets/SuperShop/SS.png"
          className="w-full rounded lg:rounded-xl object-fill"
          alt="These is home banner"
          loading="lazy"
        />
      </div>
      <h2 className="title text-center py-6">অ্যাপে নাই এমন প্রোডাক্ট অর্ডার করতে নিচের নাম্বারে কল করুন: ({bazar?.phoneNumber})</h2>

      <div className="flex flex-col justify-between gap-2 pb-2 pt-0.5 lg:flex-row lg:items-center lg:gap-4">

        <AppInput
          className="myInput text-sm lg:text-base input-design always"
          value={searchQuery}
          onChange={onSearch}
          placeholder="Search product Here"
        />
        {/* <div>
          <AppSelect
            defaultValue="Select Bazar"
            value={ssBazarId as number}
            data={bzrIdNameModels}
            onChange={handleBazarIdChange}
          />
        </div>
        <div>
          <AppSelect
            defaultValue="Select Main Category"
            value={mainCategoryId}
            data={mCatIdNameModels}
            onChange={handleMainCategory}
          />
        </div>
        {(mainCategoryId !== 0) &&
          <div>
            <AppSelect
              defaultValue="Select Sub Category "
              value={subCategoryId}
              data={sCatIdNameModels}
              onChange={handleSubCategory}
            />
          </div>
        }
        <div>
          <AppSelect
            defaultValue="Select Product Brand"
            value={productBrandId}
            data={pbIdNameModels}
            onChange={handleProductBrand}
          />
        </div>
        <div className="w-full lg:w-1/4">
          <AppSelect
            defaultValue="Filter Products"
            data={filterOptions}
            value={filterById}
            onChange={handleOptionChange}
          />
        </div> */}
      </div>
      {isLoading ? (
        <AppLoader status={true} />
      ) : (
        <>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-2 md:gap-4 lg:gap-6">
            {products &&
              (
                products?.map((product: any, index: any) =>
                  <SSProductBox key={index}
                    id={product?.id}
                    name={product?.name}
                    bannerPath={product?.photoPath}
                    price={product?.price}
                    displayPrice={product?.displayPrice}
                    oldPrice={product?.oldPrice}
                    totalSold={product?.totalSold}
                    isDisountProduct={product?.isDisountProduct}
                    discountText={product?.discountText}
                    totalReview={product?.totalReview}
                    totalView={product?.totalView}
                    totalFavourite={product?.totalFavourite}
                  />
                )
              )}
          </div>
        </>
      )}
    </div>
  );
}
