import { PaginationPayloadModel } from "../../common/paginationModels";

export class SSCreateRiderRequest {
    firstName?: string;
    lastName?: string;
    emailOrPhone?: string;
    password?: any;
    confirmPassword?: any;
    bazarId?: any;
}

export class SSActiveInActiveRiderRequest {
    riderId?: number;
}

export class SSEnableDisableRiderRequest {
    riderId?: number;
}

export class SSGetRiderByIdRequest {
    riderId?: number;
}

export class SSGetRiderListRequest extends PaginationPayloadModel {
    bazarId?: number = 0;
}

export class SSRemoveRiderRequest {
    riderId?: number;
}

export class SSUpdateRiderRequest {
    riderId?: number;
    firstName?: string;
    lastName?: string;
    emailOrPhone?: string;
}