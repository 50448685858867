import { useState } from "react";
import { AppInput } from "../../common/AppInput";
import { AppCheckBox } from "../../common/AppCheckBox";
import { AppButton } from "../../common/AppButton";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { addToCardOrFavouriteService } from "../../../httpService/shoppingCart/resellingCartServices";
import { SignalRService } from "../../../signalR/SignalRServices";
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { AlertColor } from "@mui/material/Alert";
import { AppSnackBar } from "../../common/AppSnackBar";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { SignInModel } from "../../../models/auth/user/UserModels";
import { signInService } from "../../../httpService/user/userServices";
import { ShoppingCartFavouriteModel, ShoppingCartFavouriteRequest } from "../../../models/shoppingCart/resellingCartModels";

const signalRService = new SignalRService();

export default function SignInMobile() {
  const navigate = useNavigate();

  const navigateToSignUp = () => {
    navigate("/signUp", { replace: true });
  };

  const ip = reactLocalStorage.get("ip") as string;
  const ref = reactLocalStorage.get("resellRef") as string;


  const signIn = useSelector((state: RootState) => {
    return state.signInModalResponse.signIn;
  });

  const dispatch = useDispatch();

  const closeSignIn = () => {
    dispatch({ type: "CLOSE-SIGN-IN" });
  };

  const openSignUp = () => {
    dispatch({ type: "CLOSE-SIGN-IN" });
    dispatch({ type: "OPEN-SIGN-UP" });
  };

  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const [emailOrPhoneError, setEmailOrPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handleEmailOrPhoneChange = (event: any) => {
    setEmailOrPhone(event);
    setEmailOrPhoneError("")
  };

  const handlePasswordChange = (event: any) => {
    setPassword(event);
    setEmailOrPhoneError("")
  };

  const handleRememberMeChange = (event: any) => {
    setRememberMe(event);
  };

  const userProfile = useSelector((state: RootState) => {
    return state.getUserProfileResponse.data;
  });

  const [isSignInButtonLoading, setSignInButtonLoading] = useState(false);

  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();


  const handleSubmit = (event: any) => {
    event.preventDefault()
    const data = new SignInModel();

    data.emailOrPhone = emailOrPhone;
    data.password = password;
    data.rememberMe = rememberMe;
    data.ReferrerId = ref;

    setSignInButtonLoading(true)

    signInService(data)
      .then((res:any) => {

        setSignInButtonLoading(false)

        signalRService.startConnection();

        reactLocalStorage.setObject("SignIn", res.data);

        const decodedToken = jwt_decode(res.data?.accessToken) as CustomJwtPayload;

        let role = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
        let userId = decodedToken['sub'] as string;

        reactLocalStorage.set("RoleName", role);

        reactLocalStorage.set("UserId", userId);

        navigate("/", { replace: true });

        var cartItems: ShoppingCartFavouriteModel[] = [];

        var pItems = reactLocalStorage.getObject(
          "CartItems"
        ) as ShoppingCartFavouriteModel[];

        if (
          pItems !== undefined &&
          pItems !== null &&
          pItems?.length &&
          pItems
        ) {
          pItems?.map((x: any) => {
            cartItems.push(x);
          });
        }

        var req = new ShoppingCartFavouriteRequest();
        req.customerIp = ip;
        req.shoppingCartType = 1;
        req.cartItems = cartItems;

        addToCardOrFavouriteService(req)
          .then(() => { })
          .catch(() => { });

        var favouriteItems: ShoppingCartFavouriteModel[] = [];

        var fItems = reactLocalStorage.getObject(
          "FavouriteItems"
        ) as ShoppingCartFavouriteModel[];

        if (
          fItems !== undefined &&
          fItems !== null &&
          fItems?.length &&
          fItems
        ) {
          fItems?.map((x: any) => {
            favouriteItems.push(x);
          });
        }

        var req = new ShoppingCartFavouriteRequest();
        req.customerIp = ip;
        req.shoppingCartType = 2;
        req.cartItems = favouriteItems;

        addToCardOrFavouriteService(req)
          .then((res) => { })
          .catch(() => { });

        reactLocalStorage.remove("CartItems");
        reactLocalStorage.remove("FavouriteItems");
      })
      .catch(() => {
        setSignInButtonLoading(false)
        setEmailOrPhoneError("Wrong Email/Phone or Password");
        
      });
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const naviagteToVendor = () => {
    navigate("/vendorSignUp", { replace: true });
  }


  return (
    <div className="container mx-auto py-16">
      {isShowSnackBar && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      {/* these is main container section  */}
      <div className="w-full md:w-2/5 rounded mx-auto md:shadow-xl">
        <h2 className="subTitle text-center text-textBlack">Sign in to your Account</h2>
        <p className="text-sm text-gray-700 text-center pt-1">Welcome  to হ্যালোবাজার, please enter your details.</p>

        <div className="flex flex-col px-4 py-6">
          <AppInput
            type="email"
            label="Email Or Phone*"
            className="myInput"
            placeholder="Enter your Email Or Phone"
            onChange={handleEmailOrPhoneChange}
            errorMessage={emailOrPhoneError}
          />
          <div className="relative pt-2 pb-1">
            <AppInput
              type={showPassword ? "text" : "password"}
              label="Password*"
              className="myInput"
              placeholder="Enter your Password"
              value={password}
              onChange={handlePasswordChange}
            />
            {showPassword ? (
              <AiOutlineEyeInvisible
                className="absolute text-2xl text-gray-700 font-bold cursor-pointer right-3 top-9 lg:top-10"
                onClick={togglePasswordVisibility}
              />
            ) : (
              <AiOutlineEye
                className="absolute text-2xl text-gray-700 font-bold cursor-pointer right-3 top-9 lg:top-10"
                onClick={togglePasswordVisibility}
              />
            )}
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center gap-x-1">
              <AppCheckBox onChange={() => handleRememberMeChange(!rememberMe)} />
              <p className="text-sm text-gray-600">Remember me</p>
            </div>

            <Link to={"/resetPassEmail"} onClick={closeSignIn}>
              <p className="text-sm font-medium text-royalBlue underline">Forgot Password?</p>
            </Link>
          </div>
          <AppButton
            className="myBtn mt-2 mb-3"
            onClick={handleSubmit}
            text="Sign In"
            isSubmitting={isSignInButtonLoading}
          />
          <div className='flex justify-end'>
            <Link to="/privacyPolicy" className="text-sm underline underline-offset-1 text-royalBlue">Privacy Policy</Link>
          </div>
          <div className='flex items-center justify-between gap-4 lg:px-8 pt-28 lg:pt-2'>
            {/* these is sign up button  */}
            <div className="flex flex-col justify-center text-center items-center gap-2">
              <p className="text-gray-700">একাউন্ট নেই?</p> <Link to="/signUp" className="myBtn px-[22px] bg-royalBlue border-royalBlue cursor-pointer">Sign Up</Link>
            </div>
            {/* these is vendor button  */}
            <div className="flex flex-col justify-center text-center items-center gap-2">
              <p className="text-gray-700">ভেন্ডর হতে চান?</p> <Link to="/vendorSignUp" className="myBtn cursor-pointer">Click here</Link>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

interface CustomJwtPayload extends JwtPayload {
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/role': string;
}