import { Link } from "react-router-dom";

const AboutHelloBazar = () => {
  return (
    <div className="mx-auto max-w-5xl space-y-1 px-4 py-12">
      <p className="pb-2 text-xl font-semibold">হ্যালোবাজার (Hellobazar).</p>
      <p>
        হাতের মুঠোয় বাজার!!! হ্যালোবাজার এর মূল উদ্দেশ্য হল, দেশের যেকোনো
        বাজারকে আপানার হাতের মুঠোয় এনে দেওয়া। আপনি হ্যালোবাজার অ্যাপের মাধ্যমে
        পেয়ে যাচ্ছেন আপনার নিজ এলাকার বাজার একেবারে আপনার হাতের মুঠোয়, যেকোনো
        জায়গায় বসে। পাশাপাশি অ্যাপের মাধ্যমে পাবেন আরও অনেক গুলো দরকারী সার্ভিস।
      </p>
      <p>
        সেগুলো হলঃ ইমার্জেন্সি বাজার, রেস্টুরেন্ট সার্ভিস, মূল্যছাড় প্রোডাক্ট,
        ফ্রী প্রোডাক্ট, প্রাইভেট প্রোডাক্ট, জরুরি বিজ্ঞপ্তি, অফার স্টোর, বেচতে
        চাই, ব্লাড ক্লাব, এডুকেশন ক্লাব, রিসেলিং প্লাটফর্ম। এছাড়াও দেশের বিভিন্ন
        স্থান থেকে আরও অনেক সার্ভিস পেয়ে যাবেন যেকোনো জায়গায় বসে। ডাউনলোড করুন
        আমাদের অ্যাপ। প্লেস্টোর এ গিয়ে সার্চ দিন Hellobazar লিখে আর উপভোগ করুন
        একটি মাত্র অ্যাপে অনেক গুলো দরকারী সার্ভিস। অ্যাপের ভিতরেই ব্যবহার করার
        নিয়ম দেওয়া আছে।
      </p>
      <Link
        target="_blank"
        to="https://play.google.com/store/apps/details?id=com.hellobazar.com"
        className="cursor-pointer"
      >
        <img
          className="mt-4 w-32 object-contain"
          src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/googleplay_g9y89j.png"
          alt="google play store"
        />
      </Link>
    </div>
  );
};

export default AboutHelloBazar;
