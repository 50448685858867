import { GET } from "../service";

export const getVendorRequestListServices = (payload: any) => {
  if (payload) {
    return GET(`/api/Users/GetVendorRequest?limit=${payload?.limit}&offset=${payload?.offset}${payload?.searchQuery ? "&searchQuery=" + payload.searchQuery : ""}&userRole=${payload.userRole}`
    );
  } else {
    return GET(`/api/Users/GetVendorRequest`);
  }
};
