import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetailsAction } from "../../../../store/actions/auth/address/userActions";
import { RootState } from "../../../../store";
import { AppDefaultProfilePicture } from "../../../common/AppDefaultProfilePicture";
import { AppModal } from "../../../common/AppModal";
import { AppButton } from "../../../common/AppButton";
import { AddCountryZilaUpazilaAdminRequest } from "../../../../models/auth/user/UserModels";
import { addCountryZilaUpazilaAdmin, blockUnBlockUserService, createUpazilaAdminService, removeUpazilaAdminService } from "../../../../httpService/user/userServices";
import { AlertColor } from "@mui/material/Alert";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { isAdmin, isDataOfferVendor, isModerator, isUpazilaAdmin, isVendor } from "../../../../common/common";

interface IProps {
	id?: any;
}

export const AdminGetUserById: React.FC<IProps> = ({ id }) => {

	const dispatch = useDispatch();

	const user = useSelector((state: RootState) => {
		return state.userDetailsResponse.data;
	});

	useEffect(() => {
		dispatch(getUserDetailsAction(id));
	}, [dispatch]);


	const [snackBarMessage, setsnackBarMessage] = useState('');
	const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
	const [snackBarOn, setSnackBarOn] = useState(false);

	const [roleId, setRoleId] = useState(0);
	const [isButtonLoading, setIsButtonLoading] = useState(false);
	const [openUserRoleConfirmationModal, setOpenUserRoleConfirmationModal] = useState(false);


	const closeUserRoleConfirmationModal = () => {
		setOpenUserRoleConfirmationModal(false)
	}

	const handleSubmit = () => {
		var model = new AddCountryZilaUpazilaAdminRequest();
		model.userId = id;
		model.roleId = roleId;
		setIsButtonLoading(true);
		addCountryZilaUpazilaAdmin(model)
			.then((res: any) => {
				setIsButtonLoading(false);
				setSnackBarOn(true)
				setsnackBarMessage(res.data)
				setSnackBarMessageType('success')
				dispatch(getUserDetailsAction(id));
				closeUserRoleConfirmationModal();
			})
			.catch((error: any) => {
				setIsButtonLoading(false);
				setSnackBarOn(true)
				setsnackBarMessage(error.message)
				setSnackBarMessageType('error')
				dispatch(getUserDetailsAction(id));
				closeUserRoleConfirmationModal();
			})
	}

	const handleMakeUpazilaAdmin = () => {
		var model = { userId: id };
		setIsButtonLoading(true);
		createUpazilaAdminService(model)
			.then((res: any) => {
				setIsButtonLoading(false);
				setSnackBarOn(true)
				setsnackBarMessage("Successfully Created Upazila Admin")
				setSnackBarMessageType('success')
				dispatch(getUserDetailsAction(id));
				closeUserRoleConfirmationModal();
			})
			.catch((error: any) => {
				setIsButtonLoading(false);
				setSnackBarOn(true)
				setsnackBarMessage("Something wrong, May be upazila Admin Already Exist")
				setSnackBarMessageType('error')
				dispatch(getUserDetailsAction(id));
				closeUserRoleConfirmationModal();
			})
	}


	const handleRemoveUpazilaAdmin = () => {
		var model = { userId: id };
		setIsButtonLoading(true);
		removeUpazilaAdminService(model)
			.then((res: any) => {
				setIsButtonLoading(false);
				setSnackBarOn(true)
				setsnackBarMessage("Successfully Removed Upazila Admin")
				setSnackBarMessageType('success')
				dispatch(getUserDetailsAction(id));
				closeUserRoleConfirmationModal();
			})
			.catch((error: any) => {
				setIsButtonLoading(false);
				setSnackBarOn(true)
				setsnackBarMessage("Something wrong, May be upazila Admin Already Exist")
				setSnackBarMessageType('error')
				dispatch(getUserDetailsAction(id));
				closeUserRoleConfirmationModal();
			})
	}

	const handleBlockUnblockUser = () => {
		var model = { userId: id };
		setIsButtonLoading(true);
		blockUnBlockUserService(model)
			.then((res: any) => {
				setIsButtonLoading(false);
				setSnackBarOn(true)
				setsnackBarMessage("Successfully blocked user")
				setSnackBarMessageType('success')
				dispatch(getUserDetailsAction(id));
				closeUserRoleConfirmationModal();
			})
			.catch((error: any) => {
				setIsButtonLoading(false);
				setSnackBarOn(true)
				setsnackBarMessage("Something wrong")
				setSnackBarMessageType('error')
				dispatch(getUserDetailsAction(id));
				closeUserRoleConfirmationModal();
			})
	}


	return (
		<div className="container mx-auto overflow-y-auto">
			{snackBarOn &&
				<AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
			}
			{openUserRoleConfirmationModal && (
				<AppModal>
					<div className='flex flex-col gap-4 text-2xl max-w-md text-center p-4'>
						Are you sure you want to Change this user to {roleId == 9 ? "Zila Admin" : roleId == 10 ? "UpaZila Admin" : "Country Admin"}?
						<div className='flex items-center justify-center gap-4'>
							<AppButton isSubmitting={isButtonLoading} text="Yes" onClick={handleSubmit} />
							<AppButton isSubmitting={isButtonLoading} text="No" onClick={closeUserRoleConfirmationModal} />
						</div>
					</div>
				</AppModal>
			)}

			<div className='px-2 py-2'>
				<div className='flex items-end justify-between'>
					<div className="relative bg-white rounded-full">
						<AppDefaultProfilePicture className="profile-image-header" name={user?.firstName} profilePicture={user?.ProfilePictureUrl} />
					</div>

					{!isVendor(user?.userRoles?.map((x: any) => x.name)) && !isAdmin(user?.userRoles?.map((x: any) => x.name)) && !isModerator(user?.userRoles?.map((x: any) => x.name)) && !isDataOfferVendor(user?.userRoles?.map((x: any) => x.name)) && (
						<>
							{isUpazilaAdmin(user?.userRoles?.map((x: any) => x.name)) ? (
								<div className=''>
									<AppButton text="Remove Upazila Admin" isSubmitting={isButtonLoading} onClick={handleRemoveUpazilaAdmin} />
								</div>
							) : (
								<div className=''>
									<AppButton text="Make Upazila Admin" isSubmitting={isButtonLoading} onClick={handleMakeUpazilaAdmin} />
								</div>
							)}
						</>
					)}
					
					{user?.isBlocked === "Yes" ? (<div className=''>
						<AppButton text="UnBlock this user" isSubmitting={isButtonLoading} onClick={handleBlockUnblockUser} />
					</div>) : (<div className=''>
						<AppButton text="Block this user" isSubmitting={isButtonLoading} onClick={handleBlockUnblockUser} />
					</div>)}

				</div>
				<div className='h-[70vh] overflow-y-auto lg:h-auto grid grid-cols-1 lg:grid-cols-3 gap-1 lg:gap-x-8 lg:gap-y-2 lg:gap-2 mt-2 lg:mt-4 myBorder p-2 lg:p-4 font-medium'>
					<div className='myBorder px-2 lg:px-4 py-1 w-full'>
						<h3>Name: <span>{user?.firstName}</span></h3>
					</div>
					<div className='myBorder px-2 lg:px-4 py-1 w-full'>
						<p>Email: <span className="font-medium"> {user?.email}</span></p>
					</div>
					<div className='myBorder px-2 lg:px-4 py-1 w-full'>
						<p>Phone Number: <span className="font-medium"> {user?.phoneNumber}</span></p>
					</div>
					<div className='myBorder px-2 lg:px-4 py-1 w-full'>
						<p>Gender: <span className="font-medium"> {user?.genderName}</span></p>
					</div>
					<div className='myBorder px-2 lg:px-4 py-1 w-full'>
						<p>Blood Group: <span className="font-medium"> {user?.bloodGroup}</span></p>
					</div>
					<div className='myBorder px-2 lg:px-4 py-1 w-full'>
						<p>Address: <span className="font-medium"> {user?.userAddress}</span></p>
					</div>
					<div className='myBorder px-2 lg:px-4 py-1 w-full'>
						<p>Area: <span className="font-medium"> {user?.areaName}</span></p>
					</div>
					<div className='myBorder px-2 lg:px-4 py-1 w-full'>
						<p>Division: <span className="font-medium"> {user?.divisionName}</span></p>
					</div>
					<div className='myBorder px-2 lg:px-4 py-1 w-full'>
						<p>District: <span className="font-medium"> {user?.districtName}</span></p>
					</div>
					<div className='myBorder px-2 lg:px-4 py-1 w-full'>
						<p>User Role: <span className="font-medium"> {user?.userRoles?.map((x: any) => x.name).join(', ')}</span></p>
					</div>
					<div className='myBorder px-2 lg:px-4 py-1 w-full'>
						<p>NID: <span className="font-medium"> {user?.nidNumber}</span></p>
					</div>
					<div className='myBorder px-2 lg:px-4 py-1 w-full'>
						<p>Bkash: <span className="font-medium"> {user?.bkashNumber}</span></p>
					</div>
					<div className='myBorder px-2 lg:px-4 py-1 w-full'>
						<p>Nagad: <span className="font-medium"> {user?.nagadNumber}</span></p>
					</div>
					<div className='myBorder px-2 lg:px-4 py-1 w-full'>
						<p>Rocket: <span className="font-medium"> {user?.rocketNumber}</span></p>
					</div>
					<div className='myBorder px-2 lg:px-4 py-1 w-full'>
						<p>Bank Name: <span className="font-medium"> {user?.bankName}</span></p>
					</div>
					<div className='myBorder px-2 lg:px-4 py-1 w-full'>
						<p>Bank Account Number: <span className="font-medium"> {user?.bankAccountNumber}</span></p>
					</div>
					<div className='myBorder px-2 lg:px-4 py-1 w-full'>
						<p>Facebook Link: <span className="font-medium"> {user?.faceBookLink}</span></p>
					</div>
					<div className='myBorder px-2 lg:px-4 py-1 w-full'>
						<p>Instagram Link: <span className="font-medium"> {user?.instagramLink}</span></p>
					</div>
					<div className='myBorder px-2 lg:px-4 py-1 w-full'>
						<p>Whatsapp Number: <span className="font-medium"> {user?.whatsAppNumber}</span></p>
					</div>
					<div className='myBorder px-2 lg:px-4 py-1 w-full'>
						<p>Twitter Link: <span className="font-medium"> {user?.twitterLink}</span></p>
					</div>
				</div>
			</div>
		</div>
	);
}
