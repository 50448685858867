import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTrainingListAction } from "../../../store/actions/training/trainingAction";
import { RootState } from "../../../store";
import { reactLocalStorage } from "reactjs-localstorage";
import { useNavigate } from "react-router";
import { TbCurrencyTaka } from "react-icons/tb";
import { Link } from "react-router-dom";
import { AppModal } from "../../common/AppModal";
import { getIsAreaSavedAction } from "../../../store/actions/auth/address/userActions";
import { getUserWalletAction } from "../../../store/actions/auth/user/userActions";
import { AppInput } from "../../common/AppInput";
import {
  verifyAffiliateReferrerService,
  verifyReferrerService,
} from "../../../httpService/training/trainingService";

export default function TrainingGetTrainingList() {
  const dispatch = useDispatch();

  const [verified, setVerified] = useState(false);
  const [verifiedAffiliate, setVerifiedAffiliate] = useState(false);
  const [referrerName, setReferrerName] = useState("");
  const [referrer, setReferrer] = useState("");
  const [referrerErrorMessage, setReferrerErrorMessage] = useState("");
  const [affiliateReferrerName, setAffiliateReferrerName] = useState("");
  const [affiliateReferrer, setAffiliateReferrer] = useState("");
  const [affiliateReferrerErrorMessage, setAffiliateReferrerErrorMessage] =
    useState("");

  var token = reactLocalStorage.getObject("SignIn");

  const navigate = useNavigate();

  const trainingList = useSelector((state: RootState) => {
    return state.getTrainingListResponse.data;
  });

  useEffect(() => {
    dispatch(getTrainingListAction());
  }, [dispatch]);

  const isAreaSaved = useSelector((state: RootState) => {
    return state.getAreaSavedResponse.data;
  });

  useEffect(() => {
    dispatch(getIsAreaSavedAction());
  }, [dispatch, token && JSON.stringify(token) !== JSON.stringify({})]);

  const navigateToMyTraining = (id: any) => {
    if (token && JSON.stringify(token) !== JSON.stringify({})) {
      navigate(`/trainingPlaylist/${id}`);
    } else {
      dispatch({ type: "OPEN-SIGN-IN" });
      dispatch({ type: "CLOSE-SIGN-UP" });
    }
  };

  const navigateToTrainingPaymentPage = (id: any) => {
    if (token && JSON.stringify(token) !== JSON.stringify({})) {
      navigate(`/trainingPayment/${id}`);
    } else {
      dispatch({ type: "OPEN-SIGN-IN" });
      dispatch({ type: "CLOSE-SIGN-UP" });
    }
  };

  useEffect(() => {
    dispatch(getUserWalletAction(0));
  }, [dispatch]);

  const handleReferrerChange = (event: any) => {
    setReferrer(event);
    setReferrerErrorMessage("");
  };
  const handleAffiliateReferrerChange = (event: any) => {
    setAffiliateReferrer(event);
    setAffiliateReferrerErrorMessage("");
  };

  const verifyReferrer = () => {
    verifyReferrerService(referrer)
      .then((response: any) => {
        if (response?.data?.verified) {
          setVerified(true);
          setReferrerErrorMessage("");
          setReferrerName(
            `${response?.data?.firstName} ${response?.data?.lastName}`
          );
          reactLocalStorage.remove("resellRef");
          reactLocalStorage.set("resellRef", response?.data?.id);
        } else {
          setVerified(false);
          setReferrer("");
          setReferrerErrorMessage("Not a valid referrer. Please recheck!");
        }
      })
      .catch((error: any) => {
        console.log(error);
        setVerified(false);
        setReferrer("");
        setReferrerErrorMessage("Not a valid referrer. Please recheck!");
      });
  };

  const verifyAffiliateReferrer = () => {
    verifyAffiliateReferrerService(affiliateReferrer)
      .then((response: any) => {
        if (response?.data?.verified) {
          setVerifiedAffiliate(true);
          setAffiliateReferrerErrorMessage("");
          setAffiliateReferrerName(
            `${response?.data?.firstName} ${response?.data?.lastName}`
          );
          reactLocalStorage.remove("affiliateRef");
          reactLocalStorage.set("affiliateRef", response?.data?.id);
        } else {
          setVerifiedAffiliate(false);
          setAffiliateReferrer("");
          setAffiliateReferrerErrorMessage(
            "Not a valid referrer. Please recheck!"
          );
        }
      })
      .catch((error: any) => {
        console.log(error);
        setVerifiedAffiliate(false);
        setAffiliateReferrer("");
        setAffiliateReferrerErrorMessage(
          "Not a valid referrer. Please recheck!"
        );
      });
  };

  return (
    <div className="container mx-auto pb-20 pt-6 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28">
      {!isAreaSaved && (
        <AppModal>
          <div className="flex flex-col-reverse items-center justify-between gap-4 px-4 py-12 md:flex-row md:px-20">
            <div className="flex flex-col items-center gap-3 md:items-start">
              <h2 className="text-xl font-semibold text-royalBlue md:max-w-md md:text-3xl">
                Please save your Division District and Thana First
              </h2>
              <div
                className="myBtn w-fit border-royalBlue bg-royalBlue py-1.5"
                onClick={() => { }}
              >
                <Link to="/myProfile">Go to Profile</Link>
              </div>
            </div>
            <img
              className="h-40 w-60 object-contain md:h-48 md:w-72"
              src="https://res.cloudinary.com/ddqenwff0/image/upload/v1687276368/Group_cmdbqn.png"
              alt=""
            />
          </div>
        </AppModal>
      )}

      <div className="mx-auto grid grid-cols-1 gap-7 px-4 lg:w-3/4 lg:grid-cols-2 2xl:grid-cols-2">
        {trainingList.length > 0 &&
          trainingList?.map((training: any, index: any) => {
            return (
              <div
                key={training.id}
                className="flex flex-col gap-y-6 rounded-b border-2 border-slate-50 pb-5 duration-150 hover:bg-slate-50"
              >
                {/* <video
                  controls
                  className="h-[250px] rounded-t object-cover object-top"
                >
                  <source src={training.videoUrl} type="video/mp4" />
                </video> */}
                <iframe
                  title={training?.trainingName}
                  src={training.videoUrl}
                  className="w-full h-[25dvh] md:h-[42dvh]"
                  width="100%"
                  height="305"
                ></iframe>
                <div className=" px-5">
                  <h3 className="subTitle font-semibold">
                    {training.trainingName}
                  </h3>
                  <div className="flex justify-between w-full py-1 md:pt-2 md:pb-4">
                    <div>
                      <h3 className="subTitle flex items-center font-semibold">
                        BDT <TbCurrencyTaka />
                        {training.price}
                      </h3>
                      <p className="text-sm text-black/50">
                        {training.totalTrainingSold} sold
                      </p>
                    </div>
                    <div>
                      {training.isPurchased ? (
                        <div
                          onClick={() => navigateToMyTraining(training.id)}
                          className="myBtn"
                        >
                          Go to Training
                        </div>
                      ) : (
                        <>
                          {training.id === 1 && (
                            <>
                              {verified ? (
                                <>
                                  {verified ? (
                                    <div>
                                      <p>
                                        <strong>Referrer: </strong>
                                        {referrerName}
                                      </p>
                                    </div>
                                  ) : null}
                                  <div
                                    onClick={() =>
                                      navigateToTrainingPaymentPage(training.id)
                                    }
                                    className="myBtn border-royalBlue bg-royalBlue"
                                  >
                                    Buy Training
                                  </div>
                                </>
                              ) : (
                                <div className="space-y-1">
                                  <AppInput
                                    label=""
                                    className="myInput"
                                    type="text"
                                    placeholder="Enter Referrer Number"
                                    onChange={handleReferrerChange}
                                    errorMessage={referrerErrorMessage}
                                    value={referrer}
                                  />
                                  <div
                                    onClick={verifyReferrer}
                                    className="myBtn border-royalBlue bg-royalBlue"
                                  >
                                    Verify Referrer
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                          {training.id === 2 && (
                            <>
                              {verifiedAffiliate ? (
                                <>
                                  {verifiedAffiliate ? (
                                    <div>
                                      <p>
                                        <strong>Referrer: </strong>
                                        {affiliateReferrerName}
                                      </p>
                                    </div>
                                  ) : null}
                                  <div
                                    onClick={() =>
                                      navigateToTrainingPaymentPage(training.id)
                                    }
                                    className="myBtn border-royalBlue bg-royalBlue"
                                  >
                                    Buy Training
                                  </div>
                                </>
                              ) : (
                                <div className="space-y-1 ml-auto w-3/5">
                                  <AppInput
                                    label=""
                                    className="myInput"
                                    type="text"
                                    placeholder="Enter Affiliate Referrer Id"
                                    onChange={handleAffiliateReferrerChange}
                                    errorMessage={affiliateReferrerErrorMessage}
                                    value={affiliateReferrer}
                                  />
                                  <div className=''>
                                    <div className="flex justify-between">
                                      <div
                                        onClick={verifyAffiliateReferrer}
                                        className="myBtn border-royalBlue bg-royalBlue"
                                      >
                                        Verify
                                      </div>
                                      <div
                                        onClick={() =>
                                          navigateToTrainingPaymentPage(training.id)
                                        }
                                        className="myBtn border-royalBlue bg-royalBlue"
                                      >
                                        Skip
                                      </div>
                                    </div>
                                    <p className="text-rose-500 text-center text-xs"> আপনি Skip  বাটন এ ক্লিক করলে, কোম্পানি আপনার রেফারার হিসাবে নিয়োগ করা হবে. </p>
                                  </div>

                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div>
                    <h3 className="subTitle text-base font-semibold">
                      সতর্কতা বানীঃ
                    </h3>
                    <p className="text">{training.trainingDescription}</p>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
