import { SSGET_RIDER_BY_ID, SSGET_RIDER_LIST } from "../../constants/superShop/ssRiderConstants";

const inititalState = {
    data: [],
    dataById: []
}

export function sSGetRiderListReducer(state = inititalState, action: any) {
    switch (action.type) {
        case SSGET_RIDER_LIST:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

export function sSGetRiderByIdReducer(state = { data: {} }, action: any) {
	switch (action.type) {
		case SSGET_RIDER_BY_ID:
			return { loading: false, data: action.payload };
		default:
			return state;
	}
}