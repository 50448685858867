import { Link } from "react-router-dom";
import { TbCurrencyTaka } from "react-icons/tb";
import { AiFillEye, AiFillHeart } from "react-icons/ai";
import { MdReviews } from "react-icons/md";
import { HiShoppingCart } from "react-icons/hi";

interface IInputProps {
  id?: any;
  name?: string;
  shortDescription?: string;
  price?: number;
  oldPrice?: number;
  discount?: string;
  bannerPath?: any;
  isBestSeller?: boolean;
  totalSold?: number;
  totalReview?: number;
  totalView?: number;
  totalFavourite?: number;
  rating?: number;
  afterAddToCart?: any;
  isDisountProduct?: any;
  discountText?: any
}

export const AffiliateProductBox: React.FC<IInputProps> = ({
  id,
  name,
  shortDescription,
  price = 1,
  oldPrice = 1,
  discount,
  bannerPath,
  isBestSeller,
  totalSold,
  totalReview,
  totalView,
  rating,
  totalFavourite,
  afterAddToCart,
  isDisountProduct,
  discountText
}) => {
  return (
    <div className="relative w-full h-[200px] md:h-[360px] bg-white rounded shadow-md cursor-pointer hover:shadow-xl">
      <Link to={`/affiliate/productDetails/${id}`}>
        <div className="rounded-t h-[136px] md:h-[260px] border border-gray-100">
          <img className="rounded-t" src={bannerPath} alt="Product Pic" loading="lazy" />
        </div>

        <div className="absolute w-full h-[74px] md:h-[115px] overflow-hidden border border-grey bottom-0 rounded bg-white md:p-4 px-1 py-1.5 flex flex-col justify-between">

          <div className="flex justify-between items-center md:items-center flex-row md:flex-row">
            <div className="flex items-center gap-x-1 lg:gap-x-3">
              <div className="flex items-center">
                <TbCurrencyTaka className="text-sm md:text-sm lg:text-base" />
                <span className="productPrice">{price}</span>
              </div>
              <div className="flex items-center line-through">
                <TbCurrencyTaka className="text-sm md:text-sm lg:text-base" />
                <span className="productPrice">{oldPrice}</span>
              </div>
            </div>
            {isDisountProduct === true && (
              <div className="text-white bg-red text-xsm md:text-sm rounded md:px-2 px-1">{discountText}% OFF</div>
            )}
          </div>

          {/* product title section  */}

          <div className="text-textBlack text-xs lg:text-base">
            <span className="lg:hidden">
              {name && name?.length > 30
                ? `${name.substring(0, 30)}...`
                : name
              }
            </span>
            <span className="hidden lg:block">
              {name && name?.length > 38
                ? `${name.substring(0, 38)}...`
                : name
              }
            </span>
          </div>

          <div className="flex justify-evenly items-center">
            {totalSold !== 0 && (
              <div className="centerDiv gap-1 text-xs md:text-sm md:font-medium text-textBlack">
                <HiShoppingCart className="text-green-400" />
                <span className="">{totalSold}</span>
              </div>
            )}
            {totalReview !== 0 && (
              <div className="centerDiv gap-1 md:text-sm text-xs md:font-medium text-textRed">
                <MdReviews className="text-royalBlue" />
                <span className="">{totalReview}</span>
              </div>
            )}
            {totalView !== 0 && (
              <div className="centerDiv gap-1 md:text-sm text-xs md:font-medium text-textGreen">
                <AiFillEye className="text-textGrey" />
                <span className="">{totalView}</span>
              </div>
            )}
            {totalFavourite !== 0 && (
              <div className="centerDiv gap-1 md:text-sm text-xs md:font-medium text-textGreen">
                <AiFillHeart className="text-affiliate" />
                <span className="">{totalFavourite}</span>
              </div>
            )}
          </div>

        </div>
      </Link >
    </div >
  );
};
