import { AlertColor } from "@mui/material/Alert/Alert";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { createUserService } from "../../../../httpService/user/userServices";
import { CreateUserRequest } from "../../../../models/auth/user/UserModels";
import { RootState } from "../../../../store";
import { getUseRoleListAction } from "../../../../store/actions/auth/address/userRoleActions";
import { AppButton } from "../../../common/AppButton";
import { AppInput } from "../../../common/AppInput";
import { AppSelect } from "../../../common/AppSelect";
import { AppTextArea } from "../../../common/AppTextArea";

const userTypes = [
  { id: 1, name: "Regular" },
  { id: 2, name: "Affiliate" },
];

export default function AdminCreateUser() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userRoleList = useSelector((state: RootState) => {
    return state.userRoleListResponse.userRole;
  });

  useEffect(() => {
    dispatch(getUseRoleListAction());
  }, []);

  const [firstName, setFirstName] = useState("");
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState("");

  const [lastName, setLastName] = useState("");
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState("");

  const [email, setEmail] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState("");

  const [gender, setGender] = useState(0);
  const [genderErrorMessage, setGenderErrorMessage] = useState("");

  const [password, setPassword] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState("");

  const [roleId, setRoleId] = useState(0);
  const [roleIdErrorMessage, setRoleIdErrorMessage] = useState("");

  const [userTypeId, setUserTypeId] = useState(0);
  const [userTypeErrorMessage, setUserTypeErrorMessage] = useState("");

  const [userAddress, setUserAddress] = useState('')
  const [userAddressError, setUserAddressError] = useState('')

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    setSnackBarOn(false);

    var model = new CreateUserRequest();
    model.firstName = firstName;
    model.lastName = lastName;
    model.email = email;
    model.phoneNumber = phoneNumber;
    model.gender = gender;
    model.password = password;
    model.confirmPassword = confirmPassword;
    model.roleId = roleId;

    createUserService(model)
      .then((response) => {
        setSnackBarOn(true);
        setsnackBarMessage("User Added Successfully.");
        setSnackBarMessageType("success");

        setTimeout(() => {
          navigate("/admin/auth/users");
        }, 300);

      })
      .catch((error: any) => {
        setFirstNameErrorMessage(error.response.data.errors?.FirstName?.[0]);
        setLastNameErrorMessage(error.response.data.errors?.LastName?.[0]);
        setEmailErrorMessage(error.response.data.errors?.Email?.[0]);
        setPhoneNumberErrorMessage(error.response.data.errors?.PhoneNumber?.[0]);
        setGenderErrorMessage(error.response.data.errors?.Gender?.[0]);
        setPasswordErrorMessage(error.response.data.errors?.Password?.[0]);
        setConfirmPasswordErrorMessage(error.response.data.errors?.ConfirmPassword?.[0]);
        setRoleIdErrorMessage(error.response.data.errors?.RoleId?.[0]);
        setUserAddressError(error.response.data.errors?.UserAddress?.[0]);
        setUserTypeErrorMessage(error.response.data.errors?.UserType?.[0])
      });
  };

  return (
    <div>
      <div className="lg:pl-4 lg:flex items-center lg:justify-between w-full lg:w-3/6 pb-2 lg:pb-6">
        <AppButton
          onClick={() => navigate("/admin/auth/users")}
          text="Back"
        />
        <h2 className="text-center title">Create User</h2>
      </div>

      <form className="w-full lg:max-w-3xl flex flex-col gap-2 mx-auto myBorder py-2 lg:py-4 px-2 lg:px-5">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4">
          <AppInput
            type="text"
            label="First Name:"
            className="myInput"
            placeholder="Enter Your First Name"
            onChange={(e: any) => setFirstName(e)}
            errorMessage={firstNameErrorMessage}
          />
          <AppInput
            type="text"
            label="Last Name:"
            className="myInput"
            placeholder="Enter Your Last Name"
            onChange={(e: any) => setLastName(e)}
            errorMessage={lastNameErrorMessage}
          />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4">
          <AppInput
            type="email"
            label="Email:"
            className="myInput"
            placeholder="Enter Your Email"
            onChange={(e: any) => setEmail(e)}
            errorMessage={emailErrorMessage}
          />
          <AppInput
            type="number"
            label="Phone Number:"
            className="myInput"
            placeholder="Enter Phone Number"
            onChange={(e: any) => setPhoneNumber(e)}
            errorMessage={phoneNumberErrorMessage}
          />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4">
          <AppInput
            type="text"
            label="Password:"
            className="myInput"
            placeholder="Enter Your Password"
            onChange={(e: any) => setPassword(e)}
            errorMessage={passwordErrorMessage}
          />
          <AppInput
            type="text"
            label="Confirm Password: "
            className="myInput"
            placeholder="Confirm Your Password"
            onChange={(e: any) => setConfirmPassword(e)}
            errorMessage={confirmPasswordErrorMessage}
          />
        </div>

        <AppTextArea
          label="Address:"
          placeholder="Your Address"
          onChange={(e: any) => setUserAddress(e)}
          errorMessage={userAddressError}
        />

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 lg:gap-4">
          <div className="">
            <label className="text-sm lg:text-base">Gender: </label>
            <AppSelect
              data={[
                { id: "0", name: "MALE" },
                { id: "1", name: "FEMALE" },
              ]}
              defaultValue="Select Gender"
              value={gender}
              onChange={(e: any) => setGender(e)}
              errorMessage={genderErrorMessage}
            />
          </div>
          <div className="">
            <label className="text-sm lg:text-base">Role: </label>
            <AppSelect
              data={userRoleList}
              defaultValue="Select User Role"
              value={roleId}
              onChange={(e: any) => setRoleId(e)}
              errorMessage={roleIdErrorMessage}
            />
          </div>
          <div className="">
            <label className="text-sm lg:text-base">User Type: </label>
            <AppSelect
              data={userTypes}
              defaultValue="Select User Type"
              value={userTypeId}
              onChange={(e: any) => setUserTypeId(e)}
              errorMessage={userTypeErrorMessage}
            />
          </div>
        </div>

        <div className="flex justify-end">
          <AppButton onClick={handleSubmit} variant="contained" text="Submit" />
        </div>
      </form>
    </div>
  );
}
