import { GET_ADDRESS_LIST } from "../../../constants/auth/address/AddressConstants";

function addressListReducer(state= { data: []} , action: any) {
    switch (action.type) {
        case GET_ADDRESS_LIST :
            return {...state, loading: false, data: action.payload };
        default:
            return state;
    }
}



export { addressListReducer }