import { GET_POLICEOFFICIALS_NUMBER } from "../../../constants/importantNumbers/policeOfficialsInfo/policeOfficialsConstant";


export function getPoliceOfficialsReducer(state = { data: [] }, action: any) {
	switch (action.type) {
		case GET_POLICEOFFICIALS_NUMBER:
			return { loading: false, data: action.payload };
		default:
			return state;
	}
}