import { getMagazineCommentListService } from "../../../httpService/magazine/magazineCommentServices";
import { PaginationPayloadModel } from "../../../models/common/paginationModels";

export const magazineCommentPostListAction = (payload?: any) => async (dispatch: any) => {
    try {
        const data = getMagazineCommentListService(payload);
        const pload = (await data).data;
        dispatch({ type: 'GET_MAGAZINE_POST_COMMENT_LIST', payload: pload });
    }
    catch (error: any) { }
}