import { AlertColor } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { AppButton } from "../../../common/AppButton";
import { AppInput } from "../../../common/AppInput";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { AppSelect } from "../../../common/AppSelect";
import {
  TypeUnionOrWard,
  UpdateUnionModel,
} from "../../../../models/importantNumber/unionModels";
import { Link } from "react-router-dom";
import {
  getUnionByIdService,
  updateUnionServices,
} from "../../../../httpService/importantNumbers/unionServices";
import { isAdmin } from "../../../../common/common";
import { reactLocalStorage } from "reactjs-localstorage";

export default function INUpdateUnion(props: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const unionById = async (id: any) => {
    const data = getUnionByIdService(id);
    const pload = (await data).data;
    loadData(
      pload.id,
      pload.name,
      pload.bnName,
      pload.code,
      pload.lat,
      pload.lon,
      pload.phoneNumberFirst,
      pload.phoneNumberSecond,
      pload.typeName,
      pload.type
    );
  };

  useEffect(() => {
    unionById(id);
  }, []);

  const loadData = (
    Id: any,
    name: any,
    bnName: any,
    code: any,
    lat: any,
    lon: any,
    mobileFirst: any,
    mobileSecond: any,
    typeName: any,
    type: any
  ) => {
    setId(Id);
    setName(name);
    setBnName(bnName);
    setCode(code);
    setLat(lat);
    setLon(lon);
    setMobileFirst(mobileFirst);
    setMobileSecond(mobileSecond);
    setTypeName(typeName);
    setType(type);
  };

  const [Id, setId] = useState(0);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [bnName, setBnName] = useState("");
  const [bnNameError, setBnNameError] = useState("");
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");
  const [lat, setLat] = useState("");
  const [latError, setLatError] = useState("");
  const [lon, setLon] = useState("");
  const [lonError, setLonError] = useState("");
  const [mobileFirst, setMobileFirst] = useState("");
  const [mobileFirstError, setMobileFirstError] = useState("");
  const [mobileSecond, setMobileSecond] = useState("");
  const [mobileSecondError, setMobileSecondError] = useState("");
  const [typeName, setTypeName] = useState("");
  const [type, setType] = useState(0);
  const [typeError, setTypeError] = useState("");
  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);

  const nameHandle = (value: any) => {
    setName(value);
  };
  const bnNameHandle = (value: any) => {
    setBnName(value);
  };
  const codeHandle = (value: any) => {
    setCode(value);
  };
  const latHandle = (value: any) => {
    setLat(value);
  };
  const lonHandle = (value: any) => {
    setLon(value);
  };
  const mobileFirstHandle = (value: any) => {
    setMobileFirst(value);
  };
  const mobileSecondHandle = (value: any) => {
    setMobileSecond(value);
  };
  const typeHandle = (value: any) => {
    setType(value);
  };

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    var model = new UpdateUnionModel();
    model.Id = Id;
    model.Name = name;
    model.BnName = bnName;
    model.Code = code;
    model.Lat = lat;
    model.Lon = lon;
    model.PhoneNumberFirst = mobileFirst;
    model.PhoneNumberSecond = mobileSecond;
    model.Type = type;

    setIsButtonLoading(true);

    setSnackBarOn(false);
    updateUnionServices(model)
      .then((response) => {
        setSnackBarOn(true);
        setsnackBarMessage("Successfull");
        setSnackBarMessageType("success");

        setIsButtonLoading(false);

        setTimeout(() => {
          if (isAdmin(reactLocalStorage.get("RoleName"))) {
            navigate("/admin/important-numbers/unions");
          } else {
            navigate("/important-numbers/unions");
          }
        }, 300);
      })
      .catch((error: any) => {
        setIsButtonLoading(false);
        setNameError(error.response.data.errors?.Name?.[0]);
        setBnNameError(error.response.data.errors?.BnName?.[0]);
        setCodeError(error.response.data.errors?.Code?.[0]);
        setMobileFirstError(error.response.data.errors?.PhoneNumberFirst?.[0]);
        setMobileSecondError(
          error.response.data.errors?.PhoneNumberSecond?.[0]
        );
        setTypeError(error.response.data.errors?.Type?.[0]);
      });
  };
  return (
    <div>
      <div>
        {snackBarOn && (
          <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
        )}
      </div>

      <div className="flex w-3/5 items-center justify-between pb-6 pl-4">
        {isAdmin(reactLocalStorage.get("RoleName")) ? (
          <Link className="myBtn" to="/admin/important-numbers/unions">
            Back
          </Link>
        ) : (
          <Link className="myBtn" to="/important-numbers/unions">
            Back
          </Link>
        )}

        <h2 className="title text-center">Update Union</h2>
      </div>

      <form className="myBorder mx-auto max-w-xl px-5 py-4">
        <div className="my-2 flex flex-col items-center justify-between gap-2 lg:flex-row lg:gap-6">
          <AppInput
            className="myInput"
            type="text"
            placeholder="Union Name"
            label="Union Name: "
            value={name}
            onChange={nameHandle}
            errorMessage={nameError}
          />
          <AppInput
            className="myInput"
            type="text"
            placeholder="Bangla Name"
            label="Bangla Name: "
            value={bnName}
            onChange={bnNameHandle}
            errorMessage={bnNameError}
          />
        </div>
        <AppInput
          className="myInput"
          type="text"
          placeholder="Union No."
          label="Union No: "
          value={code}
          onChange={codeHandle}
          errorMessage={codeError}
        />
        <AppInput
          className="myInput"
          type="text"
          placeholder="Latitude"
          label="Latitude: "
          value={lat}
          onChange={latHandle}
          errorMessage={latError}
        />
        <AppInput
          className="myInput"
          type="text"
          placeholder="Longitude"
          label="Longitude: "
          value={lon}
          onChange={lonHandle}
          errorMessage={lonError}
        />
        <AppInput
          className="myInput"
          type="text"
          placeholder="Phone Number 1"
          label="Phone Number 1: "
          value={mobileFirst}
          onChange={mobileFirstHandle}
          errorMessage={mobileFirstError}
        />
        <AppInput
          className="myInput"
          type="text"
          placeholder="Phone Number 2"
          label="Phone Number 2: "
          value={mobileSecond}
          onChange={mobileSecondHandle}
          errorMessage={mobileSecondError}
        />
        <div className="">
          <p className="text-sm md:text-base">Type</p>
          <AppSelect
            value={type}
            defaultValue="Type"
            data={TypeUnionOrWard}
            onChange={typeHandle}
            errorMessage={typeError}
          />
        </div>
        <div className="mt-2 flex justify-end">
          <AppButton
            onClick={handleSubmit}
            isSubmitting={isButtonLoading}
            variant="contained"
            text="Update"
          />
        </div>
      </form>
    </div>
  );
}
