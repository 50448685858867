import { AlertColor } from "@mui/material/Alert";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import {
  CartItemSelectionRequest,
  IncreseDecreseCartItemRequest,
  RemoveSingleOrListCartitemRequest,
  ShoppingCartFavouriteModel,
  ShoppingCartType,
} from "../../../models/shoppingCart/resellingCartModels";
import { RootState } from "../../../store";
import { AppCheckBox } from "../../common/AppCheckBox";
import { AppSnackBar } from "../../common/AppSnackBar";
import { HiMinus, HiPlus } from "react-icons/hi";
import { TbCurrencyTaka } from "react-icons/tb";
import { AppButton } from "../../common/AppButton";
import { AppLoader } from "../../common/AppLoader";
import {
  getAffiliateCartItemsAction,
  getAffiliateTotalShippingChargeAction,
  getAffiliateTotalShoppingCartItemAction,
} from "../../../store/actions/cart/affiliateCartActions";
import {
  affiliateCartItemsSelectionService,
  increaseDecreaseAffiliateCartItemService,
  removeSingleOrListAffiliateCartitemService,
} from "../../../httpService/shoppingCart/affiliateCartServices";

export default function AffiliateGetCartItems() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const [cartItems, setCartItems] = useState<ShoppingCartFavouriteModel[]>([]);

  const [totalPrice, setTotalPrice] = useState(0);
  const [allTotalPrice, setAllTotalPrice] = useState(0);

  const [isRemoveButtonDisable, setIsRemoveButtonDisable] = useState(false);

  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

  var token = reactLocalStorage.getObject("SignIn");

  const cartItemsSignIn = useSelector((state: RootState) => {
    return state.affiliateGetTotalCartItemListResponse.data;
  });

  const cartItemsMemo = useMemo(() => {
    return cartItemsSignIn as ShoppingCartFavouriteModel[];
  }, [cartItemsSignIn]);

  useEffect(() => {
    if (token && JSON.stringify(token) !== JSON.stringify({})) {
      if (cartItemsMemo) {
        setCartItems(cartItemsMemo);
        calculateTotalEarning(cartItemsMemo);
      }
    } else {
    }
  }, [cartItemsMemo, token]);

  const groupedProducts = cartItemsMemo.reduce(
    (result: any, product: ShoppingCartFavouriteModel) => {
      const index = result.findIndex(
        (vendorProducts: any) => vendorProducts[0].vendorId === product.vendorId
      );
      if (index >= 0) {
        result[index].push(product);
      } else {
        result.push([product]);
      }
      return result;
    },
    []
  );

  useEffect(() => {
    if (token && JSON.stringify(token) === JSON.stringify({})) {
      const pItems = reactLocalStorage.getObject(
        "AffiliateCartItems"
      ) as ShoppingCartFavouriteModel[];
      if (pItems) {
        setCartItems(pItems);
      }
    }
  }, [token]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await dispatch(getAffiliateCartItemsAction());
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    getTotalPrice();
  }, [cartItems]);

  const uniqueVendors = new Set<string>();

  const [selectedVendorCount, setSelectedVendorCount] = useState(0);

  const getTotalPrice = () => {
    let p = 0;
    if (cartItems && JSON.stringify(cartItems) !== JSON.stringify({})) {
      cartItems
        ?.filter((_) => _.isSelected)
        ?.map((x: any) => {
          if (x.vendorName) {
            uniqueVendors.add(x.vendorName);
          }
          return (p = p + x.totalPrice);
        });
      setTotalPrice(p);
      setSelectedVendorCount(uniqueVendors.size);
      setAllTotalPrice(p + insideDhakAShippingCharge);
    }
  };

  const removeCartItem = (item: any) => {
    if (token && JSON.stringify(token) !== JSON.stringify({})) {
      var ids = [];
      ids.push(item.id);

      let payload = new RemoveSingleOrListCartitemRequest();
      payload.ids = ids;
      payload.cartType = ShoppingCartType.ShoppingCart;

      setIsRemoveButtonDisable(true);
      removeSingleOrListAffiliateCartitemService(payload)
        .then((res: any) => {
          setTimeout(() => {
            dispatch(getAffiliateCartItemsAction());
            dispatch(
              getAffiliateTotalShoppingCartItemAction(res.data.totalItem)
            );
            setIsRemoveButtonDisable(false);
          }, 1000);

          setIsShowSnackBar(true);
          setSnackBarMessage("Successfully Removed");
          setSnackBarMessageType("error");
          setTimeout(() => {
            setIsShowSnackBar(false);
          }, 3000);
        })
        .catch(() => {
          setIsRemoveButtonDisable(false);
        });
    } else {
      let price =
        parseFloat(totalPrice.toString()) - parseFloat(item?.totalPrice);
      setTotalPrice(price);
      setAllTotalPrice(price + insideDhakAShippingCharge);
      var newItems = cartItems?.filter((_) => _.productId !== item.productId);

      var totalItem = 0;
      totalItem = newItems?.reduce(
        (q: any, p: any) => (q + p.productQuantity) as number,
        0
      );
      dispatch(getAffiliateTotalShoppingCartItemAction(totalItem));

      reactLocalStorage.setObject("AffiliateCartItems", newItems);
      setCartItems(newItems);
    }
  };

  const handleCountChange = (type: any, index: any, item: any) => {
    if (type === "increse") {
      const currentItems: any = [];

      var req = new IncreseDecreseCartItemRequest();
      req.id = item.id;
      req.cartType = 1;
      req.isIncrese = true;

      if (token && JSON.stringify(token) !== JSON.stringify({})) {
        increaseDecreaseAffiliateCartItemService(req)
          .then((res) => {
            if (res?.data?.isOperationSuccessfull) {
              dispatch(getAffiliateCartItemsAction());
              dispatch(getAffiliateTotalShippingChargeAction(isInDhaka));
            } else {
              setIsShowSnackBar(true);
              setSnackBarMessage("Out Of Stock");
              setSnackBarMessageType("warning");
              setTimeout(() => {
                setIsShowSnackBar(false);
              }, 2000);
            }
          })
          .catch((err) => { });
      } else {
        cartItems.forEach((item: any) => {
          if (!currentItems.some((x: any) => x.productId === item.productId)) {
            currentItems.push(item);
          }
        });

        var quantty = currentItems[index].productQuantity;
        currentItems[index].productQuantity = quantty ? quantty + 1 : 1;

        var crntQuantty = currentItems[index].productQuantity;
        var untPrice = currentItems[index].unitPrice;

        currentItems[index].totalPrice = crntQuantty * untPrice;

        let price = parseFloat(totalPrice.toString()) + parseFloat(untPrice);

        setTotalPrice(price);
        setAllTotalPrice(price + insideDhakAShippingCharge);

        setCartItems([]);
        setCartItems(currentItems);

        reactLocalStorage.setObject("AffiliateCartItems", cartItems);

        var totalItem = 0;
        totalItem = currentItems?.reduce(
          (q: any, p: any) => (q + p.productQuantity) as number,
          0
        );
        dispatch(getAffiliateTotalShoppingCartItemAction(totalItem));
      }
    }

    if (type === "decrese") {
      if (token && JSON.stringify(token) !== JSON.stringify({})) {
        if (item.productQuantity > 1) {
          var req = new IncreseDecreseCartItemRequest();
          req.id = item.id;
          req.cartType = 1;
          req.isIncrese = false;

          increaseDecreaseAffiliateCartItemService(req)
            .then((res) => {
              dispatch(getAffiliateCartItemsAction());
              dispatch(getAffiliateTotalShippingChargeAction(isInDhaka));
            })
            .catch((err) => { });
        }
      } else {
      }
    }
  };

  const handleNavigateToBillingAndShippingAddress = () => {
    if (
      cartItems?.length <= 0 ||
      JSON.stringify(cartItems) === JSON.stringify({})
    ) {
      setIsShowSnackBar(true);
      setSnackBarMessage("Your cart list is empty");
      setSnackBarMessageType("error");
      setTimeout(() => {
        setIsShowSnackBar(false);
      }, 3000);
    } else {
      if (cartItems?.filter((_) => _.isSelected).length <= 0) {
        setIsShowSnackBar(true);
        setSnackBarMessage("Please select item(s)");
        setSnackBarMessageType("error");
        setTimeout(() => {
          setIsShowSnackBar(false);
        }, 3000);
      } else {
        if (token && JSON.stringify(token) !== JSON.stringify({})) {
        } else {
          dispatch({ type: "OPEN-SIGN-IN" });
          dispatch({ type: "CLOSE-SIGN-UP" });
        }
      }
    }
  };

  const [totalEarning, setTotalEarning] = useState(0);

  const handleItemSelected = (item: any, isChecked: any) => {
    if (JSON.stringify(token) !== JSON.stringify({})) {
      setIsShippingChargeSelected(false);

      var models: CartItemSelectionRequest[] = [];

      cartItems.map((i: ShoppingCartFavouriteModel) => {
        var model = new CartItemSelectionRequest();
        if (item.id === i.id) {
          model.id = i.id;
          model.productId = i.productId;
          model.isSelected = isChecked;
          models.push(model);
        }
      });

      affiliateCartItemsSelectionService(models)
        .then((res: any) => {
          const newItems = cartItems.map((i: ShoppingCartFavouriteModel) => {
            if (item.id === i.id) {
              i.isSelected = isChecked;
            }
            return i;
          });
          setCartItems(newItems);
          dispatch(getAffiliateCartItemsAction());
          //calculateTotalEarning(cartItemsMemo);
        })
        .catch(() => { });
    }
  };

  const calculateTotalEarning = (items: ShoppingCartFavouriteModel[]) => {
    let total = 0;
    items
      .filter((_: any) => _.isSelected)
      .forEach((item: ShoppingCartFavouriteModel) => {
        total += item.totalIncome ? item.totalIncome : 0;
      });
    setTotalEarning(total);
  };

  const handleSelectAll = (isChecked: any) => {
    if (JSON.stringify(token) !== JSON.stringify({})) {
      setIsShippingChargeSelected(false);

      var models: CartItemSelectionRequest[] = [];

      cartItems.map((i: ShoppingCartFavouriteModel) => {
        var model = new CartItemSelectionRequest();
        model.id = i.id;
        model.productId = i.productId;
        model.isSelected = isChecked;
        models.push(model);
      });

      affiliateCartItemsSelectionService(models)
        .then((res: any) => {
          const newItems = cartItems.map((i: ShoppingCartFavouriteModel) => {
            i.isSelected = isChecked;
            return i;
          });
          setCartItems(newItems);
          //calculateTotalEarning(newItems);
        })
        .catch(() => { });
    }
  };

  const handleNavigateToHome = () => {
    navigate("/affiliate");
  };

  const navigateToProductDetails = (id?: any) => {
    navigate(`/affiliate/productDetails/${id}`);
  };

  const insideDhakAShippingCharge = 60;
  const outideDhakAShippingCharge = 120;

  const [isInDhaka, setIsInDhaka] = useState(false);
  const [isOutSideDhaka, setIsOutSideDhaka] = useState(false);

  const [shippingCharge, setShippingCharge] = useState(
    insideDhakAShippingCharge
  );
  const [isShippingChargeSelected, setIsShippingChargeSelected] =
    useState(false);

  const hanldeShippingCharge = (inDhaka: any) => {
    if (inDhaka) {
      dispatch(getAffiliateTotalShippingChargeAction(true));
      setIsInDhaka(true);
      setIsOutSideDhaka(false);
      setShippingCharge(insideDhakAShippingCharge * selectedVendorCount);
      setAllTotalPrice(
        totalPrice + insideDhakAShippingCharge * selectedVendorCount
      );
    } else {
      dispatch(getAffiliateTotalShippingChargeAction(false));
      setIsInDhaka(false);
      setIsOutSideDhaka(true);
      setShippingCharge(outideDhakAShippingCharge * selectedVendorCount);
      setAllTotalPrice(
        totalPrice + outideDhakAShippingCharge * selectedVendorCount
      );
    }
    setIsShippingChargeSelected(true);
  };

  const getTotalShippingCharge = useSelector((state: RootState) => {
    return state.affiliateGetTotalShippingChargeResponse.data;
  });

  const getTotalShippingChargeMemo = useMemo(() => {
    return getTotalShippingCharge;
  }, [getTotalShippingCharge]);

  if (isLoading) {
    return (
      <div className="container mx-auto flex h-screen items-center justify-center px-2 pb-12 md:px-0">
        <AppLoader status={true} />
      </div>
    );
  }

  return (
    <>
      {isShowSnackBar && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}

      {cartItems?.length === 0 && (
        <div className="flex min-h-[90dvh] flex-col items-center justify-center pb-8">
          <img
            className="w-11/12 object-contain md:h-1/3 md:w-1/3"
            src="https://res.cloudinary.com/dwx2jd8b1/image/upload/v1686977554/Website-assets/SoftKarrot/image_1_1_u2ahiz.png"
            alt=""
          />
          <h2 className="pb-2">Your shopping cart is empty.</h2>
          <AppButton className="affiliateBtn" text="Continue shopping" onClick={handleNavigateToHome} />
        </div>
      )}

      {cartItems?.length !== 0 && (
        <div className="customer-shopping-cart-design container mx-auto flex flex-col justify-between gap-x-4 px-2 py-2 pb-8 md:flex-row lg:pt-4 items-center 2xl:py-3 lg:py-2 md:px-16 lg:px-28 ">
          <div className="w-full md:w-3/4">
            <h3 className="title pb-1">My cart</h3>
            {/* <div className="shopping-cart-items-title">
            Total {cartItems?.length} Items
              {cartItems?.length != 0 && (
                <h3>
                  Total Selcted (
                  {cartItems?.filter((_) => _.isSelected)?.length})
                </h3>
              )}
          </div> */}
            {cartItems?.length !== 0 && (
              <div className="flex items-center justify-between md:pr-4">
                <div className="flex items-center py-1 pl-2 md:gap-x-2 2xl:py-3 2xl:pl-4">
                  <AppCheckBox
                    isChecked={
                      cartItems?.length ===
                      cartItems?.filter((_) => _.isSelected).length
                    }
                    onChange={handleSelectAll}
                  />
                  <span className="uppercase 2xl:text-lg"> Select all </span>
                </div>
              </div>
            )}

            {/* these is cart product info with scrollbar for desktop  */}
            <div className="mt-1 max-h-[70dvh] space-y-3 overflow-y-auto">
              {groupedProducts.map((vendorProducts: any, index: any) => (
                <section
                  key={index}
                  className="myBorder pb-1 shadow hover:shadow-lg"
                >
                  <div className="gap-x-2 border-b border-grey p-1 text-center 2xl:p-2">
                    <h2 className="text-lg font-medium 2xl:text-xl">
                      {vendorProducts[0].vendorName}
                    </h2>
                  </div>
                  {/* these is header section for title */}
                  <div className="flex items-center px-1 py-1 text-sm font-medium md:py-2 lg:px-2 lg:text-base 2xl:px-4">
                    <div className="w-[56%] pl-2 lg:w-[52%]">
                      <h3>Product</h3>
                    </div>
                    <div className="w-[18%] text-center lg:w-[16%]">
                      <h3>Price</h3>
                    </div>
                    <div className="w-[8%] text-center lg:w-[16%]">
                      <h3 className="lg:hidden">Qty</h3>
                      <h3 className="hidden lg:block">Quantity</h3>
                    </div>
                    <div className="w-[18%] text-center lg:w-[16%]">
                      <h3>Total</h3>
                    </div>
                  </div>

                  {vendorProducts.map((item: any, indx: any) => (
                    <div
                      className="flex items-center px-1 py-0.5 md:py-1 lg:px-2 2xl:px-4"
                      key={indx}
                    >
                      {/* product section here  */}
                      <div className="flex w-[56%] items-center lg:w-[52%]">
                        {/* product section checkbox here  */}
                        <AppCheckBox
                          className="h-5 w-5"
                          isChecked={item?.isSelected}
                          onChange={(e: any) => handleItemSelected(item, e)}
                        />
                        {/* product details section here  */}
                        <div className="flex items-center gap-x-1 pl-1.5 md:gap-x-2.5 lg:pl-2.5">
                          <img
                            onClick={() =>
                              navigateToProductDetails(item.productId)
                            }
                            className="h-8 w-8 rounded object-cover md:h-14 md:w-14"
                            src={item?.bannerPath}
                            alt="These is product pic"
                          />
                          {/* this is product details details section  */}
                          <div className="text-xsm md:text-base">
                            <h3
                              className="cursor-pointer "
                              onClick={() =>
                                navigateToProductDetails(item.productId)
                              }
                            >
                              <span className="lg:hidden">
                                {item?.productName.length > 16
                                  ? `${item?.productName.substring(0, 16)}...`
                                  : item?.productName}
                              </span>
                              <span className="hidden lg:block">
                                {item?.productName.length > 40
                                  ? `${item?.productName.substring(0, 40)}...`
                                  : item?.productName}
                              </span>
                            </h3>

                            {item?.attributes && (
                              <div className="">
                                {item?.attributes?.map(
                                  (attribute: any, x: any) =>
                                    attribute?.name === "Color" ? (
                                      <div key={x}>
                                        <p>{attribute?.name}:</p>
                                        <div
                                          style={{
                                            background: attribute?.value,
                                          }}
                                        ></div>
                                      </div>
                                    ) : (
                                      <div className="flex items-center justify-between">
                                        <div
                                          key={x}
                                          className="flex items-center gap-x-[2px] md:gap-x-1"
                                        >
                                          <p>{attribute?.name}:</p>
                                          <div>{attribute?.value}</div>
                                        </div>
                                        {/* <AppButton text="-" isDisable={isRemoveButtonDisable} className="bg-red text-white p-1 rounded font-extrabold" onClick={() => removeCartItem(item)} /> */}
                                      </div>
                                    )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        {/* these remove button for desktop  */}
                        <div className="ml-auto mt-auto flex items-end justify-end lg:mt-0">
                          <AppButton
                            text="-"
                            isDisable={isRemoveButtonDisable}
                            className="flex h-3 w-4 items-center justify-center rounded-sm bg-red pb-2 text-2xl text-white md:w-6 md:pb-2 lg:h-4"
                            onClick={() => removeCartItem(item)}
                          />
                        </div>
                      </div>
                      {/* these ia price section  */}
                      <div className="flex w-[18%] flex-col items-center justify-center gap-0.5 lg:w-[16%]">
                        <span className="text flex items-center justify-center gap-x-[1px] md:gap-x-1">
                          {" "}
                          <TbCurrencyTaka /> {item?.unitPrice}
                        </span>
                        <div
                          onClick={() =>
                            handleCountChange("decrese", indx, item)
                          }
                          className="myBorder flex h-4 w-4 cursor-pointer items-center justify-center rounded-[1px] border-[.5px] bg-white p-0.5 text-sm lg:hidden"
                        >
                          {" "}
                          <HiMinus />{" "}
                        </div>
                      </div>
                      {/* these is quantity section  */}
                      <div className="mt-auto flex w-[8%] flex-col-reverse items-center justify-center gap-x-4 gap-y-[2px] md:flex-row lg:mt-0 lg:w-[16%]">
                        <div
                          onClick={() =>
                            handleCountChange("decrese", indx, item)
                          }
                          className="myBorder hidden h-5 w-5 cursor-pointer items-center justify-center rounded-sm bg-white text-base md:h-6 md:w-6 lg:flex"
                        >
                          {" "}
                          <HiMinus />{" "}
                        </div>
                        <span className="text-xs font-medium md:text-lg">
                          {item?.productQuantity}
                        </span>
                        <div
                          onClick={() =>
                            handleCountChange("increse", indx, item)
                          }
                          className="myBorder hidden h-5 w-5 cursor-pointer items-center justify-center rounded-sm bg-white text-base md:h-6 md:w-6 lg:flex"
                        >
                          {" "}
                          <HiPlus />{" "}
                        </div>
                      </div>
                      {/* these is total section  */}
                      <div className="flex w-[18%] flex-col items-center justify-center gap-0.5 lg:w-[16%]">
                        <span className="text flex items-center justify-center gap-x-[1px] md:gap-x-1">
                          {" "}
                          <TbCurrencyTaka /> {item?.totalPrice}
                        </span>
                        <div
                          onClick={() =>
                            handleCountChange("increse", indx, item)
                          }
                          className="myBorder flex h-4 w-4 cursor-pointer items-center justify-center rounded-[1px] border-[.5px] bg-white p-0.5 text-sm lg:hidden"
                        >
                          {" "}
                          <HiPlus />{" "}
                        </div>
                      </div>
                    </div>
                  ))}
                </section>
              ))}
            </div>
          </div>

          {/* <ShoppingCartSummary section start here  */}
          <div className="mb-4 mt-2 h-fit w-full space-y-6 rounded bg-bgSecondary px-4 py-6 md:mb-0 md:mt-16 md:w-1/4 2xl:mt-20 2xl:space-y-7">
            <h3 className="title">Order Summary</h3>
            <div className="space-y-2 2xl:space-y-3">
              {/* subtotal items here  */}
              <div className="flex items-center justify-between text-lg 2xl:text-xl">
                <h3>
                  Subtotal <span>({cartItems?.length} items)</span>
                </h3>
                <p className="flex items-center justify-end gap-x-1">
                  <TbCurrencyTaka /> <span>{totalPrice}</span>
                </p>
              </div>
              {/* shipping charge here  */}
              <div className="flex items-center justify-between gap-x-4">
                <div
                  className={`${isInDhaka && isShippingChargeSelected
                    ? "affiliateBtn"
                    : "affiliateOutlineBtn"
                    } text-center`}
                  onClick={() => hanldeShippingCharge(true)}
                >
                  In Dhaka
                </div>
                <div
                  className={`${isOutSideDhaka && isShippingChargeSelected
                    ? "affiliateBtn"
                    : "affiliateOutlineBtn"
                    } text-center`}
                  onClick={() => hanldeShippingCharge(false)}
                >
                  OutSide Dhaka
                </div>
              </div>
              {isShippingChargeSelected && (
                <>
                  <div className="flex flex-col justify-between">
                    <h3 className="font-medium lg:text-lg 2xl:text-xl">
                      Shipping Charge:
                    </h3>
                    {getTotalShippingChargeMemo?.shippingCharges?.map(
                      (_: any, index: any) => {
                        return (
                          <div
                            key={index}
                            className="flex items-center justify-between gap-x-1 pl-4 text-sm lg:text-base"
                          >
                            <p>{_.vendorName}</p>
                            <p className="flex items-center">
                              <TbCurrencyTaka />
                              {_.charge}
                            </p>
                          </div>
                        );
                      }
                    )}
                    {/* <p className="flex items-center gap-x-1 justify-end"><TbCurrencyTaka /> <span>{shippingCharge}</span></p> */}
                  </div>
                  <div className="flex items-center justify-between gap-x-1 font-medium">
                    <p>Total Shipping Charge:</p>
                    <p className="flex items-center">
                      <TbCurrencyTaka />{" "}
                      {getTotalShippingChargeMemo?.totalCharge}
                    </p>
                  </div>

                  <hr className="my-1 border border-textBlack" />
                  {/* total amount here  */}
                  <div className="subTitle flex items-center justify-between">
                    <h3>Total</h3>
                    <p className="flex items-center justify-end gap-x-1">
                      <TbCurrencyTaka />{" "}
                      <span>
                        {totalPrice + getTotalShippingChargeMemo !== 'undefined'
                          ? getTotalShippingChargeMemo?.totalCharge
                          : 0}
                      </span>
                    </p>
                  </div>
                </>
              )}
            </div>
            {/* proceed to checkout btn here  */}

            {isShippingChargeSelected && (
              <>
                {cartItems?.filter((_: any) => _.isSelected).length > 0 ? (
                  <div
                    className="affiliateBtn mx-8 text-center"
                    onClick={handleNavigateToBillingAndShippingAddress}
                  >
                    <Link to="/affiliate/submitBillingAndShippingAddress">
                      Proceed to checkout
                    </Link>
                  </div>
                ) : (
                  <div
                    className="affiliateBtn mx-8 text-center"
                    onClick={handleNavigateToBillingAndShippingAddress}
                  >
                    Proceed to checkout
                  </div>
                )}
              </>
            )}
          </div>
          {/* <RelatedProducts /> */}
        </div>
      )}
      {/* these shopping cart design for desktop  */}
    </>
  );
}
