import {
    ADMIN_RESELLING_GET_ORDER_DETAILS,
    RESELLING_GET_ORDER_DETAILS,
    ADMIN_RESELLING_GET_ORDER_LIST,
    RESELLING_GET_ORDER_LIST,
} from "../../constants/sales/resellingOrderConstants";

export function resellingGetOrderListReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case RESELLING_GET_ORDER_LIST:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

export function resellingGetOrderDetailsReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case RESELLING_GET_ORDER_DETAILS:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

export function adminResellingGetOrderListReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case ADMIN_RESELLING_GET_ORDER_LIST:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

export function adminResellingGetOrderDetailsReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case ADMIN_RESELLING_GET_ORDER_DETAILS:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}


