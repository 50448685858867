import { AlertColor } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addAreaService } from "../../../../httpService/area/areaServices";
import { RootState } from "../../../../store";
import { districtList } from "../../../../store/actions/auth/address/districtActions";
import { AppButton } from "../../../common/AppButton";
import { AppInput } from "../../../common/AppInput";
import { AppSelect } from "../../../common/AppSelect";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { CreateAreaRequest } from "../../../../models/importantNumber/ImportantNumberModel";
import { IdNameResponseModel } from "../../../../models/common/IdNameModel";

export default function AdminCreateArea() {

  const districts = useSelector((state: RootState) => {
    return state.districtListResponse.data;
  });
  var districtsIdNameModels: IdNameResponseModel[] = [];

  districts?.map((x: any) => {
    var district = new IdNameResponseModel();
    district.id = x.id;
    district.name = x.name;
    districtsIdNameModels.push(district);
  });

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(districtList());
  }, []);

  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [bnName, setBnName] = useState("");
  const [lat, setLat] = useState("");
  const [lon, setLon] = useState("");
  const [districtId, setdistrictId] = useState(0);

  const [nameError, setNameError] = useState("");
  const [codeError, setCodeError] = useState("");
  const [bnNameError, setBnNameError] = useState("");
  const [latError, setLatError] = useState("");
  const [lonError, setLonError] = useState("");
  const [districtIderror, setDistrictIdError] = useState("");

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);

  const handleNameChange = (event: any) => {
    setName(event);
    setNameError("");
  };

  const handleCodeChange = (event: any) => {
    setCode(event);
    setCodeError("");
  };

  const handleBnNameChange = (event: any) => {
    setBnName(event);
    setBnNameError("");
  };

  const handleLatChange = (event: any) => {
    setLat(event);
    setLatError("");
  };

  const handleLonChange = (event: any) => {
    setLon(event);
    setLonError("");
  };

  const handledistrictIdChange = (event: any) => {
    setdistrictId(event);
    setDistrictIdError("");
  };

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    var model = new CreateAreaRequest();
    model.name = name;
    model.code = code;
    model.bnName = bnName;
    model.lat = lat;
    model.lon = lon;
    model.districtId = districtId;
    setIsButtonLoading(true);
    setSnackBarOn(false);
    addAreaService(model)
      .then((response) => {
        setSnackBarOn(true);
        setsnackBarMessage("Successfully Added");
        setSnackBarMessageType("success");

        setTimeout(() => {
          navigate("/thanas");
        }, 300);
        setIsButtonLoading(false);
      })
      .catch((error: any) => {
        setIsButtonLoading(false);
        setNameError(error.response.data.errors?.Name?.[0]);
        setCodeError(error.response.data.errors?.Code?.[0]);
        setBnNameError(error.response.data.errors?.BnName?.[0]);
        setLatError(error.response.data.errors?.Lat?.[0]);
        setLonError(error.response.data.errors?.Lon?.[0]);
        setDistrictIdError(error.response.data.errors?.DistrictId?.[0]);
      });
  };

  return (
    <div>
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      <div className="pl-4 flex items-center justify-between w-3/6 pb-6">
        <AppButton onClick={() => navigate("/thanas")} text="Back" />
        <h2 className="text-center title">Create Thana/Upazila</h2>
      </div>

      <form className="max-w-xl space-y-2 mx-auto myBorder py-4 px-5">
        <div className="flex justify-between items-center gap-8">

          <AppInput
            className="myInput"
            type="text"
            placeholder="Enter Eng Name"
            label="Enter Eng Name: "
            onChange={handleNameChange}
            errorMessage={nameError}
          />

          {/* <AppInput
            className="myInput"
            type="text"
            placeholder="Enter Area Code"
            label="Enter Area Code: "
            onChange={handleCodeChange}
            errorMessage={codeError}
          /> */}
        </div>
        {/* <div className="flex justify-between items-center gap-8">

          <AppInput
            className="myInput"
            type="text"
            placeholder="Enter Latitude"
            label="Enter Latitude: "
            onChange={handleLatChange}
            errorMessage={latError}
          />
          <AppInput
            className="myInput"
            type="text"
            placeholder="Enter Longitude"
            label="Enter Longitude: "
            onChange={handleLonChange}
            errorMessage={lonError}
          />
        </div> */}
        <div className="flex justify-between items-center gap-8">
          <AppInput
            className="myInput"
            type="text"
            placeholder="Enter Bangla Name"
            label="Enter Bangla Name: "
            onChange={handleBnNameChange}
            errorMessage={bnNameError}
          />
        </div>
        <div className="w-full">
          <label className="2xl:text-xl md:text-lg text-base">Select District: </label>
          <AppSelect
            value={districtId}
            data={districtsIdNameModels}
            defaultValue="Select District"
            onChange={handledistrictIdChange}
            errorMessage={districtIderror}
          />
        </div>

        <div className="flex justify-end">
          <AppButton isSubmitting={isButtonLoading} onClick={handleSubmit} variant="contained" text="Submit" />
        </div>
      </form>
    </div>
  );
}
