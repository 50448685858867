import { GET_CITY_CORPORATION_LIST } from "../../../constants/auth/address/AddressConstants";

function cityCorporationListReducer(state= { data: []} , action: any) {
    switch (action.type) {
        case GET_CITY_CORPORATION_LIST :
            //return {...state, loading: false, data: action.payload };
            return {loading: false, data: action.payload };
        default:
            return state;
    }
}

export { cityCorporationListReducer }