import { POST } from "../service";

export const sSAddProductMediaService = (data: any) => {
  return POST("/api/SSProduct/SSAddProductMedias", data);
};

export const sSRemoveProductMediaService = (data: any) => {
  return POST("/api/SSProduct/SSRemoveProductMedia", data);
};

// export const sSRemoveProductMediaService = (data: any) => {
//   return DELETE(`/api/SSProduct/SSRemoveProductMedia`, {
//     data: { "ids": data },
//   });
// };