import { useMemo, useEffect, useState } from "react";
import { TbCurrencyTaka } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { sSCancelOrderProductsService, sSReturnOrderService } from "../../../../httpService/superShops/ssOrderServices";
import { SSGetOrderDetailsResponse, SSGetOrderProductResponse, SSGetOrderDetailsRequest, SSCancelOrderRequest, SSReturnOrderRequest } from "../../../../models/superShopp/order/sSOrderModel";
import { RootState } from "../../../../store";
import { AppButton } from "../../../common/AppButton";
import { AppModal } from "../../../common/AppModal";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import _moment from "moment";
import { sSGetOrderDetailsAction } from "../../../../store/actions/superShop/sSOrderActions";
import { AppLoader } from "../../../common/AppLoader";

export default function SSGetOrderById() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const orderDetails = useSelector((state: RootState) => {
    return state.sSGetOrderDetailsResponse.data as SSGetOrderDetailsResponse;
  });

  const orderProducts = useMemo(() => {
    return orderDetails.orderProductList as SSGetOrderProductResponse[];
  }, [orderDetails]);

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        let model = new SSGetOrderDetailsRequest();
        model.orderId = id;
        await dispatch(sSGetOrderDetailsAction(model));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [id]);

  const handleBackToOrderList = () => {
    navigate("/super-shop/order-list");
  };


  const navigateToProductDetails = (id?: any) => {
    navigate(`/super-shop/product/${id}`);
  };

  const orderTimeFormatter = (createdDateTime: any) => {
    const createdTime = _moment(createdDateTime, "YYYY-MM-DD HH:mm:ss.SSSSSSS");
    return createdTime.format("MMM D, YY [at] h:mm A");
  };

  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);

  const [isOpenReturnModal, setIsOpenReturnModal] = useState(false);

  const [productId, setProductId] = useState(0);

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleCancelOne = (item: any) => {
    setIsOpenCancelModal(true);
    setProductId(item.productId);
  };

  const [isCancelButtonLoading, setIsCancelButtonLoading] = useState(false);

  const handleCancelOneOrder = () => {
    var model = new SSCancelOrderRequest();
    model.orderId = id;
    model.productId = productId;
    setIsCancelButtonLoading(true);
    sSCancelOrderProductsService(model)
      .then(() => {
        setIsButtonLoading(false);
        setIsOpenCancelModal(false);
        let model = new SSGetOrderDetailsRequest();
        model.orderId = id;
        dispatch(sSGetOrderDetailsAction(model));
        setIsCancelButtonLoading(false);
      })
      .catch(() => {
        setIsCancelButtonLoading(false);
      });
  };

  const handleCloseCancelOneModal = () => {
    setIsOpenCancelModal(false);
  };

  const handleReturnOneOrder = () => {
    var model = new SSReturnOrderRequest();
    model.orderId = id;
    setIsButtonLoading(true);
    sSReturnOrderService(model)
      .then(() => {
        setIsButtonLoading(false);
        setIsOpenReturnModal(false);
        let model = new SSGetOrderDetailsRequest();
        model.orderId = id;
        dispatch(sSGetOrderDetailsAction(model));
      })
      .catch(() => {
        setIsButtonLoading(false);
      });
  };

  const handleCloseReturnOneModal = () => {
    setIsOpenReturnModal(false);
  };

  if (isLoading) {
    return (
      <div className="container mx-auto flex h-screen items-center justify-center px-2 pb-12 md:px-0">
        <AppLoader status={true} />
      </div>
    );
  }

  return (
    <div className="container mx-auto pb-8 pt-2 md:py-4 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28">
      {isOpenCancelModal && (
        <AppModal>
          <div className="flex max-w-md flex-col gap-4 p-6 text-center md:text-2xl">
            Are you sure you want to cancel this product?
            <div className="flex items-center justify-center gap-4">
              <AppButton text="Yes" onClick={handleCancelOneOrder} isSubmitting={isCancelButtonLoading} />
              <AppButton text="No" onClick={handleCloseCancelOneModal} />
            </div>
          </div>
        </AppModal>
      )}

      {isOpenReturnModal && (
        <AppModal>
          <div className="flex max-w-md flex-col gap-4 p-6 text-center md:text-2xl">
            Are you sure you want to return this product?
            <div className="flex items-center justify-center gap-4">
              <AppButton text="Yes" onClick={handleReturnOneOrder} />
              <AppButton text="No" onClick={handleCloseReturnOneModal} />
            </div>
          </div>
        </AppModal>
      )}

      {/* these is back button  */}
      <div
        className="flex cursor-pointer items-center lg:gap-x-2 lg:px-4"
        onClick={handleBackToOrderList}
      >
        <KeyboardBackspaceOutlinedIcon />
        <h4 className="subTitle">Back to order list</h4>
      </div>

      <div className="md:px-16">
        <h3 className="title pb-2 lg:pb-0 lg:text-center">Order details</h3>
        {/* these is buttons and text  */}
        <div className="flex justify-between lg:items-center">
          <h3 className="subTitle flex items-center gap-1">
            <span className={`${orderDetails?.paymentStatus == "Paid" ? "paided" : "unpaid"}`}>
              Payment Status: {orderDetails?.paymentStatus == "Paid" ? `Paid` : `NotPaid`}
            </span>
          </h3>
        </div>

        <div className="flex flex-col items-center gap-2 pt-2 md:flex-row md:gap-8">
          <div className="myBorder grid w-full grid-cols-2 space-y-0.5 px-2 py-1 text-xs md:w-3/5 lg:px-4 lg:py-1.5 lg:text-base lg:font-medium">
            <span>Address: {orderDetails?.userAddress}</span>
            <span>Phone: {orderDetails?.userPhoneNo}</span>
          </div>
          <div className="myBorder w-full px-2 py-1 md:w-1/4 lg:space-y-2 lg:px-4 lg:py-2">
            <div className="flex items-center justify-between border-t-2 border-gray-600 pt-1 text-sm lg:text-base">
              <p>Total cost</p>
              <p className="flex items-center text-sm lg:text-base">
                <TbCurrencyTaka />{orderDetails?.totalAmount}
              </p>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <h3 className="text-xs lg:text-base">
            <span className="font-medium">Order id: #{orderDetails.orderId}</span>
          </h3>

          <h3 className="text-xs lg:text-base">
            <span className="font-medium text-gray-600">
              {orderTimeFormatter(orderDetails?.orderDateTime)}
            </span>
          </h3>
        </div>

        {orderDetails?.confirmByRiderName != null && (
          <>
            Order Confirmed by: {orderDetails?.confirmByRiderName} Phone {orderDetails?.confirmByRiderPhone}
          </>
        )}

        <div className="mx-auto flex max-w-2xl items-center justify-center gap-1 py-1 md:py-2 lg:gap-6">
          {orderDetails?.orderStatus === "Cancelled" ? <p className="Cancelled">Cancelled</p> :
            orderDetails?.orderStatus === "Returned" ? <p className="Returned">Returned</p> :
              <>
                {orderDetails?.orderStatus === "InProgress" || orderDetails?.orderStatus === "Complete" || orderDetails?.orderStatus === "Confirm" ?
                  <p className="InProgress">Pending</p> :
                  <p className="disableBtn">Pending</p>
                }

                {orderDetails?.orderStatus === "Confirm" || orderDetails?.orderStatus === "Complete" ?
                  <p className="Shipped">Confirmed</p> :
                  <p className="disableBtn">Confirmed</p>
                }

                {orderDetails?.shippingStatus === "Shipped" ?
                  <p className="paid">On The Way</p> :
                  <p className="disableBtn">On The Way</p>
                }

                {orderDetails?.orderStatus === "Complete" ?
                  <p className="Completed">Completed</p> :
                  <p className="disableBtn">Completed</p>
                }
              </>
          }
        </div>

        {orderProducts?.map((item: SSGetOrderProductResponse, index: any) => (
          <section key={index} className="myBorder my-2 lg:my-4">
            {/* these is shop info  */}
            <div className="border-b border-grey px-2 py-1 lg:px-4">
              <div className="flex items-center justify-between">
                <h3 className="text-xs lg:text-base">
                  <span className="font-medium">
                    Sold by: {item.brandName}
                  </span>
                </h3>
                <h3 className="text-xs lg:text-base">
                  <span className="font-medium text-royalBlue">
                    {item.brandPhone}
                  </span>
                </h3>
              </div>

              <div className="w-full px-2 lg:px-4">
                <div className="flex items-center justify-between py-1.5">
                  <div className="flex w-[40%] items-center gap-x-1 md:gap-x-4 lg:w-[32%] lg:justify-start">
                    <img
                      onClick={() => navigateToProductDetails(item.productId)}
                      className="h-10 w-10 rounded object-cover md:h-14 md:w-14"
                      src={item?.bannerPath}
                      alt="These is product pic"
                    />
                    <div className="text-xs md:text-base">
                      <h3 className="cursor-pointer" onClick={() => navigateToProductDetails(item.productId)}>
                        {item?.productName}
                      </h3>
                    </div>
                  </div>
                  <div className="flex w-[15%] items-center justify-center text-xs lg:w-[17%] lg:text-base">
                    <span className="flex items-center font-normal md:font-medium">
                      <TbCurrencyTaka /> {item?.unitPrice}
                    </span>
                  </div>
                  <div className="flex w-[15%] items-center justify-center text-xs lg:w-[17%] lg:text-base">
                    <span className=" font-normal md:font-medium">
                      {item?.quantity}
                    </span>
                  </div>
                  <div className="flex w-[15%] items-center justify-center text-xs lg:w-[17%] lg:text-base">
                    <span className="flex items-center font-normal md:font-medium">
                      <TbCurrencyTaka /> {item?.totalPrice}
                    </span>
                  </div>
                  <div className="flex w-[15%] justify-end lg:w-[17%]">
                    {item?.shippingStatus === "InProgress" &&
                      item?.orderStatus !== "Cancelled" && (
                        <AppButton
                          isSubmitting={isButtonLoading}
                          className="resellingOutlineBtn border-red px-2 text-red hover:bg-red lg:px-4"
                          text="Cancel"
                          onClick={() => { handleCancelOne(item) }}
                        />
                      )}
                    {item?.orderStatus === "Cancelled" && (
                      <div className="Cancelled">Cancelled</div>
                    )}
                    {item?.shippingStatus === "Shipped" && item?.orderStatus !== "Cancelled" && item?.orderStatus !== "Returned" && item?.orderStatus !== "Complete" && (
                      <p className="paid">On The Way</p>
                    )}
                    {item?.orderStatus === "Returned" && (
                      <div className="Returned">Returned</div>
                    )}
                    {item?.orderStatus === "Complete" && (
                      <div className="Completed">Completed</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        ))}
      </div>
    </div>
  );
}
