import { getHospitalByIdService, getHospitalService } from "../../../httpService/importantNumbers/hospitalServices";
import { GET_HOSPITAL_NUMBER, GET_HOSPITAL_NUMBER_BY_ID } from "../../constants/importantNumbers/hospital/hospitalConstant";


const getHospitalAction = (payload?: any) => async (dispatch: any) => {
	var data = getHospitalService(payload);
	const pload = (await data).data;
	dispatch({ type: GET_HOSPITAL_NUMBER, payload: pload });
};

const getHospitalByIdAction = (payload?: any) => async (dispatch: any) => {
	var data = getHospitalByIdService(payload);
	const pload = (await data).data;
	dispatch({ type: GET_HOSPITAL_NUMBER_BY_ID, payload: pload });
};


export { getHospitalAction, getHospitalByIdAction };