import { useEffect, useState } from 'react'
import { RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { popularLinkListAction } from '../../../store/actions/popularLink/popularLinkActions';
import { PaginationPayloadModel } from '../../../models/common/paginationModels';
import { AppLoader } from '../../common/AppLoader';
import { Link } from 'react-router-dom';

export default function PopularLinkGetPopularLinks() {

    const dispatch = useDispatch();

    const popularLinkList = useSelector((state: RootState) => {
        return state.popularLinkListResponse.data;
    });

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const paginationModel = new PaginationPayloadModel();
        const fetchData = async () => {
            try {
                setIsLoading(true);
                await dispatch(popularLinkListAction(paginationModel));
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [dispatch]);

    return (
        <>
            {isLoading ? (
                <AppLoader status={true} />
            ) : (
                <>
                    {popularLinkList.items ? (
                        <div className="container mx-auto grid justify-items-center  grid-cols-3 lg:grid-cols-5 2xl:grid-cols-6 gap-x-2 gap-y-2 lg:gap-x-6 lg:gap-y-8 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28">
                            {popularLinkList.items.map((popularLink: any) => (
                                <Link key={popularLink?.id} target='_blank' to={popularLink?.url} className="w-full cursor-pointer rounded text-center flex flex-col items-center border border-grey shadow hover:shadow-lg py-2">
                                    <img src={popularLink?.bannerPath} className="object-cover w-12 lg:w-20 h-12 lg:h-20 rounded-full" alt="popular link img" />
                                    <p className='text-sm lg:text-xl font-semibold'>{popularLink?.name}</p>
                                </Link>
                            ))}
                        </div>
                    ) : (
                        <div className="w-full h-[50dvh] flex justify-center items-center text-3xl text-textBlack/90 font-medium">
                            <span>No PopularLink found</span>
                        </div>
                    )}
                </>)}

        </>
    )
}
