import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AppInput } from '../../../../../common/AppInput';
import { AppButton } from '../../../../../common/AppButton';
import { AppFileInput } from '../../../../../common/AppFileInput';
import { AppSelect } from '../../../../../common/AppSelect';
import { RootState } from '../../../../../../store';
import { sSAddProductService } from '../../../../../../httpService/superShops/ssProductServices';
import { AlertColor, IconButton } from '@mui/material';
import { AppSnackBar } from '../../../../../common/AppSnackBar';
import { AppCheckBox } from '../../../../../common/AppCheckBox';
import { AppDescription } from "../../../../../common/AppDescription";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { SSUpdateProductModel } from '../../../../../../models/superShopp/catalog/sSProduct/sSProductModel';
import { IdNameResponseModel } from '../../../../../../models/common/IdNameModel';
import { PaginationPayloadModel } from '../../../../../../models/common/paginationModels';
import { sSGetMainCategoryListAction } from '../../../../../../store/actions/superShop/sSMainCategoryActions';
import { SSGetSubCategoryListRequest } from '../../../../../../models/superShopp/catalog/sSCategory/ssSubCategoryModel';
import { SSGetProductBrandListRequest } from '../../../../../../models/superShopp/catalog/sSProductBrand/sSProductBrandModel';
import { sSgetProductBrandListAction } from '../../../../../../store/actions/superShop/sSProductBrandAction';
import { sSGetSubCategoryListAction } from '../../../../../../store/actions/superShop/sSSubCategoryActions';

const customerTypeTags = [
  { id: 1, name: 'All' },
  { id: 2, name: 'Men' },
  { id: 3, name: 'Women' },
  { id: 4, name: 'Kid' },
]

export default function SSCreateProduct() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [fullDescription, setFullDescription] = useState('');
  const [producCode, setProducCode] = useState('');
  const [customerTypeTag, setCustomerTypeTag] = useState(0);
  const [isPublished, setIsPublished] = useState(false);
  const [productBrandId, setProductBrandId] = useState(0);
  const [mainCategoryId, setMainCategoryId] = useState(0);
  const [subCategoryId, setSubCategoryId] = useState(0);
  const [price, setPrice] = useState(0);
  const [originalPrice, setOriginalPrice] = useState(0);
  const [bannerId, setBannerId] = useState(0);

  const [snackBarMessage, setsnackBarMessage] = useState('');
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);

  var mainCatList = useSelector((state: RootState) => {
    return state.sSGetMainCategoryListResponse.data;
  });

  var mCatIdNameModels: IdNameResponseModel[] = [];

  mainCatList.items?.map((x: any) => {
    var cat = new IdNameResponseModel;
    cat.id = x.id;
    cat.name = x.name;
    mCatIdNameModels.push(cat);
  })

  useEffect(() => {
    const model = new PaginationPayloadModel();
    model.limit = 10000;
    model.offset = 0;
    dispatch(sSGetMainCategoryListAction(model));
  }, []);

  const subCatList = useSelector((state: RootState) => {
    return state.sSGetSubCategoryListResponse.data;
  });

  var sCatIdNameModels: IdNameResponseModel[] = [];

  subCatList.items?.map((x: any) => {
    var cat = new IdNameResponseModel;
    cat.id = x.id;
    cat.name = x.name;
    sCatIdNameModels.push(cat);
  })

  useEffect(() => {
    const model = new SSGetSubCategoryListRequest();
    model.limit = 10000;
    model.offset = 0;
    model.sSMainCategoryId = mainCategoryId;
    dispatch(sSGetSubCategoryListAction(model));
  }, [mainCategoryId]);

  const pbList = useSelector((state: RootState) => {
    return state.sSProductBrandResponse.data as any;
  });

  var pbIdNameModels: IdNameResponseModel[] = [];

  pbList.items?.map((x: any) => {
    var pb = new IdNameResponseModel;
    pb.id = x.id;
    pb.name = x.name;
    pbIdNameModels.push(pb);
  })

  useEffect(() => {
    const model = new SSGetProductBrandListRequest();
    model.limit = 10;
    model.offset = 0;
    dispatch(sSgetProductBrandListAction(model));
  }, [dispatch]);

  const [bannerPath, setBannerPath] = useState("");

  const handleBanner = (id: any, path: any) => {
    setBannerId(id);
    setBannerPath(path);
    setBannerError("");
  };

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleSubmit = (e: any, isVariationAdd: boolean) => {

    e.preventDefault();

    var model = new SSUpdateProductModel();
    model.name = name;
    model.shortDescription = shortDescription;
    model.fullDescription = fullDescription;
    model.productCode = producCode;
    model.customerTypeTag = customerTypeTag;
    model.isPublished = isPublished;
    model.mainCategoryId = mainCategoryId;
    model.subCategoryId = subCategoryId;
    model.productBrandId = productBrandId;
    model.price = price;
    model.oldPrice = originalPrice;

    setIsButtonLoading(true); 

    sSAddProductService(model)
      .then((res: any) => {
        setIsButtonLoading(false);
        setSnackBarOn(true)
        setsnackBarMessage("Product Created Successfully")
        setSnackBarMessageType('success')
        navigate(`/admin/super-shop/rider/product-list`);
      })
      .catch((error) => {
        setIsButtonLoading(false);
        setNameError(error.response.data.errors?.Name?.[0]);
        setShortDescriptionError(error.response.data.errors?.ShortDescription?.[0]);
        setFullDescriptionError(error.response.data.errors?.FullDescription?.[0]);
        setMainCategoryError(error.response.data.errors?.MainCategoryId?.[0]);
        setSubCategoryError(error.response.data.errors?.SubCategoryId?.[0]);
        setCustomertypeTagError(error.response.data.errors?.CustomerTypeTag?.[0]);
        setProductBrandError(error.response.data.errors?.ProductBrandId?.[0]);
        setBannerError(error.response.data.errors?.BannerId?.[0]);
        setPriceError(error.response.data.errors?.Price?.[0]);
        setOriginalPriceError(error.response.data.errors?.OldPrice?.[0]);
      })
  }
  const [nameError, setNameError] = useState('');
  const [shortDescriptionError, setShortDescriptionError] = useState('');
  const [fullDescriptionError, setFullDescriptionError] = useState('');
  const [productCodeError, setProductCodeError] = useState('');
  const [mainCategoryError, setMainCategoryError] = useState('');
  const [subCategoryError, setSubCategoryError] = useState('');
  const [productBrandError, setProductBrandError] = useState('');
  const [customertypeTagError, setCustomertypeTagError] = useState('');
  const [bannerError, setBannerError] = useState('');
  const [priceError, setPriceError] = useState('');
  const [originalPriceError, setOriginalPriceError] = useState('');

  const handleProdcutName = (value: any) => {
    setName(value)
    setNameError("")
  }

  const handlShortDescription = (value: any) => {
    setShortDescription(value)
    setShortDescriptionError("")
  }

  const handleProdcutFullDescription = (value: any) => {
    setFullDescription(value)
    setFullDescriptionError("")
  }

  const handleProdcutCode = (value: any) => {
    setProducCode(value)
    setProductCodeError("")
  }

  const handleProdcutDisplayPrice = (value: any) => {
    setPrice(value)
    setPriceError("")
  }

  const handleProdcutOriginalPrice = (value: any) => {
    setOriginalPrice(value)
    setOriginalPriceError("")
  }

  const handleMainCategory = (mainCatId: number) => {
    setMainCategoryId(mainCatId);
    setMainCategoryError("");
    setSubCategoryId(0);
  }

  const handleSubCategory = (subCatId: number) => {
    setSubCategoryId(subCatId);
    setSubCategoryError("");
  }

  const handleCustomerTypeTag = (id: any) => {
    setCustomerTypeTag(id);
    setCustomertypeTagError("");
  }

  const handleProductBrand = (id: any) => {
    setProductBrandId(id);
    setProductBrandError("");
  }

  const deleteBanner = () => {
    setBannerId(0);
    setBannerPath("");
    setBannerError("");
  };


  return (
    <div className="lg:px-4 pb-6">

      {snackBarOn &&
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      }

      <h2 className="title text-center">Add Product</h2>

      <div>
        <div className="flex flex-col lg:flex-row justify-between items-center gap-2 lg:gap-12">
          <AppInput
            type='text'
            className="myInput"
            label='Name'
            placeholder='Enter Product Name'
            onChange={handleProdcutName}
            errorMessage={nameError}
          />
        </div>
        <AppInput
          type='text'
          className="myInput"
          label='Short Description'
          placeholder='Enter short Description'
          onChange={handlShortDescription}
          errorMessage={shortDescriptionError}
        />

        <AppDescription
          value={fullDescription}
          label='Full Description'
          onChange={handleProdcutFullDescription}
          errorMessage={fullDescriptionError}
        />

        {/* <div className="grid grid-cols-1 lg:grid-cols-2 py-2 gap-2 lg:gap-8">
          <AppInput
            type='text'
            className="myInput"
            label='Product Code'
            placeholder='Enter Product Code'
            onChange={handleProdcutCode}
            errorMessage={productCodeError}
          />
        </div> */}

        <div className="grid grid-cols-1 lg:grid-cols-2 py-2 gap-2 lg:gap-8">
          {/* <div className="">
            <label className="md:text-base text-sm">Customer Type </label>
            <AppSelect
              defaultValue="Select Customer Type"
              value={customerTypeTag}
              data={customerTypeTags}
              onChange={handleCustomerTypeTag}
              errorMessage={customertypeTagError}
            />
          </div> */}
          <div className="">
            <label className="md:text-base text-sm">Product Brand</label>
            <AppSelect
              defaultValue="Select Customer Type"
              value={productBrandId}
              data={pbIdNameModels}
              onChange={handleProductBrand}
              errorMessage={productBrandError}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 py-2 gap-2 lg:gap-8">
          <div className="">
            <label className="md:text-base text-sm">Select Main Category: </label>
            <AppSelect
              defaultValue="Select Main Category"
              value={mainCategoryId}
              data={mCatIdNameModels}
              onChange={handleMainCategory}
              errorMessage={mainCategoryError}
            />
          </div>
          {(mainCategoryId !== 0) &&
            <div className="">
              <label className="md:text-base text-sm">Sub Category: </label>
              <AppSelect
                defaultValue="Select Sub Category "
                value={subCategoryId}
                data={sCatIdNameModels}
                onChange={handleSubCategory}
                errorMessage={subCategoryError}
              />
            </div>
          }
        </div>

        <div className="flex justify-center items-center py-2">
          <div className="flex items-center gap-x-2">
            <label htmlFor="isPublished" className="text-xl">Is Published </label>
            <AppCheckBox
              isChecked={isPublished}
              onChange={(value: any) => setIsPublished(value)}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 py-2 gap-2 lg:gap-8">
        <div>
          <AppInput
            className="myInput"
            type='number'
            label='Wholesale Price'
            placeholder='Enter Your Product Price'
            onChange={handleProdcutDisplayPrice}
            errorMessage={priceError}
          />
        </div>
        <div>
          <AppInput
            className="myInput"
            type='number'
            label='Retail Price'
            placeholder='Enter Your Product Price'
            onChange={handleProdcutOriginalPrice}
            errorMessage={originalPriceError}
          />
        </div>
      </div>

      <div>
        <div className='flex items-center gap-1'>
          <h3 className="text">Upload Photo</h3>
          <AppFileInput
            onSave={handleBanner}
            multiSelect={true}
            errorMessage={bannerError} />
        </div>
        <div className="flex items-center gap-x-2">
          {bannerId !== 0 && (
            <>
              <img className="w-60 lg:w-80 h-40 lg:h-60 object-contain" src={bannerPath} alt="product img" />
              <IconButton onClick={deleteBanner}>
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </div>
      </div>

      {/* these is save button  */}
      <div className="flex items-center justify-between lg:justify-end gap-4 py-2">
        <AppButton text='Save' variant="contained" isSubmitting={isButtonLoading} color="primary" onClick={(e: any) => handleSubmit(e, false)} />
      </div>

    </div>
  )
}

