import { AlertColor } from "@mui/material/Alert";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AppButton } from "../../common/AppButton";
import { AppInput } from "../../common/AppInput";
import { AppSnackBar } from "../../common/AppSnackBar";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { AddUpdateEmailPhoneModel, VerifyVerificationCodeModel } from "../../../models/auth/user/UserModels";
import { addUpdateEmailOrPhone, emailOrPhoneSendConfirmationCodeService, emailOrPhoneVerifyConfirmationCodeService } from "../../../httpService/user/userServices";

export default function AddUpdateEmailOrPhone() {

    const navigate = useNavigate();

    const { search } = useLocation();

    const { phone, email } = useMemo(() => {
        const params = new URLSearchParams(search);
        return {
            phone: params.get('phone') || '',
            email: params.get('email') || '',
        };
    }, [search]);

    const [emailOrPhone, setEmailOrPhone] = useState("");
    const [otp, setOtp] = useState("");

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isOtpValid, setIsOtpValid] = useState(false);

    const [emailOrPhoneError, setEmailOrPhoneError] = useState("");
    const [otpError, setOtpError] = useState("");

    const [countdown, setCountdown] = useState(60);
    const [canResendOTP, setCanResendOTP] = useState(false);

    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const [isShowSnackBar, setIsShowSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setConfirmShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmShowPassword(!showConfirmPassword);
    };

    useEffect(() => {
        let timer: any;
        if (countdown > 0) {
            timer = setTimeout(() => setCountdown(countdown - 1), 1000);
        } else {
            setCanResendOTP(true);
        }
        return () => clearTimeout(timer);
    }, [countdown]);


    const handleEmailOrPhoneChange = (event: any) => {
        setEmailOrPhone(event);
        setEmailOrPhoneError("");
    };

    const handleOtpChange = (event: any) => {
        setOtp(event);
        setOtpError("");
    };

    const handlePasswordChange = (event: any) => {
        setPassword(event);
        setPasswordError("");
    };

    const handleConfirmPasswordChange = (event: any) => {
        setConfirmPassword(event);
        setConfirmPasswordError("");
    };

    const [isEmailVerificationButtonLoading, setIsEmailVerificationButtonLoading] = useState(false);
    const [isResendOtpButtonLoading, setResendOtpButtonLoading] = useState(false);

    const handleSendEmailVerificationCode = (event: any) => {

        if (!isValidPhoneNumber(emailOrPhone) && search.includes("phone")) {
            setIsShowSnackBar(true);
            setSnackBarMessage("Please Enter Phone Number ");
            setSnackBarMessageType("error");
            setTimeout(() => {
                setIsShowSnackBar(false);
            }, 2000);

            return
        }

        else if (!isValidEmail(emailOrPhone) && search.includes("email")) {
            setIsShowSnackBar(true);
            setSnackBarMessage("Please Enter Email");
            setSnackBarMessageType("error");
            setTimeout(() => {
                setIsShowSnackBar(false);
            }, 2000);

            return
        }

        else {
            event.preventDefault();
            setIsEmailVerificationButtonLoading(true)
            setResendOtpButtonLoading(true)
            emailOrPhoneSendConfirmationCodeService(emailOrPhone)
                .then(() => {
                    setIsEmailValid(true);
                    setCountdown(60);
                    setCanResendOTP(false);

                    setIsEmailVerificationButtonLoading(false)
                    setResendOtpButtonLoading(false)

                    setIsShowSnackBar(true);
                    setSnackBarMessage("OTP Send Successfully");
                    setSnackBarMessageType("success");
                    setTimeout(() => {
                        setIsShowSnackBar(false);
                    }, 2000);

                }).catch((error: any) => {
                    setIsEmailVerificationButtonLoading(false)
                    setResendOtpButtonLoading(false)
                    setEmailOrPhoneError(error.response.data.errors?.EmailOrPhone?.[0]);
                })
        }

    };


    const [isVerifyOtpButtonLoading, setVerifyOtpButtonLoading] = useState(false);

    const handleVerifyOTP = (event: any) => {
        event.preventDefault();

        var model = new VerifyVerificationCodeModel();
        model.emailOrPhone = emailOrPhone;
        model.code = otp;
        setVerifyOtpButtonLoading(true)
        emailOrPhoneVerifyConfirmationCodeService(model)
            .then(() => {
                setIsOtpValid(true);
                setVerifyOtpButtonLoading(false)
                setIsShowSnackBar(true);
                setSnackBarMessage("Submit Code Successfully");
                setSnackBarMessageType("success");
                setTimeout(() => {
                    setIsShowSnackBar(false);
                }, 2000);
            }).catch((error: any) => {
                setVerifyOtpButtonLoading(false)
                setOtpError(error.response.data.errors?.EmailOrPhone?.[0])
                // setOtpError(error.response.data.errors?.Code?.[0])
            })
    };

    const [isSubmitButtonLoading, setSubmitButtonLoading] = useState(false);

    const handleSubmit = () => {
        var model = new AddUpdateEmailPhoneModel();
        model.emailOrPhone = emailOrPhone;
        model.password = password;
        setSubmitButtonLoading(true)
        addUpdateEmailOrPhone(model)
            .then(() => {
                setIsShowSnackBar(true);
                setSnackBarMessage("Update Email or Phone Successfully");
                setSnackBarMessageType("success");
                setTimeout(() => {
                    setIsShowSnackBar(false);
                }, 2000);
                setSubmitButtonLoading(false)
                navigate("/profile");
            }).catch((error: any) => {
                setSubmitButtonLoading(false)
                setPasswordError(error.response.data.errors?.Password?.[0])
            })
    }


    function isValidPhoneNumber(phoneNumber: any) {
        // Define a regular expression to match against the phone number format
        const phoneRegex = /^01[0-9]{9}$/;
        // Test the phone number against the regex pattern and return the result
        return phoneRegex.test(phoneNumber);
    }


    function isValidEmail(email: any) {
        // regular expression to validate email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // test the email against the regex pattern
        return emailRegex.test(email);
    }


    return (
        <>
            {isShowSnackBar && (
                <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
            )}
            {/* these is main container  */}
            <div className="container mx-auto py-16">
                {/* these is body of reset pass  */}
                <div className="w-full md:w-3/5 rounded mx-auto md:shadow-lg p-6">
                    <h4 className="subTitle text-center pb-2 text-textBlack">{search.includes("email") ? "Update Email Account" : "Update Phone number"}</h4>
                    <div className="flex flex-col md:px-20 py-4">
                        <AppInput
                            type={search.includes("email") ? "email" : "number"}
                            value={emailOrPhone}
                            label={search.includes("email") ? "Email Account" : "Phone number"}
                            className="myInput mb-3"
                            placeholder={search.includes("email") ? "Enter your email" : "Enter your Phone number"}
                            onChange={handleEmailOrPhoneChange}
                            errorMessage={emailOrPhoneError}
                            isDisabled={isEmailValid ? true : false}
                        />
                        {!isEmailValid && (
                            <AppButton
                                text="Send Verification Code"
                                className="myBtn"
                                onClick={handleSendEmailVerificationCode}
                                isSubmitting={isEmailVerificationButtonLoading}
                            />
                        )}

                        {(isEmailValid && !isOtpValid) && (
                            <>
                                <div>
                                    <p className="text-center text-xl font-medium text-gray-700">OTP</p>
                                    <AppInput
                                        className="py-2 flex text-center text-xl"
                                        type="text"
                                        maxLength={6}
                                        placeholder="X X X X X X"
                                        onChange={(event: any) => handleOtpChange(event)}
                                        errorMessage={otpError}
                                    />
                                </div>
                                {countdown != 0 && (
                                    <span className="text-sm text-gray-600">Can resend OTP after {countdown} seconds</span>
                                )}
                                {countdown == 0 && (
                                    <AppButton
                                        text="Resend OTP"
                                        isSubmitting={isResendOtpButtonLoading}
                                        className="myBtn"
                                        onClick={handleSendEmailVerificationCode}
                                    />
                                )}
                                <AppButton
                                    text="Verify OTP"
                                    isSubmitting={isVerifyOtpButtonLoading}
                                    className="myBtn mt-3"
                                    onClick={handleVerifyOTP} />
                            </>
                        )}

                        {isOtpValid && (
                            <>
                                <div className="relative pt-2">
                                    <AppInput
                                        type={showPassword ? "text" : "password"}
                                        className="myInput"
                                        label="Password"
                                        placeholder="Enter your Password"
                                        value={password}
                                        onChange={handlePasswordChange}
                                        errorMessage={passwordError}
                                    />


                                    {showPassword ? (
                                        <AiOutlineEyeInvisible
                                            className="absolute text-2xl text-gray-700 font-bold cursor-pointer right-3 top-[55%]"
                                            onClick={togglePasswordVisibility}
                                        />
                                    ) : (
                                        <AiOutlineEye
                                            className="absolute text-2xl text-gray-700 font-bold cursor-pointer right-3 top-[55%]"
                                            onClick={togglePasswordVisibility}
                                        />
                                    )}
                                </div>

                                {/* <div>
                                        <p className="s-u-para">Confirm Password</p>
                                        <div className="visibility-input-field-pass">
                                            <div className="sign-up-input-field-pass">
                                                <AppInput
                                                    type={showConfirmPassword ? "text" : "password"}
                                                    className="s-u-input-field-pass"
                                                    placeholder="Enter your Password"
                                                    value={confirmPassword}
                                                    onChange={handleConfirmPasswordChange}
                                                    errorMessage={confirmPasswordError}
                                                />
                                            </div>
                                            <div className="sign-up-pass-visibility">
                                                {showConfirmPassword ? (
                                                    <VisibilityOffIcon
                                                        style={{ fontSize: "1.2rem", color: "#333" }}
                                                        className="visibility-icon"
                                                        onClick={toggleConfirmPasswordVisibility}
                                                    />
                                                ) : (
                                                    <VisibilityIcon
                                                        style={{ fontSize: "1.2rem", color: "#333" }}
                                                        className="visibility-icon"
                                                        onClick={toggleConfirmPasswordVisibility}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div> */}

                                <AppButton
                                    text="Submit"
                                    isSubmitting={isSubmitButtonLoading}
                                    className="myBtn mt-3"
                                    onClick={handleSubmit}
                                />
                            </>
                        )}

                    </div>
                </div>

                {/* <div className="reset-passemail-reset2">
            <p className="reset-passemail-input-txt">
              Yes! Send me exclusive offers, unique gift ideas, and personalized
              tips for on BlackTree.
            </p>

            <div className="reset-passemail-input">
              <AppInput
                type="text"
                className="reset-passemail-input-2"
                placeholder="Enter your email"
                onChange={(value: any) => setEmailOrPhone(value)}
              />

              <AppButton
                onClick={subscribeHandler}
                className="reset-passemail-Subscribe"
                text={"Subscribe"}
              />
            </div>
          </div> */}
            </div>
        </>
    );
}