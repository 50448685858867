import { GET_ADMIN_DASHBOARD, GET_RIDER_DASHBOARD, GET_UP_ADMIN_DASHBOARD, GET_VENDOR_DASHBOARD } from "../../constants/dashboard/dashboardConstant";

export function getAdminDashboardReducer(state = { data: {} }, action: any) {
  switch (action.type) {
    case GET_ADMIN_DASHBOARD:
      return { loading: false, data: action.payload };
    default:
      return state;
  }
}

export function getVendorDashboardReducer(state = { data: {} }, action: any) {
  switch (action.type) {
    case GET_VENDOR_DASHBOARD:
      return { loading: false, data: action.payload };
    default:
      return state;
  }
}

export function getUpAdminDashboardReducer(state = { data: {} }, action: any) {
  switch (action.type) {
    case GET_UP_ADMIN_DASHBOARD:
      return { loading: false, data: action.payload };
    default:
      return state;
  }
}

export function getRiderDashboardReducer(state = { data: {} }, action: any) {
  switch (action.type) {
    case GET_RIDER_DASHBOARD:
      return { loading: false, data: action.payload };
    default:
      return state;
  }
}
