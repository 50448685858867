import { ResellingProductContainer } from "./ResellingProductContainer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { reactLocalStorage } from "reactjs-localstorage";
import {
  getIsPurchasedAction,
  getIsTrainingVideoWatchedAction,
} from "../../../store/actions/training/trainingAction";
import { AppModal } from "../../common/AppModal";
import { RootState } from "../../../store";
import { Link, useNavigate } from "react-router-dom";
import { HomeProductFilterEnum } from "../../../models/catalog/product/productsModels";
import { isAdmin, isRoleExist, isVendor } from "../../../common/common";

const ResellingHomeComponent = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ip = reactLocalStorage.get("ip");

  useEffect(() => {
    if (!ip) {
      axios
        .get("https://api.ipify.org?format=json")
        .then((response) => {
          reactLocalStorage.set("ip", response.data.ip);
        })
        .catch((error) => { });
    }
  }, [dispatch, ip]);

  const isRoleHave = isRoleExist(reactLocalStorage.get("RoleName"))

  useEffect(() => {
    if (
      isAdmin(reactLocalStorage.get("RoleName")) ||
      isVendor(reactLocalStorage.get("RoleName"))
    ) {
      navigate(`/admin`);
    }
  }, [dispatch, navigate, isRoleHave]);

  const isAllTrainingVideoNotWatched = useSelector((state: RootState) => {
    return state.getIsTrainingVideoWatchedResponse.data;
  });

  useEffect(() => {
    dispatch(getIsTrainingVideoWatchedAction(1));
  }, [dispatch]);

  const isPurchased = useSelector((state: RootState) => {
    return state.getIsPurchasedResponse.data;
  });

  useEffect(() => {
    dispatch(getIsPurchasedAction(1));
  }, [dispatch]);

  const [currentFilter, setCurrentFilter] = useState(0);
  const [filterOn, setFilterOn] = useState(true);

  const handleProductFilter = (type: HomeProductFilterEnum) => {
    setFilterOn(false);
    setTimeout(() => {
      setFilterOn(true);
    }, 1000);
    setCurrentFilter(type);
  };

  const productFilter = [
    {
      text: "New Upload",
      handler: () => handleProductFilter(HomeProductFilterEnum.NewUpload),
      img: "/assets/Reselling/New Uploade.png",
    },
    {
      text: "Top Sold",
      handler: () => handleProductFilter(HomeProductFilterEnum.TopSold),
      img: "/assets/Reselling/Top Sold.png",
    },
    {
      text: "Top Commented",
      handler: () => handleProductFilter(HomeProductFilterEnum.TopReviewed),
      img: "/assets/Reselling/Top Comment.png",
    },
    {
      text: "Top Viewed",
      handler: () => handleProductFilter(HomeProductFilterEnum.TopViewed),
      img: "/assets/Reselling/Top Viewed.png",
    },
    {
      text: "Top Favourites",
      handler: () => handleProductFilter(HomeProductFilterEnum.TopFavourites),
      img: "/assets/Reselling/Favourite.png",
    },
  ];

  return (
    <div className="container mx-auto pt-2 pb-4 md:pb-6 md:pt-4 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28">
      
      {!isPurchased && (
        <AppModal>
          <div className="flex flex-col-reverse items-center justify-between gap-4 px-4 py-12 md:flex-row md:px-20">
            <div className="flex flex-col items-center gap-3 md:items-start">
              <h2 className="text-xl font-semibold text-royalBlue md:max-w-md md:text-3xl">
                Please Purchase Your Training First
              </h2>
              <div className="myBtn w-fit border-royalBlue bg-royalBlue py-1.5" onClick={() => { }}>
                <Link to="/training">Buy Now</Link>
              </div>
            </div>
            <img
              className="h-40 w-60 object-contain md:h-48 md:w-72"
              src="https://res.cloudinary.com/ddqenwff0/image/upload/v1687276368/Group_cmdbqn.png"
              alt=""
            />
          </div>
        </AppModal>
      )}

      {isAllTrainingVideoNotWatched && (
        <AppModal>
          <div className="flex flex-col-reverse items-center justify-between gap-4 px-4 py-12 md:flex-row md:px-20">
            <div className="flex flex-col items-center gap-3 md:items-start">
              <h2 className="text-xl font-semibold md:max-w-md md:text-3xl">
                Please Complete your Training First
              </h2>
              <div className="myBtn w-fit py-1.5" onClick={() => { }}>
                <Link to="/trainingPlaylist/1">Go To Training</Link>
              </div>
            </div>
            <img
              className="h-40 w-60 object-contain md:h-48 md:w-72"
              src="https://res.cloudinary.com/ddqenwff0/image/upload/v1687276368/Frame_2_dncct1.png"
              alt=""
            />
          </div>
        </AppModal>
      )}

      {/* these is home top section  */}
      <div className="flex gap-2 lg:gap-4 h-[16vh] lg:h-[50vh] box-border">
        <img
          src="/assets/Reselling/Reselling_h_w.png"
          className="w-full rounded lg:rounded-xl object-fill"
          alt="These is home banner"
          loading="lazy"
        />
        {/* <img
          src="/assets/Reselling/Data offar.png"
          className="w-[30%] rounded lg:rounded-xl object-fill"
          alt="These is home banner"
          loading="lazy"
        /> */}
      </div>

      <div className="grid grid-cols-5 pt-2 lg:px-16 2xl:px-20 lg:pb-4 lg:pt-4">
        {productFilter.map((product) => (
          <div
            key={product?.text}
            onClick={product?.handler}
            className="centerDiv cursor-pointer flex-col gap-1 lg:gap-0.5"
          >
            <div className='bg-gray-200 centerDiv p-2 lg:p-6 rounded-full'>
              <img
                className="w-10 lg:w-24 2xl:w-24 h-10 lg:h-24 2xl:h-24"
                src={product?.img}
                alt={`This for ${product?.text}`}
              />
            </div>
            <h2 className="select-none text-center text-xxs text-black md:text-xs lg:text-lg lg:font-semibold 2xl:text-xl">
              {product?.text}
            </h2>
          </div>
        ))}
      </div>

      {filterOn && <ResellingProductContainer type={currentFilter} />}
    </div>
  );
};

export default ResellingHomeComponent;
