export class CreateDataOfferRequest {
    dataOfferType?: DataOfferType;
    mobileOperator?: MobileOperator;
    dataPackageType?: DataPackageType;
    price?: any;
    validityDuration?: any;
    title?: any;
    description?: any;
    isActive?: any;
}

export class UpdateDataOfferRequest {
    id?: any;
    dataOfferType?: DataOfferType;
    mobileOperator?: MobileOperator;
    dataPackageType?: DataPackageType;
    price?: any;
    validityDuration?: any;
    title?: any;
    description?: any;
    isActive?: any;
}

export enum DataOfferType {
    All = 0,
    AjkerOffer = 1,
    ProtidinerOffer = 2
}

export enum DataOrderStatus {
    All = 0,
    InProgress = 1,
    Declined = 2,
    Accepted = 3,
    Confirm = 4
}

export enum DataPackageType {
    All = 0,
    Minutes = 1,
    Internet = 2,
    CallRate = 3,
    CashBack = 4,
    Bundle = 5
}

export enum MobileOperator {
    All = 0,
    GrameenPhone = 1,
    BanglaLink = 2,
    Robi = 3,
    Airtel = 4,
    Skitto = 5,
    Teletalk = 6
}