import { GET_DISTRICT_LIST } from "../../../constants/auth/address/AddressConstants";

function districtListReducer(state= { data: []} , action: any) {
    switch (action.type) {
        case GET_DISTRICT_LIST :
            //return {...state, loading: false, data: action.payload };
            return {loading: false, data: action.payload };
        default:
            return state;
    }
}



export { districtListReducer }