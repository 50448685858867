import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import { RootState } from "../../../../../../store";
import { ProductReviewCustomerResponse } from "../../../../../../models/catalog/productReview/productReviewModels";
import { FiDownload } from "react-icons/fi";
import { HiCheck } from "react-icons/hi";
import { TbCurrencyTaka } from "react-icons/tb";
import { resellingGetProductByIdAction } from "../../../../../../store/actions/catalog/resellingProductActions";
import { AdminGetCustomerReviews } from "../../../../../admin/catalog/product/AdminGetCustomerReviews";

export default function SSGetProductById() {

  const [titleBannerPath, setTitleBannerPath] = useState("");

  const dispatch = useDispatch();

  const productReviews = useSelector((state: RootState) => {
    return state.productReviewListResponse.data as ProductReviewCustomerResponse[];
  });

  const { id } = useParams();

  useEffect(() => {
    localStorage.setItem('shouldMaintainPage', 'true');
  }, []);

  const productDetailsRed = useSelector((state: RootState) => {
    return state.resellingGetProductByIdResponse.data.data;
  });

  const productDetails = useMemo(() => {
    return productDetailsRed;
  }, [productDetailsRed]);

  useEffect(() => {
    dispatch(resellingGetProductByIdAction(id));
  }, [dispatch]);


  useEffect(() => {
    if (productDetails) {
      setTitleBannerPath(productDetails.bannerPath)
    }
  }, [productDetails]);

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const affiliateRefValue = searchParams.get("affiliateRef");
    if (affiliateRefValue)
      reactLocalStorage.set("affiliateRef", affiliateRefValue);

    const affiliateProductRefValue = searchParams.get("affiliateProductRef");
    if (affiliateProductRefValue)
      reactLocalStorage.set("affiliateProductRef", affiliateProductRefValue);
  }, [location]);


  const handleProductMainPhotoCahange = (path: any) => {
    setTitleBannerPath(path);
  };

  const handleDownload = async () => {
    const response = await fetch(productDetails?.bannerPath);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'picture.jpg';
    link.click();

    URL.revokeObjectURL(url);
  };

  return (
    <div className="container mx-auto px-2 md:px-0 pb-12">

      {/* product photo and details start here  */}
      <div className="md:pt-4 pt-2 flex flex-col md:flex-row gap-y-4 gap-x-8">
        {/* these is product photo section */}
        <div className="w-full md:w-1/2 flex flex-col gap-y-2 md:gap-y-4">

          {/* large photo section here  */}
          <div className="relative w-full md:h-[58dvh] h-[35dvh] bg-white myBorder">
            <div className="absolute top-1 md:top-3 right-1 md:right-3 text-primary font-bold md:text-xl myBorder p-1 md:p-1.5 bg-emerald-200 cursor-pointer" title="Download image" onClick={handleDownload} ><FiDownload /></div>
            <img className="object-contain w-full h-full" src={titleBannerPath != "" ? titleBannerPath : productDetails?.bannerPath} alt="main big product pic" />
          </div>
          {/* small photos section here  */}
          <div className="w-full flex gap-x-2 md:gap-x-4 md:h-[18dvh] h-[8dvh]">
            {productDetails?.bannerPathList &&
              productDetails?.bannerPathList?.map((x: any, index: any) => {
                return (
                  <div
                    className="cursor-pointer bg-white myBorder"
                    key={index}
                    onClick={() => handleProductMainPhotoCahange(x)}
                  >
                    <img className="object-contain rounded w-full h-full" src={x} alt="" />
                  </div>
                );
              })}
          </div>
        </div>

        {/* product details section beside product photo start here  */}
        <div className="w-full md:w-1/2">
          <p className="text-royalBlue text-xl cursor-pointer font-medium 2xl:pb-2 pb-1">{productDetails?.vendorName}</p>
          <h2 className="2xl:text-2xl text-lg md:text-xl 2xl:pb-2 pb-1 font-medium"> {productDetails?.name} </h2>

          {/* rating reviews sold section here  */}
          <div className="flex items-center justify-between md:max-w-md max-w-sm">
            {/* reviews section here  */}
            <span className="font-medium">{productReviews?.length} reviews</span>
            {/* sold section here  */}
            <span className="font-medium">{productDetails?.totalSold} sold</span>
            {/* rating section here  */}
            {/* {productDetails?.rating && ( */}
            <div className="font-medium">

              {(productDetails?.rating > 0 && productDetails?.rating <= 1) && (
                <img
                  src="https://res.cloudinary.com/ddqenwff0/image/upload/v1678478594/Star_17_paiezv.png"
                  alt=""
                />
              )}

              {(productDetails?.rating > 1 && productDetails?.rating <= 2) && (
                <img
                  src="https://res.cloudinary.com/ddqenwff0/image/upload/v1678478594/Group_2631_xtm5u7.png"
                  alt=""
                />
              )}

              {(productDetails?.rating > 2 && productDetails?.rating <= 3) && (
                <img
                  src="https://res.cloudinary.com/ddqenwff0/image/upload/v1678478595/Group_2632_ksjv3i.png"
                  alt=""
                />
              )}

              {(productDetails?.rating > 3 && productDetails?.rating <= 4) && (
                <img
                  src="https://res.cloudinary.com/ddqenwff0/image/upload/v1678478594/Group_2633_ediwi7.png"
                  alt=""
                />
              )}

              {(productDetails?.rating > 4 && productDetails?.rating <= 5) && (
                <img
                  src="https://res.cloudinary.com/ddqenwff0/image/upload/v1678478594/Group_2634_kn5jp8.png"
                  alt=""
                />
              )}

              {(productDetails?.rating >= 0 && productDetails?.rating != 0) && (
                <>{productDetails?.rating}</>
              )}

            </div>
            {/* )} */}
          </div>

          {/* in stock green section here  */}
          {productDetails?.availableStock > 0 && (
            <div className="bg-[#D1FFF5] text-primary 2xl:text-lg text-base flex items-center font-medium px-2 2xl:my-2 my-1 rounded w-fit gap-x-2">
              <HiCheck /> in stock
            </div>
          )}

          {/* in stock red section here  */}
          {productDetails?.availableStock <= 0 && (
            <div className="bg-[#FF7474] text-white 2xl:text-lg text-base flex items-center font-medium px-2 2xl:my-2 my-1 rounded w-fit gap-x-2">
              <HiCheck /> Out of stock
            </div>
          )}

          {/* price and offer section here  */}
          <div className="flex gap-x-8 2xl:pb-2 pb-1 items-center">
            <h2 className="2xl:text-xl text-xl font-medium flex 2xl:gap-x-1 gap-x-[1px] items-center">  <TbCurrencyTaka /> {productDetails?.price}</h2>
            <h2 className="2xl:text-xl text-xl font-medium line-through flex 2xl:gap-x-1 gap-x-[1px] items-center">  <TbCurrencyTaka />{productDetails?.regularPrice}</h2>
          </div>

          <div className="flex flex-col 2xl:gap-y-1 gap-y-[2px]">
            <h2 className="subTitle">Description</h2>
            <p className="max-w-xl">
              <div dangerouslySetInnerHTML={{ __html: productDetails?.fullDescription }} />
            </p>
          </div>
        </div>
      </div>
      <AdminGetCustomerReviews productReviews={productReviews} />
    </div>
  );
}
