import { SSAddOrRemoveFavouriteRequest, SSGetFavouriteItemsRequest, SSGetIsAlreadyFavouriteOrNotRequest } from "../../models/superShopp/favourite/sSFavouriteModel";
import { GET, POST } from "../service";

export const sSAddOrRemoveFavouriteService = (model: SSAddOrRemoveFavouriteRequest) => {
    return POST(`/api/SSFavourite/SSAddOrRemoveFavourite`, model);
};

export const sSGetFavouriteItemsService = (model?: SSGetFavouriteItemsRequest) => {
    return GET(`/api/SSFavourite/SSGetFavouriteItems?userId=${model?.userId}&searchQuery=${model?.searchQuery}`);
};

export const sSGetIsAlreadyFavouriteOrNotService = (model?: SSGetIsAlreadyFavouriteOrNotRequest) => {
    return GET(`/api/SSFavourite/SSGetIsAlreadyFavouriteOrNot?ProductId=${model?.productId}`);
};