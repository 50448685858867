import './AppDefaultProfilePicture.css'

interface IDefaultProfilePictureProps {
    name?: string;
    profilePicture?: any
    className?: string
}

export const AppDefaultProfilePicture: React.FC<IDefaultProfilePictureProps> = ({ name = "", profilePicture = "", className }) => {

    const colorClass = getColorClass(name?.charAt(0));

    return (
        <div className={className ? className : "default-class"}>
            {profilePicture ? (
                <img className='rounded' src={profilePicture} alt={`${name}'s profile pic`} />
            ) : (
                <div className={`rounded select-none cursor-pointer 2xl:w-[50px] 2xl:h-[50px] lg:w-10 w-8 h-8 lg:h-10 text-2xl text-white flex items-center justify-center font-semibold no-profile-picture ${colorClass}`}>
                    {name?.charAt(0).toUpperCase()}
                </div>
            )}
        </div>
    );
}


function getColorClass(char: string): string {
    const colors = [
        "color1",
        "color2",
        "color3",
        "color4",
        "color5",
        "color6",
        "color7",
        "color8",
        "color9",
        "color10",
    ];

    const index = char?.charCodeAt(0) % colors.length;
    return colors[index];
}