import { SSGetBazarListRequest } from "../../models/superShopp/Address/sSBazar/sSBazarModel";
import { DELETE, GET, POST, PUT } from "../service";

export const sSaddBazarService = (data: any) => {
  return POST("/api/sSBazar/sSCreateBazar", data);
};

export const sSdeleteBazarService = (data: any) => {
  return DELETE(`/api/sSBazar/sSRemoveBazar`, {
    data: { "ids": data },
  });
};

export const sSgetBazarListService = (payload?: SSGetBazarListRequest) => {
  return GET(
    `/api/sSBazar/sSGetBazarList?divisionId=${payload?.divisionId}&districtId=${payload?.districtId}&areaId=${payload?.areaId}&isAll=${payload?.isAll}&forPublic=${payload?.forPublic}&limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}`
  );
};

export const sSGetBazarByIdService = (id: any) => {
	return GET(`/api/SSBazar/SSGetBazarById?Id=${id}`);
};


export const sSupdateBazarService = (data: any) => {
  return PUT(`/api/sSBazar/sSUpdateBazar`, data);
};
