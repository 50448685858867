import { AlertColor } from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import { TbCurrencyTaka } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { reactLocalStorage } from "reactjs-localstorage";
import { GetUserWalletResponse } from "../../../../models/auth/user/UserPointModels";
import { ShoppingCartType } from "../../../../models/shoppingCart/resellingCartModels";
import { RootState } from "../../../../store";
import { getUserWalletAction } from "../../../../store/actions/auth/user/userActions";
import { getCartItemListAction } from "../../../../store/actions/cart/resellingCartActions";
import { AppButton } from "../../../common/AppButton";
import { AppInput } from "../../../common/AppInput";
import { AppLoader } from "../../../common/AppLoader";
import { AppScrollBar } from "../../../common/AppScrollBar";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { SSSubmitOrderProductRequest, SSSubmitOrderRequest } from "../../../../models/superShopp/order/sSOrderModel";
import { sSGetCartItemsAction, sSGetTotalCartItemAction } from "../../../../store/actions/superShop/sSCartActions";
import { sSSubmitOrderWithMainBalanceService } from "../../../../httpService/superShops/ssOrderServices";
import { useParams } from "react-router";
import { SSGetCartItemsRequest } from "../../../../models/superShopp/cart/sSCartModel";
import { AppModal } from "../../../common/AppModal";

export default function SSSubmitOrder() {

  const { bazarId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");

  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [phoneNo, setPhoneNo] = useState("");
  const [phoneError, setPhoneNoError] = useState("");

  const [address, setAddress] = useState("");
  const [addressError, setaddressError] = useState("");

  const [cartItems, setCartItems] = useState<any[]>([]);
  const [orderItems, setOrderItems] = useState<SSSubmitOrderProductRequest[]>([]);

  const [totalPrice, setTotalPrice] = useState(0);

  var token = reactLocalStorage.getObject("SignIn");

  useEffect(() => {
    dispatch(getCartItemListAction(ShoppingCartType.ShoppingCart));
  }, [dispatch]);

  const handleFirstName = (value: string) => {
    setFirstName(value);
    setFirstNameError("")
  };

  const handleLastName = (value: string) => {
    setLastName(value);
  };

  const handlePhoneNo = (value: string) => {
    setPhoneNo(value);
    setPhoneNoError("")
  };

  const handleEmail = (value: string) => {
    setEmail(value)
    setEmailError("")
  };

  const handleAddress = (value: string) => {
    setAddress(value)
    setaddressError("")
  };

  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

  function isValidPhoneNumber(phoneNumber: any) {
    const phoneRegex = /^01[0-9]{9}$/;
    return phoneRegex.test(phoneNumber);
  }

  function isValidEmail(email: any) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  var token = reactLocalStorage.getObject("SignIn");

  const cartItemsSignIn = useSelector((state: RootState) => {
    return state.sSGetCartItemsResponse.data;
  });

  const cartItemsMemo = useMemo(() => {
    return cartItemsSignIn;
  }, [cartItemsSignIn]);

  useEffect(() => {
    if (token && JSON.stringify(token) !== JSON.stringify({})) {
      if (cartItemsMemo) {
        setCartItems(cartItemsMemo);
      }
    } else {
    }
  }, [cartItemsMemo, token]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        let model = new SSGetCartItemsRequest();
        model.bazarId = bazarId;
        await dispatch(sSGetCartItemsAction(model));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    getTotalPrice();
  }, [cartItems]);

  const getTotalPrice = () => {
    let p = 0;
    if (cartItems && JSON.stringify(cartItems) !== JSON.stringify({})) {
      cartItems?.filter(_ => _.isSelected)?.map((x: any) => {
        return p = p + x.totalPrice;
      });
      setTotalPrice(p);
    }
  };

  const [isConfirmOrderButtonLoading, setIsConformOrderButtonLoading] = useState(false);
  const [isSelectedCashOnDelivery, setIsSelectedCashOnDelivery] = useState(false);

  const selectCashOnDelivery = () => {
    setIsSelectedCashOnDelivery(true)
  }

  const selectFullPayment = () => {
    setIsSelectedCashOnDelivery(false)
  }

  const wallet = useSelector((state: RootState) => {
    return state.getuserWalletResponse.data as GetUserWalletResponse;
  });

  useEffect(() => {
    dispatch(getUserWalletAction(0));
  }, [dispatch]);

  const handleSubmitWithMainBalance = () => {

    let canExecute = true;

    if (!firstName) {
      setFirstNameError("First Name Is Required");
      canExecute = false;
    }
    if (!phoneNo) {
      setPhoneNoError("Phone no is required");
      canExecute = false;
    }
    if (!isValidPhoneNumber(phoneNo)) {
      setPhoneNoError("Phone no is invalid");
      canExecute = false;
    }

    if (!isValidEmail(email) && email) {
      setEmailError("Email is invalid");
      canExecute = false;
    }
    if (!address) {
      setaddressError("Address line one is required");
      canExecute = false;
    }

    if (canExecute) {

      var model = new SSSubmitOrderRequest();

      model.userFirstName = firstName;
      model.userLastName = lastName;
      model.userPhoneNo = phoneNo;
      model.userEmail = email;
      model.userAddress = address;
      model.isPaid = true;
      model.bazarId = bazarId;

      cartItems.filter(item => item.isSelected).forEach(item => {
        let op = new SSSubmitOrderProductRequest();
        op.productId = item.productId;
        op.quantity = item.productQuantity;
        op.unitPrice = item.unitPrice;
        op.totalPrice = item.totalPrice;
        op.bannerPath = item.bannerPath;
        orderItems?.push(op);
        setOrderItems([...orderItems, op])
      });

      model.orderProductRequest = orderItems;
      model.totalAmount = totalPrice;

      setIsConformOrderButtonLoading(true);

      sSSubmitOrderWithMainBalanceService(model)
        .then((res: any) => {
          setIsShowSnackBar(true);
          setSnackBarMessage("Successfully Placed Order from Main Balance");
          setSnackBarMessageType("success");
          setTimeout(() => {
            setIsShowSnackBar(false);
          }, 2000);
          setIsConformOrderButtonLoading(false);
          navigate("/super-shop/order-list");
        })
        .catch((error) => {
          setIsConformOrderButtonLoading(false);
        });
    }
  }

  const [isOpenConfirmOrderModal, setIsOpenConfirmOrderModal] = useState(false);

  const handleOpenConfirmOrderOneModal = (item: any) => {
    setIsOpenConfirmOrderModal(true);
  };

  const handleSubmitOnCashOnDelivery = () => {

    let canExecute = true;

    if (!firstName) {
      setFirstNameError("First Name Is Required");
      canExecute = false;
    }
    if (!phoneNo) {
      setPhoneNoError("Phone no is required");
      canExecute = false;
    }
    if (!isValidPhoneNumber(phoneNo)) {
      setPhoneNoError("Phone no is invalid");
      canExecute = false;
    }

    if (!isValidEmail(email) && email) {
      setEmailError("Email is invalid");
      canExecute = false;
    }
    if (!address) {
      setaddressError("Address line one is required");
      canExecute = false;
    }

    if (canExecute) {

      var model = new SSSubmitOrderRequest();

      model.userFirstName = firstName;
      model.userLastName = lastName;
      model.userPhoneNo = phoneNo;
      model.userEmail = email;
      model.userAddress = address;
      model.isPaid = false;
      model.bazarId = bazarId;

      cartItems.filter(item => item.isSelected).forEach(item => {
        let op = new SSSubmitOrderProductRequest();
        op.productId = item.productId;
        op.quantity = item.productQuantity;
        op.unitPrice = item.unitPrice;
        op.oldUnitPrice = item.oldUnitPrice;
        op.totalPrice = item.totalPrice;
        op.bannerPath = item.bannerPath;
        orderItems?.push(op);
        setOrderItems([...orderItems, op])
      });

      model.orderProductRequest = orderItems;
      model.totalAmount = totalPrice;

      setIsConformOrderButtonLoading(true);

      sSSubmitOrderWithMainBalanceService(model)
        .then((res: any) => {
          setIsShowSnackBar(true);
          setSnackBarMessage("Successfully Placed Order from Main Balance");
          setSnackBarMessageType("success");
          setTimeout(() => {
            setIsShowSnackBar(false);
          }, 2000);
          setIsConformOrderButtonLoading(false);
          dispatch(sSGetTotalCartItemAction());
          navigate("/super-shop/order-list");
        })
        .catch((error) => {
          setIsConformOrderButtonLoading(false);
        });
    }
  }

  const handleCloseConfirmOrderModal = () => {
    setIsOpenConfirmOrderModal(false);
  };


  const [isLoading, setIsLoading] = useState(false);

  if (isLoading) {
    return (
      <div className="container h-screen flex items-center justify-center mx-auto px-2 md:px-0 pb-12 ">
        <AppLoader status={true} />
      </div>
    );
  }

  return (
    <div className="container mx-auto py-8 mb-16 md:py-12 px-2 2xl:py-3 lg:py-2 md:px-16 lg:px-28 ">
      {isShowSnackBar && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}

      {/* {isOpenConfirmOrderModal && (
        <AppModal>
          <div className="flex max-w-md flex-col gap-4 p-6 text-center md:text-2xl">
            Are you sure you want to confirm this order?
            <div className="flex items-center justify-center gap-4">
              <AppButton text="Yes" onClick={handleSubmitOnCashOnDelivery} isSubmitting={isConfirmOrderButtonLoading} />
              <AppButton text="No" onClick={handleCloseConfirmOrderModal} />
            </div>
          </div>
        </AppModal>
      )} */}

      <div className="flex flex-col md:flex-row justify-between 2xl:gap-8 gap-y-6 gap-4">
        <div className="w-full md:w-1/2 space-y-2 lg:space-y-4">
          <h2 className="subTitle">Billing & Shipping Details</h2>
          <div className="flex flex-col lg:flex-row justify-between items-center gap-2 lg:gap-4">
            <AppInput
              type="text"
              label="First name"
              className="myInput"
              placeholder="Enter your first name"
              onChange={handleFirstName}
              errorMessage={firstNameError}
              value={firstName}
              isRequired={true}
            />
            {/* <AppInput
              label="Last name"
              type="text"
              className="myInput"
              placeholder="Enter your last name"
              onChange={handleLastName}
              value={lastNameError}
            /> */}
          </div>
          <div className="flex flex-col lg:flex-row justify-between items-center gap-2 lg:gap-4">
            <AppInput
              label="Phone number"
              type="text"
              className="myInput"
              placeholder="Enter phone number"
              onChange={handlePhoneNo}
              value={phoneNo}
              isRequired={true}
              errorMessage={phoneError}
            />
          </div>
          {/* <AppInput
            label="Email"
            type="text"
            className="myInput"
            placeholder="Enter your email"
            onChange={handleEmail}
            value={email}
            errorMessage={emailError}
          /> */}
          <AppInput
            label="Address"
            type="text"
            className="myInput"
            placeholder="Enter your address"
            onChange={handleAddress}
            value={address}
            isRequired={true}
            errorMessage={addressError}
          />
        </div>

        <div className="w-full md:w-1/2 flex flex-col">
          <div className="h-[35dvh] md:h-[50dvh]">
            <AppScrollBar height={`100%`}>
              <div className="space-y-4 pb-2">
                <ul className="space-y-2">
                  {cartItemsMemo?.filter((_: any) => _.isSelected).map((item: any, indx: any) => (
                    <>
                      <div className="flex items-center justify-between md:gap-x-2 gap-x-[2px]">
                        <div className="flex items-center gap-x-1">
                          <div className="md:rounded-2xl rounded cursor-pointer">
                            <img className='md:w-16 w-12 md:h-16 h-12 object-cover md:rounded-xl rounded' src={item?.bannerPath} alt="These is product pic" />
                          </div>
                          <div className="">
                            <h3 className="md:text-base text-xs"> {item?.productName} </h3>
                          </div>
                        </div>
                        <div className="flex justify-between 2xl:pr-8 pr-4 items-center md:gap-x-4 gap-x-1">
                          <div className="flex items-center md:text-base text-sm  md:gap-x-[2px] gap-x-[1px]">
                            <span>Price:</span> <TbCurrencyTaka /> {item?.unitPrice}
                          </div>
                          <div className="flex items-center md:text-base text-sm md:gap-x-1 gap-x-[1px]">
                            <span>Qtn:</span> {item?.productQuantity}
                          </div>
                          <div className="flex items-center md:text-base text-sm  gap-x-[1px]">
                            <span>Total:</span> <TbCurrencyTaka /> {item?.totalPrice}
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </ul>
              </div>
            </AppScrollBar>
          </div>
          <>
            <div className="border-y border-gray-600 py-2 px-1">
              <div className="flex flex-col text-xs md:text-lg justify-between text-textBlack">
                <p className="text-center font-medium">You will order {cartItems?.length} items.</p>
                <p className="text-center font-medium">Minimum Shipping Charge: 20 TK</p>
              </div>
            </div>

            <div className="flex items-center justify-between subTitle text-textBlack pt-2 px-1">
              <span>Total payment:</span>   <p className="flex items-center"> <TbCurrencyTaka />{totalPrice} </p>
            </div>
            <p className="text-end text-xs text-gray-500">প্রোডাক্ট রিটার্ন করলেও ডেলিভারি চার্জ দেওয়া বাধ্যতামূলক।</p>
            <h3 className="subTitle">Payment method:</h3>
            {/* <div className="flex items-center justify-center gap-4 pt-2">
              <AppButton onClick={selectFullPayment} text="Pay now" className={`${!isSelectedCashOnDelivery ? "resellingBtn" : 'resellingOutlineBtn'}`} />
              <AppButton onClick={selectCashOnDelivery} text="Cash on delivery" className={`${isSelectedCashOnDelivery ? 'resellingBtn' : 'resellingOutlineBtn'} `} />
            </div> */}

            {/* {!isSelectedCashOnDelivery &&
              <>
                <div className="p-2 md:w-96">
                  {wallet.mainBalance >= totalPrice ? (
                    <>
                      <div className="text-royalBlue text-center font-medium py-2">
                        <p>You have to pay: {totalPrice}</p>
                        <p>Your Main Balance have: {wallet.mainBalance}</p>
                        <p>Your remaining balance will be: {wallet.mainBalance - totalPrice}</p>
                      </div>
                      <div className="flex justify-end mt-4">
                        <AppButton
                          isSubmitting={isConfirmOrderButtonLoading}
                          isDisable={isConfirmOrderButtonLoading}
                          className="resellingBtn"
                          text="Confirm With Main Balance"
                          onClick={handleSubmitWithMainBalance}
                        />
                      </div>
                    </>
                  ) : (
                    <div className='text-royalBlue font-medium p-2'>
                      <p>You do not have sufficient balance you have {wallet.mainBalance}</p>
                    </div>
                  )}
                </div>
              </>
            } */}

            {/* {isSelectedCashOnDelivery && */}
            <>
              <div className="p-2 md:w-96">
                {!isOpenConfirmOrderModal && (
                  <div className="flex justify-end mt-4">
                    <AppButton
                      isSubmitting={isConfirmOrderButtonLoading}
                      isDisable={isConfirmOrderButtonLoading}
                      className="ssBtn"
                      text="Confirm"
                      onClick={handleOpenConfirmOrderOneModal}
                    />
                  </div>
                )}
                {isOpenConfirmOrderModal && (
                  <div className="flex max-w-md flex-col gap-4 p-6 text-center md:text-2xl">
                    Are you sure you want to confirm this order?
                    <div className="flex items-center justify-center gap-4">
                      <AppButton text="Yes" onClick={handleSubmitOnCashOnDelivery} isSubmitting={isConfirmOrderButtonLoading} />
                      <AppButton text="No" onClick={handleCloseConfirmOrderModal} />
                    </div>
                  </div>
                )}
              </div>
            </>
            {/* } */}
          </>
        </div>
      </div>
    </div>
  );
}

