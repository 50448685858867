import { SSGET_ADMIN_ORDER_BY_ID, SSGET_ADMIN_ORDER_LIST, SSGET_ORDER_BY_ID, SSGET_ORDER_LIST } from "../../constants/superShop/ssOrderConstants";

const inititalState = {
    data: [],
    dataById: []
}

export function sSGetOrderListReducer(state = inititalState, action: any) {
    switch (action.type) {
        case SSGET_ORDER_LIST:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

export function sSAdminGetOrderListReducer(state = inititalState, action: any) {
    switch (action.type) {
        case SSGET_ADMIN_ORDER_LIST:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

export function sSGetOrderDetailsReducer(state = { data: {} }, action: any) {
	switch (action.type) {
		case SSGET_ORDER_BY_ID:
			return { loading: false, data: action.payload };
		default:
			return state;
	}
}

export function sSAdminGetOrderDetailsReducer(state = { data: {} }, action: any) {
	switch (action.type) {
		case SSGET_ADMIN_ORDER_BY_ID:
			return { loading: false, data: action.payload };
		default:
			return state;
	}
}
