import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { AlertColor } from "@mui/material";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { AppButton } from "../../../common/AppButton";
import { AppInput } from "../../../common/AppInput";
import { AppSelect } from "../../../common/AppSelect";
import { addPublicRepresentativesNumber } from "../../../../models/importantNumber/ImportantNumberModel";
import {
  getPublicRepresentativesByIdService,
  updatePublicRepresentativesService,
} from "../../../../httpService/importantNumbers/publicRepresentativesServices";
import { IdNameResponseModel } from "../../../../models/common/IdNameModel";
import { RootState } from "../../../../store";
import { getUnionAuthListAction } from "../../../../store/actions/importantNumbers/getUnionListAction";
import { isAdmin } from "../../../../common/common";
import { reactLocalStorage } from "reactjs-localstorage";

const designations = [
  { id: 1, name: "UnionChairman" },
  { id: 2, name: "UnionMember" },
  { id: 3, name: "UpazilaChairman" },
];
export default function INUpdatePublicRepresentatives() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [name, setName] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [
    publicRepresentativeDesignations,
    setpublicRepresentativeDesignations,
  ] = useState(0);
  const [
    publicRepresentativeDesignationsErrorMessage,
    setPublicRepresentativeDesignationsErrorMessage,
  ] = useState("");
  const [phoneNumberFirst, setPhoneNumberFirst] = useState("");
  const [phoneNumberFirstErrorMessage, setPhoneNumberFirstErrorMessage] =
    useState("");
  const [phoneNumberSecond, setPhoneNumberSecond] = useState("");
  const [phoneNumberSecondErrorMessage, setPhoneNumberSecondErrorMessage] =
    useState("");
  const [note, setNote] = useState("");
  const [noteErrorMessage, setNoteErrorMessage] = useState("");

  const [unionOrWard, setUnionOrWard] = useState(0);
  const [unionOrWardErrorMessage, setUnionOrWardErrorMessage] = useState("");

  const publicRepresentative = async (id: any) => {
    const data = await getPublicRepresentativesByIdService(id);
    const response: addPublicRepresentativesNumber = data?.data;
    setName(response.name);
    setpublicRepresentativeDesignations(
      response.publicRepresentativeDesignations
    );
    setPhoneNumberFirst(response.phoneNumberFirst);
    setPhoneNumberSecond(response.phoneNumberSecond);
    setNote(response.note);
    setUnionOrWard(response.unionOrWardId);
  };

  useEffect(() => {
    dispatch(getUnionAuthListAction());
  }, [dispatch]);

  const dataUnionList = useSelector((state: RootState) => {
    return state.getUnionListResponseByAuthArea.data;
  });

  var dataUnionListIdNameModels: IdNameResponseModel[] = [];
  dataUnionList?.map((x: any) => {
    var union = new IdNameResponseModel();
    union.id = x.id;
    union.name = x.name;
    dataUnionListIdNameModels.push(union);
  });

  const handleNameChange = (event: any) => {
    setName(event);
    setNameErrorMessage("");
  };

  const handlepublicRepresentativeDesignations = (id: any) => [
    setpublicRepresentativeDesignations(id),
  ];

  const handlePhoneNumberFirst = (event: any) => {
    setPhoneNumberFirst(event);
    setPhoneNumberFirstErrorMessage("");
  };
  const handlePhoneNumberSecond = (event: any) => {
    setPhoneNumberSecond(event);
    setPhoneNumberSecondErrorMessage("");
  };

  const handleNote = (event: any) => {
    setNote(event);
    setNoteErrorMessage("");
  };
  const handleUnionOrWardId = (id: any) => [setUnionOrWard(id)];

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSnackBarOn(false);
    var payload = {
      id: id,
      name: name,
      publicRepresentativeDesignations: publicRepresentativeDesignations,
      phoneNumberFirst: phoneNumberFirst,
      phoneNumberSecond: phoneNumberSecond,
      note: note,
      unionOrWardId: unionOrWard,
    };

    setIsButtonLoading(true);

    updatePublicRepresentativesService(payload)
      .then((Response) => {
        setSnackBarOn(true);
        setsnackBarMessage(
          "Public Representative Number Updated Successfully."
        );
        setSnackBarMessageType("success");
        setIsButtonLoading(false);
        setTimeout(() => {
          navigateToPublicRepresentatives();
        }, 300);
      })
      .catch((error: any) => {
        setIsButtonLoading(false);
        setNameErrorMessage(error.response.data.errors?.Name?.[0]);
        setPhoneNumberFirstErrorMessage(
          error.response.data.errors?.PhoneNumberFirst?.[0]
        );
        setPhoneNumberSecondErrorMessage(
          error.response.data.errors?.PhoneNumberSecond?.[0]
        );
        setPublicRepresentativeDesignationsErrorMessage(
          error.response.data.errors?.PublicRepresentativeDesignations?.[0]
        );
        setNoteErrorMessage(error.response.data.errors?.Note?.[0]);
        setUnionOrWardErrorMessage(
          error.response.data.errors?.UnionOrWardId?.[0]
        );
      });
  };

  useEffect(() => {
    publicRepresentative(id);
  }, []);

  const navigateToPublicRepresentatives = () => {
		if (isAdmin(reactLocalStorage.get("RoleName"))) {
		  navigate(`/admin/important-numbers/public-representatives`);
		} else {
		  navigate(`/important-numbers/public-representatives`);
		}
	  };
    
  return (
    <>
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      <div className="flex w-3/5 items-center justify-between pb-6 pl-4">
        <AppButton
          onClick={navigateToPublicRepresentatives}
          text="Back"
        />
        <h2 className="title text-center">
          Update Public Representative Contact Number
        </h2>
      </div>

      <form className="myBorder mx-auto max-w-xl px-5 py-4">
        <AppInput
          label="Name"
          className="myInput"
          type="text"
          placeholder="Enter Name"
          onChange={handleNameChange}
          errorMessage={nameErrorMessage}
          value={name}
        />
        <div className="my-1">
          <p className="text-sm md:text-base">Designation:</p>
          <AppSelect
            defaultValue="Select Designation"
            value={publicRepresentativeDesignations}
            data={designations}
            onChange={handlepublicRepresentativeDesignations}
            errorMessage={publicRepresentativeDesignationsErrorMessage}
          />
        </div>
        <AppInput
          label="Phone Number First"
          className="myInput"
          type="text"
          placeholder="Enter Phone Number"
          onChange={handlePhoneNumberFirst}
          errorMessage={phoneNumberFirstErrorMessage}
          value={phoneNumberFirst}
        />
        <AppInput
          label="Phone Number Second"
          className="myInput"
          type="text"
          placeholder="Enter Phone Number"
          onChange={handlePhoneNumberSecond}
          errorMessage={phoneNumberSecondErrorMessage}
          value={phoneNumberSecond}
        />
        <AppInput
          label="Note"
          className="myInput"
          type="text"
          placeholder="Enter Note"
          onChange={handleNote}
          errorMessage={noteErrorMessage}
          value={note}
        />
        <div className="my-1">
          <p className="text-sm md:text-base">Union or Ward:</p>
          <AppSelect
            defaultValue="Select Ward Or Union"
            value={unionOrWard}
            data={dataUnionListIdNameModels}
            onChange={handleUnionOrWardId}
            errorMessage={unionOrWardErrorMessage}
          />
        </div>
        <div className="flex justify-end">
          <AppButton
            text="Update"
            onClick={handleSubmit}
            isSubmitting={isButtonLoading}
            variant="contained"
          />
        </div>
      </form>
    </>
  );
}
