import { getMagazineCategoryByIdService, getMagazineCategoryListService } from "../../../httpService/magazine/magazineCategoryServices";
import { GET } from "../../../httpService/service";
import { PaginationPayloadModel } from "../../../models/common/paginationModels";

export const magazineCategoryList = (payload?: PaginationPayloadModel) => async (dispatch: any) => {
    try {
        const data = getMagazineCategoryListService(payload);
        const pload = (await data).data.items;
        dispatch({ type: 'GET_MAGAZINE_CATEGORY_LIST', payload: pload });
    }
    catch (error: any) { }
}

export const magazineCategoryById = (id: any) => async (dispatch: any) => {
    try {
        const data = getMagazineCategoryByIdService(id);
        const pload = (await data).data;
        dispatch({ type: 'GET_MAGAZINE_CATEGORY_BY_ID', payload: pload });
    }
    catch (error: any) { }
}
