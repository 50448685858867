import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  getAffiliaterTeamAction,
  getUserProfileAction,
} from "../../../store/actions/auth/user/userActions";
import { BiSearchAlt2 } from "react-icons/bi";
import { AppSearchBox } from "../../common/AppSearchBox";
import { AppButton } from "../../common/AppButton";
import { GetAffiliaterTeamPayload } from "../../../models/auth/user/UserModels";
import { TeamMember } from "../../common/TeamMember";

export default function AffiliateGetMyTeam() {
  const dispatch = useDispatch();

  const [isSelected, setIsSelected] = useState("");

  const teamList = useSelector((state: RootState) => {
    return state.getAffiliaterTeamResponse.data;
  });

  const userProfile = useSelector((state: RootState) => {
    return state.getUserProfileResponse.data;
  });

  var paginationModel = new GetAffiliaterTeamPayload();
  paginationModel.limit = 10000;
  paginationModel.offset = 0;
  paginationModel.searchQuery = "";
  paginationModel.isAffiliaterTeam = true;

  useEffect(() => {
    dispatch(getAffiliaterTeamAction(paginationModel));
    dispatch(getUserProfileAction());
  }, [dispatch]);

  const handleSearchChange = (newValue: any) => {
    paginationModel.searchQuery = newValue;
    dispatch(getAffiliaterTeamAction(paginationModel));
  };

  const handleOpenAffiliaterTeam = () => {
    setIsSelected("AffiliaterTeam");
    paginationModel.isAffiliaterTeam = true;
    dispatch(getAffiliaterTeamAction(paginationModel));
  };

  const handleOpenCustomerTeam = () => {
    setIsSelected("CustomerTeam");
    paginationModel.isAffiliaterTeam = false;
    dispatch(getAffiliaterTeamAction(paginationModel));
  };

  return (
    <div className="container px-2 pb-8 pt-2 lg:mx-auto lg:px-0 lg:pb-4 lg:pt-4">
      <h1 className="title md:text-center">My Team</h1>

      <div className="flex w-full flex-col-reverse justify-between gap-2 py-2 md:flex-row md:items-end">
        <div className="flex cursor-pointer items-center rounded border border-grey p-1 shadow-sm md:p-1.5 2xl:p-2">
          <BiSearchAlt2 className="cursor-pointer text-xl font-bold text-secondary md:text-2xl 2xl:text-3xl" />
          <AppSearchBox
            onChange={handleSearchChange}
            className="border-none px-2 text-sm md:w-[25dvw] md:text-base"
          />
        </div>

        <div className="">
          <h1 className="subTitle">Upline</h1>
          <div className="mt-1 flex w-full flex-col items-center gap-1 overflow-hidden rounded px-1 py-2 lg:w-80">
            {userProfile?.myAffiliateUpline ? (
              <TeamMember
                index={1111}
                name={userProfile?.myAffiliateUpline?.name}
                image={userProfile?.myAffiliateUpline?.profilePicUrl}
                roles={userProfile?.myAffiliateUpline?.userRoles}
                phoneNumber={userProfile?.myAffiliateUpline?.phoneNumber}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <div className="lg:myBorder my-2 flex items-center justify-between gap-1 px-0 py-0 md:gap-6 lg:mb-4 lg:justify-evenly lg:gap-4 lg:rounded lg:px-2 lg:py-2.5">
        <AppButton
          className={`${
            isSelected === "AffiliaterTeam" || isSelected === ""
              ? "myBtn px-1.5 py-0 text-xxs lg:px-4 lg:py-1 lg:text-base"
              : "outlineBtn px-1.5 py-0 text-xxs lg:px-4 lg:py-1 lg:text-base"
          }`}
          text="Affiliater Team"
          onClick={handleOpenAffiliaterTeam}
        />
        <AppButton
          className={`${
            isSelected === "CustomerTeam"
              ? "myBtn px-1.5 py-0 text-xxs lg:px-4 lg:py-1 lg:text-base"
              : "outlineBtn px-1.5 py-0 text-xxs lg:px-4 lg:py-1 lg:text-base"
          }`}
          text="Customer Team"
          onClick={handleOpenCustomerTeam}
        />
      </div>

      <h1 className="subTitle">Downlines ({teamList?.size})</h1>

      <div className="h-[55dvh] w-full overflow-y-auto lg:h-[47dvh]">
        <div className="flex grid-cols-1 flex-col content-center gap-2 md:grid-cols-4 lg:grid">
          {teamList?.items?.map((item: any, index: any) => {
            return (
              <TeamMember
                key={index}
                index={item.id}
                name={item?.name}
                image={item?.profilePicUrl}
                roles={item?.userRoles}
                phoneNumber={item?.phoneNumber}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
