import { useState } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { NavLink, useNavigate } from "react-router-dom";
import { MdCategory, MdManageAccounts, MdOutlineCategory, MdOutlineManageAccounts, MdOutlineRateReview, MdSpaceDashboard } from "react-icons/md";
import { BiMessageAltError, BiSolidOffer, BiUser } from "react-icons/bi";
import { FiChevronRight } from "react-icons/fi";
import { IoApps, IoWallet } from "react-icons/io5";
import { VscActivateBreakpoints } from "react-icons/vsc";
import { ImBooks } from "react-icons/im";
import { TbBrandD3, TbCategory2, TbTruckDelivery, TbTruckReturn } from "react-icons/tb";
import { FaFacebook, FaSellsy, FaYoutube } from "react-icons/fa";
import { BsBoxes, BsCart2, BsDownload, BsMicrosoftTeams } from "react-icons/bs";
import { RiDropboxFill, RiListSettingsLine } from "react-icons/ri";
import { CgCloseR } from "react-icons/cg";
import { RxDashboard } from "react-icons/rx";
import { LuLogOut } from "react-icons/lu";
import { Link } from "react-router-dom";
import { HiLink } from "react-icons/hi";
import { isAdmin, isBazarRider, isDataOfferVendor, isVendor } from "../../../common/common";

export default function AdminLeftSideBar() {

	const [openUser, setOpenUser] = useState(false);
	const [openAppUser, setOpenAppUser] = useState(false);
	const [openCatalog, setOpenCatalog] = useState(false);
	const [openDataOffer, setOpenDataOffer] = useState(false);
	const [openCategoryCatalog, setOpenCategoryCatalog] = useState(false);
	const [openAttributeCatalog, setOpenAttributeCatalog] = useState(false);
	const [openSales, setOpenSales] = useState(false);
	const [openDashboard, setOpenDashboard] = useState(false);
	const [openInvoices, setOpenInvoices] = useState(false);

	const [openBechteChai, setOpenBechteChai] = useState(false);
	const [openCategoryBechteChai, setOpenCategoryBechteChai] = useState(false);

	const [openSuperShop, setOpenSuperShop] = useState(false);
	const [openCategorySuperShop, setOpenCategorySuperShop] = useState(false);

	const navigate = useNavigate();

	const handleUser = () => {
		setOpenUser(!openUser)
		setOpenAppUser(false)
	}
	const handleAppUser = () => {
		setOpenAppUser(!openAppUser)
	}

	const handleLogOut = () => {
		reactLocalStorage.remove("SignIn");
		reactLocalStorage.remove("RoleName");
		reactLocalStorage.remove("UserId");
		navigate(`/`);
	}

	const handleCatalog = () => {
		setOpenCatalog(!openCatalog)
		setOpenCategoryCatalog(false)
		setOpenAttributeCatalog(false)
	}
	const handleCategoryCatalog = () => {
		setOpenCategoryCatalog(!openCategoryCatalog)
	}
	const handleAttributeCatalog = () => {
		setOpenAttributeCatalog(!openAttributeCatalog)
	}

	const handleBechteChai = () => {
		setOpenBechteChai(!openBechteChai)
		setOpenCategoryBechteChai(false)
	}
	const handleCategoryBechteChai = () => {
		setOpenCategoryBechteChai(!openCategoryBechteChai)
	}

	const handleSuperShop = () => {
		setOpenSuperShop(!openSuperShop)
		setOpenCategorySuperShop(false)
	}
	const handleCategorySuperShop = () => {
		setOpenCategorySuperShop(!openCategorySuperShop)
	}

	const handleDataOffer = () => {
		setOpenDataOffer(!openDataOffer)
	}


	return (
		<div className="fixed top-0 left-0 z-50 h-screen lg:relative flex lg:h-[91dvh] 2xl:h-[92dvh] bg-gray-100">
			<nav className={`h-full relative shadow-xl bg-white font-medium text-gray-600 z-10 ${openDashboard ? "w-60" : "w-0"}`}>
				{isAdmin(reactLocalStorage.get("RoleName")) && (
					<ul className="space-y-1.5 pt-8 pb-4 h-[85%] lg:h-[83%] overflow-y-auto ">
						<li className="font-semibold text-xl text-gray-700 pb-6 px-3">এডমিন ড্যাসবোর্ড</li>

						{/* these is dashboard navigation  */}
						<li >
							<NavLink to="/admin/dashboard" className='flex items-center gap-1 text-lg px-3 py-[2px] hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg'>
								<RxDashboard /> <span>Dashboard</span>
							</NavLink>
						</li>
						<li >
							<NavLink to="/admin/auth/companyWallet" className='flex items-center gap-1 text-lg px-3 py-[2px] hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg'>
								<IoWallet /> <span>Company Wallet</span>
							</NavLink>
						</li>

						<li >
							<NavLink to="/admin/auth/companyTeam" className='flex items-center gap-1 text-lg px-3 py-[2px] hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg'>
								<BsMicrosoftTeams /> <span>Company Teams</span>
							</NavLink>
						</li>

						{/* these is user item  */}
						<li>
							<div onClick={handleUser} className="flex items-center justify-between text-lg hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px] px-3 cursor-pointer">
								<div className="flex items-center gap-1"><BiUser /> User</div>
								<FiChevronRight className={`${openUser ? "rotate-90" : "transition-all"}`} />
							</div>
							{/* these is nested user elements */}
							<div onClick={handleAppUser} className={`${openUser ? "block" : "hidden"}`}>
								<div className="flex items-center justify-between hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg text-base py-[2px] px-3 cursor-pointer">
									<div className="flex items-center gap-1"><IoApps /> App User</div>
									<FiChevronRight className={`${openAppUser ? "rotate-90" : "transition-all"}`} /></div>
							</div>
							{/* this is nested of app user elements  */}
							<div className={` mt-1 space-y-1 text-sm ${openAppUser ? "block" : "hidden"} ${openUser ? "block" : "hidden"}`}>
								{/* manage user here  */}
								<NavLink to="/admin/auth/users" className='flex items-center  gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
									<MdManageAccounts /> <span>Manage User</span>
								</NavLink>
								{/* manage vendor here  */}
								<NavLink to="/admin/auth/vendorrequest" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
									<MdOutlineManageAccounts /> <span>Manage Vendor</span>
								</NavLink>
								{/* user points here  */}
								<NavLink to="/admin/auth/reseller-wr" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
									<VscActivateBreakpoints /> <span>Reseller W.R.</span>
								</NavLink>
								<NavLink to="/admin/auth/wallet-rr" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
									<VscActivateBreakpoints /> <span>Wallet R.R.</span>
								</NavLink>
								<NavLink to="/admin/auth/vendor-wr" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
									<VscActivateBreakpoints /> <span>Vendor W.R.</span>
								</NavLink>
								<NavLink to="/admin/auth/data-offer-vendor-wr" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
									<VscActivateBreakpoints /> <span>D. Vendor W.R.</span>
								</NavLink>

							</div>
						</li>


						{/* these is catalog item  */}
						<li>
							<div onClick={handleCatalog} className="flex items-center justify-between text-lg hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px] px-3 cursor-pointer">
								<div className="flex items-center gap-1"><ImBooks /> Catalog</div>
								<FiChevronRight className={`${openCatalog ? "rotate-90" : "transition-all"}`} />
							</div>
							{/* these is nested Catalog elements */}
							<div className={` space-y-1 mt-1 text-base ${openCatalog ? "block" : "hidden"}`}>
								<div onClick={handleCategoryCatalog}>
									<div className="flex items-center justify-between hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px] px-3 cursor-pointer"><div className="flex items-center gap-1"><TbCategory2 /> Category</div>
										<FiChevronRight className={`${openCategoryCatalog ? "rotate-90" : "transition-all"}`} /></div>
								</div>
								{/* this is nested of Category elements  */}
								<div className={` text-sm ${openCategoryCatalog ? "block" : "hidden"} ${openCatalog ? "block" : "hidden"}`}>
									{/* manage user here  */}
									<NavLink to="/admin/catalog/main-categories" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
										<MdCategory /> <span>Main Category</span>
									</NavLink>
									{/* manage vendor here  */}
									<NavLink to="/admin/catalog/sub-cat-lvl-ones" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
										<MdOutlineCategory /> <span>Sub Category</span>
									</NavLink>
								</div>
								{/* these is product brand  */}
								<NavLink to="/admin/catalog/productBrands" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
									<TbBrandD3 /> <span>Product Brand</span>
								</NavLink>

								{/* these is nested attribute elements */}
								<div onClick={handleAttributeCatalog} className={`${openCatalog ? "block" : "hidden"}`}>
									<div className="flex items-center justify-between hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px] px-3 cursor-pointer"><div className="flex items-center gap-1"><RiListSettingsLine /> Attribute</div>
										<FiChevronRight className={`${openAttributeCatalog ? "rotate-90" : "transition-all"}`} /></div>
								</div>
								{/* this is nested of Category elements  */}
								<div className={`text-sm ${openAttributeCatalog ? "block" : "hidden"} ${openCatalog ? "block" : "hidden"}`}>
									{/* manage user here  */}
									<NavLink to="/admin/catalog/product-attributes" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
										<RiDropboxFill /> <span>Product Attributes</span>
									</NavLink>
								</div>
								{/* manage product here  */}
								<NavLink to="/admin/catalog/products" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
									<BsBoxes /> <span>Product</span>
								</NavLink>
								{/* product reviews here  */}
								<NavLink to="/admin/catalog/productreviews" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
									<MdOutlineRateReview /> <span>Product Reviews</span>
								</NavLink>
							</div>
						</li>

						<li>
							<div onClick={handleSuperShop} className="flex items-center justify-between text-lg hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px] px-3 cursor-pointer">
								<div className="flex items-center gap-1"><ImBooks /> Super Shop</div>
								<FiChevronRight className={`${openSuperShop ? "rotate-90" : "transition-all"}`} />
							</div>

							<div className={` space-y-1 mt-1 text-base ${openSuperShop ? "block" : "hidden"}`}>
								<NavLink to="/admin/super-shop/rider-list" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
									<BsCart2 /> <span>Rider</span>
								</NavLink>
								<div onClick={handleCategorySuperShop}>
									<div className="flex items-center justify-between hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px] px-3 cursor-pointer"><div className="flex items-center gap-1"><TbCategory2 /> Category</div>
										<FiChevronRight className={`${openCategorySuperShop ? "rotate-90" : "transition-all"}`} />
									</div>
								</div>
								<div className={` text-sm ${openCategorySuperShop ? "block" : "hidden"} ${openSuperShop ? "block" : "hidden"}`}>
									<NavLink to="/admin/super-shop/main-category-list" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
										<MdCategory /> <span>Main Category</span>
									</NavLink>
									<NavLink to="/admin/super-shop/sub-category-list" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
										<MdOutlineCategory /> <span>Sub Category</span>
									</NavLink>
								</div>
								<NavLink to="/admin/super-shop/product-list" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
									<BsBoxes /> <span>Product</span>
								</NavLink>
								<NavLink to="/admin/super-shop/product-brand-list" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
									<BsBoxes /> <span>Brand</span>
								</NavLink>
								<NavLink to="/admin/super-shop/bazar-list" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
									<BsCart2 /> <span>Bazar</span>
								</NavLink>
								<NavLink to="/admin/super-shop/order-list" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
									<BsCart2 /> <span>Orders</span>
								</NavLink>
							</div>

						</li>

						{/* Bechte Chai Start */}

						<li>
							<div onClick={handleBechteChai} className="flex items-center justify-between text-lg hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px] px-3 cursor-pointer">
								<div className="flex items-center gap-1"><ImBooks /> Bechte Chai</div>
								<FiChevronRight className={`${openBechteChai ? "rotate-90" : "transition-all"}`} />
							</div>

							<div className={` space-y-1 mt-1 text-base ${openBechteChai ? "block" : "hidden"}`}>
								<div onClick={handleCategoryBechteChai}>
									<div className="flex items-center justify-between hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px] px-3 cursor-pointer"><div className="flex items-center gap-1"><TbCategory2 /> Category</div>
										<FiChevronRight className={`${openCategoryBechteChai ? "rotate-90" : "transition-all"}`} />
									</div>
								</div>
								<div className={` text-sm ${openCategoryBechteChai ? "block" : "hidden"} ${openBechteChai ? "block" : "hidden"}`}>
									<NavLink to="/admin/bechte-chai/main-categories" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
										<MdCategory /> <span>Main Category</span>
									</NavLink>
									<NavLink to="/admin/bechte-chai/sub-categories" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
										<MdOutlineCategory /> <span>Sub Category</span>
									</NavLink>
								</div>
								<NavLink to="/admin/bechte-chai/products" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
									<BsBoxes /> <span>Product</span>
								</NavLink>
							</div>
						</li>

						{/* Bechte Chai End */}

						<li >
							<NavLink to="/admin/data-offer-vendor/data-offer" className='flex items-center gap-1 text-lg px-3 py-[2px] hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg'>
								<BiSolidOffer /> <span>DataOffer</span>
							</NavLink>
						</li>

						<li >
							<NavLink to="/admin/notice/notice-list" className='flex items-center gap-1 text-lg px-3 py-[2px] hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg'>
								<BiMessageAltError /> <span>Notice</span>
							</NavLink>
							<NavLink to="/admin/popular-link/popular-links" className='flex items-center gap-1 text-lg px-3 py-[2px] hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg'>
								<HiLink /> <span>Popular Link</span>
							</NavLink>
						</li>

						<li >
							<NavLink to="/admin/important-numbers" className='flex items-center gap-1 text-lg px-3 py-[2px] hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg'>
								<BiSolidOffer /> <span>Dorkari Number</span>
							</NavLink>
						</li>

						{/* these is sales items */}
						<li>
							<div onClick={() => setOpenSales(!openSales)} className="flex items-center justify-between text-lg hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px] px-3 cursor-pointer">
								<div className="flex items-center gap-1"><FaSellsy /> Sales</div>
								<FiChevronRight className={`${openSales ? "rotate-90" : "transition-all"}`} /></div>
							{/* this is nested of sells elements  */}

							<div className={`mt-1 space-y-1 text-base ${openSales ? "block" : "hidden"}`}>
								{/* manage user here  */}
								<NavLink to="/admin/sales/order-list" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
									<BsCart2 /> <span>Reselling Orders</span>
								</NavLink>
								<NavLink to="/admin/sales/affiliate-order-list" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
									<BsCart2 /> <span>Affiliate Orders</span>
								</NavLink>
								<NavLink to="/admin/data-offer-vendor/order-list" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
									<BsCart2 /> <span>Data Offer Orders</span>
								</NavLink>
							</div>
						</li>

						<li>
							<div
								onClick={() => setOpenInvoices(!openInvoices)}
								className="flex cursor-pointer items-center justify-between px-3 py-[2px] text-lg hover:border-r-4 hover:border-primary hover:bg-white hover:text-primary hover:shadow-lg"
							>
								<div className="flex items-center gap-1">
									<BsDownload /> Invoices
								</div>
								<FiChevronRight
									className={`${openInvoices ? "rotate-90" : "transition-all"}`}
								/>
							</div>
							{/* this is nested of sells elements  */}

							<div
								className={`mt-1 space-y-1 text-base ${openInvoices ? "block" : "hidden"
									}`}
							>
								{/* manage user here  */}
								<NavLink
									to="/admin/invoices/order-invoices"
									className="flex items-center gap-1 px-3 py-[2px] hover:border-r-4 hover:border-primary hover:bg-white hover:text-primary hover:shadow-lg"
								>
									<BsCart2 /> <span>Orders Invoices</span>
								</NavLink>
								<NavLink
									to="/admin/invoices/data-offer-invoices"
									className="flex items-center gap-1 px-3 py-[2px] hover:border-r-4 hover:border-primary hover:bg-white hover:text-primary hover:shadow-lg"
								>
									<BsCart2 /> <span>Data Offer Invoices</span>
								</NavLink>

								<NavLink
									to="/admin/invoices/give-back-invoices"
									className="flex items-center gap-1 px-3 py-[2px] hover:border-r-4 hover:border-primary hover:bg-white hover:text-primary hover:shadow-lg"
								>
									<BsDownload /> <span>Give Back Invoices</span>
								</NavLink>

								<NavLink
									to="/admin/invoices/trainning-invoices"
									className="flex items-center gap-1 px-3 py-[2px] hover:border-r-4 hover:border-primary hover:bg-white hover:text-primary hover:shadow-lg"
								>
									<BsDownload /> <span>Trainning Invoices</span>
								</NavLink>

								<NavLink
									to="/admin/invoices/wallet-rechargeable-invoices"
									className="flex items-center gap-1 px-3 py-[2px] hover:border-r-4 hover:border-primary hover:bg-white hover:text-primary hover:shadow-lg"
								>
									<BsDownload /> <span>Wallet Rechargeable Invoices</span>
								</NavLink>
							</div>
						</li>
					</ul>
				)}

				{isVendor(reactLocalStorage.get("RoleName")) && (
					<ul className="space-y-1.5 pt-8 pb-4 h-[85%] lg:h-[83%] overflow-y-auto ">
						<li className="font-semibold text-xl text-gray-700 pb-6 px-3">ভেন্ডর ড্যাসবোর্ড</li>
						{/* these is dashboard navigation  */}
						<li>
							<NavLink to="/admin/vendor-dashboard" className='flex items-center gap-1 text-lg px-3 py-[2px] hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg'>
								<RxDashboard /> <span>Dashboard</span>
							</NavLink>
						</li>
						<li >
							<NavLink to="/admin/vendorWallet" className='flex items-center gap-1 text-lg px-3 py-[2px] hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg'>
								<IoWallet /> <span>Wallet</span>
							</NavLink>
						</li>
						{/* these is catalog item  */}
						<li>
							<div onClick={handleCatalog} className="flex items-center justify-between text-lg hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px] px-3 cursor-pointer">
								<div className="flex items-center gap-1"><ImBooks /> Catalog</div>
								<FiChevronRight className={`${openCatalog ? "rotate-90" : "transition-all"}`} />
							</div>
							{/* these is nested Catalog elements */}
							<div className={` space-y-1 mt-1 text-base ${openCatalog ? "block" : "hidden"}`}>
								{/* manage product here  */}
								<NavLink to="/admin/catalog/productBrands" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
									<TbBrandD3 /> <span>Product Brand</span>
								</NavLink>
								<NavLink to="/admin/catalog/products" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
									<BsBoxes /> <span>Product</span>
								</NavLink>
								<NavLink to="/admin/catalog/productreviews" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
									<MdOutlineRateReview /> <span>Product Reviews</span>
								</NavLink>
							</div>
						</li>


						{/* these is sales items */}
						<li>
							<div onClick={() => setOpenSales(!openSales)} className="flex items-center justify-between text-lg hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px] px-3 cursor-pointer">
								<div className="flex items-center gap-1"><FaSellsy /> Sales</div>
								<FiChevronRight className={`${openSales ? "rotate-90" : "transition-all"}`} /></div>
							{/* this is nested of sells elements  */}

							<div className={`mt-1 space-y-1 text-base ${openSales ? "block" : "hidden"}`}>
								{/* manage user here  */}
								<NavLink to="/admin/sales/order-list" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
									<BsCart2 /> <span>Reselling Orders</span>
								</NavLink>
								<NavLink to="/admin/sales/affiliate-order-list" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
									<BsCart2 /> <span>Affiliate Orders</span>
								</NavLink>

								<NavLink to="/admin/sales/shippingCharge" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
									<TbTruckDelivery /> <span>Shipping Charge</span>
								</NavLink>
								<NavLink to="/admin/sales/returnPolicy" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
									<TbTruckReturn /> <span>রিটার্ন পলিসি</span>
								</NavLink>
							</div>
						</li>
						<li>
							<div
								onClick={() => setOpenInvoices(!openInvoices)}
								className="flex cursor-pointer items-center justify-between px-3 py-[2px] text-lg hover:border-r-4 hover:border-primary hover:bg-white hover:text-primary hover:shadow-lg"
							>
								<div className="flex items-center gap-1">
									<BsDownload /> Invoices
								</div>
								<FiChevronRight
									className={`${openInvoices ? "rotate-90" : "transition-all"}`}
								/>
							</div>
							{/* this is nested of sells elements  */}
							<div
								className={`mt-1 space-y-1 text-base ${openInvoices ? "block" : "hidden"
									}`}
							>
								{/* manage user here  */}
								<NavLink
									to="/admin/invoices/order-invoices"
									className="flex items-center gap-1 px-3 py-[2px] hover:border-r-4 hover:border-primary hover:bg-white hover:text-primary hover:shadow-lg"
								>
									<BsCart2 /> <span>Orders Invoices</span>
								</NavLink>
							</div>
						</li>
					</ul>
				)}

				{isDataOfferVendor(reactLocalStorage.get("RoleName")) && (
					<ul className="space-y-1.5 pt-8 pb-4 h-[85%] lg:h-[83%] overflow-y-auto ">
						<li className="font-semibold text-xl text-gray-700 pb-6 px-3">ডাটা অফার ড্যাসবোর্ড</li>

						<li>
							<NavLink to="/admin/data-offer-vendor/wallet" className='flex items-center gap-1 text-lg px-3 py-[2px] hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg'>
								<IoWallet /> <span>Wallet</span>
							</NavLink>
						</li>
						<li>
							<NavLink to="/admin/data-offer-vendor/data-offer" className='flex items-center gap-1 text-lg px-3 py-[2px] hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg'>
								<BiSolidOffer /> <span>DataOffer</span>
							</NavLink>
						</li>

						<li>
							<NavLink to="/admin/data-offer-vendor/order-list" className='flex items-center gap-1 text-lg px-3 py-[2px] hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg'>
								<BsCart2 /> <span>Orders</span>
							</NavLink>
						</li>
						<li>
							<NavLink
								to="/admin/invoices/data-offer-invoices"
								className="flex items-center gap-1 px-3 py-[2px] hover:border-r-4 hover:border-primary hover:bg-white hover:text-primary hover:shadow-lg"
							>
								<BsCart2 /> <span>Data Offer Invoices</span>
							</NavLink>
						</li>
					</ul>
				)}

				{isBazarRider(reactLocalStorage.get("RoleName")) && (
					<ul className="space-y-1.5 pt-8 pb-4 h-[85%] lg:h-[83%] overflow-y-auto ">
						<li className="font-semibold text-xl text-gray-700 pb-6 px-3">রাইডার ড্যাসবোর্ড</li>
						<li>
							<NavLink to="/admin/super-shop/rider/dashboard" className='flex items-center gap-1 text-lg px-3 py-[2px] hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg'>
								<IoWallet /> <span>Dashboard</span>
							</NavLink>
						</li>
						<li>
							<NavLink to="/admin/super-shop/rider/wallet" className='flex items-center gap-1 text-lg px-3 py-[2px] hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg'>
								<IoWallet /> <span>Wallet</span>
							</NavLink>
						</li>
						<li>
							<NavLink to="/admin/super-shop/rider/product-brand-list" className='flex items-center gap-1 text-lg px-3 py-[2px] hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg'>
								<BiSolidOffer /> <span>Brand</span>
							</NavLink>
						</li>
						<li>
							<NavLink to="/admin/super-shop/rider/product-list" className='flex items-center gap-1 text-lg px-3 py-[2px] hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg'>
								<BsCart2 /> <span>Product</span>
							</NavLink>
						</li>
						<NavLink to="/admin/super-shop/order-list" className='flex items-center gap-1 px-3 hover:bg-white hover:text-primary hover:border-r-4 hover:border-primary hover:shadow-lg py-[2px]'>
							<BsCart2 /> <span>Orders</span>
						</NavLink>
					</ul>
				)}

				<div className={`absolute bg-white overflow-hidden pt-2.5 bottom-8 lg:bottom-2 space-y-2 mt-auto w-full  px-2 ${openDashboard ? "w-60" : "w-0 hidden"}`}>
					<div className="flex items-center justify-between w-full">
						<Link to={'https://www.youtube.com/@Hellobazarofficial'} className="myBorder flex items-center gap-1 justify-center group text-primary hover:text-white py-1 2xl:px-4 hover:bg-primary cursor-pointer w-[47%] text-center">
							<FaYoutube className='text-red text-xl group-hover:text-white' />
							<h2 className='2xl:text-xl md:text-base text-sm  font-semibold'>Youtube</h2>
						</Link>
						<Link to={'https://www.facebook.com/groups/190319601597423/?ref=share_group_link'} className="myBorder flex items-center gap-1 justify-center group text-primary hover:text-white py-1 2xl:px-4 hover:bg-primary cursor-pointer w-[47%] text-center">
							<FaFacebook className='text-royalBlue text-xl group-hover:text-white' />
							<h2 className='2xl:text-xl md:text-base text-sm  font-semibold'>Facebook</h2>
						</Link>
					</div>
					<div onClick={handleLogOut} className='flex items-center justify-center text-red myBorder border-red gap-2 cursor-pointer text-lg px-4 py-0.5 hover:bg-red hover:text-white hover:shadow-lg'>
						<LuLogOut title="Log Out" className="text-base md:text-lg" />
						<p>Logout</p>
					</div>
				</div>
			</nav>
			{/* open close div  */}
			{openDashboard ?
				<div onClick={() => setOpenDashboard(prev => (!prev))} className="absolute top-1 right-1 w-8 h-8 text-primary font-bold flex items-center justify-center text-2xl z-20 cursor-pointer">
					<CgCloseR />
				</div>
				:
				<div onClick={() => setOpenDashboard(prev => (!prev))} className="absolute z-10 top-2 left-3 lg:static border border-secondary shadow lg:border-l-0 lg:border-t-0 lg:border-b-0 lg:border-r lg:border-r-gray-300  text-secondary bg-white lg:bg-gray-50 lg:shadow-xl w-7 lg:w-10 h-7 lg:h-full rounded lg:rounded-none p-0.5 lg:px-0.5 lg:py-3 flex items-center lg:items-start justify-center text-3xl lg:text-3xl lg:z-20 cursor-pointer">
					<MdSpaceDashboard />
				</div>
			}
		</div>
	);
};
