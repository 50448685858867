import { GET_DIVISION_LIST } from "../../../constants/auth/address/AddressConstants";

function divisionListReducer(state= { data: []} , action: any) {
    switch (action.type) {
        case GET_DIVISION_LIST :
            return {...state, loading: false, data: action.payload };
        default:
            return state;
    }
}



export { divisionListReducer }