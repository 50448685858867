import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppInput } from "../../../../../common/AppInput";
import { AppButton } from "../../../../../common/AppButton";
import { AlertColor } from "@mui/material";
import { AppSnackBar } from "../../../../../common/AppSnackBar";
import { RootState } from "../../../../../../store";
import { sSGetProductBrandByIdAction, sSgetProductBrandListAction } from "../../../../../../store/actions/superShop/sSProductBrandAction";
import { sSupdateProductBrandService } from "../../../../../../httpService/superShops/sSProductBrandServices";
import { SSUpdateProdcutBrand } from "../../../../../../models/superShopp/catalog/sSProductBrand/sSProductBrandModel";
import { IdNameResponseModel } from "../../../../../../models/common/IdNameModel";
import { PaginationPayloadModel } from "../../../../../../models/common/paginationModels";
import { sSGetBazarListAction } from "../../../../../../store/actions/superShop/sSBazarActions";
import { AppLoader } from "../../../../../common/AppLoader";
import { AppSelect } from "../../../../../common/AppSelect";


export default function SSUpdateProductBrand() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const brand = useSelector((state: RootState) => {
    return state.sSGetProductBrandByIdResponse.data;
  });

  useEffect(() => {
    dispatch(sSGetProductBrandByIdAction(id));
  }, [dispatch]);

  const [name, setName] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");

  const [bnName, setBnName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [bazarId, setBazarId] = useState(0);
  const [bazarError, setBazarError] = useState("");

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if ((brand)) {
      try {
        setName(brand.name);
        setBnName(brand.bnName);
        setPhoneNumber(brand.phoneNumber);
        setBazarId(brand.bazarId);
      } catch {
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    }
  }, [brand]);


  const handleNameChange = (event: any) => {
    setName(event);
    setNameErrorMessage("");
  };
  const handleBnNameChange = (event: any) => {
    setBnName(event);
  };

  const handlePhoneNumber = (event: any) => {
    setPhoneNumber(event);
  };

  const bazarList = useSelector((state: RootState) => {
    return state.sSBazarListResponse.data;
  });

  var idNameModels: IdNameResponseModel[] = [];
  bazarList.items?.map((x: { id: number; name: string | undefined }) => {
    var temp = new IdNameResponseModel();
    temp.id = x.id;
    temp.name = x.name;
    idNameModels.push(temp);
  });

  useEffect(() => {
    let paginationModel = new PaginationPayloadModel();
    paginationModel.limit = 1000;
    paginationModel.offset = 0;
    dispatch(sSGetBazarListAction(paginationModel));
  }, []);

  const handleBazarIdChange = (event: any) => {
    setBazarId(event);
    setBazarError("");
  };

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    var model = new SSUpdateProdcutBrand();
    model.id = id;
    model.name = name;
    model.bnName = bnName;
    model.phoneNumber = phoneNumber;
    model.bazarId = bazarId;

    setIsButtonLoading(true);

    setSnackBarOn(false);
    sSupdateProductBrandService(model)
      .then(() => {
        setSnackBarOn(true);
        setsnackBarMessage("Successfull");
        setSnackBarMessageType("success");
        setIsButtonLoading(false);
        setTimeout(() => {
          navigate("/super-shop/product-brand-list");
        }, 300);
        dispatch(sSgetProductBrandListAction());
      })
      .catch((error: any) => {
        setIsButtonLoading(false);
        setNameErrorMessage(error.response.data.errors?.Name?.[0]);
        setBazarError(error.response.data.errors.BazarId[0]);
      });
  };

  if (isLoading) {
    return (
      <div className="container h-[50dvh] flex items-center justify-center mx-auto px-2 md:px-0 pb-12">
        <AppLoader status={true} />
      </div>
    );
  }

  return (
    <div>
      <div>
        {snackBarOn && (
          <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
        )}
      </div>

      <div className="pl-4 flex items-center justify-between w-3/5 pb-6">
        <AppButton
          onClick={() => navigate("/super-shop/product-brand-list")}
          text="Back"
        />
        <h2 className="text-center title">Update Product Brand</h2>
      </div>

      <form className="max-w-xl mx-auto myBorder py-4 px-5">
        <AppInput
          label="Brand name"
          className="myInput"
          type="text"
          placeholder="Enter Brand Name"
          value={name}
          onChange={handleNameChange}
          errorMessage={nameErrorMessage}
        />

        <AppInput
          label="BnName"
          placeholder="Enter Bangla Name"
          className="myInput"
          type="text"
          value={bnName}
          onChange={handleBnNameChange}
        />

        <AppInput
          label="Phone Number"
          className="myInput"
          type="text"
          placeholder="Enter Phone Number"
          value={phoneNumber}
          onChange={handlePhoneNumber}
        />

        <div className='w-full lg:w-1/2'>
          <label className="text-sm lg:text-base" placeholder="Dhaka">Select Bazar</label>
          <AppSelect
            value={bazarId}
            data={idNameModels}
            defaultValue="Select Bazar"
            errorMessage={bazarError}
            onChange={handleBazarIdChange}
          />
        </div>

        <div className="flex justify-end">
          <AppButton onClick={handleSubmit} isSubmitting={isButtonLoading} variant="contained" text="Submit" />
        </div>
      </form>
    </div>
  );
}
