export default function AfterVendorRequestSubmit() {
  return (
    <div className="w-screen h-[90dvh] flex flex-col gap-1 items-center justify-center">
      <img className="w-11/12 lg:w-1/4 object-contain h-1/2 lg:h-2/3" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Vendor_request_zvvtlo.png" alt="vendor request" />
      <p className="text-xl lg:text-2xl text-primary font-medium text-center max-w-lg px-4 lg:px-0">
        Vendor request has been sent Successfully, you will get phone call or email within 24 hours
      </p>
    </div>
  )
}
