import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../../../../store";
import { sSGetProductListAction } from "../../../../../../store/actions/superShop/sSProductActions";
import { PaginationPayloadModel } from "../../../../../../models/common/paginationModels";
import { AppButton } from "../../../../../common/AppButton";
import { AppSearchBox } from "../../../../../common/AppSearchBox";
import { AppSnackBar } from "../../../../../common/AppSnackBar";
import { AppDeleteModal } from "../../../../../common/AppDeleteModal";
import { AlertColor } from "@mui/material";
import { BsFillCircleFill } from "react-icons/bs";
import { TbCurrencyTaka } from "react-icons/tb";
import {
  sSDeleteProductService,
  sSProductPublishService,
} from "../../../../../../httpService/superShops/ssProductServices";
import { AppLoader } from "../../../../../common/AppLoader";
import { AppPagination } from "../../../../../common/AppPagination";
import { AppSelect } from "../../../../../common/AppSelect";
import { SSGetProductsRequest, SSProductPublishModel } from "../../../../../../models/superShopp/catalog/sSProduct/sSProductModel";
import { getUserProfileAction } from "../../../../../../store/actions/auth/user/userActions";
import { IdNameResponseModel } from "../../../../../../models/common/IdNameModel";
import { SSGetSubCategoryListRequest } from "../../../../../../models/superShopp/catalog/sSCategory/ssSubCategoryModel";
import { SSGetProductBrandListRequest } from "../../../../../../models/superShopp/catalog/sSProductBrand/sSProductBrandModel";
import { sSGetMainCategoryListAction } from "../../../../../../store/actions/superShop/sSMainCategoryActions";
import { sSgetProductBrandListAction } from "../../../../../../store/actions/superShop/sSProductBrandAction";
import { sSGetSubCategoryListAction } from "../../../../../../store/actions/superShop/sSSubCategoryActions";
import { SSGetBazarListRequest } from "../../../../../../models/superShopp/Address/sSBazar/sSBazarModel";
import { sSGetBazarListAction } from "../../../../../../store/actions/superShop/sSBazarActions";

export default function SSGetProductList() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  var request = new SSGetProductsRequest();

  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);

  const productss = useSelector((state: RootState) => {
    return state.sSGetProductListResponse.data;
  });

  const [isLoading, setIsLoading] = useState(true);

  const filterOptions = [
    { name: "Active Products", id: 2 },
    { name: "Inactive Products", id: 3 },
    { name: "Stock Out Products", id: 4 },
  ];

  const [filterById, setFilterById] = useState(localStorage.getItem("shouldMaintainPage") ? parseInt(localStorage.getItem("filterById") || "0", 10) : 0);
  const [searchQuery, setSearchQuery] = useState(localStorage.getItem("shouldMaintainPage") ? localStorage.getItem("searchQuery") ?? "" : "");
  const [currentPage, setCurrentPage] = useState(localStorage.getItem("shouldMaintainPage") ? parseInt(localStorage.getItem("currentPage") || "1", 10) : 1);
  const [pageLimit, setPageLimit] = useState(localStorage.getItem("shouldMaintainPage") ? parseInt(localStorage.getItem("pageLimit") || "5", 10) : 10);

  useEffect(() => {
    localStorage.setItem("currentPage", currentPage.toString());
    localStorage.setItem("pageLimit", pageLimit.toString());
    localStorage.setItem("searchQuery", searchQuery);
    localStorage.setItem("filterById", filterById.toString());
  }, [currentPage, pageLimit, filterById, searchQuery]);

  useEffect(() => {
    localStorage.removeItem("shouldMaintainPage");
  }, []);

  const handleOptionChange = (value: any) => {
    setFilterById(value);
    setCurrentPage(1);
    request.filterBy = value;
    request.limit = pageLimit;
    request.offset = 0;
    request.searchQuery = searchQuery;
    dispatch(sSGetProductListAction(request));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        request.limit = pageLimit;
        request.offset = pageLimit * (currentPage - 1);
        request.filterBy = filterById;
        request.searchQuery = searchQuery;
        await dispatch(sSGetProductListAction(request));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  const handleOnRowChange = (payload: PaginationPayloadModel) => {
    const fetchData = async () => {
      try {
        setCurrentPage(payload.curPage);
        setPageLimit(payload.limit ?? pageLimit);
        request.limit = payload.limit;
        request.offset = payload.offset;
        request.filterBy = filterById;
        request.searchQuery = searchQuery;
        await dispatch(sSGetProductListAction(request));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  };

  const handleOnPageChange = (payload: PaginationPayloadModel) => {
    const fetchData = async () => {
      try {
        setCurrentPage(payload.curPage ?? 1);
        request.limit = payload.limit;
        request.offset = payload.offset;
        request.filterBy = filterById;
        request.searchQuery = searchQuery;
        await dispatch(sSGetProductListAction(request));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  };

  const onSearch = (searchText: string) => {
    setSearchQuery(searchText);
    setCurrentPage(1);
    request.searchQuery = searchText;
    request.limit = pageLimit;
    request.offset = 0;
    request.filterBy = filterById;
    dispatch(sSGetProductListAction(request));
  };

  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  const handleUpdate = (id: any) => {
    navigate(`/super-shop/product/update/${id}`);
  };

  const [bulkIds, setBulkIds] = useState([]);

  const openDeleteModal = (id: any) => {
    setOpenDeleteModal(true);

    let ids: any = [];
    ids.push(id);

    setBulkIds(ids);
  };

  const handleNavigateProductDetails = (id: any) => {
    navigate(`/super-shop/product/${id}`);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleDelete = () => {
    setSnackBarOn(false);
    sSDeleteProductService(bulkIds)
      .then(() => {
        dispatch(sSGetProductListAction());
        setSnackBarOn(true);
        setSnackBarMessage("Successfully Deleted");
        setSnackBarMessageType("success");
        closeDeleteModal();
      })
      .catch((error: any) => {
        setSnackBarOn(true);
        setSnackBarMessage("Something went wrong");
        setSnackBarMessageType("error");
      });
  };

  const [isUpdating, setIsUpdating] = useState(false);
  const [products, setProducts] = useState<any>([]);

  useEffect(() => {
    setProducts(productss?.items);
  }, [dispatch, productss]);

  const handleProductPublish = (id: any, publish: boolean) => {
    var model = new SSProductPublishModel();
    model.productId = id;
    setIsUpdating(true);
    sSProductPublishService(model)
      .then(() => {
        setIsUpdating(false);
        const pro = products.map((p: any) => {
          if (p.id === id) {
            return { ...p, isPublished: publish };
          }
          return p;
        });
        setProducts(pro);
      })
      .catch(() => {
        setIsUpdating(false);
      });
  };

  const userProfile = useSelector((state: RootState) => {
    return state.getUserProfileResponse.data;
  });

  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);


  const [mainCategoryId, setMainCategoryId] = useState(0);
  const [subCategoryId, setSubCategoryId] = useState(0);
  const [productBrandId, setProductBrandId] = useState(0);
  const [ssBazarId, setSSBazarId] = useState(0);

  const bazarList = useSelector((state: RootState) => {
    return state.sSBazarListResponse.data;
  });

  var bzrIdNameModels: IdNameResponseModel[] = [];
  bazarList.items?.map((x: { id: number; name: string | undefined }) => {
    var temp = new IdNameResponseModel();
    temp.id = x.id;
    temp.name = x.name;
    bzrIdNameModels.push(temp);
  });

  useEffect(() => {
    let paginationModel = new SSGetBazarListRequest();
    paginationModel.limit = 1000;
    paginationModel.offset = 0;
    dispatch(sSGetBazarListAction(paginationModel));
  }, []);

  var mainCatList = useSelector((state: RootState) => {
    return state.sSGetMainCategoryListResponse.data;
  });

  var mCatIdNameModels: IdNameResponseModel[] = [];

  mainCatList.items?.map((x: any) => {
    var cat = new IdNameResponseModel;
    cat.id = x.id;
    cat.name = x.name;
    mCatIdNameModels.push(cat);
  })

  useEffect(() => {
    const model = new PaginationPayloadModel();
    model.limit = 10000;
    model.offset = 0;
    dispatch(sSGetMainCategoryListAction(model));
  }, []);

  const subCatList = useSelector((state: RootState) => {
    return state.sSGetSubCategoryListResponse.data;
  });

  var sCatIdNameModels: IdNameResponseModel[] = [];

  subCatList.items?.map((x: any) => {
    var cat = new IdNameResponseModel;
    cat.id = x.id;
    cat.name = x.name;
    sCatIdNameModels.push(cat);
  })

  useEffect(() => {
    const model = new SSGetSubCategoryListRequest();
    model.limit = 10000;
    model.offset = 0;
    model.sSMainCategoryId = mainCategoryId;
    dispatch(sSGetSubCategoryListAction(model));
  }, [mainCategoryId]);

  const pbList = useSelector((state: RootState) => {
    return state.sSProductBrandResponse.data as any;
  });

  var pbIdNameModels: IdNameResponseModel[] = [];

  pbList.items?.map((x: any) => {
    var pb = new IdNameResponseModel;
    pb.id = x.id;
    pb.name = x.name;
    pbIdNameModels.push(pb);
  })

  useEffect(() => {
    const model = new SSGetProductBrandListRequest();
    model.limit = 10;
    model.offset = 0;
    dispatch(sSgetProductBrandListAction(model));
  }, [dispatch]);

  const handleMainCategory = (mainCatId: number) => {
    const fetchData = async () => {
      try {
        setMainCategoryId(mainCatId);
        setSubCategoryId(0);
        setIsLoading(true);
        request.bazarId = ssBazarId;
        request.mainCategoryId = mainCatId;
        await dispatch(sSGetProductListAction(request));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }

  const handleSubCategory = (subCatId: number) => {
    const fetchData = async () => {
      try {
        setSubCategoryId(subCatId);
        setIsLoading(true);
        request.subCategoryId = subCatId;
        request.bazarId = ssBazarId;
        await dispatch(sSGetProductListAction(request));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }

  const handleBazarIdChange = (bId: any) => {
    const fetchData = async () => {
      try {
        setSSBazarId(bId);
        setIsLoading(true);
        request.bazarId = bId;
        request.mainCategoryId = mainCategoryId;
        request.subCategoryId = subCategoryId;
        await dispatch(sSGetProductListAction(request));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }

  const handleProductBrand = (pbId: any) => {
    const fetchData = async () => {
      try {
        setProductBrandId(pbId);
        setIsLoading(true);
        request.productBrandId = pbId;
        request.bazarId = ssBazarId;
        request.mainCategoryId = mainCategoryId;
        request.subCategoryId = subCategoryId;
        await dispatch(sSGetProductListAction(request));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }

  return (
    <div>
      {userProfile?.isBlocked && <h2 className="text-xl text-red md:text-3xl font-semibold">Your Account has been Suspended. Please contact with Admin.</h2>}
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}

      <h2 className="title text-center">Product list ({productss?.size})</h2>

      <div className="flex flex-col justify-between gap-2 pb-2 pt-0.5 lg:flex-row lg:items-center lg:gap-4">
  
        <AppSearchBox
          className="myInput text-sm lg:text-base"
          value={searchQuery}
          onChange={onSearch}
          placeholder="Search product Here"
        />
        <div>
          <AppSelect
            defaultValue="Select Bazar"
            value={ssBazarId as number}
            data={bzrIdNameModels}
            onChange={handleBazarIdChange}
          />
        </div>
        <div>
          <AppSelect
            defaultValue="Select Main Category"
            value={mainCategoryId}
            data={mCatIdNameModels}
            onChange={handleMainCategory}
          />
        </div>
        {(mainCategoryId !== 0) &&
          <div>
            <AppSelect
              defaultValue="Select Sub Category "
              value={subCategoryId}
              data={sCatIdNameModels}
              onChange={handleSubCategory}
            />
          </div>
        }
        <div>
          <AppSelect
            defaultValue="Select Product Brand"
            value={productBrandId}
            data={pbIdNameModels}
            onChange={handleProductBrand}
          />
        </div>
        <div className="w-full lg:w-1/4">
          <AppSelect
            defaultValue="Filter Products"
            data={filterOptions}
            value={filterById}
            onChange={handleOptionChange}
          />
        </div>
      </div>
      {isLoading ? (
        <AppLoader status={true} />
      ) : (
        <>
          {products ? (
            <div className="w-full">
              {/* these is header part for desktop */}
              <div className="hidden w-full rounded bg-primary lg:flex">
                <div className="centerDiv w-[4%] border-r py-2.5 font-medium text-white">
                  ID
                </div>
                <div className="centerDiv w-[8%] border-r py-2.5 font-medium text-white">
                  Photo
                </div>
                <div className="centerDiv w-[14.9%] border-r py-2.5 font-medium text-white">
                  Name
                </div>
                <div className="centerDiv w-[12%] border-r py-2.5 font-medium text-white">
                  Brand
                </div>
                <div className="centerDiv w-[6.9%] border-r py-2.5 font-medium text-white">
                  In Stock
                </div>
                <div className="centerDiv w-[9.9%] border-r py-2.5 font-medium text-white">
                  Display Price
                </div>
                <div className="centerDiv w-[10%] border-r py-2.5 font-medium text-white">
                  Price
                </div>
                <div className="centerDiv w-[7.9%] border-r py-2.5 font-medium text-white">
                  Published
                </div>
                <div className="centerDiv w-[8%] border-r py-2.5 font-medium text-white">
                  Update
                </div>
                <div className="centerDiv w-[9.9%] border-r py-2.5 font-medium text-white">
                  Status
                </div>
                <div className="centerDiv w-[8%] py-2.5 font-medium text-white">
                  Details
                </div>
                <div className="centerDiv w-[8%] py-2.5 font-medium text-white">
                  Delete
                </div>
              </div>
              <div className="mb-2 hidden h-[62dvh] flex-col gap-1 overflow-y-auto py-1 lg:flex  2xl:h-[68dvh]">
                {products?.map((product: any) => (
                  <div
                    key={product.id}
                    className="myBorder flex w-full items-center"
                  >
                    <div className="centerDiv h-full w-[4%] border-r border-grey font-medium">
                      {product.id}
                    </div>
                    <div className="centerDiv h-full w-[8%] overflow-hidden border-r border-grey">
                      <img
                        className="h-12 w-14 rounded object-cover object-center"
                        title={product.name}
                        src={product.photoPath}
                        alt="product pic"
                      />
                    </div>
                    <div className="centerDiv h-full w-[15%] border-r border-grey pl-0.5">
                      {product.name}
                    </div>
                    <div className="centerDiv h-full w-[12%] border-r border-grey font-medium">
                      {product.prodcutBrand}
                    </div>
                    <div className="centerDiv h-full w-[7%] border-r border-grey font-medium">
                      {product.availableStock}
                    </div>
                    <div className="centerDiv h-full w-[10%] border-r border-grey font-medium">
                      ৳{parseInt(product.price).toFixed(0)}
                    </div>
                    <div className="centerDiv h-full w-[10%] border-r border-grey font-medium">
                      {product.oldPrice}
                    </div>
                    <div className="centerDiv h-full w-[8%] border-r border-grey">
                      {product.isPublished ? (
                        <div className="centerDiv gap-1">
                          <BsFillCircleFill className="text-xs text-green-500" />{" "}
                          Active
                        </div>
                      ) : (
                        <div className="centerDiv gap-1">
                          <BsFillCircleFill className="text-xs text-red" />{" "}
                          Inactive
                        </div>
                      )}
                    </div>
                    <div className="centerDiv h-full w-[8%] border-r border-grey font-medium">
                      <AppButton
                        className="myBtn border-royalBlue bg-royalBlue py-0.5 text-sm lg:py-1"
                        text="Update"
                        onClick={() => handleUpdate(product.id)}
                      />
                    </div>
                    <div className="centerDiv h-full w-[10%] border-r border-grey font-medium">
                      {product.isPublished ? (
                        <AppButton
                          isSubmitting={isUpdating}
                          className="myBtn border-red bg-red py-0.5 text-sm lg:px-2 lg:py-1"
                          text="UnPublish"
                          onClick={() =>
                            handleProductPublish(product.id, false)
                          }
                        />
                      ) : (
                        <AppButton
                          isSubmitting={isUpdating}
                          className="myBtn bg-green border-green py-0.5 text-sm lg:py-1"
                          text="Publish"
                          onClick={() => handleProductPublish(product.id, true)}
                        />
                      )}
                    </div>
                    <div className="centerDiv h-full w-[8%] border-r border-grey font-medium">
                      <AppButton
                        className="myBtn py-0.5 text-sm lg:py-1"
                        text="Details"
                        onClick={() => handleNavigateProductDetails(product.id)}
                      />
                    </div>
                    <div className="centerDiv h-full w-[8%] border-r border-grey font-medium">
                      <AppButton
                        className="myBtn border-red bg-red py-0.5 text-sm lg:py-1"
                        text="Delete"
                        onClick={() => openDeleteModal(product.id)}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex h-[78dvh] flex-col gap-2 overflow-y-auto pb-10 pt-2 lg:hidden">
                {products?.map((product: any) => (
                  <div
                    key={product.id}
                    className="myBorder flex w-full flex-col p-2 lg:flex-row"
                  >
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center gap-1">
                        <span className="font-medium">ID:</span>{" "}
                        <span> {product.id}</span>
                      </div>
                      <div className="flex items-center gap-1">
                        {product.isPublished ? (
                          <div className="centerDiv gap-1">
                            <BsFillCircleFill className="text-xs text-green-500" />{" "}
                            Active
                          </div>
                        ) : (
                          <div className="centerDiv gap-1">
                            <BsFillCircleFill className="text-xs text-red" />{" "}
                            Inactive
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex w-full items-start justify-between gap-1 overflow-hidden py-1">
                      <div className="flex w-full items-start gap-1">
                        <span className="font-medium">Name:</span>{" "}
                        <span className=" text-sm ">{product.name}</span>
                      </div>
                      <img
                        className="h-12 w-14 rounded border border-grey object-cover object-center shadow-md"
                        title={product.name}
                        src={product.photoPath}
                        alt="product pic"
                      />
                    </div>
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center gap-1">
                        <span className="font-medium">Brand:</span>{" "}
                        <span>{product.prodcutBrand}</span>
                      </div>
                      <div className="flex items-center gap-1">
                        <span className="font-medium">In Stock:</span>{" "}
                        <span>{product.availableStock}</span>
                      </div>
                    </div>
                    <div className="flex w-full items-center justify-between gap-2">
                      <div className="flex items-center gap-1">
                        <span className="font-medium">Price</span>{" "}
                        <span className="flex items-center">
                          <TbCurrencyTaka />
                          {parseInt(product.price).toFixed(0)}
                        </span>
                      </div>

                      <div className="flex items-center gap-1">
                        <span className="font-medium">Highest price</span>{" "}
                        <span className="flex items-center">
                          <TbCurrencyTaka /> {product.heightPrice}
                        </span>
                      </div>
                    </div>

                    <div className="flex w-full items-center justify-between pt-2">
                      <AppButton
                        className="myBtn border-royalBlue bg-royalBlue py-0.5 text-sm lg:py-1"
                        text="Update"
                        onClick={() => handleUpdate(product.id)}
                      />
                      {product.isPublished ? (
                        <AppButton
                          isSubmitting={isUpdating}
                          className="myBtn border-red bg-red py-0.5 text-sm lg:py-1"
                          text="UnPublish"
                          onClick={() =>
                            handleProductPublish(product.id, false)
                          }
                        />
                      ) : (
                        <AppButton
                          isSubmitting={isUpdating}
                          className="myBtn bg-green border-green py-0.5 text-sm lg:py-1"
                          text="Publish"
                          onClick={() => handleProductPublish(product.id, true)}
                        />
                      )}
                      <AppButton
                        className="myBtn py-0.5 text-sm lg:py-1"
                        text="Details"
                        onClick={() => handleNavigateProductDetails(product.id)}
                      />
                      <AppButton
                        isSubmitting={isUpdating}
                        className="myBtn border-red bg-red py-0.5 text-sm lg:py-1"
                        text="Delete"
                        onClick={() => openDeleteModal(product.id)}
                      />
                    </div>

                  </div>
                ))}
              </div>
              <div className="fixed bottom-1 flex w-full items-center bg-white py-1 lg:static">
                <AppPagination
                  curPage={currentPage}
                  limit={pageLimit}
                  offset={pageLimit * (currentPage - 1)}
                  onPageChange={handleOnPageChange}
                  onRowChange={handleOnRowChange}
                  size={productss?.size}
                />
              </div>
            </div>
          ) : (
            <div className="flex h-[50dvh] w-full items-center justify-center text-3xl font-medium text-textBlack/90">
              <span>No product found</span>
            </div>
          )}
        </>
      )}
      {isOpenDeleteModal && (
        <AppDeleteModal
          text="Are you sure to Delete?"
          handleDelete={handleDelete}
          closeDeleteModal={closeDeleteModal}
        />
      )}
    </div>
  );
}
