import { AlertColor } from "@mui/material/Alert";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppInput } from "../../common/AppInput";
import { AppSnackBar } from "../../common/AppSnackBar";
import { AppButton } from "../../common/AppButton";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { EmailOrPhoneResetModel, EmailOrPhoneTokenResetModel } from "../../../models/auth/user/UserModels";
import { emailOrPhoneSendResetPassCodeService, resetPassCodeService, verifyResetPassCodeService } from "../../../httpService/user/userServices";

export default function ResetPassEmail() {
  const navigate = useNavigate();

  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [otp, setOtp] = useState("");

  const [firstName, setFirstName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");


  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isOtpValid, setIsOtpValid] = useState(false);

  const [emailOrPhoneError, setEmailOrPhoneError] = useState("");
  const [otpError, setOtpError] = useState("");

  const [countdown, setCountdown] = useState(60);
  const [canResendOTP, setCanResendOTP] = useState(false);

  const [firstNameError, setFirstNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmShowPassword(!showConfirmPassword);
  };

  useEffect(() => {
    let timer: any;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else {
      setCanResendOTP(true);
    }
    return () => clearTimeout(timer);
  }, [countdown]);


  const handleNameChange = (event: any) => {
    setFirstName(event);
    setFirstNameError("");
  };

  const handleEmailOrPhoneChange = (event: any) => {
    setEmailOrPhone(event);
    setEmailOrPhoneError("");
  };

  const handleOtpChange = (event: any) => {
    setOtp(event);
    setOtpError("");
  };

  const handlePasswordChange = (event: any) => {
    setPassword(event);
    setPasswordError("");
  };

  const handleConfirmPasswordChange = (event: any) => {
    setConfirmPassword(event);
    setConfirmPasswordError("");
  };

  const subscribeHandler = (event: any) => {
    event.preventDefault();
  };

  const handleEmailOrPhone = (event: any) => {
    setEmailOrPhone(event)
    setEmailOrPhoneError("");
  };

  const [isSendEmailButtonLoading, setIsSendEmailButtonLoading] = useState(false);
  const [isSubmitButtonLoadin, setIsSubmitButtonLoading] = useState(false);

  const handleSendEmailVerificationCode = (event: any) => {
    setIsSendEmailButtonLoading(true);
    emailOrPhoneSendResetPassCodeService(emailOrPhone)
      .then((res: any) => {
        setIsEmailValid(true);
        setCountdown(60);
        setCanResendOTP(false);
        setIsSendEmailButtonLoading(false);
        setEmailOrPhoneError("");
        setIsShowSnackBar(true);
        setSnackBarMessage("OTP Send Successfuly");
        setSnackBarMessageType("success");
        setTimeout(() => {
          setIsShowSnackBar(false);
        }, 2000);
      })
      .catch((error: any) => {
        setIsSendEmailButtonLoading(false);
        setEmailOrPhoneError(error.response.data.errors?.EmailOrPhone?.[0]);
      });
  };

  const handleResetPass = () => {
    var canExecute = true;
    if (password.length < 6) {
      setPasswordError("Minimum password length is 6");
      canExecute = false;
    }

    if (password != confirmPassword) {
      setConfirmPasswordError("Password does not match");
      canExecute = false;
    }

    if (canExecute) {

      var model = new EmailOrPhoneResetModel();
      model.emailOrPhone = emailOrPhone;
      model.code = otp;
      model.newPassword = password;
      model.confirmNewPassword = confirmPassword;

      verifyResetPassCodeService(model)
        .then((res: any) => {

          var data = new EmailOrPhoneTokenResetModel();
          data.emailOrPhone = emailOrPhone;
          data.token = res?.data;
          data.newPassword = password;
          data.confirmNewPassword = confirmPassword;

          setIsSubmitButtonLoading(true)

          resetPassCodeService(data)
            .then(() => {
              setIsSubmitButtonLoading(false)
              setIsShowSnackBar(true);
              setSnackBarMessage("Password Updated Successfuly");
              setSnackBarMessageType("success");
              setTimeout(() => {
                setIsShowSnackBar(false);
              }, 2000);

              setTimeout(() => {
                navigate("/signin", {
                  replace: true,
                });
              }, 2000);

            }).catch(() => {
              setIsSubmitButtonLoading(false)
              setIsShowSnackBar(true);
              setSnackBarMessage("Something went wrong please try again");
              setSnackBarMessageType("error");
              setTimeout(() => {
                setIsShowSnackBar(false);
              }, 3000);
            })

        }).catch((err: any) => {
          setOtpError("Invalid OTP");
        })
    }

  };


  return (
    <div className="max-h-screen overflow-y-auto">
      {isShowSnackBar && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      {/* these is main container  */}
      <div className="container mx-auto py-16">
        {/* these is body of reset pass  */}
        <div className="w-full md:w-3/5 rounded mx-auto md:shadow-lg p-6">
          <h4 className="subTitle text-center pb-2 text-textBlack">Reset your Password</h4>
          <p className="text-sm text-gray-700 text-center pt-1">Enter your email address and we'll send you a link to reset your password.</p>
          <h3 className="text-lg font-medium text-center text-textBlack">Or</h3>
          <p className="text-sm text-gray-700 text-center pt-1">Enter your Phone address and we'll send you a OTP to reset your password.</p>
          <div className="flex flex-col md:px-20 py-6">
            <AppInput
              type="email"
              label="Email or Phone"
              className="myInput"
              placeholder="Enter your email or phone"
              onChange={handleEmailOrPhoneChange}
              errorMessage={emailOrPhoneError}
              isDisabled={isEmailValid ? true : false}
            />
            {!isEmailValid && (
              <AppButton
                text=" Send Verification Code"
                className="myBtn mt-3"
                isSubmitting={isSendEmailButtonLoading}
                onClick={handleSendEmailVerificationCode}
              />
            )}

            {isEmailValid &&
              <>
                <div>
                  <p className="text-center text-xl font-medium text-gray-700">OTP</p>
                  <AppInput
                    className="py-2 flex text-center text-xl"
                    type="text"
                    maxLength={6}
                    placeholder="X X X X X X"
                    onChange={(event: any) => handleOtpChange(event)}
                    errorMessage={otpError}
                  />
                </div>

                {countdown != 0 && (
                  <span className="text-sm text-gray-600">Can resend OTP after {countdown} seconds</span>
                )}
                {countdown == 0 && (
                  // <AppButton
                  //   text="Resend OTP"
                  //   isSubmitting={isSendEmailButtonLoading}
                  //   className="myBtn"
                  //   onClick={handleSendEmailVerificationCode}
                  // />
                  <div onClick={handleSendEmailVerificationCode} className="text-primary font-medium text-sm lg:text-base cursor-pointer text-center underline underline-offset-2">
                    Resend OTP
                  </div>
                )}
                <div className="relative pt-2">
                  <AppInput
                    type={showPassword ? "text" : "password"}
                    className="myInput"
                    label="Password"
                    placeholder="Enter your Password"
                    value={password}
                    onChange={handlePasswordChange}
                    errorMessage={passwordError}
                  />
                  {showPassword ? (
                    <AiOutlineEyeInvisible
                      className="absolute text-2xl text-gray-700 font-bold cursor-pointer right-3 top-9 lg:top-10"
                      onClick={togglePasswordVisibility}
                    />
                  ) : (
                    <AiOutlineEye
                      className="absolute text-2xl text-gray-700 font-bold cursor-pointer right-3 top-9 lg:top-10"
                      onClick={togglePasswordVisibility}
                    />
                  )}
                </div>

                <div className="relative pt-2">
                  <AppInput
                    type={showConfirmPassword ? "text" : "password"}
                    className="myInput"
                    label="Password"
                    placeholder="Enter your Password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    errorMessage={confirmPasswordError}
                  />
                  {showConfirmPassword ? (
                    <AiOutlineEyeInvisible
                      className="absolute text-2xl text-gray-700 font-bold cursor-pointer right-3 top-9 lg:top-10"
                      onClick={toggleConfirmPasswordVisibility}
                    />
                  ) : (
                    <AiOutlineEye
                      className="absolute text-2xl text-gray-700 font-bold cursor-pointer right-3 top-9 lg:top-10"
                      onClick={toggleConfirmPasswordVisibility}
                    />
                  )}

                </div>
                <AppButton
                  text="Submit"
                  isSubmitting={isSubmitButtonLoadin}
                  className="myBtn mt-4"
                  onClick={handleResetPass}
                />
              </>
            }
          </div>
        </div>

        {/* <div className="reset-passemail-reset2">
          <p className="reset-passemail-input-txt">
            Yes! Send me exclusive offers, unique gift ideas, and personalized
            tips for on BlackTree.
          </p>

          <div className="reset-passemail-input">
            <AppInput
              type="text"
              className="reset-passemail-input-2"
              placeholder="Enter your email"
              onChange={(value: any) => setEmailOrPhone(value)}
            />

            <AppButton
              onClick={subscribeHandler}
              className="reset-passemail-Subscribe"
              text={"Subscribe"}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
}
