import { DELETE, GET, POST, PUT } from "../service";

export const addHospitalService = (data: any) => {
	return POST("/api/HospitalNumber/CreateHospitalNumber", data);
};

export const updateHospitalService = (data: any) => {
	return PUT("/api/HospitalNumber/UpdateHospitalNumber", data);
};

export const getHospitalService = (payload: any) => {
	return GET(`/api/HospitalNumber/GetHospitalNumbers?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}&divisionId=${payload?.divisionId}&districtId=${payload?.districtId}&areaId=${payload?.areaId}`);
};

export const getHospitalByIdService = (data: any) => {
	return GET(`/api/HospitalNumber/GetHospitalNumberById?Id=${data.Id}`);
};



export const deleteHospitalService = (data: any) => {
	return DELETE(`/api/HospitalNumber/RemoveHospitalNumbers`, {
		data: { "ids": data },
	});
};


