import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { PaginationPayloadModel } from '../../../models/common/paginationModels';
import { magazinePostList } from '../../../store/actions/magazine/magazinePostActions';
import { RootState } from '../../../store';
import { AppButton } from '../../common/AppButton';
import { deleteMagazinePostService } from '../../../httpService/magazine/magazinePostServices';

export const MagazineAdminPostList = () => {

  var pModel = new PaginationPayloadModel();

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  pModel.limit = 1000;
  pModel.offset = 0;

  var postList = useSelector((state: RootState) => {
    return state.magazineGetPostListResponse.data;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(magazinePostList(pModel))
  }, [])

  const handlePostDelete = (value: any) => {
    deleteMagazinePostService(value)
      .then(() => {
        dispatch(magazinePostList(pModel))
      })
  }
  
  return (
    <div>
      <div>
        <h1>Admin All Post List</h1>
        {postList &&
          (
            postList.items?.map((post: any, index: any) =>
              <div>
                <div className="w-2/5">
                  <img className="rounded-l" src={post.bannerPath} alt="Post Pic" loading="lazy" />
                </div>
                <h2>{post.id}</h2>
                <h2>{post.title}</h2>
                <h2>{post.description}</h2>
                <AppButton
                  className="createMagazinePost"
                  text="Delete"
                  variant="contained"
                  isSubmitting={isButtonLoading}
                  color="primary"
                  onClick={() => handlePostDelete(post.id)}
                />
              </div>
            )
          )}
      </div>


  </div>
)
}
