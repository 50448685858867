import { AlertColor } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sSDeleteSubCatagoryService } from "../../../../../httpService/superShops/ssSubCategoryServices";
import { PaginationPayloadModel } from "../../../../../models/common/paginationModels";
import { RootState } from "../../../../../store";
import { sSGetSubCategoryListAction } from "../../../../../store/actions/superShop/sSSubCategoryActions";
import { AppButton } from "../../../../common/AppButton";
import { AppDeleteModal } from "../../../../common/AppDeleteModal";
import { AppLoader } from "../../../../common/AppLoader";
import { AppPagination } from "../../../../common/AppPagination";
import { AppSearchBox } from "../../../../common/AppSearchBox";
import { AppSnackBar } from "../../../../common/AppSnackBar";
import { SSGetSubCategoryListRequest } from "../../../../../models/superShopp/catalog/sSCategory/ssSubCategoryModel";

export default function AdminSSGetSubCategoryList() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  var request = new SSGetSubCategoryListRequest();

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);

  const catagoryList = useSelector((state: RootState) => {
    return state.sSGetSubCategoryListResponse.data;
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        request.limit = 10;
        request.offset = 0;
        await dispatch(sSGetSubCategoryListAction(request));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  const handleOnRowChange = (payload: SSGetSubCategoryListRequest) => {
    const fetchData = async () => {
      try {
        request.limit = payload.limit;
        request.offset = 0;
        await dispatch(sSGetSubCategoryListAction(request));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }

  const handleOnPageChange = (payload: SSGetSubCategoryListRequest) => {
    const fetchData = async () => {
      try {
        request.limit = payload.limit;
        request.offset = payload.offset;
        await dispatch(sSGetSubCategoryListAction(request));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }

  const onSearch = (searchQuery: string) => {
    request.searchQuery = searchQuery;
    dispatch(sSGetSubCategoryListAction(request));
  };

  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  const navigateToAdd = () => {
    navigate("/admin/super-shop/sub-category/add");
  };

  const handleUpdate = (id: any) => {
    navigate(`/admin/super-shop/sub-category/update/${id}`);
  };

  const [bulkIds, setBulkIds] = useState([]);

  const openDeleteModal = (id: any) => {
    setOpenDeleteModal(true);

    let ids: any = [];
    ids.push(id);

    setBulkIds(ids);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleDelete = () => {
    setSnackBarOn(false);
    sSDeleteSubCatagoryService(bulkIds)
      .then((response: any) => {
        if (response?.data?.statusCode == 'error') {
          setSnackBarOn(true);
          setsnackBarMessage("This Sub Category can not be deleted");
          setSnackBarMessageType("error");
          closeDeleteModal();
        } else {
          setSnackBarOn(true);
          setsnackBarMessage("Successfully Deleted");
          setSnackBarMessageType("success");
          closeDeleteModal();
          dispatch(sSGetSubCategoryListAction(request));
        }
      })
      .catch((error: any) => {
        setSnackBarOn(true);
        setsnackBarMessage("Something went wrong");
        setSnackBarMessageType("error");
      });
  };

  return (
    <div>
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}

      <h2 className="title text-center">Sub Category ({catagoryList?.size})</h2>

      <div className="flex flex-col lg:flex-row lg:items-center gap-2 lg:gap-6 pt-2">
        <AppButton onClick={navigateToAdd} text="Sub Category" />
        <AppSearchBox className="myInput" onChange={onSearch} placeholder="Search Here" />
      </div>
      {isLoading ? (
        <AppLoader status={true} />
      ) : (
        <>
          {catagoryList.items ? (
            <div className="w-full pt-4">
              {/* these is header part for desktop */}
              <div className="hidden lg:flex w-full bg-primary myBorder mr-4">
                <div className="centerDiv text-white font-medium py-2.5 border-r w-[16%]">Photo</div>
                <div className="centerDiv text-white font-medium py-2.5 border-r w-[24%]">Name</div>
                <div className="centerDiv text-white font-medium py-2.5 border-r w-[23.8%]">Total Product</div>
                <div className="centerDiv text-white font-medium py-2.5 border-r w-[15.7%]">Is Publish</div>
                <div className="centerDiv text-white font-medium py-2.5 border-r w-[10%]">Update</div>
                <div className="centerDiv text-white font-medium py-2.5 w-[10%]">Remove</div>
              </div>
              {/* these is middle part for desktop */}
              <div className="hidden lg:flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-1 py-1.5">
                {catagoryList.items.map((category: any) => (
                  <div key={category?.id} className="myBorder flex w-full items-center">
                    <div className="centerDiv justify-start border-r border-grey h-full w-[16%]">
                      <img src={category?.bannerPath} className="object-contain mx-auto w-10 h-10" alt="Sub category img" />
                    </div>
                    <div className="centerDiv justify-start border-r border-grey pl-2 py-1.5 h-full whitespace-nowrap overflow-hidden text-ellipsis w-[24%]">{category?.name}</div>
                    <div className="centerDiv justify-start border-r border-grey pl-2 py-1.5 h-full whitespace-nowrap overflow-hidden text-ellipsis w-[24%]">{category?.totalProduct}</div>
                    <div className="centerDiv border-r border-grey pl-2 py-1.5 h-full whitespace-nowrap overflow-hidden text-ellipsis w-[16%]">{category?.isPublished === true ? "Yes" : "No"}</div>
                    <div className="centerDiv border-r border-grey py-1.5 h-full  w-[10%]">
                      <AppButton
                        className="myBtn bg-royalBlue border-royalBlue py-0.5 lg:py-1 text-sm"
                        text="Update"
                        onClick={() => handleUpdate(category?.id)}
                      />
                    </div>
                    <div className="centerDiv border-r border-grey py-1.5 h-full  w-[10%]">
                      <AppButton
                        className="myBtn bg-red border-red py-0.5 lg:py-1 text-sm"
                        text="Remove"
                        onClick={() => openDeleteModal(category?.id)}
                      />
                    </div>
                  </div>
                ))}
              </div>
              {/* these is middle part for mobile */}
              <div className="h-[78dvh] lg:hidden overflow-y-auto flex flex-col gap-2">
                {catagoryList.items.map((category?: any) => (
                  <div key={category?.id} className="myBorder flex flex-col w-full p-2">
                    <div className="flex items-center justify-between w-full px-4">
                      <img src={category?.bannerPath} className="object-contain w-10 h-10" alt="Sub category img" />
                      <div className="flex items-center gap-1">
                        <span className="font-medium">Is Publish:</span> <span>{category?.isPublished === true ? "Yes" : "No"}</span>
                      </div>
                    </div>
                    <div className="flex flex-col w-full">
                      <div className="flex items-center gap-1">
                        <span className="font-medium">Name:</span> <span>{category?.name}</span>
                      </div>
                      <div className="flex items-center gap-1">
                        <span className="font-medium">Total Product:</span> <span>{category?.totalProduct}</span>
                      </div>

                    </div>
                    {/* these is approve decline details div  */}
                    <div className="flex items-center justify-center gap-4 w-full pt-2">
                      <AppButton
                        className="myBtn bg-royalBlue border-royalBlue py-0.5 lg:py-1 text-sm"
                        text="Update"
                        onClick={() => handleUpdate(category?.id)}
                      />
                      <AppButton
                        className="myBtn bg-red border-red py-0.5 lg:py-1 text-sm"
                        text="Remove"
                        onClick={() => openDeleteModal(category?.id)}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="py-1 flex items-center">
                <AppPagination curPage={1} limit={10} offset={0} onPageChange={handleOnPageChange} onRowChange={handleOnRowChange} size={catagoryList?.size} />
              </div>
            </div>
          ) : (
            <div className="w-full h-[50dvh] flex justify-center items-center text-3xl text-textBlack/90 font-medium">
              <span>No Sub Category found</span>
            </div>
          )}
        </>)}

      {isOpenDeleteModal && (
        <AppDeleteModal
          text="Are you sure to Delete?"
          handleDelete={handleDelete}
          closeDeleteModal={closeDeleteModal}
        />
      )}
    </div>
  );
}
