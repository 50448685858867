import { Route, Routes } from "react-router-dom";
import Invoices from "../../components/public/Invoices/Invoices";
import IVDataOfferInvoice from "../../components/public/Invoices/IVDataOfferInvoice";
import IVDataOfferInvoices from "../../components/public/Invoices/IVDataOfferInvoices";
import IVOrderInvoice from "../../components/public/Invoices/IVOrderInvoice";
import IVOrderInvoices from "../../components/public/Invoices/IVOrderInvoices";

export default function InvoiceRoute() {
  return (
    <>
      <Routes>
        <Route index element={<Invoices />} />
        <Route path="/orders" element={<IVOrderInvoices />} />
        <Route path="/order/:id" element={<IVOrderInvoice />} />
        <Route path="/data-offers" element={<IVDataOfferInvoices />} />
        <Route path="/data-offer/:id" element={<IVDataOfferInvoice />} />
      </Routes>
    </>
  );
}
