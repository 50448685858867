import { getIsAreaSaved, getUserDetailsService, getUserListService } from "../../../../httpService/user/userServices";
import { GetUserListModel } from "../../../../models/auth/user/UserModels";
import { GET_USER_DETAILS, IS_AREA_SAVED } from "../../../constants/auth/user/userConstant";

const getUserListAction = (ppayload: GetUserListModel) => async (dispatch: any) => {
	try {
		const data = getUserListService(ppayload);
		const payload = (await data).data;
		dispatch({ type: "GET-USER-LIST", payload: payload });
	} catch (error) {
	}
};

const getUserDetailsAction = (p: any) => async (dispatch: any) => {
	try {
		const data = getUserDetailsService(p);
		const payload = (await data)?.data;
		dispatch({ type: GET_USER_DETAILS, payload: payload });
	} catch (error) {
	}
};


const getIsAreaSavedAction = (userId?: any) => async (dispatch: any) => {
	try {
		const data = getIsAreaSaved(userId);
		const payload = (await data)?.data;
		dispatch({ type: IS_AREA_SAVED, payload: payload });
	} catch (error) {
	}
};


export { getUserListAction, getUserDetailsAction, getIsAreaSavedAction };
