import React, { useEffect, useState } from "react";
import { AppButton } from "../../common/AppButton";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PaginationPayloadModel } from "../../../models/common/paginationModels";
import { AlertColor } from "@mui/material";
import { RootState } from "../../../store";
import { getDataOfferListAction } from "../../../store/actions/dataOffer/dataOfferAction";
import { AppSnackBar } from "../../common/AppSnackBar";
import { AppLoader } from "../../common/AppLoader";
import { AppPagination } from "../../common/AppPagination";
import { DataOfferListRequest } from "../../../models/auth/user/UserPointModels";
import { BsFillCircleFill } from "react-icons/bs";
import { getDataOfferStatusUpdateService, removeDataOfferService } from "../../../httpService/dataOffer/dataOfferServices";
import { AppModal } from "../../common/AppModal";


export default function AdminGetDataOffers() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  var dataOfferListRequest = new DataOfferListRequest();

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);
  const [roleId, setRoleId] = useState(0);
  const [roleIdErrorMessage, setRoleIdErrorMessage] = useState("");
  const [searchQueryValue, setSearchQueryValue] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [dataOfferType, setDataOfferType] = useState(0);
  const [dataPackageType, setDataPackageType] = useState(0);
  const [mobileOperator, setMobileOperator] = useState(0);

  const [isPackageTypeOpen, setIsPackageTypeOpen] = useState(false);
  const [isMobileOperatorOpen, setIsMobileOperatorOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dataOfferListRequest.limit = 10;
        dataOfferListRequest.offset = 0;
        dataOfferListRequest.dataOfferType = 0;
        dataOfferListRequest.dataPackageType = 0;
        dataOfferListRequest.mobileOperator = 0;
        await dispatch(getDataOfferListAction(dataOfferListRequest));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  const dataOferList = useSelector((state: RootState) => {
    return state.dataOfferListResponse.dataOfferList;
  });

  const handleOnRowChange = (payload: PaginationPayloadModel) => {
    const fetchData = async () => {
      try {
        dataOfferListRequest.limit = payload.limit;
        dataOfferListRequest.offset = 0;
        dataOfferListRequest.dataOfferType = dataOfferType;
        dataOfferListRequest.dataPackageType = dataPackageType;
        dataOfferListRequest.mobileOperator = mobileOperator;
        await dispatch(getDataOfferListAction(dataOfferListRequest));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  };

  const handleOnPageChange = (payload: PaginationPayloadModel) => {
    const fetchData = async () => {
      try {
        dataOfferListRequest.limit = payload.limit;
        dataOfferListRequest.offset = payload.offset;
        dataOfferListRequest.dataOfferType = dataOfferType;
        dataOfferListRequest.dataPackageType = dataPackageType;
        dataOfferListRequest.mobileOperator = mobileOperator;
        await dispatch(getDataOfferListAction(dataOfferListRequest));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  };

  const handleDataOfferDetails = (id: any) => {
    navigate(`/admin/data-offer-vendor/data-offer-details/${id}`);
  };

  const handlDataOfferUpdate = (id: any) => {
    navigate(`/admin/data-offer-vendor/data-offer-update/${id}`);
  }

  const [isDeleteButtonLoading, setIsDeleteButtonLoading] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [dataOfferId, setDataOfferId] = useState(0);

  const openDeleteModal = (id: any) => {
    setDataOfferId(id);
    setIsOpenDeleteModal(true);
  }

  const closeDeleteModal = () => {
    setIsOpenDeleteModal(false)
  }

  const handleDeleteDataOffer = () => {
    var model = {
      id: dataOfferId
    }
    setIsDeleteButtonLoading(true);
    removeDataOfferService(model)
      .then(() => {
        dataOfferListRequest.limit = 10;
        dataOfferListRequest.offset = 0;
        dataOfferListRequest.dataOfferType = 0;
        dataOfferListRequest.dataPackageType = 0;
        dataOfferListRequest.mobileOperator = 0;
        dispatch(getDataOfferListAction(dataOfferListRequest));
        setIsDeleteButtonLoading(false);
        closeDeleteModal()
      }).catch(() => {
        setIsDeleteButtonLoading(false);
      })
  };

  const handleDataOfferType = (value: any) => {
    setIsPackageTypeOpen(true)
    const fetchData = async () => {
      try {
        setDataOfferType(value);
        setDataPackageType(0);
        setMobileOperator(0);
        dataOfferListRequest.limit = 10;
        dataOfferListRequest.offset = 0;
        dataOfferListRequest.dataOfferType = value;
        dataOfferListRequest.dataPackageType = 0;
        dataOfferListRequest.mobileOperator = 0;

        await dispatch(getDataOfferListAction(dataOfferListRequest));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  };

  const handleDataPackageType = (value: any) => {
    setIsMobileOperatorOpen(true)
    const fetchData = async () => {
      try {
        setDataPackageType(value);
        setMobileOperator(0);
        dataOfferListRequest.limit = 10;
        dataOfferListRequest.offset = 0;
        dataOfferListRequest.dataOfferType = dataOfferType;
        dataOfferListRequest.dataPackageType = value;
        dataOfferListRequest.mobileOperator = 0;
        await dispatch(getDataOfferListAction(dataOfferListRequest));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  };

  const handleMobileOperator = (value: any) => {
    const fetchData = async () => {
      try {
        setMobileOperator(value);
        dataOfferListRequest.limit = 10;
        dataOfferListRequest.offset = 0;
        dataOfferListRequest.dataOfferType = dataOfferType;
        dataOfferListRequest.dataPackageType = dataPackageType;
        dataOfferListRequest.mobileOperator = value;
        await dispatch(getDataOfferListAction(dataOfferListRequest));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  };

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleActiveInActive = (id: any, status: any) => {
    var model = {
      id: id,
      isActive: status
    }
    setIsButtonLoading(true);
    getDataOfferStatusUpdateService(model)
      .then(() => {
        dataOfferListRequest.limit = 10;
        dataOfferListRequest.offset = 0;
        dataOfferListRequest.dataOfferType = 0;
        dataOfferListRequest.dataPackageType = 0;
        dataOfferListRequest.mobileOperator = 0;
        dispatch(getDataOfferListAction(dataOfferListRequest));
        setIsButtonLoading(false);
      }).catch(() => {
        setIsButtonLoading(false);
      })
  }

  return (
    <div className="container mx-auto">
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      {isOpenDeleteModal && (
        <AppModal>
          <div className='flex flex-col gap-4 text-2xl max-w-md text-center p-4'>
            Are you sure you want to Delete this Offer?
            <div className='flex items-center justify-center gap-4'>
              <AppButton text="Yes" onClick={handleDeleteDataOffer} />
              <AppButton text="No" onClick={closeDeleteModal} />
            </div></div>
        </AppModal>
      )}
      <div className='w-full pb-2 lg:pb-4 flex items-center justify-between'>
        <Link to="/admin/data-offer-vendor/data-offer-create">
          <AppButton className="myOfferBtn" text="Crate Data offer" onClick={() => { }} />
        </Link>
        <div className='flex items-center w-[58%] justify-end lg:justify-start'>
          <h2 className="title text-center">
            Data Offer list ({dataOferList?.size})
          </h2>
        </div>
      </div>
      <div className='flex flex-col items-center justify-center  gap-1.5 lg:gap-2 pb-2 lg:pb-4'>
        <div className="flex items-center gap-2 lg:gap-4">
          <AppButton className={dataOfferType === 1 ? 'myOfferBtn' : 'offerOutlineBtn'} onClick={() => handleDataOfferType(1)} text="Ajker Offer" />
          <AppButton className={dataOfferType === 2 ? 'myOfferBtn' : 'offerOutlineBtn'}
            onClick={() => handleDataOfferType(2)}
            text="Protidiner Offer"
          />
        </div>
        {isPackageTypeOpen &&
          <div className="flex items-center gap-2 lg:gap-4">
            <AppButton className={dataPackageType === 0 ? 'myOfferBtn' : 'offerOutlineBtn'} onClick={() => handleDataPackageType(0)} text="All" />
            <AppButton className={dataPackageType === 1 ? 'myOfferBtn' : 'offerOutlineBtn'} onClick={() => handleDataPackageType(1)} text="Minutes" />
            <AppButton className={dataPackageType === 2 ? 'myOfferBtn' : 'offerOutlineBtn'} onClick={() => handleDataPackageType(2)} text="Internet" />
            <AppButton className={dataPackageType === 5 ? 'myOfferBtn' : 'offerOutlineBtn'} onClick={() => handleDataPackageType(5)} text="Bundle" />
          </div>
        }
        {isMobileOperatorOpen &&
          <div className="flex items-center gap-2 lg:gap-4">
            <AppButton className={mobileOperator === 0 ? 'myOfferBtn' : 'offerOutlineBtn'} onClick={() => handleMobileOperator(0)} text="All" />
            <div className={`${mobileOperator === 1 ? 'myOfferBtn' : 'offerOutlineBtn'}  flex items-center gap-1`} onClick={() => handleMobileOperator(1)}>
              <span className="hidden lg:block">GrameenPhone</span>
              <img className="h-4 lg:h-5" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/pngegg_8_yd64b0.png" alt="gp" /></div>
            <div className={`${mobileOperator === 2 ? 'myOfferBtn' : 'offerOutlineBtn'}  flex items-center`} onClick={() => handleMobileOperator(2)}>
              <span className="hidden lg:block">  BanglaLink</span>
              <img className="h-4 lg:h-5" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/pngegg_9_gbxxlc.png" alt="bl" /></div>
            <div className={`${mobileOperator === 3 ? 'myOfferBtn' : 'offerOutlineBtn'}  flex items-center gap-1`} onClick={() => handleMobileOperator(3)}>
              <span className="hidden lg:block">     Robi</span>
              <img className="h-4 lg:h-5" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/PikPng.com_outlook-logo-png_5792430_pfbmm8.png" alt="robi" /></div>
            <div className={`${mobileOperator === 4 ? 'myOfferBtn' : 'offerOutlineBtn'}  flex items-center gap-1`} onClick={() => handleMobileOperator(4)}>
              <span className="hidden lg:block"> Airtel</span>
              <img className="h-4 lg:h-5" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/pngegg_7_qib2h0.png" alt="airtel" /></div>
            <div className={`${mobileOperator === 5 ? 'myOfferBtn' : 'offerOutlineBtn'}  flex items-center`} onClick={() => handleMobileOperator(5)} >
              <img className="h-4 lg:h-5" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/7dda3b58784509.5a60b0bd2ae78-removebg-preview_uyzhko.png" alt="skitto" /></div>
            <div className={`${mobileOperator === 6 ? 'myOfferBtn' : 'offerOutlineBtn'}  flex items-center`} onClick={() => handleMobileOperator(6)}>
              <img className="h-4 lg:h-5" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Teletalk_ds29pc.png" alt="teletalk" />
            </div>
          </div>
        }
      </div>

      {isLoading ? (
        <AppLoader status={true} />
      ) : (
        <>
          {dataOferList?.items ? (
            <>
              <div className="w-full">
                {/* these is header part for desktop */}
                <div className="myBorder hidden w-full bg-royalBlue lg:flex">
                  <div className="tableHead w-[14%]">Title</div>
                  <div className="tableHead w-[11.9%]"> Mobile Operator</div>
                  <div className="tableHead w-[10.9%]">Package Type</div>
                  <div className="tableHead w-[9%]">Duration</div>
                  <div className="tableHead w-[9%]">Price</div>
                  <div className="tableHead w-[9%]">Status</div>
                  <div className="tableHead w-[9%]">Details</div>
                  <div className="tableHead w-[9%]">Publish</div>
                  <div className="tableHead w-[9%]">Edit</div>
                  <div className="tableHead border-none w-[9%]">Delete</div>
                </div>
                {/* these is middle part for desktop */}
                <div className="hidden h-[65dvh] flex-col gap-1 overflow-y-auto py-1.5 lg:flex 2xl:h-[68dvh]">
                  {dataOferList?.items?.map((dataOfer: any) => (
                    <div
                      key={dataOfer?.id}
                      className="myBorder flex w-full items-center"
                    >
                      <div className="centerDiv h-full w-[14%] justify-start overflow-hidden text-ellipsis whitespace-nowrap border-r border-grey py-1  pl-2">
                        {dataOfer?.title}
                      </div>
                      <div className="centerDiv h-full w-[12%] overflow-hidden text-ellipsis whitespace-nowrap border-r border-grey py-1  pl-2">
                        {dataOfer?.mobileOperatorName}
                      </div>
                      <div className="centerDiv h-full w-[11%] overflow-hidden text-ellipsis whitespace-nowrap border-r border-grey  py-1">
                        {dataOfer?.dataPackageTypeName}
                      </div>
                      <div className="centerDiv h-full w-[9%] overflow-hidden text-ellipsis whitespace-nowrap border-r border-grey  py-1">
                        {dataOfer?.validityDuration}
                      </div>
                      <div className="centerDiv h-full w-[9%] overflow-hidden text-ellipsis whitespace-nowrap border-r border-grey  py-1">
                        {dataOfer?.price}
                      </div>
                      <div className="centerDiv h-full w-[9%] overflow-hidden text-ellipsis whitespace-nowrap border-r border-grey  py-1">
                        {dataOfer?.isActive ? <div className="centerDiv gap-1"><BsFillCircleFill className="text-green-500 text-xs" /> Active</div> : <div className="centerDiv gap-1"><BsFillCircleFill className="text-red text-xs" /> Inactive</div>}
                      </div>
                      <div className="centerDiv h-full w-[9%] border-r border-grey py-1">
                        <AppButton
                          text="Details"
                          className="myOfferBtn"
                          onClick={() => handleDataOfferDetails(dataOfer?.id)}
                        />
                      </div>
                      <div className="centerDiv font-medium border-r border-grey h-full w-[9%]">
                        {dataOfer.isActive ? (
                          <AppButton
                            isSubmitting={isButtonLoading}
                            className="myOfferBtn bg-red border-red"
                            text="UnPublish"
                            onClick={() => handleActiveInActive(dataOfer?.id, false)}
                          />
                        ) : (
                          <AppButton
                            isSubmitting={isButtonLoading}
                            className="myOfferBtn"
                            text="Publish"
                            onClick={() => handleActiveInActive(dataOfer?.id, true)}
                          />
                        )}
                      </div>
                      <div className="centerDiv h-full w-[9%] border-r border-grey py-1">
                        <AppButton
                          text="Update"
                          className="myOfferBtn"
                          onClick={() => handlDataOfferUpdate(dataOfer?.id)}
                        />
                      </div>
                      <div className="centerDiv h-full w-[9%] border-r border-grey py-1">
                        <AppButton
                          isSubmitting={isDeleteButtonLoading}
                          text="Delete"
                          className="myOfferBtn bg-red border-red"
                          onClick={() => openDeleteModal(dataOfer?.id)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                {/* these is middle part for mobile */}
                <div className="flex h-[78dvh] flex-col gap-2 overflow-y-auto lg:hidden">
                  {dataOferList?.items?.map((dataOfer?: any) => (
                    <div
                      key={dataOfer?.id}
                      className="myBorder flex w-full flex-col gap-0.5 px-1 py-1.5 text-xs"
                    >
                      <div className="grid grid-cols-2">
                        <div className="flex items-center gap-0.5">
                          <span className="font-medium">Title:</span>{" "}
                          <span>{dataOfer?.title}</span>
                        </div>
                        <div className="flex items-center gap-0.5">
                          <span className="font-medium">Operator:</span>{" "}
                          <span>{dataOfer?.mobileOperatorName}</span>
                        </div>
                        <div className="flex items-center gap-0.5">
                          <span className="font-medium">Package Type:</span>{" "}
                          <span>{dataOfer?.dataPackageTypeName}</span>
                        </div>
                        <div className="flex items-center gap-0.5">
                          <span className="font-medium">Duration:</span>
                          <span>{dataOfer?.validityDuration}</span>
                        </div>
                        <div className="flex items-center gap-0.5">
                          <span className="font-medium">Status:</span>
                          <span>{dataOfer?.isActive ? <div className="centerDiv gap-1"><BsFillCircleFill className="text-green-500 text-xxs" /> Active</div> : <div className="centerDiv gap-1"><BsFillCircleFill className="text-red text-xxs" /> Inactive</div>}</span>
                        </div>
                        <div className="flex items-center gap-0.5">
                          <span className="font-medium">Price:</span>{" "}
                          <span> {dataOfer?.price}</span>
                        </div>
                      </div>

                      <div className="grid grid-cols-4 gap-1">
                        <AppButton
                          text="Details"
                          className="myOfferBtn"
                          onClick={() => handleDataOfferDetails(dataOfer?.id)}
                        />
                        {dataOfer.isActive ? (
                          <AppButton
                            isSubmitting={isButtonLoading}
                            className="myOfferBtn bg-red border-red"
                            text="UnPublish"
                            onClick={() => handleActiveInActive(dataOfer?.id, false)}
                          />
                        ) : (
                          <AppButton
                            isSubmitting={isButtonLoading}
                            className="myOfferBtn"
                            text="Publish"
                            onClick={() => handleActiveInActive(dataOfer?.id, true)}
                          />
                        )}
                        <AppButton
                          text="Edit"
                          className="myOfferBtn"
                          onClick={() => handlDataOfferUpdate(dataOfer?.id)}
                        />
                        <AppButton
                          text="Delete"
                          className="myOfferBtn bg-red border-red"
                          onClick={() => openDeleteModal(dataOfer?.id)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                {/* these is footer part  */}
                {/* <div className="myBorder py-1 text-center">pagination here</div> */}
              </div>
              <div className="flex items-center py-1">
                <AppPagination
                  curPage={1}
                  limit={10}
                  offset={0}
                  onPageChange={handleOnPageChange}
                  onRowChange={handleOnRowChange}
                  size={dataOferList?.size}
                />
              </div>
            </>
          ) : (
            <div className="flex h-[50dvh] w-full items-center justify-center text-3xl font-medium text-textBlack/90">
              <span>No Data Offer found</span>
            </div>
          )}
        </>
      )}
    </div>
  );
}
