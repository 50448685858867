import { GET_SS_PRODUCT_REVIEW_LIST, GET_SS_ADMIN_PRODUCT_REVIEW_LIST } from "../../constants/superShop/ssProductReviewConstants";

const inititalState = {
  data: [],
  dataById: []
}

export function sSProductReviewListReducer(state = inititalState, action: any) {
  switch (action.type) {
    case GET_SS_PRODUCT_REVIEW_LIST:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

export function sSProductAdminReviewListReducer(state = inititalState, action: any) {
  switch (action.type) {
    case GET_SS_ADMIN_PRODUCT_REVIEW_LIST:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

