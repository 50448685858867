export class CreateNoticeRequest {
	title?: any;
	description?: any;
	roles?: any;
}

export class UpdateNoticeRequest {
	id?: any;
	title?: any;
	description?: any;
	roles?: any;
}