import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import { RootState } from "../../../../store";
import { getUserProfileAction, getUserProfileNameAction } from "../../../../store/actions/auth/user/userActions";
import { CgMenu } from "react-icons/cg";
import { BiHomeAlt2 } from "react-icons/bi";
import { MdOutlineShoppingCart } from "react-icons/md";
import Navbar from "../../../landingPage/Navbar";
import { isCustomer, isUpazilaAdmin } from "../../../../common/common";
import { sSGetTotalCartItemAction } from "../../../../store/actions/superShop/sSCartActions";

export default function SSNavBar() {

    const location = useLocation();

    const [sticky, setSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setSticky(window.scrollY > 100);
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    });

    const dispatch = useDispatch();

    var token = reactLocalStorage.getObject("SignIn");

    const userProfile = useSelector((state: RootState) => {
        return state.getUserProfileResponse.data;
    });

    useEffect(() => {
        dispatch(getUserProfileAction());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getUserProfileNameAction(userProfile?.firstName));
    }, [dispatch, userProfile]);

    const openLeftDrawer = () => {
        dispatch({ type: "OPEN-LEFT-MENU" });
    }

    const navigateToShoppingCart = () => {
        if (token && JSON.stringify(token) !== JSON.stringify({})) {
        } else {
            dispatch({ type: "OPEN-SIGN-IN" });
            dispatch({ type: "CLOSE-SIGN-UP" });
        }
    };

    var total = 0;

    var token = reactLocalStorage.getObject("SignIn");

    let totalCartItemCount = useSelector((state: RootState) => {
        return state.sSGetTotalCartItemResponse;
    });

    let totalCartItem = totalCartItemCount.data >= 0 ? totalCartItemCount.data : total;

    useEffect(() => {
        if (token && JSON.stringify(token) !== JSON.stringify({})) {
            dispatch(sSGetTotalCartItemAction());
        }
    }, [dispatch]);

    const openExplore = () => {
        dispatch({ type: "OPEN-EXPLORE" });
    };

    return (
        <>
            <div className={`${sticky ? "stickyNav" : ""}`}>
                <Navbar />
                {(JSON.stringify(token) !== JSON.stringify({})) && (
                    <>
                        {location?.pathname?.includes('super-shop') &&

                            <div className="border-b border-grey">
                                <div className="container mx-auto flex gap-2 items-center justify-between 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28">
                                    <div className="flex items-center gap-x-3 md:gap-x-6 2xl:gap-x-8">
                                        <div className="border shadow-sm border-grey 2xl:p-2 md:p-1.5 p-1 rounded cursor-pointer" onClick={openLeftDrawer}>
                                            <CgMenu className=" text-primary cursor-pointer 2xl:text-3xl md:text-2xl text-xl font-bold" />
                                        </div>
                                        <div title="Home" className="border shadow-sm border-grey 2xl:p-2 md:p-1.5 p-1 rounded cursor-pointer">
                                            <Link to="/super-shop">
                                                <BiHomeAlt2 className=" text-primary cursor-pointer 2xl:text-3xl md:text-2xl text-xl font-bold" />
                                            </Link>
                                        </div>
                                    </div>

                                    {(isCustomer(reactLocalStorage.get("RoleName")) && !isUpazilaAdmin(reactLocalStorage.get("RoleName")) &&
                                        <>
                                            <div className="flex gap-4">
                                            <div onClick={openExplore} className="2xl:text-xl text-lg px-10 2xl:py-2 py-1 flex justify-end border border-grey rounded font-semibold hover:bg-primary shadow-sm hover:text-white cursor-pointer">All Category</div>
                                            <div className="hidden md:flex items-center gap-x-6 2xl:gap-x-8" >
                                                <div title="Cart" onClick={navigateToShoppingCart} className="relative border shadow-sm border-grey 2xl:p-2 md:p-1.5 p-1 rounded cursor-pointer">
                                                    <Link to="/super-shop/shoppingcart" >
                                                        <MdOutlineShoppingCart className=" text-primary cursor-pointer 2xl:text-3xl md:text-2xl text-xl font-bold" />
                                                        <p className={`${totalCartItem > 0 ? 'cartNotification' : ''}`}>{totalCartItem > 0 ? totalCartItem : ""}</p>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="md:hidden flex items-center gap-3">
                                                <div onClick={navigateToShoppingCart} className="relative border shadow-sm border-grey 2xl:p-2 md:p-1.5 p-1 rounded cursor-pointer">
                                                    <Link to="/super-shop/shoppingcart" >
                                                        <MdOutlineShoppingCart className=" text-primary cursor-pointer 2xl:text-3xl md:text-2xl text-xl font-bold" />
                                                        <p className={`${totalCartItem > 0 ? 'cartNotification' : ''}`}>{totalCartItem > 0 ? totalCartItem : ""}</p>
                                                    </Link>
                                                </div>
                                            </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        }
                    </>
                )}
            </div>

        </>
    );
}
