import { PaginationPayloadModel } from "../../common/paginationModels";

export class CreateUserRequest {
	firstName?: string;
	lastName?: string;
	email?: string;
	phoneNumber?: string;
	gender?: number = 0;
	password?: string;
	confirmPassword?: string;
	userAddress?: string;
	roleId?: number;
}

export class UpdateUserRequest {
	userId?: any;
	firstName?: string;
	lastName?: string;
	email?: string;
	phoneNumber?: string;
	gender?: number = 0;
	password?: string;
	confirmPassword?: string;
	userAddress?: string;
	roleId?: number;
	userType?: any;
}

export class WithdrawRequest {
	requestAmount?: number;
	paymentMethod?: PaymentMethods;
	paymentNumber?: any;
	note?: any;
}

export enum PaymentMethods {
	BKASH = 1,
	NAGAD = 2,
	ROCKET = 3,
	BANK = 4,
	MAINBALANCE = 5
}

export enum UserType {
	Regular = 1,
	Affiliate = 2,
}

export class GetWalletRechargeHistoryRequest {
	isMyRecord?: any;
	userId?: any;
}

export class WalletRechargeModel {
	amount?: number;
	paymentMethods?: PaymentMethods;
	isRechargeSuccessful?: any;
	paymentId?: any;
}

export class WalletRechargeNagadModel {
	amount?: number;
	TransectionNumber?: string;
	SenderPhoneNumber?: string;
	paymentMethods?: PaymentMethods;
}

export class GetWalletRechargeRequestListRequest extends PaginationPayloadModel {
	requestStatus?: any;
}

export class GetGiveBackHistoryRequest {
	isMyRecord?: any;
	userId?: any;
}

export class GiveBackModel {
	amount?: number;
	paymentMethods?: PaymentMethods;
	isPaymentSuccessful?: boolean;
	paymentId?: any;
}

export class BloodGroupListResponse {
	userId?: number;
	name?: string;
	area?: string;
	bloodGroup?: string;
	phoneNumber?: string;
	email?: string;
	profilePicUrl?: any;
	thana?: string
}


export class PaginationWithUserId extends PaginationPayloadModel {
	id?: string;
}

export class GetUserListModel extends PaginationPayloadModel {
	divisionId?: any = 0;
	districtId?: any = 0;
	areaId?: any = 0;
}


export class AddCountryZilaUpazilaAdminRequest {
	userId?: any;
	roleId?: any;
}


export class SignUpModel {
	firstName?: string;
	emailOrPhone?: string;
	password?: string;
	confirmPassword?: string;
	ResellingRefId?: any;
	AffiliateRefId?: any;
	shopName?: string;
	isVendor: boolean = false;
}


export class SignInModel {
	emailOrPhone?: string;
	password?: string;
	rememberMe?: boolean;
	ReferrerId?: any;
	shopName?: string;
}

export class VendorSignUpModel {
	firstName?: string;
	lastname?: string;
	phone?: any;
	shopName?: string;
	emailOrPhone?: string;
	password?: string;
	confirmPassword?: string;
}

export class VerifyEmail {
	email: string | undefined = "";
	code: string = "";
}


export class UpdateUserProfileRequest {
	firstName?: string;
	lastName?: string;
	fullName?: string;
	email?: string;
	profilePictureId?: string;
	phoneNumber?: string;
	phoneNumberFirst?: string;
	phoneNumberSecond?: string;
	gender?: Gender;
	areaId?: number;
	cityCorporationId?: number;
	wardId?: number;
	userAddress?: string;
	postCode?: string;

	nIDNumber?: string;
	bkashNumber?: string;
	nagadNumber?: string;
	rocketNumber?: string;
	bankName?: string;
	bankAccountNumber?: string;

	faceBookLink?: string;
	instagramLink?: string;
	whatsAppNumber?: string;
	twitterLink?: string;
	bloodGroup?: BloodGroups;

	occupation?: UserOccupation;
	aboutMe?: string;
}

export class DisableUserProfileRequest {
	userId?: number
}



export enum Gender {
	Male,
	Female,
	Unspecified
}

export enum BloodGroups {
	A_Positive,
	A_Negative,

	B_Positive,
	B_Negative,

	O_Positive,
	O_Negative,

	AB_Positive,
	AB_Negative,
}

export enum UserOccupation {
	Teacher = 1,
	Businessman = 2,
	Farmer = 3,
	GovtEmployee = 4,
	ServiceHolder = 5,
	Freelancer = 6,
	Others = 7
}


export class EmailRecoveryResetModel {
	email?: string;
	code?: string;
	newPassword?: string;
	confirmNewPassword?: string;
}


export class EmailOrPhoneResetModel {
	emailOrPhone?: string;
	code?: string;
	newPassword?: string;
	confirmNewPassword?: string;
}

export class EmailOrPhoneTokenResetModel {
	emailOrPhone?: string;
	token?: string;
	newPassword?: string;
	confirmNewPassword?: string;
}


export class VerifyVerificationCodeModel {
	emailOrPhone?: string;
	code?: string;
}


export class AddUpdateEmailPhoneModel {
	emailOrPhone?: string;
	password?: string;
}


export class ChangePasswordRequest {
	oldPassword?: string;
	newPassword?: string
	confirmPassword?: string
}

export class GetAffiliaterTeamPayload extends PaginationPayloadModel {
	userId?: number = 0;
	isAffiliaterTeam?: boolean = true;
}

export class GetCompanyTeamPayload extends PaginationPayloadModel {
	isResellingReferTeam?: any = false
	isAffiliateReferTeam?: any = false
	isAffiliateProductTeam?: any = false
}

export class UpdateUserAddressRequest {
	areaId?: any;
}


export class RiderGivenToPayRequest {
	amount?: any;
}
