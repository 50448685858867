// import { TextareaAutosize } from '@mui/material';
interface ITextAreaProps {
  value?: string;
  maxRows?: number;
  minRows?: number;
  placeholder?: string;
  errorMessage?: string;
  onChange?: any;
  isRequired?: boolean;
  className?: string;
  label?: string;
}

export const AppTextArea: React.FC<ITextAreaProps> = ({
  value,
  maxRows,
  minRows,
  placeholder,
  onChange,
  errorMessage,
  isRequired,
  className,
  label,
}) => {
  const handleChange = (e: any) => {
    onChange(e.target.value);
  };

  return (
    <>
      <div className="flex flex-col w-full gap-y-[2px]">
        <label className=" md:text-base text-sm">{label} {isRequired && <span className="text-red text-lg">*</span>} </label>
        <textarea
          className="myBorder p-2 w-full box-border resize-y transition-all min-h-[100px] outline-1 outline-gray-400"
          onChange={handleChange}
          name="w3review"
          rows={maxRows}
          value={value}
          cols={minRows}
        >
          {value}
        </textarea>
        <p className="text-xxs lg:text-xs text-red pl-[2px]">{errorMessage}</p>
      </div>
     
    </>
  );
};
