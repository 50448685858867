import { CreateUpdateBechteChaiProductRequest } from "../../models/bechteChai/product/bechteChaiProductModels";
import { GetProductListRequest, ProductPublishModel } from "../../models/catalog/product/productsModels";
import { PaginationPayloadModel } from "../../models/common/paginationModels";
import { POST, DELETE, GET, PUT } from "../service";

export const addBechteChaiProductService = (
  model: CreateUpdateBechteChaiProductRequest
) => {
  return POST(`/api/BechteChaiProduct/CreateBechteChaiProduct`, model);
};

export const updateBechteChaiProductService = ( model: CreateUpdateBechteChaiProductRequest)=> {
  return PUT(`/api/BechteChaiProduct/UpdateBechteChaiProduct`, model);
};

export const deleteProductBrandService = (data: any) => {
  return DELETE(`/api/BechteChaiProduct/GetBechteChaiRemoveProduct`, {
    data: { ids: data },
  });
};

export const getProductListService = (pModel?: GetProductListRequest) => {
  if (pModel) {
    return GET(
      `/api/BechteChaiProduct/GetBechteChaiProductList?homeProductFilterType=${pModel.homeProductFilterType}&limit=${pModel?.limit}&offset=${pModel?.offset}&searchQuery=${pModel?.searchQuery}&sortBy=${pModel?.sortBy}&minimumPrice=${pModel?.minimumPrice}&maximumPrice=${pModel?.maximumPrice}&vendorId=${pModel?.vendorId}&mainCategoryId=${pModel?.mainCategoryId}&subCategoryLevelOneId=${pModel?.subCategoryLevelOneId}`
    );
  }
  return GET(`/api/BechteChaiProduct/GetBechteChaiProductList`);
};

export const getBechteChaiProductByIdService = (id?: any) => {
  return GET(`/api/BechteChaiProduct/GetBechteChaiProductById?Id=${id}`);
};

export const addBechteChaiProductMediaService = (data: any) => {
  return POST("/api/BechteChaiProduct/AddProductMedias", data);
};

export const removeBechteChaiProductMediaService = (data: any) => {
  return POST("/api/BechteChaiProduct/RemoveProductMedia", data);
};


export const bechteChaiProductPublishService = (data: ProductPublishModel) => {
  return POST(`/api/BechteChaiProduct/ProductPublish`, data);
};

export const deleteBechteChaiProductService = (data: any) => {
  return DELETE(`/api/BechteChaiProduct/RemoveBechteChaiProduct`, {
    data: { "ids": data },
  });
};

export const addBechteChaiProductCommentService = (data: any) => {
  return POST("/api/BechteChaiProductComment/SubmitProductCommentOrReply", data);
};


export const bechteChaiProductCommentListService = (productId: any) => {
  return GET(`/api/BechteChaiProductComment/GetProductCommentList?productId=${productId}`);
};


export const deleteBechteChaiProductCommentService = (data: any) => {
  return DELETE(`/api/BechteChaiProductComment/RemoveProductComment`, {data: { "id": data }});
};

export const addOrRemoveBechteChaiFavouriteService = (data: any) => {
  return POST("/api/BechteChaiFavorite/AddOrRemoveBechteChaiFavourite", data);
};

export const getBechteChaiFavouriteItemsService = () => {
  return GET(`/api/BechteChaiFavorite/GetBechteChaiFavouriteItems`);
};
export const isAlreadyBechteChaiFavouriteService = (id: any) => {
  return GET(`/api/BechteChaiFavorite/GetIsAlreadyBechteChaiFavouriteOrNot?ProductId=${id}`);
};

