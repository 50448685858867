import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EarningType,
  GetUserWalletResponse,
  UserPullAmountRequest,
} from "../../../models/auth/user/UserPointModels";
import { RootState } from "../../../store";
import {
  getUserProfileAction,
  getUserWalletAction,
} from "../../../store/actions/auth/user/userActions";
import _moment from "moment";
import { AppButton } from "../../common/AppButton";
import { AppModal } from "../../common/AppModal";
import { AppInput } from "../../common/AppInput";
import {
  userPullAmountService,
  walletRecharge,
  walletRechargeNagad,
  withdrawPointRequestService,
} from "../../../httpService/user/userServices";
import {
  PaymentMethods,
  WalletRechargeModel,
  WalletRechargeNagadModel,
  WithdrawRequest,
} from "../../../models/auth/user/UserModels";
import { AppSelect } from "../../common/AppSelect";
import { IoMdClose } from "react-icons/io";
import { AppSnackBar } from "../../common/AppSnackBar";
import { AlertColor } from "@mui/material/Alert";
import {
  CreateBkashPaymentRequest,
  CreateBkashPaymentResponse,
} from "../../../models/payment/paymnetModel";
import { appUrl } from "../../../config/config";
import { createBkashPaymentService } from "../../../httpService/payment/paymnetServices";
import { BKASH_CHARGE_PERCENTAGE } from "../../../constants";
import { AppLoader } from "../../common/AppLoader";
import { trainingPaymentFromMainBalance } from "../../../httpService/training/trainingService";
import { reactLocalStorage } from "reactjs-localstorage";
import { Link } from "react-router-dom";
import { isReseller, isAffiliater, isCustomer, isCountryAdmin, isUpazilaAdmin } from "../../../common/common";


export const orderPaymentStatuses = [
  { id: 1, name: "Paid" },
  { id: 2, name: "NotPaid" },
];

export const pointStatuses = [
  { id: 1, name: "Pending" },
  { id: 3, name: "Received" },
  { id: 4, name: "Cancelled" },
];

export const earningStatus = [
  { id: 1, name: "In-progress" },
  { id: 2, name: "Earned" },
  { id: 3, name: "Denied" },
];

export const earningPointsType = [
  { id: 1, name: "ResellerEarning" },
  { id: 2, name: "CourseEarning" },
  { id: 3, name: "RefferEarning" },
  { id: 4, name: "ModeratorEarning" },
  { id: 5, name: "ProductEarning" },
  { id: 18, name: "ResellingManagerEarning" }
];

const paymentMethodsRecharge = [
  // { id: 1, name: "Bkash" },
  { id: 2, name: "Nagad" },
  { id: 3, name: "Rocket" },
  // { id: 4, name: "Bank" },
];

const paymentMethodsWithdraw = [
  { id: 1, name: "Bkash" },
  { id: 2, name: "Nagad" },
  { id: 3, name: "Rocket" },
  // { id: 4, name: "Bank" },
];

export default function GetUserWallet() {

  const dispatch = useDispatch();

  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

  const [isLoading, setIsLoading] = useState(true);

  const userProfile = useSelector((state: RootState) => {
    return state.getUserProfileResponse.data;
  });

  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);

  const wallet = useSelector((state: RootState) => {
    return state.getuserWalletResponse.data as GetUserWalletResponse;
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getUserWalletAction(0));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  const [isPullModalOpen, setIsPullModalOpen] = useState(false);
  const [pullModalTitle, setPullModalTitle] = useState("");
  const [accounttype, setAccountType] = useState(0);
  const [pullAmount, setPullAmount] = useState(0);
  const [pullAmountError, setPullAmountError] = useState("");

  const handlePullAmount = (value: any) => {
    if (value === "") {
      setPullAmount(0);
    } else {
      setPullAmount(value);
    }
    setPullAmountError("");
  };

  const openPullModal = (type: any) => {
    setIsPullModalOpen(true);
    if (type === EarningType.ResellerEarning) {
      setPullModalTitle("Pull ResellerEarning Money");
      setAccountType(EarningType.ResellerEarning);
    }
    if (type === EarningType.ResellingModeratorEarning) {
      setPullModalTitle("Pull ModeratorEarning Money");
      setAccountType(EarningType.ResellingModeratorEarning);
    }
    if (type === EarningType.ResellingReferEarning) {
      setPullModalTitle("Pull RefferEarning Money");
      setAccountType(EarningType.ResellingReferEarning);
    }
    if (type === EarningType.ResellingCourseEarning) {
      setPullModalTitle("Pull Reselling CourseEarning Money");
      setAccountType(EarningType.ResellingCourseEarning);
    }
    if (type === EarningType.PaymentBackEarning) {
      setPullModalTitle("Pull PaymentBackEarning Money");
      setAccountType(EarningType.PaymentBackEarning);
    }
    if (type === EarningType.AffiliateCourseEarning) {
      setPullModalTitle("Pull Affiliate Course Earning");
      setAccountType(EarningType.AffiliateCourseEarning);
    }
    if (type === EarningType.AffiliateProductEarning) {
      setPullModalTitle("Pull AffiliateProductEarning Money");
      setAccountType(EarningType.AffiliateProductEarning);
    }
    if (type === EarningType.AffiliateReferEarning) {
      setPullModalTitle("Pull AffiliateReferEarning Money");
      setAccountType(EarningType.AffiliateReferEarning);
    }
    if (type === EarningType.AffiliatePaymentBackEarning) {
      setPullModalTitle("Pull AffiliatePaymentBackEarning Money");
      setAccountType(EarningType.AffiliatePaymentBackEarning);
    }
  };

  const closePullModal = () => {
    setPullAmountError("");
    setIsPullModalOpen(false);
  };

  const [isPullButtonLoading, setIsPullButtonLoading] = useState(false);

  const handlePullRequest = (value: any) => {
    var pullmodel = new UserPullAmountRequest();
    pullmodel.amount = pullAmount;
    pullmodel.earningType = accounttype;
    setIsPullButtonLoading(true);
    userPullAmountService(pullmodel)
      .then(() => {
        dispatch(getUserWalletAction(0));
        setIsPullModalOpen(false);
        setIsPullButtonLoading(false);
      })
      .catch((error: any) => {
        setIsPullButtonLoading(false);
        setPullAmountError(error.response.data.errors.Amount[0]);
      });
  };

  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const [isRechargeModalOpen, setIsRechargeModalOpen] = useState(false);

  const openWithdrawModal = () => {
    setIsWithdrawModalOpen(true);
  };

  const closeWithdrawModal = () => {
    setRequestAmount(0);
    setPaymentMethod(undefined);
    setPaymentNumber("");
    setNote(undefined);

    setRequestAmountError("");
    setPaymentMethodError("");
    setPaymentNumberError("");
    setNoteError("");

    setIsWithdrawModalOpen(false);
  };

  const [requestAmount, setRequestAmount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState();
  const [paymentNumber, setPaymentNumber] = useState("");
  const [note, setNote] = useState();

  const [requestAmountError, setRequestAmountError] = useState("");
  const [paymentMethodError, setPaymentMethodError] = useState("");
  const [paymentNumberError, setPaymentNumberError] = useState("");
  const [noteError, setNoteError] = useState("");

  const handleRequestAmount = (value: any) => {
    setRequestAmountError("");
    setRequestAmount(value);
  };

  const handlePaymentMethod = (value: any) => {
    setPaymentMethodError("");
    setPaymentNumberError("");
    setPaymentMethod(value);
    if (value === 1) {
      setPaymentNumber(userProfile?.bkashNumber);
    }
    if (value === 2) {
      setPaymentNumber(userProfile?.nagadNumber ? userProfile.nagadNumber : "");
    }
    if (value === 3) {
      setPaymentNumber(
        userProfile?.rocketNumber ? userProfile?.rocketNumber : ""
      );
    }
    if (value === 4) {
      setPaymentNumber(
        userProfile?.bankAccountNumber ? userProfile?.bankAccountNumber : ""
      );
    }
    if (value === 0) {
      setPaymentNumber("");
    }
  };

  const handleNote = (value: any) => {
    setNoteError("");
    if (value.length > 200) {
      setNoteError("Note must be less than 200 characters");
    }
    setNote(value);
  };

  const [isWithdrawButtonLoading, setIsWithdrawButtonLoading] = useState(false);

  const handleSubmitWithdrawRequest = () => {
    var model2 = new WithdrawRequest();
    model2.requestAmount = requestAmount;
    model2.paymentMethod = paymentMethod;
    model2.paymentNumber = paymentNumber;
    model2.note = note;
    setIsWithdrawButtonLoading(true);
    withdrawPointRequestService(model2)
      .then(() => {
        dispatch(getUserWalletAction(0));
        closeWithdrawModal();
        setIsWithdrawButtonLoading(false);
      })
      .catch((error: any) => {
        setPaymentNumberError(error.response.data.errors?.PaymentMethod?.[0]);
        setRequestAmountError(error.response.data.errors?.RequestAmount?.[0]);
        setIsWithdrawButtonLoading(false);
      });
  };

  const [rechargeAmount, setRechargeAmount] = useState<number>(0);
  const [rechargeAmountError, setRechargeAmountError] = useState("");
  const [rechargeAmountNagad, setRechargeAmountNagad] = useState<number>(0);
  const [rechargeAmountNagadError, setRechargeAmountNagadError] = useState("");
  const [rechargeTransactionNumber, setRechargeTransactionNumber] =
    useState("");
  const [rechargeTransactionNumberError, setRechargeTransactionNumberError] =
    useState("");
  const [rechargeSenderPhoneNumber, setRechargeSenderPhoneNumber] =
    useState("");
  const [rechargeSenderPhoneNumberError, setRechargeSenderPhoneNumberError] =
    useState("");
  const [rechargePaymentMethod, setRechargePaymentMethod] = useState<number>(0);
  const [rechargePaymentMethodError, setRechargePaymentMethodError] =
    useState("");

  const handleRechargeAmount = (amount: number) => {
    setRechargeAmountError("");
    setRechargeAmount(amount);
  };

  const handleRechargeAmountNagad = (amount: number) => {
    setRechargeAmountNagadError("");
    setRechargeAmountNagad(amount);
  };

  const handleRechargeTransactionNumber = (transaction: string) => {
    setRechargeTransactionNumberError("");
    setRechargeTransactionNumber(transaction);
  };

  const handleRechargeSenderPhoneNumber = (phoneNum: string) => {
    setRechargeSenderPhoneNumberError("");
    setRechargeSenderPhoneNumber(phoneNum);
  };

  const handleRechargePaymnetMethod = (item: any) => {
    setRechargePaymentMethod(item);
    setRechargePaymentMethodError("");
  };

  const handleRecharge = () => {
    if (rechargeAmount <= 0) {
      setRechargeAmountError("Invalid Amount");
      return;
    }
    var model = new WalletRechargeModel();
    model.amount = rechargeAmount;
    model.paymentMethods = PaymentMethods.BKASH;
    model.isRechargeSuccessful = false;
    model.paymentId = 0;
    setIsConfrmOrderButtonLoading(true);
    walletRecharge(model)
      .then((response: any) => {
        bkashPayment(
          response?.data?.invoiceNumber,
          response?.data?.totalAmount
        );
      })
      .catch(() => {
        setIsConfrmOrderButtonLoading(false);
      });
  };

  const handleRechargeNagad = () => {
    let canExecute = true;

    if (rechargeAmountNagad < 100) {
      setRechargeAmountNagadError(
        "Invalid Amount must be greater than 100 BDT"
      );
      canExecute = false;
    }
    if (!rechargeTransactionNumber) {
      setRechargeTransactionNumberError("Transection number is required");
      canExecute = false;
    }
    if (!rechargeSenderPhoneNumber) {
      setRechargeSenderPhoneNumberError("Phone number is required");
      canExecute = false;
    }
    if (
      !isValidPhoneNumber(rechargeSenderPhoneNumber) &&
      rechargeSenderPhoneNumber
    ) {
      setRechargeSenderPhoneNumberError("Phone no is invalid");
      canExecute = false;
    }
    if (rechargePaymentMethod <= 0) {
      setRechargePaymentMethodError("Select payment method");
      canExecute = false;
    }

    if (canExecute) {
      var model = new WalletRechargeNagadModel();
      model.amount = rechargeAmountNagad;
      model.paymentMethods = rechargePaymentMethod;
      model.SenderPhoneNumber = rechargeSenderPhoneNumber;
      model.TransectionNumber = rechargeTransactionNumber;
      setIsConfrmOrderButtonLoading(true);
      walletRechargeNagad(model)
        .then(() => {
          setIsConfrmOrderButtonLoading(false);
          setRechargeAmountNagad(0);
          setRechargeSenderPhoneNumber("");
          setRechargeTransactionNumber("");
          setIsShowSnackBar(true);
          setSnackBarMessage("Send Successfully Recharge Request");
          setSnackBarMessageType("success");
          setIsRechargeModalOpen(false);
        })
        .catch(() => {
          setIsConfrmOrderButtonLoading(false);
        });
    }
  };

  function isValidPhoneNumber(phoneNumber: any) {
    // Define a regular expression to match against the phone number format
    const phoneRegex = /^01[0-9]{9}$/;
    // Test the phone number against the regex pattern and return the result
    return phoneRegex.test(phoneNumber);
  }

  const [isConfrmOrderButtonLoading, setIsConfrmOrderButtonLoading] =
    useState(false);

  const bkashPayment = (invoiceNumber: any, totalAmount: number) => {
    var model = new CreateBkashPaymentRequest();
    model.amount = Number(
      (
        (totalAmount as number) +
        (totalAmount as number) * BKASH_CHARGE_PERCENTAGE
      ).toFixed(2)
    );
    model.callbackURL = `${appUrl}/myWallet`;
    model.invoiceNumber = invoiceNumber;

    createBkashPaymentService(model)
      .then((response: any) => {
        let res = response?.data as CreateBkashPaymentResponse;
        if (res?.isSucceeded) {
          window.location.replace(res.data?.bkashURL);
        } else {
          setIsConfrmOrderButtonLoading(false);
        }
      })
      .catch(() => {
        setIsConfrmOrderButtonLoading(false);
      });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const paymentID = urlParams.get("paymentID");
    const status = urlParams.get("status");

    if (paymentID && status) {
      var rechargeModel = new WalletRechargeModel();
      rechargeModel.amount = rechargeAmount;
      rechargeModel.paymentMethods = PaymentMethods.BKASH;
      rechargeModel.isRechargeSuccessful = true;
      rechargeModel.paymentId = paymentID;

      setIsConfrmOrderButtonLoading(true);
      setIsLoading(true);

      walletRecharge(rechargeModel)
        .then(() => {
          dispatch(getUserWalletAction(0));
          setRechargeAmount(0);
          setIsLoading(false);
          setIsConfrmOrderButtonLoading(false);
          setIsShowSnackBar(true);
          setSnackBarMessage("Successfully Recharged");
          setSnackBarMessageType("success");
          setTimeout(() => {
            setIsShowSnackBar(false);
          }, 2000);
        })
        .catch(() => {
          setIsConfrmOrderButtonLoading(false);
        });
    }
  }, []);

  const [isPaymentButnLoading, setIsPaymentButnLoading] = useState(false);

  const handlePayTrainingAmount = () => {
    var payload = {
      trainingId: 1,
    };
    setIsPaymentButnLoading(true);
    trainingPaymentFromMainBalance(payload)
      .then(() => {
        setIsWithdrawModalOpen(false);
        dispatch(getUserWalletAction(0));
        setIsShowSnackBar(true);
        setSnackBarMessage("Payment Successful");
        setSnackBarMessageType("success");
        setTimeout(() => {
          setIsShowSnackBar(false);
        }, 2000);
        setIsPaymentButnLoading(false);
      })
      .catch(() => {
        setIsPaymentButnLoading(false);
      });
  };

  const incomeInfo = [
    {
      text: "Reselling Income",
      value: wallet.resellingIncome,
      handlerValue: EarningType.ResellerEarning,
      isReseller: true,
    },
    {
      text: "Moderator Income",
      value: wallet.resellingModeratorIncome,
      handlerValue: EarningType.ResellingModeratorEarning,
      isReseller: true,
    },
    {
      text: "Refer Income",
      value: wallet.resellingReferIncome,
      handlerValue: EarningType.ResellingReferEarning,
      isReseller: true,
    },
    {
      text: "Course Income",
      value: wallet.resellingCourseIncome,
      handlerValue: EarningType.ResellingCourseEarning,
      isReseller: true,
    },
    {
      text: "Back Amount",
      value: wallet.resellingBackAmount,
      handlerValue: EarningType.PaymentBackEarning,
      isReseller: true,
    },
    {
      text: "Product Income",
      value: wallet.affiliateIncome,
      handlerValue: EarningType.AffiliateProductEarning,
      isAffiliater: true,
    },
    {
      text: "Course Income",
      value: wallet.affiliateCourseIncome,
      handlerValue: EarningType.AffiliateCourseEarning,
      isAffiliater: true,
    },
    {
      text: "Refer Amount",
      value: wallet.affiliateReferIncome,
      handlerValue: EarningType.AffiliateReferEarning,
      isAffiliater: true,
    },
    {
      text: "Back Amount",
      value: wallet.affiliateBackAmount,
      handlerValue: EarningType.AffiliatePaymentBackEarning,
      isAffiliater: true,
    },
  ];

  const earningInfo = [
    {
      name: "Total Reselling Earning",
      value: wallet.totalResellingIncome,
      isReseller: true,
    },
    {
      name: "Total Moderator Earning",
      value: wallet.totalResellingModeratorIncome,
      isReseller: true,
    },
    {
      name: "Total Refer Earning",
      value: wallet.totalResellingReferIncome,
      isReseller: true,
    },
    {
      name: "Total Course Earning",
      value: wallet.totalResellingCourseIncome,
      isReseller: true,
    },
    {
      name: "Total Back amount",
      value: wallet.totalResellingBackAmount,
      isReseller: true,
    },
    {
      name: "Total Affiliate Earning",
      value: wallet.totalAffiliateIncome,
      isAffiliater: true,
    },
    {
      name: "Total Course Earning",
      value: wallet.totalAffiliateCourseIncome,
      isAffiliater: true,
    },
    {
      name: "Total Refer Earning",
      value: wallet.totalAffiliateReferIncome,
      isAffiliater: true,
    },
    {
      name: "Total Back Amount",
      value: wallet.totalAffiliateBackAmount,
      isAffiliater: true,
    },
  ];

  const redirectToUserHistory = () => {
    return "/myWallet/history";
  }

  if (isLoading) {
    return (
      <div className="container mx-auto flex h-screen items-center justify-center px-2 pb-12 md:px-0">
        <AppLoader status={true} />
      </div>
    );
  }

  return (
    <>
      {isShowSnackBar && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}

      {isWithdrawModalOpen && (wallet?.isTrainingAmountPaid || !isReseller(reactLocalStorage.get("RoleName"))) && (
        <AppModal>
          <div className=" p-4 md:w-96">
            <div className="flex justify-end">
              <IoMdClose
                className="cursor-pointer text-2xl font-semibold text-primary"
                onClick={closeWithdrawModal}
              />
            </div>

            <div className="flex flex-col gap-1 px-2 pb-2">
              <AppInput
                type="number"
                className="myInput"
                label="Amount"
                value={requestAmount}
                placeholder="Enter Amount"
                onChange={(value: any) => handleRequestAmount(value)}
                errorMessage={requestAmountError}
              />
              <div>
                <p>Payment Method</p>
                <AppSelect
                  defaultValue="Payment Method"
                  value={paymentMethod}
                  data={paymentMethodsWithdraw}
                  placeholder="Select Payment Method"
                  onChange={(value: any) => handlePaymentMethod(value)}
                  errorMessage={paymentMethodError}
                />
              </div>
              <AppInput
                type="text"
                className="myInput"
                label="Payment Number"
                value={paymentNumber}
                isDisabled={true}
                errorMessage={paymentNumberError}
              />
              <AppInput
                type="text"
                className="myInput"
                label="Note"
                value={note}
                placeholder="Enter Note"
                onChange={(value: any) => handleNote(value)}
                errorMessage={noteError}
              />
            </div>

            <div className="mb-2 mr-2 flex justify-end">
              <AppButton
                text="Submit"
                isSubmitting={isWithdrawButtonLoading}
                onClick={handleSubmitWithdrawRequest}
              />
            </div>
          </div>
        </AppModal>
      )}

      {isWithdrawModalOpen && wallet?.isTrainingAmountPaid === false &&
        isReseller(reactLocalStorage.get("RoleName")) && (
          <AppModal>
            <div className="p-4 md:w-96">
              <div className="flex w-full items-center justify-end">
                <IoMdClose
                  className="cursor-pointer text-2xl font-semibold text-primary"
                  onClick={closeWithdrawModal}
                />
              </div>

              {wallet.mainBalance < 200 && (
                <div className="text-center text-lg font-medium">
                  <p>
                    You have to pay training payment 200tk first. But your main
                    balance doesn't have enough amount.
                  </p>
                </div>
              )}

              {wallet.mainBalance >= 200 && (
                <div className="text-center text-lg font-medium">
                  <p>
                    You have to pay training payment 200tk first. Pay from your
                    Main balance?
                  </p>
                  <div className="flex items-center justify-center pt-1">
                    <AppButton
                      text="Pay"
                      isSubmitting={isPaymentButnLoading}
                      onClick={handlePayTrainingAmount}
                    />
                  </div>
                </div>
              )}
            </div>
          </AppModal>
        )}

      {isRechargeModalOpen && (
        <AppModal>
          <div className="p-2 text-black md:w-96 lg:p-4">
            <div className="flex w-full items-center justify-end">
              <IoMdClose
                className="cursor-pointer text-2xl font-semibold text-primary"
                onClick={() => setIsRechargeModalOpen(false)}
              />
            </div>
            <div className="flex flex-col items-center gap-2 px-2 py-1 lg:gap-2.5 lg:px-4">
              <h2 className="text-center font-medium text-primary">
                Send money to 01732969610 [Nagad/Rocket]
              </h2>

              <AppInput
                type="number"
                label="Amount"
                className="myInput"
                placeholder="Enter your Amount"
                value={rechargeAmountNagad}
                errorMessage={rechargeAmountNagadError}
                onChange={handleRechargeAmountNagad}
              />

              <AppInput
                type="string"
                label="Transection Number"
                className="myInput"
                placeholder="Enter transaction number"
                value={rechargeTransactionNumber}
                errorMessage={rechargeTransactionNumberError}
                onChange={handleRechargeTransactionNumber}
              />

              <AppInput
                type="string"
                label="Sender Phone Number"
                className="myInput"
                placeholder="Enter sender number"
                value={rechargeSenderPhoneNumber}
                errorMessage={rechargeSenderPhoneNumberError}
                onChange={handleRechargeSenderPhoneNumber}
              />

              <p>Payment Method</p>
              <AppSelect
                className="rounded-sm  border border-primary px-1.5 py-0.5 text-sm font-medium leading-3 text-textBlack lg:px-0.5 lg:text-xxs"
                data={paymentMethodsRecharge}
                defaultValue="Payment Method"
                value={rechargePaymentMethod}
                onChange={handleRechargePaymnetMethod}
                errorMessage={rechargePaymentMethodError}
              />

              <AppButton
                text="Recharge"
                isSubmitting={isConfrmOrderButtonLoading}
                className="w-full rounded-sm  border border-grey bg-white py-1 text-center text-sm text-primary hover:shadow-lg lg:py-1.5  lg:text-base lg:font-medium"
                onClick={handleRechargeNagad}
              />
            </div>
          </div>
        </AppModal>
      )}

      {isPullModalOpen && (
        <AppModal>
          <div className="p-4 md:w-96">
            <div className="flex justify-end">
              <IoMdClose
                className="cursor-pointer text-2xl font-semibold text-primary"
                onClick={closePullModal}
              />
            </div>
            <div className="flex flex-col space-y-2 px-2 pb-2">
              <h1 className="text-lg font-medium">{pullModalTitle}</h1>
              <AppInput
                type="number"
                label="Amount"
                className="myInput"
                value={note}
                placeholder="Enter Amount"
                onChange={(value: any) => handlePullAmount(value)}
                errorMessage={pullAmountError}
              />
            </div>
            <div className="flex justify-end px-4">
              <AppButton
                text="Submit"
                isSubmitting={isPullButtonLoading}
                onClick={handlePullRequest}
              />
            </div>
          </div>
        </AppModal>
      )}

      <div className={`${isReseller(reactLocalStorage.get("RoleName")) ? "container" : "max-w-4xl"} mx-auto px-2 pb-4 2xl:py-3 lg:py-2 py-2 md:px-16 lg:px-28 lg:pb-8`}>
        <div className="flex items-center justify-between py-1">
          <h2 className="title pb-1 lg:py-2">My wallet</h2>
          <Link className="myBtn" to={redirectToUserHistory()}>
            History
          </Link>
        </div>

        <div className="w-full mx-auto mb-4 grid grid-cols-2 lg:grid-cols-4 gap-3 lg:gap-4">
          <div
            className={`w-full h-24 lg:h-36 2xl:h-44  rounded-sm  bg-gradient-to-r from-emerald-500 via-emerald-400 to-emerald-300 py-1 lg:space-y-1 lg:py-2 lg:shadow`}
          >
            <p className="pt-1 text-center text-base font-medium text-white lg:pt-2 lg:text-[22px]">
              Main Balance
            </p>
            <div className="flex items-center justify-center">
              <h3 className="text-lg font-semibold text-white lg:text-3xl">
                {wallet.mainBalance}
              </h3>
            </div>
            <div className="flex justify-between px-0.5 pt-1 lg:px-2">
              <div className="flex items-center justify-center">
                <h3 className="text-xsm font-medium text-white lg:text-base lg:font-semibold">
                  {wallet.requestedAmount} Req
                </h3>
              </div>
              <AppButton
                className="rounded-sm  border-grey bg-white px-1 py-0 text-xs font-medium text-primary hover:shadow-xl lg:px-4 lg:py-1 lg:text-base lg:shadow-lg"
                text="Withdraw"
                onClick={openWithdrawModal}
              />
            </div>
          </div>

          {isReseller(reactLocalStorage.get("RoleName")) && (
            <div className="myShd h-24 lg:h-36 2xl:h-44  flex flex-col items-center justify-center space-y-1  rounded-sm border-b-lime-300 bg-white lg:border-b-4 lg:py-2">
              <div className="myShadow flex w-full flex-col items-center gap-0.5 rounded-sm  border-b-2 border-b-red py-0.5 lg:gap-0 lg:rounded-none lg:border-none lg:py-0 lg:shadow-none">
                <p className="text-xs font-medium text-textBlack lg:text-lg">
                  Total Withdrew{" "}
                </p>
                <div className="flex items-center">
                  <h3 className="text-sm font-semibold text-red lg:text-xl">
                    {wallet.totalWithdrawAmount}
                  </h3>
                </div>
              </div>
              <div className="myShadow flex w-full flex-col items-center gap-0.5 rounded-sm py-0.5 lg:gap-0 lg:rounded-none lg:border-none lg:py-0 lg:shadow-none">
                <p className="text-xs font-medium text-textBlack lg:text-lg">
                  Total Earning
                </p>
                <div className="flex items-center">
                  <h3 className="text-sm font-semibold text-primary lg:text-xl">
                    {wallet.totalEarning}
                  </h3>
                </div>
              </div>
            </div>
          )}

          <div className={`myShadow h-24 lg:h-36 2xl:h-44  flex  flex-col  justify-center rounded-sm border-b-2 border-b-teal-700 bg-white py-1.5 lg:space-y-1 lg:border-b-4 lg:py-2`}>
            <p className="text-center text-xs font-medium text-textBlack lg:text-base">
              Recharge your Amount
            </p>
            <div className="flex flex-col items-center gap-1 px-2 pt-1 lg:gap-2 lg:px-4">
              <AppInput
                type="number"
                className="rounded-sm  border border-primary px-1.5 py-0 text-xs font-medium leading-3 text-textBlack lg:px-4 lg:py-0.5 lg:text-base"
                placeholder="Enter your Amount"
                value={rechargeAmount}
                errorMessage={rechargeAmountError}
                onChange={handleRechargeAmount}
              />
              <AppButton
                text="Recharge"
                isSubmitting={isConfrmOrderButtonLoading}
                className="myShadow w-full rounded-sm  border border-grey bg-white text-center text-xs text-primary hover:shadow-lg lg:py-1  lg:text-base lg:font-medium"
                onClick={handleRecharge}
              />
            </div>
          </div>

          <div className={` myShadow h-24 lg:h-36 2xl:h-44  flex flex-col justify-center rounded-sm border-b-2 border-b-teal-700 bg-white py-1.5 lg:space-y-1 lg:border-b-4 lg:py-2`}>
            <p className="text-center text-xs font-medium text-textBlack lg:text-base px-0 lg:px-1 ">
              Recharge your Amount by any method
            </p>
            <div className="px-4 py-4">
              <div
                onClick={() => setIsRechargeModalOpen(true)}
                className="myShadow w-full cursor-pointer rounded-sm  border border-grey bg-white text-center text-xs text-primary hover:shadow-lg lg:py-1  lg:text-base lg:font-medium"
              >
                Recharge
              </div>
            </div>
          </div>

          {isCountryAdmin(reactLocalStorage.get("RoleName")) && !isAffiliater(reactLocalStorage.get("RoleName")) && (
            <div className="myShadow h-20 lg:h-36 2xl:h-44 flex flex-col justify-center space-y-0.5 rounded-sm border-b-2 border-b-affiliate bg-white px-0.5 py-1 lg:space-y-1.5 lg:border-b-4 lg:px-2 lg:py-4">
              <p className="text-center text-xs font-medium text-textBlack lg:text-lg 2xl:text-xl">
                Back Amount
              </p>
              <div className="flex items-center justify-center">
                <h3 className="text-xs font-semibold text-affiliate lg:text-xl">
                  {wallet.affiliateBackAmount}
                </h3>
              </div>
              <div className="flex items-center justify-center px-1 lg:px-0">
                <AppButton
                  text="Pull"
                  className="myShadow w-full rounded-sm  border-grey bg-white text-center text-xsm font-medium text-affiliate lg:border lg:py-1 lg:text-base lg:hover:shadow-xl"
                  onClick={() => {
                    openPullModal(EarningType.AffiliatePaymentBackEarning as any);
                  }}
                />
              </div>
            </div>
          )}

          {isCountryAdmin(reactLocalStorage.get("RoleName")) && (
            <div className="myShadow h-20 lg:h-36 2xl:h-44 flex flex-col justify-center space-y-0.5 rounded-sm border-b-2 border-b-affiliate bg-white px-0.5 py-1 lg:space-y-1.5 lg:border-b-4 lg:px-2 lg:py-4">
              <p className="text-center text-xs font-medium text-textBlack lg:text-lg 2xl:text-xl">
                Super Shop Income (C.A.)
              </p>
              <div className="flex items-center justify-center">
                <h3 className="text-xs font-semibold text-affiliate lg:text-xl">
                  {wallet.totalSuperShopCAIncome}
                </h3>
              </div>
            </div>
          )}

          {isUpazilaAdmin(reactLocalStorage.get("RoleName")) && (
            <div className="myShadow h-20 lg:h-36 2xl:h-44 flex flex-col justify-center space-y-0.5 rounded-sm border-b-2 border-b-affiliate bg-white px-0.5 py-1 lg:space-y-1.5 lg:border-b-4 lg:px-2 lg:py-4">
              <p className="text-center text-xs font-medium text-textBlack lg:text-lg 2xl:text-xl">
                Super Shop Income (UP.A.)
              </p>
              <div className="flex items-center justify-center">
                <h3 className="text-xs font-semibold text-affiliate lg:text-xl">
                  {wallet.totalSuperShopIncome}
                </h3>
              </div>
            </div>
          )}

        </div>

        <div className="w-full flex flex-col lg:flex-row gap-4 lg:gap-4 pb-4">
          {isReseller(reactLocalStorage.get("RoleName")) && (
            <div className="w-full h-32 overflow-y-scroll rounded-sm border-2 border-reselling bg-white lg:h-44">
              <div className="w-full text-sm lg:text-base text-center font-medium border-b-2 border-reselling px-1 py-1  lg:px-3 lg:py-1">
                Reselling
              </div>
              <div className='px-1 py-1  lg:px-3 lg:py-1'>
                {earningInfo.filter((x: any) => x.isReseller)?.map((earning) => (
                  <div className="flex items-center justify-between">
                    <p className="text-xsm text-textBlack lg:text-base lg:font-medium">
                      {earning?.name}
                    </p>
                    <div className="flex items-center">
                      <h3 className="text-xsm font-semibold text-reselling lg:text-base">
                        {earning?.value}
                      </h3>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {isAffiliater(reactLocalStorage.get("RoleName")) && (
            <div className="w-full h-32 overflow-y-scroll rounded-sm border-2 border-affiliate bg-white lg:h-44">
              <div className="w-full text-sm lg:text-base text-center font-medium border-b-2 border-affiliate  px-1 py-1  lg:px-3 lg:py-1">
                Affiliate
              </div>
              <div className='px-1 py-2  lg:px-3 lg:py-1'>
                {earningInfo.filter((x: any) => x.isAffiliater)?.map((earning) => (
                  <div className="flex items-center justify-between">
                    <p className="text-xsm text-textBlack lg:text-lg lg:font-medium">
                      {earning?.name}
                    </p>
                    <div className="flex items-center">
                      <h3 className="text-xsm font-semibold text-affiliate lg:text-base">
                        {earning?.value}
                      </h3>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* these is second div for show earnings */}
        {isReseller(reactLocalStorage.get("RoleName")) && (
          <div className="border-2 2xl:border-4 border-reselling p-2 lg:p-4 mb-4 lg:mb-0">
            <h2 className=" text-center font-bold lg:text-xl">Reselling</h2>
            <div className="grid grid-cols-2 items-center gap-4 pt-2 lg:grid-cols-5 lg:gap-4 lg:py-4">
              {incomeInfo.filter((x: any) => x.isReseller)?.map((item) => (
                <div
                  key={item.text}
                  className="myShadow h-20 lg:h-36 2xl:h-44 flex flex-col  justify-center space-y-0.5 rounded-sm border-b-2 border-b-reselling bg-white px-0.5 py-1 lg:space-y-1.5 lg:border-b-4  lg:px-2 lg:py-4"
                >
                  <p className="text-center text-xs font-medium text-textBlack lg:text-lg 2xl:text-xl">
                    {item.text}
                  </p>
                  <div className="flex items-center justify-center">
                    <h3 className="text-xs font-semibold text-reselling lg:text-xl">
                      {item.value}
                    </h3>
                  </div>
                  <div className="flex items-center justify-center px-1 lg:px-0">
                    <AppButton
                      text="Pull"
                      className="myShadow w-full rounded-sm  border-grey bg-white text-center text-xsm font-medium text-reselling lg:border lg:py-1 lg:text-base lg:hover:shadow-xl"
                      onClick={() => {
                        openPullModal(item.handlerValue);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {isAffiliater(reactLocalStorage.get("RoleName")) && (
          <div className="border-2 2xl:border-4 border-affiliate p-2 lg:p-4 lg:mt-4">
            <h1 className="text-center font-bold lg:text-xl">
              Affiliate
            </h1>
            <div className="grid grid-cols-2 gap-1.5 pt-2 lg:grid-cols-4 lg:gap-4 lg:pt-4">
              {incomeInfo.filter((x: any) => x.isAffiliater)?.map((item) => (
                <div
                  key={item.text}
                  className="myShadow h-20 lg:h-36 2xl:h-44 flex flex-col justify-center space-y-0.5 rounded-sm border-b-2 border-b-affiliate bg-white px-0.5 py-1 lg:space-y-1.5 lg:border-b-4 lg:px-2 lg:py-4"
                >
                  <p className="text-center text-xs font-medium text-textBlack lg:text-lg 2xl:text-xl">
                    {item.text}
                  </p>
                  <div className="flex items-center justify-center">
                    <h3 className="text-xs font-semibold text-affiliate lg:text-xl">
                      {item.value}
                    </h3>
                  </div>
                  <div className="flex items-center justify-center px-1 lg:px-0">
                    <AppButton
                      text="Pull"
                      className="myShadow w-full rounded-sm  border-grey bg-white text-center text-xsm font-medium text-affiliate lg:border lg:py-1 lg:text-base lg:hover:shadow-xl"
                      onClick={() => {
                        openPullModal(item.handlerValue);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
