import { Route, Routes } from "react-router-dom";
import AdminGetNoticeList from '../../components/admin/notice/AdminGetNoticeList'
import AdminCreateNotice from "../../components/admin/notice/AdminCreateNotice";
import AdminUpdateNotice from "../../components/admin/notice/AdminUpdateNotice";
import AdminGetNoticeById from "../../components/admin/notice/AdminGetNoticeById";


export default function NoticeRoute() {
	return (
		<>
			<Routes>
				<Route path="/notice-list" element={<AdminGetNoticeList />} />
				<Route path="/notice-create" element={<AdminCreateNotice />} />
				<Route path="/notice-update/:id" element={<AdminUpdateNotice />} />
				<Route path="/notice-details/:id" element={<AdminGetNoticeById />} />
			</Routes>
		</>
	)
}