import { PaginationPayloadModel } from "../common/paginationModels";

export class GetOrderInvoicesModel extends PaginationPayloadModel {
  id: number = 0;
  invoiceNumber: string = "";
  invoiceDateTime: string = "";
  invoiceName: string = "";
  userName: string = "";
  paidStatus: string = "";
  transactionId: string = "";
  totalAmount: string = "";
}

export class GetDataOfferInvoicesModel extends PaginationPayloadModel {
  id: number = 0;
  invoiceNumber: string = "";
  invoiceDateTime: string = "";
  invoiceName: string = "";
  userName: string = "";
  paidStatus: string = "";
  transactionId: string = "";
  totalAmount: string = "";
}
