import { PaginationPayloadModel } from "../../../models/common/paginationModels";
import { sSProductAdminReviewListService, sSProductReviewListService } from "../../../httpService/superShops/ssProductReviewServices";
import { GET_SS_PRODUCT_REVIEW_LIST, GET_SS_ADMIN_PRODUCT_REVIEW_LIST } from "../../constants/superShop/ssProductReviewConstants";

export const sSProductReviewListAction = (productId: any) => async (dispatch: any) => {
  var data = sSProductReviewListService(productId);
  const pload = (await data).data;
  dispatch({ type: GET_SS_PRODUCT_REVIEW_LIST, payload: pload });
};

export const sSProductAdminReviewListAction = (paylaod: PaginationPayloadModel) => async (dispatch: any) => {
  var data = sSProductAdminReviewListService(paylaod);
  const pload = (await data).data;
  dispatch({ type: GET_SS_ADMIN_PRODUCT_REVIEW_LIST, payload: pload });
};
