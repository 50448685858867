import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { AlertColor } from "@mui/material";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { AppButton } from "../../../common/AppButton";
import { AppInput } from "../../../common/AppInput";
import { addHospitalNumber } from "../../../../models/importantNumber/ImportantNumberModel";
import { GET } from "../../../../httpService/service";
import { updateHospitalService } from "../../../../httpService/importantNumbers/hospitalServices";
import { isAdmin } from "../../../../common/common";
import { reactLocalStorage } from "reactjs-localstorage";

export default function INUpdateHospitalNumber() {

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { id } = useParams();

	const [snackBarMessage, setsnackBarMessage] = useState("");
	const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
	const [snackBarOn, setSnackBarOn] = useState(false);
	const [isButtonLoading, setIsButtonLoading] = useState(false);

	const [name, setName] = useState("");
	const [nameErrorMessage, setNameErrorMessage] = useState("");
	const [phoneNumberFirst, setPhoneNumberFirst] = useState("");
	const [phoneNumberFirstErrorMessage, setPhoneNumberFirstErrorMessage] = useState("");
	const [phoneNumberSecond, setPhoneNumberSecond] = useState("");
	const [phoneNumberSecondErrorMessage, setPhoneNumberSecondErrorMessage] = useState("");
	const [email, setEmail] = useState("");
	const [emailErrorMessage, setEmailErrorMessage] = useState("");
	const [hotLineNumber, setHotLineNumber] = useState("");
	const [hotLineNumberErrorMessage, setHotLineNumberErrorMessage] = useState("");
	const [address, setAddress] = useState("");
	const [addressErrorMessage, setAddressErrorMessage] = useState("");

	const hospital = async (id: any) => {
		const data = await GET(`/api/HospitalNumber/GetHospitalNumberById?Id=${id}`);
		const response: addHospitalNumber = data?.data;
		setName(response.name);
		setPhoneNumberFirst(response.phoneNumberFirst);
		setPhoneNumberSecond(response.phoneNumberSecond);
		setEmail(response.email);
		setHotLineNumber(response.hotLineNumber);
		setAddress(response.address);
	};

	const handleNameChange = (event: any) => {
		setName(event);
		setNameErrorMessage("");
	}

	const handlePhoneNumberFirstChange = (event: any) => {
		setPhoneNumberFirst(event);
		setPhoneNumberFirstErrorMessage("");
	}
	const handlePhoneNumberSecondChange = (event: any) => {
		setPhoneNumberSecond(event);
		setPhoneNumberSecondErrorMessage("");
	}

	const handleEmailChange = (event: any) => {
		setEmail(event);
		setEmailErrorMessage("");
	}

	const handleHotLineNumberChange = (event: any) => {
		setHotLineNumber(event);
		setHotLineNumberErrorMessage("");
	}

	const handleAddressChange = (event: any) => {
		setAddress(event);
		setAddressErrorMessage("");
	}

	const handleSubmit = (e: any) => {
		e.preventDefault();
		setSnackBarOn(false);
		var payload = {
			id: id,
			name: name,
			phoneNumberFirst: phoneNumberFirst,
			phoneNumberSecond: phoneNumberSecond,
			email: email,
			hotLineNumber: hotLineNumber,
			address: address
		};

		setIsButtonLoading(true);

		updateHospitalService(payload)
			.then(() => {
				setSnackBarOn(true);
				setsnackBarMessage("Hospital Number Updated Successfully.");
				setSnackBarMessageType("success");
				setIsButtonLoading(false);
				setTimeout(() => {
					navigateToHospitalNumbers();
				}, 300);
			})
			.catch((error: any) => {
				setIsButtonLoading(false);
				setNameErrorMessage(error.response.data.errors?.Name?.[0]);
				setPhoneNumberFirstErrorMessage(error.response.data.errors?.PhoneNumberFirst?.[0]);
				setPhoneNumberSecondErrorMessage(error.response.data.errors?.PhoneNumberSecond?.[0]);
				setEmailErrorMessage(error.response.data.errors?.Email?.[0]);
				setHotLineNumberErrorMessage(error.response.data.errors?.HotLineNumber?.[0]);
				setAddressErrorMessage(error.response.data.errors?.Address?.[0]);
			});
	};

	useEffect(() => {
		hospital(id);
	}, []);

	const navigateToHospitalNumbers = () => {
		if (isAdmin(reactLocalStorage.get("RoleName"))) {
		  navigate(`/admin/important-numbers/hospital-numbers`);
		} else {
		  navigate(`/important-numbers/hospital-numbers`);
		}
	  };

	  
	return (
		<>
			{snackBarOn && (
				<AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
			)}
			<div className="pl-4 flex items-center justify-between w-3/5 pb-6">
				<AppButton
					onClick={navigateToHospitalNumbers}
					text="Back"
				/>
				<h2 className="text-center title">Update Hospital Number</h2>
			</div>

			<form className="max-w-xl mx-auto myBorder py-4 px-5">
				<AppInput
					label="Name"
					className="myInput"
					type="text"
					placeholder="Enter Name"
					onChange={handleNameChange}
					errorMessage={nameErrorMessage}
					value={name}
				/>
				<AppInput
					label="Phone Number First"
					className="myInput"
					type="text"
					placeholder="Enter Phone Number"
					onChange={handlePhoneNumberFirstChange}
					errorMessage={phoneNumberFirstErrorMessage}
					value={phoneNumberFirst}
				/>
				<AppInput
					label="Phone Number Second"
					className="myInput"
					type="text"
					placeholder="Enter Phone Number"
					onChange={handlePhoneNumberSecondChange}
					errorMessage={phoneNumberSecondErrorMessage}
					value={phoneNumberSecond}
				/>
				<AppInput
					label="Email"
					className="myInput"
					type="text"
					placeholder="Enter Email"
					onChange={handleEmailChange}
					errorMessage={emailErrorMessage}
					value={email}
				/>
				<AppInput
					label="HotLine Number"
					className="myInput"
					type="text"
					placeholder="Enter HotLine Number"
					onChange={handleHotLineNumberChange}
					errorMessage={hotLineNumberErrorMessage}
					value={hotLineNumber}
				/>
				<AppInput
					label="Address"
					className="myInput"
					type="text"
					placeholder="Enter Address"
					onChange={handleAddressChange}
					errorMessage={addressErrorMessage}
					value={address}
				/>
				<div className="flex justify-end">
					<AppButton
						text="Submit"
						onClick={handleSubmit}
						isSubmitting={isButtonLoading}
						variant="contained"
					/>
				</div>

			</form>
		</>
	);
}