export const GET_BECHTE_CHAI_MAIN_CATEGORY_LIST = 'GET_BECHTE_CHAI_MAIN_CATEGORY_LIST';
export const GET_BECHTE_CHAI_MAIN_CATEGORY_LIST_BY_ID = 'GET_BECHTE_CHAI_MAIN_CATEGORY_LIST_BY_ID';

export const GET_BECHTE_CHAI_SUB_CATEGORY_LIST = 'GET_BECHTE_CHAI_SUB_CATEGORY_LIST';

export const ADD_BECHTE_CHAI_MAIN_CATEGORY = 'ADD_BECHTE_CHAI_MAIN_CATEGORY';
export const ADD_BECHTE_CHAI_SUB_CATEGORY_ONE = 'ADD_BECHTE_CHAI_SUB_CATEGORY';

export const UPDATE_BECHTE_CHAI_MAIN_CATEGORY = 'UPDATE_BECHTE_CHAI_MAIN_CATEGORY';
export const UPDATE_BECHTE_CHAI_SUB_CATEGORY_ONE = 'UPDATE_BECHTE_CHAI_SUB_CATEGORY';

export const REMOVE_BECHTE_CHAI_MAIN_CATEGORY = 'REMOVE_BECHTE_CHAI_MAIN_CATEGORY';
export const REMOVE_BECHTE_CHAI_SUB_CATEGORY_ONE = 'REMOVE_BECHTE_CHAI_SUB_CATEGORY';