import { Route, Routes } from "react-router-dom";
import ImportantNumber from "../../components/public/importantNumber/ImportantNumber";
import INGetPublicRepresentatives from "../../components/public/importantNumber/publicRepresentativesNumber/INGetPublicRepresentatives";
import INCreatePublicRepresentatives from "../../components/public/importantNumber/publicRepresentativesNumber/INCreatePublicRepresentatives";
import INUpdatePublicRepresentatives from "../../components/public/importantNumber/publicRepresentativesNumber/INUpdatePublicRepresentatives";
import INUpdateImamsNumber from "../../components/public/importantNumber/imamsNumber/INUpdateImamsNumber";
import CreateImamsNumber from "../../components/public/importantNumber/imamsNumber/INCreateImamsNumbers";
import INGetImamsNumbers from "../../components/public/importantNumber/imamsNumber/INGetImamsNumbers";
import INGetPoliceOfficialsNumbers from "../../components/public/importantNumber/policeOfficialsNumber/INGetPoliceOfficialsNumbers";
import INCreatePoliceOfficialsNumber from "../../components/public/importantNumber/policeOfficialsNumber/INCreatePoliceOfficialsNumber";
import INUpdatePoliceOfficialsNumber from "../../components/public/importantNumber/policeOfficialsNumber/INUpdatePoliceOfficialsNumber";
import INCreateUnion from "../../components/public/importantNumber/union/INCreateUnion";
import INUpdateUnion from "../../components/public/importantNumber/union/INUpdateUnion";
import INGetUnions from "../../components/public/importantNumber/union/INGetUnions";
import INGetHospitalNumbers from "../../components/public/importantNumber/hospitalNumber/INGetHospitalNumbers";
import INUpdateHospitalNumber from "../../components/public/importantNumber/hospitalNumber/INUpdateHospitalNumber";
import INCreateHospitalNumber from "../../components/public/importantNumber/hospitalNumber/INCreateHospitalNumber";
import INCreateDoctorNumbers from "../../components/public/importantNumber/doctorsNumber/INCreateDoctorNumbers";
import INGetDoctorNumbers from "../../components/public/importantNumber/doctorsNumber/INGetDoctorNumbers";
import INUpdateDoctorNumber from "../../components/public/importantNumber/doctorsNumber/INUpdateDoctorNumber";
import INGetAcademicInstitutes from "../../components/public/importantNumber/academicInstitutes/INGetAcademicInstitutes";
import INCreateAcademicInstitutes from "../../components/public/importantNumber/academicInstitutes/INCreateAcademicInstitutes";
import INUpdateAcademicInstitutes from "../../components/public/importantNumber/academicInstitutes/INUpdateAcademicInstitutes";
import INGetAcademicInstituteById from "../../components/public/importantNumber/academicInstitutes/INGetAcademicInstituteById";
import INCreateAcademicInstituteEmployee from "../../components/public/importantNumber/academicInstitutes/INCreateAcademicInstituteEmployee";
import INUpdateAcademicInstituteEmployee from "../../components/public/importantNumber/academicInstitutes/INUpdateAcademicInstituteEmployee";
import INGetTeachersNumber from "../../components/public/importantNumber/teachersNumber/INGetTeachersNumber";

export default function ImportantNumberRoute() {
  return (
    <>
      <Routes>
        <Route index element={<ImportantNumber />} />
        <Route path="/unions" element={<INGetUnions />} />
        <Route path="/unions/create" element={<INCreateUnion />} />
        <Route path="/unions/update/:id" element={<INUpdateUnion />} />

        <Route path="/academic-institutes" element={<INGetAcademicInstitutes />} />
        <Route path="/academic-institutes/create" element={<INCreateAcademicInstitutes />} />
        <Route path="/academic-institutes/update/:id" element={<INUpdateAcademicInstitutes />} />

        <Route path="/academic-institutes/view/:id" element={<INGetAcademicInstituteById />} />
        <Route path="/academic-institutes/employee-create/:id" element={<INCreateAcademicInstituteEmployee />} />
        <Route path="/academic-institutes/:instituteId/employee-update/:id" element={<INUpdateAcademicInstituteEmployee />} />

        <Route path="/teachers-number" element={<INGetTeachersNumber />} />

        <Route path="/public-representatives" element={<INGetPublicRepresentatives />} />
        <Route path="/public-representatives/create" element={<INCreatePublicRepresentatives />} />
        <Route path="/public-representatives/update/:id" element={<INUpdatePublicRepresentatives />} />

        <Route path="/imams-numbers" element={<INGetImamsNumbers />} />
        <Route path="/imams-number/create" element={<CreateImamsNumber />} />
        <Route path="/imams-number/update/:id" element={<INUpdateImamsNumber />} />

        <Route path="/police-officials-numbers" element={<INGetPoliceOfficialsNumbers />} />
        <Route path="/police-officials-number/create" element={<INCreatePoliceOfficialsNumber />} />
        <Route path="/police-officials-number/update/:id" element={<INUpdatePoliceOfficialsNumber />} />

        <Route path="/hospital-numbers" element={<INGetHospitalNumbers />} />
        <Route path="/hospital-number/create" element={<INCreateHospitalNumber />} />
        <Route path="/hospital-number/update/:id" element={<INUpdateHospitalNumber />} />

        <Route path="/doctor-numbers" element={<INGetDoctorNumbers />} />
        <Route path="/doctor-number/create" element={<INCreateDoctorNumbers />} />
        <Route path="/doctor-number/update/:id" element={<INUpdateDoctorNumber />} />
      </Routes>
    </>
  );
}
