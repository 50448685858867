import { AlertColor, IconButton } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import DeleteIcon from "@mui/icons-material/Delete";
import { addPopularLinkService } from "../../../httpService/popularLink/popularLinkServices";
import { AddPopularLink } from "../../../models/catalog/category/categoryModels";
import { AppButton } from "../../common/AppButton";
import { AppFileInput } from "../../common/AppFileInput";
import { AppInput } from "../../common/AppInput";
import { AppSnackBar } from "../../common/AppSnackBar";
import { AppTextArea } from "../../common/AppTextArea";
import { popularLinkListAction } from "../../../store/actions/popularLink/popularLinkActions";

export default function AdminCreatePopularLink() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
    const [snackBarOn, setSnackBarOn] = useState(false);

    const [id, setId] = useState(0);

    const [name, setName] = useState("");
    const [url, setUrl] = useState("");
    const [nameErrorMessage, setNameErrorMessage] = useState("");
    const [urlErrorMessage, setUrlErrorMessage] = useState("");

    const [description, setDescription] = useState("");
    const [isPublished, setIsPublished] = useState(false);
    const [isShowOnHomePage, setIsShowOnHomePage] = useState(false);
    const [displayOrder, setDisplayOrder] = useState(0);

    const [bannerId, setBanner] = useState(0);
    const [bannerError, setBannerError] = useState("");

    const [tempPath, setTempPath] = useState("");

    const handleNameChange = (event: any) => {
        setName(event);
        setNameErrorMessage("");
    };

    const handleUrlChange = (event: any) => {
        setUrl(event);
        setUrlErrorMessage("");
    };

    const handleDescriptionChange = (event: any) => {
        setDescription(event);
    };

    const handleIsPublishedChange = (event: any) => [setIsPublished(event)];

    const handleIsShowOnHomePageChange = (event: any) => {
        setIsShowOnHomePage(event);
    };

    const handleDisplayOrder = (event: any) => {
        setDisplayOrder(event);
    };

    const handleBanner = (id: any, path: any) => {
        setBanner(id);
        setTempPath(path);
        setBannerError("");
    };

    const deleteBanner = () => {
        setBanner(0);
        setTempPath("");
    };

    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const handleSubmit = (e: any) => {

        e.preventDefault();

        const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

        let isValidUrl = urlPattern.test(url);
        if (url !== "" && !isValidUrl) {
            setUrlErrorMessage("Please Enter A Valid Url!");
            return;
        }

        setSnackBarOn(false);

        var model = new AddPopularLink();
        model.id = id;
        model.name = name;
        model.description = description;
        model.isPublished = isPublished;
        model.displayOrder = displayOrder;
        model.bannerId = bannerId;
        model.url = url;

        setIsButtonLoading(true);

        addPopularLinkService(model)
            .then(() => {
                setSnackBarOn(true);
                setSnackBarMessage("Popular Link Added Successfully.");
                setSnackBarMessageType("success");
                setIsButtonLoading(false);
                setTimeout(() => {
                    navigate("/admin/popular-link/popular-links");
                }, 300);

                dispatch(popularLinkListAction());
            })
            .catch((error: any) => {
                setIsButtonLoading(false);
                setNameErrorMessage(error.response.data.errors?.Name?.[0]);
                setBannerError(error.response.data.errors?.BannerId?.[0]);
            });
    };
    return (
        <div>
            {snackBarOn && (
                <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
            )}

            <div className="pl-2 lg:pl-4 flex items-center justify-between w-3/5 pb-6">
                <AppButton
                    className="popularLinkBtn"
                    onClick={() => navigate("/admin/popular-link/popular-links")}
                    text="Back"
                />
                <h2 className="text-center title">Create PopularLink</h2>
            </div>

            <form className="max-w-xl mx-auto myBorder py-4 px-5">
                <AppInput
                    label="Name"
                    className="myInput"
                    type="text"
                    placeholder="Enter PopularLink Name"
                    onChange={handleNameChange}
                    errorMessage={nameErrorMessage}
                />
                <AppInput
                    label="Url"
                    className="myInput"
                    type="text"
                    placeholder="Enter PopularLink Url"
                    onChange={handleUrlChange}
                    errorMessage={urlErrorMessage}
                />
                <AppTextArea
                    label="Description"
                    placeholder="Enter Description"
                    onChange={handleDescriptionChange}
                />
                {/* publish and show to home checkbox here  */}
                <div className="flex justify-between items-center py-1">

                    <div className="flex items-center gap-x-3">
                        <label htmlFor="isPublished" className="">
                            Published
                        </label>
                        <input
                            className="w-5 h-5 cursor-pointer"
                            type="checkbox"
                            name="isPublished"
                            onChange={() => handleIsPublishedChange(!isPublished)}
                        />
                    </div>

                </div>
                {/* order photo upload and submit button here  */}
                <div className="flex items-center gap-3 py-2 justify-between">
                    <div className="w-1/2 flex items-center gap-1">
                        <label className="">Photo</label>
                        {!bannerId && (
                            <AppFileInput onSave={handleBanner} errorMessage={bannerError} />
                        )}
                        <br />
                        {bannerId !== 0 && (
                            <div className="flex items-center gap-1 lg:gap-x-2">
                                <img
                                    className="w-20 h-16 lg:h-20 rounded object-contain"
                                    src={`${tempPath}`}
                                    alt="upload"
                                />
                                <IconButton onClick={deleteBanner}>
                                    <DeleteIcon className="hover:text-red" />
                                </IconButton>
                            </div>
                        )}
                    </div>

                    <div className="w-1/2">
                        <AppInput
                            className="myInput"
                            label="Display order"
                            type="number"
                            placeholder="Enter Display Order"
                            onChange={handleDisplayOrder}
                        />
                    </div>
                </div>
                <div className="flex justify-center lg:justify-end">
                    <AppButton className="popularLinkBtn" onClick={handleSubmit} isSubmitting={isButtonLoading} variant="contained" text="Submit" />
                </div>
            </form>
        </div>
    )
}