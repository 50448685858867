import { Routes, Route } from "react-router";
import AdminSSGetBazarList from "../../components/admin/superShop/addresss/sSBazarr/AdminSSGetBazarList";
import AdminSSUpdateBazar from "../../components/admin/superShop/addresss/sSBazarr/AdminSSUpdateBazar";
import AdminSSGetMainCategoryList from "../../components/admin/superShop/catalog/sSMainCategory/AdminSSGetMainCategoryList";
import AdminSSCreateMainCategory from "../../components/admin/superShop/catalog/sSMainCategory/AdminSSCreateMainCategory";
import AdminSSUpdateMainCategory from "../../components/admin/superShop/catalog/sSMainCategory/AdminSSUpdateMainCategory";
import AdminSSGetSubCategoryList from "../../components/admin/superShop/catalog/sSSubCategory/AdminSSGetSubCategoryList";
import AdminSSCreateSubCategory from "../../components/admin/superShop/catalog/sSSubCategory/AdminSSCreateSubCategory";
import AdminSSUpdateSubCategory from "../../components/admin/superShop/catalog/sSSubCategory/AdminSSUpdateSubCategory";
import AdminSSGetProductList from "../../components/admin/superShop/catalog/sSProduct/AdminSSGetProductList";
import AdminSSCreateProduct from "../../components/admin/superShop/catalog/sSProduct/AdminSSCreateProduct";
import AdminSSGetProductById from "../../components/admin/superShop/catalog/sSProduct/AdminSSGetProductById";
import AdminSSUpdateProduct from "../../components/admin/superShop/catalog/sSProduct/AdminSSUpdateProduct";
import AdminSSGetProductBrandList from "../../components/admin/superShop/catalog/sSProductBrand/AdminSSGetProductBrandList";
import AdminSSCreateProductBrand from "../../components/admin/superShop/catalog/sSProductBrand/AdminSSCreateProductBrand";
import AdminSSUpdateProductBrand from "../../components/admin/superShop/catalog/sSProductBrand/AdminSSUpdateProductBrand";
import AdminSSCreateBazar from "../../components/admin/superShop/addresss/sSBazarr/AdminSSCreateBazar";
import AdminSSGetRiderList from "../../components/admin/superShop/rider/AdminSSGetRiderList";
import AdminSSCreateRider from "../../components/admin/superShop/rider/AdminSSCreateRider";
import AdminSSGetRiderDashboard from "../../components/admin/superShop/rider/AdminSSGetRiderDashboard";
import SSAdminSSGetOrderList from "../../components/admin/superShop/sales/SSAdminSSGetOrderList";
import SSAdminSSGetOrderById from "../../components/admin/superShop/sales/SSAdminSSGetOrderById";
import AdminSSGetRiderWallet from "../../components/admin/superShop/rider/AdminSSGetRiderWallet";

export default function SuperShopRoute() {
  return (
    <>
      <Routes>
        <Route index element={<AdminSSGetBazarList />} />

        <Route path="/rider-list" element={<AdminSSGetRiderList />} />
        <Route path="/rider/add" element={<AdminSSCreateRider />} />

        <Route path="/bazar-list" element={<AdminSSGetBazarList />} />
        <Route path="/bazar/add" element={<AdminSSCreateBazar />} />
        <Route path="/bazar/update/:id" element={<AdminSSUpdateBazar />} />

        <Route path="/main-category-list" element={<AdminSSGetMainCategoryList />} />
        <Route path="/main-category/add" element={<AdminSSCreateMainCategory />} />
        <Route path="/main-category/update/:id" element={<AdminSSUpdateMainCategory />} />

        <Route path="/sub-category-list" element={<AdminSSGetSubCategoryList />} />
        <Route path="/sub-category/add" element={<AdminSSCreateSubCategory />} />
        <Route path="/sub-category/update/:id" element={<AdminSSUpdateSubCategory />} />

        <Route path="/product-list" element={<AdminSSGetProductList />} />
        <Route path="/product/add" element={<AdminSSCreateProduct />} />
        <Route path="/product/:id" element={<AdminSSGetProductById />} />
        <Route path="/product/update/:id" element={<AdminSSUpdateProduct />} />

        <Route path="/product-brand-list" element={<AdminSSGetProductBrandList />} />
        <Route path="/product-brand/add" element={<AdminSSCreateProductBrand />} />
        <Route path="/product-brand/update/:id" element={<AdminSSUpdateProductBrand />} />

        <Route path="/rider/dashboard" element={<AdminSSGetRiderDashboard />} />
        <Route path="/rider/wallet" element={<AdminSSGetRiderWallet />} />

        <Route path="/rider/product-brand-list" element={<AdminSSGetProductBrandList />} />
        <Route path="/rider/product-list" element={<AdminSSGetProductList />} />

        <Route path="/order-list" element={<SSAdminSSGetOrderList />} />
        <Route path="/order/:id" element={<SSAdminSSGetOrderById />} />
        
      </Routes>
    </>
  )
}
