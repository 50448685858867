import { getCartItemsAdminService, getCartItemsService, getCartListAdminService, getFavouriteItemsService, getTotalShippingChargeService, increseDecreseCartItemService, isAlreadyFavouriteService, removeSingleOrListCartitemService, } from "../../../httpService/shoppingCart/resellingCartServices";
import { GetCartListAdminModel, IncreseDecreseCartItemRequest } from "../../../models/shoppingCart/resellingCartModels";
import { GET_ALL_CART_ITEMES, GET_ALL_FAVOURITE_ITEMES, GET_CART_ITEMS_ADMIN_LIST, GET_CART_LIST_ADMIN_LIST, GET_IS_ALREADY_FAVOURITE, GET_TOTAL_CART_ITEM, GET_TOTAL_SHIPPING_CHARGE, INCRESE_DECRESE_CART_ITEM, REMOVE_SINGLE_OR_LIST_CART_ITEM } from "../../constants/shoppingCart/shoppingCartConstants";

const getTotalShoppingCartItemAction = (count?: any) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_TOTAL_CART_ITEM, payload: count });
    }
    catch (error: any) { }
}

const getCartItemListAction = (pload: any) => async (dispatch: any) => {
    try {
        const data = await getCartItemsService(pload);
        dispatch({ type: GET_ALL_CART_ITEMES, payload: data.data });
    }
    catch (error: any) { }
}

const getFavouriteItemListAction = (pload: any) => async (dispatch: any) => {
    try {
        const data = await getFavouriteItemsService(pload);
        dispatch({ type: GET_ALL_FAVOURITE_ITEMES, payload: data.data });
    }
    catch (error: any) { }
}

const getIsAlreadyFavouriteOrNotAction = (id: any) => async (dispatch: any) => {
    try {
        const data = await isAlreadyFavouriteService(id);
        dispatch({ type: GET_IS_ALREADY_FAVOURITE, payload: data.data });
    }
    catch (error: any) { }
}

const increseDecreseCartItemAction = (payload: IncreseDecreseCartItemRequest) => async (dispatch: any) => {
    try {
        const data = await increseDecreseCartItemService(payload);
        dispatch({ type: INCRESE_DECRESE_CART_ITEM, payload: data.data });
    }
    catch (error: any) { }
}


const removeSingleOrListCartitemAction = (payload: IncreseDecreseCartItemRequest) => async (dispatch: any) => {
    try {
        const data = await removeSingleOrListCartitemService(payload);
        dispatch({ type: REMOVE_SINGLE_OR_LIST_CART_ITEM, payload: data.data });
    }
    catch (error: any) { }
}

const getCartListAdminAction = (payload?: GetCartListAdminModel) => async (dispatch: any) => {
    var data = getCartListAdminService(payload);
    const pload = (await data).data;
    dispatch({ type: GET_CART_LIST_ADMIN_LIST, payload: pload });
};


const getCartItemsAdminAction = (userId: any) => async (dispatch: any) => {
    var data = getCartItemsAdminService(userId);
    const pload = (await data).data;
    dispatch({ type: GET_CART_ITEMS_ADMIN_LIST, payload: pload });
};


const getTotalShippingChargeAction = (inSideDhaka: boolean) => async (dispatch: any) => {
    var data = getTotalShippingChargeService(inSideDhaka);
    const pload = (await data).data;
    dispatch({ type: GET_TOTAL_SHIPPING_CHARGE, payload: pload });
};




export {
    getTotalShoppingCartItemAction,
    getCartItemListAction,
    getFavouriteItemListAction,
    increseDecreseCartItemAction,
    removeSingleOrListCartitemAction,
    getCartItemsAdminAction,
    getCartListAdminAction,
    getTotalShippingChargeAction,
    getIsAlreadyFavouriteOrNotAction
};