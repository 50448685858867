import { sSGetBazarByIdService, sSgetBazarListService } from "../../../httpService/superShops/ssBazarServices";
import { SSGetBazarListRequest } from "../../../models/superShopp/Address/sSBazar/sSBazarModel";
import { SSGET_BAZAR_BY_ID, SSGET_BAZAR_LIST } from "../../constants/superShop/ssBazarConstants";


export const sSGetBazarListAction = (payload?: SSGetBazarListRequest) => async (dispatch: any) => {
	var data = sSgetBazarListService(payload);
	const pload = (await data).data;
	dispatch({ type: SSGET_BAZAR_LIST, payload: pload });
};

export const sSGetBazarByIdAction = (id: any) => async (dispatch: any) => {
	try {
		var data = sSGetBazarByIdService(id);
		const payload = (await data).data;
		dispatch({ type: SSGET_BAZAR_BY_ID, payload: payload });
	} catch (error: any) { }
};