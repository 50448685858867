import React from 'react';

interface IModalProps {
  children?: React.ReactNode;
}

export const AppModal: React.FC<IModalProps> = ({ children }) => {
  return (
    <div className="fixed top-0 right-0 left-0 bottom-0 flex justify-center items-center w-screen bg-black/80 min-h-screen z-50">
      <div className="w-fit m-2 text-primary bg-white rounded">
        {children}
      </div>
    </div>
  );
}
