import { SSUpdateProductModel, SSProductPublishModel, SSGetProductsRequest } from "../../models/superShopp/catalog/sSProduct/sSProductModel";
import { DELETE, GET, POST, PUT } from "../service";

export const sSAddProductService = (model: SSUpdateProductModel) => {
  return POST(`/api/SSProduct/SSCreateProduct`, model);
};

export const sSDeleteProductBrandService = (data: any) => {
  return DELETE(`/api/SSProduct/SSRemoveProduct`, {
    data: { "ids": data },
  });
};

export const sSGetProductListService = (payload?: SSGetProductsRequest) => {
  if (payload) {
    return GET(`/api/SSProduct/SSGetProductList?bazarId=${payload?.bazarId}&mainCategoryId=${payload?.mainCategoryId}&subCategoryId=${payload?.subCategoryId}&productBrandId=${payload?.productBrandId}&limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}&filterBy=${payload?.filterBy}`);
  }
  return GET(`/api/SSProduct/SSGetProductList`);
};

export const sSGetProductByIdService = (id?: any) => {
    return GET(`/api/SSProduct/SSGetProductById?Id=${id}`);
};

export const sSUpdateProductServices = (data: any, id: any) => {
  return PUT(`/api/SSProduct/SSUpdateProduct/${id}`, data);
};

export const sSProductPublishService = (data: SSProductPublishModel) => {
  return POST(`/api/SSProduct/SSProductPublish`, data);
};

export const sSDeleteProductService = (data: any) => {
  return DELETE(`/api/SSProduct/SSRemoveProduct`, {
    data: { "ids": data },
  });
};

export const sSProductPublishServices = (data: any, id: any) => {
  return PUT(`/api/SSProduct/SSProductPublish/${id}`, data);
};

export const sSProductDisableServices = (data: any, id: any) => {
  return PUT(`/api/SSProduct/SSProductDisable/${id}`, data);
};
