import { bechteChaiSubCategoryListService, getBechteChaiMainCatagoryListService } from "../../../httpService/bechteChai/categoryServices";
import { PaginationPayloadModel } from "../../../models/common/paginationModels";
import { GET_BECHTE_CHAI_MAIN_CATEGORY_LIST, GET_BECHTE_CHAI_SUB_CATEGORY_LIST } from "../../constants/bechteChai/category";

export const bechteChaiMainCategoryList = (payload?: PaginationPayloadModel, isAll?: boolean, forPublic?: boolean) => async (dispatch: any) => {
    var data = getBechteChaiMainCatagoryListService(payload, isAll, forPublic);
    const pload = (await data).data;
    dispatch({ type: GET_BECHTE_CHAI_MAIN_CATEGORY_LIST, payload: pload });
};

export const bechteChaiSubCategoryList = (mainCategoryId?: number, payload?: PaginationPayloadModel, isAll?: any) => async (dispatch: any) => {
    var data = bechteChaiSubCategoryListService(mainCategoryId, payload, isAll)
    const pload = (await data).data;
    dispatch({ type: GET_BECHTE_CHAI_SUB_CATEGORY_LIST, payload: pload });
}

