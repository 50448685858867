import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
	getUserProfileByIdAction,
	getResellerTeamAction,
	getAffiliaterTeamAction,
} from "../../../../store/actions/auth/user/userActions";
import { AppDefaultProfilePicture } from "../../../common/AppDefaultProfilePicture";
import { BiSearchAlt2 } from "react-icons/bi";
import { AppSearchBox } from "../../../common/AppSearchBox";
import { Link } from "react-router-dom";
import { GetAffiliaterTeamPayload, PaginationWithUserId } from "../../../../models/auth/user/UserModels";
import { isAffiliater, isReseller } from "../../../../common/common";
import { AppButton } from "../../../common/AppButton";

interface IProps {
	id?: any;
}

export const AdminGetUserTeam: React.FC<IProps> = ({ id }) => {

	const dispatch = useDispatch();

	const [isSelected, setIsSelected] = useState("");

	const resellTeamList = useSelector((state: RootState) => {
		return state.getResellerTeamResponse.data;
	});

	const affiliateTeamList = useSelector((state: RootState) => {
		return state.getAffiliaterTeamResponse.data;
	});

	const userProfile = useSelector((state: RootState) => {
		return state.getUserProfileResponse.data;
	});

	var paginationModel = new PaginationWithUserId();
	paginationModel.limit = 10000;
	paginationModel.offset = 0;
	paginationModel.searchQuery = "";
	paginationModel.id = id;

	var aPaginationModel = new GetAffiliaterTeamPayload();
	aPaginationModel.limit = 10000;
	aPaginationModel.offset = 0;
	aPaginationModel.searchQuery = "";
	aPaginationModel.userId = id;
	aPaginationModel.isAffiliaterTeam = true;

	useEffect(() => {
		if (isReseller(userProfile?.userRoles?.map((x: any) => x.name))) {
			setIsSelected("ResellerTeam")
		}
		else {
			setIsSelected("AffiliaterTeam")
		}
		dispatch(getResellerTeamAction(paginationModel));
		dispatch(getAffiliaterTeamAction(aPaginationModel));
		dispatch(getUserProfileByIdAction(id));
	}, [dispatch]);

	const handleOpenResellingTeam = () => {
		setIsSelected("ResellerTeam")
		dispatch(getResellerTeamAction(paginationModel));
	}

	const handleOpenAffiliaterTeam = () => {
		setIsSelected("AffiliaterTeam")
		aPaginationModel.isAffiliaterTeam = true;
		aPaginationModel.userId = id;
		dispatch(getAffiliaterTeamAction(aPaginationModel));
	}

	const handleOpenCustomerTeam = () => {
		setIsSelected("CustomerTeam")
		aPaginationModel.isAffiliaterTeam = false;
		aPaginationModel.userId = id;
		dispatch(getAffiliaterTeamAction(aPaginationModel));
	}

	const handleSearchChange = (newValue: any) => {
		if (isReseller(userProfile?.userRoles?.map((x: any) => x.name))) {
			paginationModel.searchQuery = newValue;
			dispatch(getResellerTeamAction(paginationModel));
		}
		else {
			aPaginationModel.searchQuery = newValue;
			dispatch(getResellerTeamAction(aPaginationModel));
		}
	};

	return (
		<div className="container px-4 lg:px-0 lg:mx-auto py-2 overflow-y-auto">

			<h1 className="title md:text-center">User team</h1>

			<div className="w-full flex flex-col-reverse md:flex-row justify-between gap-2 md:items-end py-2">

				<div className="flex items-center border shadow-sm border-grey 2xl:p-2 md:p-1.5 p-1 rounded cursor-pointer">
					<BiSearchAlt2 className="text-secondary cursor-pointer 2xl:text-3xl md:text-2xl text-xl font-bold" />
					<AppSearchBox onChange={handleSearchChange} className="border-none md:w-[25dvw] px-2 text-sm md:text-base" />
				</div>

				{isReseller(userProfile?.userRoles?.map((x: any) => x.name)) && (
					<div className="">
						<h1 className="subTitle">Reselling Upline</h1>
						<div className="w-full lg:w-80 flex flex-col items-center gap-1 mt-1 px-1 py-2 border-royalBlue bg-blue-100 rounded border-2 overflow-hidden">
							{/* this is info div  */}
							<div className="w-full flex items-center justify-between">
								{/* this is img div and name  */}
								<div className="flex items-center gap-1">
									<AppDefaultProfilePicture
										className="customer-review-image"
										name={userProfile?.myResellingUpline?.name}
										profilePicture={userProfile?.myResellingUpline?.profilePicUrl}
									/>
									<div className="text-xs space-y-1">
										<p><b>{userProfile?.myResellingUpline?.name}</b></p>
										<p>Refer Code: {userProfile?.myResellingUpline?.referCode}</p>
									</div>
								</div>
								{/* this is refer div  */}
								<div className="text-xs space-y-1">
									<p>Total Refer: {userProfile?.myResellingUpline?.totalRefer}</p>
									<p>Training Purchase: {userProfile?.myResellingUpline?.isTrainingPurchased === true ? <span className="text-green-500">Yes</span> : <span className="text-red">No</span>}</p>
								</div>
							</div>
							{/* this is link div  */}
							<div className="w-full flex items-center justify-between">
								{/* phone number div  */}
								<div className="flex items-center text-xxs">
									<img className="w-2 h-2 object-fill" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Vector_kyj8fc.png" alt="" />
									<p>: {userProfile?.myResellingUpline?.phoneNumber}</p>
								</div>
								{/* email div  */}
								<div className="flex items-center text-xxs">
									<img className="w-2 h-2 object-fill" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/fluent-emoji-flat_e-mail_cb5kc0.png" alt="" />
									<p>: {userProfile?.myResellingUpline?.email}</p>
								</div>
								{/* facebook div  */}
								<div className="flex items-center text-xxs">
									<img className="w-2 h-2 object-fill" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/logos_facebook_xo9kot.png" alt="" />
									<p>: <Link className="text-royalBlue" to={userProfile?.myResellingUpline?.facebookLink}>link</Link></p>
								</div>
								{/* whatsapp number div  */}
								<div className="flex items-center text-xxs">
									<img className="w-2 h-2 object-fill" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/logos_whatsapp-icon_hozd3f.png" alt="" />
									<p>: {userProfile?.myResellingUpline?.whatsAppNumber}</p>
								</div>

							</div>
						</div>
					</div>
				)}


				{isAffiliater(userProfile?.userRoles?.map((x: any) => x.name)) && (
					<div className="">
						<h1 className="subTitle">Affiliate Upline</h1>
						<div className="w-full lg:w-80 flex flex-col items-center gap-1 mt-1 px-1 py-2 border-royalBlue bg-blue-100 rounded border-2 overflow-hidden">
							{/* this is info div  */}
							<div className="w-full flex items-center justify-between">
								{/* this is img div and name  */}
								<div className="flex items-center gap-1">
									<AppDefaultProfilePicture
										className="customer-review-image"
										name={userProfile?.myAffiliateUpline?.name}
										profilePicture={userProfile?.myAffiliateUpline?.profilePicUrl}
									/>
									<div className="text-xs space-y-1">
										<p><b>{userProfile?.myAffiliateUpline?.name}</b></p>
										<p>Refer Code: {userProfile?.myAffiliateUpline?.referCode}</p>
									</div>
								</div>
								{/* this is refer div  */}
								<div className="text-xs space-y-1">
									<p>Total Refer: {userProfile?.myAffiliateUpline?.totalRefer}</p>
									<p>Training Purchase: {userProfile?.myAffiliateUpline?.isTrainingPurchased === true ? <span className="text-green-500">Yes</span> : <span className="text-red">No</span>}</p>
								</div>
							</div>
							{/* this is link div  */}
							<div className="w-full flex items-center justify-between">
								{/* phone number div  */}
								<div className="flex items-center text-xxs">
									<img className="w-2 h-2 object-fill" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Vector_kyj8fc.png" alt="" />
									<p>: {userProfile?.myAffiliateUpline?.phoneNumber}</p>
								</div>
								{/* email div  */}
								<div className="flex items-center text-xxs">
									<img className="w-2 h-2 object-fill" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/fluent-emoji-flat_e-mail_cb5kc0.png" alt="" />
									<p>: {userProfile?.myAffiliateUpline?.email}</p>
								</div>
								{/* facebook div  */}
								<div className="flex items-center text-xxs">
									<img className="w-2 h-2 object-fill" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/logos_facebook_xo9kot.png" alt="" />
									<p>: <Link className="text-royalBlue" to={userProfile?.myAffiliateUpline?.facebookLink}>link</Link></p>
								</div>
								{/* whatsapp number div  */}
								<div className="flex items-center text-xxs">
									<img className="w-2 h-2 object-fill" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/logos_whatsapp-icon_hozd3f.png" alt="" />
									<p>: {userProfile?.myAffiliateUpline?.whatsAppNumber}</p>
								</div>

							</div>
						</div>
					</div>
				)}

			</div>

			<div className="lg:myBorder flex items-center justify-between lg:justify-evenly gap-1 lg:gap-4 lg:rounded px-0 lg:px-2 py-0 lg:py-2.5 md:gap-6 my-2 lg:mb-4">
				{isReseller(userProfile?.userRoles?.map((x: any) => x.name)) && (
					<AppButton className={`${isSelected === "ResellerTeam" ? "myBtn text-xxs lg:text-base px-1.5 lg:px-4 py-0 lg:py-1" : "outlineBtn text-xxs lg:text-base px-1.5 lg:px-4 py-0 lg:py-1"}`} text="Reseller Team" onClick={handleOpenResellingTeam} />
				)}
				{isAffiliater(userProfile?.userRoles?.map((x: any) => x.name)) && (
					<>
						<AppButton className={`${isSelected === "AffiliaterTeam" ? "myBtn text-xxs lg:text-base px-1.5 lg:px-4 py-0 lg:py-1" : "outlineBtn text-xxs lg:text-base px-1.5 lg:px-4 py-0 lg:py-1"}`} text="Affiliater Team" onClick={handleOpenAffiliaterTeam} />
						<AppButton className={`${isSelected === "CustomerTeam" ? "myBtn text-xxs lg:text-base px-1.5 lg:px-4 py-0 lg:py-1" : "outlineBtn text-xxs lg:text-base px-1.5 lg:px-4 py-0 lg:py-1"}`} text="Customer Team" onClick={handleOpenCustomerTeam} />
					</>
				)}
			</div>

			<h1 className="subTitle">Downlines ({isReseller(userProfile?.userRoles?.map((x: any) => x.name)) ? resellTeamList?.size : affiliateTeamList?.size})</h1>

			{isSelected === "ResellerTeam" && (

				<div className="w-full h-[55dvh] lg:h-[47dvh] overflow-y-auto">
					<div className='flex flex-col lg:grid grid-cols-1 md:grid-cols-4 content-center gap-2'>
						{resellTeamList?.items?.map((item: any, index: any) => {
							return (
								<div key={index} className="w-full h-fit flex flex-col items-center gap-1 px-1 py-2 border-primary bg-emerald-100 rounded border-2 overflow-hidden">
									{/* this is info div  */}
									<div className="w-full flex items-center justify-between">
										{/* this is img div and name  */}
										<div className="flex items-center gap-1">
											<AppDefaultProfilePicture
												className="customer-review-image"
												name={item?.name}
												profilePicture={item?.profilePicUrl}
											/>
											<div className="text-xs space-y-1">
												<p><b>{item?.name}</b></p>
												<p>Refer Code: {item?.referCode}</p>
											</div>
										</div>
										{/* this is refer div  */}
										<div className="text-xs space-y-1">
											<p>Total Refer:  {item?.totalRefer}</p>
											<p>Training Purchase: {item?.isTrainingPurchased === true ? <span className="text-green-500">Yes</span> : <span className="text-red">No</span>}</p>
										</div>
									</div>
									{/* this is link div  */}
									<div className="w-full flex items-center justify-between">
										{/* phone number div  */}
										<div className="flex items-center text-xxs">
											<img className="w-2 h-2 object-fill" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Vector_kyj8fc.png" alt="" />
											<p>: {item?.phoneNumber}</p>
										</div>
										{/* email div  */}
										<div className="flex items-center text-xxs">
											<img className="w-2 h-2 object-fill" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/fluent-emoji-flat_e-mail_cb5kc0.png" alt="" />
											<p>: {item?.email}</p>
										</div>
										{/* facebook div  */}
										<div className="flex items-center text-xxs">
											<img className="w-2 h-2 object-fill" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/logos_facebook_xo9kot.png" alt="" />
											<p>: <Link className="text-royalBlue" to={item?.facebookLink}>link</Link></p>
										</div>
										{/* whatsapp number div  */}
										<div className="flex items-center text-xxs">
											<img className="w-2 h-2 object-fill" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/logos_whatsapp-icon_hozd3f.png" alt="" />
											<p>: {item?.whatsAppNumber}</p>
										</div>

									</div>
								</div>
							);
						})}
					</div>
				</div>
			)}

			{(isSelected === "AffiliaterTeam" || isSelected === "CustomerTeam") && (

				<div className="w-full h-[55dvh] lg:h-[47dvh] overflow-y-auto">
					<div className='flex flex-col lg:grid grid-cols-1 md:grid-cols-4 content-center gap-2'>
						{affiliateTeamList?.items?.map((item: any, index: any) => {
							return (
								<div key={index} className="w-full h-fit flex flex-col items-center gap-1 px-1 py-2 border-primary bg-emerald-100 rounded border-2 overflow-hidden">
									{/* this is info div  */}
									<div className="w-full flex items-center justify-between">
										{/* this is img div and name  */}
										<div className="flex items-center gap-1">
											<AppDefaultProfilePicture
												className="customer-review-image"
												name={item?.name}
												profilePicture={item?.profilePicUrl}
											/>
											<div className="text-xs space-y-1">
												<p><b>{item?.name}</b></p>
												<p>Refer Code: {item?.referCode}</p>
											</div>
										</div>
										{/* this is refer div  */}
										<div className="text-xs space-y-1">
											<p>Total Refer:  {item?.totalRefer}</p>
											<p>Training Purchase: {item?.isTrainingPurchased === true ? <span className="text-green-500">Yes</span> : <span className="text-red">No</span>}</p>
										</div>
									</div>
									{/* this is link div  */}
									<div className="w-full flex items-center justify-between">
										{/* phone number div  */}
										<div className="flex items-center text-xxs">
											<img className="w-2 h-2 object-fill" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Vector_kyj8fc.png" alt="" />
											<p>: {item?.phoneNumber}</p>
										</div>
										{/* email div  */}
										<div className="flex items-center text-xxs">
											<img className="w-2 h-2 object-fill" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/fluent-emoji-flat_e-mail_cb5kc0.png" alt="" />
											<p>: {item?.email}</p>
										</div>
										{/* facebook div  */}
										<div className="flex items-center text-xxs">
											<img className="w-2 h-2 object-fill" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/logos_facebook_xo9kot.png" alt="" />
											<p>: <Link className="text-royalBlue" to={item?.facebookLink}>link</Link></p>
										</div>
										{/* whatsapp number div  */}
										<div className="flex items-center text-xxs">
											<img className="w-2 h-2 object-fill" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/logos_whatsapp-icon_hozd3f.png" alt="" />
											<p>: {item?.whatsAppNumber}</p>
										</div>

									</div>
								</div>
							);
						})}
					</div>
				</div>
			)}

		</div>
	);
}
