import { PaginationPayloadModel } from "../common/paginationModels";

export class ShoppingCartFavouriteModel {
    id?: any;
    productId?: string;
    productQuantity?: number;
    unitPrice?: number;
    totalPrice?: number;
    customerIP?: string;
    shoppingCartType?: ShoppingCartType;

    productName?: string;
    productShortDescription?: string;
    productCode?: string;
    bannerPath?: string;
    availableStock?: number;

    isSelected?: boolean;

    attributes?: any;

    shortDescription?: any;
    totalSold?: any;
    price?: any;
    rating?: any;
    discount?: any;
    isBestSeller?: any;
    isDisountProduct?: any;
    discountText?: any;

    vendorId?: any;
    vendorName?: string;

    cartCategory?: ShoppingCartCategory;
    unitIncome?: any;
    totalIncome?: any;
    shoppingCharge?: GetShippingChargeResponse;
    attributeValueIds?: string;
}

export enum ShoppingCartCategory {
    Regular = 1,
    Reselling = 2
}

// type Attribute = {
//     name: string;
//     value: any;
// };

export class ShoppingCartFavouriteRequest {
    customerIp?: string;
    shoppingCartType?: ShoppingCartType;
    cartItems?: any;
}

export class IncreseDecreseCartItemRequest {
    id?: number;
    cartType?: ShoppingCartType.ShoppingCart
    isIncrese?: boolean;
}

export class RemoveSingleOrListCartitemRequest {
    ids?: any[];
    cartType?: ShoppingCartType.ShoppingCart
}

export enum ShoppingCartType {
    ShoppingCart = 1,
    Favourite = 2
}

export class GetCartListAdminModel extends PaginationPayloadModel {
    startDateTime?: Date;
    endDateTime?: Date;
    shoppingCartType?: ShoppingCartType
}


export class CartItemSelectionRequest {
    id?: number;
    productId?: string;
    isSelected?: boolean
}


export class GetShippingChargeResponse
{
    Id?:any;
    VendorId?:any;
    InSideDhaka?:any;
    OutSideDhaka?:any;
}