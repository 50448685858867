import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { AlertColor, debounce } from "@mui/material";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { AppButton } from "../../../common/AppButton";
import {
  AcademicInstituteAuthorityTypeObject,
  AcademicInstituteOccupationTypeObject,
  AcademicInstituteTypeObject,
  GetAcademicInstituteEmployeesNumber,
} from "../../../../models/importantNumber/ImportantNumberModel";
import { RootState } from "../../../../store";
import { getAcademicInstituteEmployeesListAction } from "../../../../store/actions/importantNumbers/academicInstitutesActions";
import { AppPagination } from "../../../common/AppPagination";
import { AppDeleteModal } from "../../../common/AppDeleteModal";
import { BiSearchAlt2 } from "react-icons/bi";
import { AppSearchBox } from "../../../common/AppSearchBox";
import { AppLoader } from "../../../common/AppLoader";
import {
  deleteAcademicInstituteEmployeeService,
  getAcademicInstitutesByIdService,
} from "../../../../httpService/importantNumbers/academicInstitutesServices";
import { isAdmin } from "../../../../common/common";
import { reactLocalStorage } from "reactjs-localstorage";

export default function INGetAcademicInstituteById() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [unionOrWardName, setUnionOrWardName] = useState("");
  const [academicInstituteType, setAcademicInstituteType] = useState(0);
  const [academicInstituteAuthorityType, setAcademicInstituteAuthorityType] =
    useState(0);
  const [eiin, setEIIN] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumberFirst, setPhoneNumberFirst] = useState("");
  const [phoneNumberSecond, setPhoneNumberSecond] = useState("");
  const [note, setNote] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  var pModel = new GetAcademicInstituteEmployeesNumber();

  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [bulkIds, setBulkIds] = useState([]);

  const academicInstitute = async (id: any) => {
    const data = await getAcademicInstitutesByIdService(id);
    const response = data?.data;
    setName(response.name);
    setUnionOrWardName(response.name);
    setEIIN(response.eiin);
    setEmail(response.email);
    setAcademicInstituteType(response.academicInstituteType);
    setAcademicInstituteAuthorityType(response.academicInstituteAuthorityType);
    setPhoneNumberFirst(response.phoneNumberFirst);
    setPhoneNumberSecond(response.phoneNumberSecond);
    setAddress(response.address);
    setNote(response.note);
  };

  useEffect(() => {
    academicInstitute(id);
  }, [dispatch, id]);

  const openDeleteModal = (id: any) => {
    setOpenDeleteModal(true);

    let ids: any = [];
    ids.push(id);

    setBulkIds(ids);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const academicInstituteEmployees = useSelector((state: RootState) => {
    return state.getAcademicInstituteEmployeesResponse.data;
  });

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        var pModel = new GetAcademicInstituteEmployeesNumber();
        pModel.limit = 10;
        pModel.offset = 0;
        pModel.searchQuery = searchQuery;
        pModel.instituteId = Number(id);
        await dispatch(getAcademicInstituteEmployeesListAction(pModel));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  const handleOnRowChange = (payload: GetAcademicInstituteEmployeesNumber) => {
    const fetchData = async () => {
      try {
        var pModel = new GetAcademicInstituteEmployeesNumber();
        pModel.limit = payload.limit;
        pModel.offset = 0;
        pModel.instituteId = Number(id);
        pModel.searchQuery = searchQuery;

        dispatch(getAcademicInstituteEmployeesListAction(pModel));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();
  };

  const handleOnPageChange = (payload: GetAcademicInstituteEmployeesNumber) => {
    const fetchData = async () => {
      try {
        var pModel = new GetAcademicInstituteEmployeesNumber();
        pModel.limit = payload.limit;
        pModel.offset = payload.offset;
        pModel.instituteId = Number(id);
        pModel.searchQuery = searchQuery;

        dispatch(getAcademicInstituteEmployeesListAction(pModel));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  };

  const handleSearchQuery = debounce((searchQuery: string) => {
    const fetchData = async () => {
      try {
        setSearchQuery(searchQuery);
        var pModel = new GetAcademicInstituteEmployeesNumber();
        pModel.limit = 10;
        pModel.offset = 0;
        pModel.instituteId = Number(id);
        pModel.searchQuery = searchQuery;

        dispatch(getAcademicInstituteEmployeesListAction(pModel));
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, 500);

  const handleDelete = () => {
    setSnackBarOn(false);
    deleteAcademicInstituteEmployeeService(bulkIds)
      .then(() => {
        setSnackBarOn(true);
        setsnackBarMessage("Successfully Deleted");
        setSnackBarMessageType("success");
        closeDeleteModal();
        dispatch(getAcademicInstituteEmployeesListAction(pModel));
      })
      .catch((error: any) => {
        setSnackBarOn(true);
        setsnackBarMessage("Something went wrong");
        setSnackBarMessageType("error");
      });
  };

  const navigateToAcademicInstitutes = () => {
    if (isAdmin(reactLocalStorage.get("RoleName"))) {
      navigate("/admin/important-numbers/academic-institutes");
    } else {
      navigate("/important-numbers/academic-institutes");
    }
  };

  const navigateToAddAcademicEmployees = (id: any) => {
    if (isAdmin(reactLocalStorage.get("RoleName"))) {
      navigate(
        `/admin/important-numbers/academic-institutes/employee-create/${id}`
      );
    } else {
      navigate(`/important-numbers/academic-institutes/employee-create/${id}`);
    }
  };

  return (
    <>
      <div className="container mx-auto px-2 py-4">
        {snackBarOn && (
          <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
        )}
        <div className="flex flex-col items-start pb-4 lg:flex-row lg:items-center">
          <AppButton
            className="gradientBtn"
            onClick={navigateToAcademicInstitutes}
            text="Back"
          />
          <div className="w-[80%]">
            <h2 className="title text-center text-cyan-600">
              View Academic Institute
            </h2>
          </div>
          <AppButton
            className="gradientBtn"
            onClick={() => navigateToAddAcademicEmployees(id)}
            text="Add Employee"
          />
        </div>

        <div className="grid grid-cols-2">
          <div className="flex items-center gap-0.5">
            <span className="font-medium">Name:</span> <span>{name}</span>
          </div>
          <div className="flex items-center gap-0.5">
            <span className="font-medium">EIIN:</span> <span>{eiin}</span>
          </div>
        </div>
        <div className="grid grid-cols-2">
          <div className="flex items-center gap-0.5">
            <span className="font-medium">Type:</span>{" "}
            <span>
              {
                AcademicInstituteTypeObject.find(
                  (_) => _.id === academicInstituteType
                )?.name
              }
            </span>
          </div>

          <div className="flex items-center gap-0.5">
            <span className="font-medium">Authority Type:</span>{" "}
            <span>
              {
                AcademicInstituteAuthorityTypeObject.find(
                  (_) => _.id === academicInstituteAuthorityType
                )?.name
              }
            </span>
          </div>
        </div>
        <div className="grid grid-cols-2">
          <div className="flex items-center gap-0.5">
            <span className="font-medium">Phone 1:</span>{" "}
            <span>{phoneNumberFirst}</span>
          </div>
          <div className="flex items-center gap-0.5">
            <span className="font-medium">Phone 2:</span>{" "}
            <span> {phoneNumberSecond}</span>
          </div>
        </div>
        <div className="grid grid-cols-2">
          <div className="flex items-center gap-0.5">
            <span className="font-medium">Note:</span> <span>{note}</span>
          </div>
          <div className="flex items-center gap-0.5">
            <span className="font-medium">WardOrUnion:</span>{" "}
            <span>{unionOrWardName}</span>
          </div>
        </div>
        <div className="grid grid-cols-1">
          <div className="flex items-center gap-0.5">
            <span className="font-medium">Email:</span> <span>{email}</span>
          </div>
          <div className="flex items-center gap-0.5">
            <span className="font-medium">Address:</span> <span>{address}</span>
          </div>
        </div>
        <br />
        <div className="flex cursor-pointer items-center rounded border border-grey p-1 shadow-sm md:px-1.5 lg:w-full 2xl:p-2">
          <BiSearchAlt2 className="cursor-pointer text-xl font-bold text-cyan-600 md:text-2xl 2xl:text-3xl" />
          <AppSearchBox
            onChange={handleSearchQuery}
            placeholder="Search here"
            className="w-full border-none text-xs md:text-base lg:px-2"
          />
        </div>
        <br />
        {isLoading ? (
          <AppLoader status={true} />
        ) : (
          <>
            {academicInstituteEmployees?.items ? (
              <>
                <div className="w-full">
                  <div className="myBorder myGradient hidden w-full lg:flex">
                    <div className="tableHead w-[10%]">FirstName</div>
                    <div className="tableHead w-[10%]">LastName</div>
                    <div className="tableHead w-[10%]">Occupation</div>
                    <div className="tableHead w-[8%]">Designation</div>
                    <div className="tableHead w-[8%]">Email</div>
                    <div className="tableHead w-[8%]">Phone First</div>
                    <div className="tableHead w-[8%]">Phone Second</div>
                    <div className="tableHead w-[8%]">note</div>
                    <div className="tableHead w-[7%]">Address</div>
                    <div className="tableHead w-[8%]">Update</div>
                    <div className="tableHead w-[8%] border-none">Remove</div>
                  </div>
                  <div className="hidden h-[65dvh] flex-col gap-1 overflow-y-auto py-1.5 lg:flex 2xl:h-[68dvh]">
                    {academicInstituteEmployees?.items?.map(
                      (academicInstituteEmployee: any) => (
                        <div
                          key={academicInstituteEmployee?.id}
                          id={academicInstituteEmployee?.id}
                          className="myBorder flex w-full items-center"
                        >
                          <div className="tableRow textOverflowHidden w-[10%] justify-start">
                            {academicInstituteEmployee?.firstName}
                          </div>
                          <div className="tableRow textOverflowHidden w-[10%] justify-start">
                            {academicInstituteEmployee?.lastName}
                          </div>

                          <div className="tableRow w-[10%]">
                            {
                              AcademicInstituteOccupationTypeObject.find(
                                (_) =>
                                  _.id === academicInstituteEmployee?.occupation
                              )?.name
                            }
                          </div>

                          <div className="tableRow w-[8%]">
                            {academicInstituteEmployee?.designation}
                          </div>

                          <div className="tableRow w-[8%]">
                            {academicInstituteEmployee?.email}
                          </div>

                          <div className="tableRow w-[8%]">
                            {academicInstituteEmployee?.phoneNumberFirst}
                          </div>
                          <div className="tableRow w-[8%]">
                            {academicInstituteEmployee?.phoneNumberSecond}
                          </div>

                          <div className="tableRow w-[8%]">
                            {academicInstituteEmployee?.note}
                          </div>

                          <div className="tableRow w-[7%]">
                            {academicInstituteEmployee?.address}
                          </div>

                          <div className="tableRow w-[8%]">
                            {isAdmin(reactLocalStorage.get("RoleName")) ? (
                              <AppButton
                                onClick={() =>
                                  navigate(
                                    `/admin/important-numbers/academic-institutes/${id}/employee-update/${academicInstituteEmployee?.id}`
                                  )
                                }
                                className="gradientBtn  py-0.5 text-sm lg:py-1"
                                text="Update"
                              />
                            ) : (
                              <AppButton
                                onClick={() =>
                                  navigate(
                                    `/important-numbers/academic-institutes/${id}/employee-update/${academicInstituteEmployee?.id}`
                                  )
                                }
                                className="gradientBtn  py-0.5 text-sm lg:py-1"
                                text="Update"
                              />
                            )}
                          </div>
                          <div className="tableRow w-[8%] border-none">
                            <AppButton
                              className="myBtn border-red bg-red py-0.5 text-sm lg:py-1"
                              text="Remove"
                              onClick={() =>
                                openDeleteModal(academicInstituteEmployee?.id)
                              }
                            />
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
                {/* these is footer part  */}
                <div className="fixed bottom-1 flex w-full items-center bg-white py-1 lg:static">
                  <AppPagination
                    curPage={1}
                    limit={10}
                    offset={0}
                    onPageChange={handleOnPageChange}
                    onRowChange={handleOnRowChange}
                    size={academicInstituteEmployees?.size}
                  />
                </div>
              </>
            ) : (
              <div className="flex h-[50dvh] w-full items-center justify-center text-3xl font-medium text-textBlack/90">
                <span>Academic Institute Employees Number Not found</span>
              </div>
            )}
          </>
        )}
        {isOpenDeleteModal && (
          <AppDeleteModal
            text="Are you sure to Delete?"
            handleDelete={handleDelete}
            closeDeleteModal={closeDeleteModal}
          />
        )}
      </div>
    </>
  );
}
