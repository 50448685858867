import { sSAdminGetOrderDetailsService, sSAdminGetOrderListService, sSGetOrderDetailsService, sSGetOrderListService } from "../../../httpService/superShops/ssOrderServices";
import { SSGetOrderListRequest, SSAdminGetOrderListRequest, SSGetOrderDetailsRequest, SSGetOrderDetailsForAdminRequest } from "../../../models/superShopp/order/sSOrderModel";
import { SSGET_ADMIN_ORDER_BY_ID, SSGET_ADMIN_ORDER_LIST, SSGET_ORDER_BY_ID, SSGET_ORDER_LIST } from "../../constants/superShop/ssOrderConstants";

export const sSGetOrderListAction = (payload?: SSGetOrderListRequest) => async (dispatch: any) => {
	var data = sSGetOrderListService(payload);
	const pload = (await data).data;
	dispatch({ type: SSGET_ORDER_LIST, payload: pload });
};

export const sSAdminGetOrderListAction = (payload?: SSAdminGetOrderListRequest) => async (dispatch: any) => {
	var data = sSAdminGetOrderListService(payload);
	const pload = (await data).data;
	dispatch({ type: SSGET_ADMIN_ORDER_LIST, payload: pload });
};

export const sSGetOrderDetailsAction = (payload?: SSGetOrderDetailsRequest) => async (dispatch: any) => {
	var data = sSGetOrderDetailsService(payload);
	const pload = (await data).data;
	dispatch({ type: SSGET_ORDER_BY_ID, payload: pload });
};

export const sSAdminGetOrderDetailsAction = (payload?: SSGetOrderDetailsForAdminRequest) => async (dispatch: any) => {
	var data = sSAdminGetOrderDetailsService(payload);
	const pload = (await data).data;
	dispatch({ type: SSGET_ADMIN_ORDER_BY_ID, payload: pload });
};