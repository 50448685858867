import { AppInput } from "../../common/AppInput";
import { AppSelect } from "../../common/AppSelect";
import { AppRadioButton } from "../../common/AppRadioButton";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileAction } from "../../../store/actions/auth/user/userActions";
import { RootState } from "../../../store";
import { divisionList } from "../../../store/actions/auth/address/divisionActions";
import { districtListByDivisionId as districtListByDivisionId } from "../../../store/actions/auth/address/districtActions";
import { areaListByDistrictId } from "../../../store/actions/auth/address/areaActions";
import { updateUserProfileService } from "../../../httpService/user/userServices";
import { useNavigate } from "react-router-dom";
import { AppFileInput } from "../../common/AppFileInput";
import { reactLocalStorage } from "reactjs-localstorage";
import { AlertColor } from "@mui/material/Alert";
import { AppSnackBar } from "../../common/AppSnackBar";
import { AppDefaultProfilePicture } from "../../common/AppDefaultProfilePicture";
import { Link } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { AppButton } from "../../common/AppButton";
import { AppTextArea } from "../../common/AppTextArea";
import { UpdateUserProfileRequest } from "../../../models/auth/user/UserModels";
import {
  isAdmin,
  isAffiliater,
  isBazarRider,
  isCountryAdmin,
  isCustomer,
  isReseller,
  isUpazilaAdmin,
  isVendor,
  isZilaAdmin,
} from "../../../common/common";
import { IdNameResponseModel } from "../../../models/common/IdNameModel";
import { BsThreeDotsVertical } from "react-icons/bs";

const areaTypes = [
  { id: 1, name: "Upazila" },
  { id: 2, name: "CityCorporation" },
];

const Gender = [
  { id: 0, name: "Male" },
  { id: 1, name: "Female" },
  { id: 2, name: "Unspecified" },
];

const occupations = [
  { id: 1, name: "Teacher" },
  { id: 2, name: "Businessman" },
  { id: 3, name: "Farmer" },
  { id: 4, name: "GovtEmployee" },
  { id: 5, name: "ServiceHolder" },
  { id: 6, name: "Freelancer" },
  { id: 7, name: "Others" },
];

export default function ResellingGetProfile() {

  const currentUserId = reactLocalStorage.get("UserId") as string;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

  const divisions = useSelector((state: RootState) => {
    return state.divisionListResponse.data;
  });
  var divisionIdNameModels: IdNameResponseModel[] = [];

  divisions?.map((x: any) => {
    var division = new IdNameResponseModel();
    division.id = x.id;
    division.name = x.name;
    divisionIdNameModels.push(division);
  });

  const districts = useSelector((state: RootState) => {
    return state.districtListResponse.data;
  });
  var districtsIdNameModels: IdNameResponseModel[] = [];

  districts?.map((x: any) => {
    var district = new IdNameResponseModel();
    district.id = x.id;
    district.name = x.name;
    districtsIdNameModels.push(district);
  });

  const areass = useSelector((state: RootState) => {
    return state.areaListResponse.data;
  });
  var areaIdNameModels: IdNameResponseModel[] = [];

  areass?.map((x: any) => {
    var area = new IdNameResponseModel();
    area.id = x.id;
    area.name = x.name;
    areaIdNameModels.push(area);
  });

  const userProfile = useSelector((state: RootState) => {
    return state.getUserProfileResponse.data;
  });

  const [firstName, setFirstName] = useState("");
  const [bloodGroup, setBloodGroup] = useState(0);
  const [lastName, setLastName] = useState("");
  const [photoPath, setPhotoPath] = useState("");
  const [profilePictureId, setProfilePictureId] = useState("");
  const [gender, setGender] = useState(0);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [postCode, setPostCode] = useState("");
  const [divisionId, setDivisionId] = useState(0);
  const [districtId, setDistrictId] = useState(0);
  const [areaId, setAreaId] = useState(0);
  const [occupationId, setOccupationId] = useState(0);
  const [aboutMe, setAboutMe] = useState("");

  const [nIDNumber, setNIDNumber] = useState("");
  const [bkashNumber, setBkashNumber] = useState("");
  const [nagadNumber, setNagadNumber] = useState("");
  const [rocketNumber, setRocketNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");

  const [faceBookLink, setFaceBookLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [whatsAppNumber, setWhatsAppNumber] = useState("");
  const [twitterLink, setTwitterLink] = useState("");

  const [faceBookLinkError, setFaceBookLinkError] = useState("");
  const [instagramLinkError, setInstagramLinkError] = useState("");
  const [whatsAppNumberError, setWhatsAppNumberError] = useState("");
  const [twitterLinkError, setTwitterLinkError] = useState("");

  useEffect(() => {
    if (userProfile) {
      setFirstName(userProfile?.firstName);
      setLastName(userProfile?.lastName);
      setBloodGroup(userProfile?.bloodGroup);
      setEmail(userProfile?.email);
      setPhoneNumber(userProfile?.phoneNumber);
      setProfilePictureId(userProfile?.profilePictureId);
      setGender(userProfile?.gender);
      setAddress(userProfile?.userAddress);
      setPhotoPath(userProfile?.photoPath);
      setPostCode(userProfile?.postCode);
      setDivisionId(
        userProfile?.divisionId == null ? 0 : userProfile?.divisionId
      );
      setDistrictId(
        userProfile?.districtId == null ? 0 : userProfile?.districtId
      );
      setAreaId(userProfile?.areaId == null ? 0 : userProfile?.areaId);

      setNIDNumber(userProfile?.nidNumber);
      setBkashNumber(userProfile?.bkashNumber);
      setNagadNumber(userProfile?.nagadNumber);
      setRocketNumber(userProfile?.rocketNumber);
      setBankName(userProfile?.bankName);
      setBankAccountNumber(userProfile?.bankAccountNumber);

      setFaceBookLink(userProfile?.faceBookLink);
      setInstagramLink(userProfile?.instagramLink);
      setWhatsAppNumber(userProfile?.whatsAppNumber);
      setTwitterLink(userProfile?.twitterLink);

      setOccupationId(userProfile?.occupation);
      setAboutMe(userProfile?.aboutMe);
    }
  }, [userProfile]);

  const [firstNameError, setFirstNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [divisionError, setDivisionError] = useState("");
  const [districtError, setDistrictError] = useState("");
  const [areaError, setAreaError] = useState("");

  const [bkashNoError, setbkashNoError] = useState("");
  const [nagadNoError, setNagadNoError] = useState("");
  const [rocketError, setRocketNOError] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const handleRef = useRef<HTMLDivElement>(null);

  const handleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleClick = (e: any) => {
      if (!handleRef.current?.contains(e.target)) {
        setIsOpen(false);
        console.log(handleRef.current);
      }
    };
    document.addEventListener("mousedown", handleClick);
  }, []);

  useEffect(() => {
    dispatch(divisionList());
  }, [dispatch]);

  useEffect(() => {
    if (divisionId != 0 && divisionId != null) {
      dispatch(districtListByDivisionId(divisionId));
    }
  }, [divisionId, dispatch]);

  useEffect(() => {
    if (districtId != 0 && districtId != null) {
      dispatch(areaListByDistrictId(districtId));
    }
  }, [districtId, dispatch]);

  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);

  const handleFirstName = (value: string) => {
    setFirstNameError("");
    setFirstName(value);
  };

  const handleBloodGroup = (id: any) => {
    setBloodGroup(id);
  };

  const handleLastName = (value: string) => {
    setLastName(value);
  };

  const handlePhoneNumber = (value: string) => {
    setPhoneNumberError("");
    setPhoneNumber(value);
  };

  const handleEmail = (value: string) => {
    setEmailError("");
    setEmail(value);
  };

  const handleDivision = (id: any) => {
    setDivisionError("");
    setDivisionId(id);
    setDistrictId(0);
    setAreaId(0);
  };

  const handleDistrict = (id: any) => {
    setDistrictError("");
    setDistrictId(id);
    setAreaId(0);
  };

  const handleArea = (id: any) => {
    setAreaError("");
    setAreaId(id);
  };

  const handleOccupation = (id: any) => {
    setOccupationId(id);
  };

  const handleAboutMe = (value: string) => {
    setAboutMe(value);
  };

  const handleAddress = (value: string) => {
    setAddressError("");
    setAddress(value);
  };

  const handlePostCode = (value: string) => {
    setPostCode(value);
  };

  const handleNidNumber = (value: string) => {
    setNIDNumber(value);
  };

  const handleBakashNumber = (value: string) => {
    setBkashNumber(value);
    setbkashNoError("");
  };

  const handleNgadNumber = (value: string) => {
    setNagadNumber(value);
    setNagadNoError("");
  };

  const handleRocketnumber = (value: string) => {
    setRocketNumber(value);
    setRocketNOError("");
  };

  const handleBankName = (value: string) => {
    setBankName(value);
  };

  const handleBankAccountNumber = (value: string) => {
    setBankAccountNumber(value);
  };

  const handleFacebookLink = (value: string) => {
    setFaceBookLink(value);
    setFaceBookLinkError("");
  };

  const handleInstagramLink = (value: string) => {
    setInstagramLink(value);
    setInstagramLinkError("");
  };

  const handleTwitterLink = (value: string) => {
    setTwitterLink(value);
    setTwitterLinkError("");
  };

  const handleWhatsAppNumber = (value: string) => {
    setWhatsAppNumber(value);
    setWhatsAppNumberError("");
  };

  const navigateToChangePassword = () => {
    navigate(`/changePassword`);
  };

  const navigateToChangePhone = () => {
    navigate(`/updateEmailOrPhone?phone=${phoneNumber}`);
  };

  const navigateToChangeEmail = () => {
    navigate(`/updateEmailOrPhone?email=${email}`);
  };

  function isValidPhoneNumber(phoneNumber: any) {
    const phoneRegex = /^01[0-9]{9}$/;
    return phoneRegex.test(phoneNumber);
  }

  function isValidEmail(email: any) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const [isSaving, setIsSaving] = useState(false);

  const handleSaveProfile = () => {
    let canExecute = true;

    if (!firstName) {
      setFirstNameError("First Name Is Required");
      canExecute = false;
    }

    if (
      isCustomer(reactLocalStorage.get("RoleName")) ||
      isReseller(reactLocalStorage.get("RoleName"))
    ) {
      if (divisionId == 0) {
        setDivisionError("Division is required");
        canExecute = false;
      }
      if (!divisionId) {
        setDivisionError("Division is required");
        canExecute = false;
      }
      if (!districtId && districtId != 0) {
        setDistrictError("District is required");
        canExecute = false;
      }
      if (districtId == 0) {
        setDistrictError("District is required");
        canExecute = false;
      }
      if (!areaId) {
        setAreaError("Thana is required");
        canExecute = false;
      }
      if (areaId == 0) {
        setAreaError("Thana is required");
        canExecute = false;
      }
    }

    if (
      bkashNumber &&
      !isValidPhoneNumber(bkashNumber) &&
      bkashNumber != null
    ) {
      setbkashNoError("Bkash Number is Invalid");
      canExecute = false;
    }
    if (
      nagadNumber &&
      !isValidPhoneNumber(nagadNumber) &&
      nagadNumber != null
    ) {
      setNagadNoError("Nagad Number is Invalid");
      canExecute = false;
    }
    if (
      rocketNumber &&
      !isValidPhoneNumber(rocketNumber) &&
      rocketNumber != null
    ) {
      setRocketNOError("Rocket Number is Invalid");
      canExecute = false;
    }
    if (
      whatsAppNumber &&
      !isValidPhoneNumber(whatsAppNumber) &&
      whatsAppNumber != null
    ) {
      setWhatsAppNumberError("Whats app Number is Invalid");
      canExecute = false;
    }

    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

    let isValidFacebookLink = urlPattern.test(faceBookLink);
    if (faceBookLink && faceBookLink !== "" && !isValidFacebookLink) {
      setFaceBookLinkError("Please Enter A Valid Facebook link!");
      canExecute = false;
    }

    let isValidInstagramLink = urlPattern.test(instagramLink);
    if (instagramLink && instagramLink !== "" && !isValidInstagramLink) {
      setInstagramLinkError("Please Enter A Valid Instaram link!");
      canExecute = false;
    }

    let isValidTwitterLink = urlPattern.test(twitterLink);
    if (twitterLink && twitterLink !== "" && !isValidTwitterLink) {
      setTwitterLinkError("Please Enter A Valid Twitter link!");
      canExecute = false;
    }

    if (areaId == 0) {
      setIsShowSnackBar(true);
      setSnackBarMessage("Please save your Division District and Thana.");
      setSnackBarMessageType("warning");
      setTimeout(() => {
        setIsShowSnackBar(false);
      }, 3000);
      canExecute = false;
    }

    if (canExecute) {
      const model = new UpdateUserProfileRequest();
      model.firstName = firstName;
      model.lastName = lastName;
      model.email = email;
      model.gender = gender;
      model.profilePictureId = profilePictureId;
      model.phoneNumber = phoneNumber;
      model.userAddress = address;
      model.postCode = postCode;
      model.areaId = areaId;

      model.nIDNumber = nIDNumber;
      model.bkashNumber = bkashNumber;
      model.nagadNumber = nagadNumber;
      model.rocketNumber = rocketNumber;
      model.bankName = bankName;
      model.bankAccountNumber = bankAccountNumber;

      model.faceBookLink = faceBookLink;
      model.twitterLink = twitterLink;
      model.instagramLink = instagramLink;
      model.whatsAppNumber = whatsAppNumber;

      model.bloodGroup = bloodGroup;
      model.occupation = occupationId;
      model.aboutMe = aboutMe;

      setIsSaving(true);

      updateUserProfileService(model)
        .then(() => {
          setIsSaving(false);
          dispatch(getUserProfileAction());
          setIsShowSnackBar(true);
          setSnackBarMessage("Successfully Updated Profile");
          setSnackBarMessageType("success");
          setTimeout(() => {
            setIsShowSnackBar(false);
          }, 3000);
        })
        .catch(() => {
          setIsSaving(false);
          setIsShowSnackBar(true);
          setSnackBarMessage("Something went wrong please try again");
          setSnackBarMessageType("error");
          setTimeout(() => {
            setIsShowSnackBar(false);
          }, 3000);
        });
    }
  };

  const handleProfilePictureChange = (id: any, path: any) => {
    setPhotoPath(path);
    setProfilePictureId(id);
  };

  const handleSelectGender = (item: any) => {
    setGender(item.id);
  };

  const [isCopied, setIsCopied] = useState(false);
  const [isAffiliateCopied, setAffiliateIsCopied] = useState(false);

  const url = window.location.origin;

  function copyResellingLinkToClipboard() {
    const refUrl = `${url}/resellRef/${currentUserId}`;
    navigator.clipboard.writeText(refUrl);
    setIsCopied(true);
    setIsShowSnackBar(true);
    setSnackBarMessage("Successfully copied your profile link.");
    setSnackBarMessageType("success");
    setTimeout(() => {
      setIsShowSnackBar(false);
    }, 3000);
  }
  function copyAffiliateLinkToClipboard() {
    const refUrl = `${url}/affiliateRef/${currentUserId}`;
    navigator.clipboard.writeText(refUrl);
    setAffiliateIsCopied(true);
    setIsShowSnackBar(true);
    setSnackBarMessage("Successfully copied your affiliate link.");
    setSnackBarMessageType("success");
    setTimeout(() => {
      setIsShowSnackBar(false);
    }, 3000);
  }

  var token = reactLocalStorage.getObject("SignIn");

  return (
    <>
      {isShowSnackBar && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}

      <div className="container mx-auto px-4 pb-20 pt-6 md:px-20 md:pb-12 md:pt-8">
        <Link to="/">
          <h3 className="flex items-center gap-x-1 text-lg font-semibold text-primary lg:ps-6">
            <BiArrowBack /> Back to home
          </h3>{" "}
        </Link>
        {/* basic information div starts here  */}
        <div className="px-0 py-4 md:px-8 md:py-8">
          {/* <h2 className="text-lg font-semibold uppercase md:text-xl">
            Basic information
          </h2> */}

          {/* {isCustomer(reactLocalStorage.get("RoleName")) && (
            <h2 className="text-lg font-semibold uppercase md:text-xl">
              Customer
            </h2>
          )}
          {isReseller(reactLocalStorage.get("RoleName")) && (
            <h2 className="text-lg font-semibold uppercase md:text-xl">
              Reseller
            </h2>
          )}
          {isAffiliater(reactLocalStorage.get("RoleName")) && (
            <h2 className="text-lg font-semibold uppercase md:text-xl">
              Affiliater
            </h2>
          )}
          {isAdmin(reactLocalStorage.get("RoleName")) && (
            <h2 className="text-lg font-semibold uppercase md:text-xl">
              Admin
            </h2>
          )}
          {isUpazilaAdmin(reactLocalStorage.get("RoleName")) && (
            <h2 className="text-lg font-semibold uppercase md:text-xl">
              Upazila Admin
            </h2>
          )}
          {isZilaAdmin(reactLocalStorage.get("RoleName")) && (
            <h2 className="text-lg font-semibold uppercase md:text-xl">
              Zila Admin
            </h2>
          )}
          {isVendor(reactLocalStorage.get("RoleName")) && (
            <h2 className="text-lg font-semibold uppercase md:text-xl">
              Vendor
            </h2>
          )} */}

          <div className=" flex flex-col justify-between gap-y-4 pb-6 pt-4">
            <div className="  flex gap-x-4 md:gap-x-8">
              <div className=" flex gap-4 lg:w-4/5">
                <div className="relative rounded-full bg-white">
                  <AppDefaultProfilePicture
                    className="profile-image-header"
                    name={userProfile?.firstName}
                    profilePicture={photoPath}
                  />
                  <div className="absolute -right-[5%] top-[65%] cursor-pointer">
                    <AppFileInput onSave={handleProfilePictureChange} />
                  </div>
                </div>

                <div className="space-y-2 self-center">
                  <h4 className="text-xl font-medium capitalize text-textBlack md:text-2xl">
                    {userProfile?.firstName} {userProfile?.lastName}{" "}
                  </h4>
                  <div className=" flex flex-wrap items-center  gap-2 lg:flex-nowrap">
                    {/* <h2 className="text-lg font-semibold uppercase md:text-xl">
              Basic information
            </h2> */}

                    {isCustomer(reactLocalStorage.get("RoleName")) && (
                      <h2 className="inline-block bg-purple-400 px-2 py-1 text-sm font-normal uppercase text-slate-600">
                        Customer
                      </h2>
                    )}
                    {isReseller(reactLocalStorage.get("RoleName")) && (
                      <h2 className="bg-green-400 px-2 py-1 text-sm font-normal uppercase text-slate-600">
                        Reseller
                      </h2>
                    )}
                    {isAffiliater(reactLocalStorage.get("RoleName")) && (
                      <h2 className="bg-orange-400 px-2 py-1 text-sm font-normal uppercase text-slate-600">
                        Affiliater
                      </h2>
                    )}
                    {isAdmin(reactLocalStorage.get("RoleName")) && (
                      <h2 className="bg-orange-400 px-2 py-1 text-sm font-normal uppercase text-slate-600">
                        Admin
                      </h2>
                    )}
                    {isUpazilaAdmin(reactLocalStorage.get("RoleName")) && (
                      <h2 className="text-slate6500 bg-green-400 px-2 py-1 text-sm font-normal uppercase">
                        Upazila Admin
                      </h2>
                    )}
                    {isZilaAdmin(reactLocalStorage.get("RoleName")) && (
                      <h2 className="text-slate6500 bg-orange-400 px-2 py-1 text-sm font-normal uppercase">
                        Zila Admin
                      </h2>
                    )}
                    {isVendor(reactLocalStorage.get("RoleName")) && (
                      <h2 className="text-slate6500 bg-amber-400 px-2 py-1 text-sm font-normal uppercase">
                        Vendor
                      </h2>
                    )}
                  </div>
                </div>
              </div>
              <div
                ref={handleRef}
                className="relative flex justify-end md:justify-end lg:w-1/5 lg:justify-between 2xl:justify-between"
              >
                <div className=" hidden lg:block">
                  <div className="flex flex-col gap-3">
                    {isReseller(reactLocalStorage.get("RoleName")) && (
                      <div
                        className="myBtn border-royalBlue bg-royalBlue text-sm 2xl:text-lg"
                        onClick={copyResellingLinkToClipboard}
                      >
                        <span>
                          {" "}
                          {isCopied ? "Copied!" : "Copy Reselling Link"}
                        </span>
                      </div>
                    )}
                    {isAffiliater(reactLocalStorage.get("RoleName")) && (
                      <div
                        className="myBtn border-affiliate bg-affiliate text-sm 2xl:text-lg"
                        onClick={copyAffiliateLinkToClipboard}
                      >
                        <span>
                          {" "}
                          {isAffiliateCopied
                            ? "Copied!"
                            : "Copy Affiliate Link"}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className=" cursor-pointer text-primary"
                  onClick={handleOpen}
                >
                  <BsThreeDotsVertical size={25} />
                </div>
                {isOpen && (
                  <div className="absolute -top-16 right-7 ">
                    <div className="flex w-56 flex-col gap-2 rounded bg-slate-100 p-2 2xl:gap-4">
                      {!isBazarRider(reactLocalStorage.get("RoleName")) &&
                        <>
                          <div className="outlineBtn px-2 py-2 text-sm 2xl:text-lg" onClick={navigateToChangePhone}>
                            <span>Add or update Phone</span>
                          </div>
                          <div className="outlineBtn text-sm 2xl:text-lg" onClick={navigateToChangeEmail}>
                            <span>Add or update Email</span>
                          </div>
                        </>
                      }
                      <div className="outlineBtn text-sm 2xl:text-lg" onClick={navigateToChangePassword}>
                        <span>Change Password</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className=" lg:hidden">
              <div className="flex gap-3">
                {isReseller(reactLocalStorage.get("RoleName")) && (
                  <div
                    className="myBtn border-royalBlue bg-royalBlue text-sm 2xl:text-lg"
                    onClick={copyResellingLinkToClipboard}
                  >
                    <span> {isCopied ? "Copied!" : "Copy Reselling Link"}</span>
                  </div>
                )}
                {isAffiliater(reactLocalStorage.get("RoleName")) && (
                  <div
                    className="myBtn border-affiliate bg-affiliate text-sm 2xl:text-lg"
                    onClick={copyAffiliateLinkToClipboard}
                  >
                    <span>
                      {" "}
                      {isAffiliateCopied ? "Copied!" : "Copy Affiliate Link"}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-x-8 gap-y-4 lg:grid-cols-2">
            <div className="myBorder flex flex-col space-y-2 p-4 shadow md:p-8">
              <div className="grid w-full grid-cols-1 items-center gap-2 md:gap-4 lg:grid-cols-1">
                <AppInput
                  className="myInput w-full"
                  type="text"
                  label="Name"
                  placeholder="Enter your Name"
                  value={firstName}
                  onChange={handleFirstName}
                  errorMessage={firstNameError}
                />
              </div>
              <AppInput
                className="myInput"
                label="Email"
                type="text"
                isDisabled={true}
                placeholder="Email address"
                value={email}
                onChange={handleEmail}
                errorMessage={emailError}
              />
              <AppInput
                className="myInput"
                label="Phone"
                type="text"
                isDisabled={true}
                placeholder=""
                value={phoneNumber}
                onChange={handlePhoneNumber}
                errorMessage={phoneNumberError}
              />

              <AppRadioButton
                items={Gender}
                title="Select Gender"
                defaultValue={gender}
                onChange={handleSelectGender}
              />

              <div>
                <AppTextArea
                  className="myInput"
                  label="About Me"
                  placeholder="Enter something about me"
                  value={aboutMe}
                  onChange={handleAboutMe}
                />
              </div>
            </div>

            <div className="myBorder flex flex-col space-y-2 p-4 shadow md:p-8">
              <AppInput
                className="myInput"
                label="Address"
                type="text"
                placeholder="Enter your address"
                value={address}
                onChange={handleAddress}
              />

              <div>
                <label className="text-sm md:text-base" placeholder="Dhaka">
                  Division <span style={{ color: "red", fontSize: 20 }}>*</span>
                </label>
                <AppSelect
                  defaultValue="Select Division"
                  data={divisionIdNameModels}
                  value={divisionId}
                  onChange={handleDivision}
                  errorMessage={divisionError}
                  disabled={
                    isCountryAdmin(reactLocalStorage.get("RoleName")) ||
                      isZilaAdmin(reactLocalStorage.get("RoleName")) ||
                      isUpazilaAdmin(reactLocalStorage.get("RoleName")) ||
                      isBazarRider(reactLocalStorage.get("RoleName"))
                      ? true
                      : false
                  }
                />
              </div>

              {divisionId != 0 && (
                <div>
                  <label className="text-sm md:text-base" placeholder="Dhaka">
                    District{" "}
                    <span style={{ color: "red", fontSize: 20 }}>*</span>
                  </label>
                  <AppSelect
                    defaultValue="Select District"
                    data={districtsIdNameModels}
                    value={districtId}
                    onChange={handleDistrict}
                    errorMessage={districtError}
                    disabled={
                      isCountryAdmin(reactLocalStorage.get("RoleName")) ||
                        isZilaAdmin(reactLocalStorage.get("RoleName")) ||
                        isUpazilaAdmin(reactLocalStorage.get("RoleName")) ||
                        isBazarRider(reactLocalStorage.get("RoleName"))
                        ? true
                        : false
                    }
                  />
                </div>
              )}

              {divisionId != 0 && districtId != 0 && (
                <div>
                  <label className="text-sm md:text-base" placeholder="Dhaka">
                    Thana <span style={{ color: "red", fontSize: 20 }}>*</span>
                  </label>
                  <AppSelect
                    defaultValue="Select Thana"
                    data={areaIdNameModels}
                    value={areaId}
                    onChange={handleArea}
                    errorMessage={areaError}
                    disabled={
                      isCountryAdmin(reactLocalStorage.get("RoleName")) ||
                        isZilaAdmin(reactLocalStorage.get("RoleName")) ||
                        isUpazilaAdmin(reactLocalStorage.get("RoleName")) ||
                        isBazarRider(reactLocalStorage.get("RoleName"))
                        ? true
                        : false
                    }
                  />
                </div>
              )}

              <div>
                <label className="text-sm md:text-base" placeholder="Dhaka">
                  Occupation
                </label>
                <AppSelect
                  defaultValue="Select Occupation"
                  data={occupations}
                  value={occupationId}
                  onChange={handleOccupation}
                // disabled={
                //   isCountryAdmin(reactLocalStorage.get("RoleName")) ||
                //   isZilaAdmin(reactLocalStorage.get("RoleName")) ||
                //   isUpazilaAdmin(reactLocalStorage.get("RoleName"))
                //     ? true
                //     : false
                // }
                />
              </div>

              <div>
                <AppInput
                  className="myInput"
                  label="National Identity Number (NID)"
                  type="text"
                  placeholder="Enter your NID number"
                  value={nIDNumber}
                  onChange={handleNidNumber}
                // isDisabled={
                //   isCountryAdmin(reactLocalStorage.get("RoleName")) ||
                //   isZilaAdmin(reactLocalStorage.get("RoleName")) ||
                //   isUpazilaAdmin(reactLocalStorage.get("RoleName"))
                //     ? true
                //     : false
                // }
                />
              </div>
            </div>
          </div>
        </div>

        {/* payment method div starts here  */}
        <div className="px-0 md:px-8">
          <h2 className="text-lg font-semibold uppercase md:text-xl">
            Payment Method
          </h2>
          <p className="text-sm">Add Your Payment Method</p>
          {/* payment div section here  */}
          <div className="myBorder mt-4 grid grid-cols-1 items-center gap-x-8 gap-y-4 p-4 shadow md:grid-cols-2 md:p-8">
            <AppInput
              className="myInput"
              label="Bkash Number"
              type="text"
              placeholder="Enter your bkash Number"
              value={bkashNumber}
              onChange={handleBakashNumber}
              errorMessage={bkashNoError}
            />
            <AppInput
              className="myInput"
              label="Nagad Number"
              type="text"
              placeholder="Enter your Nagad Number"
              value={nagadNumber}
              onChange={handleNgadNumber}
              errorMessage={nagadNoError}
            />
            <AppInput
              className="myInput"
              label="Rocket Number"
              type="text"
              placeholder="Enter your Nagad Number"
              value={rocketNumber}
              onChange={handleRocketnumber}
              errorMessage={rocketError}
            />
            <div className="flex w-full flex-col items-center gap-x-4 lg:flex-row">
              <AppInput
                className="myInput"
                label="Bank Account Name"
                type="text"
                placeholder="Bank Account Name"
                value={bankName}
                onChange={handleBankName}
              />
              <AppInput
                className="myInput"
                label="Bank Account Number"
                type="text"
                placeholder="Bank Account Number"
                value={bankAccountNumber}
                onChange={handleBankAccountNumber}
              />
            </div>
          </div>
        </div>
        {/* Social Profiles div starts here  */}
        <div className="flex flex-col px-0 py-4 md:px-8 md:py-8">
          <h2 className="text-lg font-semibold uppercase md:text-xl">
            Social Profiles
          </h2>
          <p className="text-sm">Add elsewhere links to your profile</p>
          {/* social profile div body starts here  */}
          <div className="myBorder mt-4 grid grid-cols-1 items-center gap-x-8 gap-y-4 p-4 shadow md:grid-cols-2 md:p-8">
            <AppInput
              className="myInput"
              label="Facebook Link"
              type="text"
              placeholder="Enter your Facebook Link"
              value={faceBookLink}
              onChange={handleFacebookLink}
              errorMessage={faceBookLinkError}
            />
            <AppInput
              className="myInput"
              label="Twitter Link"
              type="text"
              placeholder="Enter your Twitter Link"
              value={twitterLink}
              onChange={handleTwitterLink}
              errorMessage={twitterLinkError}
            />
            <AppInput
              className="myInput"
              label="Instagram Link"
              type="text"
              placeholder="Enter your Instagram Link"
              value={instagramLink}
              onChange={handleInstagramLink}
              errorMessage={instagramLinkError}
            />
            <AppInput
              className="myInput"
              label="WhatsApp Number"
              type="text"
              placeholder="Enter your WhatsApp Number"
              value={whatsAppNumber}
              onChange={handleWhatsAppNumber}
              errorMessage={whatsAppNumberError}
            />
          </div>
        </div>
        {/* these is save button section  */}
        <div className="flex justify-end px-8">
          <AppButton
            text="Save"
            isSubmitting={isSaving}
            className="myBtn px-4 md:px-8"
            onClick={handleSaveProfile}
          />
        </div>
      </div >
    </>
  );
}
