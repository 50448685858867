import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CreateNoticeRequest } from "../../../models/notice/noticeModels";
import { createNoticeService } from "../../../httpService/notice/noticeServices";
import { AppButton } from "../../common/AppButton";
import { AppInput } from "../../common/AppInput";
import { AppDescription } from "../../common/AppDescription";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getUseRoleListAction } from "../../../store/actions/auth/address/userRoleActions";
import { AppCheckBox } from "../../common/AppCheckBox";
import { AlertColor } from "@mui/material";
import { AppSnackBar } from "../../common/AppSnackBar";

export default function CreateNotice() {

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [snackBarMessage, setSnackBarMessage] = useState("");
    const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
    const [snackBarOn, setSnackBarOn] = useState(false);

	const [isButtonLoading, setIsButtonLoading] = useState(false);

	const [selectedRoles, setSelectedRoles] = useState<number[]>([]);
	const [selectedRolesError, setSelectedRolesError] = useState("");
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [titleError, setTitleError] = useState("");
	const [descriptionError, setDescriptionError] = useState("");

	useEffect(() => {
		dispatch(getUseRoleListAction());
	}, []);

	const roles = useSelector((state: RootState) => {
		return state.userRoleListResponse.userRole;
	});

	const handleTitle = (event: any) => {
		setTitle(event);
		setTitleError("");
	};

	const handleDescription = (event: any) => {
		setDescription(event);
		setDescriptionError("");
	};

	const handleSelectedRoles = (event: number) => {
		if (selectedRoles.includes(event)) {
			const updatedSelectedRoles = [...selectedRoles];
			const roleIndex = updatedSelectedRoles.indexOf(event);
			if (roleIndex !== -1) {
				updatedSelectedRoles.splice(roleIndex, 1);
			}
			setSelectedRoles(updatedSelectedRoles);
		} else {
			setSelectedRoles([...selectedRoles, event]);
		}
	};


	const handleSubmit = (event: any) => {

		let canExecute = true;
		if (title === "" || title == null || title === undefined) {
			setTitleError("Title is Required");
			canExecute = false;
		}
		if (description === "" || description == null || description === undefined) {
			setDescriptionError("Description is required");
			canExecute = false;
		}
		if (selectedRoles === null || selectedRoles === undefined || selectedRoles.length === 0) {
			setSelectedRolesError("Please select roles");
			canExecute = false;
		}
		if (canExecute) {
			setSnackBarOn(false);
			event.preventDefault();
			var model = new CreateNoticeRequest();
			model.title = title;
			model.description = description;
			model.roles = selectedRoles;
			setIsButtonLoading(true);
			createNoticeService(model)
				.then(() => {
					setSnackBarOn(true);
					setSnackBarMessage("Notice Added Successfully.");
					setSnackBarMessageType("success");
					setIsButtonLoading(false);
					setTimeout(() => {
						navigate("/admin/notice/notice-list", { replace: true });
					}, 1000);
					
				})
				.catch(() => {
					setIsButtonLoading(false);
				});
		}
	};

	return (
		<>
			{snackBarOn && (
                <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
            )}
			
			<div className="px-2 lg:px-4 lg:pt-4">
				<AppButton
					className="myOfferBtn text-xs lg:text-sm"
					onClick={() => navigate("/admin/notice/notice-list")}
					text="Back"
				/>
			</div>

			<div className='max-w-3xl mx-auto mt-2 lg:mt-0 p-2 lg:p-4 space-y-2 shadow-md myBorder'>
				<h2 className="title text-center">Create Notice</h2>
				<div className='grid grid-cols-1 lg:grid-cols-1 gap-4'>
					<AppInput
						type="text"
						label="Title"
						className="myInput"
						value={title}
						onChange={handleTitle}
						errorMessage={titleError}
					/>
					<AppDescription
						label='Full Description'
						value={description}
						onChange={handleDescription}
						errorMessage={descriptionError}
					/>
				</div>
				<div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
					{roles?.filter((_:any) => _.name == "RESELLER" || _.name == "VENDOR")?.map((role: any) => (
						<div className="flex items-center gap-x-0.5 lg:gap-x-2">
							<AppCheckBox
								key={role.id}
								onChange={() => handleSelectedRoles(role.id)}
							/>
							<label className="text-sm lg:text-lg">{role.name}</label>
						</div>
					))}
					<p className="text-xs text-red pl-[2px]">{selectedRolesError}</p>
				</div>
				<div className="flex items-center justify-end">
					<AppButton className="myOfferBtn" text="Submit" isSubmitting={isButtonLoading} onClick={handleSubmit} />
				</div>
			</div>
		</>
	);
}
