import { sSGetCartItemsService, sSGetTotalCartItemService } from "../../../httpService/superShops/ssCartService";
import { SSGetCartItemsRequest } from "../../../models/superShopp/cart/sSCartModel";
import { SSGET_CART_ITEMS, SSGET_TOTAL_CART_ITEMS } from "../../constants/superShop/ssCartConstants";

export const sSGetCartItemsAction = (payload?: SSGetCartItemsRequest) => async (dispatch: any) => {
	var data = sSGetCartItemsService(payload);
	const pload = (await data).data;
	dispatch({ type: SSGET_CART_ITEMS, payload: pload });
};

export const sSGetTotalCartItemAction = () => async (dispatch: any) => {
	var data = sSGetTotalCartItemService();
	const pload = (await data).data;
	dispatch({ type: SSGET_TOTAL_CART_ITEMS, payload: pload });
};