import { PaginationPayloadModel } from "../../models/common/paginationModels";
import { GET, POST, PUT } from "../service";

export const sSProductReviewListService = (productId: any) => {
  return GET(`/api/SSProductReview/GetProductReviews?productId=${productId}`);
};

export const sSProductAdminReviewListService = (payload: PaginationPayloadModel) => {
  return GET(`/api/SSProductReview/GetAdminProductReviews?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}`);
};

export const sSAddProductReviewService = (data: any) => {
  return POST("/api/SSproductReview/SubmitProductReviewOrReply", data);
};

export const sSDeleteProductReviewService = (data: any) => {
  return PUT(`/api/SSProductReview/DeleteProductReview`, data);
};
