import { PaginationPayloadModel } from "../common/paginationModels";

export class GetBloodDonorsRequest extends PaginationPayloadModel {
    divisionId: number = 0;
    districtId: number = 0;
    areaId: number = 0;
    bloodGroup?: BloodGroups;
}


export enum BloodGroups {
    All,

    A_Positive,
    A_Negative,

    B_Positive,
    B_Negative,

    O_Positive,
    O_Negative,

    AB_Positive,
    AB_Negative,
}