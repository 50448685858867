import { AlertColor } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PaginationPayloadModel } from "../../../../../../models/common/paginationModels";
import { RootState } from "../../../../../../store";
import { AppButton } from "../../../../../common/AppButton";
import { AppDeleteModal } from "../../../../../common/AppDeleteModal";
import { AppSearchBox } from "../../../../../common/AppSearchBox";
import { AppSnackBar } from "../../../../../common/AppSnackBar";
import { AppLoader } from "../../../../../common/AppLoader";
import { AppPagination } from "../../../../../common/AppPagination";
import { sSGetBazarListAction } from "../../../../../../store/actions/superShop/sSBazarActions";
import { sSdeleteBazarService } from "../../../../../../httpService/superShops/ssBazarServices";
import { SSGetBazarListRequest } from "../../../../../../models/superShopp/Address/sSBazar/sSBazarModel";

export default function SSGetBazarList() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  var request = new SSGetBazarListRequest();

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);

  const bazarList = useSelector((state: RootState) => {
    return state.sSBazarListResponse.data;
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        request.limit = 10;
        request.offset = 0;
        await dispatch(sSGetBazarListAction(request));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  const handleOnRowChange = (payload: PaginationPayloadModel) => {
    const fetchData = async () => {
      try {
        request.limit = payload.limit;
        request.offset = 0;
        setIsLoading(true);
        await dispatch(sSGetBazarListAction(request));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }

  const handleOnPageChange = (payload: PaginationPayloadModel) => {
    const fetchData = async () => {
      try {
        request.limit = payload.limit;
        request.offset = payload.offset;
        setIsLoading(true);
        await dispatch(sSGetBazarListAction(request));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }

  const onSearch = (searchQuery: string) => {
    const fetchData = async () => {
      try {
        request.searchQuery = searchQuery;
        setIsLoading(true);
        await dispatch(sSGetBazarListAction(request));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  };

  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  const navigateToAdd = () => {
    navigate("/super-shop/bazar/add");
  };

  const handleUpdate = (id: any) => {
    navigate(`/super-shop/bazar/update/${id}`);
  };

  const [bulkIds, setBulkIds] = useState([]);

  const openDeleteModal = (id: any) => {
    setOpenDeleteModal(true);

    let ids: any = [];
    ids.push(id);

    setBulkIds(ids);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleDelete = () => {
    setSnackBarOn(false);
    sSdeleteBazarService(bulkIds)
      .then((response: any) => {
        if (response?.data?.statusCode == 'error') {
          setSnackBarOn(true);
          setsnackBarMessage("This bazar can not be deleted");
          setSnackBarMessageType("error");
          closeDeleteModal();
        } else {
          setSnackBarOn(true);
          setsnackBarMessage("Successfully Deleted");
          setSnackBarMessageType("success");
          closeDeleteModal();
          dispatch(sSGetBazarListAction(request));
        }
      })
      .catch(() => {
        setSnackBarOn(true);
        setsnackBarMessage("Something went wrong");
        setSnackBarMessageType("error");
      });
  };


  return (
    <div>
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}

      <h2 className="title text-center">Bazer({bazarList?.size})</h2>

      <div className="flex flex-col lg:flex-row lg:items-center gap-2 lg:gap-6 pt-2">
        <AppButton onClick={navigateToAdd} text="Add Bazar" />
        <AppSearchBox className="myInput" onChange={onSearch} placeholder="Search Here" />
      </div>
      <>
        {bazarList.items ? (
          <div className="w-full pt-4">
            {/* these is header part for desktop */}
            <div className="hidden lg:flex w-full bg-primary myBorder mr-4">
              <div className="centerDiv text-white font-medium py-2.5 border-r w-[40%]">Name</div>
              <div className="centerDiv text-white font-medium py-2.5 border-r w-[40%]">Bangla Name</div>
              <div className="centerDiv text-white font-medium py-2.5 border-r w-[10%]">Update</div>
              <div className="centerDiv text-white font-medium py-2.5 w-[10%]">Remove</div>
            </div>
            {isLoading ? (
              <AppLoader status={true} />
            ) : (
              <>
                {/* these is middle part for desktop */}
                <div className="hidden lg:flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-1 py-1.5">
                  {bazarList.items.map((item: any) => (
                    <div key={item?.id} className="myBorder flex w-full items-center">

                      <div className="centerDiv justify-start border-r border-grey pl-2 py-1.5 h-full whitespace-nowrap overflow-hidden text-ellipsis w-[40%]">{item?.name}</div>
                      <div className="centerDiv justify-start border-r border-grey pl-2 py-1.5 h-full whitespace-nowrap overflow-hidden text-ellipsis w-[40%]">{item?.bnName}</div>

                      <div className="centerDiv border-r border-grey py-1.5 h-full  w-[10%]">
                        <AppButton
                          className="myBtn bg-royalBlue border-royalBlue py-0.5 lg:py-1 text-sm"
                          text="Update"
                          onClick={() => handleUpdate(item?.id)}
                        />
                      </div>
                      <div className="centerDiv border-r border-grey py-1.5 h-full  w-[10%]">
                        <AppButton
                          className="myBtn bg-red border-red py-0.5 lg:py-1 text-sm"
                          text="Remove"
                          onClick={() => openDeleteModal(item?.id)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                {/* these is middle part for mobile */}
                <div className="h-[78dvh] lg:hidden overflow-y-auto flex flex-col gap-2">
                  {bazarList.items.map((item?: any) => (
                    <div key={item?.id} className="myBorder flex flex-col w-full p-2">
                      <div className="flex flex-col w-full">
                        <div className="flex items-center gap-1">
                          <span className="font-medium">Name:</span> <span>{item?.name}</span>
                        </div>
                        <div className="flex items-center gap-1">
                          <span className="font-medium">Bn Name:</span> <span>{item?.bnName}</span>
                        </div>
                      </div>
                      {/* these is approve decline details div  */}
                      <div className="flex items-center justify-center gap-4 w-full pt-2">
                        <AppButton
                          className="myBtn bg-royalBlue border-royalBlue py-0.5 lg:py-1 text-sm"
                          text="Update"
                          onClick={() => handleUpdate(item?.id)}
                        />
                        <AppButton
                          className="myBtn bg-red border-red py-0.5 lg:py-1 text-sm"
                          text="Remove"
                          onClick={() => openDeleteModal(item?.id)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
            <div className="py-1 flex items-center">
              <AppPagination curPage={1} limit={10} offset={0} onPageChange={handleOnPageChange} onRowChange={handleOnRowChange} size={bazarList?.size} />
            </div>
          </div>
        ) : (
          <div className="w-full h-[50dvh] flex justify-center items-center text-3xl text-textBlack/90 font-medium">
            <span>No Bazar found</span>
          </div>
        )}
      </>

      {isOpenDeleteModal && (
        <AppDeleteModal
          text="Are you sure to Delete?"
          handleDelete={handleDelete}
          closeDeleteModal={closeDeleteModal}
        />
      )}
    </div>
  );
}
