import { SSGET_CART_ITEMS, SSGET_TOTAL_CART_ITEMS } from "../../constants/superShop/ssCartConstants";

const inititalState = {
    data: [],
}

export function sSGetCartItemsReducer(state = inititalState, action: any) {
    switch (action.type) {
        case SSGET_CART_ITEMS:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

export function sSGetTotalCartItemReducer(state = inititalState, action: any) {
    switch (action.type) {
        case SSGET_TOTAL_CART_ITEMS:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}
