import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { ResellingAdminGetOrderListRequest, OrderAdminResponse } from "../../../../models/order/resellingOrdersModels";
import { RootState } from "../../../../store";
import { AppButton } from "../../../common/AppButton";
import _moment from 'moment';
import { AppLoader } from "../../../common/AppLoader";
import { AppSearchBox } from "../../../common/AppSearchBox";
import { BiSearchAlt2 } from "react-icons/bi";
import { adminAffiliateGetOrderListAction } from "../../../../store/actions/sales/affiliateOrderActions";
import { AppPagination } from "../../../common/AppPagination";

export default function AdminAffiliateGetOrderList() {

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const orders = useSelector((state: RootState) => {
    return state.affiliateGetOrderListAdminResponse.data;
  });

  const [orderId, setOrderId] = useState(0);
  const [customerId, setCustomerId] = useState(0);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [paymentStatus, setPaymentStatus] = useState(0);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orderStatusFilter = queryParams.get('orderStatus');
  const shippingStatusFilter = queryParams.get('shippingStatus');

  var payload = new ResellingAdminGetOrderListRequest();
  payload.orderId = orderId;
  payload.customerId = customerId;
  payload.startDateTime = startDateTime;
  payload.endDateTime = endDateTime;
  payload.orderStatus = orderStatusFilter != null ? parseInt(orderStatusFilter) : 0;
  payload.paymentStatus = paymentStatus;
  payload.shippingStatus = shippingStatusFilter != null ? parseInt(shippingStatusFilter) : 0;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        setIsComplete(payload.orderStatus === 4);
        setIsCancelled(payload.orderStatus === 2);
        setIsShipped(payload.shippingStatus === 2);
        setIsReturned(payload.orderStatus === 3);
        setIsConfirmed(payload.orderStatus === 5);
        setInProgress(payload.orderStatus === 1);

        await dispatch(adminAffiliateGetOrderListAction(payload));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  const handleOnPageChange = (payload: ResellingAdminGetOrderListRequest) => {
    const fetchData = async () => {
      try {
        payload.orderId = 0;
        payload.customerId = 0;
        payload.startDateTime = startDateTime;
        payload.endDateTime = endDateTime;
        payload.paymentStatus = paymentStatus;
        payload.orderStatus = orderStatusFilter != null ? parseInt(orderStatusFilter) : 0;
        payload.shippingStatus = shippingStatusFilter != null ? parseInt(shippingStatusFilter) : 0;
        payload.limit = payload.limit;
        payload.offset = payload.offset;
        await dispatch(adminAffiliateGetOrderListAction(payload));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }

  const handleOnRowChange = (payload: ResellingAdminGetOrderListRequest) => {
    const fetchData = async () => {
      try {
        payload.orderId = 0;
        payload.customerId = 0;
        payload.startDateTime = startDateTime;
        payload.endDateTime = endDateTime;
        payload.paymentStatus = paymentStatus;
        payload.orderStatus = orderStatusFilter != null ? parseInt(orderStatusFilter) : 0;
        payload.shippingStatus = shippingStatusFilter != null ? parseInt(shippingStatusFilter) : 0;

        payload.limit = payload.limit;
        payload.offset = 0;
        await dispatch(adminAffiliateGetOrderListAction(payload));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchOrder = (e: any) => {
    const fetchData = async () => {
      try {
        setSearchQuery(e)
        
        payload.orderId = 0;
        payload.customerId = 0;
        payload.startDateTime = startDateTime;
        payload.endDateTime = endDateTime;
        payload.paymentStatus = paymentStatus;
        payload.searchQuery = e;

        await dispatch(adminAffiliateGetOrderListAction(payload));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }

  const orderTimeFormatter = (createdDateTime: any) => {
    const createdTime = _moment(createdDateTime, 'YYYY-MM-DD HH:mm:ss.SSSSSSS');
    return createdTime.format('MMM D, YY [at] h:mm A');
  }

  const [isAll, setIsAll] = useState(true);
  const [isInProgress, setInProgress] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [isShipped, setIsShipped] = useState(false);
  const [isReturned, setIsReturned] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const selectAll = () => {
    setIsAll(true);
    setInProgress(false);
    setIsComplete(false);
    setIsCancelled(false);
    setIsShipped(false);
    setIsReturned(false);
    setIsConfirmed(false);
    dispatch(adminAffiliateGetOrderListAction(payload));
  }

  const selectInProgress = () => {
    setIsAll(false);
    setInProgress(true);
    setIsComplete(false);
    setIsCancelled(false);
    setIsShipped(false);
    setIsReturned(false);
    setIsConfirmed(false);
    payload.orderStatus = 1;
    dispatch(adminAffiliateGetOrderListAction(payload));
  }

  const selectComplete = () => {
    setIsAll(false);
    setInProgress(false);
    setIsComplete(true);
    setIsCancelled(false);
    setIsShipped(false);
    setIsReturned(false);
    setIsConfirmed(false);
    payload.orderStatus = 4;
    dispatch(adminAffiliateGetOrderListAction(payload));
  }

  const selectCancelled = () => {
    setIsAll(false);
    setInProgress(false);
    setIsComplete(false);
    setIsCancelled(true);
    setIsShipped(false);
    setIsReturned(false);
    setIsConfirmed(false);
    payload.orderStatus = 2;
    dispatch(adminAffiliateGetOrderListAction(payload));
  }

  const selectConfirmed = () => {
    setIsAll(false);
    setInProgress(false);
    setIsComplete(false);
    setIsCancelled(false);
    setIsShipped(false);
    setIsReturned(false);
    setIsConfirmed(true);
    payload.orderStatus = 5;
    dispatch(adminAffiliateGetOrderListAction(payload));
  }

  const selectShipped = () => {
    setIsAll(false);
    setInProgress(false);
    setIsComplete(false);
    setIsCancelled(false);
    setIsShipped(true);
    setIsReturned(false);
    setIsConfirmed(false);
    payload.shippingStatus = 2;
    dispatch(adminAffiliateGetOrderListAction(payload));
  }

  const selectReturned = () => {
    setIsAll(false);
    setInProgress(false);
    setIsComplete(false);
    setIsCancelled(false);
    setIsShipped(false);
    setIsReturned(true);
    setIsConfirmed(false);
    payload.orderStatus = 3;
    dispatch(adminAffiliateGetOrderListAction(payload));
  }

  return (
    <div className="container mx-auto px-0 md:px-16 pb-8 md:py-0">
      <div className="flex items-center justify-between pb-2 md:pb-4">
        <h3 className="title">Order List</h3>
        <h3 className="subTitle">Orders: <span>{orders?.items?.length}</span></h3>
      </div>
      <div className="flex flex-col-reverse lg:flex-row gap-2 items-center justify-between pb-2">
        {/* this is search bar div */}
        <div className="flex items-center border shadow-sm border-grey 2xl:p-2 px-1.5 py-0.5 rounded cursor-pointer">
          <BiSearchAlt2 className="text-secondary cursor-pointer 2xl:text-3xl md:text-2xl text-xl font-bold" />
          <AppSearchBox onChange={handleSearchOrder} className="border-none w-[81dvw] md:w-[25dvw] px-2 text-sm md:text-base" />
        </div>
        {/* this is buttons div  */}
        <div className="grid grid-cols-3 md:flex gap-2">
          {/* <AppButton className={isAll ? "myBtn" : "outlineBtn"} text="All" onClick={selectAll} /> */}
          <AppButton className={isInProgress ? "myBtn text-xs lg:text-sm px-1 lg:px-4" : "outlineBtn text-xs lg:text-sm px-1 lg:px-4"} text="Pending" onClick={selectInProgress} />
          <AppButton className={isCancelled ? "myBtn text-xs lg:text-sm px-1 lg:px-4" : "outlineBtn text-xs lg:text-sm px-1 lg:px-4"} text="Cancelled" onClick={selectCancelled} />
          <AppButton className={isConfirmed ? "myBtn text-xs lg:text-sm px-1 lg:px-4" : "outlineBtn text-xs lg:text-sm px-1 lg:px-4"} text="Confirmed" onClick={selectConfirmed} />
          <AppButton className={isShipped ? "myBtn text-xs lg:text-sm px-1 lg:px-4" : "outlineBtn text-xs lg:text-sm px-1 lg:px-4"} text="Delivered" onClick={selectShipped} />
          <AppButton className={isReturned ? "myBtn text-xs lg:text-sm px-1 lg:px-4" : "outlineBtn text-xs lg:text-sm px-1 lg:px-4"} text="Returned" onClick={selectReturned} />
          <AppButton className={isComplete ? "myBtn text-xs lg:text-sm px-1 lg:px-4" : "outlineBtn text-xs lg:text-sm px-1 lg:px-4"} text="Completed" onClick={selectComplete} />
        </div>
      </div>
      <hr className="border border-textBlack" />
      {isLoading ? (
        <div className="container mx-auto px-2 md:px-0 pb-12">
          <AppLoader status={true} />
        </div>
      ) : (

        <>
          {orders?.items?.length ? (
            <div className="w-full pt-2">
              {/* these is header part for desktop */}
              <div className="hidden lg:flex w-full bg-primary rounded">
                <div className="centerDiv text-white font-medium py-2 border-r w-[8%]">Order ID</div>
                <div className="centerDiv text-white font-medium py-2 border-r w-[10%]">Quantity</div>
                <div className="centerDiv text-white font-medium py-2 border-r w-[9.9%]">Price</div>
                <div className="centerDiv text-white font-medium py-2 border-r w-[19.9%]">Order Status</div>
                <div className="centerDiv text-white font-medium py-2 border-r w-[19.89%]">Shipping Status</div>
                <div className="centerDiv text-white font-medium py-2 border-r w-[19.8%]">Date & Time</div>
                <div className="centerDiv text-white font-medium py-2 w-[12%]">Details</div>
              </div>
              {/* these is middle part for desktop */}
              <div className="hidden lg:flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-1 py-1">
                {orders?.items?.map((order: OrderAdminResponse) => (
                  <div key={order?.id} className="myBorder flex w-full items-center">
                    <div className="centerDiv border-r border-grey py-1 h-full w-[8%]">{order?.id}</div>
                    <div className="centerDiv border-r border-grey py-1 h-full font-medium w-[10%]">{order?.qty}</div>
                    <div className="centerDiv border-r border-grey py-1 h-full w-[10%]">৳{Number(order?.totalAmount)}</div>
                    <div className="centerDiv border-r border-grey py-1 h-full w-[20%]">
                      <span className={`${order?.orderStatus === "InProgress" ? 'InProgress' : ''}` || `${order?.orderStatus === "Cancelled" ? 'Cancelled' : ''}` || `${order?.orderStatus === "Complete" ? 'Completed' : ''}` || `${order?.orderStatus === "Returned" ? 'Returned' : ''}` || `${order?.orderStatus === "Confirm" ? 'Confirmed' : ''}` || `${order?.orderStatus === "paid" ? 'paid' : ''}`}>
                        {order?.orderStatus === "InProgress" ? "Pending" : order?.orderStatus === "Cancelled" ? "Cancelled" : order?.orderStatus === "Complete" ? "Completed" : order?.orderStatus === "Returned" ? "Returned" : order?.orderStatus === "Confirm" ? "Confirmed" : ""}
                      </span>
                    </div>
                    <div className="centerDiv border-r border-grey py-1 h-full w-[20%]">
                      <span className={`${order?.orderStatus === "Cancelled" ? 'Cancelled' : ''}` || `${order?.orderStatus === "Returned" ? 'Returned' : ''}` || `${order?.shippingStatus === "Shipped" ? 'Given-to-delivery' : ''}` || `${order?.shippingStatus === "InProgress" ? 'InProgress' : ''}` || `${order?.shippingStatus === "Delivered" ? 'Shipped' : ''}`}>
                        {order?.orderStatus === "Cancelled" ? "Cancelled" : order?.orderStatus === "Returned" ? "Returned" : order?.shippingStatus === "Shipped" ? "Delivered" : order?.shippingStatus === "InProgress" ? 'Pending' : ""}
                      </span>
                    </div>
                    <div className="centerDiv border-r border-grey py-1 h-full text-sm w-[20%]">{orderTimeFormatter(order?.orderDateTime)}</div>
                    <div className="centerDiv py-0.5 w-[12%]">
                      <Link className="myBtn" to={`/admin/sales/affiliate-order-details/${order?.id}/${order?.customerId}`}>
                        Details
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
              {/* these is middle part for mobile */}
              <div className="h-[70dvh] lg:hidden overflow-y-auto flex flex-col gap-1">
                {orders?.items?.map((order: OrderAdminResponse) => (
                  <Link key={order?.id} to={`/admin/sales/affiliate-order-details/${order?.id}/${order?.customerId}`} className="myBorder flex w-full hover:shadow">
                    <div className="w-[32%] h-full p-1 space-y-1 border-r border-grey">
                      <div className="text-xsm flex items-center justify-between gap-1 pr-1">
                        <span className="">Order ID:</span> <span>#{order?.id}</span>
                      </div>
                      <div className="text-[9px] text-center"> {orderTimeFormatter(order?.orderDateTime)}</div>
                    </div>
                    <div className="w-[24%] text-xsm h-full p-1 space-y-1 border-r border-grey">
                      <div className="flex items-center justify-between gap-1 pr-1">
                        <span>Quantity:</span> <span className="font-medium">{order?.qty}</span>
                      </div>
                      <div className="flex items-center justify-between gap-1 pr-1">
                        <span className="">Price:</span> <span className="font-medium">৳{Number(order?.totalAmount)}</span>
                      </div>
                    </div>
                    <div className="w-[44%] text-[9px] h-full px-0.5 py-1 space-y-0.5 border-r border-grey">
                      <div className="flex items-center justify-between gap-1 pr-1">
                        <span className="">Order Status:</span>
                        <span className={`${order?.orderStatus == "InProgress" ? 'InProgress' : ''}` || `${order?.orderStatus == "paid" ? 'paid' : ''}` || `${order?.orderStatus == "Cancelled" ? 'Cancelled' : ''}` || `${order?.orderStatus == "Returned" ? 'ModeratorEarning' : ''}` || `${order?.orderStatus == "Complete" ? 'Completed' : ''}`}>
                          {order?.orderStatus == "InProgress" ? "Pending" : order?.orderStatus == "Cancelled" ? "Cancelled" : order?.orderStatus == "Complete" ? "Completed" : order?.orderStatus == "Returned" ? "Returned" : order?.orderStatus == "Confirm" ? "Confirmed" : ""}
                        </span>
                      </div>
                      <div className="flex items-center justify-between gap-1 pr-1">
                        <span className="">Shipping Status:</span>
                        <span className={`${order?.shippingStatus == "InProgress" ? 'InProgress' : ''}` || `${order?.shippingStatus == "Shipped" ? 'Shipped' : ''}` || `${order?.shippingStatus == "Delivered" ? 'Shipped' : ''}`}>
                          {order?.orderStatus == "Cancelled" ? "Cancelled" : order?.orderStatus == "Returned" ? "Returned" : order?.shippingStatus == "Shipped" ? "Delivered" : order?.shippingStatus == "InProgress" ? 'Pending' : ""}
                        </span>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
              {/* these is footer part  */}
              {/* <div className="myBorder py-1 text-center">pagination here</div> */}
              <div className="py-1 flex items-center">
                <AppPagination curPage={1} limit={10} offset={0} onPageChange={handleOnPageChange} onRowChange={handleOnRowChange} size={orders?.size} />
              </div>
            </div>
          ) : (
            <div className="w-full h-[50dvh] flex justify-center items-center text-3xl text-textBlack/90 font-medium">
              <span>No order found</span>
            </div>
          )}
        </>
      )}
    </div>
  );
}
