import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import "./App.css";
import { AdminNavbar } from "./components/admin/root/AdminNavbar";
import IndexRoute from "./router/IndexRoute";
import ResellingLeftDrawer from "./components/public/reselling/ResellingLeftDrawer";
import { ResellingCategoryFilter } from "./components/public/reselling/ResellingCategoryFilter";
import AffiliateLeftDrawer from "./components/public/affiliate/AffiliateLeftDrawer";
import MainMenuDrawer from "./components/public/common/MainMenuDrawer";
import ResellingNavBar from "./components/public/reselling/ResellingNavBar";
import AffiliateNavBar from "./components/public/affiliate/AffiliateNavBar";
import {
  isAdmin,
  isBazarRider,
  isDataOfferVendor,
  isModerator,
  isVendor,
} from "./common/common";
import { AffiliateCategoryFilter } from "./components/public/affiliate/AffiliateCategoryFilter";
import BechteChaiNavBar from "./components/public/bechteChai/BechteChaiNavBar";
import BechteChaiLeftDrawer from "./components/public/bechteChai/BechteChaiLeftDrawer";
import { BechteChaiCategoryFilter } from "./components/public/bechteChai/BechteChaiCategoryFilter";
import SSNavBar from "./components/public/superShop/public/SSNavbar";
import SSLeftDrawer from "./components/public/superShop/public/SSLeftDrawer";
import Navbar from "./components/landingPage/Navbar";
import SSFilterComponent from "./components/public/superShop/public/SSFilterComponent";

export default function App() {

  const navigate = useNavigate();

  const url = window.location.pathname;

  const pathSegments = url.split("/");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (url === "/admin") {
      if (isAdmin(reactLocalStorage.get("RoleName")) ||
        isModerator(reactLocalStorage.get("RoleName")) ||
        isVendor(reactLocalStorage.get("RoleName")) ||
        isBazarRider(reactLocalStorage.get("RoleName")) ||
        isDataOfferVendor(reactLocalStorage.get("RoleName"))) {
      } else {
        navigate("/signIn");
      }
    }
  }, [navigate, isAdmin(reactLocalStorage.get("RoleName")), url]);

  return (
    <>

      {/* <div className="flex items-center justify-center h-screen">
        <div className="flex items-center justify-center h-screen">
          <p className="text-center text-2xl">
            <h6>
              Update Running... Will back Soon...
            </h6>
          </p>
        </div>
      </div> */}

      {pathSegments &&
        (pathSegments[1] === "affiliate" ? <AffiliateLeftDrawer /> :
          pathSegments[1] === "reselling-platform" ? <ResellingLeftDrawer /> :
            pathSegments[1] === "bechte-chai" ? <BechteChaiLeftDrawer /> :
              pathSegments[1] === "super-shop" ? <SSLeftDrawer /> :
                <></>
        )}

      {pathSegments && pathSegments[1] === "affiliate" && <AffiliateCategoryFilter />}
      {pathSegments && pathSegments[1] === "bechte-chai" && <BechteChaiCategoryFilter />}
      {pathSegments && pathSegments[1] === "reselling-platform" && <ResellingCategoryFilter />}
      {pathSegments && pathSegments[1] === "super-shop" && <SSFilterComponent />}

      <MainMenuDrawer />

      {isAdmin(reactLocalStorage.get("RoleName")) ||
        isModerator(reactLocalStorage.get("RoleName")) ||
        isVendor(reactLocalStorage.get("RoleName")) ||
        isBazarRider(reactLocalStorage.get("RoleName")) ||
        isDataOfferVendor(reactLocalStorage.get("RoleName")) ? (
        <AdminNavbar />
      ) : pathSegments &&
      (pathSegments[1] === "affiliate" ? <AffiliateNavBar /> :
        pathSegments[1] === "reselling-platform" ? <ResellingNavBar /> :
          pathSegments[1] === "bechte-chai" ? <BechteChaiNavBar /> :
            pathSegments[1] === "super-shop" ? <SSNavBar /> :
              <Navbar />
      )}
      <IndexRoute />
    </>
  );
}