import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  Outlet,
  useNavigate
} from 'react-router-dom';
import AdminLeftSideBar from "./AdminLeftSideBar";
import { useEffect } from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import { isAdmin, isBazarRider, isVendor } from '../../../common/common';

export default function AdminRoot(props: any) {

  const navigate = useNavigate();

  const url = window.location.pathname;

  useEffect(() => {
    if (url == "/admin") {
      if (isAdmin(reactLocalStorage.get("RoleName"))) {
        navigate("/admin/dashboard")
      }
      if (isVendor(reactLocalStorage.get("RoleName"))) {
        navigate("/admin/vendor-dashboard")
      }
      if (isBazarRider(reactLocalStorage.get("RoleName"))) {
        navigate("/admin/super-shop/rider/dashboard")
      }
    }
  })

  return (
    <div className='flex'>
      <AdminLeftSideBar />
      <div className="w-full pt-2 px-2 lg:px-4 relative overflow-auto scroll-auto h-[94dvh] lg:h-[91dvh] 2xl:h-[92dvh]">
        <Outlet />
      </div>
    </div>
  );
}
