import { AlertColor } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { AppButton } from "../../../common/AppButton";
import { AppInput } from "../../../common/AppInput";
import { RootState } from "../../../../store";
import { IdNameResponseModel } from "../../../../models/common/IdNameModel";
import { AppSelect } from "../../../common/AppSelect";
import { getHospitalsByAuthAction } from "../../../../store/actions/importantNumbers/doctorNumbersActions";
import { addDoctorNumbersService } from "../../../../httpService/importantNumbers/doctorNumbersServices";
import { reactLocalStorage } from "reactjs-localstorage";
import { isAdmin } from "../../../../common/common";

export default function INCreateDoctorNumbers() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [name, setName] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [phoneNumberFirst, setPhoneNumberFirst] = useState("");
  const [phoneNumberFirstErrorMessage, setPhoneNumberFirstErrorMessage] =
    useState("");
  const [phoneNumberSecond, setPhoneNumberSecond] = useState("");
  const [phoneNumberSecondErrorMessage, setPhoneNumberSecondErrorMessage] =
    useState("");
  const [email, setEmail] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [experties, setExperties] = useState("");
  const [expertiesErrorMessage, setExpertiesErrorMessage] = useState("");
  const [address, setAddress] = useState("");
  const [addressErrorMessage, setAddressErrorMessage] = useState("");
  const [hospitalsNumbersId, setHospitalsNumbersId] = useState(0);

  const handleHospitalsNumbersId = (id: any) => [setHospitalsNumbersId(id)];

  const handleNameChange = (event: any) => {
    setName(event);
    setNameErrorMessage("");
  };

  const handlePhoneNumberFirst = (event: any) => {
    setPhoneNumberFirst(event);
    setPhoneNumberFirstErrorMessage("");
  };
  const handlePhoneNumberSecond = (event: any) => {
    setPhoneNumberSecond(event);
    setPhoneNumberSecondErrorMessage("");
  };

  const handleEmail = (event: any) => {
    setEmail(event);
    setEmailErrorMessage("");
  };

  const handleExperties = (event: any) => {
    setExperties(event);
    setExpertiesErrorMessage("");
  };

  const handleAddress = (event: any) => {
    setAddress(event);
    setAddressErrorMessage("");
  };

  useEffect(() => {
    dispatch(getHospitalsByAuthAction());
  }, [dispatch]);

  const hospitalsList = useSelector((state: RootState) => {
    return state.getHospitalsByAuthResponse.data;
  });

  var dataHospitalListIdNameModels: IdNameResponseModel[] = [];
  hospitalsList?.items?.map((x: any) => {
    var hospital = new IdNameResponseModel();
    hospital.id = x.id;
    hospital.name = x.name;
    dataHospitalListIdNameModels.push(hospital);
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSnackBarOn(false);
    var payload = {
      name: name,
      phoneNumberFirst: phoneNumberFirst,
      phoneNumberSecond: phoneNumberSecond,
      email: email,
      experties: experties,
      address: address,
      hospitalsNumbersId: hospitalsNumbersId,
    };

    setIsButtonLoading(true);

    addDoctorNumbersService(payload)
      .then(() => {
        setSnackBarOn(true);
        setsnackBarMessage("Doctor Number Added Successfully.");
        setSnackBarMessageType("success");
        setIsButtonLoading(false);
        setTimeout(() => {
          navigateToDoctorNumbers();
        }, 300);
      })
      .catch((error: any) => {
        setIsButtonLoading(false);
        setNameErrorMessage(error.response.data.errors?.Name?.[0]);
        setExpertiesErrorMessage(error.response.data.errors?.Experties?.[0]);
        setPhoneNumberFirstErrorMessage(
          error.response.data.errors?.PhoneNumberFirst?.[0]
        );
        setPhoneNumberSecondErrorMessage(
          error.response.data.errors?.PhoneNumberSecond?.[0]
        );
        setEmailErrorMessage(error.response.data.errors?.Email?.[0]);
        setAddressErrorMessage(error.response.data.errors?.Address?.[0]);
      });
  };

  const navigateToDoctorNumbers = () => {
    if (isAdmin(reactLocalStorage.get("RoleName"))) {
      navigate(`/admin/important-numbers/doctor-numbers`);
    } else {
      navigate(`/important-numbers/doctor-numbers`);
    }
  };

  return (
    <div className="container mx-auto px-2 py-4">
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      <div className="flex flex-col lg:flex-row items-start lg:items-center pb-4">
        <AppButton
          className="gradientBtn"
          onClick={navigateToDoctorNumbers}
          text="Back"
        />
        <div className='w-full'>
          <h2 className="title text-center text-cyan-600">
            Create Doctor Number
          </h2>
        </div>
      </div>

      <form className="myBorder mx-auto max-w-xl grid grid-cols-2 gap-x-2 items-center lg:gap-x-4 gap-y-2 shadow-lg px-2 lg:px-5 py-2 lg:py-4">
        <AppInput
          label="Name"
          className="myInput"
          type="text"
          placeholder="Enter Name"
          onChange={handleNameChange}
          errorMessage={nameErrorMessage}
        />
        <AppInput
          label="Experties"
          className="myInput"
          type="text"
          placeholder="Enter Experties"
          onChange={handleExperties}
          errorMessage={expertiesErrorMessage}
        />
        <AppInput
          label="Phone Number First"
          className="myInput"
          type="text"
          placeholder="Enter Phone Number"
          onChange={handlePhoneNumberFirst}
          errorMessage={phoneNumberFirstErrorMessage}
        />
        <AppInput
          label="Phone Number Second"
          className="myInput"
          type="text"
          placeholder="Enter Phone Number"
          onChange={handlePhoneNumberSecond}
          errorMessage={phoneNumberSecondErrorMessage}
        />
        <AppInput
          label="Email"
          className="myInput"
          type="text"
          placeholder="Enter Email"
          onChange={handleEmail}
          errorMessage={emailErrorMessage}
        />
        <AppInput
          label="Address"
          className="myInput"
          type="text"
          placeholder="Enter Address"
          onChange={handleAddress}
          errorMessage={addressErrorMessage}
        />
        <div className="">
          <p className="text-sm md:text-base">Hospital:</p>
          <AppSelect
            defaultValue="Select Hospital"
            value={hospitalsNumbersId}
            data={dataHospitalListIdNameModels}
            onChange={handleHospitalsNumbersId}
          />
        </div>
        <div className="flex justify-end">
          <AppButton
            text="Submit"
            className="gradientBtn"
            onClick={handleSubmit}
            isSubmitting={isButtonLoading}
            variant="contained"
          />
        </div>
      </form>
    </div>
  );
}
