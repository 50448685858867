import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { RootState } from "../../../store";
import { reactLocalStorage } from "reactjs-localstorage";
import { AppSearchBox } from "../../common/AppSearchBox";
import {
  getUserProfileAction,
  getUserProfileNameAction,
} from "../../../store/actions/auth/user/userActions";
import { BiCategoryAlt, BiHomeAlt2, BiSearchAlt2 } from 'react-icons/bi';
import { CgMenu } from "react-icons/cg";
import { RESELLING_PRODUCT_FILTER } from "../../../store/constants/catalog/resellingProductConstants";
import Navbar from "../../landingPage/Navbar";

export default function BechteChaiNavBar() {

  const location = useLocation();

  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 100);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  const navigate = useNavigate();

  const dispatch = useDispatch();

  var token = reactLocalStorage.getObject("SignIn");

  const userProfile = useSelector((state: RootState) => {
    return state.getUserProfileResponse.data;
  });

  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);


  useEffect(() => {
    dispatch(getUserProfileNameAction(userProfile?.firstName));
  }, [dispatch, userProfile]);


  const openExplore = () => {
    dispatch({ type: "OPEN-EXPLORE" });
  };

  const openLeftDrawer = () => {
    dispatch({ type: "OPEN-LEFT-MENU" });
  }

  const [searchQuery, setSearchQuery] = useState("");

  const onSearchQueryUpdate = (query: any) => {
    dispatch({ type: RESELLING_PRODUCT_FILTER, payload: query });
    setSearchQuery(query);
  }

  const handleSearchProduct = (query: any) => {
    navigate(`/bechte-chai/filterProductList/${0}/${0}?searchQuery=${searchQuery}`);
  }

  const navigateToHome = () => {
    setSearchQuery("")
  };

  const [isShowNotification, setIsShowNotification] = useState(false);

  const [searchIconVisiable, setSearchIconVisiable] = useState(true);
  const [searchBarVisiable, setSearchBarVisiable] = useState(false);

  const handleSearchIconVisiable = () => {
    if (searchIconVisiable) {
      setSearchIconVisiable(false)
      setSearchBarVisiable(true)
    }
  }

  return (
    <>
      <div className={`${sticky ? "stickyNav" : ""}`}>
        {/* these is first navbar  */}
        <Navbar />
        {(JSON.stringify(token) !== JSON.stringify({})) && (
          <>
            {location?.pathname?.includes('bechte-chai') &&

              <div className="border-b border-grey">
                <div className="container mx-auto flex gap-2 items-center justify-between 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28">
                  {/* training and search side start here  */}
                  <div className="flex items-center gap-x-3 md:gap-x-6 2xl:gap-x-8">
                    {/* these is menu section  */}
                    <div className="border shadow-sm border-grey 2xl:p-2 md:p-1.5 p-1 rounded cursor-pointer" onClick={openLeftDrawer}>
                      <CgMenu className="text-bechteChai cursor-pointer 2xl:text-3xl md:text-2xl text-xl font-bold" />
                    </div>
                    {/* these is home button */}
                    <div title="Home" onClick={navigateToHome} className="border shadow-sm border-grey 2xl:p-2 md:p-1.5 p-1 rounded cursor-pointer">
                      <Link to="/bechte-chai">
                        <BiHomeAlt2 className="text-bechteChai cursor-pointer 2xl:text-3xl md:text-2xl text-xl font-bold" />
                      </Link>
                    </div>

                  </div>
                  {/* all category start here  */}
                  <div className="hidden md:flex items-center gap-x-6 2xl:gap-x-8" >
                    {/* these is short search section  */}
                    {searchIconVisiable && (
                      <div onClick={handleSearchIconVisiable} className="border shadow-sm border-grey 2xl:p-2 md:p-1.5 p-1 rounded cursor-pointer">
                        <BiSearchAlt2 className="text-bechteChai cursor-pointer 2xl:text-3xl md:text-2xl text-xl font-bold" />
                      </div>
                    )}
                    {searchBarVisiable && (
                      <div className="flex items-center border shadow-sm border-grey 2xl:p-2 md:p-1.5 p-1 rounded cursor-pointer">
                        <AppSearchBox onChange={onSearchQueryUpdate} className="border-none w-[25dvw] px-2 text-sm md:text-base" />
                        <BiSearchAlt2 onClick={handleSearchProduct} className="text-bechteChai cursor-pointer 2xl:text-3xl md:text-2xl text-xl font-bold" />
                      </div>
                    )}
                    {/* these is all category section for desktop  */}
                    <div onClick={openExplore} className="2xl:text-xl text-lg px-10 2xl:py-2 py-1 border border-grey rounded font-semibold hover:bg-bechteChai shadow-sm hover:text-white cursor-pointer">All Category</div>

                  </div>

                  {/* these is icon for mobile  */}
                  <div className="md:hidden flex items-center gap-3">
                    {/* these is short search section  */}
                    {searchIconVisiable && (
                      <div onClick={handleSearchIconVisiable} className="border shadow-sm border-grey 2xl:p-2 md:p-1.5 p-1 rounded cursor-pointer">
                        <BiSearchAlt2 className="text-bechteChai cursor-pointer 2xl:text-3xl md:text-2xl text-xl font-bold" />
                      </div>
                    )}
                    {searchBarVisiable && (
                      <div className="flex items-center border shadow-sm border-grey 2xl:p-2 md:p-1.5 p-1 rounded cursor-pointer">
                        <AppSearchBox onChange={onSearchQueryUpdate} className="border-none w-[25dvw] px-2 text-sm md:text-base" />
                        <BiSearchAlt2 onClick={handleSearchProduct} className="text-bechteChai cursor-pointer 2xl:text-3xl md:text-2xl text-xl font-bold" />
                      </div>
                    )}
                    {/* these is category for mobile  */}
                    <div onClick={openExplore} className="border shadow-sm border-grey p-1 rounded cursor-pointer">
                      <BiCategoryAlt className="text-bechteChai cursor-pointer 2xl:text-3xl md:text-2xl text-xl font-bold" />
                    </div>

                  </div>
                </div>
              </div>
            }
          </>
        )}
      </div>

    </>
  );
}
