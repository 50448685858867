import { PaginationPayloadModel } from "../../models/common/paginationModels";
import { DELETE, GET, POST, PUT } from "../service";

export const addBechteChaiMainCatagoryService = (data: any) => {
    return POST("/api/BechteChaiCategory/CreateMainCategory", data);
  };
  
  export const deleteBechteChaiMainCatagoryService = (data: any) => {
    return DELETE(`/api/BechteChaiCategory/RemoveMainCategory`, {
      data: { "ids": data },
    });
  };
  
  export const getBechteChaiMainCatagoryListService = (
    payload?: PaginationPayloadModel, isAll:any = true, forPublic:boolean = false
  ) => {
      return GET(
        `/api/BechteChaiCategory/GetMainCategoryList?isAll=${isAll}&forPublic=${forPublic}&limit=${10000}&offset=${0}&searchQuery=`
      );
  };
  
  export const updateBechteChaiMainCatagoryServices = (data: any) => {
    return PUT(`/api/BechteChaiCategory/UpdateMainCategory`, data);
  };
  
  export const bechteChaiSubCategoryListService = (mainCategoryId: any = 0, payload: any, isAll:any=true) => {
    // if(mainCategoryId){
    //     return GET(`/api/SubCategoryLevelOne/GetSubCategoryLevelOnes?mainCategoryId=${mainCategoryId}`);
    // }
    // else if(payload){
    return GET(`/api/BechteChaiCategory/GetSubCategoryList?isAll=${isAll}&mainCategoryId=${mainCategoryId}&limit=${1000}&offset=${0}&searchQuery=`);
    // }
    // else{
    //     return GET(`/api/SubCategoryLevelOne/GetSubCategoryLevelOnes`);
    // }
}

export const addBechteChaiSubCategoryServices = (data: any) => {
    return POST('/api/BechteChaiCategory/CreateSubCategory', data);
}

export const updateBechteChaiSubCategoryService = (data: any) => {
    return PUT(`/api/BechteChaiCategory/UpdateSubCategory`, data);
}

export const deleteBechteChaiSubCategoryService = (data: any) => {
    return DELETE(`/api/BechteChaiCategory/RemoveSubCategory`, {
        data: { "ids": data },
    });
};
  