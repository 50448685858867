import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { AlertColor } from "@mui/material/Alert/Alert";
import { AppInput } from "../../common/AppInput";
import { reactLocalStorage } from "reactjs-localstorage";
import { useNavigate } from "react-router-dom";
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { SignalRService } from "../../../signalR/SignalRServices";
import { getUserProfileAction, getUserProfileNameAction } from "../../../store/actions/auth/user/userActions";
import { AppButton } from "../../common/AppButton";
import { AppSnackBar } from "../../common/AppSnackBar";
import { IoMdClose } from "react-icons/io";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { AppCheckBox } from "../../common/AppCheckBox";
import { SignUpModel, VerifyVerificationCodeModel } from "../../../models/auth/user/UserModels";
import { emailOrPhoneVerifyConfirmationCodeService, phoneSendConfirmationCodeService, signUpService } from "../../../httpService/user/userServices";
import { PrivacyPolicy } from "../common/PrivacyPolicy";

const signalRService = new SignalRService();

interface CustomJwtPayload extends JwtPayload {
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/role': string;
}

interface SignUpProps {
  afterSignUp?: any;
  targetRoute?: string;
}

export const SignUp: React.FC<SignUpProps> = ({ afterSignUp, targetRoute }) => {

  const resellingRef = reactLocalStorage.get("resellRef") as string;
  const affiliateRef = reactLocalStorage.get("affiliateRef") as string;

  const signUpModal = useSelector((state: RootState) => {
    return state.signUpModalResponse.signUp;
  });

  const dispatch = useDispatch();

  const closeSignUp = () => {
    document.body.classList.remove('noScroll');
    setEmailOrPhone("");
    setFirstName("");
    setPassword("");
    setConfirmPassword("");
    setEmailOrPhoneError("");
    setFirstNameError("");
    setPasswordError("");
    setConfirmPasswordError("");

    setIsCheckedTermsAndCondition(false)
    setIsEmailValid(false);
    setIsOtpValid(false);

    dispatch({ type: "CLOSE-SIGN-UP" });
  };

  const openSignIn = () => {
    dispatch({ type: "OPEN-SIGN-IN" });
    dispatch({ type: "CLOSE-SIGN-UP" });
  };


  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [otp, setOtp] = useState("");

  const [firstName, setFirstName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");


  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isOtpValid, setIsOtpValid] = useState(false);

  const [countdown, setCountdown] = useState(60);
  const [canResendOTP, setCanResendOTP] = useState(false);


  const [emailOrPhoneError, setEmailOrPhoneError] = useState("");
  const [otpError, setOtpError] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();



  useEffect(() => {
    let timer: any;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else {
      setCanResendOTP(true);
    }
    return () => clearTimeout(timer);
  }, [countdown]);


  const handleNameChange = (event: any) => {
    setFirstName(event);
    setFirstNameError("");
  };

  const handleOtpChange = (event: any) => {
    setOtp(event);
    setOtpError("");
  };

  const handleEmailOrPhoneChange = (event: any) => {
    setEmailOrPhone(event);
    setEmailOrPhoneError("");
  };

  const handlePasswordChange = (event: any) => {
    setPassword(event);
    setPasswordError("");
  };

  const handleConfirmPasswordChange = (event: any) => {
    setConfirmPassword(event);
    setConfirmPasswordError("");
  };

  const [isEmailVerificationButtonLoading, setIsEmailVerificationButtonLoading] = useState(false);
  const [isResendOtpButtonLoading, setResendOtpButtonLoading] = useState(false);

  const handleSendPhoneConfimationCode = (event: any) => {
    event.preventDefault();
    setIsEmailVerificationButtonLoading(true)
    setResendOtpButtonLoading(true)
    // emailOrPhoneSendConfirmationCodeService(emailOrPhone)
    phoneSendConfirmationCodeService(emailOrPhone)
      .then(() => {
        setIsEmailValid(true);
        setCountdown(60);
        setCanResendOTP(false);

        setIsEmailVerificationButtonLoading(false)
        setResendOtpButtonLoading(false)

        setIsShowSnackBar(true);
        setSnackBarMessage("Send Email Successfully");
        setSnackBarMessageType("success");
        setTimeout(() => {
          setIsShowSnackBar(false);
        }, 2000);

      }).catch((error: any) => {
        setIsEmailVerificationButtonLoading(false)
        setResendOtpButtonLoading(false)
        setEmailOrPhoneError(error.response.data.errors?.Phone?.[0]);
      })
  };


  const [isVerifyOtpButtonLoading, setVerifyOtpButtonLoading] = useState(false);

  const handleVerifyOTP = (event: any) => {
    event.preventDefault();

    var model = new VerifyVerificationCodeModel();
    model.emailOrPhone = emailOrPhone;
    model.code = otp;
    setVerifyOtpButtonLoading(true)
    emailOrPhoneVerifyConfirmationCodeService(model)
      .then(() => {
        setIsOtpValid(true);
        setVerifyOtpButtonLoading(false)
        setIsShowSnackBar(true);
        setSnackBarMessage("Submit Code Successfully");
        setSnackBarMessageType("success");
        setTimeout(() => {
          setIsShowSnackBar(false);
        }, 2000);
      }).catch((error: any) => {
        setVerifyOtpButtonLoading(false)
        setOtpError(error.response.data.errors?.EmailOrPhone?.[0])
      })
  };

  const navigate = useNavigate();

  const userProfile = useSelector((state: RootState) => {
    return state.getUserProfileResponse.data;
  });

  const [isSignUpButtonLoading, setSignUpButtonLoading] = useState(false);

  const handleSignUp = (event: any) => {

    event.preventDefault();

    document.body.classList.remove('noScroll');

    const user = new SignUpModel();
    user.firstName = firstName;
    user.emailOrPhone = emailOrPhone;
    user.password = password;
    user.confirmPassword = confirmPassword;
    user.ResellingRefId = resellingRef;
    user.AffiliateRefId = affiliateRef;

    setSignUpButtonLoading(true)

    signUpService(user)
      .then((res: any) => {

        setSignUpButtonLoading(false)

        signalRService.startConnection();

        reactLocalStorage.setObject("SignIn", res.data);

        const decodedToken = jwt_decode(res.data?.accessToken) as CustomJwtPayload;

        let role = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
        let userId = decodedToken['sub'] as string;

        reactLocalStorage.set("RoleName", role);

        reactLocalStorage.set("UserId", userId);

        afterSignUp(true);
        setEmailOrPhone("");
        setFirstName("");
        setPassword("");
        setConfirmPassword("");
        dispatch(getUserProfileAction());
        dispatch(getUserProfileNameAction(userProfile?.firstName));

        setIsShowSnackBar(true);
        setSnackBarMessage("Successfully SignedUp");
        setSnackBarMessageType("success");
        setTimeout(() => {
          setIsShowSnackBar(false);
        }, 2000);

        navigate(`${targetRoute}`, {
          replace: true,
        });


      })
      .catch((error) => {
        setSignUpButtonLoading(false)
        setEmailOrPhoneError(error.response.data.errors?.EmailOrPhone?.[0]);
        setFirstNameError(error.response.data.errors?.FirstName?.[0]);
        setPasswordError(error.response.data.errors?.Password?.[0]);
        setConfirmPasswordError(
          error.response.data.errors?.ConfirmPassword?.[0]
        );
      });
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmShowPassword(!showConfirmPassword);
  };

  const [isCheckedTermsAndCondition, setIsCheckedTermsAndCondition] = useState(false);

  const handleClickTermsAndCondition = () => {
    setIsCheckedTermsAndCondition(!isCheckedTermsAndCondition)
  }

  const [isOpenTermsAndCondition, setIsOpenTermsAndCondition] = useState(false);

  const handleOpenTermsAndCondition = () => {
    setIsOpenTermsAndCondition(!isOpenTermsAndCondition)
  }

  const [isOpenSignInButton, setIsOpenSignInButton] = useState(false);

  const handleOpenSignInButton = () => {
    setIsOpenSignInButton(!isOpenSignInButton)
  }

  if (!signUpModal) return null;
  else
    return (
      <>
        <div className="fixed flex justify-center items-center w-screen bg-black/80 min-h-screen z-50">

          {isShowSnackBar && (
            <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
          )}

          <div className="w-11/12 md:w-2/5 bg-white p-1 md:p-4 rounded">

            <div className="flex justify-end">
              <IoMdClose className="text-primary text-2xl font-semibold cursor-pointer" onClick={closeSignUp} />
            </div>

            <div className="">

              <h2 className="subTitle text-center text-textBlack">Create An Account</h2>
              <p className="text-sm text-textBlack text-center">Sign up now to get started with an account.</p>

              <div className="flex flex-col px-3 md:px-8 py-6">
                <AppInput
                  type="email"
                  label="Phone"
                  className="myInput"
                  placeholder="Enter your Phone"
                  onChange={handleEmailOrPhoneChange}
                  errorMessage={emailOrPhoneError}
                  isDisabled={isEmailValid ? true : false} />

                <div className='mt-4 w-full'>

                  <AppButton
                    text="Send Verification Code"
                    className="myBtn w-full"
                    onClick={handleSendPhoneConfimationCode}
                    isSubmitting={isEmailVerificationButtonLoading} />

                </div>

                {(isEmailValid && !isOtpValid) && (
                  <>
                    <div>
                      <p className="text-center text-xl font-medium text-gray-700">OTP</p>
                      <AppInput
                        className="py-2 flex text-center text-xl"
                        type="text"
                        maxLength={6}
                        placeholder="X X X X X X"
                        onChange={(event: any) => handleOtpChange(event)}
                        errorMessage={otpError}
                      />
                    </div>
                    {countdown !== 0 && (
                      <span className="text-sm text-gray-600">Can resend OTP after {countdown} seconds</span>
                    )}
                    {countdown === 0 && (
                      <div onClick={handleSendPhoneConfimationCode} className="text-primary font-medium text-sm lg:text-base cursor-pointer text-center underline underline-offset-2">
                        Resend OTP
                      </div>
                    )}
                    <AppButton
                      text="Verify OTP"
                      isSubmitting={isVerifyOtpButtonLoading}
                      className="myBtn mt-1"
                      onClick={handleVerifyOTP} />

                  </>
                )}

                {isOtpValid && (
                  <>
                    <AppInput
                      type="text"
                      label="Name"
                      className="myInput"
                      placeholder="Type your full name"
                      onChange={handleNameChange}
                      errorMessage={firstNameError} />

                    <div className="relative pt-2">
                      <AppInput
                        type={showPassword ? "text" : "password"}
                        className="myInput"
                        label="Password"
                        placeholder="Enter your Password"
                        value={password}
                        onChange={handlePasswordChange}
                        errorMessage={passwordError} />
                      {showPassword ? (
                        <AiOutlineEyeInvisible
                          className="absolute text-2xl text-gray-700 font-bold cursor-pointer right-3 top-9 lg:top-10"
                          onClick={togglePasswordVisibility} />
                      ) : (
                        <AiOutlineEye
                          className="absolute text-2xl text-gray-700 font-bold cursor-pointer right-3 top-9 lg:top-10"
                          onClick={togglePasswordVisibility} />
                      )}
                    </div>

                    <div className="relative pt-2">
                      <AppInput
                        type={showConfirmPassword ? "text" : "password"}
                        className="myInput"
                        label="Confirm Password"
                        placeholder="Enter your Password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        errorMessage={confirmPasswordError} />
                      {showConfirmPassword ? (
                        <AiOutlineEyeInvisible
                          className="absolute text-2xl text-gray-700 font-bold cursor-pointer right-3 top-9 lg:top-10"
                          onClick={toggleConfirmPasswordVisibility} />
                      ) : (
                        <AiOutlineEye
                          className="absolute text-2xl text-gray-700 font-bold cursor-pointer right-3 top-9 lg:top-10"
                          onClick={toggleConfirmPasswordVisibility} />
                      )}
                    </div>

                    <div className='text-xs lg:text-base mt-4 mb-2'>
                      <AppCheckBox onChange={handleClickTermsAndCondition} /> I have read and agree to the <span onClick={handleOpenTermsAndCondition} className="text-primary font-medium cursor-pointer">Terms of Service</span>
                    </div>

                    {isCheckedTermsAndCondition && (
                      <AppButton
                        text="Sign Up"
                        isSubmitting={isSignUpButtonLoading}
                        className="myBtn bg-royalBlue border-royalBlue"
                        onClick={handleSignUp} />
                    )}

                  </>
                )}

                <div className="flex items-center justify-between gap-2 my-2">
                  <p className="text-primary font-medium cursor-pointer" onClick={handleOpenSignInButton}>আগেই একাউন্ট আছে?</p>
                  {isOpenSignInButton && (
                    <span onClick={openSignIn} className="myBtn px-[25px] cursor-pointer"> Sign in</span>
                  )}
                </div>

              </div>
            </div>
          </div>
        </div>

        {isOpenTermsAndCondition && (
          <div className='fixed flex justify-center items-center w-screen bg-black/80 min-h-screen z-50'>
            <div className="w-11/12 md:w-2/5 bg-white p-1 md:p-4 rounded min-w-[80vw] h-[80vh] overflow-y-auto pb-4 px-2 lg:px-0">
              <PrivacyPolicy onClick={handleOpenTermsAndCondition} />
            </div>
          </div>
        )}
      </>

    );
};
