import * as signalR from '@microsoft/signalr';
import { GOUP_CONNECTION_METHOD, MESSAGE_HAS_CREATED_EVENT, NOTIFICATION_HAS_CREATED_EVENT } from './SignalRConstants';
import { reactLocalStorage } from "reactjs-localstorage";
import { catalogBaseUrl } from '../config/config';

export class SignalRService {

    private uId: string;

    private hubConnection: signalR.HubConnection;

    constructor() {
        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${catalogBaseUrl}/socket-hub`)
            .build();

        this.uId = reactLocalStorage.get("UserId")
    }

    public startConnection = () => {
        this.hubConnection
            .start()
            .then(() => {
                if (this.uId) {
                    this.joinMessageGroup(this.uId)
                    this.getNotification(this.uId)
                }
            })
            .catch((err: any) => {})
    }


    public messageListener = (callback: any) => {
        this.hubConnection.on(MESSAGE_HAS_CREATED_EVENT, (message: any) => {
            callback(message);
        });
    }

    public joinMessageGroup = (userId: any) => {
        this.hubConnection.invoke(GOUP_CONNECTION_METHOD, userId).then(() => {
        });
    }

    public getNotification = (userId: any) => {
        this.hubConnection.invoke(GOUP_CONNECTION_METHOD, userId).then(() => {
        });
    }

    public notificationListener = (callback: any) => {
        this.hubConnection.on(NOTIFICATION_HAS_CREATED_EVENT, (user: string, notification: string) => {
            callback(user, notification);
        });
    }

    public sendMessage = (user: string, message: string) => {
        this.hubConnection
            .invoke('SendMessage', user, message)
            .catch((err: any) => console.error(err));
    }
}
