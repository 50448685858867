import { useParams } from "react-router";
import { AppInput } from "../../common/AppInput";
import { AppButton } from "../../common/AppButton";
import { useEffect, useState } from "react";
import { ResellingProductBox } from "./ResellingProductBox";
import { AlertColor } from "@mui/material/Alert";
import { RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { AppSnackBar } from "../../common/AppSnackBar";
import { GetProductListRequest } from "../../../models/catalog/product/productsModels";
import { getVendorAction } from "../../../store/actions/auth/user/userActions";
import { GET } from "../../../httpService/service";
import { AppLoadMore } from "../../common/AppLoadMore";
import { resellingGetProductsAction } from "../../../store/actions/catalog/resellingProductActions";

export default function ResellingGetProductsByShop() {

  const [isGridSelect, setIsGridSelect] = useState(true);

  const { id } = useParams();

  const dispatch = useDispatch();

  const vendor = useSelector((state: RootState) => {
    return state.getVendorResponse.data;
  });

  useEffect(() => {
    dispatch(getVendorAction(id));
  }, [dispatch]);


  const searchQuery = useSelector((state: RootState) => {
    return state.resellingProductFilterResponse.data;
  });

  const [isLoadMoreButtonLoading, setIsLoadMoreButtonLoading] = useState(false);

  useEffect(() => {
    var pModel = new GetProductListRequest();
    pModel.limit = 50;
    pModel.offset = 0;
    pModel.searchQuery = searchQuery;
    pModel.sortBy = 0;
    pModel.minimumPrice = 0;
    pModel.maximumPrice = 0;
    pModel.mainCategoryId = 0;
    pModel.vendorId = id;
    dispatch(resellingGetProductsAction(pModel));
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  const [size, setSize] = useState(0);
  const [page, setPage] = useState(1);
  const [isDisable, setIsDisable] = useState(false);

  const [loadedProducts, setLoadedProducts] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadMoreButtonLoading(true);
        var payload = new GetProductListRequest();
        payload.limit = 50;
        payload.offset = 0;
        payload.searchQuery = searchQuery;
        payload.sortBy = 0;
        payload.minimumPrice = 0;
        payload.maximumPrice = 0;
        payload.mainCategoryId = 0;
        payload.vendorId = id;
        const response = await GET(`/api/Product/GetProductsPublic?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}&sortBy=${payload?.sortBy}&minimumPrice=${payload?.minimumPrice}&maximumPrice=${payload?.maximumPrice}&vendorId=${payload?.vendorId}&mainCategoryId=${payload?.mainCategoryId}&subCategoryLevelOneId=${payload?.subCategoryLevelOneId}`);
        setLoadedProducts(response?.data?.items);
        setSize(response?.data?.size)
        setIsLoading(false);
        setIsLoadMoreButtonLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [searchQuery]);


  const handleOnRowChange = async (payload: GetProductListRequest) => {
    setPage(page + 1)
    setIsDisable(payload.offset as number >= size)
    var pModel = new GetProductListRequest();
    pModel.limit = payload.limit;
    pModel.offset = payload.offset;
    pModel.searchQuery = searchQuery;
    pModel.sortBy = 0;
    pModel.minimumPrice = 0;
    pModel.maximumPrice = 0;
    pModel.mainCategoryId = 0;
    pModel.vendorId = id;
    try {
      setIsLoadMoreButtonLoading(true);
      setIsLoading(true);
      const response = await GET(`/api/Product/GetProductsPublic?limit=${pModel?.limit}&offset=${pModel?.offset}&searchQuery=${pModel?.searchQuery}&sortBy=${pModel?.sortBy}&minimumPrice=${pModel?.minimumPrice}&maximumPrice=${pModel?.maximumPrice}&vendorId=${pModel?.vendorId}&mainCategoryId=${pModel?.mainCategoryId}&subCategoryLevelOneId=${pModel?.subCategoryLevelOneId}`);
      setLoadedProducts(prevProducts => [...prevProducts, ...response.data.items]);
      setIsLoadMoreButtonLoading(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  const [sortById, setSortById] = useState(0);

  const handleSortByFilter = async (id: any) => {
    setSortById(id);
    var pModel = new GetProductListRequest();
    pModel.limit = 50;
    pModel.offset = 0;
    pModel.searchQuery = searchQuery;
    pModel.sortBy = id;
    pModel.minimumPrice = minPrice;
    pModel.maximumPrice = maxPrice;
    pModel.mainCategoryId = 0;
    pModel.vendorId = id;
    dispatch(resellingGetProductsAction(pModel));
  }


  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);

  const onMinMaxSubmit = async () => {

    if ((minPrice - maxPrice) >= 0) {
      setIsShowSnackBar(true);
      setSnackBarMessage("Min Price is grater than Max price.");
      setSnackBarMessageType("warning");
      setTimeout(() => {
        setIsShowSnackBar(false);
      }, 2000);
      return;
    }

    if (minPrice <= 0 || maxPrice <= 0) {
      setIsShowSnackBar(true);
      setSnackBarMessage("Min Price or Max price must be positive.");
      setSnackBarMessageType("warning");
      setTimeout(() => {
        setIsShowSnackBar(false);
      }, 2000);

      return;
    }

    if ((minPrice > 0 && maxPrice > 0 && (minPrice as number) <= (maxPrice as number)) && (maxPrice - minPrice) > 0) {
      var payload = new GetProductListRequest();
      payload.limit = 50;
      payload.offset = 0;
      payload.searchQuery = searchQuery;
      payload.sortBy = sortById;
      payload.minimumPrice = minPrice;
      payload.maximumPrice = maxPrice;
      payload.mainCategoryId = 0;
      payload.vendorId = id;
      const response = await GET(`/api/Product/GetProductsPublic?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}&sortBy=${payload?.sortBy}&minimumPrice=${payload?.minimumPrice}&maximumPrice=${payload?.maximumPrice}&vendorId=${payload?.vendorId}&mainCategoryId=${payload?.mainCategoryId}&subCategoryLevelOneId=${payload?.subCategoryLevelOneId}`);
      setLoadedProducts(response?.data?.items);
      setSize(response?.data?.size)
      setIsLoading(false);
    }

    else {
      setIsShowSnackBar(true);
      setSnackBarMessage("Min Max price range is invalid.");
      setSnackBarMessageType("warning");
      setTimeout(() => {
        setIsShowSnackBar(false);
      }, 2000);

      return;
    }
  }

  const [isActive, setIsActive] = useState(null);
  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

  const products = useSelector((state: RootState) => {
    return state.resellingGetProductsResponse.data;
  });

  const handleAfterAddToCart = () => {
    setIsShowSnackBar(true);
    setSnackBarMessage("Add to cart Successfully");
    setSnackBarMessageType("success");
    setTimeout(() => {
      setIsShowSnackBar(false);
    }, 2000);
  }

  const handleGridSelect = () => {
    setIsGridSelect(true)
  }

  const handleListSelect = () => {
    setIsGridSelect(false)
  }

  const handleProductFilter = async (subCatId: any) => {
    setIsActive(subCatId)
    var payload = new GetProductListRequest();
    payload.limit = 50;
    payload.offset = 0;
    payload.searchQuery = searchQuery;
    payload.sortBy = 0;
    payload.minimumPrice = minPrice;
    payload.maximumPrice = maxPrice;
    payload.mainCategoryId = 0;
    payload.subCategoryLevelOneId = subCatId;
    payload.vendorId = id;
    setIsLoadMoreButtonLoading(true);
    const response = await GET(`/api/Product/GetProductsPublic?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}&sortBy=${payload?.sortBy}&minimumPrice=${payload?.minimumPrice}&maximumPrice=${payload?.maximumPrice}&vendorId=${payload?.vendorId}&mainCategoryId=${payload?.mainCategoryId}&subCategoryLevelOneId=${payload?.subCategoryLevelOneId}`);
    setLoadedProducts(response?.data?.items);
    setSize(response?.data?.size)
    setIsLoading(false);
    setIsLoadMoreButtonLoading(false);
  }

  return (
    <div className="container mx-auto md:py-8 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28">
      {isShowSnackBar && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      {/* these is shop title  */}
      <h1 className="subTitle text-center">{vendor?.shopName} ({size})</h1>
      {/* these is category div section   */}
      <div className="w-full px-2 max-w-7xl mx-auto py-4 flex flex-wrap gap-3 items-center justify-center">
        {vendor?.mainCategories?.map((item: any, index: any) => {
          return (
            <div key={index} onClick={() => handleProductFilter(item.id)} className={`flex overflow-hidden ${isActive === item.id ? "bg-reselling text-white" : "bg-gray-100"}  md:w-28 w-16 md:h-20 h-16 cursor-pointer myBorder hover:bg-reselling hover:text-white flex-col p-1 items-center justify-center`}>
              {/* <img className="md:w-6 w-5 md:h-6 h-5 object-contain" src={item?.bannerPath} alt="Category info" /> */}
              <p className="text-xs lg:text-sm select-none md:font-medium text-center">{item?.name}</p>
            </div>
          )
        })}
      </div>

      {/* these is filter product main div start  */}
      <div className="">
        {/* these is min max button and search and filter button  */}
        <div className="flex flex-col lg:flex-row gap-y-4 items-center justify-between pb-4 px-0 md:px-4">
          {/* these is min and max button section  */}
          <div className="flex items-center gap-4 justify-center md:justify-start">
            {/* these is min max button  */}
            <div className="input-control flex items-center w-3/5 md:w-1/2 gap-4">
              <AppInput className="border-2 border-reselling cursor-pointer py-1 text-sm pl-2 rounded w-full" value={minPrice !== 0 ? minPrice : ""} onChange={(value: any) => setMinPrice(value)} placeholder="Minimum price" type={'number'} />
              <AppInput className="border-2 border-reselling cursor-pointer py-1 text-sm pl-2 rounded w-full" value={maxPrice !== 0 ? maxPrice : ""} onChange={(value: any) => setMaxPrice(value)} placeholder="Maximum price" type={'number'} />
            </div>
            {(minPrice !== 0 && maxPrice !== 0) && (
              <AppButton
                onClick={onMinMaxSubmit}
                text={"Submit"}
                className="myBtn"
              />
            )}
          </div>
          {/* these is list view and search section  */}
          {/* <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              <p className="text">Sort by:</p>
              <AppSelect
                data={shortByFilter}
                defaultValue="Select Price Filter"
                value={sortById}
                onChange={handleSortByFilter}
              />
            </div>

            <div className="flex items-center gap-4">
              <div className="list-select-part-icon" onClick={handleGridSelect}>
                <RiLayoutGridFill className="text-reselling text-3xl font-bold" />
              </div>

              <div className="list-select-part-icon" onClick={handleListSelect}>
                <FaListUl className="text-reselling text-3xl font-bold" />
              </div>

            </div>
          </div> */}
        </div>

        {/* these is products list div  */}
        {loadedProducts.length ? (
          <div className="">
            {/* ---------when grid icon click open this div-------  */}
            {isGridSelect ? (
              <>
                <div className="productContainer">
                  {loadedProducts &&
                    (
                      loadedProducts?.map((x: any) =>
                        <ResellingProductBox key={x.id}
                          id={x.id}
                          name={x.name}
                          shortDescription={x.shortDescription}
                          bannerPath={x.bannerPath}
                          discount={x.discount}
                          isBestSeller={x.isBestSeller}
                          price={x.price}
                          oldPrice={x.oldPrice}
                          rating={x.rating}
                          totalSold={x.totalSold}
                          isDisountProduct={x.isDisountProduct}
                          discountText={x.discountText}
                          afterAddToCart={handleAfterAddToCart}
                          totalReview={x.totalReview}
                          totalView={x.totalView}
                        />
                      )
                    )}
                </div>
                <div className='flex items-center justify-center pt-6'>
                  <AppLoadMore text="Show more Products" isSubmitting={isLoadMoreButtonLoading} className="outlineLoadMoreBtn" isDisable={isDisable} page={page} onRowChange={handleOnRowChange} size={size} />
                </div>
              </>


            ) : (
              <></>
            )}
          </div>
        ) : (
          <p className="py-16 text-xl md:text-3xl text-gray-600 text-center">No product found</p>
        )}

      </div>
    </div>
  );
}
