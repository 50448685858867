import { getUnionListService, getUnionListByAreaIdService, getUnionAuthListService } from "../../../httpService/importantNumbers/unionServices";
import { GET_UNION_LIST, GET_UNION_LIST_BY_AREA } from "../../constants/importantNumbers/unionConstants";

const getUnionListAction = (p: any) => async (dispatch: any) => {
	try {
		const data = getUnionListService(p);
		const payload = (await data).data;
		dispatch({ type: GET_UNION_LIST, payload: payload });
	} catch (error:any) { }
};

const getUnionListByAreaId = (p: any) => async (dispatch: any) => {
	try {
		const data = getUnionListByAreaIdService(p);
		const payload = (await data).data;
		dispatch({ type: GET_UNION_LIST_BY_AREA, payload: payload });
	} catch (error:any) { }
};

const getUnionAuthListAction = () => async (dispatch: any) => {
	try {
		const data = getUnionAuthListService();
		const payload = (await data).data;
		dispatch({ type: GET_UNION_LIST, payload: payload });
	} catch (error:any) { }
};

export { getUnionListAction, getUnionListByAreaId, getUnionAuthListAction };
