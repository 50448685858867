import { getAffiliateCartItemsAdminService, getAffiliateCartItemsService, getAffiliateCartListAdminService, getAffiliateFavouriteItemsService, getAffiliateTotalShippingChargeService, increaseDecreaseAffiliateCartItemService, isAlreadyAffiliateFavouriteService, removeSingleOrListAffiliateCartitemService } from "../../../httpService/shoppingCart/affiliateCartServices";
import { GetCartListAdminModel, IncreseDecreseCartItemRequest } from "../../../models/shoppingCart/resellingCartModels";
import { GET_AFFILIATE_ALL_CART_ITEMS, GET_AFFILIATE_ALL_FAVOURITE_ITEMS, GET_AFFILIATE_CART_ITEMS_ADMIN_LIST, GET_AFFILIATE_CART_LIST_ADMIN_LIST, GET_AFFILIATE_IS_ALREADY_FAVOURITE, GET_AFFILIATE_TOTAL_CART_ITEM, GET_AFFILIATE_TOTAL_SHIPPING_CHARGE, INCRESE_DECRESE_AFFILIATE_CART_ITEM, REMOVE_SINGLE_OR_LIST_AFFILIATE_CART_ITEM } from "../../constants/shoppingCart/affiliateShoppingCartConstants";

export const getAffiliateTotalShoppingCartItemAction = (count?: any) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_AFFILIATE_TOTAL_CART_ITEM, payload: count });
    }
    catch (error: any) { }
}

export const getAffiliateCartItemsAction = () => async (dispatch: any) => {
    try {
        const data = await getAffiliateCartItemsService();
        dispatch({ type: GET_AFFILIATE_ALL_CART_ITEMS, payload: data.data });
    }
    catch (error: any) { }
}

export const getAffiliateFavouriteItemListAction = (pload: any) => async (dispatch: any) => {
    try {
        const data = await getAffiliateFavouriteItemsService(pload);
        dispatch({ type: GET_AFFILIATE_ALL_FAVOURITE_ITEMS, payload: data.data });
    }
    catch (error: any) { }
}

export const getIsAlreadyAffiliateFavouriteOrNotAction = (id: any) => async (dispatch: any) => {
    try {
        const data = await isAlreadyAffiliateFavouriteService(id);
        dispatch({ type: GET_AFFILIATE_IS_ALREADY_FAVOURITE, payload: data.data });
    }
    catch (error: any) { }
}

export const increaseDecreaseAffiliateCartItemAction = (payload: IncreseDecreseCartItemRequest) => async (dispatch: any) => {
    try {
        const data = await increaseDecreaseAffiliateCartItemService(payload);
        dispatch({ type: INCRESE_DECRESE_AFFILIATE_CART_ITEM, payload: data.data });
    }
    catch (error: any) { }
}


export const removeSingleOrListAffiliateCartitemAction = (payload: IncreseDecreseCartItemRequest) => async (dispatch: any) => {
    try {
        const data = await removeSingleOrListAffiliateCartitemService(payload);
        dispatch({ type: REMOVE_SINGLE_OR_LIST_AFFILIATE_CART_ITEM, payload: data.data });
    }
    catch (error: any) { }
}

export const getAffiliateCartListAdminAction = (payload?: GetCartListAdminModel) => async (dispatch: any) => {
    var data = getAffiliateCartListAdminService(payload);
    const pload = (await data).data;
    dispatch({ type: GET_AFFILIATE_CART_LIST_ADMIN_LIST, payload: pload });
};


export const getAffiliateCartItemsAdminAction = (userId: any) => async (dispatch: any) => {
    var data = getAffiliateCartItemsAdminService(userId);
    const pload = (await data).data;
    dispatch({ type: GET_AFFILIATE_CART_ITEMS_ADMIN_LIST, payload: pload });
};


export const getAffiliateTotalShippingChargeAction = (inSideDhaka: boolean) => async (dispatch: any) => {
    var data = getAffiliateTotalShippingChargeService(inSideDhaka);
    const pload = (await data).data;
    dispatch({ type: GET_AFFILIATE_TOTAL_SHIPPING_CHARGE, payload: pload });
};



