import { GET_HOSPITALS_BY_AUTH, GET_HOSPITAL_NUMBER } from "../../../constants/importantNumbers/hospital/hospitalConstant";



export function getHospitalReducer(state = { data: [] }, action: any) {
	switch (action.type) {
		case GET_HOSPITAL_NUMBER:
			return { loading: false, data: action.payload };		
		case GET_HOSPITALS_BY_AUTH:
			return { loading: false, data: action.payload };
		default:
			return state;
	}
}