import { useEffect, useMemo, useState } from "react";
import { BechteChaiProductBox } from "./BechteChaiProductBox";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { bechteChaiGetFavouriteItemListAction } from "../../../store/actions/bechteChai/bechteChaiProductActions";
import { BechteChaiFavouriteResponse } from "../../../models/bechteChai/product/bechteChaiProductModels";

export default function BechteChaiGetFavouriteItems() {

  const dispatch = useDispatch();

  const favouriteItemsSignIn = useSelector((state: RootState) => {
    return state.bechteChaiGetFavouriteItemResponse.data;
  });

  const favItemsMemo = useMemo(() => {
    return favouriteItemsSignIn as BechteChaiFavouriteResponse[];
  }, [favouriteItemsSignIn]);


  useEffect(() => {
    dispatch(bechteChaiGetFavouriteItemListAction({}));
  }, [dispatch]);

  return (
    <div className="container mx-auto md:pt-6 lg:pb-12 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28">
      <h3 className="title pb-4 text-center md:pb-8 px-4 md:px-0">Favorites</h3>
      <div className="productContainer">
        {favItemsMemo &&
          favItemsMemo?.map((x: any, index: any) => {
            return (
              <BechteChaiProductBox key={index}
                id={x.id}
                name={x.name}
                shortDescription={x.shortDescription}
                bannerPath={x.bannerPath}
                isBestSeller={x.isBestSeller}
                price={x.price}
                rating={x.rating}
                totalSold={x.totalSold}
                totalReview={x.totalReview}
                totalView={x.totalView}
                createdAt={x.createdAt}
              />
            );
          })}
      </div>
    </div>
  );
}
