import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'

interface IScrollBarProps {
  height?: any;
  children?: React.ReactNode;
  width?: any;
}

export const AppScrollBar: React.FC<IScrollBarProps> = ({ height, children, width }) => {
  return (
    <PerfectScrollbar style={{ height: height, width: width }}>
      {children}
    </PerfectScrollbar>
  );
}