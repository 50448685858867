export class BillingAddress {
  userId?: any;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNo_First?: string;
  phoneNo_Second?: string;
  address_First?: string;
  address_Second?: string;
  companyName?: string;
  postCode?: string;
  divisionId?: number;
  districtId?: number;
  areaId?: number;
}

export class ShippingAddress {
  userId?: any;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNo_First?: string;
  phoneNo_Second?: string;
  address_First?: string;
  address_Second?: string;
  companyName?: string;
  postCode?: string;
  divisionId?: number;
  districtId?: number;
  areaId?: number;
}

export class BillingShippingSubmitOrderAddress {
  
  billingAddressFirstName?: string;
  billingAddressLastName?: string;
  billingAddressEmail?: string;
  billingAddressPhoneNoFirst?: string;
  billingAddressPhoneNoSecond?: string;
  billingAddressAddressFirst?: string;
  billingAddressAddressSecond?: string;
  billingAddressCompanyName?: string;
  billingAddressUserId?: number;
  billingAddressAreaId?: number;
  billingAddressWardId?: number;
  billingAddressPostCode?: string;
  billingAddressOrderId?: number;

  
  
  shippingAddressFirstName?: string;
  shippingAddressLastName?: string;
  shippingAddressEmail?: string;
  shippingAddressShippingId?: number;
  shippingAddressAreaId?: number;
  shippingAddressPhoneNoFirst?: string;
  shippingAddressPhoneNoSecond?: string;
  shippingAddressCompanyName?: string;
  shippingAddressAddressFirst?: string;
  shippingAddressUserId?: number;
  shippingAddressAddressSecond?: string;
  shippingAddressPostCode?: string;
  shippingAddressOrderId?: number;
}
