import { districtListService, getDistrictListByDivisionIdService } from "../../../../httpService/area/districtServices";
import { PaginationPayloadModel } from "../../../../models/common/paginationModels";
import { GET_DISTRICT_LIST } from "../../../constants/auth/address/AddressConstants";

export const districtList = (payload?: PaginationPayloadModel) => async (dispatch : any) => {
        const data = districtListService(payload)
        const pload = (await data).data;
        dispatch({ type: GET_DISTRICT_LIST, payload: pload });
}
export const districtListByDivisionId = (payload?:any) => async (dispatch : any) => {
        const data = getDistrictListByDivisionIdService(payload)
        const pload = (await data).data;
        dispatch({ type: GET_DISTRICT_LIST, payload: pload });
}
