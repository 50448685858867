import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useNavigate } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import { HiArrowLeft } from "react-icons/hi";

export const AffiliateCategoryFilterGetSubCategories = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const exploreLevelTwoState = useSelector((state: RootState) => {
    return state.exploreLevelTwoStateResponse.exploreLevelTwo;
  });

  const exploreLevelTwoData = useSelector((state: RootState) => {
    return state.exploreLevelTwoDataResponse.exploreLevelTwoData;
  });

  const goToListPage = (id: any) => {
    dispatch({ type: "CLOSE-EXPLORE-LEVEL-THREE" });
    dispatch({ type: "OPEN-EXPLORE-LEVEL-TWO" });
    dispatch({ type: "CLOSE-EXPLORE-LEVEL-ONE" });
    dispatch({ type: "CLOSE-EXPLORE" });
    navigate(`/affiliate/filterProductList/${0}/${id}`);
  };

  const openExploreLevelOne = () => {
    dispatch({ type: "OPEN-EXPLORE-LEVEL-ONE" });
    dispatch({ type: "CLOSE-EXPLORE-LEVEL-TWO" });
  };

  if (!exploreLevelTwoState) return null;
  else
    return (
      <div className="">
        <div onClick={openExploreLevelOne} className="text-lg md:text-xl font-medium text-textBlack flex gap-x-3 items-center cursor-pointer px-6 md:px-10 pb-2 md:pb-4">
          <HiArrowLeft /> <span>Main menu</span>
        </div>

        {exploreLevelTwoData?.filter((_: any) => _.isPublished == true)?.map((item: any) => (
          <div
            className="flex items-center justify-between hover:bg-green-100 py-2.5 px-6 lg:pl-8 lg:pr-6 2xl:px-10 cursor-pointer"
            key={item.id}
            onClick={() => goToListPage(item.id)}
          >
            <div className="flex items-center gap-x-2 md:gap-x-4">
              {/* <img className="w-6 md:w-9 h-6 md:h-9 object-contain" src={item.bannerPath} alt="icon" /> */}
              <h2 className="text">{item.name}  [{item.totalProduct}]</h2>
            </div>
            <BsChevronRight className="text-xl md:text-2xl font-extrabold text-affiliate" />
          </div>
        ))}
      </div>
    );
};
