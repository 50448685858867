import { AlertColor, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { sSAddSubCatagoryService } from "../../../../../httpService/superShops/ssSubCategoryServices";
import { sSGetSubCategoryListAction } from "../../../../../store/actions/superShop/sSSubCategoryActions";
import { AppButton } from "../../../../common/AppButton";
import { AppFileInput } from "../../../../common/AppFileInput";
import { AppInput } from "../../../../common/AppInput";
import { AppSnackBar } from "../../../../common/AppSnackBar";
import { AppTextArea } from "../../../../common/AppTextArea";
import { AppSelect } from "../../../../common/AppSelect";
import { IdNameResponseModel } from "../../../../../models/common/IdNameModel";
import { RootState } from "../../../../../store";
import { sSGetMainCategoryListAction } from "../../../../../store/actions/superShop/sSMainCategoryActions";
import { SSAddSubCatagory } from "../../../../../models/superShopp/catalog/sSCategory/ssSubCategoryModel";
import { PaginationPayloadModel } from "../../../../../models/common/paginationModels";

export default function AdminSSCreateSubCategory() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);


  const [name, setName] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");

  const [description, setDescription] = useState("");
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState("");

  const [isPublished, setIsPublished] = useState(false);

  const [mainCategoryId, setMainCategoryId] = useState(0);
  const [mainCategoryError, setMainCategoryError] = useState("");


  const [bannerId, setBanner] = useState(0);
  const [bannerError, setBannerError] = useState("");

  const [tempPath, setTempPath] = useState("");

  const catagoryList = useSelector((state: RootState) => {
    return state.sSGetMainCategoryListResponse.data;
  });

  var idNameModels: IdNameResponseModel[] = [];
  catagoryList.items?.map((x: { id: number; name: string | undefined }) => {
    var temp = new IdNameResponseModel();
    temp.id = x.id;
    temp.name = x.name;
    idNameModels.push(temp);
  });

  useEffect(() => {
    const model = new PaginationPayloadModel();
    model.limit = 10000;
    model.offset = 0;
    dispatch(sSGetMainCategoryListAction(model));
  }, []);

  const handleMainCategoryIdChange = (event: any) => {
    setMainCategoryId(event);
    setMainCategoryError("")
  };

  const handleNameChange = (event: any) => {
    setName(event);
    setNameErrorMessage("");
  };

  const handleDescriptionChange = (event: any) => {
    setDescription(event);
    setDescriptionErrorMessage("");
  };

  const handleIsPublishedChange = (event: any) => [setIsPublished(event)];


  const handleBanner = (id: any, path: any) => {
    setBanner(id);
    setTempPath(path);
    setBannerError("");
  };

  const deleteBanner = () => {
    setBanner(0);
    setTempPath("");
  };

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    setSnackBarOn(false);

    var model = new SSAddSubCatagory();
    model.name = name;
    model.description = description;
    model.isPublished = isPublished;
    model.bannerId = bannerId;
    model.sSMainCategoryId = mainCategoryId;

    setIsButtonLoading(true);

    sSAddSubCatagoryService(model)
      .then(() => {
        setSnackBarOn(true);
        setsnackBarMessage("Category  Added Successfully.");
        setSnackBarMessageType("success");
        setIsButtonLoading(false);
        setTimeout(() => {
          navigate("/admin/super-shop/sub-category-list");
        }, 300);
        dispatch(sSGetSubCategoryListAction());
      })
      .catch((error: any) => {
        setIsButtonLoading(false);
        setNameErrorMessage(error.response.data.errors?.Name?.[0]);
        setDescriptionErrorMessage(error.response.data.errors?.Description?.[0]);
        setBannerError(error.response.data.errors?.BannerId?.[0]);
        setMainCategoryError(error.response.data.errors.SSMainCategoryId[0]);
      });
  };

  return (
    <div>
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      <div className="pl-4 flex items-center justify-between w-3/5 pb-6">
        <AppButton
          onClick={() => navigate("/admin/super-shop/sub-category-list")}
          text="Back"
        />
        <h2 className="text-center title">Create Sub Category</h2>
      </div>

      <form className="max-w-xl mx-auto myBorder py-4 px-5">
        <AppInput
          label="Category name"
          className="myInput"
          type="text"
          placeholder="Enter Category Name"
          onChange={handleNameChange}
          errorMessage={nameErrorMessage}
        />
        <AppTextArea
          label="Description"
          placeholder="Enter Description"
          onChange={handleDescriptionChange}
          errorMessage={descriptionErrorMessage}
        />

        <div className="flex justify-between items-center py-1">

          <div className="flex items-center gap-x-3">
            <label htmlFor="isPublished" className="">
              Published
            </label>
            <input
              className="w-5 h-5 cursor-pointer"
              type="checkbox"
              name="isPublished"
              onChange={() => handleIsPublishedChange(!isPublished)}
            />
          </div>

          <div className='w-full lg:w-1/2'>
            <label className="text-sm lg:text-base" placeholder="Dhaka">Select Main Category</label>
            <AppSelect
              value={mainCategoryId}
              data={idNameModels}
              defaultValue="Select Main Category"
              errorMessage={mainCategoryError}
              onChange={handleMainCategoryIdChange}
            />
          </div>
        </div>

        <div className="flex items-center gap-3 py-2 justify-between">
          <div className="w-1/2 flex items-center gap-1">
            <label className="">Photo</label>
            {!bannerId && (
              <AppFileInput onSave={handleBanner} errorMessage={bannerError} />
            )}
            <br />
            {bannerId !== 0 && (
              <div className="flex items-center gap-x-2">
                <img
                  className=""
                  src={`${tempPath}`}
                  alt="upload"
                />
                <IconButton onClick={deleteBanner}>
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
          </div>

        </div>
        <div className="flex justify-end">
          <AppButton onClick={handleSubmit} isSubmitting={isButtonLoading} variant="contained" text="Submit" />
        </div>
      </form>
    </div>
  );
}
