import { useEffect } from "react";
import { RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import {
	getVendorDashboardAction,
} from "../../../store/actions/dashboard/dashboardActions";
import { reactLocalStorage } from "reactjs-localstorage";
import { AppModal } from "../../common/AppModal";
import { Link, useNavigate } from "react-router-dom";
import { IsShippingChargeAvailableShipping } from "../../../store/actions/shippingCharge/shippingCharge";
import { VendorDashboardModel } from "../../../models/dashboard/dashboardModel";
import { AppSelect } from "../../common/AppSelect";
import { getNoticeAction } from "../../../store/actions/notice/noticeAction";
import { markNoticeSeenService } from "../../../httpService/notice/noticeServices";
import { getUserProfileAction } from "../../../store/actions/auth/user/userActions";

export default function VendorDashboard() {

	const dispatch = useDispatch();
	const navigate = useNavigate();

	let token = reactLocalStorage.getObject("SignIn");

	const dashboard = useSelector((state: RootState) => {
		return state.getVendorDashboardResponse.data as VendorDashboardModel;
	});

	const shippingChargeIsAvailable = useSelector((state: RootState) => {
		return state.IsShippingChargeAvailableShippingResponse.data;
	});

	useEffect(() => {
		dispatch(getVendorDashboardAction());
		dispatch(IsShippingChargeAvailableShipping());
	}, [dispatch]);

	const notice = useSelector((state: RootState) => {
		return state.getNoticeResponse.data;
	});

	useEffect(() => {
		dispatch(getNoticeAction());
	}, [dispatch]);

	const closeNoticeModel = (id: any) => {
		markNoticeSeenService(id)
			.then((response) => {
				navigate("/", { replace: true });
				dispatch(getNoticeAction());
			})
			.catch(() => { });
	}

	const vendorAffiliatCards = [
		{
			text: 'Total Orders',
			value: dashboard?.affiliateTotalOrders,
			link: "/admin/sales/order-list",
			image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Frame_35024_b17srq.png",
			bgColor: 'bg-[#E2E7FF]',
			borderColor: 'border-b-blue-700'
		},
		{
			text: 'Pending Orders',
			value: dashboard?.affiliateTotalPendingOrders,
			link: "/admin/sales/order-list?orderStatus=1",
			image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/ic_twotone-pending-actions_sj45ij.png",
			bgColor: 'bg-[#E2E7FF]',
			borderColor: 'border-b-cyan-500'
		},
		{
			text: 'Orders Shipped',
			value: dashboard?.affiliateTotalShippedOrders,
			link: "/admin/sales/order-list?shippingStatus=2",
			image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/flat-color-icons_shipped_g8d8uu.png",
			bgColor: 'bg-[#E2E7FF]',
			borderColor: 'border-b-slate-300'
		},
		{
			text: 'Confirmed Orders',
			value: dashboard?.affiliateTotalConfirmOrders,
			link: "/admin/sales/order-list?orderStatus=5",
			image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/game-icons_confirmed_fnl0k0.png",
			bgColor: 'bg-[#E2E7FF]',
			borderColor: 'border-b-sky-200'
		},
		{
			text: 'Completed Orders',
			value: dashboard?.affiliateTotalCompletedOrders,
			link: "/admin/sales/order-list?orderStatus=4",
			image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Done_idyekg.png",
			bgColor: 'bg-[#E2E7FF]',
			borderColor: 'border-b-[#37FF37]'
		},
		{
			text: 'Canceled Orders',
			value: dashboard?.affiliateTotalCancelledOrders,
			link: "/admin/sales/order-list?orderStatus=2",
			image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/pajamas_canceled-circle_txjeuw.png",
			bgColor: 'bg-[#E2E7FF]',
			borderColor: 'border-b-rose-600'
		},
		{
			text: 'Orders Returned',
			value: dashboard?.affiliateTotalReturnOrders,
			link: "/admin/sales/order-list?orderStatus=3",
			image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/ic_baseline-assignment-return_dfq3my.png",
			bgColor: 'bg-[#E2E7FF]',
			borderColor: 'border-b-amber-300'
		},

		{
			text: 'Product Comments',
			value: dashboard?.totalProductReview,
			link: "/admin/catalog/productreviews",
			image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/rating_ozpvir.png",
			bgColor: 'bg-[#E2E7FF]',
			borderColor: 'border-b-royalBlue'
		},
	];

	const vendorProductCards = [
		{
			text: 'Total Products',
			value: dashboard?.totalProducts,
			link: "/admin/catalog/products?filterBy=0",
			image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Frame_35069_nyrvbi.png",
			bgColor: 'bg-[#DCFFDD]',
			borderColor: 'border-b-blue-300'
		},
		{
			text: 'Active Products',
			value: dashboard?.totalActiveProducts,
			link: "/admin/catalog/products?filterBy=1",
			image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Frame_35070_nysnxt.png",
			bgColor: 'bg-[#DCFFDD]',
			borderColor: 'border-b-green-400'
		},
		{
			text: 'Inactive Products',
			value: dashboard?.totalInActiveProducts,
			link: "/admin/catalog/products?filterBy=2",
			image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Done-1_zynuru.png",
			bgColor: 'bg-[#DCFFDD]',
			borderColor: 'border-b-yellow-300'
		},
		{
			text: 'Stock Out Products',
			value: dashboard?.totalOutOfStockProducts,
			link: "/admin/catalog/products?filterBy=3",
			image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Frame_21_idesqh.png",
			bgColor: 'bg-[#DCFFDD]',
			borderColor: 'border-b-rose-500'
		},
	];

	const vendorResellingCards = [
		{
			text: 'Total Orders',
			value: dashboard?.resellingTotalOrders,
			link: "/admin/sales/order-list",
			image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Frame_35024_b17srq.png",
			bgColor: 'bg-[#E2E7FF]',
			borderColor: 'border-b-blue-700'
		},
		{
			text: 'Pending Orders',
			value: dashboard?.resellingTotalPendingOrders,
			link: "/admin/sales/order-list?orderStatus=1",
			image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/ic_twotone-pending-actions_sj45ij.png",
			bgColor: 'bg-[#E2E7FF]',
			borderColor: 'border-b-cyan-500'
		},
		{
			text: 'Orders Shipped',
			value: dashboard?.resellingTotalShippedOrders,
			link: "/admin/sales/order-list?shippingStatus=2",
			image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/flat-color-icons_shipped_g8d8uu.png",
			bgColor: 'bg-[#E2E7FF]',
			borderColor: 'border-b-slate-300'
		},
		{
			text: 'Confirmed Orders',
			value: dashboard?.resellingTotalConfirmOrders,
			link: "/admin/sales/order-list?orderStatus=5",
			image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/game-icons_confirmed_fnl0k0.png",
			bgColor: 'bg-[#E2E7FF]',
			borderColor: 'border-b-sky-200'
		},
		{
			text: 'Completed Orders',
			value: dashboard?.resellingTotalCompletedOrders,
			link: "/admin/sales/order-list?orderStatus=4",
			image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Done_idyekg.png",
			bgColor: 'bg-[#E2E7FF]',
			borderColor: 'border-b-[#37FF37]'
		},
		{
			text: 'Canceled Orders',
			value: dashboard?.resellingTotalCancelledOrders,
			link: "/admin/sales/order-list?orderStatus=2",
			image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/pajamas_canceled-circle_txjeuw.png",
			bgColor: 'bg-[#E2E7FF]',
			borderColor: 'border-b-rose-600'
		},
		{
			text: 'Orders Returned',
			value: dashboard?.resellingTotalReturnOrders,
			link: "/admin/sales/order-list?orderStatus=3",
			image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/ic_baseline-assignment-return_dfq3my.png",
			bgColor: 'bg-[#E2E7FF]',
			borderColor: 'border-b-amber-300'
		},
	]
	
	const userProfile = useSelector((state: RootState) => {
		return state.getUserProfileResponse.data;
	});

	useEffect(() => {
		dispatch(getUserProfileAction());
	}, [dispatch]);

	return (
		<div className="container mx-auto px-0 lg:px-12">

			{userProfile?.isBlocked && <h2 className="text-xl text-red md:text-3xl font-semibold">Your Account has been Suspended. Please contact with Admin.</h2>}

			{!shippingChargeIsAvailable && (token && JSON.stringify(token) !== JSON.stringify({})) ? (
				<AppModal>
					<div className="py-12 px-4 md:px-20 flex flex-col-reverse md:flex-row items-center justify-between gap-4">
						<div className="flex flex-col items-center md:items-start gap-3">
							<h2 className="text-xl text-royalBlue md:text-3xl md:max-w-md font-semibold">Please Set Your Shipping Charge First</h2>
							<div className="myBtn w-fit py-1.5 bg-royalBlue border-royalBlue" onClick={() => { }} >
								<Link to="/admin/sales/shippingCharge">
									Shipping Charge
								</Link>
							</div>
						</div>
						{/* <img className="w-60 md:w-72 h-40 md:h-48 object-contain" src="https://res.cloudinary.com/ddqenwff0/image/upload/v1687276368/Group_cmdbqn.png" alt="" /> */}
					</div>
				</AppModal>
			) : (
				(notice && JSON.stringify(notice) !== JSON.stringify({})) && (
					<AppModal>
						<div className="py-12 px-4 md:px-20 flex flex-col-reverse md:flex-row items-center justify-between gap-4">
							<div className="flex flex-col items-center md:items-start gap-3">
								<p className="max-w-xl">
									<div dangerouslySetInnerHTML={{ __html: notice.description }} />
								</p>
								<div className="myBtn w-fit py-1.5 bg-royalBlue border-royalBlue" onClick={() => closeNoticeModel(notice.id)} >
									Ok
								</div>
							</div>
						</div>
					</AppModal>
				))}
			<div className="flex items-center justify-between">
				<h2 className="text-base lg:text-xl font-semibold">Dashboard </h2>
				<div className="flex flex-col w-1/2 lg:w-1/5">
					<h2 className="subTitle">Short By</h2>
					<AppSelect defaultValue="All Time" data={[]} />
				</div>
			</div>

			{/* this div for products  */}
			<div className="border-2 2xl:border-4 border-primary rounded p-4 lg:p-5 2xl:p-6 my-4 lg:my-5 2xl:my-6">
				<h2 className="title text-center">Products</h2>
				<div className="grid grid-cols-2 gap-6  pt-4 lg:grid-cols-4">
					{vendorProductCards.map((dashboardItem: any) => (
						<Link
							className="shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] shadow-blue-500/20 drop-shadow-xl hover:shadow-xl"
							key={dashboardItem?.text}
							to={dashboardItem?.link}
						>
							<div
								className={`flex flex-col items-center rounded-sm border-b-2 2xl:border-b-4 p-2 lg:p-4 border-primary`}
							>
								<div className="2xl:pb-2">
									<div className="mb-2 2xl:mb-4 flex items-center justify-center">
										{/* <img
											className="h-5 w-5 object-contain  lg:h-9 lg:w-9 "
											src={dashboardItem?.image}
											alt={dashboardItem?.text}
										/> */}
									</div>
									<h3 className="subTitle">{dashboardItem?.text}</h3>
								</div>
								<h3 className="text-base font-semibold lg:text-2xl">
									{dashboardItem?.value} 
								</h3>
							</div>
						</Link>
					))}
				</div>
			</div>


			{/* this div is for reselling  */}
			<div className="border-2 2xl:border-4 border-reselling rounded p-4 lg:p-5 2xl:p-6 my-4 lg:my-5 2xl:my-6">
				<h2 className="title text-center">Reselling</h2>
				<div className="grid grid-cols-2 gap-6  pt-4 lg:grid-cols-4">
					{vendorResellingCards.map((dashboardItem) => (
						<Link
							className="shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] shadow-blue-500/20 drop-shadow-xl hover:shadow-xl"
							key={dashboardItem?.text}
							to={dashboardItem?.link}
						>
							<div
								className={`flex flex-col items-center rounded-sm border-b-2 2xl:border-b-4 p-2 lg:p-4 border-reselling`}
							>
								<div className="2xl:pb-2">
									<div className="mb-2 2xl:mb-4 flex items-center justify-center">
										{/* <img
											className="h-5 w-5 object-contain  lg:h-9 lg:w-9 "
											src={dashboardItem?.image}
											alt={dashboardItem?.text}
										/> */}
									</div>
									<h3 className="subTitle">{dashboardItem?.text}</h3>
								</div>
								<h3 className="text-base font-semibold lg:text-2xl">
									{dashboardItem?.value}
								</h3>
							</div>
						</Link>
					))}
				</div>
			</div>

			{/* this div is for affiliate  */}
			<div className="border-2 2xl:border-4 border-affiliate rounded p-4 lg:p-5 2xl:p-6 my-4 lg:my-5 2xl:my-6">
				<h2 className="title text-center">Affiliate</h2>
				<div className="grid grid-cols-2 gap-6   pt-4 lg:grid-cols-4">
					{vendorAffiliatCards.map((dashboardItem) => (
						<Link
							className="shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] shadow-blue-500/20 drop-shadow-xl hover:shadow-xl"
							key={dashboardItem?.text}
							to={dashboardItem?.link}
						>
							<div
								className={`flex flex-col items-center rounded-sm border-b-2 2xl:border-b-4 p-2 lg:p-4 border-affiliate`}
							>
								<div className="2xl:pb-2">
									<div className="mb-2 2xl:mb-4 flex items-center justify-center">
										{/* <img
											className="h-5 w-5 object-contain  lg:h-9 lg:w-9 "
											src={dashboardItem?.image}
											alt={dashboardItem?.text}
										/> */}
									</div>
									<h3 className="subTitle">{dashboardItem?.text}</h3>
								</div>
								<h3 className="text-base font-semibold lg:text-2xl">
									{dashboardItem?.value}
								</h3>
							</div>
						</Link>
					))}
				</div>
			</div>

		</div>
	);
}
