import { Route, Routes } from 'react-router-dom'
import ResellingHomeComponent from '../../components/public/reselling/ResellingHomeComponent'
import ResellingGetOrderList from '../../components/public/reselling/ResellingGetOrderList'
import ResellingGetOrderDetails from '../../components/public/reselling/ResellingGetOrderDetails'
import ResellingGetFavouriteItems from '../../components/public/reselling/ResellingGetFavouriteItems'
import ResellingGetMyTeam from '../../components/public/reselling/ResellingGetMyTeam'
import ResellingGetCartItems from '../../components/public/reselling/ResellingGetCartItems'
import ResellingSubmitOrder from '../../components/public/reselling/ResellingSubmitOrder'
import ResellingGetFilterProducs from '../../components/public/reselling/ResellingGetFilterProducs'
import ResellingGetProductsByShop from '../../components/public/reselling/ResellingGetProductsByShop'
import ResellingGetProductById from '../../components/public/reselling/ResellingGetProductById'
import ReturnPolicy from '../../components/public/common/ReturnPolicy'

export default function ResellingPlatfromRoute() {
    return (
        <Routes>
            <Route index element={<ResellingHomeComponent />} />
            <Route path="/myOrder" element={<ResellingGetOrderList />} />
            <Route path="/myOrder/:id" element={<ResellingGetOrderDetails />} />

            <Route path="/favourites" element={<ResellingGetFavouriteItems />} />
            <Route path="/myTeam" element={<ResellingGetMyTeam />} />
            <Route path="/returnPolicy" element={<ReturnPolicy />} />

            <Route path="/shoppingcart" element={<ResellingGetCartItems />} />
            <Route path="/submitBillingAndShippingAddress" element={<ResellingSubmitOrder />} />

            <Route path="/filterProductList/:mainCatId/:subCatId" element={<ResellingGetFilterProducs />} />
            <Route path="/vendorProducts/:id" element={<ResellingGetProductsByShop />} />
            <Route path="/productDetails/:id" element={<ResellingGetProductById />} />
        </Routes>
    )
}