import { GET_MAIN_CATEGORY_LIST, GET_MAIN_CATEGORY_LIST_BY_ID, GET_SUB_CATEGORY__ONE_LIST, GET_SUB_CATEGORY__TWO_LIST } from "../../constants/catalog/mainCategory";

const inititalState = {
    data: [],
    dataById: []
}

export function mainCategoryByListReducer(state = inititalState, action: any) {
    switch (action.type) {
        case GET_MAIN_CATEGORY_LIST:
            return { ...state, loading: false, data: action.payload };
        case GET_MAIN_CATEGORY_LIST_BY_ID:
            return { ...state, loading: false, dataById: action.payload };
        default:
            return state;
    }
}

export function subCategoryLevelOneListReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case GET_SUB_CATEGORY__ONE_LIST:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

export function subCategoryLevelTwoListReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case GET_SUB_CATEGORY__TWO_LIST:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}