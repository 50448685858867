import { Routes, Route } from "react-router-dom";
import AdminResellingGetOrderDetails from "../../components/admin/sales/resellingOrder/AdminResellingGetOrderDetails";
import VendorShippingCharge from "../../components/admin/shippingCharge/VendorShippingCharge";
import VendorGetWallet from "../../components/admin/auth/wallet/VendorGetWallet";
import ReturnPolicy from "../../components/public/common/ReturnPolicy";
import AdminAffiliateGetOrderList from "../../components/admin/sales/affiliateOrder/AdminAffiliateGetOrderList";
import AdminAffiliateGetOrderDetails from "../../components/admin/sales/affiliateOrder/AdminAffiliateGetOrderDetails";
import AdminResellingGetOrderList from "../../components/admin/sales/resellingOrder/AdminResellingGetOrderList";

export default function OrderRoute() {
  return (
    <>
      <Routes>
        <Route path="/order-list" element={<AdminResellingGetOrderList />} />
        <Route path="/affiliate-order-list" element={<AdminAffiliateGetOrderList />} />
        <Route path="/order-details/:orderId/:customerId" element={<AdminResellingGetOrderDetails />} />
        <Route path="/affiliate-order-details/:orderId/:customerId" element={<AdminAffiliateGetOrderDetails />} />
        <Route path="/shippingCharge" element={<VendorShippingCharge />} />
        <Route path="/returnPolicy" element={<ReturnPolicy />} />
      </Routes>
    </>
  );
}
