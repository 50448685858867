export class AddMainCatagory {
    id?: number;
    name?: string;
    description?: string;
    isPublished?: boolean;
    isShowOnHomePage?: boolean;
    isSpecial?: boolean;
    displayOrder?: number;
    mainCategoryId?: Number;
    userRoleId?: number;
    bannerId?: number;
}

export class AddPopularLink {
    id?: number;
    name?: string;
    description?: string;
    url?: string;
    isPublished?: boolean;
    displayOrder?: number;
    bannerId?: number;
}

export class AddSubCatagoryOne {
    id?: Number;
    name?: string;
    description?: string;
    isPublished?: boolean;
    isShowOnHomePage?: boolean;
    isSpecial?: boolean;
    displayOrder?: Number;
    mainCategoryId?: Number;
    userRoleId?: Number;
    bannerId?: Number
}
export class updateSubCatagoryOne {
    subCategoryLevelOneName?: string;
    subCategoryLevelOneDescription?: string;
    mainCategoryId?: number;
    bannerId?: any;
}


export class AddSubCatagoryTwo {
    id?: Number;
    name?: string;
    description?: string;
    isPublished?: boolean;
    isShowOnHomePage?: boolean;
    isSpecial?: boolean;
    displayOrder?: Number;
    mainCategoryId?: Number;
    subCategoriesLevelOneId?: Number;
    userRoleId?: Number;
    bannerId?: Number
}

export class UpdateSubCatagoryTwo {
    subCategoryLevelTwoName?: string;
    subCategoryLevelTwoDescription?: string;
    subCategoryLevelOneId?: number;
    bannerId?: any;
}
