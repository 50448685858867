import { useEffect, useRef, useState } from "react";
import { IdNameResponseModel } from "../../models/common/IdNameModel";
import './AppSelect.css'
import { IoIosArrowDown } from "react-icons/io";

interface ISelectProps {
  data: IdNameResponseModel[];
  value?: any;
  placeholder?: string;
  onChange?: any;
  className?: string;
  defaultValue?: string;
  errorMessage?: string;
  selectedValue?: any;
  disabled?: boolean;
}


export const AppSelect: React.FC<ISelectProps> = ({
  data,
  value = 0,
  onChange,
  placeholder,
  className,
  defaultValue,
  errorMessage,
  selectedValue,
  disabled = false
}) => {
  const [selectedOption, setSelectedOption] = useState<IdNameResponseModel | null>(selectedValue);
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLSelectElement>(null);
  const customSelectRef = useRef<HTMLDivElement>(null);

  const handleOptionClick = (option: IdNameResponseModel, id?: any) => {
    if (selectRef.current) {
      selectRef.current.value = id;
    }
    onChange(id);
    setSelectedOption(option);
    setIsOpen(false);
  };

  const toggleSelect = () => {
    if (!disabled) setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      customSelectRef.current &&
      !customSelectRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className={`w-full custom-select ${disabled ? 'disabled' : ''}`} ref={customSelectRef}>
      <div className={`w-full flex items-center justify-between border border-grey rounded text-xs lg:text-base px-1 lg:px-4 py-1 2xl:py-2 bg-white cursor-pointer select-none`} onClick={toggleSelect}>
        <p> {value !== 0 ? data.find(item => item.id === value)?.name : defaultValue}</p>
        <IoIosArrowDown className={`lg:text-lg ${isOpen ? 'rotate-180' : ''}`} />
      </div>

      <div className={`select-items border-r border-b border-l border-grey rounded-b text-xs lg:text-base max-h-[20dvh] lg:max-h-[30dvh] overflow-y-auto ${isOpen ? '' : 'select-hide'}`}>
        <div
          className={`${selectedOption?.id === 0 ? 'same-as-selected' : ''}`}
          onClick={() => handleOptionClick({ id: 0, name: defaultValue }, 0)}
        >
          {defaultValue}
        </div>
        {data?.map((option: IdNameResponseModel) => (
          <div
            key={option.id}
            className={`${selectedOption?.id === option.id ? 'same-as-selected' : ''}`}
            onClick={() => handleOptionClick(option, option.id)}
          >
            {option.name}
          </div>
        ))}
      </div>
      {errorMessage &&
        <p className="pl-0.5 text-red text-sm">{errorMessage}</p>
      }
      <select ref={selectRef} style={{ display: 'none' }} disabled={disabled}>
        <option value={0}>{defaultValue}</option>
        {data.map((option: IdNameResponseModel) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};








// import FormControl from "@mui/material/FormControl";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";

// interface ISelectProps {
//   data: any[];
//   value?: number;
//   placeholder?: string;
//   onChange?: any;
//   className?: string;
//   defaultValue?: string;
//   height?: any;
//   width?: any;
//   maxHeight?: any;
//   maxWidth?: any;
//   minHeight?: any;
//   minWidth?: any;
//   parentClassName?: string;
//   childClassName?: string;
//   errorMessage?: string;
// }

// export const AppSelect: React.FC<ISelectProps> = ({
//   data,
//   value = 0,
//   onChange,
//   placeholder,
//   className,
//   defaultValue,
//   height = 10,
//   width,
//   maxHeight,
//   maxWidth,
//   minHeight,
//   minWidth,
//   parentClassName,
//   childClassName,
//   errorMessage,
// }) => {
//   const ITEM_HEIGHT = 48;
//   const ITEM_PADDING_TOP = 8;
//   const MenuProps = {
//     PaperProps: {
//       style: {
//         maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//         width: 100,
//       },
//     },
//   };

//   const handleChange = (event: any) => {
//     onChange(event.target.value);
//   };

//   return (
//     <FormControl
//       className={
//         parentClassName ? parentClassName : "w-full"
//       }
//     >
//       <Select
//         className={childClassName ? childClassName : "w-full h-8 lg:h-9 text-sm"}
//         MenuProps={MenuProps}
//         sx={{
//           // width: width,
//           // borderRadius: 0,
//           // height: height,
//           // maxHeight: maxHeight,
//           // maxWidth: maxWidth,
//           // minHeight: minHeight,
//           // minWidth: minWidth,
//           // color: "white",
//           // '.MuiOutlinedInput-notchedOutline': {
//           //   borderColor: 'rgba(228, 219, 233, 0.25)',
//           // },
//           // padding: 0,
//           // fontSize: "1rem",
//           // border: 'none'
//           "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//             borderColor: "#D8D8D8",
//             borderWidth: 1,
//           },
//           "&:hover .MuiOutlinedInput-notchedOutline": {
//             borderColor: "#D8D8D8",
//           },
//           // '.MuiSvgIcon-root ': {
//           //   fill: "white !important",
//           // }
//         }}
//         value={value}
//         onChange={handleChange}
//         displayEmpty
//         inputProps={{ "aria-label": "Without label" }}
//       >
//         <MenuItem
//           // className="pl-4"
//           value={0}
//         // sx={{
//         //   // paddingLeft: "16px",
//         //   paddingRight: "0px",
//         //   borderRadius: "8px",
//         // }}
//         >
//           <p className="text-xs lg:text-base">{defaultValue}</p>
//         </MenuItem>
//         {data?.map((x: any, index: any) => {
//           return (
//             <MenuItem key={index} value={x.id}>
//               <p className="text-xs lg:text-base">{x.name}</p>
//             </MenuItem>
//           );
//         })}
//       </Select>
//       <p className="text-sm text-red pl-[2px]">{errorMessage}</p>
//     </FormControl>
//   );
// };
