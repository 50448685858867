import { GET_VENDOR_REQUEST } from "../../../constants/auth/vendorRequest/vendorRequestConstant";
const initialState = {
  userList: [],
};
// function getVendorRequestReducer(state = { data: {} }, action: any) {
//   switch (action.type) {
//     case GET_VENDOR_REQUEST:
//       return { loading: false, data: action.payload };
//     default:
//       return state;
//   }
// }

function getVendorRequestReducer(state = initialState, action: any) {
  if (action.type === "GET_VENDOR_REQUEST") {
    return { userList: action.payload };
  }
  return state;
}
export { getVendorRequestReducer };
