import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { IoMdClose, IoMdHeartEmpty } from 'react-icons/io';
import { RiArrowRightSLine, RiFileList3Line } from 'react-icons/ri';
import { FaFacebook, FaRegEdit, FaYoutube } from 'react-icons/fa';
import { LuLogOut } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import { AppDefaultProfilePicture } from '../../common/AppDefaultProfilePicture';
import { getUserProfileAction } from '../../../store/actions/auth/user/userActions';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TbTruckReturn } from 'react-icons/tb';

export default function ResellingLeftDrawer() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const explore = useSelector((state: RootState) => {
        return state.leftMenuResponse.leftmenu;
    });

    const closeLeftMenu = () => {
        dispatch({ type: "CLOSE-LEFT-MENU" });
    };

    var token = reactLocalStorage.getObject("SignIn");

    const userProfile = useSelector((state: RootState) => {
        return state.getUserProfileResponse.data;
    });

    useEffect(() => {
        dispatch(getUserProfileAction());
    }, [dispatch]);

    const navigateToMyTeam = () => {
        if (token && JSON.stringify(token) !== JSON.stringify({})) {
            navigate("/reselling-platform/myTeam");
            // <Link to="/myTeam" />
            dispatch({ type: "CLOSE-LEFT-MENU" });
        } else {
            dispatch({ type: "OPEN-SIGN-IN" });
            dispatch({ type: "CLOSE-SIGN-UP" });
        }
    };

    const navigateToMyFavorite = () => {
        if (token && JSON.stringify(token) !== JSON.stringify({})) {
            navigate("/reselling-platform/favourites");
            dispatch({ type: "CLOSE-LEFT-MENU" });
        } else {
            dispatch({ type: "OPEN-SIGN-IN" });
            dispatch({ type: "CLOSE-SIGN-UP" });
        }
    };

    const navigateToMyOrder = () => {
        if (token && JSON.stringify(token) !== JSON.stringify({})) {
            navigate("/reselling-platform/myOrder");
            dispatch({ type: "CLOSE-LEFT-MENU" });
        } else {
            dispatch({ type: "OPEN-SIGN-IN" });
            dispatch({ type: "CLOSE-SIGN-UP" });
        }
    };

    const navigateToReturnPOlicy = () => {
        navigate("/reselling-platform/returnPolicy");
        dispatch({ type: "CLOSE-LEFT-MENU" });

    };

    const handleLogout = () => {
        reactLocalStorage.remove("SignIn");
        reactLocalStorage.remove("RoleName");
        reactLocalStorage.remove("UserId");
        navigate(`/`);
        dispatch({ type: "CLOSE-LEFT-MENU" });
    };


    return (
        <div className={explore ? "drawerOpen" : "drawerHidden"}>
            <div className="drawerContainer md:w-1/3 w-4/5 ">
                {/* left drawer header start here  */}
                <div className="pt-6 pr-6 text-reselling flex justify-end">
                    <IoMdClose onClick={closeLeftMenu} className="cursor-pointer  2xl:text-4xl text-2xl font-semibold" />
                </div>
                {/* left drawer body start here  */}
                <div className="px-6 2xl:px-12 2xl:space-y-6 space-y-5">

                    <div className="flex items-center gap-x-4">
                        <AppDefaultProfilePicture className="rounded cursor-pointer 2xl:w-[50px] 2xl:h-[50px] lg:w-10 w-8 h-8 lg:h-10" name={userProfile?.firstName} profilePicture={userProfile?.photoPath} />
                        <h2 className='2xl:text-2xl text-xl  font-bold'>{userProfile?.firstName} {userProfile?.lastName}</h2>
                    </div>

                    <div onClick={navigateToMyTeam} className="flex text-reselling hover:text-white myBorder py-1 2xl:px-4 pl-4 pr-1 hover:bg-reselling cursor-pointer justify-between items-center">
                        <div className=" flex items-center gap-x-2">
                            <FaRegEdit className=" text-xl md:text-2xl 2xl:text-3xl font-semibold" />
                            <h2 className='2xl:text-2xl  md:text-xl text-lg  font-semibold'>My Team</h2>
                        </div>
                        <RiArrowRightSLine className=" text-xl md:text-2xl 2xl:text-3xl font-semibold" />
                    </div>

                    <div onClick={navigateToMyFavorite} className="flex text-reselling hover:text-white myBorder py-1 2xl:px-4 pl-4 pr-1 hover:bg-reselling cursor-pointer justify-between items-center">
                        <div className=" flex items-center gap-x-2">
                            <IoMdHeartEmpty className=" text-xl md:text-2xl 2xl:text-3xl font-semibold" />
                            <h2 className='2xl:text-2xl  md:text-xl text-lg   font-semibold'>Favorites</h2>
                        </div>
                        <RiArrowRightSLine className=" text-xl md:text-2xl 2xl:text-3xl font-semibold" />
                    </div>

                    <div onClick={navigateToMyOrder} className="flex text-reselling hover:text-white myBorder py-1 2xl:px-4 pl-4 pr-1 hover:bg-reselling cursor-pointer justify-between items-center">
                        <div className=" flex items-center gap-x-2">
                            <RiFileList3Line className=" text-xl md:text-2xl 2xl:text-3xl font-semibold" />
                            <h2 className='2xl:text-2xl  md:text-xl text-lg   font-semibold'>Order List</h2>
                        </div>
                        <RiArrowRightSLine className=" text-xl md:text-2xl 2xl:text-3xl font-semibold" />
                    </div>

                    <div onClick={navigateToReturnPOlicy} className="flex text-reselling hover:text-white myBorder py-1 2xl:px-4 pl-4 pr-1 hover:bg-reselling cursor-pointer justify-between items-center">
                        <div className=" flex items-center gap-x-2">
                            <TbTruckReturn className=" text-xl md:text-2xl 2xl:text-3xl font-semibold" />
                            <h2 className='2xl:text-xl  md:text-lg   font-semibold'>রিটার্ন পলিসি</h2>
                        </div>
                        <RiArrowRightSLine className=" text-xl md:text-2xl 2xl:text-3xl font-semibold" />
                    </div>

                    <div className="flex items-center justify-between">
                        <Link to={'https://www.youtube.com/@Hellobazarofficial'} className="myBorder flex items-center gap-2 justify-center group text-reselling hover:text-white py-1 2xl:px-4 hover:bg-reselling cursor-pointer w-[47%] md:w-[45%] text-center">
                            <FaYoutube className='text-red text-2xl group-hover:text-white' />
                            <h2 className='2xl:text-xl md:text-base text-sm  font-semibold'>Youtube</h2>
                        </Link>
                        <Link to={'https://www.facebook.com/groups/190319601597423/?ref=share_group_link'} className="myBorder flex items-center gap-2 justify-center group text-reselling hover:text-white py-1 2xl:px-4 hover:bg-reselling cursor-pointer w-[47%] md:w-[45%] text-center">
                            <FaFacebook className='text-royalBlue text-2xl group-hover:text-white' />
                            <h2 className='2xl:text-xl md:text-base text-sm  font-semibold'>Facebook</h2>
                        </Link>
                    </div>

                    {/* these is sign out section  */}
                    <div className="myBorder text-reselling hover:text-white py-1 2xl:px-4 pl-4 pr-1 hover:bg-reselling cursor-pointer flex items-center justify-center gap-x-2" onClick={handleLogout}>
                        <LuLogOut className=" text-xl md:text-2xl 2xl:text-3xl font-semibold" />
                        <h2 className='2xl:text-2xl  md:text-xl text-lg  font-semibold'>Sign out</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}
