import { DELETE, GET, POST, PUT } from "../service";

export const addPublicRepresentativesService = (data: any) => {
	return POST("/api/PublicRepresentativesNumber/CreatePublicRepresentativesNumber", data);
};

export const updatePublicRepresentativesService = (data: any) => {
	return PUT("/api/PublicRepresentativesNumber/UpdatePublicRepresentativesNumber", data);
};

export const getPublicRepresentativesService = (payload: any) => {
	return GET(`/api/PublicRepresentativesNumber/GetPublicRepresentativesNumber?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}&divisionId=${payload?.divisionId}&districtId=${payload?.districtId}&areaId=${payload?.areaId}&unionOrWardId=${payload?.unionOrWardId}`);
};

export const getPublicRepresentativesByIdService = (Id: any) => {
    return GET(`/api/PublicRepresentativesNumber/GetPublicRepresentativesNumberById?Id=${Id}`)
};

export const deletePublicRepresentativesService = (data: any) => {
	return DELETE(`/api/PublicRepresentativesNumber/RemovePublicRepresentativesNumber`, {
		data: { "ids": data },
	});
};