import { getPoliceOfficialsService, getPoliceOfficialsByIdService } from "../../../httpService/importantNumbers/policeOfficialsInfoServices";
import {
	GET_POLICEOFFICIALS_NUMBER,
	GET_POLICEOFFICIALS_NUMBER_BY_ID
} from "../../constants/importantNumbers/policeOfficialsInfo/policeOfficialsConstant";

const getPoliceOfficialsAction = (payload?: any) => async (dispatch: any) => {
	var data = getPoliceOfficialsService(payload);
	const pload = (await data).data;
	dispatch({ type: GET_POLICEOFFICIALS_NUMBER, payload: pload });
};

const getPoliceOfficialsByIdAction = (payload?: any) => async (dispatch: any) => {
	var data = getPoliceOfficialsByIdService(payload);
	const pload = (await data).data;
	dispatch({ type: GET_POLICEOFFICIALS_NUMBER_BY_ID, payload: pload });
};

export { getPoliceOfficialsAction, getPoliceOfficialsByIdAction };