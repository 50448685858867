import { AFFILIATE_GET_PRODUCTS, AFFILIATE_GET_PRODUCT_BY_ID } from "../../constants/catalog/affiliateProductConstants";

function affiliateGetProductsReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case AFFILIATE_GET_PRODUCTS:
            return { loading: false, data: action.payload };
        default:
            return state;
    }
}

function affiliateGetProductByIdReducer(state = { data: {} }, action: any) {
    switch (action.type) {
        case AFFILIATE_GET_PRODUCT_BY_ID:
            return { loading: false, data: action.payload };
        default:
            return state;
    }
}

export {
    affiliateGetProductsReducer,
    affiliateGetProductByIdReducer
}