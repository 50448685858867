import axios from 'axios';
import { reactLocalStorage } from 'reactjs-localstorage';
import { catalogBaseUrl } from '../config/config'
import store from '../store';

export const catalog_http = axios.create({
    baseURL: catalogBaseUrl,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        //'Authorization': `Bearer ${localStorage.getItem('')}`,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
        // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-XSRF-TOKEN',
    }
})

// export const s3_http = axios.create({
//     baseURL: s3EndPoint,
//     headers: {
//         'Accept': 'application/json',
//         // 'Content-Type': 'application/json',
//         //'Authorization': `Bearer ${localStorage.getItem('')}`,
//         'Access-Control-Allow-Origin': '*',
//         'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
//         // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-XSRF-TOKEN',
//     }
// })

class TokenModel {
    accessToken?: string;
    expireAt?: number;
    refreshToken?: string;
    rememberMeToken?: string
}

export const HTTP = axios.create({ baseURL: catalogBaseUrl });
HTTP.interceptors.request.use(
    async config => {
        let token = reactLocalStorage.getObject('SignIn') as TokenModel;
        config.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token?.accessToken}`,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
            'Access-Control-Allow-Headers': 'Origin, Content-Type, X-XSRF-TOKEN',
        };
        return config;
    },
    error => {
        Promise.reject(error);
    }
);


HTTP.interceptors.response.use(undefined, function (error) {
    
    if (error) {
        const originalRequest = error.config;
        if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
            originalRequest._retry = true;
            store.dispatch({ type: "OPEN-SIGN-IN" });
        }
       
    }
    return Promise.reject(error);
});


export function GET(url: string, queryPayload = {}) {
    return HTTP.get(`${catalogBaseUrl}${url}`, queryPayload);
}

export function POST(url: string, body: any) {
    return HTTP.post(`${catalogBaseUrl}${url}`, body);
}

export function PUT(url: string, body: any) {
    return HTTP.put(`${catalogBaseUrl}${url}`, body);
}

export function DELETE(url: string, queryPayload = {}) {
    return HTTP.delete(`${catalogBaseUrl}${url}`, queryPayload);
}
