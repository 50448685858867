export class AddBechteChaiMainCatagory {
    id?: number;
    name?: string;
    description?: string;
    isPublished?: boolean;
    isShowOnHomePage?: boolean;
    isSpecial?: boolean;
    displayOrder?: number;
    mainCategoryId?: Number;
    userRoleId?: number;
    bannerId?: number;
}

export class AddBechteChaiSubCatagory {
    id?: Number;
    name?: string;
    description?: string;
    isPublished?: boolean;
    isShowOnHomePage?: boolean;
    isSpecial?: boolean;
    displayOrder?: Number;
    mainCategoryId?: Number;
    userRoleId?: Number;
    bannerId?: Number
}
export class updateBechteChaiSubCatagory {
    subCategoryLevelOneName?: string;
    subCategoryLevelOneDescription?: string;
    mainCategoryId?: number;
    bannerId?: any;
}
