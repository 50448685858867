import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { AppButton } from "../../common/AppButton";
import { getShippingCharge } from "../../../store/actions/shippingCharge/shippingCharge";
import { CreateShippingCharge, UpdateShippingCharge } from "../../../httpService/shippingCharge/shippingCharge";
import { AppInput } from "../../common/AppInput";
import { AlertColor } from "@mui/material/Alert";
import { AppSnackBar } from "../../common/AppSnackBar";

export default function VendorShippingCharge() {

  const dispatch = useDispatch();

  const shippingCharge = useSelector((state: RootState) => {
    return state.getShippingChargeResponse.data;
  });

  const [inSideDhaka, setInSideDhaka] = useState(0);
  const [outSideDhaka, setOutSideDhaka] = useState(0);
  const [id, setId] = useState<number>(0);

  const [snackBarMessage, setsnackBarMessage] = useState('');
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);

  useEffect(() => {
    dispatch(getShippingCharge());
  }, [dispatch]);

  useEffect(() => {
    if (shippingCharge) {
      setInSideDhaka(shippingCharge.inSideDhaka);
      setOutSideDhaka(shippingCharge.outSideDhaka);
      setId(shippingCharge.id || 0);
    }
  }, [shippingCharge]);

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleSave = () => {
    var payload = {
      inSideDhaka: inSideDhaka || 0,
      outSideDhaka: outSideDhaka || 0
    };
    UpdateShippingCharge(payload)
      .then((res: any) => {
        setSnackBarOn(true)
        setsnackBarMessage("Shipping Charge Updated Successfully")
        setSnackBarMessageType('success')
        dispatch(getShippingCharge());
        setIsButtonLoading(false);
      })
      .catch((error: any) => {
        setIsButtonLoading(false);
      });
  };


  const handleCreate = () => {
    var payload = {
      inSideDhaka: inSideDhaka || 0,
      outSideDhaka: outSideDhaka || 0
    };
    CreateShippingCharge(payload)
      .then((res: any) => {
        setSnackBarOn(true)
        setsnackBarMessage("Shipping Charge Added Successfully")
        setSnackBarMessageType('success')
        dispatch(getShippingCharge());
        setIsButtonLoading(false);
      })
      .catch((error: any) => {
        setIsButtonLoading(false);
      });

  };

  return (
    <div className="lg:px-2 2xl:px-4">
      {snackBarOn &&
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      }
      <h2 className="title text-center">Change shipping Charge</h2> 
      <div className="flex flex-col items-center gap-4 p-4 max-w-md mx-auto myBorder shadow-md my-6">
        <AppInput
          type="number"
          className="myInput"
          label="Inside Dhaka:"
          id="inSideDhaka"
          value={inSideDhaka}
          onChange={(value: any) => setInSideDhaka(value)}
        />
        <AppInput
          type="number"
          label="Outside Dhaka:"
          className="myInput"
          id="outSideDhaka"
          value={outSideDhaka}
          onChange={(value: any) => setOutSideDhaka(value)}
        />

        {id === 0 ? (
          <AppButton text="Create" onClick={handleCreate} />
        ) : (
          <AppButton isSubmitting={isButtonLoading} text="Save" onClick={handleSave} />
        )}
       
      </div>
    </div>
  );
}
