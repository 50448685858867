import { useState } from 'react'
import { AppButton } from '../../common/AppButton'
import { AppInput } from '../../common/AppInput';
import { changePasswordService } from '../../../httpService/user/userServices';
import { AlertColor } from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import { AppSnackBar } from '../../common/AppSnackBar';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { ChangePasswordRequest } from '../../../models/auth/user/UserModels';

export default function ChangePassword() {


    const [isShowSnackBar, setIsShowSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setNewShowPassword] = useState(false);
    const [showConfirmPassword, setConfirmShowPassword] = useState(false);

    const [oldPasswordError, setOldPasswordError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const toggleNewPasswordVisibility = () => {
        setNewShowPassword(!showNewPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmShowPassword(!showConfirmPassword);
    };
    const navigate = useNavigate();

    const submitHandler = (event: any) => {
        event.preventDefault();
        var model = new ChangePasswordRequest();
        model.oldPassword = oldPassword;
        model.newPassword = password;
        model.confirmPassword = confirmPassword;

        changePasswordService(model)
            .then((res: any) => {

                setIsShowSnackBar(true);
                setSnackBarMessage("Password Changed Successfully");
                setSnackBarMessageType("success");
                setTimeout(() => {
                    setIsShowSnackBar(false);
                }, 2000);

                setTimeout(() => {
                    navigate("/myOptions/profile", {
                        replace: true,
                    });
                }, 2000);


            })
            .catch((error) => {
                setOldPasswordError(error.response.data.errors?.OldPassword?.[0]);
                setPasswordError(error.response.data.errors?.NewPassword?.[0]);
                setConfirmPasswordError(error.response.data.errors?.ConfirmPassword?.[0]);
            });
    };


    const handleOldPass = (value: any) => {
        setOldPassword(value)
        setOldPasswordError("")
    }

    const handleNewPass = (value: any) => {
        setPassword(value)
        setPasswordError("");
    }

    const handleConfirmPass = (value: any) => {
        setConfirmPassword(value)
        setConfirmPasswordError("");
    }


    return (
        <div className="container mx-auto py-16">
            {isShowSnackBar && (
                <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
            )}
            {/* these is body of change pass  */}
            <div className="w-full md:w-3/5 rounded mx-auto md:shadow-lg p-6">
                <h4 className="subTitle text-center pb-2 text-textBlack">Change Password</h4>
                <div className="flex flex-col md:px-20 py-6">
                    <div className="relative pt-2">
                        <AppInput
                            label="Old Password"
                            className="myInput"
                            type={showPassword ? "text" : "password"}
                            onChange={handleOldPass}
                            placeholder={"Old Password"}
                            errorMessage={oldPasswordError}
                        />
                        {showPassword ? (
                            <AiOutlineEyeInvisible
                                className="absolute text-2xl text-gray-700 font-bold cursor-pointer right-3 top-[55%]"
                                onClick={togglePasswordVisibility}
                            />
                        ) : (
                            <AiOutlineEye
                                className="absolute text-2xl text-gray-700 font-bold cursor-pointer right-3 top-[55%]"
                                onClick={togglePasswordVisibility}
                            />
                        )}
                    </div>

                    <div className="relative pt-2">
                        <AppInput
                            type={showNewPassword ? "text" : "password"}
                            className="myInput"
                            label="New Password"
                            onChange={handleNewPass}
                            placeholder={"Password"}
                            errorMessage={passwordError}
                        />
                        {showNewPassword ? (
                            <AiOutlineEyeInvisible
                                className="absolute text-2xl text-gray-700 font-bold cursor-pointer right-3 top-[55%]"
                                onClick={toggleNewPasswordVisibility}
                            />
                        ) : (
                            <AiOutlineEye
                                className="absolute text-2xl text-gray-700 font-bold cursor-pointer right-3 top-[55%]"
                                onClick={toggleNewPasswordVisibility}
                            />
                        )}
                    </div>

                    <div className="relative pt-2">
                        <AppInput
                            type={showConfirmPassword ? "text" : "password"}
                            className="myInput"
                            label="Confirm Password"
                            onChange={handleConfirmPass}
                            placeholder={"Confirm Password"}
                            errorMessage={confirmPasswordError}
                        />
                        {showConfirmPassword ? (
                            <AiOutlineEyeInvisible
                                className="absolute text-2xl text-gray-700 font-bold cursor-pointer right-3 top-[55%]"
                                onClick={toggleConfirmPasswordVisibility}
                            />
                        ) : (
                            <AiOutlineEye
                                className="absolute text-2xl text-gray-700 font-bold cursor-pointer right-3 top-[55%]"
                                onClick={toggleConfirmPasswordVisibility}
                            />
                        )}
                    </div>
                    <AppButton
                        className="myBtn mt-3"
                        onClick={submitHandler}
                        text={"Set password"}
                    />
                </div>
            </div>
        </div>
    )
}
