import { useState } from "react";
import { useNavigate } from "react-router";
import { AlertColor } from "@mui/material";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { AppButton } from "../../../common/AppButton";
import { AppInput } from "../../../common/AppInput";
import { addHospitalService } from "../../../../httpService/importantNumbers/hospitalServices";
import { isAdmin } from "../../../../common/common";
import { reactLocalStorage } from "reactjs-localstorage";

export default function INCreateHospitalNumber() {

	const navigate = useNavigate();

	const [snackBarMessage, setsnackBarMessage] = useState("");
	const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
	const [snackBarOn, setSnackBarOn] = useState(false);
	const [isButtonLoading, setIsButtonLoading] = useState(false);

	const [name, setName] = useState("");
	const [nameErrorMessage, setNameErrorMessage] = useState("");
	const [phoneNumberFirst, setPhoneNumberFirst] = useState("");
	const [phoneNumberFirstErrorMessage, setPhoneNumberFirstErrorMessage] = useState("");
	const [phoneNumberSecond, setPhoneNumberSecond] = useState("");
	const [phoneNumberSecondErrorMessage, setPhoneNumberSecondErrorMessage] = useState("");
	const [email, setEmail] = useState("");
	const [emailErrorMessage, setEmailErrorMessage] = useState("");
	const [hotLineNumber, setHotLineNumber] = useState("");
	const [hotLineNumberErrorMessage, setHotLineNumberErrorMessage] = useState("");
	const [address, setAddress] = useState("");
	const [addressErrorMessage, setAddressErrorMessage] = useState("");

	const handleNameChange = (event: any) => {
		setName(event);
		setNameErrorMessage("");
	}

	const handlePhoneNumberFirstChange = (event: any) => {
		setPhoneNumberFirst(event);
		setPhoneNumberFirstErrorMessage("");
	}
	const handlePhoneNumberSecondChange = (event: any) => {
		setPhoneNumberSecond(event);
		setPhoneNumberSecondErrorMessage("");
	}

	const handleEmailChange = (event: any) => {
		setEmail(event);
		setEmailErrorMessage("");
	}

	const handleHotLineNumberChange = (event: any) => {
		setHotLineNumber(event);
		setHotLineNumberErrorMessage("");
	}

	const handleAddressChange = (event: any) => {
		setAddress(event);
		setAddressErrorMessage("");
	}

	const handleSubmit = (e: any) => {
		e.preventDefault();
		setSnackBarOn(false);
		var payload = {
			name: name,
			phoneNumberFirst: phoneNumberFirst,
			phoneNumberSecond: phoneNumberSecond,
			email: email,
			hotLineNumber: hotLineNumber,
			address: address
		};

		setIsButtonLoading(true);

		addHospitalService(payload)
			.then(() => {
				setSnackBarOn(true);
				setsnackBarMessage("Hospital Number Added Successfully.");
				setSnackBarMessageType("success");
				setIsButtonLoading(false);
				setTimeout(() => {
					navigateToHospitalNumbers();
				}, 300);
			})
			.catch((error: any) => {
				setIsButtonLoading(false);
				setNameErrorMessage(error.response.data.errors?.Name?.[0]);
				setPhoneNumberFirstErrorMessage(error.response.data.errors?.PhoneNumberFirst?.[0]);
				setPhoneNumberSecondErrorMessage(error.response.data.errors?.PhoneNumberSecond?.[0]);
				setEmailErrorMessage(error.response.data.errors?.Email?.[0]);
				setHotLineNumberErrorMessage(error.response.data.errors?.HotLineNumber?.[0]);
				setAddressErrorMessage(error.response.data.errors?.Address?.[0]);
			});
	};

	const navigateToHospitalNumbers = () => {
		if (isAdmin(reactLocalStorage.get("RoleName"))) {
		  navigate(`/admin/important-numbers/hospital-numbers`);
		} else {
		  navigate(`/important-numbers/hospital-numbers`);
		}
	  };

	return (
		<div className="container mx-auto px-2 py-4">
			{snackBarOn && (
				<AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
			)}
			<div className="flex flex-col lg:flex-row items-start lg:items-center pb-4">
				<AppButton
					className="gradientBtn"
					onClick={navigateToHospitalNumbers}
					text="Back"
				/>
				<div className='w-full'>
					<h2 className="title text-center text-cyan-600">
						Create Hospital Number
					</h2>
				</div>
			</div>

			<form className="myBorder mx-auto max-w-xl grid grid-cols-2 gap-x-2 items-center lg:gap-x-4 gap-y-2 shadow-lg px-2 lg:px-5 py-2 lg:py-4">
				<AppInput
					label="Name"
					className="myInput"
					type="text"
					placeholder="Enter Name"
					onChange={handleNameChange}
					errorMessage={nameErrorMessage}
				/>
				<AppInput
					label="Phone Number First"
					className="myInput"
					type="text"
					placeholder="Enter Phone Number"
					onChange={handlePhoneNumberFirstChange}
					errorMessage={phoneNumberFirstErrorMessage}
				/>
				<AppInput
					label="Phone Number Second"
					className="myInput"
					type="text"
					placeholder="Enter Phone Number"
					onChange={handlePhoneNumberSecondChange}
					errorMessage={phoneNumberSecondErrorMessage}
				/>
				<AppInput
					label="Email"
					className="myInput"
					type="text"
					placeholder="Enter Email"
					onChange={handleEmailChange}
					errorMessage={emailErrorMessage}
				/>
				<AppInput
					label="HotLine Number"
					className="myInput"
					type="text"
					placeholder="Enter HotLine Number"
					onChange={handleHotLineNumberChange}
					errorMessage={hotLineNumberErrorMessage}
				/>
				<AppInput
					label="Address"
					className="myInput"
					type="text"
					placeholder="Enter Address"
					onChange={handleAddressChange}
					errorMessage={addressErrorMessage}
				/>
				<div className="flex justify-end col-span-2">
					<AppButton
						text="Submit"
						className="gradientBtn"
						onClick={handleSubmit}
						isSubmitting={isButtonLoading}
						variant="contained"
					/>
				</div>

			</form>
		</div>
	);
}
