import { useEffect, useState } from 'react'
import { AppSelect } from '../../common/AppSelect'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { IdNameResponseModel } from '../../../models/common/IdNameModel';
import { divisionList } from '../../../store/actions/auth/address/divisionActions';
import { districtListByDivisionId } from '../../../store/actions/auth/address/districtActions';
import { areaListByDistrictId } from '../../../store/actions/auth/address/areaActions';
import { getBloodDonorsAction, getUserProfileAction } from '../../../store/actions/auth/user/userActions';
import { BloodGroupListResponse } from '../../../models/auth/user/UserModels';
import { AppSearchBox } from '../../common/AppSearchBox';
import { BiSearchAlt2 } from 'react-icons/bi';
import { MdLocationOn } from 'react-icons/md';
import { AppDefaultProfilePicture } from '../../common/AppDefaultProfilePicture';
import { GetBloodDonorsRequest } from '../../../models/bloodClub/bloodClubModels';
import { AppButton } from "../../common/AppButton";
import { useNavigate } from 'react-router-dom';
import { AppModal } from '../../common/AppModal';
import { getUserDetailsAction } from '../../../store/actions/auth/address/userActions';
import _moment from 'moment';
import { AiOutlineClose } from 'react-icons/ai';

export default function BloodClub() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [divisionId, setDivisionId] = useState(0);
    const [districtId, setDistrictId] = useState(0);
    const [areaId, setAreaId] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");

    const [divisionError, setDivisionError] = useState("");
    const [bDistrictError, setBDistrictError] = useState("");
    const [bAreaError, setBAreaError] = useState("");

    const divisions = useSelector((state: RootState) => {
        return state.divisionListResponse.data;
    });
    var divisionIdNameModels: IdNameResponseModel[] = [];

    divisions?.map((x: any) => {
        var division = new IdNameResponseModel();
        division.id = x.id;
        division.name = x.name;
        divisionIdNameModels.push(division);
    });

    const districts = useSelector((state: RootState) => {
        return state.districtListResponse.data;
    });
    var districtsIdNameModels: IdNameResponseModel[] = [];

    districts?.map((x: any) => {
        var district = new IdNameResponseModel();
        district.id = x.id;
        district.name = x.name;
        districtsIdNameModels.push(district);
    });

    const thanas = useSelector((state: RootState) => {
        return state.areaListResponse.data;
    });
    var areaIdNameModels: IdNameResponseModel[] = [];

    thanas?.map((x: any) => {
        var area = new IdNameResponseModel();
        area.id = x.id;
        area.name = x.name;
        areaIdNameModels.push(area);
    });

    const userProfile = useSelector((state: RootState) => {
        return state.getUserProfileResponse.data;
    });
    
    useEffect(() => {
        dispatch(getUserProfileAction());
    }, [dispatch]);

    useEffect(() => {
        dispatch(divisionList());
    }, [dispatch]);

    useEffect(() => {
        if (divisionId != 0) {
            dispatch(districtListByDivisionId(divisionId));
        }
    }, [divisionId, dispatch]);

    useEffect(() => {
        if (districtId != 0) {
            dispatch(areaListByDistrictId(districtId));
        }
    }, [districtId, dispatch]);

    const navigateToAddInfo = () => {
        navigate("/bloodClub/addUserInfo");
    }

    const handleSearchQuery = (query: any) => {
        setSearchQuery(query);
        var pModel = new GetBloodDonorsRequest();
        pModel.limit = 100000;
        pModel.offset = 0;
        pModel.divisionId = divisionId;
        pModel.districtId = 0;
        pModel.areaId = 0;
        pModel.bloodGroup = gropid;
        pModel.searchQuery = query;
        dispatch(getBloodDonorsAction(pModel));
    }

    const handleDivision = (id: number) => {
        setDivisionError("");
        setDivisionId(id);
        setDistrictId(0)
        setAreaId(0)
        var pModel = new GetBloodDonorsRequest();
        pModel.limit = 100000;
        pModel.offset = 0;
        pModel.divisionId = id;
        pModel.districtId = 0;
        pModel.areaId = 0;
        pModel.bloodGroup = gropid;
        pModel.searchQuery = searchQuery;
        dispatch(getBloodDonorsAction(pModel));
    };

    const handleDistrict = (id: number) => {
        setBDistrictError("");
        setDistrictId(id);
        setAreaId(0)
        var pModel = new GetBloodDonorsRequest();
        pModel.limit = 100000;
        pModel.offset = 0;
        pModel.divisionId = divisionId;
        pModel.districtId = id;
        pModel.areaId = 0;
        pModel.bloodGroup = gropid;
        pModel.searchQuery = searchQuery;
        dispatch(getBloodDonorsAction(pModel));
    };

    const handleArea = (id: number) => {
        setBAreaError("");
        setAreaId(id);
        var pModel = new GetBloodDonorsRequest();
        pModel.limit = 100000;
        pModel.offset = 0;
        pModel.divisionId = divisionId;
        pModel.districtId = districtId;
        pModel.areaId = id;
        pModel.bloodGroup = gropid;
        pModel.searchQuery = searchQuery;
        dispatch(getBloodDonorsAction(pModel));
    };


    const donors = useSelector((state: RootState) => {
        return state.getBloodDonorsResponse.data;
    });

    useEffect(() => {
        var pModel = new GetBloodDonorsRequest();
        pModel.limit = 100000;
        pModel.offset = 0;
        pModel.searchQuery = searchQuery;
        pModel.bloodGroup = 0;
        pModel.divisionId = userProfile.divisionId ? userProfile.divisionId : 0;
        pModel.districtId = userProfile.districtId ? userProfile.districtId : 0;
        pModel.areaId = userProfile.areaId ? userProfile.areaId : 0;
        setDivisionId(userProfile.divisionId ? userProfile.divisionId : 0);
        setDistrictId(userProfile.districtId ? userProfile.districtId : 0);
        setAreaId(userProfile.areaId ? userProfile.areaId : 0);
        dispatch(getBloodDonorsAction(pModel));
    }, []);

    const handleReset = () => {
        var pModel = new GetBloodDonorsRequest();
        pModel.limit = 100000;
        pModel.offset = 0;
        pModel.searchQuery = "";
        pModel.bloodGroup = 0;
        pModel.divisionId = 0;
        pModel.districtId = 0;
        pModel.areaId = 0;
        setDivisionId(0);
        setDistrictId(0);
        setAreaId(0);
        setGrpId(0);
        dispatch(getBloodDonorsAction(pModel));
    }

    const [gropid, setGrpId] = useState(0);

    const handleSelectBloodGroup = (value: any) => {
        setGrpId(value);
        var pModel = new GetBloodDonorsRequest();
        pModel.limit = 100000;
        pModel.offset = 0;
        pModel.divisionId = divisionId;
        pModel.districtId = districtId;
        pModel.areaId = 0;
        pModel.bloodGroup = value;
        pModel.searchQuery = searchQuery;
        dispatch(getBloodDonorsAction(pModel));
    };

    const userDetailsData = useSelector((state: RootState) => {
        return state.userDetailsResponse.data;
    });

    const [isOpenDonnerCard, setIsOpenDonnerCard] = useState(false);

    const openDonnerCard = (id: any) => {
        setIsOpenDonnerCard(true);
        dispatch(getUserDetailsAction(id));
    }

    const closeDonnerCard = () => {
        setIsOpenDonnerCard(false);
    }

    const timeFormatter = (createdDateTime: any) => {
        const createdTime = _moment(createdDateTime, 'YYYY-MM-DD HH:mm:ss.SSSSSSS');
        return createdTime.format('MMM D, YY');
    }

    const [canUpdateInfo, setCanUpdateInfo] = useState(false);

    useEffect(() => {
        if (userProfile) {
            const threeMonthsAgo = _moment().subtract(3, 'months');
            const lastDonateTime = _moment(userProfile?.lastDonateDate);

            if ((userProfile.bloodGroup === 0 || userProfile.bloodGroup === null) || lastDonateTime.isBefore(threeMonthsAgo)) {
                setCanUpdateInfo(true);
            }
        }
    }, [dispatch, userProfile]);

    const calculateTime = (createdDateTime: any) => {
        const createdTime = _moment(createdDateTime, 'YYYY-MM-DD HH:mm:ss.SSSSSSS');
        let formattedTime;
        formattedTime = createdTime.format('MMM D, YYYY');
        return formattedTime;
    }

    return (
        <div className='container mx-auto 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28'>

            {isOpenDonnerCard && (
                <AppModal>
                    <div className="p-2 lg:p-4 w-[92dvw] lg:w-[440px] text-black">
                        <div className='flex justify-end'>
                            <AiOutlineClose className='text-red text-xl cursor-pointer' onClick={closeDonnerCard} />
                        </div>

                        <div className="flex flex-col gap-2 pt-4">
                            <div className='flex items-center justify-between gap-4'>
                                <div className="flex items-center gap-2">
                                    <AppDefaultProfilePicture
                                        className="customer-review-image"
                                        name={userDetailsData?.firstName}
                                        profilePicture={userDetailsData?.photoPath}
                                    />
                                    <div className='lg:space-y-0.5'>
                                        <h3 className=' font-medium'>{userDetailsData?.firstName}</h3>
                                        {/* <h3 className='flex items-center text-sm text-royalBlue'>4 Times</h3> */}
                                    </div>
                                </div>

                                <div className={`flex w-10 h-9 items-center justify-center font-semibold text-white rounded ${userDetailsData?.bloodGroup === "A_Positive" ? "bg-[#ED208A]" : ""} ${userDetailsData?.bloodGroup === "A_Negative" ? "bg-[#0054A6]" : ""} ${userDetailsData?.bloodGroup === "B_Positive" ? "bg-[#F26522]" : ""} ${userDetailsData?.bloodGroup === "B_Negative" ? "bg-[#981B1F]" : ""} ${userDetailsData?.bloodGroup === "O_Positive" ? "bg-[#981B1F]" : ""} ${userDetailsData?.bloodGroup === "O_Negative" ? "bg-[#00AEEF]" : ""} ${userDetailsData?.bloodGroup === "AB_Positive" ? "bg-[#8EC73F]" : ""} ${userDetailsData?.bloodGroup === "AB_Negative" ? "bg-[#92278F]" : ""}`}>
                                    <span>{userDetailsData?.bloodGroup === "A_Positive" ? "A+" : ""} {userDetailsData?.bloodGroup === "A_Negative" ? "A-" : ""} {userDetailsData?.bloodGroup === "B_Positive" ? "B+" : ""} {userDetailsData?.bloodGroup === "B_Negative" ? "B-" : ""} {userDetailsData?.bloodGroup === "O_Positive" ? "O+" : ""} {userDetailsData?.bloodGroup === "O_Negative" ? "O-" : ""} {userDetailsData?.bloodGroup === "AB_Positive" ? "AB+" : ""} {userDetailsData?.bloodGroup === "AB_Negative" ? "AB-" : ""}</span>
                                </div>
                            </div>
                            <div className='bg-[#ECECEC] px-1 lg:px-2 py-1 rounded mt-2'>
                                <div className='flex items-center text-sm lg:text-base justify-between'>
                                    <p className='font-medium '>Last donate Date </p>
                                    <h3>{timeFormatter(userDetailsData?.lastDonateDate)}</h3>
                                </div>
                                <div className='flex items-center text-sm lg:text-base justify-between'>
                                    <p className='font-medium '>Donate to:  {userDetailsData?.donateTo}</p>
                                    <h3>
                                        {/* <span className='text-red  text-sm lg:text-base'>Not Available</span> */}
                                        {/* <span className='text-primary  text-sm lg:text-base'>Available</span> */}
                                    </h3>
                                </div>
                            </div>
                            <div className='flex justify-between items-center'>

                                <div className='w-[45%]'>
                                    <p>Phone</p>
                                    <div className='rounded  text-sm lg:text-base bg-[#ECECEC] px-4 py-0.5  font-medium'>
                                        <h3>{userDetailsData?.phoneNumber}</h3>
                                    </div>
                                </div>
                                <div className='w-[45%]'>
                                    <p>Thana</p>
                                    <div className='rounded  text-sm lg:text-base bg-[#ECECEC] px-4 py-0.5   font-medium'>
                                        <h3>{userDetailsData?.areaName}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='flex justify-between items-center'>

                                <div className='w-[45%]'>
                                    <p>District</p>
                                    <div className='rounded  text-sm lg:text-base bg-[#ECECEC] px-4 py-0.5  font-medium'>
                                        <h3>{userDetailsData?.districtName}</h3>
                                    </div>
                                </div>
                                <div className='w-[45%]'>
                                    <p>Division</p>
                                    <div className='rounded  text-sm lg:text-base bg-[#ECECEC] px-4 py-0.5   font-medium'>
                                        <h3>{userDetailsData?.divisionName}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </AppModal>
            )}

            <h2 className="title text-red">ব্লাড ক্লাব</h2>
            {/* this is blood section div  */}
            <div className="mt-2 lg:mt-4">
                <h3 className='text-red font-medium subTitle text-center pb-2'>We have {donors?.items?.length} Collections</h3>
                <div className='flex items-center justify-between'>
                    <div className="flex items-center text-sm lg:text-base justify-center gap-2 lg:gap-6 font-semibold text-white">
                        <div className={`w-8 lg:w-11 h-8 lg:h-11 cursor-pointer flex items-center justify-center rounded ${gropid === 0 ? "bg-[green]" : "bg-gray-300"}`} onClick={() => handleReset()}>
                            <span>All</span>
                        </div>
                        <div className={`bloodGroupBtn ${gropid === 1 ? "bg-[#ED208A]" : "bg-gray-300"}`} onClick={() => handleSelectBloodGroup(1)}>
                            <span>A+</span>
                        </div>
                        <div className={`bloodGroupBtn ${gropid === 2 ? "bg-[#0054A6]" : "bg-gray-300"}`} onClick={() => handleSelectBloodGroup(2)}>
                            <span>A-</span>
                        </div>
                        <div className={`bloodGroupBtn ${gropid === 3 ? "bg-[#F26522]" : "bg-gray-300"}`} onClick={() => handleSelectBloodGroup(3)}>
                            <span>B+</span>
                        </div>
                        <div className={`bloodGroupBtn ${gropid === 4 ? "bg-[#981B1F]" : "bg-gray-300"}`} onClick={() => handleSelectBloodGroup(4)}>
                            <span>B-</span>
                        </div>
                        <div className={`bloodGroupBtn ${gropid === 5 ? "bg-[#981B1F]" : "bg-gray-300"}`} onClick={() => handleSelectBloodGroup(5)}>
                            <span>O+</span>
                        </div>
                        <div className={`bloodGroupBtn ${gropid === 6 ? "bg-[#00AEEF]" : "bg-gray-300"}`} onClick={() => handleSelectBloodGroup(6)}>
                            <span>O-</span>
                        </div>
                        <div className={`bloodGroupBtn ${gropid === 7 ? "bg-[#8EC73F]" : "bg-gray-300"}`} onClick={() => handleSelectBloodGroup(7)}>
                            <span>AB+</span>
                        </div>
                        <div className={`bloodGroupBtn ${gropid === 8 ? "bg-[#92278F]" : "bg-gray-300"}`} onClick={() => handleSelectBloodGroup(8)}>
                            <span>AB-</span>
                        </div>
                    </div>
                    <div className='hidden lg:block'>
                        {canUpdateInfo === true ? (
                            <AppButton
                                text="Add Your Info"
                                onClick={navigateToAddInfo}
                                className='myBtn bg-red border-red py-1.5 text-base'
                            />
                        ) : (
                            <AppButton
                                text={`Last Donate at: ${calculateTime(userProfile?.lastDonateDate)}`}
                                onClick={() => { }}
                                className='myBtn bg-green border-green py-1.5 text-base'
                            />
                        )}
                    </div>
                </div>
            </div>
            {/* this is search div  */}
            <div className="w-full flex flex-col lg:flex-row items-center gap-2 lg:gap-4 py-2 lg:py-4">
                <div className='w-full lg:w-[20%] flex items-center justify-between gap-2'>
                    <div className="w-[55%] lg:w-full flex items-center border shadow-sm border-grey 2xl:p-2 md:p-1.5 p-1 rounded cursor-pointer">
                        <BiSearchAlt2 className="text-red cursor-pointer 2xl:text-3xl md:text-2xl text-xl font-bold" />
                        <AppSearchBox onChange={handleSearchQuery} placeholder='Search here' className="border-none w-full lg:px-2 text-xs md:text-base" />
                    </div>
                    <div className='w-[45%] lg:hidden flex justify-end'>

                        {canUpdateInfo == true ? (
                            <AppButton
                                text="Add Your Info"
                                onClick={navigateToAddInfo}
                                className='myBtn text-xsm lg:text-sm px-1 lg:px-2 bg-red border-red'
                            />
                        ) : (
                            <AppButton
                                text={`Last Donate at: ${calculateTime(userProfile?.lastDonateDate)}`}
                                onClick={() => { }}
                                className='myBtn text-xsm lg:text-sm px-1 lg:px-2 bg-green border-green'
                            />
                        )}
                    </div>
                </div>
                <div className='w-full lg:w-[80%] flex items-center gap-1 lg:gap-4 lg:justify-between'>
                    <div className='w-1/3'>
                        <AppSelect
                            defaultValue="Select Division"
                            data={divisionIdNameModels}
                            value={divisionId}
                            onChange={handleDivision}
                            errorMessage={divisionError}
                        />
                    </div>
                    <div className='w-1/3'>
                        {divisionId != 0 && (
                            <AppSelect
                                defaultValue="Select District"
                                data={districtsIdNameModels}
                                value={districtId}
                                onChange={handleDistrict}
                                errorMessage={bDistrictError}
                            />
                        )}
                    </div>
                    <div className='w-1/3'>

                        {divisionId != 0 && districtId != 0 && (
                            <AppSelect
                                defaultValue="Select Thana"
                                data={areaIdNameModels}
                                value={areaId}
                                onChange={handleArea}
                                errorMessage={bAreaError}
                            />
                        )}
                    </div>

                </div>
            </div>
            {/* these is header part for desktop */}
            {/* <div className="hidden lg:flex w-full rounded text-xxs lg:text-base">
                <div className="centerDiv rounded-l text-white bg-red font-medium py-1 lg:py-2.5 border-r border-r-grey lg:w-[29.9%]">Name</div>
                <div className="centerDiv text-white bg-red font-medium text-[7px] lg:text-base py-1 lg:py-2.5 border-r border-r-grey w-[14.9%]">Blood Group</div>
                <div className="centerDiv text-white bg-red font-medium py-1 lg:py-2.5 border-r border-r-grey w-[19.8%]">Phone Number</div>
                <div className="centerDiv text-white bg-red font-medium py-1 lg:py-2.5 border-r border-r-grey w-[19.9%]">Thana</div>
                <div className="centerDiv rounded-r text-white bg-red font-medium py-1 lg:py-2.5 w-[15%]">Details</div>
            </div> */}
            <div className="hidden lg:grid xl:grid-cols-4 lg:grid-cols-3  h-[48dvh] 2xl:h-[68dvh] overflow-y-auto gap-6 px-.5 py-1">
                {donors?.items?.map((item: BloodGroupListResponse, index: any) => (
                    <div key={index} className="myBorder border-2 border-gray-200 rounded-xl bg-slate-50 flex justify-between  w-full 2xl:h-[130px] lg:h-20px">
                        <div className='w-1/3 flex justify-center'>
                            <div className=" mt-2 lg:mt-5 2xl:mt-8 h-full whitespace-nowrap overflow-hidden text-ellipsis">
                                <AppDefaultProfilePicture
                                    className="customer-review-image "
                                    name={item?.name}
                                    profilePicture={item?.profilePicUrl}
                                />
                            </div>
                        </div>
                        <div className='w-2/3 flex flex-col'>
                            <div className=' flex justify-between'>
                                <div className='lg:pt-5 2xl:pt-6 '>
                                    <h3 className='font-semibold text-lg lg:text-2xl 2xl:text-3xl '>{item.name}</h3>
                                    {/* <h3 className='flex items-center text-xsm lg:text-base'><MdLocationOn className='text-primary' /> {item.area}</h3> */}
                                </div>
                                <div className="centerDiv pt-2 lg:pt-5 2xl:pt-8 h-full pr-2">
                                    <div className={`hidden lg:flex w-9 h-9 items-center justify-center font-semibold text-white rounded ${item.bloodGroup === "A_Positive" ? "bg-[#ED208A]" : ""} ${item.bloodGroup === "A_Negative" ? "bg-[#0054A6]" : ""} ${item.bloodGroup === "B_Positive" ? "bg-[#F26522]" : ""} ${item.bloodGroup === "B_Negative" ? "bg-[#981B1F]" : ""} ${item.bloodGroup === "O_Positive" ? "bg-[#981B1F]" : ""} ${item.bloodGroup === "O_Negative" ? "bg-[#00AEEF]" : ""} ${item.bloodGroup === "AB_Positive" ? "bg-[#8EC73F]" : ""} ${item.bloodGroup === "AB_Negative" ? "bg-[#92278F]" : ""}`}>
                                        <span>{item.bloodGroup === "A_Positive" ? "A+" : ""} {item.bloodGroup === "A_Negative" ? "A-" : ""} {item.bloodGroup === "B_Positive" ? "B+" : ""} {item.bloodGroup === "B_Negative" ? "B-" : ""} {item.bloodGroup === "O_Positive" ? "O+" : ""} {item.bloodGroup === "O_Negative" ? "O-" : ""} {item.bloodGroup === "AB_Positive" ? "AB+" : ""} {item.bloodGroup === "AB_Negative" ? "AB-" : ""}</span>
                                    </div>
                                    <span className="lg:hidden text-sm font-medium">{item.bloodGroup === "A_Positive" ? "A+" : ""} {item.bloodGroup === "A_Negative" ? "A-" : ""} {item.bloodGroup === "B_Positive" ? "B+" : ""} {item.bloodGroup === "B_Negative" ? "B-" : ""} {item.bloodGroup === "O_Positive" ? "O+" : ""} {item.bloodGroup === "O_Negative" ? "O-" : ""} {item.bloodGroup === "AB_Positive" ? "AB+" : ""} {item.bloodGroup === "AB_Negative" ? "AB-" : ""}</span>
                                </div>
                            </div>
                            <div className=" text-xsm lg:text-base font-medium h-full whitespace-nowrap overflow-hidden text-ellipsis  ">{item.thana}</div>
                            <div className=" text-xsm lg:text-base font-medium 2xl:pt-0 pt-1 h-full ">{item.phoneNumber}</div>
                            {/* <div className=" centerDiv text-xsm flex justify-end lg:text-base mb-2 pr-2 h-full whitespace-nowrap overflow-hidden text-ellipsis rounded-xl">
                                <AppButton text="Details" onClick={() => openDonnerCard(item.userId)} />
                            </div> */}
                        </div>
                    </div>
                ))}
            </div>
            <div className="flex lg:hidden h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-1 py-1">
                {donors?.items?.map((item: BloodGroupListResponse, index: any) => (
                    <div key={index} className="myBorder space-y-1 px-2 py-1" onClick={() => openDonnerCard(item.userId)}>
                        <div className='flex items-center justify-between'>
                            <div className="flex items-center text-xs lg:text-base gap-1">
                                <AppDefaultProfilePicture
                                    className="customer-review-image"
                                    name={item?.name}
                                    profilePicture={item?.profilePicUrl}
                                />
                                <div className='lg:space-y-1'>
                                    <h3 className='font-medium'>{item.name}</h3>
                                </div>

                            </div>

                            <div className="flex items-center">
                                <img className='w-5 h-5' src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/noto_drop-of-blood_jfjvld.png" alt="" />:
                                <div className={`ml-1 flex w-6 h-6 text-xs items-center justify-center font-semibold text-white rounded-sm ${item.bloodGroup === "A_Positive" ? "bg-[#ED208A]" : ""} ${item.bloodGroup === "A_Negative" ? "bg-[#0054A6]" : ""} ${item.bloodGroup === "B_Positive" ? "bg-[#F26522]" : ""} ${item.bloodGroup === "B_Negative" ? "bg-[#981B1F]" : ""} ${item.bloodGroup === "O_Positive" ? "bg-[#981B1F]" : ""} ${item.bloodGroup === "O_Negative" ? "bg-[#00AEEF]" : ""} ${item.bloodGroup === "AB_Positive" ? "bg-[#8EC73F]" : ""} ${item.bloodGroup === "AB_Negative" ? "bg-[#92278F]" : ""}`}>
                                    <span>{item.bloodGroup === "A_Positive" ? "A+" : ""} {item.bloodGroup === "A_Negative" ? "A-" : ""} {item.bloodGroup === "B_Positive" ? "B+" : ""} {item.bloodGroup === "B_Negative" ? "B-" : ""} {item.bloodGroup === "O_Positive" ? "O+" : ""} {item.bloodGroup === "O_Negative" ? "O-" : ""} {item.bloodGroup === "AB_Positive" ? "AB+" : ""} {item.bloodGroup === "AB_Negative" ? "AB-" : ""}</span>
                                </div>
                            </div>
                        </div>

                        <div className='flex items-center justify-between'>
                            <div className="flex items-center text-xsm lg:text-base h-full">
                                <img className='w-3 h-3' src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Vector_kyj8fc.png" alt="" />
                                :<p className='ml-1'>{item.phoneNumber}</p>
                            </div>
                            <h3 className='flex items-center text-xsm gap-1 lg:text-base'><MdLocationOn className='text-primary' />{item.thana}</h3>
                        </div>
                    </div>
                ))}
            </div>
            <div>

            </div>
        </div>
    )
}
