import { POPULAR_LINK_GET_BY_ID, POPULAR_LINK_GET_LIST } from "../../constants/popularLink/popularLink";

const inititalState = {
    data: [],
    dataById: []
}

export function popularLinkByListReducer(state = inititalState, action: any) {
    switch (action.type) {
        case POPULAR_LINK_GET_LIST:
            return { ...state, loading: false, data: action.payload };
        case POPULAR_LINK_GET_BY_ID:
            return { ...state, loading: false, dataById: action.payload };
        default:
            return state;
    }
}