import { GET } from "../service";

export const getAdminDashboardService = () => {
	return GET(`/api/dashboard/GetAdminDashboard`);
};

export const getVendorDashboardService = () => {
	return GET(`/api/dashboard/GetVendorDashboard`);
};

export const getVendorDashboardServiceById = (id: any) => {
	return GET(`/api/dashboard/GetVendorDashboardById?id=${id}`);
};

export const getUpAdminDashboardService = () => {
	return GET(`/api/dashboard/GetUpAdminDashboard`);
};

export const getRiderDashboardService = () => {
	return GET(`/api/dashboard/GetRiderDashboard`);
};