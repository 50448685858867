import { AlertColor } from "@mui/material/Alert/Alert";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PaginationPayloadModel } from "../../../../models/common/paginationModels";
import { RootState } from "../../../../store";
import { getUserListAction } from "../../../../store/actions/auth/address/userActions";
import { getUseRoleListAction } from "../../../../store/actions/auth/address/userRoleActions";
import { debounce } from "lodash";
import _moment from 'moment';
import { IdNameResponseModel } from "../../../../models/common/IdNameModel";
import { divisionList } from "../../../../store/actions/auth/address/divisionActions";
import { areaListByDistrictId } from "../../../../store/actions/auth/address/areaActions";
import { districtListByDivisionId } from "../../../../store/actions/auth/address/districtActions";
import { GetUserListModel } from "../../../../models/auth/user/UserModels";
import { IoMdClose } from "react-icons/io";
import { AdminGetUserTeam } from "./AdminGetUserTeam";
import { AdminVendorDashboard } from "./AdminVendorDashboard";
import { AdminGetUserById } from "./AdminGetUserById";
import { AdminGetUserWallet } from "../wallet/AdminGetUserWallet";
import { AdminGetVendorWallet } from "../wallet/AdminGetVendorWallet";
import { AppButton } from "../../../common/AppButton";
import { AppLoader } from "../../../common/AppLoader";
import { AppModal } from "../../../common/AppModal";
import { AppPagination } from "../../../common/AppPagination";
import { AppSearchBox } from "../../../common/AppSearchBox";
import { AppSelect } from "../../../common/AppSelect";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { isAffiliater, isCountryAdmin, isCustomer, isReseller, isUpazilaAdmin, isVendor, isZilaAdmin } from "../../../../common/common";

export default function AdminGetUserList() {

	const dispatch = useDispatch();

	var getUserModel = new GetUserListModel();

	const [snackBarMessage, setsnackBarMessage] = useState("");
	const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
	const [snackBarOn, setSnackBarOn] = useState(false);
	const [roleId, setRoleId] = useState(0);
	const [roleIdErrorMessage, setRoleIdErrorMessage] = useState("");
	const [searchQueryValue, setSearchQueryValue] = useState("");

	const userList = useSelector((state: RootState) => {
		return state.userListResponse.userList;
	});

	const userRoleList = useSelector((state: RootState) => {
		return state.userRoleListResponse.userRole;
	});

	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				getUserModel.limit = 10;
				getUserModel.offset = 0;
				getUserModel.divisionId = divisionId;
				getUserModel.districtId = districtId;
				getUserModel.areaId = areaId;
				await dispatch(getUserListAction(getUserModel));
				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
			}
		};
		fetchData();
	}, [dispatch]);

	const handleOnRowChange = (payload: PaginationPayloadModel) => {
		const fetchData = async () => {
			try {
				getUserModel.limit = payload.limit;
				getUserModel.offset = 0;
				getUserModel.userRole = roleId;
				getUserModel.divisionId = divisionId;
				getUserModel.districtId = districtId;
				getUserModel.areaId = areaId;
				await dispatch(getUserListAction(getUserModel));
				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
			}
		};
		fetchData();
	}

	const handleOnPageChange = (payload: PaginationPayloadModel) => {
		const fetchData = async () => {
			try {
				getUserModel.limit = payload.limit;
				getUserModel.offset = payload.offset;
				getUserModel.userRole = roleId;
				getUserModel.divisionId = divisionId;
				getUserModel.districtId = districtId;
				getUserModel.areaId = areaId;
				await dispatch(getUserListAction(getUserModel));
				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
			}
		};
		fetchData();
	}

	useEffect(() => {
		dispatch(getUseRoleListAction());
	}, []);

	const onSearch = debounce((searchQuery: string) => {
		const fetchData = async () => {
			try {
				setSearchQueryValue(searchQuery);
				getUserModel.limit = 10;
				getUserModel.offset = 0;
				getUserModel.searchQuery = searchQuery;
				getUserModel.userRole = roleId;
				getUserModel.divisionId = divisionId;
				getUserModel.districtId = districtId;
				getUserModel.areaId = areaId;
				dispatch(getUserListAction(getUserModel));
			} catch (error) {
				setIsLoading(false);
			}
		}
		fetchData();
	}, 500);

	const onFilter = (e: any) => {
		setRoleId(e);
		getUserModel.limit = 10;
		getUserModel.offset = 0;
		getUserModel.searchQuery = searchQueryValue;
		getUserModel.userRole = e;
		getUserModel.divisionId = divisionId;
		getUserModel.districtId = districtId;
		getUserModel.areaId = areaId;
		dispatch(getUserListAction(getUserModel));
	};

	const [userId, setUserId] = useState(0);

	const [isOpenResellerTeamModal, setIsOpenResellerTeamModal] = useState(false);

	const handleUserTeam = (id: any) => {
		setUserId(id);
		setIsOpenResellerTeamModal(true);
	};

	const closeUserTeamModal = () => {
		setIsOpenResellerTeamModal(false);
	};

	const [isOpenVendorDashboardModal, setIsOpenVendorDashboardModal] = useState(false);

	const handleVendorDashboard = (id: any) => {
		setUserId(id);
		setIsOpenVendorDashboardModal(true);
	};

	const closeVendorDashboardModal = () => {
		setIsOpenVendorDashboardModal(false);
	};

	const [isOpenUserDetailsModal, setIsOpenUserDetailsModal] = useState(false);

	const handleUserDetails = (id: any) => {
		setUserId(id);
		setIsOpenUserDetailsModal(true);
	};

	const closeUserDetailsModal = () => {
		setIsOpenUserDetailsModal(false);
	};

	const [isOpenUserWalletModal, setIsOpenUserWalletModal] = useState(false);

	const handleUserWallet = (id: any) => {
		setUserId(id);
		setIsOpenUserWalletModal(true);
	};
	const closeUserWalletModal = () => {
		setIsOpenUserWalletModal(false);
	};

	const [isOpenVendorWalletModal, setIsOpenVendorWalletModal] = useState(false);

	const handleVendorWallet = (id: any) => {
		setUserId(id);
		setIsOpenVendorWalletModal(true);
	};
	const closeVendorWalletModal = () => {
		setIsOpenVendorWalletModal(false);
	};



	const [divisionId, setDivisionId] = useState(0);
	const [districtId, setDistrictId] = useState(0);
	const [areaId, setAreaId] = useState(0);

	const divisions = useSelector((state: RootState) => {
		return state.divisionListResponse.data;
	});
	var divisionIdNameModels: IdNameResponseModel[] = [];

	divisions?.map((x: any) => {
		var division = new IdNameResponseModel();
		division.id = x.id;
		division.name = x.name;
		divisionIdNameModels.push(division);
	});

	const districts = useSelector((state: RootState) => {
		return state.districtListResponse.data;
	});
	var districtsIdNameModels: IdNameResponseModel[] = [];

	districts?.map((x: any) => {
		var district = new IdNameResponseModel();
		district.id = x.id;
		district.name = x.name;
		districtsIdNameModels.push(district);
	});

	const areass = useSelector((state: RootState) => {
		return state.areaListResponse.data;
	});
	var areaIdNameModels: IdNameResponseModel[] = [];

	areass?.map((x: any) => {
		var area = new IdNameResponseModel();
		area.id = x.id;
		area.name = x.name;
		areaIdNameModels.push(area);
	});


	useEffect(() => {
		dispatch(divisionList());
	}, [dispatch]);

	useEffect(() => {
		if (divisionId !== 0) {
			dispatch(districtListByDivisionId(divisionId));
		}
	}, [divisionId, dispatch]);

	useEffect(() => {
		if (districtId !== 0) {
			dispatch(areaListByDistrictId(districtId));
		}
	}, [districtId, dispatch]);

	const handleDivision = (id: number) => {
		setIsLoading(true);
		setDivisionId(id);
		setDistrictId(0)
		setAreaId(0)
		const fetchData = async () => {
			try {
				getUserModel.limit = 10;
				getUserModel.offset = 0;
				getUserModel.divisionId = id;
				getUserModel.userRole = roleId;
				await dispatch(getUserListAction(getUserModel));
				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
			}
		};
		fetchData();
	};

	const handleDistrict = (id: number) => {
		setIsLoading(true);
		setDistrictId(id);
		setAreaId(0)
		const fetchData = async () => {
			try {
				getUserModel.limit = 10;
				getUserModel.offset = 0;
				getUserModel.divisionId = divisionId;
				getUserModel.districtId = id;
				getUserModel.userRole = roleId;
				await dispatch(getUserListAction(getUserModel));
				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
			}
		};
		fetchData();
	};

	const handleArea = (id: number) => {
		setIsLoading(true);
		setAreaId(id)
		const fetchData = async () => {
			try {
				getUserModel.limit = 10;
				getUserModel.offset = 0;
				getUserModel.divisionId = divisionId;
				getUserModel.districtId = districtId;
				getUserModel.areaId = id;
				getUserModel.userRole = roleId;
				await dispatch(getUserListAction(getUserModel));
				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
			}
		};
		fetchData();
	};

	const dateTimeFormatter = (createdDateTime: any) => {
		const createdTime = _moment(createdDateTime, 'YYYY-MM-DD HH:mm:ss.SSSSSSS');
		return createdTime.format('MMM D, YY [at] h:mm A');
	}

	return (
		<div>
			{snackBarOn && (
				<AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
			)}

			{isOpenResellerTeamModal && (
				<AppModal>
					<div className="p-2 md:p-4 md:min-w-full rounded">
						<div className="flex justify-end">
							<IoMdClose
								className="cursor-pointer text-2xl font-semibold text-royalBlue"
								onClick={closeUserTeamModal}
							/>
						</div>
						<AdminGetUserTeam id={userId} />
					</div>
				</AppModal>
			)}

			{isOpenVendorDashboardModal && (
				<AppModal>
					<div className="p-2 md:p-4 md:min-w-full rounded">
						<div className="flex justify-end">
							<IoMdClose
								className="cursor-pointer text-2xl font-semibold text-royalBlue"
								onClick={closeVendorDashboardModal}
							/>
						</div>
						<AdminVendorDashboard id={userId} />
					</div>
				</AppModal>
			)}

			{isOpenUserDetailsModal && (
				<AppModal>
					<div className="p-2 md:p-4 md:min-w-full rounded">
						<div className="flex justify-end">
							<IoMdClose
								className="cursor-pointer text-2xl font-semibold text-royalBlue"
								onClick={closeUserDetailsModal}
							/>
						</div>
						<AdminGetUserById id={userId} />
					</div>
				</AppModal>
			)}

			{isOpenUserWalletModal && (
				<AppModal>
					<div className="p-2 md:p-4 md:min-w-full rounded">
						<div className="flex justify-end">
							<IoMdClose
								className="cursor-pointer text-2xl font-semibold text-royalBlue"
								onClick={closeUserWalletModal}
							/>
						</div>
						<AdminGetUserWallet id={userId} />
					</div>
				</AppModal>
			)}

			{isOpenVendorWalletModal && (
				<AppModal>
					<div className="p-2 md:p-4 md:min-w-full rounded">
						<div className="flex justify-end">
							<IoMdClose
								className="cursor-pointer text-2xl font-semibold text-royalBlue"
								onClick={closeVendorWalletModal}
							/>
						</div>
						<AdminGetVendorWallet id={userId} />
					</div>
				</AppModal>
			)}

			<h2 className="title text-center">User List ({userList?.size})</h2>
			<div className="grid grid-cols-3 lg:grid-cols-5 gap-1 lg:gap-4 py-1 lg:py-2">
				{/* <AppButton onClick={() => navigate("/admin/auth/user/add")} text="Add User" /> */}
				<AppSearchBox className="mySearchBox" onChange={onSearch} placeholder="Search Here" />

				<AppSelect
					data={userRoleList}
					defaultValue="User Role"
					value={roleId}
					onChange={onFilter}
					errorMessage={roleIdErrorMessage}
				/>
				<AppSelect
					defaultValue="Select Division"
					data={divisionIdNameModels}
					value={divisionId}
					onChange={handleDivision}
				/>

				{divisionId !== 0 && (
					<AppSelect
						defaultValue="Select District"
						data={districtsIdNameModels}
						value={districtId}
						onChange={handleDistrict}
					/>
				)}

				{divisionId !== 0 && districtId !== 0 && (
					<AppSelect
						defaultValue="Select Thana"
						data={areaIdNameModels}
						value={areaId}
						onChange={handleArea}
					/>
				)}

			</div>

			{isLoading ? (
				<AppLoader status={true} />
			) : (
				<>
					{userList?.items ? (
						<>
							<div className="w-full">
								{/* these is header part for desktop */}
								<div className="hidden lg:flex w-full bg-primary myBorder">
									<div className="centerDiv text-white font-medium py-2.5 border-r w-[18%]">Name</div>
									<div className="centerDiv text-white font-medium py-2.5 border-r w-[20.9%]">Joined At</div>
									<div className="centerDiv text-white font-medium py-2.5 border-r w-[15.8%]">Phone</div>
									<div className="centerDiv text-white font-medium py-2.5 border-r w-[11.9%]">User Role</div>
									<div className="centerDiv text-white font-medium py-2.5 border-r w-[10%]">T/D</div>
									<div className="centerDiv text-white font-medium py-2.5 border-r w-[10%]">Details</div>
									<div className="centerDiv text-white font-medium py-2.5  w-[13%]">Wallet</div>
								</div>
								{/* these is middle part for desktop */}
								<div className="hidden lg:flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-1 py-1.5">
									{userList?.items?.map((user: any) => (
										<div key={user?.id} className="myBorder flex w-full items-center">
											<div className="centerDiv justify-start border-r border-grey pl-2 py-1 h-full whitespace-nowrap overflow-hidden text-ellipsis  w-[18%]">{user?.firstName} {user?.userRole == "VENDOR" ? `Shop: ${user?.shopName}` : ""}</div>
											<div className="centerDiv justify-start border-r border-grey pl-2 py-1 h-full whitespace-nowrap overflow-hidden text-ellipsis  w-[21%]">{dateTimeFormatter(user?.joinedAt)}</div>
											<div className="centerDiv justify-start border-r border-grey pl-2 py-1 h-full whitespace-nowrap overflow-hidden text-ellipsis  w-[16%]">{user?.phoneNumber}</div>
											<div className="centerDiv border-r border-grey py-1 h-full whitespace-nowrap overflow-hidden text-ellipsis  w-[12%]">{user?.userRoles?.map((x: any) => x.name).join(', ')}</div>
											<div className="centerDiv border-r border-grey py-1 h-full w-[10%]">
												{(isReseller(user?.userRoles?.map((x: any) => x.name)) ||
													isAffiliater(user?.userRoles?.map((x: any) => x.name))
												) ? (
													<AppButton
														text="Team"
														className="myBtn py-0.5 lg:py-1 text-sm"
														onClick={() => handleUserTeam(user?.id)}
													/>
												) : (isVendor(user?.userRoles?.map((x: any) => x.name))) ? (
													<AppButton
														text="Dashboard"
														className="myBtn py-0.5 lg:py-1 text-sm"
														onClick={() => handleVendorDashboard
															(user?.id)}
													/>
												) : (
													<>
														<AppButton
															text="N/A"
															className="myBtn py-0.5 bg-red border-red lg:py-1 text-sm"
															onClick={() => { }}
														/>
													</>
												)}

											</div>
											<div className="centerDiv border-r border-grey py-1 h-full w-[10%]">
												<AppButton
													text="Details"
													className="myBtn py-0.5 lg:py-1 text-sm"
													onClick={() => handleUserDetails(user?.id)}
												/>
											</div>
											<div className="centerDiv w-[13%]">
												{(isReseller(user?.userRoles?.map((x: any) => x.name)) || isCountryAdmin(user?.userRoles?.map((x: any) => x.name)) || isZilaAdmin(user?.userRoles?.map((x: any) => x.name)) || isUpazilaAdmin(user?.userRoles?.map((x: any) => x.name)) || isCustomer(user?.userRoles?.map((x: any) => x.name))) ? (
													<div onClick={() => handleUserWallet(user?.id)} className="myBtn py-0.5 lg:py-1 text-sm" >
														Wallet
													</div>
												) : (isVendor(user?.userRoles?.map((x: any) => x.name))) ? (
													<div onClick={() => handleVendorWallet(user?.id)} className="myBtn py-0.5 lg:py-1 text-sm">
														Wallet
													</div>
												) :
													(
														<>
															<AppButton
																text="N/A"
																className="myBtn py-0.5 bg-red border-red lg:py-1 text-sm"
																onClick={() => { }}
															/>
														</>
													)}
											</div>
										</div>
									))}
								</div>
								{/* these is middle part for mobile */}
								<div className="h-[78dvh] lg:hidden overflow-y-auto flex flex-col gap-2">
									{userList?.items?.map((user?: any) => (
										<div key={user?.id} className="myBorder text-xs flex flex-col w-full p-1">
											<div className='grid grid-cols-2'>
												<div className="flex items-center gap-0.5">
													<span className="font-medium">Name:</span> <span>{user?.firstName} {user?.userRole == "VENDOR" ? `Shop: ${user?.shopName}` : ""}</span>
												</div>
												<div className="flex items-center gap-0.5">
													<span className="font-medium">Joined At:</span> <span>{dateTimeFormatter(user?.joinedAt)}</span>
												</div>
											</div>
											<div className='grid grid-cols-2'>
												<div className="flex items-center gap-0.5">
													<span className="font-medium">Phone:</span> <span>{user?.phoneNumber}</span>
												</div>
												<div className="flex items-center gap-0.5">
													<span className="font-medium">User Role:</span> <span> {user?.userRole}</span>
												</div>
											</div>

											{/* these is approve decline details div */}
											<div className="flex items-center justify-between gap-4 w-full pt-2">
												{(isReseller(user?.userRoles?.map((x: any) => x.name)) ||
													isAffiliater(user?.userRoles?.map((x: any) => x.name))
												) ? (
													<AppButton
														text="Team"
														className="myBtn py-0.5 lg:py-1 text-sm"
														onClick={() => handleUserTeam(user?.id)}
													/>
												) : (isVendor(user?.userRoles?.map((x: any) => x.name))) ? (
													<AppButton
														text="Dashboard"
														className="myBtn py-0.5 lg:py-1 text-sm"
														onClick={() => handleVendorDashboard
															(user?.id)}
													/>
												) : (
													<>
														<AppButton
															text="N/A"
															className="myBtn py-0.5 bg-red border-red lg:py-1 text-sm"
															onClick={() => { }}
														/>
													</>
												)}
												<AppButton
													text="Details"
													className="myBtn py-0.5 lg:py-1 text-sm"
													onClick={() => handleUserDetails(user?.id)}
												/>
												{(isReseller(user?.userRoles?.map((x: any) => x.name)) || isAffiliater(user?.userRoles?.map((x: any) => x.name))) ? (
													<div onClick={() => handleUserWallet(user?.id)} className="myBtn py-0.5 lg:py-1 text-sm" >
														Wallet
													</div>
												) : (isVendor(user?.userRoles?.map((x: any) => x.name))) ? (
													<div onClick={() => handleVendorWallet(user?.id)} className="myBtn py-0.5 lg:py-1 text-sm">
														Wallet
													</div>
												) : (
													<>
														<AppButton
															text="N/A"
															className="myBtn py-0.5 bg-red border-red lg:py-1 text-sm"
															onClick={() => { }}
														/>
													</>
												)}
											</div>
										</div>
									))}
								</div>
								{/* these is footer part  */}
								{/* <div className="myBorder py-1 text-center">pagination here</div> */}
							</div>
							<div className="py-1 flex items-center">
								<AppPagination curPage={1} limit={10} offset={0} onPageChange={handleOnPageChange} onRowChange={handleOnRowChange} size={userList?.size} />
							</div>
						</>
					) : (
						<div className="w-full h-[50dvh] flex justify-center items-center text-3xl text-textBlack/90 font-medium">
							<span>No Users found</span>
						</div>
					)}
				</>)}
		</div>
	);
}
