import {
  CartItemSelectionRequest,
  GetCartListAdminModel,
  IncreseDecreseCartItemRequest,
  RemoveSingleOrListCartitemRequest,
  ShoppingCartType
} from "../../models/shoppingCart/resellingCartModels";
import { DELETE, GET, POST, PUT } from "../service";


export const affiliateAddToCartService = (data: any) => {
  return POST("/api/AffiliateCart/AddToCart", data);
};

export const addOrRemoveAffiliateFavouriteService = (data: any) => {
  return POST("/api/AffiliateFavorite/AddOrRemoveAffiliateFavorite", data);
};

export const isAlreadyAffiliateFavouriteService = (id: any) => {
  return GET(`/api/AffiliateFavorite/GetIsAlreadyAffiliateFavoriteOrNot?productId=${id}`);
};

export const getAffiliateFavouriteItemsService = (data: any) => {
  return GET(`/api/AffiliateFavorite/GetAffiliateFavoriteItems?searchQuery=${data}`);
};

export const getAffiliateCartItemsService = () => {
  return GET(`/api/AffiliateCart/GetCartItems`);
};

export const increaseDecreaseAffiliateCartItemService = (data: IncreseDecreseCartItemRequest) => {
  return PUT(`/api/AffiliateCart/IncreseOrDecreseProduct`, data);
};

export const removeSingleOrListAffiliateCartitemService = (data: RemoveSingleOrListCartitemRequest) => {
  return DELETE(`/api/AffiliateCart/RemoveItemSingleOrListItemFromCart`, { data });
};

export const getAffiliateCartListAdminService = (payload?: GetCartListAdminModel) => {
  if (payload) {
    return GET(
      `/api/AffiliateCart/GetCartList?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}&startDateTime=${payload.startDateTime}&endDateTime=${payload.endDateTime}&shoppingCartType=${payload.shoppingCartType}`
    );
  }
  return GET(`/api/AffiliateCart/GetCartList`);
};

export const getAffiliateCartItemsAdminService = (userId: any) => {
  return GET(`/api/AffiliateCart/GetCartItems?userId=${userId}&CartType=${ShoppingCartType.ShoppingCart}`);
};


export const affiliateCartItemsSelectionService = (items: CartItemSelectionRequest[]): Promise<{ data: string }> => {
  return PUT(`/api/AffiliateCart/CartItemSelection`, {
    cartItemSelectionList: items
  });
};

export const getAffiliateTotalShippingChargeService = (InSideDhaka: any) => {
  return GET(`/api/AffiliateCart/getTotalShippingCharge?InSideDhaka=${InSideDhaka}`);
};