import { AlertColor } from "@mui/material";
import { useEffect, useState } from "react";
import { TbCurrencyTaka } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../store";
import _moment from "moment";
import { getDataOfferInvoiceByIdAction } from "../../../store/actions/invoices/orderInvoicesActions";
import { AppLoader } from "../../common/AppLoader";
import { AppSnackBar } from "../../common/AppSnackBar";

export default function IVDataOfferInvoice() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();

  const invoiceDetails = useSelector((state: RootState) => {
    return state.getOrderInvoiceByIdResponse.data;
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await dispatch(getDataOfferInvoiceByIdAction(id));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch, id]);

  const navigateToProductDetails = (id?: any) => {
    navigate(`/admin/catalog/product-details/${id}`, {
      replace: true,
    });
  };

  const orderTimeFormatter = (createdDateTime: any) => {
    const createdTime = _moment(createdDateTime, "YYYY-MM-DD HH:mm:ss.SSSSSSS");
    return createdTime.format("MMM D, YY [at] h:mm A");
  };

  if (isLoading) {
    return (
      <div className="container mx-auto flex h-screen items-center justify-center px-2 pb-12 md:px-0">
        <AppLoader status={true} />
      </div>
    );
  }

  return (
    <div className="container mx-auto px-0 pb-8 md:px-16 md:py-4">
      {isShowSnackBar && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      <div className="flex flex-col items-center pb-2 lg:flex-row lg:items-center lg:justify-between">
        <h2 className="title">Invoice ({invoiceDetails?.paidStatus})</h2>
      </div>

      {invoiceDetails?.dataOffer && (
        <section className="myBorder my-2 lg:my-4">
          {/* these is shop info  */}
          <div className="border-b border-grey px-2 py-1 lg:px-4">
            <div className="flex items-center justify-between">
              <h3 className="text-xs lg:text-base">
                <span className="font-medium">
                  {invoiceDetails?.dataOffer?.vendorName}
                </span>
              </h3>
            </div>            
          </div>

          <div className="w-full px-2 lg:px-4">
            <div className="flex items-center justify-between text-xs font-medium lg:text-base">
              <div className="w-[20%] lg:w-[20%]">
                <span>Package</span>
              </div>
              <div className="w-[20%] lg:w-[20%]">
                <span>Offer Type</span>
              </div>
              <div className="w-[15%] lg:w-[15%]">
                <span>Operator</span>
              </div>
              <div className="w-[15%] lg:w-[15%]">
                <span>Package Type</span>
              </div>
              <div className="w-[15%] lg:w-[15%]">
                <span>Duration</span>
              </div>
              <div className="w-[15%] lg:w-[15%]">
                <span>Price</span>
              </div>             
            </div>
            {invoiceDetails?.dataOffer && (
              <div className="flex items-center justify-between py-1.5 text-xs font-normal lg:text-base">
                <div className="w-[20%] lg:w-[20%]">                  
                  <span>
                      {invoiceDetails?.dataOffer?.title}                
                  </span>
                </div>

                <div className="w-[20%] lg:w-[20%]">
                  <span>
                  {invoiceDetails?.dataOffer?.dataOfferTypeName}
                  </span>
                </div>
                
                <div className="w-[15%] lg:w-[15%]">
                  <span>
                  {invoiceDetails?.dataOffer?.mobileOperatorName}
                  </span>
                </div>
                
                <div className="w-[15%] lg:w-[15%]">
                  <span>
                  {invoiceDetails?.dataOffer?.dataPackageTypeName}
                  </span>
                </div>
               
                <div className="w-[15%] lg:w-[15%]">
                  <span >
                  {invoiceDetails?.dataOffer?.validityDuration}
                  </span>
                </div>

                <div className="w-[15%] lg:w-[15%]">
                  <span className="flex items-center">
                    <TbCurrencyTaka /> {invoiceDetails?.dataOffer?.price}
                  </span>
                </div>
                
              </div>
          )}
          </div>
        </section>
      )}
      <div className="amounts">
        <div className="col-12 flex justify-end">
          <div className="col-6">Subtotal:</div>
          <div className="col-6">
            <span className="flex items-center font-normal md:font-medium">
              <TbCurrencyTaka />
              {invoiceDetails?.totalAmount}
            </span>
          </div>
        </div>
        <div className="col-12 flex justify-end">
          <div className="col-6">Vat:</div>
          <div className="col-6">
            <span className="flex items-center font-normal md:font-medium">
              <TbCurrencyTaka />
              {invoiceDetails?.vat}
            </span>
          </div>
        </div>
        <hr />
        <div className="col-12 flex justify-end">
          <div className="mx-2">Total Payable Amount: </div>
          <div className="col-6">
            <span className="flex items-center font-normal md:font-medium">
              <TbCurrencyTaka />
              {invoiceDetails?.totalAmount}
            </span>
          </div>
        </div>
      </div>
      <br />
      <div className="invoice-details my-5">
        <div className="col-12 flex justify-start">
          <div className="col-6 mx-2">Invoice Number:</div>
          <div className="col-6">
            <span className="flex items-center font-normal md:font-medium">
              {invoiceDetails?.invoiceNumber}
            </span>
          </div>
        </div>
        <div className="col-12 flex justify-start">
          <div className="col-6 mx-2">Due Date: </div>
          <div className="col-6">
            <span className="flex items-center font-normal md:font-medium">
              {orderTimeFormatter(invoiceDetails?.invoiceDateTime)}
            </span>
          </div>
        </div>
      </div>
      <div className="invoice-details my-5">
        <div className="title font-large">Account Details:</div>
        <div className="col-12 flex justify-start">
          <div className=" mx-2">Payment Method</div>
          <div className="col-6">
            <span className="flex items-center font-normal md:font-medium">
              {invoiceDetails?.paymentMethod?.length>0?invoiceDetails?.paymentMethod.length:"Bkash"}
            </span>
          </div>
        </div>
        <div className="col-12 flex justify-start">
          <div className="col-6 mx-2">Account Number: </div>
          <div className="col-6">
            <span className="flex items-center font-normal md:font-medium">
              {invoiceDetails?.paymentAccount?.length > 0
                ? invoiceDetails?.paymentAccount
                : invoiceDetails?.resellerPhoneNumber}
            </span>
          </div>
        </div>
      </div>
      <div className="invoice-details my-5">
        <div className="title font-large">Receiving Number:</div>
        <div className="col-12 flex justify-end">
          <div>
            <p className="items-center font-normal md:font-medium">
              {invoiceDetails?.phoneNumber}
            </p>
           
          </div>
        </div>
      </div>
      <div className="invoice-details my-5">
        <div className="title font-large">Purchased By:</div>
        <div className="col-12 flex justify-end">
          <div>
            <p className="items-center font-normal md:font-medium">
              {invoiceDetails?.customerName}
            </p>
            <p className="items-center font-normal md:font-medium">
              {invoiceDetails?.resellerPhoneNumber}
            </p>
          </div>
        </div>
      </div>
      <div className="invoice-details my-5">
        <div className="title font-large">Sold By:</div>
        <div className="col-12 flex justify-end">
          <div>
            <p className="items-center font-normal md:font-medium">
              {invoiceDetails?.vendorName}
            </p>
            <p className="items-center font-normal md:font-medium">
              {invoiceDetails?.dataOffer?.shopNumber}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
