const initialState = {
    signIn: false,
  };
  
  function signInReducer(state = initialState, action: any) {
    if(action.type==="OPEN-SIGN-IN"){
      return {signIn:true}
    }
    if(action.type==="CLOSE-SIGN-IN"){
      return{signIn:false}
    }
    return state;
  }
  
  export { signInReducer };