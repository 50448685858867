import React, { useState } from "react";
import { AppInput } from "../../common/AppInput";
import { AppSelect } from "../../common/AppSelect";
import { AppButton } from "../../common/AppButton";
import { CreateDataOfferRequest } from "../../../models/dataOffer/dataOfferModels";
import { creatDataOfferService } from "../../../httpService/dataOffer/dataOfferServices";
import { useNavigate } from "react-router";
import { AppCheckBox } from "../../common/AppCheckBox";

const dataOfferTypes = [
  { id: 1, name: "Ajker Offer" },
  { id: 2, name: "Protidiner Offer" },
];

const mobileOperators = [
  { id: 1, name: "GrameenPhone" },
  { id: 2, name: "BanglaLink" },
  { id: 3, name: "Robi" },
  { id: 4, name: "Airtel" },
  { id: 5, name: "Skitto" },
  { id: 6, name: "Teletalk" },
];

const dataPackageTypes = [
  { id: 1, name: "Minutes" },
  { id: 2, name: "Internet" },
  { id: 3, name: "CallRate" },
  { id: 4, name: "CashBack" },
  { id: 5, name: "Bundle" },
];

const durations = [
  { id: "Hours", name: "Hours" },
  { id: "Days", name: "Days" },
  { id: "Months", name: "Months" },
];

export default function AdminCreateDataOffer() {
  
  const navigate = useNavigate();
  const [dataOfferType, setDataOfferType] = useState(0);
  const [mobileOperator, setMobileOperator] = useState(0);
  const [dataPackageType, setDataPackageType] = useState(0);
  const [price, setPrice] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [validityDuration, setValidityDuration] = useState(0);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [duration, setDuration] = useState(0);

  const [priceError, setPriceError] = useState("");
  const [validityDurationError, setValidityDurationError] = useState("");
  const [durationError, setDurationError] = useState("");
  const [dataOfferTypeError, setDataOfferTypeError] = useState("");
  const [mobileOperatorError, setMobileOperatorError] = useState("");
  const [dataPackageTypeError, setDataPackageTypeError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
 

  const handleDataOfferType = (id: any) => {
    setDataOfferType(id);
    setDataOfferTypeError("");
  };

  const handleMobileOperator = (id: any) => {
    setMobileOperator(id);
    setMobileOperatorError("")
  };

  const handleDataPackgetype = (id: any) => {
    setDataPackageType(id);
    setDataPackageTypeError("")
  };

  const handleTitle = (event: any) => {
    setTitle(event);
    setTitleError("");
  };

  const handleDescription = (event: any) => {
    setDescription(event);
    setDescriptionError("");
  };

  const handlePrice = (event: any) => {
    setPrice(event);
    setPriceError("");
  };

  const handleDuration = (event: any) => {
    setValidityDuration(event);
    setValidityDurationError("");
  };

  const handleSelectDuration = (event: any) => {
    setDuration(event);
    setDurationError("");
  };


  const handleSubmit = (event: any) => {
    let canExecute = true;

    if (title == "" || title == null || title == undefined) {
      setTitleError("Title is Requied")
      canExecute = false;
    }

    if (price == 0 || price == null || price == undefined) {
      setPriceError("Price is Required")
      canExecute = false;
    }

    if (validityDuration == 0 || validityDuration == null || validityDuration == undefined) {
      setValidityDurationError("Duration value is Required")
      canExecute = false;
    }

    if (duration == 0 || duration == null || duration == undefined) {
      setDurationError("Duration time is Required")
      canExecute = false;
    }

    if (dataOfferType == 0 || dataOfferType == null || dataOfferType == undefined) {
      setDataOfferTypeError("Data Offer type is Required")
      canExecute = false;
    }

    if (dataPackageType == 0 || dataPackageType == null || dataPackageType == undefined) {
      setDataPackageTypeError("Data Package type is Required")
      canExecute = false;
    }

    if (mobileOperator == 0 || mobileOperator == null || mobileOperator == undefined) {
      setMobileOperatorError("Mobile Operator is Required")
      canExecute = false;
    }

    if (canExecute) {
      event.preventDefault();
      var model = new CreateDataOfferRequest();
      model.dataOfferType = dataOfferType;
      model.dataPackageType = dataPackageType;
      model.mobileOperator = mobileOperator;
      model.price = price;
      model.title = title;
      model.validityDuration = validityDuration + " " + duration;
      model.description = description;
      model.isActive = isActive;
      creatDataOfferService(model)
        .then(() => {
          navigate("/admin/data-offer-vendor/data-offer");
        })
        .catch(() => { });
    }
  };

  return (
    <>
      <div className="px-4 pt-4">
        <AppButton
          className="myOfferBtn text-xs lg:text-sm"
          onClick={() => navigate("/admin/data-offer-vendor/data-offer")}
          text="Back"
        />
      </div>
      <div className='max-w-2xl mx-auto mt-2 lg:mt-0 p-2 lg:p-4 space-y-2 shadow-md myBorder'>
        <h2 className="title text-center">Create Data Offer</h2>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
          <AppInput
            type="text"
            label="Title"
            className="myInput"
            onChange={handleTitle}
            errorMessage={titleError}
          />
          <AppInput
            type="text"
            label="Description"
            className="myInput"
            onChange={handleDescription}
            errorMessage={descriptionError}
          />
          <AppInput
            type="number"
            label="Price"
            className="myInput"
            onChange={handlePrice}
            errorMessage={priceError}
          />
          <AppInput
            type="number"
            label="Duration"
            className="myInput"
            onChange={handleDuration}
            errorMessage={validityDurationError}
          />
          <AppSelect
            defaultValue="Select Duration"
            value={duration}
            data={durations}
            onChange={handleSelectDuration}
            errorMessage={durationError}
          />
          <AppSelect
            defaultValue="Offer type"
            value={dataOfferType}
            data={dataOfferTypes}
            onChange={handleDataOfferType}
            errorMessage={dataOfferTypeError}
          />
          <AppSelect
            defaultValue="Mobile Operator"
            value={mobileOperator}
            data={mobileOperators}
            onChange={handleMobileOperator}
            errorMessage={mobileOperatorError}
          />
          <AppSelect
            defaultValue="Package Type"
            value={dataPackageType}
            data={dataPackageTypes}
            onChange={handleDataPackgetype}
            errorMessage={dataPackageTypeError}
          />
          <div className="flex items-center gap-x-2">
            <label htmlFor="isActive" className="text-xl">
              Is Active{" "}
            </label>
            <AppCheckBox
              isChecked={isActive}
              onChange={() => setIsActive(!isActive)}
            />
          </div>
        </div>
        <div className="flex items-center justify-end">
          <AppButton className="myOfferBtn" text="Submit" onClick={handleSubmit} />
        </div>
      </div>
    </>
  );
}
