import * as React from 'react';
import { useState } from 'react';

interface ISearchBoxProps {
  onChange?: any;
  placeholder?: string;
  className?: string;
  isIcon?: boolean;
  value?: any;
}

export const AppSearchBox: React.FC<ISearchBoxProps> = ({ onChange, placeholder, className, isIcon, value }) => {

  const [searchQuery, setSearchQuery] = React.useState('');
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);
  const handleChange = (e: any) => {
    const newValue = e.target.value;
    setSearchQuery(newValue);

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    const newTimer = setTimeout(() => {
      if (onChange) {
        onChange(newValue);
      }
    }, 300);

    setDebounceTimer(newTimer);
  }

  return (
    // <Paper
    //   component="form"
    //   sx={{ p: '2px 4px 4px 4px', display: 'flex', alignItems: 'center', width: 400 }}
    // >
    //   {isIcon &&
    //     <IconButton type="submit" sx={{ p: '10px' }} aria-label="menu">
    //       <CameraAltIcon />
    //     </IconButton>
    //   }
    //   <InputBase
    //     sx={{ ml: 1, flex: 1 }}
    //     placeholder={placeholder}
    //     onChange={handleChange}
    //   />
    //   <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
    //     <SearchIcon />
    //   </IconButton>
    // </Paper>
    <>
      <input
        onChange={handleChange}
        placeholder={placeholder}
        value={value}
        className={`${className ? className : "input-design"} always`}></input>
    </>
  )
}
