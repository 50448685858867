import React, { useEffect, useState } from 'react'
import { AppInput } from '../../common/AppInput'
import { AppCheckBox } from '../../common/AppCheckBox'
import { CreateOrUpdateMagazineMainCategoryModel } from '../../../models/magazine/magazineCategoryModels';
import { createMagazineCategoryService, getMagazineCategoryListService, removeMagazineCategoryByIdService } from '../../../httpService/magazine/magazineCategoryServices';
import { AppButton } from '../../common/AppButton';
import { PaginationPayloadModel } from '../../../models/common/paginationModels';
import { useDispatch, useSelector } from 'react-redux';
import { magazineCategoryList } from '../../../store/actions/magazine/magazineCategoryActions';
import { RootState } from '../../../store';
import { AlertColor, Button } from '@mui/material';
import { AppSnackBar } from '../../common/AppSnackBar';
import { useNavigate } from 'react-router';
import { PhotoModel } from '../../../models/bechteChai/product/bechteChaiProductModels';
import { AppFileInput } from '../../common/AppFileInput';

export const MagazineCreateMagazineCategory = () => {

    const magazineCategoryListData = useSelector((state: RootState) => {
        return state.magazineCategoryListResponse.data;
    })

    const [snackBarMessage, setsnackBarMessage] = useState("");
    const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
    const [snackBarOn, setSnackBarOn] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(magazineCategoryList(pModel))
    }, [])


    var pModel = new PaginationPayloadModel();
    pModel.limit = 1000;
    pModel.offset = 0;


    const [categoryName, setCategoryName] = useState('');
    const [categoryNameError, setCategoryNameError] = useState('');


    const [categoryDescription, setCategoryDescription] = useState('');
    const [categoryDescriptioError, setCategoryDescriptionError] = useState('');

    const [isPublished, setIsPublished] = useState(false);
    const [isShowOnHomePage, setIsShowOnHomePage] = useState(false);

    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const [bannerId, setBannerId] = useState(0);
    const [bannerError, setBannerError] = useState("");

    const [productMedias, setProductMedias] = useState<PhotoModel[]>([]);
    const [photoIds, setPhotoIds] = useState<any[]>([]);

    const handleBanner = (id: any, path: any) => {
        const photoModel = new PhotoModel();
        photoModel.id = id;
        photoModel.url = path;
        setBannerId(id);
        setBannerError("");
        setProductMedias(prevMedias => [...prevMedias, photoModel]);
        setPhotoIds(prevPhotoId => [...prevPhotoId, id]);
        console.log(id);
        console.log(path);
    };


    const categoryNameHandler = (value: any) => {
        setCategoryName(value);
        setCategoryNameError('');
    }

    const categoryDescriptionHandler = (value: any) => {
        setCategoryDescription(value);
        setCategoryDescriptionError('');
    }

    const handleClearForm = () => {
        setCategoryName('');
        setCategoryDescription('');
        setIsPublished(false);
        setIsShowOnHomePage(false);
    }

    const handleCategoryDelete = (value: any) => {
        removeMagazineCategoryByIdService(value)
            .then(() => {
                dispatch(magazineCategoryList(pModel))
            })
    }

    const navigate = useNavigate();

    const handelNagivateToCategoryDetails = (id: any) => {
        navigate(`/updateMagazineCategory/${id}`);
    }




    const handleSubmit = (e: any) => {
        e.preventDefault();

        var model = new CreateOrUpdateMagazineMainCategoryModel();

        model.Name = categoryName;
        model.Description = categoryDescription;
        model.IsPublished = isPublished;
        model.IsShowOnHomePage = isShowOnHomePage;
        model.BannerId = bannerId;

        createMagazineCategoryService(model)
            .then((res: any) => {
                setSnackBarOn(true);
                setsnackBarMessage("Category  Added Successfully.");
                setSnackBarMessageType("success");
                setIsButtonLoading(false);
                handleClearForm();
                dispatch(magazineCategoryList(pModel))
            })
            .catch((err: any) => {
                setIsButtonLoading(false);
                setCategoryNameError(err.response.data.errors?.Name?.[0]);
                setCategoryDescriptionError(err.response.data.errors?.BannerId?.[0]);
            })
    }
    return (
        <div>
            {snackBarOn && (
                <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
            )}
            <div>
                <h1>Create Magazine Category</h1>
                <AppInput
                    type="text"
                    className="categoryName"
                    label="Category name"
                    placeholder="Enter category name"
                    value={categoryName}
                    onChange={categoryNameHandler}
                    errorMessage={categoryNameError}
                />
                <AppInput
                    type="text"
                    className="categoryDescription"
                    label="Category description"
                    placeholder="Enter category description"
                    value={categoryDescription}
                    onChange={categoryDescriptionHandler}
                    errorMessage={categoryDescriptioError}
                />
                <label>Is published: </label>
                <AppCheckBox
                    isChecked={isPublished}
                    onChange={(value: any) => setIsPublished(value)}
                />
                <br />
                <label>Is shown on homepage: </label>
                <AppCheckBox
                    isChecked={isShowOnHomePage}
                    onChange={(value: any) => setIsShowOnHomePage(value)}
                />
                <br />
                <AppFileInput onSave={handleBanner} errorMessage={bannerError} />
                <br />
                <AppButton
                    className="createMagazineCategory"
                    text="Save"
                    variant="contained"
                    isSubmitting={isButtonLoading}
                    color="primary"
                    onClick={(e: any) => handleSubmit(e)}
                />
            </div>
            <br />
            <div>

                <h1>Category List</h1>
                {magazineCategoryListData &&
                    (
                        magazineCategoryListData?.map((cat: any, index: any) =>
                            <div>
                                <div className="w-2/5">
                                    <img className="rounded-l" src={cat.bannerPath} alt="Category Pic" loading="lazy" />
                                </div>
                                <h2>{cat.name}</h2>
                                <h2>{cat.description}</h2>
                                <h2>{cat.isPublished}</h2>
                                <h2>{cat.isShowOnHomePage}</h2>
                                <AppButton
                                    className="createMagazinePost"
                                    text="Delete"
                                    variant="contained"
                                    isSubmitting={isButtonLoading}
                                    color="primary"
                                    onClick={() => handleCategoryDelete(cat.id)}
                                />
                                <Button onClick={() => handelNagivateToCategoryDetails(cat.id)}>Update</Button>
                            </div>
                        )
                    )}
            </div>
        </div>
    )
}
