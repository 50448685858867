export const GET_ADDRESS_LIST = "GET_AREA_LIST";

export const GET_WARD_LIST = "GET_CITY_CORPORATION_LIST";

export const GET_CITY_CORPORATION_LIST = "GET_CITY_CORPORATION_LIST";

export const GET_AREA_LIST = "GET_AREA_LIST";

export const GET_AREA_ADMIN_LIST = "GET_AREA_ADMIN_LIST";

export const GET_DIVISION_LIST = "GET_DIVISION_LIST";

export const GET_DISTRICT_LIST = "GET_DISTRICT_LIST";

export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";

export const GET_BILLING_ADDRESS_LIST = "GET_CITY_CORPORATION_LIST";

export const GET_SHIPPING_ADDRESS_LIST = "GET_CITY_CORPORATION_LIST";