export const GET_MAIN_CATEGORY_LIST = 'GET_MAIN_CATEGORY_LIST';
export const GET_MAIN_CATEGORY_LIST_BY_ID = 'GET_MAIN_CATEGORY_LIST_BY_ID';

export const GET_SUB_CATEGORY__ONE_LIST = 'GET_SUB_CATEGORY__ONE_LIST'
export const GET_SUB_CATEGORY__TWO_LIST = 'GET_SUB_CATEGORY__TWO_LIST'

export const ADD_MAIN_CATEGORY = 'ADD_MAIN_CATEGORY';
export const ADD_SUB_CATEGORY_ONE = 'ADD_SUB_CATEGORY_ONE';
export const ADD_SUB_CATEGORY_TWO = 'ADD_SUB_CATEGORY_TWO';

export const UPDATE_MAIN_CATEGORY = 'UPDATE_MAIN_CATEGORY';
export const UPDATE_SUB_CATEGORY_ONE = 'UPDATE_SUB_CATEGORY_ONE';
export const UPDATE_SUB_CATEGORY_TWO = 'UPDATE_SUB_CATEGORY_TWO';

export const REMOVE_MAIN_CATEGORY = 'REMOVE_MAIN_CATEGORY';
export const REMOVE_SUB_CATEGORY_ONE = 'REMOVE_SUB_CATEGORY_ONE';
export const REMOVE_SUB_CATEGORY_TWO = 'REMOVE_SUB_CATEGORY_TWO';