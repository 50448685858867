export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';
export const GET_PRODUCT_BY_ID = 'GET_PRODUCT_BY_ID';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const GET_PRODUCT_ATTRIBUTE_MAPPING = 'GET_PRODUCT_ATTRIBUTE_MAPPING';
export const GET_PRODUCT_ATTRIBUTE_VALUES = 'GET_PRODUCT_ATTRIBUTE_VALUES';
export const GET_PRODUCT_ATTRIBUTE_VALUE = 'GET_PRODUCT_ATTRIBUTE_VALUE';
export const GET_PRODUCT_ATTRIBUTE_MAPPING_BY_ID = 'GET_PRODUCT_ATTRIBUTE_MAPPING_BY_ID';
export const GET_PRODUCT_ATTRIBUTE_VALUES_BY_MAPPING_ID = 'GET_PRODUCT_ATTRIBUTE_VALUES_BY_MAPPING_ID';
export const GET_ALL_POSSIBLE_COMBINATIONS = 'GET_ALL_POSSIBLE_COMBINATIONS';
