export class SSAddMainCatagory {
    id?: number;
    name?: string;
    description?: string;
    isPublished?: boolean;
    isShowOnHomePage?: boolean;
    isSpecial?: boolean;
    displayOrder?: number;
    userRoleId?: number;
    bannerId?: number;
}

export class SSUpdateMainCatagory {
    id?: any;
    name?: string;
    description?: string;
    isPublished?: boolean;
    isShowOnHomePage?: boolean;
    isSpecial?: boolean;
    displayOrder?: number;
    userRoleId?: number;
    bannerId?: number;
}