import { getNoticeService, getNoticeByIdService, getNoticeListService } from "../../../httpService/notice/noticeServices";
import { PaginationPayloadModel } from "../../../models/common/paginationModels";
import { GET_NOTICE_LIST, GET_NOTICE, GET_NOTICE_BY_ID } from "../../constants/notice/noticeConstants";

export const getNoticeListAction = (p?: PaginationPayloadModel) => async (dispatch: any) => {
	try {
		const data = getNoticeListService(p);
		const payload = (await data).data;
		dispatch({ type: GET_NOTICE_LIST, payload: payload });
	} catch (error: any) { }

};

export const getNoticeByIdAction = (id: any) => async (dispatch: any) => {
	try {
		var data = getNoticeByIdService(id);
		const payload = (await data).data;
		dispatch({ type: GET_NOTICE_BY_ID, payload: payload });
	} catch (error: any) { }
};

export const getNoticeAction = () => async (dispatch: any) => {
	try {
		var data = getNoticeService();
		const payload = (await data).data;
		dispatch({ type: GET_NOTICE, payload: payload });
	} catch (error: any) { }
}