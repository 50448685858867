import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GetUserWalletResponse, GetUserWithdrawListRequest, UpdateUserPointRequest } from '../../../../models/auth/user/UserPointModels';
import { RootState } from '../../../../store';
import _moment from 'moment';
import { AppButton } from '../../../common/AppButton';
import { AppSelect } from '../../../common/AppSelect';
import { AppSearchBox } from '../../../common/AppSearchBox';
import { getUserWalletAction, getUserWithdrawRequestListAction } from '../../../../store/actions/auth/user/userActions';
import { updateUserPointStatusService } from '../../../../httpService/user/userServices';
import { AlertColor } from '@mui/material';
import { AppSnackBar } from '../../../common/AppSnackBar';
import { AppModal } from '../../../common/AppModal';
import { AppPagination } from '../../../common/AppPagination';
import { AppLoader } from '../../../common/AppLoader';

export const pointStatuses = [
    { "id": 1, "name": "In Progress" },
    { "id": 2, "name": "Received" },
    { "id": 3, "name": "Cancelled" },
]

export default function AdminGetResellerWithdrawRequests() {

    const dispatch = useDispatch();

    const [isShowSnackBar, setIsShowSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();


    const [orderPaymentStatus, setOrderPaymentStatus] = useState(0);
    const [pointStatus, setPointStatus] = useState(1);

    const withdrawRequestList = useSelector((state: RootState) => {
        return state.getUserWithdrawRequestListResponse.data;
    });

    var paginationModel = new GetUserWithdrawListRequest();
    paginationModel.pointStatus = pointStatus;

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                paginationModel.limit = 10;
                paginationModel.offset = 0;
                await dispatch(getUserWithdrawRequestListAction(paginationModel));
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [dispatch]);

    const handleOnRowChange = (payload: GetUserWithdrawListRequest) => {
        const fetchData = async () => {
            try {
                paginationModel.limit = payload.limit;
                paginationModel.offset = 0;
                await dispatch(getUserWithdrawRequestListAction(paginationModel));
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };
        fetchData();
    }

    const handleOnPageChange = (payload: GetUserWithdrawListRequest) => {
        const fetchData = async () => {
            try {
                paginationModel.limit = payload.limit;
                paginationModel.offset = payload.offset;
                await dispatch(getUserWithdrawRequestListAction(paginationModel));
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };
        fetchData();
    }

    const handlePointStatusChange = async (event: any) => {
        setPointStatus(event)
        paginationModel.pointStatus = event;
        try {
            await dispatch(getUserWithdrawRequestListAction(paginationModel));
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    const onSearch = async (value: any) => {
        paginationModel.searchQuery = value;
        try {
            await dispatch(getUserWithdrawRequestListAction(paginationModel));
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    const orderTimeFormatter = (createdDateTime: any) => {
        const createdTime = _moment(createdDateTime, 'YYYY-MM-DD HH:mm:ss.SSSSSSS');
        return createdTime.format('MMM D, YY [at] h:mm A');
    }

    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const handelWithdrawPoint = (id: any, status: any) => {

        var model2 = new UpdateUserPointRequest();
        model2.pointStatus = status;
        model2.userPointId = id;

        setIsButtonLoading(true);

        updateUserPointStatusService(model2)
            .then(() => {
                setApprovePopUp(false);
                setDeclinePopUp(false);
                setIsButtonLoading(false);
                setIsShowSnackBar(true);
                setSnackBarMessage("Successfully Updated Status.");
                setSnackBarMessageType("success");
                setTimeout(() => {
                    setIsShowSnackBar(false);
                }, 3000);

                dispatch(getUserWithdrawRequestListAction(paginationModel));
            }).catch(() => {
                setApprovePopUp(false);
                setDeclinePopUp(false);
                setIsButtonLoading(false);
                setIsShowSnackBar(true);
                setSnackBarMessage("Something Wrong");
                setSnackBarMessageType("error");
                setTimeout(() => {
                    setIsShowSnackBar(false);
                }, 3000);
            })
    };

    const [openWalletModal, setOpenWalletModal] = useState(false);

    const handleCloseWalletModal = () => {
        setOpenWalletModal(false);
    }

    const handleOpenWalletModal = (id: number) => {
        setOpenWalletModal(!openWalletModal)
        dispatch(getUserWalletAction(id));
    }


    const wallet = useSelector((state: RootState) => {
        return state.getuserWalletResponse.data as GetUserWalletResponse;
    });

    const [withdrawRequestId, setWithdrawRequestId] = useState(0);

    const [approvePopUp, setApprovePopUp] = useState(false);
    const [declinePopUp, setDeclinePopUp] = useState(false);

    const openApproveModal = (id: number) => {
        setApprovePopUp(true);
        setWithdrawRequestId(id);
    }

    const handleApprove = () => {
        handelWithdrawPoint(withdrawRequestId, 2);
    }

    const closeApprove = () => {
        setApprovePopUp(false);
    }

    const openDeclineModal = (id: number) => {
        setDeclinePopUp(true);
        setWithdrawRequestId(id);
    }

    const handleDecline = () => {
        handelWithdrawPoint(withdrawRequestId, 3);
    }

    const closeDecline = () => {
        setDeclinePopUp(false);
    }


    return (
        <>

            {approvePopUp && (
                <AppModal>
                    <div className='flex flex-col gap-4 text-2xl max-w-md text-center p-4'>
                        Are you sure you want to Approve?
                        <div className='flex items-center justify-center gap-4'>
                            <AppButton isSubmitting={isButtonLoading} text="Yes" onClick={handleApprove} />
                            <AppButton isSubmitting={isButtonLoading} text="No" onClick={closeApprove} />
                        </div>
                    </div>
                </AppModal>
            )}

            {declinePopUp && (
                <AppModal>
                    <div className='flex flex-col gap-4 text-2xl max-w-md text-center p-4'>
                        Are you sure you want to Decline?
                        <div className='flex items-center justify-center gap-4'>
                            <AppButton isSubmitting={isButtonLoading} text="Yes" onClick={handleDecline} />
                            <AppButton isSubmitting={isButtonLoading} text="No" onClick={closeDecline} />
                        </div>
                    </div>
                </AppModal>
            )}


            {openWalletModal &&
                <AppModal>
                    <h2 className="title pb-1 lg:py-2">Wallet</h2>
                    {/* this is first div  */}
                    <AppButton text='Close' onClick={handleCloseWalletModal} />
                    <div className="flex flex-col lg:flex-row items-center gap-2 lg:gap-5 w-full mx-auto">
                        {/* this is main balance and withdraw  */}
                        <div className="w-full grid grid-cols-5 lg:flex items-center gap-1 lg:gap-5">
                            {/* this is Main balance of wallet  */}
                            <div className="w-full col-span-3 lg:w-60 h-24 lg:h-36 py-1 lg:py-2 rounded lg:space-y-1 bg-gradient-to-r from-emerald-500 via-emerald-400 to-emerald-300 lg:shadow">
                                <p className="font-medium text-white text-center text-base lg:text-[22px] pt-1 lg:pt-2">Main Balance</p>
                                <div className="flex items-center justify-center">
                                    <img className="w-4 lg:w-7 h-4 lg:h-7 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka-icon_s8y7yy.png" alt="" />
                                    <h3 className="text-lg lg:text-3xl font-semibold text-white">{wallet.mainBalance}</h3>
                                </div>
                            </div>
                            {/* this is total withdraw and total earning of wallet  */}
                            <div className="w-full col-span-2 lg:w-72 h-24 lg:h-36 py-1 lg:py-2 flex flex-col items-center justify-center rounded space-y-1 lg:space-y-2 bg-white myShd lg:border-b-4 border-b-lime-300">
                                <div className="w-full flex flex-col items-center myShadow lg:shadow-none rounded lg:rounded-none lg:border-none border-b-2 border-b-red py-0.5 lg:py-0 gap-0.5 lg:gap-0">
                                    <p className="font-medium text-textBlack text-xs lg:text-lg">Total Withdrew </p>
                                    <div className="flex items-center">
                                        <img className="w-3 lg:w-5 h-3 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_2_j2npjs.png" alt="" />
                                        <h3 className="text-sm lg:text-xl font-semibold text-red">{wallet.totalWithdrawAmount}</h3>
                                    </div>
                                </div>
                                <div className="w-full flex flex-col items-center myShadow lg:shadow-none rounded lg:rounded-none lg:border-none border-b-2 border-b-primary py-0.5 lg:py-0 gap-0.5 lg:gap-0">
                                    <p className="font-medium text-textBlack text-xs lg:text-lg">Total Earning</p>
                                    <div className="flex items-center">
                                        <img className="w-3 lg:w-5 h-3 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_3_amiw8g.png" alt="" />
                                        <h3 className="text-sm lg:text-xl font-semibold text-primary">{wallet.totalEarning}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* recharge amount and total earnings  */}
                        <div className="w-full flex flex-row items-center gap-1 lg:gap-5">
                            <div className="w-[76%] lg:w-full flex flex-row-reverse lg:flex-row items-center gap-1 lg:gap-5">
                                <div className="w-2/3 lg:w-80 h-20 lg:h-36 px-1 lg:px-3 py-1.5 lg:py-2 rounded lg:space-y-1 bg-white myShadow border-b-2 lg:border-b-4 border-b-violet-500">
                                    <div className="flex items-center justify-between">
                                        <p className="font-medium text-textBlack text-xsm lg:text-lg">Total Reselling Earning</p>
                                        <div className="flex items-center">
                                            <img className="w-2.5 lg:w-4 h-2.5 lg:h-4 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_5_emi1io.png" alt="" />
                                            <h3 className="text-xs lg:text-lg font-semibold text-violet-800">{wallet.totalResellingIncome}</h3>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <p className="font-medium text-textBlack text-xsm lg:text-lg">Total Moderator Earning</p>
                                        <div className="flex items-center">
                                            <img className="w-2.5 lg:w-4 h-2.5 lg:h-4 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_5_emi1io.png" alt="" />
                                            <h3 className="text-xs lg:text-lg font-semibold text-violet-800">{wallet.totalResellingModeratorIncome}</h3>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <p className="font-medium text-textBlack text-xsm lg:text-lg">Total Refer Earning</p>
                                        <div className="flex items-center">
                                            <img className="w-2.5 lg:w-4 h-2.5 lg:h-4 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_5_emi1io.png" alt="" />
                                            <h3 className="text-xs lg:text-lg font-semibold text-violet-800">{wallet.totalResellingReferIncome}</h3>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <p className="font-medium text-textBlack text-xsm lg:text-lg">Total Course Earning</p>
                                        <div className="flex items-center">
                                            <img className="w-2.5 lg:w-4 h-2.5 lg:h-4 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_5_emi1io.png" alt="" />
                                            <h3 className="text-xs lg:text-lg font-semibold text-violet-800">{wallet.totalResellingCourseIncome}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* this is Back Amount of wallet  */}
                            <div className="w-[24%] block lg:hidden h-20 px-0.5 py-2 lg:p-4 rounded space-y-0.5 lg:space-y-1.5 bg-white myShadow border-b-2 lg:border-b-4 border-b-royalBlue">
                                <p className="font-medium text-center text-textBlack text-xxs lg:text-xl">Back Amount</p>
                                <div className="flex items-center justify-center">
                                    <img className="w-3 lg:w-5 h-3 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_4_grdgmx.png" alt="" />
                                    <h3 className="text-xs lg:text-xl font-semibold text-royalBlue">{wallet.resellingBackAmount}</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* this is second div  */}
                    <div className="grid grid-cols-4 lg:grid-cols-5 gap-1 lg:gap-4 pt-2 lg:pt-4">
                        <div className="px-0.5 py-1 lg:p-4 rounded space-y-0.5 lg:space-y-1.5 bg-white myShadow border-b-2 lg:border-b-4 border-b-royalBlue">
                            <p className="font-medium text-center text-textBlack text-xxs lg:text-xl">Reselling Income</p>
                            <div className="flex items-center justify-center">
                                <img className="w-3 lg:w-5 h-3 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_4_grdgmx.png" alt="" />
                                <h3 className="text-xs lg:text-xl font-semibold text-royalBlue">{wallet.resellingIncome}</h3>
                            </div>

                        </div>
                        <div className="px-0.5 py-1 lg:p-4 rounded space-y-0.5 lg:space-y-1.5 bg-white myShadow border-b-2 lg:border-b-4 border-b-royalBlue">
                            <p className="font-medium text-center text-textBlack text-xxs lg:text-xl">Moderator Income</p>
                            <div className="flex items-center justify-center">
                                <img className="w-3 lg:w-5 h-3 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_4_grdgmx.png" alt="" />
                                <h3 className="text-xs lg:text-xl font-semibold text-royalBlue">{wallet.resellingModeratorIncome}</h3>
                            </div>
                        </div>
                        <div className="px-0.5 py-1 lg:p-4 rounded space-y-0.5 lg:space-y-1.5 bg-white myShadow border-b-2 lg:border-b-4 border-b-royalBlue">
                            <p className="font-medium text-center text-textBlack text-xxs lg:text-xl">Refer Income</p>
                            <div className="flex items-center justify-center">
                                <img className="w-3 lg:w-5 h-3 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_4_grdgmx.png" alt="" />
                                <h3 className="text-xs lg:text-xl font-semibold text-royalBlue">{wallet.resellingReferIncome}</h3>
                            </div>

                        </div>
                        <div className="px-0.5 py-1 lg:p-4 rounded space-y-0.5 lg:space-y-1.5 bg-white myShadow border-b-2 lg:border-b-4 border-b-royalBlue">
                            <p className="font-medium text-center text-textBlack text-xxs lg:text-xl">Course Income</p>
                            <div className="flex items-center justify-center">
                                <img className="w-3 lg:w-5 h-3 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_4_grdgmx.png" alt="" />
                                <h3 className="text-xs lg:text-xl font-semibold text-royalBlue">{wallet.resellingCourseIncome}</h3>
                            </div>
                        </div>
                        <div className="hidden lg:block p-4 rounded space-y-1.5 bg-white myShadow border-b-4 border-b-royalBlue">
                            <p className="font-medium text-center text-textBlack text-xl">Back Amount</p>
                            <div className="flex items-center justify-center">
                                <img className="w-5 h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_4_grdgmx.png" alt="" />
                                <h3 className="text-xl font-semibold text-royalBlue">{wallet.resellingBackAmount}</h3>
                            </div>
                        </div>
                    </div>
                </AppModal>
            }

            <div className='max-w-6xl mx-auto'>
                {isShowSnackBar && (
                    <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
                )}
                <h2 className="title text-center">Reseller Withdraw Request List ({withdrawRequestList?.size})</h2>
                <div className="grid grid-cols-2 lg:flex items-center justify-between gap-2 py-2">
                    <AppSearchBox onChange={onSearch} className="myInput" placeholder='Search Reseller request' />
                    <div className='w-full lg:w-1/4'>
                        <AppSelect data={pointStatuses} value={pointStatus} defaultValue='Select Request Status' onChange={handlePointStatusChange} />
                    </div>
                </div>
                {isLoading ? (
                    <AppLoader status={true} />
                ) : (
                    <>
                        <div>
                            {withdrawRequestList?.items?.length ? (
                                <div className="w-full">
                                    {/* these is header part for desktop */}
                                    <div className="hidden lg:flex w-full bg-primary myBorder">
                                        {/* <div className="centerDiv text-white font-medium py-2.5 border-r w-[8%]">User ID</div> */}
                                        <div className="centerDiv text-white font-medium py-2.5 border-r w-[15%]">User Name</div>
                                        <div className="centerDiv text-white font-medium py-2.5 border-r w-[9%]">Amount</div>
                                        <div className="centerDiv text-white font-medium py-2.5 border-r w-[12%]">Request Status</div>
                                        <div className="centerDiv text-white font-medium py-2.5 border-r w-[12%]">PaymentNumber</div>
                                        <div className="centerDiv text-white font-medium py-2.5 border-r w-[6%]">Method</div>
                                        <div className="centerDiv text-white font-medium py-2.5 border-r w-[15%]">Date & Time</div>
                                        <div className="centerDiv text-white font-medium py-2.5 border-r w-[12%]">Approve</div>
                                        <div className="centerDiv text-white font-medium py-2.5 border-r w-[12%]">Decline</div>
                                        <div className="centerDiv text-white font-medium py-2.5 w-[12%]">Wallet</div>
                                    </div>
                                    {/* these is middle part for desktop */}
                                    <div className="hidden lg:flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-1 py-1.5">
                                        {withdrawRequestList?.items?.map((request: any) => (
                                            <div key={request?.id} className="myBorder flex w-full items-center">
                                                {/* <div className="centerDiv border-r border-grey pl-2 py-1 h-full whitespace-nowrap overflow-hidden text-ellipsis  w-[8%]">{request?.userId}</div> */}
                                                <div className="centerDiv justify-start border-r border-grey pl-2 py-1 h-full whitespace-nowrap overflow-hidden text-ellipsis w-[15%]">{request?.userName}</div>
                                                <div className="centerDiv border-r border-grey py-1 h-full w-[9%]">{request?.requestedAmount}</div>
                                                <div className={`centerDiv border-r border-grey py-1 h-full whitespace-nowrap overflow-hidden text-ellipsis  w-[12%]`}> <span className={`${request?.pointStatus === "InProgress" ? "InProgress" : "" || request?.pointStatus === "Cancelled" ? "canceled" : "" || request.pointStatus === "Received" ? "completed" : ""}`}>{request?.pointStatus}</span></div>
                                                <div className="centerDiv justify-start border-r border-grey pl-2 py-1 h-full text-sm w-[12%]">{request?.paymentNumber}</div>
                                                <div className="centerDiv justify-start border-r border-grey pl-2 py-1 h-full text-sm w-[6%]">{request?.paymentMethod}</div>
                                                <div className="centerDiv justify-start border-r border-grey pl-2 py-1 h-full text-sm w-[15%]">{orderTimeFormatter(request?.requestDateTime)}</div>

                                                {pointStatus === 1 ? (
                                                    (
                                                        <>
                                                            <div className="centerDiv border-r border-grey py-1 h-full  w-[12%]">
                                                                <AppButton
                                                                    text="Approve"
                                                                    className="myBtn bg-royalBlue border-royalBlue py-0.5 lg:py-1 text-sm"
                                                                    isSubmitting={isButtonLoading}
                                                                    onClick={() => openApproveModal(request?.id)}
                                                                />
                                                            </div>
                                                            <div className="centerDiv border-r border-grey py-1 h-full  w-[12%]">
                                                                <AppButton
                                                                    text="Decline"
                                                                    className="myBtn bg-red border-red py-0.5 lg:py-1 text-sm"
                                                                    isSubmitting={isButtonLoading}
                                                                    onClick={() => openDeclineModal(request?.id)}
                                                                />
                                                            </div>
                                                        </>
                                                    )
                                                ) : (
                                                    <>
                                                        <div className="centerDiv border-r border-grey py-1 h-full  w-[12%]">
                                                            <AppButton
                                                                text=""
                                                                className="myBtn bg-royalBlue border-royalBlue py-0.5 lg:py-1 text-sm"
                                                                isSubmitting={isButtonLoading}
                                                                onClick={() => { }}
                                                            />
                                                        </div>
                                                        <div className="centerDiv border-r border-grey py-1 h-full  w-[12%]">
                                                            <AppButton
                                                                text=""
                                                                className="myBtn bg-red border-red py-0.5 lg:py-1 text-sm"
                                                                isSubmitting={isButtonLoading}
                                                                onClick={() => { }}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                                <div className="centerDiv py-1 h-full  w-[12%]">
                                                    <AppButton
                                                        text="Wallet"
                                                        className="myBtn bg-green border-green py-0.5 lg:py-1 text-sm"
                                                        onClick={() => handleOpenWalletModal(request?.userId)}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {/* these is middle part for mobile */}
                                    <div className="h-[78dvh] lg:hidden overflow-y-auto flex flex-col gap-2">
                                        {withdrawRequestList?.items?.map((request?: any) => (
                                            <div key={request?.id} className="myBorder flex flex-col w-full p-2">

                                                <div className="flex flex-col w-full">
                                                    {/* <div className="flex items-center gap-1">
                                            <span className="font-medium">User Name:</span> <span>{request?.userName}</span>
                                        </div> */}
                                                    <div className="grid grid-cols-2">
                                                        <div className="flex items-center gap-1">
                                                            <span className="font-medium">User ID:</span> <span>{request?.userId}</span>
                                                        </div>
                                                        <div className="flex items-center gap-1">
                                                            <span className="font-medium">Amount:</span> <span>{request?.requestedAmount}</span>
                                                        </div>
                                                    </div>
                                                    <div className="flex items-center gap-1">
                                                        <span className="font-medium">Request Status:</span>  <span className={`${request?.pointStatus === "InProgress" ? "InProgress" : "" || request?.pointStatus === "Cancelled" ? "canceled" : "" || request.pointStatus === "Received" ? "completed" : ""}`}>{request?.pointStatus}</span>
                                                    </div>
                                                    <div className="flex items-center gap-1">
                                                        <span className="font-medium">Date & Time:</span> <span className="text-sm"> {orderTimeFormatter(request?.requestDateTime)}</span>
                                                    </div>
                                                    <div className="grid grid-cols-1">
                                                        <div className="flex items-center gap-1">
                                                            <span className="font-medium">PaymentNumber:</span> <span>{request?.paymentNumber}</span>
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-1">
                                                        <div className="flex items-center gap-1">
                                                            <span className="font-medium">Method:</span> <span>{request?.paymentMethod}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {pointStatus === 1 ? (
                                                    (
                                                        <>
                                                            <div className="flex items-center justify-center gap-4 w-full pt-2">
                                                                <AppButton
                                                                    text="Approve"
                                                                    className="myBtn bg-royalBlue border-royalBlue py-0.5 lg:py-1 text-sm"
                                                                    isSubmitting={isButtonLoading}
                                                                    onClick={() => openApproveModal(request?.id)}
                                                                />
                                                                <AppButton
                                                                    text="Decline"
                                                                    className="myBtn bg-red border-red py-0.5 lg:py-1 text-sm"
                                                                    isSubmitting={isButtonLoading}
                                                                    onClick={() => openDeclineModal(request?.id)}
                                                                />
                                                            </div>
                                                        </>
                                                    )
                                                ) : (
                                                    <>
                                                        <div className="flex items-center justify-center gap-4 w-full pt-2">
                                                            <AppButton
                                                                text=""
                                                                className="myBtn bg-royalBlue border-royalBlue py-0.5 lg:py-1 text-sm"
                                                                isSubmitting={isButtonLoading}
                                                                onClick={() => { }}
                                                            />
                                                            <AppButton
                                                                text=""
                                                                className="myBtn bg-red border-red py-0.5 lg:py-1 text-sm"
                                                                isSubmitting={isButtonLoading}
                                                                onClick={() => { }}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                                <div className="flex items-center justify-center gap-4 w-full pt-2">
                                                    <AppButton
                                                        text="Wallet"
                                                        className="myBtn bg-green border-green py-0.5 lg:py-1 text-sm"
                                                        onClick={() => handleOpenWalletModal(request?.userId)}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {/* these is footer part  */}
                                    {/* <div className="myBorder py-1 text-center">pagination here</div> */}
                                    <div className="py-1 flex items-center">
                                        <AppPagination curPage={1} limit={10} offset={0} onPageChange={handleOnPageChange} onRowChange={handleOnRowChange} size={withdrawRequestList?.size} />
                                    </div>
                                </div>
                            ) : (
                                <div className="w-full h-[50dvh] flex justify-center items-center text-3xl text-textBlack/90 font-medium">
                                    <span>No Withdraw Requests found</span>
                                </div>
                            )}
                        </div>
                    </>)}
            </div>
        </>
    )
}
