import { getDoctorService, getHospitalsByAuthService } from "../../../httpService/importantNumbers/doctorNumbersServices";
import { GET_DOCTOR_NUMBER } from "../../constants/importantNumbers/doctorNumbers/doctorNumbersConstant";
import { GET_HOSPITALS_BY_AUTH } from "../../constants/importantNumbers/hospital/hospitalConstant";

const getDoctorAction = (payload?: any) => async (dispatch: any) => {
    var data = getDoctorService(payload);
    const pload = (await data).data;
    dispatch({ type: GET_DOCTOR_NUMBER, payload: pload });
};

const getHospitalsByAuthAction = (payload?: any) => async (dispatch: any) => {
    try {
        const data = getHospitalsByAuthService(payload);
        const pload = (await data).data;
        dispatch({ type: GET_HOSPITALS_BY_AUTH, payload: pload });
    } catch (error: any) { }
};

export { getDoctorAction, getHospitalsByAuthAction };