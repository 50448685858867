import { PaginationPayloadModel } from "../common/paginationModels";

export class CreateAreaRequest {
  id?: number;
  name: string = "";
  code: string = "";
  bnName: string = "";
  lat: string = "";
  lon: string = "";
  districtId: any = "";
}

export class UpdateAreaRequest {
  id?: number;
  name: string = "";
  code: string = "";
  bnName: string = "";
  lat: string = "";
  lon: string = "";
  districtId: any = "";
}

export class addHospitalNumber {
  id?: number;
  name: string = "";
  phoneNumberFirst: string = "";
  phoneNumberSecond: string = "";
  email: string = "";
  hotLineNumber: string = "";
  address: string = "";
}

export class addDoctorNumber {
  id?: number;
  name: string = "";
  experties: string = "";
  phoneNumberFirst: string = "";
  phoneNumberSecond: string = "";
  email: string = "";
  address: string = "";
  hospitalsNumbersId: any = "";
}

export class addPoliceOfficialsNumber {
  id?: number;
  name: string = "";
  policeOfficialsDesignations: number = 0;
  phoneNumberFirst: string = "";
  phoneNumberSecond: string = "";
  note: string = "";
}

export class addImamsNumbersNumber {
  id?: number;
  name: string = "";
  mosqueName: string = "";
  phoneNumberFirst: string = "";
  phoneNumberSecond: string = "";
  note: string = "";
  unionOrWardId: any = "";
}

export enum PoliceOfficialsDesignations {
  OC_INCHARGE = 1,
  OC_INVESTIGATION = 2,
}

export class addPublicRepresentativesNumber {
  id?: number;
  name: string = "";
  publicRepresentativeDesignations: number = 0;
  phoneNumberFirst: string = "";
  phoneNumberSecond: string = "";
  note: string = "";
  unionOrWardId: any = "";
}
export class GetPublicRepresentativesNumber extends PaginationPayloadModel {
  divisionId: number = 0;
  districtId: number = 0;
  areaId: number = 0;
  unionOrWardId: number = 0;
}

export class GetAcademicInstitutesNumber extends PaginationPayloadModel {
  divisionId: number = 0;
  districtId: number = 0;
  areaId: number = 0;
  unionOrWardId: number = 0;
}

export class GetTeachersNumberModel extends PaginationPayloadModel {
  divisionId: number = 0;
  districtId: number = 0;
  areaId: number = 0;
  unionOrWardId: number = 0;
  instituteId: number = 0;
  type: string = "teacher";
}

export class GetAcademicInstituteEmployeesNumber extends PaginationPayloadModel {
  instituteId: number = 0;
}

export class GetImamsNumbersModel extends PaginationPayloadModel {
  divisionId: number = 0;
  districtId: number = 0;
  areaId: number = 0;
  unionOrWardId: number = 0;
}

export class GetPoliceOfficialsNumber extends PaginationPayloadModel {
  divisionId: number = 0;
  districtId: number = 0;
  areaId: number = 0;
}

export class GetHospitalNumberModel extends PaginationPayloadModel {
  divisionId: number = 0;
  districtId: number = 0;
  areaId: number = 0;
}

export class GetAreaNumberModel extends PaginationPayloadModel {
  districtId: number = 0;

}

export class GetDoctorsNumberModel extends PaginationPayloadModel {
  divisionId: number = 0;
  districtId: number = 0;
  areaId: number = 0;
  hospitalsNumbersId: number = 0;
}

export const AcademicInstituteTypeObject = [
  { id: 1, name: "School" },
  { id: 2, name: "College" },
  { id: 3, name: "SchoolAndCollege" },
  { id: 4, name: "Madrasha" },
  { id: 5, name: "University" },
];

export const AcademicInstituteAuthorityTypeObject = [
  { id: 1, name: "Government" },
  { id: 2, name: "Semi-Government" },
  { id: 3, name: "Private" },
];

export const AcademicInstituteOccupationTypeObject = [
  { id: 1, name: "Teacher" },
  { id: 2, name: "Employee" },
];
