import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { AppInput } from '../../common/AppInput';
import { AppButton } from '../../common/AppButton';
import { getCommentByPostId, submitCommentOrReplyModel } from '../../../models/magazine/magazineCommentModels';
import { AppFileInput } from '../../common/AppFileInput';
import { ProductMediaModel } from '../../../models/catalog/productReview/productReviewModels';
import { createMagazineCommentOrReplyService, deleteMagazineCommentService, getMagazineAdminCommentListService, getMagazineCommentListService } from '../../../httpService/magazine/magazineCommentServices';
import { PaginationPayloadModel } from '../../../models/common/paginationModels';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { magazineCommentPostListAction } from '../../../store/actions/magazine/magazineCommentActions';
import { AlertColor } from '@mui/material';
import { AppSnackBar } from '../../common/AppSnackBar';
import { AppModal } from '../../common/AppModal';
import { AppDefaultProfilePicture } from '../../common/AppDefaultProfilePicture';
import _moment from 'moment';
import { reactLocalStorage } from 'reactjs-localstorage';
import { AiFillDelete } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';
import { GET } from '../../../httpService/service';

export const MagazinePostDetails = () => {

    const currentUserId = reactLocalStorage.get("UserId") as string;

    const [isShowSnackBar, setIsShowSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

    const [isConfirmOrderModal, setIsConfirmOrderModal] = useState(false);
    const [commentId, setCommentId] = useState(0);

    const [isAlreadyCommented, setIsAlreadyCommented] = useState(false);

    const getIsAlreadyCommentedService = async (MagazinePostId: any) => {
        const tempData = GET(`/api/MagazineComment/GetIsAlreadyCommented?MagazinePostId=${MagazinePostId}`);
        const finalData = (await tempData).data;
        loadData(finalData);
    }

    const loadData = (
        value: any
    ) => {
        setIsAlreadyCommented(value);
    };

    console.log("isCom", isAlreadyCommented)

    const closeConfirmOrderModal = () => {
        setIsConfirmOrderModal(false);
    }

    const handleRemovePostComment = (item: any) => {
        setCommentId(item.id)
        setIsConfirmOrderModal(true);
    }

    const handleReplyRemovePhoto = (item: any) => {
        setReplyMedias(replyMedias.filter(_ => _ !== item));
    }

    const handleRemovePhoto = (item: any) => {
        setMedias(medias.filter(_ => _ !== item));
    }

    const handleReplySaveDocumentIds = (id: any, url: any) => {
        setIsShowFullHight(true);
        let model = new ProductMediaModel();
        model.id = id;
        model.url = url;
        setReplyMedias(_ => [..._, model])
    }


    const removePostComment = () => {
        deleteMagazineCommentService(commentId)
            .then(() => {
                setIsShowSnackBar(true);
                setSnackBarMessage("Comment deleted successfully");
                setSnackBarMessageType("success");
                setTimeout(() => {
                    setIsShowSnackBar(false);
                }, 2000);
                dispatch(magazineCommentPostListAction(cModel))
                closeConfirmOrderModal();
            }).catch(() => {
                setSnackBarMessage("Comment deleted error occurred");
            })
    }

    const calculateTime = (createdDateTime: any) => {
        const createdTime = _moment(createdDateTime, 'YYYY-MM-DD HH:mm:ss A');
        let formattedTime;

        if (_moment().diff(createdTime, 'hours') < 1) {
            formattedTime = createdTime.fromNow();
        } else if (_moment().diff(createdTime, 'days') < 1) {
            formattedTime = createdTime.format('h:mm A');
        } else {
            formattedTime = createdTime.format('MMM D, YYYY [at] h:mm A');
        }
        return formattedTime;
    }


    const [isRepOpen, setIsRepOpen] = useState(false);
    const [selectedRepId, setSelectedRepId] = useState(0);
    const [repText, setRepText] = useState("");
    const [repTextError, setRepTextError] = useState("");

    const [replyMedias, setReplyMedias] = useState<ProductMediaModel[]>([]);

    const handleReplyOpen = (item: any) => {
        setIsRepOpen(!isRepOpen)
        setSelectedRepId(item.id)
    }

    const handleRepText = (value: any) => {
        setRepText(value)
        setRepTextError("")
    }
    const { id } = useParams();

    const handleSubmitReply = (item: any) => {

        if (repText === "" || repText === null || repText === undefined) {
            setRepTextError("Reply text Is Required")
        }
        else {
            let model = new submitCommentOrReplyModel();
            model.IsParent = false;
            model.ParentId = item.id;
            model.CommentText = repText;
            model.MagazinePostId = id;
            model.MediaIds = replyMedias?.map((_: any) => _.id)
            setIsCommentSubmitButtonLoading(true)
            createMagazineCommentOrReplyService(model)
                .then(() => {
                    setIsCommentSubmitButtonLoading(false)
                    setIsRepOpen(false);
                    setIsShowSnackBar(true);
                    setSnackBarMessage("Submitted Reply Successfully");
                    setSnackBarMessageType("success");
                    setTimeout(() => {
                        setIsShowSnackBar(false);
                    }, 2000);
                    dispatch(magazineCommentPostListAction(cModel))
                    setRepText("")
                    setReplyMedias([])
                    setIsShowFullHight(false)
                })
                .catch(() => {
                    setIsCommentSubmitButtonLoading(false)
                    setRepTextError("Comment reply submitting error occurred")
                });
        }

    }

    var cModel = new getCommentByPostId();
    cModel.MagazinePostId = id;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(magazineCommentPostListAction(cModel))
        getIsAlreadyCommentedService(id)
    }, [])

    var commentData = useSelector((state: RootState) => {
        return state.magazinePostCommentListResponse.data;
    });

    console.log('comment data', commentData)

    const [commentText, setCommentText] = useState('');
    const [commentTextError, setCommentTextError] = useState('');

    const handleClearCommentTextInput = () => {
        setCommentText('');
    }

    const handleCommentText = (value: any) => {
        setCommentText(value)
        setCommentTextError("")
    }

    const [isCommentSubmitButtonLoading, setIsCommentSubmitButtonLoading] = useState(false);

    const [medias, setMedias] = useState<ProductMediaModel[]>([]);
    const [isShowFullHight, setIsShowFullHight] = useState(false);

    const handleSaveDocumentIds = (id: any, url: any) => {
        setIsShowFullHight(true);
        let model = new ProductMediaModel();
        model.id = id;
        model.url = url;
        setMedias(_ => [..._, model])
    }

    const handleCommentSubmit = (e: any) => {
        e.preventDefault();

        const model = new submitCommentOrReplyModel();

        model.IsParent = true;
        model.ParentId = 0;
        model.CommentText = commentText;
        model.MagazinePostId = id;
        model.MediaIds = medias?.map((_: any) => _.id);

        createMagazineCommentOrReplyService(model)
            .then((res: any) => {
                handleClearCommentTextInput();
                dispatch(magazineCommentPostListAction(cModel))
                getIsAlreadyCommentedService(id);
                console.log('res', res)
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    return (
        <div>
            <h1>MagazinePostDetails</h1>
            <h1>Post Id: {id}</h1>
            <div>
                <div className="py-4">
                    {isShowSnackBar && (
                        < AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
                    )}
                    {isConfirmOrderModal && (
                        <AppModal>
                            <div className='flex flex-col gap-4 text-2xl max-w-md text-center p-4'>
                                Are you sure you want to Remove?
                                <div className='flex items-center justify-center gap-4'>
                                    <AppButton text="Yes" onClick={removePostComment} />
                                    <AppButton text="No" onClick={closeConfirmOrderModal} />
                                </div>
                            </div>
                        </AppModal>
                    )}


                    <h2 className="title text-center">Comments</h2>
                    {commentData?.length ? (
                        commentData?.map((item: any, index: any) => {
                            return (
                                <div key={index}>
                                    <div className="flex flex-col gap-1.5 py-2">
                                        <div className="flex items-center gap-2 lg:gap-4">
                                            <div className="">
                                                <AppDefaultProfilePicture className="customer-comment-image" name={item?.customerName} profilePicture={item?.customerPhotoPath} />
                                            </div>
                                            <div className="">
                                                <h3 className="font-medium">{item?.customerName}</h3>
                                                <p className="text-gray-600 text-xs">{calculateTime(item?.updatedDateTime)}</p>
                                            </div>
                                        </div>

                                        <div className="ml-10 lg:ml-16">
                                            <p className="flex items-center gap-1"> {item?.text}
                                                {(String(item?.userId) === currentUserId) && (<AiFillDelete onClick={() => handleRemovePostComment(item)} className="text-textBlack text-lg hover:text-red cursor-pointer" />)}
                                            </p>

                                            <div className="flex items-center gap-2 flex-wrap">
                                                {item?.commentMedias?.map((i: any, index: any) => {
                                                    return (
                                                        <img key={index} className="w-12 lg:w-20 h-12 lg:h-20 object-fill" src={i.url} alt="" />
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                    {item.commentResponseList?.map((rep: any, index: any) => {
                                        return (
                                            <div key={index} className="flex flex-col gap-1.5 pl-4 ml-12 lg:ml-20 border-l-2 border-reselling">
                                                <div className="flex items-center gap-2 lg:gap-4">
                                                    <div className="">
                                                        <AppDefaultProfilePicture className="customer-comment-image" name={rep?.customerName} profilePicture={rep?.customerPhotoPath} />
                                                    </div>
                                                    <div className="">
                                                        <h3 className="font-medium">{rep.customerName}</h3>
                                                        <p className="text-gray-600 text-xs">{calculateTime(rep.updatedDateTime)}</p>
                                                    </div>
                                                </div>

                                                <p className="ml-10 lg:ml-16">
                                                    {rep?.text}
                                                    {(String(rep?.userId) === currentUserId) && (<AiFillDelete onClick={() => handleRemovePostComment(rep)} className="text-textBlack text-lg hover:text-red cursor-pointer" />)}
                                                </p>

                                                <div className="ml-10 lg:ml-16 flex items-center gap-2 flex-wrap">
                                                    {rep?.commentMedias?.map((i: any, index: any) => {
                                                        return (
                                                            <img key={index} className="w-8 lg:w-16 h-8 lg:h-16 object-fill" src={i.url} alt="" />
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })}

                                    <div className="pt-4 ml-10 lg:ml-16">
                                        <AppButton className="bechteChaiBtn" text="Reply" onClick={() => handleReplyOpen(item)} />

                                        {isRepOpen && (selectedRepId === item.id) && (
                                            <div className={`flex flex-col gap-2 md:px-12 py-4 md:py-2 ${isShowFullHight ? '2xl:px-0' : '2xl:px-32'}`}>
                                                <p className="text-lg font-medium">Reply</p>
                                                <div className="flex flex-col myBorder px-2 md:px-4 py-2">
                                                    <div className={`flex flex-wrap gap-2 md:gap-4 py-1`}>
                                                        {replyMedias && (
                                                            replyMedias.map((item: ProductMediaModel, index: any) => {
                                                                return (
                                                                    <div key={index} className="relative w-fit h-fit pr-1">
                                                                        <IoMdClose onClick={() => handleReplyRemovePhoto(item)} className="cursor-pointer text-reselling absolute top-0 right-0 2xl:text-2xl text-xl font-semibold" />
                                                                        <img
                                                                            className="lg:w-20 w-12 h-12 lg:h-20 object-fill rounded"
                                                                            src={item.url}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                )
                                                            })
                                                        )}
                                                    </div>
                                                    <div className="flex items-center justify-between">
                                                        <AppInput
                                                            className="text-lg"
                                                            placeholder="Write your reply"
                                                            value={repText}
                                                            onChange={handleRepText}
                                                            errorMessage={repTextError}
                                                        />
                                                        <div className="flex items-center gap-x-4">
                                                            <div className="w-7 h-7 cursor-pointer">
                                                                <AppFileInput onSave={handleReplySaveDocumentIds} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex justify-end items-center pt-2">
                                                    <AppButton
                                                        isSubmitting={isCommentSubmitButtonLoading}
                                                        text="Submit Reply"
                                                        className="bechteChaiBtn w-fit"
                                                        onClick={() => handleSubmitReply(item)}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )
                        })
                    ) : (
                        <p className="text-center text-textGrey">No comment yet.</p>
                    )}

                    <div className={`flex flex-col gap-2 md:px-12 py-4 md:py-2 ${isShowFullHight ? '2xl:px-0' : '2xl:px-32'}`}>
                        {!isAlreadyCommented && <p className="text-lg font-medium">Write a comment</p>}
                        {isAlreadyCommented && <p className="text-lg font-medium">You have alrady commented</p>}
                        {/* these is writing div  */}
                        <div className="flex flex-col myBorder px-2 md:px-4 py-2">
                            {/* these is image container  */}
                            <div className={`flex flex-wrap gap-2 md:gap-4 py-1`}>
                                {medias && (
                                    medias.map((item: ProductMediaModel, index: any) => {
                                        return (
                                            <div key={index} className="relative w-fit h-fit pr-1">
                                                <IoMdClose onClick={() => handleRemovePhoto(item)} className="cursor-pointer text-reselling absolute top-0 right-0 2xl:text-2xl text-xl font-semibold" />
                                                <img
                                                    className="w-12 lg:w-20 h-12 lg:h-20 object-fill rounded"
                                                    src={item.url}
                                                    alt=""
                                                />
                                            </div>
                                        )
                                    })
                                )}
                            </div>
                            {/* these is input element  */}


                            <div className="flex items-center justify-between">

                                {!isAlreadyCommented &&
                                    <AppInput
                                        className="text-lg"
                                        placeholder="Write your comment"
                                        value={commentText}
                                        onChange={handleCommentText}
                                        errorMessage={commentTextError}
                                    />}

                                {!isAlreadyCommented &&
                                    <div className="flex items-center gap-x-4">
                                        <div className="w-7 h-7 cursor-pointer">
                                            <AppFileInput onSave={handleSaveDocumentIds} />
                                        </div>
                                    </div>}

                            </div>

                        </div>
                        <div className="flex justify-end items-center pt-2">
                            {!isAlreadyCommented &&
                                <AppButton
                                    isSubmitting={isCommentSubmitButtonLoading}
                                    text="Submit"
                                    className="bechteChaiBtn w-fit"
                                    onClick={handleCommentSubmit}
                                />}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
