import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../../store';
import { getUserPointAction } from '../../../../store/actions/auth/user/userActions';
import _moment from 'moment';
import { AppSelect } from '../../../common/AppSelect';
import { updateUserPointStatusService } from '../../../../httpService/user/userServices';
import { UpdateUserPointRequest } from '../../../../models/auth/user/UserPointModels';
import { AlertColor } from '@mui/material/Alert';
import { AppSnackBar } from '../../../common/AppSnackBar';

export const pointStatuses = [
    { "id": 1, "name": "InProgress" },
    { "id": 2, "name": "Received" },
    { "id": 3, "name": "Cancelled" },
]

export default function AdminGetResellerPoints() {

    const dispatch = useDispatch();

    const [pointStatus, setPointStatus] = useState(0);

    const [isShowSnackBar, setIsShowSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

    const point = useSelector((state: RootState) => {
        return state.getuserPointResponse.data;
    });

    const { id } = useParams();

    useEffect(() => {
        dispatch(getUserPointAction(id));
        setPointStatus(point?.pointStatus)
    }, [dispatch])

    const orderTimeFormatter = (createdDateTime: any) => {
        const createdTime = _moment(createdDateTime, 'YYYY-MM-DD HH:mm:ss.SSSSSSS');
        return createdTime.format('MMM D, YY [at] h:mm A');
    }

    const handlePointStatusChange = (event: any) => {
        var model = new UpdateUserPointRequest();
        model.pointStatus = event;
        model.userPointId = id;
        updateUserPointStatusService(model)
            .then(() => {
                dispatch(getUserPointAction(id));
                setIsShowSnackBar(true);
                setSnackBarMessage("Successfully Updated Status.");
                setSnackBarMessageType("success");
                setTimeout(() => {
                    setIsShowSnackBar(false);
                }, 3000);
            }).catch(() => {
                setIsShowSnackBar(true);
                setSnackBarMessage("Something Wrong");
                setSnackBarMessageType("error");
                setTimeout(() => {
                    setIsShowSnackBar(false);
                }, 3000);
            })
    }

    return (
        <div>
            {isShowSnackBar && (
                <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
            )}
            <div className="my-points-body-main" >
                <div className="my-points-body-small"><p>{point?.orderId}</p></div>
                <div className="my-points-body-big"><p>{orderTimeFormatter(point?.orderDateTime)}</p></div>
                <div className="my-points-body-big"><p>{point?.customerName}</p></div>
                <div className="my-points-body-big"><p>{point?.totalOrderProductCount}</p></div>
                <div className="my-points-body-big"><p>{point?.totalOrderAmount}</p></div>
                <div className="my-points-body-big">
                    {point?.orderPaymentStatus == "NotPaid" && (
                        <p className="payment-status-points-un-paid">Unpaid</p>
                    )}
                    {point?.orderPaymentStatus == "Paid" && (
                        <p className="payment-status-points-paid">Paid</p>
                    )}
                </div>
                <div className="my-points-body-small"><p>{point?.totalPoint}</p></div>
                <div className="my-points-body-big">
                    {point?.presentPointStatus == "Pending" && (
                        <p className="order-status-points-in-pending">Pending</p>
                    )}
                    {point?.presentPointStatus == "Received" && (
                        <p className="order-status-points-received">Received</p>
                    )}
                    {point?.presentPointStatus == "Cancelled" && (
                        <p className="shipping-status-points-cancel">Cancelled</p>
                    )}
                </div>
            </div>
            <br />
            <br />
            <AppSelect data={pointStatuses} value={pointStatus} defaultValue='Select Point Status' onChange={handlePointStatusChange} />
        </div>
    )
}
