import { useEffect } from "react";
import { RootState } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import {
	getVendorDashboardByIdAction,
} from "../../../../store/actions/dashboard/dashboardActions";
import { reactLocalStorage } from "reactjs-localstorage";
import { Link } from "react-router-dom";
import { IsShippingChargeAvailableShipping } from "../../../../store/actions/shippingCharge/shippingCharge";
import { VendorDashboardModel } from "../../../../models/dashboard/dashboardModel";
import { AppSelect } from "../../../common/AppSelect";

interface IProps {
	id?: any;
}

export const AdminVendorDashboard: React.FC<IProps> = ({ id }) => {

	const dispatch = useDispatch();

	const dashboard = useSelector((state: RootState) => {
		return state.getVendorDashboardResponse.data as VendorDashboardModel;
	});

	useEffect(() => {
		dispatch(getVendorDashboardByIdAction(id));
	}, [dispatch]);

	var token = reactLocalStorage.getObject("SignIn");

	const shippingChargeIsAvailable = useSelector((state: RootState) => {
		return state.IsShippingChargeAvailableShippingResponse.data;
	});

	useEffect(() => {
		dispatch(IsShippingChargeAvailableShipping());
	}, [dispatch]);

	return (
		<div className="container mx-auto px-0 lg:px-8 lg:py-4 overflow-y-auto">
			<div className="flex items-center justify-between">
				<h2 className="text-base lg:text-xl font-semibold">Vendor Dashboard [id: {id}]</h2>
				<div className="flex items-center gap-1.5">
					<h2 className="subTitle">Short By</h2>
					<AppSelect defaultValue="All Time" data={[]} />
				</div>
			</div>
			{/* this is first div  */}
			<div className="grid gap-4 grid-cols-2 lg:grid-cols-4 pt-4">
				{/* total products  */}
				<div className="p-2 lg:p-4 bg-[#DCFFDD] border border-gray-200 border-b-4 border-b-blue-300 rounded shadow-md hover:shadow-xl">
					<div className="flex justify-between pb-2">
						<Link to="/admin/catalog/products?filterBy=0">
							<h3 className="subTitle">Total Products</h3>
						</Link>
						<img className="w-5 lg:w-7 h-5 lg:h-7 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Frame_35069_nyrvbi.png" alt="this is products pic" />
					</div>
					<div className="flex items-center">
						{/* <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_ds9nzp.png" alt="" /> */}
						<h3 className="text-base lg:text-2xl font-semibold">{dashboard.totalProducts}</h3>
					</div>
				</div>
				{/* Active Products  */}
				<div className="p-2 lg:p-4 bg-[#DCFFDD] border border-gray-200 border-b-4 border-b-green-400 rounded shadow-md hover:shadow-xl">
					<div className="flex justify-between pb-2">
						<Link to="/admin/catalog/products?filterBy=1">
							<h3 className="subTitle">Active Products</h3>
						</Link>
						<img className="w-5 lg:w-7 h-5 lg:h-7 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Frame_35070_nysnxt.png" alt="this is products pic" />
					</div>
					<div className="flex items-center">
						{/* <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_ds9nzp.png" alt="" /> */}
						<h3 className="text-base lg:text-2xl font-semibold">{dashboard.totalActiveProducts}</h3>
					</div>
				</div>
				{/* Inactive Products  */}
				<div className="p-2 lg:p-4 bg-[#DCFFDD] border border-gray-200 border-b-4 border-b-yellow-300 rounded shadow-md hover:shadow-xl">
					<div className="flex justify-between pb-2">
						<Link to="/admin/catalog/products?filterBy=2">
							<h3 className="subTitle">Inactive Products</h3>
						</Link>
						<img className="w-5 lg:w-7 h-5 lg:h-7 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Done-1_zynuru.png" alt="this is products pic" />
					</div>
					<div className="flex items-center">
						{/* <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_ds9nzp.png" alt="" /> */}
						<h3 className="text-base lg:text-2xl font-semibold">{dashboard.totalInActiveProducts}</h3>
					</div>
				</div>
				{/* Out Of Stock Products  */}
				<div className="p-2 lg:p-4 bg-[#DCFFDD] border border-gray-200 border-b-4 border-b-rose-500 rounded shadow-md hover:shadow-xl">
					<div className="flex justify-between pb-2">
						<Link to="/admin/catalog/products?filterBy=3">
							<h3 className="subTitle">Stock Out Products</h3>
						</Link>
						<img className="w-5 lg:w-7 h-5 lg:h-7 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Frame_21_idesqh.png" alt="this is products pic" />
					</div>
					<div className="flex items-center">
						{/* <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_ds9nzp.png" alt="" /> */}
						<h3 className="text-base lg:text-2xl font-semibold">{dashboard.totalOutOfStockProducts}</h3>
					</div>
				</div>
				<div className="px-4 py-2 lg:p-4 bg-[#DCFFDD] border border-gray-200 border-b-4 border-b-royalBlue rounded shadow-md hover:shadow-xl">
					<div className="flex justify-between pb-2">
						<Link to="/admin/catalog/productreviews">
							<h3 className="subTitle">Products Reviews</h3>
						</Link>
						<img className="w-5 lg:w-7 h-5 lg:h-7 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/rating_ozpvir.png" alt="this is products pic" />
					</div>
					<div className="flex items-center">
						{/* <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_ds9nzp.png" alt="" /> */}
						<h3 className="text-base lg:text-2xl font-semibold">{dashboard.totalProductReview}</h3>
					</div>
				</div>
			</div>
			{/* this is second div  */}
			<div className="grid gap-4 grid-cols-2 lg:grid-cols-4 pt-4">
				{/* Total Orders  */}
				<div className="p-2 lg:p-4 bg-[#DCFFDD] border border-gray-200 border-b-4 border-b-blue-700 rounded shadow-md hover:shadow-xl">
					<div className="flex justify-between pb-2">
						<Link to="/admin/sales/order-list">
							<h3 className="subTitle">Reselling Total Orders</h3>
						</Link>
						<img className="w-5 lg:w-7 h-5 lg:h-7 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Frame_35024_b17srq.png" alt="this is products pic" />
					</div>
					<div className="flex items-center">
						{/* <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_ds9nzp.png" alt="" /> */}
						<h3 className="text-base lg:text-2xl font-semibold">{dashboard.resellingTotalOrders}</h3>
					</div>
				</div>
				{/* Pending Orders  */}
				<div className="p-2 lg:p-4 bg-[#DCFFDD] border border-gray-200 border-b-4 border-b-cyan-500 rounded shadow-md hover:shadow-xl">
					<div className="flex justify-between pb-2">
						<Link to="/admin/sales/order-list?orderStatus=1">
							<h3 className="subTitle">Reselling Pending Orders</h3>
						</Link>
						<img className="w-5 lg:w-7 h-5 lg:h-7 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/ic_twotone-pending-actions_sj45ij.png" alt="this is products pic" />
					</div>
					<div className="flex items-center">
						{/* <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_ds9nzp.png" alt="" /> */}
						<h3 className="text-base lg:text-2xl font-semibold">{dashboard.resellingTotalPendingOrders}</h3>
					</div>
				</div>
				{/* Orders Shipped  */}
				<div className="p-2 lg:p-4 bg-[#DCFFDD] border border-gray-200 border-b-4 border-b-slate-300 rounded shadow-md hover:shadow-xl">
					<div className="flex justify-between pb-2">
						<Link to="/admin/sales/order-list?shippingStatus=2">
							<h3 className="subTitle">Reselling Orders Shipped</h3>
						</Link>
						<img className="w-5 lg:w-7 h-5 lg:h-7 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/flat-color-icons_shipped_g8d8uu.png" alt="this is products pic" />
					</div>
					<div className="flex items-center">
						{/* <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_ds9nzp.png" alt="" /> */}
						<h3 className="text-base lg:text-2xl font-semibold">{dashboard.resellingTotalShippedOrders}</h3>
					</div>
				</div>
				{/* Confirmed Orders  */}
				<div className="p-2 lg:p-4 bg-[#DCFFDD] border border-gray-200 border-b-4 border-b-sky-200 rounded shadow-md hover:shadow-xl">
					<div className="flex justify-between pb-2">
						<Link to="/admin/sales/order-list?orderStatus=5">
							<h3 className="subTitle">Reselling Confirmed Orders</h3>
						</Link>
						<img className="w-5 lg:w-7 h-5 lg:h-7 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/game-icons_confirmed_fnl0k0.png" alt="this is products pic" />
					</div>
					<div className="flex items-center">
						{/* <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_ds9nzp.png" alt="" /> */}
						<h3 className="text-base lg:text-2xl font-semibold">{dashboard.resellingTotalConfirmOrders}</h3>
					</div>
				</div>
			</div>
			{/* this is third div  */}
			<div className="grid gap-4 grid-cols-2 lg:grid-cols-4 pt-4">
				{/* Completed Orders  */}
				<div className="px-4 py-2 lg:p-4 bg-[#DCFFDD] border border-gray-200 border-b-4 border-b-[#37FF37] rounded shadow-md hover:shadow-xl">
					<div className="flex justify-between pb-2">
						<Link to="/admin/sales/order-list?orderStatus=4">
							<h3 className="subTitle">Reselling Completed Orders</h3>
						</Link>
						<img className="w-5 lg:w-7 h-5 lg:h-7 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Done_idyekg.png" alt="this is products pic" />
					</div>
					<div className="flex items-center">
						{/* <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_ds9nzp.png" alt="" /> */}
						<h3 className="text-base lg:text-2xl font-semibold">{dashboard.resellingTotalCompletedOrders}</h3>
					</div>
				</div>
				{/* Canceled Orders  */}
				<div className="px-4 py-2 lg:p-4 bg-[#DCFFDD] border border-gray-200 border-b-4 border-b-rose-600 rounded shadow-md hover:shadow-xl">
					<div className="flex justify-between pb-2">
						<Link to="/admin/sales/order-list?orderStatus=2">
							<h3 className="subTitle">Reselling Canceled Orders</h3>
						</Link>
						<img className="w-5 lg:w-7 h-5 lg:h-7 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/pajamas_canceled-circle_txjeuw.png" alt="this is products pic" />
					</div>
					<div className="flex items-center">
						{/* <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_ds9nzp.png" alt="" /> */}
						<h3 className="text-base lg:text-2xl font-semibold">{dashboard.resellingTotalCancelledOrders}</h3>
					</div>
				</div>
				{/* Orders Returned  */}
				<div className="px-4 py-2 lg:p-4 bg-[#DCFFDD] border border-gray-200 border-b-4 border-b-amber-300 rounded shadow-md hover:shadow-xl">
					<div className="flex justify-between pb-2">
						<Link to="/admin/sales/order-list?orderStatus=3">
							<h3 className="subTitle">Reselling Orders Returned</h3>
						</Link>
						<img className="w-5 lg:w-7 h-5 lg:h-7 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/ic_baseline-assignment-return_dfq3my.png" alt="this is products pic" />
					</div>
					<div className="flex items-center">
						{/* <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_ds9nzp.png" alt="" /> */}
						<h3 className="text-base lg:text-2xl font-semibold">{dashboard.resellingTotalReturnOrders}</h3>
					</div>
				</div>

			</div>

			{/* this is second div  */}
			<div className="grid gap-4 grid-cols-2 lg:grid-cols-4 pt-4">
				{/* Total Orders  */}
				<div className="p-2 lg:p-4 bg-[#DCFFDD] border border-gray-200 border-b-4 border-b-blue-700 rounded shadow-md hover:shadow-xl">
					<div className="flex justify-between pb-2">
						<Link to="/admin/sales/order-list">
							<h3 className="subTitle">Affiliate Total Orders</h3>
						</Link>
						<img className="w-5 lg:w-7 h-5 lg:h-7 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Frame_35024_b17srq.png" alt="this is products pic" />
					</div>
					<div className="flex items-center">
						{/* <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_ds9nzp.png" alt="" /> */}
						<h3 className="text-base lg:text-2xl font-semibold">{dashboard.affiliateTotalOrders}</h3>
					</div>
				</div>
				{/* Pending Orders  */}
				<div className="p-2 lg:p-4 bg-[#DCFFDD] border border-gray-200 border-b-4 border-b-cyan-500 rounded shadow-md hover:shadow-xl">
					<div className="flex justify-between pb-2">
						<Link to="/admin/sales/order-list?orderStatus=1">
							<h3 className="subTitle">Affiliate Pending Orders</h3>
						</Link>
						<img className="w-5 lg:w-7 h-5 lg:h-7 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/ic_twotone-pending-actions_sj45ij.png" alt="this is products pic" />
					</div>
					<div className="flex items-center">
						{/* <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_ds9nzp.png" alt="" /> */}
						<h3 className="text-base lg:text-2xl font-semibold">{dashboard.affiliateTotalPendingOrders}</h3>
					</div>
				</div>
				{/* Orders Shipped  */}
				<div className="p-2 lg:p-4 bg-[#DCFFDD] border border-gray-200 border-b-4 border-b-slate-300 rounded shadow-md hover:shadow-xl">
					<div className="flex justify-between pb-2">
						<Link to="/admin/sales/order-list?shippingStatus=2">
							<h3 className="subTitle">Affiliate Orders Shipped</h3>
						</Link>
						<img className="w-5 lg:w-7 h-5 lg:h-7 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/flat-color-icons_shipped_g8d8uu.png" alt="this is products pic" />
					</div>
					<div className="flex items-center">
						{/* <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_ds9nzp.png" alt="" /> */}
						<h3 className="text-base lg:text-2xl font-semibold">{dashboard.affiliateTotalShippedOrders}</h3>
					</div>
				</div>
				{/* Confirmed Orders  */}
				<div className="p-2 lg:p-4 bg-[#DCFFDD] border border-gray-200 border-b-4 border-b-sky-200 rounded shadow-md hover:shadow-xl">
					<div className="flex justify-between pb-2">
						<Link to="/admin/sales/order-list?orderStatus=5">
							<h3 className="subTitle">Affiliate Confirmed Orders</h3>
						</Link>
						<img className="w-5 lg:w-7 h-5 lg:h-7 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/game-icons_confirmed_fnl0k0.png" alt="this is products pic" />
					</div>
					<div className="flex items-center">
						{/* <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_ds9nzp.png" alt="" /> */}
						<h3 className="text-base lg:text-2xl font-semibold">{dashboard.affiliateTotalConfirmOrders}</h3>
					</div>
				</div>
			</div>
			{/* this is third div  */}
			<div className="grid gap-4 grid-cols-2 lg:grid-cols-4 pt-4">
				{/* Completed Orders  */}
				<div className="px-4 py-2 lg:p-4 bg-[#DCFFDD] border border-gray-200 border-b-4 border-b-[#37FF37] rounded shadow-md hover:shadow-xl">
					<div className="flex justify-between pb-2">
						<Link to="/admin/sales/order-list?orderStatus=4">
							<h3 className="subTitle">Affiliate Completed Orders</h3>
						</Link>
						<img className="w-5 lg:w-7 h-5 lg:h-7 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Done_idyekg.png" alt="this is products pic" />
					</div>
					<div className="flex items-center">
						{/* <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_ds9nzp.png" alt="" /> */}
						<h3 className="text-base lg:text-2xl font-semibold">{dashboard.affiliateTotalCompletedOrders}</h3>
					</div>
				</div>
				{/* Canceled Orders  */}
				<div className="px-4 py-2 lg:p-4 bg-[#DCFFDD] border border-gray-200 border-b-4 border-b-rose-600 rounded shadow-md hover:shadow-xl">
					<div className="flex justify-between pb-2">
						<Link to="/admin/sales/order-list?orderStatus=2">
							<h3 className="subTitle">Affiliate Canceled Orders</h3>
						</Link>
						<img className="w-5 lg:w-7 h-5 lg:h-7 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/pajamas_canceled-circle_txjeuw.png" alt="this is products pic" />
					</div>
					<div className="flex items-center">
						{/* <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_ds9nzp.png" alt="" /> */}
						<h3 className="text-base lg:text-2xl font-semibold">{dashboard.affiliateTotalCancelledOrders}</h3>
					</div>
				</div>
				{/* Orders Returned  */}
				<div className="px-4 py-2 lg:p-4 bg-[#DCFFDD] border border-gray-200 border-b-4 border-b-amber-300 rounded shadow-md hover:shadow-xl">
					<div className="flex justify-between pb-2">
						<Link to="/admin/sales/order-list?orderStatus=3">
							<h3 className="subTitle">Affiliate Orders Returned</h3>
						</Link>
						<img className="w-5 lg:w-7 h-5 lg:h-7 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/ic_baseline-assignment-return_dfq3my.png" alt="this is products pic" />
					</div>
					<div className="flex items-center">
						{/* <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_ds9nzp.png" alt="" /> */}
						<h3 className="text-base lg:text-2xl font-semibold">{dashboard.affiliateTotalReturnOrders}</h3>
					</div>
				</div>

			</div>

		</div>
	);
}
