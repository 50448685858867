import { DELETE, GET, POST, PUT } from "../service";

export const districtListService = (payload: any) => {
  if (payload) {
    return GET(
      `/api/District/GetDistricts?searchQuery=${payload?.searchQuery}`
    );
  } else {
    return GET(`/api/District/GetDistricts`);
  }
};

export const getDistrictListByDivisionIdService = (payload: any) => {
  return GET(`/api/District/GetDistricts?DivisionId=${payload}`);
};

export const addDistrictService = (data: any) => {
  return POST("/api/District/CreateDistrict", data);
};

export const updateDistrictService = (data: any) => {
  return PUT(`/api/District/UpdateDistrict`, data);
};

export const deleteDistrictService = (data: any) => {
  return DELETE(`/api/District/RemoveDistrict`, {
    data: { id: [data] },
  });
};
