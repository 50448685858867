import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { getDataOfferOrderListAction } from '../../../store/actions/auth/user/userActions';
import { ChangeDataOfferStatusRequest, DataOfferOrderListRequest } from '../../../models/auth/user/UserPointModels';
import { changeDataOfferOrderStatusService } from '../../../httpService/user/userServices';
import { DataOrderStatus } from '../../../models/dataOffer/dataOfferModels';
import { AppLoader } from '../../common/AppLoader';
import { AppButton } from '../../common/AppButton';
import _moment from 'moment';
import { AppPagination } from '../../common/AppPagination';
import { BiSearchAlt2 } from 'react-icons/bi';
import { AppSearchBox } from '../../common/AppSearchBox';
import { AppModal } from '../../common/AppModal';
import { TableHead } from '../../common/AppTable';

export default function DataOfferGetOrders() {
  
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [orderStatus, setOrderStatus] = useState(0);

  const dataOffer = useSelector((state: RootState) => {
    return state.getDataOfferOrderListResponse.data;
  });

  var model = new DataOfferOrderListRequest();
  model.limit = 10;
  model.offset = 0;
  model.dataOrderStatus = DataOrderStatus.All;
  model.userId = 0;

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getDataOfferOrderListAction(model));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const [orderId, setOrderId] = useState(0);

  const openCancelModal = (orderId: any) => {
    setOrderId(orderId);
    setIsOpenCancelModal(true)
  }

  const closeCancelModal = () => {
    setIsOpenCancelModal(false)
  }

  const [isButtonLoading, setIsDeclineButtonLoading] = useState(false)

  const handleDeclineDataOfferOrder = () => {
    let model = new ChangeDataOfferStatusRequest();
    model.orderId = orderId;
    model.dataOrderStatus = DataOrderStatus.Declined;
    setIsDeclineButtonLoading(true);
    changeDataOfferOrderStatusService(model)
      .then(() => {
        closeCancelModal();
        setIsDeclineButtonLoading(false);
        let model = new DataOfferOrderListRequest();
        model.limit = 10;
        model.offset = 0;
        model.dataOrderStatus = DataOrderStatus.All;
        model.userId = 0;
        dispatch(getDataOfferOrderListAction(model));
      }).catch(() => {
        setIsDeclineButtonLoading(false);
      })
  }

  const handleOnPageChange = (payload: DataOfferOrderListRequest) => {
    const fetchData = async () => {
      try {
        model.limit = payload.limit;
        model.offset = payload.offset;
        await dispatch(getDataOfferOrderListAction(model));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  };

  const handleOnRowChange = (payload: DataOfferOrderListRequest) => {
    const fetchData = async () => {
      try {
        model.limit = payload.limit;
        model.offset = 0;
        await dispatch(getDataOfferOrderListAction(model));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();
  };

  const orderTimeFormatter = (createdDateTime: any) => {
    const createdTime = _moment(createdDateTime, 'YYYY-MM-DD HH:mm:ss.SSSSSSS');
    return createdTime.format('MMM D, YY [at] h:mm A');
  }


  const getOrderByStatus = async (status: any) => {
    model.dataOrderStatus = status;
    setIsLoading(true);
    await dispatch(getDataOfferOrderListAction(model));
    setOrderStatus(status)
    setIsLoading(false);
  }

  const tableHead = [
    { name: 'ID', width: 'w-[5%]' },
    { name: 'Number', width: 'w-[12%]' },
    { name: 'Price', width: 'w-[10%]' },
    { name: 'Contact', width: 'w-[12.9%]' },
    { name: 'Package Type', width: 'w-[12.9%]' },
    { name: 'Operator', width: 'w-[12.9%]' },
    { name: 'Date & Time', width: 'w-[16%]' },
    { name: 'Status', width: 'w-[18%]', borderNone: true },
  ]

  return (
    <div className="container mx-auto px-2 md:px-16 pb-8 md:py-0">

      {isOpenCancelModal && (
        <AppModal>
          <div className='flex flex-col gap-4 text-2xl max-w-md text-center p-4'>
            Are you sure you want to cancel this Order?
            <div className='flex items-center justify-center gap-4'>
              <AppButton text="Yes" onClick={handleDeclineDataOfferOrder} />
              <AppButton text="No" onClick={closeCancelModal} />
            </div></div>
        </AppModal>
      )}

      <div className="flex items-center justify-between pb-2 md:pb-3">
        <h3 className="title">My Orders</h3>
        <h3 className="subTitle text-royalBlue">Orders: <span>{dataOffer?.items?.length}</span></h3>
      </div>

      <div className="flex gap-2 items-center justify-between pb-2">
        {/* this is search bar div */}
        <div className="flex items-center border shadow-sm border-grey 2xl:p-2 px-1.5 lg:py-0.5 rounded cursor-pointer">
          <BiSearchAlt2 className="text-royalBlue cursor-pointer 2xl:text-3xl md:text-2xl text-base font-bold" />
          <AppSearchBox onChange={() => { "" }} className="border-none w-[20dvw] md:w-[25dvw] lg:px-2 text-xsm md:text-base" />
        </div>
        {/* this is buttons div  */}
        <div className="grid grid-cols-3 md:flex gap-1 lg:gap-2">
          {/* <AppButton className={isAll ? "myBtn" : "outlineBtn"} text="All" onClick={selectAll} /> */}
          <AppButton text="Pending" onClick={() => getOrderByStatus(1)} className={orderStatus === 1 ? "myOfferBtn" : "offerOutlineBtn"} />
          <AppButton text="Cancelled" onClick={() => getOrderByStatus(2)} className={orderStatus === 2 ? "myOfferBtn" : "offerOutlineBtn"} />
          <AppButton text="Completed" onClick={() => getOrderByStatus(3)} className={orderStatus === 3 ? "myOfferBtn" : "offerOutlineBtn"} />
        </div>
      </div>

      <hr className="border border-textBlack" />
      {isLoading ? (
        <div className="container mx-auto px-2 md:px-0 pb-12">
          <AppLoader status={true} />
        </div>
      ) : (

        <>
          {dataOffer?.items?.length ? (
            <div className="w-full pt-2">
              {/* these is header part for desktop */}
              <TableHead color='bg-royalBlue' tableHead={tableHead} />
              {/* these is middle part for desktop */}
              <div className="hidden lg:flex h-[62dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-1 py-1">
                {dataOffer?.items?.map((order: any) => (
                  <div key={order?.id} className="myBorder flex w-full items-center">
                    <div className="tableRow w-[5%]">{order?.id}</div>
                    <div className="tableRow font-medium w-[12%]">{order?.phoneNumber}</div>
                    <div className="tableRow w-[10%]">৳{Number(order?.dataOffer?.price)}</div>
                    <div className="tableRow w-[13%]">{order?.dataOffer?.contact}</div>
                    <div className="tableRow w-[13%]">{order?.dataOffer?.dataPackageTypeName}</div>
                    <div className="tableRow w-[13%]">{order?.dataOffer?.mobileOperatorName}</div>
                    <div className="tableRow text-sm w-[16%]">{orderTimeFormatter(order?.createdAt)}</div>
                    <div className="tableRow border-none w-[18%]">
                      {order?.dataOrderStatus === 1 && (
                        <AppButton className="offerOutlineBtn text-red hover:bg-red border-red" text='Cancel' isSubmitting={isButtonLoading} onClick={() => openCancelModal(order?.id)} />
                      )}
                      {order?.dataOrderStatus === 2 && (
                        <p className="Cancelled">Canceled</p>
                      )}
                      {order?.dataOrderStatus === 3 && (
                        <p className="Accepted">Accepted</p>
                      )}
                      {order?.dataOrderStatus === 4 && (
                        <p className="Earned">Confirmed</p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              {/* these is middle part for mobile */}
              <div className="h-[78dvh] lg:hidden overflow-y-auto flex flex-col gap-2">
                {dataOffer?.items?.map((order: any) => (
                  <div key={order?.id} className="myBorder text-xsm grid grid-cols-4 px-1 py-2 w-full hover:shadow">
                    <div className="text-xsm flex items-center gap-1">
                      {/* <span className="">Order ID:</span> */}
                      <span>#{order?.id}</span>
                    </div>
                    <div className="text-xsm flex items-center justify-center gap-1">
                      {/* <span className="">Price:</span>  */}
                      <span className="font-medium">৳{Number(order?.dataOffer?.price)}</span>
                    </div>

                    <div className="text-xsm flex items-center gap-1">
                      {/* <span>Number:</span>  */}
                      <span className="font-medium">{order?.phoneNumber}</span>
                    </div>
                    <div className="text-xsm flex justify-center items-center gap-1">
                      {/* <span>Number:</span>  */}
                      <span className="font-medium">{order?.dataOffer?.mobileOperatorName}</span>
                    </div>
                    <div className="text-xxs"> {orderTimeFormatter(order?.createdAt)}</div>
                    <div className="text-xsm flex justify-center items-center gap-1">
                      {/* <span>Number:</span>  */}
                      <span className="font-medium">{order?.dataOffer?.dataPackageTypeName}</span>
                    </div>
                    <div className="text-xsm flex items-center gap-1">
                      {/* <span>Number:</span>  */}
                      <span className="font-medium">{order?.contact}</span>
                    </div>
                    <div className="flex items-center justify-center gap-1 pr-1">
                      {/* <span className="">Order Status:</span> */}
                      <span> {order?.dataOrderStatus === 1 && (
                        <AppButton className="offerOutlineBtn text-red hover:bg-red border-red" text='Cancel' isSubmitting={isButtonLoading} onClick={() => openCancelModal(order?.id)} />
                      )}
                        {order?.dataOrderStatus === 2 && (
                          <p className="Cancelled">Canceled</p>
                        )}
                        {order?.dataOrderStatus === 3 && (
                          <p className="Earned">Accepted</p>
                        )}
                        {order?.dataOrderStatus === 4 && (
                          <p className="Earned">Received</p>
                        )}
                      </span>
                    </div>

                  </div>
                ))}
              </div>
              {/* these is footer part  */}
              <div className="py-1 flex items-center fixed bottom-1 bg-white w-full lg:static">
                <AppPagination
                  curPage={1}
                  limit={10}
                  offset={0}
                  onPageChange={handleOnPageChange}
                  onRowChange={handleOnRowChange}
                  size={dataOffer?.size}
                />
              </div>
            </div>
          ) : (
            <div className="w-full h-[50dvh] flex justify-center items-center text-3xl text-textBlack/90 font-medium">
              <span>No order found</span>
            </div>
          )}
        </>
      )}
    </div>
  )
}
