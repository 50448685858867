import { useEffect, useState } from "react";
import { RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { getAdminDashboardAction } from "../../../store/actions/dashboard/dashboardActions";
import { AdminDashboardModel } from "../../../models/dashboard/dashboardModel";
import { AppSelect } from "../../common/AppSelect";
import { Link, useNavigate } from "react-router-dom";
import { getNoticeAction } from "../../../store/actions/notice/noticeAction";
import { AppModal } from "../../common/AppModal";
import { markNoticeSeenService } from "../../../httpService/notice/noticeServices";
import { MdPeopleAlt } from "react-icons/md";
import { AppLoader } from "../../common/AppLoader";

export default function DashboardComponent() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const dashboard = useSelector((state: RootState) => {
    return state.getAdminDashboardResponse.data as AdminDashboardModel;
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getAdminDashboardAction()); // Await the dispatch if it returns a promise
      } catch (error) {
        // Handle error if needed
      } finally {
        setIsLoading(false); // Set loading to false in the finally block to ensure it runs after try/catch
      }
    };
    fetchData();
  }, [dispatch]);

  const notice = useSelector((state: RootState) => {
    return state.getNoticeResponse.data;
  });

  useEffect(() => {
    dispatch(getNoticeAction());
  }, [dispatch]);

  const closeNoticeModel = (id: any) => {
    markNoticeSeenService(id)
      .then((response) => {
        navigate("/", { replace: true });
        dispatch(getNoticeAction());
      })
      .catch(() => { });
  };

  const adminAffiliatCards = [
    {
      text: "Total Orders",
      value: dashboard?.affiliateTotalOrders,
      link: "/admin/sales/order-list",
      image:
        "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Frame_35024_b17srq.png",
      bgColor: "bg-[#E2E7FF]",
      borderColor: "border-b-blue-700",
    },
    {
      text: "Pending Orders",
      value: dashboard?.affiliateTotalPendingOrders,
      link: "/admin/sales/order-list?orderStatus=1",
      image:
        "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/ic_twotone-pending-actions_sj45ij.png",
      bgColor: "bg-[#E2E7FF]",
      borderColor: "border-b-cyan-500",
    },
    {
      text: "Orders Shipped",
      value: dashboard?.affiliateTotalShippedOrders,
      link: "/admin/sales/order-list?shippingStatus=2",
      image:
        "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/flat-color-icons_shipped_g8d8uu.png",
      bgColor: "bg-[#E2E7FF]",
      borderColor: "border-b-slate-300",
    },
    {
      text: "Confirmed Orders",
      value: dashboard?.affiliateTotalConfirmOrders,
      link: "/admin/sales/order-list?orderStatus=5",
      image:
        "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/game-icons_confirmed_fnl0k0.png",
      bgColor: "bg-[#E2E7FF]",
      borderColor: "border-b-sky-200",
    },
    {
      text: "Completed Orders",
      value: dashboard?.affiliateTotalCompletedOrders,
      link: "/admin/sales/order-list?orderStatus=4",
      image:
        "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Done_idyekg.png",
      bgColor: "bg-[#E2E7FF]",
      borderColor: "border-b-[#37FF37]",
    },
    {
      text: "Canceled Orders",
      value: dashboard?.affiliateTotalCancelledOrders,
      link: "/admin/sales/order-list?orderStatus=2",
      image:
        "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/pajamas_canceled-circle_txjeuw.png",
      bgColor: "bg-[#E2E7FF]",
      borderColor: "border-b-rose-600",
    },
    {
      text: "Orders Returned",
      value: dashboard?.affiliateTotalReturnOrders,
      link: "/admin/sales/order-list?orderStatus=3",
      image:
        "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/ic_baseline-assignment-return_dfq3my.png",
      bgColor: "bg-[#E2E7FF]",
      borderColor: "border-b-amber-300",
    },
    {
      text: "Product Comments",
      value: dashboard?.totalProductReview,
      link: "/admin/catalog/productreviews",
      image:
        "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/rating_ozpvir.png",
      bgColor: "bg-[#E2E7FF]",
      borderColor: "border-b-royalBlue",
    },
  ];

  const adminProductCards = [
    {
      text: "Total Products",
      value: dashboard?.totalProducts,
      link: "/admin/catalog/products?filterBy=0",
      image:
        "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Frame_35069_nyrvbi.png",
      bgColor: "bg-[#DCFFDD]",
      borderColor: "border-b-blue-300",
    },
    {
      text: "Active Products",
      value: dashboard?.totalActiveProducts,
      link: "/admin/catalog/products?filterBy=1",
      image:
        "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Frame_35070_nysnxt.png",
      bgColor: "bg-[#DCFFDD]",
      borderColor: "border-b-green-400",
    },
    {
      text: "Inactive Products",
      value: dashboard?.totalInActiveProducts,
      link: "/admin/catalog/products?filterBy=2",
      image:
        "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Done-1_zynuru.png",
      bgColor: "bg-[#DCFFDD]",
      borderColor: "border-b-yellow-300",
    },
    {
      text: "Stock Out Products",
      value: dashboard?.totalOutOfStockProducts,
      link: "/admin/catalog/products?filterBy=3",
      image:
        "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Frame_21_idesqh.png",
      bgColor: "bg-[#DCFFDD]",
      borderColor: "border-b-rose-500",
    },
  ];

  const adminResellingCards = [
    {
      text: "Total Orders",
      value: dashboard?.resellingTotalOrders,
      link: "/admin/sales/order-list",
      image:
        "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Frame_35024_b17srq.png",
      bgColor: "bg-[#E2E7FF]",
      borderColor: "border-b-blue-700",
    },
    {
      text: "Pending Orders",
      value: dashboard?.resellingTotalPendingOrders,
      link: "/admin/sales/order-list?orderStatus=1",
      image:
        "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/ic_twotone-pending-actions_sj45ij.png",
      bgColor: "bg-[#E2E7FF]",
      borderColor: "border-b-cyan-500",
    },
    {
      text: "Orders Shipped",
      value: dashboard?.resellingTotalShippedOrders,
      link: "/admin/sales/order-list?shippingStatus=2",
      image:
        "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/flat-color-icons_shipped_g8d8uu.png",
      bgColor: "bg-[#E2E7FF]",
      borderColor: "border-b-slate-300",
    },
    {
      text: "Confirmed Orders",
      value: dashboard?.resellingTotalConfirmOrders,
      link: "/admin/sales/order-list?orderStatus=5",
      image:
        "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/game-icons_confirmed_fnl0k0.png",
      bgColor: "bg-[#E2E7FF]",
      borderColor: "border-b-sky-200",
    },
    {
      text: "Completed Orders",
      value: dashboard?.resellingTotalCompletedOrders,
      link: "/admin/sales/order-list?orderStatus=4",
      image:
        "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Done_idyekg.png",
      bgColor: "bg-[#E2E7FF]",
      borderColor: "border-b-[#37FF37]",
    },
    {
      text: "Canceled Orders",
      value: dashboard?.resellingTotalCancelledOrders,
      link: "/admin/sales/order-list?orderStatus=2",
      image:
        "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/pajamas_canceled-circle_txjeuw.png",
      bgColor: "bg-[#E2E7FF]",
      borderColor: "border-b-rose-600",
    },
    {
      text: "Orders Returned",
      value: dashboard?.resellingTotalReturnOrders,
      link: "/admin/sales/order-list?orderStatus=3",
      image:
        "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/ic_baseline-assignment-return_dfq3my.png",
      bgColor: "bg-[#E2E7FF]",
      borderColor: "border-b-amber-300",
    },

    {
      text: "Product Comments",
      value: dashboard?.totalProductReview,
      link: "/admin/catalog/productreviews",
      image:
        "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/rating_ozpvir.png",
      bgColor: "bg-[#E2E7FF]",
      borderColor: "border-b-royalBlue",
    },
  ];

  const totalStats = [
    {
      title: "Total Resellers",
      value: dashboard?.totalResellers as number,
      paid: dashboard?.totalResellers - dashboard?.totalUnPaidResellers,
      borderColor: "border-reselling",
      textColor: "text-reselling"
    },
    {
      title: "Total Affiliaters",
      value: dashboard?.totalAffiliaters,
      borderColor: "border-affiliate",
      textColor: "text-affiliate"
    },
    {
      title: "Total Vendor",
      value: dashboard?.totalVendor,
      borderColor: "border-royalBlue",
      textColor: "text-royalBlue"
    },
    {
      title: "Total Customer",
      value: dashboard?.totalUser,
      borderColor: "border-primary",
      textColor: "text-primary"
    },
  ]

  if (isLoading) {
    return (
      <div className="container h-[50dvh] flex items-center justify-center mx-auto px-2 md:px-0 pb-12">
        <AppLoader status={true} />
      </div>
    );
  }

  return (
    <div className="container mx-auto px-0 pb-4 lg:px-8 2xl:px-12">
      {notice && JSON.stringify(notice) !== JSON.stringify({}) && (
        <AppModal>
          <div className="flex flex-col-reverse items-center justify-between gap-4 px-4 py-12 md:flex-row md:px-20">
            <div className="flex flex-col items-center gap-3 md:items-start">
              <p className="max-w-xl">
                <div dangerouslySetInnerHTML={{ __html: notice.description }} />
              </p>
              <div
                className="myBtn w-fit border-royalBlue bg-royalBlue py-1.5"
                onClick={() => closeNoticeModel(notice.id)}
              >
                Ok
              </div>
            </div>
          </div>
        </AppModal>
      )}

      <div className="flex items-center justify-between">
        <h2 className="text-base font-semibold lg:text-xl">Dashboard </h2>
        <div className="flex lg:items-center gap-1 w-1/2 flex-col lg:flex-row lg:w-1/4">
          <h2 className="subTitle">Short_By</h2>
          <AppSelect defaultValue="All Time" data={[]} />
        </div>
      </div>

      {/* this is statistics div */}
      <div className='grid grid-cols-2 lg:grid-cols-4 gap-4 2xl:gap-6 my-4 lg:my-5 2xl:my-6'>
        {totalStats &&
          totalStats.map(stats => (
            <div key={stats.title} className={`h-20 2xl:h-24 space-y-1 2xl:space-y-2 rounded-lg bg-slate-50 shadow-md border-l-4 lg:border-l-[6px] px-3 py-2 2xl:p-4 ${stats.borderColor}`}>
              <h3 className="text-lg font-semibold text-textBlack lg:text-xl 2xl:text-2xl">{stats.title}</h3>
              <div className={`text-lg 2xl:text-xl flex items-center gap-2 font-medium ${stats.textColor}`}><MdPeopleAlt /> <p>{stats?.value}</p> {stats.paid && <p className="ml-auto">Paid: {stats.paid}</p>}</div>
            </div>
          ))
        }
      </div>

      {/* this div for products  */}
      <div className="border-2 2xl:border-4 border-primary rounded p-4 lg:p-5 2xl:p-6 my-4 lg:my-5 2xl:my-6">
        <h2 className="title text-center">Products</h2>
        <div className="grid grid-cols-2 gap-6  pt-4 lg:grid-cols-4">
          {adminProductCards.map((dashboardItem: any) => (
            <Link
              className="shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] shadow-blue-500/20 drop-shadow-xl hover:shadow-xl"
              key={dashboardItem?.text}
              to={dashboardItem?.link}
            >
              <div
                className={`flex flex-col items-center rounded-sm border-b-2 2xl:border-b-4 p-2 lg:p-4 border-primary`}
              >
                <div className="2xl:pb-2">
                  <div className="mb-2 2xl:mb-4 flex items-center justify-center">
                    {/* <img
                      className="h-5 w-5 object-contain  lg:h-9 lg:w-9 "
                      src={dashboardItem?.image}
                      alt={dashboardItem?.text}
                    /> */}
                  </div>
                  <h3 className="subTitle">{dashboardItem?.text}</h3>
                </div>
                <h3 className="text-base font-semibold lg:text-2xl">
                  {dashboardItem?.value}
                </h3>
              </div>
            </Link>
          ))}
        </div>
      </div>

      {/* this div is for reselling  */}
      <div className="border-2 2xl:border-4 border-reselling rounded p-4 lg:p-5 2xl:p-6 my-4 lg:my-5 2xl:my-6">
        <h2 className="title text-center">Reselling</h2>
        <div className="grid grid-cols-2 gap-6  pt-4 lg:grid-cols-4">
          {adminResellingCards.map((dashboardItem) => (
            <Link
              className="shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] shadow-blue-500/20 drop-shadow-xl hover:shadow-xl"
              key={dashboardItem?.text}
              to={dashboardItem?.link}
            >
              <div
                className={`flex flex-col items-center rounded-sm border-b-2 2xl:border-b-4 p-2 lg:p-4 border-reselling`}
              >
                <div className="2xl:pb-2">
                  <div className="mb-2 2xl:mb-4 flex items-center justify-center">
                    {/* <img
                      className="h-5 w-5 object-contain  lg:h-9 lg:w-9 "
                      src={dashboardItem?.image}
                      alt={dashboardItem?.text}
                    /> */}
                  </div>
                  <h3 className="subTitle">{dashboardItem?.text}</h3>
                </div>
                <h3 className="text-base font-semibold lg:text-2xl">
                  {dashboardItem?.value}
                </h3>
              </div>
            </Link>
          ))}
        </div>
      </div>

      {/* this div is for affiliate  */}
      <div className="border-2 2xl:border-4 border-affiliate rounded p-4 lg:p-5 2xl:p-6 my-4 lg:my-5 2xl:my-6">
        <h2 className="title text-center">Affiliate</h2>
        <div className="grid grid-cols-2 gap-6   pt-4 lg:grid-cols-4">
          {adminAffiliatCards.map((dashboardItem) => (
            <Link
              className="shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] shadow-blue-500/20 drop-shadow-xl hover:shadow-xl"
              key={dashboardItem?.text}
              to={dashboardItem?.link}
            >
              <div
                className={`flex flex-col items-center rounded-sm border-b-2 2xl:border-b-4 p-2 lg:p-4 border-affiliate`}
              >
                <div className="2xl:pb-2">
                  <div className="mb-2 2xl:mb-4 flex items-center justify-center">
                    {/* <img
                      className="h-5 w-5 object-contain  lg:h-9 lg:w-9 "
                      src={dashboardItem?.image}
                      alt={dashboardItem?.text}
                    /> */}
                  </div>
                  <h3 className="subTitle">{dashboardItem?.text}</h3>
                </div>
                <h3 className="text-base font-semibold lg:text-2xl">
                  {dashboardItem?.value}
                </h3>
              </div>
            </Link>
          ))}
        </div>
      </div>

      <div className="w-full grid grid-cols-1 gap-5 lg:grid-cols-2">
        <div className="space-y-2 rounded border-2 border-teal-400 px-6 py-4">
          <h3 className="text-base text-center font-semibold text-textBlack lg:text-lg">
            User Withdraw Request
          </h3>
          <div className="mx-auto flex w-fit items-center gap-3">
            {/* <img
              className="h-7 w-7 object-contain lg:h-10 lg:w-10"
              src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Frame_35071_lght7q.png"
              alt="these is taka icon img"
            /> */}
            <h3 className="text-base font-semibold text-textBlack lg:text-xl">
              {dashboard?.totalUserWithdrawAmount} BDT
            </h3>
          </div>
        </div>
        <div className="space-y-2 rounded border-2 border-purple-500 px-6 py-4">
          <h3 className="text-base  text-center font-semibold text-textBlack lg:text-lg">
            Vendor Withdraw Request
          </h3>
          <div className="mx-auto flex w-fit items-center gap-3">
            {/* <img
              className="h-7 w-7 object-contain lg:h-10 lg:w-10"
              src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Frame_35031_lnszgq.png"
              alt="these is taka icon img"
            /> */}
            <h3 className="text-base font-semibold text-textBlack lg:text-xl">
              {dashboard?.totalVendorWithdrawAmount} BDT
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}
