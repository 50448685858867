import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { AlertColor } from "@mui/material";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { AppButton } from "../../../common/AppButton";
import { AppInput } from "../../../common/AppInput";
import { AppSelect } from "../../../common/AppSelect";
import { addPoliceOfficialsNumber } from "../../../../models/importantNumber/ImportantNumberModel";
import { GET } from "../../../../httpService/service";
import { updatePoliceOfficialsService } from "../../../../httpService/importantNumbers/policeOfficialsInfoServices";
import { isAdmin } from "../../../../common/common";
import { reactLocalStorage } from "reactjs-localstorage";

const policeOfficialsDesignationList = [
	{ id: 1, name: 'OC INCHARGE' },
	{ id: 2, name: 'OC INVESTIGATION' }
]

export default function INUpdatePoliceOfficialsNumber() {
	
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { id } = useParams();

	const [snackBarMessage, setsnackBarMessage] = useState("");
	const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
	const [snackBarOn, setSnackBarOn] = useState(false);
	const [isButtonLoading, setIsButtonLoading] = useState(false);

	const [name, setName] = useState("");
	const [nameErrorMessage, setNameErrorMessage] = useState("");
	const [policeOfficialsDesignation, setPoliceOfficialsDesignation] = useState(0);
	const [policeOfficialsDesignationErrorMessage, setPoliceOfficialsDesignationErrorMessage] = useState("");
	const [phoneNumberFirst, setPhoneNumberFirst] = useState("");
	const [phoneNumberFirstErrorMessage, setPhoneNumberFirstErrorMessage] = useState("");
	const [phoneNumberSecond, setPhoneNumberSecond] = useState("");
	const [phoneNumberSecondErrorMessage, setPhoneNumberSecondErrorMessage] = useState("");
	const [note, setNote] = useState("");
	const [noteErrorMessage, setNoteErrorMessage] = useState("");

	const policeOfficial = async (id: any) => {
		const data = await GET(`/api/PoliceOfficialsNumber/GetPoliceOfficialsNumberById?Id=${id}`);
		const response: addPoliceOfficialsNumber = data?.data;
		setName(response.name);
		setPoliceOfficialsDesignation(response.policeOfficialsDesignations);
		setPhoneNumberFirst(response.phoneNumberFirst);
		setPhoneNumberSecond(response.phoneNumberSecond);
		setNote(response.note);
	};

	const handleNameChange = (event: any) => {
		setName(event);
		setNameErrorMessage("");
	}

	const handlePoliceOfficialsDesignation = (id: any) => [setPoliceOfficialsDesignation(id)];

	const handlePhoneNumberFirst = (event: any) => {
		setPhoneNumberFirst(event);
		setPhoneNumberFirstErrorMessage("");
	}
	const handlePhoneNumberSecond = (event: any) => {
		setPhoneNumberSecond(event);
		setPhoneNumberSecondErrorMessage("");
	}

	const handleNote = (event: any) => {
		setNote(event);
		setNoteErrorMessage("");
	}

	const handleSubmit = (e: any) => {
		e.preventDefault();
		setSnackBarOn(false);
		var payload = {
			id: id,
			name: name,
			policeOfficialsDesignations: policeOfficialsDesignation,
			phoneNumberFirst: phoneNumberFirst,
			phoneNumberSecond: phoneNumberSecond,
			note: note,
		};

		setIsButtonLoading(true);

		updatePoliceOfficialsService(payload)
			.then(() => {
				setSnackBarOn(true);
				setsnackBarMessage("Police Official Number Updated Successfully.");
				setSnackBarMessageType("success");
				setIsButtonLoading(false);
				setTimeout(() => {
					navigateToPoliceOfficials();
				}, 300);
			})
			.catch((error: any) => {
				setIsButtonLoading(false);
				setNameErrorMessage(error.response.data.errors?.Name?.[0]);
				setPoliceOfficialsDesignationErrorMessage(error.response.data.errors?.PoliceOfficialsDesignations?.[0]);
				setPhoneNumberFirstErrorMessage(error.response.data.errors?.PhoneNumberFirst?.[0]);
				setPhoneNumberSecondErrorMessage(error.response.data.errors?.PhoneNumberSecond?.[0]);
				setNoteErrorMessage(error.response.data.errors?.Note?.[0]);
			});
	};

	useEffect(() => {
		policeOfficial(id);
	}, []);

	const navigateToPoliceOfficials = () => {
		if (isAdmin(reactLocalStorage.get("RoleName"))) {
		  navigate(`/admin/important-numbers/police-officials-numbers`);
		} else {
		  navigate(`/important-numbers/police-officials-numbers`);
		}
	  };
	  
	return (
		<>
			{snackBarOn && (
				<AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
			)}
			<div className="pl-4 flex items-center justify-between w-3/5 pb-6">
				<AppButton
					onClick={navigateToPoliceOfficials}
					text="Back"
				/>
				<h2 className="text-center title">Update Police Official Contact Number</h2>
			</div>

			<form className="max-w-xl mx-auto myBorder py-4 px-5">
				<AppInput
					label="Name"
					className="myInput"
					type="text"
					placeholder="Enter Name"
					onChange={handleNameChange}
					errorMessage={nameErrorMessage}
					value={name}
				/>
				<AppSelect
					defaultValue="Select Designation"
					value={policeOfficialsDesignation}
					data={policeOfficialsDesignationList}
					onChange={handlePoliceOfficialsDesignation}
					errorMessage={policeOfficialsDesignationErrorMessage}
				/>
				<AppInput
					label="Phone Number First"
					className="myInput"
					type="text"
					placeholder="Enter Phone Number"
					onChange={handlePhoneNumberFirst}
					errorMessage={phoneNumberFirstErrorMessage}
					value={phoneNumberFirst}
				/>
				<AppInput
					label="Phone Number Second"
					className="myInput"
					type="text"
					placeholder="Enter Phone Number"
					onChange={handlePhoneNumberSecond}
					errorMessage={phoneNumberSecondErrorMessage}
					value={phoneNumberSecond}
				/>
				<AppInput
					label="Note"
					className="myInput"
					type="text"
					placeholder="Enter Note"
					onChange={handleNote}
					errorMessage={noteErrorMessage}
					value={note}
				/>
				<div className="flex justify-end">
					<AppButton
						text="Submit"
						onClick={handleSubmit}
						isSubmitting={isButtonLoading}
						variant="contained"
					/>
				</div>

			</form>
		</>
	);



}

