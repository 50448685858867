import { PaginationPayloadModel } from "../../models/common/paginationModels";
import { DELETE, GET, POST, PUT } from "../service";

export const sSAddMainCatagoryService = (data: any) => {
  return POST("/api/sSMainCategory/sSCreateMainCategory", data);
};

export const sSDeleteMainCatagoryService = (data: any) => {
  return DELETE(`/api/sSMainCategory/sSRemoveMainCategory`, {
    data: { "ids": data },
  });
};

export const sSGetMainCategoryListService = (payload?: PaginationPayloadModel, isAll: any = true, forPublic: boolean = false) => {
  return GET(
    `/api/sSMainCategory/sSGetMainCategoryList?isAll=${isAll}&forPublic=${forPublic}&limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}`
  );
};

export const sSGetMainCategoryByIdService = (id: any) => {
	return GET(`/api/SSMainCategory/SSGetMainCategoryById?Id=${id}`);
};


export const sSupdateMainCatagoryService = (data: any) => {
  return PUT(`/api/sSMainCategory/sSUpdateMainCategory`, data);
};
