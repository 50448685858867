import { TrainingPurchaseRequest } from "../../models/training/trainingModels";
import { GET, POST, PUT } from "../service";

export const getTrainingListService = () => {
  return GET(`/api/Training/GetTrainingList`)
};

export const getTrainingByIdService = (id: any) => {
  return GET(`/api/Training/GetTrainingById?id=${id}`)
};

export const getTrainingVideo = () => {
  return GET(`/api/Training/GetTrainingVideo`)
};

export const getIsPurchasedService = (id: any) => {
  return GET(`/api/Training/GetIsPurchased?id=${id}`)
};

export const getIsTrainingPaidService = (userId: any = 0, trainingId: any) => {
  return GET(`/api/Training/getIsTrainingPaid?userId=${userId}&trainingId=${trainingId}`)
};

export const getUserTrainingVideoService = (id: any) => {
  return GET(`/api/Training/GetUserTrainingVideo?id=${id}`)
};

export const submitTrainingPurchase = (payload: TrainingPurchaseRequest) => {
  return POST(`/api/Training/TrainingPurchase`, payload)
};

export const submitTrainingPurchaseWithOutPayment = (payload: any) => {
  return POST(`/api/Training/TrainingPurchaseWithOutPayment`, payload)
};

export const trainingPaymentFromMainBalance = (payload: TrainingPurchaseRequest) => {
  return POST(`/api/Training/TrainingPaymentFromMainBalance`, payload)
};

export const trainingPurchaseWithMainBalance = (payload: TrainingPurchaseRequest) => {
  return POST(`/api/Training/trainingPurchaseWithMainBalance`, payload)
};

export const submitWatchedService = (payload: any) => {
  return PUT(`/api/Training/TrainingVideoWatched`, payload)
};

export const isTrainingVideoWacthedService = (trainingId?: any) => {
  return GET(`/api/Training/IsTrainingVideoWatched?trainingId=${trainingId}`)
};

export const verifyReferrerService = (refferer?: any) => {
  return GET(`/api/Training/VerifyReferrer?referrerId=${refferer}`)
};

export const verifyAffiliateReferrerService = (refferer?: any) => {
  return GET(`/api/Training/VerifyAffiliateReferrer?referrerId=${refferer}`)
};