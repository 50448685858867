export function magazinePostListReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case 'GET_MAGAZINE_POST_LIST':
            return { loading: false, data: action.payload };
        default:
            return state;
    }
}

export function myMagazinePostListReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case 'GET_MY_MAGAZINE_POST_LIST':
            return { loading: false, data: action.payload };
        default:
            return state;
    }
}

export function magazinePostByIdReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case 'GET_MAGAZINE_POST_BY_ID':
            return { loading: false, data: action.payload };
        default:
            return state;
    }
}