import { GET, POST, DELETE, PUT } from "../service";

export const getUnionByIdService = (id: any) => {
  return GET(`/api/UnionWard/GetUnionWardById?Id=${id}`);
};

export const createUnionService = (data: any) => {
  return POST(`/api/UnionWard/CreateUnionWard`, data);
};

export const updateUnionServices = (data: any) => {
  return PUT(`/api/UnionWard/UpdateUnionWard`, data);
};

export const deleteUnionService = (data: any) => {
  return DELETE(`/api/UnionWard/RemoveUnionWard?`, { data: { id: data } });
};

export const getUnionListService = (payload: any) => {
  if (payload) {
    return GET(
      `/api/UnionWard/getUnionWards?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}&divisionId=${payload?.divisionId}&districtId=${payload?.districtId}&areaId=${payload?.areaId}`
    );
  } else {
    return GET(`/api/UnionWard/getUnionWards`);
  }
};

export const getUnionListByAreaIdService = (payload: any) => {
  return GET(`/api/UnionWard/GetUnionWardByAuthArea?areaId=${payload}`);
};

export const getUnionAuthListService = () => {
  return GET(`/api/UnionWard/GetUnionWardByAuthArea`);
};
