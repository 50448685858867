import { AppInput } from "../../common/AppInput";
import { AppButton } from "../../common/AppButton";
import { AppSelect } from "../../common/AppSelect";
import { useEffect, useState } from "react";
import { ResellingProductBox } from "./ResellingProductBox";
import { AlertColor } from "@mui/material/Alert";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import { AppSnackBar } from "../../common/AppSnackBar";
import { GetProductListRequest } from "../../../models/catalog/product/productsModels";
import { useParams } from "react-router-dom";
import { AppLoadMore } from "../../common/AppLoadMore";
import { GET } from "../../../httpService/service";
import { AppLoader } from "../../common/AppLoader";

const shortByFilter = [
  { id: 1, name: 'Price Low to High' },
  { id: 2, name: 'Price High to Low' },
]

export default function ResellingGetFilterProducs() {

  const [isGridSelect, setIsGridSelect] = useState(true);

  const searchQuery = useSelector((state: RootState) => {
    return state.resellingProductFilterResponse.data;
  });

  const { mainCatId } = useParams();
  const { subCatId } = useParams();

  const [isLoadMoreButtonLoading, setIsLoadMoreButtonLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [size, setSize] = useState(0);
  const [page, setPage] = useState(1);
  const [isDisable, setIsDisable] = useState(false);

  const [loadedProducts, setLoadedProducts] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadMoreButtonLoading(true);
        var pModel = new GetProductListRequest();
        pModel.limit = 50;
        pModel.offset = 0;
        pModel.searchQuery = searchQuery;
        pModel.sortBy = 0;
        pModel.minimumPrice = minPrice;
        pModel.maximumPrice = maxPrice;
        pModel.vendorId = 0;
        pModel.mainCategoryId = mainCatId;
        pModel.subCategoryLevelOneId = subCatId;
        const response = await GET(`/api/Product/GetProductsPublic?limit=${pModel?.limit}&offset=${pModel?.offset}&searchQuery=${pModel?.searchQuery}&sortBy=${pModel?.sortBy}&minimumPrice=${pModel?.minimumPrice}&maximumPrice=${pModel?.maximumPrice}&vendorId=${pModel?.vendorId}&mainCategoryId=${pModel?.mainCategoryId}&subCategoryLevelOneId=${pModel?.subCategoryLevelOneId}`);
        setLoadedProducts(response?.data?.items);
        setSize(response?.data?.size)
        setIsLoadMoreButtonLoading(false);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [loadedProducts.length == 0, searchQuery, subCatId]);

  const handleOnRowChange = async (payload: GetProductListRequest) => {
    setPage(page + 1)
    setIsDisable(payload.offset as number >= size)
    var pModel = new GetProductListRequest();
    pModel.limit = payload.limit;
    pModel.offset = payload.offset;
    pModel.searchQuery = searchQuery;
    pModel.sortBy = 0;
    pModel.minimumPrice = minPrice;
    pModel.maximumPrice = maxPrice;
    pModel.vendorId = 0;
    pModel.mainCategoryId = mainCatId;
    pModel.subCategoryLevelOneId = subCatId;
    try {
      setIsLoadMoreButtonLoading(true);
      const response = await GET(`/api/Product/GetProductsPublic?limit=${pModel?.limit}&offset=${pModel?.offset}&searchQuery=${pModel?.searchQuery}&sortBy=${pModel?.sortBy}&minimumPrice=${pModel?.minimumPrice}&maximumPrice=${pModel?.maximumPrice}&vendorId=${pModel?.vendorId}&mainCategoryId=${pModel?.mainCategoryId}&subCategoryLevelOneId=${pModel?.subCategoryLevelOneId}`);
      setLoadedProducts(prevProducts => [...prevProducts, ...response.data.items]);
      setIsLoadMoreButtonLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  const [sortById, setSortById] = useState(0);

  const handleSortByFilter = async (id: any) => {
    setSortById(id);
    var pModel = new GetProductListRequest();
    pModel.limit = 50;
    pModel.offset = 0;
    pModel.searchQuery = searchQuery;
    pModel.sortBy = id;
    pModel.minimumPrice = minPrice;
    pModel.maximumPrice = maxPrice;
    pModel.vendorId = 0;
    pModel.mainCategoryId = mainCatId;
    pModel.subCategoryLevelOneId = subCatId;
    try {
      setIsLoadMoreButtonLoading(true);
      setIsLoading(true);
      const response = await GET(`/api/Product/GetProductsPublic?limit=${pModel?.limit}&offset=${pModel?.offset}&searchQuery=${pModel?.searchQuery}&sortBy=${pModel?.sortBy}&minimumPrice=${pModel?.minimumPrice}&maximumPrice=${pModel?.maximumPrice}&vendorId=${pModel?.vendorId}&mainCategoryId=${pModel?.mainCategoryId}&subCategoryLevelOneId=${pModel?.subCategoryLevelOneId}`);
      setLoadedProducts(response.data.items);
      setIsLoadMoreButtonLoading(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }


  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);

  const onMinMaxSubmit = async () => {

    if ((minPrice - maxPrice) >= 0) {
      setIsShowSnackBar(true);
      setSnackBarMessage("Min Price is grater than Max price.");
      setSnackBarMessageType("warning");
      setTimeout(() => {
        setIsShowSnackBar(false);
      }, 2000);

      return;
    }

    if (minPrice <= 0 || maxPrice <= 0) {
      setIsShowSnackBar(true);
      setSnackBarMessage("Min Price or Max price must be positive.");
      setSnackBarMessageType("warning");
      setTimeout(() => {
        setIsShowSnackBar(false);
      }, 2000);

      return;
    }

    if ((minPrice > 0 && maxPrice > 0 && (minPrice as number) <= (maxPrice as number)) && (maxPrice - minPrice) > 0) {

      var pModel = new GetProductListRequest();
      pModel.limit = 100000;
      pModel.offset = 0;
      pModel.searchQuery = searchQuery;
      pModel.sortBy = sortById;
      pModel.minimumPrice = minPrice;
      pModel.maximumPrice = maxPrice;
      pModel.vendorId = 0;
      pModel.mainCategoryId = 0;
      try {
        setIsLoading(true);
        const response = await GET(`/api/Product/GetProductsPublic?limit=${pModel?.limit}&offset=${pModel?.offset}&searchQuery=${pModel?.searchQuery}&sortBy=${pModel?.sortBy}&minimumPrice=${pModel?.minimumPrice}&maximumPrice=${pModel?.maximumPrice}&vendorId=${pModel?.vendorId}&mainCategoryId=${pModel?.mainCategoryId}&subCategoryLevelOneId=${pModel?.subCategoryLevelOneId}`);
        setLoadedProducts(prevProducts => [...prevProducts, ...response.data.items]);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
    else {
      setIsShowSnackBar(true);
      setSnackBarMessage("Min Max price range is invalid.");
      setSnackBarMessageType("warning");
      setTimeout(() => {
        setIsShowSnackBar(false);
      }, 2000);

      return;
    }
  }

  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

  const products = useSelector((state: RootState) => {
    return state.resellingGetProductsResponse.data;
  });

  const handleAfterAddToCart = () => {
    setIsShowSnackBar(true);
    setSnackBarMessage("Add to cart Successfully");
    setSnackBarMessageType("success");
    setTimeout(() => {
      setIsShowSnackBar(false);
    }, 2000);
  }

  const handleGridSelect = () => {
    setIsGridSelect(true)
  }

  const handleListSelect = () => {
    setIsGridSelect(false)
  }

  if (isLoading) {
    return (
      <div className="container h-[50dvh] flex items-center justify-center mx-auto px-2 md:px-0 pb-12">
        <AppLoader status={true} />
      </div>
    );
  }

  return (
    <>
      {isShowSnackBar && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}

      {/* these is filter product main div start  */}
      <div className="container mx-auto 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28">
        {/* these is min max button and search and filter button  */}
        <div className="flex flex-col lg:flex-row gap-y-2 items-center justify-between pb-4 px-0 md:px-4">
          {/* these is min and max button section  */}
          <div className="flex items-center gap-2 md:gap-4 justify-center md:justify-start">
            {/* these is min max button  */}
            <div className="input-control flex items-center w-[65%] md:w-3/5 gap-2 md:gap-4">
              <AppInput className="border border-primary cursor-pointer text-xs md:text-base py-1.5 md:py-1 pl-2 rounded w-full" value={minPrice !== 0 ? minPrice : ""} onChange={(value: any) => setMinPrice(value)} placeholder="Minimum price" type={'number'} />
              <AppInput className="border border-primary cursor-pointer text-xs md:text-base py-1.5 md:py-1 pl-2 rounded w-full" value={maxPrice !== 0 ? maxPrice : ""} onChange={(value: any) => setMaxPrice(value)} placeholder="Maximum price" type={'number'} />
            </div>

            {(minPrice !== 0 && maxPrice !== 0) && (
              <AppButton
                onClick={onMinMaxSubmit}
                text={"Submit"}
                className="resellingBtn md:py-1.5"
              />
            )}
          </div>
          {/* these is list view and search section  */}
          <div className="w-11/12 lg:w-1/4 flex items-center gap-4">
            <div className="w-full flex-col flex">
              <p className="subTitle font-normal">Sort by:</p>
              <AppSelect
                data={shortByFilter}
                defaultValue="Select Price Filter"
                value={sortById}
                onChange={handleSortByFilter}
              />
            </div>
          </div>
        </div>
        {/* these is products list div  */}
        {loadedProducts.length ? (
          <div className="">
            {/* ---------when grid icon click open this div-------  */}
            {isGridSelect ? (
              <>
                <div className="productContainer">
                  {loadedProducts &&
                    (
                      loadedProducts?.map((x: any) =>
                        <ResellingProductBox key={x.id}
                          id={x.id}
                          name={x.name}
                          shortDescription={x.shortDescription}
                          bannerPath={x.bannerPath}
                          discount={x.discount}
                          isBestSeller={x.isBestSeller}
                          price={x.price}
                          oldPrice={x.oldPrice}
                          rating={x.rating}
                          totalSold={x.totalSold}
                          isDisountProduct={x.isDisountProduct}
                          discountText={x.discountText}
                          afterAddToCart={handleAfterAddToCart}
                          totalReview={x.totalReview}
                          totalView={x.totalView}
                        />
                      )
                    )}
                </div>
                <div className='flex items-center justify-center pt-6'>
                  <AppLoadMore text="Show more Products" isSubmitting={isLoadMoreButtonLoading} className="outlineLoadMoreBtn" isDisable={isDisable} page={page} onRowChange={handleOnRowChange} size={size} />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <p className="py-16 text-xl md:text-3xl text-gray-600 text-center">No product found</p>
        )}

      </div>

    </>
  );
}
