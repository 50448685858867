import { PaginationPayloadModel } from "../../models/common/paginationModels";
import { DELETE, GET, POST, PUT } from "../service";

export const addPopularLinkService = (data: any) => {
    return POST("/api/PopularLink/CreatePopularLink", data);
};

export const getPopularLinkListService = (
    payload?: PaginationPayloadModel
) => {
    return GET(
        `/api/PopularLink/GetPopularLinks?searchQuery=${payload?.searchQuery}&Offset=${0}&limit=${10000}`
    );
};

export const deletePopularLinkService = (data: any) => {
    return DELETE(`/api/PopularLink/RemovePopularLink`, {
        data: { "ids": data },
    });
};

export const updatePopularLinkService = (data: any) => {
    return PUT(`/api/PopularLink/UpdatePopularLink`, data);
};