import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { AlertColor } from "@mui/material";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { AppButton } from "../../../common/AppButton";
import { AppInput } from "../../../common/AppInput";
import { AppSelect } from "../../../common/AppSelect";
import { AcademicInstituteOccupationTypeObject } from "../../../../models/importantNumber/ImportantNumberModel";
import {
  getAcademicInstituteEmployeeByIdService,
  updateAcademicInstituteEmployeeService,
} from "../../../../httpService/importantNumbers/academicInstitutesServices";
import { reactLocalStorage } from "reactjs-localstorage";
import { isAdmin } from "../../../../common/common";

export default function INUpdateAcademicInstituteEmployee() {
  const navigate = useNavigate();

  const { id, instituteId } = useParams();
  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState("");
  const [occupationType, setOccupationType] = useState(0);
  const [occupationTypeErrorMessage, setOccupationTypeErrorMessage] =
    useState("");

  const [designation, setDesignation] = useState("");
  const [designationErrorMessage, setDesignationErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [phoneNumberFirst, setPhoneNumberFirst] = useState("");
  const [phoneNumberFirstErrorMessage, setPhoneNumberFirstErrorMessage] =
    useState("");
  const [phoneNumberSecond, setPhoneNumberSecond] = useState("");
  const [phoneNumberSecondErrorMessage, setPhoneNumberSecondErrorMessage] =
    useState("");
  const [note, setNote] = useState("");
  const [noteErrorMessage, setNoteErrorMessage] = useState("");

  const [address, setAddress] = useState("");
  const [addressErrorMessage, setAddressErrorMessage] = useState("");

  const handleFirstNameChange = (event: any) => {
    setFirstName(event);
    setFirstNameErrorMessage("");
  };

  const handleLastNameChange = (event: any) => {
    setLastName(event);
    setLastNameErrorMessage("");
  };

  const handleDesignation = (event: any) => {
    setDesignation(event);
    setDesignationErrorMessage("");
  };

  const handleOccupationType = (event: any) => {
    setOccupationType(event);
    setOccupationTypeErrorMessage("");
  };

  const handleEmail = (event: any) => {
    setEmail(event);
    setEmailErrorMessage("");
  };

  const handlePhoneNumberFirst = (event: any) => {
    setPhoneNumberFirst(event);
    setPhoneNumberFirstErrorMessage("");
  };
  const handlePhoneNumberSecond = (event: any) => {
    setPhoneNumberSecond(event);
    setPhoneNumberSecondErrorMessage("");
  };

  const handleNote = (event: any) => {
    setNote(event);
    setNoteErrorMessage("");
  };

  const handleAddress = (event: any) => {
    setAddress(event);
    setAddressErrorMessage("");
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSnackBarOn(false);
    var payload = {
      id: id,
      firstName: firstName,
      lastName: lastName,
      occupation: occupationType,
      designation: designation,
      email: email,
      phoneNumberFirst: phoneNumberFirst,
      phoneNumberSecond: phoneNumberSecond,
      address: address,
      note: note,
      academicInstituteId: id,
    };

    setIsButtonLoading(true);

    updateAcademicInstituteEmployeeService(payload)
      .then(() => {
        setSnackBarOn(true);
        setsnackBarMessage(
          "Academic Institute Employee Number Updated Successfully."
        );
        setSnackBarMessageType("success");
        setIsButtonLoading(false);
        setTimeout(() => {
          navigateToAcademicInstituteView(instituteId);
        }, 300);
      })
      .catch((error: any) => {
        setIsButtonLoading(false);
        setFirstNameErrorMessage(error.response.data.errors?.FirstName?.[0]);
        setLastNameErrorMessage(error.response.data.errors?.LastName?.[0]);
        setOccupationTypeErrorMessage(
          error.response.data.errors?.Occupation?.[0]
        );
        setDesignationErrorMessage(
          error.response.data.errors?.Designation?.[0]
        );
        setPhoneNumberFirstErrorMessage(
          error.response.data.errors?.PhoneNumberFirst?.[0]
        );
        setPhoneNumberSecondErrorMessage(
          error.response.data.errors?.PhoneNumberSecond?.[0]
        );
        setAddressErrorMessage(error.response.data.errors?.Address?.[0]);
        setNoteErrorMessage(error.response.data.errors?.Note?.[0]);
      });
  };

  const instituteEmployee = async (id: any) => {
    const data = await getAcademicInstituteEmployeeByIdService(id);
    const response = data?.data;
    setFirstName(response.firstName);
    setLastName(response.lastName);
    setOccupationType(response.occupation);
    setDesignation(response.designation);
    setEmail(response.email);
    setPhoneNumberFirst(response.phoneNumberFirst);
    setPhoneNumberSecond(response.phoneNumberSecond);
    setAddress(response.address);
    setNote(response.note);
  };

  useEffect(() => {
    instituteEmployee(id);
  }, [id]);

  const navigateToAcademicInstituteView = (instituteId: any) => {
    if (isAdmin(reactLocalStorage.get("RoleName"))) {
      navigate(
        `/admin/important-numbers/academic-institutes/view/${instituteId}`
      );
    } else {
      navigate(`/important-numbers/academic-institutes/view/${instituteId}`);
    }
  };
  return (
    <div className="container mx-auto px-2 py-4">
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      <div className="flex flex-col items-start pb-4 lg:flex-row lg:items-center">
        <AppButton
          className="gradientBtn"
          onClick={() => navigateToAcademicInstituteView(instituteId)}
          text="Back"
        />
        <div className="w-full">
          <h2 className="title text-center text-cyan-600">Update Employee</h2>
        </div>
      </div>

      <form className="myBorder mx-auto grid max-w-xl grid-cols-2 items-center gap-x-2 gap-y-2 px-2 py-2 shadow-lg lg:gap-x-4 lg:px-5 lg:py-4">
        <AppInput
          label="First Name"
          className="myInput"
          type="text"
          placeholder="Enter First Name"
          value={firstName}
          onChange={handleFirstNameChange}
          errorMessage={firstNameErrorMessage}
        />
        <AppInput
          label="Last Name"
          className="myInput"
          type="text"
          placeholder="Enter Last Name"
          value={lastName}
          onChange={handleLastNameChange}
          errorMessage={lastNameErrorMessage}
        />

        <div className="">
          <p className="text-sm md:text-base">Occupation</p>
          <AppSelect
            defaultValue="Select Occupation"
            value={occupationType}
            data={AcademicInstituteOccupationTypeObject}
            onChange={handleOccupationType}
            errorMessage={occupationTypeErrorMessage}
          />
        </div>

        <AppInput
          label="Designation"
          className="myInput"
          type="text"
          placeholder="Enter Designation"
          value={designation}
          onChange={handleDesignation}
          errorMessage={designationErrorMessage}
        />
        <AppInput
          label="Email"
          className="myInput"
          type="text"
          placeholder="Enter Email"
          value={email}
          onChange={handleEmail}
          errorMessage={emailErrorMessage}
        />

        <AppInput
          label="Phone Number First"
          className="myInput"
          type="text"
          placeholder="Enter Phone Number"
          value={phoneNumberFirst}
          onChange={handlePhoneNumberFirst}
          errorMessage={phoneNumberFirstErrorMessage}
        />

        <AppInput
          label="Phone Number Second"
          className="myInput"
          type="text"
          placeholder="Enter Phone Number2"
          value={phoneNumberSecond}
          onChange={handlePhoneNumberSecond}
          errorMessage={phoneNumberSecondErrorMessage}
        />
        <AppInput
          label="Note"
          className="myInput"
          type="text"
          placeholder="Enter Note"
          value={note}
          onChange={handleNote}
          errorMessage={noteErrorMessage}
        />
        <AppInput
          label="Address"
          className="myInput"
          type="text"
          placeholder="Enter Address"
          value={address}
          onChange={handleAddress}
          errorMessage={addressErrorMessage}
        />

        <div className="col-span-2 flex justify-end">
          <AppButton
            text="Update"
            className="gradientBtn"
            onClick={handleSubmit}
            isSubmitting={isButtonLoading}
            variant="contained"
          />
        </div>
      </form>
    </div>
  );
}
