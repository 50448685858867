import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { GET } from "../../../../httpService/service";
import { AppInput } from "../../../common/AppInput";
import { AppButton } from "../../../common/AppButton";
import { RootState } from "../../../../store";
import { AlertColor } from "@mui/material";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { AppSelect } from "../../../common/AppSelect";
import { AppTextArea } from "../../../common/AppTextArea";
import { AddMainCatagory } from "../../../../models/catalog/category/categoryModels";
import { bechteChaiMainCategoryList, bechteChaiSubCategoryList } from "../../../../store/actions/bechteChai/bechteChaiCategoryActions";
import { updateBechteChaiSubCategoryService } from "../../../../httpService/bechteChai/categoryServices";
import { IdNameResponseModel } from "../../../../models/common/IdNameModel";

export default function AdminUpdateBechteChaiSubCategory(props: any) {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const mainCatagoryList = useSelector((state: RootState) => {
    return state.bechteChaiMainCategoryListResponse.data;
  });

  console.log("list", mainCatagoryList);

  var idNameModels: IdNameResponseModel[] = [];
  mainCatagoryList.items?.map((x: { id: number; name: string | undefined }) => {
    var temp = new IdNameResponseModel();
    temp.id = x.id;
    temp.name = x.name;
    idNameModels.push(temp);
  });

  const bechteChaiSubCategoryById = async (id: any) => {
    const data = GET(
      `/api/BechteChaiCategory/GetSubCategoryById?Id=${id}`
    );
    console.log("data", data);
    const pload = (await data).data;
    loadData(
      pload.id,
      pload.name,
      pload.description,
      pload.displayOrder,
      pload.isPublished,
      pload.isShowOnHomePage,
      pload.isSpecial,
      pload.mainCategoryId,
      pload.userRoleId,
      pload.banner
    );
  };

  useEffect(() => {
    bechteChaiSubCategoryById(id);
    dispatch(bechteChaiMainCategoryList());
  }, []);

  const loadData = (
    Id: any,
    name: any,
    description: any,
    displayOrder: any,
    isPublished: any,
    isShowOnHomePage: any,
    isSpecial: any,
    mainCategoryId: any,
    userRoleId: any,
    banner: any
  ) => {
    setId(Id);
    setName(name);
    setDescription(description);
    setDisplayOrder(displayOrder);
    setIsPublished(isPublished);
    setIsShowOnHomePage(isShowOnHomePage);
    setIsSpecial(isSpecial);
    setMainCategoryId(mainCategoryId);
    setUserRoleId(userRoleId);
    setBannerId(banner?.id);
  };

  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [bannerError, setBannerError] = useState("");

  const [Id, setId] = useState(0);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState("");

  const [isPublished, setIsPublished] = useState(false);
  const [isShowOnHomePage, setIsShowOnHomePage] = useState(false);
  const [isSpecial, setIsSpecial] = useState(false);
  const [displayOrder, setDisplayOrder] = useState(0);
  const [mainCategoryId, setMainCategoryId] = useState(0);
  const [userRoleId, setUserRoleId] = useState(0);
  const [bannerId, setBannerId] = useState(0);
  const [bannerPath, setBannerPath] = useState("");

  const [errors, setErrors] = useState<any>({});

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);

  const handleIdChange = (event: any) => {
    setId(event);
    errors.Id = "";
  };
  const handleNameChange = (event: any) => {
    setName(event);
    // errors.name = "";
    setNameErrorMessage("");
  };
  const handleDescriptionChange = (event: any) => {
    setDescription(event);
    errors.description = "";
  };

  const handleIsPublishedChange = (event: any) => [
    setIsPublished(event),
    (errors.isPublished = ""),
  ];

  const handleIsShowOnHomePageChange = (event: any) => {
    setIsShowOnHomePage(event);
    errors.isShowOnHomePage = "";
  };

  const handleIsSpecialChange = (event: any) => {
    setIsSpecial(event);
    errors.isSpecial = "";
  };

  const handleDisplayOrder = (event: any) => {
    setDisplayOrder(event);
    errors.displayOrder = "";
  };

  const handleMainCategoryIdChange = (event: any) => {
    setMainCategoryId(event);
    errors.mainCategoryId = "";
  };

  const handleUserRoleIdChange = (event: any) => {
    setUserRoleId(event);
    errors.userRoleId = "";
  };

  const handleBanner = (id: any, path: any) => {
    setBannerId(id);
    setBannerPath(path);
    setBannerError("");
  };

  const deleteBanner = () => {
    setBannerId(0);
    setBannerPath("");
  };

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    var model = new AddMainCatagory();
    model.id = Id;
    model.name = name;
    model.description = description;
    model.isPublished = isPublished;
    model.isShowOnHomePage = isShowOnHomePage;
    model.isSpecial = isSpecial;
    model.displayOrder = displayOrder;
    model.mainCategoryId = mainCategoryId;
    model.userRoleId = userRoleId;
    model.bannerId = bannerId;

    setIsButtonLoading(true);

    setSnackBarOn(false);

    updateBechteChaiSubCategoryService(model)
      .then((response: any) => {
        setSnackBarOn(true);
        setsnackBarMessage("Successfull");
        setSnackBarMessageType("success");

        setIsButtonLoading(false);

        setErrors({});

        setTimeout(() => {
          navigate("/admin/bechte-chai/sub-categories");
        }, 300);

        dispatch(bechteChaiMainCategoryList());
      })
      .catch((error: any) => {

        setIsButtonLoading(false);
        setNameErrorMessage(error.response.data.errors?.Name?.[0]);
        setDescriptionErrorMessage(error.response.data.errors?.Description?.[0]);
        setBannerError(error.response.data.errors?.BannerId?.[0]);
      });
  };
  return (
    <div>
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      <div className="pl-4 flex items-center justify-between w-3/5 pb-6">
        <AppButton
          className="bechteChaiBtn"
          onClick={() => navigate("/admin/bechte-chai/sub-categories")}
          text="Back"
        />
        <h2 className="text-center title">Update Bechte Chai Sub category</h2>
      </div>

      <form className="max-w-xl mx-auto myBorder py-4 px-5">

        <AppInput
          label="Main Category name"
          className="myInput"
          type="text"
          placeholder="Enter Sub Category Name"
          value={name}
          onChange={handleNameChange}
          errorMessage={nameErrorMessage}
        />
        <p>{errors?.MainCategoryName?.[0]}</p>

        <AppTextArea
          label="Description"
          placeholder="Enter Description"
          value={description}
          onChange={handleDescriptionChange}
          errorMessage={descriptionErrorMessage}
        />
        <div className="flex justify-between items-center py-2">
          <div className="flex items-center gap-x-3">
            <label htmlFor="isPublished" className="text-xl">Published</label>
            <input
              className="w-6 h-6 cursor-pointer"
              type="checkbox"
              name="isPublished"
              checked={isPublished}
              onChange={() => handleIsPublishedChange(!isPublished)}
            />
            <p>{errors?.isPublished?.[0]}</p>
          </div>
        
        </div>

        <div>
          <label className="2xl:text-xl md:text-lg text-base" placeholder="Dhaka">Select Main Category</label>
          <AppSelect
            value={mainCategoryId}
            data={idNameModels}
            defaultValue="Select Main Category"
            placeholder="Select Main Category"
            onChange={handleMainCategoryIdChange}
          />
        </div>
       
        <div className="flex justify-end mt-2">
          <AppButton onClick={handleSubmit} isSubmitting={isButtonLoading} variant="contained" text="Submit" className="bechteChaiBtn" />
        </div>
      </form>

    </div>
  );
}
