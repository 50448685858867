import { getImamsNumberService, getImamsNumberByIdService } from "../../../httpService/importantNumbers/imamsNumberServices";
import { GET_IMAMS_NUMBER, GET_IMAMS_NUMBER_BY_ID } from "../../constants/importantNumbers/imamsNumbers/imamsNumbersConstant";

const getImamsNumbersAction = (payload?: any) => async (dispatch: any) => {
    var data = getImamsNumberService(payload);
    const pload = (await data).data;
    dispatch({ type: GET_IMAMS_NUMBER, payload: pload });
};

const getImamsNumbersByIdAction = (payload?: any) => async (dispatch: any) => {
    var data = getImamsNumberByIdService(payload);
    const pload = (await data).data;
    dispatch({ type: GET_IMAMS_NUMBER_BY_ID, payload: pload });
};

export { getImamsNumbersAction, getImamsNumbersByIdAction };