import { AlertColor } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { addDoctorNumber } from "../../../../models/importantNumber/ImportantNumberModel";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { AppButton } from "../../../common/AppButton";
import { AppInput } from "../../../common/AppInput";
import { IdNameResponseModel } from "../../../../models/common/IdNameModel";
import { RootState } from "../../../../store";
import { AppSelect } from "../../../common/AppSelect";
import { getHospitalsByAuthAction } from "../../../../store/actions/importantNumbers/doctorNumbersActions";
import { getDoctorByIdService, updateDoctorService } from "../../../../httpService/importantNumbers/doctorNumbersServices";
import { isAdmin } from "../../../../common/common";
import { reactLocalStorage } from "reactjs-localstorage";

export default function INUpdateDoctorNumber() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [name, setName] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [phoneNumberFirst, setPhoneNumberFirst] = useState("");
  const [phoneNumberFirstErrorMessage, setPhoneNumberFirstErrorMessage] =
    useState("");
  const [phoneNumberSecond, setPhoneNumberSecond] = useState("");
  const [phoneNumberSecondErrorMessage, setPhoneNumberSecondErrorMessage] =
    useState("");
  const [experties, setExperties] = useState("");
  const [expertiesErrorMessage, setExpertiesErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [hospitalsNumbersId, setHospitalsNumbersId] = useState(0);
  const [address, setAddress] = useState("");
  const [addressErrorMessage, setAddressErrorMessage] = useState("");

  const doctor = async (id: any) => {
    const data = await getDoctorByIdService(id);
    const response: addDoctorNumber = data?.data;
    setName(response.name);
    setExperties(response.experties);
    setPhoneNumberFirst(response.phoneNumberFirst);
    setPhoneNumberSecond(response.phoneNumberSecond);
    setEmail(response.email);
    setAddress(response.address);
    if(response.hospitalsNumbersId!==null) setHospitalsNumbersId(response.hospitalsNumbersId);
  };

  const handleNameChange = (event: any) => {
    setName(event);
    setNameErrorMessage("");
  };

  const handlePhoneNumberFirstChange = (event: any) => {
    setPhoneNumberFirst(event);
    setPhoneNumberFirstErrorMessage("");
  };
  const handlePhoneNumberSecondChange = (event: any) => {
    setPhoneNumberSecond(event);
    setPhoneNumberSecondErrorMessage("");
  };

  const handleEmailChange = (event: any) => {
    setEmail(event);
    setEmailErrorMessage("");
  };

  const handleExperties = (event: any) => {
    setExperties(event);
    setExpertiesErrorMessage("");
  };

  const handleAddressChange = (event: any) => {
    setAddress(event);
    setAddressErrorMessage("");
  };

  const handleHospitalsNumbersId = (id: any) => [setHospitalsNumbersId(id)];

  useEffect(() => {
    dispatch(getHospitalsByAuthAction());
  }, [dispatch]);
  
  const hospitalsList = useSelector((state: RootState) => {
    return state.getHospitalsByAuthResponse.data;
  });

  var dataHospitalListIdNameModels: IdNameResponseModel[] = [];
  hospitalsList?.items?.map((x: any) => {
    var hospital = new IdNameResponseModel();
    hospital.id = x.id;
    hospital.name = x.name;
    dataHospitalListIdNameModels.push(hospital);
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSnackBarOn(false);
    var payload = {
      id: id,
      name: name,
      phoneNumberFirst: phoneNumberFirst,
      phoneNumberSecond: phoneNumberSecond,
      email: email,
      experties: experties,
      hospitalsNumbersId: hospitalsNumbersId,
      address: address,
    };

    setIsButtonLoading(true);
    
    updateDoctorService(payload)
      .then(() => {
        setSnackBarOn(true);
        setsnackBarMessage("Doctor Number Updated Successfully.");
        setSnackBarMessageType("success");
        setIsButtonLoading(false);
        setTimeout(() => {
          navigateToDoctorNumbers();
        }, 300);
      })
      .catch((error: any) => {
        setIsButtonLoading(false);
        setNameErrorMessage(error.response.data.errors?.Name?.[0]);
        setPhoneNumberFirstErrorMessage(
          error.response.data.errors?.PhoneNumberFirst?.[0]
        );
        setPhoneNumberSecondErrorMessage(
          error.response.data.errors?.PhoneNumberSecond?.[0]
        );
        setEmailErrorMessage(error.response.data.errors?.Email?.[0]);
        setAddressErrorMessage(error.response.data.errors?.Address?.[0]);
      });
  };

  useEffect(() => {
    doctor(id);
  }, []);

  const navigateToDoctorNumbers = () => {
    if (isAdmin(reactLocalStorage.get("RoleName"))) {
      navigate(`/admin/important-numbers/doctor-numbers`);
    } else {
      navigate(`/important-numbers/doctor-numbers`);
    }
  };
  return (
    <div>
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      <div className="flex w-3/5 items-center justify-between pb-6 pl-4">
        <AppButton
          onClick={navigateToDoctorNumbers}
          text="Back"
        />
        <h2 className="title text-center">Update Doctor Number</h2>
      </div>
      <form className="myBorder mx-auto max-w-xl px-5 py-4">
        <AppInput
          label="Name"
          className="myInput"
          type="text"
          placeholder="Enter Name"
          onChange={handleNameChange}
          errorMessage={nameErrorMessage}
          value={name}
        />
        <AppInput
          label="Experties"
          className="myInput"
          type="text"
          value={experties}
          placeholder="Enter Experties"
          onChange={handleExperties}
          errorMessage={expertiesErrorMessage}
        />
        <AppInput
          label="Phone Number First"
          className="myInput"
          type="text"
          placeholder="Enter Phone Number"
          onChange={handlePhoneNumberFirstChange}
          errorMessage={phoneNumberFirstErrorMessage}
          value={phoneNumberFirst}
        />
        <AppInput
          label="Phone Number Second"
          className="myInput"
          type="text"
          placeholder="Enter Phone Number"
          onChange={handlePhoneNumberSecondChange}
          errorMessage={phoneNumberSecondErrorMessage}
          value={phoneNumberSecond}
        />
        <AppInput
          label="Email"
          className="myInput"
          type="text"
          placeholder="Enter Email"
          onChange={handleEmailChange}
          errorMessage={emailErrorMessage}
          value={email}
        />
        <AppInput
          label="Address"
          className="myInput"
          type="text"
          placeholder="Enter Address"
          onChange={handleAddressChange}
          errorMessage={addressErrorMessage}
          value={address}
        />
        <div className="my-1">
          <p className="text-sm md:text-base">Hospital:</p>
          <AppSelect
            defaultValue="Select Hospital"
            value={hospitalsNumbersId}
            data={dataHospitalListIdNameModels}
            onChange={handleHospitalsNumbersId}
          />
        </div>
        <div className="flex justify-end">
          <AppButton
            text="Submit"
            onClick={handleSubmit}
            isSubmitting={isButtonLoading}
            variant="contained"
          />
        </div>
      </form>
    </div>
  );
}
