import { useEffect, useState } from "react";
import {
  getIsPurchasedAction,
  getIsTrainingPaidAction,
  getIsTrainingVideoWatchedAction,
} from "../../../store/actions/training/trainingAction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { AppModal } from "../../common/AppModal";
import { Link } from "react-router-dom";
import { AppButton } from "../../common/AppButton";
import { AppLoader } from "../../common/AppLoader";
import { AppPagination } from "../../common/AppPagination";
import { CreateDataOfferOrderRequest, DataOfferListRequest, GetUserWalletResponse } from "../../../models/auth/user/UserPointModels";
import { IoMdClose } from "react-icons/io";
import { AppInput } from "../../common/AppInput";
import { getUserWalletAction } from "../../../store/actions/auth/user/userActions";
import { createDataOfferOrderService } from "../../../httpService/user/userServices";
import { getDataOfferListAction } from "../../../store/actions/dataOffer/dataOfferAction";
import { PaginationPayloadModel } from "../../../models/common/paginationModels";

export default function DataOffersGetOffers() {

  const dispatch = useDispatch();

  var dataOfferListRequest = new DataOfferListRequest();
  const [isLoading, setIsLoading] = useState(true);
  const [dataOfferType, setDataOfferType] = useState(0);
  const [dataPackageType, setDataPackageType] = useState(0);
  const [mobileOperator, setMobileOperator] = useState(0);

  const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false);
  const [purchesModalTitle, setPurchaseModalTitle] = useState("");
  const [selectedDataOfferId, setSelectedDataOfferId] = useState(0);
  const [selectedDataOfferPrice, setSelectedDataOfferPrice] = useState(0);

  const [isPackageTypeOpen, setIsPackageTypeOpen] = useState(false);
  const [isMobileOperatorOpen, setIsMobileOperatorOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dataOfferListRequest.limit = 10;
        dataOfferListRequest.offset = 0;
        dataOfferListRequest.dataOfferType = 0;
        dataOfferListRequest.dataPackageType = 0;
        dataOfferListRequest.mobileOperator = 0;
        await dispatch(getDataOfferListAction(dataOfferListRequest));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  const wallet = useSelector((state: RootState) => {
    return state.getuserWalletResponse.data as GetUserWalletResponse;
  });

  useEffect(() => {
    dispatch(getUserWalletAction(0));
  }, [dispatch]);

  const dataOfferList = useSelector((state: RootState) => {
    return state.dataOfferListResponse.dataOfferList;
  });

  const isAllTrainingVideoNotWatched = useSelector((state: RootState) => {
    return state.getIsTrainingVideoWatchedResponse.data;
  });

  useEffect(() => {
    dispatch(getIsTrainingVideoWatchedAction(0));
  }, [dispatch]);

  const isPurchased = useSelector((state: RootState) => {
    return state.getIsPurchasedResponse.data;
  });

  useEffect(() => {
    dispatch(getIsPurchasedAction(1));
  }, [dispatch]);

  const isPaid = useSelector((state: RootState) => {
    return state.getIsPaidResponse.data;
  });

  useEffect(() => {
    dispatch(getIsTrainingPaidAction(0, 1));
  }, [dispatch]);

  const handleOnRowChange = (payload: PaginationPayloadModel) => {
    const fetchData = async () => {
      try {
        dataOfferListRequest.limit = payload.limit;
        dataOfferListRequest.offset = 0;
        dataOfferListRequest.dataOfferType = dataOfferType;
        dataOfferListRequest.dataPackageType = dataPackageType;
        dataOfferListRequest.mobileOperator = mobileOperator;
        await dispatch(getDataOfferListAction(dataOfferListRequest));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();
  };

  const handleOnPageChange = (payload: PaginationPayloadModel) => {
    const fetchData = async () => {
      try {
        dataOfferListRequest.limit = payload.limit;
        dataOfferListRequest.offset = payload.offset;
        dataOfferListRequest.dataOfferType = dataOfferType;
        dataOfferListRequest.dataPackageType = dataPackageType;
        dataOfferListRequest.mobileOperator = mobileOperator;
        await dispatch(getDataOfferListAction(dataOfferListRequest));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  };

  const handleDataOfferType = (value: any) => {
    setIsPackageTypeOpen(true)
    const fetchData = async () => {
      try {
        setDataOfferType(value);
        setDataPackageType(0);
        setMobileOperator(0);
        dataOfferListRequest.limit = 10;
        dataOfferListRequest.offset = 0;
        dataOfferListRequest.dataOfferType = value;
        dataOfferListRequest.dataPackageType = 0;
        dataOfferListRequest.mobileOperator = 0;

        await dispatch(getDataOfferListAction(dataOfferListRequest));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  };

  const handleDataPackageType = (value: any) => {
    setIsMobileOperatorOpen(true)
    const fetchData = async () => {
      try {
        setDataPackageType(value);
        setMobileOperator(0);
        dataOfferListRequest.limit = 10;
        dataOfferListRequest.offset = 0;
        dataOfferListRequest.dataOfferType = dataOfferType;
        dataOfferListRequest.dataPackageType = value;
        dataOfferListRequest.mobileOperator = 0;
        await dispatch(getDataOfferListAction(dataOfferListRequest));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  };

  const handleMobileOperator = (value: any) => {
    const fetchData = async () => {
      try {
        setMobileOperator(value);
        dataOfferListRequest.limit = 10;
        dataOfferListRequest.offset = 0;
        dataOfferListRequest.dataOfferType = dataOfferType;
        dataOfferListRequest.dataPackageType = dataPackageType;
        dataOfferListRequest.mobileOperator = value;
        await dispatch(getDataOfferListAction(dataOfferListRequest));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  };

  const closePurchaseModal = () => {
    setIsPurchaseModalOpen(false);
    setPhoneNumberError("");
    setPhoneNumber('')
  };

  const openPurchaseModal = (dataOfferId: any, price: any) => {
    setIsPurchaseModalOpen(true);
    setPurchaseModalTitle("Purchase Offer");
    setSelectedDataOfferId(dataOfferId);
    setSelectedDataOfferPrice(price);
  };

  const handlePhoneNumber = (value: any) => {
    setPhoneNumber(value)
    setPhoneNumberError("");
  }

  const [phoneNumber, setPhoneNumber] = useState("")
  const [phoneNumberError, setPhoneNumberError] = useState("")

  const [isConfirmButtonLoading, setIsConfirmButtonLoading] = useState(false)

  const confirmOrder = () => {

    var model = new CreateDataOfferOrderRequest();
    model.dataOfferId = selectedDataOfferId;
    model.phoneNumber = phoneNumber;

    setIsConfirmButtonLoading(true);

    createDataOfferOrderService(model)
      .then(() => {
        setIsConfirmButtonLoading(false)
        closePurchaseModal();
      }).catch((error: any) => {
        setIsConfirmButtonLoading(false)
        setPhoneNumberError(error.response.data.errors?.PhoneNumber?.[0]);
      })
  };


  return (
    <div className="container mx-auto px-2 lg:px-0 py-2 lg:py-4">
      {isPurchaseModalOpen && (
        <AppModal>
          <div className="p-2 md:w-96">
            <div className="flex justify-end">
              <IoMdClose
                className="cursor-pointer text-2xl font-semibold text-royalBlue"
                onClick={closePurchaseModal}
              />
            </div>
            {wallet.mainBalance >= selectedDataOfferPrice ? (
              <div className="text-royalBlue text-center font-medium py-2">
                <p>For purchase this offer</p>
                <p>You have to pay {selectedDataOfferPrice}</p>
                <p>Your Main Balance have :{wallet.mainBalance}</p>
                <p>After purchase Your remaining balance will be: {wallet.mainBalance - selectedDataOfferPrice}</p>
                <AppInput
                  type='text'
                  className="myInput"
                  placeholder="Enter Phone Number"
                  value={phoneNumber}
                  onChange={handlePhoneNumber}
                  errorMessage={phoneNumberError}
                />
                <div className="my-2 flex justify-end">
                  <AppButton className="myBtn border-royalBlue bg-royalBlue" isSubmitting={isConfirmButtonLoading} text="Confirm" onClick={confirmOrder} />
                </div>
              </div>
            ) : (
              <div className='text-royalBlue font-medium p-2'>
                <p>You do not have sufficient balance you have {wallet.mainBalance}</p>
              </div>
            )}

          </div>
        </AppModal>
      )}

      {!isPaid && (
        <AppModal>
          <div className="flex flex-col-reverse items-center justify-between gap-4 px-4 py-12 md:flex-row md:px-20">
            <div className="flex flex-col items-center gap-3 md:items-start">
              <h2 className="text-xl font-semibold text-royalBlue md:max-w-md md:text-3xl">
                Please Pay Your Training Payment First
              </h2>
              <div
                className="myOfferBtn w-fit border-royalBlue bg-royalBlue py-1.5"
                onClick={() => { }}
              >
                <Link to="/myWallet">Wallet</Link>
              </div>
            </div>
            <img
              className="h-40 w-60 object-contain md:h-48 md:w-72"
              src="https://res.cloudinary.com/ddqenwff0/image/upload/v1687276368/Group_cmdbqn.png"
              alt=""
            />
          </div>
        </AppModal>
      )}

      {!isPurchased && (
        <AppModal>
          <div className="flex flex-col-reverse items-center justify-between gap-4 px-4 py-12 md:flex-row md:px-20">
            <div className="flex flex-col items-center gap-3 md:items-start">
              <h2 className="text-xl font-semibold text-royalBlue md:max-w-md md:text-3xl">
                Please Purchase Your Training First
              </h2>
              <div
                className="myOfferBtn w-fit border-royalBlue bg-royalBlue py-1.5"
                onClick={() => { }}
              >
                <Link to="/training">Buy Now</Link>
              </div>
            </div>
            <img
              className="h-40 w-60 object-contain md:h-48 md:w-72"
              src="https://res.cloudinary.com/ddqenwff0/image/upload/v1687276368/Group_cmdbqn.png"
              alt=""
            />
          </div>
        </AppModal>
      )}

      {isAllTrainingVideoNotWatched && (
        <AppModal>
          <div className="flex flex-col-reverse items-center justify-between gap-4 px-4 py-12 md:flex-row md:px-20">
            <div className="flex flex-col items-center gap-3 md:items-start">
              <h2 className="text-xl font-semibold md:max-w-md md:text-3xl">
                Please Complete your Training First
              </h2>
              <div className="myOfferBtn w-fit py-1.5" onClick={() => { }}>
                <Link to="/training">Go To Training</Link>
              </div>
            </div>
            <img
              className="h-40 w-60 object-contain md:h-48 md:w-72"
              src="https://res.cloudinary.com/ddqenwff0/image/upload/v1687276368/Frame_2_dncct1.png"
              alt=""
            />
          </div>
        </AppModal>
      )}
      <div className=''>
        <Link to="/dataOffer/orders">
          <AppButton className='myOfferBtn' onClick={() => { }} text="My Orders" />
        </Link>
      </div>
      <div className='flex flex-col items-center justify-center  gap-1.5 lg:gap-2 pb-2 lg:pb-4'>
        <div className="flex items-center gap-2 lg:gap-4">
          <AppButton className={dataOfferType === 1 ? 'myOfferBtn' : 'offerOutlineBtn'} onClick={() => handleDataOfferType(1)} text="Ajker Offer" />
          <AppButton className={dataOfferType === 2 ? 'myOfferBtn' : 'offerOutlineBtn'}
            onClick={() => handleDataOfferType(2)}
            text="Protidiner Offer"
          />
        </div>
        {isPackageTypeOpen &&
          <div className="flex items-center gap-2 lg:gap-4">
            <AppButton className={dataPackageType === 0 ? 'myOfferBtn' : 'offerOutlineBtn'} onClick={() => handleDataPackageType(0)} text="All" />
            <AppButton className={dataPackageType === 1 ? 'myOfferBtn' : 'offerOutlineBtn'} onClick={() => handleDataPackageType(1)} text="Minutes" />
            <AppButton className={dataPackageType === 2 ? 'myOfferBtn' : 'offerOutlineBtn'} onClick={() => handleDataPackageType(2)} text="Internet" />
            <AppButton className={dataPackageType === 5 ? 'myOfferBtn' : 'offerOutlineBtn'} onClick={() => handleDataPackageType(5)} text="Bundle" />
          </div>
        }
        {isMobileOperatorOpen &&
          <div className="flex items-center gap-2 lg:gap-4">
            <AppButton className={mobileOperator === 0 ? 'myOfferBtn' : 'offerOutlineBtn'} onClick={() => handleMobileOperator(0)} text="All" />
            <div className={`${mobileOperator === 1 ? 'myOfferBtn' : 'offerOutlineBtn'}  flex items-center gap-1`} onClick={() => handleMobileOperator(1)}>
              <span className="hidden lg:block">GrameenPhone</span>
              <img className="h-4 lg:h-5" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/pngegg_8_yd64b0.png" alt="gp" /></div>
            <div className={`${mobileOperator === 2 ? 'myOfferBtn' : 'offerOutlineBtn'}  flex items-center`} onClick={() => handleMobileOperator(2)}>
              <span className="hidden lg:block">  BanglaLink</span>
              <img className="h-4 lg:h-5" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/pngegg_9_gbxxlc.png" alt="bl" /></div>
            <div className={`${mobileOperator === 3 ? 'myOfferBtn' : 'offerOutlineBtn'}  flex items-center gap-1`} onClick={() => handleMobileOperator(3)}>
              <span className="hidden lg:block">     Robi</span>
              <img className="h-4 lg:h-5" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/PikPng.com_outlook-logo-png_5792430_pfbmm8.png" alt="robi" /></div>
            <div className={`${mobileOperator === 4 ? 'myOfferBtn' : 'offerOutlineBtn'}  flex items-center gap-1`} onClick={() => handleMobileOperator(4)}>
              <span className="hidden lg:block"> Airtel</span>
              <img className="h-4 lg:h-5" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/pngegg_7_qib2h0.png" alt="airtel" /></div>
            <div className={`${mobileOperator === 5 ? 'myOfferBtn' : 'offerOutlineBtn'}  flex items-center`} onClick={() => handleMobileOperator(5)} >
              <img className="h-4 lg:h-5" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/7dda3b58784509.5a60b0bd2ae78-removebg-preview_uyzhko.png" alt="skitto" /></div>
            <div className={`${mobileOperator === 6 ? 'myOfferBtn' : 'offerOutlineBtn'}  flex items-center`} onClick={() => handleMobileOperator(6)}>
              <img className="h-4 lg:h-5" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Teletalk_ds29pc.png" alt="teletalk" />
            </div>
          </div>
        }
      </div>

      {isLoading ? (
        <AppLoader status={true} />
      ) : (
        <>
          {dataOfferList?.items ? (
            <>
              <div className="w-full">
                {/* these is header part for desktop */}
                <div className="myBorder hidden w-full bg-royalBlue lg:flex">
                  <div className="tableHead w-[25%]">
                    Title
                  </div>
                  <div className="tableHead w-[15%]">
                    {" "}
                    Mobile Operator
                  </div>
                  <div className="tableHead w-[10%]">
                    Package Type
                  </div>
                  <div className="tableHead w-[9.8%]">
                    Duration
                  </div>
                  <div className="tableHead w-[9.8%]">
                    Price
                  </div>
                  <div className="tableHead w-[15%]">
                    Shop Number
                  </div>
                  <div className="tableHead border-none w-[15%]">
                    Purchase
                  </div>
                </div>
                {/* these is middle part for desktop */}
                <div className="hidden h-[65dvh] flex-col gap-1 overflow-y-auto py-1.5 lg:flex 2xl:h-[68dvh]">
                  {dataOfferList?.items?.map((dataOffer: any) => (
                    <div
                      key={dataOffer?.id}
                      className="myBorder flex w-full items-center"
                    >
                      <div className="text-start h-full flex flex-col w-[25%] justify-start border-r border-grey py-1  pl-2">
                        <p className="font-medium">{dataOffer?.title}</p>
                        <p className="text-sm">{dataOffer?.description}</p>
                      </div>
                      <div className="centerDiv h-full w-[15%] overflow-hidden text-ellipsis whitespace-nowrap border-r border-grey py-1  pl-2">
                        {dataOffer?.mobileOperatorName}
                      </div>
                      <div className="centerDiv h-full w-[10%] overflow-hidden text-ellipsis whitespace-nowrap border-r border-grey  py-1">
                        {dataOffer?.dataPackageTypeName}
                      </div>
                      <div className="centerDiv h-full w-[10%] overflow-hidden text-ellipsis whitespace-nowrap border-r border-grey  py-1">
                        {dataOffer?.validityDuration}
                      </div>
                      <div className="centerDiv font-medium h-full w-[10%] overflow-hidden text-ellipsis whitespace-nowrap border-r border-grey  py-1">
                        ৳{dataOffer?.price}Tk
                      </div>
                      <div className="centerDiv h-full w-[15%] overflow-hidden text-ellipsis whitespace-nowrap border-r border-grey  py-1">
                        {dataOffer?.shopNumber}
                      </div>
                      <div className="centerDiv h-full w-[15%] overflow-hidden text-ellipsis whitespace-nowrap border-r border-grey  py-1">
                        <AppButton className="myOfferBtn"
                          onClick={() => openPurchaseModal(dataOffer?.id, dataOffer.price)}
                          text="Purchase"
                        />
                      </div>
                    </div>
                  ))}
                </div>
                {/* these is middle part for mobile */}
                <div className="flex h-[78dvh] flex-col gap-2 overflow-y-auto lg:hidden">
                  {dataOfferList?.items?.map((dataOffer?: any) => (
                    <div onClick={() => openPurchaseModal(dataOffer?.id, dataOffer.price)}
                      key={dataOffer?.id}
                      className="myBorder flex w-full flex-col p-2 text-xs"
                    >
                      <div className="">
                        <div className="flex items-center justify-between font-medium w-full">
                          {/* <span className="font-medium">Title:</span>{" "} */}
                          <span>{dataOffer?.title} ({dataOffer?.dataPackageTypeName})</span>
                          <div className='ml-auto'>
                            <span>{dataOffer?.mobileOperatorName === "Robi" && <img className="h-4 lg:h-5" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/PikPng.com_outlook-logo-png_5792430_pfbmm8.png" alt="robi" />}</span>
                            <span>{dataOffer?.mobileOperatorName === "GrameenPhone" && <img className="h-4 lg:h-5" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/pngegg_8_yd64b0.png" alt="gp" />}</span>
                            <span>{dataOffer?.mobileOperatorName === "BanglaLink" && <img className="h-4 lg:h-5" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/pngegg_9_gbxxlc.png" alt="bl" />}</span>
                            <span>{dataOffer?.mobileOperatorName === "Airtel" && <img className="h-4 lg:h-5" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/pngegg_7_qib2h0.png" alt="airtel" />}</span>
                            <span>{dataOffer?.mobileOperatorName === "Skitto" && <img className="h-4 lg:h-5" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/7dda3b58784509.5a60b0bd2ae78-removebg-preview_uyzhko.png" alt="skitto" />}</span>
                            <span>{dataOffer?.mobileOperatorName === "Teletalk" && <img className="h-4 lg:h-5" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Teletalk_ds29pc.png" alt="teletalk" />}</span>
                          </div>
                        </div>
                        <div className="flex items-center text-xs gap-0.5">
                          {dataOffer?.description}
                        </div>
                        <div className="flex justify-between items-center gap-0.5">
                          <span className="font-medium">{dataOffer?.validityDuration}</span>
                          <span className="font-medium"> Shop Number: {dataOffer?.shopNumber}</span>
                          <span className="font-semibold text-royalBlue">৳{dataOffer?.price}Tk</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {/* these is footer part  */}
                {/* <div className="myBorder py-1 text-center">pagination here</div> */}
              </div>
              <div className="flex items-center py-1">
                <AppPagination
                  curPage={1}
                  limit={10}
                  offset={0}
                  onPageChange={handleOnPageChange}
                  onRowChange={handleOnRowChange}
                  size={dataOfferList?.size}
                />
              </div>
            </>
          ) : (
            <div className="flex h-[50dvh] w-full items-center justify-center text-3xl font-medium text-textBlack/90">
              <span>No Data Offer found</span>
            </div>
          )}
        </>
      )}
    </div>
  );
}
