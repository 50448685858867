import { PaginationPayloadModel } from "../../../common/paginationModels";


export class SSUpdateProductModel {
    id?: any;
    name?: string;
    shortDescription?: string;
    fullDescription?: string;
    productCode?: any;
    customerTypeTag?: any = 0;
    isPublished?: any;
    mainCategoryId?: any = 0;
    subCategoryId?: any = 0;
    productBrandId?: any = 0;
    price?: any = 0;
    oldPrice?: any = 0;
    availableStock?: any = 0;
    mediaIds?: Array<string | null>;
}

export enum SSCustomerTypeTag {
    All = 0,
    Men = 1,
    Women = 2,
    Kid = 3,
}

export class SSProductPublishModel {
    productId?: any;
    isPublished?: any;
}


export enum HomeProductFilterEnum {
    All = 0,
    QuickSell = 1,
    TopSold = 2,
    TopReviewed = 3,
    TopFavourites = 4,
    TopViewed = 5,
    NewUpload = 6
}


export class Type {
    label?: string;
    handler?: () => void;
    icon?: React.ReactElement;
    imgIcon?: string;
    lgImg?: string;
    smImg?: string;
}


export class AddSSProductMedia {
    id?: number;
    url: string = "";
}


export class SSGetProductsRequest extends PaginationPayloadModel {
    productCode?: any;
    productTag?: any;
    mainCategoryId?: any = 0;
    subCategoryId?: any = 0;
    productBrandId?: any = 0;
    bazarId?: any= 0;
    customerTypeTag?: any;
    isPublished?: any;
    sortBy?: any;
    maximumPrice?: any;
    minimumPrice?: any;
    homeProductFilterType?: any;
}
