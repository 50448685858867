import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { AlertColor } from "@mui/material";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import { BiSearchAlt2 } from "react-icons/bi";
import { GetAreaNumberModel } from "../../../../models/importantNumber/ImportantNumberModel";
import { getUserProfileAction } from "../../../../store/actions/auth/user/userActions";
import { areaListAdmin, areaListByDistrictId } from "../../../../store/actions/auth/address/areaActions";
import { RootState } from "../../../../store";
import { deleteAreaService } from "../../../../httpService/area/areaServices";
import { AppSearchBox } from "../../../common/AppSearchBox";
import { AppLoader } from "../../../common/AppLoader";
import { AppPagination } from "../../../common/AppPagination";
import { AppDeleteModal } from "../../../common/AppDeleteModal";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { AppButton } from "../../../common/AppButton";
import { IdNameResponseModel } from "../../../../models/common/IdNameModel";

export default function AdminGetAreas() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    var pModel = new GetAreaNumberModel();

    const [snackBarMessage, setsnackBarMessage] = useState("");
    const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
    const [snackBarOn, setSnackBarOn] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [districtId, setDistrictId] = useState(0);
    const [districtError, setDistrictError] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        dispatch(getUserProfileAction());
    }, [dispatch]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                var pModel = new GetAreaNumberModel();
                pModel.searchQuery = searchQuery;
                pModel.limit = 10;
                pModel.offset = 0;
                pModel.districtId = districtId;
                await dispatch(areaListAdmin(pModel));
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [dispatch]);

    const areas = useSelector((state: RootState) => {
        return state.areaListAdminResponse.data;
    });

    useEffect(() => {
        if (districtId !== 0) {
            dispatch(areaListByDistrictId(districtId));
        }
    }, [districtId, dispatch]);

    const districts = useSelector((state: RootState) => {
        return state.districtListResponse.data;
    });
    var districtsIdNameModels: IdNameResponseModel[] = [];

    districts?.map((x: any) => {
        var district = new IdNameResponseModel();
        district.id = x.id;
        district.name = x.name;
        districtsIdNameModels.push(district);
    });

    const handleOnRowChange = (payload: GetAreaNumberModel) => {
        const fetchData = async () => {
            try {
                var pModel = new GetAreaNumberModel();
                pModel.limit = payload.limit;
                pModel.offset = 0;
                await dispatch(areaListAdmin(pModel));
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };
        fetchData();
    };


    const handleOnPageChange = (payload: GetAreaNumberModel) => {
        const fetchData = async () => {
            try {
                var pModel = new GetAreaNumberModel();
                pModel.limit = payload.limit;
                pModel.offset = payload.offset;
                await dispatch(areaListAdmin(pModel));
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };
        fetchData();
    };

    const handleSearchQuery = debounce((searchQuery: string) => {
        const fetchData = async () => {
            try {
                setSearchQuery(searchQuery);
                var pModel = new GetAreaNumberModel();
                pModel.limit = 10;
                pModel.offset = 0;
                pModel.searchQuery = searchQuery;
                dispatch(areaListAdmin(pModel));
            } catch (error) {
                setIsLoading(false);
            }
        };
        fetchData();
    }, 500);

    const [bulkIds, setBulkIds] = useState([]);
    const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

    const openDeleteModal = (id: any) => {
        setOpenDeleteModal(true);
        let ids: any = [];
        ids.push(id);
        setBulkIds(ids);
    };

    const closeDeleteModal = () => {
        setOpenDeleteModal(false);
    };

    const handleDelete = () => {
        setSnackBarOn(false);
        deleteAreaService(bulkIds)
            .then(() => {
                setSnackBarOn(true);
                setsnackBarMessage("Successfully Deleted");
                setSnackBarMessageType("success");
                closeDeleteModal();
                dispatch(areaListAdmin(pModel));
            })
            .catch((error: any) => {
                setSnackBarOn(true);
                setsnackBarMessage("Something went wrong");
                setSnackBarMessageType("error");
            });
    };

    const handleDistrict = (id: number) => {
        setDistrictError("");
        setDistrictId(id);

        var pModel = new GetAreaNumberModel();
        pModel.limit = 10;
        pModel.offset = 0;
        pModel.districtId = id;

        pModel.searchQuery = searchQuery;
        dispatch(areaListAdmin(pModel));
    };


    return (
        <div className="container mx-auto px-2 py-2">
            {snackBarOn && (
                <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
            )}
            <div className='flex flex-col lg:flex-row items-center'>
                <div className='w-full flex items-center justify-between'>
                    <h2 className="hidden lg:block title w-full text-center text-cyan-600">
                        Total Thana/Upazila ({areas?.size})
                    </h2>
                    <Link className="lg:w-64 2xl:w-72 flex justify-end" to="/thanas/create">
                        <AppButton
                            className="gradientBtn"
                            text="Add Thana/Upazila"
                            onClick={() => { }}
                        />
                    </Link>
                </div>
                <h2 className="lg:hidden title w-full text-center text-cyan-600">
                    Total Thana/Upazila ({areas?.size})
                </h2>
            </div>

            <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 py-2 lg:py-4">
                <div className="flex cursor-pointer items-center rounded border border-grey p-1 shadow-sm md:px-1.5 lg:w-full 2xl:p-2">
                    <BiSearchAlt2 className="cursor-pointer text-xl font-bold text-cyan-600 md:text-2xl 2xl:text-3xl" />
                    <AppSearchBox
                        onChange={handleSearchQuery}
                        placeholder="Search here"
                        className="w-full border-none text-xs md:text-base lg:px-2"
                    />
                </div>

            </div>


            {isLoading ? (
                <AppLoader status={true} />
            ) : (
                <>
                    {areas?.items ? (
                        <div className="w-full">
                            {/* these is header part for desktop */}
                            <div className="myBorder hidden w-full myGradient lg:flex">
                                <div className="tableHead w-[33.33%]">
                                    Name
                                </div>
                                <div className="tableHead w-[33.33%]">
                                    BnName
                                </div>
                                <div className="tableHead w-[33.33%]">
                                    DistrictName
                                </div>
                                {/* <div className="tableHead w-[9%]">
                                    Update
                                </div>
                                <div className="tableHead border-none w-[9%]">
                                    Remove
                                </div> */}
                            </div>
                            {/* these is middle part for desktop */}
                            <div className="hidden h-[58dvh] flex-col gap-1 overflow-y-auto py-1.5 lg:flex 2xl:h-[62dvh]">
                                {areas?.items?.map((area: any) => (
                                    <div
                                        key={area?.id}
                                        id={area?.id}
                                        className="myBorder flex w-full items-center"
                                    >
                                        <div className="tableRow justify-start textOverflowHidden w-[33.33%]">
                                            {area?.name}
                                        </div>
                                        <div className="tableRow w-[33.33%]">
                                            {area?.bnName}
                                        </div>
                                        <div className="tableRow w-[33.33%]">
                                            {area?.districtName}
                                        </div>

                                        {/* <div className="tableRow w-[9%]">
                                            <AppButton
                                                onClick={() => navigate(`/thanas/update/${area?.id}`)}
                                                className="gradientBtn  py-0.5 text-sm lg:py-1"
                                                text="Update"
                                            />
                                        </div>
                                        <div className="tableRow border-none w-[9%]">
                                            <AppButton
                                                className="myBtn border-red bg-red py-0.5 text-sm lg:py-1"
                                                text="Remove"
                                                onClick={() => openDeleteModal(area?.id)}
                                            />
                                        </div> */}
                                    </div>
                                ))}
                            </div>
                            {/* these is middle part for mobile */}
                            <div className="flex h-[78dvh] flex-col gap-2 overflow-y-auto lg:hidden">
                                {areas?.items?.map((area: any) => (
                                    <div
                                        key={area?.id}
                                        className="myBorder flex w-full flex-col p-1 text-xs"
                                    >
                                        <div className="grid grid-cols-2">
                                            <div className="flex items-center gap-0.5">
                                                <span className="font-medium">Name:</span>{" "}
                                                <span>{area?.name}</span>
                                            </div>
                                            <div className="flex items-center gap-0.5">
                                                <span className="font-medium">BnName:</span>{" "}
                                                <span>{area?.bnName}</span>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <div className="flex items-center gap-0.5">
                                                <span className="font-medium">DistrictId:</span>{" "}
                                                <span>{area?.districtName}</span>
                                            </div>
                                        </div>

                                        {/* <div className="flex items-center justify-center w-full gap-2 lg:gap-0.5">
                                            <AppButton
                                                onClick={() =>
                                                    navigate(
                                                        `/admin/auth/area/update/${area?.id}`
                                                    )
                                                }
                                                className="gradientBtn  py-0.5 text-sm lg:py-1"
                                                text="Update"
                                            />
                                            <AppButton
                                                className="myBtn border-red bg-red py-0.5 text-sm lg:py-1"
                                                text="Remove"
                                                onClick={() => openDeleteModal(area?.id)}
                                            />
                                        </div> */}
                                    </div>
                                ))}
                            </div>
                            {/* these is footer part  */}
                            <div className="py-1 flex items-center fixed bottom-1 bg-white w-full lg:static">
                                <AppPagination
                                    curPage={1}
                                    limit={10}
                                    offset={0}
                                    onPageChange={handleOnPageChange}
                                    onRowChange={handleOnRowChange}
                                    size={areas?.size}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="flex h-[50dvh] w-full items-center justify-center text-3xl font-medium text-textBlack/90">
                            <span>Area Not found</span>
                        </div>
                    )}
                </>
            )}
            {isOpenDeleteModal && (
                <AppDeleteModal
                    text="Are you sure to Delete?"
                    handleDelete={handleDelete}
                    closeDeleteModal={closeDeleteModal}
                />
            )}

        </div>
    );
}