interface TableHeadProps {
    tableHead: { name: string, width: string, borderNone?: boolean, start?: boolean }[],
    color: string
}

interface TableBodyProps {
    tableBody: { name: string, width: string, borderNone?: boolean }[],
    items: {}[]
}

export const TableHead: React.FC<TableHeadProps> = ({ tableHead, color }) => {
    return (
        <div className={`hidden lg:flex w-full rounded ${color}`}>
            {
                tableHead.map(tableItem => (
                    <div className={`tableHead ${tableItem?.width} ${tableItem?.start && 'justify-start pl-2'} ${tableItem?.borderNone && 'border-none'}`}>{tableItem?.name}</div>
                ))
            }
        </div>
    );
};


export const TableHeadBody: React.FC<TableBodyProps> = ({ items, tableBody }) => {
    return (
        <div className=''>
            AppTable
        </div>
    );
};
