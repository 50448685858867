import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import { AlertColor } from "@mui/material/Alert";
import { TbCurrencyTaka } from "react-icons/tb";
import { BsHeart, BsHeartFill } from "react-icons/bs";
import { MdPlayCircle } from "react-icons/md";
import { dateTimeFormatter } from "../../../../common/common";
import { addOrRemoveBechteChaiFavouriteService } from "../../../../httpService/bechteChai/bechteChaiProductServices";
import { BechteChaiProductCustomerCommentResponse, BechteChaiFavouriteResponse, BechteChaiAddFavouriteRequest } from "../../../../models/bechteChai/product/bechteChaiProductModels";
import { RootState } from "../../../../store";
import { bechteChaiGetIsAlreadyFavouriteOrNotAction, bechteChaiGetFavouriteItemListAction, bechteChaiGetProductByIdAction, bechteChaiProductCommentsAction } from "../../../../store/actions/bechteChai/bechteChaiProductActions";
import { AppLoader } from "../../../common/AppLoader";
import { AppSnackBar } from "../../../common/AppSnackBar";

export default function BechteChaiGetProductById() {
  
  const ip = reactLocalStorage.get("ip") as string;

  const [isLoading, setIsLoading] = useState(true);

  const [titleBannerPath, setTitleBannerPath] = useState("");

  const [isUpdateFavourite, setIsUpdateFavourite] = useState(false);

  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

  const dispatch = useDispatch();

  const productDetails = useSelector((state: RootState) => {
    return state.bechteChaiProductByIdResponse.data.data;
  });

  const productComments = useSelector((state: RootState) => {
    return state.bechteChaiProductCommentListResponse.data as BechteChaiProductCustomerCommentResponse[];
  });

  const { id } = useParams();

  const [favItems, setFavouriteItems] = useState<BechteChaiFavouriteResponse[]>(
    []
  );

  let isSignIn: any = reactLocalStorage.getObject("SignIn");

  let token = isSignIn?.accessToken;

  const favouriteItemsSignIn = useSelector((state: RootState) => {
    return state.bechteChaiGetFavouriteItemResponse.data;
  });

  const favItemsMemo = useMemo(() => {
    return favouriteItemsSignIn as BechteChaiFavouriteResponse[];
  }, [favouriteItemsSignIn]);

  useEffect(() => {
    if (token && JSON.stringify(token) !== JSON.stringify({})) {
      if (favItemsMemo) {
        setFavouriteItems(favItemsMemo);
      }
    } else {
    }
  }, [favItemsMemo, token]);

  const isFavourite = useSelector((state: RootState) => {
    return state.bechteChaiGetIsAlreadyFavouriteResponse.data;
  });

  useEffect(() => {
    dispatch(bechteChaiGetIsAlreadyFavouriteOrNotAction(id));
  }, [productDetails, id]);

  useEffect(() => {
    dispatch(bechteChaiGetFavouriteItemListAction({}));
  }, [dispatch, productDetails]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await dispatch(bechteChaiGetProductByIdAction(id));
        await dispatch(bechteChaiProductCommentsAction(id));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch, id]);

  useEffect(() => {
    if (productDetails) {
      setTitleBannerPath(productDetails.photoPath);
    }
  }, [productDetails]);

  const handleProductMainPhotoChange = (path: any) => {
    setTitleBannerPath(path?.url);
  };

  const handleAddToFavourite = () => {
    if (token && JSON.stringify(token) !== JSON.stringify({})) {
      let model = new BechteChaiAddFavouriteRequest();
      model.productId = id;
      addOrRemoveBechteChaiFavouriteService(model)
        .then((res) => {
          setIsUpdateFavourite(true);
          dispatch(bechteChaiGetIsAlreadyFavouriteOrNotAction(id));
          setIsShowSnackBar(true);
          setSnackBarMessage("Successfully done");
          setSnackBarMessageType("success");
          setTimeout(() => {
            setIsShowSnackBar(false);
          }, 2000);
        })
        .catch(() => {});
    }
  };

  const handleDownload = async () => {
    const response = await fetch(productDetails?.bannerPath);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "picture.jpg";
    link.click();

    URL.revokeObjectURL(url);
  };

  if (isLoading) {
    return (
      <div className="container mx-auto flex h-screen items-center justify-center px-2 pb-12 md:px-0">
        <AppLoader status={true} />
      </div>
    );
  }

  return (
    <div className="container mx-auto px-2 pb-8 md:px-0">
      {isShowSnackBar && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      
      <div className="flex flex-col gap-x-8 gap-y-4 pt-2 md:flex-row md:pt-4">
        <div className="flex w-full flex-col gap-y-2 md:w-1/2 md:gap-y-4">
          <div className="myBorder relative h-[35dvh] w-full bg-white md:h-[58dvh]">
            <img
              className="h-full w-full object-contain"
              src={
                titleBannerPath !== ""
                  ? titleBannerPath
                  : productDetails?.photoPath
              }
              alt="main big product pic"
            />
          </div>
          <div className="flex h-[8dvh] w-full gap-x-2 md:h-[18dvh] md:gap-x-4">
            {productDetails?.productMediaList &&
              productDetails?.productMediaList?.map((x: any, index: any) => {
                return (
                  <div
                    className="myBorder w-1/5 cursor-pointer bg-white"
                    key={index}
                    onClick={() => handleProductMainPhotoChange(x)}
                  >
                    <img
                      className="h-full w-full rounded object-cover"
                      src={x?.url}
                      alt=""
                    />
                  </div>
                );
              })}
          </div>
        </div>

        <div className="w-full md:w-1/2">
          <p
            className="cursor-pointer pb-1 text-xl font-medium text-royalBlue 2xl:pb-2"
          >
           Posted By, {productDetails?.ownerUser?.firstName} {productDetails?.ownerUser?.lastName}.
          </p>
          <h2 className="pb-1 text-lg font-medium md:text-xl 2xl:pb-2 2xl:text-2xl">
            {productDetails?.name}{" "}
          </h2>

          <div className="flex max-w-sm items-center justify-between md:max-w-md">
            <span className="font-medium">
              {productComments?.length} comments
            </span>
          </div>
          <div className="flex max-w-xs items-center justify-between gap-4">
            {productDetails?.videoUrl && (
              <Link to={productDetails?.videoUrl} target="_blank">
                <MdPlayCircle className="cursor-pointer text-4xl text-affiliate" />
              </Link>
            )}
          </div>

          <div className="flex w-full justify-between gap-x-2 py-1 md:w-11/12 md:gap-x-4 2xl:py-2">
            <div
              className="myBorder h-fit cursor-pointer p-1.5 2xl:p-2"
              onClick={handleAddToFavourite}
            >
              {!isFavourite && (
                <BsHeart className="text-2xl font-bold text-affiliate" />
              )}
              {isFavourite && (
                <BsHeartFill className="text-2xl font-bold text-affiliate" />
              )}
            </div>
          </div>
          {/*Price section here  */}
          <div className="flex flex-row gap-y-[2px] 2xl:gap-y-1">
            <h2 className="subTitle">Price: </h2>
              <TbCurrencyTaka />
              <strong>{productDetails?.price}</strong>
          </div>   
           {/*Category section here  */}
          <div className="flex flex-row gap-y-[2px] 2xl:gap-y-1">
            <h2 className="subTitle">Category: </h2>
              &nbsp;
              <strong>{productDetails?.subCategoryName}, {productDetails?.categoryName}</strong>
          </div>
          <br />
          <br />
          {/*Short description section here  */}
          <div className="flex flex-col gap-y-[2px] 2xl:gap-y-1">
            <h2 className="subTitle">Short Description</h2>
            {/* description title here  */}
            <p className="max-w-xl">
              <div
                dangerouslySetInnerHTML={{
                  __html: productDetails?.shortDescription,
                }}
              />
            </p>
          </div>
          <br />
          <div className="flex flex-col gap-y-[2px] 2xl:gap-y-1">
            <h2 className="subTitle">Description</h2>
            {/* description title here  */}
            <p className="max-w-xl">
              <div
                dangerouslySetInnerHTML={{
                  __html: productDetails?.fullDescription,
                }}
              />
            </p>
          </div>
          <br /><br />

          <div className="flex flex-row gap-y-[2px] 2xl:gap-y-1">
            <h2 className="subTitle">Phone: </h2>&nbsp;
              <p>{productDetails?.phone}</p>
          </div>

          <div className="flex flex-row gap-y-[2px] 2xl:gap-y-1">
            <h2 className="subTitle">Address: </h2>&nbsp;
              <p>{productDetails?.address}, {productDetails?.areaName}, {productDetails?.districtName}, {productDetails?.divisionName} </p>
          </div>

          <div className="flex flex-row gap-x-[2px] 2xl:gap-y-1">
            <h2 className="subTitle">Posted: </h2>&nbsp;
              <p>{dateTimeFormatter(productDetails?.createdAt)}</p>
          </div>
        </div>
      </div>
      {/* <BechteChaiGetComments productComments={productComments} productId={id} /> */}
    </div>
  );
}
