import { AlertColor } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sSaddProductBrandService } from "../../../../../httpService/superShops/sSProductBrandServices";
import { SSAddProductBrand } from "../../../../../models/superShopp/catalog/sSProductBrand/sSProductBrandModel";
import { AppButton } from "../../../../common/AppButton";
import { AppInput } from "../../../../common/AppInput";
import { AppSnackBar } from "../../../../common/AppSnackBar";
import { AppSelect } from "../../../../common/AppSelect";
import { RootState } from "../../../../../store";
import { IdNameResponseModel } from "../../../../../models/common/IdNameModel";
import { PaginationPayloadModel } from "../../../../../models/common/paginationModels";
import { sSGetBazarListAction } from "../../../../../store/actions/superShop/sSBazarActions";
import { isAdmin } from "../../../../../common/common";
import { reactLocalStorage } from "reactjs-localstorage";

export default function AdminSSCreateProductBrand() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);

  const [name, setName] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");

  const [banglaName, setBanglaName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [bazarId, setBazarId] = useState(0);
  const [bazarError, setBazarError] = useState("");

  const handleNameChange = (event: any) => {
    setName(event);
    setNameErrorMessage("");
  };

  const handleBanglaName = (event: any) => {
    setBanglaName(event);
  };

  const handlePhoneNumber = (event: any) => {
    setPhoneNumber(event);
  };

  const bazarList = useSelector((state: RootState) => {
    return state.sSBazarListResponse.data;
  });

  var idNameModels: IdNameResponseModel[] = [];
  bazarList.items?.map((x: { id: number; name: string | undefined }) => {
    var temp = new IdNameResponseModel();
    temp.id = x.id;
    temp.name = x.name;
    idNameModels.push(temp);
  });

  useEffect(() => {
    let paginationModel = new PaginationPayloadModel();
    paginationModel.limit = 1000;
    paginationModel.offset = 0;
    dispatch(sSGetBazarListAction(paginationModel));
  }, []);

  const handleBazarIdChange = (event: any) => {
    setBazarId(event);
    setBazarError("");
  };

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    setSnackBarOn(false);

    var model = new SSAddProductBrand();
    model.name = name;
    model.bnName = banglaName;
    model.phoneNumber = phoneNumber;
    model.bazarId = bazarId;

    setIsButtonLoading(true);

    sSaddProductBrandService(model)
      .then(() => {
        setSnackBarOn(true);
        setsnackBarMessage("Shop Added Successfully.");
        setSnackBarMessageType("success");
        setIsButtonLoading(false);
        setTimeout(() => {
          navigate("/admin/super-shop/product-brand-list");
        }, 300);
      })
      .catch((error: any) => {
        setIsButtonLoading(false);
        setNameErrorMessage(error.response.data.errors?.Name?.[0]);
        setBazarError(error.response.data.errors.BazarId[0]);
      });
  };

  return (
    <div>
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      <div className="pl-4 flex items-center justify-between w-3/5 pb-6">
        <AppButton
          onClick={() => navigate("/admin/super-shop/product-brand-list")}
          text="Back"
        />
        <h2 className="text-center title">Create Brand</h2>
      </div>

      <form className="max-w-xl mx-auto myBorder py-4 px-5">

        <AppInput
          label="Name"
          className="myInput"
          type="text"
          placeholder="Enter Name"
          onChange={handleNameChange}
          errorMessage={nameErrorMessage}
        />

        <AppInput
          label="Bangla Name"
          className="myInput"
          type="text"
          placeholder="Enter Bangla Name"
          onChange={handleBanglaName}
        />

        <AppInput
          label="Phone Number"
          className="myInput"
          type="text"
          placeholder="Enter Phone Number"
          onChange={handlePhoneNumber}
        />

        {isAdmin(reactLocalStorage.get("RoleName")) && (
          <div className='w-full lg:w-1/2'>
            <label className="text-sm lg:text-base" placeholder="Dhaka">Select Bazar</label>
            <AppSelect
              value={bazarId}
              data={idNameModels}
              defaultValue="Select Bazar"
              errorMessage={bazarError}
              onChange={handleBazarIdChange}
            />
          </div>
        )}

        <div className="flex justify-end">
          <AppButton onClick={handleSubmit} isSubmitting={isButtonLoading} variant="contained" text="Submit" />
        </div>

      </form>
    </div>
  );
}
