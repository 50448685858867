import React, { useEffect, useState } from 'react'
import { SignUp } from '../public/auth/SignUp'
import { Link, useNavigate } from 'react-router-dom'
import { SignIn } from '../public/auth/SignIn'
import { useDispatch, useSelector } from 'react-redux';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { reactLocalStorage } from 'reactjs-localstorage';
import { resetNotificationCountService } from '../../httpService/notification/NotificationServices';
import { getNotificationCountAction } from '../../store/actions/notification/notificationActions';
import { RootState } from '../../store';
import { AppDefaultProfilePicture } from '../common/AppDefaultProfilePicture';
import { CgMenu } from 'react-icons/cg';
import { AppButton } from '../common/AppButton';
import { getUserProfileAction } from '../../store/actions/auth/user/userActions';

export default function Navbar() {

    const [sticky, setSticky] = useState(false);

    const [targetRoute, setTargetRoute] = useState("/");

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [hidden, setHidden] = useState(true);
    const [signedIn, setSignedIn] = useState(false);

    const handleAfterSignUp = (data: any) => {
        setSignedIn(data);
        setHidden(true);
        if (data) {
            dispatch({ type: "CLOSE-SIGN-UP" });
        }
    };

    const handleAfterSignIn = (data: any) => {
        setSignedIn(data);
        setHidden(true);
        if (data) {
            dispatch({ type: "CLOSE-SIGN-IN" });
        }
    };

    var token = reactLocalStorage.getObject("SignIn");

    const resetNotification = () => {
        resetNotificationCountService()
            .then(() => {
                dispatch(getNotificationCountAction());
            }).catch(() => {
            })
    }

    const notificationCount = useSelector((state: RootState) => {
        return state.getNotificationCountResponse.data;
    });

    const openSignIn = () => {
        document.body.classList.add('noScroll');
        dispatch({ type: "OPEN-SIGN-IN" });
        dispatch({ type: "CLOSE-SIGN-UP" });
    };

    const userProfile = useSelector((state: RootState) => {
        return state.getUserProfileResponse.data;
    });

    useEffect(() => {
        dispatch(getUserProfileAction());
    }, [dispatch]);

    const openMainMenuDrawer = () => {
        dispatch({ type: "OPEN-MAIN-MENU-DRAWER" });
    }

    return (
        <div className={`${sticky ? "stickyNav" : ""}`}>
            {/* these is first navbar  */}
            <div className="border-b-2 border-grey">

                <SignUp targetRoute={targetRoute} afterSignUp={handleAfterSignUp} />
                <SignIn targetRoute={targetRoute} afterSignIn={handleAfterSignIn} />

                <div className="container mx-auto flex justify-between items-center 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28 ">
                    {/* these is logo section  */}
                    <Link to='/'>
                        <div className="flex items-center gap-x-1 lg:gap-x-2">
                            <img className="h-6 w-7 lg:h-10 lg:w-14  2xl:h-11 2xl:w-16 object-fill" src="/assets/hb_logo.png" alt="These is logo" />
                            <h2 className="text-base md:text-lg lg:text-2xl 2xl:text-4xl">হ্যালোবাজার</h2>
                        </div>
                    </Link>

                    {/* these is login section  */}
                    <div className="flex items-center 2xl:gap-x-10 gap-x-4 md:gap-x-6 lg:gap-x-8">
                        {JSON.stringify(token) !== JSON.stringify({}) && (
                            <Link to="/notifications" >
                                <div onClick={resetNotification} className="relative shadow-sm 2xl:p-2 md:p-1.5 p-1 rounded cursor-pointer">
                                    <IoMdNotificationsOutline className="text-primary cursor-pointer 2xl:text-4xl md:text-3xl text-2xl font-bold" />
                                    <div className={`${notificationCount > 0 ? 'notification' : ''}`}>{notificationCount > 0 ? notificationCount : ''}</div>
                                </div>
                            </Link>
                        )}

                        {JSON.stringify(token) !== JSON.stringify({}) ? (
                            <div className="flex items-center gap-x-2 md:gap-x-4">
                                {/* <h2 className='2xl:text-xl md:text-lg text-base font-medium'>{userProfile?.firstName} {userProfile?.lastName}</h2> */}
                                <Link to='/myProfile'>
                                    <AppDefaultProfilePicture className="rounded-xl cursor-pointer 2xl:w-[50px] 2xl:h-[50px] lg:w-10 w-8 h-8 lg:h-10" name={userProfile?.firstName} profilePicture={userProfile?.photoPath} />
                                </Link>
                                {/* these is menu section  */}
                                <div className="border shadow-sm border-grey 2xl:p-2 md:p-1.5 p-1 rounded cursor-pointer" onClick={openMainMenuDrawer}>
                                    <CgMenu className="text-primary cursor-pointer 2xl:text-3xl md:text-2xl text-xl font-bold" />
                                </div>
                                {/* <LuLogOut title="Log Out" onClick={handleLogout} className="text-affiliate cursor-pointer text-xl md:text-2xl 2xl:text-3xl font-bold" /> */}
                            </div>

                        ) :
                            (
                                <div>
                                    <AppButton text="Login" onClick={openSignIn} className="hidden md:block text-primary text-sm lg:text-lg md:py-[2px] py-[1px] 2xl:px-6 2xl:py-1 px-2 lg:px-4 font-medium 2xl:text-2xl border-2 border-primary hover:border-primary hover:bg-primary hover:text-white rounded" />
                                    <Link to="/signIn" >
                                        <AppButton text="Login" onClick={() => { }} className="block md:hidden text-primary text-sm lg:text-lg md:py-[2px] py-[1px] 2xl:px-6 2xl:py-1 px-2 lg:px-4 font-medium 2xl:text-2xl border-2 border-primary hover:border-primary hover:bg-primary hover:text-white rounded" />
                                    </Link>
                                </div>
                            )}
                    </div>
                </div>
            </div>


        </div>
    )
}
