export class ProductReviewCustomerRequest {
    productId?: any;
    orderId?: any;
    reviewText?: string;
    isParent?: boolean;
    parentId?: number;
    mediaIds?: any
}

export class ProductReviewCustomerResponse {
    id: any;
    createdDateTime?: string
    reviewerId?: string
    customerName?: string
    customerPhotoPath?: string
    isParent?: boolean
    reviewResponseList?: ProductReviewCustomerResponse[];
    reviewText?: string
    updatedDateTime?: string;
    reviewMedias?: any
    orderId?: any
}

export class ProductMediaModel {
    id?: any;
    url?: string;
}

export class ProductRatingCustomerRequest {
    productId?: any;
    value?: number;
}

export class ProductReviewApproveDeclineModel {
    isApprove?: boolean;
    reviewId?: number;
}