import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { AppInput } from "../../common/AppInput";
import { AppCheckBox } from "../../common/AppCheckBox";
import { AppButton } from "../../common/AppButton";
import { Link, useNavigate } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import {
  ShoppingCartFavouriteModel as ShoppingCartFavouriteModel,
  ShoppingCartFavouriteRequest,
} from "../../../models/shoppingCart/resellingCartModels";
import { addToCardOrFavouriteService } from "../../../httpService/shoppingCart/resellingCartServices";
import { getUserProfileAction, getUserProfileNameAction } from "../../../store/actions/auth/user/userActions";
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { SignalRService } from "../../../signalR/SignalRServices";
import { AppSnackBar } from "../../common/AppSnackBar";
import { AlertColor } from "@mui/material/Alert";
import { IoMdClose } from "react-icons/io";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { SignInModel } from "../../../models/auth/user/UserModels";
import { signInService } from "../../../httpService/user/userServices";
import { isAdmin, isBazarRider, isDataOfferVendor, isVendor } from "../../../common/common";

const signalRService = new SignalRService();

interface SignInProps {
  afterSignIn?: any;
  targetRoute?: string;
}

export const SignIn: React.FC<SignInProps> = ({ afterSignIn, targetRoute }) => {

  const ip = reactLocalStorage.get("ip") as string;
  const ref = reactLocalStorage.get("resellRef") as string;

  const signIn = useSelector((state: RootState) => {
    return state.signInModalResponse.signIn;
  });

  const dispatch = useDispatch();

  const closeSignIn = () => {
    document.body.classList.remove('noScroll');
    setEmailOrPhone("");
    setPassword("");
    setEmailOrPhoneError("");
    setPasswordError("");
    dispatch({ type: "CLOSE-SIGN-IN" });
  };

  const goToPrivacyPolicy = () => {
    document.body.classList.remove('noScroll');
    dispatch({ type: "CLOSE-SIGN-IN" });
  }
  const openSignUp = () => {
    dispatch({ type: "CLOSE-SIGN-IN" });
    dispatch({ type: "OPEN-SIGN-UP" });
  };

  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const [emailOrPhoneError, setEmailOrPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handleEmailOrPhoneChange = (event: any) => {
    setEmailOrPhone(event);
    setEmailOrPhoneError("")
  };

  const handlePasswordChange = (event: any) => {
    setPassword(event);
    setEmailOrPhoneError("")
  };

  const handleRememberMeChange = (event: any) => {
    setRememberMe(event);
  };

  const userProfile = useSelector((state: RootState) => {
    return state.getUserProfileResponse.data;
  });

  const navigate = useNavigate();


  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();


  const [isSignInButtonLoading, setSignInButtonLoading] = useState(false);

  const handleSubmit = (event: any) => {
    document.body.classList.remove('noScroll');
    event.preventDefault()
    const data = new SignInModel();

    data.emailOrPhone = emailOrPhone;
    data.password = password;
    data.rememberMe = rememberMe;
    data.ReferrerId = ref;

    setSignInButtonLoading(true)

    signInService(data)
      .then((res: any) => {

        signalRService.startConnection();

        reactLocalStorage.setObject("SignIn", res.data);

        const decodedToken = jwt_decode(res.data?.accessToken) as CustomJwtPayload;

        let role = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
        let userId = decodedToken['sub'] as string;

        reactLocalStorage.set("RoleName", role);

        reactLocalStorage.set("UserId", userId);

        setSignInButtonLoading(false)

        setIsShowSnackBar(true);
        setSnackBarMessage("Successfully SignedIn");
        setSnackBarMessageType("success");
        setTimeout(() => {
          setIsShowSnackBar(false);
        }, 2000);


        afterSignIn(true);
        setEmailOrPhone("");
        setPassword("");
        dispatch(getUserProfileAction());
        dispatch(getUserProfileNameAction(userProfile?.firstName));

        if (isAdmin(reactLocalStorage.get("RoleName"))) {
          navigate(`/admin/dashboard`, {
            replace: true,
          });
        }

        if (isVendor(reactLocalStorage.get("RoleName"))) {
          navigate(`/admin/vendor-dashboard`, {
            replace: true,
          });
        }

        if (isBazarRider(reactLocalStorage.get("RoleName"))) {
          navigate(`/admin/super-shop/rider/dashboard`, {
            replace: true,
          });
        }

        if (isDataOfferVendor(reactLocalStorage.get("RoleName"))) {
          navigate(`/admin/data-offer-vendor/wallet`, {
            replace: true,
          });
        }

        else {

          var cartItems: ShoppingCartFavouriteModel[] = [];

          var pItems = reactLocalStorage.getObject(
            "CartItems"
          ) as ShoppingCartFavouriteModel[];

          if (
            pItems !== undefined &&
            pItems !== null &&
            pItems?.length &&
            pItems
          ) {
            pItems?.map((x: any) => cartItems.push(x));
          }

          var req = new ShoppingCartFavouriteRequest();
          req.customerIp = ip;
          req.shoppingCartType = 1;
          req.cartItems = cartItems;

          addToCardOrFavouriteService(req)
            .then((res) => { })
            .catch(() => { });

          var favouriteItems: ShoppingCartFavouriteModel[] = [];

          var fItems = reactLocalStorage.getObject(
            "FavouriteItems"
          ) as ShoppingCartFavouriteModel[];

          if (
            fItems !== undefined &&
            fItems !== null &&
            fItems?.length &&
            fItems
          ) {
            fItems?.map((x: any) => favouriteItems.push(x));
          }

          var req = new ShoppingCartFavouriteRequest();
          req.customerIp = ip;
          req.shoppingCartType = 2;
          req.cartItems = favouriteItems;

          addToCardOrFavouriteService(req)
            .then((res) => { })
            .catch(() => { });

          reactLocalStorage.remove("CartItems");
          reactLocalStorage.remove("FavouriteItems");
        }
        dispatch({ type: "USER-ROLE-DISPATCH" });
      })
      .catch(() => {
        setEmailOrPhoneError("Wrong Email/Phone or Password");
        setSignInButtonLoading(false)
        afterSignIn(false);
      });
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const naviagteToVendor = () => {
    dispatch({ type: "CLOSE-SIGN-UP" });
    dispatch({ type: "CLOSE-SIGN-IN" });
    navigate("/vendorSignUp", { replace: true });
  }

  if (!signIn) return null;
  else
    return (
      <div className="fixed flex justify-center items-center w-screen bg-black/80 min-h-screen z-50">
        {isShowSnackBar && (
          <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
        )}
        {/* these is main container of sign in  */}
        <div className="w-11/12 md:w-2/5 bg-white p-1 md:p-4 rounded">
          {/* these is sign in header  */}
          <div className="flex justify-end">
            <IoMdClose className="text-primary text-2xl font-semibold cursor-pointer" onClick={closeSignIn} />
          </div>
          {/* these is sign in body  */}
          <div className="">
            <h2 className="subTitle text-center text-textBlack">Sign in to your Account</h2>
            <p className="text-sm text-gray-700 text-center pt-1">Welcome  to হ্যালোবাজার, please enter your details.</p>
            <div className="flex flex-col px-3 md:px-8 py-6">
              <AppInput
                type="email"
                label="Email Or Phone"
                className="myInput"
                placeholder="Enter your Email Or Phone"
                onChange={handleEmailOrPhoneChange}
                errorMessage={emailOrPhoneError}
              />
              <div className="relative pt-2 pb-1">
                <AppInput
                  type={showPassword ? "text" : "password"}
                  label="Password*"
                  className="myInput"
                  placeholder="Enter your password"
                  value={password}
                  onChange={handlePasswordChange}
                />
                {showPassword ? (
                  <AiOutlineEyeInvisible
                    className="absolute text-2xl text-gray-700 font-bold cursor-pointer right-3 top-[55%]"
                    onClick={togglePasswordVisibility}
                  />
                ) : (
                  <AiOutlineEye
                    className="absolute text-2xl text-gray-700 font-bold cursor-pointer right-3 top-[55%]"
                    onClick={togglePasswordVisibility}
                  />
                )}
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-1">
                  <AppCheckBox onChange={() => handleRememberMeChange(!rememberMe)} />
                  <p className="text-sm text-gray-600">Remember me</p>
                </div>

                <Link to={"/resetPassEmail"} onClick={closeSignIn}>
                  <p className="text-sm font-medium text-royalBlue hover:underline underline-offset-1">Forgot Password?</p>
                </Link>
              </div>

              <AppButton
                className="myBtn my-2"
                onClick={handleSubmit}
                text="Sign In"
                isSubmitting={isSignInButtonLoading}
              />
              <div className='flex items-center justify-end'>
                <Link to="/privacyPolicy" onClick={goToPrivacyPolicy} className="text-sm hover:underline underline-offset-1 text-royalBlue">Privacy Policy</Link>
              </div>
              <div className='flex items-center justify-between gap-4 lg:px-8 pt-20 lg:pt-2'>
                {/* these is sign up button  */}
                <div className="flex flex-col justify-center text-center items-center gap-2">
                  <p className="text-gray-700">একাউন্ট নেই?</p> <span onClick={openSignUp} className="myBtn px-[22px] bg-royalBlue border-royalBlue cursor-pointer"> Sign Up </span>
                </div>
                {/* these is vendor button  */}
                <div className="flex flex-col justify-center text-center items-center gap-2">
                  <p className="text-gray-700">ভেন্ডর হতে চান?</p> <span onClick={naviagteToVendor} className="myBtn cursor-pointer">Click here</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

interface CustomJwtPayload extends JwtPayload {
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/role': string;
}