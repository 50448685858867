import { useEffect, useMemo, useState } from "react";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { AppInput } from "../../common/AppInput";
import { AppFileInput } from "../../common/AppFileInput";
import { useDispatch, useSelector } from "react-redux";
import { ProductMediaModel, ProductRatingCustomerRequest, ProductReviewCustomerRequest } from "../../../models/catalog/productReview/productReviewModels";
import { addProductReviewService, submitProductRatingService } from "../../../httpService/catalog/productReviewServices";
import { useNavigate, useParams } from "react-router-dom";
import { AlertColor } from "@mui/material/Alert";
import { AppButton } from "../../common/AppButton";
import { AppSnackBar } from "../../common/AppSnackBar";
import { RootState } from "../../../store";
import { IoMdClose } from "react-icons/io";
import { resellingGetProductByIdAction } from "../../../store/actions/catalog/resellingProductActions";

export default function ResellingSubmitReviewRatingAfterOrder() {

  const dispatch = useDispatch();

  const [reviewText, setReviewText] = useState("");
  const [reviewTextError, setReviewTextError] = useState("");

  const [isReviewRatingSubmitButtonLoading, setIsReviewRatingSubmitButtonLoading] = useState(false);

  const [medias, setMedias] = useState<ProductMediaModel[]>([]);

  const { productId } = useParams();
  const { orderId } = useParams();

  const productDetailsRed = useSelector((state: RootState) => {
    return state.resellingGetProductByIdResponse.data.data;
  });

  const productDetails = useMemo(() => {
    return productDetailsRed;
  }, [productDetailsRed]);

  useEffect(() => {
    dispatch(resellingGetProductByIdAction(productId));
  }, [dispatch, productId]);


  const [isShowFullHight, setIsShowFullHight] = useState(false);

  const handleSaveDocumentIds = (id: any, url: any) => {
    setIsShowFullHight(true);
    var model = new ProductMediaModel();
    model.id = id;
    model.url = url;
    setMedias(_ => [..._, model])
  }

  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

  const handleReviewText = (value: any) => {
    setReviewText(value)
    setReviewTextError("")
  }

  const handleReviewSubmit = () => {

    setIsReviewRatingSubmitButtonLoading(true)

    if (selectedRating) {
      var rModel = new ProductRatingCustomerRequest();
      rModel.value = selectedRating;
      rModel.productId = productId;

      submitProductRatingService(rModel)
        .then(() => {
          setIsReviewRatingSubmitButtonLoading(false)
        })
        .catch(() => {
          setIsReviewRatingSubmitButtonLoading(false)
        })
    }

    var model = new ProductReviewCustomerRequest();
    model.isParent = true;
    model.parentId = 0;
    model.orderId = orderId;
    model.productId = productId;
    model.reviewText = reviewText;
    model.mediaIds = medias?.map((_: any) => _.id)

    // return;
    addProductReviewService(model)
      .then(() => {
        setIsReviewRatingSubmitButtonLoading(false)

        setIsShowSnackBar(true);
        setSnackBarMessage("Review & Ratings Submitted Successfully");
        setSnackBarMessageType("success");
        setTimeout(() => {
          setIsShowSnackBar(false);
        }, 2000);
        setTimeout(() => {
          navigate(`/productDetails/${productId}`);
        }, 2000);
      })
      .catch((error: any) => {
        setIsReviewRatingSubmitButtonLoading(false);
        setReviewTextError(error.response.data.errors?.ReviewText?.[0])
      });
  };

  const navigate = useNavigate();

  const handleNavigateOrderDetailsPage = (item: any) => {
    navigate(`/myOrder/${item.id}`);
  };

  const [selectedRating, setSelectedRating] = useState(null);


  const handleRatingClick = (rating: any) => {
    setSelectedRating(rating);
  };

  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      const className = selectedRating && selectedRating >= i ? 'myBtn' : '';
      stars.push(
        <div
          key={i}
          className={`w-10 h-10 flex items-center justify-center cursor-pointer text-lg font-medium border-2 border-reselling rounded ${className}`}
          onClick={() => handleRatingClick(i)}
        >
          {i}
        </div>
      );
    }
    return stars;
  };

  const handleRemovePhoto = (item: any) => {
    setMedias(medias.filter(_ => _ !== item));
  }


  return (
    <div className="container mx-auto md:py-4 pb-20 md:pb-4 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28">
      {isShowSnackBar && (
        < AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      {/* these is back button  */}
      <div className="flex items-center cursor-pointer gap-x-2" onClick={handleNavigateOrderDetailsPage}>
        <KeyboardBackspaceOutlinedIcon />
        <h4 className="text">Back to order list</h4>
      </div>
      <h3 className="subTitle py-1 pl-8">Reviews & Ratings</h3>
      {/* these is image and rating div  */}
      <div className="flex flex-col max-w-xs mx-auto">
        {/* these is image and name section  */}
        <div className="flex w-full flex-col gap-[2px]">
          <p className="text-base 2xl:text-xl font-medium text-center">{productDetails?.name}</p>
          <div className="w-full lg:w-1/2 2xl:w-3/5 mx-auto">
            <img className="rounded object-cover" src={productDetails?.bannerPath} alt='' />
          </div>
        </div>
        {/* these is rating div  */}
        <div className="flex flex-col gap-2 pt-1">
          <p className="text-center">Rate your experience with our product</p>
          <div className="flex items-center gap-x-4">
            <div className="flex items-center gap-3">
              {renderStars()}
            </div>
            <p className="flex items-center gap-x-1"><img className="w-6 h-6 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Star_1_rzazpl.png" alt="" /> Stars</p>
          </div>
        </div>

      </div>
      {/* these is write a review div  */}
      <div className={`flex flex-col gap-2 md:px-12 py-4 md:py-2 ${isShowFullHight ? '2xl:px-0' : '2xl:px-32'}`}>
        <p className="text-lg font-medium">Write a review</p>
        {/* these is writing div  */}
        <div className="flex flex-col myBorder px-2 md:px-4 py-2">
          <div className={`flex flex-wrap gap-2 md:gap-4 ${isShowFullHight ? 'h-48' : 'h-12'} py-1`}>
            {medias && (
              medias.map((item: ProductMediaModel, index: any) => {
                return (
                  <div className="relative w-fit h-fit pr-1">
                    <IoMdClose onClick={() => handleRemovePhoto(item)} className="cursor-pointer text-reselling absolute top-0 right-0 2xl:text-2xl text-xl font-semibold" />
                    <img
                      className="w-20 h-20 object-contain rounded"
                      src={item.url}
                      alt=""
                    />
                  </div>
                )
              })
            )}
          </div>
          <div className="flex items-center justify-between">
            <AppInput
              className="text-lg"
              placeholder="Write your comment"
              onChange={handleReviewText}
              errorMessage={reviewTextError}
            />
            {/* these is icons div  */}
            <div className="flex items-center gap-x-4">
              {/* <MdOutlineAddPhotoAlternate className="cursor-pointer text-reselling text-xl font-semibold" /> */}
              {/* <BsEmojiSmile className="cursor-pointer text-reselling text-xl font-semibold" /> */}
              <div className="w-7 h-7 cursor-pointer">
                <AppFileInput onSave={handleSaveDocumentIds} />
                {/* <MdAddAPhoto className="text-reselling text-3xl font-semibold" /> */}
              </div>
              {/* <div className="w-7 h-7 cursor-pointer">
                <LuSend className=" text-reselling text-3xl font-semibold" />
              </div> */}
            </div>
          </div>
        </div>
        <div className="flex justify-end items-center pt-2">
          <AppButton
            isSubmitting={isReviewRatingSubmitButtonLoading}
            text="Submit"
            className="myBtn w-fit"
            onClick={handleReviewSubmit}
          />
        </div>
      </div>
    </div>
  );
}
