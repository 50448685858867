import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  getUserProfileAction,
  getResellerTeamAction,
} from "../../../store/actions/auth/user/userActions";
import { BiSearchAlt2 } from "react-icons/bi";
import { AppSearchBox } from "../../common/AppSearchBox";
import { PaginationPayloadModel } from "../../../models/common/paginationModels";
import { TeamMember } from "../../common/TeamMember";

export default function ResellingGetMyTeam() {
  const dispatch = useDispatch();

  const teamList = useSelector((state: RootState) => {
    return state.getResellerTeamResponse.data;
  });

  const userProfile = useSelector((state: RootState) => {
    return state.getUserProfileResponse.data;
  });

  useEffect(() => {
    var paginationModel = new PaginationPayloadModel();
    paginationModel.limit = 100;
    paginationModel.offset = 0;
    paginationModel.searchQuery = "";
    dispatch(getResellerTeamAction(paginationModel));
    dispatch(getUserProfileAction());
  }, [dispatch]);

  const handleSearchChange = (newValue: any) => {
    var paginationModel = new PaginationPayloadModel();
    paginationModel.limit = 100;
    paginationModel.offset = 0;
    paginationModel.searchQuery = newValue;
    dispatch(getResellerTeamAction(paginationModel));
  };

  return (
    <div className="container pb-8 pt-2 lg:mx-auto lg:pb-4 lg:pt-4 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28">
      <h1 className="title md:text-center">My team</h1>

      <div className="flex w-full flex-col-reverse justify-between gap-2 py-2 md:flex-row md:items-end">
        <div className="flex cursor-pointer items-center rounded border border-grey p-1 shadow-sm md:p-1.5 2xl:p-2">
          <BiSearchAlt2 className="cursor-pointer text-xl font-bold text-secondary md:text-2xl 2xl:text-3xl" />
          <AppSearchBox
            onChange={handleSearchChange}
            className="border-none px-2 text-sm md:w-[25dvw] md:text-base"
          />
        </div>

        <div className="">
          <h1 className="subTitle">Upline</h1>
          <div className="mt-1 flex w-full flex-col items-center gap-1 overflow-hidden rounded px-1 py-2 lg:w-80">
            {userProfile?.myResellingUpline ? (
              <TeamMember
                index={1111}
                name={userProfile?.myResellingUpline?.name}
                image={userProfile?.myResellingUpline?.profilePicUrl}
                roles={userProfile?.myResellingUpline?.userRoles}
                phoneNumber={userProfile?.myResellingUpline?.phoneNumber}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <h1 className="subTitle">Downlines ({teamList?.size})</h1>

      <div className="h-[55dvh] w-full overflow-y-auto lg:h-[47dvh]">
        <div className="flex grid-cols-1 flex-col content-center gap-2 md:grid-cols-4 lg:grid">
          {teamList?.items?.map((item: any, index: any) => {
            return (
              <TeamMember
                key={index}
                index={index}
                name={item?.name}
                image={item?.profilePicUrl}
                roles={item?.userRoles}
                phoneNumber={item?.phoneNumber}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
