import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _moment from "moment";
import { GetWalletCalculationsResponse } from "../../../../models/auth/user/UserPointModels";
import { RootState } from "../../../../store";
import { getWalletCalculationsAction } from "../../../../store/actions/auth/user/userActions";

export default function AdminGetWalletCalculations() {

  const dispatch = useDispatch();

  const wallet = useSelector((state: RootState) => {
    return state.getWalletCalculationsResponse.data as GetWalletCalculationsResponse;
  });

  useEffect(() => {
    dispatch(getWalletCalculationsAction());
  }, [dispatch]);

  
  const companyWallets = [
    {
      label: "Total Withdraws Company",
      income: (wallet?.totalWithdrawsCompany?.toFixed(2))
    },
    {
      label: "Total Pull Income Company",
      income: (wallet?.totalPullIncomeCompany?.toFixed(2))
    },
    {
      label: "Total Main Balance Company",
      income: (wallet?.totalMainBalanceCompany?.toFixed(2))
    },
    {
      label: "Total Affiliate Course Income Company",
      income: (wallet?.totalAffiliateCourseIncomeCompany?.toFixed(2))
    },
    {
      label: "Total Reselling Course Income Company",
      income: (wallet?.totalResellingCourseIncomeCompany?.toFixed(2))
    }
  ];

  const vendorWallets = [
    {
      label: "Total Recharge Vendor",
      income: (wallet?.totalRechargeVendor?.toFixed(2))
    },
    {
      label: "Total Withdraws Vendor",
      income: (wallet?.totalWithdrawsVendor?.toFixed(2))
    },
    {
      label: "Total Main Balance Vendor",
      income: (wallet?.totalMainBalanceVendor?.toFixed(2))
    },
    {
      label: "Total Advance Balance Vendor",
      income: (wallet?.totalAdvanceBalanceVendor?.toFixed(2))
    }
  ];

  const userWallets = [

    {
      label: "Total User Earning",
      income: (wallet?.totalUserEarning?.toFixed(2))
    },
    {
      label: "Total User Main Balance",
      income: (wallet?.totalUserMainBalance?.toFixed(2))
    },
    {
      label: "totalUserMainBalanceOver500",
      income: (wallet?.totalUserMainBalanceOver500?.toFixed(2))
    },
    {
      label: "Total User RechargeUser",
      income: (wallet?.totalUserRechargeUser?.toFixed(2))
    },
    {
      label: "Total User Reselling Back Amount",
      income: (wallet?.totalUserResellingBackAmount?.toFixed(2))
    },
    {
      label: "Total User Reselling Course Income",
      income: (wallet?.totalUserResellingCourseIncome?.toFixed(2))
    },
    {
      label: "Total User Reselling Income",
      income: (wallet?.totalUserResellingIncome?.toFixed(2))
    },
    {
      label: "Total User Reselling Moderator Income",
      income: (wallet?.totalUserResellingModeratorIncome?.toFixed(2))
    },
    {
      label: "Total User Reselling Refer Income",
      income: (wallet?.totalUserResellingReferIncome?.toFixed(2))
    },
    {
      label: "Total User Withdraws",
      income: (wallet?.totalUserWithdraws?.toFixed(2))
    },
    {
      label: "Back Amount User",
      income: (wallet?.backAmountUser?.toFixed(2))
    },
    {
      label: "Refer Income User",
      income: (wallet?.referIncomeUser?.toFixed(2))
    },
    {
      label: "Course Income User",
      income: (wallet?.courseIncomeUser?.toFixed(2))
    },
    {
      label: "Moderator Income User",
      income: (wallet?.moderatorIncomeUser?.toFixed(2))
    },
    {
      label: "Reselling Income User",
      income: (wallet?.resellingIncomeUser?.toFixed(2))
    }
  ];


  return (
    <div className="mx-auto max-w-6xl pb-2 lg:pb-12">
      <div className='flex justify-between items-center py-1'>
        Wallet Calculations
      </div>

      <div className="border-2 rounded 2xl:border-4 border-reselling p-2 lg:p-4 my-4 lg:my-5">
        <h2 className="text-center font-bold lg:text-xl">Company wallet</h2>
        <div className="grid grid-cols-2 items-center gap-4 pt-2 lg:grid-cols-4 lg:gap-4 lg:py-4">
          {companyWallets.map(singleIncome => (
            <div key={singleIncome?.label} className="h-16 lg:h-32 px-0.5 py-1 lg:px-2 lg:py-4 lg:flex flex-col items-center justify-center rounded space-y-1 lg:space-y-2 bg-gradient-to-r from-emerald-500 via-emerald-400 to-emerald-300 shadow-md">
              <p className="lg:font-medium text-center text-textBlack text-xs lg:text-lg">{singleIncome?.label}</p>
              <div className="flex items-center justify-center">
                {/* <img className="w-3 lg:w-5 h-3 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_5_emi1io.png" alt="" /> */}
                <h3 className="text-sm lg:text-xl font-semibold text-reselling">{singleIncome?.income}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="border-2 rounded 2xl:border-4 border-affiliate p-2 lg:p-4 my-4 lg:my-5">
        <h2 className=" text-center font-bold lg:text-xl">Vendor Wallets</h2>

        <div className="grid grid-cols-2 items-center gap-4 pt-2 lg:grid-cols-4 lg:gap-4 lg:py-4">
          {vendorWallets.map(singleIncome => (
            <div key={singleIncome?.label} className="h-16 lg:h-32 px-0.5 py-1 lg:px-2 lg:py-4 lg:flex flex-col items-center justify-center rounded space-y-1 lg:space-y-2.5 bg-white myShadow  border-b-2 lg:border-b-4 border-b-reselling">
              <p className="lg:font-medium text-center text-textBlack text-xs lg:text-lg">{singleIncome?.label}</p>
              <div className="flex items-center justify-center">
                {/* <img className="w-3 lg:w-5 h-3 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka-affiliate.png" alt="" /> */}
                <h3 className="text-sm lg:text-xl font-semibold text-affiliate">{singleIncome?.income}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="border-2 rounded 2xl:border-4 border-affiliate p-2 lg:p-4 my-4 lg:my-5">
        <h2 className=" text-center font-bold lg:text-xl">User Wallets</h2>

        <div className="grid grid-cols-2 items-center gap-4 pt-2 lg:grid-cols-4 lg:gap-4 lg:py-4">
          {userWallets.map(singleIncome => (
            <div key={singleIncome?.label} className="h-16 lg:h-32 px-0.5 py-1 lg:px-2 lg:py-4 lg:flex flex-col items-center justify-center rounded space-y-1 lg:space-y-2.5 bg-white myShadow  border-b-2 lg:border-b-4 border-b-affiliate">
              <p className="lg:font-medium text-center text-textBlack text-xs lg:text-lg">{singleIncome?.label}</p>
              <div className="flex items-center justify-center">
                {/* <img className="w-3 lg:w-5 h-3 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka-affiliate.png" alt="" /> */}
                <h3 className="text-sm lg:text-xl font-semibold text-affiliate">{singleIncome?.income}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>

    </div>
  );
}
