import { AlertColor } from "@mui/material/Alert";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { updateUserService } from "../../../../httpService/user/userServices";
import {
  UpdateUserRequest,
  UserType,
} from "../../../../models/auth/user/UserModels";
import { RootState } from "../../../../store";
import { getUseRoleListAction } from "../../../../store/actions/auth/address/userRoleActions";
import { getUserAction } from "../../../../store/actions/auth/user/userActions";
import { AppButton } from "../../../common/AppButton";
import { AppInput } from "../../../common/AppInput";
import { AppSelect } from "../../../common/AppSelect";
import { AppTextArea } from "../../../common/AppTextArea";

export default function AdminUpdateUser() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const userRoleList = useSelector((state: RootState) => {
    return state.userRoleListResponse.userRole;
  });

  useEffect(() => {
    dispatch(getUseRoleListAction());
  }, []);

  const user = useSelector((state: RootState) => {
    return state.getUserResponse.data;
  });

  useEffect(() => {
    dispatch(getUserAction(id));
  }, []);

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setEmail(user.email);
      setPhoneNumber(user.phoneNumber);
      setGender(user.gender);
      if (user.userType == 'Regular') {
        setUserType(UserType.Regular);
      } else {
        setUserType(UserType.Affiliate);
      }

      setUserAddress(user.userAddress);
      setRoleId(user.userRole);
    }
  }, [user]);

  const [firstName, setFirstName] = useState("");
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState("");

  const [lastName, setLastName] = useState("");
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState("");

  const [email, setEmail] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState("");

  const [gender, setGender] = useState(0);
  const [genderErrorMessage, setGenderErrorMessage] = useState("");

  const [roleId, setRoleId] = useState(0);
  const [roleIdErrorMessage, setRoleIdErrorMessage] = useState("");

  const [userType, setUserType] = useState(0);

  const [userAddress, setUserAddress] = useState("");
  const [userAddressError, setUserAddressError] = useState("");

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    setSnackBarOn(false);

    var model = new UpdateUserRequest();
    model.userId = id;
    model.firstName = firstName;
    model.lastName = lastName;
    model.email = email;
    model.phoneNumber = phoneNumber;
    model.gender = gender;
    model.roleId = roleId;
    model.userType = userType;

    updateUserService(model)
      .then(() => {
        setSnackBarOn(true);
        setsnackBarMessage("User Added Successfully.");
        setSnackBarMessageType("success");

        setTimeout(() => {
          navigate("/admin/auth/users");
        }, 300);
      })
      .catch(() => { });
  };

  return (
    <div>
      <div className="pl-4 flex items-center justify-between w-3/6 pb-6">
        <AppButton
          onClick={() => navigate("/admin/auth/users")}
          text="Back"
        />
        <h2 className="text-center title">Update User</h2>
      </div>

      <form className="max-w-3xl flex flex-col gap-2 mx-auto myBorder py-4 px-5">
        <div className="flex justify-between items-center gap-8">
          <AppInput
            type="text"
            label="First Name:"
            className="myInput"
            value={firstName}
            placeholder="Enter Your First Name"
            onChange={(e: any) => setFirstName(e)}
            errorMessage={firstNameErrorMessage}
          />
          <AppInput
            type="text"
            label="Last Name:"
            className="myInput"
            value={lastName}
            placeholder="Enter Your Last Name"
            onChange={(e: any) => setLastName(e)}
            errorMessage={lastNameErrorMessage}
          />
        </div>

        <div className="flex justify-between items-center gap-8">
          <AppInput
            type="email"
            label="Email:"
            className="myInput"
            value={email}
            placeholder="Enter Your Email"
            onChange={(e: any) => setEmail(e)}
            errorMessage={emailErrorMessage}
          />
          <AppInput
            type="number"
            label="Phone Number:"
            className="myInput"
            value={phoneNumber}
            placeholder="Enter Phone Number"
            onChange={(e: any) => setPhoneNumber(e)}
            errorMessage={phoneNumberErrorMessage}
          />
        </div>

        <AppTextArea
          label="Address:"
          value={userAddress}
          placeholder="Your Address"
          onChange={(e: any) => setUserAddress(e)}
          errorMessage={userAddressError}
        />

        <div className="flex justify-between items-center py-2 gap-8">
          <div className="flex items-center gap-1">
            <label className="2xl:text-xl md:text-lg text-base">Gender: </label>
            <AppSelect
              data={[
                { id: "1", name: "MALE" },
                { id: "2", name: "FEMALE" },
              ]}
              defaultValue="Select Gender"
              value={gender}
              onChange={(e: any) => setGender(e)}
              errorMessage={genderErrorMessage}
            />
          </div>

          <div className="flex items-center gap-1">
            <label className="2xl:text-xl md:text-lg text-base">Role: </label>
            <AppSelect
              data={userRoleList}
              defaultValue="Select User Role"
              value={roleId}
              onChange={(e: any) => setRoleId(e)}
              errorMessage={roleIdErrorMessage}
            />
          </div>
          <div className="flex items-center gap-1">
            <label className="2xl:text-xl md:text-lg text-base">User Type: </label>
            <AppSelect
              data={[
                { id: "1", name: "Regular" },
                { id: "2", name: "Affiliate" },
              ]}
              defaultValue="Select User Type"
              value={userType}
              onChange={(e: any) => setUserType(e)}
              errorMessage={roleIdErrorMessage}
            />
          </div>
        </div>

        <div className="flex justify-end">
          <AppButton onClick={handleSubmit} variant="contained" text="Submit" />
        </div>
      </form>

      {/* <div className="form-input">
          <label className="label">Password: </label>
          <AppInput
            type="text"
            placeholder="Enter Your Password"
            onChange={(e: any) => setPassword(e)}
            errorMessage={passwordErrorMessage}
          />
        </div>
        <div className="form-input">
          <label className="label">Confirm Password: </label>
          <AppInput
            type="text"
            placeholder="Confirm Your Password"
            onChange={(e: any) => setConfirmPassword(e)}
            errorMessage={confirmPasswordErrorMessage}
          />
        </div> */}
    </div>
  );
}
