import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { useEffect, useMemo, useState } from "react";
import { resellingGetOrderDetailsAction } from "../../../store/actions/sales/resellingOrderActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  CancellOrderRequest,
  OrderProductCustomer,
  OrderProductCustomerResponse,
  ReturnOrderRequest,
} from "../../../models/order/resellingOrdersModels";
import { TbCurrencyTaka } from "react-icons/tb";
import { AppButton } from "../../common/AppButton";
import _moment from "moment";
import { AppModal } from "../../common/AppModal";
import {
  adminResellingCancelOrderService,
  adminResellingReturnOrderService,
} from "../../../httpService/sales/resellingOrderServices";

export default function ResellingGetOrderDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const orderDetails = useSelector((state: RootState) => {
    return state.resellingGetOrderDetailsResponse
      .data as OrderProductCustomerResponse;
  });

  const orderProducts = useMemo(() => {
    return orderDetails.orderProductList as OrderProductCustomer[];
  }, [orderDetails]);

  const { id } = useParams();

  useEffect(() => {
    dispatch(resellingGetOrderDetailsAction(id as any));
  }, [id]);

  const handleBackToOrderList = () => {
    navigate("/reselling-platform/myOrder");
  };

  const navigateToReviewRating = (item: any) => {
    navigate(`/productReview/${item?.productId}/${id}`);
  };

  const groupedProducts = orderProducts?.reduce(
    (result: any, product: OrderProductCustomer) => {
      const index = result.findIndex(
        (vendorProducts: any) => vendorProducts[0].vendorId === product.vendorId
      );
      if (index >= 0) {
        result[index].push(product);
      } else {
        result.push([product]);
      }
      return result;
    },
    []
  );

  const navigateToProductDetails = (id?: any) => {
    navigate(`/reselling-platform/productDetails/${id}`);
  };

  const orderTimeFormatter = (createdDateTime: any) => {
    const createdTime = _moment(createdDateTime, "YYYY-MM-DD HH:mm:ss.SSSSSSS");
    return createdTime.format("MMM D, YY [at] h:mm A");
  };

  const [isShippingDetailsOpen, setIsShippingDetailsOpen] = useState(false);

  const handleShippingDetails = () => {
    setIsShippingDetailsOpen(!isShippingDetailsOpen);
  };

  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);

  const [isOpenReturnModal, setIsOpenReturnModal] = useState(false);

  const [productId, setProductId] = useState(0);
  const [vendorId, setVendorId] = useState(0);

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleCancelOne = (item: any) => {
    setIsOpenCancelModal(true);
    setProductId(item.productId);
    setVendorId(item.vendorId);
  };

  const handleCancelOneOrder = () => {
    var model = new CancellOrderRequest();
    model.orderId = id;
    model.productId = productId;
    model.isAllCancell = false;
    model.isAllCancellOfVendor = false;
    model.vendorId = vendorId;
    setIsButtonLoading(true);
    adminResellingCancelOrderService(model)
      .then(() => {
        setIsButtonLoading(false);
        setIsOpenCancelModal(false);
        dispatch(resellingGetOrderDetailsAction(id as any));
      })
      .catch(() => {
        setIsButtonLoading(false);
      });
  };

  const handleCloseCancelOneModal = () => {
    setIsOpenCancelModal(false);
  };

  const handleReturnOne = (item: any) => {
    setIsOpenReturnModal(true);
    setProductId(item.productId);
    setVendorId(item.vendorId);
  };

  const handleReturnOneOrder = () => {
    var model = new ReturnOrderRequest();
    model.orderId = id;
    model.productId = productId;
    model.isAllReturn = false;
    model.isAllReturnOfVendor = false;
    model.vendorId = vendorId;
    setIsButtonLoading(true);
    adminResellingReturnOrderService(model)
      .then(() => {
        setIsButtonLoading(false);
        setIsOpenReturnModal(false);
        dispatch(resellingGetOrderDetailsAction(id as any));
      })
      .catch(() => {
        setIsButtonLoading(false);
      });
  };

  const handleCloseReturnOneModal = () => {
    setIsOpenReturnModal(false);
  };

  return (
    <div className="container mx-auto pb-8 pt-2 md:py-4 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28">
      {isOpenCancelModal && (
        <AppModal>
          <div className="flex max-w-md flex-col gap-4 p-6 text-center md:text-2xl">
            Are you sure you want to cancel this product?
            <div className="flex items-center justify-center gap-4">
              <AppButton text="Yes" onClick={handleCancelOneOrder} />
              <AppButton text="No" onClick={handleCloseCancelOneModal} />
            </div>
          </div>
        </AppModal>
      )}

      {isOpenReturnModal && (
        <AppModal>
          <div className="flex max-w-md flex-col gap-4 p-6 text-center md:text-2xl">
            Are you sure you want to return this product?
            <div className="flex items-center justify-center gap-4">
              <AppButton text="Yes" onClick={handleReturnOneOrder} />
              <AppButton text="No" onClick={handleCloseReturnOneModal} />
            </div>
          </div>
        </AppModal>
      )}

      {/* these is back button  */}
      <div
        className="flex cursor-pointer items-center lg:gap-x-2 lg:px-4"
        onClick={handleBackToOrderList}
      >
        <KeyboardBackspaceOutlinedIcon />
        <h4 className="subTitle">Back to order list</h4>
      </div>

      <div className="md:px-16">
        <h3 className="title pb-2 lg:pb-0 lg:text-center">Order details</h3>
        {/* these is buttons and text  */}
        <div className="flex justify-between lg:items-center">
          <div className="flex flex-col justify-between gap-1 lg:flex-row lg:gap-2">
            {isShippingDetailsOpen ? (
              <AppButton
                text="Close Shipping details"
                className="resellingBtn w-fit px-1 py-0.5 text-xs lg:px-4 lg:py-1 lg:text-sm"
                onClick={handleShippingDetails}
              />
            ) : (
              <AppButton
                className="resellingBtn px-1 py-0.5 text-xs lg:px-4 lg:py-1 lg:text-sm"
                text="See Shipping Details"
                onClick={handleShippingDetails}
              />
            )}


          </div>
          <h3 className="subTitle flex items-center gap-1">
            Payment Status:{" "}
            <span
              className={`${orderDetails?.isOrderTotalPaid ? "paided" : "unpaid"
                }`}
            >
              {orderDetails?.isOrderTotalPaid ? `Paid` : `UnPaid`}
            </span>
          </h3>
        </div>

        {isShippingDetailsOpen && (
          <div className="flex flex-col items-center gap-2 pt-2 md:flex-row md:gap-8">
            {/* these is shipping details  */}
            <div className="myBorder grid w-full grid-cols-2 space-y-0.5 px-2 py-1 text-xs md:w-3/5 lg:px-4 lg:py-1.5 lg:text-base lg:font-medium">
              <p>
                Address Line 1:
                <span> {orderDetails?.shippingAddress?.addressLine1}</span>{" "}
              </p>
              <p>
                Address Line 2:
                <span> {orderDetails?.shippingAddress?.addressLine2} </span>
              </p>
              <p>
                Division:
                <span> {orderDetails?.shippingAddress?.divisionName} </span>
              </p>
              <p>
                District:{" "}
                <span>{orderDetails?.shippingAddress?.districtName}</span>
              </p>
              <p>
                Area: <span>{orderDetails?.shippingAddress?.areaName}</span>
              </p>
              <p>
                Phone: <span>{orderDetails?.shippingAddress?.phoneNo1}</span>
              </p>
            </div>
            {/* these is delivery div  */}
            <div className="myBorder w-full px-2 py-1 md:w-1/4 lg:space-y-2 lg:px-4 lg:py-2">
              <div className="flex items-center justify-between pb-2 text-sm lg:pb-4 lg:text-base">
                <p>Delivery Charge</p>
                <p className="flex items-center text-sm lg:text-base">
                  <TbCurrencyTaka /> {orderDetails?.deliveryCharge}
                </p>
              </div>
              <div className="flex items-center justify-between border-t-2 border-gray-600 pt-1 text-sm lg:text-base">
                <p>Total cost</p>
                <p className="flex items-center text-sm lg:text-base">
                  {" "}
                  <TbCurrencyTaka />{" "}
                  {(orderDetails?.totalCost as number) +
                    (orderDetails?.deliveryCharge as number)}
                </p>
              </div>
            </div>
          </div>
        )}

        {groupedProducts?.map((vendorProducts: any, index: any) => (
          <section key={index} className="myBorder my-2 lg:my-4">
            {/* these is shop info  */}
            <div className="border-b border-grey px-2 py-1 lg:px-4">
              <div className="flex items-center justify-between">
                <h3 className="text-xs lg:text-base">
                  Sold by:{" "}
                  <span className="font-medium">
                    {vendorProducts[0].vendorName}
                  </span>{" "}
                </h3>
                <h3 className="text-xs lg:text-base">
                  Order id: #
                  <span className="font-medium">{orderDetails.orderId}</span>{" "}
                </h3>
              </div>

              <div className="flex items-center justify-between">
                <h3 className="text-xs lg:text-base">
                  <span className="font-medium text-royalBlue">
                    {vendorProducts[0].vendorPhone}
                  </span>{" "}
                </h3>
                {vendorProducts[0]?.trackUrl?.length > 0 && (
                  <h3 className="text-xs lg:text-base">
                    <a
                      target="_blank"
                      href={vendorProducts[0].trackUrl}
                      rel="noopener noreferrer"
                      className="text-decoration-underline text-blue-600"
                    >
                      <b><u>Track Shipment</u></b>
                    </a>
                  </h3>
                )}
                <h3 className="text-xs lg:text-base">
                  <span className="font-medium text-gray-600">
                    {orderTimeFormatter(orderDetails?.orderDateTime)}
                  </span>{" "}
                </h3>
              </div>

              {/* <AppButton className="resellingBtn text-sm 2xl:text-xl" text="Cancel" onClick={() => { handleOpenCalcellAllVendorModal(vendorProducts[0].vendorId) }} />
            <AppButton className="resellingBtn text-sm 2xl:text-xl" text="Return" onClick={() => { handleOpenReturnAllVendorModal(vendorProducts[0].vendorId) }} /> */}
            </div>
            {/* these is order date and cancel date  */}
            {/* <div className="myBorder flex justify-between items-center 2xl:p-3 px-4 py-2 shadow hover:shadow-md">
            <h3 className="2xl:text-xl text-lg">Canceled date: <span className="font-medium text-gray-600 text-sm">{orderTimeFormatter(orderDetails?.orderDateTime)}</span> </h3>
          </div> */}

            {/* these is delivery report section button  */}
            <div className="mx-auto flex max-w-2xl items-center justify-center gap-1 py-1 md:py-2 lg:gap-6">
              {vendorProducts?.filter((_: any) => _.orderStatus === "Cancelled")
                ?.length === vendorProducts?.length ? (
                <p className="Cancelled">Cancelled</p>
              ) : vendorProducts?.filter(
                (_: any) => _.orderStatus === "Returned"
              )?.length === vendorProducts?.length ? (
                <p className="Returned">Returned</p>
              ) : (
                <>
                  {vendorProducts.filter(
                    (_: any) =>
                      _.orderStatus === "InProgress" ||
                      _.orderStatus === "Complete" ||
                      _.orderStatus === "Confirm"
                  )?.length ? (
                    <p className="InProgress">Pending</p>
                  ) : (
                    <p className="disableBtn">Pending</p>
                  )}

                  {vendorProducts.filter(
                    (_: any) =>
                      _.orderStatus === "Confirm" ||
                      _.orderStatus === "Complete"
                  )?.length ? (
                    <p className="Shipped">Confirmed</p>
                  ) : (
                    <p className="disableBtn">Confirmed</p>
                  )}

                  {vendorProducts.filter(
                    (_: any) => _.shippingStatus === "Shipped"
                  )?.length ? (
                    <p className="Shipped">Delivered</p>
                  ) : (
                    <p className="disableBtn">Delivered</p>
                  )}

                  {vendorProducts.filter(
                    (_: any) => _.shippingStatus === "Shipped"
                  )?.length ? (
                    <p className="paid">On The Way</p>
                  ) : (
                    <p className="disableBtn">On The Way</p>
                  )}

                  {vendorProducts.filter(
                    (_: any) => _.orderStatus === "Completed"
                  )?.length ? (
                    <p className="completed">Completed</p>
                  ) : (
                    <p className="disableBtn">Completed</p>
                  )}
                </>
              )}
            </div>

            <div className="w-full px-2 lg:px-4">
              <div className="flex items-center justify-between text-xs font-medium lg:text-base">
                <div className="w-[40%] lg:w-[32%]">
                  <span>Product</span>
                </div>
                <div className="w-[15%] text-center lg:w-[17%]">
                  <span>Price</span>
                </div>
                <div className="w-[15%] text-center lg:w-[17%]">
                  <span>Qty</span>
                </div>
                <div className="w-[15%] text-center lg:w-[17%]">
                  <span>Total</span>
                </div>
                <div className="w-[15%] text-center lg:w-[17%]">
                  <span></span>
                </div>
              </div>
              {vendorProducts?.map((item: any, indx: any) => (
                <div
                  key={indx}
                  className="flex items-center justify-between py-1.5"
                >
                  {/* product details section here  */}
                  <div className="flex w-[40%] items-center gap-x-1 md:gap-x-4 lg:w-[32%] lg:justify-start">
                    {/* these is product details image section  */}
                    <img
                      onClick={() => navigateToProductDetails(item.productId)}
                      className="h-10 w-10 rounded object-cover md:h-14 md:w-14"
                      src={item?.bannerPath}
                      alt="These is product pic"
                    />
                    {/* this is product details name section  */}
                    <div className="text-xs md:text-base">
                      <h3
                        className="cursor-pointer "
                        onClick={() => navigateToProductDetails(item.productId)}
                      >
                        {item?.productName}
                      </h3>
                      {item?.attributes && (
                        <div className="">
                          {item?.attributes?.map((attribute: any, x: any) =>
                            attribute?.name === "Color" ? (
                              <div key={x}>
                                <p>{attribute?.name}:</p>
                                <div
                                  style={{ background: attribute?.value }}
                                ></div>
                              </div>
                            ) : (
                              <div className="flex items-center justify-between">
                                <div
                                  key={x}
                                  className="flex items-center gap-x-[2px] md:gap-x-1"
                                >
                                  <p>{attribute?.name}:</p>
                                  <div>{attribute?.value}</div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* this is price section  */}
                  <div className="flex w-[15%] items-center justify-center text-xs lg:w-[17%] lg:text-base">
                    <span className="flex items-center font-normal md:font-medium">
                      {" "}
                      <TbCurrencyTaka /> {item?.unitPrice}
                    </span>
                  </div>
                  {/* this is quantity section  */}
                  <div className="flex w-[15%] items-center justify-center text-xs lg:w-[17%] lg:text-base">
                    <span className=" font-normal md:font-medium">
                      {item?.quantity}
                    </span>
                  </div>
                  {/* this is total price  */}
                  <div className="flex w-[15%] items-center justify-center text-xs lg:w-[17%] lg:text-base">
                    <span className="flex items-center font-normal md:font-medium">
                      {" "}
                      <TbCurrencyTaka /> {item?.totalPrice}
                    </span>
                  </div>
                  {/* these is buttons  */}
                  <div className="flex w-[15%] justify-end lg:w-[17%]">
                    {item?.shippingStatus === "InProgress" &&
                      item?.orderStatus !== "Cancelled" && (
                        <AppButton
                          isSubmitting={isButtonLoading}
                          className="resellingOutlineBtn border-red px-2 text-red hover:bg-red lg:px-4"
                          text="Cancel"
                          onClick={() => {
                            handleCancelOne(item);
                          }}
                        />
                      )}
                    {item?.orderStatus === "Cancelled" && (
                      <div className="Cancelled">Cancelled</div>
                    )}
                    {item?.orderStatus === "Returned" && (
                      <div className="Returned">Returned</div>
                    )}
                    {item?.orderStatus === "Complete" && (
                      <AppButton
                        className="resellingBtn px-1 py-0.5 text-xsm lg:px-4 lg:py-1 lg:text-sm"
                        text="Review"
                        onClick={() => navigateToReviewRating(item)}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </section>
        ))}
      </div>
    </div>
  );
}

// OrderStatus --> Shipping Status --> Shipping Status --> OrderStatus
