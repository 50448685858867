import React from "react";
import { AppDefaultProfilePicture } from "./AppDefaultProfilePicture";

interface ITeamMemberProps {
  index?: any;
  name?: string;
  image?: string;
  roles?: any;
  phoneNumber?: string;
  onChange?: any;
}

export const TeamMember: React.FC<ITeamMemberProps> = ({
  name,
  image,
  roles,
  phoneNumber,
  onChange,
}) => {
  return (
    <div
      className="flex h-fit w-full items-center gap-2 overflow-hidden rounded-md px-2 py-2 bg-slate-50 shadow-md hover:shadow-lg hover:cursor-pointer border-l-4 border-primary"
    >
      <AppDefaultProfilePicture
        className="customer-review-image"
        name={name}
        profilePicture={image}
      />
      <div className='w-full space-y-0.5'>
        <p className="subTitle">{name}</p>
        <p className="text-sm flex">{roles?.map((role: any, idx: any) => {
          return (
            <>
              <span key={idx}> {role.name}</span>
              {roles?.length - 1 !== idx ? <>,</> : <></>}
              {idx % 2 !== 0 ? <br /> : <></>}
            </>
          );
        })}</p>
        <p className="">{phoneNumber}</p>
      </div>
    </div>
  );
};
