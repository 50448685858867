import { SSAddToCartRequest, SSCartItemSelection, SSGetCartItemsRequest, SSIncreseOrDecreseProductQuantityRequest, SSRemoveItemSingleOrListItemRequest } from "../../models/superShopp/cart/sSCartModel";
import { DELETE, GET, POST, PUT } from "../service";

export const sSAddToCartService = (model: SSAddToCartRequest) => {
    return POST(`/api/SSCart/SSAddToCart`, model);
};

export const sSGetCartItemsService = (model?: SSGetCartItemsRequest) => {
    return GET(`/api/SSCart/SSGetCartItems?userId=${model?.userId ? model.userId : 0}&bazarId=${model?.bazarId}`);
};

export const sSGetTotalCartItemService = () => {
    return GET(`/api/SSCart/SSGetTotalCartItem`);
};

export const sSRemoveCartItemsService = (data: SSRemoveItemSingleOrListItemRequest) => {
    return DELETE(`/api/SSCart/SSRemoveItemSingleOrListItem`, { data });
};

export const sSIncreseDecreseCartItemService = (data: SSIncreseOrDecreseProductQuantityRequest) => {
    return PUT(`/api/SSCart/SSIncreseOrDecreseProductQuantity`, data);
};

export const sSCartItemSelectionService = (data: SSCartItemSelection[]): Promise<{ data: string }> => {
    return PUT(`/api/SSCart/SSCartItemSelection`, {
        cartItemSelectionList: data
    });
};

