import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { RootState } from "../../../store";
import { getDataOfferAction } from "../../../store/actions/dataOffer/dataOfferAction";
import { Link } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";

export default function AdminGetDataOfferById() {

  const dispatch = useDispatch();

  const { id } = useParams();

  const dataOffer = useSelector((state: RootState) => {
    return state.getDataOfferResponse.data;
  });

  useEffect(() => {
    dispatch(getDataOfferAction(id));
  }, []);

  return (
    <div className="container mx-auto lg:py-4">
      <Link to="/admin/data-offer-vendor/data-offer">
        <h3 className="flex items-center gap-x-1 text-lg font-semibold text-royalBlue">
          <BiArrowBack /> Back to Data Offer Lists
        </h3>{" "}
      </Link>
      <h2 className="title text-center">Data Offer details Information</h2>

      <div className="px-2 py-2 lg:px-20 lg:py-4">
        <div className="myBorder mt-2 grid grid-cols-1 gap-1 p-2 font-medium lg:mt-4 lg:grid-cols-2 lg:gap-2 lg:gap-x-8 lg:gap-y-2 lg:p-4">
          <div className="myBorder w-full px-2 py-1 lg:px-4">
            <p>
              Title: <span className="font-medium"> {dataOffer?.title}</span>
            </p>
          </div>
          <div className="myBorder w-full px-2 py-1 lg:px-4">
            <p>
              Description:{" "}
              <span className="font-medium"> {dataOffer?.description}</span>
            </p>
          </div>
          <div className="myBorder w-full px-2 py-1 lg:px-4">
            <p>
              Price: <span className="font-medium"> {dataOffer?.price}</span>
            </p>
          </div>
          <div className="myBorder w-full px-2 py-1 lg:px-4">
            <p>
              Duration:{" "}
              <span className="font-medium">
                {" "}
                {dataOffer?.validityDuration}
              </span>
            </p>
          </div>
          <div className="myBorder w-full px-2 py-1 lg:px-4">
            <p>
              Offer Type:{" "}
              <span className="font-medium"> {dataOffer?.dataOfferType}</span>
            </p>
          </div>
          <div className="myBorder w-full px-2 py-1 lg:px-4">
            <p>
              Mobile Operator:{" "}
              <span className="font-medium"> {dataOffer?.mobileOperator}</span>
            </p>
          </div>
          <div className="myBorder w-full px-2 py-1 lg:px-4">
            <p>
              Packge Type:{" "}
              <span className="font-medium"> {dataOffer?.dataPackageType}</span>
            </p>
          </div>
          <div className="myBorder w-full px-2 py-1 lg:px-4">
            <p>
              Is Active:{" "}
              <span className="font-medium">
                {" "}
                {dataOffer?.isActive == true ? "Active" : "Deactive"}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
