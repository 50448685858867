import { Route, Routes } from "react-router-dom";
import BechteChaiGetProducts from "../../components/admin/bechteChai/products/BechteChaiGetProducts";
import BechteChaiHomeComponent from "../../components/public/bechteChai/BechteChaiHomeComponent";
import BechteChaiPostAd from "../../components/public/bechteChai/BechteChaiPostAd";
import BechteChaiGetProductById from "../../components/public/bechteChai/BechteChaiGetProductById";
import BechteChaiGetFavouriteItems from "../../components/public/bechteChai/BechteChaiGetFavouriteItems";
import BechteChaiGetPostedProducts from "../../components/public/bechteChai/BechteChaiGetPostedProducts";
import BechteChaiGetFilterProducts from "../../components/public/bechteChai/BechteChaiGetFilterProducts";


export default function BechteChaiPublicRoute() {
    return (
        <>
            <Routes>
                <Route index element={<BechteChaiHomeComponent />} />
                <Route path="/posted-ads" element={<BechteChaiGetPostedProducts />} />
                <Route path="/product/add" element={<BechteChaiPostAd />} />
                <Route path="/filterProductList/:mainCatId/:subCatId" element={<BechteChaiGetFilterProducts />} />
                <Route path="/ad/:id" element={<BechteChaiGetProductById />} />
                <Route path="/favourites" element={<BechteChaiGetFavouriteItems />} />
            </Routes>
        </>
    );
}