import { AlertColor } from "@mui/material/Alert/Alert";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../store";
import { getUseRoleListAction } from "../../../../store/actions/auth/address/userRoleActions";
import { debounce } from "lodash";
import { POST } from "../../../../httpService/service";
import _moment from 'moment';
import { getVendorRequestListAction } from "../../../../store/actions/auth/user/userActions";
import { AppButton } from "../../../common/AppButton";
import { AppModal } from "../../../common/AppModal";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { PaginationPayloadModel } from "../../../../models/common/paginationModels";
import { AppPagination } from "../../../common/AppPagination";
import { AppLoader } from "../../../common/AppLoader";

export default function VendorRequest() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  var paginationModel = new PaginationPayloadModel();

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);
  const [roleId, setRoleId] = useState(0);
  const [searchQueryValue, setSearchQueryValue] = useState("");

  const userList = useSelector((state: RootState) => {
    return state.getVendorRequestResponse.userList;
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        paginationModel.limit = 10;
        paginationModel.offset = 0;
        await dispatch(getVendorRequestListAction(paginationModel));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  const handleOnRowChange = (payload: PaginationPayloadModel) => {
    const fetchData = async () => {
      try {
        paginationModel.limit = payload.limit;
        paginationModel.offset = 0;
        await dispatch(getVendorRequestListAction(paginationModel));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }

  const handleOnPageChange = (payload: PaginationPayloadModel) => {
    const fetchData = async () => {
      try {
        paginationModel.limit = payload.limit;
        paginationModel.offset = payload.offset;
        await dispatch(getVendorRequestListAction(paginationModel));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }

  useEffect(() => {
    dispatch(getUseRoleListAction());
  }, []);

  const onSearch = debounce((searchQuery: string) => {
    setSearchQueryValue(searchQuery);
    paginationModel.searchQuery = searchQuery;
    paginationModel.userRole = roleId;
    dispatch(getVendorRequestListAction(paginationModel));
  }, 500);

  const onFilter = (e: any) => {
    setRoleId(e);
    paginationModel.searchQuery = searchQueryValue;
    paginationModel.userRole = e;
    dispatch(getVendorRequestListAction(paginationModel));
  };

  const handleUpdate = (id: any) => {
    navigate(`/admin/auth/user/update/${id}`);
  };

  const handleUserDetails = (id: any) => {
    navigate(`/admin/auth/user/details/${id}`);
  };

  const [isSignInButtonLoading, setSignInButtonLoading] = useState(false);

  const handleApprove = (id: any) => {
    setSignInButtonLoading(true);
    POST(`/api/Users/AcceptVendorRequest?id=${id}`, {}).then(() => {
      setApprovePopUp(false);
      setDeclinePopUp(false);
      dispatch(getVendorRequestListAction(paginationModel));
      setSnackBarOn(true);
      setsnackBarMessage("Accept vendor successfully.");
      setSnackBarMessageType("success");
      dispatch(getVendorRequestListAction(paginationModel));
      setSignInButtonLoading(false)

      setTimeout(() => {
        navigate("/admin/auth/VendorRequest");
      }, 300);
    })
      .catch(() => {
        setSignInButtonLoading(false)
      });
  };

  const handleDecline = (id: any) => {
    setSignInButtonLoading(true);
    POST(`/api/Users/DeclineVendorRequest?id=${id}`, {})
      .then(() => {
        setApprovePopUp(false);
        setDeclinePopUp(false);
        setSnackBarOn(true);
        setsnackBarMessage("Decline vendor successfully.");
        setSnackBarMessageType("success");
        dispatch(getVendorRequestListAction(paginationModel));
        setSignInButtonLoading(false)

        setTimeout(() => {
          navigate("/admin/auth/VendorRequest");
        }, 300);

      })
      .catch(() => {
        setSignInButtonLoading(false)
      });
  };

  const [bulkIds, setBulkIds] = useState([]);

  const handelBulkSelection = (event: any) => {
    setBulkIds(event);
  };

  const orderTimeFormatter = (createdDateTime: any) => {
    const createdTime = _moment(createdDateTime, 'YYYY-MM-DD HH:mm:ss.SSSSSSS');
    return createdTime.format('MMM D, YY [at] h:mm A');
  }

  const [requestUserId, setRequestUserId] = useState(0);

  const [approvePopUp, setApprovePopUp] = useState(false);
  const [declinePopUp, setDeclinePopUp] = useState(false);

  const openApproveModal = (id: number) => {
    setApprovePopUp(true);
    setRequestUserId(id);
  }

  const clcikApprove = () => {
    handleApprove(requestUserId);
  }

  const closeApprove = () => {
    setApprovePopUp(false);
  }

  const openDeclineModal = (id: number) => {
    setDeclinePopUp(true);
    setRequestUserId(id);
  }

  const clickDecline = () => {
    handleDecline(requestUserId);
  }

  const closeDecline = () => {
    setDeclinePopUp(false);
  }

  return (
    <div>
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}


      {approvePopUp && (
        <AppModal>
          <div className='flex flex-col gap-4 text-2xl max-w-md text-center p-4'>
            Are you sure you want to Approve?
            <div className='flex items-center justify-center gap-4'>
              <AppButton isSubmitting={isSignInButtonLoading} text="Yes" onClick={clcikApprove} />
              <AppButton isSubmitting={isSignInButtonLoading} text="No" onClick={closeApprove} />
            </div>
          </div>
        </AppModal>
      )}

      {declinePopUp && (
        <AppModal>
          <div className='flex flex-col gap-4 text-2xl max-w-md text-center p-4'>
            Are you sure you want to Decline?
            <div className='flex items-center justify-center gap-4'>
              <AppButton isSubmitting={isSignInButtonLoading} text="Yes" onClick={clickDecline} />
              <AppButton isSubmitting={isSignInButtonLoading} text="No" onClick={closeDecline} />
            </div>
          </div>
        </AppModal>
      )}

      <h2 className="title text-center">Vendor Request List</h2>

      <AppButton onClick={() => navigate("/admin/auth/user/add")} text="Add Vendor" />

      {isLoading ? (
        <AppLoader status={true} />
      ) : (
        <>

          {userList.items ? (
            <div className="w-full pt-4">
              {/* these is header part for desktop */}
              <div className="hidden lg:flex w-full bg-primary myBorder">
                <div className="centerDiv text-white font-medium py-2.5 border-r w-[15%]">Name</div>
                <div className="centerDiv text-white font-medium py-2.5 border-r w-[17%]">Email</div>
                <div className="centerDiv text-white font-medium py-2.5 border-r w-[12%]">Phone</div>
                <div className="centerDiv text-white font-medium py-2.5 border-r w-[15%]">Shop Name</div>
                <div className="centerDiv text-white font-medium py-2.5 border-r w-[14%]">Date & Time</div>
                <div className="centerDiv text-white font-medium py-2.5 border-r w-[9%]">Update</div>
                <div className="centerDiv text-white font-medium py-2.5 border-r w-[9%]">Remove</div>
                <div className="centerDiv text-white font-medium py-2.5 w-[9%]">Details</div>
              </div>
              {/* these is middle part for desktop */}
              <div className="hidden lg:flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-1 py-1.5">
                {userList.items?.map((vendor: any) => (
                  <div key={vendor?.id} className="myBorder flex w-full items-center">
                    <div className="centerDiv justify-start border-r border-grey pl-2 py-1 h-full whitespace-nowrap overflow-hidden text-ellipsis  w-[15%]">{vendor?.firstName}</div>
                    <div className="centerDiv justify-start border-r border-grey pl-2 py-1 h-full whitespace-nowrap overflow-hidden text-ellipsis   w-[17%]">{vendor?.email}</div>
                    <div className="centerDiv justify-start border-r border-grey py-1 h-full w-[12%]">{vendor?.phoneNumber}</div>
                    <div className="centerDiv border-r border-grey py-1 h-full whitespace-nowrap overflow-hidden text-ellipsis  w-[15%]">{vendor?.shopName}</div>
                    <div className="centerDiv justify-start border-r border-grey pl-1 py-1 h-full text-xs w-[14%]">{orderTimeFormatter(vendor?.dateTime)}</div>
                    <div className="centerDiv border-r border-grey py-1 h-full  w-[9%]">
                      <AppButton
                        text="Approve"
                        className="myBtn bg-royalBlue border-royalBlue py-0.5 lg:py-1 text-sm"
                        isSubmitting={isSignInButtonLoading}
                        onClick={() => openApproveModal(vendor?.id)}
                      />
                    </div>
                    <div className="centerDiv border-r border-grey py-1 h-full  w-[9%]">
                      <AppButton
                        text="Decline"
                        className="myBtn bg-red border-red py-0.5 lg:py-1 text-sm"
                        isSubmitting={isSignInButtonLoading}
                        onClick={() => openDeclineModal(vendor?.id)}
                      />
                    </div>
                    <div className="centerDiv w-[9%]">
                      <AppButton
                        text="Details"
                        className="myBtn py-0.5 lg:py-1 text-sm"
                        onClick={() => handleUserDetails(vendor?.id)}
                      />
                    </div>
                  </div>
                ))}
              </div>
              {/* these is middle part for mobile */}
              <div className="h-[78dvh] lg:hidden overflow-y-auto flex flex-col gap-2">
                {userList.items?.map((vendor?: any) => (
                  <div key={vendor?.id} className="myBorder flex flex-col w-full p-2">

                    <div className="flex flex-col w-full">
                      <div className="flex items-center gap-1">
                        <span className="font-medium">Name:</span> <span>{vendor?.firstName}</span>
                      </div>
                      <div className="flex items-center gap-1">
                        <span className="font-medium">Phone:</span> <span>{vendor?.phoneNumber}</span>
                      </div>
                      <div className="flex items-center gap-1">
                        <span className="font-medium">Email:</span> <span>{vendor?.email}</span>
                      </div>
                      <div className="flex items-center gap-1">
                        <span className="font-medium">Shop Name:</span> <span>{vendor?.shopName}</span>
                      </div>
                      <div className="flex items-center gap-1">
                        <span className="font-medium">Date:</span> <span className="text-sm">{orderTimeFormatter(vendor?.dateTime)}</span>
                      </div>
                    </div>
                    {/* these is approve decline details div */}
                    <div className="flex items-center justify-between w-full pt-2">
                      <AppButton
                        text="Approve"
                        className="myBtn bg-royalBlue border-royalBlue py-0.5 lg:py-1 text-sm"
                        isSubmitting={isSignInButtonLoading}
                        onClick={() => openApproveModal(vendor?.id)}
                      />
                      <AppButton
                        text="Decline"
                        className="myBtn bg-red border-red py-0.5 lg:py-1 text-sm"
                        isSubmitting={isSignInButtonLoading}
                        onClick={() => openDeclineModal(vendor?.id)}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="py-1 flex items-center">
                <AppPagination curPage={1} limit={10} offset={0} onPageChange={handleOnPageChange} onRowChange={handleOnRowChange} size={userList?.size} />
              </div>
            </div>
          ) : (
            <div className="w-full h-[50dvh] flex justify-center items-center text-3xl text-textBlack/90 font-medium">
              <span>No vendor requests found</span>
            </div>
          )}

        </>)}
    </div>
  );
}
