import { AlertColor } from '@mui/material';
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { addImamsNumbersNumber } from '../../../../models/importantNumber/ImportantNumberModel';
import { AppSnackBar } from '../../../common/AppSnackBar';
import { AppButton } from '../../../common/AppButton';
import { AppInput } from '../../../common/AppInput';
import { getUnionAuthListAction, getUnionListAction } from '../../../../store/actions/importantNumbers/getUnionListAction';
import { IdNameResponseModel } from '../../../../models/common/IdNameModel';
import { RootState } from '../../../../store';
import { AppSelect } from '../../../common/AppSelect';
import { getImamsNumberByIdService, updateImamsNumberService } from '../../../../httpService/importantNumbers/imamsNumberServices';
import { isAdmin } from '../../../../common/common';
import { reactLocalStorage } from 'reactjs-localstorage';

export default function INUpdateImamsNumber() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [name, setName] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [mosqueName, setMosqueName] = useState("");
  const [mosqueNameErrorMessage, setMosqueNameErrorMessage] = useState("");
  const [phoneNumberFirst, setPhoneNumberFirst] = useState("");
  const [phoneNumberFirstErrorMessage, setPhoneNumberFirstErrorMessage] = useState("");
  const [phoneNumberSecond, setPhoneNumberSecond] = useState("");
  const [phoneNumberSecondErrorMessage, setPhoneNumberSecondErrorMessage] = useState("");
  const [note, setNote] = useState("");
  const [noteErrorMessage, setNoteErrorMessage] = useState("");
  const [unionOrWard, setUnionOrWard] = useState(0);
  const [unionOrWardErrorMessage, setUnionOrWardErrorMessage] = useState("");

  const imamsNumber = async (id: any) => {
    const data = await getImamsNumberByIdService(id);
    const response: addImamsNumbersNumber = data?.data;
    setName(response.name);
    setMosqueName(response.mosqueName);
    setPhoneNumberFirst(response.phoneNumberFirst);
    setPhoneNumberSecond(response.phoneNumberSecond);
    setNote(response.note);
    setUnionOrWard(response.unionOrWardId);
  };

  useEffect(() => {
    dispatch(getUnionAuthListAction());
  }, [dispatch]);

  const dataUnionList = useSelector((state: RootState) => {
    return state.getUnionListResponseByAuthArea.data;
  });

  var dataUnionListIdNameModels: IdNameResponseModel[] = [];
  dataUnionList?.map((x: any) => {
    var union = new IdNameResponseModel();
    union.id = x.id;
    union.name = x.name;
    dataUnionListIdNameModels.push(union);
  });

  const handleNameChange = (event: any) => {
    setName(event);
    setNameErrorMessage("");
  }

  const handleMosqueNameChange = (event: any) => {
    setMosqueName(event);
    setMosqueNameErrorMessage("");
  }

  const handlePhoneNumberFirst = (event: any) => {
    setPhoneNumberFirst(event);
    setPhoneNumberFirstErrorMessage("");
  }
  const handlePhoneNumberSecond = (event: any) => {
    setPhoneNumberSecond(event);
    setPhoneNumberSecondErrorMessage("");
  }

  const handleNote = (event: any) => {
    setNote(event);
    setNoteErrorMessage("");
  }

  const handleUnionOrWardId = (id: any) => [setUnionOrWard(id)];

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSnackBarOn(false);
    var payload = {
      id: id,
      name: name,
      mosqueName: mosqueName,
      phoneNumberFirst: phoneNumberFirst,
      phoneNumberSecond: phoneNumberSecond,
      note: note,
      unionOrWardId: unionOrWard,
    };

    setIsButtonLoading(true);

    updateImamsNumberService(payload)
      .then(() => {
        setSnackBarOn(true);
        setsnackBarMessage("Imams Number Updated Successfully.");
        setSnackBarMessageType("success");
        setIsButtonLoading(false);
        setTimeout(() => {
          navigateToImamsNumbers();
        }, 300);
      })
      .catch((error: any) => {
        setIsButtonLoading(false);
        setNameErrorMessage(error.response.data.errors?.Name?.[0]);
        setPhoneNumberFirstErrorMessage(error.response.data.errors?.PhoneNumberFirst?.[0]);
        setPhoneNumberSecondErrorMessage(error.response.data.errors?.PhoneNumberSecond?.[0]);
        setNoteErrorMessage(error.response.data.errors?.Note?.[0]);
        setUnionOrWardErrorMessage(error.response.data.errors?.UnionOrWardId?.[0]);
      });
  };

  useEffect(() => {
    imamsNumber(id);
  }, []);

  const navigateToImamsNumbers = () => {
		if (isAdmin(reactLocalStorage.get("RoleName"))) {
		  navigate(`/admin/important-numbers/imams-numbers`);
		} else {
		  navigate(`/important-numbers/imams-numbers`);
		}
	  };
    
  return (
    <div>
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      <div className="pl-4 flex items-center justify-between w-3/5 pb-6">
        <AppButton
          onClick={navigateToImamsNumbers}
          text="Back"
        />
        <h2 className="text-center title">Update Imams Number</h2>
      </div>
      <form className="max-w-xl mx-auto myBorder py-4 px-5">
        <AppInput
          label="Name"
          className="myInput"
          type="text"
          placeholder="Enter Name"
          onChange={handleNameChange}
          errorMessage={nameErrorMessage}
          value={name}
        />
        <AppInput
          label="Mosque Name"
          className="myInput"
          type="text"
          placeholder="Enter Name"
          onChange={handleMosqueNameChange}
          errorMessage={mosqueNameErrorMessage}
          value={mosqueName}
        />
        <AppInput
          label="Phone Number First"
          className="myInput"
          type="text"
          placeholder="Enter Phone Number"
          onChange={handlePhoneNumberFirst}
          errorMessage={phoneNumberFirstErrorMessage}
          value={phoneNumberFirst}
        />
        <AppInput
          label="Phone Number Second"
          className="myInput"
          type="text"
          placeholder="Enter Phone Number"
          onChange={handlePhoneNumberSecond}
          errorMessage={phoneNumberSecondErrorMessage}
          value={phoneNumberSecond}
        />
        <AppInput
          label="Note"
          className="myInput"
          type="text"
          placeholder="Enter Note"
          onChange={handleNote}
          errorMessage={noteErrorMessage}
          value={note}
        />
        <div className="my-1">
          <p className="text-sm md:text-base">Union or Ward:</p>
          <AppSelect
            defaultValue="Select Ward Or Union"
            value={unionOrWard}
            data={dataUnionListIdNameModels}
            onChange={handleUnionOrWardId}
            errorMessage={unionOrWardErrorMessage}
          />
        </div>
        <div className="flex justify-end">
          <AppButton
            text="Submit"
            onClick={handleSubmit}
            isSubmitting={isButtonLoading}
            variant="contained"
          />
        </div>

      </form>
    </div>
  )
}
