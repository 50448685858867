import { Link } from "react-router-dom";
import { TbCurrencyTaka } from "react-icons/tb";
import { dateTimeFormatter } from "../../../common/common";

interface IInputProps {
  id?: any;
  name?: string;
  shortDescription?: string;
  price?: number;
  bannerPath?: any;
  isBestSeller?: boolean;
  totalSold?: number;
  totalReview?: number;
  totalView?: number;
  totalFavourite?: number;
  rating?: number;
  createdAt: string;
}

export const BechteChaiProductBox: React.FC<IInputProps> = ({
  id,
  name,
  shortDescription,
  price = 1,
  bannerPath,
  isBestSeller,
  totalSold,
  totalReview,
  totalView,
  rating,
  totalFavourite,
  createdAt
}) => {
  return (
    <div className=" w-full h-36 md:h-40 2xl:h-[190px] bg-white-300  border border-gray-100 rounded shadow-md cursor-pointer hover:shadow-xl">
      <Link to={`/bechte-chai/ad/${id}`} className="flex w-full h-full">
        <div className="w-2/5">
          <img className="rounded-l" src={bannerPath} alt="Product Pic" loading="lazy" />
        </div>

        <div className="w-3/5 overflow-hidden bottom-0 rounded-r bg-white md:p-4 p-2 flex flex-col gap-2">
          <h2 className="title">{name}</h2>
          <div className="flex items-center">
            <h2 className="subTitle">Price: </h2>&nbsp;
            <TbCurrencyTaka className="text-sm lg:text-base" />
            <span className="productPrice">{price}</span>
          </div>
          <div className="flex flex-row gap-x-[2px] 2xl:gap-y-1">
            <h2 className="subTitle">Posted At: </h2>&nbsp;
            <p className="text-sm md:text-base">{dateTimeFormatter(createdAt)}</p>
          </div>
        </div>
      </Link >
    </div >
  );
};
