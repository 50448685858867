export class CreateBkashPaymentRequest {
    amount?: number;
    callbackURL?: any;
    invoiceNumber?: any;
}

export class CreateBkashPaymentResponse {
    data?: CreatePaymentData;
    isSucceeded?: boolean;
    problem?: BkashProblem;
}

export class CreatePaymentData {
    paymentID?: any;
    bkashURL?: any;
    callbackURL?: any;
    amount?: any;
    transactionStatus?: any;
    merchantInvoiceNumber?: any;
}

export class BkashProblem {
    statusCode?: any;
    message?: any;
}

export class ExecuteBkashPaymentRequest{
    paymentId?:any;
    status?:any;
}

export class CreateTrainingInvoiceRequest{
    invoiceName?:any;
    invoiceDescription?:any;
    totalAmount?:any;
    trainingId?:any;
}