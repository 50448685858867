import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { UpdateWalletRechargeNagadRequest } from '../../../../models/auth/user/UserPointModels';
import { RootState } from '../../../../store';
import _moment from 'moment';
import { AppButton } from '../../../common/AppButton';
import { getWalletRechargeRequestListAction } from '../../../../store/actions/auth/user/userActions';
import { updateWalletRechargeNagadStatusService } from '../../../../httpService/user/userServices';
import { AlertColor } from '@mui/material';
import { AppSnackBar } from '../../../common/AppSnackBar';
import { AppModal } from '../../../common/AppModal';
import { AppSearchBox } from '../../../common/AppSearchBox';
import { AppSelect } from '../../../common/AppSelect';
import { GetWalletRechargeRequestListRequest } from '../../../../models/auth/user/UserModels';
import { AppLoader } from '../../../common/AppLoader';
import { AppPagination } from '../../../common/AppPagination';

export const pointStatuses = [
    { "id": 0, "name": "All" },
    { "id": 1, "name": "Received" },
    { "id": 2, "name": "Cancelled" },
    { "id": 3, "name": "In Progress" },
]

export default function AdminGetWalletRechargeRequests() {

    const dispatch = useDispatch();

    const [isShowSnackBar, setIsShowSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
    const [pointStatus, setPointStatus] = useState(0);

    const rechargeRequestList = useSelector((state: RootState) => {
        return state.getWalletRechargeNagadRequestListResponse.data;
    });

    var paginationModel = new GetWalletRechargeRequestListRequest();
    paginationModel.requestStatus = pointStatus;

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                paginationModel.limit = 10;
                paginationModel.offset = 0;
                await dispatch(getWalletRechargeRequestListAction(paginationModel));
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [dispatch]);

    const handleOnRowChange = (payload: GetWalletRechargeRequestListRequest) => {
        const fetchData = async () => {
            try {
                paginationModel.limit = payload.limit;
                paginationModel.offset = 0;
                await dispatch(getWalletRechargeRequestListAction(paginationModel));
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };
        fetchData();
    }

    const handleOnPageChange = (payload: GetWalletRechargeRequestListRequest) => {
        const fetchData = async () => {
            try {
                paginationModel.limit = payload.limit;
                paginationModel.offset = payload.offset;
                await dispatch(getWalletRechargeRequestListAction(paginationModel));
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };
        fetchData();
    }

    const orderTimeFormatter = (createdDateTime: any) => {
        const createdTime = _moment(createdDateTime, 'YYYY-MM-DD HH:mm:ss.SSSSSSS');
        return createdTime.format('MMM D, YY [at] h:mm A');
    }

    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const handelWithdrawPoint = (id: any, status: any) => {

        var model2 = new UpdateWalletRechargeNagadRequest();
        model2.action = status;
        model2.walletRechargeId = id;

        setIsButtonLoading(true);

        updateWalletRechargeNagadStatusService(model2)
            .then(() => {
                setApprovePopUp(false);
                setDeclinePopUp(false);
                setIsButtonLoading(false);
                setIsShowSnackBar(true);
                setSnackBarMessage("Successfully Updated Status.");
                setSnackBarMessageType("success");
                setTimeout(() => {
                    setIsShowSnackBar(false);
                }, 3000);

                dispatch(getWalletRechargeRequestListAction(paginationModel));
            }).catch(() => {
                setApprovePopUp(false);
                setDeclinePopUp(false);
                setIsButtonLoading(false);
                setIsShowSnackBar(true);
                setSnackBarMessage("Something Wrong");
                setSnackBarMessageType("error");
                setTimeout(() => {
                    setIsShowSnackBar(false);
                }, 3000);
            })
    };

    const [withdrawRequestId, setWithdrawRequestId] = useState(0);

    const [approvePopUp, setApprovePopUp] = useState(false);
    const [declinePopUp, setDeclinePopUp] = useState(false);

    const handlePointStatusChange = (event: any) => {
        setPointStatus(event)
        paginationModel.requestStatus = event;
        dispatch(getWalletRechargeRequestListAction(paginationModel));
    }

    const onSearch = (value: any) => {
        paginationModel.searchQuery = value;
        dispatch(getWalletRechargeRequestListAction(paginationModel));
    }

    const openApproveModal = (id: number) => {
        setApprovePopUp(true);
        setWithdrawRequestId(id);
    }

    const handleApprove = () => {
        handelWithdrawPoint(withdrawRequestId, 1);
    }

    const closeApprove = () => {
        setApprovePopUp(false);
    }

    const openDeclineModal = (id: number) => {
        setDeclinePopUp(true);
        setWithdrawRequestId(id);
    }

    const handleDecline = () => {
        handelWithdrawPoint(withdrawRequestId, 2);
    }

    const closeDecline = () => {
        setDeclinePopUp(false);
    }


    return (
        <>
            {approvePopUp && (
                <AppModal>
                    <div className='flex flex-col gap-4 text-2xl max-w-md text-center p-4'>
                        Are you sure you want to Approve?
                        <div className='flex items-center justify-center gap-4'>
                            <AppButton isSubmitting={isButtonLoading} text="Yes" onClick={handleApprove} />
                            <AppButton isSubmitting={isButtonLoading} text="No" onClick={closeApprove} />
                        </div>
                    </div>
                </AppModal>
            )}

            {declinePopUp && (
                <AppModal>
                    <div className='flex flex-col gap-4 text-2xl max-w-md text-center p-4'>
                        Are you sure you want to Decline?
                        <div className='flex items-center justify-center gap-4'>
                            <AppButton isSubmitting={isButtonLoading} text="Yes" onClick={handleDecline} />
                            <AppButton isSubmitting={isButtonLoading} text="No" onClick={closeDecline} />
                        </div>
                    </div>
                </AppModal>
            )}

            <div className='max-w-6xl mx-auto'>
                {isShowSnackBar && (
                    <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
                )}
                <h2 className="title text-center">Wallet Recharge Request List</h2>
                <div className="grid grid-cols-2 lg:flex items-center justify-between gap-2 py-2">
                    <AppSearchBox onChange={onSearch} className="myInput" placeholder='Search Reseller request' />
                    <div className='w-full lg:w-1/4'>
                        <AppSelect data={pointStatuses} value={pointStatus} defaultValue='Select Request Status' onChange={handlePointStatusChange} />
                    </div>
                </div>
                {isLoading ? (
                    <AppLoader status={true} />
                ) : (
                    <>
                        <div>
                            {rechargeRequestList?.items?.length ? (
                                <div className="w-full">
                                    {/* these is header part for desktop */}
                                    <div className="hidden lg:flex w-full bg-primary myBorder">
                                        {/* <div className="centerDiv text-white font-medium py-2.5 border-r w-[8%]">User ID</div> */}
                                        <div className="centerDiv text-white font-medium py-2.5 border-r w-[15%]">User Name</div>
                                        <div className="centerDiv text-white font-medium py-2.5 border-r w-[9%]">Amount</div>
                                        <div className="centerDiv text-white font-medium py-2.5 border-r w-[12%]">Request Status</div>
                                        <div className="centerDiv text-white font-medium py-2.5 border-r w-[12%]">PaymentNumber</div>
                                        <div className="centerDiv text-white font-medium py-2.5 border-r w-[12%]">Transection Number</div>
                                        <div className="centerDiv text-white font-medium py-2.5 border-r w-[6%]">Method</div>
                                        <div className="centerDiv text-white font-medium py-2.5 border-r w-[15%]">Request on</div>
                                        <div className="centerDiv text-white font-medium py-2.5 border-r w-[12%]">Approve</div>
                                        <div className="centerDiv text-white font-medium py-2.5 border-r w-[12%]">Decline</div>
                                    </div>
                                    {/* these is middle part for desktop */}
                                    <div className="hidden lg:flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-1 py-1.5">
                                        {rechargeRequestList?.items?.map((request: any) => (
                                            <div key={request?.id} className="myBorder flex w-full items-center">
                                                {/* <div className="centerDiv border-r border-grey pl-2 py-1 h-full whitespace-nowrap overflow-hidden text-ellipsis  w-[8%]">{request?.userId}</div> */}
                                                <div className="centerDiv justify-start border-r border-grey pl-2 py-1 h-full whitespace-nowrap overflow-hidden text-ellipsis w-[15%]">{request?.user?.fullName}</div>
                                                <div className="centerDiv border-r border-grey py-1 h-full w-[9%]">{request?.amount}</div>
                                                <div className={`centerDiv border-r border-grey py-1 h-full whitespace-nowrap overflow-hidden text-ellipsis  w-[12%]`}> <span className={`${request?.statusName == "InProgress" ? "InProgress" : "" || request?.statusName == "Failed" ? "canceled" : "" || request.statusName == "Done" ? "completed" : ""}`}>{request?.statusName}</span></div>
                                                <div className="centerDiv justify-start border-r border-grey pl-2 py-1 h-full text-sm w-[12%]">{request?.senderPhoneNumber}</div>
                                                <div className="centerDiv justify-start border-r border-grey pl-2 py-1 h-full text-sm w-[12%]">{request?.transectionNumber}</div>
                                                <div className="centerDiv justify-start border-r border-grey pl-2 py-1 h-full text-sm w-[6%]">{request?.paymentMethodName}</div>
                                                <div className="centerDiv justify-start border-r border-grey pl-2 py-1 h-full text-sm w-[15%]">{orderTimeFormatter(request?.craetedAt)}</div>

                                                {request?.statusName == "InProgress" ? (
                                                    (
                                                        <>
                                                            <div className="centerDiv border-r border-grey py-1 h-full  w-[12%]">
                                                                <AppButton
                                                                    text="Approve"
                                                                    className="myBtn bg-royalBlue border-royalBlue py-0.5 lg:py-1 text-sm"
                                                                    isSubmitting={isButtonLoading}
                                                                    onClick={() => openApproveModal(request?.id)}
                                                                />
                                                            </div>
                                                            <div className="centerDiv border-r border-grey py-1 h-full  w-[12%]">
                                                                <AppButton
                                                                    text="Decline"
                                                                    className="myBtn bg-red border-red py-0.5 lg:py-1 text-sm"
                                                                    isSubmitting={isButtonLoading}
                                                                    onClick={() => openDeclineModal(request?.id)}
                                                                />
                                                            </div>
                                                        </>
                                                    )
                                                ) : (
                                                    <>
                                                        <div className="centerDiv border-r border-grey py-1 h-full  w-[12%]">
                                                            <AppButton
                                                                text=""
                                                                className="myBtn bg-royalBlue border-royalBlue py-0.5 lg:py-1 text-sm"
                                                                isSubmitting={isButtonLoading}
                                                                onClick={() => { }}
                                                            />
                                                        </div>
                                                        <div className="centerDiv border-r border-grey py-1 h-full  w-[12%]">
                                                            <AppButton
                                                                text=""
                                                                className="myBtn bg-red border-red py-0.5 lg:py-1 text-sm"
                                                                isSubmitting={isButtonLoading}
                                                                onClick={() => { }}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    {/* these is middle part for mobile */}
                                    <div className="h-[78dvh] lg:hidden overflow-y-auto flex flex-col gap-2">
                                        {rechargeRequestList?.items?.map((request?: any) => (
                                            <div key={request?.id} className="myBorder flex flex-col w-full p-2">

                                                <div className="flex flex-col w-full">
                                                    {/* <div className="flex items-center gap-1">
                                            <span className="font-medium">User Name:</span> <span>{request?.userName}</span>
                                        </div> */}
                                                    <div className="grid grid-cols-2">
                                                        <div className="flex items-center gap-1">
                                                            <span className="font-medium">User Name:</span> <span>{request?.user?.fullName}</span>
                                                        </div>
                                                        <div className="flex items-center gap-1">
                                                            <span className="font-medium">Amount:</span> <span>{request?.amount}</span>
                                                        </div>
                                                    </div>
                                                    <div className="flex items-center gap-1">
                                                        <span className="font-medium">Request Status:</span>  <span className={`${request?.statusName == "InProgress" ? "InProgress" : "" || request?.statusName == "Cancelled" ? "canceled" : "" || request.statusName == "Received" ? "completed" : ""}`}>{request?.statusName}</span>
                                                    </div>
                                                    <div className="flex items-center gap-1">
                                                        <span className="font-medium">Requested on:</span> <span className="text-sm"> {orderTimeFormatter(request?.craetedAt)}</span>
                                                    </div>
                                                    <div className="grid grid-cols-1">
                                                        <div className="flex items-center gap-1">
                                                            <span className="font-medium">PaymentNumber:</span> <span>{request?.senderPhoneNumber}</span>
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-1">
                                                        <div className="flex items-center gap-1">
                                                            <span className="font-medium">Transection Number:</span> <span>{request?.transectionNumber}</span>
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-1">
                                                        <div className="flex items-center gap-1">
                                                            <span className="font-medium">Method:</span> <span>{request?.paymentMethodName}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {request?.statusName == "InProgress" ? (
                                                    (
                                                        <>
                                                            <div className="flex items-center justify-center gap-4 w-full pt-2">
                                                                <AppButton
                                                                    text="Approve"
                                                                    className="myBtn bg-royalBlue border-royalBlue py-0.5 lg:py-1 text-sm"
                                                                    isSubmitting={isButtonLoading}
                                                                    onClick={() => openApproveModal(request?.id)}
                                                                />
                                                                <AppButton
                                                                    text="Decline"
                                                                    className="myBtn bg-red border-red py-0.5 lg:py-1 text-sm"
                                                                    isSubmitting={isButtonLoading}
                                                                    onClick={() => openDeclineModal(request?.id)}
                                                                />
                                                            </div>
                                                        </>
                                                    )
                                                ) : (
                                                    <>
                                                        <div className="flex items-center justify-center gap-4 w-full pt-2">
                                                            <AppButton
                                                                text=""
                                                                className="myBtn bg-royalBlue border-royalBlue py-0.5 lg:py-1 text-sm"
                                                                isSubmitting={isButtonLoading}
                                                                onClick={() => { }}
                                                            />
                                                            <AppButton
                                                                text=""
                                                                className="myBtn bg-red border-red py-0.5 lg:py-1 text-sm"
                                                                isSubmitting={isButtonLoading}
                                                                onClick={() => { }}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        ))}

                                    </div>
                                    <div className="py-1 flex items-center">
                                        <AppPagination curPage={1} limit={10} offset={0} onPageChange={handleOnPageChange} onRowChange={handleOnRowChange} size={rechargeRequestList?.size} />
                                    </div>
                                </div>
                            ) : (
                                <div className="w-full h-[50dvh] flex justify-center items-center text-3xl text-textBlack/90 font-medium">
                                    <span>No Recharge Requests</span>
                                </div>
                            )}
                        </div>
                    </>)}
            </div>
        </>
    )
}
