import React from 'react'
import { PaginationPayloadModel } from '../../models/common/paginationModels';
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';
import { AppSelect } from './AppSelect';

const options = [
  { id: 5, name: '5' },
  { id: 10, name: '10' },
  { id: 20, name: '20' },
  { id: 100, name: '100' }
]

interface IPaginationProps {
  size: number | 0;
  curPage: number | 0;
  limit: number | 10;
  offset: number | 0;
  onRowChange: any;
  onPageChange: any;
}

export const AppPagination: React.FC<IPaginationProps> = ({curPage, size, limit, offset, onRowChange, onPageChange }) => {

  const [page, setPage] = React.useState(curPage);
  const [rowsPerPage, setRowsPerPage] = React.useState(limit);

  const [lmt, setLimit] = React.useState(limit);
  const [ofst, setOffset] = React.useState(offset);

  const handlePrevious = () => {
    if (page > 1) {

      setPage(page - 1);

      var payload = new PaginationPayloadModel;
      payload.limit = lmt;
      payload.offset = lmt * (page - 2);
      payload.curPage = page > 1 ? page - 1 : page;

      setOffset(lmt * (page - 2));

      onPageChange(payload)
    }
  };

  const handleNext = () => {
    if (size > (lmt * (page))) {
      

      var payload = new PaginationPayloadModel;
      payload.limit = lmt;
      payload.offset = lmt * (page);
      payload.curPage = page + 1;
      setPage(page + 1);

      setOffset(lmt * (page));

      onPageChange(payload)
    }
  };

  const handleChangeRowsPerPage = (value?: number) => {
    if (value == 0) value = 5;
    if (value as number > 0) {
      const payload = new PaginationPayloadModel();
      payload.limit = value !== undefined ? value : 1000000; // Provide a default value if value is undefined
      payload.offset = 0;
      payload.curPage = 1;

      setLimit(value !== undefined ? value : 1000000);
      setPage(1);
      setOffset(0);
      setRowsPerPage(value !== undefined ? value : 1000000);

      onRowChange(payload);
    }
  };


  return (
    <div className='w-full flex items-center justify-center lg:justify-between font-medium gap-4'>
      <div className='hidden lg:flex w-1/4 gap-2'>
        <p className='text-lg'>Item per page</p>
        <div className='w-1/3'>
          <AppSelect  data={options} className='' value={rowsPerPage} onChange={handleChangeRowsPerPage} />
        </div>
      </div>

      <div className='flex items-center gap-4'>
        <div onClick={handlePrevious} className='myBorder flex items-center justify-center w-12 h-8 2xl:h-10 2x:w-14  hover:bg-primary hover:text-white cursor-pointer'>
          <MdArrowBackIosNew />
        </div>
        <div className='myBorder px-2 py-1 select-none'>
          <div className=''>Page No: {page}</div>
        </div>
        <div onClick={handleNext} className='myBorder  flex items-center justify-center w-12 h-8 2xl:h-10 2x:w-14 hover:bg-primary hover:text-white cursor-pointer'>
          <MdArrowForwardIos />
        </div>
      </div>
    </div>
  )
}
