import {
  CancellOrderRequest,
  ResellingAdminGetOrderListRequest,
  GetOrdersCustomerRequest,
  OrderDetailsAdminRequest,
  ReturnOrderRequest,
  SubmitOrderRequest,
  ResellingSubmitOrderModel,
  AdminCompleteOrderRequest,
  AdminShippedOrderRequest
} from "../../models/order/resellingOrdersModels";
import { ExecuteBkashPaymentRequest } from "../../models/payment/paymnetModel";
import { GET, POST, PUT } from "../service";

export const SubmitOrderService = (payload?: SubmitOrderRequest) => {
  return POST("/api/Order/SubmitOrder", payload);
};

export const resellingSubmitOrderPGService = (payload?: ResellingSubmitOrderModel) => {
  return POST("/api/Order/ResellingSubmitOrderWithPG", payload);
};

export const resellingSubmitOrderMainBalanceService = (payload?: ResellingSubmitOrderModel) => {
  return POST("/api/Order/ResellingSubmitOrderWithMainBalance", payload);
};

export const resellingMakeOrderPaymentService = (payload?: ExecuteBkashPaymentRequest) => {
  return POST("/api/Order/UpdateOrderPayment", payload);
};

export const resellingGetOrderListService = (payload: GetOrdersCustomerRequest) => {
  return GET(
    `/api/Order/GetOrderList?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}&orderId=${payload.orderId}&orderStatus=${payload.orderStatus}&paymentStatus=${payload.paymentStatus}&shippingStatus=${payload.shippingStatus}`
  )
};

export const resellingGetOrderDetailsService = (id?: any) => {
  return GET(
    `/api/Order/GetOrderDetails?orderId=${id}`
  );
};

export const adminResellingGetOrderListService = (payload: ResellingAdminGetOrderListRequest) => {
  return GET(
    `/api/Order/GetOrderAdminList?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}&orderId=${payload.orderId}&customerId=${payload.customerId}&startDateTime=${payload.startDateTime}&endDateTime=${payload.endDateTime}&orderStatus=${payload.orderStatus}&paymentStatus=${payload.paymentStatus}&shippingStatus=${payload.shippingStatus}`
  );
};

export const adminResellingGetOrderDetailsService = (payload?: OrderDetailsAdminRequest) => {
  return GET(
    `/api/Order/GetOrderDetailsForAdmin?orderId=${payload?.orderId}&customerId=${payload?.customerId}`
  );
};

export const adminResellingCompleteOrderService = (payload?: AdminCompleteOrderRequest) => {
  return PUT("/api/Order/CompleteOrder", payload);
};

export const adminResellingConfirmOrderService = (payload?: AdminCompleteOrderRequest) => {
  return PUT("/api/Order/ConfirmOrder", payload);
};

export const adminResellingCancelOrderService = (payload: CancellOrderRequest) => {
  return POST(`/api/Order/CancelOrder`, payload);
};

export const adminResellingReturnOrderService = (payload: ReturnOrderRequest) => {
  return POST(`/api/Order/ReturnOrder`, payload);
};

export const adminResellingShippedOrderService = (payload?: AdminShippedOrderRequest) => {
  return POST("/api/Order/ShippedOrder", payload);
};
