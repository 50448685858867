import { divisionListService } from "../../../../httpService/area/divisionServices";
import { PaginationPayloadModel } from "../../../../models/common/paginationModels";
import { GET_DIVISION_LIST } from "../../../constants/auth/address/AddressConstants";

const divisionList = (payload?: PaginationPayloadModel) => async (dispatch : any) => {
        var data = divisionListService(payload)
        const pload = (await data).data;
        dispatch({ type: GET_DIVISION_LIST, payload:pload  });
}


export {divisionList}