import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { GetRiderDashboardResponse } from '../../../../models/dashboard/dashboardModel';
import { getRiderDashboardAction } from '../../../../store/actions/dashboard/dashboardActions';
import { AppLoader } from '../../../common/AppLoader';
import { getUserProfileAction } from '../../../../store/actions/auth/user/userActions';
import { reactLocalStorage } from 'reactjs-localstorage';
import { useNavigate } from 'react-router';
import { AppModal } from '../../../common/AppModal';

export default function AdminSSGetRiderDashboard() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);

    const dashboard = useSelector((state: RootState) => {
        return state.getRiderDashboardResponse.data as GetRiderDashboardResponse;
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                await dispatch(getRiderDashboardAction());
                setIsLoading(false);
            } catch (error) {
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [dispatch]);

    const orderCards = [
        {
            text: "Total Order",
            value: dashboard?.totalOrders,
            image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Frame_35024_b17srq.png",
            bgColor: "bg-[#E2E7FF]",
            borderColor: "border-b-blue-700",
        },
        {
            text: "Pending Orders",
            value: dashboard?.totalPendingOrders,
            image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/ic_twotone-pending-actions_sj45ij.png",
            bgColor: "bg-[#E2E7FF]",
            borderColor: "border-b-cyan-500",
        },
        {
            text: "Orders Shipped",
            value: dashboard?.totalShippedOrders,
            image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/flat-color-icons_shipped_g8d8uu.png",
            bgColor: "bg-[#E2E7FF]",
            borderColor: "border-b-slate-300",
        },
        {
            text: "Confirmed Orders",
            value: dashboard?.totalConfirmOrders,
            image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/game-icons_confirmed_fnl0k0.png",
            bgColor: "bg-[#E2E7FF]",
            borderColor: "border-b-sky-200",
        },
        {
            text: "Completed Orders",
            value: dashboard?.totalCompletedOrders,
            image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Done_idyekg.png",
            bgColor: "bg-[#E2E7FF]",
            borderColor: "border-b-[#37FF37]",
        },
        {
            text: "Canceled Orders",
            value: dashboard?.totalCancelledOrders,
            image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/pajamas_canceled-circle_txjeuw.png",
            bgColor: "bg-[#E2E7FF]",
            borderColor: "border-b-rose-600",
        },
        {
            text: "Orders Returned",
            value: dashboard?.totalReturnOrders,
            image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/ic_baseline-assignment-return_dfq3my.png",
            bgColor: "bg-[#E2E7FF]",
            borderColor: "border-b-amber-300",
        },
        {
            text: "Product Comments",
            value: dashboard?.totalProductReview,
            image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/rating_ozpvir.png",
            bgColor: "bg-[#E2E7FF]",
            borderColor: "border-b-royalBlue",
        },
    ];

    const productCards = [
        {
            text: "Total Products",
            value: dashboard?.totalProducts,
            image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Frame_35069_nyrvbi.png",
            bgColor: "bg-[#DCFFDD]",
            borderColor: "border-b-blue-300",
        },
        {
            text: "Active Products",
            value: dashboard?.totalActiveProducts,
            image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Frame_35070_nysnxt.png",
            bgColor: "bg-[#DCFFDD]",
            borderColor: "border-b-green-400",
        },
        {
            text: "Inactive Products",
            value: dashboard?.totalInActiveProducts,
            image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Done-1_zynuru.png",
            bgColor: "bg-[#DCFFDD]",
            borderColor: "border-b-yellow-300",
        },
        {
            text: "Stock Out Products",
            value: dashboard?.totalOutOfStockProducts,
            image: "https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Frame_21_idesqh.png",
            bgColor: "bg-[#DCFFDD]",
            borderColor: "border-b-rose-500",
        },
    ];

    const userProfile = useSelector((state: RootState) => {
        return state.getUserProfileResponse.data;
    });

    useEffect(() => {
        dispatch(getUserProfileAction());
    }, [dispatch]);

    const handleLogout = () => {
        reactLocalStorage.remove("SignIn");
        reactLocalStorage.remove("RoleName");
        reactLocalStorage.remove("UserId");
        navigate(`/`);
    };

    if (userProfile?.isBlocked) {
        return (
            <div className="container mx-auto flex h-screen items-center justify-center px-2 pb-12 md:px-0">
                <AppModal>
                    <div className="py-12 px-4 md:px-20 flex flex-col-reverse md:flex-row items-center justify-between gap-4">
                        <div className="flex flex-col items-center md:items-start gap-3">
                            <h2 className="text-xl text-red md:text-3xl md:max-w-md font-semibold">Your Account has been Suspended. Please contact with Admin</h2>
                            <div className="myBtn w-fit py-1.5 bg-red border-red" onClick={handleLogout} >
                                Ok
                            </div>
                        </div>
                    </div>
                </AppModal>
            </div>
        );
    }

    if (isLoading) {
        return (
            <div className="container h-[50dvh] flex items-center justify-center mx-auto px-2 md:px-0 pb-12">
                <AppLoader status={true} />
            </div>
        );
    }

    return (
        <div className="container mx-auto px-0 pb-4 lg:px-8 2xl:px-12">

            <div className="border-2 2xl:border-4 border-primary rounded p-4 lg:p-5 2xl:p-6 my-4 lg:my-5 2xl:my-6">
                <h2 className="title text-center">Products</h2>
                <div className="grid grid-cols-2 gap-6  pt-4 lg:grid-cols-4">
                    {productCards.map((item: any) => (
                        <div className={`flex flex-col items-center rounded-sm border-b-2 2xl:border-b-4 p-2 lg:p-4 border-primary`}>
                            <div className="2xl:pb-2">
                                <h3 className="subTitle">{item?.text}</h3>
                            </div>
                            <h3 className="text-base font-semibold lg:text-2xl">
                                {item?.value}
                            </h3>
                        </div>
                    ))}
                </div>
            </div>

            <div className="border-2 2xl:border-4 border-affiliate rounded p-4 lg:p-5 2xl:p-6 my-4 lg:my-5 2xl:my-6">
                <h2 className="title text-center">Orders</h2>
                <div className="grid grid-cols-2 gap-6   pt-4 lg:grid-cols-4">
                    {orderCards.map((item) => (

                        <div className={`flex flex-col items-center rounded-sm border-b-2 2xl:border-b-4 p-2 lg:p-4 border-affiliate`}>
                            <div className="2xl:pb-2">
                                <h3 className="subTitle">{item?.text}</h3>
                            </div>
                            <h3 className="text-base font-semibold lg:text-2xl">
                                {item?.value}
                            </h3>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}