import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { addressListReducer } from "./reducers/auth/address/addressReducer";
import { districtListReducer } from "./reducers/auth/address/districtReducer";
import { countryListReducer } from "./reducers/auth/address/countryReducer";
import {
	productAttributeMappingByIdReducer,
	productAttributeMappingReducer,
	productAttributeValueByIdReducer,
	productAttributeValuesReducer,
	productListReducer,
	productReducer,
} from "./reducers/catalog/adminProductReducers";
import { areaListAdminReducer, areaListReducer } from "./reducers/auth/address/upazillaReducer";
import { divisionListReducer } from "./reducers/auth/address/divisionReducer";
import { signUpReducer } from "./reducers/auth/address/signUpReducer";
import { signInReducer } from "./reducers/auth/address/signInReducer";
import { cityCorporationListReducer } from "./reducers/auth/address/cityCorporationReducer";
import { wardListReducer } from "./reducers/auth/address/wardReducer";
import {
	billingAddressByIdReducer,
	billingListReducer,
} from "./reducers/auth/address/billingReducer";
import { shippingListReducer } from "./reducers/auth/address/shippingReducer";
import {
	exploreLevelOneStateReducer,
	exploreLevelThreeDataReducer,
	exploreLevelThreeStateReducer,
	exploreLevelTwoDataReducer,
	exploreLevelTwoStateReducer,
	exploreReducer,
} from "./reducers/catalog/resellingExploreReducers";
import { productBrandByListReducer } from "./reducers/catalog/adminProductBrandReducer";
import {
	productAdminReviewListReducer,
	productReviewListReducer,
} from "./reducers/catalog/productReviewReducer";
import {
	getCartItemsAdminReducer,
	getCartListAdminReducer,
	getFavouriteItemReducer,
	getIsAlreadyFavouriteReducer,
	getTotalCartitemListReducer,
	getTotalCartitemReducer,
	getTotalShippingChargeReducer,
} from "./reducers/shoppingCart/shoppingCartReducers";
import userRoleReducer, {
	userRoleDispatchReducer,
} from "./reducers/auth/user/userRoleReducer";
import {
	adminResellingGetOrderDetailsReducer,
	resellingGetOrderDetailsReducer,
	adminResellingGetOrderListReducer,
	resellingGetOrderListReducer,
} from "./reducers/sales/resellingorderReducers";
import {
	getUserNameReducer,
	getuserPointListReducer,
	getuserPointReducer,
	getUserProfileReducer,
	getUserReducer,
	getResellerTeamReducer,
	getAffiliaterTeamReducer,
	getUserTotalPointsReducer,
	getUserWithdrawRequestListReducer,
	openWithdrawReducer,
	userListReducer,
	userDetailsReducer,
	getVendorReducer,
	getuserWalletReducer,
	getUserPullHistoryReducer,
	getUserWithdrawHistoryReducer,
	getCompanyWalletReducer,
	getCompanyPointListReducer,
	getVendorWalletReducer,
	getVendorPointListReducer,
	getVendorWithdrawHistoryReducer,
	getVendorPullHistoryReducer,
	getVendorWithdrawRequestListReducer,
	getWalletRechargeHistoryReducer,
	getGiveBackHistoryReducer,
	getBloodDonorsReducer,
	IsVendorAllAmountPaidReducer,
	getAreaSavedReducer,
	getDatOfferVendorWalletReducer,
	getDataOfferVendorPullHistoryReducer,
	getDatOfferVendorWithdrawHistoryReducer,
	getDataOfferVendorPointListReducer,
	getDataOfferOrderListReducer,
	getWalletRechargeNagadListReducer,
	getCompanyTeamReducer,
	getWalletCalculationsReducer,
	getRiderWalletReducer,
	getRiderPointListReducer,
	getIsRiderHaveToPayReducer,
} from "./reducers/auth/user/userReducer";
import { confirmOrderModalReducer } from "./reducers/auth/address/confirmOrderModalReducer";
import { createOrEditAttributeReducer, productAttributeListReducer } from "./reducers/catalog/adminProductAttributeReducers";
import {
	getNotificationCountReducer,
	getNotificationsReducer,
} from "./reducers/notification/notificationreducers";

import {
	getAdminDashboardReducer,
	getRiderDashboardReducer,
	getUpAdminDashboardReducer,
	getVendorDashboardReducer,
} from "./reducers/dashboard/dashboardReducer";
import {
	getIsPaidReducer,
	getIsPurchasedReducer,
	getIsTrainingVideoWatchedReducer,
	getTrainingByIdReducer,
	getTrainingListReducer,
	getTrainingVideoReducer,
	getUserTrainingVideoReducer,
} from "./reducers/training/trainingReducers";
import {
	leftMenuReducer,
	mainMenuReducer,
} from "./reducers/catalog/leftMenuReducer";
import { getVendorRequestReducer } from "./reducers/auth/vendorRequest/vendorRequestReducer";
import {
	IsProductDisabledReducer,
	IsProductPublishedReducer,
	IsShippingChargeAvailableShippingReducer,
	getShippingChargeReducer,
} from "./reducers/shippingCharge/shippingChargeReducers";

import { getUnionByAreaListReducer, getUnionListReducer } from "./reducers/importantNumbers/unionReducers";
import { getPoliceOfficialsReducer } from "./reducers/importantNumbers/policeOfficialsInfoReducer/policeOfficialsInfoReducer";
import {
	dataOfferListReducer,
	getDataOfferReducer,
} from "./reducers/dataOffer/dataOfferReducer";
import { getPublicRepresentativesReducer } from "./reducers/importantNumbers/publicRepresentatives/publicRepresentativesReducer";
import { getImamsNumbersReducer } from "./reducers/importantNumbers/imamsNumbersReducer/imamsNumbersReducer";
import { getHospitalReducer } from "./reducers/importantNumbers/hospitalReducer/hospitalReducer";
import {
	getAcademicInstituteEmployeesReducer,
	getAcademicInstitutesReducer,
} from "./reducers/importantNumbers/academicInstitutes/academicInstitutesReducer";
import {
	getDoctorListReducer,
	getDoctorNumbersReducer,
} from "./reducers/importantNumbers/doctorNumberReducer/doctorNumberReducer";
import {
	mainCategoryByListReducer,
	subCategoryLevelOneListReducer,
	subCategoryLevelTwoListReducer,
} from "./reducers/catalog/adminCategoryReducers";
import {
	bechteChaiMainCategoryByListReducer,
	bechteChaiSubCategoryListReducer,
} from "./reducers/bechteChai/adminCategoryReducers";
import {
	getNoticeListReducer,
	getNoticeByIdReducer,
	getNoticeReducer,
} from "./reducers/notice/noticeReducers";
import { getInvoicesReducer } from "./reducers/invoices/orderInvoicesReducer";
import {
	getAffiliateTotalCartItemReducer,
	getAffiliateFavouriteItemReducer,
	getIsAlreadyAffiliateFavouriteReducer,
	getAffiliateTotalCartItemListReducer,
	getAffiliateTotalShippingChargeReducer
} from "./reducers/shoppingCart/affiliateShoppingCartReducers";
import {
	resellingGetProductByIdReducer,
	resellingGetProductsReducer,
	resellingProductFilterReducer
} from "./reducers/catalog/resellingProductReducers";
import { popularLinkByListReducer } from "./reducers/popularLink/popularLinkReducers";
import { affiliateGetProductByIdReducer } from "./reducers/catalog/affiliateProductReducers";
import {
	affiliateGetOrderListCustomerReducer,
	affiliateGetOrderItemsCustomerReducer,
	affiliateGetOrderListAdminReducer,
	affiliateGetOrderItemsAdminReducer
} from "./reducers/sales/affiliateOrderReducers";
import {
	bechteChaiGetFavouriteItemReducer,
	bechteChaiGetIsAlreadyFavouriteReducer,
	bechteChaiMyProductsListReducer,
	bechteChaiProductByIdReducer,
	bechteChaiProductCommentListReducer,
	bechteChaiProductListReducer
} from "./reducers/bechteChai/bechteChaiProductReducer";
import { magazinePostByIdReducer, magazinePostListReducer, myMagazinePostListReducer } from "./reducers/magazine/magazinePostReducers";
import { magazineCategoryByIdReducer, magazineCategoryListReducer } from "./reducers/magazine/magazineCategoryReducer";
import { magazinePostCommentListReducer } from "./reducers/magazine/magazineCommentReducer";
import { sSGetBazarListReducer, sSGetBazarByIdReducer } from "./reducers/supperShop/sSBazarReducer";
import { sSGetMainCategoryListReducer, sSGetMainCategoryByIdReducer } from "./reducers/supperShop/sSMainCategoryReducer";
import { sSGetProductByIdReducer, sSGetProductListReducer } from "./reducers/supperShop/sSProductReducer";
import { sSGetSubCategoryListReducer, sSGetSubCategoryByIdReducer } from "./reducers/supperShop/sSSubCategoryReducer";
import { sSGetProductBrandByIdReducer, sSGetProductBrandListReducer } from "./reducers/supperShop/sSProductBrandReducer";
import { sSGetRiderListReducer, sSGetRiderByIdReducer } from "./reducers/supperShop/sSRiderReducer";
import { sSGetCartItemsReducer, sSGetTotalCartItemReducer } from "./reducers/supperShop/sSCartReducer";
import { sSGetFavouriteItemsReducer, sSGetIsAlreadyFavouriteOrNotReducer } from "./reducers/supperShop/sSFavouriteReducer";
import { sSAdminGetOrderDetailsReducer, sSAdminGetOrderListReducer, sSGetOrderDetailsReducer, sSGetOrderListReducer } from "./reducers/supperShop/sSOrderReducer";
import { sSProductAdminReviewListReducer, sSProductReviewListReducer } from "./reducers/supperShop/sSProductReviewReducer";

const reducer = combineReducers({

	getNoticeListResponse: getNoticeListReducer,
	getNoticeByIdResponse: getNoticeByIdReducer,
	getNoticeResponse: getNoticeReducer,

	productListResponse: productListReducer,
	productResponse: productReducer,
	productAttributeMappingResponse: productAttributeMappingReducer,
	productAttributeValuesResponse: productAttributeValuesReducer,
	productAttributeValueByIdResponse: productAttributeValueByIdReducer,
	productAttributeMappingByIdResponse: productAttributeMappingByIdReducer,

	resellingGetProductsResponse: resellingGetProductsReducer,
	resellingGetProductByIdResponse: resellingGetProductByIdReducer,

	resellingProductFilterResponse: resellingProductFilterReducer,

	productAttributeListResponse: productAttributeListReducer,

	productReviewListResponse: productReviewListReducer,
	productAdminReviewListResponse: productAdminReviewListReducer,

	mainCategoryListResponse: mainCategoryByListReducer,

	popularLinkListResponse: popularLinkByListReducer,

	productBrandListResponse: productBrandByListReducer,

	sSGetMainCategoryListResponse: sSGetMainCategoryListReducer,
	sSGetMainCategoryByIdResponse: sSGetMainCategoryByIdReducer,

	sSGetSubCategoryListResponse: sSGetSubCategoryListReducer,
	sSGetSubCategoryByIdResponse: sSGetSubCategoryByIdReducer,

	sSGetProductListResponse: sSGetProductListReducer,
	sSGetProductByIdResponse: sSGetProductByIdReducer,

	sSBazarListResponse: sSGetBazarListReducer,
	sSGetBazarByIdResponse: sSGetBazarByIdReducer,

	sSProductBrandResponse: sSGetProductBrandListReducer,
	sSGetProductBrandByIdResponse: sSGetProductBrandByIdReducer,

	sSGetRiderListResponse: sSGetRiderListReducer,
	sSGetRiderByIdResponse: sSGetRiderByIdReducer,

	sSGetCartItemsResponse: sSGetCartItemsReducer,
	sSGetTotalCartItemResponse: sSGetTotalCartItemReducer,

	sSGetFavouriteItemsResponse: sSGetFavouriteItemsReducer,
	sSGetIsAlreadyFavouriteOrNotResponse: sSGetIsAlreadyFavouriteOrNotReducer,

	sSGetOrderListResponse: sSGetOrderListReducer,
	sSAdminGetOrderListResponse: sSAdminGetOrderListReducer,

	sSGetOrderDetailsResponse: sSGetOrderDetailsReducer,
	sSAdminGetOrderDetailsResponse: sSAdminGetOrderDetailsReducer,

	sSProductReviewListResponse: sSProductReviewListReducer,
	sSProductAdminReviewListResponse: sSProductAdminReviewListReducer,

	subCategoryLevelOneListResponse: subCategoryLevelOneListReducer,
	subCategoryLevelTwoListResponse: subCategoryLevelTwoListReducer,

	getTotalCartitemResponse: getTotalCartitemReducer,
	getFavouriteItemResponse: getFavouriteItemReducer,
	getIsAlreadyFavouriteResponse: getIsAlreadyFavouriteReducer,
	getTotalCartitemListResponse: getTotalCartitemListReducer,

	affiliateGetProductByIdResponse: affiliateGetProductByIdReducer,
	affiliateGetTotalShippingChargeResponse: getAffiliateTotalShippingChargeReducer,
	affiliateGetTotalCartItemResponse: getAffiliateTotalCartItemReducer,
	getAffiliateFavouriteItemResponse: getAffiliateFavouriteItemReducer,
	getIsAlreadyAffiliateFavouriteResponse: getIsAlreadyAffiliateFavouriteReducer,
	affiliateGetTotalCartItemListResponse: getAffiliateTotalCartItemListReducer,

	getCartListAdminResponse: getCartListAdminReducer,
	getCartitemsAdminResponse: getCartItemsAdminReducer,

	getTotalShippingChargeResponse: getTotalShippingChargeReducer,

	resellingGetOrderListResponse: resellingGetOrderListReducer,
	resellingGetOrderDetailsResponse: resellingGetOrderDetailsReducer,
	adminResellingGetOrderListResponse: adminResellingGetOrderListReducer,
	adminResellingGetOrderDetailsResponse: adminResellingGetOrderDetailsReducer,

	affiliateGetOrderListCustomerResponse: affiliateGetOrderListCustomerReducer,
	affiliateGetOrderItemsCustomerResponse: affiliateGetOrderItemsCustomerReducer,
	affiliateGetOrderListAdminResponse: affiliateGetOrderListAdminReducer,
	affiliateGetOrderItemsAdminResponse: affiliateGetOrderItemsAdminReducer,

	getTrainingListResponse: getTrainingListReducer,
	getTrainingByIdResponse: getTrainingByIdReducer,
	getTrainingVideoResponse: getTrainingVideoReducer,
	getUserTrainingVideoResponse: getUserTrainingVideoReducer,
	getIsPurchasedResponse: getIsPurchasedReducer,
	getIsPaidResponse: getIsPaidReducer,
	getIsTrainingVideoWatchedResponse: getIsTrainingVideoWatchedReducer,

	getShippingChargeResponse: getShippingChargeReducer,

	addressListResponse: addressListReducer,

	areaListResponse: areaListReducer,
	areaListAdminResponse: areaListAdminReducer,

	districtListResponse: districtListReducer,

	divisionListResponse: divisionListReducer,

	countryListResponse: countryListReducer,

	signUpModalResponse: signUpReducer,

	signInModalResponse: signInReducer,

	cityCorporationResponse: cityCorporationListReducer,

	wardResponse: wardListReducer,

	billingResponse: billingListReducer,

	shippingResponse: shippingListReducer,
	IsShippingChargeAvailableShippingResponse: IsShippingChargeAvailableShippingReducer,

	exploreResponse: exploreReducer,
	leftMenuResponse: leftMenuReducer,
	mainMenuResponse: mainMenuReducer,

	exploreLevelOneStateResponse: exploreLevelOneStateReducer,
	exploreLevelTwoStateResponse: exploreLevelTwoStateReducer,
	exploreLevelThreeResponse: exploreLevelThreeStateReducer,
	exploreLevelTwoDataResponse: exploreLevelTwoDataReducer,
	exploreLevelThreeDataResponse: exploreLevelThreeDataReducer,

	userRoleListResponse: userRoleReducer,
	userRoleDispatchResponse: userRoleDispatchReducer,
	getuserPointListResponse: getuserPointListReducer,
	getCompanyPointListResponse: getCompanyPointListReducer,
	getVendorPointListResponse: getVendorPointListReducer,
	getDataOfferVendorPointListResponse: getDataOfferVendorPointListReducer,
	getDataOfferOrderListResponse: getDataOfferOrderListReducer,
	getUserPullHistoryResponse: getUserPullHistoryReducer,
	getVendorPullHistoryResponse: getVendorPullHistoryReducer,
	getDataOfferVendorPullHistoryResponse: getDataOfferVendorPullHistoryReducer,
	getUserWithdrawHistoryResponse: getUserWithdrawHistoryReducer,
	getVendorWithdrawHistoryResponse: getVendorWithdrawHistoryReducer,
	getDatOfferVendorWithdrawHistoryResponse: getDatOfferVendorWithdrawHistoryReducer,
	getuserWalletResponse: getuserWalletReducer,
	getCompanyWalletResponse: getCompanyWalletReducer,
	getVendorWalletResponse: getVendorWalletReducer,
	getDatOfferVendorWalletResponse: getDatOfferVendorWalletReducer,
	getuserPointResponse: getuserPointReducer,
	getResellerTeamResponse: getResellerTeamReducer,
	getAffiliaterTeamResponse: getAffiliaterTeamReducer,
	getCompanyTeamResponse: getCompanyTeamReducer,
	getWalletCalculationsResponse: getWalletCalculationsReducer,
	getVendorResponse: getVendorReducer,
	userListResponse: userListReducer,
	getAreaSavedResponse: getAreaSavedReducer,
	dataOfferListResponse: dataOfferListReducer,
	userDetailsResponse: userDetailsReducer,
	getUserProfileResponse: getUserProfileReducer,
	getUserResponse: getUserReducer,
	getDataOfferResponse: getDataOfferReducer,
	getUserNameResponse: getUserNameReducer,
	getUserTotalPointsResponse: getUserTotalPointsReducer,
	getUserWithdrawRequestListResponse: getUserWithdrawRequestListReducer,
	getWalletRechargeNagadRequestListResponse: getWalletRechargeNagadListReducer,
	getVendorWithdrawRequestListResponse: getVendorWithdrawRequestListReducer,

	getWalletRechargeHistoryResponse: getWalletRechargeHistoryReducer,
	getGiveBackHistoryResponse: getGiveBackHistoryReducer,
	IsVendorAllAmountPaidResponse: IsVendorAllAmountPaidReducer,
	getBloodDonorsResponse: getBloodDonorsReducer,

	getRiderWalletResponse: getRiderWalletReducer,
	getIsRiderHaveToPayResponse: getIsRiderHaveToPayReducer,
	getRiderPointListResponse: getRiderPointListReducer,

	openWithdrawResponse: openWithdrawReducer,

	getAdminDashboardResponse: getAdminDashboardReducer,
	getVendorDashboardResponse: getVendorDashboardReducer,
	getUpAdminDashboardResponse: getUpAdminDashboardReducer,
	getRiderDashboardResponse: getRiderDashboardReducer,

	billingAddressByIdResponse: billingAddressByIdReducer,

	confirmOrderModalResponse: confirmOrderModalReducer,

	createOrEditAttributeModalResponse: createOrEditAttributeReducer,

	getNotificationsResponse: getNotificationsReducer,
	getNotificationCountResponse: getNotificationCountReducer,

	getVendorRequestResponse: getVendorRequestReducer,

	IsProductPublishedResponse: IsProductPublishedReducer,
	IsProductDisabledResponse: IsProductDisabledReducer,

	getUnionListResponse: getUnionListReducer,
	getUnionListResponseByArea: getUnionByAreaListReducer,
	getUnionListResponseByAuthArea: getUnionByAreaListReducer,
	getDoctorListResponseByAuthArea: getDoctorListReducer,
	getPoliceOfficialsResponse: getPoliceOfficialsReducer,
	getHospitalResponse: getHospitalReducer,
	getHospitalsByAuthResponse: getHospitalReducer,
	getDoctorNumberResponse: getDoctorNumbersReducer,
	getPublicRepresentativesResponse: getPublicRepresentativesReducer,
	getImamsNumbersResponse: getImamsNumbersReducer,
	getAcademicInstitutesResponse: getAcademicInstitutesReducer,
	getAcademicInstituteEmployeesResponse: getAcademicInstituteEmployeesReducer,
	getTeachersOnlyResponse: getAcademicInstituteEmployeesReducer,

	getOrderInvoicesResponse: getInvoicesReducer,
	getOrderInvoiceByIdResponse: getInvoicesReducer,
	getDataOfferInvoicesResponse: getInvoicesReducer,
	getDataOfferInvoiceByIdResponse: getInvoicesReducer,

	bechteChaiMainCategoryListResponse: bechteChaiMainCategoryByListReducer,
	bechteChaiSubCategoryListResponse: bechteChaiSubCategoryListReducer,
	bechteChaiProductsListResponse: bechteChaiProductListReducer,
	bechteChaiMyProductsListResponse: bechteChaiMyProductsListReducer,
	bechteChaiProductByIdResponse: bechteChaiProductByIdReducer,
	bechteChaiProductCommentListResponse: bechteChaiProductCommentListReducer,
	bechteChaiGetFavouriteItemResponse: bechteChaiGetFavouriteItemReducer,
	bechteChaiGetIsAlreadyFavouriteResponse: bechteChaiGetIsAlreadyFavouriteReducer,

	magazineGetPostListResponse: magazinePostListReducer,
	magazineCategoryListResponse: magazineCategoryListReducer,
	magazineGetMyPostListReducer: myMagazinePostListReducer,
	magazinePostCommentListResponse: magazinePostCommentListReducer,
	magazinePostByIdResponse: magazinePostByIdReducer,
	magazineCategoryByIdResponse: magazineCategoryByIdReducer

});

const composeEnhancer = compose;

const store = createStore(reducer, composeEnhancer(applyMiddleware(thunk)));

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
