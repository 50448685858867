import { GET_NOTICE, GET_NOTICE_BY_ID, GET_NOTICE_LIST } from "../../constants/notice/noticeConstants";

export function getNoticeListReducer(state = { data: [] }, action: any) {
	switch (action.type) {
		case GET_NOTICE_LIST:
			return { loading: false, data: action.payload };
		default:
			return state;
	}
}

export function getNoticeByIdReducer(state = { data: {} }, action: any) {
	switch (action.type) {
		case GET_NOTICE_BY_ID:
			return { loading: false, data: action.payload };
		default:
			return state;
	}
}

export function getNoticeReducer(state = { data: {} }, action: any) {
	switch (action.type) {
		case GET_NOTICE:
			return { loading: false, data: action.payload };
		default:
			return state;
	}
}