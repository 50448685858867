import { AppInput } from "../../common/AppInput";
import { AppButton } from "../../common/AppButton";
import { AppSelect } from "../../common/AppSelect";
import { useEffect, useState } from "react";
import { BechteChaiProductBox } from "./BechteChaiProductBox";
import { AlertColor } from "@mui/material/Alert";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import { AppSnackBar } from "../../common/AppSnackBar";
import { GetProductListRequest } from "../../../models/catalog/product/productsModels";
import { useParams } from "react-router-dom";
import { AppLoadMore } from "../../common/AppLoadMore";
import { AppLoader } from "../../common/AppLoader";
import { getProductListService } from "../../../httpService/bechteChai/bechteChaiProductServices";

const shortByFilter = [
  { id: 1, name: "Price Low to High" },
  { id: 2, name: "Price High to Low" },
];

export default function BechteChaiGetFilterProducts() {
  const [isGridSelect, setIsGridSelect] = useState(true);

  const searchQuery = useSelector((state: RootState) => {
    return state.bechteChaiProductsListResponse.data;
  });

  const { mainCatId } = useParams();
  const { subCatId } = useParams();

  const [isLoadMoreButtonLoading, setIsLoadMoreButtonLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [size, setSize] = useState(0);
  const [page, setPage] = useState(1);
  const [isDisable, setIsDisable] = useState(false);

  const [loadedProducts, setLoadedProducts] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadMoreButtonLoading(true);
        var pModel = new GetProductListRequest();
        pModel.limit = 50;
        pModel.offset = 0;
        pModel.searchQuery = searchQuery;
        pModel.sortBy = 0;
        pModel.minimumPrice = minPrice;
        pModel.maximumPrice = maxPrice;
        pModel.vendorId = 0;
        pModel.mainCategoryId = mainCatId;
        pModel.subCategoryLevelOneId = subCatId;
        const response = await getProductListService(pModel);
        setLoadedProducts(response?.data?.items);
        setSize(response?.data?.size);
        setIsLoadMoreButtonLoading(false);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [loadedProducts.length == 0, searchQuery, subCatId]);

  const handleOnRowChange = async (payload: GetProductListRequest) => {
    setPage(page + 1);
    setIsDisable((payload.offset as number) >= size);
    var pModel = new GetProductListRequest();
    pModel.limit = payload.limit;
    pModel.offset = payload.offset;
    pModel.searchQuery = searchQuery;
    pModel.sortBy = 0;
    pModel.minimumPrice = minPrice;
    pModel.maximumPrice = maxPrice;
    pModel.vendorId = 0;
    pModel.mainCategoryId = mainCatId;
    pModel.subCategoryLevelOneId = subCatId;
    try {
      setIsLoadMoreButtonLoading(true);
      const response = await getProductListService(pModel);
      setLoadedProducts((prevProducts) => [
        ...prevProducts,
        ...response.data.items,
      ]);
      setIsLoadMoreButtonLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [sortById, setSortById] = useState(0);

  const handleSortByFilter = async (id: any) => {
    setSortById(id);
    var pModel = new GetProductListRequest();
    pModel.limit = 50;
    pModel.offset = 0;
    pModel.searchQuery = searchQuery;
    pModel.sortBy = id;
    pModel.minimumPrice = minPrice;
    pModel.maximumPrice = maxPrice;
    pModel.vendorId = 0;
    pModel.mainCategoryId = mainCatId;
    pModel.subCategoryLevelOneId = subCatId;
    try {
      setIsLoadMoreButtonLoading(true);
      setIsLoading(true);
      const response = await getProductListService(pModel);
      setLoadedProducts(response.data.items);
      setIsLoadMoreButtonLoading(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);

  const onMinMaxSubmit = async () => {
    if (minPrice - maxPrice >= 0) {
      setIsShowSnackBar(true);
      setSnackBarMessage("Min Price is grater than Max price.");
      setSnackBarMessageType("warning");
      setTimeout(() => {
        setIsShowSnackBar(false);
      }, 2000);

      return;
    }

    if (minPrice <= 0 || maxPrice <= 0) {
      setIsShowSnackBar(true);
      setSnackBarMessage("Min Price or Max price must be positive.");
      setSnackBarMessageType("warning");
      setTimeout(() => {
        setIsShowSnackBar(false);
      }, 2000);

      return;
    }

    if (
      minPrice > 0 &&
      maxPrice > 0 &&
      (minPrice as number) <= (maxPrice as number) &&
      maxPrice - minPrice > 0
    ) {
      var pModel = new GetProductListRequest();
      pModel.limit = 100000;
      pModel.offset = 0;
      pModel.searchQuery = searchQuery;
      pModel.sortBy = sortById;
      pModel.minimumPrice = minPrice;
      pModel.maximumPrice = maxPrice;
      pModel.vendorId = 0;
      pModel.mainCategoryId = 0;
      try {
        setIsLoading(true);
        const response = await getProductListService(pModel);
        setLoadedProducts((prevProducts) => [
          ...prevProducts,
          ...response.data.items,
        ]);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      setIsShowSnackBar(true);
      setSnackBarMessage("Min Max price range is invalid.");
      setSnackBarMessageType("warning");
      setTimeout(() => {
        setIsShowSnackBar(false);
      }, 2000);

      return;
    }
  };

  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

  if (isLoading) {
    return (
      <div className="container mx-auto flex h-[50dvh] items-center justify-center px-2 pb-12 md:px-0">
        <AppLoader status={true} />
      </div>
    );
  }

  return (
    <>
      {isShowSnackBar && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}

      {/* these is filter product main div start  */}
      <div className="container mx-auto 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28">
        {/* these is min max button and search and filter button  */}
        <div className="flex flex-col items-center justify-between gap-y-2 px-0 pb-4 md:px-4 lg:flex-row">
          {/* these is min and max button section  */}
          <div className="flex items-center justify-center gap-2 md:justify-start md:gap-4">
            {/* these is min max button  */}
            <div className="input-control flex w-[65%] items-center gap-2 md:w-3/5 md:gap-4">
              <AppInput
                className="w-full cursor-pointer rounded border border-bechteChai py-1.5 pl-2 text-xs md:py-1 md:text-base"
                value={minPrice !== 0 ? minPrice : ""}
                onChange={(value: any) => setMinPrice(value)}
                placeholder="Minimum price"
                type={"number"}
              />
              <AppInput
                className="w-full cursor-pointer rounded border border-bechteChai py-1.5 pl-2 text-xs md:py-1 md:text-base"
                value={maxPrice !== 0 ? maxPrice : ""}
                onChange={(value: any) => setMaxPrice(value)}
                placeholder="Maximum price"
                type={"number"}
              />
            </div>

            {minPrice !== 0 && maxPrice !== 0 && (
              <AppButton
                onClick={onMinMaxSubmit}
                text={"Submit"}
                className="bechteChaiBtn md:py-1.5"
              />
            )}
          </div>
          {/* these is list view and search section  */}
          <div className="flex w-11/12 items-center gap-4 lg:w-1/4">
            <div className="flex w-full flex-col">
              <p className="subTitle font-normal">Sort by:</p>
              <AppSelect
                data={shortByFilter}
                defaultValue="Select Price Filter"
                value={sortById}
                onChange={handleSortByFilter}
              />
            </div>
          </div>
        </div>
        {/* these is products list div  */}
        {loadedProducts.length ? (
          <div className="">
            {/* ---------when grid icon click open this div-------  */}
            {isGridSelect ? (
              <>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 lg:gap-6 2xl:gap-8">
                  {loadedProducts &&
                    loadedProducts?.map((x: any) => (
                      <BechteChaiProductBox
                        key={x.id}
                        id={x.id}
                        name={x.name}
                        shortDescription={x.shortDescription}
                        bannerPath={x.photoPath}
                        isBestSeller={x.isBestSeller}
                        price={x.price}
                        rating={x.rating}
                        totalSold={x.totalSold}
                        totalReview={x.totalReview}
                        totalView={x.totalView}
                        createdAt={x.createdAt}
                      />
                    ))}
                </div>
                <div className="flex items-center justify-center pt-6">
                  <AppLoadMore
                    text="Show more Products"
                    isSubmitting={isLoadMoreButtonLoading}
                    className="outlineLoadMoreBtnBechteChai"
                    isDisable={isDisable}
                    page={page}
                    onRowChange={handleOnRowChange}
                    size={size}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <p className="py-16 text-center text-xl text-gray-600 md:text-3xl">
            No product found
          </p>
        )}
      </div>
    </>
  );
}
