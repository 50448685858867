import { GET, POST } from "../service";

export const createNotificationService = (data?: any) => {
    return POST("/api/Notification/CreateNotification", data);
};

export const getNotificationsService = (payload?: any) => {
    return GET(`/api/Notification/GetNotifications?limit=${100000000}&offset=${0}`);
};

export const getNotificationCountService = () => {
    return GET(`/api/Notification/GetNotificationArrivalCount`);
};

export const resetNotificationCountService = () => {
    return POST(`/api/Notification/ResetNotificationArrivalCount`, {});
};
