import { GET_DATA_OFFER, GET_DATA_OFFER_LIST } from "../../constants/auth/dataOffer/dataOfferConstatnt";

const initialState = {
    dataOfferList: [],
  };
function dataOfferListReducer(state = initialState, action: any) {
    if (action.type === GET_DATA_OFFER_LIST) {
      return { dataOfferList: action.payload };
    }
    return state;
}

function getDataOfferReducer(state = { data: {} }, action: any) {
  switch (action.type) {
    case GET_DATA_OFFER:
      return { loading: false, data: action.payload };
    default:
      return state;
  }
}

export {
    dataOfferListReducer,
    getDataOfferReducer
}