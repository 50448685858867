import { DELETE, GET, POST, PUT } from "../service";

export const updateDoctorService = (data: any) => {
    return PUT("/api/DoctorNumber/UpdateDoctorNumber", data);
};
export const addDoctorNumbersService = (data: any) => {
    return POST("/api/DoctorNumber/CreateDoctorNumber", data);
};

export const getDoctorByIdService = (id: any) => {
    return GET(`/api/DoctorNumber/GetDoctorNumberById?Id=${id}`);
};
export const getDoctorService = (payload: any) => {
    return GET(`/api/DoctorNumber/GetDoctorNumbers?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}&divisionId=${payload?.divisionId}&districtId=${payload?.districtId}&areaId=${payload?.areaId}&hospitalsNumbersId=${payload?.hospitalsNumbersId}`);
};

export const deleteDoctorService = (data: any) => {
    return DELETE(`/api/DoctorNumber/RemoveDoctorNumbers`, {
        data: { "ids": data },
    });
};
export const getHospitalsByAuthService = (payload: any) => {
    return GET(`/api/HospitalNumber/GetHospitalNumbers?byAuth=${"y"}`);
};