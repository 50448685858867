const ReturnPolicy = () => {
    return (
        <div className='max-w-xl mx-auto min-h-[80dvh] text-center flex items-center flex-col text-textBlack px-3 pt-20 lg:pt-28  space-y-2'>
            <p className='text-primary text-xl font-semibold pb-2'>রিটার্ন পলিসি</p>
            <p>ডেলিভারি ম্যানের সামনে প্যাকেট খুলে চেক করে নিতে হবে। প্রোডাক্ট খারাপ হলে সাথে সাথে ডেলিভারি ম্যানকে সেটা ফেরত দিয়ে দিতে হবে। পরবর্তী অভিযোগ গ্রহনযোগ্য নয়।</p>
            <p>রিসেলার/কাস্টমারের ভুলের কারণে রিটার্ন হলে ডেলিভারি চার্জ ফেরত পাবেন না। ভেন্ডরের ভুলের কারণে রিটার্ন হলে ডেলিভারি চার্জ ফেরত পাবেন।</p>
        </div>
    );
};

export default ReturnPolicy;