import { sSGetProductBrandByIdService, sSgetProductBrandListService } from "../../../httpService/superShops/sSProductBrandServices";
import { SSGetProductBrandListRequest } from "../../../models/superShopp/catalog/sSProductBrand/sSProductBrandModel";
import { SSGET_PRODUCT_BRAND_BY_ID, SSGET_PRODUCT_BRAND_LIST } from "../../constants/superShop/ssProductBrandConstants";

export const sSgetProductBrandListAction = (payload?: SSGetProductBrandListRequest, isAll?: boolean, forPublic?: boolean) => async (dispatch: any) => {
	var data = sSgetProductBrandListService(payload, isAll, forPublic);
	const pload = (await data).data;
	dispatch({ type: SSGET_PRODUCT_BRAND_LIST, payload: pload });
};

export const sSGetProductBrandByIdAction = (id: any) => async (dispatch: any) => {
	try {
		var data = sSGetProductBrandByIdService(id);
		const payload = (await data).data;
		dispatch({ type: SSGET_PRODUCT_BRAND_BY_ID, payload: payload });
	} catch (error: any) { }
};