import { DataOfferListRequest } from "../../models/auth/user/UserPointModels";
import { GET, POST, PUT } from "../service";

export const creatDataOfferService = (payload: any) => {
	return POST(`/api/DataOffer/createDataOffer`, payload)
};

export const updateDataOfferService = (payload: any) => {
	return PUT(`/api/DataOffer/UpdateDataOffer`, payload)
};

export const getDataOfferStatusUpdateService = (payload?: any) => {
	return PUT(`/api/DataOffer/updateActiveStatus`, payload)
};

export const getDataOfferService = (id: any) => {
	return GET(`/api/DataOffer/getDataOfferById?id=${id}`)
};

export const getDataOfferListService = (payload?: DataOfferListRequest) => {
	if (payload) {
		return GET(
			`/api/DataOffer/getDataOffers?limit=${payload?.limit}&offset=${payload?.offset}${payload?.searchQuery ? '&searchQuery=' + payload.searchQuery : ''}&userRole=${payload.userRole}&dataOfferType=${payload.dataOfferType}&dataPackageType=${payload.dataPackageType}&mobileOperator=${payload.mobileOperator}`
		);
	} else {
		return GET(`/api/DataOffer/getDataOffers`);
	}
};

export const removeDataOfferService = (payload?: any) => {
	return PUT(`/api/DataOffer/removeDataOffer`, payload)
};

