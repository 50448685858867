import { ResellingGetCustomerReviews } from "./ResellingGetCustomerReviews";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import {
  ShoppingCartCategory,
  ShoppingCartFavouriteModel,
  ShoppingCartFavouriteRequest,
  ShoppingCartType,
} from "../../../models/shoppingCart/resellingCartModels";
import { AppSnackBar } from "../../common/AppSnackBar";
import { AlertColor } from "@mui/material/Alert";
import { getFavouriteItemListAction, getIsAlreadyFavouriteOrNotAction, getTotalShoppingCartItemAction } from "../../../store/actions/cart/resellingCartActions";
import { addToCardOrFavouriteService, addOrRemoveFavouriteService } from "../../../httpService/shoppingCart/resellingCartServices";
import { resellingProductReviewsActions } from "../../../store/actions/catalog/resellingProductReviewActions";
import { ProductReviewCustomerResponse } from "../../../models/catalog/productReview/productReviewModels";
import { AppButton } from "../../common/AppButton";
import { AppInput } from "../../common/AppInput";
import { HiPlus, HiMinus, HiCheck } from 'react-icons/hi';
import { TbCurrencyTaka } from 'react-icons/tb';
import { BsHeart, BsHeartFill } from "react-icons/bs";
import { AppLoader } from "../../common/AppLoader";
import { getIsTrainingVideoWatchedAction } from "../../../store/actions/training/trainingAction";
import { AppModal } from "../../common/AppModal";
import { MdPlayCircle } from "react-icons/md";
import { resellingGetProductByIdAction } from "../../../store/actions/catalog/resellingProductActions";

type Attribute = {
  name: string;
  value: any;
  availableInStock?: any
};

export default function ResellingGetProductById() {

  const ip = reactLocalStorage.get("ip") as string;

  const [isLoading, setIsLoading] = useState(true);

  const [titleBannerPath, setTitleBannerPath] = useState("");

  const [isUpdateFavourite, setIsUpdateFavourite] = useState(false);

  const [count, setProductCount] = useState(1);

  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

  const [isCustomizationOn, setIsCustomizationOn] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const productDetails = useSelector((state: RootState) => {
    return state.resellingGetProductByIdResponse.data.data;
  });

  const productReviews = useSelector((state: RootState) => {
    return state.productReviewListResponse.data as ProductReviewCustomerResponse[];
  });

  const { id } = useParams();

  const [favItems, setFavouriteItems] = useState<ShoppingCartFavouriteModel[]>([]);

  let isSignIn: any = reactLocalStorage.getObject("SignIn");
  let token = isSignIn?.accessToken;

  const favouriteItemsSignIn = useSelector((state: RootState) => {
    return state.getFavouriteItemResponse.data;
  });

  const favItemsMemo = useMemo(() => {
    return favouriteItemsSignIn as ShoppingCartFavouriteModel[];
  }, [favouriteItemsSignIn]);


  useEffect(() => {
    if (token && JSON.stringify(token) !== JSON.stringify({})) {
      if (favItemsMemo) {
        setFavouriteItems(favItemsMemo);
      }
    } else {

    }
  }, [favItemsMemo, token]);

  const isFavourite = useSelector((state: RootState) => {
    return state.getIsAlreadyFavouriteResponse.data;
  });


  useEffect(() => {
    dispatch(getIsAlreadyFavouriteOrNotAction(id))
  }, [productDetails, id]);


  useEffect(() => {
    dispatch(getFavouriteItemListAction(ShoppingCartType.Favourite));
  }, [dispatch, productDetails])


  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await dispatch(resellingGetProductByIdAction(id));
        await dispatch(resellingProductReviewsActions(id));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch, id]);

  useEffect(() => {
    if (productDetails) {
      setTitleBannerPath(productDetails.bannerPath)
    }
  }, [productDetails]);

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const refValue = searchParams.get("resellRef");
    if (refValue)
      reactLocalStorage.set("resellRef", refValue);
  }, [location]);


  const handleProductMainPhotoCahange = (path: any) => {
    setTitleBannerPath(path);
  };

  var cartItems: ShoppingCartFavouriteModel[] = [];

  const [isAddToCardLoading, setIsAddToCardLoading] = useState(false);

  const handleAddToCart = () => {

    const formattedData: Attribute[] = attributesList.map((item: any) => ({
      ...item,
      value: item.value.value,
      availableInStock: item.value.availableInStock
    }));

    if (productDetails?.isVariationAvailable && (selectedAttributes && JSON.stringify(selectedAttributes) === JSON.stringify({}))) {
      setIsShowSnackBar(true);
      setSnackBarMessage("Please Select Varient");
      setSnackBarMessageType("error");
      setTimeout(() => {
        setIsShowSnackBar(false);
      }, 2000);
      return
    }

    if (earning < 0) {
      setIsShowSnackBar(true);
      setSnackBarMessage("Earning must be positive number");
      setSnackBarMessageType("error");
      setTimeout(() => {
        setIsShowSnackBar(false);
      }, 2000);
      return
    }

    if (token && JSON.stringify(token) !== JSON.stringify({})) {
      let model = new ShoppingCartFavouriteModel();
      model.productId = id;
      model.productQuantity = count;
      model.attributes = JSON.stringify(formattedData);;
      model.unitPrice = defaultPrice * 1; /// to do
      model.totalPrice = count * defaultPrice;
      model.customerIP = ip;
      model.shoppingCartType = ShoppingCartType.ShoppingCart;

      model.productName = productDetails?.name;
      model.productShortDescription = productDetails?.shortDescription;
      model.productCode = productDetails?.productCode;
      model.bannerPath = titleBannerPath;
      model.availableStock = productDetails?.availableStock;
      model.cartCategory = ShoppingCartCategory.Reselling;
      model.unitIncome = ppEarning;
      model.totalIncome = earning;
      model.attributeValueIds = attributeValueIds;

      cartItems = [];

      if (token && JSON.stringify(token) !== JSON.stringify({})) {
        cartItems.push(model);

        var req = new ShoppingCartFavouriteRequest();
        req.customerIp = ip;
        req.shoppingCartType = ShoppingCartType.ShoppingCart;
        req.cartItems = cartItems;

        setIsAddToCardLoading(true)

        addToCardOrFavouriteService(req)
          .then((res) => {

            setIsAddToCardLoading(false)

            if (res?.data?.isOperationSuccessfull) {
              setIsShowSnackBar(true);
              setSnackBarMessage("Add to cart Successfully");
              setSnackBarMessageType("success");
              setTimeout(() => {
                setIsShowSnackBar(false);
              }, 2000);
            }
            else {
              setIsShowSnackBar(true);
              setSnackBarMessage("Out Of Stock");
              setSnackBarMessageType("warning");
              setTimeout(() => {
                setIsShowSnackBar(false);
              }, 2000);
            }

            dispatch(getTotalShoppingCartItemAction(res.data.totalItem));
          })
          .catch(() => {
            setIsAddToCardLoading(false)
          });
      } else {
        var pItems = reactLocalStorage.getObject(
          "CartItems"
        ) as ShoppingCartFavouriteModel[];

        if (pItems !== undefined && pItems !== null && pItems?.length && pItems) {
          pItems?.map((x: ShoppingCartFavouriteModel, index: any) => {
            if (
              x.productId !== model.productId &&
              model.shoppingCartType === ShoppingCartType.ShoppingCart
            ) {
              cartItems.push(x as ShoppingCartFavouriteModel);
            }
            if (
              x.productId === model.productId &&
              model.shoppingCartType === ShoppingCartType.ShoppingCart
            ) {
              let q: number = x.productQuantity as number;

              model.productQuantity = ((model.productQuantity as number) + q) as number;

              let qntty: number = model.productQuantity as number;
              let prc: number = model.totalPrice as number;

              model.totalPrice = prc * qntty;

              cartItems.push(model);
            }
          });
          if (pItems?.filter((x) => x.productId === model.productId).length <= 0) {
            cartItems.push(model);
          }
        } else {
          cartItems.push(model);
        }

        reactLocalStorage.setObject("CartItems", cartItems);

        setIsShowSnackBar(true);
        setSnackBarMessage("Add to cart Successfully");
        setSnackBarMessageType("success");
        setTimeout(() => {
          setIsShowSnackBar(false);
        }, 2000);

        var totalItem = 0;
        cartItems?.map((x) => {
          totalItem = totalItem + (x.productQuantity as number);
        });

        dispatch(getTotalShoppingCartItemAction(totalItem));
      }
    } else {
      dispatch({ type: "OPEN-SIGN-IN" });
      dispatch({ type: "CLOSE-SIGN-UP" });
    }

  };

  const onCustomizeProductPrice = () => {
    setIsCustomizationOn(!isCustomizationOn)
  }


  const handleAddToFavourite = () => {

    if (token && JSON.stringify(token) !== JSON.stringify({})) {

      let model = new ShoppingCartFavouriteModel();
      model.productId = id;
      model.customerIP = ip;

      addOrRemoveFavouriteService(model)
        .then(() => {

          setIsUpdateFavourite(true);
          dispatch(getIsAlreadyFavouriteOrNotAction(id))
          setIsShowSnackBar(true);
          setSnackBarMessage("Successfully done");
          setSnackBarMessageType("success");
          setTimeout(() => {
            setIsShowSnackBar(false);
          }, 2000);

        })
        .catch(() => {

        });
    }
  };


  const handleCountChange = (type: any) => {
    if (type === "increse") {
      if (count < productDetails?.availableStock) {
        setProductCount(count + 1);
        setEarning(ppEarning * (count + 1));
      }
      else {
        setProductCount(count + 1);
        setEarning(ppEarning * (count + 1));
      }
    }

    if (type === "decrese") {
      if (count > 1) {
        setProductCount(count - 1);
        setEarning(ppEarning * (count - 1));
      };
    }
  };

  const [selectedAttributes, setSelectedAttributes]: any[] = useState({});
  const [attributesList, setAttributesList] = useState<Attribute[]>([]);
  const [finalAttributesList, setFinalAttributesList] = useState<Attribute[]>([]);

  const [setAvailabeInStock, setSetAvailabeInStock] = useState(0)

  const [attributeValueIds, setAttributeValueIds] = useState("");

  const handleAttributeSelection = (value: any, attributeName: string, valueName: any, availabeInStock: any) => {

    setAttributeValueIds(value?.id);

    setSelectedAttributes((prevSelectedAttributes: Record<string, any>) => {
      const existingAttribute = Object.entries(prevSelectedAttributes).find(
        ([name]) => name === attributeName
      );
      if (existingAttribute) {
        const [name, value] = existingAttribute;
        return { ...prevSelectedAttributes, [name]: { value: valueName, availableInStock: availabeInStock } };
      } else {
        return { ...prevSelectedAttributes, [attributeName]: { value: valueName, availableInStock: availabeInStock } };
      }
    });
    setSetAvailabeInStock(availabeInStock)
  };

  useEffect(() => {
    setAttributesList(Object.entries(selectedAttributes).map(
      ([name, value]) => ({ name, value })
    ));
  }, [selectedAttributes]);

  const isValueSelected = (attributeName: any, valueName: any, availabeInStock: any) => {
    const selectedValue = selectedAttributes[attributeName];
    return selectedValue && selectedValue.value === valueName && selectedValue.availableInStock === availabeInStock;
  };

  useEffect(() => {
    setDefaultPrice(productDetails?.price)
  }, [productDetails])

  const [defaultPrice, setDefaultPrice] = useState(0);
  const [earning, setEarning] = useState(0);
  const [ppEarning, setPpEarning] = useState(0);

  const handleEarning = (value: any) => {
    setDefaultPrice(value);
    setEarning((value - productDetails?.price) * count);
    setPpEarning(value - productDetails?.price)
  }

  const navigateToVendorShop = (vendorId: any) => {
    navigate(`/reselling-platform/vendorProducts/${vendorId}`);
  }

  const handleDownload = async () => {
    const response = await fetch(productDetails?.bannerPath);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'picture.jpg';
    link.click();

    URL.revokeObjectURL(url);
  };


  const isAllTrainingVideoNotWatched = useSelector((state: RootState) => {
    return state.getIsTrainingVideoWatchedResponse.data;
  });

  useEffect(() => {
    dispatch(getIsTrainingVideoWatchedAction(0));
  }, [dispatch]);


  if (isLoading) {
    return (
      <div className="container h-screen flex items-center justify-center mx-auto px-2 md:px-0 pb-12">
        <AppLoader status={true} />
      </div>
    );
  }


  return (
    <div className="container mx-auto pb-8 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28">
      {isShowSnackBar && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      {isAllTrainingVideoNotWatched && (token && JSON.stringify(token) !== JSON.stringify({})) && (
        <AppModal>
          <div className="py-12 px-4 md:px-20 flex flex-col-reverse md:flex-row items-center justify-between gap-4">
            <div className="flex flex-col items-center md:items-start gap-3">
              <h2 className="text-xl md:text-3xl md:max-w-md font-semibold">Please Complete your Training First</h2>
              <div className="resellingBtn w-fit py-1.5" onClick={() => { }} >
                <Link to="/training">
                  Go To Training
                </Link>
              </div>
            </div>
            <img className="w-60 md:w-72 h-40 md:h-48 object-contain" src="https://res.cloudinary.com/ddqenwff0/image/upload/v1687276368/Frame_2_dncct1.png" alt="" />
          </div>
        </AppModal>
      )}
      {/* product photo and details start here  */}
      <div className="md:pt-4 pt-2 flex flex-col md:flex-row gap-y-4 gap-x-8">
        {/* these is product photo section */}
        <div className="w-full md:w-1/2 flex flex-col gap-y-2 md:gap-y-4">

          {/* large photo section here  */}
          <div className="relative w-full md:h-[58dvh] h-[35dvh] bg-white myBorder">
            {/* <div className="absolute top-1 md:top-3 right-1 md:right-3 text-reselling font-bold md:text-xl myBorder p-1 md:p-1.5 bg-emerald-200 cursor-pointer" title="Download image" onClick={handleDownload} ><FiDownload /></div> */}
            <img className="object-contain w-full h-full" src={titleBannerPath !== "" ? titleBannerPath : productDetails?.bannerPath} alt="main big product pic" />
          </div>
          {/* small photos section here  */}
          <div className="w-full flex gap-x-2 md:gap-x-4 md:h-[18dvh] h-[8dvh]">
            {productDetails?.bannerPathList &&
              productDetails?.bannerPathList?.map((x: any, index: any) => {
                return (
                  <div
                    className="cursor-pointer bg-white myBorder w-1/5"
                    key={index}
                    onClick={() => handleProductMainPhotoCahange(x)}
                  >
                    <img className="object-cover rounded w-full h-full" src={x} alt="" />
                  </div>
                );
              })}
          </div>
        </div>

        {/* product details section beside product photo start here  */}
        <div className="w-full md:w-1/2">
          <p onClick={() => navigateToVendorShop(productDetails?.vendorId)} className="text-royalBlue text-xl cursor-pointer font-medium 2xl:pb-2 pb-1">{productDetails?.vendorName}</p>
          <h2 className="2xl:text-2xl text-lg md:text-xl 2xl:pb-2 pb-1 font-medium"> {productDetails?.name} </h2>

          {/* rating reviews sold section here  */}
          <div className="flex items-center justify-between md:max-w-md max-w-sm">
            {/* reviews section here  */}
            <span className="font-medium">{productReviews?.length} reviews</span>
            {/* sold section here  */}
            <span className="font-medium">{productDetails?.totalSold} sold</span>
            {/* rating section here  */}
            {/* {productDetails?.rating && ( */}
            <div className="font-medium">

              {(productDetails?.rating > 0 && productDetails?.rating <= 1) && (
                <img
                  src="https://res.cloudinary.com/ddqenwff0/image/upload/v1678478594/Star_17_paiezv.png"
                  alt=""
                />
              )}

              {(productDetails?.rating > 1 && productDetails?.rating <= 2) && (
                <img
                  src="https://res.cloudinary.com/ddqenwff0/image/upload/v1678478594/Group_2631_xtm5u7.png"
                  alt=""
                />
              )}

              {(productDetails?.rating > 2 && productDetails?.rating <= 3) && (
                <img
                  src="https://res.cloudinary.com/ddqenwff0/image/upload/v1678478595/Group_2632_ksjv3i.png"
                  alt=""
                />
              )}

              {(productDetails?.rating > 3 && productDetails?.rating <= 4) && (
                <img
                  src="https://res.cloudinary.com/ddqenwff0/image/upload/v1678478594/Group_2633_ediwi7.png"
                  alt=""
                />
              )}

              {(productDetails?.rating > 4 && productDetails?.rating <= 5) && (
                <img
                  src="https://res.cloudinary.com/ddqenwff0/image/upload/v1678478594/Group_2634_kn5jp8.png"
                  alt=""
                />
              )}

              {(productDetails?.rating >= 0 && productDetails?.rating !== 0) && (
                <>{productDetails?.rating}</>
              )}

            </div>
            {/* )} */}
          </div>
          <div className='flex items-center justify-between gap-4 max-w-xs'>
            <div>
              {/* in stock green section here  */}
              {productDetails?.availableStock > 0 && productDetails.isVariationAvailable === false && (
                <div className="bg-[#D1FFF5] text-reselling 2xl:text-lg text-base flex items-center font-medium px-2 2xl:my-2 my-1 rounded w-fit gap-x-2">
                  <HiCheck /> in stock ({productDetails.availableStock})
                </div>
              )}

              {/* in stock red section here  */}
              {productDetails?.availableStock <= 0 && productDetails.isVariationAvailable === false && (
                <div className="bg-[#FF7474] text-white 2xl:text-lg text-base flex items-center font-medium px-2 2xl:my-2 my-1 rounded w-fit gap-x-2">
                  <HiCheck /> Out of stock
                </div>
              )}
            </div>
            {productDetails?.videoUrl &&
              <Link to={productDetails?.videoUrl} target="_blank">
                <MdPlayCircle className="text-reselling text-4xl cursor-pointer" />
              </Link>
            }
          </div>

          {/* price and offer section here  */}
          <div className="flex gap-x-8 2xl:pb-2 pb-1 items-center">
            {/* present price here  */}
            <h2 className="2xl:text-xl text-xl font-medium flex 2xl:gap-x-1 gap-x-[1px] items-center">  <TbCurrencyTaka /> {productDetails?.price}</h2>
            {/* old price here  */}
            <h2 className="2xl:text-xl text-xl font-medium line-through flex 2xl:gap-x-1 gap-x-[1px] items-center">  <TbCurrencyTaka />{productDetails?.regularPrice}</h2>
            {productDetails?.isDisountProduct === true && (
              <h2 className="bg-red text-xs 2xl:text-sm rounded px-2 italic text-white font-medium">{productDetails?.discountText}% OFF </h2>
            )}
          </div>
          {/* long size section start here  */}
          {/* <div className="flex flex-col 2xl:pb-2 pb-1">
            <h2 className="2xl:text-2xl text-xl font-medium 2xl:pb-1 pb-[2px]">Long</h2>
            <div className="flex items-center gap-x-4">
              <div className="myBorder flex justify-center items-center 2xl:w-10 w-8 2xl:h-10 h-8 bg-white text-lg font-medium ">52</div>
              <div className="myBorder flex justify-center items-center 2xl:w-10 w-8 2xl:h-10 h-8 bg-white text-lg font-medium ">54</div>
              <div className="myBorder flex justify-center items-center 2xl:w-10 w-8 2xl:h-10 h-8 bg-white text-lg font-medium ">56</div>
            </div>
          </div> */}

          <div className="flex md:flex-col">
            {productDetails?.productAttibuteWithValueList?.map((attribute: any, index: any) => (
              <>
                {attribute.attributeName === "Color" ? (
                  <>
                    {attribute.valueList?.length !== 0 && (
                      <h3>{attribute.attributeName}</h3>
                    )}
                    <div className="">
                      {attribute.valueList.map((value: any) => (
                        <div
                          className={`${isValueSelected(attribute.attributeName, value.valueName, value.availabeInStock) ? "product-selected" : ""}`}
                          key={value.value}
                          onClick={() => handleAttributeSelection(value, attribute.attributeName, value.valueName, value.availabeInStock)}
                          style={{ backgroundColor: attribute.attributeName === "Color" ? value.value : "", }} >
                          {/* <p>{value.valueName}</p> */}
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <div>
                    {attribute.valueList?.length !== 0 && (
                      <div className="2xl:text-2xl text-xl font-medium 2xl:pb-1 pb-[2px]">{attribute.attributeName}</div>
                    )}
                    <div className="flex 2xl:gap-x-8 gap-x-6">
                      {attribute.valueList.map((value: any) => (
                        <div className="flex flex-col space-y-1 text-center">
                          <div
                            className={`myBorder flex justify-center cursor-pointer items-center 2xl:w-10 w-9 2xl:h-10 h-9 text-lg font-medium  ${isValueSelected(attribute.attributeName, value.valueName, value.availabeInStock) ? "product-selected" : ""}`}
                            key={value.value}
                            onClick={() => handleAttributeSelection(value, attribute.attributeName, value.valueName, value.availabeInStock)}>
                            <p>{value.value}</p>
                          </div>
                          <p className="text-reselling font-semibold text-lg">{value.availabeInStock}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
            ))}
          </div>

          {/* quantity section here  */}
          <div className="flex flex-col 2xl:pb-2 pb-1">
            <h3 className="subTitle 2xl:pb-1 pb-[2px]">Quantity</h3>
            <div className="flex items-center gap-4">
              <div className="2xl:w-8 w-7 2xl:h-8 h-7 text-xl cursor-pointer flex justify-center items-center bg-white myBorder rounded"
                onClick={() => handleCountChange("decrese")}
              ><HiMinus />  </div>
              <p className="font-medium 2xl:text-2xl text-xl text-center select-none">{count}</p>
              <div className="2xl:w-8 w-7 2xl:h-8 h-7 flex cursor-pointer justify-center items-center text-xl bg-white myBorder rounded"
                onClick={() => handleCountChange("increse")}
              >
                <HiPlus />
              </div>
            </div>
          </div>
          {/* customize price button here  */}
          <div className="2xl:py-2 py-1 flex md:gap-x-4 gap-x-2 md:w-11/12 w-full justify-between">
            {/* customize button and favourite section here  */}
            <div className="">
              {!isCustomizationOn && (
                <div className="select-none">
                  <AppButton
                    text="Customize Your Price"
                    className="resellingBtn"
                    onClick={onCustomizeProductPrice}
                  />
                </div>
              )}

              {/* add to cart button  */}
              {isCustomizationOn && (
                <div className="2xl:mt-2 mt-1 2xl:p-4 p-2 space-y-2 myBorder 2xl:max-w-xl md:max-w-lg w-full select-none">
                  {/* these is sell price and earning price  */}
                  <div className="flex gap-x-2 md:gap-x-4 items-center justify-between">
                    <div className="md:font-medium md:text-base text-xs ">
                      <p className="pb-[2px]">Enter your Sell price:</p>
                      <div className="myBorder bg-white flex gap-x-1 2xl:py-1.5 py-[2px] px-1 2xl:px-3 items-center">
                        <TbCurrencyTaka />
                        <AppInput className="bg-transparent w-full border-none" type='number' value={defaultPrice} placeholder="0" onChange={handleEarning} />
                      </div>
                    </div>
                    <div className="md:font-medium md:text-base text-xs ">
                      <p className="pb-[2px]">Earning:</p>
                      <div className="myBorder bg-white flex 2xl:py-1.5 py-[2px] px-2 2xl:px-3 gap-x-1 items-center">
                        <TbCurrencyTaka />
                        <AppInput className="bg-transparent w-full border-none" type='number' isDisabled={true} value={earning} />
                      </div>
                    </div>
                  </div>

                  {/* // these is add to cart and cancel button */}
                  <div className="flex max-w-xs mx-auto gap-x-2 md:gap-x-4 justify-between items-center">
                    <div>
                      <AppButton
                        className="resellingBtn bg-red border-red py-[2px] md:py-1" text="Cancel"
                        onClick={onCustomizeProductPrice} />
                    </div>
                    <div>
                      <AppButton
                        text="Add to cart"
                        className="resellingBtn py-[2px] md:py-1"
                        onClick={handleAddToCart}
                        isSubmitting={isAddToCardLoading}
                      />
                    </div>
                  </div>

                </div>
              )}
            </div>

            <div className="myBorder h-fit 2xl:p-2 p-1.5 cursor-pointer" onClick={handleAddToFavourite}>
              {!isFavourite && (
                <BsHeart className="text-reselling text-2xl font-bold" />
              )}
              {isFavourite && (
                <BsHeartFill className="text-reselling text-2xl font-bold" />
              )}
            </div>
          </div>
          {/* description section here  */}
          <div className="flex flex-col 2xl:gap-y-1 gap-y-[2px]">
            <h2 className="subTitle">Description</h2>
            {/* description title here  */}
            <p className="max-w-xl">
              <div dangerouslySetInnerHTML={{ __html: productDetails?.fullDescription }} />
            </p>
          </div>
        </div>
      </div>
      <ResellingGetCustomerReviews productReviews={productReviews} productId={id} />
    </div>
  );
}
