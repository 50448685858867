import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { IoMdClose } from 'react-icons/io';
import { sSGetProductListAction } from '../../../../store/actions/superShop/sSProductActions';
import { useEffect, useState } from 'react';
import { IdNameResponseModel } from '../../../../models/common/IdNameModel';
import { SSGetBazarListRequest } from '../../../../models/superShopp/Address/sSBazar/sSBazarModel';
import { sSGetBazarListAction } from '../../../../store/actions/superShop/sSBazarActions';
import { PaginationPayloadModel } from '../../../../models/common/paginationModels';
import { sSGetMainCategoryListAction } from '../../../../store/actions/superShop/sSMainCategoryActions';
import { SSGetSubCategoryListRequest } from '../../../../models/superShopp/catalog/sSCategory/ssSubCategoryModel';
import { sSGetSubCategoryListAction } from '../../../../store/actions/superShop/sSSubCategoryActions';
import { SSGetProductBrandListRequest } from '../../../../models/superShopp/catalog/sSProductBrand/sSProductBrandModel';
import { sSgetProductBrandListAction } from '../../../../store/actions/superShop/sSProductBrandAction';
import { AppSelect } from '../../../common/AppSelect';
import { AppButton } from '../../../common/AppButton';
import { useNavigate } from 'react-router';
import { SSGetProductsRequest } from '../../../../models/superShopp/catalog/sSProduct/sSProductModel';

export default function SSFilterComponent() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const explore = useSelector((state: RootState) => {
        return state.exploreResponse.explore;
    });

    const closeExplore = () => {
        dispatch({ type: "CLOSE-EXPLORE" });
    };

    const filterOptions = [
        { name: "Active Products", id: 2 },
        { name: "Inactive Products", id: 3 },
        { name: "Stock Out Products", id: 4 },
    ];

    const [mainCategoryId, setMainCategoryId] = useState(0);
    const [subCategoryId, setSubCategoryId] = useState(0);
    const [productBrandId, setProductBrandId] = useState(0);
    const [sSBazarId, setSSBazarId] = useState(0);
    const [filterById, setFilterById] = useState(localStorage.getItem("shouldMaintainPage") ? parseInt(localStorage.getItem("filterById") || "0", 10) : 0);

    const bazarList = useSelector((state: RootState) => {
        return state.sSBazarListResponse.data;
    });

    var bzrIdNameModels: IdNameResponseModel[] = [];
    bazarList.items?.map((x: { id: number; name: string | undefined }) => {
        var temp = new IdNameResponseModel();
        temp.id = x.id;
        temp.name = x.name;
        bzrIdNameModels.push(temp);
    });

    useEffect(() => {
        let model = new SSGetBazarListRequest();
        model.limit = 1000;
        model.offset = 0;
        dispatch(sSGetBazarListAction(model));
    }, []);

    var mainCatList = useSelector((state: RootState) => {
        return state.sSGetMainCategoryListResponse.data;
    });

    var mCatIdNameModels: IdNameResponseModel[] = [];

    mainCatList.items?.map((x: any) => {
        var cat = new IdNameResponseModel;
        cat.id = x.id;
        cat.name = x.name;
        mCatIdNameModels.push(cat);
    })

    useEffect(() => {
        const model = new PaginationPayloadModel();
        model.limit = 10000;
        model.offset = 0;
        dispatch(sSGetMainCategoryListAction(model));
    }, []);

    const subCatList = useSelector((state: RootState) => {
        return state.sSGetSubCategoryListResponse.data;
    });

    var sCatIdNameModels: IdNameResponseModel[] = [];

    subCatList.items?.map((x: any) => {
        var cat = new IdNameResponseModel;
        cat.id = x.id;
        cat.name = x.name;
        sCatIdNameModels.push(cat);
    })

    useEffect(() => {
        const model = new SSGetSubCategoryListRequest();
        model.limit = 10000;
        model.offset = 0;
        model.sSMainCategoryId = mainCategoryId;
        dispatch(sSGetSubCategoryListAction(model));
    }, [mainCategoryId]);

    const handleMainCategory = (mainCatId: number) => {
        setMainCategoryId(mainCatId);
        setSubCategoryId(0);
    }

    const handleSubCategory = (subCatId: number) => {
        setSubCategoryId(subCatId);
    }

    const handleBazarIdChange = (bId: any) => {
        setSSBazarId(bId);
    }

    const handleProductBrand = (pbId: any) => {
        setProductBrandId(pbId);
    }

    const handleOptionChange = (value: any) => {
        setFilterById(value);
    };

    const handleReset = () => {
        setMainCategoryId(0);
        setSubCategoryId(0);
        setProductBrandId(0);
        setSSBazarId(0);
        setProductBrandId(0)
        setFilterById(0)
    };

    const handleFilter = () => {
        navigate(`/super-shop/${sSBazarId}?mainCat=${mainCategoryId}&subCat=${subCategoryId}&brand=${productBrandId}&filter=${filterById}`);
        var request = new SSGetProductsRequest();
        request.filterBy = filterById;
        request.bazarId = sSBazarId;
        request.mainCategoryId = mainCategoryId;
        request.subCategoryId = subCategoryId;
        request.productBrandId = productBrandId;
        request.filterBy = filterById;
        dispatch(sSGetProductListAction(request));
        closeExplore();
    };

    return (
        <div className={explore ? "drawerOpen" : "drawerHidden"}>
            <div className="drawerContainer md:w-1/4 w-4/5 h-screen px-6 2xl:px-12 2xl:space-y-6 space-y-5">
                <div className="pt-6 pr-6 text-affiliate flex justify-end">
                    <IoMdClose onClick={closeExplore} className="cursor-pointer  2xl:text-3xl text-2xl font-semibold" />
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-1 py-2 gap-2 lg:gap-8">
                    <div>
                        <AppButton
                            text='Reset'
                            onClick={handleReset}
                        />
                    </div>
                    <div>
                        <AppSelect
                            defaultValue="Select Bazar"
                            value={sSBazarId as number}
                            data={bzrIdNameModels}
                            onChange={handleBazarIdChange}
                        />
                    </div>
                    {sSBazarId != 0 && (
                        <>
                            <div>
                                <AppSelect
                                    defaultValue="Select Main Category"
                                    value={mainCategoryId}
                                    data={mCatIdNameModels}
                                    onChange={handleMainCategory}
                                />
                            </div>
                            {mainCategoryId !== 0 && (
                                <div>
                                    <AppSelect
                                        defaultValue="Select Sub Category"
                                        value={subCategoryId}
                                        data={sCatIdNameModels}
                                        onChange={handleSubCategory}
                                    />
                                </div>
                            )}
                            <div>
                                <AppSelect
                                    defaultValue="Filter Products"
                                    data={filterOptions}
                                    value={filterById}
                                    onChange={handleOptionChange}
                                />
                            </div>
                        </>
                    )}

                    <div className='flex justify-end'>
                        <AppButton
                            text='Submit'
                            onClick={handleFilter}
                        />
                    </div>
                </div>

            </div>
        </div>
    )
}
