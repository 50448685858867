import { Route, Routes } from "react-router-dom";
import AdminCreateBechteChaiMainCategory from "../../components/admin/bechteChai/categories/AdminCreateBechteChaiMainCategory";
import AdminCreateBechteChaiSubCategory from "../../components/admin/bechteChai/categories/AdminCreateBechteChaiSubCategory";
import AdminGetBechteChaiMainCategories from "../../components/admin/bechteChai/categories/AdminGetBechteChaiMainCategories";
import AdminGetBechteChaiSubCategories from "../../components/admin/bechteChai/categories/AdminGetBechteChaiSubCategories";
import AdminUpdateBechteChaiMainCategory from "../../components/admin/bechteChai/categories/AdminUpdateBechteChaiMainCategory";
import AdminUpdateBechteChaiSubCategory from "../../components/admin/bechteChai/categories/AdminUpdateBechteChaiSubCategory";
import BechteChaiGetProducts from "../../components/admin/bechteChai/products/BechteChaiGetProducts";
import BechteChaiCreateProduct  from "../../components/admin/bechteChai/products/BechteChaiCreateProduct";
import BechteChaiUpdateProduct from "../../components/admin/bechteChai/products/BechteChaiUpdateProduct";
import BechteChaiGetProductById from "../../components/admin/bechteChai/products/BechteChaiGetProductById";


export default function BechteChaiRoute() {
    return (
        <>
            <Routes>
                <Route index element={<AdminGetBechteChaiMainCategories />} />

                <Route path="/main-categories" element={<AdminGetBechteChaiMainCategories />} />
                <Route path="/main-category/add" element={<AdminCreateBechteChaiMainCategory />} />
                <Route path="/main-category/update/:id" element={<AdminUpdateBechteChaiMainCategory />} />

                <Route path="/sub-categories" element={<AdminGetBechteChaiSubCategories />} />
                <Route path="/sub-category/add" element={<AdminCreateBechteChaiSubCategory />} />
                <Route path="/sub-category/update/:id" element={<AdminUpdateBechteChaiSubCategory />} />

                <Route path="/products" element={<BechteChaiGetProducts />} />
                <Route path="/product/add" element={<BechteChaiCreateProduct />} />
                <Route path="/product/update/:id" element={<BechteChaiUpdateProduct />} />
                <Route path="/product/view/:id" element={<BechteChaiGetProductById />} />
            </Routes>
        </>
    );
}