import { AlertColor } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "../../../../store";
import { getUserProfileAction } from "../../../../store/actions/auth/user/userActions";
import { PaginationPayloadModel } from "../../../../models/common/paginationModels";
import { getDoctorAction } from "../../../../store/actions/importantNumbers/doctorNumbersActions";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import { AppButton } from "../../../common/AppButton";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { AppDeleteModal } from "../../../common/AppDeleteModal";
import { AppPagination } from "../../../common/AppPagination";
import { BiSearchAlt2 } from "react-icons/bi";
import { AppSearchBox } from "../../../common/AppSearchBox";
import { AppLoader } from "../../../common/AppLoader";
import { AppSelect } from "../../../common/AppSelect";
import {
  GetDoctorsNumberModel,
  GetHospitalNumberModel,
} from "../../../../models/importantNumber/ImportantNumberModel";
import { IdNameResponseModel } from "../../../../models/common/IdNameModel";
import { areaListByDistrictId } from "../../../../store/actions/auth/address/areaActions";
import { divisionList } from "../../../../store/actions/auth/address/divisionActions";
import { districtListByDivisionId as districtListByDivisionId } from "../../../../store/actions/auth/address/districtActions";
import { getHospitalAction } from "../../../../store/actions/importantNumbers/hospitalActions";
import { deleteDoctorService } from "../../../../httpService/importantNumbers/doctorNumbersServices";
import { reactLocalStorage } from "reactjs-localstorage";
import { isAdmin } from "../../../../common/common";

export default function INGetDoctorNumbers() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  var pModel = new GetDoctorsNumberModel();

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [divisionId, setDivisionId] = useState(0);
  const [districtId, setDistrictId] = useState(0);
  const [areaId, setAreaId] = useState(0);
  const [hospitalsNumbersId, setHospitalsNumbersId] = useState(0);
  const [divisionError, setDivisionError] = useState("");
  const [districtError, setDistrictError] = useState("");
  const [areaError, setAreaError] = useState("");
  const [hospitalsNumbersIdError, setHospitalsNumbersIdError] = useState("");

  const userProfile = useSelector((state: RootState) => {
    return state.getUserProfileResponse.data;
  });

  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);

  const doctors = useSelector((state: RootState) => {
    return state.getDoctorNumberResponse.data;
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        var pModel = new GetDoctorsNumberModel();
        pModel.searchQuery = searchQuery;
        pModel.limit = 10;
        pModel.offset = 0;
        pModel.divisionId = userProfile.divisionId ? userProfile.divisionId : 0;
        pModel.districtId = userProfile.districtId ? userProfile.districtId : 0;
        pModel.areaId = userProfile.areaId ? userProfile.areaId : 0;
        setDivisionId(userProfile.divisionId ? userProfile.divisionId : 0);
        setDistrictId(userProfile.districtId ? userProfile.districtId : 0);
        setAreaId(userProfile.areaId ? userProfile.areaId : 0);
        dispatch(getDoctorAction(pModel));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    dispatch(divisionList());
  }, [dispatch]);

  useEffect(() => {
    if (divisionId !== 0) {
      dispatch(districtListByDivisionId(divisionId));
    }
  }, [divisionId, dispatch]);

  useEffect(() => {
    if (districtId !== 0) {
      dispatch(areaListByDistrictId(districtId));
    }
  }, [districtId, dispatch]);

  useEffect(() => {
    if (areaId !== 0) {
      var pModel = new GetHospitalNumberModel();
      pModel.areaId = areaId;
      dispatch(getHospitalAction(pModel));
    }
  }, [areaId, dispatch]);

  const divisions = useSelector((state: RootState) => {
    return state.divisionListResponse.data;
  });
  var divisionIdNameModels: IdNameResponseModel[] = [];

  divisions?.map((x: any) => {
    var division = new IdNameResponseModel();
    division.id = x.id;
    division.name = x.name;
    divisionIdNameModels.push(division);
  });

  const districts = useSelector((state: RootState) => {
    return state.districtListResponse.data;
  });
  var districtsIdNameModels: IdNameResponseModel[] = [];

  districts?.map((x: any) => {
    var district = new IdNameResponseModel();
    district.id = x.id;
    district.name = x.name;
    districtsIdNameModels.push(district);
  });

  const thanas = useSelector((state: RootState) => {
    return state.areaListResponse.data;
  });
  var areaIdNameModels: IdNameResponseModel[] = [];

  thanas?.map((x: any) => {
    var area = new IdNameResponseModel();
    area.id = x.id;
    area.name = x.name;
    areaIdNameModels.push(area);
  });

  const hospitals = useSelector((state: RootState) => {
    return state.getHospitalResponse.data;
  });
  var hospitalIdNameModels: IdNameResponseModel[] = [];

  hospitals?.items?.map((x: any) => {
    var hospital = new IdNameResponseModel();
    hospital.id = x.id;
    hospital.name = x.name;
    hospitalIdNameModels.push(hospital);
  });

  const handleOnRowChange = (payload: PaginationPayloadModel) => {
    const fetchData = async () => {
      try {
        var pModel = new GetDoctorsNumberModel();
        pModel.limit = payload.limit;
        pModel.offset = 0;
        await dispatch(getDoctorAction(pModel));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();
  };

  const handleOnPageChange = (payload: PaginationPayloadModel) => {
    const fetchData = async () => {
      try {
        var pModel = new GetDoctorsNumberModel();
        pModel.limit = payload.limit;
        pModel.offset = payload.offset;
        await dispatch(getDoctorAction(pModel));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  };

  const handleSearchQuery = debounce((searchQuery: string) => {
    const fetchData = async () => {
      try {
        setSearchQuery(searchQuery);
        var pModel = new GetDoctorsNumberModel();
        pModel.limit = 100000;
        pModel.offset = 0;
        pModel.divisionId = divisionId;
        pModel.districtId = districtId;
        pModel.areaId = areaId;
        pModel.hospitalsNumbersId = hospitalsNumbersId;
        pModel.searchQuery = searchQuery;

        dispatch(getDoctorAction(pModel));
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, 500);

  const [bulkIds, setBulkIds] = useState([]);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  const openDeleteModal = (id: any) => {
    setOpenDeleteModal(true);

    let ids: any = [];
    ids.push(id);

    setBulkIds(ids);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleDelete = () => {
    setSnackBarOn(false);
    deleteDoctorService(bulkIds)
      .then(() => {
        setSnackBarOn(true);
        setsnackBarMessage("Successfully Deleted");
        setSnackBarMessageType("success");
        closeDeleteModal();
        dispatch(getDoctorAction(pModel));
      })
      .catch((error: any) => {
        setSnackBarOn(true);
        setsnackBarMessage("Something went wrong");
        setSnackBarMessageType("error");
      });
  };
  const handleDivision = (id: number) => {
    setDivisionError("");
    setDivisionId(id);
    setDistrictId(0);
    setAreaId(0);
    setHospitalsNumbersId(0);
    var pModel = new GetDoctorsNumberModel();
    pModel.limit = 10;
    pModel.offset = 0;
    pModel.divisionId = id;
    pModel.districtId = 0;
    pModel.areaId = 0;
    pModel.hospitalsNumbersId = 0;

    pModel.searchQuery = searchQuery;
    dispatch(getDoctorAction(pModel));
  };

  const handleDistrict = (id: number) => {
    setDistrictError("");
    setDistrictId(id);
    setAreaId(0);
    setHospitalsNumbersId(0);
    var pModel = new GetDoctorsNumberModel();
    pModel.limit = 10;
    pModel.offset = 0;
    pModel.divisionId = divisionId;
    pModel.districtId = id;
    pModel.areaId = 0;
    pModel.hospitalsNumbersId = 0;

    pModel.searchQuery = searchQuery;
    dispatch(getDoctorAction(pModel));
  };

  const handleArea = (id: number) => {
    setAreaError("");
    setAreaId(id);
    setHospitalsNumbersId(0);
    var pModel = new GetDoctorsNumberModel();
    pModel.limit = 10;
    pModel.offset = 0;
    pModel.divisionId = divisionId;
    pModel.districtId = districtId;
    pModel.areaId = id;
    pModel.hospitalsNumbersId = 0;

    pModel.searchQuery = searchQuery;
    dispatch(getDoctorAction(pModel));
  };

  const handleHospital = (id: number) => {
    setHospitalsNumbersIdError("");
    setHospitalsNumbersId(id);
    var pModel = new GetDoctorsNumberModel();
    pModel.limit = 10;
    pModel.offset = 0;
    pModel.divisionId = divisionId;
    pModel.districtId = districtId;
    pModel.areaId = areaId;
    pModel.hospitalsNumbersId = id;
    pModel.searchQuery = searchQuery;
    dispatch(getDoctorAction(pModel));
  };
  const navigateToDoctorNumberUpdate = (id: any) => {
    if (isAdmin(reactLocalStorage.get("RoleName"))) {
      navigate(`/admin/important-numbers/doctor-number/update/${id}`);
    } else {
      navigate(`/important-numbers/doctor-number/update/${id}`);
    }
  };
  const navigateToImportantNumbers = () => {
    if (isAdmin(reactLocalStorage.get("RoleName"))) {
      navigate(`/admin/important-numbers`);
    } else {
      navigate(`/important-numbers`);
    }
  };

  return (
    <div className="container mx-auto px-2 py-2">
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      {/* these is header part  */}
      <div className="flex flex-col items-center lg:flex-row">
        <div className="flex w-full items-center justify-between">
          <AppButton
            className="gradientBtn"
            text="Back"
            onClick={navigateToImportantNumbers}
          />

          <h2 className="title hidden w-full text-center text-cyan-600 lg:block">
            Doctor's Information ({doctors?.size})
          </h2>
          {isAdmin(reactLocalStorage.get("RoleName")) ? (
            <Link
              className="flex justify-end lg:w-64"
              to="/admin/important-numbers/doctor-number/create"
            >
              <AppButton
                className="gradientBtn"
                text="Add Doctor information"
                onClick={() => {}}
              />
            </Link>
          ) : (
            <Link
              className="flex justify-end lg:w-64"
              to="/important-numbers/doctor-number/create"
            >
              <AppButton
                className="gradientBtn"
                text="Add Doctor information"
                onClick={() => {}}
              />
            </Link>
          )}
        </div>
        <h2 className="title w-full text-center text-cyan-600 lg:hidden">
          Doctor's Information ({doctors?.size})
        </h2>
      </div>
      {/* these is header select part  */}
      <div className="grid grid-cols-3 gap-2 py-2 lg:grid-cols-5 lg:py-4">
        <div className="flex cursor-pointer items-center rounded border border-grey p-1 shadow-sm md:px-1.5 lg:w-full 2xl:p-2">
          <BiSearchAlt2 className="cursor-pointer text-xl font-bold text-cyan-600 md:text-2xl 2xl:text-3xl" />
          <AppSearchBox
            onChange={handleSearchQuery}
            placeholder="Search here"
            className="w-full border-none text-xs md:text-base lg:px-2"
          />
        </div>
        <AppSelect
          defaultValue="Select Division"
          data={divisionIdNameModels}
          value={divisionId}
          onChange={handleDivision}
          errorMessage={divisionError}
        />
        {divisionId !== 0 && (
          <AppSelect
            defaultValue="Select District"
            data={districtsIdNameModels}
            value={districtId}
            onChange={handleDistrict}
            errorMessage={districtError}
          />
        )}
        {divisionId !== 0 && districtId !== 0 && (
          <AppSelect
            defaultValue="Select Thana"
            data={areaIdNameModels}
            value={areaId}
            onChange={handleArea}
            errorMessage={areaError}
          />
        )}
        {divisionId !== 0 && districtId !== 0 && areaId !== 0 && (
          <AppSelect
            defaultValue="Select Hospital"
            data={hospitalIdNameModels}
            value={hospitalsNumbersId}
            onChange={handleHospital}
            errorMessage={hospitalsNumbersIdError}
          />
        )}
      </div>

      {isLoading ? (
        <AppLoader status={true} />
      ) : (
        <>
          {doctors?.items ? (
            <div className="w-full">
              {/* these is header part for desktop */}
              <div className="myBorder myGradient hidden w-full lg:flex">
                <div className="tableHead w-[16%]">Name</div>
                <div className="tableHead w-[14%]">Expertise</div>
                <div className="tableHead w-[14%]">Phone (First)</div>
                <div className="tableHead w-[14%]">Phone (Second)</div>
                <div className="tableHead w-[10%]">Email</div>
                <div className="tableHead w-[14%]">Hospital</div>
                <div className="tableHead w-[14%]">Address</div>
                <div className="tableHead w-[9%]">Update</div>
                <div className="tableHead w-[9%] border-none">Remove</div>
              </div>
              {/* these is middle part for desktop */}
              <div className="hidden h-[58dvh] flex-col gap-1 overflow-y-auto py-1.5 lg:flex 2xl:h-[62dvh]">
                {doctors?.items?.map((doctor: any) => (
                  <div
                    key={doctor?.id}
                    id={doctor?.id}
                    className="myBorder flex w-full items-center"
                  >
                    <div className="tableRow textOverflowHidden w-[16%] justify-start">
                      {doctor?.name}
                    </div>
                    <div className="tableRow w-[14%]">{doctor?.experties}</div>
                    <div className="tableRow w-[14%]">
                      {doctor?.phoneNumberFirst}
                    </div>
                    <div className="tableRow w-[14%]">
                      {doctor?.phoneNumberSecond}
                    </div>
                    <div className="tableRow w-[10%]">{doctor?.email}</div>
                    <div className="tableRow w-[14%]">
                      {doctor?.hospitalsNumber.name}
                    </div>
                    <div className="tableRow w-[14%]">{doctor?.address}</div>
                    <div className="tableRow w-[9%]">
                      <AppButton
                        onClick={() => navigateToDoctorNumberUpdate(doctor?.id)}
                        className="gradientBtn  py-0.5 text-sm lg:py-1"
                        text="Update"
                      />
                    </div>
                    <div className="tableRow w-[9%] border-none">
                      <AppButton
                        className="myBtn border-red bg-red py-0.5 text-sm lg:py-1"
                        text="Remove"
                        onClick={() => openDeleteModal(doctor?.id)}
                      />
                    </div>
                  </div>
                ))}
              </div>
              {/* these is middle part for mobile */}
              <div className="flex h-[78dvh] flex-col gap-2 overflow-y-auto lg:hidden">
                {doctors?.items?.map((doctor?: any) => (
                  <div
                    key={doctor?.id}
                    className="myBorder flex w-full flex-col p-1 text-xs"
                  >
                    <div className="grid grid-cols-2">
                      <div className="flex items-center gap-0.5">
                        <span className="font-medium">Name:</span>{" "}
                        <span>{doctor?.name}</span>
                      </div>
                      <div className="flex items-center gap-0.5">
                        <span className="font-medium">Expertise:</span>{" "}
                        <span>{doctor?.experties}</span>
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="flex items-center gap-0.5">
                        <span className="font-medium">Phone 1:</span>{" "}
                        <span>{doctor?.phoneNumberFirst}</span>
                      </div>
                      <div className="flex items-center gap-0.5">
                        <span className="font-medium">Phone 2:</span>{" "}
                        <span> {doctor?.phoneNumberSecond}</span>
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="flex items-center gap-0.5 overflow-hidden">
                        <span className="font-medium">Email:</span>{" "}
                        <span>{doctor?.email}</span>
                      </div>

                      <div className="flex items-center gap-0.5">
                        <span className="font-medium">Hospital:</span>{" "}
                        <span>{doctor?.hospitalsNumbersId}</span>
                      </div>
                    </div>
                    <div className="flex w-full items-center gap-0.5">
                      <span className="font-medium">Address:</span>{" "}
                      <span>{doctor?.address}</span>
                    </div>
                    <div className="flex w-full items-center justify-center gap-2 lg:gap-0.5">
                      <AppButton
                        onClick={() => navigateToDoctorNumberUpdate(doctor?.id)}
                        className="gradientBtn  py-0.5 text-sm lg:py-1"
                        text="Update"
                      />
                      <AppButton
                        className="myBtn border-red bg-red py-0.5 text-sm lg:py-1"
                        text="Remove"
                        onClick={() => openDeleteModal(doctor?.id)}
                      />
                    </div>
                  </div>
                ))}
              </div>
              {/* these is footer part  */}
              <div className="fixed bottom-1 flex w-full items-center bg-white py-1 lg:static">
                <AppPagination
                  curPage={1}
                  limit={10}
                  offset={0}
                  onPageChange={handleOnPageChange}
                  onRowChange={handleOnRowChange}
                  size={doctors?.size}
                />
              </div>
            </div>
          ) : (
            <div className="flex h-[50dvh] w-full items-center justify-center text-3xl font-medium text-textBlack/90">
              <span>Doctor Not found</span>
            </div>
          )}
        </>
      )}
      {isOpenDeleteModal && (
        <AppDeleteModal
          text="Are you sure to Delete?"
          handleDelete={handleDelete}
          closeDeleteModal={closeDeleteModal}
        />
      )}
    </div>
  );
}
