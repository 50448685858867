import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "../../../../store";
import { sSGetBazarListAction } from "../../../../store/actions/superShop/sSBazarActions";
import { AppLoader } from "../../../common/AppLoader";
import { AppSearchBox } from "../../../common/AppSearchBox";
import { IdNameResponseModel } from "../../../../models/common/IdNameModel";
import { areaListByDistrictId } from "../../../../store/actions/auth/address/areaActions";
import { divisionList } from "../../../../store/actions/auth/address/divisionActions";
import { getUserProfileAction } from "../../../../store/actions/auth/user/userActions";
import { districtListByDivisionId } from "../../../../store/actions/auth/address/districtActions";
import { SSGetBazarListRequest } from "../../../../models/superShopp/Address/sSBazar/sSBazarModel";

export default function SSHomeComponent() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  var model = new SSGetBazarListRequest();

  const bazarList = useSelector((state: RootState) => {
    return state.sSBazarListResponse.data;
  });

  const userProfile = useSelector((state: RootState) => {
    return state.getUserProfileResponse.data;
  });

  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userProfile?.divisionId !== 0) {
          var pModel = new SSGetBazarListRequest();
          pModel.limit = 100000;
          pModel.offset = 0;
          pModel.divisionId = userProfile.divisionId ? userProfile.divisionId : 0;
          pModel.districtId = userProfile.districtId ? userProfile.districtId : 0;
          pModel.areaId = userProfile.areaId ? userProfile.areaId : 0;
          setDivisionId(userProfile.divisionId ? userProfile.divisionId : 0);
          setDistrictId(userProfile.districtId ? userProfile.districtId : 0);
          setAreaId(userProfile.areaId ? userProfile.areaId : 0);
          await dispatch(sSGetBazarListAction(pModel));
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };
    if (userProfile?.divisionId) {
      fetchData();
    }
  }, [userProfile]);


  const onSearch = (searchQuery: string) => {
    model.searchQuery = searchQuery;
    dispatch(sSGetBazarListAction(model));
  };

  const [divisionId, setDivisionId] = useState(0);
  const [districtId, setDistrictId] = useState(0);
  const [areaId, setAreaId] = useState(0);

  const [divisionError, setDivisionError] = useState("");
  const [bDistrictError, setBDistrictError] = useState("");
  const [bAreaError, setBAreaError] = useState("");

  const divisions = useSelector((state: RootState) => {
    return state.divisionListResponse.data;
  });
  var divisionIdNameModels: IdNameResponseModel[] = [];

  divisions?.map((x: any) => {
    var division = new IdNameResponseModel();
    division.id = x.id;
    division.name = x.name;
    divisionIdNameModels.push(division);
  });

  const districts = useSelector((state: RootState) => {
    return state.districtListResponse.data;
  });
  var districtsIdNameModels: IdNameResponseModel[] = [];

  districts?.map((x: any) => {
    var district = new IdNameResponseModel();
    district.id = x.id;
    district.name = x.name;
    districtsIdNameModels.push(district);
  });

  const thanas = useSelector((state: RootState) => {
    return state.areaListResponse.data;
  });
  var areaIdNameModels: IdNameResponseModel[] = [];

  thanas?.map((x: any) => {
    var area = new IdNameResponseModel();
    area.id = x.id;
    area.name = x.name;
    areaIdNameModels.push(area);
  });

  useEffect(() => {
    dispatch(divisionList());
  }, [userProfile]);

  useEffect(() => {
    if (divisionId != 0) {
      dispatch(districtListByDivisionId(divisionId));
    }
  }, [divisionId]);

  useEffect(() => {
    if (districtId != 0) {
      dispatch(areaListByDistrictId(districtId));
    }
  }, [districtId]);

  const handleDivision = (id: number) => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setDivisionError("");
        setDivisionId(id);
        setDistrictId(0)
        setAreaId(0)
        var pModel = new SSGetBazarListRequest();
        pModel.limit = 100000;
        pModel.offset = 0;
        pModel.divisionId = id;
        pModel.districtId = 0;
        pModel.areaId = 0;
        await dispatch(sSGetBazarListAction(pModel));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  };

  const handleDistrict = (id: number) => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setBDistrictError("");
        setDistrictId(id);
        setAreaId(0)
        var pModel = new SSGetBazarListRequest();
        pModel.limit = 100000;
        pModel.offset = 0;
        pModel.divisionId = divisionId;
        pModel.districtId = id;
        pModel.areaId = 0;
        await dispatch(sSGetBazarListAction(pModel));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  };

  const handleArea = (id: number) => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setBAreaError("");
        setAreaId(id);
        var pModel = new SSGetBazarListRequest();
        pModel.limit = 100000;
        pModel.offset = 0;
        pModel.divisionId = divisionId;
        pModel.districtId = districtId;
        pModel.areaId = id;
        await dispatch(sSGetBazarListAction(pModel));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  };

  const handleNavigateProductPage = (id: any) => {
    navigate(`/super-shop/${id}`);
  };

  if (isLoading) {
    return (
      <div className="container h-screen flex items-center justify-center mx-auto px-2 md:px-0 pb-12">
        <AppLoader status={true} />
      </div>
    );
  }

  return (
    <div className="container mx-auto items-center 2xl:pt-24 lg:pt-20 pt-14  px-2 md:px-16 lg:px-28 ">
      <h2 className="title text-center">Bazer({bazarList?.size})</h2>
      <div className="flex flex-col  lg:flex-row lg:items-center gap-2 lg:gap-6 px-2 pt-6 2xl:pt-10 lg:pt-8 md:px-32 2xl:px-80 xl:px-72 lg:px-52">
        <AppSearchBox className="myInput cursor-pointer border border-primary" onChange={onSearch} placeholder="Search Here" />
        {/* <div className='w-full lg:w-[80%] flex items-center gap-1 lg:gap-4 lg:justify-between'>
          <div className='w-1/3'>
            <AppSelect
              defaultValue="Select Division"
              data={divisionIdNameModels}
              value={divisionId}
              onChange={handleDivision}
              errorMessage={divisionError}
            />
          </div>
          <div className='w-1/3'>
            {divisionId != 0 && (
              <AppSelect
                defaultValue="Select District"
                data={districtsIdNameModels}
                value={districtId}
                onChange={handleDistrict}
                errorMessage={bDistrictError}
              />
            )}
          </div>
          <div className='w-1/3'>
            {divisionId != 0 && districtId != 0 && (
              <AppSelect
                defaultValue="Select Thana"
                data={areaIdNameModels}
                value={areaId}
                onChange={handleArea}
                errorMessage={bAreaError}
              />
            )}
          </div>
        </div> */}
      </div>

      <>
        {bazarList.items ? (
          <div className="w-full pt-4 container mx-auto items-center 2xl:py-3 lg:py-2  px-2 md:px-32 2xl:px-80 xl:px-72 lg:px-52">
            <div className="hidden cursor-pointer lg:flex h-[54dvh] 2xl:h-[49dvh] overflow-y-auto flex-col gap-1 py-1.5">
              {bazarList?.items?.map((item: any) => (
                <div key={item?.id} onClick={() => handleNavigateProductPage(item.id)} className="myBorder flex w-full items-center">
                  <div className="centerDiv justify-start border border-primary pl-2 py-1.5 h-full whitespace-nowrap overflow-hidden text-ellipsis w-[100%]">
                    {item?.name} {item?.bnName ? `(${item?.bnName})` : ""}
                  </div>
                </div>
              ))}
            </div>
            <div className="h-[60dvh] lg:hidden overflow-y-auto cursor-pointer flex flex-col gap-2">
              {bazarList?.items?.map((item?: any) => (
                <div key={item?.id} onClick={() => handleNavigateProductPage(item.id)} className="myBorder flex flex-col w-full p-2">
                  <div className="flex items-center justify-between w-full px-4">
                    <div className="flex items-center gap-1">
                      <span className="font-medium">{item?.name} {item?.bnName ? `(${item?.bnName})` : ""}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="w-full h-[50dvh] flex justify-center items-center text-3xl text-textBlack/90 font-medium">
            <span>No Bazar found</span>
          </div>
        )}
      </>

    </div>
  );
}
