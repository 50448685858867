export const GET_AFFILIATE_TOTAL_CART_ITEM = "GET_AFFILIATE_TOTAL_CART_ITEM";

export const GET_AFFILIATE_ALL_CART_ITEMS = "GET_AFFILIATE_ALL_CART_ITEMS";
export const GET_AFFILIATE_ALL_FAVOURITE_ITEMS = "GET_AFFILIATE_ALL_FAVOURITE_ITEMS";
export const GET_AFFILIATE_IS_ALREADY_FAVOURITE = "GET_AFFILIATE_IS_ALREADY_FAVOURITE";

export const INCRESE_DECRESE_AFFILIATE_CART_ITEM = "INCRESE_DECRESE_AFFILIATE_CART_ITEM";

export const REMOVE_SINGLE_OR_LIST_AFFILIATE_CART_ITEM = "REMOVE_SINGLE_OR_LIST_AFFILIATE_CART_ITEM";

export const GET_AFFILIATE_CART_LIST_ADMIN_LIST = 'GET_AFFILIATE_CART_LIST_ADMIN_LIST';

export const GET_AFFILIATE_CART_ITEMS_ADMIN_LIST = 'GET_AFFILIATE_CART_ITEMS_ADMIN_LIST';

export const GET_AFFILIATE_TOTAL_SHIPPING_CHARGE = 'GET_AFFILIATE_CART_ITEMS_ADMIN_LIST';
