
import { GetShippingCharge, IsShippingChargeAvailable, ProductDisabled, ProductPublish } from "../../../httpService/shippingCharge/shippingCharge";
import { GET_IS_AVAILABLE, GET_PRODUCT_DISABLED, GET_PRODUCT_PUBLISH, GET_SHIPPING_CHARGE } from "../../constants/shippingCharge/shippingCharge";


const getShippingCharge = () => async (dispatch: any) => {
    var data = GetShippingCharge();
    const pload = (await data).data;
    dispatch({ type: GET_SHIPPING_CHARGE, payload: pload });
};

const IsShippingChargeAvailableShipping = () => async (dispatch: any) => {
    var data = IsShippingChargeAvailable();
    const pload = (await data).data;
    dispatch({ type: GET_IS_AVAILABLE, payload: pload });
};

const ProductPublishAction = (payload:any) => async (dispatch: any) => {
    var data = ProductPublish(payload);
    const pload = (await data).data;
    dispatch({ type: GET_PRODUCT_PUBLISH, payload: pload });
};


const ProductDisabledAction = (payload:any) => async (dispatch: any) => {
    var data = ProductDisabled(payload);
    const pload = (await data).data;
    dispatch({ type: GET_PRODUCT_DISABLED, payload: pload });
};

export {
    getShippingCharge,
    IsShippingChargeAvailableShipping,
    ProductPublishAction,
    ProductDisabledAction
};