import { PaginationPayloadModel } from '../../models/common/paginationModels';
import { DELETE, GET, POST, PUT } from '../service'

export const createMagazineCategoryService = (model: any) => {
  return POST(
    `/api/MagazineCategory/CreateCategory`, model
  );
};

export const getMagazineCategoryListService = (payload?: PaginationPayloadModel) => {
  return GET(`/api/MagazineCategory/GetCategories?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}`);
};


export const getMagazineCategoryByIdService = (id?: any) => {
  return GET(`/api/MagazineCategory/GetCategoryPublicById?Id=${id}`);
};

export const removeMagazineCategoryByIdService = (data: any) => {
  return DELETE(`/api/MagazineCategory/RemoveCategory`, {
    data: { "ids": [data] },
  });
};

export const updateMagazineCategoryByIdService = (data: any) => {
  return PUT(`/api/MagazineCategory/UpdateCategory`, data);
};

export const getMagazineCategoryListPublicService = () => {
  return GET(`/api/MagazineCategory/GetCategoryListPublic`);
};



