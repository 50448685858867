import { DELETE, GET, POST, PUT } from '../service'

export const createMagazineCommentOrReplyService = (model: any) => {
    return POST(
        `/api/MagazineComment/SubmitCommentOrReply`, model
    );
};

export const getMagazineCommentListService = (payload: any) => {
    return GET(`/api/MagazineComment/GetCommentList?SearchQuery=${payload?.SearchQuery}&MagazinePostId=${payload?.MagazinePostId}`);
};

export const getMagazineAdminCommentListService = () => {
    return GET(`/api/MagazineComment/GetAdminCommentList`);
};

export const updateMagazineCommentService = (payload?: any) => {
    return PUT(`/api/MagazineComment/UpdateComment`, payload)
};

export const deleteMagazineCommentService = (data: any) => {
    return DELETE(`/api/MagazineComment/RemoveComment`, {
        data: { "id": data },
    });
};

export const approveMagazineCommentService = (payload?: any) => {
    return PUT(`/api/MagazineComment/ApproveComment`, payload)
};

export const approveDeclineMagazineCommentService = (payload?: any) => {
    return PUT(`/api/MagazineComment/ApproveDeclineComment`, payload)
};

export const getGetIsAlreadyCommentedtService = () => {
    return GET(`/api/MagazineComment/GetIsAlreadyCommented`);
};