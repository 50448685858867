import { useEffect, useMemo, useState } from "react";
import { ResellingProductBox } from "./ResellingProductBox";
import {
  ShoppingCartFavouriteModel,
} from "../../../models/shoppingCart/resellingCartModels";
import {
  getFavouriteItemListAction,
} from "../../../store/actions/cart/resellingCartActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";

export default function ResellingGetFavouriteItems() {

  const dispatch = useDispatch();

  const favouriteItemsSignIn = useSelector((state: RootState) => {
    return state.getFavouriteItemResponse.data;
  });

  const favItemsMemo = useMemo(() => {
    return favouriteItemsSignIn as ShoppingCartFavouriteModel[];
  }, [favouriteItemsSignIn]);

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(getFavouriteItemListAction(searchQuery));
  }, [dispatch, searchQuery]);

  const handleAfterAddToCart = () => { }

  return (
    <div className="container mx-auto md:pt-6 lg:pb-12 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28">
      <h3 className="title pb-4 text-center md:pb-8 px-4 md:px-0">Favorites</h3>
      <div className="productContainer">
        {favItemsMemo &&
          favItemsMemo?.map((x: any, index: any) => {
            return (
              <ResellingProductBox key={index}
                id={x.id}
                name={x.name}
                shortDescription={x.shortDescription}
                bannerPath={x.bannerPath}
                discount={x.discount}
                isBestSeller={x.isBestSeller}
                price={x.price}
                oldPrice={x.oldPrice}
                rating={x.rating}
                totalSold={x.totalSold}
                isDisountProduct={x.isDisountProduct}
                discountText={x.discountText}
                afterAddToCart={handleAfterAddToCart}
                totalReview={x.totalReview}
                totalView={x.totalView}
              />
            );
          })}
      </div>
    </div>
  );
}
