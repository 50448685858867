

import { GET_DATA_OFFER_INVOICES, GET_DATA_OFFER_INVOICE_BY_ID, GET_ORDER_INVOICES, GET_ORDER_INVOICE_BY_ID } from "../../constants/invoices/orderInvoicesConstant";

export function getInvoicesReducer(state = { data: [] }, action: any) {
	switch (action.type) {
		case GET_ORDER_INVOICES:
			return { loading: false, data: action.payload };		
		case GET_ORDER_INVOICE_BY_ID:
			return { loading: false, data: action.payload };
		case GET_DATA_OFFER_INVOICES:
			return { loading: false, data: action.payload };		
		case GET_DATA_OFFER_INVOICE_BY_ID:
			return { loading: false, data: action.payload };
		default:
			return state;
	}
}
