import { DELETE, GET, POST, PUT } from "../service";

export const divisionListService = (payload: any) => {
  if (payload) {
    return GET(
      `/api/Division/GetDivisions?searchQuery=${payload?.searchQuery}`
    );
  } else {
    return GET(`/api/Division/GetDivisions`);
  }
};

export const addDivisionService = (data: any) => {
  return POST("/api/Division/CreateDivision", data);
};

export const updateDivisionService = (data: any) => {
  return PUT(`/api/Division/UpdateDivision`, data);
};

export const deleteDivisionService = (data: any) => {
  return DELETE("/api/Division/RemoveDivision", {
    data: { "id": [data] },
  });
};
