import { getPublicRepresentativesService } from "../../../httpService/importantNumbers/publicRepresentativesServices";
import { GET_PUBLICREPRESENTATIVES_NUMBER } from "../../constants/importantNumbers/publicRepresentatives/publicRepresentativesConstant";


// const getpublicRepresentatives = (payload?: any) => async (dispatch: any) => {
// 	var data = getPublicRepresentativesService(payload);
// 	const pload = (await data).data;
// 	dispatch({ type: GET_PUBLICREPRESENTATIVES_NUMBER, payload: pload });
// };

const getpublicRepresentativesListAction = (p: any) => async (dispatch: any) => {
	try {
		const data = getPublicRepresentativesService(p);
		const payload = (await data).data;
		dispatch({ type: GET_PUBLICREPRESENTATIVES_NUMBER, payload: payload });
	} catch (error:any) {}
};


export { getpublicRepresentativesListAction };
