import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GetWalletRechargeHistoryRequest } from '../../../models/auth/user/UserModels';
import { GetWalletRechargeHistory, GetUserPointResponse, GetUserPointWithdrawHistoryResponse, GetUserPullHistoryResponse, GetUserPointRequest, GetUserPullHistoryRequest, GetUserPointWithdrawHistoryRequest } from '../../../models/auth/user/UserPointModels';
import { RootState } from '../../../store';
import { getUserPointListAction, getUserPullHistoryAction, getUserWithdrawHistoryAction, getWalletRechargeHistoryAction } from '../../../store/actions/auth/user/userActions';
import { AppButton } from '../../common/AppButton';
import _moment from "moment";
import { AppLoader } from '../../common/AppLoader';
import { Link } from 'react-router-dom';
import { BiLeftArrowAlt } from 'react-icons/bi';

export default function GetUserWalletHistory() {

    const dispatch = useDispatch();

    const orderTimeFormatter = (createdDateTime: any) => {
        const createdTime = _moment(createdDateTime, "YYYY-MM-DD HH:mm:ss.SSSSSSS");
        return createdTime.format("MMM D, YY [at] h:mm A");
    };

    const [isLoading, setIsLoading] = useState(false);

    const [isEarningHistry, setIsEarningHistry] = useState(true);
    const [isWithdrawHistry, setIsWithdrawHistry] = useState(false);
    const [isPullHistry, setIsPullHistry] = useState(false);
    const [isRechargeHistry, setIsRechargeHistry] = useState(false);
    const [isSelected, setIsSelected] = useState("");

    const openEaringHistry = () => {
        setIsSelected("EaringHistry")
        setIsEarningHistry(true);
        setIsWithdrawHistry(false);
        setIsPullHistry(false);
        setIsRechargeHistry(false);
    };

    const openWithdrawHistry = () => {
        setIsSelected("WithdrawHistry")
        setIsEarningHistry(false);
        setIsWithdrawHistry(true);
        setIsPullHistry(false);
        setIsRechargeHistry(false);
    };

    const openPullHistry = () => {
        setIsSelected("PullHistry")
        setIsEarningHistry(false);
        setIsWithdrawHistry(false);
        setIsPullHistry(true);
        setIsRechargeHistry(false);
    };

    const [searchQuery, setSearchQuery] = useState("");
    const [earningType, setEarningType] = useState(0);
    const [earningStatus, setEarningStatus] = useState(0);

    const rechargeHistory = useSelector((state: RootState) => {
        return state.getWalletRechargeHistoryResponse.data as GetWalletRechargeHistory[];
    });

    const pointList = useSelector((state: RootState) => {
        return state.getuserPointListResponse.data as GetUserPointResponse[];
    });

    var model = new GetUserPointRequest();
    model.userId = 0;
    model.searchQuery = searchQuery;
    model.earningStatus = earningStatus;
    model.earningType = earningType;

    useEffect(() => {
        dispatch(getUserPointListAction(model));
    }, [dispatch]);


    const pullList = useSelector((state: RootState) => {
        return state.getUserPullHistoryResponse
            .data as GetUserPullHistoryResponse[];
    });

    var pullHistryModel = new GetUserPullHistoryRequest();
    pullHistryModel.userId = 0;
    pullHistryModel.earningType = earningType;

    useEffect(() => {
        dispatch(getUserPullHistoryAction(pullHistryModel));
    }, [dispatch]);


    const openRechargeHistry = () => {
        var model = new GetWalletRechargeHistoryRequest();
        model.isMyRecord = true;
        model.userId = 0;
        dispatch(getWalletRechargeHistoryAction(model));
        setIsSelected("RechargeHistry");
        setIsEarningHistry(false);
        setIsWithdrawHistry(false);
        setIsPullHistry(false);
        setIsRechargeHistry(true);
    };

    const [pointStatus, setPointStatus] = useState(0);

    const withdrawList = useSelector((state: RootState) => {
        return state.getUserWithdrawHistoryResponse.data;
    });

    var withdrawalHistryModel = new GetUserPointWithdrawHistoryRequest();
    withdrawalHistryModel.userId = 0;
    withdrawalHistryModel.PointStatus = pointStatus;

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                await dispatch(getUserWithdrawHistoryAction(withdrawalHistryModel));
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [dispatch]);

    if (isLoading) {
        return (
            <div className="container h-screen flex items-center justify-center mx-auto px-2 md:px-0 pb-12">
                <AppLoader status={true} />
            </div>
        );
    }

    return (
        <>
            <div className="mx-auto container pt-2 lg:pt-4 pb-4 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28">
                <Link className='text-primary flex items-center gap-1' to="/myWallet">
                    <BiLeftArrowAlt className='text-lg lg:text-2xl 2xl:text-2xl' />
                    <h2 className='text-sm lg:text-lg 2xl:text-xl font-medium'>Back to My wallet</h2>
                </Link>

                <div className="lg:myBorder flex items-center justify-between lg:justify-evenly gap-1 lg:gap-4 lg:rounded px-0 lg:px-2 py-0 lg:py-2.5 md:gap-6 my-2 lg:mb-4">
                    <AppButton className={`${isSelected === "EaringHistry" || isSelected === "" ? "myBtn text-xxs lg:text-base px-1.5 lg:px-4 py-0 lg:py-1" : "outlineBtn text-xxs lg:text-base px-1.5 lg:px-4 py-0 lg:py-1"}`} text="Earning History" onClick={openEaringHistry} />
                    <AppButton className={`${isSelected === "WithdrawHistry" ? "myBtn text-xxs lg:text-base px-1.5 lg:px-4 py-0 lg:py-1" : "outlineBtn text-xxs lg:text-base px-1.5 lg:px-4 py-0 lg:py-1"}`} text="Withdraw History" onClick={openWithdrawHistry} />
                    <AppButton className={`${isSelected === "PullHistry" ? "myBtn text-xxs lg:text-base px-1.5 lg:px-4 py-0 lg:py-1" : "outlineBtn text-xxs lg:text-base px-1.5 lg:px-4 py-0 lg:py-1"}`} text="Pull History" onClick={openPullHistry} />
                    <AppButton className={`${isSelected === "RechargeHistry" ? "myBtn text-xxs lg:text-base px-1.5 lg:px-4 py-0 lg:py-1" : "outlineBtn text-xxs lg:text-base px-1.5 lg:px-4 py-0 lg:py-1"}`} text="Recharge History" onClick={openRechargeHistry} />
                </div>

                {/* earning history div table start here  */}
                {isEarningHistry && (
                    <div className="flex w-full bg-primary rounded">
                        <div className="tableHead text-xxs lg:text-base w-[20%]">Earning Amount</div>
                        <div className="tableHead text-xxs lg:text-base w-[20%]">Date & Time</div>
                        <div className="tableHead text-xxs lg:text-base w-[19.8%]">Earning Type</div>
                        <div className="tableHead text-xxs lg:text-base w-[19.9%]">Earning Status</div>
                        <div className="tableHead text-xxs lg:text-base border-none w-[20%]">Earned From</div>
                    </div>
                )}

                {isEarningHistry && <div className="flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-0.5 lg:gap-1 py-0.5 lg:py-1">
                    {isEarningHistry &&
                        pointList?.map((item: GetUserPointResponse) => {
                            return (
                                <div key={item?.id} className="myBorder flex w-full items-center">
                                    <div className="tableRow w-[20%]">৳ {item?.earnedAmount}</div>
                                    <div className="tableRow text-xxs lg:text-base w-[20%]">{orderTimeFormatter(item.createdAt)}</div>
                                    <div className="tableRow w-[20%]">
                                        <p className={item.earningType === "CourseEarning" ? "CourseEarning" : "" || item.earningType === "ResellingEarning" ? "ResellerEarning" : "" || item.earningType === "RefferEarning" ? "RefferEarning" : "" || item.earningType === "ModeratorEarning" ? "ModeratorEarning" : "" || item.earningType === "ProductEarning" ? "ProductEarning" : "" || item.earningType === "AdvancedPaymentEarning" ? "AdvancedPaymentEarning" : "" || item.earningType === "ShippingCost" ? "ShippingCost" : ""  || item.earningType === "ResellingManagerEarning" ? "CourseEarning" : ""}>
                                            {item.earningType === "CourseEarning" ? "CourseEarning" : "" || item.earningType === "ResellingEarning" ? "ResellingEarning" : "" || item.earningType === "RefferEarning" ? "RefferEarning" : "" || item.earningType === "ModeratorEarning" ? "ModeratorEarning" : "" || item.earningType === "ProductEarning" ? "ProductEarning" : "" || item.earningType === "AdvancedPaymentEarning" ? "AdvancedBack" : "" || item.earningType === "SuperShop" ? "SuperShop" : "" || item.earningType === "ShippingCost" ? "ShippingCost" : "" || item.earningType === "ResellingManagerEarning" ? "R.M. Earning" : ""}
                                        </p>
                                    </div>
                                    <div className="tableRow w-[20%]">
                                        <p className={item.earningStatus === "InProgress" ? "ProgressIn" : "" || item.earningStatus === "Denied" ? "Denied" : "" || item.earningStatus === "Earned" ? "Earned" : ""}>{(item.earningType === "AdvancedPaymentEarning" || item.earningType === "ShippingCost") ? "Returned" : item.earningStatus === "Denied" ? "Cancelled" : item.earningStatus}</p>
                                    </div>
                                    <div className="tableRow border-none text-xxs lg:text-base w-[20%]">{item?.earnedFrom}</div>
                                </div>
                            );
                        })}
                </div>}

                {/* withdraw history div table start here  */}
                {isWithdrawHistry && (
                    <div className="flex w-full bg-primary rounded">
                        <div className="tableHead text-xxs lg:text-base w-[33.33%]">Withdraw Amount</div>
                        <div className="tableHead text-xxs lg:text-base w-[33.33%]">Date & Time</div>
                        <div className="tableHead text-xxs lg:text-base border-none w-[33.33%]">Withdraw status</div>
                    </div>
                )}

                {isWithdrawHistry && <div className="flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-0.5 lg:gap-1 py-0.5 lg:py-1">
                    {isWithdrawHistry &&
                        withdrawList?.items?.map((item: GetUserPointWithdrawHistoryResponse) => {
                            return (
                                <div key={item?.id} className="myBorder flex w-full items-center">
                                    <div className="tableRow w-[33.33%]">৳ {item.requestedAmount}</div>
                                    <div className="tableRow text-xxs lg:text-base w-[33.33%]">{orderTimeFormatter(item.requestDateTime)}</div>
                                    <div className="tableRow border-none w-[33.33%]">
                                        <p className={item.pointStatus === "InProgress" ? "ProgressIn" : "" || item.pointStatus === "Cancelled" ? "Denied" : "" || item.pointStatus === "Received" ? "Earned" : ""}>{item.pointStatus}</p>
                                    </div>
                                </div>
                            );
                        })}
                </div>}

                {/* pull history div table start here  */}
                {isPullHistry && (
                    <div className="flex w-full bg-primary rounded">
                        <div className="tableHead text-xxs lg:text-base w-[33.33%]">Pull Amount</div>
                        <div className="tableHead text-xxs lg:text-base w-[33.33%]">Date & Time</div>
                        <div className="tableHead text-xxs lg:text-base border-none w-[33.33%]">Pull Type</div>
                    </div>
                )}

                {isPullHistry && <div className="flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-0.5 lg:gap-1 py-0.5 lg:py-1">
                    {isPullHistry &&
                        pullList?.map((item: GetUserPullHistoryResponse) => {
                            return (
                                <div key={item?.id} className="myBorder flex w-full items-center">
                                    <div className="tableRow text-xxs lg:text-base w-[33.33%]">৳ {item?.pulledAmount}</div>
                                    <div className="tableRow text-xxs lg:text-base w-[33.33%]">{orderTimeFormatter(item.dateTime)}</div>
                                    <div className="tableRow border-none w-[33.33%]">
                                        <p className={item.earningType === "CourseEarning" ? "CourseEarning" : "" || item.earningType === "ResellerEarning" ? "ResellerEarning" : "" || item.earningType === "RefferEarning" ? "RefferEarning" : "" || item.earningType === "ModeratorEarning" ? "ModeratorEarning" : "" || item.earningType === "ProductEarning" ? "ProductEarning" : "" || item.earningType === "PaymentBackEarning" ? "PaymentBackEarning" : ""}>{item.earningType}</p>
                                    </div>
                                </div>
                            );
                        })}
                </div>}

                {/* recharge history div table start here  */}
                {isRechargeHistry && (
                    <div className="flex w-full bg-primary rounded">
                        <div className="tableHead text-xxs lg:text-base w-[25%]">Recharge Amount</div>
                        <div className="tableHead text-xxs lg:text-base w-[25%]">Date & Time</div>
                        <div className="tableHead text-xxs lg:text-base w-[25%]">Payment Method</div>
                        <div className="tableHead text-xxs lg:text-base border-none w-[25%]">Recharge status</div>
                    </div>
                )}

                {isRechargeHistry && <div className="flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-0.5 lg:gap-1 py-0.5 lg:py-1">
                    {isRechargeHistry &&
                        rechargeHistory?.map((item: GetWalletRechargeHistory, index: any) => {
                            return (
                                <div key={index} className="myBorder flex w-full items-center">
                                    <div className="tableRow text-xxs lg:text-base w-[25%]">৳ {item?.amount}</div>
                                    <div className="tableRow text-xxs lg:text-base w-[25%]">{orderTimeFormatter(item.dateTime)}</div>
                                    <div className="tableRow text-xxs lg:text-base w-[25%]">
                                        {item.paymentMethods}
                                    </div>
                                    <div className="tableRow text-xxs lg:text-base border-none w-[25%]">
                                        {item.transectionStatus}
                                    </div>
                                </div>
                            );
                        })}
                </div>}
            </div>
        </>
    )
}
