import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import {
  ShoppingCartFavouriteModel,
} from "../../../models/shoppingCart/resellingCartModels";
import { AppSnackBar } from "../../common/AppSnackBar";
import { AlertColor } from "@mui/material/Alert";
import { TbCurrencyTaka } from "react-icons/tb";
import { BsHeart, BsHeartFill } from "react-icons/bs";
import { AppLoader } from "../../common/AppLoader";
import { MdPlayCircle } from "react-icons/md";
import { dateTimeFormatter } from "../../../common/common";
import { bechteChaiGetFavouriteItemListAction, bechteChaiGetIsAlreadyFavouriteOrNotAction, bechteChaiGetProductByIdAction, bechteChaiProductCommentsAction } from "../../../store/actions/bechteChai/bechteChaiProductActions";
import { BechteChaiGetComments } from "./BechteChaiGetComments";
import { BechteChaiAddFavouriteRequest, BechteChaiFavouriteResponse, BechteChaiProductCustomerCommentResponse } from "../../../models/bechteChai/product/bechteChaiProductModels";
import { addOrRemoveBechteChaiFavouriteService } from "../../../httpService/bechteChai/bechteChaiProductServices";

export default function BechteChaiGetProductById() {

  const [isLoading, setIsLoading] = useState(true);

  const [titleBannerPath, setTitleBannerPath] = useState("");

  const [isUpdateFavourite, setIsUpdateFavourite] = useState(false);

  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

  const dispatch = useDispatch();

  const productDetails = useSelector((state: RootState) => {
    return state.bechteChaiProductByIdResponse.data.data;
  });

  const productComments = useSelector((state: RootState) => {
    return state.bechteChaiProductCommentListResponse.data as BechteChaiProductCustomerCommentResponse[];
  });

  const { id } = useParams();

  const [favItems, setFavouriteItems] = useState<BechteChaiFavouriteResponse[]>(
    []
  );

  let isSignIn: any = reactLocalStorage.getObject("SignIn");

  let token = isSignIn?.accessToken;

  const favouriteItemsSignIn = useSelector((state: RootState) => {
    return state.bechteChaiGetFavouriteItemResponse.data;
  });

  const favItemsMemo = useMemo(() => {
    return favouriteItemsSignIn as BechteChaiFavouriteResponse[];
  }, [favouriteItemsSignIn]);

  useEffect(() => {
    if (token && JSON.stringify(token) !== JSON.stringify({})) {
      if (favItemsMemo) {
        setFavouriteItems(favItemsMemo);
      }
    } else {
    }
  }, [favItemsMemo, token]);

  const isFavourite = useSelector((state: RootState) => {
    return state.bechteChaiGetIsAlreadyFavouriteResponse.data;
  });

  useEffect(() => {
    dispatch(bechteChaiGetIsAlreadyFavouriteOrNotAction(id));
  }, [productDetails, id]);

  useEffect(() => {
    dispatch(bechteChaiGetFavouriteItemListAction({}));
  }, [dispatch, productDetails]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await dispatch(bechteChaiGetProductByIdAction(id));
        await dispatch(bechteChaiProductCommentsAction(id));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch, id]);

  useEffect(() => {
    if (productDetails) {
      setTitleBannerPath(productDetails.photoPath);
    }
  }, [productDetails]);

  const handleProductMainPhotoChange = (path: any) => {
    setTitleBannerPath(path?.url);
  };

  const handleAddToFavourite = () => {
    if (token && JSON.stringify(token) !== JSON.stringify({})) {
      let model = new BechteChaiAddFavouriteRequest();
      model.productId = id;
      addOrRemoveBechteChaiFavouriteService(model)
        .then((res) => {
          setIsUpdateFavourite(true);
          dispatch(bechteChaiGetIsAlreadyFavouriteOrNotAction(id));
          setIsShowSnackBar(true);
          setSnackBarMessage("Successfully done");
          setSnackBarMessageType("success");
          setTimeout(() => {
            setIsShowSnackBar(false);
          }, 2000);
        })
        .catch(() => { });
    }
  };

  const handleDownload = async () => {
    const response = await fetch(productDetails?.bannerPath);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "picture.jpg";
    link.click();

    URL.revokeObjectURL(url);
  };

  if (isLoading) {
    return (
      <div className="container mx-auto flex h-screen items-center justify-center px-2 pb-12 md:px-0">
        <AppLoader status={true} />
      </div>
    );
  }

  return (
    <div className="container mx-auto pb-8 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28">
      {isShowSnackBar && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      <p
        className="cursor-pointer pb-1 text-center py-1 text-xl 2xl:pb-2"
      >
        Posted By, <span className="text-royalBlue font-medium ">{productDetails?.ownerUser?.firstName} {productDetails?.ownerUser?.lastName}.</span>
      </p>
      <div className="flex flex-col gap-x-8 gap-y-4 pt-2 md:pt-4">

        <div className="flex w-full h-[45dvh] mx-auto gap-2 md:w-1/2 md:gap-4">
          <div className="myBorder relative h-full w-4/5 bg-white md:h-full">
            <img
              className="h-full w-full object-contain"
              src={
                titleBannerPath !== ""
                  ? titleBannerPath
                  : productDetails?.photoPath
              }
              alt="main big product pic"
            />
          </div>
          <div className="flex flex-col h-full w-1/5 gap-1 md:gap-2">
            {productDetails?.productMediaList &&
              productDetails?.productMediaList?.map((x: any, index: any) => {
                return (
                  <div
                    className="myBorder cursor-pointer bg-white"
                    key={index}
                    onClick={() => handleProductMainPhotoChange(x)}
                  >
                    <img
                      className="h-16 w-full rounded object-cover"
                      src={x?.url}
                      alt=""
                    />
                  </div>
                );
              })}
          </div>
        </div>

        <div className="w-full flex flex-col md:flex-row md:gap-2 gap-1">
          <div className='w-full md:w-1/2'>
            <h2 className="pb-1 text-lg font-medium md:text-xl 2xl:pb-2 2xl:text-2xl">
              {productDetails?.name}
            </h2>
            {/*Short description section here  */}
            <div className="flex flex-col gap-y-[2px] 2xl:gap-y-1">
              <h2 className="subTitle">Short Description</h2>
              {/* description title here  */}
              <p className="max-w-xl">
                <div
                  dangerouslySetInnerHTML={{
                    __html: productDetails?.shortDescription,
                  }}
                />
              </p>
            </div>

            <div className="flex flex-col gap-y-[2px] 2xl:gap-y-1">
              <h2 className="subTitle">Description</h2>
              {/* description title here  */}
              <p className="max-w-xl">
                <div
                  dangerouslySetInnerHTML={{
                    __html: productDetails?.fullDescription,
                  }}
                />
              </p>
            </div>
          </div>
          <div className='w-full md:w-1/2'>
            <div className="flex max-w-sm items-center justify-between md:max-w-md">
              <span className="font-medium">
                {productComments?.length} comments
              </span>
              <div
                className="myBorder h-fit cursor-pointer p-1.5 2xl:p-2"
                onClick={handleAddToFavourite}
              >
                {!isFavourite && (
                  <BsHeart className="text-2xl font-bold text-bechteChai" />
                )}
                {isFavourite && (
                  <BsHeartFill className="text-2xl font-bold text-bechteChai" />
                )}
              </div>
            </div>
            <div className='flex flex-col md:flex-row max-w-sm md:items-center justify-between md:max-w-md'>

              {/*Price section here  */}
              <div className="flex items-center gap-1">
                <h2 className="subTitle">Price: </h2>
                <TbCurrencyTaka />
                <strong>{productDetails?.price}</strong>
              </div>
              {/*Category section here  */}
              <div className="flex items-center gap-1">
                <h2 className="subTitle">Category: </h2>
                &nbsp;
                <strong>{productDetails?.subCategoryName}, {productDetails?.categoryName}</strong>
              </div>
            </div>
            <div className="flex flex-row gap-y-[2px] 2xl:gap-y-1">
              <h2 className="subTitle text-royalBlue font-bold">Phone: </h2>&nbsp;
              <p>{productDetails?.phone}</p>
            </div>
            <div className="flex max-w-xs items-center justify-between gap-4">
              {productDetails?.videoUrl && (
                <Link to={productDetails?.videoUrl} target="_blank">
                  <MdPlayCircle className="cursor-pointer text-4xl text-bechteChai" />
                </Link>
              )}
            </div>
            <div className="flex flex-row gap-y-[2px] 2xl:gap-y-1">
              <h2 className="subTitle">Address: </h2>&nbsp;
              <p>{productDetails?.address}, {productDetails?.areaName}, {productDetails?.districtName}, {productDetails?.divisionName} </p>
            </div>

            <div className="flex flex-row gap-x-[2px] 2xl:gap-y-1">
              <h2 className="subTitle">Posted: </h2>&nbsp;
              <p>{dateTimeFormatter(productDetails?.createdAt)}</p>
            </div>
          </div>
        </div>
      </div>

      <BechteChaiGetComments productComments={productComments} productId={id} />
    </div>
  );
}
