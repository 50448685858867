import { PaginationPayloadModel } from "../../models/common/paginationModels";
import { SSGetSubCategoryListRequest } from "../../models/superShopp/catalog/sSCategory/ssSubCategoryModel";
import { DELETE, GET, POST, PUT } from "../service";

export const sSAddSubCatagoryService = (data: any) => {
  return POST("/api/sSSubCategory/sSCreateSubCategory", data);
};

export const sSDeleteSubCatagoryService = (data: any) => {
  return DELETE(`/api/sSSubCategory/sSRemoveSubCategory`, {
    data: { "ids": data },
  });
};

export const sSGetSubCategoryListService = (payload?: SSGetSubCategoryListRequest) => {
  return GET(
    `/api/sSSubCategory/sSGetSubCategoryList?sSMainCategoryId=${payload?.sSMainCategoryId}&isAll=${payload?.isAll}&forPublic=${payload?.forPublic}&limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}`
  );
};

export const sSGetSubCategoryByIdService = (id: any) => {
	return GET(`/api/SSSubCategory/SSGetSubCategoryById?Id=${id}`);
};


export const sSupdateSubCatagoryService = (data: any) => {
  return PUT(`/api/sSSubCategory/sSUpdateSubCategory`, data);
};
