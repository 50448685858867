export class SSAddOrRemoveFavouriteRequest {
    productId?: any;
}

export class SSGetFavouriteItemsRequest {
    userId?: any = 0;
    searchQuery?: string = "";
}

export class SSGetIsAlreadyFavouriteOrNotRequest {
    productId?: any;
}
