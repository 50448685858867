export function magazineCategoryListReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case 'GET_MAGAZINE_CATEGORY_LIST':
            return { loading: false, data: action.payload };
        default:
            return state;
    }
}

export function magazineCategoryByIdReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case 'GET_MAGAZINE_CATEGORY_BY_ID':
            return { loading: false, data: action.payload };
        default:
            return state;
    }
}