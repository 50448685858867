
import { getIsPurchasedService, getTrainingVideo, getUserTrainingVideoService, isTrainingVideoWacthedService, getIsTrainingPaidService, submitTrainingPurchase, getTrainingListService, getTrainingByIdService } from "../../../httpService/training/trainingService";
import { GET_IS_TRAINING_VIDEO_WATCHED, GET_TRAINING_BY_ID, GET_TRAINING_IS_PAID, GET_TRAINING_IS_PURCHASED, GET_TRAINING_LIST, GET_TRAINING_VIDEO_LIST, GET_USER_TRAINING_VIDEO_LIST } from "../../constants/training/trainingConstants";

export const getTrainingListAction = () => async (dispatch: any) => {
    var data = getTrainingListService();
    const pload = (await data).data;
    dispatch({ type: GET_TRAINING_LIST, payload: pload });
};

export const getTrainingByIdAction = (id:any) => async (dispatch: any) => {
    var data = getTrainingByIdService(id);
    const pload = (await data).data;
    dispatch({ type: GET_TRAINING_BY_ID, payload: pload });
};

export const getTrainingVideoAction = () => async (dispatch: any) => {
    var data = getTrainingVideo();
    const pload = (await data).data;
    dispatch({ type: GET_TRAINING_VIDEO_LIST, payload: pload });
};

export const getIsPurchasedAction = (id:any) => async (dispatch: any) => {
    var data = getIsPurchasedService(id);
    const pload = (await data).data;
    dispatch({ type: GET_TRAINING_IS_PURCHASED, payload: pload });
};

export const getIsTrainingPaidAction = (userId: any = 0, trainingId: any) => async (dispatch: any) => {
    var data = getIsTrainingPaidService(userId, trainingId);
    const pload = (await data).data;
    dispatch({ type: GET_TRAINING_IS_PAID, payload: pload });
};

export const getUserTrainingVideoAction = (id:any) => async (dispatch: any) => {
    var data = getUserTrainingVideoService(id);
    const pload = (await data).data;
    dispatch({ type: GET_USER_TRAINING_VIDEO_LIST, payload: pload });
};

export const TrainingPurchase = (id:any) => async (dispatch: any) => {
    var data = submitTrainingPurchase(id);
    const pload = (await data).data;
    dispatch({ type: GET_TRAINING_VIDEO_LIST, payload: pload });
};

export const getIsTrainingVideoWatchedAction = (trainingId:any) => async (dispatch: any) => {
    var data = isTrainingVideoWacthedService(trainingId);
    const pload = (await data).data;
    dispatch({ type: GET_IS_TRAINING_VIDEO_WATCHED, payload: pload });
};
