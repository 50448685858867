const initialState = {
  isOpen: false,
};

function confirmOrderModalReducer(state = initialState, action: any) {
  if (action.type === "OPEN-CONFIRM-ORDER") {
    return { isOpen: true };
  }
  if (action.type === "CLOSE-CONFIRM-ORDER") {
    return { isOpen: false };
  }
  return state;
}

export { confirmOrderModalReducer  };
