import { getUserPointService, getUserProfileService, getUserService, getResellerTeamService, getUserTotalPointsService, getUserPointListService, getVendorService, getUserWalletService, getUserPullHistoryService, getUserWithdrawHistoryService, getCompanyWalletService, getCompanyPointListService, getVendorPointListService, getVendorWalletService, getVendorPullHistoryService, getVendorWithdrawHistoryService, getUserWithdrawListService, getVendorWithdrawListService, getWalletRechargeHistoryService, getGiveBackHistoryService, getBloodDonorsService, IsVendorAllAmountPaid, getUserProfileByIdService, getDataOfferVendorWalletService, getDataOfferVendorPullHistoryService, getDataOfferVendorWithdrawHistoryService, getDataOfferVendorPointListService, getDataOfferOrderListService, getWalletRechargeListService, getAffiliaterTeamService, getCompanyTeamService, getWalletCalculationsService, getRiderWalletService, getRiderPointListService, getIsRiderHaveToPayService } from "../../../../httpService/user/userServices";
import { getVendorRequestListServices } from "../../../../httpService/vendorRequest/VendorRequestServices";
import { GetGiveBackHistoryRequest, GetWalletRechargeHistoryRequest, GetWalletRechargeRequestListRequest } from "../../../../models/auth/user/UserModels";
import { DataOfferOrderListRequest, GetUserPointRequest, GetUserPointWithdrawHistoryRequest, GetUserPullHistoryRequest, GetUserWithdrawListRequest, GetVendorPointRequest, GetVendorPointWithdrawHistoryRequest, GetVendorWalletRequest, GetVendorWithdrawListRequest } from "../../../../models/auth/user/UserPointModels";
import { GetBloodDonorsRequest } from "../../../../models/bloodClub/bloodClubModels";
import { GET_ADMIN_WALLET, GET_BLOOD_DONORS, GET_COMPANY_POINT_LIST, GET_DATA_OFFER_ORDER_LIST, GET_DATA_OFFER_VENDOR_POINT_LIST, GET_DATA_OFFER_VENDOR_PULL_HISTORY, GET_DATA_OFFER_VENDOR_WALLET, GET_DATA_OFFER_VENDOR_WITHDRAW_HISTORY, GET_GIVE_BACK_LIST, GET_USER, GET_USER_NAME, GET_USER_POINT, GET_USER_POINT_LIST, GET_USER_PROFILE, GET_USER_PULL_HISTORY, GET_RESELLER_TEAM, GET_USER_TOTAL_POINT, GET_USER_WALLET, GET_USER_WITHDRAW_HISTORY, GET_USER_WITHDRAW_LIST, GET_VENDOR_POINT_LIST, GET_VENDOR_PULL_HISTORY, GET_VENDOR_WALLET, GET_VENDOR_WITHDRAW_HISTORY, GET_VENDOR_WITHDRAW_LIST, GET_WALLET_RECHARGE_LIST, GET_WALLET_RECHARGE_NAGAD_LIST, IS_VENDOR_ALL_AMOUNT_PAID, VENDOR_DETAILS_WITH_CAT, GET_AFFILIATER_TEAM, GET_COMPANY_TEAM, GET_ADMIN_WALLET_CALCULATIONS, GET_RIDER_WALLET, GET_RIDER_POINT_LIST, GET_IS_RIDER_HAVE_TO_PAY } from "../../../constants/auth/user/userConstant";


export const getUserProfileAction = () => async (dispatch: any) => {
	try {
		var data = getUserProfileService();
		const pload = (await data).data;
		dispatch({ type: GET_USER_PROFILE, payload: pload });
	}
	catch (error: any) { }
}

export const getUserProfileByIdAction = (id: any) => async (dispatch: any) => {
	try {
		var data = getUserProfileByIdService(id);
		const pload = (await data).data;
		dispatch({ type: GET_USER_PROFILE, payload: pload });
	}
	catch (error: any) { }
}


export const getUserAction = (id: any) => async (dispatch: any) => {
	try {
		var data = getUserService(id);
		const pload = (await data).data;
		dispatch({ type: GET_USER, payload: pload });
	}
	catch (error: any) { }
}


export const getUserProfileNameAction = (name: string) => async (dispatch: any) => {
	try { dispatch({ type: GET_USER_NAME, payload: name }); }
	catch (error: any) { }
}

export const getUserPointListAction = (payload?: GetUserPointRequest) => async (dispatch: any) => {
	var data = getUserPointListService(payload);
	const pload = (await data).data;
	dispatch({ type: GET_USER_POINT_LIST, payload: pload });
};

export const getCompanyPointListAction = (payload?: GetUserPointRequest) => async (dispatch: any) => {
	var data = getCompanyPointListService(payload);
	const pload = (await data).data;
	dispatch({ type: GET_COMPANY_POINT_LIST, payload: pload });
};

export const getVendorPointListAction = (payload?: GetVendorPointRequest) => async (dispatch: any) => {
	var data = getVendorPointListService(payload);
	const pload = (await data).data;
	dispatch({ type: GET_VENDOR_POINT_LIST, payload: pload });
};

export const getRiderPointListAction = (payload?: GetUserPointRequest) => async (dispatch: any) => {
	var data = getRiderPointListService(payload);
	const pload = (await data).data;
	dispatch({ type: GET_RIDER_POINT_LIST, payload: pload });
};

export const getDataOfferVendorPointListAction = (payload?: GetVendorPointRequest) => async (dispatch: any) => {
	var data = getDataOfferVendorPointListService(payload);
	const pload = (await data).data;
	dispatch({ type: GET_DATA_OFFER_VENDOR_POINT_LIST, payload: pload });
};

export const getUserWalletAction = (userId?: any) => async (dispatch: any) => {
	var data = getUserWalletService(userId);
	const pload = (await data).data;
	dispatch({ type: GET_USER_WALLET, payload: pload });
};

export const getRiderWalletAction = (userId?: any) => async (dispatch: any) => {
	var data = getRiderWalletService(userId);
	const pload = (await data).data;
	dispatch({ type: GET_RIDER_WALLET, payload: pload });
};

export const getIsRiderHaveToPayAction = () => async (dispatch: any) => {
	var data = getIsRiderHaveToPayService();
	const pload = (await data).data;
	dispatch({ type: GET_IS_RIDER_HAVE_TO_PAY, payload: pload });
};

export const getVendorWalletAction = (payload?: GetVendorWalletRequest) => async (dispatch: any) => {
	var data = getVendorWalletService(payload);
	const pload = (await data).data;
	dispatch({ type: GET_VENDOR_WALLET, payload: pload });
};

export const getDataOfferVendorWalletAction = (payload?: GetVendorWalletRequest) => async (dispatch: any) => {
	var data = getDataOfferVendorWalletService(payload);
	const pload = (await data).data;
	dispatch({ type: GET_DATA_OFFER_VENDOR_WALLET, payload: pload });
};


export const getCompanyWalletAction = () => async (dispatch: any) => {
	var data = getCompanyWalletService();
	const pload = (await data).data;
	dispatch({ type: GET_ADMIN_WALLET, payload: pload });
};

export const getUserPullHistoryAction = (payload?: GetUserPullHistoryRequest) => async (dispatch: any) => {
	var data = getUserPullHistoryService(payload);
	const pload = (await data).data;
	dispatch({ type: GET_USER_PULL_HISTORY, payload: pload });
};

export const getVendorPullHistoryAction = () => async (dispatch: any) => {
	var data = getVendorPullHistoryService();
	const pload = (await data).data;
	dispatch({ type: GET_VENDOR_PULL_HISTORY, payload: pload });
};


export const getDataOfferVendorPullHistoryAction = () => async (dispatch: any) => {
	var data = getDataOfferVendorPullHistoryService();
	const pload = (await data).data;
	dispatch({ type: GET_DATA_OFFER_VENDOR_PULL_HISTORY, payload: pload });
};


export const getUserWithdrawHistoryAction = (payload?: GetUserPointWithdrawHistoryRequest) => async (dispatch: any) => {
	var data = getUserWithdrawHistoryService(payload);
	const pload = (await data).data;
	dispatch({ type: GET_USER_WITHDRAW_HISTORY, payload: pload });
};

export const getVendorWithdrawHistoryAction = (payload?: GetVendorPointWithdrawHistoryRequest) => async (dispatch: any) => {
	var data = getVendorWithdrawHistoryService(payload);
	const pload = (await data).data;
	dispatch({ type: GET_VENDOR_WITHDRAW_HISTORY, payload: pload });
};

export const getDataOfferVendorWithdrawHistoryAction = (payload?: GetVendorPointWithdrawHistoryRequest) => async (dispatch: any) => {
	var data = getDataOfferVendorWithdrawHistoryService(payload);
	const pload = (await data).data;
	dispatch({ type: GET_DATA_OFFER_VENDOR_WITHDRAW_HISTORY, payload: pload });
};

export const getUserPointAction = (id: any) => async (dispatch: any) => {
	try {
		var data = getUserPointService(id);
		const pload = (await data).data;
		dispatch({ type: GET_USER_POINT, payload: pload });
	}
	catch (error: any) { }
}

export const getUserTotalPointsAction = () => async (dispatch: any) => {
	try {
		var data = getUserTotalPointsService();
		const pload = (await data).data;
		dispatch({ type: GET_USER_TOTAL_POINT, payload: pload });
	}
	catch (error: any) { }
}

export const getResellerTeamAction = (payload?: any) => async (dispatch: any) => {
	var data = getResellerTeamService(payload);
	const pload = (await data).data;
	dispatch({ type: GET_RESELLER_TEAM, payload: pload });
};

export const getAffiliaterTeamAction = (payload?: any) => async (dispatch: any) => {
	var data = getAffiliaterTeamService(payload);
	const pload = (await data).data;
	dispatch({ type: GET_AFFILIATER_TEAM, payload: pload });
};

export const getCompanyTeamAction = (payload?: any) => async (dispatch: any) => {
	var data = getCompanyTeamService(payload);
	const pload = (await data).data;
	dispatch({ type: GET_COMPANY_TEAM, payload: pload });
};

export const getVendorAction = (payload?: any) => async (dispatch: any) => {
	var data = getVendorService(payload);
	const pload = (await data).data;
	dispatch({ type: VENDOR_DETAILS_WITH_CAT, payload: pload });
};


export const getUserWithdrawRequestListAction = (payload?: GetUserWithdrawListRequest) => async (dispatch: any) => {
	var data = getUserWithdrawListService(payload);
	const pload = (await data).data;
	dispatch({ type: GET_USER_WITHDRAW_LIST, payload: pload });
};

export const getWalletRechargeRequestListAction = (payload?: GetWalletRechargeRequestListRequest) => async (dispatch: any) => {
	var data = getWalletRechargeListService(payload);
	const pload = (await data).data;
	dispatch({ type: GET_WALLET_RECHARGE_NAGAD_LIST, payload: pload });
};


export const getVendorWithdrawRequestListAction = (payload?: GetVendorWithdrawListRequest) => async (dispatch: any) => {
	var data = getVendorWithdrawListService(payload);
	const pload = (await data).data;
	dispatch({ type: GET_VENDOR_WITHDRAW_LIST, payload: pload });
};


export const getWalletRechargeHistoryAction = (payload?: GetWalletRechargeHistoryRequest) => async (dispatch: any) => {
	var data = getWalletRechargeHistoryService(payload);
	const pload = (await data).data;
	dispatch({ type: GET_WALLET_RECHARGE_LIST, payload: pload });
};


export const getGiveBackHistoryAction = (payload?: GetGiveBackHistoryRequest) => async (dispatch: any) => {
	var data = getGiveBackHistoryService(payload);
	const pload = (await data).data;
	dispatch({ type: GET_GIVE_BACK_LIST, payload: pload });
};

export const IsVendorAllAmountPaidAction = () => async (dispatch: any) => {
	var data = IsVendorAllAmountPaid();
	const pload = (await data).data;
	dispatch({ type: IS_VENDOR_ALL_AMOUNT_PAID, payload: pload });
};

export const getBloodDonorsAction = (payload?: GetBloodDonorsRequest) => async (dispatch: any) => {
	var data = getBloodDonorsService(payload);
	const pload = (await data).data;
	dispatch({ type: GET_BLOOD_DONORS, payload: pload });
};

export const getDataOfferOrderListAction = (payload?: DataOfferOrderListRequest) => async (dispatch: any) => {
	var data = getDataOfferOrderListService(payload);
	const pload = (await data).data;
	dispatch({ type: GET_DATA_OFFER_ORDER_LIST, payload: pload });
};


export const getVendorRequestListAction = (p: any) => async (dispatch: any) => {
	try {
		const data = getVendorRequestListServices(p);
		const payload = (await data).data;
		dispatch({ type: "GET_VENDOR_REQUEST", payload: payload });
	} catch (error: any) { }
};


export const getWalletCalculationsAction = () => async (dispatch: any) => {
	var data = getWalletCalculationsService();
	const pload = (await data).data;
	dispatch({ type: GET_ADMIN_WALLET_CALCULATIONS, payload: pload });
};
