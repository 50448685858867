import { Alert, AlertColor, Snackbar, SnackbarOrigin } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React from 'react';

export interface State extends SnackbarOrigin {
    open: boolean;
}

interface ISnackBarProps {
    message?: string;
    type?: AlertColor;
}

export const AppSnackBar: React.FC<ISnackBarProps> = ({ message, type }) => {

    const [state, setState] = React.useState<State>({
        open: true,
        vertical: 'top',
        horizontal: 'right',
    });

    const { vertical, horizontal, open } = state;

    const handleClick = (newState: SnackbarOrigin) => () => {
        setState({ open: true, ...newState });
    };

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                key={vertical + horizontal}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={type}
                    // action={
                    //     <CheckCircleIcon sx={{ color: 'white' }} /> // Change color of the icon
                    //   }
                    sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        // height: "50px",
                        width: '100%',
                        color: "#FFF",
                        '& .MuiSvgIcon-root': { // Change color of checkmark icon
                            color: 'white'
                        },
                        bgcolor: type == "success" ? "#47D764"
                               : type == "info" ? "#009ADC"
                               : type == "warning" ? "#FF9E44"
                               : "#F30C0C"
                    }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
}