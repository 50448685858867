import { CreateBkashPaymentRequest, CreateTrainingInvoiceRequest, ExecuteBkashPaymentRequest } from "../../models/payment/paymnetModel";
import { POST } from "../service";

export const createBkashPaymentService = (payload?: CreateBkashPaymentRequest) => {
    return POST(`/api/Payment/CreateBkashPayment`, payload);
};


export const executeBkashPaymentRequestService = (payload?: ExecuteBkashPaymentRequest) => {
    return POST(`/api/Payment/executeBkashPayment`, payload);
};

export const createTrainingInvoice = (payload?: CreateTrainingInvoiceRequest) => {
    return POST(`/api/Payment/CreateTrainingInvoice`, payload);
};


