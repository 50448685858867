import { PaginationPayloadModel } from "../../common/paginationModels";

export class SSAdminGetOrderListRequest extends PaginationPayloadModel {
    orderId?: any
    customerId?: any
    startDateTime?: any = null;
    endDateTime?: any = null;
    orderStatus?: any
    paymentStatus?: any
    shippingStatus?: any
}

export class SSCancelOrderRequest {
    orderId?: any
    productId?: any
}

export class SSCompleteOrderRequest {
    orderId?: any
}

export class SSConfirmOrderRequest {
    orderId?: any
}

export class SSGetOrderDetailsForAdminRequest {
    orderId?: any
    customerId?: any
}

export class SSGetOrderDetailsRequest {
    orderId?: any
}

export class SSGetOrderListRequest extends PaginationPayloadModel {
    orderId?: any
    orderStatus?: any
    paymentStatus?: any
    shippingStatus?: any
}

export class SSRemoveOrderRequest {
    orderId?: any
}

export class SSReturnOrderApprovalRequest {
    orderId?: any
    isApproved?: any
}

export class SSReturnOrderRequest {
    orderId?: any
    productId?: any
}

export class SSShippedOrderRequest {
    shippingStatus?: any
    orderId?: any
}

export class SSSubmitOrderRequest {
    totalAmount?: any;
    isPaid?: boolean;
    bazarId?: any = 0;
    orderProductRequest?: SSSubmitOrderProductRequest[];
    userFirstName?: string;
    userLastName?: string;
    userEmail?: string;
    userPhoneNo?: string;
    userAddress?: string;
}

export class SSSubmitOrderProductRequest {
    productId?: any;
    quantity?: any;
    unitPrice?: any;
    oldUnitPrice?: any;
    totalPrice?: any;
    bannerPath?: any;
}

export class SSUpdateOrderPaymentStatusRequest {
    paymentId?: any
}

export class SSUpdateOrderShippingStatusRequest {
    orderId?: any
    shippingStatus?: any
}

export class SSUpdateOrderStatusRequest {
    orderId?: any
    orderStatus?: any
}

export enum OrderStatus {
    All,
    InProgress,
    Cancelled,
    Returned,
    Complete,
    Confirm
}

export enum PaymentStatus {
    All,
    Paid,
    NotPaid,
}

export enum ReturnStatus {
    All,
    Approved,
    NotApproved,
}

export enum ShippingStatus {
    All,
    InProgress,
    Shipped,
    Delivered,
}

export class SSGetOrdersResponse {
    id?: any;
    bazarName?: string;
    orderDateTime?: any;
    totalAmount?: any;
    deliveryCharge?: any;
    orderStatus?: any;
    paymentStatus?: any;
    shippingStatus?: any;
    orderProducts?: any;
    totalItem?: any;
}

export class SSGetOrderDetailsResponse {
    orderId?: any;
    customerId?: any;
    totalAmount?: any;
    customerName?: any;
    userFirstName?: any;
    userLastName?: any;
    userEmail?: any;
    userPhoneNo?: any;
    userAddress?: any;
    orderStatus?: any;
    shippingStatus?: any;
    orderDateTime?: any;
    paymentStatus?: any;
    paymentMethod?: any;
    confirmByRiderId?: any;
    confirmByRiderName?: any;
    confirmByRiderPhone?: any;
    orderProductList?: SSGetOrderProductResponse[];
}

export class SSGetOrderProductResponse {
    productId?: any;
    productName?: any;
    bannerPath?: any;
    quantity?: any;
    unitPrice?: any;
    totalPrice?: any;
    orderStatus?: any;
    brandId?: any;
    brandName?: any;
    brandPhone?: any;
    shippingStatus?: any;
}
