import { useMemo, useEffect, useState } from "react";
import { TbCurrencyTaka } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { RootState } from "../../../../store";
import { AppButton } from "../../../common/AppButton";
import { AppModal } from "../../../common/AppModal";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import _moment from "moment";
import { SSCancelOrderRequest, SSCompleteOrderRequest, SSConfirmOrderRequest, SSGetOrderDetailsRequest, SSGetOrderDetailsResponse, SSGetOrderProductResponse, SSReturnOrderRequest, SSShippedOrderRequest } from "../../../../models/superShopp/order/sSOrderModel";
import { sSAdminGetOrderDetailsAction } from "../../../../store/actions/superShop/sSOrderActions";
import { sSCancelOrderProductsService, sSCompleteOrderService, sSConfirmOrderService, sSReturnOrderService, sSShippedOrderService } from "../../../../httpService/superShops/ssOrderServices";
import { AppLoader } from "../../../common/AppLoader";
import { getUserProfileAction } from "../../../../store/actions/auth/user/userActions";
import { isUpazilaAdmin } from "../../../../common/common";
import { reactLocalStorage } from "reactjs-localstorage";

export default function SSAdminSSGetOrderById() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const orderDetails = useSelector((state: RootState) => {
    return state.sSAdminGetOrderDetailsResponse.data as SSGetOrderDetailsResponse;
  });

  const orderProducts = useMemo(() => {
    return orderDetails.orderProductList as SSGetOrderProductResponse[];
  }, [orderDetails]);

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        let model = new SSGetOrderDetailsRequest();
        model.orderId = id;
        await dispatch(sSAdminGetOrderDetailsAction(model));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [id]);

  const userProfile = useSelector((state: RootState) => {
    return state.getUserProfileResponse.data;
  });

  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);


  const handleBackToOrderList = () => {
    navigate("/admin/super-shop/order-list");
  };

  const navigateToReviewRating = (item: any) => {
    navigate(`/productReview/${item?.productId}/${id}`);
  };

  const orderTimeFormatter = (createdDateTime: any) => {
    const createdTime = _moment(createdDateTime, "YYYY-MM-DD HH:mm:ss.SSSSSSS");
    return createdTime.format("MMM D, YY [at] h:mm A");
  };

  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);

  const [productId, setProductId] = useState(0);

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [isCancelButtonLoading, setIsCancelButtonLoading] = useState(false);

  const handleCancelOneModal = (item: any) => {
    setIsOpenCancelModal(true);
    setProductId(item.productId);
  };

  const handleCancelOneOrder = () => {
    var model = new SSCancelOrderRequest();
    model.orderId = id;
    model.productId = productId;
    setIsCancelButtonLoading(true);
    setIsButtonLoading(true);
    sSCancelOrderProductsService(model)
      .then(() => {
        setIsButtonLoading(false);
        setIsOpenCancelModal(false);
        let model = new SSGetOrderDetailsRequest();
        model.orderId = id;
        dispatch(sSAdminGetOrderDetailsAction(model));
        setIsButtonLoading(false);
        setIsCancelButtonLoading(false);
      })
      .catch(() => {
        setIsButtonLoading(false);
        setIsCancelButtonLoading(false);
      });
  };

  const handleCloseCancelOneModal = () => {
    setIsOpenCancelModal(false);
  };

  const [isOpenReturnModal, setIsOpenReturnModal] = useState(false);

  const [isReturnButtonLoading, setIsReturnButtonLoading] = useState(false);

  const handleOpenReturnOneModal = (item: any) => {
    setIsOpenReturnModal(true);
    setProductId(item.productId);
  };

  const handleReturnOneOrder = () => {
    var model = new SSReturnOrderRequest();
    model.orderId = id;
    model.productId = productId;
    setIsButtonLoading(true);
    setIsReturnButtonLoading(true);
    sSReturnOrderService(model)
      .then(() => {
        setIsReturnButtonLoading(true);
        let model = new SSGetOrderDetailsRequest();
        model.orderId = id;
        dispatch(sSAdminGetOrderDetailsAction(model));
        setIsOpenReturnModal(false);
        setIsButtonLoading(false);
        setIsReturnButtonLoading(false);
      })
      .catch(() => {
        setIsButtonLoading(false);
        setIsReturnButtonLoading(true);
      });
  };

  const handleCloseReturnOneModal = () => {
    setIsOpenReturnModal(false);
  };

  const [isConfirmButtonLoading, setIsConfirmButtonLoading] = useState(false);

  const [isOpenConfirmOrderModal, setIsOpenConfirmOrderModal] = useState(false);

  const handleConfirmOrderModal = () => {
    setIsOpenConfirmOrderModal(true);
  };

  const handleConfirmOrder = () => {
    var model = new SSConfirmOrderRequest();
    model.orderId = id;
    setIsConfirmButtonLoading(true);
    setIsButtonLoading(true);
    sSConfirmOrderService(model)
      .then(() => {
        setIsButtonLoading(false);
        setIsOpenCancelModal(false);
        let model = new SSGetOrderDetailsRequest();
        model.orderId = id;
        dispatch(sSAdminGetOrderDetailsAction(model));
        setIsButtonLoading(false);
        setIsConfirmButtonLoading(false);
        setIsOpenConfirmOrderModal(false);
      })
      .catch(() => {
        setIsButtonLoading(false);
        setIsConfirmButtonLoading(false);
      });
  };

  const handleCloseConfirmOrderModal = () => {
    setIsOpenConfirmOrderModal(false);
  };

  const [isShippedButtonLoading, setIsShipButtonLoading] = useState(false);

  const [isOpenShippedOrderModal, setIsOpenShippedOrderModal] = useState(false);

  const handleShippingOrderModal = () => {
    setIsOpenShippedOrderModal(true);
  };

  const handleShippedOrder = () => {
    var model = new SSShippedOrderRequest();
    model.orderId = id;
    model.shippingStatus = 2;
    setIsShipButtonLoading(true);
    setIsButtonLoading(true);
    sSShippedOrderService(model)
      .then(() => {
        setIsButtonLoading(false);
        setIsOpenCancelModal(false);
        let model = new SSGetOrderDetailsRequest();
        model.orderId = id;
        dispatch(sSAdminGetOrderDetailsAction(model));
        setIsButtonLoading(false);
        setIsShipButtonLoading(false);
        setIsOpenShippedOrderModal(false);
      })
      .catch(() => {
        setIsButtonLoading(false);
        setIsShipButtonLoading(false);
      });
  };

  const handleCloseShippingOrderModal = () => {
    setIsOpenShippedOrderModal(false);
  };

  const [isOpenCompleteOrderModal, setIsOpenCompleteOrderModal] = useState(false);
  const [isCompleteOButtonLoading, setIsCompleteOButtonLoading] = useState(false);

  const handleCompleteOrderModal = () => {
    setIsOpenCompleteOrderModal(true);
  };

  const handleCompleteOrder = () => {
    var model = new SSCompleteOrderRequest();
    model.orderId = id;
    setIsCompleteOButtonLoading(true);
    setIsButtonLoading(true);
    sSCompleteOrderService(model)
      .then(() => {
        setIsButtonLoading(false);
        setIsOpenCancelModal(false);
        let model = new SSGetOrderDetailsRequest();
        model.orderId = id;
        dispatch(sSAdminGetOrderDetailsAction(model));
        setIsButtonLoading(false);
        setIsCompleteOButtonLoading(false);
        setIsOpenCompleteOrderModal(false);
      })
      .catch(() => {
        setIsButtonLoading(false);
        setIsCompleteOButtonLoading(false);
      });
  };

  const handleCloseCompleteOrderModal = () => {
    setIsOpenCompleteOrderModal(false);
  };

  if (isLoading) {
    return (
      <div className="container mx-auto flex h-screen items-center justify-center px-2 pb-12 md:px-0">
        <AppLoader status={true} />
      </div>
    );
  }

  return (
    <div className="container mx-auto pb-8 pt-2 md:py-4 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28">

      {isOpenConfirmOrderModal && (
        <AppModal>
          <div className="flex max-w-md flex-col gap-4 p-6 text-center md:text-2xl">
            Are you sure you want to confirm this order?
            <div className="flex items-center justify-center gap-4">
              <AppButton text="Yes" onClick={handleConfirmOrder} isSubmitting={isConfirmButtonLoading} />
              <AppButton text="No" onClick={handleCloseConfirmOrderModal} />
            </div>
          </div>
        </AppModal>
      )}

      {isOpenCancelModal && (
        <AppModal>
          <div className="flex max-w-md flex-col gap-4 p-6 text-center md:text-2xl">
            Are you sure you want to cancel this product?
            <div className="flex items-center justify-center gap-4">
              <AppButton text="Yes" onClick={handleCancelOneOrder} isSubmitting={isCancelButtonLoading} />
              <AppButton text="No" onClick={handleCloseCancelOneModal} />
            </div>
          </div>
        </AppModal>
      )}

      {isOpenShippedOrderModal && (
        <AppModal>
          <div className="flex max-w-md flex-col gap-4 p-6 text-center md:text-2xl">
            Are you sure you want to deliver order?
            <div className="flex items-center justify-center gap-4">
              <AppButton text="Yes" onClick={handleShippedOrder} isSubmitting={isShippedButtonLoading} />
              <AppButton text="No" onClick={handleCloseShippingOrderModal} />
            </div>
          </div>
        </AppModal>
      )}

      {isOpenCompleteOrderModal && (
        <AppModal>
          <div className="flex max-w-md flex-col gap-4 p-6 text-center md:text-2xl">
            Are you sure you want to complete order?
            <div className="flex items-center justify-center gap-4">
              <AppButton text="Yes" onClick={handleCompleteOrder} isSubmitting={isCompleteOButtonLoading} />
              <AppButton text="No" onClick={handleCloseCompleteOrderModal} />
            </div>
          </div>
        </AppModal>
      )}

      {isOpenReturnModal && (
        <AppModal>
          <div className="flex max-w-md flex-col gap-4 p-6 text-center md:text-2xl">
            Are you sure you want to return this product?
            <div className="flex items-center justify-center gap-4">
              <AppButton text="Yes" onClick={handleReturnOneOrder} isSubmitting={isReturnButtonLoading} />
              <AppButton text="No" onClick={handleCloseReturnOneModal} />
            </div>
          </div>
        </AppModal>
      )}

      <div className="flex cursor-pointer items-center lg:gap-x-2 lg:px-4" onClick={handleBackToOrderList}>
        <KeyboardBackspaceOutlinedIcon />
        <h4 className="subTitle">Back to order list</h4>
      </div>

      <div className="md:px-16">
        <h3 className="title pb-2 lg:pb-0 lg:text-center">Order details</h3>

        <div className="flex justify-between lg:items-center">
          <h3 className="subTitle flex items-center gap-1">
            <span className={`${orderDetails?.paymentStatus == "Paid" ? "paided" : "unpaid"}`}>
              Payment Status: {orderDetails?.paymentStatus == "Paid" ? `Paid` : `NotPaid`}
            </span>
          </h3>
        </div>

        <div className="flex flex-col items-center gap-2 pt-2 md:flex-row md:gap-8">
          <div className="myBorder grid w-full grid-cols-2 space-y-0.5 px-2 py-1 text-xs md:w-3/5 lg:px-4 lg:py-1.5 lg:text-base lg:font-medium">
            <p>
              <span>Address: {orderDetails?.userAddress}</span>
            </p>
            <p>
              <span>Phone: {orderDetails?.userPhoneNo}</span>
            </p>
          </div>
          <div className="myBorder w-full px-2 py-1 md:w-1/4 lg:space-y-2 lg:px-4 lg:py-2">

            <div className="flex items-center justify-between border-t-2 border-gray-600 pt-1 text-sm lg:text-base">
              <p>Total cost</p>
              <p className="flex items-center text-sm lg:text-base">
                {" "}
                <TbCurrencyTaka />{" "}
                {orderDetails?.totalAmount}
              </p>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <h3 className="text-xs lg:text-base">
            <span className="font-medium">Order id: #{orderDetails.orderId}</span>
          </h3>

          <h3 className="text-xs lg:text-base">
            <span className="font-medium text-gray-600">
              {orderTimeFormatter(orderDetails?.orderDateTime)}
            </span>
          </h3>
        </div>

        {(orderDetails?.orderStatus == "InProgress" || orderDetails?.orderStatus == "Cancelled " || orderDetails?.orderStatus == "Confirm" || orderDetails?.orderStatus == "Complete" || orderDetails?.shippingStatus == "Shipped" || orderDetails?.orderStatus == "Returned") && (isUpazilaAdmin(reactLocalStorage.get("RoleName")) || orderDetails?.confirmByRiderId === null || orderDetails?.confirmByRiderId === userProfile?.id) ? (
          <div className="mx-auto flex max-w-2xl flex-col items-center justify-center gap-4 py-4 md:pb-0 md:pt-4">
            {orderProducts?.filter((_: any) => _.orderStatus === "Cancelled")?.length === orderProducts?.length ? (
              <div className="Cancelled  px-1 py-0.5 text-sm font-normal lg:px-4 lg:py-1 lg:text-base">
                Cancelled
              </div>
            ) : orderProducts?.filter((_: any) => _.orderStatus === "Complete")?.length > 0 ? (
              <div className="myBtn select-none">Completed</div>
            ) : (
              <>
                {orderProducts.filter((_: any) => _.orderStatus === "Returned")?.length != orderProducts?.length ? (
                  <div className="flex items-center justify-center gap-3 lg:gap-4">

                    {orderProducts.filter((_: any) => _.orderStatus === "InProgress" || _.orderStatus === "Confirm")?.length ? (
                      <div className="myBtn select-none px-2 lg:px-4">
                        Pending
                      </div>
                    ) : (
                      <div className="outlineBtn select-none px-2 lg:px-4">
                        Pending
                      </div>
                    )}

                    {orderProducts.filter((_: any) => _.orderStatus === "Confirm" || _.orderStatus === "Complete")?.length ? (
                      <div className="myBtn select-none px-2 lg:px-4">
                        Confirmed
                      </div>
                    ) : (
                      <AppButton
                        isSubmitting={isButtonLoading}
                        onClick={handleConfirmOrderModal}
                        text="Confirm"
                        className="outlineBtn select-none px-2 lg:px-4"
                      />
                    )}

                    {orderProducts.filter((_: any) => _.orderStatus === "Confirm")?.length ? (
                      <>
                        {orderProducts.filter((_: any) => _.shippingStatus === "Shipped")?.length ? (
                          <div className="myBtn select-none px-2 lg:px-4">
                            Delivered
                          </div>
                        ) : (
                          <AppButton
                            isSubmitting={isButtonLoading}
                            onClick={handleShippingOrderModal}
                            text="Delivery"
                            className="outlineBtn select-none px-2 lg:px-4"
                          />
                        )}
                      </>
                    ) : (
                      <></>
                    )}

                    {orderProducts.filter((_: any) => _.shippingStatus === "Shipped")?.length ? (
                      <div className="flex items-center justify-center gap-4">
                        {orderProducts.filter((_: any) => _.orderStatus === "Complete")?.length ? (
                          <>
                            <div className="myBtn select-none">Completed</div>
                          </>
                        ) : (
                          <>
                            {orderProducts.filter((_: any) => _.orderStatus === "Returned")?.length &&
                              orderProducts.filter((_: any) => _.orderStatus === "Confirm")?.length <= 0 ? (
                              <>
                                <div className="ModeratorEarning  px-1 py-0.5 text-sm font-normal lg:px-4 lg:py-1 lg:text-base">
                                  Returned
                                </div>
                              </>
                            ) : (
                              <>
                                <AppButton
                                  isSubmitting={isButtonLoading}
                                  onClick={handleCompleteOrderModal}
                                  text="Complete"
                                  className="outlineBtn select-none px-2 lg:px-4"
                                />
                              </>
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        ) : (
          <>
            Order Confirmed by: {orderDetails?.confirmByRiderName} Phone {orderDetails?.confirmByRiderPhone}
          </>
        )}

        {orderProducts?.map((item: SSGetOrderProductResponse, index: any) => (
          <section key={index} className="myBorder my-2 lg:my-4">
            <div className="border-b border-grey px-2 py-1 lg:px-4">
              <div className="flex items-center justify-between">
                <h3 className="text-xs lg:text-base">
                  <span className="font-medium">
                    Sold by: {item.brandName}
                  </span>
                </h3>
                <h3 className="text-xs lg:text-base">
                  <span className="font-medium text-royalBlue">
                    {item.brandPhone}
                  </span>
                </h3>
              </div>

              <div className="w-full px-2 lg:px-4">
                <div className="flex items-center justify-between py-1.5">
                  <div className="flex w-[40%] items-center gap-x-1 md:gap-x-4 lg:w-[32%] lg:justify-start">
                    <img
                      className="h-10 w-10 rounded object-cover md:h-14 md:w-14"
                      src={item?.bannerPath}
                      alt="These is product pic"
                    />
                    <div className="text-xs md:text-base">
                      <h3 className="cursor-pointer">
                        {item?.productName}
                      </h3>
                    </div>
                  </div>
                  <div className="flex w-[15%] items-center justify-center text-xs lg:w-[17%] lg:text-base">
                    <span className="flex items-center font-normal md:font-medium">
                      <TbCurrencyTaka /> {item?.unitPrice}
                    </span>
                  </div>
                  <div className="flex w-[15%] items-center justify-center text-xs lg:w-[17%] lg:text-base">
                    <span className=" font-normal md:font-medium">
                      {item?.quantity}
                    </span>
                  </div>
                  <div className="flex w-[15%] items-center justify-center text-xs lg:w-[17%] lg:text-base">
                    <span className="flex items-center font-normal md:font-medium">
                      <TbCurrencyTaka /> {item?.totalPrice}
                    </span>
                  </div>

                  <div className="flex w-[15%] justify-end lg:w-[17%]">
                    {item?.shippingStatus === "InProgress" && item?.orderStatus !== "Cancelled" && (
                      <>
                        {(orderDetails?.orderStatus == "InProgress" || orderDetails?.orderStatus == "Confirm") && orderDetails?.orderStatus !== "Complete" && orderDetails?.shippingStatus !== "Shipped" && orderDetails?.orderStatus !== "Returned" && (orderDetails?.confirmByRiderId === null || orderDetails?.confirmByRiderId == userProfile?.id) ? (
                          <AppButton
                            isSubmitting={isButtonLoading}
                            className="resellingOutlineBtn border-red px-2 text-red hover:bg-red lg:px-4"
                            text="Cancel"
                            onClick={() => { handleCancelOneModal(item) }}
                          />
                        ) : (
                          <div className="Completed">{item?.orderStatus}</div>
                        )}
                      </>
                    )}
                    {item?.orderStatus === "Cancelled" && (
                      <div className="Cancelled">Cancelled</div>
                    )}
                    {item?.shippingStatus === "Shipped" && item?.orderStatus !== "Cancelled" && item?.orderStatus !== "Returned" && item?.orderStatus !== "Complete" && (
                      <>
                        {orderDetails?.shippingStatus === "Shipped" && orderDetails?.orderStatus !== "Returned" && orderDetails?.confirmByRiderId == userProfile?.id ? (
                          <AppButton
                            isSubmitting={isButtonLoading}
                            className="resellingOutlineBtn border-red px-2 text-red hover:bg-red lg:px-4"
                            text="Return"
                            onClick={() => { handleOpenReturnOneModal(item) }}
                          />
                        ) : (
                          <div className="Completed">Confirmed</div>
                        )}
                      </>
                    )}
                    {item?.orderStatus === "Returned" && (
                      <div className="Returned">Returned</div>
                    )}
                    {item?.orderStatus === "Complete" && (
                      <div className="Completed">Completed</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        ))}
      </div>
    </div >
  );
}
