import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import React, { useState } from "react";

interface IAppRadioButtonProps {
  title?: string;
  items?: any[];
  onChange?: any;
  defaultValue?:any
}

export const AppRadioButton: React.FC<IAppRadioButtonProps> = ({title, items, onChange, defaultValue}) => {

  const handleChange = (e: any) => {
    let object = items?.find(x => x.id === +e.target.value);
    onChange(object);
  }
  
  return (
    <>
      <FormControl>
        <p>{title}</p>
        <RadioGroup
          row
          value={defaultValue}
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={handleChange}
        >
          {items?.map((x, index) => {
            // if(index === 2) return  <FormControlLabel key={x.id} value={x.id} control={<Radio />} label={x.name}  disabled/>
            return <FormControlLabel key={x.id} value={x.id} control={<Radio />} label={x.name} />
          })}
        </RadioGroup>
      </FormControl>
    </>
  );
}




