import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { HiCheck } from "react-icons/hi";
import { TbCurrencyTaka } from "react-icons/tb";
import { RootState } from "../../../../../store";
import { sSGetProductByIdAction } from "../../../../../store/actions/superShop/sSProductActions";
import { SSGetProductReviews } from "../../../../public/superShop/public/SSGetProductReviews";
import { ProductReviewCustomerResponse } from "../../../../../models/catalog/productReview/productReviewModels";
import { sSProductReviewListAction } from "../../../../../store/actions/superShop/sSProductReviewActions";

export default function AdminSSGetProductById() {

  const [titleBannerPath, setTitleBannerPath] = useState("");

  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    localStorage.setItem('shouldMaintainPage', 'true');
  }, []);

  const details = useSelector((state: RootState) => {
    return state.sSGetProductByIdResponse.data;
  });

  const productDetails = useMemo(() => {
    return details;
  }, [details]);

  useEffect(() => {
    dispatch(sSGetProductByIdAction(id));
  }, [dispatch]);


  useEffect(() => {
    if (productDetails) {
      setTitleBannerPath(productDetails.bannerPath)
    }
  }, [productDetails]);


  const handleProductMainPhotoCahange = (path: any) => {
    setTitleBannerPath(path);
  };

  const productReviews = useSelector((state: RootState) => {
    return state.sSProductReviewListResponse.data as ProductReviewCustomerResponse[];
  });

  useEffect(() => {
    dispatch(sSProductReviewListAction(id));
  }, [dispatch]);

  return (
    <div className="container mx-auto px-2 md:px-0 pb-12">

      {/* product photo and details start here  */}
      <div className="md:pt-4 pt-2 flex flex-col md:flex-row gap-y-4 gap-x-8">
        {/* these is product photo section */}
        <div className="w-full md:w-1/2 flex flex-col gap-y-2 md:gap-y-4">

          {/* large photo section here  */}
          <div className="relative w-full md:h-[58dvh] h-[35dvh] bg-white myBorder">
            <img className="object-contain w-full h-full" src={titleBannerPath != "" ? titleBannerPath : productDetails?.bannerPath} alt="main big product pic" />
          </div>
          {/* small photos section here  */}
          <div className="w-full flex gap-x-2 md:gap-x-4 md:h-[18dvh] h-[8dvh]">
            {productDetails?.bannerPathList && productDetails?.bannerPathList?.map((x: any, index: any) => {
              return (
                <div className="cursor-pointer bg-white myBorder" key={index} onClick={() => handleProductMainPhotoCahange(x)}>
                  <img className="object-contain rounded w-full h-full" src={x} alt="" />
                </div>
              );
            })}
          </div>
        </div>

        <div className="w-full md:w-1/2">
          <p className="text-royalBlue text-xl cursor-pointer font-medium 2xl:pb-2 pb-1">Brand Name: {productDetails?.productBrandName}</p>
          <p className="text-royalBlue text-xl cursor-pointer font-medium 2xl:pb-2 pb-1">Main Category Name: {productDetails?.mainCategoryName}</p>
          <p className="text-royalBlue text-xl cursor-pointer font-medium 2xl:pb-2 pb-1">Sub Category Name: {productDetails?.subCategoryName}</p>
          <h2 className="2xl:text-2xl text-lg md:text-xl 2xl:pb-2 pb-1 font-medium"> {productDetails?.name} </h2>

          <div className="flex items-center justify-between md:max-w-md max-w-sm">
            <span className="font-medium">{productReviews?.length} reviews</span>
            <div className="font-medium">

              {(productDetails?.rating > 0 && productDetails?.rating <= 1) && (
                <img
                  src="https://res.cloudinary.com/ddqenwff0/image/upload/v1678478594/Star_17_paiezv.png"
                  alt=""
                />
              )}

              {(productDetails?.rating > 1 && productDetails?.rating <= 2) && (
                <img
                  src="https://res.cloudinary.com/ddqenwff0/image/upload/v1678478594/Group_2631_xtm5u7.png"
                  alt=""
                />
              )}

              {(productDetails?.rating > 2 && productDetails?.rating <= 3) && (
                <img
                  src="https://res.cloudinary.com/ddqenwff0/image/upload/v1678478595/Group_2632_ksjv3i.png"
                  alt=""
                />
              )}

              {(productDetails?.rating > 3 && productDetails?.rating <= 4) && (
                <img
                  src="https://res.cloudinary.com/ddqenwff0/image/upload/v1678478594/Group_2633_ediwi7.png"
                  alt=""
                />
              )}

              {(productDetails?.rating > 4 && productDetails?.rating <= 5) && (
                <img
                  src="https://res.cloudinary.com/ddqenwff0/image/upload/v1678478594/Group_2634_kn5jp8.png"
                  alt=""
                />
              )}

              {(productDetails?.rating >= 0 && productDetails?.rating != 0) && (
                <>{productDetails?.rating}</>
              )}

            </div>
          </div>

          {/* in stock green section here  */}
          {productDetails?.availabeInStock > 0 && (
            <div className="bg-[#D1FFF5] text-primary 2xl:text-lg text-base flex items-center font-medium px-2 2xl:my-2 my-1 rounded w-fit gap-x-2">
              <HiCheck /> in stock
            </div>
          )}

          {/* in stock red section here  */}
          {productDetails?.availabeInStock <= 0 && (
            <div className="bg-[#FF7474] text-white 2xl:text-lg text-base flex items-center font-medium px-2 2xl:my-2 my-1 rounded w-fit gap-x-2">
              <HiCheck /> Out of stock
            </div>
          )}

          {/* price and offer section here  */}
          <div className="flex gap-x-8 2xl:pb-2 pb-1 items-center">
            {/* present price here  */}
            <h2 className="2xl:text-xl text-xl font-medium flex 2xl:gap-x-1 gap-x-[1px] items-center">  <TbCurrencyTaka /> {productDetails?.price}</h2>
            {/* old price here  */}
            <h2 className="2xl:text-xl text-xl font-medium line-through flex 2xl:gap-x-1 gap-x-[1px] items-center">  <TbCurrencyTaka />{productDetails?.oldPrice}</h2>
            {productDetails?.isDisountProduct == true && (
              <h2 className="bg-red text-xs 2xl:text-sm rounded px-2 italic text-white font-medium">{productDetails?.discountText}% OFF </h2>
            )}
          </div>
          <div className="flex md:flex-col">
            {productDetails?.productAttibuteWithValueList?.map((attribute: any, index: any) => (
              <>
                {attribute.attributeName === "Color" ? (
                  <>
                    {attribute.valueList?.length != 0 && (
                      <h3>{attribute.attributeName}</h3>
                    )}
                    <div className="">
                      {attribute.valueList.map((value: any) => (
                        <div
                          className={`product-selected`}
                          key={value.value}
                          style={{ backgroundColor: attribute.attributeName === "Color" ? value.value : "", }} >
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <div>
                    {attribute.valueList?.length != 0 && (
                      <div className="2xl:text-2xl text-xl font-medium 2xl:pb-1 pb-[2px]">{attribute.attributeName}</div>
                    )}
                    <div className="flex 2xl:gap-x-8 gap-x-6">
                      {attribute.valueList.map((value: any) => (
                        <div className="flex flex-col space-y-1 text-center">
                          <div
                            className={`myBorder flex justify-center cursor-pointer items-center 2xl:w-10 w-9 2xl:h-10 h-9 text-lg font-medium`}
                            key={value.value}>
                            <p>{value.value}</p>
                          </div>
                          <p className="text-primary font-semibold text-lg">{value.availabeInStock}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
            ))}
          </div>

          {/* description section here  */}
          <div className="flex flex-col 2xl:gap-y-1 gap-y-[2px]">
            <h2 className="subTitle">Description</h2>
            {/* description title here  */}
            <p className="max-w-xl">
              <div dangerouslySetInnerHTML={{ __html: productDetails?.fullDescription }} />
            </p>
          </div>
        </div>
      </div>
      <SSGetProductReviews productReviews={productReviews} productId={id} />
    </div>
  );
}
