import { DataOfferType, DataOrderStatus, DataPackageType, MobileOperator } from "../../dataOffer/dataOfferModels";
import { PaginationPayloadModel } from "../../common/paginationModels";

export class GetUserWithdrawListRequest extends PaginationPayloadModel {
    pointStatus?: any;
}

export class GetVendorWithdrawListRequest extends PaginationPayloadModel {
    pointStatus?: any;
}

export class GetUserPointWithdrawRequest {
    userId?: any
    pointStatus?: PointStatus
}


export class GetUserPointRequest extends PaginationPayloadModel {
    id?: any
    userId?: any
    earningStatus?: any
    earningType?: any
}


export class GetVendorPointRequest {
    id?: any
    searchQuery?: any
    userId?: any
    earningStatus?: any
    earningType?: any
}

export class GetVendorWalletRequest {
    userId?: any = 0;
}


export class GetUserPointResponse {
    id?: any
    earnedAmount?: any
    userId?: any
    userName?: any
    downlineId?: any
    downlineName?: any
    createdAt?: any
    orderId?: any
    orderDateTime?: any
    orderStatus?: any
    totalOrderProductCount?: any
    totalOrderAmount?: any
    earningType?: any
    earningStatus?: any
    earnedFrom?: any
}

export class GetCompanyPointResponse {
    id?: any
    earnedAmount?: any
    downlineId?: any
    downlineName?: any
    createdAt?: any
    orderId?: any
    orderDateTime?: any
    orderStatus?: any
    totalOrderProductCount?: any
    totalOrderAmount?: any
    earningType?: any
    earningStatus?: any
}

export class GetVendorPointResponse {
    id?: any
    earnedAmount?: any
    downlineId?: any
    downlineName?: any
    createdAt?: any
    orderId?: any
    orderDateTime?: any
    orderStatus?: any
    totalOrderProductCount?: any
    totalOrderAmount?: any
    earningType?: any
    earningStatus?: any
    earnedFrom?: any
}

export class GetUserWalletResponse {
    userId?: any;
    mainBalance?: any;
    requestedAmount?: any;
    totalEarning?: any

    resellingIncome?: any
    totalResellingIncome?: any
    resellingModeratorIncome?: any
    totalResellingModeratorIncome?: any
    resellingReferIncome?: any
    totalResellingReferIncome?: any
    resellingCourseIncome?: any
    totalResellingCourseIncome?: any
    resellingBackAmount?: any
    totalResellingBackAmount?: any

    affiliateCourseIncome?: any
    totalAffiliateCourseIncome?: any
    affiliateIncome?: any
    totalAffiliateIncome?: any
    affiliateReferIncome?: any
    totalAffiliateReferIncome?: any
    affiliateBackAmount?: any
    totalAffiliateBackAmount?: any

    superShopIncome?: any;
    totalSuperShopIncome?: any;

    superShopCAIncome?: any;
    totalSuperShopCAIncome?: any;

    totalWithdrawAmount?: any
    isTrainingAmountPaid?: boolean
}

export class GetCompanyWalletResponse {
    userId?: any
    mainBalance?: any
    totalEarning?: any
    totalSpent?: any

    resellingIncome?: any
    totalResellingIncome?: any
    resellingModeratorIncome?: any
    totalResellingModeratorIncome?: any
    resellingReferIncome?: any
    totalResellingReferIncome?: any
    resellingCourseIncome?: any
    totalResellingCourseIncome?: any
    resellingProductIncome?: any
    totalResellingProductIncome?: any

    affiliateCourseIncome?: any
    totalAffiliateCourseIncome?: any
    affiliateProductIncome?: any
    totalAffiliateProductIncome?: any
    affiliateCompanyIncome?: any
    totalAffiliateCompanyIncome?: any
    affiliateReferIncome?: any
    totalAffiliateReferIncome?: any

    pullIncome?: any
    totalPullIncome?: any
    dataOfferIncome?: any
    bechteChaiIncome?: any
    totalDataOfferIncome?: any
    totalWithdrawAmount?: any

    superShopIncome?: any;
    totalSuperShopIncome?: any;
}

export class GetWalletCalculationsResponse {
    totalWithdrawsCompany?: any
    totalMainBalanceCompany?: any
    totalPullIncomeCompany?: any
    totalAffiliateCourseIncomeCompany?: any
    totalResellingCourseIncomeCompany?: any

    totalWithdrawsVendor?: any
    totalMainBalanceVendor?: any
    totalAdvanceBalanceVendor?: any
    totalRechargeVendor?: any

    totalUserMainBalance?: any
    totalUserMainBalanceOver500?: any
    totalUserWithdraws?: any
    totalUserEarning?: any
    totalUserResellingIncome?: any
    totalUserResellingCourseIncome?: any
    totalUserResellingModeratorIncome?: any
    totalUserResellingReferIncome?: any
    totalUserResellingBackAmount?: any
    resellingIncomeUser?: any
    courseIncomeUser?: any
    moderatorIncomeUser?: any
    referIncomeUser?: any
    backAmountUser?: any
    totalUserRechargeUser?: any
}

export class GetVendorWalletResponse {
    advanceIncome?: any;
    commisionToPay?: any;
    mainBalance?: any;
    requestedAmount?: any;
    totalAdvanceIncome?: any;
    totalCommisionToPay?: any;
    totalWithdrawAmount?: any;
    userId?: any;
}

export class UpdateUserPointRequest {
    userPointId?: any;
    pointStatus?: any;
}

export class UpdateWalletRechargeNagadRequest {
    walletRechargeId?: any;
    action?: any;
}


export class UpdateVendorPointRequest {
    userPointId?: any;
    pointStatus?: any;
}


export enum EarningType {
    All = 0,

    ResellerEarning = 1,
    ResellingCourseEarning = 2,
    ResellingReferEarning = 3,
    ResellingModeratorEarning = 4,

    ProductEarning = 5,
    PullEarning = 6,
    ShippingCost = 7,
    AdvancedPaymentEarning = 8,
    PaymentBackEarning = 9,

    DataOffer_RefferEarning = 10,
    DataOfferEarning = 11,

    AffiliateProductEarning = 12,
    AffiliateCourseEarning = 13,
    AffiliateReferEarning = 14,
    AffiliateCompanyEarning = 15,
    AffiliatePaymentBackEarning = 16
}

export class UserPointWithdrawRequest {
    requestAmount: any
    paymentMethod: any
    earningType: any
    note: any
}

export class UserPullAmountRequest {
    amount?: any;
    earningType: any;
}

export class VendorPullAmountRequest {
    amount?: any;
    earningType: any;
}

export class GetUserPullHistoryRequest {
    userId?: any;
    earningType?: any;
}

export class GetVendorPullHistoryRequest {
    userId?: any;
    earningType?: any;
}

export class GetUserPullHistoryResponse {
    id?: any;
    userId?: any;
    userName?: any;
    dateTime?: any;
    pulledAmount?: any;
    earningType?: any;
}

export class GetVendorPullHistoryResponse {
    id?: any;
    userId?: any;
    userName?: any;
    dateTime?: any;
    pulledAmount?: any;
    earningType?: any;
}


export class GetWalletRechargeHistory {
    amount?: any;
    paymentMethods?: any;
    userId?: any;
    userName?: any;
    userRoleName?: any;
    transectionStatus?: any;
    dateTime?: any;
}

export class GetGiveBackHistory {
    amount?: any;
    paymentMethods?: any;
    userId?: any;
    userName?: any;
    userRoleName?: any;
    transectionStatus?: any;
    dateTime?: any;
}


export class GetUserPointWithdrawHistoryRequest {
    userId?: any;
    PointStatus?: any;
}


export class GetVendorPointWithdrawHistoryRequest extends PaginationPayloadModel {
    userId?: any;
    pointStatus?: any;
}

export class GetUserPointWithdrawHistoryResponse {
    id?: any;
    userId?: any;
    userName?: any;
    requestDateTime?: any;
    requestedAmount?: any;
    pointStatus?: any;
}

export class GetVendorPointWithdrawHistoryResponse {
    id?: any;
    userId?: any;
    userName?: any;
    requestDateTime?: any;
    requestedAmount?: any;
    pointStatus?: any;
}

export enum PointStatus {
    All = 0,
    InProgress = 1,
    Received = 2,
    Cancelled = 3
}

export class GetDataOfferVendorWalletResponse {
    dataOfferVendorId?: any
    mainBalance?: any
    advanceIncome?: any
    totalAdvanceBalance?: any
    requestedAmount?: any
    totalWithdrawAmount?: any;
    totalAdvanceIncome?: any;
    totalCommisionToPay?: any;
}


export class DataOfferOrderListRequest extends PaginationPayloadModel {
    userId?: any;
    dataOrderStatus?: DataOrderStatus;
}

export class ChangeDataOfferStatusRequest {
    orderId?: any;
    dataOrderStatus?: DataOrderStatus;
}

export class DataOfferListRequest extends PaginationPayloadModel {
    dataOfferType?: DataOfferType;
    dataPackageType?: DataPackageType;
    mobileOperator?: MobileOperator;
}


export class CreateDataOfferOrderRequest {
    dataOfferId?: any;
    phoneNumber?: any;
}

