import { sSGetSubCategoryByIdService, sSGetSubCategoryListService } from "../../../httpService/superShops/ssSubCategoryServices";
import { SSGetSubCategoryListRequest } from "../../../models/superShopp/catalog/sSCategory/ssSubCategoryModel";
import { SSGET_SUB_CATEGORY_LIST, SSGET_SUB_CATEGORY_BY_ID } from "../../constants/superShop/ssSubCategoryConstants";

export const sSGetSubCategoryListAction = (payload?: SSGetSubCategoryListRequest) => async (dispatch: any) => {
	var data = sSGetSubCategoryListService(payload);
	const pload = (await data).data;
	dispatch({ type: SSGET_SUB_CATEGORY_LIST, payload: pload });
};

export const sSGetSubCategoryByIdAction = (id: any) => async (dispatch: any) => {
	try {
		var data = sSGetSubCategoryByIdService(id);
		const payload = (await data).data;
		dispatch({ type: SSGET_SUB_CATEGORY_BY_ID, payload: payload });
	} catch (error: any) { }
};