import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ShoppingCartFavouriteModel } from '../../../../models/shoppingCart/resellingCartModels';
import { RootState } from '../../../../store';
import { sSGetFavouriteItemsAction } from '../../../../store/actions/superShop/sSFavouriteActions';
import { SSGetFavouriteItemsRequest } from '../../../../models/superShopp/favourite/sSFavouriteModel';
import { SSProductBox } from './SSProductBox';
import { AppLoader } from '../../../common/AppLoader';

export default function SSGetFavoutiteItems() {

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const favouriteItems = useSelector((state: RootState) => {
    return state.sSGetFavouriteItemsResponse.data;
  });

  const favItemsMemo = useMemo(() => {
    return favouriteItems as ShoppingCartFavouriteModel[];
  }, [favouriteItems]);

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        let model = new SSGetFavouriteItemsRequest();
        await dispatch(sSGetFavouriteItemsAction(model));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch, searchQuery]);

  if (isLoading) {
    return (
      <div className="container h-screen flex items-center justify-center mx-auto px-2 md:px-0 pb-12">
        <AppLoader status={true} />
      </div>
    );
  }

  return (
    <div className="container mx-auto md:pt-6 lg:pb-12 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28">
      <h3 className="title pb-4 text-center md:pb-8 px-4 md:px-0">Favorites</h3>
      <div className="productContainer">
        {favItemsMemo &&
          favItemsMemo?.map((x: any, index: any) => {
            return (
              <SSProductBox key={index}
                id={x.id}
                name={x.name}
                bannerPath={x.bannerPath}
                price={x.price}
                displayPrice={x?.displayPrice}
                oldPrice={x.oldPrice}
                totalSold={x.totalSold}
                isDisountProduct={x.isDisountProduct}
                discountText={x.discountText}
                totalReview={x.totalReview}
                totalView={x.totalView}
              />
            );
          })}
      </div>
    </div>
  );
}

