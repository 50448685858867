import { DELETE, GET, POST, PUT } from "../service";

export const addAcademicInstitutesService = (data: any) => {
	return POST("/api/AcademicInstitute/CreateAcademicInstitute", data);
};

export const updateAcademicInstitutesService = (data: any) => {
	return PUT("/api/AcademicInstitute/UpdateAcademicInstitute", data);
};

export const getAcademicInstitutesService = (payload: any) => {
	return GET(`/api/AcademicInstitute/GetAcademicInstitutes?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}&divisionId=${payload?.divisionId}&districtId=${payload?.districtId}&areaId=${payload?.areaId}&unionOrWardId=${payload?.unionOrWardId}`);
};

export const getAcademicInstitutesByIdService = (Id: any) => {
	return GET(`/api/AcademicInstitute/GetAcademicInstitute?Id=${Id}`)
};

export const deleteAcademicInstitutesService = (data: any) => {
	return DELETE(`/api/AcademicInstitute/RemoveAcademicInstitute`, { data: { "ids": data } });
};

export const addAcademicInstituteEmployeeService = (data: any) => {
	return POST("/api/AcademicInstitute/CreateAcademicInstituteEmployee", data);
};

export const updateAcademicInstituteEmployeeService = (data: any) => {
	return PUT("/api/AcademicInstitute/UpdateAcademicInstituteEmployee", data);
};

export const getAcademicInstituteEmployeesService = (payload: any) => {
	return GET(`/api/AcademicInstitute/GetAcademicInstituteEmployees?limit=${payload?.limit}&offset=${payload?.offset}&instituteId=${payload?.instituteId}&searchQuery=${payload?.searchQuery}`);
};
export const getTeachersService = (payload: any) => {
	return GET(`/api/AcademicInstitute/GetAcademicInstituteEmployees?limit=${payload?.limit}&offset=${payload?.offset}&instituteId=${payload?.instituteId}&searchQuery=${payload?.searchQuery}&divisionId=${payload?.divisionId}&districtId=${payload?.districtId}&areaId=${payload?.areaId}&unionOrWardId=${payload?.unionOrWardId}&type=teacher`);
};

export const getAcademicInstituteEmployeeByIdService = (Id: any) => {
	return GET(`/api/AcademicInstitute/GetAcademicInstituteEmployee?Id=${Id}`)
};

export const deleteAcademicInstituteEmployeeService = (data: any) => {
	return DELETE(`/api/AcademicInstitute/RemoveAcademicInstituteEmployee`, { data: { "ids": data } });
};