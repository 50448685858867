import { AppButton } from "../../common/AppButton";

interface IPrivacyPolicyProps {
    onClick: any;
}

export const PrivacyPolicy: React.FC<IPrivacyPolicyProps> = ({
    onClick
}) => {

    const handleOkButton = () => {
        onClick(true);
    }

    return (
        <div className="p-2 lg:px-4 lg:py-8 space-y-1">
            <h3 className="title text-center">টার্মস এন্ড কন্ডিশন</h3>
            <div className="py-2 px-4">
                <p>
                    ১। হ্যালোবাজার একটি মাল্টিভেন্ডর মার্কেটপ্লেস। এখানে একজনের প্রোডাক্ট এবং সার্ভিস অন্যজন টাকার বিনিময়ে নিয়ে থাকে।
                </p>
                <p>
                    ২। যেহেতু হ্যালোবাজার এর নিজস্ব কোনো প্রোডাক্ট বা সার্ভিস নাই, সুতরাং প্রোডাক্ট এবং সার্ভিস এর দায়বদ্ধতা সম্পূর্ণরূপে যোগানদাতা বা ভেন্ডরের উপরে বর্তাবে।
                </p>
                <p>
                    ৩। হ্যালোবাজার অবৈধ ও ক্ষতিকর প্রোডাক্ট এবং সার্ভিসের ব্যাপারে তদারকি সাপেক্ষে প্রয়োজনীয় আইনি ব্যবস্থা নিতে পারবে।
                </p>
                <p>
                    ৪। হ্যালোবাজার অবৈধ ও ক্ষতিকর প্রোডাক্ট এবং সার্ভিস এর ব্যাপারে কঠোর অসম্মতি প্রকাশ করে।
                </p>
                <p>
                    ৫। হ্যালোবাজারের উদ্দেশ্য বৈধ, সামাজিক ও উপকারী একটা মার্কেটপ্লেস তৈরি করা।
                </p>
                <p>
                    ৬। হ্যালোবাজার যেকোনো সময় তার শর্ত পরিবর্তন, পরিমার্জন ও পরিবর্ধন করতে পারবে।
                </p>
            </div>

            <p>
                আমাদের টার্মস এন্ড কন্ডিশন পড়ার জন্য আপনাকে ধন্যবাদ। আপনার কোন প্রশ্ন বা উদ্বেগ থাকলে, আমাদের সাথে যোগাযোগ করুন।
            </p>
            <div className='flex justify-end px-4'>
                <AppButton onClick={handleOkButton} text="Ok" />
            </div>
        </div>
    );
};
