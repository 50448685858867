const initialState = {
  signUp: false,
};

function signUpReducer(state = initialState, action: any) {
  if(action.type==="OPEN-SIGN-UP"){
    return {signUp:true}
  }
  if(action.type==="CLOSE-SIGN-UP"){
    return{signUp:false}
  }
  return state;
}

export { signUpReducer };
