interface IInputProps {
  label?: string;
  type?: any;
  onChange?: any;
  onKeyDown?: any;
  id?: string;
  placeholder?: string;
  value?: any;
  className?: string;
  errorMessage?: string;
  maxLength?: number;
  isDisabled?: boolean;
  isRequired?: boolean;
}

export const AppInput: React.FC<IInputProps> = ({
  label,
  type,
  placeholder,
  id,
  onChange,
  onKeyDown,
  value,
  className,
  errorMessage,
  maxLength,
  isDisabled,
  isRequired
}) => {

  const handleChange = (e: any) => {
    onChange(e.target.value);
  };

  const handleKeyDown = (e: any) => {
    if (onKeyDown)
      onKeyDown(e);
  };

  return (
    <div className="flex flex-col w-full gap-y-0 lg:gap-y-[2px]">
      <label className="md:text-base text-sm">{label} {isRequired && <span className="text-red text-lg">*</span>} </label>
      <input
        type={type}
        id={id}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        maxLength={maxLength}
        autoComplete="off" // add this line to disable autocomplete
        disabled={isDisabled}
        name={`${id}_${Math.random().toString(36).substring(7)}`}
        className={`${className ? className : "input-design"} always`}
      ></input>
      <p className="text-xxs lg:text-xs text-red pl-[2px]">{errorMessage}</p>
    </div>
  );
};
