import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppInput } from "../../../common/AppInput";
import { AppButton } from "../../../common/AppButton";
import { AppFileInput } from "../../../common/AppFileInput";
import { AppSelect } from "../../../common/AppSelect";
import { RootState } from "../../../../store";
import { AlertColor, IconButton } from "@mui/material";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { AppCheckBox } from "../../../common/AppCheckBox";
import { AppDescription } from "../../../common/AppDescription";
import { useNavigate, useParams } from "react-router-dom";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import DeleteIcon from "@mui/icons-material/Delete";
import { IdNameResponseModel } from "../../../../models/common/IdNameModel";
import { PaginationPayloadModel } from "../../../../models/common/paginationModels";
import {
  bechteChaiMainCategoryList,
  bechteChaiSubCategoryList,
} from "../../../../store/actions/bechteChai/bechteChaiCategoryActions";
import { areaListByDistrictId } from "../../../../store/actions/auth/address/areaActions";
import { divisionList } from "../../../../store/actions/auth/address/divisionActions";
import { districtListByDivisionId as districtListByDivisionId } from "../../../../store/actions/auth/address/districtActions";
import {
  AdminGetBechteChaiProductByIdModel,
  CreateUpdateBechteChaiProductRequest,
} from "../../../../models/bechteChai/product/bechteChaiProductModels";
import {
  addBechteChaiProductMediaService,
  getBechteChaiProductByIdService,
  removeBechteChaiProductMediaService,
  updateBechteChaiProductService,
} from "../../../../httpService/bechteChai/bechteChaiProductServices";
import { bechteChaiProductList } from "../../../../store/actions/bechteChai/bechteChaiProductActions";
import { MdClear } from "react-icons/md";
import { AddProductMedia } from "../../../../models/catalog/product/productMediaModels";

export default function BechteChaiUpdateProduct() {
  const navigate = useNavigate();

  var paginationModel = new PaginationPayloadModel();

  const [isOpenProductInfoTab, setIsOpenProductInfoTab] = useState(true);
  const [isOpenProductPriceTab, setIsOpenProductPriceTab] = useState(true);
  const [isOpenLocationTab, setIsOpenLocationTab] = useState(true);
  const [isOpenProductPicturesTab, setIsOpenProductPicturesTab] =
    useState(true);
  const [name, setName] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [fullDescription, setFullDescription] = useState("");
  const [isPublished, setIsPublished] = useState(false);
  const [isNegotiable, setIsNegotiable] = useState(false);
  const [mainCategoryId, setMainCategoryId] = useState(0);
  const [subCategoryLevelOneId, setSubCategoryLevelOneId] = useState(0);
  const [divisionId, setDivisionId] = useState(0);
  const [districtId, setDistrictId] = useState(0);
  const [areaId, setAreaId] = useState(0);
  const [divisionError, setDivisionError] = useState("");
  const [districtError, setDistrictError] = useState("");
  const [areaError, setAreaError] = useState("");
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [price, setPrice] = useState(0);
  const [bannerId, setBannerId] = useState(0);
  const [bannerPath, setBannerPath] = useState("");
  const [productMedias, setProductMedias] = useState([]);

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [videoUrl, setVideoUrl] = useState("");

  const { id } = useParams();

  const getProductById = async (id: any) => {
    const data = await getBechteChaiProductByIdService(id);
    const response: AdminGetBechteChaiProductByIdModel = data?.data;

    setName(response.name);
    setPhone(response.address);
    setAddress(response.address);
    setIsNegotiable(response.isNegotiable);
    setShortDescription(response.shortDescription);
    setFullDescription(response.fullDescription);
    setPrice(response.price);
    setProductMedias(response.productMediaList);

    setMainCategoryId(
      response.bechteChaiMainCategoryId != null
        ? response.bechteChaiMainCategoryId
        : 0
    );

    setSubCategoryLevelOneId(
      response.bechteChaiSubCategoryId != null
        ? response.bechteChaiSubCategoryId
        : 0
    );
    setIsPublished(response.isPublished);
    setDivisionId(response.divisionId != null ? response.divisionId : 0);
    setDistrictId(response.districtId != null ? response.districtId : 0);
    setAreaId(response.areaId != null ? response.areaId : 0);

    dispatch(bechteChaiMainCategoryList(paginationModel));
    dispatch(
      bechteChaiSubCategoryList(
        response.bechteChaiMainCategoryId,
        paginationModel,
        true
      )
    );
  };

  useEffect(() => {
    getProductById(id);
  }, []);

  var paginationModel = new PaginationPayloadModel();

  var mainCatList = useSelector((state: RootState) => {
    return state.bechteChaiMainCategoryListResponse.data;
  });

  var mCatIdNameModels: IdNameResponseModel[] = [];

  mainCatList.items?.map((x: any) => {
    var mainCategory = new IdNameResponseModel();
    mainCategory.id = x.id;
    mainCategory.name = x.name;
    mCatIdNameModels.push(mainCategory);
  });
  var subCatLevelOneList = useSelector((state: RootState) => {
    return state.bechteChaiSubCategoryListResponse.data;
  });

  var subCatLvlOneIdNameModels: IdNameResponseModel[] = [];

  subCatLevelOneList.items?.map((x: any) => {
    var subCatLevelOne = new IdNameResponseModel();
    subCatLevelOne.id = x.id;
    subCatLevelOne.name = x.name;
    subCatLvlOneIdNameModels.push(subCatLevelOne);
  });

  const divisions = useSelector((state: RootState) => {
    return state.divisionListResponse.data;
  });
  var divisionIdNameModels: IdNameResponseModel[] = [];

  divisions?.map((x: any) => {
    var division = new IdNameResponseModel();
    division.id = x.id;
    division.name = x.name;
    divisionIdNameModels.push(division);
  });

  const districts = useSelector((state: RootState) => {
    return state.districtListResponse.data;
  });
  var districtsIdNameModels: IdNameResponseModel[] = [];

  districts?.map((x: any) => {
    var district = new IdNameResponseModel();
    district.id = x.id;
    district.name = x.name;
    districtsIdNameModels.push(district);
  });

  const areass = useSelector((state: RootState) => {
    return state.areaListResponse.data;
  });
  var areaIdNameModels: IdNameResponseModel[] = [];

  areass?.map((x: any) => {
    var area = new IdNameResponseModel();
    area.id = x.id;
    area.name = x.name;
    areaIdNameModels.push(area);
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(bechteChaiMainCategoryList(paginationModel));
  }, [dispatch]);

  useEffect(() => {
    dispatch(bechteChaiSubCategoryList(mainCategoryId, paginationModel, true));
  }, [dispatch, mainCategoryId]);

  useEffect(() => {
    dispatch(divisionList());
  }, [dispatch]);

  useEffect(() => {
    if (divisionId !== 0 && divisionId != null) {
      dispatch(districtListByDivisionId(divisionId));
    }
  }, [divisionId, dispatch]);

  useEffect(() => {
    if (districtId !== 0 && districtId != null) {
      dispatch(areaListByDistrictId(districtId));
    }
  }, [districtId, dispatch]);

  const handleBanner = (id: any, path: any) => {
    setBannerId(id);
    setBannerPath(path);
    setBannerError("");
    console.log(id);
    console.log(path);
  };

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleSubmit = (e: any, isVariationAdd: boolean) => {
    e.preventDefault();

    var model = new CreateUpdateBechteChaiProductRequest();
    model.id = id;
    model.name = name;
    model.shortDescription = shortDescription;
    model.fullDescription = fullDescription;
    model.address = address;
    model.phone = phone;
    model.isPublished = isPublished;
    model.isNegotiable = isNegotiable;
    model.bechteChaiMainCategoryId = mainCategoryId;
    model.bechteChaiSubCategoryId = subCategoryLevelOneId;
    model.divisionId = divisionId;
    model.districtId = districtId;
    model.areaId = areaId;
    model.price = price;
    model.bannerId = bannerId;
    model.bannerPath = bannerPath;
    model.videoUrl = videoUrl;

    setIsButtonLoading(true);

    updateBechteChaiProductService(model)
      .then((res: any) => {
        setErrors({});
        setIsButtonLoading(false);
        setSnackBarOn(true);
        setsnackBarMessage("Product Updated Successfully");
        setSnackBarMessageType("success");

        navigate(`/admin/bechte-chai/products`);
        dispatch(bechteChaiProductList());
      })
      .catch((error) => {
        setIsButtonLoading(false);
        setNameError(error.response.data.errors?.Name?.[0]);
        setShortDescriptionError(
          error.response.data.errors?.ShortDescription?.[0]
        );
        setFullDescriptionError(
          error.response.data.errors?.FullDescription?.[0]
        );
        setMainCategoryError(error.response.data.errors?.MainCategoryId?.[0]);
        setSubCategoryLevelOneError(
          error.response.data.errors?.SubCategoryLevelOneId?.[0]
        );
        setBannerError(error.response.data.errors?.BannerId?.[0]);
        setPriceError(error.response.data.errors?.Price?.[0]);
      });
  };
  const [nameError, setNameError] = useState("");
  const [shortDescriptionError, setShortDescriptionError] = useState("");
  const [fullDescriptionError, setFullDescriptionError] = useState("");
  const [mainCategoryError, setMainCategoryError] = useState("");
  const [subCategoryLevelOneError, setSubCategoryLevelOneError] = useState("");
  const [bannerError, setBannerError] = useState("");
  const [priceError, setPriceError] = useState("");

  const handleProductName = (value: any) => {
    setName(value);
    setNameError("");
  };

  const handleProdcutShortDescription = (value: any) => {
    setShortDescription(value);
    setShortDescriptionError("");
  };

  const handleProdcutFullDescription = (value: any) => {
    setFullDescription(value);
    setFullDescriptionError("");
  };

  const handlePhone = (value: string) => {
    setPhoneError("");
    setPhone(value);
  };
  const handleAddress = (value: string) => {
    setAddressError("");
    setAddress(value);
  };
  const handleProductPrice = (value: any) => {
    setPrice(value);
    setPriceError("");
  };
  const handleDivision = (id: any) => {
    setDivisionError("");
    setDivisionId(id);
    setDistrictId(0);
    setAreaId(0);
  };

  const handleDistrict = (id: any) => {
    setDistrictError("");
    setDistrictId(id);
    setAreaId(0);
  };

  const handleArea = (id: any) => {
    setAreaError("");
    setAreaId(id);
  };

  const handleMainCategory = (mainCatId: number) => {
    setMainCategoryId(mainCatId);
    setMainCategoryError("");
    setSubCategoryLevelOneId(0);
  };

  const handleSubCategoryLevelOne = (subCatLevelOneId: number) => {
    setSubCategoryLevelOneId(subCatLevelOneId);
    setSubCategoryLevelOneError("");
  };

  const deleteBanner = () => {
    setBannerId(0);
    setBannerPath("");
    setBannerError("");
  };

  const [isPhotoSaveButtonLoading, setIsPhotoSaveButtonLoading] =
    useState(false);

  const saveProductPhoto = () => {
    var model = new AddProductMedia();
    model.bannerId = bannerId;
    model.productId = id;
    setIsPhotoSaveButtonLoading(true);
    addBechteChaiProductMediaService(model)
      .then((response) => {
        setIsPhotoSaveButtonLoading(false);
        getProductById(id);
      })
      .catch((error: any) => {
        setIsPhotoSaveButtonLoading(false);
        setBannerError(error.response.data.errors?.BannerId?.[0]);
      });
    setBannerId(0);
    setBannerPath("");
  };

  const photos = productMedias?.map((productMedia: any) => (
    <div className="relative">
      <button
        className="absolute -right-1 top-0 flex h-6 w-6 items-center justify-center rounded-full bg-primary text-xl text-white"
        onClick={() => handlePhotoDelete(productMedia.media.id)}
      >
        <MdClear />
      </button>
      <img
        className="-z-10 h-36 w-36 object-contain lg:h-40 lg:w-40"
        alt=""
        src={productMedia?.url}
      />
    </div>
  ));

  const handlePhotoDelete = (mediaId: any) => {
    if (productMedias.length === 1) {
      setSnackBarOn(true);
      setsnackBarMessage("Can't remove all Photo");
      setSnackBarMessageType("error");
    } else {
      var model = new AddProductMedia();
      model.bannerId = mediaId;
      model.productId = id;
      removeBechteChaiProductMediaService(model)
        .then((response) => {
          getProductById(id);
        })
        .catch((error: any) => {
          setBannerError(error.response.data.errors?.BannerId?.[0]);
        });
    }
  };

  const handleCollapseAll = () => {
    setIsOpenProductInfoTab(false);
    setIsOpenProductPriceTab(false);
    setIsOpenLocationTab(false);
    setIsOpenProductPicturesTab(false);
  };

  return (
    <div className="pb-6 lg:px-4">
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}

      <h2 className="title text-center">Update Product</h2>
      {/* save and collapse all btn  */}
      <div className="flex items-center justify-end py-1 lg:py-2">
        <AppButton text="CollapseAll" onClick={handleCollapseAll} />
      </div>

      {/* product info collapsible div here  */}
      <div
        className="my-1 flex cursor-pointer items-center justify-between rounded bg-primary px-4 py-0.5 text-white lg:py-1 lg:text-xl"
        onClick={() => setIsOpenProductInfoTab(!isOpenProductInfoTab)}
      >
        <h3>Product Info</h3>
        <h3>
          {isOpenProductInfoTab ? (
            <FiMinusCircle className="cursor-pointer" />
          ) : (
            <FiPlusCircle className="cursor-pointer" />
          )}
        </h3>
      </div>

      <div className={`${!isOpenProductInfoTab ? "hidden" : ""}`}>
        <div className="flex flex-col items-center justify-between gap-2 lg:flex-row lg:gap-12">
          <AppInput
            type="text"
            className="myInput"
            label="Name"
            placeholder="Enter Product Name"
            value={name}
            onChange={handleProductName}
            errorMessage={nameError}
          />
        </div>
        <div className="grid grid-cols-1 gap-2 py-2 lg:grid-cols-2 lg:gap-8">
          <div className="">
            <label className="text-sm md:text-base">
              Select Main Category:{" "}
            </label>
            <AppSelect
              defaultValue="Select Main Category"
              value={mainCategoryId}
              data={mCatIdNameModels}
              onChange={handleMainCategory}
              errorMessage={mainCategoryError}
            />
          </div>
          {mainCategoryId !== 0 && (
            <div className="">
              <label className="text-sm md:text-base">Sub Category: </label>
              <AppSelect
                defaultValue="Select Sub Category "
                value={subCategoryLevelOneId}
                data={subCatLvlOneIdNameModels}
                onChange={handleSubCategoryLevelOne}
                errorMessage={subCategoryLevelOneError}
              />
            </div>
          )}
        </div>

        <AppDescription
          value={shortDescription}
          label="Short Description"
          onChange={handleProdcutShortDescription}
          errorMessage={shortDescriptionError}
        />

        <AppDescription
          value={fullDescription}
          label="Full Description"
          onChange={handleProdcutFullDescription}
          errorMessage={fullDescriptionError}
        />

        <div className="flex items-center justify-start py-2">
          <div className="flex items-center gap-x-2">
            <label htmlFor="isPublished" className="text-xl">
              Publish
            </label>
            <AppCheckBox
              isChecked={isPublished}
              onChange={(value: any) => setIsPublished(value)}
            />
          </div>
        </div>
      </div>
      {/* these is price collapsible div  */}
      <div
        className="my-2 flex cursor-pointer items-center justify-between rounded bg-primary px-4 py-0.5 text-white lg:py-1 lg:text-xl"
        onClick={() => setIsOpenProductPriceTab(!isOpenProductPriceTab)}
      >
        <h3>Prices</h3>
        <h3>
          {isOpenProductPriceTab ? (
            <FiMinusCircle className="cursor-pointer" />
          ) : (
            <FiPlusCircle className="cursor-pointer" />
          )}
        </h3>
      </div>

      {/* these is old price and new price div  */}
      <div
        className={`grid grid-cols-1 gap-2 py-2 lg:grid-cols-2 lg:gap-8 ${
          !isOpenProductPriceTab ? "hidden" : ""
        }`}
      >
        <AppInput
          className="myInput"
          type="number"
          label="Your Price"
          value={price}
          placeholder="Enter Your Product Price"
          onChange={handleProductPrice}
          errorMessage={priceError}
        />
        <div className="flex items-center justify-center gap-x-2">
          <label htmlFor="isNegotiable" className="text-xl">
            Negotiable
          </label>
          <AppCheckBox
            isChecked={isNegotiable}
            onChange={(value: any) => setIsNegotiable(value)}
          />
        </div>
      </div>

      {/* Location collapsible div  */}
      <div
        className="my-2 flex cursor-pointer items-center justify-between rounded bg-primary px-4 py-0.5 text-white lg:py-1 lg:text-xl"
        onClick={() => setIsOpenLocationTab(!isOpenLocationTab)}
      >
        <h3>Location</h3>
        <h3>
          {isOpenLocationTab ? (
            <FiMinusCircle className="cursor-pointer" />
          ) : (
            <FiPlusCircle className="cursor-pointer" />
          )}
        </h3>
      </div>
      {/*Location div  */}
      <div
        className={`grid grid-cols-1 gap-2 py-2 lg:grid-cols-2 lg:gap-8 ${
          !isOpenLocationTab ? "hidden" : ""
        }`}
      >
        <AppInput
          className="myInput"
          label="Phone"
          type="text"
          placeholder="Enter your phone"
          value={phone}
          onChange={handlePhone}
          errorMessage={phoneError}
        />
        <AppInput
          className="myInput"
          label="Address"
          type="text"
          placeholder="Enter your address"
          value={address}
          onChange={handleAddress}
          errorMessage={addressError}
        />

        <div>
          <label
            className="text-base md:text-lg 2xl:text-xl"
            placeholder="Dhaka"
          >
            Division*
          </label>
          <AppSelect
            defaultValue="Select Division"
            data={divisionIdNameModels}
            value={divisionId}
            onChange={handleDivision}
            errorMessage={divisionError}
          />
        </div>

        {divisionId !== 0 && (
          <div>
            <label
              className="text-base md:text-lg 2xl:text-xl"
              placeholder="Dhaka"
            >
              District*
            </label>
            <AppSelect
              defaultValue="Select District"
              data={districtsIdNameModels}
              value={districtId}
              onChange={handleDistrict}
              errorMessage={districtError}
            />
          </div>
        )}

        {divisionId !== 0 && districtId !== 0 && (
          <div>
            <label
              className="text-base md:text-lg 2xl:text-xl"
              placeholder="Dhaka"
            >
              Area
            </label>
            <AppSelect
              defaultValue="Select Area"
              data={areaIdNameModels}
              value={areaId}
              onChange={handleArea}
              errorMessage={areaError}
            />
          </div>
        )}
      </div>

      {/* these is picture collapsible div  */}
      <div
        className="my-2 flex cursor-pointer items-center justify-between rounded bg-primary px-4 py-0.5 text-white lg:py-1 lg:text-xl"
        onClick={() => setIsOpenProductPicturesTab(!isOpenProductPicturesTab)}
      >
        <h3>Pictures</h3>
        <h3>
          {isOpenProductPicturesTab ? (
            <FiMinusCircle className="cursor-pointer" />
          ) : (
            <FiPlusCircle className="cursor-pointer" />
          )}
        </h3>
      </div>
      {/* these is upload picture section  */}
      <div
        className={`flex flex-col items-center gap-x-2 gap-y-2 lg:flex-row ${
          !isOpenProductPicturesTab ? " hidden" : ""
        }`}
      >
        <div className="flex flex-wrap gap-2">{photos}</div>
        <div className="flex gap-1">
          {!bannerId && (
            <AppFileInput onSave={handleBanner} errorMessage={bannerError} />
          )}
        </div>
        {bannerId !== 0 && (
          <>
            <div className="flex items-center gap-3">
              <img className="h-40 w-40 object-cover" src={bannerPath} alt="" />
              <IconButton onClick={deleteBanner}>
                <DeleteIcon />
              </IconButton>
            </div>
            <div className="flex items-center">
              <AppButton
                text="Save Photo"
                isSubmitting={isPhotoSaveButtonLoading}
                className="myBtn"
                onClick={saveProductPhoto}
              />
            </div>
          </>
        )}
      </div>

      {/* these is save button  */}
      <div className="flex items-center justify-between gap-4 py-2 lg:justify-end">
        <AppButton
          text="Save"
          variant="contained"
          isSubmitting={isButtonLoading}
          color="primary"
          onClick={(e: any) => handleSubmit(e, false)}
        />
      </div>
    </div>
  );
}
