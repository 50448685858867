import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../store";
import { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { getNoticeByIdAction } from "../../../store/actions/notice/noticeAction";
import { getUseRoleListAction } from "../../../store/actions/auth/address/userRoleActions";
import { AppButton } from "../../common/AppButton";
import { removeNoticeService } from "../../../httpService/notice/noticeServices";
import { AppModal } from "../../common/AppModal";

export default function AdminGetNoticeById() {

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { id } = useParams();

	const notice = useSelector((state: RootState) => {
		return state.getNoticeByIdResponse.data;
	});

	const roles = useSelector((state: RootState) => {
		return state.userRoleListResponse.userRole;
	});

	useEffect(() => {
		dispatch(getNoticeByIdAction(id));
		dispatch(getUseRoleListAction());
	}, []);

	const handleNoticeUpdate = () => {
		navigate(`/admin/notice/notice-update/${id}`);
	}

	const [isDeleteButtonLoading, setIsDeleteButtonLoading] = useState(false);
	const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

	const openDeleteModal = () => {
		setIsOpenDeleteModal(true);
	}

	const closeDeleteModal = () => {
		setIsOpenDeleteModal(false)
	}

	const handleDeleteNotice = () => {
		var model = {
			id: id
		}
		setIsDeleteButtonLoading(true);
		removeNoticeService(model)
			.then(() => {
				navigate(`/admin/notice/notice-list`);
				setIsDeleteButtonLoading(false);
				closeDeleteModal()
			}).catch(() => {
				setIsDeleteButtonLoading(false);
			})
	};

	const roleNameList = (selectedRoles: any, allRoles: any) => {
		var isFirstRoleName = true;
		var roleName = "";
		for (var selectedIdx = 0; selectedIdx < selectedRoles?.length; selectedIdx++) {
			for (var allIdx = 0; allIdx < allRoles?.length; allIdx++) {
				if (allRoles[allIdx].id == selectedRoles[selectedIdx]) {
					if (isFirstRoleName) {
						isFirstRoleName = false;
					} else {
						roleName += ", ";
					}
					roleName += allRoles[allIdx].name;
					break;
				}
			}
		}
		return roleName;
	}

	return (
		<div className="container mx-auto lg:py-4">
			{isOpenDeleteModal && (
				<AppModal>
					<div className='flex flex-col gap-4 text-2xl max-w-md text-center p-4'>
						Are you sure you want to Delete this Notice?
						<div className='flex items-center justify-center gap-4'>
							<AppButton text="Yes" onClick={handleDeleteNotice} />
							<AppButton text="No" onClick={closeDeleteModal} />
						</div></div>
				</AppModal>
			)}
			<Link to="/admin/notice/notice-list">
				<h3 className="flex items-center gap-x-1 text-lg font-semibold text-royalBlue">
					<BiArrowBack /> Back to Notice Lists
				</h3>{" "}
			</Link>
			<h2 className="title text-center">Notice details Information</h2>

			<div className="px-2 py-2 lg:px-20 lg:py-4">
				<div className="myBorder mt-2 grid grid-cols-1 gap-1 p-2 font-medium lg:mt-4 lg:grid-cols-1 lg:gap-2 lg:gap-x-8 lg:gap-y-2 lg:p-4">
					<div className="myBorder w-full px-2 py-1 lg:px-4">
						<p>
							Title: <span className="font-medium"> {notice?.title}</span>
						</p>
					</div>
					<div className="myBorder w-full px-2 py-1 lg:px-4">
						<p>
							Description:{" "}
							<div dangerouslySetInnerHTML={{ __html: notice?.description }} />
						</p>
					</div>
					<div className="myBorder w-full px-2 py-1 lg:px-4">
						<p>
							Roles: <span className="font-medium"> {roleNameList(notice?.userRoles, roles)}</span>
						</p>
					</div>
					<div className="myBorder w-full px-2 py-1 lg:px-4">
						<AppButton
							text="Update"
							className="myOfferBtn"
							onClick={() => handleNoticeUpdate()}
						/>
						<AppButton
							text="Delete"
							className="myOfferBtn bg-red border-red"
							onClick={() => openDeleteModal()}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}