import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { reactLocalStorage } from "reactjs-localstorage";
import { useNavigate } from "react-router-dom";
import { HomeProductFilterEnum } from "../../../models/catalog/product/productsModels";
import { AffiliateProductContainer } from "./AffiliateProductContainer";
import { isAdmin, isRoleExist, isVendor } from "../../../common/common";
import { Link } from "react-router-dom";
import { FaHandPointDown } from "react-icons/fa";

const AffiliateHomeComponent = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ip = reactLocalStorage.get("ip");

  useEffect(() => {
    if (!ip) {
      axios
        .get("https://api.ipify.org?format=json")
        .then((response) => {
          reactLocalStorage.set("ip", response.data.ip)
        })
        .catch(() => {
        });
    }
  }, [dispatch, ip]);

  const isRoleHave = isRoleExist(reactLocalStorage.get("RoleName"))

  useEffect(() => {
    if (isAdmin(reactLocalStorage.get("RoleName")) || isVendor(reactLocalStorage.get("RoleName"))) {
      navigate(`/admin`);
    }
  }, [dispatch, navigate, isRoleHave]);

  const [currentFilter, setCurrentFilter] = useState(0);
  const [filterOn, setFilterOn] = useState(true);

  const handleProductFilter = (type: HomeProductFilterEnum) => {
    setFilterOn(false);
    setTimeout(() => {
      setFilterOn(true);
    }, 1000)
    setCurrentFilter(type);
  }

  const productFilter = [
    {
      text: "New Upload",
      handler: () => handleProductFilter(HomeProductFilterEnum.NewUpload),
      img: '/assets/Affiliate/New Uploade.png',
    },
    {
      text: "Top Sold ",
      handler: () => handleProductFilter(HomeProductFilterEnum.TopSold),
      img: '/assets/Affiliate/Top Sold.png',
    },
    {
      text: "Top Commented",
      handler: () => handleProductFilter(HomeProductFilterEnum.TopReviewed),
      img: '/assets/Affiliate/Top Comment.png',
    },
    {
      text: "Top Viewed",
      handler: () => handleProductFilter(HomeProductFilterEnum.TopViewed),
      img: '/assets/Affiliate/Top Viewed.png',
    },
    {
      text: "Top Favourites",
      handler: () => handleProductFilter(HomeProductFilterEnum.TopFavourites),
      img: '/assets/Affiliate/Favourite.png',
    },
  ]

  return (
    <div className="container mx-auto items-center 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28 ">

      {/* these is home top section  */}
      <div className="flex gap-2 lg:gap-4 h-[16vh] lg:h-[50vh] box-border">
        <img
          src="/assets/Affiliate/Aff_w.png"
          className="w-[66%] lg:w-[70%] rounded lg:rounded-xl object-fill"
          alt="These is home banner"
          loading="lazy"
        />
        <div className="w-[32%] lg:w-[30%] rounded lg:rounded-xl flex items-center justify-center flex-col gap-1 lg:gap-4 bg-[#BA2934] p-4 md:p-8">
          <h2 className="text-xs md:text-lg lg:text-3xl font-bangla lg:font-semibold text-white text-center">আপনি কি এফিলিয়েটর হতে চান? তাহলে Affiliate Training  কিনুন এই বাটন এ ক্লিক করে <FaHandPointDown className="inline-block" /></h2>
          <button className="affiliateBtn text-xs lg:text-lg"><Link to={'/training'}>Training</Link></button>
        </div>
      </div>

      <div className='lg:px-12 pt-4 lg:pt-8 lg:pb-4 grid grid-cols-5 gap-1 md:gap-2 lg:gap-6 2xl:gap-8'>
        {
          productFilter.map(product => (
            <div
              key={product?.text}
              onClick={product?.handler}
              className="centerDiv cursor-pointer flex-col gap-1 lg:gap-0.5"
            >
              <div className='bg-gray-200 centerDiv p-2 lg:p-6 rounded-full'>
                <img
                  className="w-10 lg:w-24 2xl:w-24 h-10 lg:h-24 2xl:h-24"
                  src={product?.img}
                  alt={`This for ${product?.text}`}
                />
              </div>
              <h2 className="select-none text-center text-xxs text-black md:text-xs lg:text-lg lg:font-semibold 2xl:text-xl">
                {product?.text}
              </h2>
            </div>
          ))
        }
      </div>

      {filterOn &&
        <AffiliateProductContainer type={currentFilter} />
      }
    </div>
  );
}

export default AffiliateHomeComponent;
