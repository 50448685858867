import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { RootState } from "../../../store";
import { reactLocalStorage } from "reactjs-localstorage";
import { AppSearchBox } from "../../common/AppSearchBox";
import {
  getUserProfileAction,
  getUserProfileNameAction,
} from "../../../store/actions/auth/user/userActions";
import { BiCategoryAlt, BiHomeAlt2, BiSearchAlt2 } from 'react-icons/bi';
import { CgMenu } from "react-icons/cg";
import { MdOutlineShoppingCart } from "react-icons/md";
import { getAffiliateCartItemsAction, getAffiliateTotalShoppingCartItemAction } from "../../../store/actions/cart/affiliateCartActions";
import { RESELLING_PRODUCT_FILTER } from "../../../store/constants/catalog/resellingProductConstants";
import { ShoppingCartFavouriteModel } from "../../../models/shoppingCart/resellingCartModels";
import Navbar from "../../landingPage/Navbar";

export default function AffiliateNavBar() {

  const location = useLocation();

  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 100);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  const navigate = useNavigate();

  const dispatch = useDispatch();

  var total = 0;

  var pItems: any;

  const cartItemsSignIn = useSelector((state: RootState) => {
    return state.affiliateGetTotalCartItemListResponse.data;
  });

  var token = reactLocalStorage.getObject("SignIn");

  if (token && JSON.stringify(token) !== JSON.stringify({})) {
    pItems = cartItemsSignIn as ShoppingCartFavouriteModel[];
    if (pItems !== undefined && pItems !== null && pItems?.length && pItems) {
      pItems?.map((x: any) => {
        total = total + (x.productQuantity as number);
      });
    }
  }

  let totalCartItemCount = useSelector((state: RootState) => {
    return state.affiliateGetTotalCartItemResponse;
  });

  let totalCartItem = totalCartItemCount.data >= 0 ? totalCartItemCount.data : total;

  useEffect(() => {
    if (token && JSON.stringify(token) !== JSON.stringify({})) {
      if (cartItemsSignIn) {
        var count = 0;
        count = cartItemsSignIn?.reduce((q: any, p: any) => (q + p.productQuantity) as number, 0);
        dispatch(getAffiliateTotalShoppingCartItemAction(count));
      }
    }
  }, [dispatch, cartItemsSignIn]);


  useEffect(() => {
    dispatch(getAffiliateCartItemsAction());
  }, [dispatch]);

  const userProfile = useSelector((state: RootState) => {
    return state.getUserProfileResponse.data;
  });

  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);


  useEffect(() => {
    dispatch(getUserProfileNameAction(userProfile?.firstName));
  }, [dispatch, userProfile]);

  const openSignIn = () => {
    document.body.classList.add('noScroll');
    dispatch({ type: "OPEN-SIGN-IN" });
    dispatch({ type: "CLOSE-SIGN-UP" });
  };

  const openExplore = () => {
    dispatch({ type: "OPEN-EXPLORE" });
  };

  const openLeftDrawer = () => {
    dispatch({ type: "OPEN-LEFT-MENU" });
  }

  const [searchQuery, setSearchQuery] = useState("");

  const onSearchQueryUpdate = (query: any) => {
    dispatch({ type: RESELLING_PRODUCT_FILTER, payload: query });
    setSearchQuery(query);
  }

  const handleSearchProduct = (query: any) => {
    navigate(`/affiliate/filterProductList/${0}/${0}?searchQuery=${searchQuery}`);
  }

  const [targetRoute, setTargetRoute] = useState("/");

  const navigateToHome = () => {
    setSearchQuery("")
  };

  const navigateToShoppingCart = () => {
    setTargetRoute("/affiliate/shoppingcart")
    if (token && JSON.stringify(token) !== JSON.stringify({})) {
      setSearchQuery("")
    } else {
      dispatch({ type: "OPEN-SIGN-IN" });
      dispatch({ type: "CLOSE-SIGN-UP" });
    }
  };

  const [isShowNotification, setIsShowNotification] = useState(false);

  const notificationRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isShowNotification) {
      document.addEventListener('click', handleDocumentClick);
    }
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isShowNotification]);

  const handleDocumentClick = (event: MouseEvent) => {
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target as Node)
    ) {
      setIsShowNotification(false);
    } else {
      setIsShowNotification(true);
    }
  };

  const [searchIconVisiable, setSearchIconVisiable] = useState(true);
  const [searchBarVisiable, setSearchBarVisiable] = useState(false);

  const handleSearchIconVisiable = () => {
    if (searchIconVisiable) {
      setSearchIconVisiable(false)
      setSearchBarVisiable(true)
    }
  }

  return (
    <>
      <div className={`${sticky ? "stickyNav" : ""}`}>

        <Navbar />

        {(JSON.stringify(token) !== JSON.stringify({})) && (
          <>
            {location?.pathname?.includes('affiliate') &&

              <div className="border-b border-grey">
                <div className="container mx-auto flex gap-2 justify-between items-center 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28 ">
                  {/* training and search side start here  */}
                  <div className="flex items-center gap-x-3 md:gap-x-6 2xl:gap-x-8">
                    {/* these is menu section  */}
                    <div className="border shadow-sm border-grey 2xl:p-2 md:p-1.5 p-1 rounded cursor-pointer" onClick={openLeftDrawer}>
                      <CgMenu className="text-affiliate cursor-pointer 2xl:text-3xl md:text-2xl text-xl font-bold" />
                    </div>
                    {/* these is home button */}
                    <div title="Home" onClick={navigateToHome} className="border shadow-sm border-grey 2xl:p-2 md:p-1.5 p-1 rounded cursor-pointer">
                      <Link to="/affiliate">
                        <BiHomeAlt2 className="text-affiliate cursor-pointer 2xl:text-3xl md:text-2xl text-xl font-bold" />
                      </Link>
                    </div>

                  </div>
                  {/* all category start here  */}
                  <div className="hidden md:flex items-center gap-x-6 2xl:gap-x-8" >
                    {/* these is short search section  */}
                    {searchIconVisiable && (
                      <div onClick={handleSearchIconVisiable} className="border shadow-sm border-grey 2xl:p-2 md:p-1.5 p-1 rounded cursor-pointer">
                        <BiSearchAlt2 className="text-affiliate cursor-pointer 2xl:text-3xl md:text-2xl text-xl font-bold" />
                      </div>
                    )}
                    {searchBarVisiable && (
                      <div className="flex items-center border shadow-sm border-grey 2xl:p-2 md:p-1.5 p-1 rounded cursor-pointer">
                        <AppSearchBox onChange={onSearchQueryUpdate} className="border-none w-[25dvw] px-2 text-sm md:text-base" />
                        <BiSearchAlt2 onClick={handleSearchProduct} className="text-affiliate cursor-pointer 2xl:text-3xl md:text-2xl text-xl font-bold" />
                      </div>
                    )}
                    {/* these is all category section for desktop  */}
                    <div onClick={openExplore} className="2xl:text-xl text-lg px-10 2xl:py-2 py-1 border border-grey rounded font-semibold hover:bg-affiliate shadow-sm hover:text-white cursor-pointer">All Category</div>
                    {/* these is cart section  */}
                    <div title="Cart" onClick={navigateToShoppingCart} className="relative border shadow-sm border-grey 2xl:p-2 md:p-1.5 p-1 rounded cursor-pointer">
                      <Link to="/affiliate/shoppingcart" >
                        <MdOutlineShoppingCart className="text-affiliate cursor-pointer 2xl:text-3xl md:text-2xl text-xl font-bold" />
                        <p className={`${totalCartItem > 0 ? 'cartNotification' : ''}`}>{totalCartItem > 0 ? totalCartItem : ""}</p>
                      </Link>
                    </div>
                  </div>

                  {/* these is icon for mobile  */}
                  <div className="md:hidden flex items-center gap-3">
                    {/* these is short search section  */}
                    {searchIconVisiable && (
                      <div onClick={handleSearchIconVisiable} className="border shadow-sm border-grey 2xl:p-2 md:p-1.5 p-1 rounded cursor-pointer">
                        <BiSearchAlt2 className="text-affiliate cursor-pointer 2xl:text-3xl md:text-2xl text-xl font-bold" />
                      </div>
                    )}
                    {searchBarVisiable && (
                      <div className="flex items-center border shadow-sm border-grey 2xl:p-2 md:p-1.5 p-1 rounded cursor-pointer">
                        <AppSearchBox onChange={onSearchQueryUpdate} className="border-none w-[25dvw] px-2 text-sm md:text-base" />
                        <BiSearchAlt2 onClick={handleSearchProduct} className="text-affiliate cursor-pointer 2xl:text-3xl md:text-2xl text-xl font-bold" />
                      </div>
                    )}
                    {/* these is category for mobile  */}
                    <div onClick={openExplore} className="border shadow-sm border-grey p-1 rounded cursor-pointer">
                      <BiCategoryAlt className="text-affiliate cursor-pointer 2xl:text-3xl md:text-2xl text-xl font-bold" />
                    </div>
                    {/* these is cart section for mobile */}
                    <div onClick={navigateToShoppingCart} className="relative border shadow-sm border-grey 2xl:p-2 md:p-1.5 p-1 rounded cursor-pointer">
                      <Link to="/affiliate/shoppingcart" >
                        <MdOutlineShoppingCart className="text-affiliate cursor-pointer 2xl:text-3xl md:text-2xl text-xl font-bold" />
                        <p className={`${totalCartItem > 0 ? 'cartNotification' : ''}`}>{totalCartItem > 0 ? totalCartItem : ""}</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            }
          </>
        )}
      </div>

    </>
  );
}
