import {
    SSGET_PRODUCT_BY_ID,
    SSGET_PRODUCT_LIST,
} from '../../constants/superShop/ssProductConstants';

export function sSGetProductListReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case SSGET_PRODUCT_LIST:
            return { loading: false, data: action.payload };
        default:
            return state;
    }
}

export function sSGetProductByIdReducer(state = { data: {} }, action: any) {
    switch (action.type) {
        case SSGET_PRODUCT_BY_ID:
            return { loading: false, data: action.payload };
        default:
            return state;
    }
}