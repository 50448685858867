import { AppButton } from "../../common/AppButton";
import { useNavigate } from "react-router-dom";

export default function Invoices() {
  const navigate = useNavigate();
  
  const navigateToOrders = () => {
    navigate("/invoices/orders");
  };

  const navigateToDataOffers = () => {
    navigate("/invoices/data-offers");
  };
 
  const invoices = [
    {
      text: "Order Invoices",
      handler: navigateToOrders,
    },
    {
      text: "Data Offers Invoices",
      handler: navigateToDataOffers,
    },
  ];

  return (
    <div className="container mx-auto px-2 py-4 lg:px-24 lg:py-6 2xl:py-8">
      <h2 className="title text-center text-cyan-600">Invoices</h2>
      <div className="flex flex-wrap items-center justify-center gap-3 pt-3 lg:gap-6 lg:pt-6 2xl:gap-8 2xl:pt-8">
        {invoices.map((singleSection, index) => (
          <div key={index}
            onClick={singleSection?.handler}
            className="myGradient flex h-20 w-[30%] cursor-pointer items-center justify-center rounded px-1 text-center hover:shadow-xl lg:h-32 lg:w-1/5 2xl:h-36"
          >
            <AppButton
              text={singleSection?.text}
              onClick={singleSection?.handler}
              className="text-sm font-medium text-white lg:text-xl"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
