import { AlertColor } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sSdeleteProductBrandService } from "../../../../../httpService/superShops/sSProductBrandServices";
import { RootState } from "../../../../../store";
import { sSgetProductBrandListAction } from "../../../../../store/actions/superShop/sSProductBrandAction";
import { AppButton } from "../../../../common/AppButton";
import { AppDeleteModal } from "../../../../common/AppDeleteModal";
import { AppLoader } from "../../../../common/AppLoader";
import { AppPagination } from "../../../../common/AppPagination";
import { AppSearchBox } from "../../../../common/AppSearchBox";
import { AppSnackBar } from "../../../../common/AppSnackBar";
import { SSGetProductBrandListRequest } from "../../../../../models/superShopp/catalog/sSProductBrand/sSProductBrandModel";
import { AppSelect } from "../../../../common/AppSelect";
import { IdNameResponseModel } from "../../../../../models/common/IdNameModel";
import { isAdmin } from "../../../../../common/common";
import { reactLocalStorage } from "reactjs-localstorage";

export default function AdminSSGetProductBrandList() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  var request = new SSGetProductBrandListRequest();

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);

  const pbList = useSelector((state: RootState) => {
    return state.sSProductBrandResponse.data as any;
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        request.limit = 10;
        request.offset = 0;
        await dispatch(sSgetProductBrandListAction(request));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  const handleOnRowChange = (payload: SSGetProductBrandListRequest) => {
    const fetchData = async () => {
      try {
        request.limit = payload.limit;
        request.offset = 0;
        await dispatch(sSgetProductBrandListAction(request));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }

  const handleOnPageChange = (payload: SSGetProductBrandListRequest) => {
    const fetchData = async () => {
      try {
        request.limit = payload.limit;
        request.offset = payload.offset;
        await dispatch(sSgetProductBrandListAction(request));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }

  const onSearch = (searchQuery: string) => {
    request.searchQuery = searchQuery;
    dispatch(sSgetProductBrandListAction(request));
  };

  const bazarList = useSelector((state: RootState) => {
    return state.sSBazarListResponse.data;
  });

  var idNameModels: IdNameResponseModel[] = [];
  bazarList.items?.map((x: { id: number; name: string | undefined }) => {
    var temp = new IdNameResponseModel();
    temp.id = x.id;
    temp.name = x.name;
    idNameModels.push(temp);
  });

  useEffect(() => {
    let paginationModel = new SSGetProductBrandListRequest();
    paginationModel.limit = 1000;
    paginationModel.offset = 0;
    dispatch(sSgetProductBrandListAction(paginationModel));
  }, []);

  const [bazarId, setBazarId] = useState(0);

  const handleBazarIdChange = (event: any) => {
    setBazarId(event);
    request.bazarId = event;
    dispatch(sSgetProductBrandListAction(request));
  };

  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  const navigateToAdd = () => {
    navigate("/admin/super-shop/product-brand/add");
  };

  const handleUpdate = (id: any) => {
    navigate(`/admin/super-shop/product-brand/update/${id}`);
  };

  const [bulkIds, setBulkIds] = useState([]);

  const openDeleteModal = (id: any) => {
    setOpenDeleteModal(true);

    let ids: any = [];
    ids.push(id);

    setBulkIds(ids);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleDelete = () => {
    setSnackBarOn(false);
    sSdeleteProductBrandService(bulkIds)
      .then((response: any) => {
        if (response?.data?.statusCode == 'error') {
          setSnackBarOn(true);
          setsnackBarMessage("This Brand can not be deleted");
          setSnackBarMessageType("error");
          closeDeleteModal();
        } else {
          setSnackBarOn(true);
          setsnackBarMessage("Successfully Deleted");
          setSnackBarMessageType("success");
          closeDeleteModal();
          dispatch(sSgetProductBrandListAction(request));
        }
      })
      .catch(() => {
        setSnackBarOn(true);
        setsnackBarMessage("Something went wrong");
        setSnackBarMessageType("error");
      });
  };

  return (
    <div>
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}

      <h2 className="title text-center">Add Product Brand({pbList?.size})</h2>

      <div className="flex flex-col lg:flex-row lg:items-center gap-2 lg:gap-6 pt-2">
        <AppButton onClick={navigateToAdd} text="Add Product Brand" />
        <AppSearchBox className="myInput" onChange={onSearch} placeholder="Search Here" />
        {isAdmin(reactLocalStorage.get("RoleName")) && (
          <AppSelect
            value={bazarId}
            data={idNameModels}
            defaultValue="Select Bazar"
            onChange={handleBazarIdChange}
          />
        )}
      </div>
      {isLoading ? (
        <AppLoader status={true} />
      ) : (
        <>
          {pbList.items ? (
            <div className="w-full pt-4">
              {/* these is header part for desktop */}
              <div className="hidden lg:flex w-full bg-primary myBorder mr-4">
                <div className="centerDiv text-white font-medium py-2.5 border-r w-[25%]">Name</div>
                <div className="centerDiv text-white font-medium py-2.5 border-r w-[25%]">Bangla Name</div>
                <div className="centerDiv text-white font-medium py-2.5 border-r w-[25%]">Phone Number</div>
                <div className="centerDiv text-white font-medium py-2.5 border-r w-[20%]">Bazar Name</div>
                <div className="centerDiv text-white font-medium py-2.5 border-r w-[10%]">Update</div>
                <div className="centerDiv text-white font-medium py-2.5 w-[10%]">Remove</div>
              </div>
              {/* these is middle part for desktop */}
              <div className="hidden lg:flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-1 py-1.5">
                {pbList.items.map((item: any) => (
                  <div key={item?.id} className="myBorder flex w-full items-center">

                    <div className="centerDiv justify-start border-r border-grey pl-2 py-1.5 h-full whitespace-nowrap overflow-hidden text-ellipsis w-[25%]">{item?.name}</div>
                    <div className="centerDiv justify-start border-r border-grey pl-2 py-1.5 h-full whitespace-nowrap overflow-hidden text-ellipsis w-[25%]">{item?.bnName}</div>
                    <div className="centerDiv justify-start border-r border-grey pl-2 py-1.5 h-full whitespace-nowrap overflow-hidden text-ellipsis w-[25%]">{item?.phoneNumber}</div>
                    <div className="centerDiv justify-start border-r border-grey pl-2 py-1.5 h-full whitespace-nowrap overflow-hidden text-ellipsis w-[20%]">{item?.bazarName}</div>

                    <div className="centerDiv border-r border-grey py-1.5 h-full  w-[10%]">
                      <AppButton
                        className="myBtn bg-royalBlue border-royalBlue py-0.5 lg:py-1 text-sm"
                        text="Update"
                        onClick={() => handleUpdate(item?.id)}
                      />
                    </div>
                    <div className="centerDiv border-r border-grey py-1.5 h-full  w-[10%]">
                      <AppButton
                        className="myBtn bg-red border-red py-0.5 lg:py-1 text-sm"
                        text="Remove"
                        onClick={() => openDeleteModal(item?.id)}
                      />
                    </div>
                  </div>
                ))}
              </div>
              {/* these is middle part for mobile */}
              <div className="h-[78dvh] lg:hidden overflow-y-auto flex flex-col gap-2">
                {pbList.items.map((item?: any) => (
                  <div key={item?.id} className="myBorder flex flex-col w-full p-2">
                    <div className="flex items-center justify-between w-full px-4">
                      <div className="flex items-center gap-1">
                        <span className="font-medium">Is Publish:</span> <span>{item?.isPublished === true ? "Yes" : "No"}</span>
                      </div>
                    </div>
                    <div className="flex flex-col w-full">
                      <div className="flex items-center gap-1">
                        <span className="font-medium">Name:</span> <span>{item?.name}</span>
                      </div>
                      <div className="flex items-center gap-1">
                        <span className="font-medium">Total Product:</span> <span>{item?.totalProduct}</span>
                      </div>
                    </div>
                    {/* these is approve decline details div  */}
                    <div className="flex items-center justify-center gap-4 w-full pt-2">
                      <AppButton
                        className="myBtn bg-royalBlue border-royalBlue py-0.5 lg:py-1 text-sm"
                        text="Update"
                        onClick={() => handleUpdate(item?.id)}
                      />
                      <AppButton
                        className="myBtn bg-red border-red py-0.5 lg:py-1 text-sm"
                        text="Remove"
                        onClick={() => openDeleteModal(item?.id)}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="py-1 flex items-center">
                <AppPagination curPage={1} limit={10} offset={0} onPageChange={handleOnPageChange} onRowChange={handleOnRowChange} size={pbList?.size} />
              </div>
            </div>
          ) : (
            <div className="w-full h-[50dvh] flex justify-center items-center text-3xl text-textBlack/90 font-medium">
              <span>No Brand found</span>
            </div>
          )}
        </>)}

      {isOpenDeleteModal && (
        <AppDeleteModal
          text="Are you sure to Delete?"
          handleDelete={handleDelete}
          closeDeleteModal={closeDeleteModal}
        />
      )}
    </div>
  );
}
