import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _moment from "moment";
import { GetCompanyWalletResponse } from "../../../../models/auth/user/UserPointModels";
import { RootState } from "../../../../store";
import { getCompanyWalletAction } from "../../../../store/actions/auth/user/userActions";
import { Link } from "react-router-dom";

export const orderPaymentStatuses = [
  { id: 1, name: "Paid" },
  { id: 2, name: "NotPaid" },
];

export const pointStatuses = [
  { id: 1, name: "Pending" },
  { id: 3, name: "Received" },
  { id: 4, name: "Cancelled" },
];

export const earningStatus = [
  { id: 1, name: "In-progress" },
  { id: 2, name: "Earned" },
  { id: 3, name: "Denied" },
];

export const earningPointsType = [
  { id: 1, name: "ResellerEarning" },
  { id: 2, name: "CourseEarning" },
  { id: 3, name: "RefferEarning" },
  { id: 4, name: "ModeratorEarning" },
  { id: 5, name: "ProductEarning" },
];

const paymentMethods = [
  { id: 1, name: "Bkash" },
  { id: 2, name: "Nagad" },
  { id: 3, name: "Rocket" },
  { id: 4, name: "Bank" },
];

export default function AdminGetCompanyWallet() {

  const dispatch = useDispatch();

  const wallet = useSelector((state: RootState) => {
    return state.getCompanyWalletResponse.data as GetCompanyWalletResponse;
  });

  useEffect(() => {
    dispatch(getCompanyWalletAction());
  }, [dispatch]);


  const resellingIncome = [
    {
      label: "Product Income",
      income: (wallet?.resellingProductIncome?.toFixed(2))
    },
    {
      label: "Moderator Income",
      income: (wallet?.resellingModeratorIncome?.toFixed(2))
    },
    {
      label: "Refer Income",
      income: (wallet?.resellingReferIncome?.toFixed(2))
    },
    {
      label: "Course Income",
      income: (wallet?.resellingCourseIncome?.toFixed(2))
    },
    {
      label: "Pull Income",
      income: (wallet?.totalPullIncome?.toFixed(2))
    },
    {
      label: "Data offer Income",
      income: (wallet?.dataOfferIncome?.toFixed(2))
    },
    {
      label: "Bechte Chai Income",
      income: (wallet?.bechteChaiIncome?.toFixed(2))
    },

  ]
  const affiliateIncome = [

    {
      label: "Company Income",
      income: (wallet?.affiliateCompanyIncome?.toFixed(2))
    },
    {
      label: "Product Income",
      income: (wallet?.affiliateProductIncome?.toFixed(2))
    },
    {
      label: "Refer Income",
      income: (wallet?.affiliateReferIncome?.toFixed(2))
    },
    {
      label: "Course Income",
      income: (wallet?.affiliateCourseIncome?.toFixed(2))
    }
  ]

  const resellingEarningInfo = [
    {
      name: "Total Product Income",
      value: wallet?.totalResellingProductIncome?.toFixed(2)
    },
    {
      name: "Total Moderator Income",
      value: wallet?.totalResellingModeratorIncome?.toFixed(2)
    },
    {
      name: "Total Refer Income",
      value: wallet?.totalResellingReferIncome?.toFixed(2)
    },
    {
      name: "Total Course Income",
      value: wallet?.totalResellingCourseIncome?.toFixed(2)
    },
    {
      name: "Total Pull Income",
      value: wallet?.totalPullIncome?.toFixed(2)
    },
  ];

  const affiliateEarningInfo = [
    {
      name: "Total Company Income",
      value: wallet?.totalAffiliateCompanyIncome?.toFixed(2)
    },
    {
      name: "Total Product Income",
      value: wallet?.totalAffiliateProductIncome?.toFixed(2)
    },
    {
      name: "Total Refer Income",
      value: wallet?.totalAffiliateReferIncome?.toFixed(2)
    },
    {
      name: "Total Course Income",
      value: wallet?.totalAffiliateCourseIncome?.toFixed(2)
    }
  ];

  return (
    <div className="mx-auto max-w-6xl pb-2 lg:pb-12">
      <div className='flex justify-between items-center py-1'>
        <h2 className="title pb-1 lg:py-2">Company wallet</h2>

        <Link className='myBtn' to="history">History</Link>
      </div>

      {/* this is first div  */}
      <div className="w-full grid grid-cols-2 gap-4 lg:gap-6 mt-2 lg:mt-4">
        {/* this is Total Earning of wallet?  */}
        <div className="w-full h-20 lg:h-40 p-2 lg:p-5 flex flex-col items-center justify-center rounded space-y-1 lg:space-y-2 bg-gradient-to-r from-emerald-500 via-emerald-400 to-emerald-300 shadow-md">
          <p className="font-medium text-white text-center text-lg lg:text-2xl">Total Earning</p>
          <div className="flex items-center justify-center">
            {/* <img className="w-4 lg:w-8 h-4 lg:h-8 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka-icon_s8y7yy.png" alt="" /> */}
            <h3 className="text-base lg:text-3xl font-semibold text-white">{wallet?.totalEarning?.toFixed(2)} BDT</h3>
          </div>
        </div>

        {/* this is Total spend of wallet?  */}
        <div className="w-full h-20 lg:h-40 p-2 lg:p-5 flex flex-col items-center justify-center rounded space-y-1 lg:space-y-2 bg-white myShadow border-b-2 lg:border-b-4 border-b-rose-700">
          <p className="font-medium text-center text-textBlack text-lg lg:text-2xl">Total Spent</p>
          <div className="flex items-center justify-center">
            {/* <img className="w-4 lg:w-6 h-4 lg:h-6 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_2_j2npjs.png" alt="" /> */}
            <h3 className="text-base lg:text-2xl font-medium text-red">0 BDT</h3>
          </div>
        </div>
      </div>

      {/* <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-6 my-4 lg:my-5'>
        <div className="w-full lg:w-full h-24 lg:h-40 px-2 lg:px-4 py-1 lg:py-4 flex flex-col justify-center rounded bg-white myShadow  border-b-2 lg:border-b-4 border-b-violet-500">
          {
            resellingEarningInfo.map(singleEarn => (
              <div className="flex items-center justify-between">
                <p className="font-medium text-textBlack text-xs lg:text-base">{singleEarn.name}</p>
                <div className="flex items-center">
                  <img className="w-3 lg:w-4 h-3 lg:h-4 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_5_emi1io.png" alt="" />
                  <h3 className="text-xs lg:text-lg font-semibold text-violet-800">{singleEarn.value}</h3>
                </div>
              </div>
            ))
          }
        </div>
        <div className="w-full lg:w-full h-24 lg:h-40 px-2 lg:px-4 py-1 lg:py-4 flex flex-col justify-center rounded bg-white myShadow  border-b-2 lg:border-b-4 border-b-affiliate">
          {
            affiliateEarningInfo.map(singleEarn => (
              <div className="flex items-center justify-between">
                <p className="font-medium text-textBlack text-xs lg:text-base">{singleEarn.name}</p>
                <div className="flex items-center">
                  <img className="w-3 lg:w-4 h-3 lg:h-4 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka-affiliate.png" alt="" />
                  <h3 className="text-xs lg:text-lg font-semibold text-affiliate">{singleEarn.value}</h3>
                </div>
              </div>
            ))
          }
        </div>
      </div> */}

      <div className="border-2 rounded 2xl:border-4 border-reselling p-2 lg:p-4 my-4 lg:my-5">
        <h2 className=" text-center font-bold lg:text-xl">Reselling</h2>

        <div className="grid grid-cols-2 items-center gap-4 pt-2 lg:grid-cols-4 lg:gap-4 lg:py-4">
          {resellingIncome.map(singleIncome => (
            <div key={singleIncome?.label} className="h-16 lg:h-32 px-0.5 py-1 lg:px-2 lg:py-4 lg:flex flex-col items-center justify-center rounded space-y-1 lg:space-y-2.5 bg-white myShadow  border-b-2 lg:border-b-4 border-b-reselling">
              <p className="lg:font-medium text-center text-textBlack text-xs lg:text-lg">{singleIncome?.label}</p>
              <div className="flex items-center justify-center">
                {/* <img className="w-3 lg:w-5 h-3 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_5_emi1io.png" alt="" /> */}
                <h3 className="text-sm lg:text-xl font-semibold text-reselling">{singleIncome?.income} BDT</h3>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="border-2 rounded 2xl:border-4 border-affiliate p-2 lg:p-4 my-4 lg:my-5">
        <h2 className=" text-center font-bold lg:text-xl">Affiliate</h2>

        <div className="grid grid-cols-2 items-center gap-4 pt-2 lg:grid-cols-4 lg:gap-4 lg:py-4">
          {affiliateIncome.map(singleIncome => (
            <div key={singleIncome?.label} className="h-16 lg:h-32 px-0.5 py-1 lg:px-2 lg:py-4 lg:flex flex-col items-center justify-center rounded space-y-1 lg:space-y-2.5 bg-white myShadow  border-b-2 lg:border-b-4 border-b-affiliate">
              <p className="lg:font-medium text-center text-textBlack text-xs lg:text-lg">{singleIncome?.label}</p>
              <div className="flex items-center justify-center">
                {/* <img className="w-3 lg:w-5 h-3 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka-affiliate.png" alt="" /> */}
                <h3 className="text-sm lg:text-xl font-semibold text-affiliate">{singleIncome?.income} BDT</h3>
              </div>
            </div>
          ))}
        </div>
      </div>

    </div>
  );
}
