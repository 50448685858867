import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router'
import { magazineCategoryById } from '../../../store/actions/magazine/magazineCategoryActions';
import { RootState } from '../../../store';
import { CreateOrUpdateMagazineMainCategoryModel } from '../../../models/magazine/magazineCategoryModels';
import { updateMagazineCategoryByIdService } from '../../../httpService/magazine/magazineCategoryServices';
import { AlertColor } from '@mui/material';
import { AppInput } from '../../common/AppInput';
import { AppCheckBox } from '../../common/AppCheckBox';
import { AppButton } from '../../common/AppButton';
import { GET } from '../../../httpService/service';
import { PhotoModel } from '../../../models/bechteChai/product/bechteChaiProductModels';
import { AppFileInput } from '../../common/AppFileInput';

export const MagazineUpdateCategory = () => {

    const { id } = useParams();

    const categoryById = async (id: any) => {
        const data = GET(`/api/MagazineCategory/GetCategoryPublicById?Id=${id}`);
        const pload = (await data).data;
        loadData(
            //pload.id,
            pload.name,
            pload.description,
            pload.isPublished,
            pload.isShowOnHomePage
        );
    };

    //const dispatch = useDispatch();

    useEffect(() => {
        categoryById(id)
    }, [])

    // const categoryByIdData = useSelector((state: RootState) => {
    //     return state.magazineCategoryByIdResponse.data;
    // })

    const loadData = (
        //Id: any,
        name: any,
        description: any,
        isPublished: any,
        isShowOnHomePage: any,
    ) => {
        setCategoryName(name);
        setCategoryDescription(description);
        setIsPublished(isPublished);
        setIsShowOnHomePage(isShowOnHomePage);
    };


    const [categoryName, setCategoryName] = useState('');
    const [categoryNameError, setCategoryNameError] = useState('');


    const [categoryDescription, setCategoryDescription] = useState('');
    const [categoryDescriptioError, setCategoryDescriptionError] = useState('');

    const [isPublished, setIsPublished] = useState(false);
    const [isShowOnHomePage, setIsShowOnHomePage] = useState(false);

    const [snackBarMessage, setsnackBarMessage] = useState("");
    const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
    const [snackBarOn, setSnackBarOn] = useState(false);

    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const [bannerId, setBannerId] = useState(0);
    const [bannerError, setBannerError] = useState("");

    const [productMedias, setProductMedias] = useState<PhotoModel[]>([]);
    const [photoIds, setPhotoIds] = useState<any[]>([]);

    const handleBanner = (id: any, path: any) => {
        const photoModel = new PhotoModel();
        photoModel.id = id;
        photoModel.url = path;
        setBannerId(id);
        setBannerError("");
        setProductMedias(prevMedias => [...prevMedias, photoModel]);
        setPhotoIds(prevPhotoId => [...prevPhotoId, id]);
        console.log(id);
        console.log(path);
    };

    const categoryNameHandler = (value: any) => {
        setCategoryName(value);
        setCategoryNameError('');
    }

    const categoryDescriptionHandler = (value: any) => {
        setCategoryDescription(value);
        setCategoryDescriptionError('');
    }

    const navigate = useNavigate();

    const handelNagivateToCategoryList = () => {
        navigate(`/createMagazineCategory`);
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();

        var model = new CreateOrUpdateMagazineMainCategoryModel();

        model.Id = id;
        model.Name = categoryName;
        model.Description = categoryDescription;
        model.IsPublished = isPublished;
        model.IsShowOnHomePage = isShowOnHomePage;
        model.BannerId = bannerId;

        updateMagazineCategoryByIdService(model)
            .then((res: any) => {
                setSnackBarOn(true);
                setsnackBarMessage("Category updated Successfully.");
                setSnackBarMessageType("success");
                setIsButtonLoading(false);
                handelNagivateToCategoryList();

            })
            .catch((err: any) => {
                setIsButtonLoading(false);
                setCategoryNameError(err.response.data.errors?.Name?.[0]);
                setCategoryDescriptionError(err.response.data.errors?.BannerId?.[0]);
            })
    }

    return (
        <div>
            <h1>Update Category</h1>

            <AppInput
                type="text"
                className="categoryName"
                label="Category name"
                placeholder="Enter category name"
                value={categoryName}
                onChange={categoryNameHandler}
                errorMessage={categoryNameError}
            />
            <AppInput
                type="text"
                className="categoryDescription"
                label="Category description"
                placeholder="Enter category description"
                value={categoryDescription}
                onChange={categoryDescriptionHandler}
                errorMessage={categoryDescriptioError}
            />
            <label>Is published: </label>
            <AppCheckBox
                isChecked={isPublished}
                onChange={(value: any) => setIsPublished(value)}
            />
            <br />
            <label>Is shown on homepage: </label>
            <AppCheckBox
                isChecked={isShowOnHomePage}
                onChange={(value: any) => setIsShowOnHomePage(value)}
            />
            <br />

            <AppFileInput onSave={handleBanner} errorMessage={bannerError} />
            <AppButton
                className="createMagazineCategory"
                text="Save"
                variant="contained"
                isSubmitting={isButtonLoading}
                color="primary"
                onClick={(e: any) => handleSubmit(e)}
            />
        </div>
    )
}
