import { GET_IS_AVAILABLE, GET_PRODUCT_DISABLED, GET_PRODUCT_PUBLISH, GET_SHIPPING_CHARGE } from "../../constants/shippingCharge/shippingCharge";
import { GET_IS_TRAINING_VIDEO_WATCHED, GET_TRAINING_IS_PURCHASED, GET_TRAINING_VIDEO_LIST, GET_USER_TRAINING_VIDEO_LIST } from "../../constants/training/trainingConstants";

function getShippingChargeReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case GET_SHIPPING_CHARGE:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

function getIsPurchasedReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case GET_TRAINING_IS_PURCHASED:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

function getUserTrainingVideoReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case GET_USER_TRAINING_VIDEO_LIST:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

function getIsTrainingVideoWatchedReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case GET_IS_TRAINING_VIDEO_WATCHED:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

function IsShippingChargeAvailableShippingReducer(state = { data: true }, action: any) {
    switch (action.type) {
        case GET_IS_AVAILABLE:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

function IsProductPublishedReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case GET_PRODUCT_PUBLISH:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

function IsProductDisabledReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case GET_PRODUCT_DISABLED:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

export {
    getShippingChargeReducer,
    getUserTrainingVideoReducer,
    getIsPurchasedReducer,
    getIsTrainingVideoWatchedReducer,
    IsShippingChargeAvailableShippingReducer,
    IsProductPublishedReducer,
    IsProductDisabledReducer
}