import { useDispatch, useSelector } from "react-redux";
import { GetUserListModel } from "../../../models/auth/user/UserModels";
import { useEffect, useState } from "react";
import { RootState } from "../../../store";
import { getUserListAction } from "../../../store/actions/auth/address/userActions";
import { PaginationPayloadModel } from "../../../models/common/paginationModels";
import { getUseRoleListAction } from "../../../store/actions/auth/address/userRoleActions";
import { debounce } from "lodash";
import { IdNameResponseModel } from "../../../models/common/IdNameModel";
import { divisionList } from "../../../store/actions/auth/address/divisionActions";
import { districtListByDivisionId } from "../../../store/actions/auth/address/districtActions";
import { AppSearchBox } from "../../common/AppSearchBox";
import { AppSelect } from "../../common/AppSelect";
import { AppLoader } from "../../common/AppLoader";
import { AppPagination } from "../../common/AppPagination";
import { areaListByDistrictId } from "../../../store/actions/auth/address/areaActions";
import _moment from 'moment';
import { reactLocalStorage } from "reactjs-localstorage";
import { isAdmin, isCountryAdmin } from "../../../common/common";

export default function ResellingGetAppUsersForCountryZilaAdmin() {
    const dispatch = useDispatch();

    var getUserModel = new GetUserListModel();

    const [roleId, setRoleId] = useState(0);
    const [roleIdErrorMessage, setRoleIdErrorMessage] = useState("");
    const [searchQueryValue, setSearchQueryValue] = useState("");

    const userList = useSelector((state: RootState) => {
        return state.userListResponse.userList;
    });

    const userRoleList = useSelector((state: RootState) => {
        return state.userRoleListResponse.userRole;
    });

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                getUserModel.limit = 10;
                getUserModel.offset = 0;
                getUserModel.divisionId = divisionId;
                getUserModel.districtId = districtId;
                getUserModel.areaId = areaId;
                await dispatch(getUserListAction(getUserModel));
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [dispatch]);

    const handleOnRowChange = (payload: PaginationPayloadModel) => {
        const fetchData = async () => {
            try {
                getUserModel.limit = payload.limit;
                getUserModel.offset = 0;
                await dispatch(getUserListAction(getUserModel));
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };

        fetchData();
    }

    const handleOnPageChange = (payload: PaginationPayloadModel) => {
        const fetchData = async () => {
            try {
                getUserModel.limit = payload.limit;
                getUserModel.offset = payload.offset;
                await dispatch(getUserListAction(getUserModel));
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };
        fetchData();
    }


    useEffect(() => {
        if (isCountryAdmin(reactLocalStorage.get("RoleName")) || isAdmin(reactLocalStorage.get("RoleName")))
            dispatch(getUseRoleListAction());
    }, []);

    const onSearch = debounce((searchQuery: string) => {
        const fetchData = async () => {
            try {
                setSearchQueryValue(searchQuery);
                getUserModel.searchQuery = searchQuery;
                getUserModel.userRole = roleId;
                dispatch(getUserListAction(getUserModel));
            } catch (error) {
                setIsLoading(false);
            }
        }
        fetchData();
    }, 500);

    const onFilter = (e: any) => {
        setRoleId(e);
        getUserModel.searchQuery = searchQueryValue;
        getUserModel.userRole = e;
        dispatch(getUserListAction(getUserModel));
    };


    const [divisionId, setDivisionId] = useState(0);
    const [districtId, setDistrictId] = useState(0);
    const [areaId, setAreaId] = useState(0);

    const divisions = useSelector((state: RootState) => {
        return state.divisionListResponse.data;
    });
    var divisionIdNameModels: IdNameResponseModel[] = [];

    divisions?.map((x: any) => {
        var division = new IdNameResponseModel();
        division.id = x.id;
        division.name = x.name;
        divisionIdNameModels.push(division);
    });

    const districts = useSelector((state: RootState) => {
        return state.districtListResponse.data;
    });
    var districtsIdNameModels: IdNameResponseModel[] = [];

    districts?.map((x: any) => {
        var district = new IdNameResponseModel();
        district.id = x.id;
        district.name = x.name;
        districtsIdNameModels.push(district);
    });

    const areass = useSelector((state: RootState) => {
        return state.areaListResponse.data;
    });
    var areaIdNameModels: IdNameResponseModel[] = [];

    areass?.map((x: any) => {
        var area = new IdNameResponseModel();
        area.id = x.id;
        area.name = x.name;
        areaIdNameModels.push(area);
    });


    useEffect(() => {
        dispatch(divisionList());
    }, [dispatch]);

    useEffect(() => {
        if (divisionId !== 0) {
            dispatch(districtListByDivisionId(divisionId));
        }
    }, [divisionId, dispatch]);

    useEffect(() => {
        if (districtId !== 0) {
            dispatch(areaListByDistrictId(districtId));
        }
    }, [districtId, dispatch]);

    const handleDivision = (id: number) => {
        setIsLoading(true);
        setDivisionId(id);
        setDistrictId(0)
        setAreaId(0)
        const fetchData = async () => {
            try {
                getUserModel.divisionId = id;
                await dispatch(getUserListAction(getUserModel));
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };
        fetchData();
    };

    const handleDistrict = (id: number) => {
        setIsLoading(true);
        setDistrictId(id);
        setAreaId(0)
        const fetchData = async () => {
            try {
                getUserModel.divisionId = divisionId;
                getUserModel.districtId = id;
                await dispatch(getUserListAction(getUserModel));
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };
        fetchData();
    };

    const handleArea = (id: number) => {
        setIsLoading(true);
        setAreaId(id)
        const fetchData = async () => {
            try {
                getUserModel.divisionId = divisionId;
                getUserModel.districtId = districtId;
                getUserModel.areaId = id;
                await dispatch(getUserListAction(getUserModel));
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };
        fetchData();
    };

    const [bulkIds, setBulkIds] = useState([]);

    const handelBulkSelection = (event: any) => {
        setBulkIds(event);
    };

    const dateTimeFormatter = (createdDateTime: any) => {
        const createdTime = _moment(createdDateTime, 'YYYY-MM-DD HH:mm:ss.SSSSSSS');
        return createdTime.format('MMM D, YY [at] h:mm A');
    }

    return (
        <div className="container mx-auto px-2 lg:px-12 ">

            <h2 className="title text-center">User List ({userList?.size})</h2>
            <div className="grid grid-cols-3 lg:grid-cols-5 gap-1 lg:gap-4 pt-1 pb-2 lg:py-2">
                {/* <AppButton onClick={() => navigate("/admin/auth/user/add")} text="Add User" /> */}
                <AppSearchBox className="mySearchBox" onChange={onSearch} placeholder="Search Here" />

                {(isCountryAdmin(reactLocalStorage.get("RoleName")) || isAdmin(reactLocalStorage.get("RoleName"))) && (
                    <>
                        <AppSelect
                            data={userRoleList}
                            defaultValue="User Role"
                            value={roleId}
                            onChange={onFilter}
                            errorMessage={roleIdErrorMessage}
                        />
                        <AppSelect
                            defaultValue="Select Division"
                            data={divisionIdNameModels}
                            value={divisionId}
                            onChange={handleDivision}
                        />

                        {divisionId !== 0 && (
                            <AppSelect
                                defaultValue="Select District"
                                data={districtsIdNameModels}
                                value={districtId}
                                onChange={handleDistrict}
                            />
                        )}
                        {divisionId !== 0 && districtId !== 0 && (
                            <AppSelect
                                defaultValue="Select Thana"
                                data={areaIdNameModels}
                                value={areaId}
                                onChange={handleArea}
                            />
                        )}
                    </>

                )}

            </div>

            {isLoading ? (
                <AppLoader status={true} />
            ) : (
                <>
                    {userList?.items ? (
                        <>
                            <div className="w-full">
                                {/* these is header part for desktop */}
                                <div className="hidden lg:flex w-full bg-reselling myBorder">
                                    <div className="centerDiv text-white font-medium py-2.5 border-r w-[25%]">Name</div>
                                    <div className="centerDiv text-white font-medium py-2.5 border-r w-[24.9%]">Join Date</div>
                                    <div className="centerDiv text-white font-medium py-2.5 border-r w-[24.8%]">Phone</div>
                                    <div className="centerDiv text-white font-medium py-2.5 w-[25%]">Thana</div>
                                </div>
                                {/* these is middle part for desktop */}
                                <div className="hidden lg:flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-1 py-1.5">
                                    {userList?.items?.map((user: any) => (
                                        <div key={user?.id} className="myBorder flex w-full items-center">
                                            <div className="centerDiv border-r border-grey pl-2 py-1 h-full whitespace-nowrap overflow-hidden text-ellipsis  w-[25%]">{user?.firstName} {user?.userRole === "VENDOR" ? `Shop: ${user?.shopName}` : ""}</div>
                                            <div className="centerDiv border-r border-grey pl-2 py-1 h-full whitespace-nowrap overflow-hidden text-ellipsis  w-[25%]">{dateTimeFormatter(user?.joinedAt)}</div>
                                            <div className="centerDiv border-r border-grey pl-2 py-1 h-full whitespace-nowrap overflow-hidden text-ellipsis  w-[25%]">{user?.phoneNumber}</div>
                                            <div className="centerDiv border-grey py-1 h-full whitespace-nowrap overflow-hidden text-ellipsis  w-[25%]">{user?.areaName}</div>

                                        </div>
                                    ))}
                                </div>
                                {/* these is middle part for mobile */}
                                <div className="h-[78dvh] lg:hidden overflow-y-auto flex flex-col gap-2">
                                    {userList?.items?.map((user?: any) => (
                                        <div key={user?.id} className="myBorder text-xs flex flex-col w-full p-1">
                                            <div className='grid grid-cols-2'>
                                                <div className="flex items-center gap-0.5">
                                                    <span className="font-medium">Name:</span> <span>{user?.firstName} {user?.userRole === "VENDOR" ? `Shop: ${user?.shopName}` : ""}</span>
                                                </div>
                                                <div className="flex items-center gap-0.5">
                                                    <span className="font-medium">Joined At:</span> <span className="text-xsm">{dateTimeFormatter(user?.joinedAt)}</span>
                                                </div>
                                            </div>
                                            <div className='grid grid-cols-2'>
                                                <div className="flex items-center gap-0.5">
                                                    <span className="font-medium">Phone:</span> <span>{user?.phoneNumber}</span>
                                                </div>
                                                <div className="flex items-center gap-0.5">
                                                    <span className="font-medium">User Role:</span> <span> {user?.userRole}</span>
                                                </div>
                                            </div>

                                        </div>
                                    ))}
                                </div>
                                {/* these is footer part  */}
                                {/* <div className="myBorder py-1 text-center">pagination here</div> */}
                            </div>
                            <div className="py-1 flex items-center">
                                <AppPagination curPage={1} limit={10} offset={0} onPageChange={handleOnPageChange} onRowChange={handleOnRowChange} size={userList?.size} />
                            </div>
                        </>
                    ) : (
                        <div className="w-full h-[50dvh] flex justify-center items-center text-3xl text-textBlack/90 font-medium">
                            <span>No Users found</span>
                        </div>
                    )}
                </>)}
        </div>
    );
}
