import {
    SSActiveInActiveRiderRequest,
    SSCreateRiderRequest,
    SSEnableDisableRiderRequest,
    SSGetRiderListRequest,
    SSUpdateRiderRequest
}
    from "../../models/superShopp/rider/riderModel";
import { DELETE, GET, POST, PUT } from "../service";

export const sSCreateRiderService = (model: SSCreateRiderRequest) => {
    return POST(`/api/SSRider/sSCreateRider`, model);
};

export const sSGetRiderListService = (payload?: SSGetRiderListRequest) => {
    if (payload) {
        return GET(`/api/SSRider/SSGetRiderList?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}&bazarId=${payload?.bazarId}`);
    }
    return GET(`/api/SSRider/SSGetRiderList`);
};

export const sSGetRiderByIdService = (id?: any) => {
    return GET(`/api/SSRider/SSGetRiderById?RiderId=${id}`);
};

export const sSUpdateRiderService = (model: SSUpdateRiderRequest) => {
    return PUT(`/api/SSRider/sSUpdateRider`, model);
};

export const sSRemoveRiderService = (data: any) => {
    return DELETE(`/api/SSRider/sSRemoveRider`, {
        data: { "ids": data },
    });
};

export const sSEnableDisableRiderService = (model: SSEnableDisableRiderRequest) => {
    return PUT(`/api/SSRider/sSEnableDisableRider`, model);
};

export const sSActiveInactiveRiderService = (model: SSActiveInActiveRiderRequest) => {
    return PUT(`/api/SSRider/sSActiveInactiveRider`, model);
};