import { AlertColor, IconButton } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { MdClear } from "react-icons/md";
import { sSAddProductService, sSUpdateProductServices } from "../../../../../httpService/superShops/ssProductServices";
import { IdNameResponseModel } from "../../../../../models/common/IdNameModel";
import { PaginationPayloadModel } from "../../../../../models/common/paginationModels";
import { SSUpdateProductModel, AddSSProductMedia } from "../../../../../models/superShopp/catalog/sSProduct/sSProductModel";
import { RootState } from "../../../../../store";
import { sSGetMainCategoryListAction } from "../../../../../store/actions/superShop/sSMainCategoryActions";
import { AppButton } from "../../../../common/AppButton";
import { AppCheckBox } from "../../../../common/AppCheckBox";
import { AppDescription } from "../../../../common/AppDescription";
import { AppFileInput } from "../../../../common/AppFileInput";
import { AppInput } from "../../../../common/AppInput";
import { AppSelect } from "../../../../common/AppSelect";
import { AppSnackBar } from "../../../../common/AppSnackBar";
import { SSGetSubCategoryListRequest } from "../../../../../models/superShopp/catalog/sSCategory/ssSubCategoryModel";
import { SSGetProductBrandListRequest } from "../../../../../models/superShopp/catalog/sSProductBrand/sSProductBrandModel";
import { sSgetProductBrandListAction } from "../../../../../store/actions/superShop/sSProductBrandAction";
import { sSGetSubCategoryListAction } from "../../../../../store/actions/superShop/sSSubCategoryActions";
import { sSGetProductByIdAction } from "../../../../../store/actions/superShop/sSProductActions";
import DeleteIcon from "@mui/icons-material/Delete";
import { sSAddProductMediaService, sSRemoveProductMediaService } from "../../../../../httpService/superShops/ssProductMediaServices";
import { AddProductMedia } from "../../../../../models/catalog/product/productMediaModels";

const customerTypeTags = [
  { id: 1, name: "All" },
  { id: 2, name: "Men" },
  { id: 3, name: "Women" },
  { id: 4, name: "Kid" },
];

export default function AdminSSUpdateProduct() {

  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [snackBarMessage, setsnackBarMessage] = useState('');
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);

  const [name, setName] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [fullDescription, setFullDescription] = useState('');
  const [productCode, setProductCode] = useState('');
  const [customerTypeTag, setCustomerTypeTag] = useState(0);
  const [isPublished, setIsPublished] = useState(false);
  const [productBrandId, setProductBrandId] = useState(0);
  const [mainCategoryId, setMainCategoryId] = useState(0);
  const [subCategoryId, setSubCategoryId] = useState(0);
  const [price, setPrice] = useState(0);
  const [originalPrice, setOriginalPrice] = useState(0);
  const [availableStock, setAvailableStock] = useState(0);
  const [productMedias, setProductMedias] = useState([]);

  const [nameError, setNameError] = useState('');
  const [shortDescriptionError, setShortDescriptionError] = useState('');
  const [fullDescriptionError, setFullDescriptionError] = useState('');
  const [productCodeError, setProductCodeError] = useState('');
  const [mainCategoryError, setMainCategoryError] = useState('');
  const [subCategoryError, setSubCategoryError] = useState('');
  const [productBrandError, setProductBrandError] = useState('');
  const [customertypeTagError, setCustomertypeTagError] = useState('');
  const [priceError, setPriceError] = useState('');
  const [availableStockError, setAvailableStockError] = useState('');
  const [originalPriceError, setOriginalPriceError] = useState('');

  const details = useSelector((state: RootState) => {
    return state.sSGetProductByIdResponse.data;
  });

  const productDetails = useMemo(() => {
    return details;
  }, [details]);

  useEffect(() => {
    dispatch(sSGetProductByIdAction(id));
  }, [dispatch]);

  useEffect(() => {
    setName(productDetails.name);
    setShortDescription(productDetails?.shortDescription);
    setFullDescription(productDetails?.fullDescription);
    setProductCode(productDetails?.productCode);
    setCustomerTypeTag(productDetails?.customerTypeTag);
    setIsPublished(productDetails?.isPublished)
    setProductBrandId(productDetails?.productBrandId)
    setMainCategoryId(productDetails?.mainCategoryId)
    setSubCategoryId(productDetails?.subCategoryId)
    setPrice(productDetails?.price)
    setOriginalPrice(productDetails?.oldPrice)
    setAvailableStock(productDetails?.availabeInStock)
    setProductMedias(productDetails?.productMediaList)
  }, [productDetails]);

  var mainCatList = useSelector((state: RootState) => {
    return state.sSGetMainCategoryListResponse.data;
  });

  var mCatIdNameModels: IdNameResponseModel[] = [];

  mainCatList.items?.map((x: any) => {
    var cat = new IdNameResponseModel;
    cat.id = x.id;
    cat.name = x.name;
    mCatIdNameModels.push(cat);
  })

  useEffect(() => {
    const model = new PaginationPayloadModel();
    model.limit = 10000;
    model.offset = 0;
    dispatch(sSGetMainCategoryListAction(model));
  }, []);

  const subCatList = useSelector((state: RootState) => {
    return state.sSGetSubCategoryListResponse.data;
  });

  var sCatIdNameModels: IdNameResponseModel[] = [];

  subCatList.items?.map((x: any) => {
    var cat = new IdNameResponseModel;
    cat.id = x.id;
    cat.name = x.name;
    sCatIdNameModels.push(cat);
  })

  useEffect(() => {
    const model = new SSGetSubCategoryListRequest();
    model.limit = 10000;
    model.offset = 0;
    model.sSMainCategoryId = mainCategoryId;
    dispatch(sSGetSubCategoryListAction(model));
  }, [mainCategoryId]);

  const pbList = useSelector((state: RootState) => {
    return state.sSProductBrandResponse.data as any;
  });

  var pbIdNameModels: IdNameResponseModel[] = [];

  pbList.items?.map((x: any) => {
    var pb = new IdNameResponseModel;
    pb.id = x.id;
    pb.name = x.name;
    pbIdNameModels.push(pb);
  })

  useEffect(() => {
    const model = new SSGetProductBrandListRequest();
    model.limit = 10;
    model.offset = 0;
    dispatch(sSgetProductBrandListAction(model));
  }, [dispatch]);


  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleSubmit = (e: any) => {

    e.preventDefault();

    var model = new SSUpdateProductModel();
    model.id = id;
    model.name = name;
    model.shortDescription = shortDescription;
    model.fullDescription = fullDescription;
    model.productCode = productCode;
    model.customerTypeTag = customerTypeTag;
    model.isPublished = isPublished;
    model.mainCategoryId = mainCategoryId;
    model.subCategoryId = subCategoryId;
    model.productBrandId = productBrandId;
    model.price = price;
    model.oldPrice = originalPrice;
    model.availableStock = availableStock;

    setIsButtonLoading(true);

    sSUpdateProductServices(model, id)
      .then((res: any) => {
        setIsButtonLoading(false);
        setSnackBarOn(true)
        setsnackBarMessage("Product Created Successfully")
        setSnackBarMessageType('success')
        navigate(`/admin/super-shop/rider/product-list`);
      })
      .catch((error) => {
        setIsButtonLoading(false);
        setNameError(error.response.data.errors?.Name?.[0]);
        setShortDescriptionError(error.response.data.errors?.ShortDescription?.[0]);
        setProductCode(error.response.data.errors?.Name?.[0]);
        setFullDescriptionError(error.response.data.errors?.FullDescription?.[0]);
        setMainCategoryError(error.response.data.errors?.MainCategoryId?.[0]);
        setSubCategoryError(error.response.data.errors?.SubCategoryId?.[0]);
        setCustomertypeTagError(error.response.data.errors?.CustomerTypeTag?.[0]);
        setProductBrandError(error.response.data.errors?.ProductBrandId?.[0]);
        setPriceError(error.response.data.errors?.Price?.[0]);
        setOriginalPriceError(error.response.data.errors?.OldPrice?.[0]);
      })
  }

  const handleProdcutName = (value: any) => {
    setName(value)
    setNameError("")
  }

  const handlShortDescription = (value: any) => {
    setShortDescription(value)
    setShortDescriptionError("")
  }

  const handleProdcutFullDescription = (value: any) => {
    setFullDescription(value)
    setFullDescriptionError("")
  }

  const handleProdcutCode = (value: any) => {
    setProductCode(value)
    setProductCodeError("")
  }

  const handleProdcutDisplayPrice = (value: any) => {
    setPrice(value)
    setPriceError("")
  }

  const handleProdcutOriginalPrice = (value: any) => {
    setOriginalPrice(value)
    setOriginalPriceError("")
  }

  const handleProdcutQuantity = (value: any) => {
    setAvailableStock(value)
    setAvailableStockError("")
  }

  const handleMainCategory = (mainCatId: number) => {
    setMainCategoryId(mainCatId);
    setMainCategoryError("");
    setSubCategoryId(0);
  }

  const handleSubCategory = (subCatId: number) => {
    setSubCategoryId(subCatId);
    setSubCategoryError("");
  }

  const handleCustomerTypeTag = (id: any) => {
    setCustomerTypeTag(id);
    setCustomertypeTagError("");
  }

  const handleProductBrand = (id: any) => {
    setProductBrandId(id);
    setProductBrandError("");
  }

  const [bannerId, setBanner] = useState(0);
  const [bannerError, setBannerError] = useState('');
  const [bannerPath, setBannerPath] = useState("");

  const handleBanner = (id: any, path: any) => {
    setBanner(id);
    setBannerPath(path);
    setBannerError("");
  };

  const deleteBanner = () => {
    setBanner(0);
    setBannerPath("");
  };

  const [isPhotoSaveButtonLoading, setIsPhotoSaveButtonLoading] = useState(false);

  const handleSaveProductPhoto = () => {

    var model = new AddProductMedia();
    model.bannerId = bannerId;
    model.productId = id;

    setIsPhotoSaveButtonLoading(true);

    sSAddProductMediaService(model)
      .then(() => {
        setIsPhotoSaveButtonLoading(false)
        dispatch(sSGetProductByIdAction(id));
      })
      .catch((error: any) => {
        setIsPhotoSaveButtonLoading(false)
        setBannerError(error.response.data.errors?.BannerId?.[0]);
      });
    setBanner(0);
    setBannerPath("");
  };

  const handlePhotoDelete = (mediaId: any) => {

    if (productMedias.length === 1) {
      setSnackBarOn(true)
      setsnackBarMessage("Can't remove all Photo")
      setSnackBarMessageType('error')
    }
    else {
      sSRemoveProductMediaService({ ProductId: id, BannerId: mediaId })
        .then((response) => {
          setIsPhotoSaveButtonLoading(false)
          dispatch(sSGetProductByIdAction(id));
        })
        .catch((error: any) => {
          setIsPhotoSaveButtonLoading(false)
          setBannerError(error.response.data.errors?.BannerId?.[0]);
        });
    }

  }



  const photos = productMedias?.map((productMedia: any) => (
    <div className="relative">
      <button className="absolute top-0 -right-1 w-6 h-6 rounded-full text-white flex items-center justify-center bg-primary text-xl" onClick={() => handlePhotoDelete(productMedia.media.id)}><MdClear /></button>
      <img className="w-36 lg:w-40 h-36 lg:h-40 object-contain -z-10" alt="" src={productMedia.media.url} />
    </div>
  ));


  return (
    <div className="lg:px-4 pb-6">

      {snackBarOn &&
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      }

      <h2 className="title text-center">Add Product</h2>

      <div>
        <div className="flex flex-col lg:flex-row justify-between items-center gap-2 lg:gap-12">
          <AppInput
            type='text'
            className="myInput"
            label='Name'
            value={name}
            placeholder='Enter Product Name'
            onChange={handleProdcutName}
            errorMessage={nameError}
          />
        </div>
        <AppInput
          type='text'
          className="myInput"
          label='Short Description'
          value={shortDescription}
          placeholder='Enter short Description'
          onChange={handlShortDescription}
          errorMessage={shortDescriptionError}
        />

        <AppDescription
          value={fullDescription}
          label='Full Description'
          onChange={handleProdcutFullDescription}
          errorMessage={fullDescriptionError}
        />

        {/* <div className="grid grid-cols-1 lg:grid-cols-2 py-2 gap-2 lg:gap-8">
          <AppInput
            type='text'
            className="myInput"
            label='Product Code'
            value={productCode}
            placeholder='Enter Product Code'
            onChange={handleProdcutCode}
            errorMessage={productCodeError}
          />
        </div> */}

        <div className="grid grid-cols-1 lg:grid-cols-2 py-2 gap-2 lg:gap-8">
          {/* <div className="">
            <label className="md:text-base text-sm">Customer Type</label>
            <AppSelect
              defaultValue="Select Customer Type"
              value={customerTypeTag}
              data={customerTypeTags}
              onChange={handleCustomerTypeTag}
              errorMessage={customertypeTagError}
            />
          </div> */}
          <div className="">
            <label className="md:text-base text-sm">Product Brand</label>
            <AppSelect
              defaultValue="Select Customer Type"
              value={productBrandId}
              data={pbIdNameModels}
              onChange={handleProductBrand}
              errorMessage={productBrandError}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 py-2 gap-2 lg:gap-8">
          <div className="">
            <label className="md:text-base text-sm">Main Category</label>
            <AppSelect
              value={mainCategoryId}
              data={mCatIdNameModels}
              defaultValue="Select Main Category"
              onChange={handleMainCategory}
              errorMessage={mainCategoryError}
            />
          </div>
          {(mainCategoryId !== 0) &&
            <div className="">
              <label className="md:text-base text-sm">Sub Category</label>
              <AppSelect
                defaultValue="Select Sub Category "
                value={subCategoryId}
                data={sCatIdNameModels}
                onChange={handleSubCategory}
                errorMessage={subCategoryError}
              />
            </div>
          }
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 py-2 gap-2 lg:gap-8">
        <div className="flex items-center gap-x-2">
          <label htmlFor="isPublished" className="text-xl">Is Published</label>
          <AppCheckBox
            isChecked={isPublished}
            onChange={(value: any) => setIsPublished(value)}
          />
        </div>
        <div>
          <AppInput
            className="myInput"
            type='number'
            label='Stock'
            value={availableStock}
            placeholder='Enter Your Product Stock'
            onChange={handleProdcutQuantity}
            errorMessage={availableStockError}
          />
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 py-2 gap-2 lg:gap-8">
        <div>
          <AppInput
            className="myInput"
            type='number'
            label='Wholesale Price'
            value={price}
            placeholder='Enter Your Product Price'
            onChange={handleProdcutDisplayPrice}
            errorMessage={priceError}
          />
        </div>
        <div>
          <AppInput
            className="myInput"
            type='number'
            value={originalPrice}
            label='Retail Price'
            placeholder='Enter Your Product Price'
            onChange={handleProdcutOriginalPrice}
            errorMessage={originalPriceError}
          />
        </div>
      </div>

      <div className="flex gap-2 flex-wrap">{photos}</div>

      <div className="flex gap-1">
        {!bannerId && (
          <AppFileInput onSave={handleBanner} errorMessage={bannerError} />
        )}

        {bannerId !== 0 && (
          <>
            <div className="flex items-center gap-3">
              <img className="w-40 h-40 object-cover" src={bannerPath} alt="" />
              <IconButton onClick={deleteBanner}>
                <DeleteIcon />
              </IconButton>
            </div>
            <div className="flex items-center">
              <AppButton text="Save Photo" isSubmitting={isPhotoSaveButtonLoading} className="myBtn" onClick={handleSaveProductPhoto} />
            </div>
          </>
        )}

      </div>

      {/* these is save button  */}
      <div className="flex items-center justify-between lg:justify-end gap-4 py-2">
        <AppButton text='Save' variant="contained" isSubmitting={isButtonLoading} color="primary" onClick={(e: any) => handleSubmit(e)} />
      </div>

    </div>
  );
}
