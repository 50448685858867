import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppModal } from "../common/AppModal";
import { useDispatch, useSelector } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";
import { IoNewspaper } from "react-icons/io5";
import { FaGraduationCap } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { Link } from "react-router-dom";
import { RootState } from "../../store";
import { getIsPurchasedAction } from "../../store/actions/training/trainingAction";
import FacebookSupport from "./FacebookSupport";
import { getNoticeAction } from "../../store/actions/notice/noticeAction";
import { markNoticeSeenService } from "../../httpService/notice/noticeServices";
import { isAdmin, isBazarRider, isDataOfferVendor, isReseller, isRoleExist, isUpazilaAdmin, isVendor } from "../../common/common";
import { getUserProfileAction } from "../../store/actions/auth/user/userActions";
import Marquee from "react-fast-marquee";
import { GetProductListRequest } from "../../models/catalog/product/productsModels";
import { GET } from "../../httpService/service";
import { AffiliateProductBox } from "../public/affiliate/AffiliateProductBox";
import { getIsAreaSavedAction } from "../../store/actions/auth/address/userActions";
import { AppSelect } from "../common/AppSelect";
import { IdNameResponseModel } from "../../models/common/IdNameModel";
import { divisionList } from "../../store/actions/auth/address/divisionActions";
import { districtListByDivisionId } from "../../store/actions/auth/address/districtActions";
import { areaListByDistrictId } from "../../store/actions/auth/address/areaActions";
import { AppButton } from "../common/AppButton";
import { UpdateUserAddressRequest } from "../../models/auth/user/UserModels";
import { AlertColor } from "@mui/material";
import { updateUserAddressService } from "../../httpService/user/userServices";

interface productProps {
	id?: any;
	name: string;
	shortDescription?: string;
	price?: number;
	oldPrice?: number;
	discount?: string;
	bannerPath?: any;
	isBestSeller?: boolean;
	totalSold?: number;
	totalReview?: number;
	totalView?: number;
	totalFavourite?: number;
	rating?: number;
	afterAddToCart?: any;
	isDisountProduct?: any;
	discountText?: any
}

const LandingPage = () => {

	const [isLoading, setIsLoading] = useState(true);
	const [loadedProducts, setLoadedProducts] = useState<any[]>([]);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [isShowSnackBar, setIsShowSnackBar] = useState(false);
	const [snackBarMessage, setSnackBarMessage] = useState("");
	const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

	const userProfile = useSelector((state: RootState) => {
		return state.getUserProfileResponse.data;
	});

	// useEffect(() => {
	// 	const fetchData = async () => {
	// 		try {
	// 			var pModel = new GetProductListRequest();
	// 			pModel.limit = 48;
	// 			pModel.offset = 0;
	// 			pModel.searchQuery = "";
	// 			pModel.sortBy = 0;
	// 			pModel.minimumPrice = 0;
	// 			pModel.maximumPrice = 0;
	// 			pModel.homeProductFilterType = 5;
	// 			const response = await GET(`/api/AffiliateProduct/GetProductsPublic?homeProductFilterType=${pModel.homeProductFilterType}&limit=${pModel?.limit}&offset=${pModel?.offset}&searchQuery=${pModel?.searchQuery}&sortBy=${pModel?.sortBy}&minimumPrice=${pModel?.minimumPrice}&maximumPrice=${pModel?.maximumPrice}&vendorId=${pModel?.vendorId}&mainCategoryId=${pModel?.mainCategoryId}&subCategoryLevelOneId=${pModel?.subCategoryLevelOneId}`);
	// 			setLoadedProducts(response?.data?.items);
	// 			setIsLoading(false);
	// 		} catch (error) {
	// 			setIsLoading(false);
	// 		}
	// 	};
	// 	fetchData();
	// }, []);

	useEffect(() => {
		dispatch(getUserProfileAction());
	}, [dispatch]);

	useEffect(() => {
		if (isAdmin(reactLocalStorage.get("RoleName")) ||
			isVendor(reactLocalStorage.get("RoleName")) ||
			isBazarRider(reactLocalStorage.get("RoleName")) ||
			isDataOfferVendor(reactLocalStorage.get("RoleName"))) {
			navigate(`/admin`);
		}
	}, [dispatch, navigate]);

	var token = reactLocalStorage.getObject("SignIn");

	const [divisionId, setDivisionId] = useState(0);
	const [districtId, setDistrictId] = useState(0);
	const [areaId, setAreaId] = useState(0);

	const [divisionError, setDivisionError] = useState("");
	const [districtError, setDistrictError] = useState("");
	const [areaError, setAreaError] = useState("");

	const divisions = useSelector((state: RootState) => {
		return state.divisionListResponse.data;
	});
	var divisionIdNameModels: IdNameResponseModel[] = [];

	divisions?.map((x: any) => {
		var division = new IdNameResponseModel();
		division.id = x.id;
		division.name = x.name;
		divisionIdNameModels.push(division);
	});

	const districts = useSelector((state: RootState) => {
		return state.districtListResponse.data;
	});
	var districtsIdNameModels: IdNameResponseModel[] = [];

	districts?.map((x: any) => {
		var district = new IdNameResponseModel();
		district.id = x.id;
		district.name = x.name;
		districtsIdNameModels.push(district);
	});

	const areass = useSelector((state: RootState) => {
		return state.areaListResponse.data;
	});
	var areaIdNameModels: IdNameResponseModel[] = [];

	areass?.map((x: any) => {
		var area = new IdNameResponseModel();
		area.id = x.id;
		area.name = x.name;
		areaIdNameModels.push(area);
	});

	const handleDivision = (id: any) => {
		setDivisionError("");
		setDivisionId(id);
		setDistrictId(0);
		setAreaId(0);
	};

	const handleDistrict = (id: any) => {
		setDistrictError("");
		setDistrictId(id);
		setAreaId(0);
	};

	const handleArea = (id: any) => {
		setAreaError("");
		setAreaId(id);
	};

	const [isUpdateAddressButtonLoading, setIsUpdateAddressButtonLoading] = useState(false);

	const updateAddress = () => {

		let canExecute = true;

		if (divisionId == 0) {
			setDivisionError("Division is required");
			canExecute = false;
		}
		if (!divisionId) {
			setDivisionError("Division is required");
			canExecute = false;
		}
		if (!districtId && districtId != 0) {
			setDistrictError("District is required");
			canExecute = false;
		}
		if (districtId == 0) {
			setDistrictError("District is required");
			canExecute = false;
		}
		if (!areaId) {
			setAreaError("Thana is required");
			canExecute = false;
		}
		if (areaId == 0) {
			setAreaError("Thana is required");
			canExecute = false;
		}
		if (canExecute) {

			const model = new UpdateUserAddressRequest();
			model.areaId = areaId

			setIsUpdateAddressButtonLoading(true);

			updateUserAddressService(model)
				.then(() => {
					setIsShowSnackBar(true);
					setSnackBarMessage("Successfully Updated Profile");
					setSnackBarMessageType("success");
					setTimeout(() => {
						setIsShowSnackBar(false);
					}, 3000);
					dispatch(getIsAreaSavedAction());
					setIsUpdateAddressButtonLoading(false);
				})
				.catch(() => {
					setIsUpdateAddressButtonLoading(false);
					setIsShowSnackBar(true);
					setSnackBarMessage("Something went wrong please try again");
					setSnackBarMessageType("error");
					setTimeout(() => {
						setIsShowSnackBar(false);
					}, 3000);
				});
		}
	}

	useEffect(() => {
		dispatch(divisionList());
	}, [dispatch]);

	useEffect(() => {
		if (divisionId != 0 && divisionId != null) {
			dispatch(districtListByDivisionId(divisionId));
		}
	}, [divisionId, dispatch]);

	useEffect(() => {
		if (districtId != 0 && districtId != null) {
			dispatch(areaListByDistrictId(districtId));
		}
	}, [districtId, dispatch]);

	const isAreaSaved = useSelector((state: RootState) => {
		return state.getAreaSavedResponse.data;
	});

	useEffect(() => {
		if (Object.keys(userProfile).length !== 0) {
			dispatch(getIsAreaSavedAction());
		}
	}, [userProfile]);

	const [isOpenWarningModal, setOpenWarningModal] = useState(false);
	const [isClickReseller, setIsClickReseller] = useState(false);

	const navigateToReseller = () => {
		if ((token && JSON.stringify(token) !== JSON.stringify({})) || (isReseller(reactLocalStorage.get("RoleName")) && isRoleExist(reactLocalStorage.get("RoleName")))) {
			navigate("/reselling-platform");
		} else {
			setIsClickReseller(true);
			dispatch({ type: "OPEN-SIGN-IN" });
			dispatch({ type: "CLOSE-SIGN-UP" });
		}
	}

	const navigateToDataOffer = () => {
		if (token && JSON.stringify(token) !== JSON.stringify({})) {
			navigate("/dataOffer");
		} else {
			setIsClickReseller(true);
			dispatch({ type: "OPEN-SIGN-IN" });
			dispatch({ type: "CLOSE-SIGN-UP" });
		}
	}

	const navigateToImportantNumber = () => {
		if (token && JSON.stringify(token) !== JSON.stringify({})) {
			navigate("/important-numbers");
		} else {
			setIsClickReseller(true);
			dispatch({ type: "OPEN-SIGN-IN" });
			dispatch({ type: "CLOSE-SIGN-UP" });
		}
	}

	const navigateToAffiliate = () => {
		if (token && JSON.stringify(token) !== JSON.stringify({})) {
			navigate("/affiliate");
		} else {
			setIsClickReseller(true);
			dispatch({ type: "OPEN-SIGN-IN" });
			dispatch({ type: "CLOSE-SIGN-UP" });
		}
	}

	const navigateToBechteChai = () => {
		if (token && JSON.stringify(token) !== JSON.stringify({})) {
			navigate("/bechte-chai");
		} else {
			setIsClickReseller(true);
			dispatch({ type: "OPEN-SIGN-IN" });
			dispatch({ type: "CLOSE-SIGN-UP" });
		}
	}

	const navigateToBloodClub = () => {
		if (token && JSON.stringify(token) !== JSON.stringify({})) {
			navigate("/bloodClub");
		} else {
			dispatch({ type: "OPEN-SIGN-IN" });
			dispatch({ type: "CLOSE-SIGN-UP" });
		}
	}

	const navigateToPopularLink = () => {
		if (token && JSON.stringify(token) !== JSON.stringify({})) {
			navigate("/popular-links");
		} else {
			dispatch({ type: "OPEN-SIGN-IN" });
			dispatch({ type: "CLOSE-SIGN-UP" });
		}
	}

	const navigateToSuperShop = () => {
		if (token && JSON.stringify(token) !== JSON.stringify({})) {
			navigate("/super-shop");
		} else {
			dispatch({ type: "OPEN-SIGN-IN" });
			dispatch({ type: "CLOSE-SIGN-UP" });
		}
	}

	const openWarningModal = () => {
		setOpenWarningModal(true)
	}

	const closeWarningModal = () => {
		setOpenWarningModal(false);
		setIsClickReseller(false);
	}

	const location = useLocation();

	const currentUrl = window.location.href;

	let { resellRef } = useParams();
	let { affiliateRef } = useParams();
	let { affiliateProductRef } = useParams();

	useEffect(() => {
		if (currentUrl.includes("resellRef")) {
			reactLocalStorage.set("resellRef", resellRef as any);
		}
		if (currentUrl.includes("affiliateRef")) {
			reactLocalStorage.set("affiliateRef", affiliateRef as any);
		}
		if (currentUrl.includes("affiliateProductRef")) {
			reactLocalStorage.set("affiliateProductRef", affiliateProductRef as any);
		}
	}, [location]);

	const isPurchased = useSelector((state: RootState) => {
		return state.getIsPurchasedResponse.data;
	});

	useEffect(() => {
		dispatch(getIsPurchasedAction(1));
	}, [dispatch, (token && JSON.stringify(token) !== JSON.stringify({}))]);

	const notice = useSelector((state: RootState) => {
		return state.getNoticeResponse.data;
	});

	useEffect(() => {
		dispatch(getNoticeAction());
	}, [dispatch, (token && JSON.stringify(token) !== JSON.stringify({}))]);

	const closeNoticeModel = (id: any) => {
		markNoticeSeenService(id)
			.then(() => {
				navigate("/", { replace: true });
				dispatch(getNoticeAction());
			})
			.catch(() => { });
	}

	const socialFeature = [
		{
			label: "ব্লাড ক্লাব",
			handler: navigateToBloodClub,
			handlerModal: openWarningModal,
			isVisiable: true,
			isVisiableUpazilaAdmin: true,
			img: "/assets/Home/05.png",
		},
		{
			label: "Popular Link",
			icon: <FaGraduationCap className="font-extrabold text-5xl lg:text-6xl text-primary" />,
			handler: navigateToPopularLink,
			handlerModal: openWarningModal,
			isVisiable: true,
			isVisiableUpazilaAdmin: true,
			img: "/assets/Home/06.png",
		},
		// {
		// 	label: "দরকারি নম্বর",
		// 	icon: <GoNumber className="font-extrabold text-4xl lg:text-5xl text-primary" />,
		// 	handler: navigateToImportantNumber,
		// 	handlerModal: openWarningModal,
		// 	isVisiable: false,
		// 	isVisiableUpazilaAdmin: true,
		// 	img: "/assets/Home/07.png",
		// },
		{
			label: "এলাকার খবর",
			icon: <IoNewspaper className="font-extrabold text-4xl lg:text-5xl text-primary" />,
			handler: openWarningModal,
			isVisiable: false,
			isVisiableUpazilaAdmin: false,
			handlerModal: openWarningModal,
			img: "/assets/Home/08.png",
		},
	]

	const handleLogout = () => {
		reactLocalStorage.remove("SignIn");
		reactLocalStorage.remove("RoleName");
		reactLocalStorage.remove("UserId");
		navigate(`/`);
	};

	if (userProfile?.isBlocked) {
		return (
			<div className="container mx-auto flex h-screen items-center justify-center px-2 pb-12 md:px-0">
				<AppModal>
					<div className="py-12 px-4 md:px-20 flex flex-col-reverse md:flex-row items-center justify-between gap-4">
						<div className="flex flex-col items-center md:items-start gap-3">
							<h2 className="text-xl text-red md:text-3xl md:max-w-md font-semibold">Your Account has been Suspended. Please contact with Admin</h2>
							<div className="myBtn w-fit py-1.5 bg-red border-red" onClick={handleLogout} >
								Ok
							</div>
						</div>
					</div>
				</AppModal>
			</div>
		);
	}

	const upcomingImages = [
		'/assets/Home/upcomming/02.png',
		'/assets/Home/upcomming/03.png',
		'/assets/Home/upcomming/04.png',
		'/assets/Home/upcomming/06.png',
		'/assets/Home/upcomming/07.png',
		'/assets/Home/upcomming/08.png',
		'/assets/Home/upcomming/09.png',
	];

	return (
		<div className="container mx-auto px-2 md:px-16 lg:px-28 2xl:pt-6 py-2 md:pt-4 lg:pb-12">
			{!isAreaSaved && (
				<AppModal>
					<div className="flex flex-col-reverse items-center justify-between gap-4 px-4 py-12 md:flex-row md:px-20">
						<div className="flex flex-col items-center gap-3 md:items-start">
							<h2 className="text-xl font-semibold text-royalBlue md:max-w-md md:text-3xl">
								Please save your Division District and Thana First
							</h2>
							<div>
								<label className="text-sm md:text-base" placeholder="Dhaka">
									Division <span style={{ color: "red", fontSize: 20 }}>*</span>
								</label>
								<AppSelect
									defaultValue="Select Division"
									data={divisionIdNameModels}
									value={divisionId}
									onChange={handleDivision}
									errorMessage={divisionError}
								/>
							</div>

							{divisionId != 0 && (
								<div>
									<label className="text-sm md:text-base" placeholder="Dhaka">
										District{" "}
										<span style={{ color: "red", fontSize: 20 }}>*</span>
									</label>
									<AppSelect
										defaultValue="Select District"
										data={districtsIdNameModels}
										value={districtId}
										onChange={handleDistrict}
										errorMessage={districtError}
									/>
								</div>
							)}

							{divisionId != 0 && districtId != 0 && (
								<>
									<div>
										<label className="text-sm md:text-base" placeholder="Dhaka">
											Thana <span style={{ color: "red", fontSize: 20 }}>*</span>
										</label>
										<AppSelect
											defaultValue="Select Thana"
											data={areaIdNameModels}
											value={areaId}
											onChange={handleArea}
											errorMessage={areaError}
										/>
									</div>
									<AppButton
										text="Save"
										onClick={updateAddress}
										isSubmitting={isUpdateAddressButtonLoading}
									/>
								</>
							)}
						</div>
					</div>
				</AppModal>
			)}

			{!isPurchased && (token && JSON.stringify(token) !== JSON.stringify({})) && isClickReseller ? (
				<AppModal>
					<div className="py-12 px-4 md:px-20 flex flex-col-reverse md:flex-row items-center justify-between gap-4">
						<div className="flex flex-col items-center md:items-start gap-3">
							<h2 className="text-xl text-royalBlue md:text-3xl md:max-w-md font-semibold">Please Purchase Your Training First</h2>
							<div className="myBtn w-fit py-1.5 bg-royalBlue border-royalBlue" onClick={() => { }} >
								<Link to="/training">
									Buy Now
								</Link>
							</div>
						</div>
						<img className="w-60 md:w-72 h-40 md:h-48 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Group_cmdbqn.png" alt="" />
					</div>
				</AppModal>
			) : (
				isOpenWarningModal ? (
					<AppModal>
						{
							<div className="p-3">
								<div className="flex justify-end">
									<IoMdClose className="text-primary text-3xl font-semibold cursor-pointer" onClick={closeWarningModal} />
								</div>
								<div className="p-6 md:p-16 flex flex-col-reverse md:flex-row items-center gap-2 md:gap-0">
									<h2 className="text-xl text-center md:text-3xl md:max-w-md font-semibold">Oops! This Page is currently under construction!</h2>
									<img className="w-60 md:w-72 h-40 md:h-40 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/Group_34177_qfdvym.png" alt="" />
								</div>
							</div>
						}
					</AppModal>
				) : (
					notice && JSON.stringify(notice) !== JSON.stringify({}) && (
						<AppModal>
							<div className="py-12 px-4 md:px-20 flex flex-col-reverse md:flex-row items-center justify-between gap-4">
								<div className="flex flex-col items-center md:items-start gap-3">
									<p className="max-w-xl">
										<div dangerouslySetInnerHTML={{ __html: notice.description }} />
									</p>
									<div className="myBtn w-fit py-1.5 bg-royalBlue border-royalBlue" onClick={() => closeNoticeModel(notice.id)} >
										Ok
									</div>
								</div>
							</div>
						</AppModal>
					)))}
			{/* this is banner image div  */}
			<div className="w-full h-[20dvh] md:h-[30dvh] lg:h-[55dvh]">
				<img className="rounded lg:rounded-xl object-fill object-center" src="/assets/Home/Home_w.png" alt="These is banner" />
			</div>

			<div>
				<h2 className="landingTitle">ইনকাম ফিচার</h2>
				<div className='flex gap-2 lg:gap-3 2xl:gap-4 w-full'>
					<div className='flex flex-col gap-2 lg:gap-3 2xl:gap-4 w-1/2 h-[24dvh] md:h-[30dvh] lg:h-[65dvh]'>
						<div onClick={navigateToReseller} className="h-[49%] rounded lg:rounded-xl cursor-pointer">
							<img src="/assets/Home/Reselling_h_w.png" alt="" className="h-full object-fill rounded lg:rounded-xl" />
						</div>
						<div onClick={navigateToBechteChai} className="h-[49%] rounded lg:rounded-xl cursor-pointer ">
							<img src="/assets/Home/04.png" alt="" className="h-full object-fill rounded lg:rounded-xl" />
						</div>
					</div>
					<div className="flex flex-col gap-2 lg:gap-3 2xl:gap-4 w-1/2 h-[24dvh] md:h-[30dvh] lg:h-[65dvh]">
						<div onClick={navigateToAffiliate} className="relative group w-full h-[49%] rounded lg:rounded-xl cursor-pointer flex items-center justify-center">
							{/* <Marquee>
								{loadedProducts &&
									(
										loadedProducts?.map((product: productProps, index: any) =>
											<div className='md:h-[25dvh]' key={index}>
												<AffiliateProductBox
													id={product?.id}
													name={product?.name}
													shortDescription={product?.shortDescription}
													bannerPath={product?.bannerPath}
													discount={product?.discount}
													isBestSeller={product?.isBestSeller}
													price={product?.price}
													oldPrice={product?.oldPrice}
													rating={product?.rating}
													totalSold={product?.totalSold}
													isDisountProduct={product?.isDisountProduct}
													discountText={product?.discountText}
													totalReview={product?.totalReview}
													totalView={product?.totalView}
													totalFavourite={product?.totalFavourite}
												/>
											</div>
										)
									)}
							</Marquee> 
							<div className='absolute md:group-hover:absolute md:hidden top-0 left-0 w-full h-full z-20 rounded lg:rounded-xl p-4 md:p-8 text-center flex md:group-hover:flex flex-col items-center justify-center text-3xl md:text-5xl font-bangla font-semibold text-white bg-black/50'>
								<h2>ঢাকার শপিং</h2>
							</div>*/}
							<div className='absolute top-0 left-0 w-full h-full z-20 rounded lg:rounded-xl p-4 md:p-8 text-center flex md:group-hover:flex flex-col items-center justify-center text-3xl md:text-5xl font-bangla font-semibold text-white bg-black/50'>
								<h2>ঢাকার শপিং</h2>
							</div>
						</div>
						<div onClick={navigateToSuperShop} className="cursor-pointer rounded lg:rounded-xl shadow-lg hover:shadow-xl h-[49%]">
							<img className="rounded lg:rounded-xl object-fill" src="/assets/Home/SS.png" alt="" loading="lazy" />
						</div>
					</div>

				</div>
			</div>

			{/* সোশ্যাল ফিচার div  */}
			<div className='grid grid-cols-4 gap-2 lg:gap-8 pt-6'>
				<div className='col-span-2 flex items-center justify-center'>
					<h2 className="text-center font-bangla font-semibold text-3xl md:text-6xl 2xl:text-6xl">সোশ্যাল ফিচার</h2>
				</div>
				{socialFeature.map(singleFeature => ((singleFeature?.isVisiable || (isUpazilaAdmin(reactLocalStorage.get("RoleName")) && singleFeature?.isVisiableUpazilaAdmin)) &&
					<div key={singleFeature?.label} onClick={singleFeature?.handler} className="cursor-pointer rounded lg:rounded-xl shadow-lg hover:shadow-xl h-[10vh] lg:h-[33vh]">
						<img className="rounded lg:rounded-xl object-fill" src={singleFeature?.img} alt="" loading="lazy" />
					</div>
				))}
			</div>



			{/* রেগুলার ফিচার div  */}
			<div className=''>
				<h2 className="landingTitle">আপকামিং ফিচার</h2>
				<Marquee>
					<div
						className='flex items-center gap-5 lg:gap-10 pl-5 lg:pl-10 '>
						{
							upcomingImages.map((img, index) => {
								return <div key={index} className='w-24 lg:w-44 h-20 lg:h-36'>
									<img className="h-full w-full object-contain" src={img} alt="" />
								</div>
							})
						}
					</div>
				</Marquee>
			</div>

			<FacebookSupport />
		</div>
	);
};

export default LandingPage;