import {
  CancellOrderRequest,
  ResellingAdminGetOrderListRequest,
  GetOrdersCustomerRequest,
  OrderDetailsAdminRequest,
  ReturnOrderRequest,
  SubmitOrderRequest,
  UpdateOrderStatusAdminRequest,
  UpdatePaymentStatusAdminRequest,
  AdminShippedOrderRequest,
  AffiliateSubmitOrderModel
} from "../../models/order/resellingOrdersModels";
import { ExecuteBkashPaymentRequest } from "../../models/payment/paymnetModel";
import { PaginationPayloadModel } from "../../models/common/paginationModels";
import { GET, POST, PUT } from "../service";
import { AdminCompleteOrderRequest } from "../../models/order/affiliatesOrderModels";

export const affiliateSubmitOrderService = (payload?: SubmitOrderRequest) => {
  return POST("/api/AffiliateOrder/SubmitOrder", payload);
};

export const affiliateSubmitOrderPGService = (payload?: AffiliateSubmitOrderModel) => {
  return POST("/api/AffiliateOrder/AffiliateSubmitOrderWithPG", payload);
};

export const affiliateSubmitOrderMainBalanceService = (payload?: AffiliateSubmitOrderModel) => {
  return POST("/api/AffiliateOrder/AffiliateSubmitOrderWithMainBalance", payload);
};

export const affiliateMakeOrderPaymentService = (payload?: ExecuteBkashPaymentRequest) => {
  return POST("/api/AffiliateOrder/UpdateOrderPayment", payload);
};

export const affiliateGetOrderListCustomerService = (payload: GetOrdersCustomerRequest) => {
  return GET(
    `/api/AffiliateOrder/GetOrderList?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}&orderId=${payload.orderId}&orderStatus=${payload.orderStatus}&paymentStatus=${payload.paymentStatus}&shippingStatus=${payload.shippingStatus}`
  )
};

export const affiliateGetOrderItemsCustomerService = (id?: any) => {
  return GET(
    `/api/AffiliateOrder/GetOrderDetails?orderId=${id}`
  );
};

export const affiliateGetOrderListAdminService = (payload: ResellingAdminGetOrderListRequest) => {
  return GET(
    `/api/AffiliateOrder/GetOrderAdminList?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}&orderId=${payload.orderId}&customerId=${payload.customerId}&startDateTime=${payload.startDateTime}&endDateTime=${payload.endDateTime}&orderStatus=${payload.orderStatus}&paymentStatus=${payload.paymentStatus}&shippingStatus=${payload.shippingStatus}`
  );
};

export const affiliateGetOrderItemsAdminService = (payload?: OrderDetailsAdminRequest) => {
  return GET(
    `/api/AffiliateOrder/GetOrderDetailsForAdmin?orderId=${payload?.orderId}&customerId=${payload?.customerId}`
  );
};

export const affiliateUpdatePaymentStatusSerice = (payload?: UpdatePaymentStatusAdminRequest) => {
  return PUT("/api/AffiliateOrder/UpdatePaymentStatus", payload);
};

export const affiliateUpdateOrderStatusService = (payload?: UpdateOrderStatusAdminRequest) => {
  return PUT("/api/AffiliateOrder/UpdateOrderStatus", payload);
};

export const affiliateUpdateOrderStatusConfirmService = (payload?: UpdateOrderStatusAdminRequest) => {
  return PUT("/api/AffiliateOrder/ConfirmOrder", payload);
};

export const affiliateUpdateOrderProductStatusService = (payload?: UpdateOrderStatusAdminRequest) => {
  return PUT("/api/AffiliateOrder/UpdateOrderProductStatus", payload);
};

export const affiliateAdminCompleteOrderService = (payload?: AdminCompleteOrderRequest) => {
  return PUT("/api/AffiliateOrder/CompleteOrder", payload);
};

export const affiliateUpdateShippingStatusService = (payload?: AdminShippedOrderRequest) => {
  return POST("/api/AffiliateOrder/ShipOrder", payload);
};

export const affiliateGetOrderReturnRequestAdminService = (payload?: PaginationPayloadModel) => {
  return GET(
    `/api/AffiliateOrder/GetReturnOrderList?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}}`
  );
};

export const affiliateCancelOrder = (payload: CancellOrderRequest) => {
  return POST(`/api/AffiliateOrder/CancelOrder`, payload);
};

export const affiliateReturnOrder = (payload: ReturnOrderRequest) => {
  return POST(`/api/AffiliateOrder/ReturnOrder`, payload);
};