import { GET_BECHTE_CHAI_MAIN_CATEGORY_LIST, GET_BECHTE_CHAI_MAIN_CATEGORY_LIST_BY_ID, GET_BECHTE_CHAI_SUB_CATEGORY_LIST } from "../../constants/bechteChai/category";

const inititalState = {
    data: [],
    dataById: []
}

export function bechteChaiMainCategoryByListReducer(state = inititalState, action: any) {
    switch (action.type) {
        case GET_BECHTE_CHAI_MAIN_CATEGORY_LIST:
            return { ...state, loading: false, data: action.payload };
        case GET_BECHTE_CHAI_MAIN_CATEGORY_LIST_BY_ID:
            return { ...state, loading: false, dataById: action.payload };
        default:
            return state;
    }
}

export function bechteChaiSubCategoryListReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case GET_BECHTE_CHAI_SUB_CATEGORY_LIST:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}