
export class CreateUpdateBechteChaiProductRequest {
    id?: any;
    name?: string;
    shortDescription?: string;
    fullDescription?: string;
    phone?: string;
    address?: string;
    isPublished?: boolean;
    bechteChaiMainCategoryId?: number;
    bechteChaiSubCategoryId?: number;
    divisionId?: number;
    districtId?: number;
    areaId?: number;
    price?: number;
    isNegotiable?:boolean;
    bannerPath?: string;
    bannerId?: any;
    videoUrl?: any;
    photoIds?: any;
}


export class AdminGetBechteChaiProductByIdModel {
    id?: number;
    name: string = "";
    address: string = "";
    shortDescription: string = "";
    fullDescription: string = "";
    availableStartDate?: Date;
    availableEndDate?: Date;
    productMediaList?: any;
    isPublished: boolean = false;
    isNegotiable: boolean = false;
    isShowOnHomePage: boolean = false;
    isAllowReview: boolean = false;
    isReviewApprovalEnable: boolean = false;
    bechteChaiMainCategoryId?: number;
    bechteChaiSubCategoryId?: number;
    divisionId?: number;
    districtId?: number;
    areaId?: number;
    price: number = 0;
    photoUrl: string = '';
    videoUrl: string = '';
    categoryName: string = '';
    subCategoryName: string = '';
}

export class PhotoModel {
    id?: number;
    url: string = "";
}


export class BechteChaiProductCustomerCommentResponse {
    id: any;
    createdDateTime?: string
    commenterId?: string
    customerName?: string
    customerPhotoPath?: string
    isParent?: boolean
    commentResponseList?: BechteChaiProductCustomerCommentResponse[];
    commentText?: string
    updatedDateTime?: string;
    commentMedias?: any
}

export class BechteChaiProductCustomerCommentRequest {
    productId?: any;
    commentText?: string;
    isParent?: boolean;
    parentId?: number;
    mediaIds?: any
}


export class BechteChaiAddFavouriteRequest {
    id?: any;
    productId?: string;
}

export class BechteChaiFavouriteResponse {
    id?: any;
    productId?: string;
    price?: number;
    productName?: string;
    photoPath?: string;
    shortDescription?: any;
    isBestSeller?: any;
    ownerName?: any;
    address?: any;
    phone?: any;
}
