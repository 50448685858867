export const GET_TOTAL_CART_ITEM = "GET_TOTAL_CART_ITEM";

export const GET_ALL_CART_ITEMES = "GET_ALL_CART_ITEMES";
export const GET_ALL_FAVOURITE_ITEMES = "GET_ALL_FAVOURITE_ITEMES";
export const GET_IS_ALREADY_FAVOURITE = "GET_IS_ALREADY_FAVOURITE";

export const INCRESE_DECRESE_CART_ITEM = "INCRESE_DECRESE_CART_ITEM";

export const REMOVE_SINGLE_OR_LIST_CART_ITEM = "REMOVE_SINGLE_OR_LIST_CART_ITEM";

export const GET_CART_LIST_ADMIN_LIST = 'GET_CART_LIST_ADMIN_LIST';

export const GET_CART_ITEMS_ADMIN_LIST = 'GET_CART_ITEMS_ADMIN_LIST';

export const GET_TOTAL_SHIPPING_CHARGE = 'GET_CART_ITEMS_ADMIN_LIST';
