import { DELETE, GET, POST, PUT } from "../service";

export const addPoliceOfficialsService = (data: any) => {
	return POST("/api/PoliceOfficialsNumber/CreatePoliceOfficialsNumber", data);
};

export const updatePoliceOfficialsService = (data: any) => {
	return PUT("/api/PoliceOfficialsNumber/UpdatePoliceOfficialsNumber", data);
};

export const getPoliceOfficialsService = (payload: any) => {
	return GET(`/api/PoliceOfficialsNumber/GetPoliceOfficialsNumber?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}&divisionId=${payload?.divisionId}&districtId=${payload?.districtId}&areaId=${payload?.areaId}`);
};

export const getPoliceOfficialsByIdService = (data: any) => {
	return GET(`/api/PoliceOfficialsNumber/GetPoliceOfficialsNumberById?Id=${data.Id}`);
};



export const deletePoliceOfficialsService = (data: any) => {
	return DELETE(`/api/PoliceOfficialsNumber/RemovePoliceOfficialsNumber`, {
		data: { "ids": data },
	});
};