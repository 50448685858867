import { Route, Routes } from "react-router-dom";
import SSCreateProduct from "../../components/public/superShop/upAdmin/catalog/sSProduct/SSCreateProduct";
import SSGetProductList from "../../components/public/superShop/upAdmin/catalog/sSProduct/SSGetProductList";
import SSUpdateProduct from "../../components/public/superShop/upAdmin/catalog/sSProduct/SSUpdateProduct";
import SSGetProductById from "../../components/public/superShop/upAdmin/catalog/sSProduct/SSGetProductById";
import SSCreateMainCategory from "../../components/public/superShop/upAdmin/catalog/sSMainCategory/SSCreateMainCategory";
import SSGetMainCategoryList from "../../components/public/superShop/upAdmin/catalog/sSMainCategory/SSGetMainCategoryList";
import SSUpdateMainCategory from "../../components/public/superShop/upAdmin/catalog/sSMainCategory/SSUpdateMainCategory";
import SSCreateSubCategory from "../../components/public/superShop/upAdmin/catalog/sSSubCategory/SSCreateSubCategory";
import SSGetSubCategoryList from "../../components/public/superShop/upAdmin/catalog/sSSubCategory/SSGetSubCategoryList";
import SSUpdateSubCategory from "../../components/public/superShop/upAdmin/catalog/sSSubCategory/SSUpdateSubCategory";
import SSHomeComponent from "../../components/public/superShop/public/SSHomeComponent";
import SSCreateBazar from "../../components/public/superShop/upAdmin/addresss/sSBazar/SSCreateBazar";
import SSGetBazarList from "../../components/public/superShop/upAdmin/addresss/sSBazar/SSGetBazarList";
import SSUpdateBazar from "../../components/public/superShop/upAdmin/addresss/sSBazar/SSUpdateBazar";
import SSGetRiderList from "../../components/public/superShop/upAdmin/rider/SSGetRiderList";
import SSGetRiderById from "../../components/public/superShop/upAdmin/rider/SSGetRiderById";
import SSCreateRider from "../../components/public/superShop/upAdmin/rider/SSCreateRider";
import SSUpdateRider from "../../components/public/superShop/upAdmin/rider/SSUpdateRider";
import SSUpAdminSSGetOrderById from "../../components/public/superShop/upAdmin/sales/sSOrders/SSUpAdminSSGetOrderById";
import AdminSSGetOrderList from "../../components/public/superShop/upAdmin/sales/sSOrders/SSUpAdminSSGetOrderList";
import SSGetOrderList from "../../components/public/superShop/public/SSGetOrderList";
import SSGetOrderById from "../../components/public/superShop/public/SSGetOrderById";
import SSGetFavoutiteItems from "../../components/public/superShop/public/SSGetFavoutiteItems";
import SSGetCartItems from "../../components/public/superShop/public/SSGetCartItems";
import SSCreateProductBrand from "../../components/public/superShop/upAdmin/catalog/sSProductBrand/SSCreateProductBrand";
import SSUpdateProductBrand from "../../components/public/superShop/upAdmin/catalog/sSProductBrand/SSUpdateProductBrand";
import SSGetProductBrandList from "../../components/public/superShop/upAdmin/catalog/sSProductBrand/SSGetProductBrandList";
import SSProductList from "../../components/public/superShop/public/SSProductList";
import SSGetPublicProductById from "../../components/public/superShop/public/SSGetPublicProductById";
import SSSubmitOrder from "../../components/public/superShop/public/SSSubmitOrder";
import SSUpAdminSSGetOrderList from "../../components/public/superShop/upAdmin/sales/sSOrders/SSUpAdminSSGetOrderList";
import SSGetUpAdminDashboard from "../../components/public/superShop/upAdmin/dashboard/SSGetUpAdminDashboard";

export default function SuperShopPublicRoute() {
    return (
        <>
            <Routes>
                <Route index element={<SSHomeComponent />} />
                <Route path="/up-dashboard" element={<SSGetUpAdminDashboard />} />

                <Route path="/:bazarId" element={<SSProductList/>} />
                <Route path="/product/:id" element={<SSGetPublicProductById/>} />

                <Route path="/order-list" element={<SSGetOrderList/>} />
                <Route path="/order-list/up" element={<SSUpAdminSSGetOrderList/>} />
                <Route path="/order/:id" element={<SSGetOrderById />} />

                <Route path="/favourites" element={<SSGetFavoutiteItems/>} />
                <Route path="/shoppingcart" element={<SSGetCartItems/>} />

                <Route path="/submit-order/:bazarId" element={<SSSubmitOrder/>} />

                <Route path="/rider-list" element={<SSGetRiderList />} />
                <Route path="/rider/:id" element={<SSGetRiderById />} />
                <Route path="/rider/add" element={<SSCreateRider />} />
                <Route path="/rider/update/:id" element={<SSUpdateRider />} />

                <Route path="/main-category-list" element={<SSGetMainCategoryList />} />
                <Route path="/main-category/add" element={<SSCreateMainCategory />} />
                <Route path="/main-category/update/:id" element={<SSUpdateMainCategory />} />

                <Route path="/sub-category-list" element={<SSGetSubCategoryList />} />
                <Route path="/sub-category/add" element={<SSCreateSubCategory />} />
                <Route path="/sub-category/update/:id" element={<SSUpdateSubCategory />} />

                <Route path="/products" element={<SSGetProductList />} />
                <Route path="/product/add" element={<SSCreateProduct />} />
                <Route path="/product/id" element={<SSGetProductById />} />
                <Route path="/product/update/:id" element={<SSUpdateProduct />} />

                <Route path="/bazar-list" element={<SSGetBazarList />} />
                <Route path="/bazar/add" element={<SSCreateBazar />} />
                <Route path="/bazar/update/:id" element={<SSUpdateBazar />} />

                <Route path="/product-brand-list" element={<SSGetProductBrandList/>} />
                <Route path="/product-brand/add" element={<SSCreateProductBrand />} />
                <Route path="/product-brand/update/:id" element={<SSUpdateProductBrand/>} />

                <Route path="/admin/orders" element={<AdminSSGetOrderList/>} />
                <Route path="/admin/order/:id" element={<SSUpAdminSSGetOrderById />} />
            </Routes>
        </>
    );
}