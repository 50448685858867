import React, { useState } from 'react';

interface IDatePickerProps {
  className?: string;
  minDate?: Date;
  maxDate?: Date;
  onChange?: any;
  errorMessage?: string;
}

export const AppDatePicker: React.FC<IDatePickerProps> = ({ className, minDate, maxDate, onChange, errorMessage }) => {
  const [value, setValue] = useState("");

  const handleChange = (e: any) => {
    setValue(e?.target?.value);
    onChange(e?.target?.value);
  }

  return (
    <>
      <input
        className={className ? className : "myInput"}
        type="date"
        value={value}
        onChange={handleChange}
      />
      <p className="text-xs text-red pl-[2px]">{errorMessage}</p>
    </>
  );
}














// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import TextField from '@mui/material/TextField';
// import DatePicker from '@mui/lab/DatePicker';
// import React from 'react';

// interface IDatePickerProps {
//   title?: string;
//   date?: Date;
//   minDate?: Date;
//   maxDate?: Date;
//   onChange?: any;
// }

// export const AppDatePicker : React.FC<IDatePickerProps> = ({title, date, minDate, maxDate, onChange}) => {
//   const [value, setValue] = React.useState<Date | null>(null);

//   const handleChange = (e: any) => {
//     setValue(e);
//     onChange(e);
//   }

//   return (
//     <>
//       <LocalizationProvider dateAdapter={AdapterDateFns}>
//         <DatePicker
//           // mask='__/__/____'
//           //inputFormat='dd/mm/yyyy'
//           inputFormat="dd/MM/yyyy"
//           label="Basic Date example"
//           value={value}
//           onChange={handleChange}
//           renderInput={(params:any) => <TextField {...params} />}
//           minDate={new Date()}
//         />
//       </LocalizationProvider>
//     </>
//   );
// }
