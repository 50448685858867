import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EarningType,
  GetVendorWalletResponse,
  UserPullAmountRequest,
  GetVendorWalletRequest,
} from "../../../../models/auth/user/UserPointModels";
import { RootState } from "../../../../store";
import {
  getVendorWalletAction,
  getVendorPullHistoryAction,
  getWalletRechargeHistoryAction,
  getGiveBackHistoryAction,
} from "../../../../store/actions/auth/user/userActions";
import _moment from "moment";
import { AppButton } from "../../../common/AppButton";
import { AppModal } from "../../../common/AppModal";
import { AppInput } from "../../../common/AppInput";
import {
  giveBack,
  vendorPullAmountService,
  walletRecharge,
  withdrawVendorPointRequestService,
} from "../../../../httpService/user/userServices";
import { GetGiveBackHistoryRequest, GetWalletRechargeHistoryRequest, GiveBackModel, PaymentMethods, WalletRechargeModel, WithdrawRequest } from "../../../../models/auth/user/UserModels";
import { AppSelect } from "../../../common/AppSelect";
import { IoMdClose } from "react-icons/io";
import { AlertColor } from "@mui/material/Alert";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { CreateBkashPaymentRequest, CreateBkashPaymentResponse } from "../../../../models/payment/paymnetModel";
import { BKASH_CHARGE_PERCENTAGE } from "../../../../constants";
import { appUrl } from "../../../../config/config";
import { createBkashPaymentService } from "../../../../httpService/payment/paymnetServices";
import { AppLoader } from "../../../common/AppLoader";
import { Link } from "react-router-dom";

export const orderPaymentStatuses = [
  { id: 1, name: "Paid" },
  { id: 2, name: "NotPaid" },
];

export const pointStatuses = [
  { id: 1, name: "Pending" },
  { id: 3, name: "Received" },
  { id: 4, name: "Cancelled" },
];

export const earningStatus = [
  { id: 1, name: "In-progress" },
  { id: 2, name: "Earned" },
  { id: 3, name: "Denied" },
];

export const earningPointsType = [
  { id: 1, name: "ResellerEarning" },
  { id: 2, name: "CourseEarning" },
  { id: 3, name: "RefferEarning" },
  { id: 4, name: "ModeratorEarning" },
  { id: 5, name: "ProductEarning" },
  { id: 6, name: "ShippingCost" },
  { id: 7, name: "AdvancedEarning" },
];

const paymentMethods = [
  { id: 1, name: "Bkash" },
  { id: 2, name: "Nagad" },
  { id: 3, name: "Rocket" },
  { id: 4, name: "Bank" },
];

export default function VendorGetWallet() {

  const dispatch = useDispatch();

  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);

  const openWithdrawModal = () => {
    setIsWithdrawModalOpen(true);
  };

  const closeWithdrawModal = () => {
    setRequestAmount(0);
    setPaymentMethod(undefined);
    setPaymentNumber("");
    setNote(undefined);

    setRequestAmountError("");
    setPaymentMethodError("");
    setPaymentNumberError("");
    setNoteError("");
    setIsWithdrawModalOpen(false);
  };

  const [requestAmount, setRequestAmount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState();
  const [paymentNumber, setPaymentNumber] = useState("");
  const [note, setNote] = useState();

  const [requestAmountError, setRequestAmountError] = useState("");
  const [paymentMethodError, setPaymentMethodError] = useState("");
  const [paymentNumberError, setPaymentNumberError] = useState("");
  const [noteError, setNoteError] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleRequestAmount = (value: any) => {
    setRequestAmountError("");
    setRequestAmount(value);
  };

  const handlePaymentMethod = (value: any) => {
    setPaymentMethodError("");
    setPaymentNumberError("");
    setPaymentMethod(value);
    if (value === 1) {
      setPaymentNumber(userProfile?.bkashNumber);
    } if (value === 2) {
      setPaymentNumber(userProfile?.nagadNumber ? userProfile.nagadNumber : "");
    } if (value === 3) {
      setPaymentNumber(userProfile?.rocketNumber ? userProfile?.rocketNumber : "");
    } if (value === 4) {
      setPaymentNumber(userProfile?.bankAccountNumber ? userProfile?.bankAccountNumber : "");
    } if (value === 0) {
      setPaymentNumber("");
    }
  };

  const handleNote = (value: any) => {
    setNoteError("");
    if (value.length > 200) {
      setNoteError("Note must be less than 200 characters");
    }
    setNote(value);
  };

  const [isWithdrawButtonLoading, setIsWithdrawButtonLoading] = useState(false);

  const handleSubmitWithdrawRequest = () => {
    var model = new WithdrawRequest();
    model.requestAmount = requestAmount;
    model.paymentMethod = paymentMethod;
    model.paymentNumber = paymentNumber;
    model.note = note;
    setIsWithdrawButtonLoading(true);
    withdrawVendorPointRequestService(model)
      .then(() => {
        closeWithdrawModal();
        var wlt = new GetVendorWalletRequest();
        wlt.userId = 0;
        dispatch(getVendorWalletAction(wlt));
        setIsWithdrawButtonLoading(false);
      })
      .catch((error: any) => {
        setPaymentNumberError(error.response.data.errors?.PaymentMethod?.[0]);
        setRequestAmountError(error.response.data.errors?.RequestAmount?.[0]);
        setIsWithdrawButtonLoading(false);
      });
  };

  const [isPullModalOpen, setIsPullModalOpen] = useState(false);
  const [pullModalTitle, setPullModalTitle] = useState("");
  const [accounttype, setAccountType] = useState(0);
  const [pullAmount, setPullAmount] = useState(0);
  const [pullAmountError, setPullAmountError] = useState("");

  const handlePullAmount = (value: any) => {
    setPullAmount(value);
    setPullAmountError("");
  };

  const openPullModal = (type: any) => {
    setIsPullModalOpen(true);
    setPullModalTitle("Pull Advance Money");
    setAccountType(EarningType.AdvancedPaymentEarning);
  };

  const closePullModal = () => {
    setIsPullModalOpen(false);
  };

  const [isPullButtonLoading, setIsPullButtonLoading] = useState(false);

  const handlePullRequest = (value: any) => {
    var pullmodel = new UserPullAmountRequest();
    pullmodel.amount = pullAmount;
    pullmodel.earningType = accounttype;
    setIsPullButtonLoading(true);
    vendorPullAmountService(pullmodel)
      .then(() => {
        var wlt = new GetVendorWalletRequest();
        wlt.userId = 0;
        dispatch(getVendorWalletAction(wlt));
        dispatch(getVendorPullHistoryAction());
        setIsPullModalOpen(false);
        setIsPullButtonLoading(false);
      })
      .catch((error: any) => {
        setPullAmountError(error.response.data.errors.Amount[0]);
        setIsPullButtonLoading(false);
      });
  };

  const userProfile = useSelector((state: RootState) => {
    return state.getUserProfileResponse.data;
  });

  const wallet = useSelector((state: RootState) => {
    return state.getVendorWalletResponse.data as GetVendorWalletResponse;
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        var wlt = new GetVendorWalletRequest();
        wlt.userId = 0;
        await dispatch(getVendorWalletAction(wlt));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);


  const [rechargeAmount, setRechargeAmount] = useState(0);
  const [rechargeAmountError, setRechargeAmountError] = useState("");

  const handleRechargeAmount = (amount: number) => {
    setRechargeAmountError("");
    setRechargeAmount(amount);
  }

  const [isConfrmOrderButtonLoading, setIsConfrmOrderButtonLoading] = useState(false);

  const handleRecharge = (type: any) => {
    if (rechargeAmount <= 0) {
      setRechargeAmountError("Invalid Amount")
      return;
    }
    var model = new WalletRechargeModel();
    model.amount = rechargeAmount;
    model.paymentMethods = PaymentMethods.BKASH;
    model.isRechargeSuccessful = false;
    model.paymentId = 0;
    setIsConfrmOrderButtonLoading(true);
    walletRecharge(model)
      .then((response: any) => {
        bkashPayment(response?.data?.invoiceNumber, response?.data?.totalAmount);
      })
      .catch(() => {
        setIsConfrmOrderButtonLoading(false);
      })
  };

  const bkashPayment = (invoiceNumber: any, totalAmount: number) => {
    var model = new CreateBkashPaymentRequest();
    model.amount = Number((totalAmount as number + (totalAmount as number * BKASH_CHARGE_PERCENTAGE)).toFixed(2));
    model.callbackURL = `${appUrl}/admin/vendorWallet`;
    model.invoiceNumber = invoiceNumber;

    createBkashPaymentService(model)
      .then((response: any) => {
        let res = response?.data as CreateBkashPaymentResponse;
        if (res?.isSucceeded) {
          window.location.replace(res.data?.bkashURL);
        } else {
          setIsConfrmOrderButtonLoading(false);
        }
      }).catch(() => {
        setIsConfrmOrderButtonLoading(false);
      })
  }

  useEffect(() => {

    const urlParams = new URLSearchParams(window.location.search);

    const paymentID = urlParams.get('paymentID');
    const status = urlParams.get('status');

    if (paymentID && status) {
      var rechargeModel = new WalletRechargeModel();
      rechargeModel.amount = rechargeAmount;
      rechargeModel.paymentMethods = PaymentMethods.BKASH;
      rechargeModel.isRechargeSuccessful = true;
      rechargeModel.paymentId = paymentID;

      setIsConfrmOrderButtonLoading(true);
      setIsLoading(true);

      walletRecharge(rechargeModel)
        .then(() => {
          setIsLoading(false);
          var model2 = new GetWalletRechargeHistoryRequest();
          model2.isMyRecord = true;
          model2.userId = 0;
          dispatch(getWalletRechargeHistoryAction(model2));

          var wlt = new GetVendorWalletRequest();
          wlt.userId = 0;
          dispatch(getVendorWalletAction(wlt));
          setRechargeAmount(0)

          setIsShowSnackBar(true);
          setSnackBarMessage("Successfully Recharged");
          setSnackBarMessageType("success");
          setTimeout(() => {
            setIsShowSnackBar(false);
          }, 2000);

          setIsConfrmOrderButtonLoading(false)
        })
        .catch(() => {
          setIsConfrmOrderButtonLoading(false);
        })
    }
  }, []);

  const [giveBackError, setGiveBackError] = useState("");
  const [isGiveBackButtonloading, setIsGiveBackButtonLoading] = useState(false);

  const handleGiveBack = (type: any) => {
    setGiveBackError("");
    var model = new GiveBackModel();
    model.amount = wallet.commisionToPay;
    model.paymentMethods = PaymentMethods.BKASH;
    setIsGiveBackButtonLoading(true);
    giveBack(model)
      .then(() => {
        var model = new GetGiveBackHistoryRequest();
        model.isMyRecord = true;
        model.userId = 0;
        dispatch(getGiveBackHistoryAction(model));

        var wlt = new GetVendorWalletRequest();
        wlt.userId = 0;
        dispatch(getVendorWalletAction(wlt));

        setIsShowSnackBar(true);
        setSnackBarMessage("Give Back Successfully");
        setSnackBarMessageType("success");
        setTimeout(() => {
          setIsShowSnackBar(false);
        }, 2000);
        setIsGiveBackButtonLoading(false);
      })
      .catch((error: any) => {
        setIsGiveBackButtonLoading(false);
        setGiveBackError(error.response.data.errors?.Amount?.[0]);
      })
  };


  if (isLoading) {
    return (
      <div className="container h-screen flex items-center justify-center mx-auto px-2 md:px-0 pb-12">
        <AppLoader status={true} />
      </div>
    );
  }

  return (
    <>
      {userProfile?.isBlocked && <h2 className="text-xl text-red md:text-3xl font-semibold">Your Account has been Suspended. Please contact with Admin.</h2>}
      {isShowSnackBar && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      {isWithdrawModalOpen && (
        <AppModal>
          <div className="p-2 md:w-96">
            <div className="flex justify-end">
              <IoMdClose
                className="cursor-pointer text-2xl font-semibold text-primary"
                onClick={closeWithdrawModal}
              />
            </div>
            <div className="flex flex-col gap-1 px-4 py-2">
              <AppInput
                type="number"
                className="myInput"
                label="Amount"
                value={requestAmount}
                placeholder="Enter Amount"
                onChange={(value: any) => handleRequestAmount(value)}
                errorMessage={requestAmountError}
              />
              <div>
                <p>Payment Method</p>
                <AppSelect
                  defaultValue="Select Payment Method"
                  value={paymentMethod}
                  data={paymentMethods}
                  placeholder="Select Payment Method"
                  onChange={(value: any) => handlePaymentMethod(value)}
                  errorMessage={paymentMethodError}
                />
              </div>

              <AppInput
                type="text"
                className="myInput"
                label="Payment Number"
                value={paymentNumber}
                isDisabled={true}
                errorMessage={paymentNumberError}
              />

              <AppInput
                type="text"
                className="myInput"
                label="Note"
                value={note}
                placeholder="Enter Note"
                onChange={(value: any) => handleNote(value)}
                errorMessage={noteError}
              />
            </div>

            <div className="mb-2 flex justify-end">
              <AppButton text="Submit" isSubmitting={isWithdrawButtonLoading} onClick={handleSubmitWithdrawRequest} />
            </div>
          </div>
        </AppModal>
      )}
      {isPullModalOpen && (
        <>
          <AppModal>
            <div className="p-2 md:w-96">
              <div className="flex justify-end">
                <IoMdClose
                  className="cursor-pointer text-2xl font-semibold text-primary"
                  onClick={closePullModal}
                />
              </div>
              <div className="flex flex-col space-y-2 px-4 py-2">
                <h1 className="text-lg font-medium">{pullModalTitle}</h1>
                <AppInput
                  type="number"
                  label="Amount"
                  className="myInput"
                  value={note}
                  placeholder="Enter Amount"
                  onChange={(value: any) => handlePullAmount(value)}
                  errorMessage={pullAmountError}
                />
              </div>
              <div className="flex justify-end px-4">
                <AppButton text="Submit" isSubmitting={isPullButtonLoading} onClick={handlePullRequest} />
              </div>
            </div>
          </AppModal>
        </>
      )}

      <div className="mx-auto max-w-6xl pb-4 ">
        <div className='flex justify-between items-center py-1'>
          <h2 className="title pb-1 lg:py-2">My wallet</h2>

          <Link className='myBtn' to="history">History</Link>
        </div>
        {/* this is first div  */}
        <div className="grid grid-cols-2 lg:grid-cols-4 flex-col lg:flex-row items-center gap-2 lg:gap-4 w-full">
          {/* this is Main balance of wallet  */}
          <div className="h-24 lg:h-36 px-2 lg:px-2 py-2 rounded lg:rounded lg:space-y-2 bg-gradient-to-r from-emerald-500 via-emerald-400 to-emerald-300 shadow-md">
            <p className="font-medium text-white text-center text-base lg:text-2xl">Main Balance</p>
            <div className="flex items-center justify-center">
              {/* <img className="w-5 lg:w-8 h-5 lg:h-8 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka-icon_s8y7yy.png" alt="" /> */}
              <h3 className="text-lg lg:text-3xl font-semibold text-white">{wallet.mainBalance} BDT</h3>
            </div>
            <div className="flex justify-between pt-1">
              <div className="flex items-center justify-center">
                {/* <img className="w-3 lg:w-4 h-3 lg:h-4 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka-icon_s8y7yy.png" alt="" /> */}
                <h3 className="text-xsm lg:text-base font-medium lg:font-semibold text-white">{wallet.requestedAmount} BDT Requested</h3>
              </div>
              <AppButton
                className="rounded text-xs lg:text-base bg-white px-1 lg:px-4 py-0 lg:py-1 font-medium text-primary lg:shadow-lg hover:shadow-xl border-grey"
                text="Withdraw"
                onClick={openWithdrawModal}
              />
            </div>
          </div>
          {/* this is recharge amount of wallet  */}
          <div className="h-24 lg:h-36 px-2 lg:px-4 py-1 lg:py-2 rounded lg:rounded space-y-1 lg:space-y-2 bg-white myShadow border-b-2 lg:border-b-4 border-b-teal-700">
            <p className="font-medium text-center text-textBlack text-base lg:text-lg">Enter Amount</p>
            <div className="flex flex-col items-center px-4 gap-1 lg:gap-2">
              <AppInput
                type='number'
                placeholder="Enter your Amount"
                className="text-xs lg:text-base px-1.5 lg:px-4 py-0 lg:py-0.5 leading-3 border border-primary text-textBlack font-medium rounded"
                value={rechargeAmount}
                errorMessage={rechargeAmountError}
                onChange={handleRechargeAmount} />
              <AppButton
                text="Recharge"
                isSubmitting={isConfrmOrderButtonLoading}
                className="w-full rounded text-xs lg:text-base bg-white lg:py-1 text-center lg:font-medium text-primary myShadow hover:shadow-lg  border border-grey"
                onClick={handleRecharge}
              />
            </div>
          </div>
          <div className="h-24 lg:h-36 p-1 lg:p-4 rounded lg:rounded lg:space-y-2.5 bg-white myShadow border-b-2 lg:border-b-4 border-b-royalBlue">
            <p className="font-medium text-center text-textBlack text-base lg:text-xl">Advance Income</p>
            <div className="flex items-center justify-center">
              {/* <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_4_grdgmx.png" alt="" /> */}
              <h3 className="text-base lg:text-xl font-semibold text-royalBlue">{wallet?.advanceIncome} BDT</h3>
            </div>
            <div className="flex justify-center items-center mx-2 pt-1">
              <AppButton
                text="Pull"
                className="text-sm lg:text-base rounded lg:rounded bg-white px-4 py-0.5 lg:py-1 text-center font-medium text-royalBlue myShadow hover:shadow-xl border border-grey "
                onClick={() => {
                  openPullModal(EarningType.AdvancedPaymentEarning);
                }}
              />
            </div>
          </div>
          {/* this is Total Reselling Earning of wallet  */}
          <div className="h-24 lg:h-36 px-2 lg:px-3 py-2 lg:py-4 rounded lg:rounded space-y-1 lg:space-y-2 bg-white myShadow border-b-2 lg:border-b-4 border-b-red">
            <div className="flex items-center justify-between">
              <p className="font-medium text-textBlack text-sm lg:text-lg">Total Withdraw</p>
              <div className="flex items-center">
                {/* <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_2_j2npjs.png" alt="" /> */}
                <h3 className="text-base lg:text-xl font-semibold text-red">{wallet.totalWithdrawAmount} BDT</h3>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <p className="font-medium text-textBlack text-sm lg:text-lg">Total Advance</p>
              <div className="flex items-center">
                {/* <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_2_j2npjs.png" alt="" /> */}
                <h3 className="text-base lg:text-xl font-semibold text-red">{wallet.totalAdvanceIncome} BDT</h3>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <p className="font-medium text-textBlack text-sm lg:text-lg">Total Give Back</p>
              <div className="flex items-center">
                {/* <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_2_j2npjs.png" alt="" /> */}
                <h3 className="text-base lg:text-xl font-semibold text-red">{wallet.totalCommisionToPay} BDT</h3>
              </div>
            </div>

          </div>
        </div>

      </div>
    </>
  );
}
