import { PaginationPayloadModel } from "../../../common/paginationModels";

export class SSAddBazar {
    name?: string;
    BnName?: string;
    PhoneNumber?: string;
}

export class SSUpdateBzar {
    id?: any;
    name?: string;
    BnName?: string;
    PhoneNumber?: string;
}

export class SSGetBazarListRequest extends PaginationPayloadModel {
    isAll?: any = true;
    forPublic?: any = false;
    divisionId?: any = 0;
    districtId?: any = 0;
    areaId?: any = 0;
}
