import { sSGetFavouriteItemsService, sSGetIsAlreadyFavouriteOrNotService } from "../../../httpService/superShops/ssFavouriteService";
import { SSGetFavouriteItemsRequest, SSGetIsAlreadyFavouriteOrNotRequest } from "../../../models/superShopp/favourite/sSFavouriteModel";
import { SSGET_FAVOURITE_ITEMS, SSGET_IS_ALREADY_EXIST_ITEMS } from "../../constants/superShop/ssFavouriteConstants";

export const sSGetFavouriteItemsAction = (payload?: SSGetFavouriteItemsRequest) => async (dispatch: any) => {
	var data = sSGetFavouriteItemsService(payload);
	const pload = (await data).data;
	dispatch({ type: SSGET_FAVOURITE_ITEMS, payload: pload });
};

export const sSGetIsAlreadyFavouriteOrNotAction = (payload?: SSGetIsAlreadyFavouriteOrNotRequest) => async (dispatch: any) => {
	var data = sSGetIsAlreadyFavouriteOrNotService(payload);
	const pload = (await data).data;
	dispatch({ type: SSGET_IS_ALREADY_EXIST_ITEMS, payload: pload });
};