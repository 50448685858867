import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetWalletRechargeHistoryRequest, GetGiveBackHistoryRequest } from "../../../../models/auth/user/UserModels";
import { GetVendorPointRequest, GetVendorPointResponse, GetVendorPullHistoryResponse, GetVendorPointWithdrawHistoryRequest, GetWalletRechargeHistory, GetGiveBackHistory, GetVendorPointWithdrawHistoryResponse } from "../../../../models/auth/user/UserPointModels";
import { RootState } from "../../../../store";
import { getUserProfileAction, getVendorPointListAction, getVendorWithdrawHistoryAction, getVendorPullHistoryAction, getWalletRechargeHistoryAction, getGiveBackHistoryAction } from "../../../../store/actions/auth/user/userActions";
import _moment from "moment";
import { AppButton } from "../../../common/AppButton";

export default function VendorGetWalletHistory() {

    const dispatch = useDispatch();

    useEffect(() => {
        var model = new GetVendorPointRequest();
        model.userId = 0;
        model.searchQuery = searchQuery;
        model.earningStatus = earningStatus;
        model.earningType = earningType;
        dispatch(getUserProfileAction());

        dispatch(getVendorPointListAction(model));
    }, [dispatch]);

    const pointList = useSelector((state: RootState) => {
        return state.getVendorPointListResponse.data as GetVendorPointResponse[];
    });

    const [pointStatus, setPointStatus] = useState(0);

    const withdrawList = useSelector((state: RootState) => {
        return state.getVendorWithdrawHistoryResponse.data;
    });

    var withdrawlHistryModel = new GetVendorPointWithdrawHistoryRequest();
    withdrawlHistryModel.userId = 0;
    withdrawlHistryModel.pointStatus = pointStatus;

    useEffect(() => {
        dispatch(getVendorWithdrawHistoryAction(withdrawlHistryModel));
    }, [dispatch]);

    const pullList = useSelector((state: RootState) => {
        return state.getVendorPullHistoryResponse
            .data as GetVendorPullHistoryResponse[];
    });

    useEffect(() => {
        dispatch(getVendorPullHistoryAction());
    }, [dispatch]);

    const [searchQuery, setSearchQuery] = useState("");

    const [earningType, setEarningType] = useState(0);
    const [earningStatus, setEarningStatus] = useState(0);

    const orderTimeFormatter = (createdDateTime: any) => {
        const createdTime = _moment(createdDateTime, "YYYY-MM-DD HH:mm:ss.SSSSSSS");
        return createdTime.format("MMM D, YY [at] h:mm A");
    };

    const [isEarningHistry, setIsEarningHistry] = useState(true);
    const [isWithdrawHistry, setIsWithdrawHistry] = useState(false);
    const [isPullHistry, setIsPullHistry] = useState(false);
    const [isRechargeHistry, setIsRechargeHistry] = useState(false);
    const [isGiveBackHistry, setGiveBackHistry] = useState(false);
    const [isSelected, setIsSelected] = useState("");

    const openEaringHistry = () => {
        var model = new GetVendorPointRequest();
        model.userId = 0;
        model.searchQuery = searchQuery;
        model.earningStatus = earningStatus;
        model.earningType = earningType;
        dispatch(getVendorPointListAction(model));
        setIsSelected("EaringHistry");
        setIsEarningHistry(true);
        setIsWithdrawHistry(false);
        setIsPullHistry(false);
        setIsRechargeHistry(false);
        setGiveBackHistry(false);
    };

    const openWithdrawHistry = () => {
        dispatch(getVendorWithdrawHistoryAction(withdrawlHistryModel))
        setIsSelected("WithdrawHistry");
        setIsEarningHistry(false);
        setIsWithdrawHistry(true);
        setIsPullHistry(false);
        setIsRechargeHistry(false);
        setGiveBackHistry(false);
    };

    const openPullHistry = () => {
        dispatch(getVendorPullHistoryAction());
        setIsSelected("PullHistry");
        setIsEarningHistry(false);
        setIsWithdrawHistry(false);
        setIsPullHistry(true);
        setIsRechargeHistry(false);
        setGiveBackHistry(false);
    };

    const rechargeHistory = useSelector((state: RootState) => {
        return state.getWalletRechargeHistoryResponse.data as GetWalletRechargeHistory[];
    });

    const openRechargeHistry = () => {
        var model = new GetWalletRechargeHistoryRequest();
        model.isMyRecord = true;
        model.userId = 0;
        dispatch(getWalletRechargeHistoryAction(model));
        setIsSelected("RechargeHistory");
        setIsEarningHistry(false);
        setIsWithdrawHistry(false);
        setIsPullHistry(false);
        setIsRechargeHistry(true);
        setGiveBackHistry(false);
    };

    const giveBackHistory = useSelector((state: RootState) => {
        return state.getGiveBackHistoryResponse.data as GetGiveBackHistory[];
    });

    const openGiveBackHistry = () => {
        var model = new GetGiveBackHistoryRequest();
        model.isMyRecord = true;
        model.userId = 0;
        dispatch(getGiveBackHistoryAction(model));
        setIsSelected("GiveBackHistory");
        setIsEarningHistry(false);
        setIsWithdrawHistry(false);
        setIsPullHistry(false);
        setIsRechargeHistry(false);
        setGiveBackHistry(true);
    };

    return (
        <>
            <div className="lg:myBorder flex items-center justify-between lg:justify-center gap-1 lg:gap-4 lg:rounded px-0 lg:px-2 py-0 lg:py-2.5 md:gap-6 my-2 lg:my-4">
                <AppButton
                    className={`${isSelected === "EaringHistry" || isSelected === ""
                        ? "myBtn text-xxs lg:text-base px-0.5 lg:px-4 py-0 lg:py-1"
                        : "outlineBtn text-xxs lg:text-base px-0.5 lg:px-4 py-0 lg:py-1"
                        }`}
                    text="Earning History"
                    onClick={openEaringHistry}
                />
                <AppButton
                    className={`${isSelected === "WithdrawHistry"
                        ? "myBtn text-xxs lg:text-base px-0.5 lg:px-4 py-0 lg:py-1"
                        : "outlineBtn text-xxs lg:text-base px-0.5 lg:px-4 py-0 lg:py-1"
                        }`}
                    text="Withdraw History"
                    onClick={openWithdrawHistry}
                />
                <AppButton
                    className={`${isSelected === "PullHistry"
                        ? "myBtn text-xxs lg:text-base px-0.5 lg:px-4 py-0 lg:py-1"
                        : "outlineBtn text-xxs lg:text-base px-0.5 lg:px-4 py-0 lg:py-1"
                        }`}
                    text="Pull History"
                    onClick={openPullHistry}
                />

                <AppButton
                    className={`${isSelected === "RechargeHistory"
                        ? "myBtn text-xxs lg:text-base px-0.5 lg:px-4 py-0 lg:py-1"
                        : "outlineBtn text-xxs lg:text-base px-0.5 lg:px-4 py-0 lg:py-1"
                        }`}
                    text="Recharge History"
                    onClick={openRechargeHistry}
                />

                <AppButton
                    className={`${isSelected === "GiveBackHistory"
                        ? "myBtn text-xxs lg:text-base px-0.5 lg:px-4 py-0 lg:py-1"
                        : "outlineBtn text-xxs lg:text-base px-0.5 lg:px-4 py-0 lg:py-1"
                        }`}
                    text="GiveBack History"
                    onClick={openGiveBackHistry}
                />
            </div>

            {isEarningHistry && (
                <div className="flex w-full bg-primary rounded lg:rounded">
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[20%]">Earning Amount</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[19.8%]">Date & Time</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[19.8%]">Earning Type</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[19.9%]">Earning Status</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 w-[20%]">Earned From</div>
                </div>
            )}

            {isEarningHistry && <div className="flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-0.5 lg:gap-1 py-0.5 lg:py-1">
                {isEarningHistry &&
                    pointList?.map((item: GetVendorPointResponse) => {
                        return (
                            <div key={item?.id} className="myBorder flex w-full items-center">
                                <div className="centerDiv text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 border-r border-grey h-full w-[20%]">৳ {item?.earnedAmount}</div>
                                <div className="centerDiv text-xxs lg:text-sm px-0.5 lg:px-4 py-0.5 lg:py-1 border-r border-grey h-full w-[20%]">{orderTimeFormatter(item.createdAt)}</div>
                                <div className="centerDiv text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 border-r border-grey h-full w-[20%]">
                                    <span
                                        className={
                                            item.earningType === "CourseEarning"
                                                ? "CourseEarning"
                                                : "" || item.earningType === "ResellerEarning"
                                                    ? "ResellerEarning"
                                                    : "" || item.earningType === "RefferEarning"
                                                        ? "RefferEarning"
                                                        : "" || item.earningType === "ModeratorEarning"
                                                            ? "ModeratorEarning"
                                                            : "" || item.earningType === "ProductEarning"
                                                                ? "ProductEarning"
                                                                : "" || item.earningType === "ShippingCost"
                                                                    ? "ShippingCost"
                                                                    : "" || item.earningType === "AdvancedPaymentEarning"
                                                                        ? "AdvancedPaymentEarning"
                                                                        : ""
                                        }
                                    >
                                        {item.earningType === "AdvancedPaymentEarning" ? "AdvancePayment" : item.earningType}
                                    </span>
                                </div>
                                <div className="centerDiv border-r border-grey py-1 h-full w-[20%]">
                                    <span
                                        className={
                                            item.earningStatus === "InProgress"
                                                ? "ProgressIn"
                                                : "" || item.earningStatus === "Denied"
                                                    ? "Denied"
                                                    : "" || item.earningStatus === "Earned"
                                                        ? "Earned"
                                                        : ""
                                        }
                                    >
                                        {item.earningStatus}
                                    </span>
                                </div>
                                <div className="centerDiv text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[20%]">{item?.earnedFrom}</div>
                            </div>
                        );
                    })}
            </div>}

            {isWithdrawHistry && (
                <div className="flex w-full bg-primary  rounded lg:rounded">
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[33.33%]">Withdraw Amount</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[33.33%]">Date & Time</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 w-[33.33%]">Withdraw status</div>
                </div>
            )}

            {isWithdrawHistry && <div className="flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-0.5 lg:gap-1 py-0.5 lg:py-1">
                {isWithdrawHistry &&
                    withdrawList?.items?.map((item: GetVendorPointWithdrawHistoryResponse) => {
                        return (
                            <div key={item?.id} className="myBorder flex w-full items-center">
                                <div className="centerDiv text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 border-r border-grey h-full w-[33.33%]">৳ {item.requestedAmount}</div>
                                <div className="centerDiv text-xxs lg:text-sm px-0.5 lg:px-4 py-0.5 lg:py-1 border-r border-grey h-full w-[33.33%]">{orderTimeFormatter(item.requestDateTime)}</div>
                                <div className="centerDiv text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[33.33%]">
                                    <span
                                        className={
                                            item.pointStatus === "InProgress" ? "ProgressIn" : "" ||
                                                item.pointStatus === "Cancelled" ? "Denied" : "" ||
                                                    item.pointStatus === "Received" ? "Earned" : ""
                                        }
                                    >
                                        {item.pointStatus}
                                    </span>
                                </div>
                            </div>
                        );
                    })}
            </div>}

            {isPullHistry && (
                <div className="flex w-full bg-primary  rounded lg:rounded">
                    <div className="centerDiv text-white font-medium  text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[50%]">Pull Amount</div>
                    <div className="centerDiv text-white font-medium  text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[50%]">Date & Time</div>
                    {/* <div className="centerDiv text-white font-medium py-2.5 w-[33.33%]">Pull Type</div> */}
                </div>
            )}

            {isPullHistry && <div className="flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-0.5 lg:gap-1 py-0.5 lg:py-1">
                {isPullHistry &&
                    pullList?.map((item: any) => {
                        return (
                            <div key={item?.id} className="myBorder flex w-full items-center">
                                <div className="centerDiv border-r border-grey  text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[50%]">৳ {item?.pulledAmount}</div>
                                <div className="centerDiv border-r border-grey  text-xxs lg:text-sm px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[50%]">{orderTimeFormatter(item.dateTime)}</div>
                                {/* <div className="centerDiv py-1 h-full w-[33.33%]">
                    <span className={item.earningType === "CourseEarning" ? "CourseEarning" : "" || item.earningType === "ResellerEarning" ? "ResellerEarning" : "" || item.earningType === "RefferEarning" ? "RefferEarning" : "" || item.earningType === "ModeratorEarning" ? "ModeratorEarning" : "" || item.earningType === "ProductEarning" ? "ProductEarning" : ""}>{item.earningType}</span>
                  </div> */}
                            </div>
                        );
                    })}
            </div>}

            {isRechargeHistry && (
                <div className="flex w-full bg-primary  rounded lg:rounded">
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[25%]">Recharge Amount</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[25%]">Date & Time</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[25%]">Payment Method</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 w-[25%]">Recharge status</div>
                </div>
            )}

            {isRechargeHistry && <div className="flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-0.5 lg:gap-1 py-0.5 lg:py-1">
                {isRechargeHistry &&
                    rechargeHistory?.map((item: GetWalletRechargeHistory, index: any) => {
                        return (
                            <div key={index} className="myBorder flex w-full items-center">
                                <div className="centerDiv border-r border-grey  text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[25%]">৳ {item?.amount}</div>
                                <div className="centerDiv border-r border-grey  text-xxs lg:text-sm px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[25%]">{orderTimeFormatter(item.dateTime)}</div>
                                <div className="centerDiv border-r border-grey  text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[25%]">
                                    {item.paymentMethods}
                                </div>
                                <div className="centerDiv  text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[25%]">
                                    {item.transectionStatus}
                                </div>
                            </div>
                        );
                    })}
            </div>}

            {isGiveBackHistry && (
                <div className="flex w-full bg-primary  rounded lg:rounded">
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[24.9%]">GiveBack Amount</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[24.9%]">Date & Time</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[24.8%]">Payment Method</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 w-[25%]">GiveBack status</div>
                </div>
            )}

            {isGiveBackHistry && <div className="flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-0.5 lg:gap-1 py-0.5 lg:py-1">
                {isGiveBackHistry &&
                    giveBackHistory?.map((item: GetWalletRechargeHistory, index: any) => {
                        return (
                            <div key={index} className="myBorder flex w-full items-center">
                                <div className="centerDiv border-r border-grey text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[25%]">৳ {item?.amount}</div>
                                <div className="centerDiv border-r border-grey text-xxs lg:text-sm px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[25%]">{orderTimeFormatter(item.dateTime)}</div>
                                <div className="centerDiv border-r border-grey text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[25%]">
                                    {item.paymentMethods}
                                </div>
                                <div className="centerDiv text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[25%]">
                                    {item.transectionStatus}
                                </div>
                            </div>
                        );
                    })}
            </div>}

        </>
    )
}
