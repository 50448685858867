import { GET_IS_TRAINING_VIDEO_WATCHED, GET_TRAINING_BY_ID, GET_TRAINING_IS_PAID, GET_TRAINING_IS_PURCHASED, GET_TRAINING_LIST, GET_TRAINING_VIDEO_LIST, GET_USER_TRAINING_VIDEO_LIST } from "../../constants/training/trainingConstants";

export function getTrainingListReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case GET_TRAINING_LIST:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

export function getTrainingByIdReducer(state = { data: {} }, action: any) {
    switch (action.type) {
        case GET_TRAINING_BY_ID:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

export function getTrainingVideoReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case GET_TRAINING_VIDEO_LIST:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

export function getIsPurchasedReducer(state = { data: true }, action: any) {
    switch (action.type) {
        case GET_TRAINING_IS_PURCHASED:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

export function getIsPaidReducer(state = { data: true }, action: any) {
    switch (action.type) {
        case GET_TRAINING_IS_PAID:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

export function getUserTrainingVideoReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case GET_USER_TRAINING_VIDEO_LIST:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

export function getIsTrainingVideoWatchedReducer(state = { data: false }, action: any) {
    switch (action.type) {
        case GET_IS_TRAINING_VIDEO_WATCHED:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}
