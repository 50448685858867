import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { GetCompanyTeamPayload } from "../../../../models/auth/user/UserModels";
import { AppButton } from "../../../common/AppButton";
import { getCompanyTeamAction } from "../../../../store/actions/auth/user/userActions";
import { BiSearchAlt2 } from "react-icons/bi";
import { AppSearchBox } from "../../../common/AppSearchBox";
import { TeamMember } from "../../../common/TeamMember";

export default function AdminGetCompanyTeam() {
  const dispatch = useDispatch();

  const [isSelected, setIsSelected] = useState("ResellerTeam");

  const companyTeam = useSelector((state: RootState) => {
    return state.getCompanyTeamResponse.data;
  });

  var paginationModel = new GetCompanyTeamPayload();
  paginationModel.limit = 10000;
  paginationModel.offset = 0;
  paginationModel.searchQuery = "";

  useEffect(() => {
    paginationModel.isResellingReferTeam = true;
    paginationModel.isAffiliateReferTeam = false;
    paginationModel.isAffiliateProductTeam = false;
    dispatch(getCompanyTeamAction(paginationModel));
  }, [dispatch]);

  const handleOpenResellingTeam = () => {
    setIsSelected("ResellerTeam");
    paginationModel.isResellingReferTeam = true;
    paginationModel.isAffiliateReferTeam = false;
    paginationModel.isAffiliateProductTeam = false;
    dispatch(getCompanyTeamAction(paginationModel));
  };

  const handleOpenAffiliateTeam = () => {
    setIsSelected("AffiliateTeam");
    paginationModel.isResellingReferTeam = false;
    paginationModel.isAffiliateReferTeam = true;
    paginationModel.isAffiliateProductTeam = false;
    dispatch(getCompanyTeamAction(paginationModel));
  };

  const handleOpenAffiliateProductTeam = () => {
    setIsSelected("AffiliateProductTeam");
    paginationModel.isResellingReferTeam = false;
    paginationModel.isAffiliateReferTeam = false;
    paginationModel.isAffiliateProductTeam = true;
    dispatch(getCompanyTeamAction(paginationModel));
  };

  const handleSearchChange = (newValue: any) => {
    if (isSelected === "ResellerTeam")
      paginationModel.isResellingReferTeam = true;
    if (isSelected === "AffiliateTeam")
      paginationModel.isAffiliateReferTeam = true;
    if (isSelected === "AffiliateProductTeam")
      paginationModel.isAffiliateProductTeam = true;
    paginationModel.searchQuery = newValue;
    dispatch(getCompanyTeamAction(paginationModel));
  };

  return (
    <div className="container overflow-y-auto px-4 py-2 lg:mx-auto lg:px-0">
      <h1 className="title md:text-center">User team</h1>

      <div className="lg:myBorder my-2 flex items-center justify-between gap-1 px-0 py-0 md:gap-6 lg:mb-4 lg:justify-evenly lg:gap-4 lg:rounded lg:px-2 lg:py-2.5">
        <AppButton
          className={`${isSelected === "ResellerTeam"
            ? "myBtn px-1.5 py-0 text-xxs lg:px-4 lg:py-1 lg:text-base"
            : "outlineBtn px-1.5 py-0 text-xxs lg:px-4 lg:py-1 lg:text-base"
            }`}
          text="Reseller Team"
          onClick={handleOpenResellingTeam}
        />
        <AppButton
          className={`${isSelected === "AffiliateTeam"
            ? "myBtn px-1.5 py-0 text-xxs lg:px-4 lg:py-1 lg:text-base"
            : "outlineBtn px-1.5 py-0 text-xxs lg:px-4 lg:py-1 lg:text-base"
            }`}
          text="Affiliater Team"
          onClick={handleOpenAffiliateTeam}
        />
        <AppButton
          className={`${isSelected === "AffiliateProductTeam"
            ? "myBtn px-1.5 py-0 text-xxs lg:px-4 lg:py-1 lg:text-base"
            : "outlineBtn px-1.5 py-0 text-xxs lg:px-4 lg:py-1 lg:text-base"
            }`}
          text="Customer Team"
          onClick={handleOpenAffiliateProductTeam}
        />
      </div>

      <div className='flex items-center justify-between'>
        <div className="flex cursor-pointer items-center rounded border border-grey p-1 shadow-sm md:p-1.5 2xl:p-2">
          <BiSearchAlt2 className="cursor-pointer text-xl font-bold text-secondary md:text-2xl 2xl:text-3xl" />
          <AppSearchBox
            placeholder="Search here"
            onChange={handleSearchChange}
            className="border-none px-2 text-sm md:w-[25dvw] md:text-base"
          />
        </div>

        <h1 className="title">Total: ({companyTeam?.size})</h1>
      </div>

      <div className="h-[75dvh] w-full overflow-y-auto lg:h-[57dvh] my-4">
        <div className="grid grid-cols-1 gap-2 lg:gap-4 lg:grid-cols-4">
          {companyTeam?.items?.map((item: any, index: any) => {
            return (
              <TeamMember
                index={index}
                name={item?.name}
                image={item?.profilePicUrl}
                roles={item?.userRoles}
                phoneNumber={item?.phoneNumber}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
