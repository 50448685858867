import { Route, Routes } from 'react-router-dom'
import AdminGetDataOffers from '../../components/admin/dataOffer/AdminGetDataOffers'
import AdminCreateDataOffer from '../../components/admin/dataOffer/AdminCreateDataOffer'
import UpdateDataOffer from '../../components/admin/dataOffer/AdminUpdateDataOffer'
import AdminGetDataOfferById from '../../components/admin/dataOffer/AdminGetDataOfferById'
import DataOfferVendorWallet from '../../components/admin/auth/wallet/AdminDataOfferVednorWallet'
import AdminGetDataOfferOrders from '../../components/admin/dataOffer/AdminGetDataOfferOrders'


export default function DataOfferVendorRoute() {
    return (
        <>
            <Routes>
                <Route path="/data-offer" element={<AdminGetDataOffers />} />
                <Route path="/wallet" element={<DataOfferVendorWallet />} />
                <Route path="/order-list" element={<AdminGetDataOfferOrders />} />
                <Route path="/data-offer-create" element={<AdminCreateDataOffer />} />
                <Route path="/data-offer-update/:id" element={<UpdateDataOffer />} />
                <Route path="/data-offer-details/:id" element={<AdminGetDataOfferById />} />
            </Routes>
        </>
    )
}
