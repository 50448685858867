import { Link, useNavigate } from "react-router-dom";
import { PaginationPayloadModel } from "../../../models/common/paginationModels";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { AlertColor } from "@mui/material";
import { getNoticeListAction } from "../../../store/actions/notice/noticeAction";
import { RootState } from "../../../store";
import { removeNoticeService } from "../../../httpService/notice/noticeServices";
import { AppSnackBar } from "../../common/AppSnackBar";
import { AppModal } from "../../common/AppModal";
import { AppButton } from "../../common/AppButton";
import { AppLoader } from "../../common/AppLoader";
import { AppPagination } from "../../common/AppPagination";
import { getUseRoleListAction } from "../../../store/actions/auth/address/userRoleActions";

export default function AdminGetNoticeList() {

	const navigate = useNavigate();
	const dispatch = useDispatch();

	var noticeListRequest = new PaginationPayloadModel();
	
	const noticeList = useSelector((state: RootState) => {
		return state.getNoticeListResponse.data;
	});

	const userRoleList = useSelector((state: RootState) => {
		return state.userRoleListResponse.userRole;
	});

	const [snackBarMessage, setsnackBarMessage] = useState("");
	const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
	const [snackBarOn, setSnackBarOn] = useState(false);

	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				noticeListRequest.limit = 10;
				noticeListRequest.offset = 0;
				await dispatch(getNoticeListAction(noticeListRequest));
				await dispatch(getUseRoleListAction());
				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
			}
		};
		fetchData();
	}, [dispatch]);


	const handleOnRowChange = (payload: PaginationPayloadModel) => {
		const fetchData = async () => {
			try {
				noticeListRequest.limit = payload.limit;
				noticeListRequest.offset = 0;
				await dispatch(getNoticeListAction(noticeListRequest));
				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
			}
		};
		fetchData();
	};

	const handleOnPageChange = (payload: PaginationPayloadModel) => {
		const fetchData = async () => {
			try {
				noticeListRequest.limit = payload.limit;
				noticeListRequest.offset = payload.offset;
				await dispatch(getNoticeListAction(noticeListRequest));
				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
			}
		};
		fetchData();
	};

	const handleNoticeDetails = (id: any) => {
		navigate(`/admin/notice/notice-details/${id}`);
	};

	const handleNoticeUpdate = (id: any) => {
		navigate(`/admin/notice/notice-update/${id}`);
	}

	const [isDeleteButtonLoading, setIsDeleteButtonLoading] = useState(false);
	const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
	const [noticeId, setNoticeId] = useState(0);

	const openDeleteModal = (id: any) => {
		setNoticeId(id);
		setIsOpenDeleteModal(true);
	}

	const closeDeleteModal = () => {
		setIsOpenDeleteModal(false)
	}

	const handleDeleteNotice = () => {
		var model = {
			id: noticeId
		}
		setIsDeleteButtonLoading(true);
		removeNoticeService(model)
			.then(() => {
				noticeListRequest.limit = 10;
				noticeListRequest.offset = 0;
				dispatch(getNoticeListAction(noticeListRequest));
				setIsDeleteButtonLoading(false);
				closeDeleteModal()
			}).catch(() => {
				setIsDeleteButtonLoading(false);
			})
	};

	const [isButtonLoading, setIsButtonLoading] = useState(false);

	const roleNameList = (selectedRoles: any, allRoles: any) => {
		var isFirstRoleName = true;
		var roleName = "";
		for (var selectedIdx = 0; selectedIdx < selectedRoles.length; selectedIdx++) {
			for (var allIdx = 0; allIdx < allRoles.length; allIdx++) {
				if (allRoles[allIdx].id === selectedRoles[selectedIdx]) {
					if (isFirstRoleName) {
						isFirstRoleName = false;
					} else {
						roleName += ", ";
					}
					roleName += allRoles[allIdx].name;
					break;
				}
			}
		}
		return roleName;
	}

	return (
		<div className="container mx-auto">
			{snackBarOn && (
				<AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
			)}
			{isOpenDeleteModal && (
				<AppModal>
					<div className='flex flex-col gap-4 text-2xl max-w-md text-center p-4'>
						Are you sure you want to Delete this Notice?
						<div className='flex items-center justify-center gap-4'>
							<AppButton text="Yes" onClick={handleDeleteNotice} />
							<AppButton text="No" onClick={closeDeleteModal} />
						</div></div>
				</AppModal>
			)}
			<div className='w-full pb-2 lg:pb-4 flex items-center justify-between'>
				<Link to="/admin/notice/notice-create">
					<AppButton className="myOfferBtn" text="Crate Notice" onClick={() => { }} />
				</Link>
				<div className='flex items-center w-[58%] justify-end lg:justify-start'>
					<h2 className="title text-center">
						Notice list ({noticeList?.size})
					</h2>
				</div>
			</div>
			{isLoading ? (
				<AppLoader status={true} />
			) : (
				<>
					{noticeList?.items ? (
						<>
							<div className="w-full">
								{/* these is header part for desktop */}
								<div className="myBorder hidden w-full bg-royalBlue lg:flex">
									<div className="tableHead w-[30%]">Title</div>
									<div className="tableHead w-[50%]">Roles</div>
									<div className="tableHead w-[10%]">Update</div>
									{/* <div className="tableHead w-[10%]">Status</div> */}
									<div className="tableHead border-none w-[10%]">Delete</div>
								</div>
								{/* these is middle part for desktop */}
								<div className="hidden h-[65dvh] flex-col gap-1 overflow-y-auto py-1.5 lg:flex 2xl:h-[68dvh]">
									{noticeList?.items?.map((notice: any) => (
										<div
											key={notice?.id}
											className="myBorder flex w-full items-center"
										>
											<div className="centerDiv h-full w-[30%] justify-start overflow-hidden text-ellipsis whitespace-nowrap border-r border-grey py-1  pl-2">
												{notice?.title}
											</div>
											<div className="centerDiv h-full w-[50%] overflow-hidden text-ellipsis border-r border-grey py-1 pl-2">
												{roleNameList(notice?.userRoles, userRoleList)}
											</div>

											<div className="centerDiv h-full w-[10%] border-r border-grey py-1">
												<AppButton
													text="Update"
													className="myOfferBtn"
													onClick={() => handleNoticeUpdate(notice?.id)}
												/>
											</div>
											{/* <div className="centerDiv h-full w-[10%] border-r border-grey py-1">
												{notice?.isPublished ? (
													<AppButton
														// isSubmitting={}
														className="myBtn border-red bg-red py-0.5 text-sm lg:px-2 lg:py-1"
														text="UnPublish"
														onClick={() => { }}
													/>
												) : (
													<AppButton
														// isSubmitting={}
														className="myBtn bg-green border-green py-0.5 text-sm lg:py-1"
														text="Publish"
														onClick={() => { }}
													/>
												)}
											</div> */}
											<div className="centerDiv h-full w-[10%] border-r border-grey py-1">
												<AppButton
													isSubmitting={isDeleteButtonLoading}
													text="Delete"
													className="myOfferBtn bg-red border-red"
													onClick={() => openDeleteModal(notice?.id)}
												/>
											</div>
										</div>
									))}
								</div>
								{/* these is middle part for mobile */}
								<div className="flex h-[78dvh] flex-col gap-2 overflow-y-auto lg:hidden">
									{noticeList?.items?.map((notice?: any) => (
										<div
											key={notice?.id}
											className="myBorder flex w-full flex-col gap-0.5 px-1 py-1.5 text-xs"
										>
											<div className="grid grid-cols-2">
												<div className="flex items-center gap-0.5">
													<span className="font-medium">Title:</span>{" "}
													<span>{notice?.title}</span>
												</div>
												<div className="flex items-center gap-0.5">
													<span className="font-medium">Roles:</span>{" "}
													<span>{roleNameList(notice?.userRoles, userRoleList)}</span>
												</div>
											</div>
											<div className="grid grid-cols-2">
												<div className="flex items-center gap-0.5">
													<span className="font-medium">Description:</span>{" "}
													<div dangerouslySetInnerHTML={{ __html: notice?.description }} />
												</div>
											</div>

											<div className="grid grid-cols-2 gap-1">
												{/* <AppButton
													text="Details"
													className="myOfferBtn"
													onClick={() => handleNoticeDetails(notice?.id)}
												/> */}
												<AppButton
													text="Update"
													className="myOfferBtn"
													onClick={() => handleNoticeUpdate(notice?.id)}
												/>
												{/* {notice?.isPublished ? (
													<AppButton
														// isSubmitting={}
														className="myBtn border-red bg-red py-0.5 text-sm lg:px-2 lg:py-1"
														text="UnPublish"
														onClick={() => { }}
													/>
												) : (
													<AppButton
														// isSubmitting={}
														className="myBtn bg-green border-green py-0.5 text-sm lg:py-1"
														text="Publish"
														onClick={() => { }}
													/>
												)} */}
												<AppButton
													text="Delete"
													className="myOfferBtn bg-red border-red"
													onClick={() => openDeleteModal(notice?.id)}
												/>
											</div>
										</div>
									))}
								</div>
								{/* these is footer part  */}
								<div className="flex items-center py-1">
									<AppPagination
										curPage={1}
										limit={10}
										offset={0}
										onPageChange={handleOnPageChange}
										onRowChange={handleOnRowChange}
										size={noticeList?.size}
									/>
								</div>
							</div>
						</>
					) : (
						<div className="flex h-[50dvh] w-full items-center justify-center text-3xl font-medium text-textBlack/90">
							<span>No Notice found</span>
						</div>
					)}
				</>
			)}
		</div>
	);

}
