import { GET, POST, PUT } from "../service";

export const GetShippingCharge = () => {
  return GET(`/api/Shipping/GetShippingCharge`)
};

export const CreateShippingCharge = (payload: any) => {
    return POST(`/api/Shipping/CreateShippingCharge`, payload)
};

export const UpdateShippingCharge = (payload: any) => {
    return PUT(`/api/Shipping/UpdateShippingCharge`, payload)
};

export const IsShippingChargeAvailable = () => {
  return GET(`/api/Shipping/IsShippingChargeAvailable`)
};

export const ProductPublish = (payload:any) => {
  return POST(`/api/Product/ProductPublish`,payload)
};

export const ProductDisabled = (payload:any) => {
  return POST(`/api/Product/ProductDisable`,payload)
};