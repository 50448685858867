import { Checkbox } from '@mui/material';

interface ICheckBoxProps {
  isChecked?: boolean;
  onChange?: any;
  className?: string;
}

export const AppCheckBox: React.FC<ICheckBoxProps> = ({ isChecked, onChange, className }) => {

  const handleChange = (e: any) => {
    onChange(e.target.checked)
  }

  return (
    <>
      <Checkbox className={`${className ? className : "w-6 h-6"}`} checked={isChecked} onChange={handleChange} color="primary" />
    </>
  );
}
