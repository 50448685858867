export const GET_PRODUCT_REVIEW_LIST = "GET_PRODUCT_REVIEW_LIST";

export const GET_ADMIN_PRODUCT_REVIEW_LIST = "GET_ADMIN_PRODUCT_REVIEW_LIST";

export const GET_PRODUCT_REVIEW_LIST_BY_ID = 'GET_PRODUCT_REVIEW_LIST_BY_ID';

export const ADD_PRODUCT_REVIEW = 'ADD_PRODUCT_REVIEW';

export const UPDATE_PRODUCT_REVIEW = 'UPDATE_PRODUCT_REVIEW';

export const REMOVE_PRODUCT_REVIEW = 'REMOVE_PRODUCT_REVIEW';