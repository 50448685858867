import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
    GetUserWithdrawListRequest,
    GetVendorWalletRequest,
    GetVendorWalletResponse,
    UpdateVendorPointRequest
} from '../../../../models/auth/user/UserPointModels';
import { RootState } from '../../../../store';
import _moment from 'moment';
import { AppButton } from '../../../common/AppButton';
import { AppSelect } from '../../../common/AppSelect';
import { AppSearchBox } from '../../../common/AppSearchBox';
import {
    getVendorWalletAction,
    getVendorWithdrawRequestListAction
} from '../../../../store/actions/auth/user/userActions';
import { updateVendorPointStatusService } from '../../../../httpService/user/userServices';
import { AlertColor } from '@mui/material';
import { AppSnackBar } from '../../../common/AppSnackBar';
import { AppModal } from '../../../common/AppModal';
import { AppPagination } from '../../../common/AppPagination';
import { AppLoader } from '../../../common/AppLoader';

export const pointStatuses = [
    { "id": 1, "name": "In Progress" },
    { "id": 2, "name": "Received" },
    { "id": 3, "name": "Cancelled" },
]

export default function AdminGetVendorWithdrawRequests() {

    const dispatch = useDispatch();

    const [isShowSnackBar, setIsShowSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

    const [pointStatus, setPointStatus] = useState(1);

    const withdrawRequestList = useSelector((state: RootState) => {
        return state.getVendorWithdrawRequestListResponse.data;
    });

    var paginationModel = new GetUserWithdrawListRequest();
    paginationModel.pointStatus = pointStatus;

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                paginationModel.limit = 10;
                paginationModel.offset = 0;
                await dispatch(getVendorWithdrawRequestListAction(paginationModel));
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [dispatch]);

    const handleOnRowChange = (payload: GetUserWithdrawListRequest) => {
        const fetchData = async () => {
            try {
                paginationModel.limit = payload.limit;
                paginationModel.offset = 0;
                await dispatch(getVendorWithdrawRequestListAction(paginationModel));
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };
        fetchData();
    }

    const handleOnPageChange = (payload: GetUserWithdrawListRequest) => {
        const fetchData = async () => {
            try {
                paginationModel.limit = payload.limit;
                paginationModel.offset = payload.offset;
                await dispatch(getVendorWithdrawRequestListAction(paginationModel));
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };
        fetchData();
    }

    const handlePointStatusChange = (event: any) => {
        setPointStatus(event)
        paginationModel.pointStatus = event;
        dispatch(getVendorWithdrawRequestListAction(paginationModel));
    }

    const onSearch = (value: any) => {
        paginationModel.searchQuery = value;
        dispatch(getVendorWithdrawRequestListAction(paginationModel));
    }

    const orderTimeFormatter = (createdDateTime: any) => {
        const createdTime = _moment(createdDateTime, 'YYYY-MM-DD HH:mm:ss.SSSSSSS');
        return createdTime.format('MMM D, YY [at] h:mm A');
    }

    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const handelWithdrawPoint = (id: any, status: any) => {

        var model2 = new UpdateVendorPointRequest();
        model2.pointStatus = status;
        model2.userPointId = id;

        setIsButtonLoading(true);

        updateVendorPointStatusService(model2)
            .then(() => {
                setApprovePopUp(false);
                setDeclinePopUp(false);
                setIsButtonLoading(false);
                setIsShowSnackBar(true);
                setSnackBarMessage("Successfully Updated Status.");
                setSnackBarMessageType("success");
                setTimeout(() => {
                    setIsShowSnackBar(false);
                }, 3000);
                dispatch(getVendorWithdrawRequestListAction(paginationModel));
            }).catch(() => {
                setApprovePopUp(false);
                setDeclinePopUp(false);
                setIsButtonLoading(false);
                setIsShowSnackBar(true);
                setSnackBarMessage("Something Wrong");
                setSnackBarMessageType("error");
                setTimeout(() => {
                    setIsShowSnackBar(false);
                }, 3000);
            })
    };

    const [openWalletModal, setOpenWalletModal] = useState(false);

    const handleCloseWalletModal = () => {
        setOpenWalletModal(false);
    }

    const handleOpenWalletModal = (id: number) => {
        setOpenWalletModal(!openWalletModal)
        var wlt = new GetVendorWalletRequest();
        wlt.userId = id;
        dispatch(getVendorWalletAction(wlt));
    }

    const wallet = useSelector((state: RootState) => {
        return state.getVendorWalletResponse.data as GetVendorWalletResponse;
    });

    const [withdrawRequestId, setWithdrawRequestId] = useState(0);

    const [approvePopUp, setApprovePopUp] = useState(false);
    const [declinePopUp, setDeclinePopUp] = useState(false);

    const openApproveModal = (id: number) => {
        setApprovePopUp(true);
        setWithdrawRequestId(id);
    }

    const handleApprove = () => {
        handelWithdrawPoint(withdrawRequestId, 2);
    }

    const closeApprove = () => {
        setApprovePopUp(false);
    }

    const openDeclineModal = (id: number) => {
        setDeclinePopUp(true);
        setWithdrawRequestId(id);
    }

    const handleDecline = () => {
        handelWithdrawPoint(withdrawRequestId, 3);
    }

    const closeDecline = () => {
        setDeclinePopUp(false);
    }

    return (
        <>

            {approvePopUp && (
                <AppModal>
                    <div className='flex flex-col gap-4 text-2xl max-w-md text-center p-4'>
                        Are you sure you want to Approve?
                        <div className='flex items-center justify-center gap-4'>
                            <AppButton isSubmitting={isButtonLoading} text="Yes" onClick={handleApprove} />
                            <AppButton isSubmitting={isButtonLoading} text="No" onClick={closeApprove} />
                        </div>
                    </div>
                </AppModal>
            )}

            {declinePopUp && (
                <AppModal>
                    <div className='flex flex-col gap-4 text-2xl max-w-md text-center p-4'>
                        Are you sure you want to Decline?
                        <div className='flex items-center justify-center gap-4'>
                            <AppButton isSubmitting={isButtonLoading} text="Yes" onClick={handleDecline} />
                            <AppButton isSubmitting={isButtonLoading} text="No" onClick={closeDecline} />
                        </div>
                    </div>
                </AppModal>
            )}

            {openWalletModal && (
                <AppModal>
                    <h2 className="title pb-1 lg:pb-4">Vendor wallet</h2>
                    <AppButton text='Close' onClick={handleCloseWalletModal} />
                    {/* this is first div  */}
                    <div className="grid grid-cols-2 lg:flex flex-col lg:flex-row items-center gap-2 lg:gap-4 w-full">
                        {/* this is Main balance of wallet  */}
                        <div className="col-span-1 w-full lg:w-1/3 h-24 lg:h-36 px-2 lg:px-4 py-2 rounded lg:rounded lg:space-y-2 bg-gradient-to-r from-emerald-500 via-emerald-400 to-emerald-300 shadow-md">
                            <p className="font-medium text-white text-center text-base lg:text-2xl">Main Balance</p>
                            <div className="flex items-center justify-center">
                                <img className="w-5 lg:w-8 h-5 lg:h-8 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka-icon_s8y7yy.png" alt="" />
                                <h3 className="text-lg lg:text-3xl font-semibold text-white">{wallet.mainBalance}</h3>
                            </div>
                        </div>
                        <div className="col-span-1 w-full lg:w-1/3 h-24 lg:h-36 p-1 lg:p-4 rounded lg:rounded lg:space-y-2.5 bg-white myShadow border-b-2 lg:border-b-4 border-b-royalBlue">
                            <p className="font-medium text-center text-textBlack text-base lg:text-xl">Advance Income</p>
                            <div className="flex items-center justify-center">
                                <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_4_grdgmx.png" alt="" />
                                <h3 className="text-base lg:text-xl font-semibold text-royalBlue">{wallet?.advanceIncome}</h3>
                            </div>
                        </div>

                        {/* this is Total Reselling Earning of wallet  */}
                        <div className="col-span-2 w-full lg:w-1/3 h-24 lg:h-36 px-2 lg:px-3 py-2 lg:py-4 rounded lg:rounded space-y-1 lg:space-y-2 bg-white myShadow border-b-2 lg:border-b-4 border-b-red">
                            <div className="flex items-center justify-between">
                                <p className="font-medium text-textBlack text-sm lg:text-lg">Total Withdraw</p>
                                <div className="flex items-center">
                                    <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_2_j2npjs.png" alt="" />
                                    <h3 className="text-base lg:text-xl font-semibold text-red">{wallet.totalWithdrawAmount}</h3>
                                </div>
                            </div>
                            <div className="flex items-center justify-between">
                                <p className="font-medium text-textBlack text-sm lg:text-lg">Total Advance</p>
                                <div className="flex items-center">
                                    <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_2_j2npjs.png" alt="" />
                                    <h3 className="text-base lg:text-xl font-semibold text-red">{wallet.totalAdvanceIncome}</h3>
                                </div>
                            </div>
                            <div className="flex items-center justify-between">
                                <p className="font-medium text-textBlack text-sm lg:text-lg">Total Give Back</p>
                                <div className="flex items-center">
                                    <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_2_j2npjs.png" alt="" />
                                    <h3 className="text-base lg:text-xl font-semibold text-red">{wallet.totalCommisionToPay}</h3>
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* this is second div  */}
                    <div className="grid grid-cols-2 gap-2 lg:gap-4 pt-2 lg:pt-4">


                        {/* this is recharge your amount of wallet  */}
                        <div className="w-full h-28 lg:h-40 px-4 py-1 lg:py-2 rounded lg:rounded space-y-2 bg-[#FF4B4B] myShadow">
                            <p className="font-medium text-center text-white text-base lg:text-lg">Give Back</p>
                            <div className="flex flex-col items-center px-4 gap-2 lg:gap-4">
                                <div className="flex items-center justify-center">
                                    <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka-icon_s8y7yy.png" alt="" />
                                    <h3 className="text-base lg:text-xl font-semibold text-white">{wallet.commisionToPay}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </AppModal>
            )}

            <div className='max-w-6xl mx-auto'>
                {isShowSnackBar && (
                    <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
                )}
                <h2 className="title text-center">Vendor Withdraw Request List</h2>
                <div className="grid grid-cols-2 lg:flex items-center justify-between gap-2 py-2">
                    <AppSearchBox onChange={onSearch} className="myInput" placeholder='Search vendor request' />
                    <div className='w-full lg:w-1/4'>
                        <AppSelect data={pointStatuses} value={pointStatus} defaultValue='Select Request Status' onChange={handlePointStatusChange} />
                    </div>
                </div>
                {isLoading ? (
                    <AppLoader status={true} />
                ) : (
                    <>
                        {withdrawRequestList?.items?.length ? (
                            <div className="w-full">
                                {/* these is header part for desktop */}
                                <div className="hidden lg:flex w-full bg-primary myBorder">
                                    <div className="centerDiv text-white font-medium py-2.5 border-r w-[15%]">User Name</div>
                                    <div className="centerDiv text-white font-medium py-2.5 border-r w-[8%]">Amount</div>
                                    <div className="centerDiv text-white font-medium py-2.5 border-r w-[10%]">Status</div>
                                    <div className="centerDiv text-white font-medium py-2.5 border-r w-[14%]">PaymentNumber</div>
                                    <div className="centerDiv text-white font-medium py-2.5 border-r w-[7%]">Method</div>
                                    <div className="centerDiv text-white font-medium py-2.5 border-r w-[15%]">Date & Time</div>
                                    <div className="centerDiv text-white font-medium py-2.5 border-r w-[12%]">Approve</div>
                                    <div className="centerDiv text-white font-medium py-2.5 border-r w-[12%]">Decline</div>
                                    <div className="centerDiv text-white font-medium py-2.5 border-r w-[12%]">Wallet</div>
                                </div>
                                {/* these is middle part for desktop */}
                                <div className="hidden lg:flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-1 py-1.5">
                                    {withdrawRequestList?.items?.map((request: any) => (
                                        <div key={request?.id} className="myBorder flex w-full items-center">
                                            {/* <div className="centerDiv border-r border-grey pl-2 py-1 h-full whitespace-nowrap overflow-hidden text-ellipsis  w-[8%]">{request?.userId}</div> */}
                                            <div className="centerDiv justify-start border-r border-grey pl-2 py-1 h-full whitespace-nowrap overflow-hidden text-ellipsis w-[15%]">{request?.userName}</div>
                                            <div className="centerDiv border-r border-grey py-1 h-full w-[8%]">{request?.requestedAmount}</div>
                                            <div className={`centerDiv border-r border-grey py-1 h-full whitespace-nowrap overflow-hidden text-ellipsis  w-[10%]`}> <span className={`${request?.pointStatus == "InProgress" ? "InProgress" : "" || request?.pointStatus == "Cancelled" ? "canceled" : "" || request.pointStatus == "Received" ? "completed" : ""}`}>{request?.pointStatus}</span></div>
                                            <div className="centerDiv justify-start border-r border-grey pl-2 py-1 h-full text-sm w-[14%]">{request?.paymentNumber}</div>
                                            <div className="centerDiv justify-start border-r border-grey pl-2 py-1 h-full text-sm w-[7%]">{request?.paymentMethod}</div>
                                            <div className="centerDiv justify-start border-r border-grey pl-2 py-1 h-full text-sm w-[15%]">{orderTimeFormatter(request?.requestDateTime)}</div>
                                            <div className="centerDiv border-r border-grey py-1 h-full  w-[12%]">
                                                {pointStatus == 1 ? (
                                                    (
                                                        <>
                                                            <AppButton
                                                                text="Approve"
                                                                className="myBtn bg-royalBlue border-royalBlue py-0.5 lg:py-1 text-sm"
                                                                isSubmitting={isButtonLoading}
                                                                onClick={() => openApproveModal(request?.id)}
                                                            />
                                                        </>
                                                    )
                                                ) : (
                                                    <>
                                                        <AppButton
                                                            text=""
                                                            className="myBtn bg-royalBlue border-royalBlue py-0.5 lg:py-1 text-sm"
                                                            isSubmitting={isButtonLoading}
                                                            onClick={() => { }}
                                                        />

                                                    </>
                                                )}
                                            </div>
                                            <div className="centerDiv border-r border-grey py-1 h-full  w-[12%]">
                                                {pointStatus == 1 ? (
                                                    (
                                                        <>
                                                            <AppButton
                                                                text="Decline"
                                                                className="myBtn bg-red border-red py-0.5 lg:py-1 text-sm"
                                                                isSubmitting={isButtonLoading}
                                                                onClick={() => openDeclineModal(request?.id)}
                                                            />

                                                        </>
                                                    )
                                                ) : (
                                                    <>
                                                        <AppButton
                                                            text=""
                                                            className="myBtn bg-red border-red py-0.5 lg:py-1 text-sm"
                                                            isSubmitting={isButtonLoading}
                                                            onClick={() => { }}
                                                        />

                                                    </>
                                                )}
                                            </div>
                                            <div className="centerDiv py-1 h-full  w-[12%]">
                                                <AppButton
                                                    text="Wallet"
                                                    className="myBtn bg-green border-green py-0.5 lg:py-1 text-sm"
                                                    onClick={() => handleOpenWalletModal(request?.userId)}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {/* these is middle part for mobile */}
                                <div className="h-[78dvh] lg:hidden overflow-y-auto flex flex-col gap-2">
                                    {withdrawRequestList?.items?.map((request?: any) => (
                                        <div key={request?.id} className="myBorder flex flex-col w-full p-2">

                                            <div className="flex flex-col w-full">
                                                <div className="flex items-center gap-1">
                                                    <span className="font-medium">User Name:</span> <span>{request?.userName}</span>
                                                </div>
                                                <div className="grid grid-cols-2">
                                                    <div className="flex items-center gap-1">
                                                        <span className="font-medium">User ID:</span> <span>{request?.userId}</span>
                                                    </div>
                                                    <div className="flex items-center gap-1">
                                                        <span className="font-medium">Amount:</span> <span>{request?.requestedAmount}</span>
                                                    </div>
                                                </div>
                                                <div className="flex items-center gap-1">
                                                    <span className="font-medium">Request Status:</span>  <span className={`${request?.pointStatus == "InProgress" ? "InProgress" : "" || request?.pointStatus == "Cancelled" ? "canceled" : "" || request.pointStatus == "Received" ? "completed" : ""}`}>{request?.pointStatus}</span>
                                                </div>
                                                <div className="flex items-center gap-1">
                                                    <span className="font-medium">Date & Time:</span> <span className="text-sm"> {orderTimeFormatter(request?.requestDateTime)}</span>
                                                </div>
                                                <div className="grid grid-cols-1">
                                                    <div className="flex items-center gap-1">
                                                        <span className="font-medium">PaymentNumber:</span> <span>{request?.paymentNumber}</span>
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-1">
                                                    <div className="flex items-center gap-1">
                                                        <span className="font-medium">Method:</span> <span>{request?.paymentMethod}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-center gap-4 w-full pt-2">
                                                {pointStatus == 1 ? (
                                                    (
                                                        <>
                                                            <AppButton
                                                                text="Approve"
                                                                className="myBtn bg-royalBlue border-royalBlue py-0.5 lg:py-1 text-sm"
                                                                isSubmitting={isButtonLoading}
                                                                onClick={() => openApproveModal(request?.id)}
                                                            />
                                                            <AppButton
                                                                text="Decline"
                                                                className="myBtn bg-red border-red py-0.5 lg:py-1 text-sm"
                                                                isSubmitting={isButtonLoading}
                                                                onClick={() => openDeclineModal(request?.id)}
                                                            />
                                                        </>
                                                    )
                                                ) : (
                                                    <>
                                                        <AppButton
                                                            text=""
                                                            className="myBtn bg-royalBlue border-royalBlue py-0.5 lg:py-1 text-sm"
                                                            isSubmitting={isButtonLoading}
                                                            onClick={() => { }}
                                                        />
                                                        <AppButton
                                                            text=""
                                                            className="myBtn bg-red border-red py-0.5 lg:py-1 text-sm"
                                                            isSubmitting={isButtonLoading}
                                                            onClick={() => { }}
                                                        />
                                                    </>
                                                )}

                                            </div>
                                            <AppButton
                                                text="Wallet"
                                                className="myBtn bg-green border-green py-0.5 lg:py-1 text-sm"
                                                onClick={() => handleOpenWalletModal(request?.userId)}
                                            />
                                        </div>
                                    ))}
                                </div>
                                <div className="py-1 flex items-center">
                                    <AppPagination curPage={1} limit={10} offset={0} onPageChange={handleOnPageChange} onRowChange={handleOnRowChange} size={withdrawRequestList?.size} />
                                </div>
                            </div>
                        ) : (
                            <div className="w-full h-[50dvh] flex justify-center items-center text-3xl text-textBlack/90 font-medium">
                                <span>No Withdraw Requests found</span>
                            </div>
                        )}
                    </>)}
            </div>
        </>
    )
}
