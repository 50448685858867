import { PaginationPayloadModel } from "../../../common/paginationModels";

export class SSAddSubCatagory {
    id?: number;
    name?: string;
    description?: string;
    isPublished?: boolean;
    isShowOnHomePage?: boolean;
    isSpecial?: boolean;
    displayOrder?: number;
    sSMainCategoryId?: Number;
    userRoleId?: number;
    bannerId?: number;
}

export class SSUpdateSubCatagory {
    id?: any;
    name?: string;
    description?: string;
    isPublished?: boolean;
    isShowOnHomePage?: boolean;
    isSpecial?: boolean;
    displayOrder?: number;
    sSMainCategoryId?: Number;
    userRoleId?: number;
    bannerId?: number;
}

export class SSGetSubCategoryListRequest extends PaginationPayloadModel {
    isAll?: any = true;
    forPublic?: any = false;
    sSMainCategoryId?: any = 0;
}
