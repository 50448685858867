import { Box, CircularProgress, Fade } from "@mui/material";
import * as React from 'react';

interface ILoadingProps {
  status: boolean;
}

export const AppLoader: React.FC<ILoadingProps> = ({ status }) => {

  //const [loading] = React.useState(status);
  const timerRef = React.useRef<number>();

  React.useEffect(
    () => () => {
      clearTimeout(timerRef.current);
    },
    [],
  );


  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ height: 40 }}>
        <Fade
          in={status}

          unmountOnExit
        >
          <CircularProgress className="text-9xl lg:text-[150px]" />
        </Fade>
      </Box>
    </Box>
  );
}
