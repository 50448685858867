import { Route, Routes } from "react-router-dom";
import { MagazinePostList } from "../../components/public/magazine/MagazinePostList";

export default function MagazineRoute() {
  return (
    <>
      <Routes>
        <Route index element={<MagazinePostList />} />
        <Route path="/newsFeed" element={<MagazinePostList />} />
      </Routes>
    </>
  );
}