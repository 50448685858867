import { AlertColor } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../store";
import { AppButton } from "../../../common/AppButton";
import { AppDeleteModal } from "../../../common/AppDeleteModal";
import { AppSearchBox } from "../../../common/AppSearchBox";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { AppLoader } from "../../../common/AppLoader";
import { bechteChaiSubCategoryList } from "../../../../store/actions/bechteChai/bechteChaiCategoryActions";
import { deleteBechteChaiSubCategoryService } from "../../../../httpService/bechteChai/categoryServices";
import { PaginationPayloadModel } from "../../../../models/common/paginationModels";

export default function AdminGetBechteChaiSubCategories() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  var limit = 10;
  var offset = 0;

  const subCatagoryLevelOneList = useSelector((state: RootState) => {
    return state.bechteChaiSubCategoryListResponse.data;
  });

  var paginationModel = new PaginationPayloadModel();

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);

  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [subCategoryLevelOneId, setSubCategoryLevelOneId] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(bechteChaiSubCategoryList());
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  const handlePageChange = (payload: PaginationPayloadModel) => {
    dispatch(bechteChaiSubCategoryList(undefined, payload));
  };

  const handleRowChange = (payload: PaginationPayloadModel) => {
    dispatch(bechteChaiSubCategoryList(undefined, payload));
  };

  const onSearch = (searchQuery: string) => {
    paginationModel.searchQuery = searchQuery;
    dispatch(bechteChaiSubCategoryList(undefined, paginationModel));
  };

  const handleUpdate = (id: number) => {
    navigate(`/admin/bechte-chai/sub-category/update/${id}`);
  };

  const [bulkIds, setBulkIds] = useState([]);

  const openDeleteModal = (id: any) => {
    setOpenDeleteModal(true);

    let ids: any = [];
    ids.push(id);

    setBulkIds(ids);
  };

  const handleBulkDelete = () => {
    handleDelete();
  };

  const handelBulkSelection = (event: any) => {
    setBulkIds(event);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleDelete = () => {
    setSnackBarOn(false);
    deleteBechteChaiSubCategoryService(bulkIds)
      .then(() => {
        dispatch(bechteChaiSubCategoryList());
        setSnackBarOn(true);
        setsnackBarMessage("Successfully Deleted");
        setSnackBarMessageType("success");
        closeDeleteModal();
      })
      .catch(() => {
        setSnackBarOn(true);
        setsnackBarMessage("Something went wrong");
        setSnackBarMessageType("error");
      });
  };

  const navigateToAdd = () => {
    navigate("/admin/bechte-chai/sub-category/add");
  };

  return (
    <div>
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      <h2 className="text-center title">Bechte Chai Sub Category Admin List</h2>

      <div className="flex flex-col lg:flex-row items-center gap-8">
        <AppButton onClick={navigateToAdd} text="Add Sub Category" />
        {/* <AppButton onClick={handleBulkDelete} text="Remove" /> */}
        <AppSearchBox className="myInput" onChange={onSearch} placeholder="Search Here" />
      </div>
      {isLoading ? (
        <AppLoader status={true} />
      ) : (
        <>
          {subCatagoryLevelOneList.items ? (
            <div className="w-full pt-4">
              {/* these is header part for desktop */}
              <div className="hidden lg:flex w-full mx-auto bg-primary myBorder">
                <div className="centerDiv text-white font-medium py-2.5 border-r w-[21%]">Name</div>
                <div className="centerDiv text-white font-medium py-2.5 border-r w-[23.9%]">Total Product</div>
                <div className="centerDiv text-white font-medium py-2.5 border-r w-[19.8%]">Main category Name</div>
                <div className="centerDiv text-white font-medium py-2.5 border-r w-[10.8%]">Is Published</div>
                <div className="centerDiv text-white font-medium py-2.5 border-r w-[12%]">Update</div>
                <div className="centerDiv text-white font-medium py-2.5 w-[12%]">Remove</div>
              </div>
              {/* these is middle part for desktop */}
              <div className="hidden lg:flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-1 py-1.5">
                {subCatagoryLevelOneList.items.map((subCategory: any) => (
                  <div key={subCategory?.id} className="myBorder flex w-full items-center">
                    <div className="centerDiv justify-start border-r border-grey pl-2 py-1 h-full whitespace-nowrap overflow-hidden text-ellipsis w-[21%]">{subCategory?.name}</div>
                    <div className="centerDiv justify-start border-r border-grey pl-2 py-1 h-full whitespace-nowrap overflow-hidden text-ellipsis w-[24%]">{subCategory?.totalProduct}</div>
                    <div className="centerDiv justify-start border-r border-grey pl-2 py-1 h-full whitespace-nowrap overflow-hidden text-ellipsis w-[20%]">{subCategory?.mainCategoryName}</div>
                    <div className="centerDiv border-r border-grey py-1 h-full w-[11%]">{subCategory?.isPublished === true ? "Yes" : "No"}</div>
                    <div className="centerDiv border-r border-grey py-1 h-full  w-[12%]">
                      <AppButton
                        className="bechteChaiBtn py-0.5 lg:py-1 text-sm"
                        text="Update"
                        onClick={() => handleUpdate(subCategory?.id)}
                      />
                    </div>
                    <div className="centerDiv border-r border-grey py-1 h-full w-[12%]">
                      <AppButton
                        className="bechteChaiBtn bg-red border-red py-0.5 lg:py-1 text-sm"
                        text="Remove"
                        onClick={() => openDeleteModal(subCategory?.id)}
                      />
                    </div>
                  </div>
                ))}
              </div>
              {/* these is middle part for mobile */}
              <div className="h-[78dvh] lg:hidden overflow-y-auto flex flex-col gap-2">
                {subCatagoryLevelOneList.items.map((subCategory?: any) => (
                  <div key={subCategory?.id} className="myBorder flex flex-col w-full p-2">

                    <div className="flex flex-col w-full">
                      <div className="flex items-center gap-1">
                        <span className="font-medium">Name:</span> <span>{subCategory?.name}</span>
                      </div>
                      <div className="flex items-center gap-1">
                        <span className="font-medium">Total Product:</span> <span>{subCategory?.totalProduct}</span>
                      </div>
                      <div className="flex items-center gap-1">
                        <span className="font-medium">Main Category Name:</span> <span>{subCategory?.mainCategoryName}</span>
                      </div>
                      <div className="flex items-center gap-1">
                        <span className="font-medium">Is published:</span> <span>{subCategory?.isPublished === true ? "Yes" : "No"}</span>
                      </div>
                    </div>
                    {/* these is approve decline details div */}
                    <div className="flex items-center justify-center gap-4 w-full pt-2">
                      <AppButton
                        className="bechteChaiBtn py-0.5 lg:py-1 text-sm"
                        text="Update"
                        onClick={() => handleUpdate(subCategory?.id)}
                      />
                      <AppButton
                        className="bechteChaiBtn bg-red border-red py-0.5 lg:py-1 text-sm"
                        text="Remove"
                        onClick={() => openDeleteModal(subCategory?.id)}
                      />
                    </div>
                  </div>
                ))}
              </div>
              {/* these is footer part  */}
              {/* <div className="myBorder py-1 text-center">pagination here</div> */}
            </div>
          ) : (
            <div className="w-full h-[50dvh] flex justify-center items-center text-3xl text-textBlack/90 font-medium">
              <span>No Sub category found</span>
            </div>
          )}
        </>)}

      {isOpenDeleteModal && (
        <AppDeleteModal
          text="Are you sure to Delete?"
          handleDelete={handleDelete}
          closeDeleteModal={closeDeleteModal}
        />
      )}
    </div>
  );
}
