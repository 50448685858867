import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../../../store";
import { PaginationPayloadModel } from "../../../../models/common/paginationModels";
import { AppButton } from "../../../common/AppButton";
import { AppSearchBox } from "../../../common/AppSearchBox";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { AppDeleteModal } from "../../../common/AppDeleteModal";
import { AlertColor } from "@mui/material";
import { reactLocalStorage } from "reactjs-localstorage";
import { BsFillCircleFill } from "react-icons/bs";
import { TbCurrencyTaka } from "react-icons/tb";
import { AppLoader } from "../../../common/AppLoader";
import { AppPagination } from "../../../common/AppPagination";
import { AppSelect } from "../../../common/AppSelect";
import { ProductPublishModel } from "../../../../models/catalog/product/productsModels";
import { isAdmin, isCustomer } from "../../../../common/common";
import { getUserProfileAction } from "../../../../store/actions/auth/user/userActions";
import { bechteChaiProductList } from "../../../../store/actions/bechteChai/bechteChaiProductActions";
import { bechteChaiProductPublishService, deleteBechteChaiProductService } from "../../../../httpService/bechteChai/bechteChaiProductServices";

export default function BechteChaiGetProducts() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  var paginationModel = new PaginationPayloadModel();

  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);

  const productList = useSelector((state: RootState) => {
    return state.bechteChaiProductsListResponse.data;
  });

  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const filterOptions = [
    { name: "Active Products", id: 1 },
    { name: "Inactive Products", id: 2 },
  ];

  const [filterById, setFilterById] = useState(localStorage.getItem("shouldMaintainPage") ? parseInt(localStorage.getItem("filterById") || "0", 10) : 0);

  const [searchQuery, setSearchQuery] = useState(localStorage.getItem("shouldMaintainPage") ? localStorage.getItem("searchQuery") ?? "" : "");

  const [currentPage, setCurrentPage] = useState(localStorage.getItem("shouldMaintainPage") ? parseInt(localStorage.getItem("currentPage") || "1", 10) : 1);

  const [pageLimit, setPageLimit] = useState(localStorage.getItem("shouldMaintainPage") ? parseInt(localStorage.getItem("pageLimit") || "5", 10) : 10);
  useEffect(() => {
    localStorage.removeItem("shouldMaintainPage");
  }, []);

  const handleOptionChange = (value: any) => {
    setFilterById(value);
    setCurrentPage(1);
    paginationModel.filterBy = value;
    paginationModel.limit = pageLimit;
    paginationModel.offset = 0;
    paginationModel.searchQuery = searchQuery;
    dispatch(bechteChaiProductList(paginationModel));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        paginationModel.limit = pageLimit;
        paginationModel.offset = pageLimit * (currentPage - 1);
        paginationModel.filterBy = filterById;
        paginationModel.searchQuery = searchQuery;
        await dispatch(bechteChaiProductList(paginationModel));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  const handleOnRowChange = (payload: PaginationPayloadModel) => {
    const fetchData = async () => {
      try {
        setCurrentPage(payload.curPage);
        setPageLimit(payload.limit ?? pageLimit);
        paginationModel.limit = payload.limit;
        paginationModel.offset = payload.offset;
        paginationModel.filterBy = filterById;
        paginationModel.searchQuery = searchQuery;
        await dispatch(bechteChaiProductList(paginationModel));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  };

  const handleOnPageChange = (payload: PaginationPayloadModel) => {
    const fetchData = async () => {
      try {
        setCurrentPage(payload.curPage ?? 1);
        paginationModel.limit = payload.limit;
        paginationModel.offset = payload.offset;
        paginationModel.filterBy = filterById;
        paginationModel.searchQuery = searchQuery;
        await dispatch(bechteChaiProductList(paginationModel));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  };

  const onSearch = (searchText: string) => {
    setSearchQuery(searchText);
    setCurrentPage(1);
    paginationModel.searchQuery = searchText;
    paginationModel.limit = pageLimit;
    paginationModel.offset = 0;
    paginationModel.filterBy = filterById;
    dispatch(bechteChaiProductList(paginationModel));
  };

  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  const navigateToAdd = () => {
    navigate("/admin/bechte-chai/product/add");
  };

  const handleUpdate = (id: any) => {
    navigate(`/admin/bechte-chai/product/update/${id}`);
  };

  const [bulkIds, setBulkIds] = useState([]);

  const openDeleteModal = (id: any) => {
    setOpenDeleteModal(true);

    let ids: any = [];
    ids.push(id);

    setBulkIds(ids);
  };

  const handleNavigateProductDetails = (id: any) => {
    navigate(`/admin/bechte-chai/product/view/${id}`);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleDelete = () => {
    setSnackBarOn(false);
    deleteBechteChaiProductService(bulkIds)
      .then((response) => {
        console.log("response: "+response);
        setSnackBarOn(true);
        setSnackBarMessage("Successfully Deleted");
        setSnackBarMessageType("success");
        closeDeleteModal();
      })
      .catch((error: any) => {
        console.log(error);
        setSnackBarOn(true);
        setSnackBarMessage("Something went wrong");
        setSnackBarMessageType("error");
      });
      paginationModel.limit = pageLimit;
      paginationModel.offset = pageLimit * (currentPage - 1);
      paginationModel.filterBy = filterById;
      paginationModel.searchQuery = searchQuery;
      dispatch(bechteChaiProductList(paginationModel));
  };

  const [isUpdating, setIsUpdating] = useState(false);
  const [products, setProducts] = useState<any>([]);

  useEffect(() => {
    setProducts(productList?.items);
  }, [dispatch, productList]);

  const handleProductPublish = (id: any, publish: boolean) => {
    var model = new ProductPublishModel();
    model.productId = id;
    setIsUpdating(true);
    bechteChaiProductPublishService(model)
      .then(() => {
        setIsUpdating(false);
        const pro = products.map((p: any) => {
          if (p.id === id) {
            return { ...p, isPublished: publish };
          }
          return p;
        });
        setProducts(pro);
      })
      .catch(() => {
        setIsUpdating(false);
      });
  };

  const userProfile = useSelector((state: RootState) => {
    return state.getUserProfileResponse.data;
  });

  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);


  return (
    <div>
      {userProfile?.isBlocked && <h2 className="text-xl text-red md:text-3xl font-semibold">Your Account has been Suspended. Please contact with Admin.</h2>}
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}

      <h2 className="title text-center">Product list ({productList?.size})</h2>

      <div className="flex flex-col justify-between gap-2 pb-2 pt-0.5 lg:flex-row lg:items-center lg:gap-4">
        <div className="space-x-4">
          {(isCustomer(reactLocalStorage.get("RoleName")) || isAdmin(reactLocalStorage.get("RoleName"))) && !userProfile?.isBlocked && (
            <AppButton
              className="myBtn px-3 text-xs lg:px-4 lg:text-base"
              onClick={navigateToAdd}
              text="Add New product"
            />
          )}
          <AppSearchBox
            className="myInput text-sm lg:text-base"
            value={searchQuery}
            onChange={onSearch}
            placeholder="Search product Here"
          />
        </div>
        <div className="w-full lg:w-1/4">
          <AppSelect
            defaultValue="Filter Products"
            data={filterOptions}
            value={filterById}
            onChange={handleOptionChange}
          />
        </div>
      </div>
      {isLoading ? (
        <AppLoader status={true} />
      ) : (
        <>
          {products ? (
            <div className="w-full">
              {/* these is header part for desktop */}
              <div className="hidden w-full rounded bg-primary lg:flex">
                <div className="centerDiv w-[4%] border-r py-2.5 font-medium text-white">
                  ID
                </div>
                <div className="centerDiv w-[8%] border-r py-2.5 font-medium text-white">
                  Photo
                </div>
                <div className="centerDiv w-[14.9%] border-r py-2.5 font-medium text-white">
                  Name
                </div>                     
                <div className="centerDiv w-[14.9%] border-r py-2.5 font-medium text-white">
                  Phpne
                </div>                
                <div className="centerDiv w-[14.9%] border-r py-2.5 font-medium text-white">
                  Category
                </div>
                <div className="centerDiv w-[9.9%] border-r py-2.5 font-medium text-white">
                  Price
                </div>
                <div className="centerDiv w-[7.9%] border-r py-2.5 font-medium text-white">
                  Published
                </div>
                <div className="centerDiv w-[8%] border-r py-2.5 font-medium text-white">
                  Update
                </div>
                <div className="centerDiv w-[9.9%] border-r py-2.5 font-medium text-white">
                  Status
                </div>
                <div className="centerDiv w-[8%] py-2.5 font-medium text-white">
                  Details
                </div>
                <div className="centerDiv w-[8%] py-2.5 font-medium text-white">
                  Delete
                </div>
              </div>
              {/* these is middle part for desktop */}
              <div className="mb-2 hidden h-[62dvh] flex-col gap-1 overflow-y-auto py-1 lg:flex  2xl:h-[68dvh]">
                {products?.map((product: any) => (
                  <div
                    key={product.id}
                    className="myBorder flex w-full items-center"
                  >
                    <div className="centerDiv h-full w-[4%] border-r border-grey font-medium">
                      {product.id}
                    </div>
                    <div className="centerDiv h-full w-[8%] overflow-hidden border-r border-grey">
                      <img
                        className="h-12 w-14 rounded object-cover object-center"
                        title={product.name}
                        src={product.photoPath}
                        alt="product pic"
                      />
                    </div>
                    <div className="centerDiv h-full w-[15%] border-r border-grey pl-0.5">
                      {product.name}
                    </div>
                    <div className="centerDiv h-full w-[15%] border-r border-grey pl-0.5">
                      {product.phone}
                    </div>
                    <div className="centerDiv h-full w-[15%] border-r border-grey pl-0.5">
                      {product.categoryName}
                    </div>
                    <div className="centerDiv h-full w-[10%] border-r border-grey font-medium">
                      ৳{parseInt(product.price).toFixed(0)}
                    </div>
                    <div className="centerDiv h-full w-[8%] border-r border-grey">
                      {product.isPublished ? (
                        <div className="centerDiv gap-1">
                          <BsFillCircleFill className="text-xs text-green-500" />{" "}
                          Active
                        </div>
                      ) : (
                        <div className="centerDiv gap-1">
                          <BsFillCircleFill className="text-xs text-red" />{" "}
                          Inactive
                        </div>
                      )}
                    </div>
                    <div className="centerDiv h-full w-[8%] border-r border-grey font-medium">
                      <AppButton
                        className="myBtn border-royalBlue bg-royalBlue py-0.5 text-sm lg:py-1"
                        text="Update"
                        onClick={() => handleUpdate(product.id)}
                      />
                    </div>
                    <div className="centerDiv h-full w-[10%] border-r border-grey font-medium">
                      {product.isPublished ? (
                        <AppButton
                          isSubmitting={isUpdating}
                          className="myBtn border-red bg-red py-0.5 text-sm lg:px-2 lg:py-1"
                          text="UnPublish"
                          onClick={() =>
                            handleProductPublish(product.id, false)
                          }
                        />
                      ) : (
                        <AppButton
                          isSubmitting={isUpdating}
                          className="myBtn bg-green border-green py-0.5 text-sm lg:py-1"
                          text="Publish"
                          onClick={() => handleProductPublish(product.id, true)}
                        />
                      )}
                    </div>
                    <div className="centerDiv h-full w-[8%] border-r border-grey font-medium">
                      <AppButton
                        className="myBtn py-0.5 text-sm lg:py-1"
                        text="Details"
                        onClick={() => handleNavigateProductDetails(product.id)}
                      />
                    </div>
                    <div className="centerDiv h-full w-[8%] border-r border-grey font-medium">
                      <AppButton
                        className="myBtn border-red bg-red py-0.5 text-sm lg:py-1"
                        text="Delete"
                        onClick={() => openDeleteModal(product.id)}
                      />
                    </div>
                  </div>
                ))}
              </div>
              {/* these is middle part for mobile */}
              <div className="flex h-[78dvh] flex-col gap-2 overflow-y-auto pb-10 pt-2 lg:hidden">
                {products?.map((product: any) => (
                  <div
                    key={product.id}
                    className="myBorder flex w-full flex-col p-2 lg:flex-row"
                  >
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center gap-1">
                        <span className="font-medium">ID:</span>{" "}
                        <span> {product.id}</span>
                      </div>
                      <div className="flex items-center gap-1">
                        {product.isPublished ? (
                          <div className="centerDiv gap-1">
                            <BsFillCircleFill className="text-xs text-green-500" />{" "}
                            Active
                          </div>
                        ) : (
                          <div className="centerDiv gap-1">
                            <BsFillCircleFill className="text-xs text-red" />{" "}
                            Inactive
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex w-full items-start justify-between gap-1 overflow-hidden py-1">
                      <div className="flex w-full items-start gap-1">
                        <span className="font-medium">Name:</span>{" "}
                        <span className=" text-sm ">{product.name}</span>
                      </div>
                      <img
                        className="h-12 w-14 rounded border border-grey object-cover object-center shadow-md"
                        title={product.name}
                        src={product.photoPath}
                        alt="product pic"
                      />
                    </div>
                    
                    <div className="flex w-full items-center justify-between gap-2">
                      <div className="flex items-center gap-1">
                        <span className="font-medium">Price</span>{" "}
                        <span className="flex items-center">
                          <TbCurrencyTaka />
                          {parseInt(product.price).toFixed(0)}
                        </span>
                      </div>

                      <div className="flex items-center gap-1">
                        <span className="font-medium">Highest price</span>{" "}
                        <span className="flex items-center">
                          <TbCurrencyTaka /> {product.heightPrice}
                        </span>
                      </div>
                    </div>

                    <div className="flex w-full items-center justify-between pt-2">
                      <AppButton
                        className="myBtn border-royalBlue bg-royalBlue py-0.5 text-sm lg:py-1"
                        text="Update"
                        onClick={() => handleUpdate(product.id)}
                      />
                      {product.isPublished ? (
                        <AppButton
                          isSubmitting={isUpdating}
                          className="myBtn border-red bg-red py-0.5 text-sm lg:py-1"
                          text="UnPublish"
                          onClick={() =>
                            handleProductPublish(product.id, false)
                          }
                        />
                      ) : (
                        <AppButton
                          isSubmitting={isUpdating}
                          className="myBtn bg-green border-green py-0.5 text-sm lg:py-1"
                          text="Publish"
                          onClick={() => handleProductPublish(product.id, true)}
                        />
                      )}
                      <AppButton
                        className="myBtn py-0.5 text-sm lg:py-1"
                        text="Details"
                        onClick={() => handleNavigateProductDetails(product.id)}
                      />
                      <AppButton
                        isSubmitting={isUpdating}
                        className="myBtn border-red bg-red py-0.5 text-sm lg:py-1"
                        text="Delete"
                        onClick={() => openDeleteModal(product.id)}
                      />
                    </div>

                  </div>
                ))}
              </div>
              {/* these is footer part  */}
              <div className="fixed bottom-1 flex w-full items-center bg-white py-1 lg:static">
                <AppPagination
                  curPage={currentPage}
                  limit={pageLimit}
                  offset={pageLimit * (currentPage - 1)}
                  onPageChange={handleOnPageChange}
                  onRowChange={handleOnRowChange}
                  size={productList?.size}
                />
              </div>
            </div>
          ) : (
            <div className="flex h-[50dvh] w-full items-center justify-center text-3xl font-medium text-textBlack/90">
              <span>No product found</span>
            </div>
          )}
        </>
      )}
      {isOpenDeleteModal && (
        <AppDeleteModal
          text="Are you sure to Delete?"
          handleDelete={handleDelete}
          closeDeleteModal={closeDeleteModal}
        />
      )}
    </div>
  );
}
