import { Route, Routes } from 'react-router-dom'
import ReturnPolicy from '../../components/public/common/ReturnPolicy'
import AffiliateGetCartItems from '../../components/public/affiliate/AffiliateGetCartItems'
import AffiliateGetFavouriteItems from '../../components/public/affiliate/AffiliateGetFavouriteItems'
import AffiliateGetFilterProducs from '../../components/public/affiliate/AffiliateGetFilterProducs'
import AffiliateGetMyTeam from '../../components/public/affiliate/AffiliateGetMyTeam'
import AffiliateGetOrderDetails from '../../components/public/affiliate/AffiliateGetOrderDetails'
import AffiliateGetOrderList from '../../components/public/affiliate/AffiliateGetOrderList'
import AffiliateGetProductById from '../../components/public/affiliate/AffiliateGetProductById'
import AffiliateGetProductsByShop from '../../components/public/affiliate/AffiliateGetProductsByShop'
import AffiliateHomeComponent from '../../components/public/affiliate/AffiliateHomeComponent'
import AffiliateSubmitOrder from '../../components/public/affiliate/AffiliateSubmitOrder'

export default function AffiliatePlatfromRoute() {
    return (
        <Routes>
            <Route index element={<AffiliateHomeComponent />} />
            <Route path="/orders" element={<AffiliateGetOrderList />} />
            <Route path="/order/:id" element={<AffiliateGetOrderDetails />} />

            <Route path="/favourites" element={<AffiliateGetFavouriteItems />} />
            <Route path="/myTeam" element={<AffiliateGetMyTeam />} />
            <Route path="/returnPolicy" element={<ReturnPolicy />} />

            <Route path="/shoppingcart" element={<AffiliateGetCartItems />} />
            <Route path="/submitBillingAndShippingAddress" element={<AffiliateSubmitOrder />} />

            <Route path="/filterProductList/:mainCatId/:subCatId" element={<AffiliateGetFilterProducs />} />
            <Route path="/vendorProducts/:id" element={<AffiliateGetProductsByShop />} />
            <Route path="/productDetails/:id" element={<AffiliateGetProductById />} />
            <Route path="/productDetails/:id/affiliateProductRef/:affiliateProductRef" element={<AffiliateGetProductById />} />
        </Routes>
    )
}