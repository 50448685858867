import { AppButton } from './AppButton'
import { AppModal } from './AppModal';

interface IDeleteModalProps {
    text: string
    handleDelete?: any;
    closeDeleteModal?: any;
}

export const AppDeleteModal: React.FC<IDeleteModalProps> = ({ text, handleDelete, closeDeleteModal }) => {

    const handleSubmit = () => {
        handleDelete();
    };

    const closeModal = () => {
        closeDeleteModal();
    };

    return (
        <AppModal>
            <div className='flex text-xl gap-3 flex-col max-w-xs p-5'>
                {text}
                <div className="flex items-center justify-between">
                    <AppButton text="Yes" onClick={handleSubmit} />
                    <AppButton text="No" onClick={closeModal} />
                </div>
            </div>
        </AppModal>
    )
}
