const initialState = {
  userRole: [],
};

const initialState2 = {
  role: false,
};

export default function userRoleReducer(state = initialState, action: any) {
  if (action.type === "GET-USER-ROLE-LIST") {
    return { userRole: action.payload };
  }
  return state;
}

function userRoleDispatchReducer(state = initialState2, action: any) {
  if (action.type === "USER-ROLE-DISPATCH") {
    return { role: true }
  }
  return state;
}

export { userRoleReducer, userRoleDispatchReducer };