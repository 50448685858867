import _moment from "moment";
import { useEffect, useState } from "react";
import { GetUserPointRequest, GetUserPointResponse } from "../../../../models/auth/user/UserPointModels";
import { RootState } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyPointListAction } from "../../../../store/actions/auth/user/userActions";
import { AppPagination } from "../../../common/AppPagination";
import { AppLoader } from "../../../common/AppLoader";

export default function AdminGetCompanyWalletHistory() {

    const dispatch = useDispatch();

    const orderTimeFormatter = (createdDateTime: any) => {
        const createdTime = _moment(createdDateTime, "YYYY-MM-DD HH:mm:ss.SSSSSSS");
        return createdTime.format("MMM D, YY [at] h:mm A");
    };

    const [isLoading, setIsLoading] = useState(true);

    const [searchQuery, setSearchQuery] = useState("");

    const [earningType, setEarningType] = useState(0);
    const [earningStatus, setEarningStatus] = useState(0);

    const pointList = useSelector((state: RootState) => {
        return state.getCompanyPointListResponse.data;
    });

    var model = new GetUserPointRequest();

    useEffect(() => {
        const fetchData = async () => {
            try {

                model.userId = 0;
                model.searchQuery = searchQuery;
                model.earningStatus = earningStatus;
                model.earningType = earningType;
                model.limit = 10;
                model.offset = 0
                await dispatch(getCompanyPointListAction(model));
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [dispatch]);

    const handleOnRowChange = (payload: GetUserPointRequest) => {
        const fetchData = async () => {
            try {
                model.limit = payload.limit;
                model.offset = 0
                await dispatch(getCompanyPointListAction(model));
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };

        fetchData();
    };

    const handleOnPageChange = (payload: GetUserPointRequest) => {
        const fetchData = async () => {
            try {
                model.limit = payload.limit;
                model.offset = payload.offset
                await dispatch(getCompanyPointListAction(model));
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };
        fetchData();
    };

    return (
        <>
            <div className="flex w-full bg-primary rounded">
                <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[20%]">Earning Amount</div>
                <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[19.85%]">Date & Time</div>
                <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[19.89%]">Earning Type</div>
                <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[19.8%]">Earning Status</div>
                <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 w-[20%]">Earned From</div>
            </div>
            <div className="flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-0.5 lg:gap-1 py-0.5 lg:py-1">
                {isLoading ? (
                    <AppLoader status={true} />
                ) : (
                    <>
                        {pointList?.items?.map((item: GetUserPointResponse) => {
                            return (
                                <div key={item?.id} className="myBorder flex w-full items-center">
                                    <div className="centerDiv border-r border-grey text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[20%]">৳ {item?.earnedAmount}</div>
                                    <div className="centerDiv border-r border-grey text-xxs lg:text-sm px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[20%]">{orderTimeFormatter(item.createdAt)}</div>
                                    <div className="centerDiv border-r border-grey text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[20%]">
                                        <p className={item.earningType === "CourseEarning" ? "CourseEarning" : "" || item.earningType === "ResellerEarning" ? "ResellerEarning" : "" || item.earningType === "RefferEarning" ? "RefferEarning" : "" || item.earningType === "ModeratorEarning" ? "ModeratorEarning" : "" || item.earningType === "ProductEarning" ? "ProductEarning" : "" || item.earningType === "AdvancedPaymentEarning" ? "AdvancedPaymentEarning" : "" || item.earningType === "ShippingCost" ? "ShippingCost" : "" || item.earningType === "PullEarning" ? "PullEarning" : ""}>
                                            {item.earningType}
                                        </p>
                                    </div>
                                    <div className="centerDiv border-r border-grey text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[20%]">
                                        <p className={item.earningStatus === "InProgress" ? "ProgressIn" : "" || item.earningStatus === "Denied" ? "Denied" : "" || item.earningStatus === "Earned" ? "Earned" : ""}>{(item.earningType === "AdvancedPaymentEarning" || item.earningType === "ShippingCost") ? "Returned" : item.earningStatus == "Denied" ? "Cancelled" : item.earningStatus}</p>
                                    </div>
                                    <div className="centerDiv text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[20%]">{item?.earnedFrom}</div>
                                </div>
                            );
                        })}
                    </>)}
            </div>
            <div className="py-1 flex items-center">
                <AppPagination curPage={1} limit={10} offset={0} onPageChange={handleOnPageChange} onRowChange={handleOnRowChange} size={pointList?.size} />
            </div>
        </>
    )
}
