import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import { AlertColor, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { GET } from "../../../httpService/service";
import { updatePopularLinkService } from "../../../httpService/popularLink/popularLinkServices";
import { AppButton } from "../../common/AppButton";
import { AppFileInput } from "../../common/AppFileInput";
import { AppInput } from "../../common/AppInput";
import { AppSnackBar } from "../../common/AppSnackBar";
import { AppTextArea } from "../../common/AppTextArea";
import { AddPopularLink } from "../../../models/catalog/category/categoryModels";
import { popularLinkListAction } from "../../../store/actions/popularLink/popularLinkActions";

export default function AdminUpdatePopularLink(props: any) {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { id } = useParams();
    const popularLinkById = async (id: any) => {
        const data = GET(`/api/PopularLink/GetPopularLinkById?Id=${id}`);
        const pload = (await data).data;
        loadData(
            pload.id,
            pload.name,
            pload.url,
            pload.description,
        );
    };

    useEffect(() => {
        popularLinkById(id);
    }, []);

    const loadData = (
        Id: any,
        name: any,
        url: any,
        description: any,
    ) => {
        setId(Id);
        setName(name);
        setUrl(url);
        setDescription(description);
    };

    const [bannerError, setBannerError] = useState("");

    const [Id, setId] = useState(0);
    const [name, setName] = useState("");
    const [url, setUrl] = useState("");
    const [nameErrorMessage, setNameErrorMessage] = useState("");
    const [urlErrorMessage, setUrlErrorMessage] = useState("");

    const [description, setDescription] = useState("");
    const [isPublished, setIsPublished] = useState(false);
    const [displayOrder, setDisplayOrder] = useState(0);
    const [bannerId, setBannerId] = useState(0);
    const [bannerPath, setBannerPath] = useState("");
    const [errors, setErrors] = useState<any>({});

    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
    const [snackBarOn, setSnackBarOn] = useState(false);

    const handleIdChange = (event: any) => {
        setId(event);
        errors.Id = "";
    };
    const handleNameChange = (event: any) => {
        setName(event);
        setNameErrorMessage("");
    };

    const handleUrlChange = (event: any) => {
        setUrl(event);
        setUrlErrorMessage("");
    };

    const handleDescriptionChange = (event: any) => {
        setDescription(event);
        errors.description = "";
    };

    const handleIsPublishedChange = (event: any) => [
        setIsPublished(event),
        (errors.isPublished = ""),
    ];

    const handleDisplayOrder = (event: any) => {
        setDisplayOrder(event);
        errors.displayOrder = "";
    };

    const handleBanner = (id: any, path: any) => {
        setBannerId(id);
        setBannerPath(path);
        setBannerError("");
    };

    const deleteBanner = () => {
        setBannerId(0);
        setBannerPath("");
    };

    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const handleSubmit = (e: any) => {

        e.preventDefault();

        const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

        let isValidUrl = urlPattern.test(url);
        if (url !== "" && !isValidUrl) {
            setUrlErrorMessage("Please Enter A Valid Url!");
            return;
        }


        var model = new AddPopularLink();
        model.id = Id;
        model.name = name;
        model.description = description;


        setIsButtonLoading(true);

        setSnackBarOn(false);
        updatePopularLinkService(model)
            .then((response: any) => {
                setSnackBarOn(true);
                setSnackBarMessage("Successful");
                setSnackBarMessageType("success");

                setIsButtonLoading(false);

                setErrors({});

                setTimeout(() => {
                    navigate("/admin/popular-link/popular-links");
                }, 300);

                dispatch(popularLinkListAction());
            })
            .catch((error: any) => {
                setIsButtonLoading(false);
                // setErrors(error.response.data.errors);
                setNameErrorMessage(error.response.data.errors?.Name?.[0]);
                setBannerError(error.response.data.errors?.BannerId?.[0]);
            });
    };
    return (
        <>
            {snackBarOn && (
                <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
            )}

            <div className="pl-2 lg:pl-4 flex items-center gap-6 lg:justify-between w-full lg:w-3/5 pb-6">
                <AppButton
                    onClick={() => navigate("/admin/popular-link/popular-links")}
                    className="popularLinkBtn"
                    text="Back"
                />
                <h2 className="text-center title">Update PopularLinkList</h2>
            </div>

            <form className="max-w-xl mx-auto myBorder py-4 px-5">
                <AppInput
                    label="PopularLink name"
                    className="myInput"
                    type="text"
                    placeholder="Enter PopularLink Name"
                    value={name}
                    onChange={handleNameChange}
                    errorMessage={nameErrorMessage}
                />
                <AppInput
                    label="Url"
                    className="myInput"
                    type="text"
                    placeholder="Enter PopularLink Url"
                    value={url}
                    onChange={handleUrlChange}
                    errorMessage={urlErrorMessage}
                />

                <AppTextArea
                    label="Description"
                    placeholder="Enter Description"
                    value={description}
                    onChange={handleDescriptionChange}
                />

                <div className="w-1/2 flex items-center gap-1 py-2">
                    <label className="text-xl">Photo</label>
                    {!bannerId && (
                        <AppFileInput onSave={handleBanner} errorMessage={bannerError} />
                    )}
                    <br />
                    {bannerId !== 0 && (
                        <div className="flex items-center gap-1 lg:gap-x-2">
                            <img
                                className="w-20 h-16 lg:h-20 rounded object-contain"
                                src={`${bannerPath}`}
                                alt=""
                            />
                            <IconButton onClick={deleteBanner}>
                                <DeleteIcon className="hover:text-red" />
                            </IconButton>
                        </div>
                    )}
                </div>

                <div className="flex justify-center lg:justify-end">
                    <AppButton className="popularLinkBtn" onClick={handleSubmit} isSubmitting={isButtonLoading} variant="contained" text="Submit" />
                </div>
            </form>
        </>
    )
}