import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsPurchasedAction,
  getTrainingByIdAction,
  getUserTrainingVideoAction,
} from "../../../store/actions/training/trainingAction";
import { RootState } from "../../../store";
import { TbPlayerTrackNext, TbPlayerTrackPrev } from "react-icons/tb";
import { AppScrollBar } from "../../common/AppScrollBar";
import { AppButton } from "../../common/AppButton";
import { submitWatchedService } from "../../../httpService/training/trainingService";
import { AppLoader } from "../../common/AppLoader";
import { BsCircle, BsFillCheckCircleFill } from "react-icons/bs";
import { AppModal } from "../../common/AppModal";
import { reactLocalStorage } from "reactjs-localstorage";
import { Link, useParams } from "react-router-dom";

export default function TrainingGetById() {
  const dispatch = useDispatch();

  const { id } = useParams();

  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [handleWatchedClicked, setHandleWatchedClicked] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);

  var token = reactLocalStorage.getObject("SignIn");

  const training = useSelector((state: RootState) => {
    return state.getTrainingByIdResponse.data;
  });

  useEffect(() => {
    dispatch(getTrainingByIdAction(id));
  }, [dispatch, id]);

  const isPurchased = useSelector((state: RootState) => {
    return state.getIsPurchasedResponse.data;
  });

  useEffect(() => {
    dispatch(getIsPurchasedAction(id));
  }, [dispatch, id]);

  const trainingVideoList = useSelector((state: RootState) => {
    return state.getUserTrainingVideoResponse.data;
  });

  const [trainingVideos, setTrainingVideos] = useState<any>([]);

  useEffect(() => {
    dispatch(getUserTrainingVideoAction(id));
  }, [dispatch, id]);

  useEffect(() => {
    setTrainingVideos(trainingVideoList);
  }, [dispatch, trainingVideoList]);

  useEffect(() => {
    if (handleWatchedClicked === false) setCurrentVideoIndex(0);
  }, [handleWatchedClicked, trainingVideos]);

  const handleNextVideo = () => {
    if (currentVideoIndex < trainingVideos.length - 1) {
      setCurrentVideoIndex((prevIndex) => prevIndex + 1);
      setIsVideoLoaded(false);
    }
  };

  const handlePreviousVideo = () => {
    if (currentVideoIndex > 0) {
      setCurrentVideoIndex((prevIndex) => prevIndex - 1);
      setIsVideoLoaded(false);
    }
  };

  const handleWatch = (currentVideo: any, currentVideoIndex: any) => {
    const payload = {
      trainingVideoId: currentVideo?.trainingVideoId,
      trainingId: currentVideo?.trainingId,
      markAsComplete: true,
    };
    submitWatchedService(payload)
      .then(() => {
        setHandleWatchedClicked(true);
        const updatedTrainingVideos = trainingVideos.map((video: any) => {
          if (video.trainingVideoId === currentVideo?.trainingVideoId) {
            return { ...video, isWatched: true };
          }
          return video;
        });
        setTrainingVideos(updatedTrainingVideos);
        setCurrentVideoIndex(currentVideoIndex);
      })
      .catch(() => { });
  };

  useEffect(() => {
    if (videoRef.current && isVideoLoaded) {
      videoRef.current.play();
    }
  }, [isVideoLoaded, trainingVideos?.length]);

  var currentVideo = trainingVideos[currentVideoIndex];

  if (!currentVideo || !currentVideo.video) {
    return <AppLoader status={isVideoLoaded} />;
  }

  let videoUrl = currentVideo.video.url; // Variable to hold the URL of the current video

  return (
    <div className="container mx-auto space-y-4 pb-12 pt-4 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28">
      {!isPurchased &&
        token &&
        JSON.stringify(token) !== JSON.stringify({}) && (
          <AppModal>
            <div className="flex flex-col-reverse items-center justify-between gap-4 px-4 py-12 md:flex-row md:px-20">
              <div className="flex flex-col items-center gap-3 md:items-start">
                <h2 className="text-xl font-semibold text-royalBlue md:max-w-md md:text-3xl">
                  Please Purchase Your Training First
                </h2>
                <div
                  className="myBtn w-fit border-royalBlue bg-royalBlue py-1.5"
                  onClick={() => { }}
                >
                  <Link to="/training">Buy Now</Link>
                </div>
              </div>
              <img
                className="h-40 w-60 object-contain md:h-48 md:w-72"
                src="https://res.cloudinary.com/ddqenwff0/image/upload/v1687276368/Group_cmdbqn.png"
                alt=""
              />
            </div>
          </AppModal>
        )}

      <h3 className="subTitle pl-12 text-center text-lg lg:text-2xl">
        Training Name: {training?.trainingName}
      </h3>
      {/* these is main div  */}
      <div className="flex flex-col-reverse justify-between gap-4 pt-2 md:flex-row lg:pt-3">
        {/* these is for video list  */}
        <div className="w-full md:w-2/6">
          <AppScrollBar height={`90dvh`}>
            <div className="flax flex-col space-y-2 pb-2">
              {trainingVideos.map((video: any, index: any) => (
                <div
                  key={video.id}
                  className={`myBorder group flex cursor-pointer items-center justify-between px-4 py-1 hover:bg-primary hover:text-white 2xl:py-1.5 ${currentVideoIndex === index ? "videoBtn" : ""
                    }`}
                  onClick={() => {
                    setCurrentVideoIndex(index);
                    setIsVideoLoaded(false);
                  }}
                >
                  <p className="text-sm md:text-base">{video.videoName}</p>
                  <div>
                    {video.isWatched ? (
                      <BsFillCheckCircleFill
                        className={`${currentVideoIndex === index
                          ? "text-white"
                          : "text-primary"
                          } text-lg group-hover:text-white`}
                      />
                    ) : (
                      <BsCircle
                        className={`${currentVideoIndex === index
                          ? "text-lg font-semibold text-white"
                          : "text-primary"
                          }  group-hover:text-white`}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </AppScrollBar>
        </div>

        {/* these is video control section  */}
        <div className="flex w-full flex-col md:w-4/6">
          {/* <video
            key={currentVideo.id}
            className="rounded object-cover"
            controls
            controlsList="nodownload"
            onLoadedData={() => setIsVideoLoaded(true)}
            ref={videoRef}
          >
            <source src="https://drive.google.com/file/d/1gFbYffa5tzdjTxqVDoQIvSLiisw6wUPS/preview" type="video/mp4" />
            Your browser does not support the video tag.
          </video> */}
          <iframe
            title={training?.trainingName}
            src={videoUrl}
            width="100%"
            height="100%"
            className="rounded w-full h-[25dvh] md:h-[68dvh]"
          ></iframe>

          <div className="flex flex-col justify-between pb-1 pt-4 text-base font-medium md:text-base lg:flex-row">
            <p className="pl-2">{currentVideo.videoName}</p>
            {/* these is button section  */}
            <div className="flex items-center justify-between gap-2">
              {currentVideo.id !== 1 && (
                <button
                  className="outlineBtn flex items-center gap-x-1 px-2 text-xs md:gap-x-2 md:px-4 md:text-base"
                  onClick={handlePreviousVideo}
                  disabled={currentVideoIndex === 0}
                >
                  <TbPlayerTrackPrev /> Previous
                </button>
              )}
              <button
                className="outlineBtn flex items-center gap-x-1 px-2 text-xs md:gap-x-2 md:px-4 md:text-base"
                onClick={handleNextVideo}
                disabled={currentVideoIndex === trainingVideos.length - 1}
              >
                Next
                <TbPlayerTrackNext />
              </button>
              {currentVideo.isWatched ? (
                <AppButton
                  text="Completed"
                  className={"myBtn px-2 text-xs md:px-4 md:text-base"}
                  onClick={() => { }}
                />
              ) : (
                <AppButton
                  text="Mark As Complete"
                  className={"outlineBtn text-xs md:text-base"}
                  onClick={() => handleWatch(currentVideo, currentVideoIndex)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
