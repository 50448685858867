import { useState } from "react";
import { useSelector } from "react-redux";
import { AlertColor } from "@mui/material/Alert/Alert";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../store";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { AppInput } from "../../../common/AppInput";
import { AppButton } from "../../../common/AppButton";
import { SignUpModel } from "../../../../models/auth/user/UserModels";
import { createDataOfferVendor } from "../../../../httpService/user/userServices";


interface SignUpProps {
    afterSignUp?: any;
    targetRoute?: string;
}

export const AdminCreateDataOfferVendor: React.FC<SignUpProps> = ({ afterSignUp, targetRoute }) => {

    const [emailOrPhone, setEmailOrPhone] = useState("");

    const [firstName, setFirstName] = useState("");
    const [shopName, setShopName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");


    const [isEmailValid, setIsEmailValid] = useState(false);


    const [emailOrPhoneError, setEmailOrPhoneError] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [shopNameError, setShopNameError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const [isShowSnackBar, setIsShowSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();


    const handleNameChange = (event: any) => {
        setFirstName(event);
        setFirstNameError("");
    };

    const handleShopNameChange = (event: any) => {
        setShopName(event);
        setShopNameError("");
    };


    const handleEmailOrPhoneChange = (event: any) => {
        setEmailOrPhone(event);
        setEmailOrPhoneError("");
    };

    const handlePasswordChange = (event: any) => {
        setPassword(event);
        setPasswordError("");
    };

    const handleConfirmPasswordChange = (event: any) => {
        setConfirmPassword(event);
        setConfirmPasswordError("");
    };

    const [isEmailVerificationButtonLoading, setIsEmailVerificationButtonLoading] = useState(false);
    const [isResendOtpButtonLoading, setResendOtpButtonLoading] = useState(false);


    const [isVerifyOtpButtonLoading, setVerifyOtpButtonLoading] = useState(false);


    const navigate = useNavigate();

    const userProfile = useSelector((state: RootState) => {
        return state.getUserProfileResponse.data;
    });

    const [isSignUpButtonLoading, setSignUpButtonLoading] = useState(false);

    const handleCraeteDataOfferVendor = (event: any) => {

        event.preventDefault();

        document.body.classList.remove('noScroll');

        const user = new SignUpModel();
        user.firstName = firstName;
        user.emailOrPhone = emailOrPhone;
        user.password = password;
        user.confirmPassword = confirmPassword;
        user.shopName = shopName;

        setSignUpButtonLoading(true)

        createDataOfferVendor(user)
            .then((res: any) => {
                setSignUpButtonLoading(false)
            })
            .catch((error: any) => {
                setSignUpButtonLoading(false)
                setEmailOrPhoneError(error.response.data.errors?.EmailOrPhone?.[0]);
                setFirstNameError(error.response.data.errors?.FirstName?.[0]);
                setPasswordError(error.response.data.errors?.Password?.[0]);
                setConfirmPasswordError(
                    error.response.data.errors?.ConfirmPassword?.[0]
                );
            });
    };

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setConfirmShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmShowPassword(!showConfirmPassword);
    };


    return (
        <div className="container mx-auto py-16">
            {isShowSnackBar && (
                <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
            )}
            {/* these is main container section  */}
            <div className="w-full md:w-2/5 rounded mx-auto md:shadow-xl">

                <h2 className="subTitle text-center text-textBlack">Create DataOffer Vendor</h2>

                <div className="flex flex-col px-4 py-6">
                    <AppInput
                        type="email"
                        label="Phone"
                        className="myInput mb-3"
                        placeholder="Enter your Phone"
                        onChange={handleEmailOrPhoneChange}
                        errorMessage={emailOrPhoneError}
                        isDisabled={isEmailValid ? true : false}
                    />

                    <AppInput
                        type="text"
                        label="Name"
                        className="myInput"
                        placeholder="Type your full name"
                        onChange={handleNameChange}
                        errorMessage={firstNameError} />

                    <AppInput
                        type="text"
                        label="Shop Name"
                        className="myInput"
                        placeholder="Type your full name"
                        onChange={handleShopNameChange}
                        errorMessage={shopNameError} />
                        
                    <div className="relative pt-2">
                        <AppInput
                            type={showPassword ? "text" : "password"}
                            className="myInput"
                            label="Password"
                            placeholder="Enter your Password"
                            value={password}
                            onChange={handlePasswordChange}
                            errorMessage={passwordError}
                        />
                        {showPassword ? (
                            <AiOutlineEyeInvisible
                                className="absolute text-2xl text-gray-700 font-bold cursor-pointer right-3 top-9 lg:top-10"
                                onClick={togglePasswordVisibility}
                            />
                        ) : (
                            <AiOutlineEye
                                className="absolute text-2xl text-gray-700 font-bold cursor-pointer right-3 top-9 lg:top-10"
                                onClick={togglePasswordVisibility}
                            />
                        )}
                    </div>

                    <div className="relative pt-2">
                        <AppInput
                            type={showConfirmPassword ? "text" : "password"}
                            className="myInput"
                            label="Confirm Password"
                            placeholder="Enter your Password"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            errorMessage={confirmPasswordError}
                        />
                        {showConfirmPassword ? (
                            <AiOutlineEyeInvisible
                                className="absolute text-2xl text-gray-700 font-bold cursor-pointer right-3 top-9 lg:top-10"
                                onClick={toggleConfirmPasswordVisibility}
                            />
                        ) : (
                            <AiOutlineEye
                                className="absolute text-2xl text-gray-700 font-bold cursor-pointer right-3 top-9 lg:top-10"
                                onClick={toggleConfirmPasswordVisibility}
                            />
                        )}
                    </div>

                    <AppButton
                        text="Craete"
                        isSubmitting={isSignUpButtonLoading}
                        className="myBtn bg-royalBlue border-royalBlue"
                        onClick={handleCraeteDataOfferVendor} />

                </div>
            </div>
        </div >
    );
};
