import React, { useState } from 'react'
import { AppButton } from './AppButton'
import { PaginationPayloadModel } from '../../models/common/paginationModels';


interface IPaginationProps {
    size: number;
    page: number;
    limit?: number;
    className?: string;
    text: string;
    offset?: number;
    onRowChange: any;
    isDisable: boolean;
    isSubmitting: boolean;
}

export const AppLoadMore: React.FC<IPaginationProps> = ({ size, className, text, page, offset, onRowChange, isDisable, isSubmitting }) => {

    const [lmt, setLimit] = React.useState(50);
    const [ofst, setOffset] = React.useState(offset);

    const handleLoadMore = () => {

        var payload = new PaginationPayloadModel;
        payload.limit = lmt;
        payload.offset = lmt * page;
        setLimit(lmt);
        setOffset(lmt * page)
        onRowChange(payload);
    };


    return (<AppButton className={className} isSubmitting={isSubmitting} isDisable={isDisable} text={text} onClick={handleLoadMore} />)
}
