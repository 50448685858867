import { useEffect, useState } from "react";
import { RootState } from "../../../store";
import { IdNameResponseModel } from "../../../models/common/IdNameModel";
import { divisionList } from "../../../store/actions/auth/address/divisionActions";
import { districtListByDivisionId as districtListByDivisionId } from "../../../store/actions/auth/address/districtActions";
import { areaListByDistrictId } from "../../../store/actions/auth/address/areaActions";
import { getUserProfileAction } from "../../../store/actions/auth/user/userActions";
import { AppButton } from "../../common/AppButton";
import { useNavigate } from "react-router-dom";
import _moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { isAdmin } from "../../../common/common";
import { reactLocalStorage } from "reactjs-localstorage";

export default function ImportantNumber() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [divisionId, setDivisionId] = useState(0);
  const [districtId, setDistrictId] = useState(0);

  const divisions = useSelector((state: RootState) => {
    return state.divisionListResponse.data;
  });
  var divisionIdNameModels: IdNameResponseModel[] = [];

  divisions?.map((x: any) => {
    var division = new IdNameResponseModel();
    division.id = x.id;
    division.name = x.name;
    divisionIdNameModels.push(division);
  });

  const districts = useSelector((state: RootState) => {
    return state.districtListResponse.data;
  });
  var districtsIdNameModels: IdNameResponseModel[] = [];

  districts?.map((x: any) => {
    var district = new IdNameResponseModel();
    district.id = x.id;
    district.name = x.name;
    districtsIdNameModels.push(district);
  });

  const thanas = useSelector((state: RootState) => {
    return state.areaListResponse.data;
  });
  var areaIdNameModels: IdNameResponseModel[] = [];

  thanas?.map((x: any) => {
    var area = new IdNameResponseModel();
    area.id = x.id;
    area.name = x.name;
    areaIdNameModels.push(area);
  });

  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(divisionList());
  }, [dispatch]);

  useEffect(() => {
    if (divisionId != 0) {
      dispatch(districtListByDivisionId(divisionId));
    }
  }, [divisionId, dispatch]);

  useEffect(() => {
    if (districtId != 0) {
      dispatch(areaListByDistrictId(districtId));
    }
  }, [districtId, dispatch]);

  const navigateToUnions = () => {
    if (isAdmin(reactLocalStorage.get("RoleName"))) {
      navigate("/admin/important-numbers/unions");
    } else {
      navigate("/important-numbers/unions");
    }
  };

  const navigateToAcademicInstitutes = () => {
    if (isAdmin(reactLocalStorage.get("RoleName"))) {
      navigate("/admin/important-numbers/academic-institutes");
    } else {
      navigate("/important-numbers/academic-institutes");
    }
  };

  const navigateToTeachersNumber = () => {
    if (isAdmin(reactLocalStorage.get("RoleName"))) {
      navigate("/admin/important-numbers/teachers-number");
    } else {
      navigate("/important-numbers/teachers-number");
    }
  };

  const navigateToPublicRepresentatives = () => {
    if (isAdmin(reactLocalStorage.get("RoleName"))) {
      navigate("/admin/important-numbers/public-representatives");
    } else {
      navigate("/important-numbers/public-representatives");
    }
  };

  const navigateToPoliceOfficialsNumber = () => {
    if (isAdmin(reactLocalStorage.get("RoleName"))) {
      navigate("/admin/important-numbers/police-officials-numbers");
    } else {
      navigate("/important-numbers/police-officials-numbers");
    }
  };

  const navigateToDoctorsNumber = () => {
    if (isAdmin(reactLocalStorage.get("RoleName"))) {
      navigate("/admin/important-numbers/doctor-numbers");
    } else {
      navigate("/important-numbers/doctor-numbers");
    }
  };

  const navigateToHospitalNumber = () => {
    if (isAdmin(reactLocalStorage.get("RoleName"))) {
      navigate("/admin/important-numbers/hospital-numbers");
    } else {
      navigate("/important-numbers/hospital-numbers");
    }
  };

  const navigateToImamsNumber = () => {
    if (isAdmin(reactLocalStorage.get("RoleName"))) {
      navigate("/admin/important-numbers/imams-numbers");
    } else {
      navigate("/important-numbers/imams-numbers");
    }
  };

  const importantNumber = [
    {
      text: "Unions",
      handler: navigateToUnions,
    },
    {
      text: "Academic Institutes",
      handler: navigateToAcademicInstitutes,
    },
    {
      text: "Teachers Number",
      handler: navigateToTeachersNumber,
    },
    {
      text: "Public Representatives",
      handler: navigateToPublicRepresentatives,
    },
    {
      text: "Imam's Number",
      handler: navigateToImamsNumber,
    },
    {
      text: "Police Officials Number",
      handler: navigateToPoliceOfficialsNumber,
    },
    {
      text: "Hospital's Number",
      handler: navigateToHospitalNumber,
    },
    {
      text: "Doctor's Number",
      handler: navigateToDoctorsNumber,
    },
  ];

  return (
    <div className="container mx-auto px-2 py-4 lg:px-24 lg:py-6 2xl:py-8">
      <h2 className="title text-center text-cyan-600">দরকারি নাম্বার</h2>
      <div className="flex flex-wrap items-center justify-center gap-3 pt-3 lg:gap-6 lg:pt-6 2xl:gap-8 2xl:pt-8">
        {importantNumber.map((singleSection, index) => (
          <div
            key={index}
            onClick={singleSection?.handler}
            className="myGradient flex h-20 w-[30%] cursor-pointer items-center justify-center rounded px-1 text-center hover:shadow-xl lg:h-32 lg:w-1/5 2xl:h-36"
          >
            <AppButton
              text={singleSection?.text}
              onClick={singleSection?.handler}
              className="text-sm font-medium text-white lg:text-xl"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
