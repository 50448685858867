import { AlertColor, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sSAddMainCatagoryService } from "../../../../../httpService/superShops/ssMainCategoryServices";
import { SSAddMainCatagory } from "../../../../../models/superShopp/catalog/sSCategory/sSMainCategoryModel";
import { sSGetMainCategoryListAction } from "../../../../../store/actions/superShop/sSMainCategoryActions";
import { AppButton } from "../../../../common/AppButton";
import { AppFileInput } from "../../../../common/AppFileInput";
import { AppInput } from "../../../../common/AppInput";
import { AppSnackBar } from "../../../../common/AppSnackBar";
import { AppTextArea } from "../../../../common/AppTextArea";

export default function AdminSSCreateMainCategory() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);

  const [name, setName] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");

  const [description, setDescription] = useState("");
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState("");

  const [isPublished, setIsPublished] = useState(false);

  const [bannerId, setBanner] = useState(0);
  const [bannerError, setBannerError] = useState("");

  const [tempPath, setTempPath] = useState("");

  const handleNameChange = (event: any) => {
    setName(event);
    setNameErrorMessage("");
  };

  const handleDescriptionChange = (event: any) => {
    setDescription(event);
    setDescriptionErrorMessage("")
  };

  const handleIsPublishedChange = (event: any) => [setIsPublished(event)];

  const handleBanner = (id: any, path: any) => {
    setBanner(id);
    setTempPath(path);
    setBannerError("");
  };

  const deleteBanner = () => {
    setBanner(0);
    setTempPath("");
  };

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleSubmit = (e: any) => {

    e.preventDefault();

    setSnackBarOn(false);

    var model = new SSAddMainCatagory();
    model.name = name;
    model.description = description;
    model.isPublished = isPublished;
    model.bannerId = bannerId;

    setIsButtonLoading(true);

    sSAddMainCatagoryService(model)
      .then(() => {
        setSnackBarOn(true);
        setsnackBarMessage("Category  Added Successfully.");
        setSnackBarMessageType("success");
        setIsButtonLoading(false);
        setTimeout(() => {
          navigate("/admin/super-shop/main-category-list");
        }, 300);
        dispatch(sSGetMainCategoryListAction());
      })
      .catch((error: any) => {
        setIsButtonLoading(false);
        setNameErrorMessage(error.response.data.errors?.Name?.[0]);
        setDescriptionErrorMessage(error.response.data.errors?.Description?.[0]);
        setBannerError(error.response.data.errors?.BannerId?.[0]);
      });
  };

  return (
    <div>
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      <div className="pl-4 flex items-center justify-between w-3/5 pb-6">
        <AppButton
          onClick={() => navigate("/admin/super-shop/main-categories")}
          text="Back"
        />
        <h2 className="text-center title">Create Category</h2>
      </div>

      <form className="max-w-xl mx-auto myBorder py-4 px-5">
        <AppInput
          label="Category name"
          className="myInput"
          type="text"
          placeholder="Enter Category Name"
          onChange={handleNameChange}
          errorMessage={nameErrorMessage}
        />
        <AppTextArea
          label="Description"
          placeholder="Enter Description"
          onChange={handleDescriptionChange}
          errorMessage={descriptionErrorMessage}
        />
        <div className="flex justify-between items-center py-1">
          <div className="flex items-center gap-x-3">
            <label htmlFor="isPublished" className="">
              Published
            </label>
            <input
              className="w-5 h-5 cursor-pointer"
              type="checkbox"
              name="isPublished"
              onChange={() => handleIsPublishedChange(!isPublished)}
            />
          </div>
        </div>
        {/* <div className="flex items-center gap-3 py-2 justify-between">
          <div className="w-1/2 flex items-center gap-1">
            <label className="">Photo</label>
            {!bannerId && (
              <AppFileInput onSave={handleBanner} errorMessage={bannerError} />
            )}
            <br />
            {bannerId !== 0 && (
              <div className="flex items-center gap-x-2">
                <img
                  className=""
                  src={`${tempPath}`}
                  alt="upload"
                />
                <IconButton onClick={deleteBanner}>
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
          </div>
        </div> */}
        <div className="flex justify-end">
          <AppButton onClick={handleSubmit} isSubmitting={isButtonLoading} variant="contained" text="Submit" />
        </div>
      </form>
    </div>
  );
}
