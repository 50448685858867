import { useEffect, useMemo, useState } from 'react'
import { HiCheck, HiMinus, HiPlus } from 'react-icons/hi';
import { TbCurrencyTaka } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { ProductReviewCustomerResponse } from '../../../../models/catalog/productReview/productReviewModels';
import { RootState } from '../../../../store';
import { sSGetProductByIdAction } from '../../../../store/actions/superShop/sSProductActions';
import { reactLocalStorage } from 'reactjs-localstorage';
import { AlertColor } from '@mui/material';
import { BsHeart, BsHeartFill } from 'react-icons/bs';
import { sSGetIsAlreadyFavouriteOrNotAction } from '../../../../store/actions/superShop/sSFavouriteActions';
import { SSAddOrRemoveFavouriteRequest, SSGetIsAlreadyFavouriteOrNotRequest } from '../../../../models/superShopp/favourite/sSFavouriteModel';
import { AppButton } from '../../../common/AppButton';
import { AppSnackBar } from '../../../common/AppSnackBar';
import { sSAddOrRemoveFavouriteService } from '../../../../httpService/superShops/ssFavouriteService';
import { AppLoader } from '../../../common/AppLoader';
import { SSAddToCartRequest, SSCartItemsRequest } from '../../../../models/superShopp/cart/sSCartModel';
import { sSAddToCartService } from '../../../../httpService/superShops/ssCartService';
import { sSGetTotalCartItemAction } from '../../../../store/actions/superShop/sSCartActions';
import { SSGetProductReviews } from './SSGetProductReviews';
import { sSProductReviewListAction } from '../../../../store/actions/superShop/sSProductReviewActions';

export default function SSGetPublicProductById() {

    const [titleBannerPath, setTitleBannerPath] = useState("");

    const dispatch = useDispatch();

    const { id } = useParams();

    useEffect(() => {
        localStorage.setItem('shouldMaintainPage', 'true');
    }, []);

    const details = useSelector((state: RootState) => {
        return state.sSGetProductByIdResponse.data;
    });

    const productDetails = useMemo(() => {
        return details;
    }, [details]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(sSGetProductByIdAction(id));
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [dispatch]);

    useEffect(() => {
        if (productDetails) {
            setTitleBannerPath(productDetails.bannerPath)
        }
    }, [productDetails]);


    const handleProductMainPhotoCahange = (path: any) => {
        setTitleBannerPath(path);
    };

    const [snackBarMessage, setsnackBarMessage] = useState('');
    const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
    const [snackBarOn, setSnackBarOn] = useState(false);

    let isSignIn: any = reactLocalStorage.getObject("SignIn");
    let token = isSignIn?.accessToken;

    const isFavourite = useSelector((state: RootState) => {
        return state.sSGetIsAlreadyFavouriteOrNotResponse.data;
    });

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let req = new SSGetIsAlreadyFavouriteOrNotRequest();
        req.productId = id;
        dispatch(sSGetIsAlreadyFavouriteOrNotAction(req))
    }, [productDetails, id]);

    const [count, setProductCount] = useState(1);

    const handleCountChange = (type: any) => {
        if (type === "increse") {
            if (count < productDetails?.availabeInStock) {
                setProductCount(count + 1);
            }
            else {
                setProductCount(count + 1);
            }
        }
        if (type === "decrese") {
            if (count > 1) {
                setProductCount(count - 1);
            };
        }
    };

    const [isAddToCardLoading, setIsAddToCardLoading] = useState(false);

    var cartItems: SSCartItemsRequest[] = [];

    const handleAddToCart = () => {
        if (token && JSON.stringify(token) !== JSON.stringify({})) {

            let model = new SSCartItemsRequest();
            model.productId = id;
            model.productQuantity = count;
            model.bannerPath = productDetails?.bannerPath;
            model.unitPrice = productDetails?.displayPrice as number;
            model.oldUnitPrice = productDetails?.oldPrice as number;
            model.totalPrice = count * productDetails?.displayPrice as number;

            cartItems = [];

            if (token && JSON.stringify(token) !== JSON.stringify({})) {
                cartItems.push(model);

                let request = new SSAddToCartRequest();
                request.cartItems = cartItems;

                setIsAddToCardLoading(true)
                sSAddToCartService(request)
                    .then((res) => {
                        setIsAddToCardLoading(false)
                        if (res?.data?.isOperationSuccessfull) {
                            setSnackBarOn(true);
                            setsnackBarMessage("Successfully Add");
                            setSnackBarMessageType("success");
                            setTimeout(() => {
                                setSnackBarOn(false);
                            }, 2000);
                            setIsAddToCardLoading(true)
                        }
                        else {
                            setSnackBarOn(true);
                            setsnackBarMessage("Successfully Remove");
                            setSnackBarMessageType("success");
                            setTimeout(() => {
                                setSnackBarOn(false);
                            }, 2000);
                        }
                        setIsAddToCardLoading(false)
                        dispatch(sSGetTotalCartItemAction());
                    })
                    .catch(() => {
                        setIsAddToCardLoading(false)
                    });
            } else {

            }
        } else {
            dispatch({ type: "OPEN-SIGN-IN" });
            dispatch({ type: "CLOSE-SIGN-UP" });
        }
    };

    const handleAddToFavourite = () => {
        if (token && JSON.stringify(token) !== JSON.stringify({})) {
            let model = new SSAddOrRemoveFavouriteRequest();
            model.productId = id;
            sSAddOrRemoveFavouriteService(model)
                .then(() => {
                    let req = new SSGetIsAlreadyFavouriteOrNotRequest();
                    req.productId = id;
                    dispatch(sSGetIsAlreadyFavouriteOrNotAction(req))
                    setSnackBarOn(true);
                    setsnackBarMessage("Successfully done");
                    setSnackBarMessageType("success");
                    setTimeout(() => {
                        setSnackBarOn(false);
                    }, 2000);
                }).catch(() => {

                })
        }
    };

    const productReviews = useSelector((state: RootState) => {
        return state.sSProductReviewListResponse.data as ProductReviewCustomerResponse[];
    });

    useEffect(() => {
        dispatch(sSProductReviewListAction(id));
    }, [dispatch]);


    if (isLoading) {
        return (
            <div className="container h-screen flex items-center justify-center mx-auto px-2 md:px-0 pb-12">
                <AppLoader status={true} />
            </div>
        );
    }

    return (
        <div className="container mx-auto  items-center 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28">
            {snackBarOn &&
                <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
            }

            <div className="md:pt-4 pt-2 flex flex-col md:flex-row gap-y-4 gap-x-8">
                <div className="w-full md:w-1/2 flex flex-col gap-y-2 md:gap-y-4">
                    <div className="relative w-full md:h-[58dvh] h-[35dvh] bg-white myBorder">
                        <img className="object-contain w-full h-full" src={titleBannerPath != "" ? titleBannerPath : productDetails?.bannerPath} alt="main big product pic" />
                    </div>
                    <div className="w-full flex gap-x-2 md:gap-x-4 md:h-[18dvh] h-[8dvh]">
                        {productDetails?.bannerPathList && productDetails?.bannerPathList?.map((x: any, index: any) => {
                            return (
                                <div className="cursor-pointer bg-white myBorder" key={index} onClick={() => handleProductMainPhotoCahange(x)}>
                                    <img className="object-contain rounded w-full h-full" src={x} alt="" />
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className="w-full md:w-1/2">
                    {/* <p className="text-royalBlue text-xl cursor-pointer font-medium 2xl:pb-2 pb-1">Brand Name: {productDetails?.productBrandName}</p>
                    <p className="text-royalBlue text-xl cursor-pointer font-medium 2xl:pb-2 pb-1">Main Category Name: {productDetails?.mainCategoryName}</p>
                    <p className="text-royalBlue text-xl cursor-pointer font-medium 2xl:pb-2 pb-1">Sub Category Name: {productDetails?.subCategoryName}</p> */}
                    <h2 className="2xl:text-2xl text-lg md:text-xl 2xl:pb-2 pb-1 font-medium"> {productDetails?.name} </h2>

                    <div className="flex items-center justify-between md:max-w-md max-w-sm">
                        <span className="font-medium">{productReviews?.length} reviews</span>
                        <div className="font-medium">
                            {(productDetails?.rating > 0 && productDetails?.rating <= 1) && (
                                <img
                                    src="https://res.cloudinary.com/ddqenwff0/image/upload/v1678478594/Star_17_paiezv.png"
                                    alt=""
                                />
                            )}
                            {(productDetails?.rating > 1 && productDetails?.rating <= 2) && (
                                <img
                                    src="https://res.cloudinary.com/ddqenwff0/image/upload/v1678478594/Group_2631_xtm5u7.png"
                                    alt=""
                                />
                            )}
                            {(productDetails?.rating > 2 && productDetails?.rating <= 3) && (
                                <img
                                    src="https://res.cloudinary.com/ddqenwff0/image/upload/v1678478595/Group_2632_ksjv3i.png"
                                    alt=""
                                />
                            )}
                            {(productDetails?.rating > 3 && productDetails?.rating <= 4) && (
                                <img
                                    src="https://res.cloudinary.com/ddqenwff0/image/upload/v1678478594/Group_2633_ediwi7.png"
                                    alt=""
                                />
                            )}
                            {(productDetails?.rating > 4 && productDetails?.rating <= 5) && (
                                <img
                                    src="https://res.cloudinary.com/ddqenwff0/image/upload/v1678478594/Group_2634_kn5jp8.png"
                                    alt=""
                                />
                            )}
                            {(productDetails?.rating >= 0 && productDetails?.rating != 0) && (
                                <>{productDetails?.rating}</>
                            )}
                        </div>
                    </div>

                    {productDetails?.availabeInStock > 0 && (
                        <div className="bg-[#D1FFF5] text-primary 2xl:text-lg text-base flex items-center font-medium px-2 2xl:my-2 my-1 rounded w-fit gap-x-2">
                            <HiCheck /> in stock
                        </div>
                    )}

                    {productDetails?.availabeInStock <= 0 && (
                        <div className="bg-[#FF7474] text-white 2xl:text-lg text-base flex items-center font-medium px-2 2xl:my-2 my-1 rounded w-fit gap-x-2">
                            <HiCheck /> Out of stock
                        </div>
                    )}

                    <div className="flex gap-x-8 2xl:pb-2 pb-1 items-center">
                        <h2 className="2xl:text-xl text-xl font-medium flex 2xl:gap-x-1 gap-x-[1px] items-center">  <TbCurrencyTaka /> {productDetails?.displayPrice}</h2>
                        <h2 className="2xl:text-xl text-xl font-medium line-through flex 2xl:gap-x-1 gap-x-[1px] items-center">  <TbCurrencyTaka />{productDetails?.oldPrice}</h2>
                    </div>

                    <div className="flex flex-col 2xl:pb-2 pb-1">
                        <h3 className="subTitle 2xl:pb-1 pb-[2px]">Quantity</h3>
                        <div className="flex items-center gap-4">
                            <div className="2xl:w-8 w-7 2xl:h-8 h-7 text-xl cursor-pointer flex justify-center items-center bg-white myBorder rounded"
                                onClick={() => handleCountChange("decrese")}>
                                <HiMinus />
                            </div>
                            <p className="font-medium 2xl:text-2xl text-xl text-center select-none">{count}</p>
                            <div className="2xl:w-8 w-7 2xl:h-8 h-7 flex cursor-pointer justify-center items-center text-xl bg-white myBorder rounded"
                                onClick={() => handleCountChange("increse")}>
                                <HiPlus />
                            </div>
                        </div>
                    </div>
                    <div className="2xl:py-2 py-1 flex md:gap-x-4 gap-x-2 md:w-11/12 w-full justify-between">
                        {productDetails?.availabeInStock > 0 && (
                            <div className="">
                                <div className="2xl:mt-2 mt-1 2xl:p-4 p-2 space-y-2 myBorder 2xl:max-w-xl md:max-w-lg w-full select-none">
                                    <div className="flex max-w-xs mx-auto gap-x-2 md:gap-x-4 justify-between items-center">
                                        <div>
                                            <AppButton
                                                text="Add to cart"
                                                className="ssBtn py-[2px] md:py-1"
                                                onClick={handleAddToCart}
                                                isSubmitting={isAddToCardLoading}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="myBorder h-fit 2xl:p-2 p-1.5 cursor-pointer" onClick={handleAddToFavourite}>
                            {!isFavourite && (
                                <BsHeart className="text-primary text-2xl font-bold" />
                            )}
                            {isFavourite && (
                                <BsHeartFill className="text-primary text-2xl font-bold" />
                            )}
                        </div>
                    </div>

                    <div className="flex flex-col 2xl:gap-y-1 gap-y-[2px]">
                        <h2 className="subTitle">Description</h2>
                        <p className="max-w-xl">
                            <div dangerouslySetInnerHTML={{ __html: productDetails?.fullDescription }} />
                        </p>
                    </div>
                </div>
            </div>
            <SSGetProductReviews productReviews={productReviews} productId={id} />
        </div>
    );
}
