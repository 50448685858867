import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { AppButton } from "../../common/AppButton";
import { AppInput } from "../../common/AppInput";
import { EmailRecoveryResetModel } from "../../../models/auth/user/UserModels";

export default function ResetPass() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { email } = useParams();
  const { otp } = useParams();

  const emailHandler = (event: any) => {
    //setEmail(event.target.value);
  };

  const submitHandler = (event: any) => {
    event.preventDefault();
    //http req
    var model = new EmailRecoveryResetModel();
    model.email = email;
    model.code = otp;
    model.newPassword = password;
    model.confirmNewPassword = confirmPassword;
  };

  const subscribeHandler = (event: any) => {
    event.preventDefault();
  };

  return (
    <div className="r-pas-res-main">
      <div className="r-pass-reset">
        <div className="r-pass-box-1">
          <h4 className="r-pass-h4">Reset Your Password</h4>
        </div>

        <div className="r-pass-box-2">
          <div>
            <p className="r-pass-para-1">New Password</p>
            <div className="r-pass-visibility-input-field">
              <AppInput
                className="r-pass-input-field"
                type="password"
                onChange={(value: any) => setPassword(value)}
                placeholder={"Password"}
              />
              <VisibilityIcon
                style={{ fontSize: "1.2rem", color: "#333" }}
                className="visibility-icon"
              />
            </div>
          </div>

          <div>
            <p className="r-pass-para-1">Confirm Password</p>
            <div className="r-pass-visibility-input-field">
              <AppInput
                className="r-pass-input-field"
                type="password"
                onChange={(value: any) => setConfirmPassword(value)}
                placeholder={"Confirm Password"}
              />
              <VisibilityIcon
                style={{ fontSize: "1.2rem", color: "#333" }}
                className="visibility-icon"
              />
            </div>
          </div>

          <div className="r-pass-submit">
            <Link to={"/resetPass"}>
              <AppButton
                className="r-pass-verify-button"
                onClick={submitHandler}
                text={"Set password"}
              />
            </Link>
          </div>
        </div>
      </div>

      {/* <div className="r-pass-reset2">
        <p className="r-pass-reset2-txt">
          Yes! Send me exclusive offers, unique gift ideas, and personalized
          tips for on BlackTree.
        </p>

        <div className="r-pass-input">
          <AppInput
            type="text"
            className="r-pass-input-2"
            placeholder="Enter your email"
            onChange={emailHandler}
          />

          <AppButton
            onClick={subscribeHandler}
            className="r-pass-Subscribe"
            text={"Subscribe"}
          />
        </div>
      </div> */}
    </div>
  );
}
