import { PaginationPayloadModel } from "../../common/paginationModels";

export class GetProductListRequest extends PaginationPayloadModel {
    minimumPrice?: any;
    maximumPrice?: any;
    sortBy?: number;
    vendorId?: any = 0;
    mainCategoryId?: any = 0;
    subCategoryLevelOneId?: any = 0;
    homeProductFilterType?: HomeProductFilterEnum
}

export class CreateUpdateProductRequest {
    id?: number;
    name?: string;
    normalizedName?: string;
    shortDescription?: string;
    fullDescription?: string;
    productCode?: string;
    GTIN?: string;
    manufacturePartNumber?: string;
    adminComment?: string;
    customerTypeTag?: CustomerTypeTag;

    availableStartDate?: Date;
    availableEndDate?: Date;

    isRootProduct?: boolean;
    isCompleteProduct?: boolean;
    isPublished?: boolean;
    isShowOnHomePage?: boolean;
    isVarientProduct?: boolean;
    isAllowReview?: boolean;
    isReviewApprovalEnable?: boolean;
    isNew?: boolean;
    isCashOnDeliveryEnable?: boolean;
    isGiftCardEnableEnable?: boolean;

    countryFromId?: number;
    mainCategoryId?: number;
    subCategoryLevelOneId?: number;
    subCategoryLevelTwoId?: number;
    userRoleId?: number;
    productBrandId?: number;

    price?: number;
    oldPrice?: number;
    productCost?: number;
    maximumGivenPoints?: number;
    isDisableBuyButton?: boolean;
    isDisableFavouriteButton?: boolean;
    isDisableAddToCartButton?: boolean;
    isAvailableForPreOrder?: boolean;
    isAllowCuponCode?: boolean;
    isDiscountProduct?: boolean;

    discountId?: number;
    isShippingEnable?: boolean;
    isShippingFree?: boolean;
    isShipSeparately?: boolean;
    shippingCharge?: number;
    additionalShippingCharge?: number;

    weight?: number; //Gram
    length?: number; //Inch
    height?: number; //Inch
    width?: number; //Inch

    // inventoryId?: number;
    warehouseId?: number;
    maximumCartQuantity?: number;
    minimumCartQuantity?: number;
    allowedQuantity?: number;
    availableStock?: number;
    notifyForQuantityBelow?: number;
    avaiablityRange?: number;
    isReturnable?: boolean;

    bannerId?: any;
    videoUrl?: any;
    // supplierCompanyId?: number

    // photoUrl?: string;
    // mediaIds?: any[];
}

export enum CustomerTypeTag {
    All = 0,
    Men = 1,
    Women = 2,
    Kid = 3,
}

export class ProductPublishModel {
    productId?: any;
    isPublished?: any;
}

export class UpdateProductModel {
    productName?: string;
    productNormalizedName?: string;
    productDescription?: string;
    productPrice?: number;
    availableStock?: number;
    customerTypeTag?: number;
    photoUrl?: string;
    countryFromId?: number;
    mainCategoryId?: number;
    subCategoryLevelOneId?: number;
    subCategoryLevelTwoId?: number;
}

export enum HomeProductFilterEnum {
    All = 0,
    QuickSell = 1,
    TopSold = 2,
    TopReviewed = 3,
    TopFavourites = 4,
    TopViewed = 5,
    NewUpload = 6
}


export class Type {
    label?: string;
    handler?: () => void;
    icon?: React.ReactElement;
    imgIcon?: string;
    lgImg?: string;
    smImg?: string;
}


export class AdminGetProductByIdModel {
    id?: number;
    name: string = "";
    normalizedName?: string;
    shortDescription: string = "";
    fullDescription: string = "";
    productCode: string = "";
    GTIN: string = "";
    manufacturePartNumber: string = "";
    adminComment: string = "";
    customerTypeTag: number = 0;

    availableStartDate?: Date;
    availableEndDate?: Date;

    productMediaList?: any;

    isRootProduct: boolean = false;
    isCompleteProduct: boolean = false;
    isPublished: boolean = false;
    isShowOnHomePage: boolean = false;
    isVarientProduct: boolean = false;
    isAllowReview: boolean = false;
    isReviewApprovalEnable: boolean = false;
    isNew: boolean = false;
    isCashOnDeliveryEnable: boolean = false;
    isGiftCardEnableEnable: boolean = false;

    countryFromId?: number;
    mainCategoryId?: number;
    subCategoryLevelOneId?: number;
    subCategoryLevelTwoId?: number;
    userRoleId?: number;
    productBrandId?: number;

    price: number = 0;
    oldPrice: number = 0;
    productCost: number = 0;
    maximumGivenPoints: number = 0;
    isDisableBuyButton: boolean = false;
    isDisableFavouriteButton: boolean = false;
    isDisableAddToCartButton: boolean = false;
    isAvailableForPreOrder: boolean = false;
    isAllowCuponCode: boolean = false;
    isDiscountProduct: boolean = false;

    discountId?: number;
    isShippingEnable: boolean = false;
    isShippingFree: boolean = false;
    isShipSeparately: boolean = false;
    shippingCharge: number = 0;
    additionalShippingCharge: number = 0;

    weight: number = 0; //Gram
    length: number = 0; //Inch
    height: number = 0; //Inch
    width: number = 0; //Inch

    // inventoryId?: number;
    warehouseId?: number;
    maximumCartQuantity: number = 0;
    minimumCartQuantity: number = 0;
    allowedQuantity: number = 0;
    availableStock: number = 0;
    notifyForQuantityBelow: number = 0;
    avaiablityRange: number = 0;
    isReturnable: boolean = false;
    productNormalizedName: string = '';
    customerType: string = '';

    photoUrl: string = '';

    videoUrl: string = '';

    countryFrom: string = '';

    mainCategoryName: string = '';

    subCategoryLevelOneName: string = '';

    subCategoryLevelTwoName: string = '';
}
