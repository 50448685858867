import {
  CartItemSelectionRequest,
  GetCartListAdminModel,
  IncreseDecreseCartItemRequest,
  RemoveSingleOrListCartitemRequest,
  ShoppingCartType
} from "../../models/shoppingCart/resellingCartModels";
import { DELETE, GET, POST, PUT } from "../service";

export const addToCardOrFavouriteService = (data: any) => {
  return POST("/api/CartAndFavourite/AddToCartOrFavourite", data);
};

export const addOrRemoveFavouriteService = (data: any) => {
  return POST("/api/ResellingFavourite/AddOrRemoveResellingFavourite", data);
};

export const isAlreadyFavouriteService = (id: any) => {
  return GET(`/api/ResellingFavourite/GetIsAlreadyResellingFavouriteOrNot?productId=${id}`);
};

export const getFavouriteItemsService = (data: any) => {
  return GET(`/api/ResellingFavourite/GetResellingFavouriteItems?searchQuery=${data}`);
};

export const getCartItemsService = (data: any) => {
  return GET(`/api/CartAndFavourite/GetCartItems?CartType=${data}`);
};

export const increseDecreseCartItemService = (data: IncreseDecreseCartItemRequest) => {
  return PUT(`/api/CartAndFavourite/IncreseOrDecreseProduct`, data);
};

export const removeSingleOrListCartitemService = (data: RemoveSingleOrListCartitemRequest) => {
  return DELETE(`/api/CartAndFavourite/RemoveItemSingleOrListItemFromCart`, { data });
};

export const getCartListAdminService = (payload?: GetCartListAdminModel) => {
  if (payload) {
    return GET(
      `/api/CartAndFavourite/GetCartList?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}&startDateTime=${payload.startDateTime}&endDateTime=${payload.endDateTime}&shoppingCartType=${payload.shoppingCartType}`
    );
  }
  return GET(`/api/CartAndFavourite/GetCartList`);
};

export const getCartItemsAdminService = (userId: any) => {
  return GET(`/api/CartAndFavourite/GetCartItems?userId=${userId}&CartType=${ShoppingCartType.ShoppingCart}`);
};

export const cartItemsSelectionService = (items: CartItemSelectionRequest[]): Promise<{ data: string }> => {
  return PUT(`/api/CartAndFavourite/CartItemSelection`, {
    cartItemSelectionList: items
  });
};

export const getTotalShippingChargeService = (InSideDhaka: any) => {
  return GET(`/api/CartAndFavourite/getTotalShippingCharge?InSideDhaka=${InSideDhaka}`);
};