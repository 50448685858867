import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../store";
import {
  GetOrdersCustomerRequest,
  OrderCustomerResponse,
} from "../../../models/order/resellingOrdersModels";
import { reactLocalStorage } from "reactjs-localstorage";
import { useNavigate } from "react-router-dom";
import _moment from 'moment';
import { AppButton } from "../../common/AppButton";
import { Link } from "react-router-dom";
import { AppLoader } from "../../common/AppLoader";
import { BiSearchAlt2 } from "react-icons/bi";
import { AppSearchBox } from "../../common/AppSearchBox";
import { affiliateGetOrderDetailsAction, affiliateGetOrderListAction } from "../../../store/actions/sales/affiliateOrderActions";

export default function AffiliateGetOrderList() {

  const ip = reactLocalStorage.get("ip") as string;

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const [orderId, setOrderId] = useState(0);
  const [orderStatus, setOrderStatus] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState(0);
  const [shippingStatus, setShippingStatus] = useState(0);

  const orders = useSelector((state: RootState) => {
    return state.affiliateGetOrderListCustomerResponse.data;
  });

  let payload = new GetOrdersCustomerRequest();
  payload.orderId = 0;
  payload.orderStatus = orderStatus;
  payload.paymentStatus = paymentStatus;
  payload.shippingStatus = shippingStatus;


  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await dispatch(affiliateGetOrderListAction(payload));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);



  const [stickyTitle, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 80);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  const orderTimeFormatter = (createdDateTime: any) => {
    const createdTime = _moment(createdDateTime, 'YYYY-MM-DD HH:mm:ss.SSSSSSS');
    return createdTime.format('MMM D, YY [at] h:mm A');
  }

  const [isAll, setIsAll] = useState(true);
  const [isInProgress, setInProgress] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [isShipped, setIsShipped] = useState(false);
  const [isReturned, setIsReturned] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const selectAll = () => {
    setIsAll(true);
    setInProgress(false);
    setIsComplete(false);
    setIsCancelled(false);
    setIsShipped(false);
    setIsReturned(false);
    setIsConfirmed(false);
    dispatch(affiliateGetOrderListAction(payload));
  }

  const selectInProgress = () => {
    setIsAll(false);
    setInProgress(true);
    setIsComplete(false);
    setIsCancelled(false);
    setIsShipped(false);
    setIsReturned(false);
    setIsConfirmed(false);
    payload.orderStatus = 1;
    dispatch(affiliateGetOrderListAction(payload));
  }

  const selectComplete = () => {
    setIsAll(false);
    setInProgress(false);
    setIsComplete(true);
    setIsCancelled(false);
    setIsShipped(false);
    setIsReturned(false);
    setIsConfirmed(false);
    payload.orderStatus = 4;
    dispatch(affiliateGetOrderListAction(payload));
  }

  const selectCancelled = () => {
    setIsAll(false);
    setInProgress(false);
    setIsComplete(false);
    setIsCancelled(true);
    setIsShipped(false);
    setIsReturned(false);
    setIsConfirmed(false);
    payload.orderStatus = 2;
    dispatch(affiliateGetOrderListAction(payload));
  }

  const selectConfirmed = () => {
    setIsAll(false);
    setInProgress(false);
    setIsComplete(false);
    setIsCancelled(false);
    setIsShipped(false);
    setIsReturned(false);
    setIsConfirmed(true);
    payload.orderStatus = 5;
    dispatch(affiliateGetOrderListAction(payload));
  }

  const selectShipped = () => {
    setIsAll(false);
    setInProgress(false);
    setIsComplete(false);
    setIsCancelled(false);
    setIsShipped(true);
    setIsReturned(false);
    setIsConfirmed(false);
    payload.shippingStatus = 2;
    dispatch(affiliateGetOrderListAction(payload));
  }

  const selectReturned = () => {
    setIsAll(false);
    setInProgress(false);
    setIsComplete(false);
    setIsCancelled(false);
    setIsShipped(false);
    setIsReturned(true);
    setIsConfirmed(false);
    payload.orderStatus = 3;
    dispatch(affiliateGetOrderListAction(payload));
  }

  const handleSearchChange = (newValue: any) => {
    payload.searchQuery = newValue;
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await dispatch(affiliateGetOrderListAction(payload));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  };

  return (
    <div className="container mx-auto px-2 md:px-16 pt-2 pb-8 md:py-4">
      <div className="flex items-center justify-between pb-2 md:pb-4">
        <h3 className="title">My Orders</h3>
        <h3 className="subTitle">Orders: <span>{orders?.items?.length}</span></h3>
      </div>
      <div className="flex flex-col-reverse lg:flex-row gap-2 items-center justify-between pb-2">
        {/* this is search bar div */}
        <div className="flex items-center border shadow-sm border-grey 2xl:p-2 px-1.5 py-0.5 rounded cursor-pointer">
          <BiSearchAlt2 className="text-secondary cursor-pointer 2xl:text-3xl md:text-2xl text-xl font-bold" />
          <AppSearchBox onChange={handleSearchChange} className="border-none w-[81dvw] md:w-[25dvw] px-2 text-sm md:text-base" />
        </div>
        {/* this is buttons div  */}
        <div className="grid grid-cols-3 md:flex gap-2">
          {/* <AppButton className={isAll ? "affiliateBtn" : "affiliateOutlineBtn"} text="All" onClick={selectAll} /> */}
          <AppButton className={isInProgress ? "affiliateBtn text-xs lg:text-sm px-1 lg:px-4" : "affiliateOutlineBtn text-xs lg:text-sm px-1 lg:px-4"} text="Pending" onClick={selectInProgress} />
          <AppButton className={isCancelled ? "affiliateBtn text-xs lg:text-sm px-1 lg:px-4" : "affiliateOutlineBtn text-xs lg:text-sm px-1 lg:px-4"} text="Cancelled" onClick={selectCancelled} />
          <AppButton className={isConfirmed ? "affiliateBtn text-xs lg:text-sm px-1 lg:px-4" : "affiliateOutlineBtn text-xs lg:text-sm px-1 lg:px-4"} text="Confirmed" onClick={selectConfirmed} />
          <AppButton className={isShipped ? "affiliateBtn text-xs lg:text-sm px-1 lg:px-4" : "affiliateOutlineBtn text-xs lg:text-sm px-1 lg:px-4"} text="On the Way" onClick={selectShipped} />
          <AppButton className={isReturned ? "affiliateBtn text-xs lg:text-sm px-1 lg:px-4" : "affiliateOutlineBtn text-xs lg:text-sm px-1 lg:px-4"} text="Returned" onClick={selectReturned} />
          <AppButton className={isComplete ? "affiliateBtn text-xs lg:text-sm px-1 lg:px-4" : "affiliateOutlineBtn text-xs lg:text-sm px-1 lg:px-4"} text="Completed" onClick={selectComplete} />
        </div>
      </div>
      <hr className="border border-gray-700" />
      {isLoading ? (
        <div className="container mx-auto px-2 md:px-0 pb-12">
          <AppLoader status={true} />
        </div>
      ) : (
        <>
          {orders?.items?.length ? (
            <div className="w-full pt-2">
              {/* these is header part for desktop */}
              {/* these is middle part for desktop */}
              <div className="hidden lg:flex h-[55dvh] 2xl:h-[60dvh] overflow-y-auto flex-col gap-1 py-1">
                {orders?.items?.map((order: OrderCustomerResponse) => (
                  <div key={order?.id} className="myBorder flex w-full items-center">
                    <div className="tableRow w-[12%]">{order?.id}</div>
                    <div className="tableRow font-medium w-[15%]">{order?.orderProducts?.length}</div>
                    <div className="tableRow w-[15%]">{order?.totalAmount}</div>
                    <div className="tableRow w-[19%]">
                      <span className={`${order?.orderStatus === "InProgress" ? 'InProgress' : ''}` || `${order?.orderStatus === "Cancelled" ? 'Cancelled' : ''}` || `${order?.orderStatus === "Complete" ? 'Completed' : ''}` || `${order?.orderStatus === "Returned" ? 'Returned' : ''}` || `${order?.orderStatus === "Confirm" ? 'Confirmed' : ''}` || `${order?.orderStatus === "paid" ? 'paid' : ''}` || `${order?.shippingStatus === "Shipped" ? 'Shipped' : ''}`}>
                        {order?.orderStatus === "InProgress" ? "Pending" : order?.orderStatus === "Cancelled" ? "Cancelled" : order?.orderStatus === "Complete" ? "Completed" : order?.orderStatus === "Returned" ? "Returned" : order?.shippingStatus === "Shipped" ? "On the Way" : order?.orderStatus === "Confirm" ? "Confirmed" : ""}
                      </span>
                    </div>
                    {/* <div className="tableRow w-[20%]">
                      <span className={`${order?.orderStatus == "Cancelled" ? 'Cancelled' : ''}` || `${order?.orderStatus == "Returned" ? 'Returned' : ''}` || `${order?.shippingStatus == "Shipped" ? 'Given-to-delivery' : ''}` || `${order?.shippingStatus == "InProgress" ? 'InProgress' : ''}` || `${order?.shippingStatus == "Delivered" ? 'Shipped' : ''}`}>
                        {order?.orderStatus == "Cancelled" ? "Cancelled" : order?.orderStatus == "Returned" ? "Returned" : order?.shippingStatus == "Shipped" ? "Given to delivery" : order?.shippingStatus == "InProgress" ? 'Pending' : ""}
                      </span>
                    </div> */}
                    <div className="tableRow text-sm w-[23%]">{orderTimeFormatter(order?.orderDateTime)}</div>
                    <div className="centerDiv py-0.5 w-[16%]">
                      <Link className="affiliateBtn" to={`/affiliate/order/${order.id}`}>
                        Details
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
              {/* these is middle part for mobile */}
              <div className="h-[70dvh] lg:hidden overflow-y-auto flex flex-col gap-1">
                {orders?.items?.map((order: OrderCustomerResponse) => (
                  <Link key={order?.id} to={`/affiliate/order/${order.id}`} className="myBorder flex w-full hover:shadow">
                    <div className="w-[37%] h-full p-1 space-y-1 border-r border-grey">
                      <div className="text-xsm flex items-center justify-between gap-1 pr-1">
                        <span className="">Order ID:</span> <span>#{order?.id}</span>
                      </div>
                      <div className="text-[9px] text-center"> {orderTimeFormatter(order?.orderDateTime)}</div>
                    </div>
                    <div className="w-[27%] text-xsm h-full p-1 space-y-1 border-r border-grey">
                      <div className="flex items-center justify-between gap-1 pr-1">
                        <span>Quantity:</span> <span className="font-medium">{order?.orderProducts?.length}</span>
                      </div>
                      <div className="flex items-center justify-between gap-1 pr-1">
                        <span className="">Price:</span> <span className="font-medium">৳{order?.totalAmount}</span>
                      </div>
                    </div>
                    <div className="w-[36%] flex items-center justify-center text-[9px] h-full px-0.5 py-1 space-y-0.5 border-r border-grey">
                      <div className="flex items-center gap-1.5 pr-1">
                        <span className="">Order Status:</span>
                        <span className={`${order?.orderStatus === "InProgress" ? 'InProgress' : ''}` || `${order?.orderStatus === "paid" ? 'paid' : ''}` || `${order?.orderStatus === "Cancelled" ? 'Cancelled' : ''}` || `${order?.orderStatus === "Returned" ? 'ModeratorEarning' : ''}` || `${order?.orderStatus === "Complete" ? 'Completed' : ''}` || `${order?.shippingStatus === "Shipped" ? 'Shipped' : ''}` || `${order?.shippingStatus === "Delivered" ? 'Shipped' : ''}`}>
                          {order?.orderStatus === "InProgress" ? "Pending" : order?.orderStatus === "Cancelled" ? "Cancelled" : order?.orderStatus === "Complete" ? "Completed" : order?.orderStatus === "Returned" ? "Returned" : order?.shippingStatus === "Shipped" ? "On the Way" : order?.orderStatus === "Confirm" ? "Confirmed" : ""}
                        </span>
                      </div>
                      {/* <div className="flex items-center justify-between gap-1 pr-1">
                        <span className="">Shipping Status:</span>
                        <span className={`${order?.shippingStatus == "InProgress" ? 'InProgress' : ''}` || `${order?.shippingStatus == "Shipped" ? 'Shipped' : ''}` || `${order?.shippingStatus == "Delivered" ? 'Shipped' : ''}`}>
                          {order?.orderStatus == "Cancelled" ? "Cancelled" : order?.orderStatus == "Returned" ? "Returned" : order?.shippingStatus == "Shipped" ? "Given to delivery" : order?.shippingStatus == "InProgress" ? 'Pending' : ""}
                        </span>
                      </div> */}
                    </div>
                  </Link>
                ))}
              </div>
              {/* these is footer part  */}
              {/* <div className="myBorder py-1 text-center">pagination here</div> */}
            </div>
          ) : (
            <div className="w-full h-[50dvh] flex justify-center items-center text-3xl text-textBlack/90 font-medium">
              <span>No order found</span>
            </div>
          )}
        </>
      )}

    </div>
  );
}
