import * as React from "react";
import "../App.css";
import { Routes, Route } from "react-router-dom";
import AdminRoot from "../components/admin/root/AdminRoot";
import OrderRoute from "./admin/OrderRoute";
import AuthRoute from "./admin/AuthRoute";
import ResellingSubmitReviewRatingAfterOrder from "../components/public/reselling/ResellingSubmitReviewRatingAfterOrder";
import AddUpdateEmailOrPhone from "../components/public/auth/AddUpdateEmailOrPhone";
import TrainingGetTrainingList from "../components/public/training/TrainingGetTrainingList";
import GetUserWallet from "../components/public/common/GetUserWallet";
import TrainingGetById from "../components/public/training/TrainingGetById";
import AboutHelloBazar from "../components/public/common/AboutHelloBazar";
import AdminGetAdminProfile from "../components/admin/auth/user/AdminGetAdminProfile";
import BloodClub from "../components/public/bloodClub/BloodClub";
import BloodClubAddInfo from "../components/public/bloodClub/BloodClubAddUpdateUserInfo";
import ImportantNumberRoute from "./public/ImportantNumberRoute";
import ResellingPlatfromRoute from "./public/ResellingPlatfromRoute";
import DataOfferVendorRoute from "./dataOffer/DataOfferVendorRoute";
import ResellingGetAppUsersForCountryZilaAdmin from "../components/public/reselling/ResellingGetAppUsersForCountryZilaAdmin";
import DataOffersGetOffers from "../components/public/dataOffer/DataOffersGetOffers";
import AdminCreateArea from "../components/admin/auth/address/AdminCreateArea";
import LandingPage from "../components/landingPage/LandingPage";
import DataOfferVendorWallet from "../components/admin/auth/wallet/AdminDataOfferVednorWallet";
import DataOfferGetOrders from "../components/public/dataOffer/DataOfferGetOrders";
import AdminGetAreas from "../components/admin/auth/address/AdminGetAreas";
import AdminUpdateArea from "../components/admin/auth/address/AdminUpdateArea";
import AfterVendorRequestSubmit from "../components/public/auth/AfterVendorRequestSubmit";
import ChangePassword from "../components/public/auth/ChangePassword";
import ResellingGetProfile from "../components/public/auth/GetUserProfile";
import ResetPass from "../components/public/auth/ResetPass";
import ResetPassEmail from "../components/public/auth/ResetPassEmail";
import SignInMobile from "../components/public/auth/SignInMobile";
import SignUpMobile from "../components/public/auth/SignUpMobile";
import VendorSignUp from "../components/public/auth/VendorSignUp";
import GetNotifications from "../components/public/common/GetNotifications";
import { PrivacyPolicy } from "../components/public/common/PrivacyPolicy";
import NoticeRoute from "./admin/NoticeRoute";
import DashboardComponent from "../components/admin/dBoard/AdminDashboard";
import VendorDashboard from "../components/admin/dBoard/VendorDashboard";
import InvoicesRoute from "./admin/InvoicesRoute";
import InvoiceRoute from "./public/InvoiceRoute";
import AffiliatePlatfromRoute from "./public/AffiliatePlatfromRoute";
import PopularLinkRoute from "./admin/PopularLinkRoute";
import PopularLinkGetPopularLinks from "../components/public/popularLink/PopularLinkGetPopularLinks";
import GetUserWalletHistory from "../components/public/common/GetUserWalletHistory";
import TrainingPayment from "../components/public/training/TrainingPayment";
import VendorGetWallet from "../components/admin/auth/wallet/VendorGetWallet";
import VendorGetWalletHistory from "../components/admin/auth/wallet/VendorGetWalletHistory";
import BechteChaiRoute from "./admin/BechteChaiRoute";
import BechteChaiPublicRoute from "./public/BechteChaiPublicRoute";
import MagazineRoute from "./public/MagazineRoute";
import { MagazinePostList } from "../components/public/magazine/MagazinePostList";
import { MagazineCreateMagazineCategory } from "../components/admin/magazine/MagazineCreateMagazineCategory";
import { MagazineAdminPostList } from "../components/admin/magazine/MagazineAdminPostList";
import { MagazinePostDetails } from "../components/public/magazine/MagazinePostDetails";
import { MagazineUpdateCategory } from "../components/admin/magazine/MagazineUpdateCategory";
import { MagazineUpdatePost } from "../components/public/magazine/MagazineUpdatePost";
import SuperShopPublicRoute from "./public/SuperShopPublicRoute";
import SuperShopRoute from "./admin/SuperShopRoute";

const CatalogRoute = React.lazy(() => import("./admin/CatalogRoute"));
const AddressRoute = React.lazy(() => import("./admin/AddressRoute"));

const IndexRoute = () => {
	return (
		<Routes>
			<Route path="/" element={<LandingPage />} />
			<Route path="/resellRef/:resellRef" element={<SignUpMobile />} />
			<Route path="/affiliateRef/:affiliateRef" element={<SignUpMobile />} />

			<Route path="/thanas" element={<AdminGetAreas />} />
			<Route path="/thanas/create" element={<AdminCreateArea />} />
			<Route path="/thanas/update/:id" element={<AdminUpdateArea />} />

			<Route path="/signIn" element={<SignInMobile />} />
			<Route path="/signUp" element={<SignUpMobile />} />
			<Route path="/VendorSignUp" element={<VendorSignUp />} />
			<Route path="/resetPassEmail" element={<ResetPassEmail />} />
			<Route path="/resetPass/:email/:otp" element={<ResetPass />} />
			<Route path="/updateEmailOrPhone" element={<AddUpdateEmailOrPhone />} />

			<Route path="/myProfile" element={<ResellingGetProfile />} />
			<Route path="/myWallet" element={<GetUserWallet />} />
			<Route path="/myWallet/history" element={<GetUserWalletHistory />} />
			<Route path="/privacyPolicy" element={<PrivacyPolicy onClick={undefined} />} />
			<Route path="/aboutHelloBazar" element={<AboutHelloBazar />} />

			<Route path="/changePassword" element={<ChangePassword />} />

			<Route path="/notifications" element={<GetNotifications />} />

			<Route path="/appUserList" element={<ResellingGetAppUsersForCountryZilaAdmin />} />

			<Route path="/productReview/:productId/:orderId" element={<ResellingSubmitReviewRatingAfterOrder />} />

			<Route path="/training" element={<TrainingGetTrainingList />} />
			<Route path="/trainingPayment/:id" element={<TrainingPayment />} />
			<Route path="/trainingPlaylist/:id" element={<TrainingGetById />} />

			<Route path="/afterVendorRequestSubmit" element={<AfterVendorRequestSubmit />} />

			<Route path="/dataOffer" element={<DataOffersGetOffers />} />
			<Route path="/dataOffer/orders" element={<DataOfferGetOrders />} />

			<Route path="/bloodClub" element={<BloodClub />} />
			<Route path="/bloodClub/addUserInfo" element={<BloodClubAddInfo />} />

			{/* Magazine */}
			<Route path="/postList" element={<MagazinePostList />} />
			<Route path="/postDetails/:id" element={<MagazinePostDetails />} />
			<Route path="/updatePost/:id" element={<MagazineUpdatePost />} />

			<Route path="/createMagazineCategory" element={<MagazineCreateMagazineCategory />} />
			<Route path="/adminMagazinePostList" element={<MagazineAdminPostList />} />
			<Route path="/updateMagazineCategory/:id" element={<MagazineUpdateCategory />} />

			<Route path="reselling-platform/*"
				element={
					<React.Suspense fallback={<>...</>}>
						<ResellingPlatfromRoute />
					</React.Suspense>
				}
			/>

			<Route path="affiliate/*"
				element={
					<React.Suspense fallback={<>...</>}>
						<AffiliatePlatfromRoute />
					</React.Suspense>
				}
			/>

			<Route
				path="important-numbers/*"
				element={
					<React.Suspense fallback={<>...</>}>
						<ImportantNumberRoute />
					</React.Suspense>
				}
			/>

			<Route
				path="invoices/*"
				element={
					<React.Suspense fallback={<>...</>}>
						<InvoiceRoute />
					</React.Suspense>
				}
			/>

			<Route
				path="bechte-chai/*"
				element={
					<React.Suspense fallback={<>...</>}>
						<BechteChaiPublicRoute />
					</React.Suspense>
				}
			/>

			<Route
				path="super-shop/*"
				element={
					<React.Suspense fallback={<>...</>}>
						<SuperShopPublicRoute />
					</React.Suspense>
				}
			/>

			<Route path="/popular-links" element={<PopularLinkGetPopularLinks />} />

			{/* <PublicRoute /> */}
			<Route path="admin" element={<AdminRoot />}>

				<Route
					path="dashboard"
					element={<DashboardComponent />} />

				<Route
					path="vendor-dashboard"
					element={<VendorDashboard />} />

				<Route
					path="data-offer-vendor-dashboard"
					element={<DataOfferVendorWallet />}
				/>

				<Route
					path="profile"
					element={<AdminGetAdminProfile />} />

				<Route
					path="vendorWallet"
					element={<VendorGetWallet />} />

				<Route
					path="vendorWallet/history"
					element={<VendorGetWalletHistory />} />

				<Route
					path="catalog/*"
					element={
						<React.Suspense fallback={<>...</>}>
							<CatalogRoute />
						</React.Suspense>
					}
				/>

				<Route
					path="super-shop/*"
					element={
						<React.Suspense fallback={<>...</>}>
							<SuperShopRoute />
						</React.Suspense>
					}
				/>

				<Route
					path="bechte-chai/*"
					element={
						<React.Suspense fallback={<>...</>}>
							<BechteChaiRoute />
						</React.Suspense>
					}
				/>

				<Route
					path="magazine/*"
					element={
						<React.Suspense fallback={<>...</>}>
							<MagazineRoute />
						</React.Suspense>
					}
				/>

				<Route
					path="auth/*"
					element={
						<React.Suspense fallback={<>...</>}>
							<AuthRoute />
							<AddressRoute />
						</React.Suspense>
					}
				/>

				<Route
					path="important-numbers/*"
					element={
						<React.Suspense fallback={<>...</>}>
							<ImportantNumberRoute />
						</React.Suspense>
					}
				/>

				<Route
					path="sales/*"
					element={
						<React.Suspense fallback={<>...</>}>
							<OrderRoute />
						</React.Suspense>
					}
				/>

				<Route
					path="invoices/*"
					element={
						<React.Suspense fallback={<>...</>}>
							<InvoicesRoute />
						</React.Suspense>
					}
				/>
				<Route
					path="data-offer-vendor/*"
					element={
						<React.Suspense fallback={<>...</>}>
							<DataOfferVendorRoute />
						</React.Suspense>
					}
				/>

				<Route
					path="notice/*"
					element={
						<React.Suspense fallback={<>...</>}>
							<NoticeRoute />
						</React.Suspense>
					}
				/>

				<Route
					path="popular-link/*"
					element={
						<React.Suspense fallback={<>...</>}>
							<PopularLinkRoute />
						</React.Suspense>
					}
				/>

				{/* <Route path="*" element={<NoMatch />} />  */}
			</Route>
		</Routes>
	);
};
export default IndexRoute;
