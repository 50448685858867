import { sSGetRiderByIdService, sSGetRiderListService } from "../../../httpService/superShops/ssRiderServices";
import { SSGetRiderListRequest } from "../../../models/superShopp/rider/riderModel";
import { SSGET_RIDER_BY_ID, SSGET_RIDER_LIST } from "../../constants/superShop/ssRiderConstants";

export const sSgetRiderListAction = (payload?: SSGetRiderListRequest) => async (dispatch: any) => {
    var data = sSGetRiderListService(payload);
    const pload = (await data).data;
    dispatch({ type: SSGET_RIDER_LIST, payload: pload });
};

export const sSGetRiderByIdAction = (id: any) => async (dispatch: any) => {
    var data = sSGetRiderByIdService(id);
    const payload = (await data).data;
    dispatch({ type: SSGET_RIDER_BY_ID, payload: payload });
};