import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdClear } from "react-icons/md";
import { AlertColor } from "@mui/material";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { useNavigate } from "react-router";
import { addBechteChaiProductService } from "../../../httpService/bechteChai/bechteChaiProductServices";
import { PhotoModel, CreateUpdateBechteChaiProductRequest } from "../../../models/bechteChai/product/bechteChaiProductModels";
import { IdNameResponseModel } from "../../../models/common/IdNameModel";
import { PaginationPayloadModel } from "../../../models/common/paginationModels";
import { RootState } from "../../../store";
import { areaListByDistrictId } from "../../../store/actions/auth/address/areaActions";
import { divisionList } from "../../../store/actions/auth/address/divisionActions";
import { bechteChaiMainCategoryList, bechteChaiSubCategoryList } from "../../../store/actions/bechteChai/bechteChaiCategoryActions";
import { bechteChaiMyProductList, bechteChaiProductList } from "../../../store/actions/bechteChai/bechteChaiProductActions";
import { AppButton } from "../../common/AppButton";
import { AppCheckBox } from "../../common/AppCheckBox";
import { AppDescription } from "../../common/AppDescription";
import { AppFileInput } from "../../common/AppFileInput";
import { AppInput } from "../../common/AppInput";
import { AppSelect } from "../../common/AppSelect";
import { AppSnackBar } from "../../common/AppSnackBar";
import { districtListByDivisionId } from "../../../store/actions/auth/address/districtActions";
import { isValidPhoneNumber } from "../../../common/common";
import { getUserWalletAction } from "../../../store/actions/auth/user/userActions";
import { GetUserWalletResponse } from "../../../models/auth/user/UserPointModels";
import { Link } from "react-router-dom";

export default function BechteChaiPostAd() {

  const navigate = useNavigate();

  var paginationModel = new PaginationPayloadModel();

  const [isOpenProductInfoTab, setIsOpenProductInfoTab] = useState(true);
  const [isOpenProductPriceTab, setIsOpenProductPriceTab] = useState(true);
  const [isOpenLocationTab, setIsOpenLocationTab] = useState(true);
  const [isOpenProductPicturesTab, setIsOpenProductPicturesTab] = useState(true);
  const [name, setName] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [fullDescription, setFullDescription] = useState("");
  const [isPublished, setIsPublished] = useState(false);
  const [isNegotiable, setIsNegotiable] = useState(false);
  const [mainCategoryId, setMainCategoryId] = useState(0);
  const [subCategoryLevelOneId, setSubCategoryLevelOneId] = useState(0);
  const [divisionId, setDivisionId] = useState(0);
  const [districtId, setDistrictId] = useState(0);
  const [areaId, setAreaId] = useState(0);
  const [divisionError, setDivisionError] = useState("");
  const [districtError, setDistrictError] = useState("");
  const [areaError, setAreaError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");

  const [price, setPrice] = useState(0);
  const [bannerId, setBannerId] = useState(0);

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [videoUrl, setVideoUrl] = useState("");

  var paginationModel = new PaginationPayloadModel();

  var mainCatList = useSelector((state: RootState) => {
    return state.bechteChaiMainCategoryListResponse.data;
  });

  var mCatIdNameModels: IdNameResponseModel[] = [];

  mainCatList.items?.map((x: any) => {
    var mainCategory = new IdNameResponseModel();
    mainCategory.id = x.id;
    mainCategory.name = x.name;
    mCatIdNameModels.push(mainCategory);
  });

  var subCatLevelOneList = useSelector((state: RootState) => {
    return state.bechteChaiSubCategoryListResponse.data;
  });

  var subCatLvlOneIdNameModels: IdNameResponseModel[] = [];

  subCatLevelOneList.items?.map((x: any) => {
    var subCatLevelOne = new IdNameResponseModel();
    subCatLevelOne.id = x.id;
    subCatLevelOne.name = x.name;
    subCatLvlOneIdNameModels.push(subCatLevelOne);
  });

  const divisions = useSelector((state: RootState) => {
    return state.divisionListResponse.data;
  });
  var divisionIdNameModels: IdNameResponseModel[] = [];

  divisions?.map((x: any) => {
    var division = new IdNameResponseModel();
    division.id = x.id;
    division.name = x.name;
    divisionIdNameModels.push(division);
  });

  const districts = useSelector((state: RootState) => {
    return state.districtListResponse.data;
  });
  var districtsIdNameModels: IdNameResponseModel[] = [];

  districts?.map((x: any) => {
    var district = new IdNameResponseModel();
    district.id = x.id;
    district.name = x.name;
    districtsIdNameModels.push(district);
  });

  const areass = useSelector((state: RootState) => {
    return state.areaListResponse.data;
  });
  var areaIdNameModels: IdNameResponseModel[] = [];

  areass?.map((x: any) => {
    var area = new IdNameResponseModel();
    area.id = x.id;
    area.name = x.name;
    areaIdNameModels.push(area);
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(bechteChaiMainCategoryList(paginationModel));
  }, [dispatch]);

  useEffect(() => {
    dispatch(bechteChaiSubCategoryList(mainCategoryId, paginationModel, true));
  }, [dispatch, mainCategoryId]);

  useEffect(() => {
    dispatch(divisionList());
  }, [dispatch]);

  useEffect(() => {
    if (divisionId !== 0 && divisionId != null) {
      dispatch(districtListByDivisionId(divisionId));
    }
  }, [divisionId, dispatch]);

  useEffect(() => {
    if (districtId !== 0 && districtId != null) {
      dispatch(areaListByDistrictId(districtId));
    }
  }, [districtId, dispatch]);

  const [productMedias, setProductMedias] = useState<PhotoModel[]>([]);
  const [photoIds, setPhotoIds] = useState<any[]>([]);

  const handleBanner = (id: any, path: any) => {
    const photoModel = new PhotoModel();
    photoModel.id = id;
    photoModel.url = path;
    setBannerId(id);
    setBannerError("");
    setProductMedias(prevMedias => [...prevMedias, photoModel]);
    setPhotoIds(prevPhotoId => [...prevPhotoId, id]);
    console.log(id);
    console.log(path);
  };

  const handleRemovePic = (id: any) => {
    // Remove the photoModel with the specified id from the state
    setProductMedias(prevMedias => prevMedias.filter(photoModel => photoModel.id !== id));
    setPhotoIds(prevPhotoId => prevPhotoId.filter(photoId => photoId !== id));
  };
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleSubmit = (e: any, isVariationAdd: boolean) => {
    e.preventDefault();

    let canExecute = true;

    if (!name) {
      setNameError("Name is required");
      canExecute = false;
    }
    if (!shortDescription) {
      setShortDescriptionError("ShortDescription is required");
      canExecute = false;
    }
    if (!price || price <= 0) {
      setPriceError("Price is required");
      canExecute = false;
    }
    if (!fullDescription) {
      setFullDescriptionError("FullDescription is required");
      canExecute = false;
    }
    if (!phone) {
      setPhoneError("Phone no is required");
      canExecute = false;
    }
    if (!isValidPhoneNumber(phone) && phone) {
      setPhoneError("Phone no is invalid");
      canExecute = false;
    }
    if (!address) {
      setAddressError("Address is required");
      canExecute = false;
    }
    if (!mainCategoryId || mainCategoryId === 0) {
      setMainCategoryError("MainCategory is required");
      canExecute = false;
    }
    if (!subCategoryLevelOneId && mainCategoryId !== 0) {
      setSubCategoryLevelOneError("SubCategory is required");
      canExecute = false;
    }
    if (!subCategoryLevelOneId || subCategoryLevelOneId === 0) {
      setSubCategoryLevelOneError("SubCategory is required");
      canExecute = false;
    }
    if (productMedias.length < 1) {
      setBannerError("Please Add At Least One Photo of the Product...");
      canExecute = false;
    }
    if (!divisionId) {
      setDivisionError("Division is required");
      canExecute = false;
    }
    if (!districtId && districtId !== 0) {
      setDistrictError("District is required");
      canExecute = false;
    }
    if (districtId === 0) {
      setDistrictError("District is required");
      canExecute = false;
    }
    if (!areaId && areaId !== 0) {
      setAreaError("Thana is required");
      canExecute = false;
    }
    if (areaId === 0) {
      setAreaError("Thana is required");
      canExecute = false;
    }

    if (canExecute) {

      var model = new CreateUpdateBechteChaiProductRequest();
      model.name = name;
      model.shortDescription = shortDescription;
      model.fullDescription = fullDescription;
      model.address = address;
      model.phone = phone;
      model.isPublished = isPublished;
      model.isNegotiable = isNegotiable;
      model.bechteChaiMainCategoryId = mainCategoryId;
      model.bechteChaiSubCategoryId = subCategoryLevelOneId;
      model.divisionId = divisionId;
      model.districtId = districtId;
      model.areaId = areaId;
      model.price = price;
      model.bannerId = bannerId;
      model.videoUrl = videoUrl;
      model.photoIds = photoIds;

      setIsButtonLoading(true);

      addBechteChaiProductService(model)
        .then((res: any) => {
          setErrors({});
          setIsButtonLoading(false);
          setSnackBarOn(true);
          setsnackBarMessage("Product Created Successfully");
          setSnackBarMessageType("success");

          navigate(`/bechte-chai/posted-ads`);
          dispatch(bechteChaiMyProductList());
        })
        .catch((error) => {
          setIsButtonLoading(false);
          setNameError(error.response.data.errors?.Name?.[0]);
          setShortDescriptionError(error.response.data.errors?.ShortDescription?.[0]);
          setFullDescriptionError(error.response.data.errors?.FullDescription?.[0]);
          setMainCategoryError(error.response.data.errors?.MainCategoryId?.[0]);
          setSubCategoryLevelOneError(error.response.data.errors?.SubCategoryLevelOneId?.[0]);
          setBannerError(error.response.data.errors?.BannerId?.[0]);
          setPriceError(error.response.data.errors?.Price?.[0]);
        });
    }
  };

  const [nameError, setNameError] = useState("");
  const [shortDescriptionError, setShortDescriptionError] = useState("");
  const [fullDescriptionError, setFullDescriptionError] = useState("");
  const [mainCategoryError, setMainCategoryError] = useState("");
  const [subCategoryLevelOneError, setSubCategoryLevelOneError] = useState("");
  const [bannerError, setBannerError] = useState("");
  const [priceError, setPriceError] = useState("");

  const handleProductName = (value: any) => {
    setName(value);
    setNameError("");
  };

  const handleProdcutShortDescription = (value: any) => {
    setShortDescription(value);
    setShortDescriptionError("");
  };

  const handleProdcutFullDescription = (value: any) => {
    setFullDescription(value);
    setFullDescriptionError("");
  };

  const handlePhone = (value: string) => {
    setPhoneError("");
    setPhone(value);
  };

  const handleAddress = (value: string) => {
    setAddressError("");
    setAddress(value);
  };
  const handleProductPrice = (value: any) => {
    setPrice(value);
    setPriceError("");
  };
  const handleDivision = (id: any) => {
    setDivisionError("");
    setDivisionId(id);
    setDistrictId(0);
    setAreaId(0);
  };

  const handleDistrict = (id: any) => {
    setDistrictError("");
    setDistrictId(id);
    setAreaId(0);
  };

  const handleArea = (id: any) => {
    setAreaError("");
    setAreaId(id);
  };

  const handleMainCategory = (mainCatId: number) => {
    setMainCategoryId(mainCatId);
    setMainCategoryError("");
    setSubCategoryLevelOneId(0);
  };

  const handleSubCategoryLevelOne = (subCatLevelOneId: number) => {
    setSubCategoryLevelOneId(subCatLevelOneId);
    setSubCategoryLevelOneError("");
  };

  const handleCollapseAll = () => {
    setIsOpenProductInfoTab(false);
    setIsOpenProductPriceTab(false);
    setIsOpenLocationTab(false);
    setIsOpenProductPicturesTab(false);
  };

  const wallet = useSelector((state: RootState) => {
    return state.getuserWalletResponse.data as GetUserWalletResponse;
  });

  useEffect(() => {
    dispatch(getUserWalletAction(0));
  }, [dispatch]);


  const photos = productMedias?.map((productMedia: any) => (
    <div className="relative">
      <button
        className="absolute -right-1 top-0 flex h-6 w-6 items-center justify-center rounded-full bg-bechteChai text-xl text-white"
        onClick={() => handleRemovePic(productMedia.id)}
      >
        <MdClear />
      </button>
      <img
        className="-z-10 h-36 w-36 object-contain lg:h-40 lg:w-40"
        alt=""
        src={productMedia?.url}
      />
    </div>
  ));

  return (
    <div className="container mx-auto pt-4 pb-6 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28">
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}

      <h2 className="title text-center">Add Product</h2>
      {/* save and collapse all btn  */}
      <div className="flex items-center justify-end py-1 lg:py-2">
        <AppButton className="bechteChaiBtn" text="CollapseAll" onClick={handleCollapseAll} />
      </div>

      {/* product info collapsible div here  */}
      <div
        className="my-1 flex cursor-pointer items-center justify-between rounded bg-bechteChai px-4 py-0.5 text-white lg:py-1 lg:text-xl"
        onClick={() => setIsOpenProductInfoTab(!isOpenProductInfoTab)}
      >
        <h3>Product Info</h3>
        <h3>
          {isOpenProductInfoTab ? (
            <FiMinusCircle className="cursor-pointer" />
          ) : (
            <FiPlusCircle className="cursor-pointer" />
          )}
        </h3>
      </div>

      <div className={`${!isOpenProductInfoTab ? "hidden" : ""}`}>
        <div className="flex flex-col items-center justify-between gap-2 lg:flex-row lg:gap-12">
          <AppInput
            type="text"
            className="myInput"
            label="Name"
            placeholder="Enter Product Name"
            onChange={handleProductName}
            errorMessage={nameError}
          />
        </div>
        <div className="grid grid-cols-1 gap-2 py-2 lg:grid-cols-2 lg:gap-8">
          <div className="">
            <label className="text-sm md:text-base">
              Select Main Category:{" "}
            </label>
            <AppSelect
              defaultValue="Select Main Category"
              value={mainCategoryId}
              data={mCatIdNameModels}
              onChange={handleMainCategory}
              errorMessage={mainCategoryError}
            />
          </div>
          {mainCategoryId !== 0 && (
            <div className="">
              <label className="text-sm md:text-base">Sub Category: </label>
              <AppSelect
                defaultValue="Select Sub Category "
                value={subCategoryLevelOneId}
                data={subCatLvlOneIdNameModels}
                onChange={handleSubCategoryLevelOne}
                errorMessage={subCategoryLevelOneError}
              />
            </div>
          )}
        </div>

        <AppInput
          type="text"
          className="myInput"
          value={shortDescription}
          label="Short Description"
          onChange={handleProdcutShortDescription}
          errorMessage={shortDescriptionError}
        />

        <AppDescription
          value={fullDescription}
          label="Full Description"
          onChange={handleProdcutFullDescription}
          errorMessage={fullDescriptionError}
        />

        <div className="flex items-center justify-start py-2">
          <div className="flex items-center gap-x-2">
            <label htmlFor="isPublished" className="text-xl">
              Publish
            </label>
            <AppCheckBox
              isChecked={isPublished}
              onChange={(value: any) => setIsPublished(value)}
            />
          </div>
        </div>
      </div>

      {/* these is price collapsible div  */}
      <div
        className="my-2 flex cursor-pointer items-center justify-between rounded bg-bechteChai px-4 py-0.5 text-white lg:py-1 lg:text-xl"
        onClick={() => setIsOpenProductPriceTab(!isOpenProductPriceTab)}
      >
        <h3>Prices</h3>
        <h3>
          {isOpenProductPriceTab ? (
            <FiMinusCircle className="cursor-pointer" />
          ) : (
            <FiPlusCircle className="cursor-pointer" />
          )}
        </h3>
      </div>

      {/* these is old price and new price div  */}
      <div
        className={`grid grid-cols-1 gap-2 py-2 lg:grid-cols-2 lg:gap-8 ${!isOpenProductPriceTab ? "hidden" : ""
          }`}
      >
        <AppInput
          className="myInput"
          type="number"
          label="Your Price"
          placeholder="Enter Your Product Price"
          onChange={handleProductPrice}
          errorMessage={priceError}
        />
        <div className="flex items-center justify-center gap-x-2">
          <label htmlFor="isNegotiable" className="text-xl">
            Negotiable
          </label>
          <AppCheckBox
            isChecked={isNegotiable}
            onChange={(value: any) => setIsNegotiable(value)}
          />
        </div>
      </div>

      {/* Location collapsible div  */}
      <div
        className="my-2 flex cursor-pointer items-center justify-between rounded bg-bechteChai px-4 py-0.5 text-white lg:py-1 lg:text-xl"
        onClick={() => setIsOpenLocationTab(!isOpenLocationTab)}
      >
        <h3>Location</h3>
        <h3>
          {isOpenLocationTab ? (
            <FiMinusCircle className="cursor-pointer" />
          ) : (
            <FiPlusCircle className="cursor-pointer" />
          )}
        </h3>
      </div>

      {/*Location div  */}
      <div
        className={`grid grid-cols-1 gap-2 py-2 lg:grid-cols-2 lg:gap-8 ${!isOpenLocationTab ? "hidden" : ""
          }`}
      >
        <AppInput
          className="myInput"
          label="Phone"
          type="text"
          placeholder="Enter your phone"
          value={phone}
          onChange={handlePhone}
          errorMessage={phoneError}
        />
        <AppInput
          className="myInput"
          label="Address"
          type="text"
          placeholder="Enter your address"
          value={address}
          onChange={handleAddress}
          errorMessage={addressError}
        />

        <div>
          <label
            className="text-base md:text-lg 2xl:text-xl"
            placeholder="Dhaka"
          >
            Division*
          </label>
          <AppSelect
            defaultValue="Select Division"
            data={divisionIdNameModels}
            value={divisionId}
            onChange={handleDivision}
            errorMessage={divisionError}
          />
        </div>

        {divisionId !== 0 && (
          <div>
            <label
              className="text-base md:text-lg 2xl:text-xl"
              placeholder="Dhaka"
            >
              District*
            </label>
            <AppSelect
              defaultValue="Select District"
              data={districtsIdNameModels}
              value={districtId}
              onChange={handleDistrict}
              errorMessage={districtError}
            />
          </div>
        )}

        {divisionId !== 0 && districtId !== 0 && (
          <div>
            <label
              className="text-base md:text-lg 2xl:text-xl"
              placeholder="Dhaka"
            >
              Area
            </label>
            <AppSelect
              defaultValue="Select Area"
              data={areaIdNameModels}
              value={areaId}
              onChange={handleArea}
              errorMessage={areaError}
            />
          </div>
        )}
      </div>

      {/* these is picture collapsible div  */}
      <div
        className="my-2 flex cursor-pointer items-center justify-between rounded bg-bechteChai px-4 py-0.5 text-white lg:py-1 lg:text-xl"
        onClick={() => setIsOpenProductPicturesTab(!isOpenProductPicturesTab)}
      >
        <h3>Pictures</h3>
        <h3>
          {isOpenProductPicturesTab ? (
            <FiMinusCircle className="cursor-pointer" />
          ) : (
            <FiPlusCircle className="cursor-pointer" />
          )}
        </h3>
      </div>

      {/* these is upload picture section  */}
      <div
        className={`flex flex-col items-center gap-x-2 gap-y-2 lg:flex-row ${!isOpenProductPicturesTab ? " hidden" : ""
          }`}
      >
        <div className="flex flex-wrap gap-2">{photos}</div>
        <div className="flex gap-1">
          <AppFileInput onSave={handleBanner} errorMessage={bannerError} />
        </div>

      </div>

      <div className="p-2 md:w-96">
        <div className="text-royalBlue text-center font-medium py-2">
          <p>You have to pay: {50} BDT</p>
        </div>
        {wallet.mainBalance >= 50 ? (
          <div className="text-royalBlue text-center font-medium py-2">
            <p>Your Main Balance have: {wallet.mainBalance} BDT</p>
            <p>Your remaining balance will be: {wallet.mainBalance - 50} BDT</p>
          </div>
        ) : (
          <div className='text-red text-center font-medium p-.5'>
            <p>You have: {wallet.mainBalance} BDT</p>
          </div>
        )}
      </div>

      {/* these is save button  */}
      {wallet.mainBalance >= 50 ?
        (
          <div className="flex items-center justify-between gap-4 py-2 lg:justify-end">
            <AppButton
              className="bechteChaiBtn"
              text="Save"
              variant="contained"
              isSubmitting={isButtonLoading}
              color="primary"
              onClick={(e: any) => handleSubmit(e, false)}
            />
          </div>
        ) : (
          <div className="flex flex-col items-center gap-3 md:items-start">
            <h2 className="text-xl font-semibold text-red md:max-w-md md:text-3xl">
              Please Recharge your wallet
            </h2>
            <div
              className="myOfferBtn w-fit border-royalBlue bg-royalBlue py-1.5"
              onClick={() => { }}
            >
              <Link to="/myWallet">Wallet</Link>
            </div>
          </div>
        )}
    </div>
  );
}
