import { AlertColor } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updateAreaService } from "../../../../httpService/area/areaServices";
import { GET } from "../../../../httpService/service";
import { RootState } from "../../../../store";
import { areaListAdmin } from "../../../../store/actions/auth/address/areaActions";
import { districtList } from "../../../../store/actions/auth/address/districtActions";
import { AppButton } from "../../../common/AppButton";
import { AppInput } from "../../../common/AppInput";
import { AppSelect } from "../../../common/AppSelect";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { IdNameResponseModel } from "../../../../models/common/IdNameModel";
import { UpdateAreaRequest } from "../../../../models/importantNumber/ImportantNumberModel";

export default function AdminUpdateArea(props: any) {

  const districts = useSelector((state: RootState) => {
    return state.districtListResponse.data;
  });

  var districtsIdNameModels: IdNameResponseModel[] = [];

  districts?.map((x: any) => {
    var district = new IdNameResponseModel();
    district.id = x.id;
    district.name = x.name;
    districtsIdNameModels.push(district);
  });

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { id } = useParams();

  const upazillaId = async (id: any) => {
    const data = GET(`/api/Area/GetArea?Id=${id}`);
    const pload = (await data).data;
    loadData(
      pload.id,
      pload.name,
      pload.bnName,
      pload.code,
      pload.bnName,
      pload.lat,
      pload.lon,
      pload.districtId
    );
  };

  useEffect(() => {
    upazillaId(id);
  }, []);

  useEffect(() => {
    dispatch(districtList());
  }, []);

  const loadData = (
    id: any,
    name: any,
    bnName: any,
    upazilaCode: any,
    bn: any,
    lat: any,
    lon: any,
    districtId: any
  ) => {
    setID(id);
    setName(name);
    setBnName(bnName)
    setCode(upazilaCode);
    setBnName(bn);
    setLat(lat);
    setLon(lon);
    setdistrictId(districtId);
  };

  const [ID, setID] = useState(0);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [bnName, setBnName] = useState("");
  const [lat, setLat] = useState("");
  const [lon, setLon] = useState("");
  const [districtId, setdistrictId] = useState(0);

  const [nameError, setNameError] = useState("");
  const [codeError, setCodeError] = useState("");
  const [bnNameError, setBnNameError] = useState("");
  const [latError, setLatError] = useState("");
  const [lonError, setLonError] = useState("");
  const [districtIdError, setDistrictIdError] = useState("");

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);

  const handleNameChange = (event: any) => {
    setName(event);
    setNameError("");
  };

  const handleCodeChange = (event: any) => {
    setCode(event);
    setCodeError("");
  };

  const handleBnNameChange = (event: any) => {
    setBnName(event);
    setBnNameError("");
  };

  const handleLatChange = (event: any) => {
    setLat(event);
    setLatError("");
  };

  const handleLonChange = (event: any) => {
    setLon(event);
    setLonError("");
  };

  const handledistrictIdChange = (event: any) => {
    setdistrictId(event);
    setDistrictIdError("");
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();

    var model = new UpdateAreaRequest();
    model.id = ID;
    model.name = name;
    model.code = code;
    model.bnName = bnName;
    model.lat = lat;
    model.lon = lon;
    model.districtId = districtId;

    setSnackBarOn(false);
    updateAreaService(model)
      .then((response) => {
        setSnackBarOn(true);
        setsnackBarMessage("Successfull");
        setSnackBarMessageType("success");

        setTimeout(() => {
          navigate("/thanas");
        }, 300);

        dispatch(areaListAdmin());
      })
      .catch((error: any) => {
        setNameError(error.response.data.errors?.Name?.[0]);
        setCodeError(error.response.data.errors?.Code?.[0]);
        setBnNameError(error.response.data.errors?.BnName?.[0]);
        setLatError(error.response.data.errors?.Lat?.[0]);
        setLonError(error.response.data.errors?.Lon?.[0]);
        setDistrictIdError(error.response.data.errors?.DistrictId?.[0]);
      });
  };

  return (
    <div>
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      <h1>Update Upazilla</h1>
      <AppButton onClick={() => navigate("/thanas")} text="Back" />

      <form className="max-w-xl space-y-2 mx-auto myBorder py-4 px-5">
        <div className="flex justify-between items-center gap-8">

          <AppInput
            className="myInput"
            type="text"
            value={name}
            placeholder="Enter Area Name"
            label="Enter Area Name: "
            onChange={handleNameChange}
            errorMessage={nameError}
          />

          {/* <AppInput
            className="myInput"
            type="text"
            placeholder="Enter Area Code"
            label="Enter Area Code: "
            onChange={handleCodeChange}
            errorMessage={codeError}
          /> */}
        </div>
        {/* <div className="flex justify-between items-center gap-8">

          <AppInput
            className="myInput"
            type="text"
            placeholder="Enter Latitude"
            label="Enter Latitude: "
            onChange={handleLatChange}
            errorMessage={latError}
          />
          <AppInput
            className="myInput"
            type="text"
            placeholder="Enter Longitude"
            label="Enter Longitude: "
            onChange={handleLonChange}
            errorMessage={lonError}
          />
        </div> */}
        <div className="flex justify-between items-center gap-8">
          <AppInput
            className="myInput"
            type="text"
            placeholder="Enter Bn"
            label="Enter Bn: "
            value={bnName}
            onChange={handleBnNameChange}
            errorMessage={bnNameError}
          />
          <div className="w-full">
            <label className="2xl:text-xl md:text-lg text-base">Select District: </label>
            <AppSelect
              value={districtId}
              data={districtsIdNameModels}
              defaultValue="Select District"
              onChange={handledistrictIdChange}
              errorMessage={districtIdError}
            />
          </div>
        </div>
        <div className="flex justify-end">
          <AppButton onClick={handleSubmit} variant="contained" text="Submit" />
        </div>
      </form>
    </div>
  );
}
