import { sSGetMainCategoryByIdService, sSGetMainCategoryListService } from "../../../httpService/superShops/ssMainCategoryServices";
import { PaginationPayloadModel } from "../../../models/common/paginationModels";
import { SSGET_MAIN_CATEGORY_LIST, SSGET_MAIN_CATEGORY_BY_ID } from "../../constants/superShop/ssMainCategoryConstants";

export const sSGetMainCategoryListAction = (payload?: PaginationPayloadModel, isAll?: boolean, forPublic?: boolean) => async (dispatch: any) => {
	var data = sSGetMainCategoryListService(payload, isAll, forPublic);
	const pload = (await data).data;
	dispatch({ type: SSGET_MAIN_CATEGORY_LIST, payload: pload });
};

export const sSGetMainCategoryByIdAction = (id: any) => async (dispatch: any) => {
	try {
		var data = sSGetMainCategoryByIdService(id);
		const payload = (await data).data;
		dispatch({ type: SSGET_MAIN_CATEGORY_BY_ID, payload: payload });
	} catch (error: any) { }
};