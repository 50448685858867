import { GET_PRODUCT_ATTRIBUTE_LIST, GET_PRODUCT_ATTRIBUTE_LIST_BY_ID } from "../../constants/catalog/productAttribute";

const initialState = {
  isOpen: false,
};

const productAttributeListinititalState = {
  data: [],
  dataById: []
}

function createOrEditAttributeReducer(state = initialState, action: any) {
  if (action.type === "OPEN-CREATE-EDIT-ATTRIBUTE") {
    return { isOpen: true };
  }
  if (action.type === "CLOSE-CREATE-EDIT-ATTRIBUTE") {
    return { isOpen: false };
  }
  return state;
}


function productAttributeListReducer(state = productAttributeListinititalState, action: any) {
  switch (action.type) {
    case GET_PRODUCT_ATTRIBUTE_LIST:
      return { ...state, loading: false, data: action.payload };
    case GET_PRODUCT_ATTRIBUTE_LIST_BY_ID:
      return { ...state, loading: false, dataById: action.payload };
    default:
      return state;
  }
}


export { createOrEditAttributeReducer, productAttributeListReducer };