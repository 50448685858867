import {
  GET_UNION_LIST,
  GET_UNION_LIST_BY_AREA,
  GET_UNION_LIST_BY_AUTH_AREA,
} from "../../constants/importantNumbers/unionConstants";

export function getUnionListReducer(state = { data: [] }, action: any) {
  switch (action.type) {
    case GET_UNION_LIST:
      return { loading: false, data: action.payload };
    default:
      return state;
  }
}

export function getUnionByAreaListReducer(state = { data: [] }, action: any) {
  switch (action.type) {
    case GET_UNION_LIST_BY_AUTH_AREA:
      return { loading: false, data: action.payload };
    case GET_UNION_LIST_BY_AREA:
      return { loading: false, data: action.payload };
    default:
      return state;
  }
}
