import { affiliateGetOrderListCustomerService, affiliateGetOrderItemsCustomerService, affiliateGetOrderListAdminService, affiliateGetOrderItemsAdminService, affiliateGetOrderReturnRequestAdminService } from "../../../httpService/sales/affiliateOrderServices";
import { ResellingAdminGetOrderListRequest, GetOrdersCustomerRequest, OrderDetailsAdminRequest } from "../../../models/order/resellingOrdersModels";
import { PaginationPayloadModel } from "../../../models/common/paginationModels";
import { AFFILIATE_GET_ORDER_LIST, AFFILIATE_GET_ORDER_DETAILS, ADMIN_AFFILIATE_GET_ORDER_LIST, ADMIN_AFFILIATE_GET_ORDER_DETAILS } from "../../constants/sales/affiliateOrderConstants";


export const affiliateGetOrderListAction = (payload: GetOrdersCustomerRequest) => async (dispatch: any) => {
    var data = affiliateGetOrderListCustomerService(payload);
    const pload = (await data).data;
    dispatch({ type: AFFILIATE_GET_ORDER_LIST, payload: pload });
};

export const affiliateGetOrderDetailsAction = (payload?: PaginationPayloadModel) => async (dispatch: any) => {
    var data = affiliateGetOrderItemsCustomerService(payload);
    const pload = (await data).data;
    dispatch({ type: AFFILIATE_GET_ORDER_DETAILS, payload: pload });
};

export const adminAffiliateGetOrderListAction = (payload: ResellingAdminGetOrderListRequest) => async (dispatch: any) => {
    var data = affiliateGetOrderListAdminService(payload);
    const pload = (await data).data;
    dispatch({ type: ADMIN_AFFILIATE_GET_ORDER_LIST, payload: pload });
};

export const adminAffiliateGetOrderDetailsAction = (payload?: OrderDetailsAdminRequest) => async (dispatch: any) => {
    var data = affiliateGetOrderItemsAdminService(payload);
    const pload = (await data).data;
    dispatch({ type: ADMIN_AFFILIATE_GET_ORDER_DETAILS, payload: pload });
};