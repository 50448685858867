import { AlertColor } from "@mui/material";
import { useState, useMemo, useEffect } from "react";
import { HiMinus, HiPlus } from "react-icons/hi";
import { TbCurrencyTaka } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import { RootState } from "../../../../store";
import { AppButton } from "../../../common/AppButton";
import { AppCheckBox } from "../../../common/AppCheckBox";
import { AppLoader } from "../../../common/AppLoader";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { sSGetCartItemsAction, sSGetTotalCartItemAction } from "../../../../store/actions/superShop/sSCartActions";
import { sSCartItemSelectionService, sSIncreseDecreseCartItemService, sSRemoveCartItemsService } from "../../../../httpService/superShops/ssCartService";
import { SSCartItemSelection, SSGetCartItemsRequest, SSIncreseOrDecreseProductQuantityRequest, SSRemoveItemSingleOrListItemRequest } from "../../../../models/superShopp/cart/sSCartModel";
import { AppSelect } from "../../../common/AppSelect";
import { IdNameResponseModel } from "../../../../models/common/IdNameModel";
import { SSGetBazarListRequest } from "../../../../models/superShopp/Address/sSBazar/sSBazarModel";
import { sSGetBazarListAction } from "../../../../store/actions/superShop/sSBazarActions";
import { getUserProfileAction } from "../../../../store/actions/auth/user/userActions";

export default function SSGetCartItems() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const [cartItems, setCartItems] = useState<any[]>([]);

  const [totalPrice, setTotalPrice] = useState(0);

  const [isRemoveButtonDisable, setIsRemoveButtonDisable] = useState(false);

  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

  var token = reactLocalStorage.getObject("SignIn");

  const [ssBazarId, setSSBazarId] = useState(0);

  const bazarList = useSelector((state: RootState) => {
    return state.sSBazarListResponse.data;
  });

  var bzrIdNameModels: IdNameResponseModel[] = [];
  bazarList.items?.map((x: { id: number; name: string | undefined }) => {
    var temp = new IdNameResponseModel();
    temp.id = x.id;
    temp.name = x.name;
    bzrIdNameModels.push(temp);
  });

  const userProfile = useSelector((state: RootState) => {
    return state.getUserProfileResponse.data;
  });

  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);

  const cartItemsSignIn = useSelector((state: RootState) => {
    return state.sSGetCartItemsResponse.data;
  });

  const cartItemsMemo = useMemo(() => {
    return cartItemsSignIn;
  }, [cartItemsSignIn]);

  useEffect(() => {
    if (token && JSON.stringify(token) !== JSON.stringify({})) {
      if (cartItemsMemo) {
        setCartItems(cartItemsMemo);
      }
    } else {
    }
  }, [cartItemsMemo, token]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userProfile?.divisionId !== 0) {
          var pModel = new SSGetBazarListRequest();
          pModel.limit = 100000;
          pModel.offset = 0;
          pModel.divisionId = userProfile.divisionId ? userProfile.divisionId : 0;
          pModel.districtId = userProfile.districtId ? userProfile.districtId : 0;
          pModel.areaId = userProfile.areaId ? userProfile.areaId : 0;
          await dispatch(sSGetBazarListAction(pModel));
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };
    if (userProfile?.divisionId) {
      fetchData();
    }
  }, [userProfile]);


  const handleBazarIdChange = (bId: any) => {
    const fetchData = async () => {
      try {
        setSSBazarId(bId);
        setIsLoading(true);
        let model = new SSGetCartItemsRequest();
        model.bazarId = bId;
        await dispatch(sSGetCartItemsAction(model));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }

  useEffect(() => {
    getTotalPrice();
  }, [cartItems]);

  const uniqueVendors = new Set<string>();

  const getTotalPrice = () => {
    let p = 0;
    if (cartItems && JSON.stringify(cartItems) !== JSON.stringify({})) {
      cartItems?.filter(_ => _.isSelected)?.map((x: any) => {
        if (x.vendorName) {
          uniqueVendors.add(x.vendorName);
        }
        return p = p + x.totalPrice;
      });
      setTotalPrice(p);
    }
  };

  const removeCartItem = (item: any) => {
    if (token && JSON.stringify(token) !== JSON.stringify({})) {
      var ids = [];
      ids.push(item.id);

      let payload = new SSRemoveItemSingleOrListItemRequest();
      payload.ids = ids;

      setIsRemoveButtonDisable(true);
      sSRemoveCartItemsService(payload)
        .then(() => {

          setTimeout(() => {
            let model = new SSGetCartItemsRequest();
            model.bazarId = ssBazarId;
            dispatch(sSGetCartItemsAction(model));
            dispatch(sSGetTotalCartItemAction());
            setIsRemoveButtonDisable(false);
          }, 1000);

          setIsShowSnackBar(true);
          setSnackBarMessage("Successfully Removed");
          setSnackBarMessageType("error");
          setTimeout(() => {
            setIsShowSnackBar(false);
          }, 3000);

        })
        .catch(() => {
          setIsRemoveButtonDisable(false);
        });
    } else {
      let price = parseFloat(totalPrice.toString()) - parseFloat(item?.totalPrice);
      setTotalPrice(price);
      var newItems = cartItems?.filter((_) => _.productId !== item.productId);
      dispatch(sSGetTotalCartItemAction());
      setCartItems(newItems);
    }
  };

  const handleCountChange = (type: any, index: any, item: any) => {

    if (type === "increse") {
      const currentItems: any = [];

      var req = new SSIncreseOrDecreseProductQuantityRequest();
      req.id = item.id;
      req.isIncrese = true;

      if (token && JSON.stringify(token) !== JSON.stringify({})) {
        sSIncreseDecreseCartItemService(req)
          .then((res) => {
            if (res?.data?.isOperationSuccessfull) {
              let model = new SSGetCartItemsRequest();
              model.bazarId = ssBazarId;
              dispatch(sSGetCartItemsAction(model));
              dispatch(sSGetTotalCartItemAction());
            }
            else {
              setIsShowSnackBar(true);
              setSnackBarMessage("Out Of Stock");
              setSnackBarMessageType("warning");
              setTimeout(() => {
                setIsShowSnackBar(false);
              }, 2000);
            }

          })
          .catch(() => { });
      } else {
        cartItems.forEach((item: any) => {
          if (!currentItems.some((x: any) => x.productId === item.productId)) {
            currentItems.push(item);
          }
        });

        var quantity = currentItems[index].productQuantity;
        currentItems[index].productQuantity = quantity ? quantity + 1 : 1;

        var currentQuantity = currentItems[index].productQuantity;
        var untPrice = currentItems[index].unitPrice;

        currentItems[index].totalPrice = currentQuantity * untPrice;

        let price = parseFloat(totalPrice.toString()) + parseFloat(untPrice);

        setTotalPrice(price);

        setCartItems([]);
        setCartItems(currentItems);

        dispatch(sSGetTotalCartItemAction());
      }
    }

    if (type === "decrese") {
      if (token && JSON.stringify(token) !== JSON.stringify({})) {
        if (item.productQuantity > 1) {
          var req = new SSIncreseOrDecreseProductQuantityRequest();
          req.id = item.id;
          req.isIncrese = false;

          sSIncreseDecreseCartItemService(req)
            .then(() => {
              let model = new SSGetCartItemsRequest();
              model.bazarId = ssBazarId;
              dispatch(sSGetCartItemsAction(model));
              dispatch(sSGetTotalCartItemAction());
            })
            .catch((err) => { });
        }
      } else { }
    }
  };

  const handleNavigateToSubmitOrderPage = () => {
    if (cartItems?.length <= 0 || JSON.stringify(cartItems) === JSON.stringify({})) {
      setIsShowSnackBar(true);
      setSnackBarMessage("Your cart list is empty");
      setSnackBarMessageType("error");
      setTimeout(() => {
        setIsShowSnackBar(false);
      }, 3000);
    } else {
      if (cartItems?.filter((_) => _.isSelected).length <= 0) {
        setIsShowSnackBar(true);
        setSnackBarMessage("Please select item(s)");
        setSnackBarMessageType("error");
        setTimeout(() => {
          setIsShowSnackBar(false);
        }, 3000);
      } else {
        if (token && JSON.stringify(token) !== JSON.stringify({})) {
          navigate(`/super-shop/submit-order/${ssBazarId}`);
        } else {
          dispatch({ type: "OPEN-SIGN-IN" });
          dispatch({ type: "CLOSE-SIGN-UP" });
        }
      }
    }
  };

  const handleItemSelected = (item: any, isChecked: any) => {
    if (JSON.stringify(token) !== JSON.stringify({})) {

      var models: SSCartItemSelection[] = [];

      cartItems.map((i: any) => {
        var model = new SSCartItemSelection();
        if (item.id === i.id) {
          model.id = i.id;
          model.productId = i.productId;
          model.isSelected = isChecked;
          models.push(model);
        }
      });

      sSCartItemSelectionService(models)
        .then((res: any) => {
          const newItems = cartItems.map((i: any) => {
            if (item.id === i.id) {
              i.isSelected = isChecked;
            }
            return i;
          });
          setCartItems(newItems);
          let model = new SSGetCartItemsRequest();
          model.bazarId = ssBazarId;
          dispatch(sSGetCartItemsAction(model));
        })
        .catch(() => { });

    } else { }
  };

  const handleSelectAll = (isChecked: any) => {

    if (JSON.stringify(token) !== JSON.stringify({})) {

      var models: SSCartItemSelection[] = [];

      cartItems.map((i: any) => {
        var model = new SSCartItemSelection();
        model.id = i.id;
        model.productId = i.productId;
        model.isSelected = isChecked;
        models.push(model);
      });

      sSCartItemSelectionService(models)
        .then(() => {
          const newItems = cartItems.map((i: any) => {
            i.isSelected = isChecked;
            return i;
          });
          setCartItems(newItems);
        })
        .catch(() => { });

    } else { }
  };

  const handleNavigateToHome = () => {
    navigate('/super-shop');
  };

  const navigateToProductDetails = (id?: any) => {
    navigate(`/super-shop/product/${id}`);
  };

  if (isLoading) {
    return (
      <>
        <div className="container mx-auto items-center 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28 ">
          <AppSelect
            defaultValue="Select Bazar"
            value={ssBazarId as number}
            data={bzrIdNameModels}
            onChange={handleBazarIdChange}
          />
        </div>
        <div className="container h-screen flex items-center justify-center mx-auto px-2 md:px-0 pb-12">
          <AppLoader status={true} />
        </div>
      </>
    );
  }

  return (
    <>
      {isShowSnackBar && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}

      <div className="container mx-auto items-center 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28 ">
        <AppSelect
          defaultValue="Select Bazar"
          value={ssBazarId as number}
          data={bzrIdNameModels}
          onChange={handleBazarIdChange}
        />
      </div>

      {ssBazarId === 0 &&
        <div className="flex flex-col min-h-[90dvh] pb-8 justify-center container mx-auto  items-center 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28 ">
          <h2 className="pb-2">Please Select Bazar</h2>
        </div>
      }
      {ssBazarId !== 0 &&
        <>
          {cartItems?.length === 0 &&
            <div className="flex flex-col min-h-[90dvh] pb-8 justify-center container mx-auto items-center 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28">
              <h2 className="pb-2">Your shopping cart is empty.</h2>
              <AppButton className="ssBtn" text="Continue shopping" onClick={handleNavigateToHome} />
            </div>
          }

          {cartItems?.length !== 0 &&
            <div className="customer-shopping-cart-design container mx-auto flex flex-col md:flex-row justify-between gap-x-4 lg:pt-4 pb-8 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28 ">


              <div className="w-full md:w-3/4">
                <h3 className="title pb-1">My cart</h3>

                {cartItems?.length !== 0 && (
                  <div className="flex items-center justify-between md:pr-4">
                    <div className="flex items-center md:gap-x-2 2xl:py-3 py-1 pl-2 2xl:pl-4">
                      <AppCheckBox isChecked={cartItems?.length === cartItems?.filter((_) => _.isSelected).length} onChange={handleSelectAll} />
                      <span className="uppercase 2xl:text-lg">Select all</span>
                    </div>
                  </div>
                )}

                <div className="max-h-[70dvh] overflow-y-auto space-y-3 mt-1">
                  <section className="myBorder shadow hover:shadow-lg pb-1">
                    <div className="flex items-center text-sm lg:text-base font-medium 2xl:px-4 lg:px-2 px-1 py-1 md:py-2">
                      <div className="w-[56%] lg:w-[52%] pl-2">
                        <h3>Product</h3>
                      </div>
                      <div className="w-[18%] lg:w-[16%] text-center">
                        <h3>Price</h3>
                      </div>
                      <div className="w-[8%] lg:w-[16%] text-center">
                        <h3 className="lg:hidden">Qty</h3>
                        <h3 className="hidden lg:block">Quantity</h3>
                      </div>
                      <div className="w-[18%] lg:w-[16%] text-center">
                        <h3>Total</h3>
                      </div>
                    </div>
                    {cartItemsMemo?.map((item: any, indx: any) => (
                      <div className="flex items-center 2xl:px-4 lg:px-2 px-1 py-0.5 md:py-1" key={indx}>
                        <div className="flex items-center w-[56%] lg:w-[52%]">
                          <AppCheckBox
                            className="w-5 h-5"
                            isChecked={item?.isSelected}
                            onChange={(e: any) => handleItemSelected(item, e)}
                          />
                          <div className="pl-1.5 lg:pl-2.5 flex items-center md:gap-x-2.5 gap-x-1">
                            <img onClick={() => navigateToProductDetails(item.productId)} className='md:w-14 w-8 md:h-14 h-8 object-cover rounded' src={item?.bannerPath} alt="These is product pic" />
                            <div className="text-xsm md:text-base">
                              <h3 className="cursor-pointer " onClick={() => navigateToProductDetails(item.productId)}>
                                <span className="text-royalBlue lg:hidden">
                                  {item?.productName.length > 16 ? `${item?.productName.substring(0, 16)}...` : item?.productName}
                                </span>
                                <span className="text-royalBlue hidden lg:block">
                                  {item?.productName.length > 40 ? `${item?.productName.substring(0, 40)}...` : item?.productName}
                                </span>
                                {/* <div>
                                  <h1>{item?.brandName}</h1>
                                </div> */}
                              </h3>
                            </div>
                          </div>
                          <div className="flex justify-end items-end ml-auto mt-auto lg:mt-0">
                            <AppButton text="-" isDisable={isRemoveButtonDisable} className="bg-red h-3 w-4 md:w-6 lg:h-4 pb-2 md:pb-2 flex justify-center text-2xl items-center rounded-sm text-white" onClick={() => removeCartItem(item)} />
                          </div>
                        </div>
                        <div className="w-[18%] lg:w-[16%] flex flex-col gap-0.5 items-center justify-center">
                          <span className="flex items-center justify-center text md:gap-x-1 gap-x-[1px]"> <TbCurrencyTaka /> {item?.unitPrice}</span>
                          <div onClick={() => handleCountChange("decrese", indx, item)} className="lg:hidden flex w-4 h-4 p-0.5 text-sm cursor-pointer justify-center items-center bg-white myBorder border-[.5px] rounded-[1px]"> <HiMinus />   </div>
                        </div>
                        <div className="w-[8%] lg:w-[16%] flex flex-col-reverse md:flex-row items-center justify-center mt-auto lg:mt-0 gap-x-4 gap-y-[2px]">
                          <div onClick={() => handleCountChange("decrese", indx, item)} className="hidden lg:flex md:w-6 w-5 md:h-6 h-5 text-base cursor-pointer justify-center items-center bg-white myBorder rounded-sm"> <HiMinus />   </div>
                          <span className="md:text-lg text-xs font-medium">
                            {item?.productQuantity}
                          </span>
                          <div onClick={() => handleCountChange("increse", indx, item)} className="hidden lg:flex md:w-6 w-5 md:h-6 h-5 text-base cursor-pointer justify-center items-center bg-white myBorder rounded-sm"> <HiPlus /> </div>
                        </div>
                        <div className="w-[18%] lg:w-[16%] flex flex-col gap-0.5 items-center justify-center">
                          <span className="flex items-center justify-center text md:gap-x-1 gap-x-[1px]"> <TbCurrencyTaka /> {item?.totalPrice}</span>
                          <div onClick={() => handleCountChange("increse", indx, item)} className="lg:hidden flex w-4 h-4 p-0.5 text-sm cursor-pointer justify-center items-center bg-white myBorder border-[.5px] rounded-[1px]"> <HiPlus /> </div>
                        </div>
                      </div>
                    ))}
                  </section>
                </div>

              </div>

              <div className="w-full mb-4 md:mb-0 md:w-1/4 2xl:mt-20 md:mt-16 mt-2 h-fit bg-bgSecondary rounded px-4 py-6 2xl:space-y-7 space-y-6">
                <h3 className="title">Order Summary</h3>
                <div className="2xl:space-y-3 space-y-2">
                  <div className="flex justify-between 2xl:text-xl text-lg items-center">
                    <h3>Subtotal <span>({cartItems?.length} items)</span></h3>
                    <p className="flex items-center gap-x-1 justify-end"><TbCurrencyTaka /> <span>{totalPrice}</span></p>
                  </div>
                  <hr className="border border-textBlack my-1" />
                  <div className="flex justify-between subTitle items-center">
                    <h3>Total</h3>
                    <p className="flex items-center gap-x-1 justify-end"><TbCurrencyTaka /> <span>{totalPrice}</span></p>
                  </div>
                </div>
                <div className="ssBtn text-center mx-8" onClick={handleNavigateToSubmitOrderPage}>
                  Proceed to checkout
                </div>
              </div>
            </div>
          }
        </>
      }
    </>
  );

}
