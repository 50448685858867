import { PaginationPayloadModel } from "../common/paginationModels";
import { BillingShippingSubmitOrderAddress } from "../auth/user/BillingShippingAddressModels";

export class ResellingSubmitOrderModel extends BillingShippingSubmitOrderAddress {
  isDeliveryChargePaid?: boolean;
  isOrderTotalPaid?: boolean;
  isInSideDhaka?: boolean;
  customerIP?: string;
  totalAmount?: number;
  shippingCost?: number;
  totalWeight?: number;
  orderProductRequest?: OrderProductRequest[];
  referrerId?: any;
}
export class AffiliateSubmitOrderModel extends BillingShippingSubmitOrderAddress {
  isDeliveryChargePaid?: boolean;
  isOrderTotalPaid?: boolean;
  isInSideDhaka?: boolean;
  customerIP?: string;
  totalAmount?: number;
  shippingCost?: number;
  totalWeight?: number;
  orderProductRequest?: OrderProductRequest[];
  affiliateProductReferrerId?: any;
}

export class SubmitOrderRequest {
  customerIP?: string;
  totalAmount?: number;
  shippingCost?: number;
  totalWeight?: number;
  orderProductRequest?: OrderProductRequest[];
}

export class OrderProductRequest {
  productId?: string;
  quantity?: number;
  unitPrice?: number;
  totalPrice?: number;
  unitIncome?: number;
  totalIncome?: number;
  attributes?: any;
  bannerPath?: string;
  attributeValueIds?: string;
}

export enum OrderStatus {
  All,
  InProgress,
  Cancelled,
  Returned,
  Complete,
  Confirm,
}

export const OrderStatuses = [
  { id: 0, name: "Order Status" },
  { id: 1, name: "InProgress" },
  { id: 2, name: "Cancelled" },
  { id: 3, name: "Delevered" },
  { id: 4, name: "NotDelevered" },
  { id: 5, name: "Received" },
  { id: 6, name: "Returned" },
];

export enum PaymentStatus {
  All,
  Paid,
  NotPaid,
}

export const PaymentStatuses = [
  { id: 0, name: "Payment Status" },
  { id: 1, name: "Paid" },
  { id: 2, name: "NotPaid" },
];

export enum ShippingStatus {
  All,
  InProgress,
  Shipped,
  Delivered,
}

export const ShippingStatuses = [
  { id: 0, name: "Shipping Status" },
  { id: 1, name: "Shipped" },
  { id: 2, name: "NotShipped" },
];

export class GetOrdersCustomerRequest extends PaginationPayloadModel {
  orderId?: number;
  orderStatus?: OrderStatus;
  paymentStatus?: PaymentStatus;
  shippingStatus?: ShippingStatus;
}

export class UpdateOrderPaymentRequest {
  paymentId?: number;
}


export class SubmitOrderResponse {
  invoiceNumber?: any
  totalItem?: any
  totalAmount?: any
  orderId?: any
}

export class ResellingAdminGetOrderListRequest extends PaginationPayloadModel {
  orderId?: number;
  customerId?: number;
  startDateTime?: string;
  endDateTime?: string;
  orderStatus?: OrderStatus;
  paymentStatus?: PaymentStatus;
  shippingStatus?: ShippingStatus;
}

export class OrderCustomerResponse {
  id?: number;
  orderDateTime?: string;
  totalAmount?: string;
  deliveryCharge?: string;
  orderStatus?: string;
  paymentStatus?: string;
  shippingStatus?: string;
  orderProducts?: OrderCustomerProductResponse[];
}

export class OrderCustomerProductResponse {
  productId?: number;
  productName?: string;
  bannerPath?: string;
  quantity?: number;
  unitPrice?: number;
  totalPrice?: number;
  attributes?: null | any; // Update with actual attribute type
  orderStatus?: string;
}

export class OrderProductCustomerResponse {
  orderId?: number;
  customerId?: any;
  deliveryCharge?: number;
  tax?: number;
  vat?: number;
  totalCost?: number;
  paymentStatus?: any;
  shippingStatus?: any;
  orderStatus?: any;
  orderDateTime?: any;
  orderProductList?: OrderProductCustomer[];
  shippingAddress?: BillingAndShippingAddressCustomerResponse;
  paymentMethod: any;
  isDeliveryChargePaid: any;
  isOrderTotalPaid: any;
}

export class OrderProductCustomer {
  productId?: number;
  productName?: string;
  bannerPath?: string;
  quantity?: number;
  unitPrice?: number;
  totalPrice?: number;
  attributes?: any;
  vendorId?: any;
  vendorName?: string;
  orderStatus?: string;
  shippingStatus?: string;
}

export class BillingAndShippingAddressCustomerResponse {
  id?: number;
  firstName?: string;
  lastName?: string;
  phoneNo1?: string;
  phoneNo2?: string;
  email?: string;
  divisionName?: string;
  districtName?: string;
  areaName?: string;
  areaId?: number;
  companyName?: string;
  postCode?: string;
  addressLine1?: string;
  addressLine2?: string;
}

export class OrderAdminResponse {
  id?: any;
  orderId?: number;
  customerName?: string;
  customerId?: null;
  customerIP?: null;
  orderDateTime?: string;
  shippingCost?: number;
  totalWeight?: string;
  totalAmount?: string;
  profit?: number;
  deliveryDateTime?: string;
  orderStatus?: string;
  paymentStatus?: string;
  shippingStatus?: string;
  deliveryStatus?: string;
  warehouseName?: string;
  qty?: any;
  vendorBackAmount?: number;
}

export class OrderDetailsAdminRequest {
  orderId?: string;
  customerId?: string;
}

export class OrderProductAdminResponse { }

export class UpdatePaymentStatusAdminRequest {
  orderId?: string;
  paymentStatus?: PaymentStatus;
}

export class UpdateOrderStatusAdminRequest{
  orderId?: string;
  orderStatus?: OrderStatus;
}

export class AdminCompleteOrderRequest {
  orderId?: string;
}

export class AdminConfirmOrderRequest {
  orderId?: string;
}

export class AdminShippedOrderRequest {
  orderId?: string;
  shippingStatus?: ShippingStatus;
  trackUrl?: string;
}

export class CancellOrderRequest {
  orderId?: any;
  productId?: any;
  vendorId?: any;
  isAllCancellOfVendor?: boolean;
  isAllCancell?: boolean;
}

export class ReturnOrderRequest {
  orderId?: any;
  productId?: any;
  vendorId?: any;
  isAllReturnOfVendor?: boolean;
  isAllReturn?: boolean;
}
