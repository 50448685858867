import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { AlertColor } from "@mui/material";
import { PaginationPayloadModel } from "../../../models/common/paginationModels";
import { RootState } from "../../../store";
import { AppButton } from "../../common/AppButton";
import { AppDeleteModal } from "../../common/AppDeleteModal";
import { AppLoader } from "../../common/AppLoader";
import { AppSearchBox } from "../../common/AppSearchBox";
import { AppSnackBar } from "../../common/AppSnackBar";
import { Link } from "react-router-dom";
import { deletePopularLinkService } from "../../../httpService/popularLink/popularLinkServices";
import { popularLinkListAction } from "../../../store/actions/popularLink/popularLinkActions";


export default function AdminGetPopularLinks() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  var paginationModel = new PaginationPayloadModel();

  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);

  const popularLinkList = useSelector((state: RootState) => {
    return state.popularLinkListResponse.data;
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(popularLinkListAction(paginationModel));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  const onSearch = (searchQuery: string) => {
    paginationModel.searchQuery = searchQuery;
    dispatch(popularLinkListAction(paginationModel));
  };

  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  const navigateToAdd = () => {
    navigate("/admin/popular-link/popular-link/add");
  };

  const handleUpdate = (id: any) => {
    navigate(`/admin/popular-link/popular-link/update/${id}`);
  };
  const [bulkIds, setBulkIds] = useState([]);

  const openDeleteModal = (id: any) => {
    setOpenDeleteModal(true);

    let ids: any = [];
    ids.push(id);

    setBulkIds(ids);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleDelete = () => {
    setSnackBarOn(false);
    deletePopularLinkService(bulkIds)
      .then((response: any) => {
        dispatch(popularLinkListAction());
        setSnackBarOn(true);
        setSnackBarMessage("Successfully Deleted");
        setSnackBarMessageType("success");
        closeDeleteModal();
        dispatch(popularLinkListAction(paginationModel));
      })
      .catch((error: any) => {
        setSnackBarOn(true);
        setSnackBarMessage("Something went wrong");
        setSnackBarMessageType("error");
      });
  };

  return (
    <div>
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}

      <h2 className="title text-center">PopularLink List</h2>

      <div className="flex flex-col gap-2 pt-2 lg:flex-row lg:items-center lg:gap-6">
        <AppButton className="popularLinkBtn" onClick={navigateToAdd} text="Add PopularLink" />
        {/* <div className="flex items-center gap-2 lg:gap-6">
        <AppButton onClick={handleBulkDelete} text="Remove" />
        </div> */}
        <AppSearchBox
          className="myInput"
          onChange={onSearch}
          placeholder="Search Here"
        />
      </div>
      {isLoading ? (
        <AppLoader status={true} />
      ) : (
        <>
          {popularLinkList.items ? (
            <div className="w-full pt-4">
              {/* these is header part for desktop */}
              <div className="hidden w-full rounded bg-blue-400 lg:flex">
                <div className="tableHead w-[12%]">
                  Photo
                </div>
                <div className="tableHead w-[23%]">
                  Name
                </div>
                <div className="tableHead w-[43%]">
                  description
                </div>
                <div className="tableHead w-[10%]">
                  Url
                </div>
                {/* <div className="tableHead w-[10%]">
                  Is Publish
                </div>
                <div className="tableHead w-[10%]">
                  Update
                </div> */}
                <div className="tableHead w-[10%] border-none">
                  Remove
                </div>
              </div>
              {/* these is middle part for desktop */}
              <div className="hidden h-[65dvh] flex-col gap-1 overflow-y-auto py-1.5 lg:flex 2xl:h-[68dvh]">
                {popularLinkList.items.map((popularLink: any) => (
                  <div
                    key={popularLink?.id}
                    className="myBorder flex w-full items-center"
                  >
                    <div className="tableRow w-[12%]">
                      <img
                        src={popularLink?.bannerPath}
                        className="mx-auto h-10 w-10 object-contain"
                        alt="Main category img"
                      />
                    </div>
                    <div className="tableRow textControl w-[23%]">
                      {popularLink?.name}
                    </div>
                    <div className="tableRow textControl w-[43%]">
                      {popularLink?.description}
                    </div>
                    <div className="tableRow w-[10%]">
                      <Link target="_blank" to={popularLink?.url} className="popularLinkBtn">Url</Link>
                    </div>
                    {/* <div className="tableRow w-[10%]">
                      {popularLink?.isPublished ? (
                        <AppButton
                          // isSubmitting={}
                          className="removeBtn"
                          text="UnPublish"
                          onClick={() => { }}
                        />
                      ) : (
                        <AppButton
                          // isSubmitting={}
                          className="myBtn"
                          text="Publish"
                          onClick={() => { }}
                        />
                      )}
                    </div> */}
                    {/* <div className="tableRow w-[10%]">
                      <AppButton
                        className="popularLinkBtn"
                        text="Update"
                        onClick={() => handleUpdate(popularLink?.id)}
                      />
                    </div> */}
                    <div className="tableRow border-r-0 w-[10%]">
                      <AppButton
                        className="removeBtn"
                        text="Remove"
                        onClick={() => openDeleteModal(popularLink?.id)}
                      />
                    </div>
                  </div>
                ))}
              </div>
              {/* these is middle part for mobile */}
              <div className="flex h-[78dvh] flex-col gap-2 overflow-y-auto lg:hidden">
                {popularLinkList.items.map((popularLink?: any) => (
                  <div
                    key={popularLink?.id}
                    className="myBorder flex w-full flex-col p-2"
                  >
                    <div className="flex w-full items-center justify-between">
                      <p><span className="font-medium">Name:</span> {popularLink?.name}</p>
                      <img
                        src={popularLink?.bannerPath}
                        className="h-10 w-10 object-contain"
                        alt="Main category img"
                      />
                    </div>
                    <p><span className="font-medium">Description:</span> {popularLink?.description}</p>

                    {/* these is approve decline details div  */}
                    <div className="flex w-full items-center justify-between gap-2 pt-2">
                      <Link target="_blank" to={popularLink?.url} className="popularLinkBtn">Url</Link>
                      {/* {popularLink?.isPublished ? (
                        <AppButton
                          // isSubmitting={}
                          className="removeBtn"
                          text="UnPublish"
                          onClick={() => { }}
                        />
                      ) : (
                        <AppButton
                          // isSubmitting={}
                          className="myBtn"
                          text="Publish"
                          onClick={() => { }}
                        />
                      )} */}
                      {/* <AppButton
                        className="popularLinkBtn"
                        text="Update"
                        onClick={() => handleUpdate(popularLink?.id)}
                      /> */}

                      <AppButton
                        className="removeBtn"
                        text="Remove"
                        onClick={() => openDeleteModal(popularLink?.id)}
                      />
                    </div>
                  </div>
                ))}
              </div>
              {/* these is footer part  */}
              {/* <div className="myBorder py-1 text-center">pagination here</div> */}
            </div>
          ) : (
            <div className="flex h-[50dvh] w-full items-center justify-center text-3xl font-medium text-textBlack/90">
              <span>No PopularLink found</span>
            </div>
          )}
        </>
      )}

      {isOpenDeleteModal && (
        <AppDeleteModal
          text="Are you sure to Delete?"
          handleDelete={handleDelete}
          closeDeleteModal={closeDeleteModal}
        />
      )}
    </div>
  );
}