import { DELETE, GET, POST, PUT } from "../service";

export const addImamsNumberService = (data: any) => {
    return POST("/api/ImamsNumber/CreateImamsNumber", data);
};

export const getImamsNumberService = (payload: any) => {
    return GET(`/api/ImamsNumber/GetImamsNumbers?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}&divisionId=${payload?.divisionId}&districtId=${payload?.districtId}&areaId=${payload?.areaId}&unionOrWardId=${payload?.unionOrWardId}`);
};

export const getImamsNumberByIdService = (id: any) => {
    return GET(`/api/ImamsNumber/GetImamsNumber?Id=${id}`);
};

export const updateImamsNumberService = (data: any) => {
    return PUT("/api/ImamsNumber/UpdateImamsNumber", data);
};

export const deleteImamsNumberService = (data: any) => {
    return DELETE(`/api/ImamsNumber/RemoveImamsNumber`, {
        data: { "ids": data },
    });
};



