import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import _moment from "moment";
import { useDispatch } from "react-redux";
import { AppButton } from "../../../common/AppButton";
import { AppInput } from "../../../common/AppInput";
import { AppSelect } from "../../../common/AppSelect";
import { createUnionService } from "../../../../httpService/importantNumbers/unionServices";
import { TypeUnionOrWard } from "../../../../models/importantNumber/unionModels";
import { reactLocalStorage } from "reactjs-localstorage";
import { isAdmin } from "../../../../common/common";

export default function INCreateUnion() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [bnName, setBnName] = useState("");
  const [bnNameError, setBnNameError] = useState("");
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");
  const [lat, setLat] = useState("");
  const [latError, setLatError] = useState("");
  const [lon, setLon] = useState("");
  const [lonError, setLonError] = useState("");
  const [mobileFirst, setMobileFirst] = useState("");
  const [mobileFirstError, setMobileFirstError] = useState("");
  const [mobileSecond, setMobileSecond] = useState("");
  const [mobileSecondError, setMobileSecondError] = useState("");
  const [type, setType] = useState(0);
  const [isSaveButtonLoading, setIsSaveButtonLoading] = useState(false);
  const [typeError, setTypeError] = useState("");

  const nameHandle = (value: any) => {
    setName(value);
  };
  const bnNameHandle = (value: any) => {
    setBnName(value);
  };
  const codeHandle = (value: any) => {
    setCode(value);
  };
  const latHandle = (value: any) => {
    setLat(value);
  };
  const lonHandle = (value: any) => {
    setLon(value);
  };
  const mobileFirstHandle = (value: any) => {
    setMobileFirst(value);
  };
  const mobileSecondHandle = (value: any) => {
    setMobileSecond(value);
  };
  const typeHandle = (value: any) => {
    setType(value);
  };

  const saveUnion = () => {
    var payload = {
      name: name,
      bnName: bnName,
      code: code,
      lat: lat,
      lon: lon,
      phoneNumberFirst: mobileFirst,
      phoneNumberSecond: mobileSecond,
      type: type,
    };

    setIsSaveButtonLoading(true);

    createUnionService(payload)
      .then(() => {
        setIsSaveButtonLoading(false);
        navigateToUnions();
      }).catch((error: any) => {
        setIsSaveButtonLoading(false);
        setNameError(error.response.data.errors?.Name?.[0]);
        setBnNameError(error.response.data.errors?.BnName?.[0]);
        setCodeError(error.response.data.errors?.Code?.[0]);
        setMobileFirstError(
          error.response.data.errors?.PhoneNumberFirst?.[0]
        );
        setMobileSecondError(
          error.response.data.errors?.PhoneNumberSecond?.[0]
        );
        setTypeError(error.response.data.errors?.Type?.[0]);
      });
  };

  const navigateToUnions = () => {
    if(isAdmin(reactLocalStorage.get("RoleName"))){
      navigate("/admin/important-numbers/unions");
    }else{
      navigate("/important-numbers/unions");
    }
  };

  return (
    <div className="container mx-auto px-2 py-4">

      <div className="flex flex-col lg:flex-row items-start lg:items-center pb-4">
        <AppButton
          className="gradientBtn"
          onClick={navigateToUnions}
          text="Back"
        />
        <div className='w-full'>
          <h2 className="title text-center text-cyan-600">
            Create Unions Document
          </h2>
        </div>
      </div>

      <form className="myBorder mx-auto max-w-xl grid grid-cols-2 gap-x-2 items-center lg:gap-x-4 gap-y-2 shadow-lg px-2 lg:px-5 py-2 lg:py-4">
        <AppInput
          className="myInput"
          type="text"
          placeholder="Union Name"
          label="Union Name: "
          onChange={nameHandle}
          errorMessage={nameError}
        />
        <AppInput
          className="myInput"
          type="text"
          placeholder="Bangla Name"
          label="Bangla Name: "
          onChange={bnNameHandle}
          errorMessage={bnNameError}
        />
        <AppInput
          className="myInput"
          type="text"
          placeholder="Union No."
          label="Union No: "
          onChange={codeHandle}
          errorMessage={codeError}
        />
        <AppInput
          className="myInput"
          type="text"
          placeholder="Phone Number 1"
          label="Phone Number 1: "
          onChange={mobileFirstHandle}
          errorMessage={mobileFirstError}
        />
        <AppInput
          className="myInput"
          type="text"
          placeholder="Phone Number 2"
          label="Phone Number 2: "
          onChange={mobileSecondHandle}
          errorMessage={mobileSecondError}
        />
        <div className="">
          <p className="text-sm md:text-base">Type</p>
          <AppSelect
            defaultValue="Type"
            value={type}
            data={TypeUnionOrWard}
            onChange={typeHandle}
            errorMessage={typeError}
          />
        </div>
        <div className="flex justify-end col-span-2">
          <AppButton
            className="gradientBtn"
            text="Submit"
            isSubmitting={isSaveButtonLoading}
            onClick={saveUnion}
          />
        </div>
      </form>
    </div>
  );
}
