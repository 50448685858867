import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  adminResellingCancelOrderService,
  adminResellingReturnOrderService,
  adminResellingCompleteOrderService,
  adminResellingShippedOrderService,
  adminResellingConfirmOrderService,
} from "../../../../httpService/sales/resellingOrderServices";

import { RootState } from "../../../../store";
import { adminResellingGetOrderDetailsAction } from "../../../../store/actions/sales/resellingOrderActions";
import { AppModal } from "../../../common/AppModal";
import { AppButton } from "../../../common/AppButton";
import { TbCurrencyTaka } from "react-icons/tb";
import _moment from "moment";
import { AppLoader } from "../../../common/AppLoader";
import {
  GiveBackModel,
  PaymentMethods,
} from "../../../../models/auth/user/UserModels";
import { giveBack } from "../../../../httpService/user/userServices";
import { AlertColor } from "@mui/material/Alert";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { IoMdClose } from "react-icons/io";
import { AppInput } from "../../../common/AppInput";
import {
  AdminCompleteOrderRequest,
  AdminConfirmOrderRequest,
  AdminShippedOrderRequest,
  CancellOrderRequest,
  OrderDetailsAdminRequest,
  OrderProductCustomer,
  ReturnOrderRequest,
  ShippingStatus,
} from "../../../../models/order/resellingOrdersModels";

export const OrderStatuses = [
  { id: 1, name: "InProgress" },
  { id: 2, name: "Cancelled" },
  { id: 3, name: "Returned" },
  { id: 4, name: "Complete" },
];

export const PaymentStatuses = [
  { id: 1, name: "Paid" },
  { id: 2, name: "NotPaid" },
];

export const ShippingStatuses = [
  { id: 2, name: "Shipped" },
  { id: 3, name: "Delivered" },
];

export default function AdminResellingGetOrderDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [trackUrl, setTrackUrl] = useState("");
  const [trackUrlErrorMessage, setTrackUrlErrorMessage] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const orderDetails = useSelector((state: RootState) => {
    return state.adminResellingGetOrderDetailsResponse.data;
  });

  const orderProducts = useMemo(() => {
    return orderDetails.orderProductList as OrderProductCustomer[];
  }, [orderDetails]);

  const { orderId } = useParams();
  const { customerId } = useParams();

  var payload = new OrderDetailsAdminRequest();
  payload.orderId = orderId;
  payload.customerId = customerId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await dispatch(adminResellingGetOrderDetailsAction(payload));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch, orderId]);

  const handleBackToOrderList = () => {
    navigate("/myOrder");
  };

  const navigateToReviewRating = (item: any) => {
    navigate(`/productReview/${item?.productId}/${orderId}`);
  };

  const groupedProducts = orderProducts?.reduce(
    (result: any, product: OrderProductCustomer) => {
      const index = result.findIndex(
        (vendorProducts: any) => vendorProducts[0].vendorId === product.vendorId
      );
      if (index >= 0) {
        result[index].push(product);
      } else {
        result.push([product]);
      }
      return result;
    },
    []
  );

  const navigateToProductDetails = (id?: any) => {
    navigate(`/admin/catalog/product-details/${id}`);
  };

  const orderTimeFormatter = (createdDateTime: any) => {
    const createdTime = _moment(createdDateTime, "YYYY-MM-DD HH:mm:ss.SSSSSSS");
    return createdTime.format("MMM D, YY [at] h:mm A");
  };

  const [isShippingDetailsOpen, setIsShippingDetailsOpen] = useState(false);

  const handleShippingDetails = () => {
    setIsShippingDetailsOpen(!isShippingDetailsOpen);
  };

  const handleTrackUrl = (event: any) => {
    setTrackUrl(event);
    setTrackUrlErrorMessage("");
  };

  const [isOpenCalcellModal, setIsOpenCancellModal] = useState(false);

  const [isOpenReturnModal, setIsOpenReturnModal] = useState(false);
  const [isConfirmOrderModal, setIsConfirmOrderModal] = useState(false);
  const [isShippingOrderModal, setIsShippingOrderModal] = useState(false);
  const [isCompleteOrderModal, setIsCompleteOrderModal] = useState(false);

  const [productId, setProductId] = useState(0);
  const [vendorId, setVendorId] = useState(0);

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleCalcellOne = (item: any) => {
    setIsOpenCancellModal(true);
    setProductId(item.productId);
    setVendorId(item.vendorId);
  };

  const handleCalcellOneOrder = () => {
    var model = new CancellOrderRequest();
    model.orderId = orderId;
    model.productId = productId;
    model.isAllCancell = false;
    model.isAllCancellOfVendor = false;
    model.vendorId = vendorId;
    setIsButtonLoading(true);
    adminResellingCancelOrderService(model)
      .then(() => {
        setIsButtonLoading(false);
        setIsOpenCancellModal(false);
        dispatch(adminResellingGetOrderDetailsAction(payload));
      })
      .catch(() => {
        setIsButtonLoading(false);
      });
  };

  const handleCloseCalcellOneModal = () => {
    setIsOpenCancellModal(false);
  };

  const handleReturnOne = (item: any) => {
    setIsOpenReturnModal(true);
    setProductId(item.productId);
    setVendorId(item.vendorId);
  };

  const handleReturnOneOrder = () => {
    var model = new ReturnOrderRequest();
    model.orderId = orderId;
    model.productId = productId;
    model.isAllReturn = false;
    model.isAllReturnOfVendor = false;
    model.vendorId = vendorId;
    setIsButtonLoading(true);
    adminResellingReturnOrderService(model)
      .then(() => {
        setIsButtonLoading(false);
        setIsOpenReturnModal(false);
        dispatch(adminResellingGetOrderDetailsAction(payload));
      })
      .catch(() => {
        setIsButtonLoading(false);
      });
  };

  const handleCloseReturnOneModal = () => {
    setIsOpenReturnModal(false);
  };

  const handleShippingOrderModal = () => {
    setIsShippingOrderModal(true);
  };

  const closeShippingOrderModal = () => {
    setIsShippingOrderModal(false);
  };

  const handleShipped = () => {
    var model = new AdminShippedOrderRequest();
    model.orderId = orderId;
    model.shippingStatus = ShippingStatus.Shipped;
    model.trackUrl = trackUrl;
    setIsButtonLoading(true);
    adminResellingShippedOrderService(model)
      .then(() => {
        setIsButtonLoading(false);
        dispatch(adminResellingGetOrderDetailsAction(payload));
        closeShippingOrderModal();
      })
      .catch(() => {
        setIsButtonLoading(false);
      });
  };

  const handleConfirmOrderModal = () => {
    setIsConfirmOrderModal(true);
  };

  const closeConfirmOrderModal = () => {
    setIsConfirmOrderModal(false);
  };

  const handleConfirmOrder = () => {
    var model = new AdminConfirmOrderRequest();
    model.orderId = orderId;
    setIsButtonLoading(true);
    adminResellingConfirmOrderService(model)
      .then(() => {
        setIsButtonLoading(false);
        dispatch(adminResellingGetOrderDetailsAction(payload));
        closeConfirmOrderModal();
      })
      .catch(() => {
        setIsButtonLoading(false);
      });
  };

  const [isUnPaid, setIsUnPaid] = useState(false);

  const handleCompleteOrderModal = () => {
    setIsCompleteOrderModal(true);
  };

  const closeCompleteOrderModal = () => {
    setIsCompleteOrderModal(false);
    cancelFromMainBalance();
  };

  const [isConfirmFomMainBalance, setIsConfirmFromMainBalance] =
    useState(false);

  const confirmFromMainBalance = () => {
    setIsConfirmFromMainBalance(true);
    cancelFromBkash();
  };

  const cancelFromMainBalance = () => {
    setIsConfirmFromMainBalance(false);
  };

  const [isMainBalanceOkLoading, setIsMainBalanceOkLoading] = useState(false);

  const submitGiveBackFromMainBalance = () => {
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

    let isValidUrl = urlPattern.test(trackUrl);
    if (trackUrl !== "" && !isValidUrl) {
      setTrackUrlErrorMessage("Please Enter A Valid Url!");
      return;
    }

    var model = new GiveBackModel();
    model.amount = orderDetails?.vendorBackAmount as number;
    model.paymentMethods = PaymentMethods.MAINBALANCE;
    model.isPaymentSuccessful = true;
    setIsMainBalanceOkLoading(true);
    giveBack(model)
      .then(() => {
        handleShipped();
        setIsMainBalanceOkLoading(false);
      })
      .catch((error: any) => {
        setIsMainBalanceOkLoading(false);
      });
  };

  const [isConfirmFomBkash, setIsConfirmFromBkash] = useState(false);

  const cancelFromBkash = () => {
    setIsConfirmFromBkash(false);
  };

  const handleComplete = () => {
    var model = new AdminCompleteOrderRequest();
    model.orderId = orderId;
    setIsButtonLoading(true);
    adminResellingCompleteOrderService(model)
      .then((res: any) => {
        setIsButtonLoading(false);
        dispatch(adminResellingGetOrderDetailsAction(payload));
        closeCompleteOrderModal();

        setIsShowSnackBar(true);
        setSnackBarMessage("Give Back Successfully");
        setSnackBarMessageType("success");
        setTimeout(() => {
          setIsShowSnackBar(false);
        }, 2000);
      })
      .catch(() => {
        setIsButtonLoading(false);
      });
  };

  const mainBlace = orderDetails.vendorMainBlance;

  if (isLoading) {
    return (
      <div className="container mx-auto flex h-screen items-center justify-center px-2 pb-12 md:px-0">
        <AppLoader status={true} />
      </div>
    );
  }

  return (
    <div className="container mx-auto px-0 pb-8 md:px-16 md:py-4">
      {isShowSnackBar && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}

      {isUnPaid && (
        <AppModal>
          <div className="flex flex-col-reverse items-center justify-between gap-4 px-4 py-12 md:flex-row md:px-20">
            <div className="flex flex-col items-center gap-3 md:items-start">
              <h2 className="text-xl font-semibold text-royalBlue md:max-w-md md:text-3xl">
                Please Pay Your Give Back Money!
              </h2>
              <div
                className="myBtn w-fit border-royalBlue bg-royalBlue py-1.5"
                onClick={() => { }}
              >
                <Link to="/admin/sales/vendorwallet">Wallet</Link>
              </div>
            </div>
            <img
              className="h-40 w-60 object-contain md:h-48 md:w-72"
              src="https://res.cloudinary.com/ddqenwff0/image/upload/v1687276368/Group_cmdbqn.png"
              alt=""
            />
          </div>
        </AppModal>
      )}

      {isConfirmOrderModal && (
        <AppModal>
          <div className="flex max-w-md flex-col gap-4 p-4 text-center text-2xl">
            Are you sure you want to Confirm this order?
            <div className="flex items-center justify-center gap-4">
              <AppButton text="Yes" onClick={handleConfirmOrder} />
              <AppButton text="No" onClick={closeConfirmOrderModal} />
            </div>
          </div>
        </AppModal>
      )}

      {isShippingOrderModal && (
        <AppModal>
          <div className="flex max-w-md flex-col gap-4 px-4 py-6 text-center text-textBlack">
            <div className="flex items-center justify-end">
              <div className="cursor-pointer" onClick={closeShippingOrderModal}>
                <IoMdClose className="text-2xl font-medium" />
              </div>
            </div>
            <p className="text-lg font-medium">
              You have to pay {orderDetails?.vendorBackAmount} TK to ship this
              order.
            </p>
            <AppInput
              label=""
              className="myInput"
              type="url"
              placeholder="Enter Tracking Url"
              onChange={handleTrackUrl}
              errorMessage={trackUrlErrorMessage}
            />
            <div className="flex items-center justify-center gap-4 px-4 lg:px-12">
              <div
                className="cursor-pointer rounded border border-primary bg-white p-2 font-medium hover:bg-primary hover:text-white"
                onClick={confirmFromMainBalance}
              >
                <p>Main Balance</p>
                <p>{mainBlace}</p>
              </div>
            </div>
            <div className="">
              <div>
                {isConfirmFomMainBalance &&
                  orderDetails?.vendorBackAmount <=
                  orderDetails?.vendorMainBlance && (
                    <div className="mt-2 p-2 shadow-lg">
                      <p>Paying From Main Balance</p>
                      <div className="flex items-center justify-center gap-2 pt-1">
                        <AppButton text="No" onClick={cancelFromMainBalance} />
                        <AppButton
                          text="Yes"
                          isSubmitting={isMainBalanceOkLoading}
                          onClick={submitGiveBackFromMainBalance}
                        />
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </AppModal>
      )}

      {isCompleteOrderModal && (
        <AppModal>
          <div className="flex max-w-md flex-col gap-4 p-4 text-center text-2xl">
            Are you sure you want to Complete this order?
            <div className="flex items-center justify-center gap-4">
              <AppButton text="Yes" onClick={handleComplete} />
              <AppButton text="No" onClick={closeCompleteOrderModal} />
            </div>
          </div>
        </AppModal>
      )}

      {isOpenCalcellModal && (
        <AppModal>
          <div className="flex max-w-md flex-col gap-4 p-4 text-center text-2xl">
            Are you sure you want to cancel this product?
            <div className="flex items-center justify-center gap-4">
              <AppButton text="Yes" onClick={handleCalcellOneOrder} />
              <AppButton text="No" onClick={handleCloseCalcellOneModal} />
            </div>
          </div>
        </AppModal>
      )}

      {isOpenReturnModal && (
        <AppModal>
          <div className="flex max-w-md flex-col gap-4 p-4 text-center text-2xl">
            Are you sure you want to return this product?
            <div className="flex items-center justify-center gap-4">
              <AppButton text="Yes" onClick={handleReturnOneOrder} />
              <AppButton text="No" onClick={handleCloseReturnOneModal} />
            </div>
          </div>
        </AppModal>
      )}

      <div className="flex flex-col items-start pb-2 lg:flex-row lg:items-center lg:justify-between">
        <div className="flex w-full items-center justify-between py-2 md:py-4 lg:w-3/5">
          <h3 className="subTitle">Order details</h3>
          <h3 className="subTitle">
            Payment Status:{" "}
            <span
              className={`${orderDetails.isOrderTotalPaid ? "paided" : "unpaid"
                }`}
            >
              {orderDetails?.isOrderTotalPaid ? `Paid` : `Cash On Delivery`}
            </span>{" "}
          </h3>
        </div>
        <div className="flow-row col-span-2 flex justify-between gap-2 lg:flex-col">
          {isShippingDetailsOpen || (
            <AppButton
              className="myBtn text-xs lg:text-sm"
              text="See Shipping Details"
              onClick={handleShippingDetails}
            />
          )}
        </div>
      </div>

      {isShippingDetailsOpen && (
        <div>
          <AppButton
            text="Close Shipping details"
            className="myBtn w-fit text-xs lg:text-sm"
            onClick={handleShippingDetails}
          />
          {/* these is shipping details and delivery div  */}
          <div className="flex flex-col items-center gap-4 py-4 md:flex-row md:gap-8">
            {/* these is shipping details  */}
            <div className="myBorder grid w-full grid-cols-2 space-y-1 px-2 py-2 text-xs font-medium md:w-1/2 lg:px-4 lg:text-base">
              <p>
                Customer Name:<span> {orderDetails?.customerName}</span>{" "}
              </p>
              <p>
                Address Line 1:
                <span> {orderDetails?.shippingAddress?.addressLine1}</span>{" "}
              </p>
              <p>
                Address Line 2:
                <span> {orderDetails?.shippingAddress?.addressLine2} </span>
              </p>
              <p>
                Division:
                <span> {orderDetails?.shippingAddress?.divisionName} </span>
              </p>
              <p>
                District:{" "}
                <span>{orderDetails?.shippingAddress?.districtName}</span>
              </p>
              <p>
                Area: <span>{orderDetails?.shippingAddress?.areaName}</span>
              </p>
              <p>
                Phone: <span>{orderDetails?.shippingAddress?.phoneNo1}</span>
              </p>
              <p>
                Reseller Phone:{" "}
                <span>
                  {orderDetails?.shippingAddress?.resellerPhoneNumber}
                </span>
              </p>
              <div className="flex items-center">
                <p>Delivery Charge</p>
                <p className="flex items-center">
                  <TbCurrencyTaka /> {orderDetails?.deliveryCharge}
                </p>
              </div>
              <p>
                Delivery Status: <span className={`paided`}>Paid</span>
              </p>
            </div>
          </div>
        </div>
      )}

      {groupedProducts?.map((vendorProducts: any, index: any) => (
        <section key={index} className="">
          {/* these is shop info  */}
          <div className="myBorder mb-2 grid grid-cols-3 items-center justify-between px-2 py-2 shadow hover:shadow-md lg:flex lg:px-4 2xl:p-3">
            <h3 className="col-span-2 text-sm lg:text-base 2xl:text-lg">
              Sold by:{" "}
              <span className="font-medium">
                {vendorProducts[0].vendorName}
              </span>{" "}
            </h3>
            <h3 className="text-sm lg:text-base 2xl:text-lg">
              Order id: #
              <span className="font-medium">{orderDetails.orderId}</span>{" "}
            </h3>
            <h3 className="col-span-3 text-sm lg:text-base 2xl:text-lg">
              Order date:{" "}
              <span className="text-sm font-medium text-gray-600">
                {orderTimeFormatter(orderDetails?.orderDateTime)}
              </span>{" "}
            </h3>
          </div>

          <div className="mx-auto flex max-w-2xl flex-col items-center justify-center gap-4 py-4 md:pb-0 md:pt-4">
            {vendorProducts?.filter((_: any) => _.orderStatus === "Cancelled")
              ?.length === vendorProducts?.length ? (
              <div className="Cancelled  px-1 py-0.5 text-sm font-normal lg:px-4 lg:py-1 lg:text-base">
                Cancelled
              </div>
            ) : vendorProducts?.filter((_: any) => _.orderStatus === "Complete")
              ?.length > 0 ? (
              <div className="myBtn select-none">Completed</div>
            ) : (
              <>
                {vendorProducts.filter((_: any) => _.orderStatus === "Returned")
                  ?.length != vendorProducts?.length ? (
                  <div className="flex items-center justify-center gap-3 lg:gap-4">
                    {vendorProducts.filter(
                      (_: any) =>
                        _.orderStatus === "InProgress" ||
                        _.orderStatus === "Confirm"
                    )?.length ? (
                      <div className="myBtn select-none px-2 lg:px-4">
                        Pending
                      </div>
                    ) : (
                      <div className="outlineBtn select-none px-2 lg:px-4">
                        Pending
                      </div>
                    )}

                    {vendorProducts.filter(
                      (_: any) =>
                        _.orderStatus === "Confirm" ||
                        _.orderStatus === "Complete"
                    )?.length ? (
                      <div className="myBtn select-none px-2 lg:px-4">
                        Confirmed
                      </div>
                    ) : (
                      <AppButton
                        isSubmitting={isButtonLoading}
                        onClick={handleConfirmOrderModal}
                        text="Confirm"
                        className="outlineBtn select-none px-2 lg:px-4"
                      />
                    )}

                    {vendorProducts.filter(
                      (_: any) => _.orderStatus === "Confirm"
                    )?.length ? (
                      <>
                        {vendorProducts.filter(
                          (_: any) => _.shippingStatus === "Shipped"
                        )?.length ? (
                          <div className="myBtn select-none px-2 lg:px-4">
                            Delivered
                          </div>
                        ) : (
                          <AppButton
                            isSubmitting={isButtonLoading}
                            onClick={handleShippingOrderModal}
                            text="Delivery"
                            className="outlineBtn select-none px-2 lg:px-4"
                          />
                        )}
                      </>
                    ) : (
                      <></>
                    )}

                    {vendorProducts.filter(
                      (_: any) => _.shippingStatus === "Shipped"
                    )?.length ? (
                      <div className="flex items-center justify-center gap-4">
                        {vendorProducts.filter(
                          (_: any) => _.orderStatus === "Complete"
                        )?.length ? (
                          <>
                            <div className="myBtn select-none">Completed</div>
                          </>
                        ) : (
                          <>
                            {vendorProducts.filter(
                              (_: any) => _.orderStatus === "Returned"
                            )?.length &&
                              vendorProducts.filter(
                                (_: any) => _.orderStatus === "Confirm"
                              )?.length <= 0 ? (
                              <>
                                <div className="ModeratorEarning  px-1 py-0.5 text-sm font-normal lg:px-4 lg:py-1 lg:text-base">
                                  Returned
                                </div>
                              </>
                            ) : (
                              <>
                                <AppButton
                                  isSubmitting={isButtonLoading}
                                  onClick={handleCompleteOrderModal}
                                  text="Complete"
                                  className="outlineBtn select-none px-2 lg:px-4"
                                />
                              </>
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
          
          {vendorProducts?.filter((_: any) => _.trackUrl?.length > 0)?.length >
            0 && (
              <div className="my-3 flex items-center justify-center font-medium">
                <a
                  target="_blank"
                  href={vendorProducts[0].trackUrl}
                  rel="noopener noreferrer"
                  className="text-decoration-underline text-blue-600"
                >
                  <u>Track Shipment</u>
                </a>
              </div>
            )}
            
          <div className="flex items-center justify-between font-medium">
            <div className="flex items-center text-sm lg:gap-1 lg:text-base">
              <p>Total Product Price</p>
              <p className="flex items-center">
                {" "}
                <TbCurrencyTaka />
                {orderDetails?.totalCost as number}
              </p>
            </div>

            <div className="flex items-center text-sm lg:gap-1 lg:text-base">
              <p>Total Give Back</p>
              <p className="flex items-center">
                {" "}
                <TbCurrencyTaka />
                {orderDetails?.vendorBackAmount}
              </p>
            </div>
          </div>
          <h3 className="">
            {orderDetails?.isOrderTotalPaid ? (
              <span className="font-bold italic text-green-500">Full Paid</span>
            ) : (
              <span className="font-bold italic text-red">
                Cash on delivery
              </span>
            )}
          </h3>
          <ul>
            {vendorProducts?.map((item: any, indx: any) => (
              <div
                className="myBorder my-2 grid grid-cols-1 px-1 py-1 lg:grid-cols-3 lg:px-2 lg:py-0"
                key={indx}
              >
                {/* product details section here  */}
                <div className="flex items-center justify-start gap-x-1 md:gap-x-4">
                  {/* these is product details image section  */}
                  <Link to={`/admin/catalog/product-details/${item.productId}`}>
                    <div className="cursor-pointer rounded py-1">
                      <img
                        className="h-12 w-16 rounded object-cover md:h-12 md:w-16"
                        src={item?.bannerPath}
                        alt="These is product pic"
                      />
                    </div>
                  </Link>
                  {/* this is product details details section  */}
                  <div className="text-sm font-normal md:text-base md:font-medium">
                    <Link
                      to={`/admin/catalog/product-details/${item.productId}`}
                    >
                      <h3
                        className="cursor-pointer "
                        onClick={() => navigateToProductDetails(item.productId)}
                      >
                        {item?.productName}
                      </h3>
                    </Link>
                    {item?.attributes && (
                      <div className="">
                        {item?.attributes?.map((attribute: any, x: any) =>
                          attribute?.name === "Color" ? (
                            <div key={x}>
                              <p>{attribute?.name}:</p>
                              <div
                                style={{ background: attribute?.value }}
                              ></div>
                            </div>
                          ) : (
                            <div className="flex items-center justify-between">
                              <div
                                key={x}
                                className="flex items-center gap-x-[2px] md:gap-x-1"
                              >
                                <p>{attribute?.name}:</p>
                                <div>{attribute?.value}</div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {/* this is price and quantity section  */}
                <div className="flex items-center justify-between gap-2 lg:gap-10 lg:pl-2">
                  <p className="flex items-center gap-1">
                    {" "}
                    Price:{" "}
                    <span className="flex items-center font-normal md:font-medium">
                      {" "}
                      <TbCurrencyTaka /> {item?.unitPrice}
                    </span>
                  </p>
                  <div className="flex gap-1 text-sm md:text-base">
                    {" "}
                    Qtn:{" "}
                    <span className=" font-normal md:font-medium">
                      {item?.quantity}
                    </span>{" "}
                  </div>
                  <div className="flex items-center gap-1 text-sm md:text-base">
                    Total price:{" "}
                    <span className="flex items-center font-normal md:font-medium">
                      {" "}
                      <TbCurrencyTaka /> {item?.totalPrice}
                    </span>
                  </div>
                </div>
                {/* these is buttons  */}
                <div className="flex items-center justify-center gap-2 pt-1 md:gap-4 lg:justify-end lg:pt-0">
                  {item?.shippingStatus === "InProgress" &&
                    item?.orderStatus !== "Cancelled" && (
                      <AppButton
                        isSubmitting={isButtonLoading}
                        className="outlineBtn border-red px-2 text-red hover:bg-red lg:px-4"
                        text="Cancel"
                        onClick={() => {
                          handleCalcellOne(item);
                        }}
                      />
                    )}
                  {item?.orderStatus === "Cancelled" && (
                    <div className="Cancelled  px-1 py-0.5 text-sm font-normal lg:px-4 lg:py-1 lg:text-base">
                      Cancelled
                    </div>
                  )}
                  {item?.shippingStatus === "Shipped" &&
                    item?.orderStatus !== "Complete" &&
                    item?.orderStatus !== "Cancelled" &&
                    item?.orderStatus !== "Returned" ? (
                    <>
                      <AppButton
                        isSubmitting={isButtonLoading}
                        className="outlineBtn border-red px-2 text-red hover:bg-red lg:px-4"
                        text="Return"
                        onClick={() => {
                          handleReturnOne(item);
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {item?.orderStatus === "Returned" ? (
                    <>
                      <div className="ModeratorEarning  px-1 py-0.5 text-sm font-normal lg:px-4 lg:py-1 lg:text-base">
                        Returned
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {item?.shippingStatus === "Shipped" &&
                    item?.orderStatus === "Complete" ? (
                    <>
                      <AppButton
                        isSubmitting={isButtonLoading}
                        className="myBtn px-2 lg:px-4"
                        text="Completed"
                        onClick={() => { }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ))}
          </ul>
        </section>
      ))}
    </div>
  );
}
