import { AlertColor } from "@mui/material/Alert";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { EarningType, GetDataOfferVendorWalletResponse, GetVendorPointRequest, GetVendorPointResponse, GetVendorPointWithdrawHistoryRequest, GetVendorPointWithdrawHistoryResponse, GetVendorPullHistoryResponse, GetVendorWalletRequest, GetVendorWalletResponse, GetWalletRechargeHistory, UserPullAmountRequest } from "../../../../models/auth/user/UserPointModels";
import { getDataOfferVendorPullHistoryAction, getDataOfferVendorWalletAction, getUserProfileAction, getDataOfferVendorWithdrawHistoryAction, getDataOfferVendorPointListAction } from "../../../../store/actions/auth/user/userActions";
import { AppLoader } from "../../../common/AppLoader";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { AppModal } from "../../../common/AppModal";
import { IoMdClose } from "react-icons/io";
import { AppInput } from "../../../common/AppInput";
import { AppSelect } from "../../../common/AppSelect";
import { withdrawDataOfferVendorPointRequestService, dataOfferVendorPullAmountService } from "../../../../httpService/user/userServices";
import { WithdrawRequest } from "../../../../models/auth/user/UserModels";
import { AppButton } from "../../../common/AppButton";
import _moment from "moment";

const paymentMethods = [
  { id: 1, name: "Bkash" },
  { id: 2, name: "Nagad" },
  { id: 3, name: "Rocket" },
  { id: 4, name: "Bank" },
];

export default function DataOfferVendorWallet() {

  const dispatch = useDispatch();


  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);

  const openWithdrawModal = () => {
    setIsWithdrawModalOpen(true);
  };

  const closeWithdrawModal = () => {
    setRequestAmount(0);
    setPaymentMethod(undefined);
    setPaymentNumber("");
    setNote(undefined);

    setRequestAmountError("");
    setPaymentMethodError("");
    setPaymentNumberError("");
    setNoteError("");
    setIsWithdrawModalOpen(false);
  };

  const [requestAmount, setRequestAmount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState();
  const [paymentNumber, setPaymentNumber] = useState("");
  const [note, setNote] = useState();

  const [requestAmountError, setRequestAmountError] = useState("");
  const [paymentMethodError, setPaymentMethodError] = useState("");
  const [paymentNumberError, setPaymentNumberError] = useState("");
  const [noteError, setNoteError] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleRequestAmount = (value: any) => {
    setRequestAmountError("");
    setRequestAmount(value);
  };

  const handlePaymentMethod = (value: any) => {
    setPaymentMethodError("");
    setPaymentNumberError("");
    setPaymentMethod(value);
    if (value === 1) {
      setPaymentNumber(userProfile?.bkashNumber);
    } if (value === 2) {
      setPaymentNumber(userProfile?.nagadNumber ? userProfile.nagadNumber : "");
    } if (value === 3) {
      setPaymentNumber(userProfile?.rocketNumber ? userProfile?.rocketNumber : "");
    } if (value === 4) {
      setPaymentNumber(userProfile?.bankAccountNumber ? userProfile?.bankAccountNumber : "");
    } if (value === 0) {
      setPaymentNumber("");
    }
  };

  const handleNote = (value: any) => {
    setNoteError("");
    if (value.length > 200) {
      setNoteError("Note must be less than 200 characters");
    }
    setNote(value);
  };

  const [isWithdrawButtonLoading, setIsWithdrawButtonLoading] = useState(false);

  const handleSubmitWithdrawRequest = () => {
    var model = new WithdrawRequest();
    model.requestAmount = requestAmount;
    model.paymentMethod = paymentMethod;
    model.paymentNumber = paymentNumber;
    model.note = note;
    setIsWithdrawButtonLoading(true);
    withdrawDataOfferVendorPointRequestService(model)
      .then(() => {
        closeWithdrawModal();
        var wlt = new GetVendorWalletRequest();
        wlt.userId = 0;
        dispatch(getDataOfferVendorWalletAction(wlt));
        dispatch(getDataOfferVendorWithdrawHistoryAction(withdrawlHistryModel));
        setIsWithdrawButtonLoading(false);
      })
      .catch((error: any) => {
        setPaymentNumberError(error.response.data.errors?.PaymentMethod?.[0]);
        setRequestAmountError(error.response.data.errors?.RequestAmount?.[0]);
        setIsWithdrawButtonLoading(false);
      });
  };

  const [isPullModalOpen, setIsPullModalOpen] = useState(false);
  const [pullModalTitle, setPullModalTitle] = useState("");
  const [accounttype, setAccountType] = useState(0);
  const [pullAmount, setPullAmount] = useState(0);
  const [pullAmountError, setPullAmountError] = useState("");

  const handlePullAmount = (value: any) => {
    setPullAmount(value);
    setPullAmountError("");
  };

  const openPullModal = (type: any) => {
    setIsPullModalOpen(true);
    setPullModalTitle("Pull Advance Money");
    setAccountType(EarningType.AdvancedPaymentEarning);
  };

  const closePullModal = () => {
    setIsPullModalOpen(false);
  };

  const [isPullButtonLoading, setIsPullButtonLoading] = useState(false);

  const handlePullRequest = (value: any) => {
    var pullmodel = new UserPullAmountRequest();
    pullmodel.amount = pullAmount;
    pullmodel.earningType = accounttype;
    setIsPullButtonLoading(true);
    dataOfferVendorPullAmountService(pullmodel)
      .then(() => {
        var wlt = new GetVendorWalletRequest();
        wlt.userId = 0;
        dispatch(getDataOfferVendorWalletAction(wlt));
        dispatch(getDataOfferVendorPullHistoryAction());
        setIsPullModalOpen(false);
        setIsPullButtonLoading(false);
      })
      .catch((error: any) => {
        setPullAmountError(error.response.data.errors.Amount[0]);
        setIsPullButtonLoading(false);
      });
  };

  const userProfile = useSelector((state: RootState) => {
    return state.getUserProfileResponse.data;
  });

  const wallet = useSelector((state: RootState) => {
    return state.getDatOfferVendorWalletResponse.data as GetDataOfferVendorWalletResponse;
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        var wlt = new GetVendorWalletRequest();
        wlt.userId = 0;
        await dispatch(getDataOfferVendorWalletAction(wlt));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    var model = new GetVendorPointRequest();
    model.userId = 0;
    model.searchQuery = searchQuery;
    model.earningStatus = earningStatus;
    model.earningType = earningType;
    dispatch(getUserProfileAction());

    dispatch(getDataOfferVendorPointListAction(model));
  }, [dispatch]);

  const pointList = useSelector((state: RootState) => {
    return state.getDataOfferVendorPointListResponse.data as GetVendorPointResponse[];
  });

  const [pointStatus, setPointStatus] = useState(0);

  const withdrawList = useSelector((state: RootState) => {
    return state.getDatOfferVendorWithdrawHistoryResponse.data as GetVendorPullHistoryResponse[];
  });

  var withdrawlHistryModel = new GetVendorPointWithdrawHistoryRequest();
  withdrawlHistryModel.userId = 0;
  withdrawlHistryModel.pointStatus = pointStatus;

  useEffect(() => {
    dispatch(getDataOfferVendorWithdrawHistoryAction(withdrawlHistryModel));
  }, [dispatch]);

  const pullList = useSelector((state: RootState) => {
    return state.getDataOfferVendorPullHistoryResponse.data as GetVendorPullHistoryResponse[];
  });

  useEffect(() => {
    dispatch(getDataOfferVendorPullHistoryAction());
  }, [dispatch]);

  const [searchQuery, setSearchQuery] = useState("");

  const [earningType, setEarningType] = useState(0);
  const [earningStatus, setEarningStatus] = useState(0);

  const orderTimeFormatter = (createdDateTime: any) => {
    const createdTime = _moment(createdDateTime, "YYYY-MM-DD HH:mm:ss.SSSSSSS");
    return createdTime.format("MMM D, YY [at] h:mm A");
  };

  const [isEarningHistry, setIsEarningHistry] = useState(true);
  const [isWithdrawHistry, setIsWithdrawHistry] = useState(false);
  const [isPullHistry, setIsPullHistry] = useState(false);
  const [isRechargeHistry, setIsRechargeHistry] = useState(false);
  const [isSelected, setIsSelected] = useState("");

  const openEaringHistry = () => {
    var model = new GetVendorPointRequest();
    model.userId = 0;
    model.searchQuery = searchQuery;
    model.earningStatus = earningStatus;
    model.earningType = earningType;
    dispatch(getDataOfferVendorPointListAction(model));
    setIsSelected("EaringHistry");
    setIsEarningHistry(true);
    setIsWithdrawHistry(false);
    setIsPullHistry(false);
    setIsRechargeHistry(false);
  };

  const openWithdrawHistry = () => {
    dispatch(getDataOfferVendorWithdrawHistoryAction(withdrawlHistryModel))
    setIsSelected("WithdrawHistry");
    setIsEarningHistry(false);
    setIsWithdrawHistry(true);
    setIsPullHistry(false);
    setIsRechargeHistry(false);
  };

  const openPullHistry = () => {
    dispatch(getDataOfferVendorPullHistoryAction());
    setIsSelected("PullHistry");
    setIsEarningHistry(false);
    setIsWithdrawHistry(false);
    setIsPullHistry(true);
    setIsRechargeHistry(false);
  };

  if (isLoading) {
    return (
      <div className="container h-screen flex items-center justify-center mx-auto px-2 md:px-0 pb-12">
        <AppLoader status={true} />
      </div>
    );
  }

  return (
    <>
      {isShowSnackBar && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      {isWithdrawModalOpen && (
        <AppModal>
          <div className="p-2 md:w-96">
            <div className="flex justify-end">
              <IoMdClose
                className="cursor-pointer text-2xl font-semibold text-primary"
                onClick={closeWithdrawModal}
              />
            </div>
            <div className="flex flex-col gap-1 px-4 py-2">
              <AppInput
                type="number"
                className="myInput"
                label="Amount"
                value={requestAmount}
                placeholder="Enter Amount"
                onChange={(value: any) => handleRequestAmount(value)}
                errorMessage={requestAmountError}
              />
              <div>
                <p>Payment Method</p>
                <AppSelect
                  defaultValue="Select Payment Method"
                  value={paymentMethod}
                  data={paymentMethods}
                  placeholder="Select Payment Method"
                  onChange={(value: any) => handlePaymentMethod(value)}
                  errorMessage={paymentMethodError}
                />
              </div>

              <AppInput
                type="text"
                className="myInput"
                label="Payment Number"
                value={paymentNumber}
                isDisabled={true}
                errorMessage={paymentNumberError}
              />

              <AppInput
                type="text"
                className="myInput"
                label="Note"
                value={note}
                placeholder="Enter Note"
                onChange={(value: any) => handleNote(value)}
                errorMessage={noteError}
              />
            </div>

            <div className="mb-2 flex justify-end">
              <AppButton text="Submit" isSubmitting={isWithdrawButtonLoading} onClick={handleSubmitWithdrawRequest} />
            </div>
          </div>
        </AppModal>
      )}

      {isPullModalOpen && (
        <>
          <AppModal>
            <div className="p-2 md:w-96">
              <div className="flex justify-end">
                <IoMdClose
                  className="cursor-pointer text-2xl font-semibold text-primary"
                  onClick={closePullModal}
                />
              </div>
              <div className="flex flex-col space-y-2 px-4 py-2">
                <h1 className="text-lg font-medium">{pullModalTitle}</h1>
                <AppInput
                  type="number"
                  label="Amount"
                  className="myInput"
                  value={note}
                  placeholder="Enter Amount"
                  onChange={(value: any) => handlePullAmount(value)}
                  errorMessage={pullAmountError}
                />
              </div>
              <div className="flex justify-end px-4">
                <AppButton text="Submit" isSubmitting={isPullButtonLoading} onClick={handlePullRequest} />
              </div>
            </div>
          </AppModal>
        </>
      )}

      <div className="mx-auto max-w-6xl pb-4">
        <h2 className="title pb-1 lg:pb-4">Vendor wallet</h2>
        {/* this is first div  */}
        <div className="grid grid-cols-2 lg:grid-cols-3 flex-col lg:flex-row items-center gap-2 lg:gap-4 w-full">
          {/* this is Main balance of wallet  */}
          <div className="h-24 lg:h-36 px-2 lg:px-2 py-2 rounded lg:rounded lg:space-y-2 bg-gradient-to-r from-emerald-500 via-emerald-400 to-emerald-300 shadow-md">
            <p className="font-medium text-white text-center text-base lg:text-2xl">Main Balance</p>
            <div className="flex items-center justify-center">
              <img className="w-5 lg:w-8 h-5 lg:h-8 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka-icon_s8y7yy.png" alt="" />
              <h3 className="text-lg lg:text-3xl font-semibold text-white">{wallet.mainBalance}</h3>
            </div>
            <div className="flex justify-between pt-1">
              <div className="flex items-center justify-center">
                <img className="w-3 lg:w-4 h-3 lg:h-4 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka-icon_s8y7yy.png" alt="" />
                <h3 className="text-xsm lg:text-base font-medium lg:font-semibold text-white">{wallet.requestedAmount} Requested</h3>
              </div>
              <AppButton
                className="rounded text-xs lg:text-base bg-white px-1 lg:px-4 py-0 lg:py-1 font-medium text-primary lg:shadow-lg hover:shadow-xl border-grey"
                text="Withdraw"
                onClick={openWithdrawModal}
              />
            </div>
          </div>

          <div className="h-24 lg:h-36 p-1 lg:p-4 rounded lg:rounded lg:space-y-2.5 bg-white myShadow border-b-2 lg:border-b-4 border-b-royalBlue">
            <p className="font-medium text-center text-textBlack text-base lg:text-xl">Advance Income</p>
            <div className="flex items-center justify-center">
              <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_4_grdgmx.png" alt="" />
              <h3 className="text-base lg:text-xl font-semibold text-royalBlue">{wallet?.advanceIncome}</h3>
            </div>
            <div className="flex justify-center items-center mx-2 pt-1">
              <AppButton
                text="Pull"
                className="text-sm lg:text-base rounded lg:rounded bg-white px-4 py-0.5 lg:py-1 text-center font-medium text-royalBlue myShadow hover:shadow-xl border border-grey "
                onClick={() => {
                  openPullModal(EarningType.AdvancedPaymentEarning);
                }}
              />
            </div>
          </div>
          {/* this is Total Reselling Earning of wallet  */}
          <div className="h-24 lg:h-36 px-2 lg:px-3 py-2 lg:py-4 rounded lg:rounded space-y-1 lg:space-y-2 bg-white myShadow border-b-2 lg:border-b-4 border-b-red">
            <div className="flex items-center justify-between">
              <p className="font-medium text-textBlack text-sm lg:text-lg">Total Withdraw</p>
              <div className="flex items-center">
                <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_2_j2npjs.png" alt="" />
                <h3 className="text-base lg:text-xl font-semibold text-red">{wallet.totalWithdrawAmount}</h3>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <p className="font-medium text-textBlack text-sm lg:text-lg">Total Advance</p>
              <div className="flex items-center">
                <img className="w-4 lg:w-5 h-4 lg:h-5 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/taka_2_j2npjs.png" alt="" />
                <h3 className="text-base lg:text-xl font-semibold text-red">{wallet.totalAdvanceIncome}</h3>
              </div>
            </div>
          </div>
        </div>

        <div className="lg:myBorder flex items-center justify-between lg:justify-center gap-1 lg:gap-4 lg:rounded px-0 lg:px-2 py-0 lg:py-2.5 md:gap-6 my-2 lg:my-4">
          <AppButton
            className={`${isSelected === "EaringHistry" || isSelected === ""
              ? "myBtn text-xxs lg:text-base px-0.5 lg:px-4 py-0 lg:py-1"
              : "outlineBtn text-xxs lg:text-base px-0.5 lg:px-4 py-0 lg:py-1"
              }`}
            text="Earning History"
            onClick={openEaringHistry}
          />
          <AppButton
            className={`${isSelected === "WithdrawHistry"
              ? "myBtn text-xxs lg:text-base px-0.5 lg:px-4 py-0 lg:py-1"
              : "outlineBtn text-xxs lg:text-base px-0.5 lg:px-4 py-0 lg:py-1"
              }`}
            text="Withdraw History"
            onClick={openWithdrawHistry}
          />
          <AppButton
            className={`${isSelected === "PullHistry"
              ? "myBtn text-xxs lg:text-base px-0.5 lg:px-4 py-0 lg:py-1"
              : "outlineBtn text-xxs lg:text-base px-0.5 lg:px-4 py-0 lg:py-1"
              }`}
            text="Pull History"
            onClick={openPullHistry}
          />

        </div>
        {/* this is earning history  */}
        {isEarningHistry && (
          <div className="flex w-full bg-primary rounded lg:rounded">
            <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[20%]">Earning Amount</div>
            <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[19.8%]">Date & Time</div>
            <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[19.8%]">Earning Type</div>
            <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[19.9%]">Earning Status</div>
            <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 w-[20%]">Earned From</div>
          </div>
        )}

        {isEarningHistry && <div className="flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-0.5 lg:gap-1 py-0.5 lg:py-1">
          {isEarningHistry &&
            pointList?.map((item: GetVendorPointResponse) => {
              return (
                <div key={item?.id} className="myBorder flex w-full items-center">
                  <div className="centerDiv text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 border-r border-grey h-full w-[20%]">৳ {item?.earnedAmount}</div>
                  <div className="centerDiv text-xxs lg:text-sm px-0.5 lg:px-4 py-0.5 lg:py-1 border-r border-grey h-full w-[20%]">{orderTimeFormatter(item.createdAt)}</div>
                  <div className="centerDiv text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 border-r border-grey h-full w-[20%]">
                    <span
                      className={
                        item.earningType === "CourseEarning"
                          ? "CourseEarning"
                          : "" || item.earningType === "ResellerEarning"
                            ? "ResellerEarning"
                            : "" || item.earningType === "RefferEarning"
                              ? "RefferEarning"
                              : "" || item.earningType === "ModeratorEarning"
                                ? "ModeratorEarning"
                                : "" || item.earningType === "ProductEarning"
                                  ? "ProductEarning"
                                  : "" || item.earningType === "ShippingCost"
                                    ? "ShippingCost"
                                    : "" || item.earningType === "AdvancedPaymentEarning"
                                      ? "AdvancedPaymentEarning"
                                      : ""
                      }
                    >
                      {item.earningType === "AdvancedPaymentEarning" ? "AdvancePayment" : item.earningType}
                    </span>
                  </div>
                  <div className="centerDiv border-r border-grey py-1 h-full w-[20%]">
                    <span
                      className={
                        item.earningStatus === "InProgress"
                          ? "ProgressIn"
                          : "" || item.earningStatus === "Denied"
                            ? "Denied"
                            : "" || item.earningStatus === "Earned"
                              ? "Earned"
                              : ""
                      }
                    >
                      {item.earningStatus}
                    </span>
                  </div>
                  <div className="centerDiv text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[20%]">{item?.earnedFrom}</div>
                </div>
              );
            })}
        </div>}

        {/* this is withdraw history  */}
        {isWithdrawHistry && (
          <div className="flex w-full bg-primary  rounded lg:rounded">
            <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[33.33%]">Withdraw Amount</div>
            <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[33.33%]">Date & Time</div>
            <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 w-[33.33%]">Withdraw status</div>
          </div>
        )}
        {isWithdrawHistry && <div className="flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-0.5 lg:gap-1 py-0.5 lg:py-1">
          {isWithdrawHistry &&
            withdrawList?.map((item: GetVendorPointWithdrawHistoryResponse) => {
              return (
                <div key={item?.id} className="myBorder flex w-full items-center">
                  <div className="centerDiv text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 border-r border-grey h-full w-[33.33%]">৳ {item.requestedAmount}</div>
                  <div className="centerDiv text-xxs lg:text-sm px-0.5 lg:px-4 py-0.5 lg:py-1 border-r border-grey h-full w-[33.33%]">{orderTimeFormatter(item.requestDateTime)}</div>
                  <div className="centerDiv text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[33.33%]">
                    <span
                      className={
                        item.pointStatus === "InProgress" ? "ProgressIn" : "" ||
                          item.pointStatus === "Cancelled" ? "Denied" : "" ||
                            item.pointStatus === "Received" ? "Earned" : ""
                      }
                    >
                      {item.pointStatus}
                    </span>
                  </div>
                </div>
              );
            })}
        </div>}


        {/* this is pull history  */}
        {isPullHistry && (
          <div className="flex w-full bg-primary  rounded lg:rounded">
            <div className="centerDiv text-white font-medium  text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[50%]">Pull Amount</div>
            <div className="centerDiv text-white font-medium  text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[50%]">Date & Time</div>
            {/* <div className="centerDiv text-white font-medium py-2.5 w-[33.33%]">Pull Type</div> */}
          </div>
        )}
        {isPullHistry && <div className="flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-0.5 lg:gap-1 py-0.5 lg:py-1">
          {isPullHistry &&
            pullList?.map((item: any) => {
              return (
                <div key={item?.id} className="myBorder flex w-full items-center">
                  <div className="centerDiv border-r border-grey  text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[50%]">৳ {item?.pulledAmount}</div>
                  <div className="centerDiv border-r border-grey  text-xxs lg:text-sm px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[50%]">{orderTimeFormatter(item.dateTime)}</div>
                  {/* <div className="centerDiv py-1 h-full w-[33.33%]">
                      <span className={item.earningType === "CourseEarning" ? "CourseEarning" : "" || item.earningType === "ResellerEarning" ? "ResellerEarning" : "" || item.earningType === "RefferEarning" ? "RefferEarning" : "" || item.earningType === "ModeratorEarning" ? "ModeratorEarning" : "" || item.earningType === "ProductEarning" ? "ProductEarning" : ""}>{item.earningType}</span>
                    </div> */}
                </div>
              );
            })}
        </div>}

        {/* this is recharge history  */}
        {isRechargeHistry && (
          <div className="flex w-full bg-primary  rounded lg:rounded">
            <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[25%]">Recharge Amount</div>
            <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[25%]">Date & Time</div>
            <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[25%]">Payment Method</div>
            <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 w-[25%]">Recharge status</div>
          </div>
        )}
      </div>
    </>
  );
}