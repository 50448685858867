import {
	getAdminDashboardService,
	getRiderDashboardService,
	getUpAdminDashboardService,
	getVendorDashboardService,
	getVendorDashboardServiceById,
} from "../../../httpService/dashboard/dashboardServices";
import {
	GET_ADMIN_DASHBOARD,
	GET_RIDER_DASHBOARD,
	GET_UP_ADMIN_DASHBOARD,
	GET_VENDOR_DASHBOARD,
} from "../../constants/dashboard/dashboardConstant";

export const getAdminDashboardAction = () => async (dispatch: any) => {
	try {
		var data = getAdminDashboardService();
		const pload = (await data).data;
		dispatch({ type: GET_ADMIN_DASHBOARD, payload: pload });
	} catch (error) { }
};

export const getVendorDashboardAction = () => async (dispatch: any) => {
	try {
		var data = getVendorDashboardService();
		const pload = (await data).data;
		dispatch({ type: GET_VENDOR_DASHBOARD, payload: pload });
	} catch (error) { }
};

export const getVendorDashboardByIdAction = (id: any) => async (dispatch: any) => {
	try {
		var data = getVendorDashboardServiceById(id);
		const pload = (await data).data;
		dispatch({ type: GET_VENDOR_DASHBOARD, payload: pload });
	} catch (error) { }
};

export const getUpAdminDashboardAction = () => async (dispatch: any) => {
	try {
		var data = getUpAdminDashboardService();
		const pload = (await data).data;
		dispatch({ type: GET_UP_ADMIN_DASHBOARD, payload: pload });
	} catch (error) { }
};

export const getRiderDashboardAction = () => async (dispatch: any) => {
	try {
		var data = getRiderDashboardService();
		const pload = (await data).data;
		dispatch({ type: GET_RIDER_DASHBOARD, payload: pload });
	} catch (error) { }
};

