import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../store";
import { AppButton } from "../../../common/AppButton";
import { AppSearchBox } from "../../../common/AppSearchBox";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { AppDeleteModal } from "../../../common/AppDeleteModal";
import { AlertColor } from "@mui/material";
import { AppLoader } from "../../../common/AppLoader";
import { AppPagination } from "../../../common/AppPagination";
import { debounce } from "lodash";
import { BiSearchAlt2 } from "react-icons/bi";
import { Link } from "react-router-dom";
import { getHospitalAction } from "../../../../store/actions/importantNumbers/hospitalActions";
import { getUserProfileAction } from "../../../../store/actions/auth/user/userActions";
import { IdNameResponseModel } from "../../../../models/common/IdNameModel";
import { divisionList } from "../../../../store/actions/auth/address/divisionActions";
import { AppSelect } from "../../../common/AppSelect";
import { areaListByDistrictId } from "../../../../store/actions/auth/address/areaActions";
import { districtListByDivisionId as districtListByDivisionId } from "../../../../store/actions/auth/address/districtActions";
import { GetHospitalNumberModel } from "../../../../models/importantNumber/ImportantNumberModel";
import { deleteHospitalService } from "../../../../httpService/importantNumbers/hospitalServices";
import { isAdmin } from "../../../../common/common";
import { reactLocalStorage } from "reactjs-localstorage";

export default function INGetHospitalNumbers() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  var pModel = new GetHospitalNumberModel();

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [divisionId, setDivisionId] = useState(0);
  const [districtId, setDistrictId] = useState(0);
  const [areaId, setAreaId] = useState(0);
  const [divisionError, setDivisionError] = useState("");
  const [districtError, setDistrictError] = useState("");
  const [areaError, setAreaError] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const userProfile = useSelector((state: RootState) => {
    return state.getUserProfileResponse.data;
  });

  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        var pModel = new GetHospitalNumberModel();
        pModel.searchQuery = searchQuery;
        pModel.limit = 10;
        pModel.offset = 0;
        pModel.divisionId = userProfile.divisionId ? userProfile.divisionId : 0;
        pModel.districtId = userProfile.districtId ? userProfile.districtId : 0;
        pModel.areaId = userProfile.areaId ? userProfile.areaId : 0;
        setDivisionId(userProfile.divisionId ? userProfile.divisionId : 0);
        setDistrictId(userProfile.districtId ? userProfile.districtId : 0);
        setAreaId(userProfile.areaId ? userProfile.areaId : 0);
        await dispatch(getHospitalAction(pModel));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  const hospitals = useSelector((state: RootState) => {
    return state.getHospitalResponse.data;
  });

  useEffect(() => {
    dispatch(divisionList());
  }, [dispatch]);

  useEffect(() => {
    if (divisionId !== 0) {
      dispatch(districtListByDivisionId(divisionId));
    }
  }, [divisionId, dispatch]);

  useEffect(() => {
    if (districtId !== 0) {
      dispatch(areaListByDistrictId(districtId));
    }
  }, [districtId, dispatch]);

  const divisions = useSelector((state: RootState) => {
    return state.divisionListResponse.data;
  });
  var divisionIdNameModels: IdNameResponseModel[] = [];

  divisions?.map((x: any) => {
    var division = new IdNameResponseModel();
    division.id = x.id;
    division.name = x.name;
    divisionIdNameModels.push(division);
  });

  const districts = useSelector((state: RootState) => {
    return state.districtListResponse.data;
  });
  var districtsIdNameModels: IdNameResponseModel[] = [];

  districts?.map((x: any) => {
    var district = new IdNameResponseModel();
    district.id = x.id;
    district.name = x.name;
    districtsIdNameModels.push(district);
  });

  const thanas = useSelector((state: RootState) => {
    return state.areaListResponse.data;
  });
  var areaIdNameModels: IdNameResponseModel[] = [];

  thanas?.map((x: any) => {
    var area = new IdNameResponseModel();
    area.id = x.id;
    area.name = x.name;
    areaIdNameModels.push(area);
  });

  const handleOnRowChange = (payload: GetHospitalNumberModel) => {
    const fetchData = async () => {
      try {
        var pModel = new GetHospitalNumberModel();
        pModel.limit = payload.limit;
        pModel.offset = 0;
        await dispatch(getHospitalAction(pModel));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();
  };

  const handleOnPageChange = (payload: GetHospitalNumberModel) => {
    const fetchData = async () => {
      try {
        var pModel = new GetHospitalNumberModel();
        pModel.limit = payload.limit;
        pModel.offset = payload.offset;
        await dispatch(getHospitalAction(pModel));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  };

  const handleSearchQuery = debounce((searchQuery: string) => {
    const fetchData = async () => {
      try {
        setSearchQuery(searchQuery);
        var pModel = new GetHospitalNumberModel();
        pModel.limit = 10;
        pModel.offset = 0;
        pModel.searchQuery = searchQuery;

        dispatch(getHospitalAction(pModel));
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, 500);

  const [bulkIds, setBulkIds] = useState([]);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  const openDeleteModal = (id: any) => {
    setOpenDeleteModal(true);
    let ids: any = [];
    ids.push(id);
    setBulkIds(ids);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleDelete = () => {
    setSnackBarOn(false);
    deleteHospitalService(bulkIds)
      .then(() => {
        setSnackBarOn(true);
        setsnackBarMessage("Successfully Deleted");
        setSnackBarMessageType("success");
        closeDeleteModal();
        dispatch(getHospitalAction(pModel));
      })
      .catch(() => {
        setSnackBarOn(true);
        setsnackBarMessage("Something went wrong");
        setSnackBarMessageType("error");
      });
  };

  const handleDivision = (id: number) => {
    setDivisionError("");
    setDivisionId(id);
    setDistrictId(0);
    setAreaId(0);
    var pModel = new GetHospitalNumberModel();
    pModel.limit = 10;
    pModel.offset = 0;
    pModel.divisionId = id;
    pModel.districtId = 0;
    pModel.areaId = 0;

    pModel.searchQuery = searchQuery;
    dispatch(getHospitalAction(pModel));
  };

  const handleDistrict = (id: number) => {
    setDistrictError("");
    setDistrictId(id);
    setAreaId(0);
    var pModel = new GetHospitalNumberModel();
    pModel.limit = 10;
    pModel.offset = 0;
    pModel.divisionId = divisionId;
    pModel.districtId = id;
    pModel.areaId = 0;

    pModel.searchQuery = searchQuery;
    dispatch(getHospitalAction(pModel));
  };

  const handleArea = (id: number) => {
    setAreaError("");
    setAreaId(id);
    var pModel = new GetHospitalNumberModel();
    pModel.limit = 10;
    pModel.offset = 0;
    pModel.divisionId = divisionId;
    pModel.districtId = districtId;
    pModel.areaId = id;

    pModel.searchQuery = searchQuery;
    dispatch(getHospitalAction(pModel));
  };

  const navigateToImportantNumbers = () => {
    if (isAdmin(reactLocalStorage.get("RoleName"))) {
      navigate(`/admin/important-numbers`);
    } else {
      navigate(`/important-numbers`);
    }
  };
  const navigateToHospitalNumberUpdate = (id: any) => {
    if (isAdmin(reactLocalStorage.get("RoleName"))) {
      navigate(`/admin/important-numbers/hospital-number/update/${id}`);
    } else {
      navigate(`/important-numbers/hospital-number/update/${id}`);
    }
  };
  return (
    <div className="container mx-auto px-2 py-2">
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      <div className="flex flex-col items-center lg:flex-row">
        <div className="flex w-full items-center justify-between">
          <AppButton
            className="gradientBtn"
            text="Back"
            onClick={navigateToImportantNumbers}
          />
          <h2 className="title hidden w-full text-center text-cyan-600 lg:block">
            Hospital's Information ({hospitals?.size})
          </h2>
          {isAdmin(reactLocalStorage.get("RoleName")) ? (
            <Link
              className="flex justify-end lg:w-64 2xl:w-72"
              to="/admin/important-numbers/hospital-number/create"
            >
              <AppButton
                className="gradientBtn"
                text="Add Public Representative"
                onClick={() => {}}
              />
            </Link>
          ) : (
            <Link
              className="flex justify-end lg:w-64 2xl:w-72"
              to="/important-numbers/hospital-number/create"
            >
              <AppButton
                className="gradientBtn"
                text="Add Public Representative"
                onClick={() => {}}
              />
            </Link>
          )}
        </div>
        <h2 className="title w-full text-center text-cyan-600 lg:hidden">
          Hospital's Information ({hospitals?.size})
        </h2>
      </div>

      <div className="grid grid-cols-2 gap-2 py-2 lg:grid-cols-4 lg:py-4">
        <div className="flex cursor-pointer items-center rounded border border-grey p-1 shadow-sm md:px-1.5 lg:w-full 2xl:p-2">
          <BiSearchAlt2 className="cursor-pointer text-xl font-bold text-cyan-600 md:text-2xl 2xl:text-3xl" />
          <AppSearchBox
            onChange={handleSearchQuery}
            placeholder="Search here"
            className="w-full border-none text-xs md:text-base lg:px-2"
          />
        </div>

        <AppSelect
          defaultValue="Select Division"
          data={divisionIdNameModels}
          value={divisionId}
          onChange={handleDivision}
          errorMessage={divisionError}
        />
        {divisionId !== 0 && (
          <AppSelect
            defaultValue="Select District"
            data={districtsIdNameModels}
            value={districtId}
            onChange={handleDistrict}
            errorMessage={districtError}
          />
        )}
        {divisionId !== 0 && districtId !== 0 && (
          <AppSelect
            defaultValue="Select Thana"
            data={areaIdNameModels}
            value={areaId}
            onChange={handleArea}
            errorMessage={areaError}
          />
        )}
      </div>

      {isLoading ? (
        <AppLoader status={true} />
      ) : (
        <>
          {hospitals?.items ? (
            <div className="w-full">
              {/* these is header part for desktop */}
              <div className="myBorder myGradient hidden w-full lg:flex">
                <div className="tableHead w-[16%]">Name</div>
                <div className="tableHead w-[14%]">Phone (First)</div>
                <div className="tableHead w-[14%]">Phone (Second)</div>
                <div className="tableHead w-[14%]">Email</div>
                <div className="tableHead w-[10%]">Hotline Number</div>
                <div className="tableHead w-[14%]">Address</div>
                <div className="tableHead w-[14%]">Area</div>
                <div className="tableHead w-[9%]">Update</div>
                <div className="tableHead w-[9%] border-none">Remove</div>
              </div>
              {/* these is middle part for desktop */}
              <div className="hidden h-[58dvh] flex-col gap-1 overflow-y-auto py-1.5 lg:flex 2xl:h-[62dvh]">
                {hospitals?.items?.map((hospital: any) => (
                  <div
                    key={hospital?.id}
                    id={hospital?.id}
                    className="myBorder flex w-full items-center"
                  >
                    <div className="tableRow textOverflowHidden w-[16%] justify-start">
                      {hospital?.name}
                    </div>
                    <div className="tableRow w-[14%]">
                      {hospital?.phoneNumberFirst}
                    </div>
                    <div className="tableRow w-[14%]">
                      {hospital?.phoneNumberSecond}
                    </div>
                    <div className="tableRow w-[10%]">{hospital?.email}</div>
                    <div className="tableRow w-[14%]">
                      {hospital?.hotLineNumber}
                    </div>
                    <div className="tableRow w-[14%]">{hospital?.address}</div>
                    <div className="tableRow w-[14%]">{hospital?.areaName}</div>
                    <div className="tableRow w-[9%]">
                      <AppButton
                        onClick={() => navigateToHospitalNumberUpdate(hospital?.id)}
                        className="gradientBtn  py-0.5 text-sm lg:py-1"
                        text="Update"
                      />
                    </div>
                    <div className="tableRow w-[9%] border-none">
                      <AppButton
                        className="myBtn border-red bg-red py-0.5 text-sm lg:py-1"
                        text="Remove"
                        onClick={() => openDeleteModal(hospital?.id)}
                      />
                    </div>
                  </div>
                ))}
              </div>
              {/* these is middle part for mobile */}
              <div className="flex h-[78dvh] flex-col gap-2 overflow-y-auto lg:hidden">
                {hospitals?.items?.map((hospital: any) => (
                  <div
                    key={hospital?.id}
                    className="myBorder flex w-full flex-col p-1 text-xs"
                  >
                    <div className="grid grid-cols-2">
                      <div className="flex items-center gap-0.5">
                        <span className="font-medium">Name:</span>{" "}
                        <span>{hospital?.name}</span>
                      </div>
                      <div className="flex items-center gap-0.5">
                        <span className="font-medium">Email:</span>{" "}
                        <span>{hospital?.email}</span>
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="flex items-center gap-0.5">
                        <span className="font-medium">Phone 1:</span>{" "}
                        <span>{hospital?.phoneNumberFirst}</span>
                      </div>
                      <div className="flex items-center gap-0.5">
                        <span className="font-medium">Phone 2:</span>{" "}
                        <span> {hospital?.phoneNumberSecond}</span>
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="flex items-center gap-0.5">
                        <span className="font-medium">Address:</span>{" "}
                        <span>{hospital?.address}</span>
                      </div>
                      <div className="flex items-center gap-0.5">
                        <span className="font-medium">Area:</span>{" "}
                        <span>{hospital?.areaName}</span>
                      </div>
                    </div>
                    <div className="flex items-center gap-0.5">
                      <span className="font-medium">Hotline Number:</span>{" "}
                      <span>{hospital?.hotLineNumber}</span>
                    </div>
                    <div className="flex w-full items-center justify-center gap-2 lg:gap-0.5">
                      <AppButton
                        onClick={() =>navigateToHospitalNumberUpdate(hospital?.id)
                        }
                        className="gradientBtn  py-0.5 text-sm lg:py-1"
                        text="Update"
                      />
                      <AppButton
                        className="myBtn border-red bg-red py-0.5 text-sm lg:py-1"
                        text="Remove"
                        onClick={() => openDeleteModal(hospital?.id)}
                      />
                    </div>
                  </div>
                ))}
              </div>
              {/* these is footer part  */}
              <div className="fixed bottom-1 flex w-full items-center bg-white py-1 lg:static">
                <AppPagination
                  curPage={1}
                  limit={10}
                  offset={0}
                  onPageChange={handleOnPageChange}
                  onRowChange={handleOnRowChange}
                  size={hospitals?.size}
                />
              </div>
            </div>
          ) : (
            <div className="flex h-[50dvh] w-full items-center justify-center text-3xl font-medium text-textBlack/90">
              <span>Hospital Not found</span>
            </div>
          )}
        </>
      )}
      {isOpenDeleteModal && (
        <AppDeleteModal
          text="Are you sure to Delete?"
          handleDelete={handleDelete}
          closeDeleteModal={closeDeleteModal}
        />
      )}
    </div>
  );
}
