import { getNotificationCountService, getNotificationsService } from "../../../httpService/notification/NotificationServices";
import { GET_NOTIFICATION_COUNT, GET_NOTIFICATION_LIST } from "../../constants/notification/NotificationConstsnts";

export const getNotificationListAction = (payload?: any) => async (dispatch: any) => {
  var data = getNotificationsService(payload);
  const pload = (await data).data;
  dispatch({ type: GET_NOTIFICATION_LIST, payload: pload });
};


export const getNotificationCountAction = () => async (dispatch: any) => {
  var data = getNotificationCountService();
  const pload = (await data).data;
  dispatch({ type: GET_NOTIFICATION_COUNT, payload: pload });
};
