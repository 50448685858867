import { AFFILIATE_GET_ORDER_LIST, AFFILIATE_GET_ORDER_DETAILS, ADMIN_AFFILIATE_GET_ORDER_LIST, ADMIN_AFFILIATE_GET_ORDER_DETAILS } from "../../constants/sales/affiliateOrderConstants";

export function affiliateGetOrderListCustomerReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case AFFILIATE_GET_ORDER_LIST:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

export function affiliateGetOrderItemsCustomerReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case AFFILIATE_GET_ORDER_DETAILS:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

export function affiliateGetOrderListAdminReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case ADMIN_AFFILIATE_GET_ORDER_LIST:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

export function affiliateGetOrderItemsAdminReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case ADMIN_AFFILIATE_GET_ORDER_DETAILS:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}
