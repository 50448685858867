import { PaginationPayloadModel } from "../../../common/paginationModels";

export class SSAddProductBrand {
    name?: string;
    bnName?: string;
    phoneNumber?: string;
    bazarId?: any;
}

export class SSUpdateProdcutBrand {
    id?: any;
    name?: string;
    bnName?: string;
    phoneNumber?: string;
    bazarId?: any;
}

export class SSGetProductBrandListRequest extends PaginationPayloadModel {
    isAll?: any;
    forPublic?: any;
    bazarId?: any = 0;
}
