import { useEffect, useMemo, useState } from "react";
import { AffiliateProductBox } from "./AffiliateProductBox";
import {
  ShoppingCartFavouriteModel,
} from "../../../models/shoppingCart/resellingCartModels";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getAffiliateFavouriteItemListAction } from "../../../store/actions/cart/affiliateCartActions";

export default function AffiliateGetFavouriteItems() {

  const dispatch = useDispatch();

  const favouriteItemsSignIn = useSelector((state: RootState) => {
    return state.getAffiliateFavouriteItemResponse.data;
  });

  const favItemsMemo = useMemo(() => {
    return favouriteItemsSignIn as ShoppingCartFavouriteModel[];
  }, [favouriteItemsSignIn]);

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(getAffiliateFavouriteItemListAction(searchQuery));
  }, [dispatch, searchQuery]);

  const handleAfterAddToCart = () => { }

  return (
    <div className="container mx-auto py-4 md:pt-6 lg:pb-12">
      <h3 className="title pb-4 text-center md:pb-8 px-4 md:px-0">Favorites</h3>
      <div className="productContainer">
        {favItemsMemo &&
          favItemsMemo?.map((x: any, index: any) => {
            return (
              <AffiliateProductBox key={index}
                id={x.id}
                name={x.name}
                shortDescription={x.shortDescription}
                bannerPath={x.bannerPath}
                discount={x.discount}
                isBestSeller={x.isBestSeller}
                price={x.price}
                oldPrice={x.oldPrice}
                rating={x.rating}
                totalSold={x.totalSold}
                isDisountProduct={x.isDisountProduct}
                discountText={x.discountText}
                afterAddToCart={handleAfterAddToCart}
                totalReview={x.totalReview}
                totalView={x.totalView}
              />
            );
          })}
      </div>
    </div>
  );
}
