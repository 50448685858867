export const GET_BECHTE_CHAI_PRODUCT_LIST = 'GET_BECHTE_CHAI_PRODUCT_LIST';
export const GET_BECHTE_CHAI_MY_PRODUCT_LIST = 'GET_BECHTE_CHAI_MY_PRODUCT_LIST';
export const GET_BECHTE_CHAI_PRODUCT_BY_ID = 'GET_BECHTE_CHAI_PRODUCT_BY_ID';
export const ADD_BECHTE_CHAI_PRODUCT = 'ADD_BECHTE_CHAI_PRODUCT';
export const UPDATE_BECHTE_CHAI_PRODUCT = 'UPDATE_BECHTE_CHAI_PRODUCT';
export const REMOVE_BECHTE_CHAI_PRODUCT = 'REMOVE_BECHTE_CHAI_PRODUCT';
export const GET_BECHTE_CHAI_PRODUCT_ATTRIBUTE_MAPPING = 'GET_BECHTE_CHAI_PRODUCT_ATTRIBUTE_MAPPING';
export const GET_BECHTE_CHAI_PRODUCT_ATTRIBUTE_VALUES = 'GET_BECHTE_CHAI_PRODUCT_ATTRIBUTE_VALUES';
export const GET_BECHTE_CHAI_PRODUCT_ATTRIBUTE_VALUE = 'GET_BECHTE_CHAI_PRODUCT_ATTRIBUTE_VALUE';
export const GET_BECHTE_CHAI_PRODUCT_ATTRIBUTE_MAPPING_BY_ID = 'GET_BECHTE_CHAI_PRODUCT_ATTRIBUTE_MAPPING_BY_ID';
export const GET_BECHTE_CHAI_PRODUCT_ATTRIBUTE_VALUES_BY_MAPPING_ID = 'GET_BECHTE_CHAI_PRODUCT_ATTRIBUTE_VALUES_BY_MAPPING_ID';
export const GET_BECHTE_CHAI_ALL_POSSIBLE_COMBINATIONS = 'GET_BECHTE_CHAI_ALL_POSSIBLE_COMBINATIONS';
export const GET_BECHTE_CHAI_COMMENT_LIST = 'GET_BECHTE_CHAI_COMMENT_LIST';
export const GET_BECHTE_CHAI_COMMENT_LIST_BY_ID = 'GET_BECHTE_CHAI_COMMENT_LIST_BY_ID';
export const GET_BECHTE_CHAI_FAVOURITE_LIST = 'GET_BECHTE_CHAI_FAVOURITE_LIST';
export const GET_BECHTE_CHAI_IS_ALREADY_FAVOURITE = 'GET_BECHTE_CHAI_IS_ALREADY_FAVOURITE';
