import { GET_AREA_ADMIN_LIST, GET_AREA_LIST } from "../../../constants/auth/address/AddressConstants";

function areaListReducer(state= { data: []} , action: any) {
    switch (action.type) {
        case GET_AREA_LIST :
            //return {...state, loading: false, data: action.payload };
            return {loading: false, data: action.payload };
        default:
            return state;
    }
}

function areaListAdminReducer(state= { data: []} , action: any) {
    switch (action.type) {
        case GET_AREA_ADMIN_LIST :
            //return {...state, loading: false, data: action.payload };
            return {loading: false, data: action.payload };
        default:
            return state;
    }
}


export { areaListReducer, areaListAdminReducer }