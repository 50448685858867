import { useEffect, useState } from 'react'
import {
    GetUserPointRequest,
    GetUserPointResponse,
    GetUserPointWithdrawHistoryRequest,
    GetUserPointWithdrawHistoryResponse,
    GetUserPullHistoryRequest,
    GetUserPullHistoryResponse,
    GetUserWalletResponse,
    GetWalletRechargeHistory
} from '../../../../models/auth/user/UserPointModels';
import {
    getUserPointListAction,
    getUserPullHistoryAction,
    getUserWalletAction,
    getUserWithdrawHistoryAction,
    getWalletRechargeHistoryAction
} from '../../../../store/actions/auth/user/userActions';
import { GetWalletRechargeHistoryRequest } from '../../../../models/auth/user/UserModels';
import { RootState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import _moment from "moment";
import { AppButton } from '../../../common/AppButton';
import { getUserDetailsAction } from '../../../../store/actions/auth/address/userActions';
import { isAffiliater, isReseller } from '../../../../common/common';

interface IProps {
    id?: any;
}

export const AdminGetUserWallet: React.FC<IProps> = ({ id }) => {

    const dispatch = useDispatch();

    const wallet = useSelector((state: RootState) => {
        return state.getuserWalletResponse.data as GetUserWalletResponse;
    });

    useEffect(() => {
        dispatch(getUserWalletAction(id));
    }, [dispatch]);

    const pointList = useSelector((state: RootState) => {
        return state.getuserPointListResponse.data as GetUserPointResponse[];
    });

    const [searchQuery, setSearchQuery] = useState("");

    const [earningType, setEarningType] = useState(0);
    const [earningStatus, setEarningStatus] = useState(0);

    var model = new GetUserPointRequest();
    model.userId = id;
    model.searchQuery = searchQuery;
    model.earningStatus = earningStatus;
    model.earningType = earningType;

    useEffect(() => {
        dispatch(getUserPointListAction(model));
    }, [dispatch]);

    const pullList = useSelector((state: RootState) => {
        return state.getUserPullHistoryResponse
            .data as GetUserPullHistoryResponse[];
    });

    var pullHistryModel = new GetUserPullHistoryRequest();
    pullHistryModel.userId = id;
    pullHistryModel.earningType = earningType;

    useEffect(() => {
        dispatch(getUserPullHistoryAction(pullHistryModel));
    }, [dispatch]);


    const [pointStatus, setPointStatus] = useState(0);

    const withdrawList = useSelector((state: RootState) => {
        return state.getUserWithdrawHistoryResponse.data;
    });

    var withdrawlHistryModel = new GetUserPointWithdrawHistoryRequest();
    withdrawlHistryModel.userId = id;
    withdrawlHistryModel.PointStatus = pointStatus;

    useEffect(() => {
        dispatch(getUserWithdrawHistoryAction(withdrawlHistryModel));
    }, [dispatch]);

    const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);

    const openWithdrawModal = () => {
        setIsWithdrawModalOpen(true);
    };

    const orderTimeFormatter = (createdDateTime: any) => {
        const createdTime = _moment(createdDateTime, "YYYY-MM-DD HH:mm:ss.SSSSSSS");
        return createdTime.format("MMM D, YY [at] h:mm A");
    };

    const [isEarningHistry, setIsEarningHistry] = useState(true);
    const [isWithdrawHistry, setIsWithdrawHistry] = useState(false);
    const [isPullHistry, setIsPullHistry] = useState(false);
    const [isRechargeHistry, setIsRechargeHistry] = useState(false);
    const [isSelected, setIsSelected] = useState("");

    const openEaringHistry = () => {
        setIsSelected("EaringHistry")
        setIsEarningHistry(true);
        setIsWithdrawHistry(false);
        setIsPullHistry(false);
        setIsRechargeHistry(false);
    };

    const openWithdrawHistry = () => {
        setIsSelected("WithdrawHistry")
        setIsEarningHistry(false);
        setIsWithdrawHistry(true);
        setIsPullHistry(false);
        setIsRechargeHistry(false);
    };

    const openPullHistry = () => {
        setIsSelected("PullHistry")
        setIsEarningHistry(false);
        setIsWithdrawHistry(false);
        setIsPullHistry(true);
        setIsRechargeHistry(false);
    };

    const rechargeHistory = useSelector((state: RootState) => {
        return state.getWalletRechargeHistoryResponse.data;
    });

    const openRechargeHistry = () => {
        var model = new GetWalletRechargeHistoryRequest();
        model.isMyRecord = false;
        model.userId = id;
        dispatch(getWalletRechargeHistoryAction(model));
        setIsSelected("RechargeHistry");
        setIsEarningHistry(false);
        setIsWithdrawHistry(false);
        setIsPullHistry(false);
        setIsRechargeHistry(true);
    };

    const user = useSelector((state: RootState) => {
        return state.userDetailsResponse.data;
    });

    useEffect(() => {
        dispatch(getUserDetailsAction(id));
    }, [dispatch]);

    return (
        <div className="w-full h-[80vh] overflow-y-auto pb-4 px-2 lg:px-0">
            <h2 className="title pb-1 lg:py-2">User wallet</h2>
            {/* this is first div  */}
            <div className="flex flex-col lg:flex-row items-center gap-2 lg:gap-5 w-full mx-auto">
                {/* this is main balance and withdraw  */}
                <div className="w-full grid grid-cols-5 lg:flex items-center gap-1 lg:gap-5">
                    {/* this is Main balance of wallet  */}
                    <div className="w-full col-span-3 lg:w-60 h-24 lg:h-36 py-1 lg:py-2 rounded lg:space-y-1 bg-gradient-to-r from-emerald-500 via-emerald-400 to-emerald-300 lg:shadow">
                        <p className="font-medium text-white text-center text-base lg:text-[22px] pt-1 lg:pt-2">Main Balance</p>
                        <div className="flex items-center justify-center">
                            <h3 className="text-lg lg:text-3xl font-semibold text-white">{wallet.mainBalance}</h3>
                        </div>
                        <div className="flex justify-center pt-1">
                            <AppButton
                                className="rounded text-xs lg:text-base bg-white px-4 py-0.5 lg:py-1 font-medium text-primary shadow-lg hover:shadow-xl"
                                text="Withdraw"
                                onClick={openWithdrawModal}
                            />
                        </div>
                    </div>
                    {/* this is total withdraw and total earning of wallet  */}
                    <div className="w-full col-span-2 lg:w-72 h-24 lg:h-36 py-1 lg:py-2 flex flex-col items-center justify-center rounded space-y-1 lg:space-y-2 bg-white myShd lg:border-b-4 border-b-lime-300">
                        <div className="w-full flex flex-col items-center myShadow lg:shadow-none rounded lg:rounded-none lg:border-none border-b-2 border-b-red py-0.5 lg:py-0 gap-0.5 lg:gap-0">
                            <p className="font-medium text-textBlack text-xs lg:text-lg">Total Withdrew </p>
                            <div className="flex items-center">
                                <h3 className="text-sm lg:text-xl font-semibold text-red">{wallet.totalWithdrawAmount}</h3>
                            </div>
                        </div>
                        <div className="w-full flex flex-col items-center myShadow lg:shadow-none rounded lg:rounded-none lg:border-none border-b-2 border-b-primary py-0.5 lg:py-0 gap-0.5 lg:gap-0">
                            <p className="font-medium text-textBlack text-xs lg:text-lg">Total Earning</p>
                            <div className="flex items-center">
                                <h3 className="text-sm lg:text-xl font-semibold text-primary">{wallet.totalEarning}</h3>
                            </div>
                        </div>
                    </div>
                </div>

                {/* recharge amount and total earnings  */}
                {(isReseller(user?.userRoles?.map((x: any) => x.name)) &&
                    <div className="w-full flex flex-row items-center gap-1 lg:gap-5">
                        <div className="w-[76%] lg:w-full flex flex-row-reverse lg:flex-row items-center gap-1 lg:gap-5">
                            <div className="w-2/3 lg:w-80 h-20 lg:h-36 px-1 lg:px-3 py-1.5 lg:py-2 rounded lg:space-y-1 bg-white myShadow border-b-2 lg:border-b-4 border-b-violet-500">
                                <div className="flex items-center justify-between">
                                    <p className="font-medium text-textBlack text-xsm lg:text-lg">Total Reselling Earning</p>
                                    <div className="flex items-center">
                                        <h3 className="text-xs lg:text-lg font-semibold text-violet-800">{wallet.totalResellingIncome}</h3>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <p className="font-medium text-textBlack text-xsm lg:text-lg">Total Reselling Moderator Earning</p>
                                    <div className="flex items-center">
                                        <h3 className="text-xs lg:text-lg font-semibold text-violet-800">{wallet.totalResellingModeratorIncome}</h3>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <p className="font-medium text-textBlack text-xsm lg:text-lg">Total Reselling Refer Earning</p>
                                    <div className="flex items-center">
                                        <h3 className="text-xs lg:text-lg font-semibold text-violet-800">{wallet.totalResellingReferIncome}</h3>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <p className="font-medium text-textBlack text-xsm lg:text-lg">Total Reselling Course Earning</p>
                                    <div className="flex items-center">
                                        <h3 className="text-xs lg:text-lg font-semibold text-violet-800">{wallet.totalResellingCourseIncome}</h3>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <p className="font-medium text-textBlack text-xsm lg:text-lg">Total Reselling Back Amount</p>
                                    <div className="flex items-center">
                                        <h3 className="text-xs lg:text-lg font-semibold text-violet-800">{wallet.totalResellingBackAmount}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* this is Back Amount of wallet  */}
                        <div className="w-[24%] block lg:hidden h-20 px-0.5 py-2 lg:p-4 rounded space-y-0.5 lg:space-y-1.5 bg-white myShadow border-b-2 lg:border-b-4 border-b-royalBlue">
                            <p className="font-medium text-center text-textBlack text-xxs lg:text-xl">Back Amount</p>
                            <div className="flex items-center justify-center">
                                <h3 className="text-xs lg:text-xl font-semibold text-royalBlue">{wallet.resellingBackAmount}</h3>
                            </div>
                        </div>
                    </div>
                )}

                {(isAffiliater(user?.userRoles?.map((x: any) => x.name)) &&
                    <div className="w-full flex flex-row items-center gap-1 lg:gap-5">
                        <div className="w-[76%] lg:w-full flex flex-row-reverse lg:flex-row items-center gap-1 lg:gap-5">
                            <div className="w-2/3 lg:w-80 h-20 lg:h-36 px-1 lg:px-3 py-1.5 lg:py-2 rounded lg:space-y-1 bg-white myShadow border-b-2 lg:border-b-4 border-b-violet-500">
                                <div className="flex items-center justify-between">
                                    <p className="font-medium text-textBlack text-xsm lg:text-lg">Total Affiliate Earning</p>
                                    <div className="flex items-center">
                                        <h3 className="text-xs lg:text-lg font-semibold text-violet-800">{wallet.totalAffiliateIncome}</h3>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <p className="font-medium text-textBlack text-xsm lg:text-lg">Total Affiliate Refer Earning</p>
                                    <div className="flex items-center">
                                        <h3 className="text-xs lg:text-lg font-semibold text-violet-800">{wallet.totalAffiliateReferIncome}</h3>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <p className="font-medium text-textBlack text-xsm lg:text-lg">Total Affiliate Course Earning</p>
                                    <div className="flex items-center">
                                        <h3 className="text-xs lg:text-lg font-semibold text-violet-800">{wallet.totalAffiliateCourseIncome}</h3>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <p className="font-medium text-textBlack text-xsm lg:text-lg">Total Affiliate BAck Amount</p>
                                    <div className="flex items-center">
                                        <h3 className="text-xs lg:text-lg font-semibold text-violet-800">{wallet.totalAffiliateBackAmount}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* this is Back Amount of wallet  */}
                        <div className="w-[24%] block lg:hidden h-20 px-0.5 py-2 lg:p-4 rounded space-y-0.5 lg:space-y-1.5 bg-white myShadow border-b-2 lg:border-b-4 border-b-royalBlue">
                            <p className="font-medium text-center text-textBlack text-xxs lg:text-xl">Back Amount</p>
                            <div className="flex items-center justify-center">
                                <h3 className="text-xs lg:text-xl font-semibold text-royalBlue">{wallet.resellingBackAmount}</h3>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {(isReseller(user?.userRoles?.map((x: any) => x.name)) &&
                <div className="grid grid-cols-4 lg:grid-cols-5 gap-1 lg:gap-4 pt-2 lg:pt-4">
                    <div className="px-0.5 py-1 lg:p-4 rounded space-y-0.5 lg:space-y-1.5 bg-white myShadow border-b-2 lg:border-b-4 border-b-royalBlue">
                        <p className="font-medium text-center text-textBlack text-xxs lg:text-xl">Reselling Income</p>
                        <div className="flex items-center justify-center">
                            <h3 className="text-xs lg:text-xl font-semibold text-royalBlue">{wallet.resellingIncome}</h3>
                        </div>

                    </div>
                    <div className="px-0.5 py-1 lg:p-4 rounded space-y-0.5 lg:space-y-1.5 bg-white myShadow border-b-2 lg:border-b-4 border-b-royalBlue">
                        <p className="font-medium text-center text-textBlack text-xxs lg:text-xl">Reselling Moderator Income</p>
                        <div className="flex items-center justify-center">
                            <h3 className="text-xs lg:text-xl font-semibold text-royalBlue">{wallet.resellingModeratorIncome}</h3>
                        </div>
                    </div>
                    <div className="px-0.5 py-1 lg:p-4 rounded space-y-0.5 lg:space-y-1.5 bg-white myShadow border-b-2 lg:border-b-4 border-b-royalBlue">
                        <p className="font-medium text-center text-textBlack text-xxs lg:text-xl">Reselling Refer Income</p>
                        <div className="flex items-center justify-center">
                            <h3 className="text-xs lg:text-xl font-semibold text-royalBlue">{wallet.resellingReferIncome}</h3>
                        </div>

                    </div>
                    <div className="px-0.5 py-1 lg:p-4 rounded space-y-0.5 lg:space-y-1.5 bg-white myShadow border-b-2 lg:border-b-4 border-b-royalBlue">
                        <p className="font-medium text-center text-textBlack text-xxs lg:text-xl">Reselling Course Income</p>
                        <div className="flex items-center justify-center">
                            <h3 className="text-xs lg:text-xl font-semibold text-royalBlue">{wallet.resellingCourseIncome}</h3>
                        </div>
                    </div>
                    <div className="hidden lg:block p-4 rounded space-y-1.5 bg-white myShadow border-b-4 border-b-royalBlue">
                        <p className="font-medium text-center text-textBlack text-xl">Reselling Back Amount</p>
                        <div className="flex items-center justify-center">
                            <h3 className="text-xl font-semibold text-royalBlue">{wallet.resellingBackAmount}</h3>
                        </div>
                    </div>
                </div>
            )}

            {(isAffiliater(user?.userRoles?.map((x: any) => x.name)) &&
                <div className="grid grid-cols-4 lg:grid-cols-5 gap-1 lg:gap-4 pt-2 lg:pt-4">
                    <div className="px-0.5 py-1 lg:p-4 rounded space-y-0.5 lg:space-y-1.5 bg-white myShadow border-b-2 lg:border-b-4 border-b-royalBlue">
                        <p className="font-medium text-center text-textBlack text-xxs lg:text-xl">Affiliate Income</p>
                        <div className="flex items-center justify-center">
                            <h3 className="text-xs lg:text-xl font-semibold text-royalBlue">{wallet.affiliateIncome}</h3>
                        </div>

                    </div>
                    <div className="px-0.5 py-1 lg:p-4 rounded space-y-0.5 lg:space-y-1.5 bg-white myShadow border-b-2 lg:border-b-4 border-b-royalBlue">
                        <p className="font-medium text-center text-textBlack text-xxs lg:text-xl">Affiliate Refer Income</p>
                        <div className="flex items-center justify-center">
                            <h3 className="text-xs lg:text-xl font-semibold text-royalBlue">{wallet.affiliateReferIncome}</h3>
                        </div>
                    </div>
                    <div className="px-0.5 py-1 lg:p-4 rounded space-y-0.5 lg:space-y-1.5 bg-white myShadow border-b-2 lg:border-b-4 border-b-royalBlue">
                        <p className="font-medium text-center text-textBlack text-xxs lg:text-xl">Affiliate Course Income</p>
                        <div className="flex items-center justify-center">
                            <h3 className="text-xs lg:text-xl font-semibold text-royalBlue">{wallet.affiliateCourseIncome}</h3>
                        </div>

                    </div>
                    <div className="px-0.5 py-1 lg:p-4 rounded space-y-0.5 lg:space-y-1.5 bg-white myShadow border-b-2 lg:border-b-4 border-b-royalBlue">
                        <p className="font-medium text-center text-textBlack text-xxs lg:text-xl">Affiliate Back Amount</p>
                        <div className="flex items-center justify-center">
                            <h3 className="text-xs lg:text-xl font-semibold text-royalBlue">{wallet.affiliateBackAmount}</h3>
                        </div>
                    </div>
                </div>
            )}

            <div className="lg:myBorder flex items-center justify-between lg:justify-evenly gap-1 lg:gap-4 lg:rounded px-0 lg:px-2 py-0 lg:py-2.5 md:gap-6 my-2 lg:my-4">
                <AppButton className={`${isSelected === "EaringHistry" || isSelected === "" ? "myBtn text-xxs lg:text-base px-1.5 lg:px-4 py-0 lg:py-1" : "outlineBtn text-xxs lg:text-base px-1.5 lg:px-4 py-0 lg:py-1"}`} text="Earning History" onClick={openEaringHistry} />
                <AppButton className={`${isSelected === "WithdrawHistry" ? "myBtn text-xxs lg:text-base px-1.5 lg:px-4 py-0 lg:py-1" : "outlineBtn text-xxs lg:text-base px-1.5 lg:px-4 py-0 lg:py-1"}`} text="Withdraw History" onClick={openWithdrawHistry} />
                {(isReseller(user?.userRoles?.map((x: any) => x.name)) || isAffiliater(user?.userRoles?.map((x: any) => x.name))) && (
                    <AppButton className={`${isSelected === "PullHistry" ? "myBtn text-xxs lg:text-base px-1.5 lg:px-4 py-0 lg:py-1" : "outlineBtn text-xxs lg:text-base px-1.5 lg:px-4 py-0 lg:py-1"}`} text="Pull History" onClick={openPullHistry} />
                )}
                <AppButton className={`${isSelected === "RechargeHistry" ? "myBtn text-xxs lg:text-base px-1.5 lg:px-4 py-0 lg:py-1" : "outlineBtn text-xxs lg:text-base px-1.5 lg:px-4 py-0 lg:py-1"}`} text="Recharge History" onClick={openRechargeHistry} />
            </div>

            {isEarningHistry && (
                <div className="flex w-full bg-primary  rounded">
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[20%]">Earning Amount</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[20%]">Date & Time</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[20%]">Earning Type</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[20%]">Earning Status</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 w-[20%]">Earned From</div>
                </div>
            )}

            {isEarningHistry && <div className="flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-0.5 lg:gap-1 py-0.5 lg:py-1">
                {isEarningHistry &&
                    pointList?.map((item: GetUserPointResponse) => {
                        return (
                            <div key={item?.id} className="myBorder flex w-full items-center">
                                <div className="centerDiv border-r border-grey text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[20%]">৳ {item?.earnedAmount}</div>
                                <div className="centerDiv border-r border-grey text-xxs lg:text-sm px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[20%]">{orderTimeFormatter(item.createdAt)}</div>
                                <div className="centerDiv border-r border-grey text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[20%]">
                                    <p className={item.earningType === "CourseEarning" ? "CourseEarning" : "" || item.earningType === "ResellerEarning" ? "ResellerEarning" : "" || item.earningType === "RefferEarning" ? "RefferEarning" : "" || item.earningType === "ModeratorEarning" ? "ModeratorEarning" : "" || item.earningType === "ProductEarning" ? "ProductEarning" : "" || item.earningType === "AdvancedPaymentEarning" ? "AdvancedPaymentEarning" : "" || item.earningType === "ShippingCost" ? "ShippingCost" : ""}>
                                        {item.earningType === "CourseEarning" ? "CourseEarning" : "" || item.earningType === "ResellerEarning" ? "ResellerEarning" : "" || item.earningType === "RefferEarning" ? "RefferEarning" : "" || item.earningType === "ModeratorEarning" ? "ModeratorEarning" : "" || item.earningType === "ProductEarning" ? "ProductEarning" : "" || item.earningType === "AdvancedPaymentEarning" ? "AdvancedBack" : "" || item.earningType === "ShippingCost" ? "ShippingCost" : ""}
                                    </p>
                                </div>
                                <div className="centerDiv border-r border-grey text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[20%]">
                                    <p className={item.earningStatus === "InProgress" ? "ProgressIn" : "" || item.earningStatus === "Denied" ? "Denied" : "" || item.earningStatus === "Earned" ? "Earned" : ""}>{(item.earningType === "AdvancedPaymentEarning" || item.earningType === "ShippingCost") ? "Returned" : item.earningStatus == "Denied" ? "Cancelled" : item.earningStatus}</p>
                                </div>
                                <div className="centerDiv text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[20%]">{item?.earnedFrom}</div>
                            </div>
                        );
                    })}
            </div>}

            {isWithdrawHistry && (
                <div className="flex w-full bg-primary rounded">
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[33.33%]">Withdraw Amount</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[33.33%]">Date & Time</div>
                    <div className="centerDiv text-white font-medium text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 w-[33.33%]">Withdraw status</div>
                </div>
            )}

            {isWithdrawHistry && <div className="flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-0.5 lg:gap-1 py-0.5 lg:py-1">
                {isWithdrawHistry &&
                    withdrawList?.items?.map((item: GetUserPointWithdrawHistoryResponse) => {
                        return (
                            <div key={item?.id} className="myBorder flex w-full items-center">
                                <div className="centerDiv border-r border-grey  text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[33.33%]">৳ {item.requestedAmount}</div>
                                <div className="centerDiv border-r border-grey  text-xxs lg:text-sm px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[33.33%]">{orderTimeFormatter(item.requestDateTime)}</div>
                                <div className="centerDiv  text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[33.33%]">
                                    <p className={item.pointStatus === "InProgress" ? "ProgressIn" : "" || item.pointStatus === "Cancelled" ? "Denied" : "" || item.pointStatus === "Received" ? "Earned" : ""}>{item.pointStatus}</p>
                                </div>
                            </div>
                        );
                    })}
            </div>}
            {(isReseller(user?.userRoles?.map((x: any) => x.name)) || isAffiliater(user?.userRoles?.map((x: any) => x.name))) && (
                <>
                    {isPullHistry && (
                        <div className="flex w-full bg-primary rounded">
                            <div className="centerDiv text-white font-medium  text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[33.33%]">Pull Amount</div>
                            <div className="centerDiv text-white font-medium  text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[33.33%]">Date & Time</div>
                            <div className="centerDiv text-white font-medium  text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 w-[33.33%]">Pull Type</div>
                        </div>
                    )}

                    {isPullHistry && <div className="flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-0.5 lg:gap-1 py-0.5 lg:py-1">
                        {isPullHistry &&
                            pullList?.map((item: GetUserPullHistoryResponse) => {
                                return (
                                    <div key={item?.id} className="myBorder flex w-full items-center">
                                        <div className="centerDiv border-r border-grey  text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[33.33%]">৳ {item?.pulledAmount}</div>
                                        <div className="centerDiv border-r border-grey  text-xxs lg:text-sm px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[33.33%]">{orderTimeFormatter(item.dateTime)}</div>
                                        <div className="centerDiv  text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[33.33%]">
                                            <p className={item.earningType === "CourseEarning" ? "CourseEarning" : "" || item.earningType === "ResellerEarning" ? "ResellerEarning" : "" || item.earningType === "RefferEarning" ? "RefferEarning" : "" || item.earningType === "ModeratorEarning" ? "ModeratorEarning" : "" || item.earningType === "ProductEarning" ? "ProductEarning" : "" || item.earningType === "PaymentBackEarning" ? "PaymentBackEarning" : ""}>{item.earningType}</p>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>}
                </>
            )}
            {isRechargeHistry && (
                <div className="flex w-full bg-primary rounded">
                    <div className="centerDiv text-white font-medium  text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[25%]">Recharge Amount</div>
                    <div className="centerDiv text-white font-medium  text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[25%]">Date & Time</div>
                    <div className="centerDiv text-white font-medium  text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 border-r w-[25%]">Payment Method</div>
                    <div className="centerDiv text-white font-medium  text-xxs lg:text-base px-0.5 lg:px-4 py-1 lg:py-2.5 w-[25%]">Recharge status</div>
                </div>
            )}

            {isRechargeHistry && <div className="flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-0.5 lg:gap-1 py-0.5 lg:py-1">
                {isRechargeHistry &&
                    rechargeHistory?.items?.map((item: GetWalletRechargeHistory, index: any) => {
                        return (
                            <div key={index} className="myBorder flex w-full items-center">
                                <div className="centerDiv border-r border-grey  text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[25%]">৳ {item?.amount}</div>
                                <div className="centerDiv border-r border-grey  text-xxs lg:text-sm px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[25%]">{orderTimeFormatter(item.dateTime)}</div>
                                <div className="centerDiv border-r border-grey  text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[25%]">
                                    {item.paymentMethods}
                                </div>
                                <div className="centerDiv  text-xxs lg:text-base px-0.5 lg:px-4 py-0.5 lg:py-1 h-full w-[25%]">
                                    {item.transectionStatus}
                                </div>
                            </div>
                        );
                    })}
            </div>}
        </div>
    );
}
