import { GET_BILLING_ADDRESS_LIST } from "../../../constants/auth/address/AddressConstants";

function billingListReducer(state = { data: [] }, action: any) {
  switch (action.type) {
    case GET_BILLING_ADDRESS_LIST:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

function billingAddressByIdReducer(
  state = {
    data: [
      {
        customerId: 3,
        bId: 1,
        bFirstName: "Sohan",
        bLastName: "Rahman",
        bPhoneNo1: "0111",
        bPhoneNo2: "",
        bEmail: "",
        bDivisionName: "Dhaka",
        bPostCode: null,
        bAddressLine1: "Malibagh",
        bAddressLine2: "Dhaka",
        bDistrictName: "Dhaka",
        bAreaName: "Malibagh",
        bCompanyName: "",
        bDivisionId: 8,
        bDistrictId: 1,
        bAreaId: 4,
        sId: 1,
        sFirstName: "Sohan",
        sLastName: "Rahman",
        sPhoneNo1: "0111",
        sPhoneNo2: "",
        sEmail: "",
        sCompanyName: "",
        sPostCode: null,
        sAddressLine1: "Malibagh",
        sAddressLine2: "Dhaka",
        sDivisionName: "Dhaka",
        sDistrictName: "Dhaka",
        sAreaName: "Malibagh",
        sDivisionId: 8,
        sDistrictId: 1,
        sAreaId: 4,
      },
    ],
  },
  action: any
) {
  switch (action.type) {
    case "GET_BILLING_ADDRESS_BY_ID":
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

export { billingListReducer, billingAddressByIdReducer };
