import { PaginationPayloadModel } from "../common/paginationModels";

export class UpdateUnionModel {
    Id?:number;
    Name?: string;
    BnName?: string;
    Code?: string;
    Lat?: string;
    Lon?: string;
    PhoneNumberFirst?: string;
    PhoneNumberSecond?: string;
    Type?:Types;
    AreaId?:number;
 }


export enum Types {
    Union = 1,
    Ward = 2
}
export const TypeUnionOrWard = [
    { id: 1, name: "Union" },
    { id: 2, name: "Ward" },
  ];

export class GetUnionsModel extends PaginationPayloadModel {
    divisionId: number = 0;
    districtId: number = 0;
    areaId: number = 0;
}