import { GET_USER, GET_USER_NAME, GET_USER_POINT, GET_USER_POINT_LIST, GET_USER_PROFILE,GET_USER_DETAILS, GET_RESELLER_TEAM, GET_USER_TOTAL_POINT, GET_USER_WITHDRAW_LIST, VENDOR_DETAILS_WITH_CAT, GET_USER_WALLET, GET_USER_PULL_HISTORY, GET_USER_WITHDRAW_HISTORY, GET_ADMIN_WALLET, GET_COMPANY_POINT_LIST, GET_VENDOR_WALLET, GET_VENDOR_POINT_LIST, GET_VENDOR_WITHDRAW_HISTORY, GET_VENDOR_PULL_HISTORY, GET_VENDOR_WITHDRAW_LIST, GET_WALLET_RECHARGE_LIST, GET_GIVE_BACK_LIST, GET_BLOOD_DONORS, IS_VENDOR_ALL_AMOUNT_PAID, IS_AREA_SAVED, GET_DATA_OFFER_VENDOR_WALLET, GET_DATA_OFFER_VENDOR_PULL_HISTORY, GET_DATA_OFFER_VENDOR_WITHDRAW_HISTORY, GET_DATA_OFFER_VENDOR_POINT_LIST, GET_DATA_OFFER_ORDER_LIST, GET_WALLET_RECHARGE_NAGAD_LIST, GET_AFFILIATER_TEAM, GET_COMPANY_TEAM, GET_ADMIN_WALLET_CALCULATIONS, GET_RIDER_POINT_LIST, GET_RIDER_WALLET, GET_IS_RIDER_HAVE_TO_PAY } from "../../../constants/auth/user/userConstant";

const initialState = {
  userList: [],
};


function userListReducer(state = initialState, action: any) {
  if (action.type === "GET-USER-LIST") {
    return { userList: action.payload };
  }
  return state;
}

function userDetailsReducer(state = { data: {} }, action: any) {
  switch (action.type) {
    case GET_USER_DETAILS:
      return { loading: false, data: action.payload };
    default:
      return state;
  }
}

function getUserReducer(state = { data: {} }, action: any) {
  switch (action.type) {
    case GET_USER:
      return { loading: false, data: action.payload };
    default:
      return state;
  }
}

function getUserProfileReducer(state = { data: {} }, action: any) {
  switch (action.type) {
    case GET_USER_PROFILE:
      return { loading: false, data: action.payload };
    default:
      return state;
  }
}

function getUserNameReducer(state = { data: "" }, action: any) {
  switch (action.type) {
    case GET_USER_NAME:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

function getuserPointListReducer(state = { data: [] }, action: any) {
  switch (action.type) {
    case GET_USER_POINT_LIST:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

function getRiderPointListReducer(state = { data: [] }, action: any) {
  switch (action.type) {
    case GET_RIDER_POINT_LIST:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

function getCompanyPointListReducer(state = { data: [] }, action: any) {
  switch (action.type) {
    case GET_COMPANY_POINT_LIST:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

function getVendorPointListReducer(state = { data: [] }, action: any) {
  switch (action.type) {
    case GET_VENDOR_POINT_LIST:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}


function getDataOfferVendorPointListReducer(state = { data: [] }, action: any) {
  switch (action.type) {
    case GET_DATA_OFFER_VENDOR_POINT_LIST:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

function getUserWalletReducer(state = { data: {} }, action: any) {
  switch (action.type) {
    case GET_USER_WALLET:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

function getRiderWalletReducer(state = { data: {} }, action: any) {
  switch (action.type) {
    case GET_RIDER_WALLET:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

function getIsRiderHaveToPayReducer(state = { data: {} }, action: any) {
  switch (action.type) {
    case GET_IS_RIDER_HAVE_TO_PAY:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

function getCompanyWalletReducer(state = { data: {} }, action: any) {
  switch (action.type) {
    case GET_ADMIN_WALLET:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

function getVendorWalletReducer(state = { data: {} }, action: any) {
  switch (action.type) {
    case GET_VENDOR_WALLET:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

function getDatOfferVendorWalletReducer(state = { data: {} }, action: any) {
  switch (action.type) {
    case GET_DATA_OFFER_VENDOR_WALLET:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}


function getUserWithdrawRequestListReducer(state = { data: [] }, action: any) {
  switch (action.type) {
    case GET_USER_WITHDRAW_LIST:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

function getWalletRechargeNagadListReducer(state = { data: [] }, action: any) {
  switch (action.type) {
    case GET_WALLET_RECHARGE_NAGAD_LIST:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

function getVendorWithdrawRequestListReducer(state = { data: [] }, action: any) {
  switch (action.type) {
    case GET_VENDOR_WITHDRAW_LIST:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

function getUserPullHistoryReducer(state = { data: [] }, action: any) {
  switch (action.type) {
    case GET_USER_PULL_HISTORY:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

function getVendorPullHistoryReducer(state = { data: [] }, action: any) {
  switch (action.type) {
    case GET_VENDOR_PULL_HISTORY:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

function getDataOfferVendorPullHistoryReducer(state = { data: [] }, action: any) {
  switch (action.type) {
    case GET_DATA_OFFER_VENDOR_PULL_HISTORY:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

function getUserWithdrawHistoryReducer(state = { data: [] }, action: any) {
  switch (action.type) {
    case GET_USER_WITHDRAW_HISTORY:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

function getVendorWithdrawHistoryReducer(state = { data: [] }, action: any) {
  switch (action.type) {
    case GET_VENDOR_WITHDRAW_HISTORY:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

function getDatOfferVendorWithdrawHistoryReducer(state = { data: [] }, action: any) {
  switch (action.type) {
    case GET_DATA_OFFER_VENDOR_WITHDRAW_HISTORY:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

function getuserPointReducer(state = { data: {} }, action: any) {
  switch (action.type) {
    case GET_USER_POINT:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

function getUserTotalPointsReducer(state = { data: {} }, action: any) {
  switch (action.type) {
    case GET_USER_TOTAL_POINT:
      return { loading: false, data: action.payload };
    default:
      return state;
  }
}

function getResellerTeamReducer(state = { data: [] }, action: any) {
  switch (action.type) {
    case GET_RESELLER_TEAM:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

function getAffiliaterTeamReducer(state = { data: [] }, action: any) {
  switch (action.type) {
    case GET_AFFILIATER_TEAM:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

function getCompanyTeamReducer(state = { data: [] }, action: any) {
  switch (action.type) {
    case GET_COMPANY_TEAM:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

function getVendorReducer(state = { data: [] }, action: any) {
  switch (action.type) {
    case VENDOR_DETAILS_WITH_CAT:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}


function getWalletCalculationsReducer(state = { data: [] }, action: any) {
  switch (action.type) {
    case GET_ADMIN_WALLET_CALCULATIONS:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}



function getWalletRechargeHistoryReducer(state = { data: [] }, action: any) {
  switch (action.type) {
    case GET_WALLET_RECHARGE_LIST:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}


function getGiveBackHistoryReducer(state = { data: [] }, action: any) {
  switch (action.type) {
    case GET_GIVE_BACK_LIST:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

function IsVendorAllAmountPaidReducer(state = { data: false }, action: any) {
  switch (action.type) {
    case IS_VENDOR_ALL_AMOUNT_PAID:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}


function getBloodDonorsReducer(state = { data: [] }, action: any) {
  switch (action.type) {
    case GET_BLOOD_DONORS:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

function getDataOfferOrderListReducer(state = { data: [] }, action: any) {
  switch (action.type) {
    case GET_DATA_OFFER_ORDER_LIST:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

function getAreaSavedReducer(state = { data: true }, action: any) {
  switch (action.type) {
    case IS_AREA_SAVED:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}


const initState = {
  isOpen: false,
};

function openWithdrawReducer(state = initState, action: any) {
  if (action.type === "OPEN-WITHDRAW_MODAL") {
    return { isOpen: true };
  }
  if (action.type === "CLOSE-WITHDRAW_MODAL") {
    return { isOpen: false };
  }
  return state;
}

export {
  userListReducer,
  getUserReducer,
  getUserProfileReducer,
  getUserNameReducer,
  getuserPointListReducer,
  getCompanyPointListReducer,
  getVendorPointListReducer,
  getDataOfferVendorPointListReducer,
  getUserPullHistoryReducer,
  getVendorPullHistoryReducer,
  getDataOfferVendorPullHistoryReducer,
  getUserWithdrawHistoryReducer,
  getVendorWithdrawHistoryReducer,
  getDatOfferVendorWithdrawHistoryReducer,
  getUserWalletReducer as getuserWalletReducer,
  getVendorWalletReducer,
  getDatOfferVendorWalletReducer,
  getCompanyWalletReducer,
  getuserPointReducer,
  getUserTotalPointsReducer,
  getUserWithdrawRequestListReducer,
  getVendorWithdrawRequestListReducer,
  getWalletRechargeNagadListReducer,
  getResellerTeamReducer,
  getAffiliaterTeamReducer,
  getCompanyTeamReducer,
  getVendorReducer,
  openWithdrawReducer,
  userDetailsReducer,
  getDataOfferOrderListReducer,
  getWalletRechargeHistoryReducer,
  getGiveBackHistoryReducer,
  IsVendorAllAmountPaidReducer,
  getBloodDonorsReducer,
  getAreaSavedReducer,
  getWalletCalculationsReducer,
  getRiderWalletReducer,
  getIsRiderHaveToPayReducer,
  getRiderPointListReducer
}