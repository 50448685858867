import { AlertColor } from "@mui/material/Alert";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import {
  cartItemsSelectionService,
  increseDecreseCartItemService,
  removeSingleOrListCartitemService,
} from "../../../httpService/shoppingCart/resellingCartServices";
import {
  CartItemSelectionRequest,
  IncreseDecreseCartItemRequest,
  RemoveSingleOrListCartitemRequest,
  ShoppingCartFavouriteModel,
  ShoppingCartType,
} from "../../../models/shoppingCart/resellingCartModels";
import { RootState } from "../../../store";
import {
  getCartItemListAction,
  getTotalShippingChargeAction,
  getTotalShoppingCartItemAction,
} from "../../../store/actions/cart/resellingCartActions";
import { AppCheckBox } from "../../common/AppCheckBox";
import { AppSnackBar } from "../../common/AppSnackBar";
import { HiMinus, HiPlus } from "react-icons/hi";
import { TbCurrencyTaka } from "react-icons/tb";
import { AppButton } from "../../common/AppButton";
import { AppLoader } from "../../common/AppLoader";

export default function ResellingGetCartItems() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const [cartItems, setCartItems] = useState<ShoppingCartFavouriteModel[]>([]);

  const [totalPrice, setTotalPrice] = useState(0);
  const [allTotalPrice, setAllTotalPrice] = useState(0);

  const [isRemoveButtonDisable, setIsRemoveButtonDisable] = useState(false);

  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

  var token = reactLocalStorage.getObject("SignIn");

  const cartItemsSignIn = useSelector((state: RootState) => {
    return state.getTotalCartitemListResponse.data;
  });

  const cartItemsMemo = useMemo(() => {
    return cartItemsSignIn as ShoppingCartFavouriteModel[];
  }, [cartItemsSignIn]);

  useEffect(() => {

    if (token && JSON.stringify(token) !== JSON.stringify({})) {
      if (cartItemsMemo) {
        setCartItems(cartItemsMemo);
        calculateTotalEarning(cartItemsMemo);
      }
    } else {
    }
  }, [cartItemsMemo, token]);

  const groupedProducts = cartItemsMemo.reduce((result: any, product: ShoppingCartFavouriteModel) => {
    const index = result.findIndex((vendorProducts: any) => vendorProducts[0].vendorId === product.vendorId);
    if (index >= 0) {
      result[index].push(product);
    } else {
      result.push([product]);
    }
    return result;
  }, []);

  useEffect(() => {
    if (token && JSON.stringify(token) === JSON.stringify({})) {
      const pItems = reactLocalStorage.getObject(
        "CartItems"
      ) as ShoppingCartFavouriteModel[];
      if (pItems) {
        setCartItems(pItems);
      }
    }
  }, [token]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await dispatch(getCartItemListAction(ShoppingCartType.ShoppingCart));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);


  useEffect(() => {
    getTotalPrice();
  }, [cartItems]);

  const uniqueVendors = new Set<string>();

  const [selectedVendorCount, setSelectedVendorCount] = useState(0);



  const getTotalPrice = () => {
    let p = 0;
    if (cartItems && JSON.stringify(cartItems) !== JSON.stringify({})) {
      cartItems?.filter(_ => _.isSelected)?.map((x: any) => {
        if (x.vendorName) {
          uniqueVendors.add(x.vendorName);
        }
        return p = p + x.totalPrice;
      });
      setTotalPrice(p);
      setSelectedVendorCount(uniqueVendors.size)
      setAllTotalPrice(p + insideDhakAShippingCharge)
    }
  };

  const removeCartItem = (item: any) => {
    if (token && JSON.stringify(token) !== JSON.stringify({})) {
      var ids = [];
      ids.push(item.id);

      let payload = new RemoveSingleOrListCartitemRequest();
      payload.ids = ids;
      payload.cartType = ShoppingCartType.ShoppingCart;

      setIsRemoveButtonDisable(true);
      removeSingleOrListCartitemService(payload)
        .then((res: any) => {

          setTimeout(() => {
            dispatch(getCartItemListAction(ShoppingCartType.ShoppingCart));
            dispatch(getTotalShoppingCartItemAction(res.data.totalItem));
            setIsRemoveButtonDisable(false);
          }, 1000);

          setIsShowSnackBar(true);
          setSnackBarMessage("Successfully Removed");
          setSnackBarMessageType("error");
          setTimeout(() => {
            setIsShowSnackBar(false);
          }, 3000);

        })
        .catch(() => {
          setIsRemoveButtonDisable(false);
        });
    } else {
      let price =
        parseFloat(totalPrice.toString()) - parseFloat(item?.totalPrice);
      setTotalPrice(price);
      setAllTotalPrice(price + insideDhakAShippingCharge)
      var newItems = cartItems?.filter((_) => _.productId !== item.productId);

      var totalItem = 0;
      totalItem = newItems?.reduce(
        (q: any, p: any) => (q + p.productQuantity) as number,
        0
      );
      dispatch(getTotalShoppingCartItemAction(totalItem));

      reactLocalStorage.setObject("CartItems", newItems);
      setCartItems(newItems);
    }
  };

  const handleCountChange = (type: any, index: any, item: any) => {

    if (type === "increse") {
      const currentItems: any = [];

      var req = new IncreseDecreseCartItemRequest();
      req.id = item.id;
      req.cartType = 1;
      req.isIncrese = true;

      if (token && JSON.stringify(token) !== JSON.stringify({})) {
        increseDecreseCartItemService(req)
          .then((res) => {
            if (res?.data?.isOperationSuccessfull) {
              dispatch(getCartItemListAction(ShoppingCartType.ShoppingCart));
              dispatch(getTotalShoppingCartItemAction(res.data.totalItem));
            }
            else {
              setIsShowSnackBar(true);
              setSnackBarMessage("Out Of Stock");
              setSnackBarMessageType("warning");
              setTimeout(() => {
                setIsShowSnackBar(false);
              }, 2000);
            }

          })
          .catch((err) => { });
      } else {
        cartItems.forEach((item: any) => {
          if (!currentItems.some((x: any) => x.productId === item.productId)) {
            currentItems.push(item);
          }
        });

        var quantity = currentItems[index].productQuantity;
        currentItems[index].productQuantity = quantity ? quantity + 1 : 1;

        var currentQuantity = currentItems[index].productQuantity;
        var untPrice = currentItems[index].unitPrice;

        currentItems[index].totalPrice = currentQuantity * untPrice;

        let price = parseFloat(totalPrice.toString()) + parseFloat(untPrice);

        setTotalPrice(price);
        setAllTotalPrice(price + insideDhakAShippingCharge);

        setCartItems([]);
        setCartItems(currentItems);

        reactLocalStorage.setObject("CartItems", cartItems);

        var totalItem = 0;
        totalItem = currentItems?.reduce(
          (q: any, p: any) => (q + p.productQuantity) as number,
          0
        );
        dispatch(getTotalShoppingCartItemAction(totalItem));
      }
    }

    if (type === "decrese") {
      if (token && JSON.stringify(token) !== JSON.stringify({})) {
        if (item.productQuantity > 1) {
          var req = new IncreseDecreseCartItemRequest();
          req.id = item.id;
          req.cartType = 1;
          req.isIncrese = false;

          increseDecreseCartItemService(req)
            .then((res) => {
              dispatch(getCartItemListAction(ShoppingCartType.ShoppingCart));
              dispatch(getTotalShoppingCartItemAction(res.data.totalItem));
            })
            .catch((err) => { });
        }
      } else {

      }
    }
  };

  const handleNavigateToBillingAndShippingAddress = () => {
    if (cartItems?.length <= 0 || JSON.stringify(cartItems) === JSON.stringify({})) {
      setIsShowSnackBar(true);
      setSnackBarMessage("Your cart list is empty");
      setSnackBarMessageType("error");
      setTimeout(() => {
        setIsShowSnackBar(false);
      }, 3000);
    } else {
      if (cartItems?.filter((_) => _.isSelected).length <= 0) {
        setIsShowSnackBar(true);
        setSnackBarMessage("Please select item(s)");
        setSnackBarMessageType("error");
        setTimeout(() => {
          setIsShowSnackBar(false);
        }, 3000);
      } else {
        if (token && JSON.stringify(token) !== JSON.stringify({})) {

        } else {
          dispatch({ type: "OPEN-SIGN-IN" });
          dispatch({ type: "CLOSE-SIGN-UP" });
        }
      }
    }
  };

  const [totalEarning, setTotalEarning] = useState(0);

  const handleItemSelected = (item: any, isChecked: any) => {
    if (JSON.stringify(token) !== JSON.stringify({})) {

      setIsShippingChargeSelected(false);

      var models: CartItemSelectionRequest[] = [];

      cartItems.map((i: ShoppingCartFavouriteModel) => {
        var model = new CartItemSelectionRequest();
        if (item.id === i.id) {
          model.id = i.id;
          model.productId = i.productId;
          model.isSelected = isChecked;
          models.push(model);
        }
      });

      cartItemsSelectionService(models)
        .then((res: any) => {
          const newItems = cartItems.map((i: ShoppingCartFavouriteModel) => {
            if (item.id === i.id) {
              i.isSelected = isChecked;
            }
            return i;
          });
          setCartItems(newItems);
          dispatch(getCartItemListAction(ShoppingCartType.ShoppingCart));
          //calculateTotalEarning(cartItemsMemo);
        })
        .catch(() => { });
    } else {
      const pItems = reactLocalStorage.getObject(
        "CartItems"
      ) as ShoppingCartFavouriteModel[];
      const newItems = pItems.map((i: ShoppingCartFavouriteModel) => {
        if (item.productId === i.productId) {
          i.isSelected = isChecked;
        }
        return i;
      });
      setCartItems(newItems);
      reactLocalStorage.setObject("CartItems", newItems);
    }
  };

  const calculateTotalEarning = (items: ShoppingCartFavouriteModel[]) => {
    let total = 0;
    items.filter((_: any) => _.isSelected).forEach((item: ShoppingCartFavouriteModel) => {
      total += item.totalIncome ? item.totalIncome : 0;
    });
    setTotalEarning(total);
  };

  const handleSelectAll = (isChecked: any) => {

    if (JSON.stringify(token) !== JSON.stringify({})) {

      setIsShippingChargeSelected(false);

      var models: CartItemSelectionRequest[] = [];

      cartItems.map((i: ShoppingCartFavouriteModel) => {
        var model = new CartItemSelectionRequest();
        model.id = i.id;
        model.productId = i.productId;
        model.isSelected = isChecked;
        models.push(model);
      });

      cartItemsSelectionService(models)
        .then((res: any) => {
          const newItems = cartItems.map((i: ShoppingCartFavouriteModel) => {
            i.isSelected = isChecked;
            return i;
          });
          setCartItems(newItems);
          //calculateTotalEarning(newItems);
        })
        .catch(() => { });
    } else {
      const pItems = reactLocalStorage.getObject(
        "CartItems"
      ) as ShoppingCartFavouriteModel[];
      const newItems = pItems.map((i: ShoppingCartFavouriteModel) => {
        i.isSelected = isChecked;
        return i;
      });
      reactLocalStorage.setObject("CartItems", newItems);
      setCartItems(newItems);
    }
  };

  const handleNavigateToHome = () => {
    navigate('/reselling-platform');
  };

  const navigateToProductDetails = (id?: any) => {
    navigate(`/reselling-platform/productDetails/${id}`);
  };

  const insideDhakAShippingCharge = 60;
  const outsideDhakAShippingCharge = 120;

  const [isInDhaka, setIsInDhaka] = useState(false);
  const [isOutSideDhaka, setIsOutSideDhaka] = useState(false);

  const [shippingCharge, setShippingCharge] = useState(insideDhakAShippingCharge);
  const [isShippingChargeSelected, setIsShippingChargeSelected] = useState(false);

  const handleShippingCharge = (inDhaka: any) => {
    if (inDhaka) {
      dispatch(getTotalShippingChargeAction(true));
      setIsInDhaka(true)
      setIsOutSideDhaka(false)
      setShippingCharge(insideDhakAShippingCharge * selectedVendorCount)
      setAllTotalPrice(totalPrice + (insideDhakAShippingCharge * selectedVendorCount))
    } else {
      dispatch(getTotalShippingChargeAction(false));
      setIsInDhaka(false)
      setIsOutSideDhaka(true)
      setShippingCharge(outsideDhakAShippingCharge * selectedVendorCount)
      setAllTotalPrice(totalPrice + (outsideDhakAShippingCharge * selectedVendorCount))
    }
    setIsShippingChargeSelected(true)
  }

  const getTotalSAhippingCharge = useSelector((state: RootState) => {
    return state.getTotalShippingChargeResponse.data;
  });

  const getTotalSAhippingChargeMemo = useMemo(() => {
    return getTotalSAhippingCharge;
  }, [getTotalSAhippingCharge]);

  if (isLoading) {
    return (
      <div className="container h-screen flex items-center justify-center mx-auto px-2 md:px-0 pb-12">
        <AppLoader status={true} />
      </div>
    );
  }

  return (
    <>
      {isShowSnackBar && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}

      {cartItems?.length === 0 &&
        <div className="flex flex-col min-h-[90dvh] pb-8 justify-center items-center">
          <img className="w-11/12 md:w-1/3 md:h-1/3 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/image_1_1_u2ahiz.png" alt="" />
          <h2 className="pb-2">Your shopping cart is empty.</h2>
          <AppButton className="resellingBtn" text="Continue shopping" onClick={handleNavigateToHome} />
        </div>
      }

      {
        cartItems?.length !== 0 &&
        <div className="customer-shopping-cart-design container mx-auto flex flex-col md:flex-row justify-between gap-x-4 lg:pt-4 pb-8 2xl:py-3 lg:py-2 py-2 px-2 md:px-16 lg:px-28">
          <div className="w-full md:w-3/4">
            <h3 className="title pb-1">My cart</h3>
            {/* <div className="shopping-cart-items-title">
            Total {cartItems?.length} Items
              {cartItems?.length != 0 && (
                <h3>
                  Total Selcted (
                  {cartItems?.filter((_) => _.isSelected)?.length})
                </h3>
              )}
          </div> */}P
            {cartItems?.length !== 0 && (
              <div className="flex items-center justify-between md:pr-4">
                <div className="flex items-center md:gap-x-2 2xl:py-3 py-1 pl-2 2xl:pl-4">
                  <AppCheckBox
                    isChecked={cartItems?.length === cartItems?.filter((_) => _.isSelected).length
                    } onChange={handleSelectAll}
                  />
                  <span className="uppercase 2xl:text-lg"> Select all </span>
                </div>
                <div className="flex items-center gap-1">
                  <h2 className="subTitle flex items-center">Total Earnings: <TbCurrencyTaka /> {totalEarning}</h2>
                </div>
              </div>
            )}

            {/* these is cart product info with scrollbar for desktop  */}
            <div className="max-h-[70dvh] overflow-y-auto space-y-3 mt-1">
              {groupedProducts.map((vendorProducts: any, index: any) => (
                <section key={index} className="myBorder shadow hover:shadow-lg pb-1">
                  <div className="text-center 2xl:p-2 p-1 gap-x-2 border-b border-grey">
                    <h2 className="2xl:text-xl text-lg font-medium">{vendorProducts[0].vendorName}</h2>
                  </div>
                  {/* these is header section for title */}
                  <div className="flex items-center text-sm lg:text-base font-medium 2xl:px-4 lg:px-2 px-1 py-1 md:py-2">
                    <div className="w-[56%] lg:w-[52%] pl-2">
                      <h3>Product</h3>
                    </div>
                    <div className="w-[18%] lg:w-[16%] text-center">
                      <h3>Price</h3>
                    </div>
                    <div className="w-[8%] lg:w-[16%] text-center">
                      <h3 className="lg:hidden">Qty</h3>
                      <h3 className="hidden lg:block">Quantity</h3>
                    </div>
                    <div className="w-[18%] lg:w-[16%] text-center">
                      <h3>Total</h3>
                    </div>
                  </div>

                  {vendorProducts.map((item: any, indx: any) => (
                    <div className="flex items-center 2xl:px-4 lg:px-2 px-1 py-0.5 md:py-1" key={indx}>
                      {/* product section here  */}
                      <div className="flex items-center w-[56%] lg:w-[52%]">
                        {/* product section checkbox here  */}
                        <AppCheckBox
                          className="w-5 h-5"
                          isChecked={item?.isSelected}
                          onChange={(e: any) =>
                            handleItemSelected(item, e)
                          }
                        />
                        {/* product details section here  */}
                        <div className="pl-1.5 lg:pl-2.5 flex items-center md:gap-x-2.5 gap-x-1">
                          <img onClick={() => navigateToProductDetails(item.productId)} className='md:w-14 w-8 md:h-14 h-8 object-cover rounded' src={item?.bannerPath} alt="These is product pic" />
                          {/* this is product details details section  */}
                          <div className="text-xsm md:text-base">
                            <h3 className="cursor-pointer " onClick={() => navigateToProductDetails(item.productId)}>
                              <span className="lg:hidden">
                                {item?.productName.length > 16
                                  ? `${item?.productName.substring(0, 16)}...`
                                  : item?.productName}
                              </span>
                              <span className="hidden lg:block">
                                {item?.productName.length > 40
                                  ? `${item?.productName.substring(0, 40)}...`
                                  : item?.productName}
                              </span>
                            </h3>

                            {item?.attributes && (
                              <div className="">
                                {item?.attributes?.map((attribute: any, x: any) => (
                                  attribute?.name === "Color" ? (
                                    <div key={x}>
                                      <p>{attribute?.name}:</p>
                                      <div style={{ background: attribute?.value }}></div>
                                    </div>
                                  ) : (
                                    <div className="flex items-center justify-between">
                                      <div key={x} className="flex items-center gap-x-[2px] md:gap-x-1">
                                        <p>{attribute?.name}:</p>
                                        <div>{attribute?.value}</div>
                                      </div>
                                      {/* <AppButton text="-" isDisable={isRemoveButtonDisable} className="bg-red text-white p-1 rounded font-extrabold" onClick={() => removeCartItem(item)} /> */}
                                    </div>
                                  )
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                        {/* these remove button for desktop  */}
                        <div className="flex justify-end items-end ml-auto mt-auto lg:mt-0">
                          <AppButton text="-" isDisable={isRemoveButtonDisable} className="bg-red h-3 w-4 md:w-6 lg:h-4 pb-2 md:pb-2 flex justify-center text-2xl items-center rounded-sm text-white" onClick={() => removeCartItem(item)} />
                        </div>
                      </div>
                      {/* these ia price section  */}
                      <div className="w-[18%] lg:w-[16%] flex flex-col gap-0.5 items-center justify-center">
                        <span className="flex items-center justify-center text md:gap-x-1 gap-x-[1px]"> <TbCurrencyTaka /> {item?.unitPrice}</span>
                        <div onClick={() => handleCountChange("decrese", indx, item)} className="lg:hidden flex w-4 h-4 p-0.5 text-sm cursor-pointer justify-center items-center bg-white myBorder border-[.5px] rounded-[1px]"> <HiMinus />   </div>
                      </div>
                      {/* these is quantity section  */}
                      <div className="w-[8%] lg:w-[16%] flex flex-col-reverse md:flex-row items-center justify-center mt-auto lg:mt-0 gap-x-4 gap-y-[2px]">
                        <div onClick={() => handleCountChange("decrese", indx, item)} className="hidden lg:flex md:w-6 w-5 md:h-6 h-5 text-base cursor-pointer justify-center items-center bg-white myBorder rounded-sm"> <HiMinus />   </div>
                        <span className="md:text-lg text-xs font-medium">
                          {item?.productQuantity}
                        </span>
                        <div onClick={() => handleCountChange("increse", indx, item)} className="hidden lg:flex md:w-6 w-5 md:h-6 h-5 text-base cursor-pointer justify-center items-center bg-white myBorder rounded-sm"> <HiPlus /> </div>
                      </div>
                      {/* these is total section  */}
                      <div className="w-[18%] lg:w-[16%] flex flex-col gap-0.5 items-center justify-center">
                        <span className="flex items-center justify-center text md:gap-x-1 gap-x-[1px]"> <TbCurrencyTaka /> {item?.totalPrice}</span>
                        <div onClick={() => handleCountChange("increse", indx, item)} className="lg:hidden flex w-4 h-4 p-0.5 text-sm cursor-pointer justify-center items-center bg-white myBorder border-[.5px] rounded-[1px]"> <HiPlus /> </div>
                      </div>
                    </div>
                  ))}
                </section>
              ))}
            </div>

          </div>

          {/* <ShoppingCartSummary section start here  */}
          <div className="w-full mb-4 md:mb-0 md:w-1/4 2xl:mt-20 md:mt-16 mt-2 h-fit bg-bgSecondary rounded px-4 py-6 2xl:space-y-7 space-y-6">
            <h3 className="title">Order Summary</h3>
            <div className="2xl:space-y-3 space-y-2">
              {/* subtotal items here  */}
              <div className="flex justify-between 2xl:text-xl text-lg items-center">
                <h3>Subtotal <span>({cartItems?.length} items)</span></h3>
                <p className="flex items-center gap-x-1 justify-end"><TbCurrencyTaka /> <span>{totalPrice}</span></p>
              </div>
              {/* shipping charge here  */}
              <div className="flex justify-between items-center gap-x-4">
                <div className={`${isInDhaka && isShippingChargeSelected ? 'resellingBtn' : 'resellingOutlineBtn'} text-center`} onClick={() => handleShippingCharge(true)}>In Dhaka</div>
                <div className={`${isOutSideDhaka && isShippingChargeSelected ? 'resellingBtn' : 'resellingOutlineBtn'} text-center`} onClick={() => handleShippingCharge(false)}>OutSide Dhaka</div>
              </div>
              {isShippingChargeSelected &&
                <>
                  <div className="flex flex-col justify-between">
                    <h3 className="font-medium lg:text-lg 2xl:text-xl">Shipping Charge:</h3>
                    {getTotalSAhippingChargeMemo?.shippingCharges?.map((_: any) => {
                      return (
                        <div className="flex items-center gap-x-1 justify-between text-sm lg:text-base pl-4">
                          <p>{_.vendorName}</p>
                          <p className="flex items-center"><TbCurrencyTaka />{_.charge}</p>
                        </div>
                      )
                    })}
                    {/* <p className="flex items-center gap-x-1 justify-end"><TbCurrencyTaka /> <span>{shippingCharge}</span></p> */}
                  </div>
                  <div className="flex items-center font-medium gap-x-1 justify-between">
                    <p>Total Shipping Charge:</p>
                    <p className="flex items-center"><TbCurrencyTaka /> {getTotalSAhippingChargeMemo?.totalCharge}</p>
                  </div>

                  <hr className="border border-textBlack my-1" />
                  {/* total amount here  */}
                  <div className="flex justify-between subTitle items-center">
                    <h3>Total</h3>
                    <p className="flex items-center gap-x-1 justify-end"><TbCurrencyTaka /> <span>{totalPrice + getTotalSAhippingChargeMemo?.totalCharge}</span></p>
                  </div>
                </>
              }
            </div>
            {/* proceed to checkout btn here  */}


            {(isShippingChargeSelected &&
              (
                <>
                  {(cartItems?.filter((_: any) => _.isSelected).length > 0) ? (
                    <div className="resellingBtn text-center mx-8" onClick={handleNavigateToBillingAndShippingAddress}>
                      <Link to="/reselling-platform/submitBillingAndShippingAddress">
                        Proceed to checkout
                      </Link>
                    </div>
                  ) : (
                    <div className="resellingBtn text-center mx-8" onClick={handleNavigateToBillingAndShippingAddress}>
                      Proceed to checkout
                    </div>
                  )}
                </>
              )
            )}

          </div>
          {/* <RelatedProducts /> */}
        </div>
      }
      {/* these shopping cart design for desktop  */}

    </>
  );

}
