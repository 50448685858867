import { SSAdminGetOrderListRequest, SSCancelOrderRequest, SSCompleteOrderRequest, SSConfirmOrderRequest, SSGetOrderDetailsForAdminRequest, SSGetOrderDetailsRequest, SSGetOrderListRequest, SSReturnOrderRequest, SSShippedOrderRequest, SSSubmitOrderRequest } from "../../models/superShopp/order/sSOrderModel";
import { GET, POST, PUT } from "../service";

export const sSSubmitOrderWithMainBalanceService = (data: SSSubmitOrderRequest) => {
    return POST("/api/sSOrder/sSSubmitOrderWithMainBalance", data);
};

export const sSGetOrderListService = (payload?: SSGetOrderListRequest) => {
    return GET(
        `/api/sSOrder/sSGetOrderList?orderId=${payload?.orderId}&searchQuery=${payload?.searchQuery}&orderStatus=${payload?.orderStatus}&paymentStatus=${payload?.paymentStatus}&shippingStatus=${payload?.shippingStatus}&offset=${payload?.offset}&limit=${payload?.limit}`
    );
};

export const sSAdminGetOrderListService = (payload?: SSAdminGetOrderListRequest) => {
    return GET(
        `/api/sSOrder/sSAdminGetOrderList?orderId=${payload?.orderId}&searchQuery=${payload?.searchQuery}&orderStatus=${payload?.orderStatus}&paymentStatus=${payload?.paymentStatus}&shippingStatus=${payload?.shippingStatus}&offset=${payload?.offset}&limit=${payload?.limit}`
    );
};

export const sSGetOrderDetailsService = (payload?: SSGetOrderDetailsRequest) => {
    return GET(
        `/api/sSOrder/sSGetOrderDetails?OrderId=${payload?.orderId}`
    );
};

export const sSAdminGetOrderDetailsService = (payload?: SSGetOrderDetailsForAdminRequest) => {
    return GET(
        `/api/sSOrder/SSAdminGetOrderDetails?orderId=${payload?.orderId}`
    );
};

export const sSConfirmOrderService = (data: SSConfirmOrderRequest) => {
    return PUT(`/api/sSOrder/sSConfirmOrder`, data);
};

export const sSCancelOrderProductsService = (data: SSCancelOrderRequest) => {
    return PUT(`/api/sSOrder/sSCancelOrderProducts`, data);
};

export const sSReturnOrderService = (data: SSReturnOrderRequest) => {
    return PUT(`/api/sSOrder/sSReturnOrder`, data);
};

export const sSCompleteOrderService = (data: SSCompleteOrderRequest) => {
    return PUT(`/api/sSOrder/sSCompleteOrder`, data);
};

export const sSShippedOrderService = (data: SSShippedOrderRequest) => {
    return PUT(`/api/sSOrder/sSShippedOrder`, data);
};
