import { getMagazinePostByIdService, getMagazinePostListService, getMyMagazinePostListService } from "../../../httpService/magazine/magazinePostServices";
import { GET } from "../../../httpService/service";
import { PaginationPayloadModel } from "../../../models/common/paginationModels";

export const magazinePostList = (payload?: PaginationPayloadModel) => async (dispatch: any) => {
    try {
        const data = getMagazinePostListService(payload);
        const pload = (await data).data;
        dispatch({ type: 'GET_MAGAZINE_POST_LIST', payload: pload });
    }
    catch (error: any) { }
}

export const magazineMyPostList = (payload?: PaginationPayloadModel) => async (dispatch: any) => {
    try {
        const data = getMyMagazinePostListService(payload);
        const pload = (await data).data;
        dispatch({ type: 'GET_MY_MAGAZINE_POST_LIST', payload: pload });
    }
    catch (error: any) { }
}

export const magazinePostById = (id: any) => async (dispatch: any) => {
    try {
        const data = getMagazinePostByIdService(id);
        const pload = (await data).data;
        dispatch({ type: 'GET_MAGAZINE_POST_BY_ID', payload: pload });
    }
    catch (error: any) { }
}