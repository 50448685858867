import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../store";
import { useEffect, useState } from "react";
import { getTrainingByIdAction } from "../../../store/actions/training/trainingAction";
import { AppButton } from "../../common/AppButton";
import { AlertColor } from "@mui/material";
import {
  CreateBkashPaymentRequest,
  CreateBkashPaymentResponse,
  CreateTrainingInvoiceRequest,
  ExecuteBkashPaymentRequest,
} from "../../../models/payment/paymnetModel";
import {
  createBkashPaymentService,
  createTrainingInvoice,
  executeBkashPaymentRequestService,
} from "../../../httpService/payment/paymnetServices";
import {
  submitTrainingPurchase,
  trainingPurchaseWithMainBalance,
} from "../../../httpService/training/trainingService";
import { reactLocalStorage } from "reactjs-localstorage";
import { BKASH_CHARGE_PERCENTAGE } from "../../../constants";
import { appUrl } from "../../../config/config";
import { GetUserWalletResponse } from "../../../models/auth/user/UserPointModels";
import { getUserWalletAction } from "../../../store/actions/auth/user/userActions";
import { AppSnackBar } from "../../common/AppSnackBar";
import { AppLoader } from "../../common/AppLoader";
import { TrainingPurchaseRequest } from "../../../models/training/trainingModels";

export default function TrainingPayment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const resellReferrerId = reactLocalStorage.get("resellRef") as string;
  const affiliateReferrerId = reactLocalStorage.get("affiliateRef") as string;

  const training = useSelector((state: RootState) => {
    return state.getTrainingByIdResponse.data;
  });

  const [isTrainingPurchaseSuccessfully, setIsTrainingPurchaseSuccessfully] =
    useState(false);

  useEffect(() => {
    dispatch(getTrainingByIdAction(id));
  }, [dispatch]);

  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const paymentID = urlParams.get("paymentID");
    const status = urlParams.get("status");

    if (paymentID && status) {
      let model = new ExecuteBkashPaymentRequest();
      model.paymentId = paymentID;
      model.status = status;
      setIsBuyButtonLoading(true);
      setIsLoading(true);
      executeBkashPaymentRequestService(model)
        .then((res: any) => {
          if (res?.data?.isSucceeded === false) {
            setOpenBkashPaymentOption(true);
            setIsLoading(false);
            setIsBuyButtonLoading(false);
            return;
          }
          if (res?.data?.isSucceeded === true) {

            var m = new TrainingPurchaseRequest();
            m.trainingId = id;
            m.resellReferrerId = resellReferrerId;
            m.affiliateReferrerId = affiliateReferrerId;
            setIsLoading(true);

            setIsBuyButtonLoading(true);
            submitTrainingPurchase(m)
              .then((res: any) => {
                setIsLoading(false);
                setIsTrainingPurchaseSuccessfully(true);
                setIsPaymentmessageShowing(true);
                setIsShowSnackBar(true);
                setSnackBarMessage("Training Purchased Successfully");
                setSnackBarMessageType("success");
                setTimeout(() => {
                  setIsShowSnackBar(false);
                }, 2000);

                reactLocalStorage.remove("SignIn");
                reactLocalStorage.remove("RoleName");
                reactLocalStorage.remove("UserId");
                reactLocalStorage.remove("resellRef");
                // navigate(`/`);
                setIsBuyButtonLoading(false);
              })
              .catch((error: any) => {
                setIsBuyButtonLoading(false);
              });
            setIsBuyButtonLoading(false);
          }
        })
        .catch(() => {
          setIsBuyButtonLoading(false);
        });
    }
  }, []);

  const [isBuyButtonLoading, setIsBuyButtonLoading] = useState(false);
  const [openBkashPaymentOption, setOpenBkashPaymentOption] = useState(false);

  const [isPaymentmessageShowing, setIsPaymentmessageShowing] = useState(false);
  const [paymentMessage, setPaymentMessage] = useState("");

  const trainingInvoice = () => {
    var mod = new CreateTrainingInvoiceRequest();
    mod.invoiceName = "Training Invoice";
    mod.invoiceDescription = "Reselling Training";
    mod.totalAmount = training.price + training.price * BKASH_CHARGE_PERCENTAGE;
    mod.trainingId = id;
    setIsBuyButtonLoading(true);
    setIsPaymentmessageShowing(false);
    createTrainingInvoice(mod)
      .then((response: any) => {
        let invoiceNumber = response?.data?.data as string;
        var model = new CreateBkashPaymentRequest();
        model.amount =
          training.price + training.price * BKASH_CHARGE_PERCENTAGE;
        model.callbackURL = `${appUrl}/trainingPayment/${id}`;
        model.invoiceNumber = invoiceNumber;

        createBkashPaymentService(model)
          .then((response: any) => {
            let res = response?.data as CreateBkashPaymentResponse;
            if (res?.isSucceeded) {
              window.location.replace(res.data?.bkashURL);
            } else {
              setOpenBkashPaymentOption(true);
              setIsPaymentmessageShowing(true);
              setPaymentMessage("Something Wrong");
              setIsBuyButtonLoading(false);
            }
          })
          .catch(() => {
            setOpenBkashPaymentOption(true);
            setIsPaymentmessageShowing(true);
            setPaymentMessage("Something Wrong");
            setIsBuyButtonLoading(false);
          });
      })
      .catch(() => {
        setOpenBkashPaymentOption(true);
        setIsPaymentmessageShowing(true);
        setPaymentMessage("Something Wrong");
        setIsBuyButtonLoading(false);
      });
  };

  const handleBuyTraining = () => {
    trainingInvoice();
  };

  const handleBuyTrainingFromBalance = () => {
    setIsSelectedMainBalance(true);
    setOpenBkashPaymentOption(false);
  };

  const handleBuyTrainingFromBkash = () => {
    setIsSelectedMainBalance(false);
    setOpenBkashPaymentOption(true);
  };

  const handleBuyTrainingFromMainBalance = () => {
    
    var payload = new TrainingPurchaseRequest();
    payload.trainingId = id;
    payload.resellReferrerId = resellReferrerId;
    payload.affiliateReferrerId = affiliateReferrerId;

    setIsBuyButtonLoading(true);
    trainingPurchaseWithMainBalance(payload)
      .then((res: any) => {
        setIsPaymentmessageShowing(true);
        setIsShowSnackBar(true);
        setSnackBarMessage("Training Purchased Successfully");
        setSnackBarMessageType("success");
        setTimeout(() => {
          setIsShowSnackBar(false);
        }, 2000);
        setIsTrainingPurchaseSuccessfully(true);
        reactLocalStorage.remove("SignIn");
        reactLocalStorage.remove("RoleName");
        reactLocalStorage.remove("UserId");
        reactLocalStorage.remove("resellRef");

        setIsBuyButtonLoading(false);
      })
      .catch(() => {
        setIsBuyButtonLoading(false);
      });
  };

  const [isSelectedMainBalance, setIsSelectedMainBalance] = useState(false);

  const wallet = useSelector((state: RootState) => {
    return state.getuserWalletResponse.data as GetUserWalletResponse;
  });

  useEffect(() => {
    dispatch(getUserWalletAction(0));
  }, [dispatch]);

  const [isLoading, setIsLoading] = useState(false);

  if (isLoading) {
    return (
      <div className="container mx-auto flex h-screen items-center justify-center px-2 pb-12 md:px-0">
        <AppLoader status={true} />
      </div>
    );
  }

  if (isTrainingPurchaseSuccessfully) {
    return (
      <div className="container mx-auto flex h-screen items-center justify-center px-2 pb-12 md:px-0">
        Congratulations Training purchse succesfully. Please login again to
        enjoy your new features.
      </div>
    );
  }

  if (isPaymentmessageShowing) {
    return (
      <div className="container mx-auto flex h-screen items-center justify-center px-2 pb-12 md:px-0">
        {paymentMessage}
      </div>
    );
  }

  return (
    <>
      {isShowSnackBar && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      <div className=" flex h-[50vh] items-center justify-center gap-2 px-5 lg:flex-row lg:gap-4">
        <AppButton
          className="myBtn h-[46px] w-full md:w-[250px] lg:w-[260px]"
          isSubmitting={isBuyButtonLoading}
          onClick={handleBuyTrainingFromBkash}
          text="Buy Training From Bkash"
        />
        <AppButton
          className="myBtn h-[46px] w-full border-royalBlue bg-royalBlue md:w-[250px] lg:w-[260px]"
          isSubmitting={isBuyButtonLoading}
          onClick={handleBuyTrainingFromBalance}
          text="Buy Training from MainBalance"
        />
      </div>

      {openBkashPaymentOption &&
        !training.isPurchased &&
        !isBuyButtonLoading &&
        !isSelectedMainBalance && (
          <div className="flex flex-col items-center justify-center gap-2 lg:flex-row lg:gap-8">
            <div
              onClick={() => handleBuyTraining()}
              className="myBorder flex w-fit cursor-pointer  items-center justify-center rounded border-primary p-2 hover:bg-primary lg:p-3"
            >
              <img
                className="h-8 w-14 rounded object-cover lg:h-10 lg:w-20"
                src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/image_1_2_h8fjvf.png"
                alt="bkash logo"
              />
            </div>
          </div>
        )}

      {isSelectedMainBalance &&
        !openBkashPaymentOption &&
        !training.isPurchased &&
        !isBuyButtonLoading && (
          <>
            <div className="p-2">
              {wallet.mainBalance >= training.price ? (
                <div className="py-2 text-center font-medium text-royalBlue">
                  <p>You have to pay: {training.price} BDT</p>
                  <p>Your Main Balance have: {wallet.mainBalance} BDT</p>
                  <p className="pb-1">
                    Your remaining balance will be:{" "}
                    {wallet.mainBalance - training.price} BDT
                  </p>
                  <AppButton
                    className="myBtn border-royalBlue bg-royalBlue"
                    isSubmitting={isBuyButtonLoading}
                    onClick={handleBuyTrainingFromMainBalance}
                    text="Buy"
                  />
                </div>
              ) : (
                <div className="text-center font-medium text-red">
                  <p>
                    You do not have sufficient balance you have{" "}
                    {wallet.mainBalance} BDT
                  </p>
                </div>
              )}
            </div>
          </>
        )}
    </>
  );
}
