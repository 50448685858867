import React, { useState } from "react";
import { catalog_http } from "../../httpService/service";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { AppLoader } from "./AppLoader";
import { AlertColor } from "@mui/material/Alert";
import { AppSnackBar } from "./AppSnackBar";

interface IFileInputProps {
  onSave?: any;
  multiSelect?: boolean;
  errorMessage?: string;
}

export const AppFileInput: React.FC<IFileInputProps> = ({
  onSave,
  multiSelect,
  errorMessage,
}) => {


  const [isLoading, setIsLoading] = React.useState(false);

  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();


  const MAX_FILE_SIZE_MB = 3;

  const handleChange = (e: any) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }
    if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      setIsShowSnackBar(true);
      setSnackBarMessage("File size must be less then 3 MB");
      setSnackBarMessageType("error");
      setTimeout(() => {
        setIsShowSnackBar(false);
      }, 2000);
      e.target.value = null;
      return;
    }

    const bodyFormData = new FormData();
    bodyFormData.append("image", file);

    setIsLoading(true);
    catalog_http
      .post("api/Document/UploadDocument", bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response: any) => {
        onSave(response.data.id, response.data.url);
        setIsLoading(false);
        e.target.value = null;
      })
      .catch((err: any) => {
        setIsLoading(false);
        e.target.value = null;
      });
  };


  return (
    <>
      {isShowSnackBar && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      <input
        key={`file-input-${Date.now()}`}
        accept="image/*"
        onChange={handleChange}
        style={{ display: "none" }}
        id="raised-button-file"
        multiple={multiSelect}
        type="file"
      />
      <label htmlFor="raised-button-file">
        {/* <Button variant="text" component="span" >
          Upload
        </Button> */}
        {/* <LoadingButton
          size="small"
          loading={isLoading}
          variant="outlined"
        >
          <span>Upload</span>
        </LoadingButton> */}
        <div style={{ marginTop: "0px" }}>
          {!isLoading ? (
            <PhotoCamera
              className="text-textBlack cursor-pointer w-9 md:w-10 h-9 md:h-10 "
            />
          ) : (
            <AppLoader status={isLoading} />
          )}
        </div>
        <p className="text-xs text-red pl-[2px]">{errorMessage}</p>
      </label>
    </>
  );
};
