import { AlertColor } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { TbCurrencyTaka } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { OrderProductCustomer } from "../../../models/order/resellingOrdersModels";
import { RootState } from "../../../store";
import _moment from "moment";
import { getOrderInvoiceByIdAction } from "../../../store/actions/invoices/orderInvoicesActions";
import { AppLoader } from "../../common/AppLoader";
import { AppSnackBar } from "../../common/AppSnackBar";

export default function IVOrderInvoice() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();

  const invoiceDetails = useSelector((state: RootState) => {
    return state.getOrderInvoiceByIdResponse.data;
  });

  const invoiceProducts = useMemo(() => {
    return invoiceDetails.orderProductList as OrderProductCustomer[];
  }, [invoiceDetails]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await dispatch(getOrderInvoiceByIdAction(id));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch, id]);

  const groupedProducts = invoiceProducts?.reduce(
    (result: any, product: OrderProductCustomer) => {
      const index = result.findIndex(
        (vendorProducts: any) => vendorProducts[0].vendorId === product.vendorId
      );
      if (index >= 0) {
        result[index].push(product);
      } else {
        result.push([product]);
      }
      return result;
    },
    []
  );

  const orderTimeFormatter = (createdDateTime: any) => {
    const createdTime = _moment(createdDateTime, "YYYY-MM-DD HH:mm:ss.SSSSSSS");
    return createdTime.format("MMM D, YY [at] h:mm A");
  };

  if (isLoading) {
    return (
      <div className="container mx-auto flex h-screen items-center justify-center px-2 pb-12 md:px-0">
        <AppLoader status={true} />
      </div>
    );
  }

  return (
    <div className="container mx-auto px-0 pb-8 md:px-16 md:py-4">
      {isShowSnackBar && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      <div className="flex flex-col items-center pb-2 lg:flex-row lg:items-center lg:justify-between">
        <h2 className="title">Invoice ({invoiceDetails?.paidStatus})</h2>
      </div>

      {groupedProducts?.map((vendorProducts: any, index: any) => (
        <section key={index} className="myBorder my-2 lg:my-4">
          {/* these is shop info  */}
          <div className="border-b border-grey px-2 py-1 lg:px-4">
            <div className="flex items-center justify-between">
              <h3 className="text-xs lg:text-base">
                <span className="font-medium">
                  {vendorProducts[0].vendorName}
                </span>
              </h3>
            </div>

            <div className="flex items-center justify-between">
              <h3 className="text-xs lg:text-base">
                <span className="font-medium text-royalBlue">
                  {vendorProducts[0].vendorPhone}
                </span>
              </h3>
            </div>
          </div>

          <div className="w-full px-2 lg:px-4">
            <div className="flex items-center justify-between text-xs font-medium lg:text-base">
              <div className="w-[40%] lg:w-[32%]">
                <span>Product</span>
              </div>
              <div className="w-[15%] text-center lg:w-[17%]">
                <span>Price</span>
              </div>
              <div className="w-[15%] text-center lg:w-[17%]">
                <span>Qty</span>
              </div>
              <div className="w-[15%] text-center lg:w-[17%]">
                <span>Total-Price</span>
              </div>
              <div className="w-[15%] text-center lg:w-[17%]">
                <span></span>
              </div>
            </div>
            {vendorProducts?.map((item: any, indx: any) => (
              <div
                key={indx}
                className="flex items-center justify-between py-1.5"
              >
                {/* product details section here  */}
                <div className="flex w-[40%] items-center gap-x-1 md:gap-x-4 lg:w-[32%] lg:justify-start">
                  {/* this is product details name section  */}
                  <div className="text-xs md:text-base">
                    <h3>{item?.productName}</h3>
                    {item?.attributes && (
                      <div className="">
                        {item?.attributes?.map((attribute: any, x: any) =>
                          attribute?.name === "Color" ? (
                            <div key={x}>
                              <p>{attribute?.name}:</p>
                              <div
                                style={{ background: attribute?.value }}
                              ></div>
                            </div>
                          ) : (
                            <div className="flex items-center justify-between">
                              <div
                                key={x}
                                className="flex items-center gap-x-[2px] md:gap-x-1"
                              >
                                <p>{attribute?.name}:</p>
                                <div>{attribute?.value}</div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {/* this is price section  */}
                <div className="flex w-[15%] items-center justify-center text-xs lg:w-[17%] lg:text-base">
                  <span className="flex items-center font-normal md:font-medium">
                    <TbCurrencyTaka /> {item?.unitPrice}
                  </span>
                </div>
                {/* this is quantity section  */}
                <div className="flex w-[15%] items-center justify-center text-xs lg:w-[17%] lg:text-base">
                  <span className=" font-normal md:font-medium">
                    {item?.quantity}
                  </span>
                </div>
                {/* this is total price  */}
                <div className="flex w-[15%] items-center justify-center text-xs lg:w-[17%] lg:text-base">
                  <span className="flex items-center font-normal md:font-medium">
                    {" "}
                    <TbCurrencyTaka /> {item?.totalPrice}
                  </span>
                </div>
                {/* these is buttons  */}
                <div className="flex w-[15%] justify-end lg:w-[17%]">
                  {item?.orderStatus === "Cancelled" && (
                    <div className="Cancelled">Cancelled</div>
                  )}
                  {item?.orderStatus === "Returned" && (
                    <div className="Returned">Returned</div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </section>
      ))}

      <div className="mb-3 flex justify-end">
        <h3 className="text-center">
          Total {invoiceProducts?.length} product
          {invoiceProducts?.length === 1 ?? "s"} ordered.
        </h3>
      </div>
      <div className="amounts">
        <div className="col-12 flex justify-end">
          <div className="col-6">Subtotal:</div>
          <div className="col-6">
            <span className="flex items-center font-normal md:font-medium">
              <TbCurrencyTaka />
              {invoiceDetails?.totalAmount - invoiceDetails?.deliveryCharge}
            </span>
          </div>
        </div>
        <div className="col-12 flex justify-end">
          <div className="col-6">Total Delivery Charge:</div>
          <div className="col-6">
            <span className="flex items-center font-normal md:font-medium">
              <TbCurrencyTaka />
              {invoiceDetails?.deliveryCharge}
            </span>
          </div>
        </div>
        <div className="col-12 flex justify-end">
          <div className="col-6">Tax:</div>
          <div className="col-6">
            <span className="flex items-center font-normal md:font-medium">
              <TbCurrencyTaka />
              {invoiceDetails?.tax}
            </span>
          </div>
        </div>
        <hr />
        <div className="col-12 flex justify-end">
          <div className="mx-2">Total Payable Amount: </div>
          <div className="col-6">
            <span className="flex items-center font-normal md:font-medium">
              <TbCurrencyTaka />
              {invoiceDetails?.totalAmount}
            </span>
          </div>
        </div>
      </div>
      <br />
      <div className="invoice-details my-5">
        <div className="col-12 flex justify-start">
          <div className="col-6 mx-2">Invoice Number:</div>
          <div className="col-6">
            <span className="flex items-center font-normal md:font-medium">
              {invoiceDetails?.invoiceNumber}
            </span>
          </div>
        </div>
        <div className="col-12 flex justify-start">
          <div className="col-6 mx-2">Due Date: </div>
          <div className="col-6">
            <span className="flex items-center font-normal md:font-medium">
              {orderTimeFormatter(invoiceDetails?.invoiceDateTime)}
            </span>
          </div>
        </div>
      </div>
      <div className="invoice-details my-5">
        <div className="title font-large">Account Details:</div>
        <div className="col-12 flex justify-start">
          <div className=" mx-2">Payment Method</div>
          <div className="col-6">
            <span className="flex items-center font-normal md:font-medium">
              {invoiceDetails?.paymentMethod}
            </span>
          </div>
        </div>
        <div className="col-12 flex justify-start">
          <div className="col-6 mx-2">Account Number: </div>
          <div className="col-6">
            <span className="flex items-center font-normal md:font-medium">
              {invoiceDetails?.paymentAccount?.length > 0
                ? invoiceDetails?.paymentAccount
                : invoiceDetails?.billingAddress?.resellerPhoneNumber}
            </span>
          </div>
        </div>
      </div>
      <div className="invoice-details my-5">
        <div className="title font-large">Billed To:</div>
        <div className="col-12 flex justify-end">
          <div>
            <p className="items-center font-normal md:font-medium">
              {invoiceDetails?.billingAddress?.firstName}{" "}
              {invoiceDetails?.billingAddress?.lastName}
            </p>
            <p className="items-center font-normal md:font-medium">
              {invoiceDetails?.billingAddress?.phoneNo1}
            </p>
            <p className="items-center font-normal md:font-medium">
              {invoiceDetails?.billingAddress?.email}
            </p>
            <p className="items-center font-normal md:font-medium">
              {invoiceDetails?.billingAddress?.addressLine1},{" "}
              {invoiceDetails?.billingAddress?.areaName},<br />
              {invoiceDetails?.billingAddress?.districtName},{" "}
              {invoiceDetails?.billingAddress?.divisionName} -{" "}
              {invoiceDetails?.billingAddress?.postCode}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
