import { GET_AFFILIATE_ALL_CART_ITEMS, GET_AFFILIATE_ALL_FAVOURITE_ITEMS, GET_AFFILIATE_CART_ITEMS_ADMIN_LIST, GET_AFFILIATE_CART_LIST_ADMIN_LIST, GET_AFFILIATE_IS_ALREADY_FAVOURITE, GET_AFFILIATE_TOTAL_CART_ITEM, GET_AFFILIATE_TOTAL_SHIPPING_CHARGE } from "../../constants/shoppingCart/affiliateShoppingCartConstants";

function getAffiliateTotalCartItemReducer(state = { data: 0 }, action: any) {
    switch (action.type) {
        case GET_AFFILIATE_TOTAL_CART_ITEM:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

function getAffiliateTotalCartItemListReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case GET_AFFILIATE_ALL_CART_ITEMS:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

function getAffiliateFavouriteItemReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case GET_AFFILIATE_ALL_FAVOURITE_ITEMS:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

function getIsAlreadyAffiliateFavouriteReducer(state = { data: false }, action: any) {
    switch (action.type) {
        case GET_AFFILIATE_IS_ALREADY_FAVOURITE:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

function getAffiliateCartListAdminReducer(state = { data: 0 }, action: any) {
    switch (action.type) {
        case GET_AFFILIATE_CART_LIST_ADMIN_LIST:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

function getAffiliateCartItemsAdminReducer(state = { data: 0 }, action: any) {
    switch (action.type) {
        case GET_AFFILIATE_CART_ITEMS_ADMIN_LIST:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}
function getAffiliateTotalShippingChargeReducer(state = { data: false }, action: any) {
    switch (action.type) {
        case GET_AFFILIATE_TOTAL_SHIPPING_CHARGE:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}


export {
    getAffiliateTotalCartItemReducer,
    getAffiliateFavouriteItemReducer,
    getAffiliateTotalCartItemListReducer,
    getAffiliateCartItemsAdminReducer,
    getAffiliateCartListAdminReducer,
    getAffiliateTotalShippingChargeReducer,
    getIsAlreadyAffiliateFavouriteReducer
}