import { SSGetProductBrandListRequest } from "../../models/superShopp/catalog/sSProductBrand/sSProductBrandModel";
import { DELETE, GET, POST, PUT } from "../service";

export const sSaddProductBrandService = (data: any) => {
  return POST("/api/sSProductBrand/sSCreateProductBrand", data);
};

export const sSdeleteProductBrandService = (data: any) => {
  return DELETE(`/api/sSProductBrand/sSRemoveProductBrand`, {
    data: { "ids": data },
  });
};

export const sSgetProductBrandListService = (payload?: SSGetProductBrandListRequest, isAll: any = true, forPublic: boolean = false) => {
  return GET(
    `/api/sSProductBrand/sSGetProductBrandList?bazarId=${payload?.bazarId}&isAll=${isAll}&forPublic=${forPublic}&limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}`
  );
};

export const sSGetProductBrandByIdService = (id: any) => {
	return GET(`/api/SSProductBrand/SSGetProductBrandById?Id=${id}`);
};


export const sSupdateProductBrandService = (data: any) => {
  return PUT(`/api/sSProductBrand/sSUpdateProductBrand`, data);
};
