import { getDataOfferListService, getDataOfferService } from "../../../httpService/dataOffer/dataOfferServices";
import { DataOfferListRequest } from "../../../models/auth/user/UserPointModels";
import { GET_DATA_OFFER, GET_DATA_OFFER_LIST } from "../../constants/auth/dataOffer/dataOfferConstatnt";

const getDataOfferListAction = (p?: DataOfferListRequest) => async (dispatch: any) => {
	try {
		const data = getDataOfferListService(p);
		const payload = (await data).data;
		dispatch({ type: GET_DATA_OFFER_LIST, payload: payload });
	} catch (error) { }
};

export const getDataOfferAction = (id: any) => async (dispatch: any) => {
	try {
		var data = getDataOfferService(id);
		const pload = (await data).data;
		dispatch({ type: GET_DATA_OFFER, payload: pload });
	}
	catch (error) { }
}

export { getDataOfferListAction };