import { GET_DOCTOR_LIST_BY_AREA, GET_DOCTOR_LIST_BY_AUTH_AREA, GET_DOCTOR_NUMBER } from "../../../constants/importantNumbers/doctorNumbers/doctorNumbersConstant";
import { GET_DOCTOR_LIST } from "../../../constants/importantNumbers/imamsNumbers/imamsNumbersConstant";

export function getDoctorNumbersReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case GET_DOCTOR_NUMBER:
            return { loading: false, data: action.payload };
        default:
            return state;
    }
}

export function getDoctorListReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case GET_DOCTOR_LIST:
            return { loading: false, data: action.payload };
        case GET_DOCTOR_LIST_BY_AREA:
            return { loading: false, data: action.payload };
        case GET_DOCTOR_LIST_BY_AUTH_AREA:
            return { loading: false, data: action.payload };
        default:
            return state;
    }
}