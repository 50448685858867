import {
  GET_ADMIN_PRODUCT_REVIEW_LIST,
  GET_PRODUCT_REVIEW_LIST, GET_PRODUCT_REVIEW_LIST_BY_ID
} from "../../constants/catalog/productReview";

const inititalState = {
  data: [],
  dataById: []
}

function productReviewListReducer(state = inititalState, action: any) {
  switch (action.type) {
    case GET_PRODUCT_REVIEW_LIST:
      return { ...state, loading: false, data: action.payload };
    case GET_PRODUCT_REVIEW_LIST_BY_ID:
      return { ...state, loading: false, dataById: action.payload };
    default:
      return state;
  }
}


function productAdminReviewListReducer(state = inititalState, action: any) {
  switch (action.type) {
    case GET_ADMIN_PRODUCT_REVIEW_LIST:
      return { ...state, loading: false, data: action.payload };
    case GET_PRODUCT_REVIEW_LIST_BY_ID:
      return { ...state, loading: false, dataById: action.payload };
    default:
      return state;
  }
}


export { productReviewListReducer, productAdminReviewListReducer };

