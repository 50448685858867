import { AlertColor } from "@mui/material";
import { useEffect, useState } from "react";
import { AppSnackBar } from "../../../../common/AppSnackBar";
import { AppInput } from "../../../../common/AppInput";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { AppButton } from "../../../../common/AppButton";
import { SSCreateRiderRequest } from "../../../../../models/superShopp/rider/riderModel";
import { sSCreateRiderService } from "../../../../../httpService/superShops/ssRiderServices";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { IdNameResponseModel } from "../../../../../models/common/IdNameModel";
import { PaginationPayloadModel } from "../../../../../models/common/paginationModels";
import { sSGetBazarListAction } from "../../../../../store/actions/superShop/sSBazarActions";
import { useNavigate } from "react-router";
import { AppSelect } from "../../../../common/AppSelect";
import { SSGetBazarListRequest } from "../../../../../models/superShopp/Address/sSBazar/sSBazarModel";

export default function SSCreateRider() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");

  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [emailOrPhoneError, setEmailOrPhoneError] = useState("");

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [bazarId, setBazarId] = useState(0);
  const [bazarError, setBazarError] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);

  const handleNameChange = (event: any) => {
    setFirstName(event);
    setFirstNameError("");
  };

  const handleEmailOrPhoneChange = (event: any) => {
    setEmailOrPhone(event);
    setEmailOrPhoneError("");
  };

  const handlePasswordChange = (event: any) => {
    setPassword(event);
    setPasswordError("");
  };

  const handleConfirmPasswordChange = (event: any) => {
    setConfirmPassword(event);
    setConfirmPasswordError("");
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmShowPassword(!showConfirmPassword);
  };

  const bazarList = useSelector((state: RootState) => {
    return state.sSBazarListResponse.data;
  });

  var idNameModels: IdNameResponseModel[] = [];
  bazarList.items?.map((x: { id: number; name: string | undefined }) => {
    var temp = new IdNameResponseModel();
    temp.id = x.id;
    temp.name = x.name;
    idNameModels.push(temp);
  });

  useEffect(() => {
    let paginationModel = new SSGetBazarListRequest();
    paginationModel.limit = 1000;
    paginationModel.offset = 0;
    dispatch(sSGetBazarListAction(paginationModel));
  }, []);

  const handleBazarIdChange = (event: any) => {
    setBazarId(event);
    setBazarError("");
  };

  const [isSignUpButtonLoading, setSignUpButtonLoading] = useState(false);

  const handleCreateRider = (event: any) => {

    event.preventDefault();

    document.body.classList.remove('noScroll');

    const user = new SSCreateRiderRequest();
    user.firstName = firstName;
    user.emailOrPhone = emailOrPhone;
    user.password = password;
    user.confirmPassword = confirmPassword;
    user.bazarId = bazarId;

    setSignUpButtonLoading(true)

    sSCreateRiderService(user)
      .then((res: any) => {
        setSignUpButtonLoading(false)
        setIsShowSnackBar(true);
        setSnackBarMessage("Successfully Created");
        setSnackBarMessageType("success");
        setIsShowSnackBar(false);
        setTimeout(() => {
          navigate("/super-shop/rider-list");
        }, 300);
      })
      .catch((error) => {
        setSignUpButtonLoading(false)
        setEmailOrPhoneError(error.response.data.errors?.EmailOrPhone?.[0]);
        setFirstNameError(error.response.data.errors?.FirstName?.[0]);
        setBazarError(error.response.data.errors.BazarId[0]);
        setPasswordError(error.response.data.errors?.Password?.[0]);
        setConfirmPasswordError(error.response.data.errors?.ConfirmPassword?.[0]);
      });
  };

  return (
    <div>

      <div className="justify-center items-center">

        {isShowSnackBar && (
          <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
        )}

        <div className="w-11/12 md:w-2/5 bg-white p-1 md:p-4 rounded">

          <div className="">

            <h2 className="subTitle text-center text-textBlack">Create Rider</h2>

            <div className="flex flex-col px-3 md:px-8 py-6">

              <AppInput
                type="text"
                label="Name"
                className="myInput"
                placeholder="Type your full name"
                onChange={handleNameChange}
                errorMessage={firstNameError} />


              <AppInput
                type="email"
                label="Phone"
                className="myInput"
                placeholder="Enter your Phone"
                onChange={handleEmailOrPhoneChange}
                errorMessage={emailOrPhoneError} />


              <div className='w-full lg:w-1/2'>
                <label className="text-sm lg:text-base" placeholder="Dhaka">Select Bazar</label>
                <AppSelect
                  value={bazarId}
                  data={idNameModels}
                  defaultValue="Select Bazar"
                  errorMessage={bazarError}
                  onChange={handleBazarIdChange}
                />
              </div>

              <div className="relative pt-2">
                <AppInput
                  type={showPassword ? "text" : "password"}
                  className="myInput"
                  label="Password"
                  placeholder="Enter your Password"
                  value={password}
                  onChange={handlePasswordChange}
                  errorMessage={passwordError} />
                {showPassword ? (
                  <AiOutlineEyeInvisible
                    className="absolute text-2xl text-gray-700 font-bold cursor-pointer right-3 top-9 lg:top-10"
                    onClick={togglePasswordVisibility} />
                ) : (
                  <AiOutlineEye
                    className="absolute text-2xl text-gray-700 font-bold cursor-pointer right-3 top-9 lg:top-10"
                    onClick={togglePasswordVisibility} />
                )}
              </div>

              <div className="relative pt-2">
                <AppInput
                  type={showConfirmPassword ? "text" : "password"}
                  className="myInput"
                  label="Confirm Password"
                  placeholder="Enter your Password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  errorMessage={confirmPasswordError} />
                {showConfirmPassword ? (
                  <AiOutlineEyeInvisible
                    className="absolute text-2xl text-gray-700 font-bold cursor-pointer right-3 top-9 lg:top-10"
                    onClick={toggleConfirmPasswordVisibility} />
                ) : (
                  <AiOutlineEye
                    className="absolute text-2xl text-gray-700 font-bold cursor-pointer right-3 top-9 lg:top-10"
                    onClick={toggleConfirmPasswordVisibility} />
                )}
              </div>

              <AppButton
                text="Create"
                isSubmitting={isSignUpButtonLoading}
                className="myBtn bg-royalBlue border-royalBlue"
                onClick={handleCreateRider} />

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
