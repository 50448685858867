import {
  getDataOfferInvoiceByIdService,
  getDataOfferInvoicesService,
  getOrderInvoiceByIdService,
  getOrderInvoicesService,
} from "../../../httpService/invoices/orderInvoicesServices";
import {
  GET_DATA_OFFER_INVOICES,
  GET_DATA_OFFER_INVOICE_BY_ID,
  GET_ORDER_INVOICES,
  GET_ORDER_INVOICE_BY_ID,
} from "../../constants/invoices/orderInvoicesConstant";

const getOrderInvoicesAction = (p: any) => async (dispatch: any) => {
  try {
    const data = getOrderInvoicesService(p);
    const payload = (await data).data;
    dispatch({ type: GET_ORDER_INVOICES, payload: payload });
  } catch (error: any) { }
};

const getOrderInvoiceByIdAction = (Id?: any) => async (dispatch: any) => {
  var data = getOrderInvoiceByIdService(Id);
  const pload = (await data).data;
  dispatch({ type: GET_ORDER_INVOICE_BY_ID, payload: pload });
};

const getDataOfferInvoicesAction = (p: any) => async (dispatch: any) => {
  try {
    const data = getDataOfferInvoicesService(p);
    const payload = (await data).data;
    dispatch({ type: GET_DATA_OFFER_INVOICES, payload: payload });
  } catch (error: any) { }
};

const getDataOfferInvoiceByIdAction = (Id?: any) => async (dispatch: any) => {
  var data = getDataOfferInvoiceByIdService(Id);
  const pload = (await data).data;
  dispatch({ type: GET_DATA_OFFER_INVOICE_BY_ID, payload: pload });
};

export {
  getOrderInvoicesAction,
  getOrderInvoiceByIdAction,
  getDataOfferInvoicesAction,
  getDataOfferInvoiceByIdAction,
};
