import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AlertColor, IconButton } from "@mui/material";
import { sSupdateMainCatagoryService } from "../../../../../httpService/superShops/ssMainCategoryServices";
import { SSUpdateMainCatagory } from "../../../../../models/superShopp/catalog/sSCategory/sSMainCategoryModel";
import { RootState } from "../../../../../store";
import { sSGetMainCategoryByIdAction, sSGetMainCategoryListAction } from "../../../../../store/actions/superShop/sSMainCategoryActions";
import { AppButton } from "../../../../common/AppButton";
import { AppFileInput } from "../../../../common/AppFileInput";
import { AppInput } from "../../../../common/AppInput";
import { AppSnackBar } from "../../../../common/AppSnackBar";
import { AppTextArea } from "../../../../common/AppTextArea";
import { AppLoader } from "../../../../common/AppLoader";

export default function AdminSSUpdateMainCategory() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const category = useSelector((state: RootState) => {
    return state.sSGetMainCategoryByIdResponse.data;
  });

  useEffect(() => {
    dispatch(sSGetMainCategoryByIdAction(id));
  }, [dispatch]);

  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [bannerError, setBannerError] = useState("");

  const [name, setName] = useState("");

  const [description, setDescription] = useState("");
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState("");

  const [isPublished, setIsPublished] = useState(false);
  const [bannerId, setBannerId] = useState(0);
  const [bannerPath, setBannerPath] = useState("");

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if ((category)) {
      try {
        setName(category.name);
        setDescription(category.description);
        setIsPublished(category.isPublished);
        setBannerId(category.banner?.id);
        setBannerPath(category.banner.url);
      } catch {
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    }
  }, [category]);


  const handleNameChange = (event: any) => {
    setName(event);
    setNameErrorMessage("");
  };
  const handleDescriptionChange = (event: any) => {
    setDescription(event);
    setDescriptionErrorMessage("");
  };

  const handleIsPublishedChange = (event: any) => [
    setIsPublished(event),
  ];

  const handleBanner = (id: any, path: any) => {
    setBannerId(id);
    setBannerPath(path);
    setBannerError("");
  };

  const deleteBanner = () => {
    setBannerId(0);
    setBannerPath("");
  };

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    var model = new SSUpdateMainCatagory();
    model.id = id;
    model.name = name;
    model.description = description;
    model.isPublished = isPublished;
    model.bannerId = bannerId;

    setIsButtonLoading(true);

    setSnackBarOn(false);
    sSupdateMainCatagoryService(model)
      .then((response: any) => {
        setSnackBarOn(true);
        setsnackBarMessage("Successfull");
        setSnackBarMessageType("success");
        setIsButtonLoading(false);
        setTimeout(() => {
          navigate("/admin/super-shop/main-category-list");
        }, 300);

        dispatch(sSGetMainCategoryListAction());
      })
      .catch((error: any) => {
        setIsButtonLoading(false);
        setNameErrorMessage(error.response.data.errors?.Name?.[0]);
        setDescriptionErrorMessage(error.response.data.errors?.Description?.[0]);
        setBannerError(error.response.data.errors?.BannerId?.[0]);
      });
  };

  if (isLoading) {
    return (
      <div className="container h-[50dvh] flex items-center justify-center mx-auto px-2 md:px-0 pb-12">
        <AppLoader status={true} />
      </div>
    );
  }

  return (
    <div>
      <div>
        {snackBarOn && (
          <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
        )}
      </div>

      <div className="pl-4 flex items-center justify-between w-3/5 pb-6">
        <AppButton
          onClick={() => navigate("/admin/super-shop/main-category-list")}
          text="Back"
        />
        <h2 className="text-center title">Update Category</h2>
      </div>

      <form className="max-w-xl mx-auto myBorder py-4 px-5">
        <AppInput
          label="Category name"
          className="myInput"
          type="text"
          placeholder="Enter Main Category Name"
          value={name}
          onChange={handleNameChange}
          errorMessage={nameErrorMessage}
        />

        <AppTextArea
          label="Description"
          placeholder="Enter Description"
          value={description}
          onChange={handleDescriptionChange}
          errorMessage={descriptionErrorMessage}
        />

        <div className="flex justify-between items-center py-2">

          <div className="flex items-center gap-x-3">
            <label htmlFor="isPublished" className="text-xl">Published</label>
            <input
              className="w-6 h-6 cursor-pointer"
              type="checkbox"
              name="isPublished"
              checked={isPublished}
              onChange={() => handleIsPublishedChange(!isPublished)}
            />
          </div>

        </div>

        {/* <div className="w-1/2 flex items-center gap-1 py-2">
          <label className="text-xl">Photo</label>
          {!bannerId && (
            <AppFileInput onSave={handleBanner} errorMessage={bannerError} />
          )}
          <br />
          {bannerId !== 0 && (
            <div className="flex items-center gap-x-2">
              <img
                src={`${bannerPath}`}
                alt=""
              />
              <IconButton onClick={deleteBanner}>
                <DeleteIcon />
              </IconButton>
            </div>
          )}
        </div> */}

        <div className="flex justify-end">
          <AppButton onClick={handleSubmit} isSubmitting={isButtonLoading} variant="contained" text="Submit" />
        </div>
      </form>
    </div>
  );
}
