import { PaginationPayloadModel } from "../../models/common/paginationModels";
import { DELETE, GET, POST, PUT } from "../service";

export const addMainCatagoryService = (data: any) => {
  return POST("/api/MainCategory/CreateMainCategory", data);
};

export const deleteMainCatagoryService = (data: any) => {
  return DELETE(`/api/MainCategory/RemoveMainCategory`, {
    data: { "ids": data },
  });
};

export const getMainCategoryListService = (
  payload?: PaginationPayloadModel, isAll: any = true, forPublic: boolean = false
) => {
  return GET(
    `/api/MainCategory/GetMainCategories?isAll=${isAll}&forPublic=${forPublic}&limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}`
  );
};

export const updateMainCatagoryServices = (data: any) => {
  return PUT(`/api/MainCategory/UpdateMainCategory`, data);
};

export const getSubCategoryListService = (mainCategoryId: any = 0, payload: any, isAll: any = true) => {
  return GET(`/api/SubCategoryLevelOne/GetSubCategoryLevelOnes?isAll=${isAll}&mainCategoryId=${mainCategoryId}&limit=${payload.limit}&offset=${payload.offset}&searchQuery=${payload.searchQuery}`);
}

export const addSubCategoryLevelOneServices = (data: any) => {
  return POST('/api/SubCategoryLevelOne/CreateSubCategoryLevelOne', data);
}

export const updateSubCategoryLevelOneService = (data: any) => {
  return PUT(`/api/SubCategoryLevelOne/UpdateSubCategoryLevelOne`, data);
}

export const deleteSubCategoryLevelOneService = (data: any) => {
  return DELETE(`/api/SubCategoryLevelOne/RemoveSubCategoryLevelOne`, {
    data: { "ids": data },
  });
};


export const addSubCategoryLevelTwoService = (data: any) => {
  return POST(
    "/api/SubCategoryLevelTwo/CreateSubCategoryLevelTwo",
    data
  );
};

export const updateSubCategoryLevelTwoService = (data: any) => {
  return PUT(
    `/api/SubCategoryLevelTwo/UpdateSubCategoryLevelTwo`,
    data
  );
};

export const deleteSubCategoryLevelTwoService = (data: any) => {
  return DELETE(
    `/api/SubCategoryLevelTwo/RemoveSubCategoryLevelTwo`, { data: { "ids": data } }
  );
};
