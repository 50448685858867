import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { GET } from "../../../../httpService/service";
import { AppInput } from "../../../common/AppInput";
import { AppButton } from "../../../common/AppButton";
import { AppFileInput } from "../../../common/AppFileInput";
import { AlertColor, IconButton } from "@mui/material";
import { AppSnackBar } from "../../../common/AppSnackBar";
import DeleteIcon from "@mui/icons-material/Delete";
import { AppTextArea } from "../../../common/AppTextArea";
import { bechteChaiMainCategoryList } from "../../../../store/actions/bechteChai/bechteChaiCategoryActions";
import { updateBechteChaiMainCatagoryServices } from "../../../../httpService/bechteChai/categoryServices";
import { AddBechteChaiMainCatagory } from "../../../../models/bechteChai/category/categoryModels";

export default function AdminUpdateBechteChaiMainCategory(props: any) {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const mainCategoryById = async (id: any) => {
    const data = GET(`/api/BechteChaiCategory/GetMainCategoryById?Id=${id}`);
    const pload = (await data).data;
    loadData(
      pload.id,
      pload.name,
      pload.description,
      pload.displayOrder,
      pload.userRoleId,
      pload.isPublished,
      pload.isShowOnHomePage,
      pload.banner
    );
  };

  useEffect(() => {
    mainCategoryById(id);
  }, []);

  const loadData = (
    Id: any,
    name: any,
    description: any,
    displayOrder: any,
    userRoleId: any,
    isPublished: any,
    isShowOnHomePage: any,
    banner: any
  ) => {
    setId(Id);
    setName(name);
    setDescription(description);
    setDisplayOrder(displayOrder);
    setUserRoleId(userRoleId);
    setIsPublished(isPublished);
    setIsShowOnHomePage(isShowOnHomePage);
    setBannerId(banner?.id);
    setBannerPath(banner?.url);
  };

  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [bannerError, setBannerError] = useState("");

  const [Id, setId] = useState(0);
  const [name, setName] = useState("");

  const [description, setDescription] = useState("");
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState("");
  const [isPublished, setIsPublished] = useState(false);
  const [isShowOnHomePage, setIsShowOnHomePage] = useState(false);
  const [displayOrder, setDisplayOrder] = useState(0);
  const [userRoleId, setUserRoleId] = useState(0);
  const [bannerId, setBannerId] = useState(0);
  const [bannerPath, setBannerPath] = useState("");
  const [errors, setErrors] = useState<any>({});

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);

  const handleIdChange = (event: any) => {
    setId(event);
    errors.Id = "";
  };
  const handleNameChange = (event: any) => {
    setName(event);
    // errors.name = "";
    setNameErrorMessage("");
  };
  const handleDescriptionChange = (event: any) => {
    setDescription(event);
    errors.description = "";
  };

  const handleIsPublishedChange = (event: any) => [
    setIsPublished(event),
    (errors.isPublished = ""),
  ];

  const handleIsShowOnHomePageChange = (event: any) => {
    setIsShowOnHomePage(event);
    errors.isShowOnHomePage = "";
  };

  const handleDisplayOrder = (event: any) => {
    setDisplayOrder(event);
    errors.displayOrder = "";
  };

  const handleUserRoleIdChange = (event: any) => {
    setUserRoleId(event);
    errors.userRoleId = "";
  };

  const handleBanner = (id: any, path: any) => {
    setBannerId(id);
    setBannerPath(path);
    setBannerError("");
  };

  const deleteBanner = () => {
    setBannerId(0);
    setBannerPath("");
  };

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    var model = new AddBechteChaiMainCatagory();
    model.id = Id;
    model.name = name;
    model.description = description;
    model.isPublished = isPublished;
    model.isShowOnHomePage = isShowOnHomePage;
    model.displayOrder = displayOrder;
    model.userRoleId = userRoleId;
    model.bannerId = bannerId;

    setIsButtonLoading(true);

    setSnackBarOn(false);
    updateBechteChaiMainCatagoryServices(model)
      .then((response: any) => {
        setSnackBarOn(true);
        setsnackBarMessage("Successfull");
        setSnackBarMessageType("success");

        setIsButtonLoading(false);

        setErrors({});

        setTimeout(() => {
          navigate("/admin/bechte-chai/main-categories");
        }, 300);

        dispatch(bechteChaiMainCategoryList());
      })
      .catch((error: any) => {
        setIsButtonLoading(false);
        setNameErrorMessage(error.response.data.errors?.Name?.[0]);
        setBannerError(error.response.data.errors?.BannerId?.[0]);
        setDescriptionErrorMessage(error.response.data.errors?.Description?.[0]);
      });
  };
  return (
    <div>
      <div>
        {snackBarOn && (
          <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
        )}
      </div>

      <div className="pl-4 flex items-center justify-between w-3/5 pb-6">
        <AppButton
          className="bechteChaiBtn"
          onClick={() => navigate("/admin/bechte-chai/main-categories")}
          text="Back"
        />
        <h2 className="text-center title">Update Bechte Chai Main category</h2>
      </div>

      <form className="max-w-xl mx-auto myBorder py-4 px-5">
        <AppInput
          label="Category name"
          className="myInput"
          type="text"
          placeholder="Enter Main Category Name"
          value={name}
          onChange={handleNameChange}
          errorMessage={nameErrorMessage}
        />
        <p>{errors?.MainCategoryName?.[0]}</p>

        <AppTextArea
          label="Description"
          placeholder="Enter Description"
          value={description}
          onChange={handleDescriptionChange}
          errorMessage={descriptionErrorMessage}
        />

        {/* publish and show to home checkbox here  */}
        <div className="flex justify-between items-center py-2">

          <div className="flex items-center gap-x-3">
            <label htmlFor="isPublished" className="text-xl">Published</label>
            <input
              className="w-6 h-6 cursor-pointer"
              type="checkbox"
              name="isPublished"
              checked={isPublished}
              onChange={() => handleIsPublishedChange(!isPublished)}
            />
            <p>{errors?.isPublished?.[0]}</p>
          </div>

          {/* <div className="flex items-center gap-x-3">
            <label htmlFor="isShowHomeOnPage" className="text-xl">
              Show on homepage
            </label>
            <input
              className="w-6 h-6 cursor-pointer"
              type="checkbox"
              name="isShowHomeOnPage"
              checked={isShowOnHomePage}
              onChange={() => handleIsShowOnHomePageChange(!isShowOnHomePage)}
            />
            <p>{errors?.isShowOnHomePage?.[0]}</p>
          </div> */}
        </div>

        <div className="w-1/2 flex items-center gap-1 py-2">
          <label className="text-xl">Photo</label>
          {!bannerId && (
            <AppFileInput onSave={handleBanner} errorMessage={bannerError} />
          )}
          <br />
          {bannerId !== 0 && (
            <div className="flex items-center gap-x-2">
              <img
                // height={100}
                // width={100}
                src={`${bannerPath}`}
                alt=""
              />
              <IconButton onClick={deleteBanner}>
                <DeleteIcon />
              </IconButton>
            </div>
          )}
        </div>

        <div className="flex justify-end">
          <AppButton onClick={handleSubmit} isSubmitting={isButtonLoading} variant="contained" className="bechteChaiBtn" text="Submit" />
        </div>
      </form>

      {/* <div className="form-input">
          <label className="label">Display Order</label>
          <AppInput
            type="text"
            placeholder="Enter Display Order"
            value={displayOrder}
            onChange={handleDisplayOrder}
          />
          <p>{errors?.displayOrder?.[0]}</p>
        </div> */}
      {/* <div className="form-input">
          <label className="label">User Role</label>
          <AppInput
            type="text"
            placeholder="Enter User Role Id"
            value={userRoleId}
            onChange={handleUserRoleIdChange}
          />
          <p>{errors?.userRoleId?.[0]}</p>
        </div> */}
    </div>
  );
}
