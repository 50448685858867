import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../store";
import { PaginationPayloadModel } from "../../../models/common/paginationModels";
import { debounce } from "lodash";
import { BiSearchAlt2 } from "react-icons/bi";
import { Link } from "react-router-dom";
import { GetOrderInvoicesModel } from "../../../models/invoices/invoicesModels";
import { getOrderInvoicesAction } from "../../../store/actions/invoices/orderInvoicesActions";
import { AlertColor } from "@mui/material";
import { AppButton } from "../../common/AppButton";
import { AppLoader } from "../../common/AppLoader";
import { AppPagination } from "../../common/AppPagination";
import { AppSearchBox } from "../../common/AppSearchBox";
import { AppSnackBar } from "../../common/AppSnackBar";

export default function IVOrderInvoices() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const orderInvoices = useSelector((state: RootState) => {
    return state.getOrderInvoicesResponse.data;
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        var pModel = new GetOrderInvoicesModel();
        pModel.limit = 10;
        pModel.offset = 0;
        pModel.searchQuery = searchQuery;

        await dispatch(getOrderInvoicesAction(pModel));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  const handleOnRowChange = (payload: PaginationPayloadModel) => {
    const fetchData = async () => {
      try {
        var pModel = new GetOrderInvoicesModel();
        pModel.limit = payload.limit;
        pModel.offset = 0;
        await dispatch(getOrderInvoicesAction(pModel));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();
  };

  const handleOnPageChange = (payload: PaginationPayloadModel) => {
    const fetchData = async () => {
      try {
        var pModel = new GetOrderInvoicesModel();
        pModel.limit = payload.limit;
        pModel.offset = payload.offset;

        await dispatch(getOrderInvoicesAction(pModel));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  };

  const handleSearchQuery = debounce((searchQuery: string) => {
    const fetchData = async () => {
      try {
        setSearchQuery(searchQuery);
        var pModel = new GetOrderInvoicesModel();
        pModel.limit = 100000;
        pModel.offset = 0;
        pModel.searchQuery = searchQuery;

        dispatch(getOrderInvoicesAction(pModel));
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, 500);

  return (
    <div className="container mx-auto px-2 py-2">
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      <div className="flex flex-col items-center lg:flex-row">
        <div className="flex w-full items-center justify-between">
          <Link to="/invoices">
            <AppButton className="gradientBtn" text="Back" onClick={() => {}} />
          </Link>
          <h2 className="title hidden w-full text-center text-cyan-600 lg:block">
            Order Invoices ({orderInvoices?.size})
          </h2>
        </div>
        <h2 className="title w-full text-center text-cyan-600 lg:hidden">
          Order Invoices({orderInvoices?.size})
        </h2>
      </div>

      <div className="grid grid-cols-3 gap-2 py-2 lg:grid-cols-5 lg:py-4">
        <div className="flex cursor-pointer items-center rounded border border-grey p-1 shadow-sm md:px-1.5 lg:w-full 2xl:p-2">
          <BiSearchAlt2 className="cursor-pointer text-xl font-bold text-cyan-600 md:text-2xl 2xl:text-3xl" />
          <AppSearchBox
            onChange={handleSearchQuery}
            placeholder="Search here"
            className="w-full border-none text-xs md:text-base lg:px-2"
          />
        </div>
      </div>

      {isLoading ? (
        <AppLoader status={true} />
      ) : (
        <>
          {orderInvoices?.items ? (
            <div className="w-full">
              {/* these is header part for desktop */}
              <div className="myBorder myGradient hidden w-full lg:flex">
                <div className="tableHead w-[18%]">InvoiceNumber</div>
                <div className="tableHead w-[13%]">InvoiceDateTime</div>
                <div className="tableHead w-[15%]">InvoiceName</div>
                <div className="tableHead w-[13%]">UserName</div>
                <div className="tableHead w-[10%]">PaidStatus</div>
                <div className="tableHead w-[11%]">TransactionId</div>
                <div className="tableHead w-[10%]">TotalAmount</div>
                <div className="tableHead w-[10%]">View</div>
              </div>
              {/* these is middle part for desktop */}
              <div className="hidden h-[58dvh] flex-col gap-1 overflow-y-auto py-1.5 lg:flex 2xl:h-[62dvh]">
                {orderInvoices?.items?.map((orderinvoices: any) => (
                  <div
                    key={orderinvoices?.id}
                    id={orderinvoices?.id}
                    className="myBorder flex w-full items-center"
                  >
                    <div className="tableRow textOverflowHidden w-[18%] justify-start">
                      {orderinvoices?.invoiceNumber}
                    </div>

                    <div className="tableRow w-[13%]">
                      {orderinvoices?.invoiceDateTime}
                    </div>

                    <div className="tableRow w-[15%]">
                      {orderinvoices?.invoiceName}
                    </div>

                    <div className="tableRow w-[13%]">
                      {orderinvoices?.userName}
                    </div>

                    <div className="tableRow w-[10%]">
                      {orderinvoices?.paidStatus}
                    </div>

                    <div className="tableRow w-[11%]">
                      {orderinvoices?.transactionId}
                    </div>

                    <div className="tableRow w-[10%]">
                      {orderinvoices?.totalAmount}
                    </div>

                    <div className="tableRow w-[10%]">
                      <AppButton
                        onClick={() =>
                          navigate(`/invoices/order/${orderinvoices?.id}`)
                        }
                        className="gradientBtn  py-0.5 text-sm lg:py-1"
                        text="View"
                      />
                    </div>
                  </div>
                ))}
              </div>
              {/* // id: number = 0; // invoiceNumber: string = ""; //
              invoiceDateTime: string = ""; // invoiceName: string = ""; //
              userName: string = ""; // paidStatus: string = ""; //
              transactionId: string = ""; // totalAmount: string = ""; */}
              {/* these is middle part for mobile */}
              <div className="flex h-[78dvh] flex-col gap-2 overflow-y-auto lg:hidden">
                {orderInvoices?.items?.map((orderinvoices?: any) => (
                  <div
                    key={orderinvoices?.id}
                    className="myBorder flex w-full flex-col p-1 text-xs"
                  >
                    <div className="grid grid-cols-2">
                      <div className="flex items-center gap-0.5">
                        <span className="font-medium">Invoice Number:</span>{" "}
                        <span>{orderinvoices?.invoiceNumber}</span>
                      </div>
                      <div className="flex items-center gap-0.5">
                        <span className="font-medium">Invoice DateTime:</span>{" "}
                        <span> {orderinvoices?.invoiceDateTime}</span>
                      </div>
                    </div>

                    <div className="grid grid-cols-2">
                      <div className="flex items-center gap-0.5">
                        <span className="font-medium">Invoice Name:</span>{" "}
                        <span>{orderinvoices?.invoiceName}</span>
                      </div>
                      <div className="flex items-center gap-0.5">
                        <span className="font-medium">User Name:</span>{" "}
                        <span> {orderinvoices?.userName}</span>
                      </div>
                    </div>

                    <div className="grid grid-cols-2">
                      <div className="flex items-center gap-0.5">
                        <span className="font-medium">Paid Status:</span>{" "}
                        <span>{orderinvoices?.paidStatus}</span>
                      </div>
                      <div className="flex items-center gap-0.5">
                        <span className="font-medium">TransactionId:</span>{" "}
                        <span> {orderinvoices?.transactionId}</span>
                      </div>
                    </div>

                    <div className="grid grid-cols-2">
                      <div className="flex items-center gap-0.5">
                        <span className="font-medium">TotalAmount:</span>{" "}
                        <span>{orderinvoices?.totalAmount}</span>
                      </div>
                      {/* <div className="flex items-center gap-0.5">
                        <span className="font-medium">Phone 2:</span>{" "}
                        <span> {orderinvoices?.phoneNumberSecond}</span>
                      </div> */}
                    </div>

                    <div className="flex w-full items-center justify-center gap-2 lg:gap-0.5">
                      <AppButton
                        onClick={() =>
                          navigate(
                            `/important-numbers/public-representatives/update/${orderinvoices?.id}`
                          )
                        }
                        className="gradientBtn  py-0.5 text-sm lg:py-1"
                        text="View"
                      />
                    </div>
                  </div>
                ))}
              </div>
              {/* these is footer part  */}
              <div className="fixed bottom-1 flex w-full items-center bg-white py-1 lg:static">
                <AppPagination
                  curPage={1}
                  limit={10}
                  offset={0}
                  onPageChange={handleOnPageChange}
                  onRowChange={handleOnRowChange}
                  size={orderInvoices?.size}
                />
              </div>
            </div>
          ) : (
            <div className="flex h-[50dvh] w-full items-center justify-center text-3xl font-medium text-textBlack/90">
              <span>Order Invoices Not found</span>
            </div>
          )}
        </>
      )}
    </div>
  );
}
