import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { BsChevronRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { PaginationPayloadModel } from "../../../models/common/paginationModels";
import { bechteChaiMainCategoryList } from "../../../store/actions/bechteChai/bechteChaiCategoryActions";

export const BechteChaiCategoryFilterGetMainCategories = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const exploreLevelOneState = useSelector((state: RootState) => {
    return state.exploreLevelOneStateResponse.exploreLevelOne;
  });

  const mainCatagoryList = useSelector((state: RootState) => {
    return state.bechteChaiMainCategoryListResponse.data;
  });

  const openExploreLevelTwo = (data: number, id: any) => {
    dispatch({ type: "OPEN-EXPLORE-LEVEL-TWO" });
    dispatch({ type: "CLOSE-EXPLORE-LEVEL-ONE" });
    dispatch({ type: "EXPLORE-LEVEL-TWO-DATA", payload: data });
    navigate(`/bechte-chai/filterProductList/${id}/${0}`);
  };

  var paginationModel = new PaginationPayloadModel();

  useEffect(() => {
    dispatch(bechteChaiMainCategoryList(paginationModel, false, true));
  }, []);

  if (!exploreLevelOneState) return null;
  else
    return (
      <div className="">
        {mainCatagoryList.items?.map((item: any) => (
          <div
            className="flex items-center justify-between hover:bg-green-100 py-2.5 px-6 2xl:px-10 cursor-pointer"
            onClick={() => openExploreLevelTwo(item.subCategoriesLevelOneResponseList, item.id)}
            key={item.id}
          >
            <div className="flex items-center gap-x-2 md:gap-x-4">
              <img className="w-6 md:w-9 h-6 md:h-9 object-contain" src={item.bannerPath} alt="icon" />
              <h2 className="text"> {item.name}  [{item.totalProduct}]</h2>
            </div>
            <BsChevronRight className="text-xl 2xl:text-2xl font-extrabold text-bechteChai" />
          </div>
        ))}
      </div>
    );
};
