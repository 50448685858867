import { GET_NOTIFICATION_COUNT, GET_NOTIFICATION_LIST } from "../../constants/notification/NotificationConstsnts";

export function getNotificationsReducer(state= { data: []} , action: any) {
    switch (action.type) {
        case GET_NOTIFICATION_LIST :
            return {...state, loading: false, data: action.payload };
        default:
            return state;
    }
}


export function getNotificationCountReducer(state = { data: 0 }, action: any) {
    switch (action.type) {
        case GET_NOTIFICATION_COUNT :
            return {...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

