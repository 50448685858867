import { useNavigate } from "react-router-dom";
import { AppInput } from "../../common/AppInput";
import { AppSelect } from "../../common/AppSelect";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { divisionList } from "../../../store/actions/auth/address/divisionActions";
import { districtListByDivisionId as districtListByDivisionId } from "../../../store/actions/auth/address/districtActions";
import { areaListByDistrictId } from "../../../store/actions/auth/address/areaActions";
import { RootState } from "../../../store";
import { IdNameResponseModel } from "../../../models/common/IdNameModel";
import {
  OrderProductRequest,
  SubmitOrderResponse,
  ResellingSubmitOrderModel,
} from "../../../models/order/resellingOrdersModels";
import { getCartItemListAction, getTotalShippingChargeAction } from "../../../store/actions/cart/resellingCartActions";
import {
  ShoppingCartFavouriteModel,
  ShoppingCartType,
} from "../../../models/shoppingCart/resellingCartModels";
import { reactLocalStorage } from "reactjs-localstorage";
import {
  resellingSubmitOrderPGService,
  resellingMakeOrderPaymentService,
  resellingSubmitOrderMainBalanceService,
} from "../../../httpService/sales/resellingOrderServices";
import { AppButton } from "../../common/AppButton";
import { AlertColor } from "@mui/material/Alert";
import { AppSnackBar } from "../../common/AppSnackBar";
import { AppScrollBar } from "../../common/AppScrollBar";
import { TbCurrencyTaka } from "react-icons/tb";
import { createBkashPaymentService } from "../../../httpService/payment/paymnetServices";
import { appUrl } from "../../../config/config";
import { BKASH_CHARGE_PERCENTAGE } from "../../../constants";
import { AppLoader } from "../../common/AppLoader";
import { CreateBkashPaymentRequest, CreateBkashPaymentResponse, ExecuteBkashPaymentRequest } from "../../../models/payment/paymnetModel";
import { getUserWalletAction } from "../../../store/actions/auth/user/userActions";
import { GetUserWalletResponse } from "../../../models/auth/user/UserPointModels";


export default function ResellingSubmitOrder() {

  const ref = reactLocalStorage.get("resellRef") as string;

  const ip = reactLocalStorage.get("ip") as string;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [bFirstName, setBFirstName] = useState("");
  const [bLastName, setBLastName] = useState("");
  const [bEmail, setBEmail] = useState("");
  const [bPhoneNoFirst, setBPhoneNoFirst] = useState("");
  const [bPhoneNoSecond, setBPhoneNoSecond] = useState("");
  const [bAddressFirst, setBAddressFirst] = useState("");
  const [bAddressSecond, setBAddressSecond] = useState("");
  const [bCompanyName, setBCompanyname] = useState("");
  const [bPostCode, setBPostCode] = useState("");
  const [bDivisionId, setBDivisionId] = useState(0);
  const [bDistrictId, setBDistrictId] = useState(0);
  const [bAreaId, setBAreaId] = useState(0);

  const [sFirstName, setSFirstName] = useState("");
  const [sLastName, setSLastName] = useState("");
  const [sEmail, setSEmail] = useState("");
  const [sPhoneNoFirst, setSPhoneNoFirst] = useState("");
  const [sPhoneNoSecond, setSPhoneNoSecond] = useState("");
  const [sAddressFirst, setSAddressFirst] = useState("");
  const [sAddressSecond, setSAddressSecond] = useState("");
  const [sCompanyName, setSCompanyname] = useState("");
  const [sPostCode, setSPostCode] = useState("");
  const [sDivisionId, setSDivisionId] = useState(0);
  const [sDistrictId, setSDistrictId] = useState(0);
  const [sAreaId, setSAreaId] = useState(0);

  const [bFirstNameError, setBFirstNameError] = useState("");
  const [bPhoneNoFirstError, setBphoneNoFirstError] = useState("");
  const [bPhoneNoSecondError, setBPhoneNoSecondError] = useState("");
  const [bEmailError, setBEmailError] = useState("");
  const [bAddressFirstError, setBAddressFirstError] = useState("");
  const [bDivisionError, setBDivisionError] = useState("");
  const [bDistrictError, setBDistrictError] = useState("");
  const [bAreaError, setBAreaError] = useState("");

  const [sFirstNameError, setSFirstNameError] = useState("");
  const [sPhoneNoFirstError, setSPhoneNoFirstError] = useState("");
  const [sPhoneNoSecondError, setSPhoneNoSecondError] = useState("");
  const [sEmailError, setSEmailError] = useState("");
  const [sAddressFirstError, setSAddressFirstError] = useState("");
  const [sDivisionError, setSDivisionError] = useState("");
  const [sDistrictError, setSDistrictError] = useState("");
  const [sAreaError, setSAreaError] = useState("");


  const [isLoad, setIsLoad] = useState(true);

  const [same, setSame] = useState(true);

  const handleIsSameAsBilling = () => {
    if (same === true) {
      setSame(!same);
    } else {
      setSame(!same);
    }
  };

  const divisions = useSelector((state: RootState) => {
    return state.divisionListResponse.data;
  });
  var divisionIdNameModels: IdNameResponseModel[] = [];

  divisions?.map((x: any) => {
    let division = new IdNameResponseModel();
    division.id = x.id;
    division.name = x.name;
    divisionIdNameModels.push(division);
  });

  const districts = useSelector((state: RootState) => {
    return state.districtListResponse.data;
  });
  var districtsIdNameModels: IdNameResponseModel[] = [];

  districts?.map((x: any) => {
    var district = new IdNameResponseModel();
    district.id = x.id;
    district.name = x.name;
    districtsIdNameModels.push(district);
  });

  const areass = useSelector((state: RootState) => {
    return state.areaListResponse.data;
  });
  var areaIdNameModels: IdNameResponseModel[] = [];

  areass?.map((x: any) => {
    var area = new IdNameResponseModel();
    area.id = x.id;
    area.name = x.name;
    areaIdNameModels.push(area);
  });

  const [cartItems, setCartItems] = useState<ShoppingCartFavouriteModel[]>([]);

  var token = reactLocalStorage.getObject("SignIn");

  const cartItemsSignIn = useSelector((state: RootState) => {
    return state.getTotalCartitemListResponse.data;
  });

  var pItems: any;

  if (token && JSON.stringify(token) !== JSON.stringify({})) {
    pItems = cartItemsSignIn as ShoppingCartFavouriteModel[];
    if (pItems && isLoad) {
      pItems?.map((x: any) => {
        cartItems.push(x);
      });
      setIsLoad(false);
    }
  }

  useEffect(() => {
    dispatch(divisionList());
  }, [dispatch]);

  useEffect(() => {
    if (bDivisionId !== 0) {
      dispatch(districtListByDivisionId(bDivisionId));
    }
  }, [bDivisionId, dispatch]);

  useEffect(() => {
    if (bDistrictId !== 0) {
      dispatch(areaListByDistrictId(bDistrictId));
    }
  }, [bDistrictId, dispatch]);

  useEffect(() => {
    dispatch(getCartItemListAction(ShoppingCartType.ShoppingCart));
  }, [dispatch]);

  const handleBFirstName = (value: string) => {
    setBFirstNameError("");
    setBFirstName(value);
  };

  const handleBPhoneNoFirst = (value: string) => {
    setBphoneNoFirstError("");
    setBPhoneNoFirst(value);
  };

  const handleBPhoneNoSecond = (value: string) => {
    setBPhoneNoSecondError("");
    setBPhoneNoSecond(value);
  };

  const handleBEmail = (value: string) => {
    setBEmailError("");
    setBEmail(value);
  };

  const handleBDivision = (id: number) => {
    setBDivisionError("");
    setBDivisionId(id);
    setBDistrictId(0)
    setBAreaId(0)
  };


  const insideDhakAShippingCharge = 60;
  const outideDhakAShippingCharge = 120;


  const handleBDistrict = (id: number) => {
    setBDistrictError("");
    setBDistrictId(id);
    setBAreaId(0)
    setIsSelectAdvanceShipping(false);
    setIsSelectFullPayment(false);
    setIsPaymentSectionVisible(false);
    if (id === 65) {
      dispatch(getTotalShippingChargeAction(true));
    } else {
      dispatch(getTotalShippingChargeAction(false));
    }

  };

  const handleBArea = (id: number) => {
    setBAreaError("");
    setBAreaId(id);
  };

  const handleBAddressFirst = (value: string) => {
    setBAddressFirstError("");
    setBAddressFirst(value);
  };

  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const paymentID = urlParams.get('paymentID');
    const status = urlParams.get('status');

    if (paymentID && status) {
      let model = new ExecuteBkashPaymentRequest();
      model.paymentId = paymentID;
      model.status = status;
      setIsConformOrderButtonLoading(true);
      setIsLoading(true);

      resellingMakeOrderPaymentService(model)
        .then(() => {
          setIsShowSnackBar(true);
          setSnackBarMessage("Successfully Placed Order");
          setSnackBarMessageType("success");
          setTimeout(() => {
            setIsShowSnackBar(false);
          }, 2000);

          setIsConformOrderButtonLoading(false);

          setTimeout(() => {
            navigate("/reselling-platform/myOrder");
            dispatch({ type: "CLOSE-CONFIRM-ORDER" });
          }, 2000);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          setIsShowSnackBar(true);
          setSnackBarMessage("Something wrong");
          setSnackBarMessageType("error");
          setTimeout(() => {
            setIsShowSnackBar(false);
          }, 2000);
        })
    }
  }, []);


  const bkashPayment = (invoiceNumber: any, totalAmount: any) => {

    var model = new CreateBkashPaymentRequest();
    model.amount = Number((totalAmount as number + (totalAmount as number * BKASH_CHARGE_PERCENTAGE)).toFixed(2));
    model.callbackURL = `${appUrl}/reselling-platform/submitBillingAndShippingAddress`;
    model.invoiceNumber = invoiceNumber;

    createBkashPaymentService(model)
      .then((response: any) => {
        let res = response?.data as CreateBkashPaymentResponse;
        if (res?.isSucceeded) {
          window.location.replace(res.data?.bkashURL);
        } else {
          setIsConformOrderButtonLoading(false);
        }
      }).catch(() => {
        setIsConformOrderButtonLoading(false);
      })
  }

  const handleSubmitWithBkash = () => {

    let canExecute = true;

    if (!bFirstName) {
      setBFirstNameError("First Name Is Required");
      canExecute = false;
    }
    if (!bPhoneNoFirst) {
      setBphoneNoFirstError("Phone no is required");
      canExecute = false;
    }
    if (!isValidPhoneNumber(bPhoneNoFirst)) {
      setBphoneNoFirstError("Phone no is invalid");
      canExecute = false;
    }
    if (!isValidPhoneNumber(bPhoneNoSecond) && bPhoneNoSecond) {
      setBPhoneNoSecondError("Phone no is invalid");
      canExecute = false;
    }
    if (!isValidEmail(bEmail) && bEmail) {
      setBEmailError("Email is invalid");
      canExecute = false;
    }
    if (!bAddressFirst) {
      setBAddressFirstError("Address line one is required");
      canExecute = false;
    }
    if (!bDivisionId) {
      setBDivisionError("Division is required");
      canExecute = false;
    }
    if (!bDistrictId && bDistrictId !== 0) {
      setBDistrictError("District is required");
      canExecute = false;
    }
    if (bDistrictId === 0) {
      setBDistrictError("District is required");
      canExecute = false;
    }
    if (!bAreaId && bAreaId !== 0) {
      setBAreaError("Thana is required");
      canExecute = false;
    }
    if (bAreaId === 0) {
      setBAreaError("Thana is required");
      canExecute = false;
    }


    if (same === false) {
      if (!sFirstName) {
        setSFirstNameError("First name is required");
        canExecute = false;
      }
      if (!sPhoneNoFirst) {
        setSPhoneNoFirstError("Phone no is required");
        canExecute = false;
      }
      if (!isValidPhoneNumber(sPhoneNoFirst)) {
        setSPhoneNoFirstError("Phone no is invalid");
        canExecute = false;
      }
      if (!isValidPhoneNumber(sPhoneNoSecond) && sPhoneNoSecond) {
        setSPhoneNoSecondError("Phone no is invalid");
        canExecute = false;
      }
      if (!isValidEmail(sEmail) && sEmail) {
        setSEmailError("Email is invalid");
        canExecute = false;
      }
      if (!sAddressFirst) {
        setSAddressFirstError("Address line one is required");
        canExecute = false;
      }
      //stay
      if (!sDivisionId) {
        setSDivisionError("Division is required");
        canExecute = false;
      }
      //stay
      if (!sDistrictId && sDistrictId !== 0) {
        setSDistrictError("District is required");
        canExecute = false;
      }
      if (sDistrictId === 0) {
        setSDistrictError("District is required");
        canExecute = false;
      }
      //stay
      if (!sAreaId) {
        setSAreaError("Thana is required");
        canExecute = false;
      }
      if (sAreaId === 0) {
        setSAreaError("Thana is required");
        canExecute = false;
      }
    }

    if (canExecute) {

      var model = new ResellingSubmitOrderModel();

      if (same === true) {
        model.billingAddressFirstName = bFirstName;
        model.billingAddressLastName = bLastName;
        model.billingAddressEmail = bEmail;
        model.billingAddressPhoneNoFirst = bPhoneNoFirst;
        model.billingAddressPhoneNoSecond = bPhoneNoSecond;
        model.billingAddressAddressFirst = bAddressFirst;
        model.billingAddressAddressSecond = bAddressSecond;
        model.billingAddressCompanyName = bCompanyName;
        model.billingAddressPostCode = bPostCode;
        model.billingAddressAreaId = bAreaId;

        model.shippingAddressFirstName = bFirstName;
        model.shippingAddressLastName = bLastName;
        model.shippingAddressEmail = bEmail;
        model.shippingAddressPhoneNoFirst = bPhoneNoFirst;
        model.shippingAddressPhoneNoSecond = bPhoneNoSecond;
        model.shippingAddressAddressFirst = bAddressFirst;
        model.shippingAddressAddressSecond = bAddressSecond;
        model.shippingAddressCompanyName = bCompanyName;
        model.shippingAddressPostCode = bPostCode;
        model.shippingAddressAreaId = bAreaId;

      } else {
        model.billingAddressFirstName = bFirstName;
        model.billingAddressLastName = bLastName;
        model.billingAddressEmail = bEmail;
        model.billingAddressPhoneNoFirst = bPhoneNoFirst;
        model.billingAddressPhoneNoSecond = bPhoneNoSecond;
        model.billingAddressAddressFirst = bAddressFirst;
        model.billingAddressAddressSecond = bAddressSecond;
        model.billingAddressCompanyName = bCompanyName;
        model.billingAddressPostCode = bPostCode;
        model.billingAddressAreaId = bAreaId;

        model.shippingAddressFirstName = sFirstName;
        model.shippingAddressLastName = sLastName;
        model.shippingAddressEmail = sEmail;
        model.shippingAddressPhoneNoFirst = sPhoneNoFirst;
        model.shippingAddressPhoneNoSecond = sPhoneNoSecond;
        model.shippingAddressAddressFirst = sAddressFirst;
        model.shippingAddressAddressSecond = sAddressSecond;
        model.shippingAddressCompanyName = sCompanyName;
        model.shippingAddressPostCode = sPostCode;

        model.shippingAddressAreaId = sAreaId;
      }

      model.customerIP = ip;
      model.shippingCost = getTotalSAhippingChargeMemo?.totalCharge;
      model.totalWeight = 0;
      model.orderProductRequest = [];
      model.referrerId = ref;
      model.isDeliveryChargePaid = isSelectFullPayment ? true : isSelectAdvanceShipping;
      model.isOrderTotalPaid = isSelectFullPayment;
      var p = 0;

      cartItems.filter(_ => _.isSelected).map((item: ShoppingCartFavouriteModel, index: any) => {
        let op = new OrderProductRequest();
        op.productId = item.productId;
        op.quantity = item.productQuantity;
        op.unitPrice = item.unitPrice;
        op.totalPrice = item.totalPrice;
        op.unitIncome = item.unitIncome;
        op.totalIncome = item.totalIncome;
        op.attributes = JSON.stringify(item.attributes);;
        op.bannerPath = item.bannerPath;
        op.attributeValueIds = item.attributeValueIds;

        if (item && item.totalPrice) {
          p = p + item.totalPrice;
        }
        model.orderProductRequest?.push(op);
      });
      model.totalAmount = p;
      model.isInSideDhaka = bDistrictId === 65 ? true : false;

      setIsConformOrderButtonLoading(true);

      resellingSubmitOrderPGService(model)
        .then((res: any) => {
          let response = res?.data as SubmitOrderResponse;
          bkashPayment(response.invoiceNumber, response.totalAmount);
        })
        .catch((error) => {
          setIsConformOrderButtonLoading(false);
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.BillingAddressRequest &&
            error.response.data.errors.BillingAddressRequest.FirstName &&
            error.response.data.errors.BillingAddressRequest.FirstName.length >
            0
          ) {
          } else {
          }
        });
    }
  };

  function isValidPhoneNumber(phoneNumber: any) {
    // Define a regular expression to match against the phone number format
    const phoneRegex = /^01[0-9]{9}$/;
    // Test the phone number against the regex pattern and return the result
    return phoneRegex.test(phoneNumber);
  }

  function isValidEmail(email: any) {
    // regular expression to validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // test the email against the regex pattern
    return emailRegex.test(email);
  }

  var token = reactLocalStorage.getObject("SignIn");

  const cartItemsMemo = useMemo(() => {
    return cartItemsSignIn as ShoppingCartFavouriteModel[];
  }, [cartItemsSignIn]);

  useEffect(() => {
    if (token && JSON.stringify(token) !== JSON.stringify({})) {
      if (cartItemsMemo) {
        setCartItems(cartItemsMemo);
      }
    } else {
    }
  }, [cartItemsMemo, token]);

  const groupedProducts = cartItemsMemo.reduce((result: any, product: ShoppingCartFavouriteModel) => {
    const index = result.findIndex((vendorProducts: any) => vendorProducts[0].vendorId === product.vendorId);
    if (index >= 0) {
      result[index].push(product);
    } else {
      result.push([product]);
    }
    return result;
  }, []);

  const [isConfirmOrderButtonLoading, setIsConformOrderButtonLoading] = useState(false);

  useEffect(() => {
    getTotalPrice();
  }, [cartItemsMemo]);

  const [totalPrice, setTotalPrice] = useState(0);
  const uniqueVendors = new Set<string>();
  const [selectedVendorCount, setSelectedVendorCount] = useState(0);

  const getTotalPrice = () => {
    let p = 0;
    if (cartItemsMemo && JSON.stringify(cartItemsMemo) !== JSON.stringify({})) {
      cartItemsMemo?.filter(_ => _.isSelected)?.map((x: any) => {
        p = p + x.totalPrice;
        if (x.vendorName) {
          uniqueVendors.add(x.vendorName);
        }
      });
      setTotalPrice(p);
      setSelectedVendorCount(uniqueVendors.size)
    }
  };

  var totalItem = 0;
  totalItem = cartItemsMemo?.filter((_: any) => _.isSelected).reduce(
    (q: any, p: any) => (q + p.productQuantity) as number,
    0
  );


  const [isSelectedBkash, setIsSelectedBkash] = useState(true);
  const [isSelectedMainBalance, setIsSelectedMainBalance] = useState(false);


  const selectBkash = () => {
    setIsSelectedBkash(true);
    // setIsSelectedNagad(false);
    // setIsSelectedCashOnDeleiery(false);
    setIsSelectedMainBalance(false)
  }

  const selectMainBalance = () => {
    setIsSelectedBkash(false);
    setIsSelectedMainBalance(true)
  }


  // const selectNagad = () => {
  //   setIsSelectedBkash(false);
  //   setIsSelectedNagad(true);
  //   setIsSelectedCashOnDeleiery(false);
  // }

  // const selectCashOnDelivery = () => {
  //   setIsSelectedBkash(false);
  //   setIsSelectedNagad(false);
  //   setIsSelectedCashOnDeleiery(true);
  // }


  const getTotalSAhippingCharge = useSelector((state: RootState) => {
    return state.getTotalShippingChargeResponse.data;
  });

  const getTotalSAhippingChargeMemo = useMemo(() => {
    return getTotalSAhippingCharge;
  }, [getTotalSAhippingCharge]);


  const [isPaymentSectionVisible, setIsPaymentSectionVisible] = useState(false);
  const [isSelectAdvanceShipping, setIsSelectAdvanceShipping] = useState(false);
  const [isSelectFullPayment, setIsSelectFullPayment] = useState(false);

  const [payableAmount, setPayableAmount] = useState(0);

  const selectAdvanceShippingCharge = () => {
    setPayableAmount(getTotalSAhippingChargeMemo?.totalCharge)
    setIsSelectAdvanceShipping(true);
    setIsSelectFullPayment(false);
    setIsPaymentSectionVisible(true);
  }

  const selectFullPayment = () => {
    setPayableAmount(getTotalSAhippingChargeMemo?.totalCharge + totalPrice)
    setIsSelectAdvanceShipping(false);
    setIsSelectFullPayment(true);
    setIsPaymentSectionVisible(true);
  }

  const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false);

  const wallet = useSelector((state: RootState) => {
    return state.getuserWalletResponse.data as GetUserWalletResponse;
  });

  useEffect(() => {
    dispatch(getUserWalletAction(0));
  }, [dispatch]);

  const closePurchesModal = () => {
    setIsPurchaseModalOpen(false);
  };

  const openConfirmFromMainBalanceModal = () => {
    setIsPurchaseModalOpen(true);
  }

  const handleSubmitWithMainBalance = () => {

    let canExecute = true;

    if (!bFirstName) {
      setBFirstNameError("First Name Is Required");
      canExecute = false;
    }
    if (!bPhoneNoFirst) {
      setBphoneNoFirstError("Phone no is required");
      canExecute = false;
    }
    if (!isValidPhoneNumber(bPhoneNoFirst)) {
      setBphoneNoFirstError("Phone no is invalid");
      canExecute = false;
    }
    if (!isValidPhoneNumber(bPhoneNoSecond) && bPhoneNoSecond) {
      setBPhoneNoSecondError("Phone no is invalid");
      canExecute = false;
    }
    if (!isValidEmail(bEmail) && bEmail) {
      setBEmailError("Email is invalid");
      canExecute = false;
    }
    if (!bAddressFirst) {
      setBAddressFirstError("Address line one is required");
      canExecute = false;
    }
    if (!bDivisionId) {
      setBDivisionError("Division is required");
      canExecute = false;
    }
    if (!bDistrictId && bDistrictId !== 0) {
      setBDistrictError("District is required");
      canExecute = false;
    }
    if (bDistrictId === 0) {
      setBDistrictError("District is required");
      canExecute = false;
    }
    if (!bAreaId && bAreaId !== 0) {
      setBAreaError("Thana is required");
      canExecute = false;
    }
    if (bAreaId === 0) {
      setBAreaError("Thana is required");
      canExecute = false;
    }


    if (same === false) {
      if (!sFirstName) {
        setSFirstNameError("First name is required");
        canExecute = false;
      }
      if (!sPhoneNoFirst) {
        setSPhoneNoFirstError("Phone no is required");
        canExecute = false;
      }
      if (!isValidPhoneNumber(sPhoneNoFirst)) {
        setSPhoneNoFirstError("Phone no is invalid");
        canExecute = false;
      }
      if (!isValidPhoneNumber(sPhoneNoSecond) && sPhoneNoSecond) {
        setSPhoneNoSecondError("Phone no is invalid");
        canExecute = false;
      }
      if (!isValidEmail(sEmail) && sEmail) {
        setSEmailError("Email is invalid");
        canExecute = false;
      }
      if (!sAddressFirst) {
        setSAddressFirstError("Address line one is required");
        canExecute = false;
      }
      //stay
      if (!sDivisionId) {
        setSDivisionError("Division is required");
        canExecute = false;
      }
      //stay
      if (!sDistrictId && sDistrictId !== 0) {
        setSDistrictError("District is required");
        canExecute = false;
      }
      if (sDistrictId === 0) {
        setSDistrictError("District is required");
        canExecute = false;
      }
      //stay
      if (!sAreaId) {
        setSAreaError("Thana is required");
        canExecute = false;
      }
      if (sAreaId === 0) {
        setSAreaError("Thana is required");
        canExecute = false;
      }
    }

    if (canExecute) {

      var model = new ResellingSubmitOrderModel();

      if (same === true) {
        model.billingAddressFirstName = bFirstName;
        model.billingAddressLastName = bLastName;
        model.billingAddressEmail = bEmail;
        model.billingAddressPhoneNoFirst = bPhoneNoFirst;
        model.billingAddressPhoneNoSecond = bPhoneNoSecond;
        model.billingAddressAddressFirst = bAddressFirst;
        model.billingAddressAddressSecond = bAddressSecond;
        model.billingAddressCompanyName = bCompanyName;
        model.billingAddressPostCode = bPostCode;
        model.billingAddressAreaId = bAreaId;

        model.shippingAddressFirstName = bFirstName;
        model.shippingAddressLastName = bLastName;
        model.shippingAddressEmail = bEmail;
        model.shippingAddressPhoneNoFirst = bPhoneNoFirst;
        model.shippingAddressPhoneNoSecond = bPhoneNoSecond;
        model.shippingAddressAddressFirst = bAddressFirst;
        model.shippingAddressAddressSecond = bAddressSecond;
        model.shippingAddressCompanyName = bCompanyName;
        model.shippingAddressPostCode = bPostCode;
        model.shippingAddressAreaId = bAreaId;

      } else {
        model.billingAddressFirstName = bFirstName;
        model.billingAddressLastName = bLastName;
        model.billingAddressEmail = bEmail;
        model.billingAddressPhoneNoFirst = bPhoneNoFirst;
        model.billingAddressPhoneNoSecond = bPhoneNoSecond;
        model.billingAddressAddressFirst = bAddressFirst;
        model.billingAddressAddressSecond = bAddressSecond;
        model.billingAddressCompanyName = bCompanyName;
        model.billingAddressPostCode = bPostCode;
        model.billingAddressAreaId = bAreaId;

        model.shippingAddressFirstName = sFirstName;
        model.shippingAddressLastName = sLastName;
        model.shippingAddressEmail = sEmail;
        model.shippingAddressPhoneNoFirst = sPhoneNoFirst;
        model.shippingAddressPhoneNoSecond = sPhoneNoSecond;
        model.shippingAddressAddressFirst = sAddressFirst;
        model.shippingAddressAddressSecond = sAddressSecond;
        model.shippingAddressCompanyName = sCompanyName;
        model.shippingAddressPostCode = sPostCode;

        model.shippingAddressAreaId = sAreaId;
      }

      model.customerIP = ip;
      model.shippingCost = getTotalSAhippingChargeMemo?.totalCharge;
      model.totalWeight = 0;
      model.orderProductRequest = [];
      model.referrerId = ref;
      model.isDeliveryChargePaid = isSelectFullPayment ? true : isSelectAdvanceShipping;
      model.isOrderTotalPaid = isSelectFullPayment;
      var p = 0;

      cartItems.filter(_ => _.isSelected).map((item: ShoppingCartFavouriteModel, index: any) => {
        let op = new OrderProductRequest();
        op.productId = item.productId;
        op.quantity = item.productQuantity;
        op.unitPrice = item.unitPrice;
        op.totalPrice = item.totalPrice;
        op.unitIncome = item.unitIncome;
        op.totalIncome = item.totalIncome;
        op.attributes = JSON.stringify(item.attributes);;
        op.bannerPath = item.bannerPath;
        op.attributeValueIds = item.attributeValueIds;

        if (item && item.totalPrice) {
          p = p + item.totalPrice;
        }
        model.orderProductRequest?.push(op);
      });
      model.totalAmount = p;
      model.isInSideDhaka = bDistrictId === 65 ? true : false;

      setIsConformOrderButtonLoading(true);

      resellingSubmitOrderMainBalanceService(model)
        .then((res: any) => {
          setIsShowSnackBar(true);
          setSnackBarMessage("Successfully Placed Order from Main Balance");
          setSnackBarMessageType("success");
          setTimeout(() => {
            setIsShowSnackBar(false);
          }, 2000);
          setIsConformOrderButtonLoading(false);
          navigate("/reselling-platform/myOrder");
        })
        .catch((error) => {
          setIsConformOrderButtonLoading(false);
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.BillingAddressRequest &&
            error.response.data.errors.BillingAddressRequest.FirstName &&
            error.response.data.errors.BillingAddressRequest.FirstName.length >
            0
          ) {
          } else {
          }
        });
    }
  }

  const [isLoading, setIsLoading] = useState(false);

  if (isLoading) {
    return (
      <div className="container h-screen flex items-center justify-center mx-auto px-2 md:px-0 pb-12 ">
        <AppLoader status={true} />
      </div>
    );
  }

  return (
    <div className="container mx-auto py-8 mb-16 md:py-12 px-2 lg:px-0">
      {isShowSnackBar && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}

      <div className="flex flex-col md:flex-row justify-between 2xl:gap-8 gap-y-6 gap-4">
        {/* billing address div here  */}
        <div className="w-full md:w-1/2 space-y-2 lg:space-y-4">
          <h2 className="subTitle">Billing & Shipping Details</h2>
          {/* first name and last name div  */}
          <div className="flex flex-col lg:flex-row justify-between items-center gap-2 lg:gap-4">
            <AppInput
              type="text"
              label="First name"
              className="myInput"
              placeholder="Enter your first name"
              onChange={handleBFirstName}
              errorMessage={bFirstNameError}
              value={bFirstName}
              isRequired={true}
            />
            <AppInput
              label="Last name"
              type="text"
              className="myInput"
              placeholder="Enter your last name"
              onChange={(value: any) => setBLastName(value)}
              value={bLastName}
            />
          </div>
          {/* phone number div section here  */}
          <div className="flex flex-col lg:flex-row justify-between items-center gap-2 lg:gap-4">
            <AppInput
              label="Phone number one"
              type="text"
              className="myInput"
              placeholder="Enter your First phone number"
              onChange={handleBPhoneNoFirst}
              errorMessage={bPhoneNoFirstError}
              value={bPhoneNoFirst}
              isRequired={true}
            />
            <AppInput
              label="Phone number two"
              type="text"
              className="myInput"
              placeholder="Enter your Second phone number"
              onChange={handleBPhoneNoSecond}
              value={bPhoneNoSecond}
              errorMessage={bPhoneNoSecondError}
            />
          </div>
          {/* these is email div  */}
          <AppInput
            label="Email"
            type="text"
            className="myInput"
            placeholder="Enter your email"
            onChange={handleBEmail}
            value={bEmail}
            errorMessage={bEmailError}
          />
          {/* these is address div  */}
          <AppInput
            label="Address"
            type="text"
            className="myInput"
            placeholder="Enter your address"
            onChange={handleBAddressFirst}
            errorMessage={bAddressFirstError}
            value={bAddressFirst}
            isRequired={true}
          />
          <AppInput
            label="Area/Bazar"
            type="text"
            placeholder="Enter your Area/Bazar"
            className="myInput"
            onChange={(value: any) => setBAddressSecond(value)}
            errorMessage={bAddressFirstError}
            value={bAddressSecond}
          />
          {/* these is division and district div  */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4">
            <div>
              <label className="md:text-base text-sm" placeholder="Dhaka">Division <span style={{ color: "red", fontSize: 20 }}>*</span></label>
              <AppSelect
                defaultValue="Select Division"
                data={divisionIdNameModels}
                value={bDivisionId}
                onChange={handleBDivision}
                errorMessage={bDivisionError}
              />
            </div>
            {bDivisionId !== 0 && (
              <div>
                <label className="md:text-base text-sm" placeholder="Dhaka">District <span style={{ color: "red", fontSize: 20 }}>*</span></label>
                <AppSelect
                  defaultValue="Select your District"
                  data={districtsIdNameModels}
                  value={bDistrictId}
                  onChange={handleBDistrict}
                  errorMessage={bDistrictError}
                />
              </div>
            )}
          </div>
          {/* these is area and postal div  */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4">
            {bDivisionId !== 0 && bDistrictId !== 0 && (
              <div>
                <label className="md:text-base text-sm" placeholder="Dhaka">Thana <span style={{ color: "red", fontSize: 20 }}>*</span></label>
                <AppSelect
                  defaultValue="Select your Thana"
                  data={areaIdNameModels}
                  value={bAreaId}
                  onChange={handleBArea}
                  errorMessage={bAreaError}
                />
              </div>
            )}

            {/* <AppInput
              label="Post code"
              type="text"
              className="myInput"
              placeholder="post code"
              onChange={(e: any) => setBPostCode(e)}
              value={bPostCode}
            /> */}
          </div>
        </div>

        {/* details info with product here  */}
        <div className="w-full md:w-1/2 flex flex-col">
          <div className="h-[35dvh] md:h-[50dvh]">
            <AppScrollBar height={`100%`}>
              <div className="space-y-4 pb-2">
                {groupedProducts.map((vendorProducts: any, index: any) => (
                  <section key={index}>
                    {(vendorProducts?.filter((_: any) => _.isSelected)?.length > 0) && (
                      <h2 className="text pb-2">{vendorProducts[0].vendorName}</h2>
                    )}

                    <ul className="space-y-2">
                      {vendorProducts?.filter((_: any) => _.isSelected).map((item: any, indx: any) => (
                        <>
                          <div className="flex items-center justify-between md:gap-x-2 gap-x-[2px]">
                            {/* product details section here  */}
                            <div className="flex items-center gap-x-1">
                              {/* these is product details image section  */}
                              <div className="md:rounded-2xl rounded cursor-pointer">
                                <img className='md:w-16 w-12 md:h-16 h-12 object-cover md:rounded-xl rounded' src={item?.bannerPath} alt="These is product pic" />
                              </div>
                              {/* this is product details details section  */}
                              <div className="">
                                <h3 className="md:text-base text-xs"> {item?.productName} </h3>

                                {item?.attributes && (
                                  <div className="">
                                    {item?.attributes?.map((attribute: any, x: any) => (
                                      attribute?.name === "Color" ? (
                                        <div key={x}>
                                          <p>{attribute?.name}:</p>
                                          <div style={{ background: attribute?.value }}></div>
                                        </div>
                                      ) : (
                                        <div key={x} className="flex items-center gap-x-[2px] md:gap-x-1">
                                          <p>{attribute?.name}:</p>
                                          <div>{attribute?.value}</div>
                                        </div>
                                      )
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                            {/* these is quantity and price section  */}
                            <div className="flex justify-between 2xl:pr-8 pr-4 items-center md:gap-x-4 gap-x-1">
                              {/* these ia price section  */}
                              <div className="flex items-center md:text-base text-sm  md:gap-x-[2px] gap-x-[1px]">
                                <span>Price:</span> <TbCurrencyTaka /> {item?.unitPrice}
                              </div>
                              {/* these is quantity section  */}
                              <div className="flex items-center md:text-base text-sm md:gap-x-1 gap-x-[1px]">
                                <span>Qtn:</span> {item?.productQuantity}
                              </div>
                              {/* these is total section  */}
                              <div className="flex items-center md:text-base text-sm  gap-x-[1px]">
                                <span>Total:</span> <TbCurrencyTaka /> {item?.totalPrice}
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </ul>
                  </section>
                ))}
              </div>
            </AppScrollBar>
          </div>
          {bDistrictId !== 0 &&
            <>
              <div className="border-y border-gray-600 py-2 px-1">
                <div className="flex flex-col text-xs md:text-lg justify-between text-textBlack">
                  <p className="text-center font-medium">You will order {totalItem} items.</p>
                  <p className="font-medium">Your delivery charge is: </p>
                  {getTotalSAhippingChargeMemo?.shippingCharges?.map((_: any) => {
                    return (
                      <div className="flex items-center text-sm gap-x-1 justify-between pl-6">
                        <p>{_.vendorName}</p>
                        <p className="flex items-center"> <TbCurrencyTaka />{_.charge}</p>
                      </div>
                    )
                  })}
                </div>

                <div className="flex items-center font-medium justify-between">
                  <p>Total Shipping Charge:</p>
                  <p className="flex items-center"><TbCurrencyTaka /> {getTotalSAhippingChargeMemo?.totalCharge}</p>
                </div>
                {/* <p className="text-royalBlue text-xs md:text-sm"> Shipping charge: </p>
            <p className="text-royalBlue text-xs md:text-sm flex items-center">Inside Dhaka <TbCurrencyTaka /> {insideDhakAShippingCharge}/=*</p>
            <p className="text-royalBlue text-xs md:text-sm flex items-center">Outside Dhaka <TbCurrencyTaka /> {outideDhakAShippingCharge}/=*</p> */}
              </div>

              <div className="flex items-center justify-between subTitle text-textBlack pt-2 px-1">
                <span>Total payment:</span>   <p className="flex items-center"> <TbCurrencyTaka />{totalPrice} + <TbCurrencyTaka />{getTotalSAhippingChargeMemo?.totalCharge} = {totalPrice + getTotalSAhippingChargeMemo?.totalCharge}</p>
              </div>
              <p className="text-end text-xs text-gray-500">VAT included, where applicable</p>
              {/* these is payment method  */}
              <h3 className="subTitle">Payment method:</h3>

              <div className="flex items-center justify-center gap-4 pt-2">
                <AppButton onClick={selectAdvanceShippingCharge} text="Only Shipping Charge" className={`${isSelectAdvanceShipping ? 'resellingBtn' : 'resellingOutlineBtn'} `} />
                <AppButton onClick={selectFullPayment} text="Full Payment" className={`${isSelectFullPayment ? "resellingBtn" : 'resellingOutlineBtn'}`} />
              </div>

              {isPaymentSectionVisible &&
                <>
                  <p className="subTitle pt-4">Payable Amount: {payableAmount} </p>
                  <div className="flex items-center justify-center gap-2 lg:gap-4 pt-2">
                    {/* <div onClick={selectCashOnDelivery} className={`${isSelectedCashOnDelivery ? 'selected' : ''} flex flex-col gap-y-1 mt-2 items-center gap-x-2 cursor-pointer`}>
                    <img className="w-14 md:w-20 h-10 md:h-12 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/image_53_fgc7ul.png" alt="" />
                    <p className="text-center text-sm md:text-lg">Cash On Delivery</p>
                  </div> */}
                    <div onClick={selectBkash} className={`w-1/3 lg:w-1/5 h-20 centerDiv flex-col cursor-pointer myBorder border-reselling text-sm ${isSelectedBkash ? "bg-reselling text-white" : ''}`}>
                      <img className="w-2/3 h-2/4 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/bkash-logo-0C1572FBB4-seeklogo.com_zl2zzj.png" alt="" />
                      <p className="text-center text-sm md:text-base">Bkash</p>
                    </div>

                    <div onClick={selectMainBalance} className={`w-1/3 lg:w-1/5 h-20 centerDiv flex-col cursor-pointer myBorder border-reselling text-sm ${isSelectedMainBalance ? "bg-reselling text-white" : ""}`}>
                      {isSelectedMainBalance ?
                        <img className="w-2/3 h-2/4 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/wallet_3_qg1iyu.png" alt="" />
                        :
                        <img className="w-2/3 h-2/4 object-contain" src="https://hb-stage-files.s3.ap-southeast-1.amazonaws.com/softKarrot_app_images/wallet_cfvuri.png" alt="" />
                      }
                      <p className="text-center text-sm md:text-base">Main Balance</p>
                    </div>

                  </div>
                  {isSelectedMainBalance && (
                    <div className="p-2 md:w-96">
                      {wallet.mainBalance >= payableAmount ? (
                        <div className="text-royalBlue text-center font-medium py-2">
                          <p>You have to pay: {payableAmount}</p>
                          <p>Your Main Balance have: {wallet.mainBalance}</p>
                          <p>Your remaining balance will be: {wallet.mainBalance - payableAmount}</p>
                        </div>
                      ) : (
                        <div className='text-royalBlue font-medium p-2'>
                          <p>You do not have sufficient balance you have {wallet.mainBalance}</p>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="flex justify-end mt-4">
                    {isSelectedBkash && (
                      <AppButton
                        isSubmitting={isConfirmOrderButtonLoading}
                        isDisable={isConfirmOrderButtonLoading}
                        className="resellingBtn"
                        text="Confirm With Bkash"
                        onClick={handleSubmitWithBkash}
                      />
                    )}
                    {(isSelectedMainBalance && wallet.mainBalance >= payableAmount) && (
                      <AppButton
                        isSubmitting={isConfirmOrderButtonLoading}
                        isDisable={isConfirmOrderButtonLoading}
                        className="resellingBtn"
                        text="Confirm With Main Balance"
                        onClick={handleSubmitWithMainBalance}
                      />
                    )}
                    {/* <AppButton
                      isSubmitting={isConfrmOrderButtonLoading}
                      isDisable={isConfrmOrderButtonLoading}
                      className="resellingBtn bg-red border-red"
                      text="Please Wait"
                      onClick={() => { }}
                    /> */}
                  </div>
                </>
              }

            </>
          }
        </div>

      </div>
    </div>
  );
}
