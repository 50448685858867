import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../../../../../store";
import { AppLoader } from "../../../../common/AppLoader";
import { AppSearchBox } from "../../../../common/AppSearchBox";
import { AppButton } from "../../../../common/AppButton";
import { AppPagination } from "../../../../common/AppPagination";
import { sSgetRiderListAction } from "../../../../../store/actions/superShop/sSRiderActions";
import { SSGetRiderListRequest } from "../../../../../models/superShopp/rider/riderModel";
import { useNavigate } from "react-router";
import { blockUnBlockUserService } from "../../../../../httpService/user/userServices";
import { AlertColor } from "@mui/material";
import { AppSelect } from "../../../../common/AppSelect";
import { IdNameResponseModel } from "../../../../../models/common/IdNameModel";
import { PaginationPayloadModel } from "../../../../../models/common/paginationModels";
import { sSGetBazarListAction } from "../../../../../store/actions/superShop/sSBazarActions";
import { AppSnackBar } from "../../../../common/AppSnackBar";
import { SSGetBazarListRequest } from "../../../../../models/superShopp/Address/sSBazar/sSBazarModel";

export default function SSGetRiderList() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [snackBarMessage, setsnackBarMessage] = useState('');
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);

  var request = new SSGetRiderListRequest();

  const riderList = useSelector((state: RootState) => {
    return state.sSGetRiderListResponse.data;
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        request.limit = 10;
        request.offset = 0;
        await dispatch(sSgetRiderListAction(request));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  const handleOnRowChange = (payload: SSGetRiderListRequest) => {
    const fetchData = async () => {
      try {
        request.limit = payload.limit;
        request.offset = 0;
        await dispatch(sSgetRiderListAction(request));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }

  const handleOnPageChange = (payload: SSGetRiderListRequest) => {
    const fetchData = async () => {
      try {
        request.limit = payload.limit;
        request.offset = payload.offset;
        await dispatch(sSgetRiderListAction(request));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }

  const onSearch = (searchQuery: string) => {
    request.searchQuery = searchQuery;
    dispatch(sSgetRiderListAction(request));
  };

  const bazarList = useSelector((state: RootState) => {
    return state.sSBazarListResponse.data;
  });

  var idNameModels: IdNameResponseModel[] = [];
  bazarList.items?.map((x: { id: number; name: string | undefined }) => {
    var temp = new IdNameResponseModel();
    temp.id = x.id;
    temp.name = x.name;
    idNameModels.push(temp);
  });

  useEffect(() => {
    let model = new SSGetBazarListRequest();
    model.limit = 1000;
    model.offset = 0;
    dispatch(sSGetBazarListAction(model));
  }, []);

  const [bazarId, setBazarId] = useState(0);

  const handleBazarIdChange = (event: any) => {
    setBazarId(event);
    request.bazarId = event;
    dispatch(sSgetRiderListAction(request));
  };

  const navigateToAdd = () => {
    navigate("/super-shop/rider/add");
  };

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleBlockUnblockUser = (id: any) => {
    var model = { userId: id };
    setIsButtonLoading(true);
    blockUnBlockUserService(model)
      .then((res: any) => {
        setIsButtonLoading(false);
        setSnackBarOn(true)
        setsnackBarMessage("Successfully blocked user")
        setSnackBarMessageType('success')
        dispatch(sSgetRiderListAction(request));
      })
      .catch((error: any) => {
        setIsButtonLoading(false);
        setSnackBarOn(true)
        setsnackBarMessage("Something wrong")
        setSnackBarMessageType('error')
        dispatch(sSgetRiderListAction(request));
      })
  }

  return (
    <div>
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      <h2 className="title text-center">Rider ({riderList?.size})</h2>

      <div className="flex flex-col lg:flex-row lg:items-center gap-2 lg:gap-6 pt-2">
        <AppButton onClick={navigateToAdd} text="Add Rider" />
        <AppSearchBox className="myInput" onChange={onSearch} placeholder="Search Here" />
        <AppSelect
          value={bazarId}
          data={idNameModels}
          defaultValue="Select Bazar"
          onChange={handleBazarIdChange}
        />
      </div>
      {isLoading ? (
        <AppLoader status={true} />
      ) : (
        <>
          {riderList.items ? (
            <div className="w-full pt-4">
              {/* these is header part for desktop */}
              <div className="hidden lg:flex w-full bg-primary myBorder mr-4">
                <div className="centerDiv text-white font-medium py-2.5 border-r w-[30%]">Name</div>
                <div className="centerDiv text-white font-medium py-2.5 border-r w-[30%]">Phone</div>
                <div className="centerDiv text-white font-medium py-2.5 border-r w-[20%]">BazarName</div>
                <div className="centerDiv text-white font-medium py-2.5 border-r w-[10%]">Actoin </div>
              </div>
              {/* these is middle part for desktop */}
              <div className="hidden lg:flex h-[65dvh] 2xl:h-[68dvh] overflow-y-auto flex-col gap-1 py-1.5">
                {riderList.items.map((item: any) => (
                  <div key={item?.id} className="myBorder flex w-full items-center">

                    <div className="centerDiv justify-start border-r border-grey pl-2 py-1.5 h-full whitespace-nowrap overflow-hidden text-ellipsis w-[30%]">{item?.firstName}</div>
                    <div className="centerDiv justify-start border-r border-grey pl-2 py-1.5 h-full whitespace-nowrap overflow-hidden text-ellipsis w-[30%]">{item?.phoneNumber}</div>
                    <div className="centerDiv justify-start border-r border-grey pl-2 py-1.5 h-full whitespace-nowrap overflow-hidden text-ellipsis w-[20%]">{item?.bazarName}</div>
                    <div className="centerDiv border-r border-grey py-1.5 h-full  w-[10%]">
                      {item?.isBlocked === true ? (<div className=''>
                        <AppButton text="UnBlock this user" isSubmitting={isButtonLoading} onClick={() => handleBlockUnblockUser(item?.id)} />
                      </div>) : (<div className=''>
                        <AppButton text="Block this user" isSubmitting={isButtonLoading} onClick={() => handleBlockUnblockUser(item?.id)} />
                      </div>)}
                    </div>
                  </div>
                ))}
              </div>
              {/* these is middle part for mobile */}
              <div className="h-[78dvh] lg:hidden overflow-y-auto flex flex-col gap-2">
                {riderList.items.map((item?: any) => (
                  <div key={item?.id} className="myBorder flex flex-col w-full p-2">
                    <div className="flex flex-col w-full">
                      <div className="flex items-center gap-1">
                        <span className="font-medium">Name:</span> <span>{item?.firstName}</span>
                      </div>
                      <div className="flex items-center gap-1">
                        <span className="font-medium">Phone:</span> <span>{item?.phoneNumber}</span>
                      </div>
                      <div className="flex items-center gap-1">
                        <span className="font-medium">Bazar Name:</span> <span>{item?.bazarName}</span>
                      </div>
                      <div className="flex items-center gap-1">
                        {item?.isBlocked === true ? (<div className=''>
                          <AppButton text="UnBlock this user" isSubmitting={isButtonLoading} onClick={() => handleBlockUnblockUser(item?.id)} />
                        </div>) : (<div className=''>
                          <AppButton text="Block this user" isSubmitting={isButtonLoading} onClick={() => handleBlockUnblockUser(item?.id)} />
                        </div>)}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="py-1 flex items-center">
                <AppPagination curPage={1} limit={10} offset={0} onPageChange={handleOnPageChange} onRowChange={handleOnRowChange} size={riderList?.size} />
              </div>
            </div>
          ) : (
            <div className="w-full h-[50dvh] flex justify-center items-center text-3xl text-textBlack/90 font-medium">
              <span>No Rider found</span>
            </div>
          )}
        </>)}

    </div>
  );
}
