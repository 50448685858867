import { PaginationPayloadModel } from '../../models/common/paginationModels';
import { GET, POST, PUT, DELETE } from '../service'

export const createMagazinePostService = (model: any) => {
    return POST(
        `/api/MagazinePost/CreateMagazinePost`, model
    );
};

export const getMagazinePostListService = (payload?: PaginationPayloadModel) => {
    return GET(`/api/MagazinePost/GetMagazinePostList?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}`);
};

export const getMyMagazinePostListService = (payload?: PaginationPayloadModel) => {
    return GET(`/api/MagazinePost/GetMagazinePostList?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}&myPost=${true}`);
};

export const getMagazinePostByIdService = (data: any) => {
    return GET(`/api/MagazinePost/GetMagazinePostById`, {
        data: { "PostId": data }
    });
};

export const updateMagazinePostService = (data: any) => {
    return PUT(`/api/MagazinePost/UpdateMagazinePost`, data);
};

export const deleteMagazinePostService = (data: any) => {
    return DELETE(`/api/MagazinePost/RemoveMagazinePost`, {
        data: { "id": data },
    });
};

export const deleteMagazinePostMediaService = (data: any) => {
    return DELETE(`/api/MagazinePost/RemoveMagazinePostMedia`, {
        data: { "ids": data },
    });
};

export const submitRemoveLikeService = (data: any) => {
    return POST(`/api/MagazinePost/SubmitRemoveLike`, {
        "MagazinePostId": data
    });
};

export const getIsAlreadyLikeOrNotService = () => {
    return GET(`/api/MagazinePost/IsAlreadyLikeOrNot`);
};



