import { SSGET_SUB_CATEGORY_LIST, SSGET_SUB_CATEGORY_BY_ID } from "../../constants/superShop/ssSubCategoryConstants";

const inititalState = {
    data: [],
    dataById: []
}

export function sSGetSubCategoryListReducer(state = inititalState, action: any) {
    switch (action.type) {
        case SSGET_SUB_CATEGORY_LIST:
            return { ...state, loading: false, data: action.payload };
        case SSGET_SUB_CATEGORY_BY_ID:
            return { ...state, loading: false, dataById: action.payload };
        default:
            return state;
    }
}

export function sSGetSubCategoryByIdReducer(state = { data: {} }, action: any) {
	switch (action.type) {
		case SSGET_SUB_CATEGORY_BY_ID:
			return { loading: false, data: action.payload };
		default:
			return state;
	}
}