import { Routes, Route } from "react-router-dom";
import AdminGetResellerWithdrawRequests from "../../components/admin/auth/wallet/AdminGetResellerWithdrawRequests";
import AdminGetResellerPoints from "../../components/admin/auth/wallet/AdminGetResellerPoints";
import VendorRequest from "../../components/admin/auth/user/AdminGetVendorRequests";
import AdminGetCompanyWallet from "../../components/admin/auth/wallet/AdminGetCompanyWallet";
import AdminGetVendorWithdrawRequests from "../../components/admin/auth/wallet/AdminGetVendorWithdrawRequests";
import { AdminCreateDataOfferVendor } from "../../components/admin/auth/user/AdminCreateDataOfferVendor";
import AdminGetDataOfferVendorWithdrawRequests from "../../components/admin/auth/wallet/AdminGetDataOfferVendorWithdrawRequests";
import AdminGetUserList from "../../components/admin/auth/user/AdminGetUserList";
import AdminCreateUser from "../../components/admin/auth/user/AdminCreateUser";
import AdminUpdateUser from "../../components/admin/auth/user/AdminUpdateUser";
import AdminGetWalletRechargeRequests from "../../components/admin/auth/wallet/AdminGetWalletRechargeRequests";
import AdminGetCompanyWalletHistory from "../../components/admin/auth/wallet/AdminGetCompanyWalletHistory";
import AdminGetCompanyTeam from "../../components/admin/auth/team/AdminGetCompanyTeam";
import AdminGetWalletCalculations from "../../components/admin/auth/wallet/AdminGetWalletCalculations";

export default function AuthRoute() {
	return (
		<>
			<Routes>
				<Route path="/users" element={<AdminGetUserList />} />
				<Route path="/user/add" element={<AdminCreateUser />} />
				<Route path="/user/update/:id" element={<AdminUpdateUser />} />
				<Route path="/reseller-wr" element={<AdminGetResellerWithdrawRequests />} />
				<Route path="/wallet-rr" element={<AdminGetWalletRechargeRequests />} />
				<Route path="/vendor-wr" element={<AdminGetVendorWithdrawRequests />} />
				<Route path="/data-offer-vendor-wr" element={<AdminGetDataOfferVendorWithdrawRequests />} />
				<Route path="/users-points/:id" element={<AdminGetResellerPoints />} />
				<Route path="/vendorrequest" element={<VendorRequest />} />
				<Route path="/companyWallet" element={<AdminGetCompanyWallet />} />
				<Route path="/companyWallet/history" element={<AdminGetCompanyWalletHistory />} />
				<Route path="/create-data-offer-vendor" element={<AdminCreateDataOfferVendor />} />
				<Route path="/companyTeam" element={<AdminGetCompanyTeam />} />
				<Route path="/wallet-calculations" element={<AdminGetWalletCalculations />} />
			</Routes>
		</>
	);
}
