import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppInput } from "../../../../../common/AppInput";
import { AppButton } from "../../../../../common/AppButton";
import { AlertColor } from "@mui/material";
import { AppSnackBar } from "../../../../../common/AppSnackBar";
import { RootState } from "../../../../../../store";
import { sSGetBazarByIdAction, sSGetBazarListAction } from "../../../../../../store/actions/superShop/sSBazarActions";
import { sSupdateBazarService } from "../../../../../../httpService/superShops/ssBazarServices";
import { SSUpdateBzar } from "../../../../../../models/superShopp/Address/sSBazar/sSBazarModel";
import { AppLoader } from "../../../../../common/AppLoader";

export default function SSUpdateBazar() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const bazar = useSelector((state: RootState) => {
    return state.sSGetBazarByIdResponse.data;
  });

  useEffect(() => {
    dispatch(sSGetBazarByIdAction(id));
  }, [dispatch]);

  const [name, setName] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");

  const [bnName, setBnName] = useState("");
  const [bnNameErrorMessage, setBnNameErrorMessage] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState("");

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if ((bazar)) {
      try {
        setName(bazar.name);
        setBnName(bazar.bnName);
        setPhoneNumber(bazar.phoneNumber)
      } catch {
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    }
  }, [bazar]);


  const handleNameChange = (event: any) => {
    setName(event);
    setNameErrorMessage("");
  };
  const handleBnNameChange = (event: any) => {
    setBnName(event);
    setBnNameErrorMessage("");
  };
  
  const handlePhoneNumber = (event: any) => {
    setPhoneNumber(event);
    setPhoneNumberErrorMessage("");
  };

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    var model = new SSUpdateBzar();
    model.id = id;
    model.name = name;
    model.BnName = bnName;
    model.PhoneNumber = phoneNumber;

    setIsButtonLoading(true);

    setSnackBarOn(false);
    sSupdateBazarService(model)
      .then(() => {
        setSnackBarOn(true);
        setsnackBarMessage("Successfull");
        setSnackBarMessageType("success");

        setIsButtonLoading(false);

        setTimeout(() => {
          navigate("/super-shop/bazar-list");
        }, 300);

        dispatch(sSGetBazarListAction());
      })
      .catch((error: any) => {
        setIsButtonLoading(false);
        setNameErrorMessage(error.response.data.errors?.Name?.[0]);
        setBnNameErrorMessage(error.response.data.errors?.BnName?.[0]);
        setPhoneNumberErrorMessage(error.response.data.errors?.PhoneNumber?.[0]);
      });
  };

  if (isLoading) {
    return (
      <div className="container h-[50dvh] flex items-center justify-center mx-auto px-2 md:px-0 pb-12">
        <AppLoader status={true} />
      </div>
    );
  }

  return (
    <div>
      <div>
        {snackBarOn && (
          <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
        )}
      </div>

      <div className="pl-4 flex items-center justify-between w-3/5 pb-6">
        <AppButton
          onClick={() => navigate("/super-shop/bazar-list")}
          text="Back"
        />
        <h2 className="text-center title">Update Bazar</h2>
      </div>

      <form className="max-w-xl mx-auto myBorder py-4 px-5">
        <AppInput
          label="Bazar name"
          className="myInput"
          type="text"
          placeholder="Enter Bazar Name"
          value={name}
          onChange={handleNameChange}
          errorMessage={nameErrorMessage}
        />
        <AppInput
          label="BnName"
          placeholder="Enter Bangla Name"
          className="myInput"
          type="text"
          value={bnName}
          onChange={handleBnNameChange}
          errorMessage={bnNameErrorMessage}
        />
         <AppInput
          label="Phone Number"
          className="myInput"
          type="text"
          placeholder="Enter Phone Number"
          value={phoneNumber}
          onChange={handlePhoneNumber}
          errorMessage={phoneNumberErrorMessage}
        />
        <div className="flex justify-end">
          <AppButton onClick={handleSubmit} isSubmitting={isButtonLoading} variant="contained" text="Submit" />
        </div>
      </form>
    </div>
  );
}
