import { GET_ALL_CART_ITEMES, GET_ALL_FAVOURITE_ITEMES, GET_CART_ITEMS_ADMIN_LIST, GET_CART_LIST_ADMIN_LIST, GET_IS_ALREADY_FAVOURITE, GET_TOTAL_CART_ITEM, GET_TOTAL_SHIPPING_CHARGE } from "../../constants/shoppingCart/shoppingCartConstants";

function getTotalCartitemReducer(state = { data: 0 }, action: any) {
    switch (action.type) {
        case GET_TOTAL_CART_ITEM:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}


function getTotalCartitemListReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case GET_ALL_CART_ITEMES:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

function getFavouriteItemReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case GET_ALL_FAVOURITE_ITEMES:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

function getIsAlreadyFavouriteReducer(state = { data: false }, action: any) {
    switch (action.type) {
        case GET_IS_ALREADY_FAVOURITE:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

function getCartListAdminReducer(state = { data: 0 }, action: any) {
    switch (action.type) {
        case GET_CART_LIST_ADMIN_LIST:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

function getCartItemsAdminReducer(state = { data: 0 }, action: any) {
    switch (action.type) {
        case GET_CART_ITEMS_ADMIN_LIST:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}
function getTotalShippingChargeReducer(state = { data: false }, action: any) {
    switch (action.type) {
        case GET_TOTAL_SHIPPING_CHARGE:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}




export {
    getTotalCartitemReducer,
    getFavouriteItemReducer,
    getTotalCartitemListReducer,
    getCartItemsAdminReducer,
    getCartListAdminReducer,
    getTotalShippingChargeReducer,
    getIsAlreadyFavouriteReducer
}