import {
    GET_PRODUCT_ATTRIBUTE_MAPPING,
    GET_PRODUCT_BY_ID,
    GET_PRODUCT_LIST,
    GET_PRODUCT_ATTRIBUTE_MAPPING_BY_ID,
    GET_PRODUCT_ATTRIBUTE_VALUES,
    GET_PRODUCT_ATTRIBUTE_VALUE
} from '../../constants/catalog/productConstants';

function productListReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case GET_PRODUCT_LIST:
            return { loading: false, data: action.payload };
        default:
            return state;
    }
}

function productReducer(state = { data: {} }, action: any) {
    switch (action.type) {
        case GET_PRODUCT_BY_ID:
            return { loading: false, data: action.payload };
        default:
            return state;
    }
}

function productAttributeMappingReducer(state = { data: {} }, action: any) {
    switch (action.type) {
        case GET_PRODUCT_ATTRIBUTE_MAPPING:
            return { loading: false, data: action.payload };
        default:
            return state;
    }
}

function productAttributeValuesReducer(state = { data: {} }, action: any) {
    switch (action.type) {
        case GET_PRODUCT_ATTRIBUTE_VALUES:
            return { loading: false, data: action.payload };
        default:
            return state;
    }
}

function productAttributeValueByIdReducer(state = { data: {} }, action: any) {
    switch (action.type) {
        case GET_PRODUCT_ATTRIBUTE_VALUE:
            return { loading: false, data: action.payload };
        default:
            return state;
    }
}

function productAttributeMappingByIdReducer(state = { data: {} }, action: any) {
    switch (action.type) {
        case GET_PRODUCT_ATTRIBUTE_MAPPING_BY_ID:
            return { loading: false, data: action.payload };
        default:
            return state;
    }
}

export {
    productListReducer,
    productReducer,
    productAttributeMappingReducer,
    productAttributeValuesReducer,
    productAttributeMappingByIdReducer,
    productAttributeValueByIdReducer
}