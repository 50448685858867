import { RESELLING_GET_PRODUCTS, RESELLING_GET_PRODUCT_BY_ID, RESELLING_PRODUCT_FILTER } from "../../constants/catalog/resellingProductConstants";

function resellingGetProductsReducer(state = { data: [] }, action: any) {
    switch (action.type) {
        case RESELLING_GET_PRODUCTS:
            return { loading: false, data: action.payload };
        default:
            return state;
    }
}

function resellingGetProductByIdReducer(state = { data: {} }, action: any) {
    switch (action.type) {
        case RESELLING_GET_PRODUCT_BY_ID:
            return { loading: false, data: action.payload };
        default:
            return state;
    }
}

function resellingProductFilterReducer(state = { data: "" }, action: any) {
    switch (action.type) {
        case RESELLING_PRODUCT_FILTER :
            return {...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

export {
    resellingGetProductsReducer,
    resellingGetProductByIdReducer,
    resellingProductFilterReducer
}