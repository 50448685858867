import { Routes, Route } from "react-router";
import AdminGetPopularLinks from "../../components/admin/pLink/AdminGetPopularLinkList";
import AdminUpdatePopularLink from "../../components/admin/pLink/AdminUpdatePopularLink";
import AdminCreatePopularLink from "../../components/admin/pLink/AdminCreatePopularLink";

export default function PopularLinkRoute() {
    return (
        <>
            <Routes>
                <Route index element={<AdminGetPopularLinks />} />

                <Route path="/popular-links" element={<AdminGetPopularLinks />} />
                <Route path="/popular-link/add" element={<AdminCreatePopularLink />} />
                <Route path="/popular-link/update/:id" element={<AdminUpdatePopularLink />} />

            </Routes>
        </>
    );
}