import { SSGET_FAVOURITE_ITEMS, SSGET_IS_ALREADY_EXIST_ITEMS } from "../../constants/superShop/ssFavouriteConstants";

const inititalState = {
    data: [],
}

export function sSGetFavouriteItemsReducer(state = inititalState, action: any) {
    switch (action.type) {
        case SSGET_FAVOURITE_ITEMS:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}

export function sSGetIsAlreadyFavouriteOrNotReducer(state = inititalState, action: any) {
    switch (action.type) {
        case SSGET_IS_ALREADY_EXIST_ITEMS:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
}
