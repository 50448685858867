
import { GET_ACADEMICINSTITUTES_NUMBER, GET_ACADEMIC_INSTITUTE_EMPLOYEES_NUMBER, GET_TEACHERS_ONLY } from "../../../constants/importantNumbers/academicInstitutes/academicInstitutesConstant";

export function getAcademicInstitutesReducer(state = { data: [] }, action: any) {
	switch (action.type) {
		case GET_ACADEMICINSTITUTES_NUMBER:
			return { loading: false, data: action.payload };
		default:
			return state;
	}
}

export function getAcademicInstituteEmployeesReducer(state = { data: [] }, action: any) {
	switch (action.type) {
		case GET_ACADEMIC_INSTITUTE_EMPLOYEES_NUMBER:
			return { loading: false, data: action.payload };
		case GET_TEACHERS_ONLY:
			return { loading: false, data: action.payload };
		default:
			return state;
	}
}
