import {
    adminResellingGetOrderDetailsService,
    resellingGetOrderDetailsService,
    adminResellingGetOrderListService,
    resellingGetOrderListService
} from "../../../httpService/sales/resellingOrderServices";
import { ResellingAdminGetOrderListRequest, GetOrdersCustomerRequest, OrderDetailsAdminRequest } from "../../../models/order/resellingOrdersModels";
import { PaginationPayloadModel } from "../../../models/common/paginationModels";
import { 
    ADMIN_RESELLING_GET_ORDER_DETAILS, 
    RESELLING_GET_ORDER_DETAILS, 
    ADMIN_RESELLING_GET_ORDER_LIST, 
    RESELLING_GET_ORDER_LIST, 
} from "../../constants/sales/resellingOrderConstants";

export const resellingGetOrderListAction = (payload: GetOrdersCustomerRequest) => async (dispatch: any) => {
    var data = resellingGetOrderListService(payload);
    const pload = (await data).data;
    dispatch({ type: RESELLING_GET_ORDER_LIST, payload: pload });
};

export const resellingGetOrderDetailsAction = (payload?: PaginationPayloadModel) => async (dispatch: any) => {
    var data = resellingGetOrderDetailsService(payload);
    const pload = (await data).data;
    dispatch({ type: RESELLING_GET_ORDER_DETAILS, payload: pload });
};

export const adminResellingGetOrderListAction = (payload: ResellingAdminGetOrderListRequest) => async (dispatch: any) => {
    var data = adminResellingGetOrderListService(payload);
    const pload = (await data).data;
    dispatch({ type: ADMIN_RESELLING_GET_ORDER_LIST, payload: pload });
};

export const adminResellingGetOrderDetailsAction = (payload?: OrderDetailsAdminRequest) => async (dispatch: any) => {
    var data = adminResellingGetOrderDetailsService(payload);
    const pload = (await data).data;
    dispatch({ type: ADMIN_RESELLING_GET_ORDER_DETAILS, payload: pload });
};


