import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { IoMdClose } from "react-icons/io";
import { RiArrowRightSLine, RiFileList3Line } from "react-icons/ri";
import { FaRegEdit } from "react-icons/fa";
import { LuLogOut } from "react-icons/lu";
import { MdOutlineModelTraining } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import { AppDefaultProfilePicture } from "../../common/AppDefaultProfilePicture";
import { getUserProfileAction } from "../../../store/actions/auth/user/userActions";
import { useEffect } from "react";
import { IoWalletOutline } from "react-icons/io5";
import { isCountryAdmin, isZilaAdmin } from "../../../common/common";

export default function MainMenuDrawer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const explore = useSelector((state: RootState) => {
    return state.mainMenuResponse.mainMenuDrawer;
  });

  const closeLeftMenu = () => {
    dispatch({ type: "CLOSE-MAIN-MENU-DRAWER" });
  };

  var token = reactLocalStorage.getObject("SignIn");

  const userProfile = useSelector((state: RootState) => {
    return state.getUserProfileResponse.data;
  });

  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);

  const navigateToMyProfile = () => {
    if (token && JSON.stringify(token) !== JSON.stringify({})) {
      navigate("/myProfile");
      dispatch({ type: "CLOSE-MAIN-MENU-DRAWER" });
    } else {
      dispatch({ type: "OPEN-SIGN-IN" });
      dispatch({ type: "CLOSE-SIGN-UP" });
    }
  };

  const navigateToMyTraining = () => {
    if (token && JSON.stringify(token) !== JSON.stringify({})) {
      navigate("/training");
      dispatch({ type: "CLOSE-MAIN-MENU-DRAWER" });
    } else {
      dispatch({ type: "OPEN-SIGN-IN" });
      dispatch({ type: "CLOSE-SIGN-UP" });
    }
  };

  const navigateToAppUserList = () => {
    if (token && JSON.stringify(token) !== JSON.stringify({})) {
      navigate("/appUserList");
      dispatch({ type: "CLOSE-MAIN-MENU-DRAWER" });
    } else {
      dispatch({ type: "OPEN-SIGN-IN" });
      dispatch({ type: "CLOSE-SIGN-UP" });
    }
  };

  const navigateToWallet = () => {
    if (token && JSON.stringify(token) !== JSON.stringify({})) {
      navigate("/myWallet");

      dispatch({ type: "CLOSE-MAIN-MENU-DRAWER" });
    } else {
      dispatch({ type: "OPEN-SIGN-IN" });
      dispatch({ type: "CLOSE-SIGN-UP" });
    }
  };

  const navigateToThanas = () => {
    if (token && JSON.stringify(token) !== JSON.stringify({})) {
      navigate("/thanas");
      dispatch({ type: "CLOSE-MAIN-MENU-DRAWER" });
    } else {
      dispatch({ type: "OPEN-SIGN-IN" });
      dispatch({ type: "CLOSE-SIGN-UP" });
    }
  };

  const navigateToPrivacyPolicy = () => {
    navigate("/privacyPolicy");
    dispatch({ type: "CLOSE-MAIN-MENU-DRAWER" });
  };

  const navigateToAbout = () => {
    navigate("/aboutHelloBazar");
    dispatch({ type: "CLOSE-MAIN-MENU-DRAWER" });
  };

  const handleLogout = () => {
    reactLocalStorage.remove("SignIn");
    reactLocalStorage.remove("RoleName");
    reactLocalStorage.remove("UserId");
    navigate(`/`);
    dispatch({ type: "CLOSE-MAIN-MENU-DRAWER" });
  };

  return (
    <div className={explore ? "drawerOpen" : "drawerHidden"}>
      <div className="drawerContainer w-4/5 md:w-1/3 ">
        {/* left drawer header start here  */}
        <div className="flex justify-end pr-6 pt-6 text-primary">
          <IoMdClose
            onClick={closeLeftMenu}
            className="cursor-pointer  text-2xl font-semibold 2xl:text-4xl"
          />
        </div>
        {/* left drawer body start here  */}
        <div className="space-y-5 px-6 2xl:space-y-6 2xl:px-12">
          {/* these is name and profile section  */}
          <div className="flex items-center gap-x-4">
            <AppDefaultProfilePicture
              className="h-8 w-8 cursor-pointer rounded lg:h-10 lg:w-10 2xl:h-[50px] 2xl:w-[50px]"
              name={userProfile?.firstName}
              profilePicture={userProfile?.photoPath}
            />
            <h2 className="text-xl font-bold  2xl:text-2xl">
              {userProfile?.firstName} {userProfile?.lastName}
            </h2>
          </div>
          {/* these is wallet section  */}
          {/* these is edit profile section  */}
          <div
            onClick={navigateToMyProfile}
            className="myBorder flex cursor-pointer items-center justify-between py-1 pl-4 pr-1 text-primary hover:bg-primary hover:text-white 2xl:px-4"
          >
            <div className=" flex items-center gap-x-2">
              <FaRegEdit className=" text-xl font-semibold md:text-2xl 2xl:text-3xl" />
              <h2 className="text-lg  font-semibold md:text-xl  2xl:text-2xl">
                Edit Profile
              </h2>
            </div>
            <RiArrowRightSLine className=" text-xl font-semibold md:text-2xl 2xl:text-3xl" />
          </div>
          {/* {(rol || role === "COUNTRY_ADMIN" || role === "UPAZILA_ADMIN" || role === "RESELLER") && ( */}
          <div
            onClick={navigateToWallet}
            className="myBorder flex cursor-pointer items-center justify-between py-1 pl-4 pr-1 text-primary hover:bg-primary hover:text-white 2xl:px-4"
          >
            <div className=" flex items-center gap-x-2">
              <IoWalletOutline className=" text-xl font-semibold md:text-2xl 2xl:text-3xl" />
              <h2 className="text-lg font-semibold md:text-xl   2xl:text-2xl">
                My Wallet
              </h2>
            </div>
            <RiArrowRightSLine className=" text-xl font-semibold md:text-2xl 2xl:text-3xl" />
          </div>
          {/* )} */}
          {/* these is favorite section  */}

          {/* these is order section  */}
          {/* these is training section  */}
          <div
            onClick={navigateToMyTraining}
            className="myBorder flex cursor-pointer items-center justify-between py-1 pl-4 pr-1 text-primary hover:bg-primary hover:text-white 2xl:px-4"
          >
            <div className="flex items-center gap-x-2">
              <MdOutlineModelTraining className=" text-xl font-semibold md:text-2xl 2xl:text-3xl" />
              <h2 className="text-lg  font-semibold md:text-xl 2xl:text-2xl">
                Training
              </h2>
            </div>
            <RiArrowRightSLine className=" text-xl font-semibold md:text-2xl 2xl:text-3xl" />
          </div>
          {/* <div onClick={navigateToInvoices} className="flex text-primary hover:text-white myBorder py-1 2xl:px-4 pl-4 pr-1 hover:bg-primary cursor-pointer justify-between items-center">
                        <div className="flex items-center gap-x-2">
                            <MdOutlinePieChartOutline className=" text-xl md:text-2xl 2xl:text-3xl font-semibold" />
                            <h2 className='2xl:text-2xl  md:text-xl text-lg font-semibold'>Invoices</h2>
                        </div>
                        <RiArrowRightSLine className=" text-xl md:text-2xl 2xl:text-3xl font-semibold" />
                    </div> */}
          {isCountryAdmin(reactLocalStorage.get("RoleName")) && (
            <>
              <div
                onClick={navigateToAppUserList}
                className="myBorder flex cursor-pointer items-center justify-between py-1 pl-4 pr-1 text-primary hover:bg-primary hover:text-white 2xl:px-4"
              >
                <div className=" flex items-center gap-x-2">
                  <RiFileList3Line className=" text-xl font-semibold md:text-2xl 2xl:text-3xl" />
                  <h2 className="text-lg  font-semibold md:text-xl   2xl:text-2xl">
                    App User List
                  </h2>
                </div>
                <RiArrowRightSLine className=" text-xl font-semibold md:text-2xl 2xl:text-3xl" />
              </div>
            </>
          )}
          {isCountryAdmin(reactLocalStorage.get("RoleName")) && (
            <>
              <div
                onClick={navigateToThanas}
                className="myBorder flex cursor-pointer items-center justify-between py-1 pl-4 pr-1 text-primary hover:bg-primary hover:text-white 2xl:px-4"
              >
                <div className=" flex items-center gap-x-2">
                  <RiFileList3Line className=" text-xl font-semibold md:text-2xl 2xl:text-3xl" />
                  <h2 className="text-lg  font-semibold md:text-xl   2xl:text-2xl">
                    Thana List
                  </h2>
                </div>
                <RiArrowRightSLine className=" text-xl font-semibold md:text-2xl 2xl:text-3xl" />
              </div>
            </>
          )}
          {isZilaAdmin(reactLocalStorage.get("RoleName")) && (
            <div
              onClick={navigateToAppUserList}
              className="myBorder flex cursor-pointer items-center justify-between py-1 pl-4 pr-1 text-primary hover:bg-primary hover:text-white 2xl:px-4"
            >
              <div className=" flex items-center gap-x-2">
                <RiFileList3Line className=" text-xl font-semibold md:text-2xl 2xl:text-3xl" />
                <h2 className="text-lg  font-semibold md:text-xl   2xl:text-2xl">
                  Upazila Admins
                </h2>
              </div>
              <RiArrowRightSLine className=" text-xl font-semibold md:text-2xl 2xl:text-3xl" />
            </div>
          )}
          {/* these is terms and data section  */}

          {/* <div className="flex items-center justify-between">
                        <div onClick={navigateToPrivacyPolicy} className="myBorder text-primary hover:text-white py-1 2xl:px-4 pl-4 pr-1 hover:bg-primary cursor-pointer w-[47%] md:w-[45%] text-center">
                            <h2 className='2xl:text-xl md:text-base text-sm  font-semibold'>Privacy Policy</h2>
                        </div>
                        <div className="myBorder text-primary hover:text-white py-1 2xl:px-4 pl-4 pr-1 hover:bg-primary cursor-pointer w-[47%] md:w-[45%] text-center">
                            <h2 className='2xl:text-xl md:text-base text-sm  font-semibold'>Data Policy</h2>
                        </div>
                    </div> */}

          <div className="flex items-center justify-between">
            <div
              onClick={navigateToPrivacyPolicy}
              className="myBorder w-[47%] cursor-pointer py-1 pl-4 pr-1 text-center text-primary hover:bg-primary hover:text-white md:w-[45%] 2xl:px-4"
            >
              <h2 className="text-sm font-semibold md:text-base  2xl:text-xl">
                Privacy Policy
              </h2>
            </div>
            <div
              onClick={navigateToAbout}
              className="myBorder w-[47%] cursor-pointer py-1 pl-4 pr-1 text-center text-primary hover:bg-primary hover:text-white md:w-[45%] 2xl:px-4"
            >
              <h2 className="text-sm font-semibold md:text-base  2xl:text-xl">
                About
              </h2>
            </div>
          </div>

          {/* these is sign out section  */}
          <div
            className="myBorder flex cursor-pointer items-center justify-center gap-x-2 py-1 pl-4 pr-1 text-primary hover:bg-primary hover:text-white 2xl:px-4"
            onClick={handleLogout}
          >
            <LuLogOut className=" text-xl font-semibold md:text-2xl 2xl:text-3xl" />
            <h2 className="text-lg  font-semibold md:text-xl  2xl:text-2xl">
              Sign out
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}
