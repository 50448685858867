import { GET_ADMIN_PRODUCT_REVIEW_LIST, GET_PRODUCT_REVIEW_LIST } from "../../constants/catalog/productReview";
import { productAdminReviewListService, productReviewListService } from "../../../httpService/catalog/productReviewServices";
import { PaginationPayloadModel } from "../../../models/common/paginationModels";

const resellingProductReviewsActions = (productId: any) => async (dispatch: any) => {
  var data = productReviewListService(productId);
  const pload = (await data).data;
  dispatch({ type: GET_PRODUCT_REVIEW_LIST, payload: pload });
};

const productAdminReviewList = (paylaod: PaginationPayloadModel) => async (dispatch: any) => {
  var data = productAdminReviewListService(paylaod);
  const pload = (await data).data;
  dispatch({ type: GET_ADMIN_PRODUCT_REVIEW_LIST, payload: pload });
};

export { resellingProductReviewsActions, productAdminReviewList };
