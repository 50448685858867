import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


interface IInputProps {
    label?: string;
    onChange?: any;
    value?: any;
    className?: string;
    errorMessage?: string;
}

export const AppDescription: React.FC<IInputProps> = ({
    label,
    onChange,
    value,
    className,
    errorMessage,
}) => {

    const handleQuillChange = (newValue: any) => {
        const plainText = newValue.replace(/<[^>]*>/g, '');
        onChange(newValue);
    };

    return (
        <div className="app-input-parent py-2">
            <label>{label}</label>
            <ReactQuill
                value={value}
                onChange={handleQuillChange}
                modules={{
                    toolbar: [
                        [{ 'header': [1, 2, 3, false] }],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'color': [] }, { 'background': [] }],
                        [{ 'align': [] }],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        ['clean']
                    ]
                }}
            />
            <p className="text-xs text-red pl-[2px]">{errorMessage}</p>
        </div>
    );
}
