import { useEffect, useState } from 'react'
import { AppSelect } from '../../common/AppSelect'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { IdNameResponseModel } from '../../../models/common/IdNameModel';
import { divisionList } from '../../../store/actions/auth/address/divisionActions';
import { districtListByDivisionId as districtListByDivisionId } from '../../../store/actions/auth/address/districtActions';
import { areaListByDistrictId } from '../../../store/actions/auth/address/areaActions';
import { getUserProfileAction } from '../../../store/actions/auth/user/userActions';
import { AppButton } from "../../common/AppButton";
import { useNavigate } from 'react-router-dom';
import { AppInput } from "../../common/AppInput";
import { createUserBloodDonateService } from '../../../httpService/user/userServices';
import { reactLocalStorage } from 'reactjs-localstorage';
import { AppDatePicker } from '../../common/AppDatePicker';

export default function BloodClubAddUserInfo() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [groupId, setGroupId] = useState(0);
    const [divisionId, setDivisionId] = useState(0);
    const [districtId, setDistrictId] = useState(0);
    const [areaId, setAreaId] = useState(0);

    const [bloodGroupError, setBloodGroupError] = useState("");
    const [divisionError, setDivisionError] = useState("");
    const [donateToError, setdonateToError] = useState("");
    const [dateTimeError, setDateTimeError] = useState("");
    const [districtError, setDistrictError] = useState("");
    const [areaError, setAreaError] = useState("");

    const divisions = useSelector((state: RootState) => {
        return state.divisionListResponse.data;
    });
    var divisionIdNameModels: IdNameResponseModel[] = [];

    divisions?.map((x: any) => {
        var division = new IdNameResponseModel();
        division.id = x.id;
        division.name = x.name;
        divisionIdNameModels.push(division);
    });

    const districts = useSelector((state: RootState) => {
        return state.districtListResponse.data;
    });
    var districtsIdNameModels: IdNameResponseModel[] = [];

    districts?.map((x: any) => {
        var district = new IdNameResponseModel();
        district.id = x.id;
        district.name = x.name;
        districtsIdNameModels.push(district);
    });

    const thanas = useSelector((state: RootState) => {
        return state.areaListResponse.data;
    });
    var areaIdNameModels: IdNameResponseModel[] = [];

    thanas?.map((x: any) => {
        var area = new IdNameResponseModel();
        area.id = x.id;
        area.name = x.name;
        areaIdNameModels.push(area);
    });

    const bloodGroupOptions: IdNameResponseModel[] = [
        { id: 1, name: 'A+' },
        { id: 2, name: 'A-' },
        { id: 3, name: 'B+' },
        { id: 4, name: 'B-' },
        { id: 5, name: 'O+' },
        { id: 6, name: 'O-' },
        { id: 7, name: 'AB+' },
        { id: 8, name: 'AB-' },
    ];

    const [isAlreadyAreaSaved, setIsAlreadyAreaSaved] = useState(true);

    const userProfile = useSelector((state: RootState) => {
        return state.getUserProfileResponse.data;
    });

    useEffect(() => {
        dispatch(getUserProfileAction());
    }, [dispatch]);

    useEffect(() => {
        if (userProfile) {
            if (userProfile?.areaId === 0 || userProfile?.areaId === null) {
                setIsAlreadyAreaSaved(false)
            }
        }
    }, [dispatch, userProfile]);

    useEffect(() => {
        dispatch(divisionList());
    }, [dispatch]);

    useEffect(() => {
        if (divisionId != 0) {
            dispatch(districtListByDivisionId(divisionId));
        }
    }, [divisionId, dispatch]);

    useEffect(() => {
        if (districtId != 0) {
            dispatch(areaListByDistrictId(districtId));
        }
    }, [districtId, dispatch]);

    const navigateToAddInfo = () => {
        navigate("/bloodClub/addUserInfo");
    }
    const ip = reactLocalStorage.get("ip") as string;
    const handleBDivision = (id: number) => {
        setDivisionError("");
        setDivisionId(id);
    };

    const handleBDistrict = (id: number) => {
        setDistrictError("");
        setDistrictId(id);
    };

    const handleBArea = (id: number) => {
        setAreaError("");
        setAreaId(id);
    };

    const handleSelectBloodGroup = (value: any) => {
        setBloodGroupError("");
        setGroupId(value);
    };

    const [donateTo, setDonateTo] = useState("");

    const donateToHandle = (value: any) => {
        setDonateTo(value);
    };

    const handleDonationDate = (event: any) => {
        setDateTimeError("");
        setDonateDateTime(event)
    }

    const [donateDateTime, setDonateDateTime] = useState("");
    const [isSaveButtonLoading, setIsSaveButtonLoading] = useState(false);


    const saveAddInfo = () => {
        let canExecute = false;

        if (userProfile?.bloodGroup == 0) {
            if (!groupId) {
                setBloodGroupError("Blood Group is required");
                canExecute = false;
            }
            if (!donateDateTime) {
                setDateTimeError('Please select a donation date.');
                return;
            }
        }

        const selectedDate = new Date(donateDateTime);
        const currentDate = new Date();

        if (selectedDate > currentDate) {
            setDateTimeError('Invalid Date.');
            return;
        }

        if (isAlreadyAreaSaved == false) {
            if (!divisionId) {
                setDivisionError("Division is required");
                canExecute = false;
            }
            //stay
            if (!districtId && districtId != 0) {
                setDistrictError("District is required");
                canExecute = false;
            }
            if (districtId == 0) {
                setDistrictError("District is required");
                canExecute = false;
            }
            //stay
            if (!areaId) {
                setAreaError("Thana is required");
                canExecute = false;
            }
            if (areaId == 0) {
                setAreaError("Thana is required");
                canExecute = false;
            }
        }

        var payload = {
            bloodGroup: groupId,
            areaId: areaId,
            donateTime: donateDateTime,
            donateTo: donateTo
        }

        setIsSaveButtonLoading(true);
        createUserBloodDonateService(payload)
            .then(() => {
                setIsSaveButtonLoading(false)
                navigate(`/bloodClub`)
            })
            .catch(() => {
                setIsSaveButtonLoading(false)
            });
    };

    return (
        <div className='lg:max-w-[50dvw] mx-auto px-2 lg:px-0 py-2 lg:py-4'>
            <h2 className="title text-red text-center mb-4">Add your info</h2>
            <div className='flex flex-col lg:flex-row items-center justify-between my-2 gap-2 lg:gap-6'>
                <AppInput
                    className="myInput"
                    type="text"
                    placeholder="Donate To"
                    label="Patient Name: "
                    onChange={donateToHandle}
                    errorMessage={donateToError}
                />
            </div>


            <div className='grid grid-cols-1 lg:grid-cols-2 lg:items-end justify-between mb-2 gap-2 lg:gap-6'>
                <div className=''>

                    {userProfile?.bloodGroup !== 0 ? (
                        <AppInput
                            className="myInput"
                            type="text"
                            placeholder=""
                            value={userProfile?.bloodGroupName}
                            label="Blood Group"
                            onChange={() => { }}
                            isDisabled={true}
                        />
                    ) : (
                        <>
                            <p className="md:text-base text-sm">Blood Group</p>
                            <AppSelect
                                defaultValue="Select Blood Group"
                                data={bloodGroupOptions}
                                value={groupId}
                                onChange={handleSelectBloodGroup}
                                errorMessage={bloodGroupError}
                            />
                        </>
                    )}


                </div>
                <div>
                    <p className="md:text-base text-sm">Select date</p>
                    <AppDatePicker
                        className="myInput w-full"
                        onChange={handleDonationDate}
                        errorMessage={dateTimeError}
                    />
                </div>
            </div>

            {isAlreadyAreaSaved === false && (
                <div className='grid grid-cols-1 lg:grid-cols-2 items-center justify-between gap-2 lg:gap-4'>
                    <div className=''>
                        <p className="md:text-base text-sm">Division</p>
                        <AppSelect
                            defaultValue="Select Division"
                            data={divisionIdNameModels}
                            value={divisionId}
                            onChange={handleBDivision}
                            errorMessage={divisionError}
                        />
                    </div>
                    <div className=''>
                        {divisionId !== 0 && (
                            <>
                                <p className="md:text-base text-sm">District</p>
                                <AppSelect
                                    defaultValue="Select District"
                                    data={districtsIdNameModels}
                                    value={districtId}
                                    onChange={handleBDistrict}
                                    errorMessage={districtError}
                                />
                            </>
                        )}
                    </div>
                </div>
            )}

            {isAlreadyAreaSaved === false && (
                <div className='mt-2 w-[49%]'>
                    {divisionId !== 0 && districtId !== 0 && (
                        <>
                            <p className="md:text-base text-sm">Thana</p>
                            <AppSelect
                                defaultValue="Select Thana"
                                data={areaIdNameModels}
                                value={areaId}
                                onChange={handleBArea}
                                errorMessage={areaError}
                            />
                        </>
                    )}
                </div>
            )}

            <div className='flex justify-end pt-2 lg:pt-3'>
                <AppButton
                    text="Save"
                    isSubmitting={isSaveButtonLoading}
                    onClick={saveAddInfo}
                />

            </div>
        </div>
    )
}
