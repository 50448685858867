import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { AlertColor } from "@mui/material";
import { AppSnackBar } from "../../../common/AppSnackBar";
import { AppButton } from "../../../common/AppButton";
import { AppInput } from "../../../common/AppInput";
import { AppSelect } from "../../../common/AppSelect";
import {
  AcademicInstituteAuthorityTypeObject,
  AcademicInstituteTypeObject,
} from "../../../../models/importantNumber/ImportantNumberModel";
import { IdNameResponseModel } from "../../../../models/common/IdNameModel";
import { RootState } from "../../../../store";
import { getUnionAuthListAction } from "../../../../store/actions/importantNumbers/getUnionListAction";
import {
  getAcademicInstitutesByIdService,
  updateAcademicInstitutesService,
} from "../../../../httpService/importantNumbers/academicInstitutesServices";
import { isAdmin } from "../../../../common/common";
import { reactLocalStorage } from "reactjs-localstorage";

export default function INUpdateAcademicInstitutes() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [name, setName] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [academicInstituteType, setAcademicInstituteType] = useState(0);
  const [
    academicInstituteTypeErrorMessage,
    setAcademicInstituteTypeErrorMessage,
  ] = useState("");
  const [academicInstituteAuthorityType, setAcademicInstituteAuthorityType] =
    useState(0);
  const [
    academicInstituteAuthorityTypeErrorMessage,
    setAcademicInstituteAuthorityTypeErrorMessage,
  ] = useState("");
  const [eiin, setEIIN] = useState("");
  const [eiinErrorMessage, setEIINErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [phoneNumberFirst, setPhoneNumberFirst] = useState("");
  const [phoneNumberFirstErrorMessage, setPhoneNumberFirstErrorMessage] =
    useState("");
  const [phoneNumberSecond, setPhoneNumberSecond] = useState("");
  const [phoneNumberSecondErrorMessage, setPhoneNumberSecondErrorMessage] =
    useState("");
  const [note, setNote] = useState("");
  const [noteErrorMessage, setNoteErrorMessage] = useState("");

  const [address, setAddress] = useState("");
  const [addressErrorMessage, setAddressErrorMessage] = useState("");

  const [unionOrWard, setUnionOrWard] = useState(0);
  const [unionOrWardErrorMessage, setUnionOrWardErrorMessage] = useState("");

  const handleNameChange = (event: any) => {
    setName(event);
    setNameErrorMessage("");
  };

  const handleUnionOrWard = (id: any) => [setUnionOrWard(id)];

  const handleAcademicInstituteType = (id: any) => {
    setAcademicInstituteTypeErrorMessage("");
    setAcademicInstituteType(id);
  };

  const handleAcademicInstituteAuthorityType = (id: any) => {
    setAcademicInstituteAuthorityTypeErrorMessage("");
    setAcademicInstituteAuthorityType(id);
  };

  const handleEIIN = (event: any) => {
    setEIIN(event);
    setEIINErrorMessage("");
  };

  const handleEmail = (event: any) => {
    setEmail(event);
    setEmailErrorMessage("");
  };

  const handlePhoneNumberFirst = (event: any) => {
    setPhoneNumberFirst(event);
    setPhoneNumberFirstErrorMessage("");
  };
  const handlePhoneNumberSecond = (event: any) => {
    setPhoneNumberSecond(event);
    setPhoneNumberSecondErrorMessage("");
  };

  const handleNote = (event: any) => {
    setNote(event);
    setNoteErrorMessage("");
  };
  const handleAddress = (event: any) => {
    setAddress(event);
    setAddressErrorMessage("");
  };

  useEffect(() => {
    dispatch(getUnionAuthListAction());
  }, [dispatch]);

  const dataUnionList = useSelector((state: RootState) => {
    return state.getUnionListResponseByAuthArea.data;
  });

  var dataUnionListIdNameModels: IdNameResponseModel[] = [];
  dataUnionList?.map((x: any) => {
    var union = new IdNameResponseModel();
    union.id = x.id;
    union.name = x.name;
    dataUnionListIdNameModels.push(union);
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSnackBarOn(false);
    var payload = {
      id: id,
      name: name,
      academicInstituteType: academicInstituteType,
      academicInstituteAuthorityType: academicInstituteAuthorityType,
      eiin: eiin,
      email: email,
      phoneNumberFirst: phoneNumberFirst,
      phoneNumberSecond: phoneNumberSecond,
      address: address,
      note: note,
      unionOrWardId: unionOrWard,
    };

    setIsButtonLoading(true);

    updateAcademicInstitutesService(payload)
      .then(() => {
        setSnackBarOn(true);
        setsnackBarMessage("Academic Institute Number Added Successfully.");
        setSnackBarMessageType("success");
        setIsButtonLoading(false);
        setTimeout(() => {
          navigateToAcademicInstitutes();
        }, 300);
      })
      .catch((error: any) => {
        setIsButtonLoading(false);
        setNameErrorMessage(error.response.data.errors?.Name?.[0]);
        setPhoneNumberFirstErrorMessage(
          error.response.data.errors?.PhoneNumberFirst?.[0]
        );
        setPhoneNumberSecondErrorMessage(
          error.response.data.errors?.PhoneNumberSecond?.[0]
        );
        setEIINErrorMessage(error.response.data.errors?.EIIN?.[0]);
        setAcademicInstituteTypeErrorMessage(
          error.response.data.errors?.AcademicInstituteType?.[0]
        );
        setAcademicInstituteAuthorityTypeErrorMessage(
          error.response.data.errors?.AcademicInstituteAuthorityType?.[0]
        );
        setAddressErrorMessage(error.response.data.errors?.Address?.[0]);
        setNoteErrorMessage(error.response.data.errors?.Note?.[0]);
        setUnionOrWardErrorMessage(
          error.response.data.errors?.UnionOrWardId?.[0]
        );
      });
  };

  const academicInstitute = async (id: any) => {
    const data = await getAcademicInstitutesByIdService(id);
    const response = data?.data;
    setName(response.name);
    setEIIN(response.eiin);
    setEmail(response.email);
    setAcademicInstituteType(response.academicInstituteType);
    setAcademicInstituteAuthorityType(response.academicInstituteAuthorityType);
    setPhoneNumberFirst(response.phoneNumberFirst);
    setPhoneNumberSecond(response.phoneNumberSecond);
    setAddress(response.address);
    setNote(response.note);
    setUnionOrWard(response.unionOrWardId);
  };

  useEffect(() => {
    academicInstitute(id);
  }, []);

  const navigateToAcademicInstitutes = () => {
    if (isAdmin(reactLocalStorage.get("RoleName"))) {
      navigate("/admin/important-numbers/academic-institutes");
    } else {
      navigate("/important-numbers/academic-institutes");
    }
  };

  return (
    <div className="container mx-auto px-2 py-4">
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      <div className="flex flex-col items-start pb-4 lg:flex-row lg:items-center">
        <AppButton
          className="gradientBtn"
          onClick={navigateToAcademicInstitutes}
          text="Back"
        />
        <div className="w-full">
          <h2 className="title text-center text-cyan-600">
            Update Academic Institute
          </h2>
        </div>
      </div>

      <form className="myBorder mx-auto grid max-w-xl grid-cols-2 items-center gap-x-2 gap-y-2 px-2 py-2 shadow-lg lg:gap-x-4 lg:px-5 lg:py-4">
        <AppInput
          label="Name"
          className="myInput"
          type="text"
          placeholder="Enter Name"
          value={name}
          onChange={handleNameChange}
          errorMessage={nameErrorMessage}
        />
        <div className="">
          <p className="text-sm md:text-base">Academic Institute Type</p>
          <AppSelect
            defaultValue="Select Designation"
            value={academicInstituteType}
            data={AcademicInstituteTypeObject}
            onChange={handleAcademicInstituteType}
            errorMessage={academicInstituteTypeErrorMessage}
          />
        </div>
        <div className="">
          <p className="text-sm md:text-base">
            Academic Institute Authority Type
          </p>
          <AppSelect
            defaultValue="Select Designation"
            value={academicInstituteAuthorityType}
            data={AcademicInstituteAuthorityTypeObject}
            onChange={handleAcademicInstituteAuthorityType}
            errorMessage={academicInstituteAuthorityTypeErrorMessage}
          />
        </div>
        <AppInput
          label="EIIN"
          className="myInput"
          type="text"
          placeholder="Enter EIIN"
          value={eiin}
          onChange={handleEIIN}
          errorMessage={eiinErrorMessage}
        />
        <AppInput
          label="Email"
          className="myInput"
          type="text"
          placeholder="Enter Email"
          value={email}
          onChange={handleEmail}
          errorMessage={emailErrorMessage}
        />

        <AppInput
          label="Phone Number First"
          className="myInput"
          type="text"
          placeholder="Enter Phone Number"
          value={phoneNumberFirst}
          onChange={handlePhoneNumberFirst}
          errorMessage={phoneNumberFirstErrorMessage}
        />

        <AppInput
          label="Phone Number Second"
          className="myInput"
          type="text"
          placeholder="Enter Phone Number"
          value={phoneNumberSecond}
          onChange={handlePhoneNumberSecond}
          errorMessage={phoneNumberSecondErrorMessage}
        />
        <AppInput
          label="Note"
          className="myInput"
          type="text"
          placeholder="Enter Note"
          value={note}
          onChange={handleNote}
          errorMessage={noteErrorMessage}
        />
        <AppInput
          label="Address"
          className="myInput"
          type="text"
          placeholder="Enter Address"
          value={address}
          onChange={handleAddress}
          errorMessage={addressErrorMessage}
        />

        <div className="">
          <p className="text-sm md:text-base">Union or Ward:</p>
          <AppSelect
            defaultValue="Select Ward Or Union"
            value={unionOrWard}
            data={dataUnionListIdNameModels}
            onChange={handleUnionOrWard}
            errorMessage={unionOrWardErrorMessage}
          />
        </div>

        <div className="col-span-2 flex justify-end">
          <AppButton
            text="Update"
            className="gradientBtn"
            onClick={handleSubmit}
            isSubmitting={isButtonLoading}
            variant="contained"
          />
        </div>
      </form>
    </div>
  );
}
