const initialState = {
    leftmenu: false,
};

const initialStateMainMenu = {
    mainMenuDrawer: false,
};

function leftMenuReducer(state = initialState, action: any) {
    if (action.type === "OPEN-LEFT-MENU") {
        return { leftmenu: true };
    }
    if (action.type === "CLOSE-LEFT-MENU") {
        return { leftmenu: false };
    }
    return state;
}


function mainMenuReducer(state = initialStateMainMenu, action: any) {
    if (action.type === "OPEN-MAIN-MENU-DRAWER") {
        return { mainMenuDrawer: true };
    }
    if (action.type === "CLOSE-MAIN-MENU-DRAWER") {
        return { mainMenuDrawer: false };
    }
    return state;
}

export {
    leftMenuReducer,
    mainMenuReducer
};
