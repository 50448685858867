import { AlertColor } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppButton } from "../../../../../common/AppButton";
import { AppInput } from "../../../../../common/AppInput";
import { AppSnackBar } from "../../../../../common/AppSnackBar";
import { SSAddBazar } from "../../../../../../models/superShopp/Address/sSBazar/sSBazarModel";
import { sSaddBazarService } from "../../../../../../httpService/superShops/ssBazarServices";
import { sSGetBazarListAction } from "../../../../../../store/actions/superShop/sSBazarActions";

export default function SSCreateBazar() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [snackBarMessage, setsnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState<AlertColor>();
  const [snackBarOn, setSnackBarOn] = useState(false);

  const [name, setName] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");

  const [bnName, setBnName] = useState("");
  const [bnNameErrorMessage, setBnNameErrorMessage] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState("");

  const handleNameChange = (event: any) => {
    setName(event);
    setNameErrorMessage("");
  };

  const handleBanglaName = (event: any) => {
    setBnName(event);
    setBnNameErrorMessage("");
  };

  const handlePhoneNumber = (event: any) => {
    setPhoneNumber(event);
    setPhoneNumberErrorMessage("");
  };

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    setSnackBarOn(false);

    var model = new SSAddBazar();
    model.name = name;
    model.BnName = bnName;
    model.PhoneNumber = phoneNumber;

    setIsButtonLoading(true);

    sSaddBazarService(model)
      .then(() => {
        setSnackBarOn(true);
        setsnackBarMessage("Bazar Added Successfully.");
        setSnackBarMessageType("success");
        setIsButtonLoading(false);
        setTimeout(() => {
          navigate("/super-shop/bazar-list");
        }, 300);

        dispatch(sSGetBazarListAction());
      })
      .catch((error: any) => {
        setIsButtonLoading(false);
        setNameErrorMessage(error.response.data.errors?.Name?.[0]);
        setBnNameErrorMessage(error.response.data.errors?.BnName?.[0]);
        setPhoneNumberErrorMessage(error.response.data.errors?.PhoneNumber?.[0]);
      });
  };

  return (
    <div>
      {snackBarOn && (
        <AppSnackBar message={snackBarMessage} type={snackBarMessageType} />
      )}
      <div className="pl-4 flex items-center justify-between w-3/5 pb-6">
        <AppButton onClick={() => navigate("/super-shop/bazar-list")} text="Back" />
        <h2 className="text-center title">Create Bazar</h2>
      </div>
      <form className="max-w-xl mx-auto myBorder py-4 px-5">
        <AppInput
          label="Bazar name"
          className="myInput"
          type="text"
          placeholder="Enter Bazar Name"
          onChange={handleNameChange}
          errorMessage={nameErrorMessage}
        />
        <AppInput
          label="Bangla Name"
          className="myInput"
          type="text"
          placeholder="Enter Bangla Name"
          onChange={handleBanglaName}
          errorMessage={bnNameErrorMessage}
        />
        <AppInput
          label="Phone Number"
          className="myInput"
          type="text"
          placeholder="Enter Phone Number"
          onChange={handlePhoneNumber}
          errorMessage={phoneNumberErrorMessage}
        />
        <div className="flex justify-end">
          <AppButton onClick={handleSubmit} isSubmitting={isButtonLoading} variant="contained" text="Submit" />
        </div>
      </form>
    </div>
  );
}
