import React, { useEffect, useState } from 'react'
import { AppInput } from '../../common/AppInput'
import { AppCheckBox } from '../../common/AppCheckBox'
import { AppButton } from '../../common/AppButton'
import { AppDescription } from '../../common/AppDescription'
import { AppSelect } from '../../common/AppSelect'
import { AppFileInput } from '../../common/AppFileInput'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { PhotoModel } from '../../../models/bechteChai/product/bechteChaiProductModels'
import { useNavigate, useParams } from 'react-router'
import { GET } from '../../../httpService/service'
import { magazineCategoryList } from '../../../store/actions/magazine/magazineCategoryActions'
import { PaginationPayloadModel } from '../../../models/common/paginationModels'
import { MagazineCreateMagazineModel } from '../../../models/magazine/magazinePostModels'
import { updateMagazinePostService } from '../../../httpService/magazine/magazinePostServices'

export const MagazineUpdatePost = () => {

  var pModel = new PaginationPayloadModel();

  pModel.limit = 1000;
  pModel.offset = 0;

  var categoryList = useSelector((state: RootState) => {
    return state.magazineCategoryListResponse.data;
  });

  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    postById(id);
    dispatch(magazineCategoryList(pModel))
  }, [])

  const postById = async (id: any) => {
    const data = GET(`/api/MagazinePost/GetMagazinePostById?PostId=${id}`);
    const pload = (await data).data;
    console.log("post data", data)
    loadData(
      //pload.id,
      pload.title,
      pload.description,
      pload.mainCategoryId,
      pload.bannerid
    );
  };

  const loadData = (
    //Id: any,
    title: any,
    description: any,
    mainCategoryId: any,
    bannerid: any
  ) => {
    setPostTitle(title);
    setPostDescription(description);
    setPostMainCategoryId(mainCategoryId);
    setBannerId(bannerid);
  };

  const [postDescription, setPostDescription] = useState('');
  const [postDescriptionError, setPostDescriptionError] = useState('');

  const [postTitle, setPostTitle] = useState('');
  const [postTitleError, setPostTitleError] = useState('');

  const [postMainCategoryId, setPostMainCategoryId] = useState('');
  const [postMainCategoryIdError, setPostMainCategoryIdError] = useState('');

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [bannerId, setBannerId] = useState(0);
  const [bannerError, setBannerError] = useState("");

  const [productMedias, setProductMedias] = useState<PhotoModel[]>([]);
  const [photoIds, setPhotoIds] = useState<any[]>([]);

  const handlePostTitle = (value: any) => {
    setPostTitle(value);
    setPostTitleError('');
  }

  const handlePostDescription = (value: any) => {
    setPostDescription(value);
    setPostDescriptionError("");
  };

  const handlePostMainCategoryId = (value: any) => {
    setPostMainCategoryId(value);
    setPostMainCategoryIdError('');
  }

  const navigate = useNavigate();
  const handelNagivateToPostList = () => {
    navigate(`/postList`);
  }

  const handleBanner = (id: any, path: any) => {
    const photoModel = new PhotoModel();
    photoModel.id = id;
    photoModel.url = path;
    setBannerId(id);
    setBannerError("");
    setProductMedias(prevMedias => [...prevMedias, photoModel]);
    setPhotoIds(prevPhotoId => [...prevPhotoId, id]);
    console.log(id);
    console.log(path);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    var model = new MagazineCreateMagazineModel();

    model.Title = postTitle;
    model.Description = postDescription;
    model.MagazineCategoryId = postMainCategoryId;
    model.MediaIds = [bannerId];

    updateMagazinePostService(model)
      .then((res: any) => {
        handelNagivateToPostList();
      })
      .catch((err: any) => {
        console.log('err', err)
      })
  }


  return (
    <div>
      <h1>MagazineUpdatePost</h1>
      <AppInput
        type="text"
        className="postTitle"
        label="Post Title"
        placeholder="Enter post title"
        onChange={handlePostTitle}
        value={postTitle}
        errorMessage={postTitleError}
      />
      <AppDescription
        value={postDescription}
        label="Enter description of your post"
        onChange={handlePostDescription}
        errorMessage={postDescriptionError}
      />
      <label>Select the category of the post:</label>
      <AppSelect
        defaultValue="Select Post Category"
        value={postMainCategoryId}
        data={categoryList}
        onChange={handlePostMainCategoryId}
        errorMessage={postMainCategoryIdError}
      />

      <AppFileInput onSave={handleBanner} errorMessage={bannerError} />

      <AppButton
        className="createMagazinePost"
        text="Save"
        variant="contained"
        isSubmitting={isButtonLoading}
        color="primary"
        onClick={(e: any) => handleSubmit(e)}
      />
    </div>
  )
}
