import { PaginationPayloadModel } from "../../models/common/paginationModels";
import { GET, POST, PUT } from "../service";

export const createNoticeService = (payload: any) => {
	return POST(`/api/Notice/createNotice`, payload);
};

export const updateNoticeService = (payload: any) => {
	return PUT(`/api/Notice/updateNotice`, payload);
};

export const removeNoticeService = (payload?: any) => {
	return PUT(`/api/Notice/removeNotice`, payload);
};

export const getNoticeListService = (payload?: PaginationPayloadModel) => {
	if (payload) {
		return GET(`/api/Notice/getNotices?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload.searchQuery}`);
	}
	return GET(`/api/Notice/getNotices`);
};

export const getNoticeByIdService = (id: any) => {
	return GET(`/api/Notice/getNoticeById?id=${id}`);
};

export const getNoticeService = () => {
	return GET(`/api/Notice/getNotice`);
};

export const markNoticeSeenService = (id: any) => {
	return POST(`/api/Notice/markNoticeSeen?id=${id}`, null);
}

