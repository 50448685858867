import { GET } from "../service";

export const getOrderInvoicesService = (payload: any) => {
  return GET(
    `/api/Invoice/GetOrderInvoices?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}`
  );
};

export const getOrderInvoiceByIdService = (Id: any) => {
  return GET(`/api/Invoice/GetOrderInvoiceById?Id=${Id}`);
};

export const getDataOfferInvoicesService = (payload: any) => {
  return GET(
    `/api/Invoice/getDataOfferInvoices?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}`
  );
};

export const getDataOfferInvoiceByIdService = (Id: any) => {
  return GET(`/api/Invoice/GetDataOfferInvoiceById?Id=${Id}`);
};
