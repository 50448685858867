const initialState = {
  explore: false,
};

const exploreLevelOneState = {
  exploreLevelOne: true,
};

const exploreLevelTwoState = {
  exploreLevelTwo: false,
};
const exploreLevelTwoData = {
  exploreLevelTwoData: [],
};

const exploreLevelThreeState = {
  exploreLevelThree: false,
};

const exploreLevelThreeData = {
  exploreLevelThreeData: [],
};

function exploreReducer(state = initialState, action: any) {
  if (action.type === "OPEN-EXPLORE") {
    return { explore: true };
  }
  if (action.type === "CLOSE-EXPLORE") {
    return { explore: false };
  }
  return state;
}

function exploreLevelOneStateReducer(
  state = exploreLevelOneState,
  action: any
) {
  if (action.type === "OPEN-EXPLORE-LEVEL-ONE") {
    return { exploreLevelOne: true };
  }
  if (action.type === "CLOSE-EXPLORE-LEVEL-ONE") {
    return { exploreLevelOne: false };
  }
  return state;
}

function exploreLevelTwoStateReducer(
  state = exploreLevelTwoState,
  action: any
) {
  if (action.type === "OPEN-EXPLORE-LEVEL-TWO") {
    return { exploreLevelTwo: true };
  }
  if (action.type === "CLOSE-EXPLORE-LEVEL-TWO") {
    return { exploreLevelTwo: false };
  }
  return state;
}

function exploreLevelTwoDataReducer(state = exploreLevelTwoData, action: any) {
  if (action.type === "EXPLORE-LEVEL-TWO-DATA") {
    return { exploreLevelTwoData: action.payload };
  }
  return state;
}

function exploreLevelThreeStateReducer(
  state = exploreLevelThreeState,
  action: any
) {
  if (action.type === "OPEN-EXPLORE-LEVEL-THREE") {
    return { exploreLevelThree: true };
  }
  if (action.type === "CLOSE-EXPLORE-LEVEL-THREE") {
    return { exploreLevelThree: false };
  }
  return state;
}

function exploreLevelThreeDataReducer(
  state = exploreLevelThreeData,
  action: any
) {
  if (action.type === "EXPLORE-LEVEL-THREE-DATA") {
    return { exploreLevelThreeData: action.payload };
  }
  return state;
}

export {
  exploreReducer,
  exploreLevelOneStateReducer,
  exploreLevelTwoStateReducer,
  exploreLevelThreeStateReducer,
  exploreLevelTwoDataReducer,
  exploreLevelThreeDataReducer,
};
