import { PaginationPayloadModel } from "../../models/common/paginationModels";
import { DELETE, GET, POST, PUT } from "../service";

export const productReviewListService = (productId: any) => {
  return GET(`/api/ProductReview/GetProductReviews?productId=${productId}`);
};

export const productAdminReviewListService = (payload: PaginationPayloadModel) => {
  return GET(`/api/ProductReview/GetAdminProductReviews?limit=${payload?.limit}&offset=${payload?.offset}&searchQuery=${payload?.searchQuery}`);
};

export const productReviewApproveDeclineService = (payload: any) => {
  return PUT(`/api/ProductReview/ApproveDeclineProductReview`, payload);
};

export const addProductReviewService = (data: any) => {
  return POST("/api/productReview/SubmitProductReviewOrReply", data);
};

export const updateProductReviewService = (data: any, id: any) => {
  return PUT(`/api/ProductReview/UpdateProductReview/${id}`, data);
};

export const removeProductReviewService = (data: any) => {
  return DELETE(`/api/ProductReview/RemoveProductReview`, {
    data: { ids: data },
  });
};

export const deleteProductReviewService = (data: any) => {
  return PUT(`/api/ProductReview/DeleteProductReview`, data);
};

export const submitProductRatingService = (data: any) => {
  return POST("/api/productReview/SubmitProductRating", data);
};

export const submitOrderReturnService = (data: any) => {
  return POST("/api/Order/ReturnOrder", data);
};
