import { RootState } from "../../../store";
import { useSelector, useDispatch } from "react-redux";
import { getNotificationListAction } from "../../../store/actions/notification/notificationActions";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationResponse } from "../../../models/notification/notificationsModels";
import _moment from 'moment';
import { AppDefaultProfilePicture } from "../../common/AppDefaultProfilePicture";

export default function GetNotifications() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  let notifications = useSelector((state: RootState) => {
    return state.getNotificationsResponse.data.items as NotificationResponse[];
  });

  useEffect(() => {
    dispatch(getNotificationListAction());
  }, [dispatch]);

  const handleNavigateToTargeturl = (item: NotificationResponse) => {
    let url;
    if (item && item.information) {
      url = JSON.parse(item.information);
    }
    navigate(`${url.TargetUrl}`);
  }

  const calculateTime = (createdDateTime: any) => {
    const createdTime = _moment(createdDateTime, 'YYYY-MM-DD HH:mm:ss.SSSSSSS');
    let formattedTime;

    if (_moment().diff(createdTime, 'hours') < 1) {
      formattedTime = createdTime.fromNow();
    } else if (_moment().diff(createdTime, 'days') < 1) {
      formattedTime = createdTime.format('h:mm A');
    } else {
      formattedTime = createdTime.format('MMM D, YYYY [at] h:mm A');
    }
    return formattedTime;
  }



  return (
    <div className="container mx-auto py-4 md:py-4 px-4 md:px-0 pb-16 md:pb-0">
      <h2 className="title md:px-20">Notifications</h2>
      {/* <div className="notification-list-header">
        <div className="notification-list-header-btn">
          <span>Action</span>
          <MoreVertOutlinedIcon style={{ cursor: "pointer" }} />
        </div>
      </div> */}

      <div className="w-full lg:w-3/5 h-[73dvh] overflow-y-auto mx-auto mt-2 lg:mt-4 flex flex-col gap-2 pb-4">
        {notifications?.length ? (
          notifications?.map((item: NotificationResponse, index: any) => {
            return (
              <div className="myBorder hover:bg-green-100 cursor-pointer flex lg:items-center gap-x-1 md:gap-x-2 py-1 md:py-1.5 px-2 md:px-3" key={index} onClick={() => handleNavigateToTargeturl(item)}>
                <AppDefaultProfilePicture className="customer-review-image" name={item.sender?.name} profilePicture={item.sender?.photoPath} />
                <div className='lg:space-y-1'>
                  <h4 className="text">{item.sender?.name} {item.body} </h4>
                  <p className="text-xsm lg:text-xs">{calculateTime(item.createdDateTime)}</p>
                </div>
              </div>
            )
          })
        ) : (
          <p className="py-16 text-xl md:text-3xl text-gray-600 text-center">No Notification Found</p>
        )}

      </div>
    </div>
  );
}
