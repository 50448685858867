import { DELETE, GET, POST, PUT } from "../service";


export const areaListService = (payload: any) => {
  return GET(`/api/Area/GetAreas?searchQuery=${payload?.searchQuery}&districtId=${payload?.districtId}`);
};

export const areaListAdminService = (payload: any) => {
  return GET(`/api/Area/GetAreasAdmin?searchQuery=${payload?.searchQuery}&districtId=${payload?.districtId}&offset=${payload?.offset}&limit=${payload?.limit}`);

};

export const getAreaByDistrictIdService = (payload: any) => {
  return GET(`/api/Area/GetAreas?DistrictId=${payload}`);
};

export const addAreaService = (data: any) => {
  return POST("/api/Area/CreateArea", data);
};

export const updateAreaService = (data: any) => {
  return PUT(`/api/Area/UpdateArea`, data);
};

export const deleteAreaService = (data: any) => {
  return DELETE(`/api/Area/RemoveArea`, { data: { id: [data] } });
};
