import {
    GET_PRODUCT_BRAND_LIST, GET_PRODUCT_BRAND_LIST_BY_ID
  } from "../../constants/catalog/productBrand";
  
  const inititalState = {
    data: [],
    dataById:[]
  }
  
  function productBrandByListReducer(state = inititalState, action: any) {
    switch (action.type) {
      case GET_PRODUCT_BRAND_LIST:
        return { ...state, loading: false, data: action.payload };
        case GET_PRODUCT_BRAND_LIST_BY_ID:
          return { ...state, loading: false, dataById: action.payload };
      default:
        return state;
    }
  }
  
  
  export { productBrandByListReducer};